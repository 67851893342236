import * as Icons from '@ant-design/icons';
import { Row, Typography, Button } from 'antd';
import styled, { css } from 'styled-components';

const { Title, Text } = Typography;

export const riskLevelList = {
	low: 1,
	moderate: 2,
	high: 3,
	very_high: 4,
	extreme: 5
};

const paletteMap = {
	[riskLevelList.low]: '#2CC852',
	[riskLevelList.moderate]: '#FFDE31',
	[riskLevelList.high]: '#F78A38',
	[riskLevelList.very_high]: '#E74150',
	[riskLevelList.extreme]: '#9B54E2'
};

const darkColor = '#4C4C4E';
const emptyColor = '#B3B3B3';

export const Container = styled(Row)`
	background: ${({ $levelRisk }) => ($levelRisk ? paletteMap[$levelRisk] : emptyColor)};
	border-radius: 5px;
	padding: 5%;
`;

export const stylesIcon = css`
	color: ${({ ismoderate }) => (ismoderate ? darkColor : 'white')};
	font-size: 20;
`;

export const styleModerate = css`
	color: ${({ $levelRisk }) => ($levelRisk === riskLevelList.moderate ? darkColor : 'white')} !important;
`;

const hideItem = css`
	display: none;
`;

export const CircleFilledIcon = styled(Icons.CheckCircleFilled)`
	${stylesIcon}
`;

export const ExclamationFilledIcon = styled(Icons.ExclamationCircleFilled)`
	${stylesIcon}
`;

export const WarningFilledIcon = styled(Icons.WarningFilled)`
	${stylesIcon}
`;

export const TitleCard = styled(Title)`
	${styleModerate}
`;

export const ResumeCard = styled(Text)`
	${styleModerate}
`;

export const ButtonCard = styled(Button)`
	float: right;

	${({ $levelRisk }) => $levelRisk === riskLevelList.low && hideItem}

	span {
		color: ${({ $levelRisk }) => ($levelRisk === riskLevelList.moderate ? darkColor : paletteMap[$levelRisk])};
	}
`;
