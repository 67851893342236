import React, { useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Modal } from 'antd';
import moment from 'moment';

import { useDeleteFile, useFileSummaryList, useDownloadFile } from './hooks/hooks';
import { useApplicationContext } from '@/context/v1/Application/context';

import { Filter } from './Filter/index';
import { List } from './List';

import { Title } from '@/components/Typography';
import { SelectReports } from './SelectReports';
import { FileDTO } from './hooks/types/response';
import { FilterRequest } from './hooks/types/request';
import { clearReports } from '@/redux/reports/actions';
import { SelectEwa } from './SelectEwa';

interface SelectReport {
	file: FileDTO | null;
	type: string | null;
	visible?: boolean;
	visibleModal: boolean;
}

export function Report() {
	const history = useHistory();

	const dispatch = useDispatch();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const { organization, company } = useApplicationContext();

	const queryParams = convertURLParamToObject(searchParams);

	const [page, setPage] = useState(0);
	const [maxReports, setMaxReports] = useState(10);
	const [filter, setFilter] = useState(queryParams);

	const initialValues = mapFilterInitialValues(queryParams) ?? {};

	const { mutateAsync: deleteFile, isLoading: deletingFile } = useDeleteFile();
	const { mutateAsync: downloadFile } = useDownloadFile();
	const {
		data: fileSummaries,
		isLoading,
		isFetching
	} = useFileSummaryList({
		organization_id: organization?.id,
		company_id: company?.id,
		filter: {
			...filter,
			offset: page === 0 ? String(page) : String(page - 1),
			limit: String(maxReports)
		}
	});

	const [selectedReport, setSelectedReport] = useState<SelectReport>({
		file: null,
		type: null,
		visibleModal: false
	});
	const [selectedEwaReport, setSelectedEwaReport] = useState<SelectReport>({
		file: null,
		type: null,
		visibleModal: false
	});

	const onSelectReport = (type: string) => {
		const reportsInFullView = [
			'angle-time',
			'niosh',
			'strain-index',
			'reba',
			'kim-mho',
			'kim-pp',
			'liberty-mutual',
			'back-compressive-force-estimation'
		];
		if (reportsInFullView.includes(type)) {
			history.push(`/reporting/${type}/${selectedReport?.file?.id}`);
		}
		setSelectedReport((current) => ({ ...current, type: type }));
	};

	const onSelectEwaReport = (type: string) => {
		const reportsInFullView = ['preliminary-ergonomic-analysis', 'ewa-jds-d86'];
		if (reportsInFullView.includes(type)) {
			history.push(`/reporting/${type}/${selectedEwaReport?.file?.id}`);
		}
		setSelectedEwaReport((current) => ({ ...current, type: type }));
	};

	const onCloseReport = () => {
		setSelectedReport((current) => ({
			...current,
			visible: false,
			file: null,
			type: null
		}));
		dispatch(clearReports());
	};

	const onCloseEwaReport = () => {
		setSelectedEwaReport((current) => ({
			...current,
			visible: false,
			file: null,
			type: null
		}));
	};

	const onDownload = async ({ id, original_name }: { id: string; original_name: string }) => {
		const data = {
			file_id: id,
			company_id: company.id,
			original_name: original_name,
			organization_id: organization.id
		};
		await downloadFile(data);
	};

	const onSelect = (file: FileDTO) => {
		setSelectedReport((current) => ({
			...current,
			file: file,
			visible: true,
			type: file.type
		}));
	};

	const onSelectEwa = (file: FileDTO) => {
		setSelectedEwaReport((current) => ({
			...current,
			file: file,
			visible: true,
			type: file.type
		}));
	};

	function mapFilterInitialValues(queryParams: FilterRequest) {
		if (hasQueryParams(queryParams)) {
			const { created_at_start, created_at_end, updated_at_start, updated_at_end } = queryParams;
			const created_at = defineInitialDate(created_at_start, created_at_end);
			const updated_at = defineInitialDate(updated_at_start, updated_at_end);
			const initialValues = {
				...queryParams,
				created_at,
				updated_at
			};
			return initialValues;
		}
	}

	function defineInitialDate(start_date: string | undefined, end_date: string | undefined) {
		return start_date && end_date ? [moment(start_date), moment(end_date)] : undefined;
	}

	function hasQueryParams(queryParams: FilterRequest) {
		const queryParamsArray = Object.keys(queryParams);
		return queryParamsArray?.length > 0;
	}

	function convertURLParamToObject(searchParams: URLSearchParams) {
		return Object.fromEntries(searchParams.entries());
	}

	function handlePageChange(page: number) {
		setPage(page);
	}

	function handleMaxReportsChange(reports: number) {
		setMaxReports(reports);
	}

	function handleFilterClick(filterData: FilterRequest) {
		setFilter(filterData);
	}

	async function handleDeleteFile(file_id: string) {
		Modal.confirm({
			title: I18n.get('Heads up! Want to delete this file?'),
			icon: <ExclamationCircleOutlined />,
			okText: I18n.get('Confirm'),
			cancelText: I18n.get('Cancel'),
			okType: 'danger',
			onOk: async () => {
				const body = {
					company_id: company?.id,
					organization_id: organization?.id,
					file_id
				};

				await deleteFile(body);
			}
		});
	}

	function handleInicialValues() {
		if (queryParams.workstation_id) {
			const files = fileSummaries.rows;
			return { ...initialValues, files, company_id: company?.id };
		}
		return initialValues;
	}

	return (
		<Row>
			<SelectReports
				onClose={onCloseReport}
				item={selectedReport.file}
				report={selectedReport.type}
				visible={selectedReport.visible}
				onSelectReport={onSelectReport}
				fileId={selectedReport?.file?.id}
			/>
			<SelectEwa
				onClose={onCloseEwaReport}
				onSelectReport={onSelectEwaReport}
				visible={selectedEwaReport.visible}
			/>
			<Col span={24}>
				<Title level={3}>{I18n.get('Files')}</Title>
			</Col>
			<Col span={24}>
				<Filter initialValues={handleInicialValues()} loading={isFetching} onFilterClick={handleFilterClick} />
			</Col>
			<Col span={24}>
				<List
					data={fileSummaries}
					page={page}
					maxReports={maxReports}
					onPageChange={handlePageChange}
					isLoading={isLoading || isFetching || deletingFile}
					onDelete={handleDeleteFile}
					onSelect={onSelect}
					onDownload={onDownload}
					onMaxReportsChange={handleMaxReportsChange}
					onSeletEwa={onSelectEwa}
				/>
			</Col>
		</Row>
	);
}
