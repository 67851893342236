import React, { createContext, ReactNode, useContext, useState } from 'react';

import { Context } from './index.types';
import { FileDTO } from '@/components/ui/Inputs/types/response/file';

type RulaProviderProps = {
	children: ReactNode;
};

const RulaContext = createContext<Context>({} as Context);

export function RulaProvider({ children }: RulaProviderProps) {
	const [file, setFile] = useState<FileDTO>({} as FileDTO);

	function handleFile(file: FileDTO | undefined) {
		if (file) {
			setFile(file);
		}
	}

	const contextValue: Context = {
		file,
		handleFile
	};

	return <RulaContext.Provider value={contextValue}>{children}</RulaContext.Provider>;
}

export function useRulaContext() {
	const context = useContext(RulaContext);
	return context;
}
