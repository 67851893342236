import React from 'react';
import Table, { ColumnsType } from 'antd/lib/table';
import { Title } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';
import { Col, Row } from 'antd';

import { SeraResultHighestRPN } from '../hooks/types';
import { CustomTable } from './styles';
import { useSeraStepsContext } from '../../context';

interface HighestRPNProps {
	highestRpnScores: SeraResultHighestRPN[];
}

export const HighestRPN: React.FC<HighestRPNProps> = ({ highestRpnScores }) => {
	const { mapRPNToPriority } = useSeraStepsContext();

	const columns: ColumnsType<SeraResultHighestRPN> = [
		{
			title: I18n.get('Position'),
			dataIndex: 'order',
			key: 1,
			align: 'center'
		},
		{
			title: I18n.get('TLID'),
			dataIndex: 'task_name',
			key: 2,
			ellipsis: true,
			align: 'center'
		},
		{
			title: I18n.get('RPN'),
			dataIndex: 'rpn',
			key: 3,
			align: 'center'
		},
		{
			title: I18n.get('Category'),
			dataIndex: 'category',
			key: 4,
			width: '40%',
			align: 'center',
			render: (category) => I18n.get(category)
		},
		{
			title: I18n.get('Priority'),
			dataIndex: 'rpn',
			key: 5,
			align: 'center',
			render: (rpn) => {
				const rpnPriority = mapRPNToPriority(rpn);
				return I18n.get(rpnPriority.priority);
			}
		}
	];

	return (
		<Col span={24}>
			<Row>
				<Title level={5}>Highest RPN</Title>
			</Row>
			<Row justify="center">
				<CustomTable span={16}>
					<Table rowKey="id" pagination={false} dataSource={highestRpnScores} columns={columns} />
				</CustomTable>
			</Row>
		</Col>
	);
};
