import React from 'react';
import { Col, Divider, Row } from 'antd';

import { Information } from './Information';
import { Score } from './Score';

export const ReportInformation = () => {
	return (
		<Row justify="start" gutter={[8, 8]} style={{ marginTop: '2rem' }}>
			<Col xs={24} xl={17} xxl={18}>
				<Information />
			</Col>
			<Col xs={1} xl={0} style={{ minWidth: '100%' }}>
				<Divider style={{ borderLeft: '1px solid #E6E8E8', margin: 0, width: '100%' }} type="horizontal" />
			</Col>
			<Col xs={0} xl={1} style={{ minHeight: '100%' }}>
				<Divider style={{ borderLeft: '1px solid #E6E8E8', margin: 0, height: '100%' }} type="vertical" />
			</Col>
			<Col xs={12} xl={6} xxl={5}>
				<Score />
			</Col>
		</Row>
	);
};
