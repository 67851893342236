import { Form } from 'antd';
import styled from 'styled-components';

export const EditButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 1.5rem;
	height: 1.5rem;
	margin-left: 20px;
	margin-bottom: 2px;
	border-radius: 5px;
	padding: 5px;
	border: none;
	cursor: pointer;
	&:hover {
		background-color: #2874e0;
		color: white;
	}
`;

export const CustomFormItem = styled(Form.Item)`
	.ant-form-item-label {
		label {
			margin-right: 0.5rem;
			::after {
				display: none;
			}
		}
	}
`;
