import React from 'react';

const defaultState = {
	organizationId: '',
	organizationName: '',
	companyId: '',
	riskRangeId: '',
	file: {
		data: {
			id: '',
			original_name: '',
			generated_name: '',
			size: 0,
			url: '',
			status: '',
			file_processed: null,
			workstation: '',
			duration: 0,
			rulaScore: 0,
			tool: '',
			blurFace: null,
			isActive: null,
			createdAt: null,
			updatedAt: null,
			user_id: '',
			organization_id: '',
			company_id: '',
			sector_id: '',
			line_id: '',
			workstation_id: '',
			workstations: {
				id: '',
				line: {
					id: '',
					sector: {
						id: ''
					}
				}
			}
		}
	},
	form: null,
	checkAllParts: false,
	parts: [],
	savedParts: []
};

const defaultContext = {
	...defaultState,
	selectRangeRisk: () => {},
	setCheckAllParts: () => {},
	setParts: () => {}
};

export const AngleTimeContext = React.createContext(defaultContext);
