import { useMutation } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import type { AxiosError } from 'axios';
import { message } from 'antd';

import type { BaseContext, Response } from '@/types';
import Api from '@/services/api';

type AdditionalItemToolScoreResult = {
	result: number;
};

export type GetAdditionalToolScoreDTO = BaseContext & {
	value_1?: number;
	value_2?: number;
	value_3?: number;
	value_4?: number;
	custom_report_step_key_id: string;
	additional_item_option_id_1?: string;
	additional_item_option_id_2?: string;
};

async function calculateAdditionalToolScore(params: GetAdditionalToolScoreDTO): Promise<AdditionalItemToolScoreResult> {
	const url = '/custom-report/result/step-key/calculate-additional-item-risk';
	const { data } = await Api.post<AdditionalItemToolScoreResult>(url, params);
	return data;
}

export const useCalculateAdditionalToolScoreWithPostRoute = () => {
	return useMutation<AdditionalItemToolScoreResult, AxiosError<Response>, GetAdditionalToolScoreDTO>(
		(body: GetAdditionalToolScoreDTO) => calculateAdditionalToolScore(body),
		{
			onError: (err) => {
				message.error(I18n.get(err?.response?.data?.message ?? err?.message));
			}
		}
	);
};
