import React, { ReactNode, useState } from 'react';
import { DeleteOutlined, ExclamationCircleOutlined, PlusCircleFilled } from '@ant-design/icons';
import { Button, Col, Collapse, Form, Modal, Row, Tooltip } from 'antd';
import { I18n } from '@aws-amplify/core';

import { DeleteSeraReport } from '@/components/views/SeraReview/SeraForm/hooks/types/request';
import { useDeleteSeraReport, useGetTasksFiles } from '../../hooks/hooks';
import { useApplicationContext } from '@/context/v1/Application/context';
import { TaskDTO } from '@/components/ui/Inputs/types/response/task';
import { SeraReportDTO } from '../../hooks/types/response';
import { TaskCollapseHeader } from './TaskCollapseHeader';
import { useSeraStepsContext } from '../../context';
import { FormListItem } from '../../context/types';
import { SeraReportForm } from './SeraReportForm';
import { Spinner } from '@/components/Spinner';
import { Text } from '@/components/Typography';
import { FormContainer } from './styles';

const { Panel } = Collapse;
const { useFormInstance } = Form;

interface SeraReportProps {
	seraReports: SeraReportDTO[];
}

export const SeraReport: React.FC<SeraReportProps> = ({ seraReports }) => {
	const form = useFormInstance();
	const { organization, company } = useApplicationContext();
	const { seraFormList, selectedFiles, seraFiles, seraSummary, showSeraResult, handleSeraFormListChange } =
		useSeraStepsContext();

	const { mutateAsync: deleteSeraReport, isLoading: deletingSeraReport } = useDeleteSeraReport();

	let initialFormList = seraFormList;
	let initialActiveKey: number | number[] = 1;

	if (seraReports?.length > 0) {
		initialFormList = seraReports?.map((report, index) => ({
			...report,
			deleted: false,
			key: index + 1,
			active: true
		}));

		initialActiveKey = initialFormList.map(({ key }) => key);
	}

	const initialForm: FormListItem[] = initialFormList ?? [
		{
			deleted: false,
			key: 1,
			active: true
		}
	];

	const [activeKey, setActiveKey] = useState(initialActiveKey);
	const [formList, setFormList] = useState<FormListItem[]>(initialForm);

	let files = selectedFiles.map(({ id }) => id);

	if (showSeraResult) {
		const mappedFiles = seraFiles.files.rows
			.filter((file) =>
				file.task.filter((task) =>
					task.sera_report?.find((report) => report.sera_summary_review_id === seraSummary.id)
				)
			)
			.map(({ id }) => id);
		files = mappedFiles;
	}

	const {
		data: tasksFiles,
		isLoading,
		isFetching,
		isError
	} = useGetTasksFiles({
		organization_id: organization?.id,
		company_id: company.id,
		files
	});

	const tasks: TaskDTO[] = tasksFiles?.map((task) => ({
		id: task.id,
		name: task.name,
		label: task.description
	}));

	function handleDeleteReport(event: React.MouseEvent, key: number) {
		event.stopPropagation();
		const reportId = form.getFieldValue([key, 'sera_report_id']);
		if (reportId) {
			deleteReport(reportId);
		} else {
			const formListCopy = [...formList];
			const index = formListCopy.findIndex((item) => item.key === key);
			if (index < 0) {
				setFormList(formListCopy);
			}
			formListCopy[index].deleted = true;
			setFormList(formListCopy);
		}
	}

	function deleteReport(reportId: string) {
		if (seraReports.length === 1) {
			return;
		}
		Modal.confirm({
			title: I18n.get('Heads up! Want to delete this report?'),
			icon: <ExclamationCircleOutlined />,
			okText: I18n.get('Confirm'),
			cancelText: I18n.get('Cancel'),
			okType: 'danger',
			onOk: async () => {
				const body: DeleteSeraReport = {
					company_id: company?.id,
					organization_id: organization?.id,
					id: reportId
				};
				await deleteSeraReport(body);
			}
		});
	}

	function handleCollapseChange(key: string | string[]) {
		const [, selectedKey] = key;
		const numberKey = Number(selectedKey);
		setActiveKey(numberKey);
		const formListMapped = mapFormList();
		const index = formListMapped.findIndex((item) => item.key === numberKey);
		if (index < 0) {
			setFormList(formListMapped);
			return;
		}
		formListMapped[index].active = true;
		setFormList(formListMapped);
	}

	function handleAddReportClick() {
		const formListMapped = mapFormList();
		const newReport: FormListItem = {
			deleted: false,
			key: formListMapped.length + 1,
			active: true
		};
		formListMapped.push(newReport);
		setActiveKey(formListMapped.length);
		setFormList(formListMapped);
		handleSeraFormListChange(formListMapped);
	}

	function mapFormList() {
		const formListCopy = [...formList];
		const formListMapped = formListCopy.map((item) => ({
			...item,
			active: false
		}));
		return formListMapped;
	}

	function renderHeader(item: FormListItem): ReactNode {
		if (!item.active) {
			return <TaskCollapseHeader tasks={tasks} collapseKey={item.key} />;
		}
		return '';
	}

	if (isError) {
		return <Text>Oops... Something went wrong!</Text>;
	}

	seraReports?.forEach((report, index) => {
		const collapseKey = index + 1;
		const { exposure_score, severity_score, vulnerability_score, rpn } = report;
		form.setFieldValue([collapseKey, 'taskRPN'], {
			rpn,
			exposureScore: exposure_score,
			severityScore: severity_score,
			vulnerabilityScore: vulnerability_score
		});
		form.setFieldValue([collapseKey, 'task_id'], report.task_id);
		form.setFieldValue([collapseKey, 'sera_report_id'], report.id);
		form.setFieldValue([collapseKey, 'exposure_id'], report.exposure_id);
		form.setFieldValue([collapseKey, 'severity_id'], report.severity_id);
		form.setFieldValue([collapseKey, 'risk_damage_id'], report.risk_damage_id);
		form.setFieldValue([collapseKey, 'specifications'], report.specifications);
		form.setFieldValue([collapseKey, 'risk_category_id'], report.risk_category_id);
		form.setFieldValue([collapseKey, 'vulnerability_id'], report.vulnerability_id);
		form.setFieldValue([collapseKey, 'risk_description_id'], report.risk_description_id);
		form.setFieldValue([collapseKey, 'existing_prevention_measures'], report.existing_prevention_measures);
	});

	const loading = isLoading || isFetching || deletingSeraReport;

	const panels = formList
		.filter((item) => !item.deleted)
		.map((item, _index, list) => {
			const disabled =
				list.length === 1 || (seraReports.length === 1 && form.getFieldValue([item.key, 'sera_report_id']));
			return (
				<Panel
					key={`${item.key}`}
					header={renderHeader(item)}
					style={{ minWidth: '100%' }}
					extra={
						<Button
							type="link"
							size="middle"
							disabled={disabled}
							onClick={(event) => handleDeleteReport(event, item.key)}
							icon={<DeleteOutlined style={{ color: disabled ? '#d6d6d6' : '#E74150' }} />}
						/>
					}
				>
					{loading ? <Spinner size={24} /> : <SeraReportForm taskList={tasksFiles} collapseKey={item.key} />}
				</Panel>
			);
		});

	return (
		<Col xs={24}>
			<FormContainer
				bordered={false}
				activeKey={activeKey}
				onChange={handleCollapseChange}
				defaultActiveKey={activeKey}
			>
				{panels}
			</FormContainer>
			<Row justify="center">
				<Col span={2}>
					<Row justify="center">
						<Tooltip title={I18n.get('Add report')}>
							<PlusCircleFilled
								onClick={handleAddReportClick}
								style={{
									fontSize: '32px',
									color: '#2F54EB'
								}}
							/>
						</Tooltip>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};
