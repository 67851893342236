import React, { useContext } from 'react';
import { Select, Row, Col, DatePicker, Form, Descriptions } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Workstations } from './Workstations';
import { AngleTimeContext } from './context';
import { Sectors } from './Sectors';
import { Lines } from './Lines';

export const Information = ({ riskRanges }) => {
	const { organizationId, organizationName, file, selectRangeRisk } = useContext(AngleTimeContext);

	return (
		<Row justify="center" gutter={[5, 20]}>
			<Col sm={18}>
				<Row>
					<Col sm={24}>
						<label>{I18n.get('Enter the data related to the selected video below')}</label>
					</Col>
				</Row>
				<Row align="middle">
					<Col sm={8} md={24} style={{ marginTop: '20px', paddingLeft: '60px' }}>
						<Descriptions column={1} size="small">
							<Descriptions.Item
								label={I18n.get('File')}
								contentStyle={{ fontWeight: 'bold' }}
								labelStyle={{ fontWeight: 'bold' }}
							>
								{file?.data.original_name}
							</Descriptions.Item>
							<Descriptions.Item label={I18n.get('Date')}>
								{moment(file?.data.createdAt).format('L')}
							</Descriptions.Item>
						</Descriptions>
					</Col>
				</Row>
				<Row justify="center" gutter={[10, 20]} style={{ marginTop: '10px', marginLeft: '20px' }}>
					<Col sm={12}>
						<Form.Item name="organization_id" label={I18n.get('Company')} labelCol={{ span: 24 }}>
							<Select disabled={true} placeholder={I18n.get('Company')}>
								<Select.Option value={organizationId}>{organizationName}</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Sectors />
					<Lines />
					<Workstations />
					<Col sm={12}>
						<Form.Item
							label={I18n.get('Collection date')}
							name="collection_date"
							rules={[
								{
									required: true,
									message: I18n.get('Insert the date of collection')
								}
							]}
							labelCol={{ span: 24 }}
						>
							<DatePicker
								format={'L'}
								placeholder={I18n.get('Collection date')}
								style={{ width: '100%' }}
								disabledDate={(current) => current && current.valueOf() >= Date.now()}
							/>
						</Form.Item>
					</Col>
					<Col sm={12}>
						<Form.Item
							name="range_risk_id"
							style={{ margin: 0 }}
							labelCol={{ span: 24 }}
							label={I18n.get('Risk range')}
							rules={[
								{
									required: true,
									message: I18n.get('Select a risk band')
								}
							]}
						>
							<Select
								showSearch
								loading={riskRanges.isLoading}
								disabled={riskRanges.isLoading}
								placeholder={I18n.get('Risk range')}
								onChange={selectRangeRisk}
							>
								{riskRanges.data?.some((e) => e.standard) && (
									<Select.OptGroup label="Default">
										{riskRanges.data?.map(
											(item, key) =>
												item.standard && (
													<Select.Option key={key} value={item.id}>
														{item.name}
													</Select.Option>
												)
										)}
									</Select.OptGroup>
								)}
								{riskRanges.data?.some((e) => !e.standard) && (
									<Select.OptGroup label="Custom">
										{riskRanges.data?.map(
											(item, key) =>
												!item.standard && (
													<Select.Option key={key} value={item.id}>
														{item.name}
													</Select.Option>
												)
										)}
									</Select.OptGroup>
								)}
							</Select>
						</Form.Item>
						<Link
							to="/settings/preferences"
							style={{
								display: 'block',
								float: 'right',
								paddingTop: '10px'
							}}
						>
							{I18n.get('Create risk range')}
						</Link>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
