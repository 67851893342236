import { SeraSummaryReviewDTO } from '../SeraForm/hooks/types/response';

export type SeraReviewRouteParams = {
	sera_summary_review_id: string;
};

export type SeraPrioritiesScale = 'none' | 'low' | 'medium' | 'medium_high' | 'high' | 'very_high';
export type SeraPriorities = '' | 'Low' | 'Medium' | 'High';

export type PriorityScaleMapper = {
	[key in keyof typeof SCALE_MAPPER]: {
		priority: SeraPriorities;
		scale: SeraPrioritiesScale;
	};
};

export type Expanded = {
	id: string;
	expanded: boolean;
};

export type TaskListProps = {
	id: string;
	taskId?: string;
	type: 'ELIMINATE' | 'CONTROL' | 'COMPENSATE';
	description: string;
	isCompleted: boolean;
};

export type RPNPriority = {
	color: string;
	priority: string;
};

export const PRIORITY_SCALE_MAPPER: PriorityScaleMapper = {
	none: {
		priority: '',
		scale: 'none'
	},
	low: {
		priority: 'Low',
		scale: 'low'
	},
	medium: {
		priority: 'Medium',
		scale: 'medium'
	},
	medium_high: {
		priority: 'Medium',
		scale: 'medium_high'
	},
	high: {
		priority: 'High',
		scale: 'high'
	},
	very_high: {
		priority: 'High',
		scale: 'very_high'
	}
};

export const SCALE_MAPPER = {
	none: 'none',
	low: 'low',
	medium: 'medium',
	medium_high: 'medium_high',
	high: 'high',
	very_high: 'very_high'
};

export const SCORE_COLLOR_MAPPER = {
	none: '',
	low: '#2CC852',
	medium: '#F8D627',
	medium_high: '#F78A38',
	high: '#E74150',
	very_high: '#9B54E2'
};

export type State = {
	visible: boolean;
	previousReviewStatus: Expanded[];
	gettingSeraSummaryReview: boolean;
	fetchingSeraSummaryReview: boolean;
	errorGettingSeraSummaryReview: boolean;
	seraSummaryReview: SeraSummaryReviewDTO;
};

export type Methods = {
	handleShowActionPlanModalChange: () => void;
	mapRPNToPriority: (rpn: number | undefined) => RPNPriority;
	handleExpandPreviousReview(id: string, event: React.MouseEvent<HTMLElement, MouseEvent>): void;
};

export type Context = State & Methods;
