import React from 'react';
import { Menu } from 'antd';
import type { MenuProps } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import Icon, {
	BarChartOutlined,
	CloudUploadOutlined,
	FilePdfOutlined,
	FileProtectOutlined,
	FileTextOutlined,
	ProfileOutlined,
	ReconciliationOutlined,
	InfoCircleOutlined,
	ControlOutlined,
	TeamOutlined,
	PartitionOutlined,
	ShareAltOutlined,
	NodeIndexOutlined,
	FileAddOutlined,
	ExceptionOutlined,
	FolderOutlined
} from '@ant-design/icons';

import Can from '@/components/Can';
import { MenuItem, ItemParams } from './types';
import { JdsD86Icon } from '@/assets/icons/ewa-jds-d86-icon';
import { JdsD92Icon } from '@/assets/icons/jds-d92-icon';

export const MenuOptions: React.FC = () => {
	const history = useHistory();
	const location = useLocation();
	const selectKey = location.pathname;

	function getItem({ label, key = null, icon, children, type, disabled = false }: ItemParams): MenuItem {
		return {
			key,
			icon,
			children,
			label,
			type,
			disabled
		} as MenuItem;
	}

	const handleJdsD86Icon = () => <Icon component={JdsD86Icon} />;

	const handleJdsD92Icon = () => <Icon component={JdsD92Icon as React.FC} />;

	const items: MenuItem[] = [
		getItem({ label: I18n.get('Dashboard'), key: '/', icon: <BarChartOutlined /> }),
		getItem({ label: I18n.get('Upload'), key: '/upload', icon: <CloudUploadOutlined /> }),
		getItem({ label: I18n.get('Reports'), key: '/reporting', icon: <FilePdfOutlined /> }),
		getItem({
			key: null,
			label: I18n.get('EWA'),
			icon: <FileProtectOutlined />,
			children: [
				getItem({
					label: I18n.get('New document'),
					key: '/ewa/new-document',
					icon: <FileTextOutlined />
				}),
				getItem({ label: I18n.get('File history'), key: '/ewa/file-history', icon: <ProfileOutlined /> })
			]
		}),

		getItem({
			key: null,
			label: I18n.get('Custom reports'),
			icon: <NodeIndexOutlined />,
			children: [
				getItem({
					label: I18n.get('Files'),
					key: '/custom-reports/files',
					icon: <FolderOutlined />
				}),
				getItem({
					label: I18n.get('B.E.R.A.'),
					key: '/custom-reports/bera',
					icon: <FileAddOutlined />
				}),
				getItem({
					label: I18n.get('S.E.R.A.'),
					key: '/custom-reports/sera',
					icon: <ExceptionOutlined />
				}),
				// Esse é a rota para o JDS-D86 puro
				getItem({
					label: I18n.get('JDS-D86'),
					key: '/custom-reports/jds-d86',
					icon: handleJdsD86Icon()
				}),
				getItem({
					label: I18n.get('JDS-D92'),
					key: '/custom-reports/jds-d92',
					icon: handleJdsD92Icon()
				}),
				getItem({
					label: I18n.get('Manage risks'),
					key: '/custom-reports/manage-risk',
					icon: <PartitionOutlined />,
					disabled: !Can('custom-report', 'management')
				})
			]
		}),

		getItem({ label: I18n.get('Action plans'), key: '/action-plans', icon: <ReconciliationOutlined /> }),
		getItem({
			key: null,
			label: I18n.get('Settings'),
			icon: <InfoCircleOutlined />,
			children: [
				getItem({ label: I18n.get('My account'), key: '/settings/account', icon: <InfoCircleOutlined /> }),
				getItem({
					label: I18n.get('Preferences'),
					key: '/settings/preferences',
					icon: <ControlOutlined />,
					disabled: !Can('settings', 'preferences')
				}),
				getItem({
					label: I18n.get('Manage users'),
					key: '/settings/manage-users',
					icon: <TeamOutlined />,
					disabled: !Can('settings', 'management')
				}),
				getItem({
					label: I18n.get('Manage companies'),
					key: '/settings/companies',
					icon: <PartitionOutlined />,
					disabled: !Can('settings', 'management')
				})
			]
		}),
		getItem({
			label: I18n.get('Integrations'),
			key: '/settings/integrations',
			icon: <ShareAltOutlined />,
			disabled: !Can('settings', 'integrations')
		})
	];

	const onClick: MenuProps['onClick'] = ({ key }): void => {
		history.push(key);
	};

	return (
		<Menu
			theme="light"
			mode="inline"
			items={items}
			onClick={onClick}
			selectedKeys={[selectKey]}
			defaultSelectedKeys={[selectKey]}
		/>
	);
};
