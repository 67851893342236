import { KimMhoDTO, NioshDTO, StrainIndexDTO } from '@/components/ui/Inputs/types/response/file';
import { StressLevelDTO } from '@/components/ui/Inputs/types/response/stressLevel';
import {
	ErgonomicToolMapper,
	ErgonomicToolsDTO,
	ErgonomicToolsName
} from '@/components/ui/Inputs/types/response/ergonomicTool';

interface StepKeyToolsInterface {
	calculateStressLevelScore(stressLevels: StressLevelDTO[], ergonomicTool: ErgonomicToolMapper): string | undefined;
}

export class StepKeyToolsService implements StepKeyToolsInterface {
	public calculateStressLevelScore(
		stressLevels: StressLevelDTO[],
		ergonomicTool: ErgonomicToolMapper
	): string | undefined {
		for (const key in ergonomicTool) {
			const tool = key as ErgonomicToolsName;
			const data = ergonomicTool[tool]?.data;

			if (this.isNiosh(data) || this.isKimMho(data)) {
				const niosh = ergonomicTool.niosh.data as NioshDTO;
				const kimMho = ergonomicTool.kim_mho.data as KimMhoDTO;
				const stressLevel = this.calculateNioshAndKimMho(niosh, kimMho, stressLevels);
				if (stressLevel) {
					return stressLevel.id;
				}
			}
			if (this.isStrainIndex(data)) {
				const rsi = data as StrainIndexDTO;
				const stressLevel = this.calculateStrainIndex(rsi, stressLevels);
				if (stressLevel) {
					return stressLevel.id;
				}
			}
			return undefined;
		}
		return undefined;
	}

	private isKimMho(data: ErgonomicToolsDTO) {
		return data?.hasOwnProperty('risk_score');
	}

	private isStrainIndex(data: ErgonomicToolsDTO) {
		return data?.hasOwnProperty('score_left_rsi') || data?.hasOwnProperty('score_right_rsi');
	}

	private isNiosh(data: ErgonomicToolsDTO) {
		return data?.hasOwnProperty('lifting_index');
	}

	private calculateStrainIndex(strainIndex: StrainIndexDTO, stressLevels: StressLevelDTO[]) {
		const { score_left_rsi, score_right_rsi } = strainIndex;
		const worst = Math.max(score_left_rsi, score_right_rsi);
		if (worst >= 12) {
			return stressLevels?.find(
				(stressLevel) => stressLevel.description.includes('Strain') && stressLevel.score === 5
			);
		}
		if (worst >= 7) {
			return stressLevels?.find(
				(stressLevel) => stressLevel.description.includes('Strain') && stressLevel.score === 4
			);
		}
		if (worst >= 5) {
			return stressLevels?.find(
				(stressLevel) => stressLevel.description.includes('Strain') && stressLevel.score === 3
			);
		}
		if (worst >= 3) {
			return stressLevels?.find(
				(stressLevel) => stressLevel.description.includes('Strain') && stressLevel.score === 2
			);
		}

		return stressLevels?.find(
			(stressLevel) => stressLevel.description.includes('Strain') && stressLevel.score === 1
		);
	}

	private calculateNioshAndKimMho(
		niosh: NioshDTO,
		kimMho: KimMhoDTO,
		stressLevels: StressLevelDTO[]
	): StressLevelDTO | undefined {
		const { lifting_index } = niosh;
		const { risk_score } = kimMho;
		if (lifting_index >= 3 && risk_score >= 60) {
			return stressLevels?.find(
				(stressLevel) => stressLevel.description.toUpperCase().includes('LI') && stressLevel.score === 5
			);
		}
		if (lifting_index >= 2 && risk_score >= 50) {
			return stressLevels?.find(
				(stressLevel) => stressLevel.description.toUpperCase().includes('LI') && stressLevel.score === 4
			);
		}
		if (lifting_index < 2 && risk_score < 50) {
			return stressLevels?.find(
				(stressLevel) => stressLevel.description.toUpperCase().includes('LI') && stressLevel.score === 3
			);
		}
		if (lifting_index < 1.5 && risk_score < 25) {
			return stressLevels?.find(
				(stressLevel) => stressLevel.description.toUpperCase().includes('LI') && stressLevel.score === 2
			);
		}
		if (lifting_index < 1 && risk_score < 20) {
			return stressLevels?.find(
				(stressLevel) => stressLevel.description.toUpperCase().includes('LI') && stressLevel.score === 1
			);
		}

		return undefined;
	}
}
