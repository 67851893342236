import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col } from 'antd';
import moment from 'moment';

import { List } from './List';
import { Filter, FormFields } from './Filter';
import { Title } from '@/components/Typography';
import { useGetCustomReportsFiles } from './hooks';
import { FilterRequest } from './hooks/types/request';
import { useApplicationContext } from '@/context/v1/Application/context';

export const CustomReportsFiles = () => {
	const { search } = useLocation();
	const [limit, setLimit] = useState(10);
	const [offset, setOffset] = useState(0);
	const searchParams = new URLSearchParams(search);
	const queryParams = convertURLParamToObject(searchParams);
	const { organization, company } = useApplicationContext();
	const [filter, setFilter] = useState<FilterRequest>(queryParams);
	const { data: files, isFetching: loading } = useGetCustomReportsFiles({
		company_id: company?.id,
		organization_id: organization?.id,
		filter: {
			...filter,
			limit: String(limit),
			offset: String(offset === 0 ? offset : offset - 1)
		}
	});

	const initialValues: FormFields = mapFilterInitialValues(queryParams) ?? {};

	function handleOffsetChange(page: number): void {
		setOffset(page);
	}

	function handlePageChange(reports: number): void {
		setLimit(reports);
	}

	function mapFilterInitialValues(queryParams: FilterRequest) {
		if (Object.keys(queryParams)?.length > 0) {
			const { created_at_start, created_at_end, status } = queryParams;

			const uploaded_at =
				created_at_start && created_at_end ? [moment(created_at_start), moment(created_at_end)] : undefined;

			const initialValues: FormFields = {
				...queryParams,
				uploaded_at,
				status: status && JSON.parse(status)
			};
			return initialValues;
		}
	}

	function convertURLParamToObject(searchParams: URLSearchParams) {
		return Object.fromEntries(searchParams.entries());
	}

	function handleFilterClick(filterData: FilterRequest) {
		setFilter(filterData);
	}

	return (
		<Row gutter={[0, 10]} justify="center">
			<Col span={24}>
				<Title level={4}>Custom reports files</Title>
			</Col>
			<Col span={24}>
				<Filter onFilterClick={handleFilterClick} initialValues={initialValues} />
			</Col>
			<Col span={24}>
				<List
					files={files}
					limit={limit}
					offset={offset}
					loading={loading}
					onPageChange={handlePageChange}
					onOffsetChange={handleOffsetChange}
				/>
			</Col>
		</Row>
	);
};
