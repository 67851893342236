import { I18n } from '@aws-amplify/core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { message, notification } from 'antd';

import { BeraJobSummaryListRequest, DeleteBeraJobSummaryRequest, DownloadFileRequest } from './types/request';
import { BeraJobSummaryListDTO } from './types/response';
import { BeraService } from './services';

const beraService = new BeraService();

export function useBeraJobSummaryList({ organization_id, company_id, filter }: BeraJobSummaryListRequest) {
	const enabled = !!organization_id && !!company_id;
	const summaryList = useQuery(
		['bera_job_summary_list', [organization_id, company_id, filter]],
		() => beraService.getBeraJobSummaryList({ organization_id, company_id, filter }),
		{
			enabled
		}
	);
	return {
		...summaryList,
		data: summaryList.data ?? ({} as BeraJobSummaryListDTO)
	};
}

export const useDeleteBeraJobSummary = () => {
	const queryClient = useQueryClient();
	return useMutation(({ body }: { body: DeleteBeraJobSummaryRequest }) => beraService.deleteBeraJobSummary(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0].split('" ');
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['bera_job_summary_list']);
			queryClient.invalidateQueries(['bera_files'], { refetchType: 'inactive' });
			message.success(I18n.get('B.E.R.A. deleted successfully'));
		}
	});
};

export const useDeleteBeraReport = () => {
	const queryClient = useQueryClient();
	return useMutation((body: DeleteBeraJobSummaryRequest) => beraService.deleteBeraReport(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0].split('" ');
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['bera_job_summary_list']);
			queryClient.invalidateQueries(['bera_files'], { refetchType: 'inactive' });
			queryClient.invalidateQueries(['custom_report'], { refetchType: 'inactive' });
			queryClient.invalidateQueries(['bera_job_results'], { refetchType: 'inactive' });
			message.success(I18n.get('B.E.R.A. report deleted successfully'));
		}
	});
};

export const useDownloadFile = () => {
	return useMutation((body: DownloadFileRequest) => beraService.downloadFile(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0].split('" ');
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: (data) => {
			const archive = document.createElement('a');
			archive.target = '_blank';
			archive.href = data;
			archive.click();
			archive.remove();
		}
	});
};
