import React from 'react';
import { Col, Divider, Row } from 'antd';
import { Title } from '@/components/Typography';


import { StepKeyResult } from '../types';
import { StepKey } from './StepKey';

interface StepsProps {
	stepName: string;
	stepKeys: StepKeyResult[];
}

export const Steps: React.FC<StepsProps> = ({ stepName, stepKeys }) => {
	return (
		<Row gutter={[0, 8]}>
			<Col offset={1} span={23}>
				<Row>
					<Title style={{ margin: 0 }} level={5}>
						{stepName}
					</Title>
				</Row>
			</Col>
			<Col offset={1} span={23}>
				<Row gutter={[0, 8]} style={{ marginLeft: '0.5rem' }}>
					<Col span={1}>
						<Divider
							type="vertical"
							style={{
								margin: '0 0 0 0.7rem',
								borderLeft: '4px solid #e6e8e8',
								height: '100%'
							}}
						/>
					</Col>
					{stepKeys?.map((stepKey, index) => (
						<StepKey
							key={stepKey.id}
							id={stepKey.id}
							hasDivider={index !== stepKeys.length - 1}
							task_rpn={stepKey.result.task_rpn}
							description={stepKey.description}
							frequency={stepKey.result.frequency}
							jobElement={stepKey.result.job_element}
							stressLevel={stepKey.result.stress_level}
							totalTaskDuration={stepKey.result.total_task_duration}
						/>
					))}
				</Row>
			</Col>
		</Row>
	);
};
