import React, { createContext, ReactNode, useContext, useState } from 'react';

import { AuthUserDTO, TFATypes } from '@/types/AuthUser';

import {
	useCheck2FA,
	useGenerate2FA,
	useResendToken,
	useReset2FA,
	useSendToken,
	useValidate2FA
} from './TwoFactorAuthenticator/hooks';
import { Context } from './index.types';

interface TFAProviderProps {
	children: ReactNode;
}

export const Check2FAContext = createContext<Context>({} as Context);

export const Check2FAProvider = ({ children }: TFAProviderProps) => {
	const [disabled, setDisabled] = useState(true);
	const [QRCodeUrl, setQRCodeUrl] = useState<string>('');
	const [QRCodeEnabled, setQRCodeEnabled] = useState(false);
	const [type, setType] = useState<TFATypes>('AUTHENTICATOR');
	const [openCheck2FAModal, setOpenCheck2FAModal] = useState(false);
	const [openReset2FAModal, setOpenReset2FAModal] = useState(false);
	const [recoveryTokens, setRecoveryTokens] = useState<string[]>([]);
	const [openForce2FAWarning, setOpenForce2FAWarning] = useState(false);
	const [openValidate2FAModal, setOpenValidate2FAModal] = useState(false);
	const [openRecoveryTokenModal, setOpenRecoveryTokenModal] = useState(false);
	const [userSession, setUserSession] = useState<AuthUserDTO>({} as AuthUserDTO);

	const { mutateAsync: generate2FA } = useGenerate2FA();
	const { mutateAsync: check2FA, isLoading: check2FALoading } = useCheck2FA();
	const { mutateAsync: reset2FA, isLoading: reset2FALoading } = useReset2FA();
	const { mutateAsync: sendToken, isLoading: sendTokenLoading } = useSendToken();
	const { mutateAsync: resendToken, isLoading: resendTokenLoading } = useResendToken();
	const { mutateAsync: validate2FA, isLoading: validate2FALoading } = useValidate2FA();

	async function handleResendToken(email = '') {
		const userEmailString = localStorage.getItem('info_user') || '';
		const user = JSON.parse(userEmailString)?.user;
		const userEmail = user.email;

		const resent = await resendToken({
			data: { email: email || userEmail }
		});

		return resent;
	}

	async function handleGenerate2FA() {
		const generated = await generate2FA('AUTHENTICATOR');

		if (generated.qr_code_url) {
			setQRCodeUrl(generated.qr_code_url);
			setType('AUTHENTICATOR');
		}

		return generated;
	}

	async function handleSendToken(email: string) {
		const sent = await sendToken({
			data: { email }
		});

		return sent;
	}

	async function handleReset2FA(type: TFATypes, token: string) {
		const reset = await reset2FA({
			data: { type, token }
		});

		return reset;
	}

	async function handleCheck2FA(type: TFATypes, token: string) {
		const checked = await check2FA({
			data: { type, token }
		});

		return checked;
	}

	async function handleValidate2FA(type: TFATypes, token: string) {
		const validatedToken = await validate2FA({
			data: { type, token }
		});

		setRecoveryTokens(validatedToken.tokens_array);

		return validatedToken;
	}

	function handleTypeChange(type: TFATypes) {
		setType(type);
	}

	function handleEnabledQRCodeChange(enabled: boolean) {
		setQRCodeEnabled(enabled);
	}

	function handleUserSessionChange(session: AuthUserDTO) {
		setUserSession(session);
	}

	function handleOpenCheck2FAModal(isOpen: boolean) {
		setOpenCheck2FAModal(isOpen);
	}

	function handleOpenReset2FAModal(isOpen: boolean) {
		setOpenReset2FAModal(isOpen);
	}

	function handleOpenValidate2FAModal(isOpen: boolean) {
		setOpenValidate2FAModal(isOpen);
	}

	function handleOpenRecoveryTokensModal(isOpen: boolean) {
		setOpenRecoveryTokenModal(isOpen);
	}

	function handleOpenForce2FAWarning(isOpen: boolean) {
		setOpenForce2FAWarning(isOpen);
	}

	function handleQRCodeUrl(qr_code_url: string) {
		setQRCodeUrl(qr_code_url);
	}

	function handleEnableButton() {
		setDisabled(false);
	}

	const context: Context = {
		type,
		disabled,
		QRCodeUrl,
		userSession,
		QRCodeEnabled,
		recoveryTokens,
		check2FALoading,
		reset2FALoading,
		sendTokenLoading,
		openCheck2FAModal,
		openReset2FAModal,
		resendTokenLoading,
		validate2FALoading,
		openForce2FAWarning,
		openValidate2FAModal,
		openRecoveryTokenModal,
		handleReset2FA,
		handleCheck2FA,
		handleQRCodeUrl,
		handleSendToken,
		handleTypeChange,
		handleResendToken,
		handleGenerate2FA,
		handleValidate2FA,
		handleEnableButton,
		handleOpenCheck2FAModal,
		handleOpenReset2FAModal,
		handleUserSessionChange,
		handleOpenForce2FAWarning,
		handleEnabledQRCodeChange,
		handleOpenValidate2FAModal,
		handleOpenRecoveryTokensModal
	};

	return <Check2FAContext.Provider value={context}>{children}</Check2FAContext.Provider>;
};

export function useCheck2FAContext() {
	const context = useContext(Check2FAContext);
	return context;
}
