import React from 'react';
import { Col, Button } from 'antd';
import styled from 'styled-components';
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';
import { FileAddOutlined } from '@ant-design/icons';

export const Title = styled.div`
	display: inline-block;
	margin-right: 5px;
`;

export function Header({ onCreate }) {
	const { user } = useSelector((state) => state.auth.user);

	return (
		<Col sm={24} style={{ paddingBottom: '15px' }}>
			<Title>
				<h2>Gerenciar usuários</h2>
			</Title>
			<Button
				size="large"
				type="primary"
				onClick={onCreate}
				icon={<FileAddOutlined />}
				disabled={user.canceled_on && true}
				style={{ display: 'inline', float: 'right' }}
			>
				{I18n.get('Create')}
			</Button>
		</Col>
	);
}
