import React, { useContext, useEffect } from 'react';
import { I18n } from '@aws-amplify/core';
import { Form, Select } from 'antd';

import { MergeAEPContext } from '../../context';
import Hooks from '../../hooks';

const { useGetOrganization } = Hooks;
const { useFormInstance } = Form;

export const Organization: React.FC = () => {
	const { organizationId } = useContext(MergeAEPContext);

	const { data: organizationsList, isRefetching } = useGetOrganization({
		name: '',
		id: organizationId
	});

	const { setFieldValue } = useFormInstance();

	useEffect(() => {
		organizationId &&
			setFieldValue('organizationId', organizationsList.find(({ id }) => id === organizationId)?.name);
	}, [isRefetching]);

	return (
		<Form.Item name="organizationId">
			<Select showSearch filterOption={false} disabled showArrow={false} placeholder={I18n.get('Company')} />
		</Form.Item>
	);
};
