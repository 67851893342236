import React from 'react';
import moment from 'moment';
import { Table, Tooltip } from 'antd';
import { I18n } from '@aws-amplify/core';
import { ColumnsType } from 'antd/lib/table';

import { Actions } from './Actions';
import { formatFileSize } from '@/utils/mask';
import { enumStatusFile } from '@/utils/enum';
import { Text } from '@/components/Typography';
import { FileStatus } from '@/components/ui/Inputs';
import { CustomReportsFiles, CustomReportsFilesPaginated } from './hooks/types/response';

interface ListProps {
	limit: number;
	offset: number;
	loading: boolean;
	files: CustomReportsFilesPaginated;
	onPageChange: (page: number) => void;
	onOffsetChange: (offset: number) => void;
}

export const List: React.FC<ListProps> = ({ files, loading, limit, offset, onOffsetChange, onPageChange }) => {
	const columns: ColumnsType<CustomReportsFiles> = [
		{
			title: I18n.get('Description'),
			dataIndex: 'original_name',
			key: 'original_name',
			width: '20%',
			render: (original_name: string) => (
				<Tooltip title={original_name}>
					<Text style={{ width: '150px' }} ellipsis={false}>
						{original_name}
					</Text>
				</Tooltip>
			)
		},
		{
			render: (size) => (size ? formatFileSize(size) : '-'),
			title: I18n.get('Size'),
			dataIndex: 'size',
			align: 'center',
			key: 'size'
		},
		{
			title: I18n.get('Custom report'),
			dataIndex: 'custom_report_name',
			key: 'custom_report_name',
			align: 'center'
		},
		{
			render: (text) => moment(text).format('L - h:mm:ss A'),
			title: I18n.get('Upload date'),
			dataIndex: 'createdAt',
			key: 'createdAt',
			align: 'center'
		},
		{
			render: (text: FileStatus) => `${I18n.get(enumStatusFile[text] || '-')}`,
			title: I18n.get('Status'),
			dataIndex: 'status',
			align: 'center',
			key: 'status'
		},
		{
			render: (workstations) => workstations?.name || '-',
			title: I18n.get('Workstation'),
			dataIndex: 'workstations',
			key: 'workstations',
			align: 'center'
		},
		{
			render: (_, file: CustomReportsFiles) => <Actions file={file} />,
			title: I18n.get('Actions'),
			align: 'center',
			key: 'url'
		}
	];

	return (
		<Table
			rowKey="id"
			columns={columns}
			loading={loading}
			dataSource={files.rows}
			showSorterTooltip={false}
			pagination={{
				pageSize: limit,
				defaultPageSize: 5,
				total: files?.count,
				showSizeChanger: true,
				hideOnSinglePage: false,
				onChange: (page) => {
					onOffsetChange(page);
				},
				current: offset === 0 ? offset + 1 : offset,
				onShowSizeChange: (_, size) => onPageChange(size),
				pageSizeOptions: ['5', '10', '20', '30', '50', '100', '200']
			}}
		/>
	);
};
