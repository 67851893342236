import React, { useEffect } from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Row, Col, Checkbox } from 'antd';

import { useSeraReviewContext } from '../../../context';
import { Container, Body, Header } from './styles';

interface CollapseCheckProps {
	value: boolean;
	header: JSX.Element;
	children?: JSX.Element;
	onChange: (isChecked: boolean) => void;
}

export const CollapseCheck: React.FC<CollapseCheckProps> = ({ header, children, value = false, onChange }) => {
	const { visible } = useSeraReviewContext();

	useEffect(() => {
		onChange(value);
	}, [value]);

	function handleOpen() {
		if (visible) {
			return;
		}
		onChange(!value);
	}

	function handleChange(event: CheckboxChangeEvent) {
		const isChecked = event.target.checked;
		if (typeof onChange === 'function') {
			onChange(isChecked);
		}
	}

	return (
		<Container hasBody={!!children} selected={value}>
			<Col onClick={handleOpen} span={24} style={{ cursor: 'pointer' }}>
				<Row justify="start" align="middle" gutter={[24, 0]}>
					<Col xs={1}>
						<Checkbox checked={value} onChange={handleChange} />
					</Col>
					<Header span={23} onClick={handleOpen} selected={value}>
						{header}
					</Header>
				</Row>
			</Col>
			{children && (
				<Col span={24}>
					<Body open={value}>{children}</Body>
				</Col>
			)}
		</Container>
	);
};
