import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { useApplicationContext } from '@/context/v1/Application/context';
import { LineSelect } from '@/components/ui/Inputs/LineSelect';
import { useLines } from '@/hooks/v1';

const { useFormInstance, useWatch } = Form;

export const Lines = () => {
	const form = useFormInstance();
	const { company, organization } = useApplicationContext();

	const lineId = useWatch('line_id', form);
	const sectorId = useWatch('sector_id', form);

	const {
		data: lines,
		isLoading,
		isFetching,
		isError
	} = useLines({ organization_id: organization?.id, company_id: company?.id, sector_id: sectorId });

	function handleLineSelect(id: string) {
		form.setFieldValue(['line_id'], id);
		form.setFieldValue(['workstation_id'], undefined);
	}

	function handleClearLines() {
		form.setFieldValue(['line_id'], undefined);
		form.setFieldValue(['workstation_id'], undefined);
	}

	return (
		<Col xs={24} sm={12}>
			<Form.Item
				name={['line_id']}
				label={I18n.get('Line')}
				rules={[
					{
						required: true,
						message: I18n.get('Select the line')
					}
				]}
			>
				<LineSelect
					lines={lines}
					lineId={lineId}
					fieldName="line_id"
					sectorId={sectorId}
					loading={isFetching}
					handleSelectLine={handleLineSelect}
					handleClearLines={handleClearLines}
					disabled={isLoading || isFetching || isError}
				/>
			</Form.Item>
		</Col>
	);
};
