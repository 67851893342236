import React, { useCallback, useState } from 'react';
import { Button, Col, DatePicker, Form, Input, InputRef, Row, Select } from 'antd';
import Spinner from '@/components/Layout/Spinner';
import { EditOutlined } from '@ant-design/icons';
import { Title } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import {
	ActionPlanTaskRequest,
	CreateSeraActionPlanRequest,
	UpdateSeraActionPlanRequest
} from '../../../hooks/types/request';
import { useCreateSeraActionPlan, useFindTasks, useGetUsers, useUpdateSeraActionPlan } from '../../../hooks/hooks';
import { useApplicationContext } from '@/context/v1/Application/context';
import { SeraActionPlanDTO } from '../../../hooks/types/response';
import { TaskListProps } from '../../../context/types';
import { useSeraStepsContext } from '../../../context';
import { EditButton } from './styles';
import { Tasks } from './Tasks';

interface CreateOrUpdateProps {
	file_id: string;
	onCreateOrUpdate: () => void;
	actionPlan?: SeraActionPlanDTO;
}

type ActionPlanField = {
	responsible_user: string;
	title: string;
	deadline: string;
};

const { useFormInstance, useWatch } = Form;

export const CreateOrUpdate: React.FC<CreateOrUpdateProps> = ({
	file_id,
	actionPlan,
	onCreateOrUpdate
}): JSX.Element => {
	const { company, organization } = useApplicationContext();
	const { seraSummary } = useSeraStepsContext();
	const form = useFormInstance();

	const { data: fetchedTasks, isFetching: gettingActionPlanTasks } = useFindTasks(
		actionPlan?.id,
		organization?.id,
		company.id
	);
	const { data: responsible_users, isFetching: fetchingUsers } = useGetUsers(organization.id, company.id);
	const { mutateAsync: createActionPlan, isLoading: creatingActionPlan } = useCreateSeraActionPlan();
	const { mutateAsync: updateActionPlan, isLoading: updatingActionPlan } = useUpdateSeraActionPlan();

	const { sera_summary_review_id } = seraSummary;

	const responsibleUser = useWatch(['action_plan', 'responsible_user'], form);
	const deadline = useWatch(['action_plan', 'deadline'], form);
	const description = useWatch(['action_plan', 'description'], form);

	const [actionPlanTitle, setActionPlanTitle] = useState<string>(actionPlan?.title ?? '');
	const [tasksBody, setTasksBody] = useState<ActionPlanTaskRequest[]>([]);
	const [editingTitle, setEditingTitle] = useState(actionPlan?.title ? false : true);
	const [titleInput, setTitleInput] = useState(actionPlan?.title ?? '');

	function handleTasksChange(tasks: TaskListProps[]) {
		const body: ActionPlanTaskRequest[] = tasks.map((task) => {
			const mappedTask: ActionPlanTaskRequest = {
				description: task.description,
				is_completed: task.isCompleted,
				type: task.type
			};

			if (task.taskId) {
				mappedTask.task_id = task.taskId;
			}

			return mappedTask;
		});

		setTasksBody(body);
		form.resetFields(['task_title', 'condition']);
	}

	function onEditTitle() {
		setTitleInput(actionPlanTitle);
		setEditingTitle(true);
	}

	async function onFinishEditing() {
		if (!titleInput) return;

		setActionPlanTitle(titleInput);
		setEditingTitle(false);
		setTitleInput('');
	}

	function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
		setTitleInput(event.target.value);
	}

	function handleCancelClick() {
		onCreateOrUpdate();
	}

	const editInputRef = useCallback((node: InputRef) => {
		if (node) {
			node.focus();
		}
	}, []);

	async function createSeraActionPlan() {
		try {
			const values = await form.validateFields(['action_plan']);
			if (await anyRequiredFieldEmpty(values.action_plan)) {
				return;
			}
			if (!actionPlan?.id) {
				const body: CreateSeraActionPlanRequest = {
					company_id: company.id,
					organization_id: organization.id,
					sera_summary_review_id,
					file_id,
					action_plan: {
						title: actionPlanTitle,
						board: 'TO DO',
						responsible_user_id: responsibleUser,
						deadline: moment(deadline).toDate(),
						description
					}
				};
				if (tasksBody.length > 0) {
					body.tasks = tasksBody;
				}
				const created = await createActionPlan(body);
				if (created) {
					onCreateOrUpdate();
				}
			} else {
				const body: UpdateSeraActionPlanRequest = {
					company_id: company.id,
					organization_id: organization.id,
					action_plan_id: actionPlan.id,
					action_plan: {
						title: actionPlanTitle,
						deadline: moment(deadline).toDate() || actionPlan.deadline,
						description: description || actionPlan.description,
						responsible_user_id: responsibleUser || actionPlan.responsible_user_id
					}
				};
				if (tasksBody.length > 0) {
					body.tasks = tasksBody.map((task) => {
						return {
							...task,
							id: task.task_id
						};
					});
				}
				const updated = await updateActionPlan(body);
				if (updated) {
					onCreateOrUpdate();
				}
			}
		} catch (error) {}
	}

	async function anyRequiredFieldEmpty(action_plan: ActionPlanField) {
		const defaultActionPlanField: ActionPlanField = {
			deadline: '',
			responsible_user: '',
			title: ''
		};
		for (const key in defaultActionPlanField) {
			const typedKey = key as keyof ActionPlanField;
			const element = action_plan[typedKey];
			if (!element) {
				await form.validateFields(['action_plan', typedKey]);
				return true;
			}
			return false;
		}
	}

	return (
		<Row justify="center">
			<Col span={24}>
				<Title level={5}>Action plan</Title>
			</Col>
			<Col span={22}>
				<Row justify="center" gutter={[10, 0]} style={{ marginTop: '1rem' }}>
					<Col span={24}>
						<Title style={{ marginBottom: '1rem' }} level={5}>
							{editingTitle ? (
								<Form.Item
									initialValue={actionPlan?.title}
									rules={[{ required: true, message: I18n.get('Please enter a title') }]}
									name={['action_plan', 'title']}
								>
									<Input
										ref={editInputRef}
										style={{ fontSize: '1.4rem' }}
										value={I18n.get(titleInput)}
										onChange={(e) => handleChange(e)}
										onPressEnter={onFinishEditing}
										onBlur={onFinishEditing}
									/>
								</Form.Item>
							) : (
								<Row align="top">
									<Col span={'auto'}>
										<Title style={{ margin: '0' }} level={5}>
											{I18n.get(actionPlanTitle)}
										</Title>
									</Col>
									<Col span={2}>
										<EditButton onClick={onEditTitle}>
											<EditOutlined />
										</EditButton>
									</Col>
								</Row>
							)}
						</Title>
					</Col>
					<Col span={12}>
						<Form.Item
							initialValue={actionPlan?.responsible_user_id}
							rules={[{ required: true, message: I18n.get('Please enter a responsible') }]}
							name={['action_plan', 'responsible_user']}
							style={{ paddingTop: '10px' }}
							label={I18n.get('Person responsible')}
							labelAlign="left"
							labelCol={{ xs: 24 }}
						>
							<Select
								allowClear
								style={{
									width: '100%'
								}}
								options={responsible_users?.map((user) => ({
									value: user.id,
									label: user.name
								}))}
								loading={fetchingUsers}
								disabled={fetchingUsers}
								placeholder={I18n.get('Person responsible')}
								value={
									responsible_users.find((user) => user.id === actionPlan?.responsible_user_id)?.id
								}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							initialValue={moment(actionPlan?.deadline)}
							rules={[{ required: true, message: I18n.get('Please enter a valid date') }]}
							name={['action_plan', 'deadline']}
							style={{ paddingTop: '10px' }}
							label={I18n.get('Deadline')}
							labelAlign="left"
							labelCol={{ xs: 24 }}
						>
							<DatePicker
								onChange={() => {}}
								style={{ width: '100%' }}
								placeholder={I18n.get('Deadline')}
								format={'L'}
							/>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							initialValue={actionPlan?.description}
							name={['action_plan', 'description']}
							style={{ margin: '0' }}
							label={I18n.get(
								'Describe the situation found and the preventive measures already implemented (if exist)'
							)}
						>
							<Input.TextArea
								rows={2}
								maxLength={500}
								placeholder={I18n.get('Enter a description for your action plan.')}
								autoSize={{
									minRows: 3,
									maxRows: 5
								}}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Col>
			<Col span={22}>
				<Row justify="center" gutter={[10, 0]} style={{ marginTop: '1rem' }}>
					<Col span={24}>
						<Title level={5}>{I18n.get('Tasks')}</Title>
					</Col>
					{gettingActionPlanTasks ? (
						<Spinner />
					) : (
						<Tasks fetchedTasks={fetchedTasks} onTasksChange={handleTasksChange} />
					)}
				</Row>
			</Col>
			<Col xs={24}>
				<Row style={{ marginTop: '1rem' }} gutter={[16, 16]} justify="end">
					<Col>
						<Button key="cancel" onClick={handleCancelClick}>
							{I18n.get('Cancel')}
						</Button>
					</Col>
					<Col>
						<Button
							key="add"
							type="primary"
							htmlType="submit"
							onClick={createSeraActionPlan}
							loading={creatingActionPlan || updatingActionPlan}
						>
							{I18n.get('Save')}
						</Button>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
