import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Col, Collapse, Form, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import moment, { Moment } from 'moment';

import { FilterRequest } from '@/components/views/BeraReports/hooks/types/request';
import { FileDTO } from '../hooks/types/response';
import { Organization } from './Organizations';
import { Workstations } from './Workstations';
import { ReportName } from './ReportName';
import { Companies } from './Companies';
import { CreatedAt } from './CreatedAt';
import { Sectors } from './Sectors';
import { Lines } from './Lines';
import * as S from './styles';

const { useForm } = Form;
const { Panel } = Collapse;

export type FormFields = {
	searching_organization_id?: string;
	company_id_filter?: string;
	company_id?: string;
	created_at?: Moment[];
	line_id?: string;
	report_name?: string;
	sector_id?: string;
	task_id?: string;
	workstation_id?: string;
	files?: FileDTO[];
	dashboard?: boolean;
};

interface FilterProps {
	loading: boolean;
	initialValues: FormFields;
	onFilterClick(filterData: FilterRequest): void;
}

export const Filter: React.FC<FilterProps> = ({ onFilterClick, loading, initialValues }) => {
	const [form] = useForm();
	const history = useHistory();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const [disabled, setDisabled] = useState(true);

	useEffect(() => {
		if (initialValues.dashboard) {
			form.setFieldValue('searching_organization_id', initialValues?.searching_organization_id);
			form.setFieldValue('company_id_filter', initialValues?.company_id);
			form.setFieldValue('sector_id', initialValues?.files?.[0]?.sector?.id);
			form.setFieldValue('line_id', initialValues?.files?.[0]?.workstations?.line?.id);
			form.setFieldValue('workstation_id', initialValues?.files?.[0]?.workstations?.id);
		}
	}, [form, initialValues]);

	function handleFieldsChange(_changedFields: any, allFields: any[]) {
		const allFieldsEmpty = allFields?.filter((field) => !!field.value);
		if (allFieldsEmpty.length === 0) {
			setDisabled(true);
			return;
		}
		setDisabled(false);
	}

	function handleClear() {
		history.push({
			pathname: '/reporting',
			search: '?cleared=true'
		});
		form.resetFields();
	}

	function handleFinish(values: FormFields) {
		const {
			searching_organization_id,
			company_id_filter,
			created_at,
			line_id,
			report_name,
			sector_id,
			task_id,
			workstation_id
		} = values;

		const params: FilterRequest = {
			searching_organization_id,
			company_id_filter,
			line_id,
			report_name,
			sector_id,
			task_id,
			workstation_id,
			created_at_start: undefined,
			created_at_end: undefined
		};

		if (created_at) {
			const [start_date, end_date] = created_at;
			const start = moment(start_date).format();
			const end = moment(end_date).format();
			params.created_at_start = start;
			params.created_at_end = end;
		}

		updateURLParams(params);

		onFilterClick(params);
	}

	function updateURLParams(params: FilterRequest) {
		for (const key in params) {
			searchParams.delete('cleared');
			searchParams.delete(key);

			if (Object.hasOwn(params, key)) {
				const typedKey: keyof FilterRequest = key as any;
				const param = params[typedKey];
				if (param) {
					searchParams.set(key, param);
				}
			}
		}

		history.push({
			pathname: '/reporting',
			search: searchParams.toString()
		});
	}

	return (
		<S.Container>
			<Collapse defaultActiveKey={search ? '1' : '0'}>
				<Panel key={'1'} header={I18n.get('Filter')}>
					<Form
						form={form}
						layout="vertical"
						onFinish={handleFinish}
						initialValues={initialValues}
						onFieldsChange={handleFieldsChange}
					>
						<Row gutter={[8, 0]}>
							<Col xs={24} lg={6} xl={5} xxl={4}>
								<Organization />
							</Col>
							<Col xs={24} lg={6} xl={5} xxl={4}>
								<Companies />
							</Col>
							<Col xs={24} lg={6} xl={5} xxl={4}>
								<Sectors />
							</Col>
							<Col xs={24} lg={6} xl={5} xxl={4}>
								<Lines />
							</Col>
						</Row>
						<Row gutter={[8, 0]}>
							<Col xs={24} lg={12} xl={10} xxl={8}>
								<ReportName />
							</Col>
							<Col xs={24} lg={6} xl={5} xxl={4}>
								<CreatedAt />
							</Col>
							<Col xs={24} lg={6} xl={5} xxl={4}>
								<Workstations />
							</Col>
						</Row>
						<Row gutter={[8, 10]} style={{ paddingTop: '20px' }}>
							<Col xs={24} lg={6} xl={4} xxl={3}>
								<Button
									type="primary"
									htmlType="submit"
									disabled={disabled}
									icon={<SearchOutlined />}
									style={{ width: '100%' }}
									loading={loading}
								>
									{I18n.get('Search')}
								</Button>
							</Col>
							<Col xs={24} lg={6} xl={4} xxl={3}>
								<Button style={{ width: '100%' }} onClick={handleClear}>
									{I18n.get('Clear')}
								</Button>
							</Col>
						</Row>
					</Form>
				</Panel>
			</Collapse>
		</S.Container>
	);
};
