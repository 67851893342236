import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Text } from '@/components/Typography';
import { TableTitle } from './styles';

interface ParticularitiesType {
	particularitiesText?: string;
	workerVerbalizationText?: string;
}

export const Particularities: React.FC<ParticularitiesType> = ({ particularitiesText, workerVerbalizationText }) => {
	return (
		<Row style={{ height: '100%' }}>
			<Col xl={10} xxl={8} style={{ padding: '1rem', background: '#e6e8e8' }}>
				<TableTitle>
					{I18n.get('Particularities')} / {I18n.get('Production')} / {I18n.get('Working regime')}
				</TableTitle>
			</Col>
			<Col xl={14} xxl={16} style={{ padding: '1rem', position: 'relative', borderBottom: '1px solid #e6e8e8' }}>
				<Text>{particularitiesText}</Text>
			</Col>
			<Col xl={10} xxl={8} style={{ padding: '1rem', background: '#e6e8e8' }}>
				<TableTitle>{I18n.get("Worker's verbalization")}</TableTitle>
			</Col>
			<Col xl={14} xxl={16} style={{ padding: '1rem' }}>
				<Text>{workerVerbalizationText}</Text>
			</Col>
		</Row>
	);
};
