import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { useApplicationContext } from '@/context/v1/Application/context';
import { UserSelect } from '@/components/ui/Inputs/Users';
import { useUsers } from '@/hooks/v1/useUsers';

const { useFormInstance, useWatch } = Form;

export const PersonResponsible: React.FC = () => {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();
	const { company, organization } = useApplicationContext();

	const responsibleUserId = useWatch('responsible_user_id', form);

	const { users: usersQuerie } = useUsers({
		organizationId: organization?.id,
		companyId: company?.id
	});

	const { data: users, isLoading, isFetching, isError } = usersQuerie;

	function handleUserSelect(id: string) {
		setFieldValue(['responsible_user_id'], id);
	}

	function handleClearUser() {
		setFieldValue(['responsible_user_id'], undefined);
	}

	return (
		<Col xs={24}>
			<Form.Item name={['responsible_user_id']} label={I18n.get('Person responsible')} labelCol={{ span: 24 }}>
				<UserSelect
					loading={isFetching}
					userId={responsibleUserId}
					fieldName={'responsible_user_id'}
					users={users}
					handleSelectUser={handleUserSelect}
					handleClearUser={handleClearUser}
					disabled={isLoading || isFetching || isError}
				/>
			</Form.Item>
		</Col>
	);
};
