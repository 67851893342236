import React from 'react';
import { NamePath } from 'antd/lib/form/interface';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { RiskDamageSelect } from '@/components/ui/Inputs/RiskDamageSelect';
import { RiskDamageDTO } from '@/components/ui/Inputs/types/response/riskDamage';
import { CustomFormItem } from './styles';

const { useFormInstance, useWatch } = Form;

interface RiskDamagesProps {
	stepKeyId: string;
	isLoading: boolean;
	isFetching: boolean;
	collapseKey: number;
	fieldName: NamePath;
	riskDamages: RiskDamageDTO[];
}

export const RiskDamages: React.FC<RiskDamagesProps> = ({
	isLoading,
	fieldName,
	stepKeyId,
	isFetching,
	collapseKey,
	riskDamages
}) => {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();

	const riskDamageId = useWatch(fieldName, form);

	async function handleRiskDamageSelect(id: string) {
		setFieldValue(fieldName, id);
		const selectedRiskDamage = riskDamages.find((riskDamage) => riskDamage.id === id);
		setFieldValue([collapseKey, 'severity_id'], selectedRiskDamage?.severity_id);
	}

	async function handleClearRiskDamage() {
		setFieldValue(fieldName, undefined);
		setFieldValue([collapseKey, 'severity_id'], undefined);
	}

	return (
		<Col xs={24} sm={12} lg={24}>
			<CustomFormItem
				name={fieldName}
				label={I18n.get('Damage')}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: true,
						message: I18n.get('Select the damage')
					}
				]}
			>
				<RiskDamageSelect
					loading={isFetching}
					allowCreation={false}
					fieldName={fieldName}
					stepKeyId={stepKeyId}
					riskDamages={riskDamages}
					riskDamageId={riskDamageId}
					disabled={isLoading || isFetching}
					handleClearRiskDamage={handleClearRiskDamage}
					handleSelectRiskDamage={handleRiskDamageSelect}
				/>
			</CustomFormItem>
		</Col>
	);
};
