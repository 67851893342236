import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Descriptions } from 'antd';

import { Container, ParameterItem } from './styles';

const couplingList = [
	{
		label: 'Good',
		value: 1
	},
	{
		label: 'Fair',
		value: 2
	},
	{
		label: 'Poor',
		value: 3
	},
	{
		label: 'Unacceptable',
		value: 4
	}
];

const loadList = [
	{
		label: 'Load less than 5kg (intermittent)',
		value: 1
	},
	{
		label: 'Load from 5kg to 20kg (intermittent)',
		value: 2
	},
	{
		label: 'Load from 5kg to 20kg (static or repetitive)',
		value: 3
	},
	{
		label: 'Bigger load 20kg of repetitive load or blows',
		value: 4
	}
];

export const Parameters = ({ report }) => {
	const { repetition, force, coupling } = report;
	const selectedLoad = loadList[force - 1];
	const selectedCoupling = couplingList[coupling - 1];
	return (
		<Container>
			<Descriptions bordered layout="vertical">
				<Descriptions.Item label={I18n.get('Repetitions')}>
					<ParameterItem>
						{repetition} / {I18n.get('minute')}
					</ParameterItem>
				</Descriptions.Item>
				<Descriptions.Item label={I18n.get('Coupling')}>
					<ParameterItem>{I18n.get(selectedCoupling.label)}</ParameterItem>
				</Descriptions.Item>
				<Descriptions.Item label={I18n.get('Load')}>
					<ParameterItem>{I18n.get(selectedLoad.label)}</ParameterItem>
				</Descriptions.Item>
			</Descriptions>
		</Container>
	);
};
