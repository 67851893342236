import React from 'react';
import { Result } from 'antd';
import styled from 'styled-components';

const Container = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
`;

export const ErrorRequest = ({ title, children }) => (
	<Container>
		<Result status="error" title={title} />
		{children}
	</Container>
);
