import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Input } from 'antd';

import { FormItem } from '../../../components/FormItem';
interface ReportNameProps {
	fontSize?: string;
	disabled?: boolean;
	isRequired?: boolean;
	fieldName: string;
}

export const ReportName: React.FC<ReportNameProps> = ({ fontSize, isRequired, fieldName, disabled }) => {
	return (
		<FormItem namePath={[fieldName, 'name']} title="Report name" fontSize={fontSize} isRequired={isRequired} >
			<Input size="large" placeholder={I18n.get('Report name')} disabled={disabled} />
		</FormItem>
	);
};
