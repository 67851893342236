import styled from 'styled-components';
import { Col } from 'antd';

export const CustomTable = styled(Col)`
	.ant-table-thead {
		.ant-table-cell {
			color: #fff;
			font-weight: 600;
			background-color: #2f54eb;

			&::before {
				background-color: #fff !important;
			}

			:first-child {
				border-radius: 0.5rem 0 0 0;
			}

			:last-child {
				border-radius: 0 0.5rem 0 0;
			}
		}
	}

	.ant-table-row {
		.ant-table-cell {
			background-color: #f8f8f8;
		}

		:last-child {
			.ant-table-cell {
				box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.09) !important;
				:first-child {
					border-radius: 0 0 0 0.5rem;
				}

				:last-child {
					border-radius: 0 0 0.5rem 0;
				}
			}
		}
	}
`;

export const ActionsInline = styled(Col)`
	display: none;
	@media (min-width: 768px) {
		display: flex;
	}
`;

export const ActionsMenu = styled(Col)`
	display: flex;
	@media (min-width: 768px) {
		display: none;
	}
`;

export const CustomButton = styled.div`
	&:hover {
		span {
			color: white;
		}
	}
`;
