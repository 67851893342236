import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Col, Collapse, Form, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import moment, { Moment } from 'moment';

import { FilterRequest } from '../hooks/types/request';
import { Workstations } from './Workstations';
import { ReportName } from './ReportName';
import { Companies } from './Companies';
import { CreatedAt } from './CreatedAt';
import { UpdatedAt } from './UpdatedAt';
import { TotalBERA } from './TotalBera';
import { Sectors } from './Sectors';
import { Cycles } from './Cycles';
import { Lines } from './Lines';
import { RPN } from './RPN';

const { useForm } = Form;

const { Panel } = Collapse;

export type FormFields = {
	company_id_filter?: string;
	created_at?: Moment[];
	cycle_id?: string;
	line_id?: string;
	report_name?: string;
	sector_id?: string;
	task_id?: string;
	updated_at?: Moment[];
	workstation_id?: string;
	type_overall_score?: string;
	min_overall_score?: string;
	max_overall_score?: string;
	type_rpn?: string;
	min_rpn?: string;
	max_rpn?: string;
};

interface FilterProps {
	loading: boolean;
	initialValues: FormFields;
	onFilterClick(filterData: FilterRequest): void;
}

export const Filter: React.FC<FilterProps> = ({ onFilterClick, loading, initialValues }) => {
	const [form] = useForm();
	const history = useHistory();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const [disabled, setDisabled] = useState(Object.keys(initialValues).length === 0);

	function handleFieldsChange(_changedFields: any, allFields: any[]) {
		const allFieldsEmpty = allFields?.filter((field) => !!field.value);
		if (allFieldsEmpty.length === 0) {
			setDisabled(true);
			return;
		}
		setDisabled(false);
	}

	function handleClear() {
		history.push({
			pathname: '/custom-reports/bera',
			search: '?cleared=true'
		});
		form.resetFields();
	}

	function handleFinish(values: FormFields) {
		const {
			company_id_filter,
			created_at,
			cycle_id,
			line_id,
			report_name,
			sector_id,
			task_id,
			updated_at,
			workstation_id,
			type_overall_score,
			min_overall_score,
			max_overall_score,
			type_rpn,
			min_rpn,
			max_rpn
		} = values;

		const params: FilterRequest = {
			company_id_filter,
			cycle_id,
			line_id,
			report_name,
			sector_id,
			task_id,
			workstation_id,
			type_overall_score,
			min_overall_score,
			max_overall_score,
			type_rpn,
			min_rpn,
			max_rpn,
			created_at_start: undefined,
			created_at_end: undefined,
			updated_at_start: undefined,
			updated_at_end: undefined
		};

		if (created_at) {
			const [start_date, end_date] = created_at;
			const start = moment(start_date).format();
			const end = moment(end_date).format();
			params.created_at_start = start;
			params.created_at_end = end;
		}

		if (updated_at) {
			const [start_date, end_date] = updated_at;
			const start = moment(start_date).format();
			const end = moment(end_date).format();
			params.updated_at_start = start;
			params.updated_at_end = end;
		}

		updateURLParams(params);

		onFilterClick(params);
	}

	function updateURLParams(params: FilterRequest) {
		for (const key in params) {
			searchParams.delete('cleared');
			searchParams.delete(key);
			if (Object.prototype.hasOwnProperty.call(params, key)) {
				const typedKey: keyof FilterRequest = key as any;
				const param = params[typedKey];
				if (param) {
					searchParams.set(key, param);
				}
			}
		}

		history.push({
			pathname: '/custom-reports/bera',
			search: searchParams.toString()
		});
	}

	return (
		<Collapse defaultActiveKey={search ? '1' : '0'}>
			<Panel key={'1'} header={I18n.get('Filter')}>
				<Form
					form={form}
					layout="vertical"
					onFinish={handleFinish}
					initialValues={initialValues}
					onFieldsChange={handleFieldsChange}
				>
					<Row gutter={8}>
						<Col sm={4}>
							<Companies />
						</Col>
						<Col sm={4}>
							<Sectors />
						</Col>
						<Col sm={4}>
							<Lines />
						</Col>
						<Col sm={4}>
							<Workstations />
						</Col>
					</Row>
					<Row gutter={8}>
						<Col sm={4}>
							<Cycles />
						</Col>
						<Col sm={4}>
							<ReportName />
						</Col>
						<Col sm={4}>
							<CreatedAt />
						</Col>
						<Col sm={4}>
							<UpdatedAt />
						</Col>
					</Row>
					<Row gutter={8}>
						<Col sm={4}>
							<TotalBERA />
						</Col>
						<Col sm={4}>
							<RPN />
						</Col>
					</Row>
					<Row gutter={8} style={{ paddingTop: '20px' }}>
						<Col sm={4}>
							<Button
								type="primary"
								htmlType="submit"
								disabled={disabled}
								icon={<SearchOutlined />}
								style={{ width: '100%' }}
								loading={loading}
							>
								{I18n.get('Search')}
							</Button>
						</Col>
						<Col sm={4}>
							<Button style={{ width: '100%' }} onClick={handleClear}>
								{I18n.get('Clear')}
							</Button>
						</Col>
					</Row>
				</Form>
			</Panel>
		</Collapse>
	);
};
