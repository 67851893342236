import React, { useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';
import { Col, Modal, Row } from 'antd';
import moment from 'moment';

import { useApplicationContext } from '@/context/v1/Application/context';
import { Title } from '@/components/Typography';

import { DeleteBeraJobSummaryRequest, FilterRequest } from './hooks/types/request';
import { useDeleteBeraJobSummary, useBeraJobSummaryList } from './hooks/hooks';
import { BeraJobSummaryList } from './hooks/types/response';
import { Filter, FormFields } from './Filter';
import { ReportList } from './ReportList';

export const BeraReports: React.FC = () => {
	const history = useHistory();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const { organization, company } = useApplicationContext();

	const queryParams = convertURLParamToObject(searchParams);

	const [page, setPage] = useState(0);
	const [maxReports, setMaxReports] = useState(10);
	const [filter, setFilter] = useState<FilterRequest>(queryParams);

	const initialValues: FormFields = mapFilterInitialValues(queryParams) ?? {};

	const { mutateAsync: deleteJobSummary, isLoading: deletingBeraJobSummary } = useDeleteBeraJobSummary();
	const {
		data: beraJobSummaries,
		isLoading,
		isFetching
	} = useBeraJobSummaryList({
		organization_id: organization?.id,
		company_id: company?.id,
		filter: {
			...filter,
			offset: page === 0 ? String(page) : String(page - 1),
			limit: String(maxReports)
		}
	});

	function handlePageChange(page: number) {
		setPage(page);
	}

	function handleMaxReportsChange(reports: number) {
		setMaxReports(reports);
	}

	function handleEditJobSummary(jobSummaryData: BeraJobSummaryList) {
		history.push(`/custom-reports/bera/report/${jobSummaryData.id}`);
	}

	function handleCreateBeraJobClick() {
		history.push('/custom-reports/bera/report');
	}

	function handleFilterClick(filterData: FilterRequest) {
		setFilter(filterData);
	}

	function mapFilterInitialValues(queryParams: FilterRequest) {
		if (Object.keys(queryParams)?.length > 0) {
			const { created_at_start, created_at_end, updated_at_start, updated_at_end } = queryParams;

			const created_at =
				created_at_start && created_at_end ? [moment(created_at_start), moment(created_at_end)] : undefined;
			const updated_at =
				updated_at_start && updated_at_end ? [moment(updated_at_start), moment(updated_at_end)] : undefined;
			const initialValues: FormFields = {
				...queryParams,
				created_at,
				updated_at
			};
			return initialValues;
		}
	}

	async function handleDeleteJobSummary(id: string) {
		Modal.confirm({
			title: I18n.get('Heads up! Want to delete this report?'),
			icon: <ExclamationCircleOutlined />,
			okText: I18n.get('Confirm'),
			cancelText: I18n.get('Cancel'),
			okType: 'danger',
			onOk: async () => {
				const body: DeleteBeraJobSummaryRequest = {
					company_id: company?.id,
					organization_id: organization?.id,
					id
				};
				await deleteJobSummary({ body });
			}
		});
	}

	function convertURLParamToObject(searchParams: URLSearchParams) {
		return Object.fromEntries(searchParams.entries());
	}

	return (
		<Row gutter={[0, 10]} justify="center">
			<Col span={24}>
				<Title level={4}>Basic Ergonomics Risk Assessment (B.E.R.A.)</Title>
			</Col>
			<Col span={24}>
				<Filter loading={isFetching} onFilterClick={handleFilterClick} initialValues={initialValues} />
			</Col>
			<Col span={24}>
				<ReportList
					data={beraJobSummaries}
					page={page}
					maxReports={maxReports}
					onPageChange={handlePageChange}
					onEditJobSummary={handleEditJobSummary}
					onCreateReport={handleCreateBeraJobClick}
					onDeleteJobSummary={handleDeleteJobSummary}
					onMaxReportsChange={handleMaxReportsChange}
					isLoading={isLoading || isFetching || deletingBeraJobSummary}
				/>
			</Col>
		</Row>
	);
};
