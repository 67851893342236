import React, { useState } from 'react';
import { Button, Col, Row } from 'antd';
import moment from 'moment';

import { useCountdown } from '@/hooks/v1/useCountdown';
import { I18n } from '@aws-amplify/core';

import { useCheck2FAContext } from '../context';
import { Countdown } from './styles';

export const ResendEmail = () => {
	const [awaitTime, setAwaitTime] = useState<Date | undefined>(moment().add(1, 'minute').toDate());
	const { seconds } = useCountdown(awaitTime);
	const formattedSeconds = ('0' + seconds).slice(-2);

	const { userSession } = useCheck2FAContext();
	const { handleResendToken } = useCheck2FAContext();

	async function handleResendClick() {
		const resent = await handleResendToken(userSession.user.email);
		if (resent) {
		}
		setAwaitTime(moment().add(1, 'minute').toDate());
	}

	return (
		<Col span={24}>
			<Row align="middle" justify="center">
				<Col>
					<Button disabled={seconds > 0} onClick={handleResendClick} type="link">
						{I18n.get('Resend e-mail')}
					</Button>
				</Col>
			</Row>
			<Row align="middle" justify="center">
				<Col>
					<Countdown>{formattedSeconds}s</Countdown>
				</Col>
			</Row>
		</Col>
	);
};
