import React from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { Col, Divider, Row } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

import { TaskResultSecondRow } from './TaskResultSecondRow';
import { TaskResultFirstRow } from './TaskResultFirstRow';
import { SeraResultTaskSummary } from '../hooks/types';
import { useSeraStepsContext } from '../../context';
import { Title } from '@/components/Typography';
import { Description } from './styles';

interface TaskSummaryProps {
	taskSummary: SeraResultTaskSummary[];
}

export const TaskSummary: React.FC<TaskSummaryProps> = ({ taskSummary }) => {
	const { mapRPNToPriority } = useSeraStepsContext();

	const style = {
		fontSize: 20,
		width: '1.75rem',
		marginTop: '0.5rem'
	};

	return (
		<Col xs={24}>
			<Row>
				<Col xs={24}>
					<Title level={5}>Tasks summary</Title>
				</Col>
				<Col span={1}>
					<Divider
						type="vertical"
						style={{
							margin: '0 0 0 0.7rem',
							borderLeft: '4px solid #e6e8e8',
							height: '90%'
						}}
					/>
				</Col>
			</Row>
			{taskSummary.map((summary) => {
				const { color } = mapRPNToPriority(summary.rpn);
				return (
					<Row key={summary.id} style={{ margin: '0 0 2rem 0' }}>
						<Col span={24}>
							<Row gutter={[0, 0]} justify="space-between" align="middle">
								<Col xs={16}>
									<Row gutter={[0, 0]} align="middle">
										<Col span={1}>
											<WarningOutlined style={{ ...style, color }} />
										</Col>
										<Col span={23}>
											<span style={{ margin: '0 0 0 1rem', fontWeight: 600, fontSize: '1rem' }}>
												{summary.task_name}
											</span>
										</Col>
									</Row>
								</Col>
								<Col xs={8}>
									<Row gutter={24} align="middle">
										<Col span={12}>
											<Row align="bottom">
												<Title style={{ margin: '0 0.4rem 0.1rem 0', fontSize: '0.8rem' }}>
													{I18n.get(
														summary?.reviewer_name !== summary?.evaluator_name
															? 'Reviewer'
															: 'Evaluator'
													)}
													:
												</Title>
												<Description>
													{summary.reviewer_name ?? summary.evaluator_name ?? '-'}
												</Description>
											</Row>
											<Row align="bottom">
												<Title style={{ margin: '0 0.4rem 0.1rem 0', fontSize: '0.8rem' }}>
													{I18n.get('Reviewed at')}:
												</Title>
												<Description>
													{moment(summary.reviewed_at ?? summary.created_at).format('L')}
												</Description>
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>

							<Row style={{ margin: '0.5rem 0 1rem 0' }}>
								<Col span={1}>
									<Divider
										type="vertical"
										style={{
											margin: '0 0 0 0.7rem',
											borderLeft: '4px solid #e6e8e8',
											height: '110%'
										}}
									/>
								</Col>

								<Col span={22} offset={1}>
									<TaskResultFirstRow summary={summary} />
									<TaskResultSecondRow summary={summary} />
								</Col>
							</Row>
						</Col>
					</Row>
				);
			})}
		</Col>
	);
};
