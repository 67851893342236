import React, { createContext, useContext, useReducer } from 'react';
import { AppReducer, Types } from './reducer';

// https://endertech.com/blog/using-reacts-context-api-for-global-state-managements

const storage = {
	organization: localStorage.getItem('organization'),
	company: localStorage.getItem('company'),
	user: localStorage.getItem('info_user')
};

const defaultState = {
	organization: JSON.parse(storage.organization),
	company: JSON.parse(storage.company),
	user: JSON.parse(storage.user)?.user,
	setOrganization: (data) => {},
	setCompany: (data) => {},
	clearProject: () => {}
};

const ApplicationContext = createContext(defaultState);

export const ApplicationProvider = ({ children }) => {
	const [state, dispatch] = useReducer(AppReducer, defaultState);

	function setOrganization(item) {
		dispatch({ type: Types.SET_ORGANIZATION, payload: item });
	}

	function setCompany(item) {
		localStorage.setItem('organization', JSON.stringify(state.organization));
		localStorage.setItem('company', JSON.stringify(item));
		dispatch({ type: Types.SET_COMPANY, payload: item });
	}

	function setUser(item) {
		localStorage.setItem('user', JSON.stringify(item));
		dispatch({ type: Types.SET_USER, payload: item });
	}

	function clearProject() {
		localStorage.removeItem('organization');
		localStorage.removeItem('company');
		dispatch({ type: Types.CLEAR_PROJECT });
	}

	const context = {
		...state,
		setOrganization,
		setCompany,
		setUser,
		clearProject
	};

	return <ApplicationContext.Provider value={context}>{children}</ApplicationContext.Provider>;
};

export function useApplicationContext() {
	const context = useContext(ApplicationContext);
	return context;
}
