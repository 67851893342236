import React, { useState } from 'react';
import { Button, Col, Row, Select } from 'antd';

import { I18n } from '@aws-amplify/core';

import { useCheck2FAContext } from '../context';
import { DefaultOptionType } from 'antd/lib/select';
import { TFATypes } from '@/types/AuthUser';
import { RecoveryToken, RecoveryTokenInput, ResetTokenContainer, ResetTokenDescription } from './styles';

export const Reset2FA = () => {
	const [recoveryToken, setRecoveryToken] = useState('');
	const [selectedType, setSelectedType] = useState(false);

	const { type, reset2FALoading } = useCheck2FAContext();
	const {
		handleReset2FA,
		handleTypeChange,
		handleOpenCheck2FAModal,
		handleOpenReset2FAModal,
		handleOpenValidate2FAModal,
		handleQRCodeUrl
	} = useCheck2FAContext();

	async function handleConfirmClick() {
		const reset = await handleReset2FA(type, recoveryToken);
		if (reset.qr_code_url || reset.message) {
			handleQRCodeUrl(reset.qr_code_url ?? '');
			handleOpenCheck2FAModal(false);
			handleOpenReset2FAModal(false);
			handleOpenValidate2FAModal(true);
		}
	}

	function handleRecoveryTokenChange(token: string) {
		setRecoveryToken(token);
	}

	function handleSelectType(type: TFATypes) {
		handleTypeChange(type);
		setSelectedType(true);
	}

	const typeOptions: DefaultOptionType[] = [
		// {
		// 	value: 'EMAIL',
		// 	label: I18n.get('E-mail')
		// },
		{
			value: 'AUTHENTICATOR',
			label: I18n.get('Authenticator app')
		}
	];

	return (
		<ResetTokenContainer align="middle" justify="center">
			<Col span={18}>
				<Row justify="center">
					<Col span={24}>
						<Row justify="center">
							<ResetTokenDescription>
								{I18n.get(
									'Select a new desired authentication type and insert one of your recovery token to perform this operation.'
								)}
							</ResetTokenDescription>
						</Row>
					</Col>
				</Row>
				<Row align="middle" justify="center">
					<RecoveryToken style={{ minWidth: '160px' }} name="recovery_token_type">
						<Select
							placeholder={I18n.get('Authentication method')}
							options={typeOptions}
							onChange={handleSelectType}
						/>
					</RecoveryToken>
				</Row>
				<Row align="middle" justify="center">
					<RecoveryToken
						name="recovery_token"
						rules={[
							() => ({
								validator() {
									return selectedType && recoveryToken.length !== 6
										? Promise.reject(I18n.get('Enter the 6 digit code.'))
										: Promise.resolve();
								}
							})
						]}
					>
						<RecoveryTokenInput
							maxLength={6}
							max={6}
							disabled={!selectedType}
							value={recoveryToken}
							placeholder={I18n.get('Recovery token')}
							onChange={(event) => handleRecoveryTokenChange(event.target.value)}
						/>
					</RecoveryToken>
				</Row>
			</Col>
			<Col span={18}>
				<Row justify="center">
					<Button
						onClick={handleConfirmClick}
						loading={reset2FALoading}
						type="primary"
						disabled={!type || recoveryToken.length !== 6}
					>
						{I18n.get('Confirm')}
					</Button>
				</Row>
			</Col>
		</ResetTokenContainer>
	);
};
