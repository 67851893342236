import { useMutation } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import type { AxiosError } from 'axios';
import { message } from 'antd';

import Api from '@/services/api';
import { CustomReportResult, Response, BaseContext } from '@/types';

export type CreateCommentsDTO = BaseContext & {
	comment: string;
	custom_report_result_id?: string;
};

async function updateComments(payload: CreateCommentsDTO) {
	const url = '/custom-report/result/comment';
	const { data } = await Api.put(url, payload);
	return data;
}

export const useUpdateCustomReportResultComment = () => {
	return useMutation<CustomReportResult, AxiosError<Response>, CreateCommentsDTO>((body) => updateComments(body), {
		onError: (err) => {
			message.error(I18n.get(err?.response?.data?.message ?? err?.message));
		},
		onSuccess: () => {
			message.success(I18n.get('Comment updated successfully'));
		}
	});
};
