import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Form, Select, SelectProps } from 'antd';
import { FileDTO } from '../types/response';

const { useFormInstance } = Form;

interface FileSelectProps {
	sectorId?: string;
	files: FileDTO[];
	fileId: string;
	loading: boolean;
	disabled: boolean;
	handleSelectFile?: (id: string) => void;
	handleClearFile?: () => void;
}

type OptionType = { label: string; value: string } | undefined;

export const FileSelect: React.FC<FileSelectProps & SelectProps> = ({
	files,
	fileId,
	loading,
	disabled,
	handleSelectFile,
	handleClearFile,
	sectorId,
	...props
}) => {
	const { validateFields } = useFormInstance();

	async function handleChange(id: string): Promise<void> {
		if (handleSelectFile) {
			handleSelectFile(id);
			await validateFields(['file_id']);
		}
	}

	function handleClear(): void {
		if (handleClearFile) {
			handleClearFile();
		}
	}

	function filterOption(option: OptionType, input: string): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	return (
		<Select
			{...props}
			showSearch
			allowClear
			value={fileId}
			loading={loading}
			disabled={disabled}
			onClear={handleClear}
			onChange={handleChange}
			placeholder={I18n.get('File')}
			filterOption={(input, option: any) => filterOption(option, input)}
			options={files?.map(({ id, original_name }) => ({ label: original_name, value: id }))}
		/>
	);
};
