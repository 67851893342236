import React, { createContext, ReactNode, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormInstance } from 'antd';

import { Hooks as ConsolidatePDFHooks } from './Form/Steps/Result/DownloadPDF/hooks';
import { useApplicationContext } from '@/context/v1/Application/context';
import { Utils } from './Form/Steps/PosturesAndMovements/utils';
import { Context } from './index.types';
import { useFindOrCreateAnalysis, useGetExhibitions, useRebaAngles, useGetResults } from './hooks';

type UrlParams = {
	file_id: string;
};

type PreliminaryAnalysisProviderProps = {
	children: ReactNode;
};
const { useConsolidatePDF } = ConsolidatePDFHooks;

const PreliminaryAnalysisContext = createContext<Context>({} as Context);

export function PreliminaryAnalysisProvider({ children }: PreliminaryAnalysisProviderProps) {
	const { file_id } = useParams<UrlParams>();
	const [consolidateClick, setConsolidateClick] = useState(false);
	const { organization, company } = useApplicationContext();
	const preliminaryAnalysis = useGetResults(organization?.id, company?.id, file_id);
	const consolidate = useConsolidatePDF();

	const { data: file } = useFindOrCreateAnalysis(organization?.id, company?.id, file_id);
	const {
		data: exhibitions,
		isLoading: gettingExhibitions,
		isError: errorGettingExhibitions
	} = useGetExhibitions(organization?.id, company?.id, file_id);

	const {
		data: angles,
		isLoading: gettingAngles,
		isError: errorGettingAngles
	} = useRebaAngles(organization?.id, company?.id, file_id);

	function getLevelRiskFields(form: FormInstance<any>, baseFormName: string, formName: string[], stepName: string[]) {
		const exhibition: string = form.getFieldValue([baseFormName, ...formName, ...stepName, 'exhibition_id']);
		const consequence: string = form.getFieldValue([baseFormName, ...formName, ...stepName, 'consequence_id']);
		const probability: string = form.getFieldValue([baseFormName, ...formName, ...stepName, 'probability_id']);

		return { exhibition, consequence, probability };
	}

	function calculateBodyPartPercentage(member: number[]) {
		return Utils.calculatePercentage(member);
	}

	const contextValue: Context = {
		file,
		preliminaryAnalysis,
		exhibitionList: {
			exhibitions,
			gettingExhibitions,
			errorGettingExhibitions
		},
		rebaAngles: {
			angles,
			gettingAngles,
			errorGettingAngles
		},
		getLevelRiskFields,
		calculateBodyPartPercentage,
		consolidate,
		consolidateClick,
		setConsolidateClick
	};

	return <PreliminaryAnalysisContext.Provider value={contextValue}>{children}</PreliminaryAnalysisContext.Provider>;
}

export function usePreliminaryAnalysis() {
	const context = useContext(PreliminaryAnalysisContext);
	return context;
}
