import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Row, Col, Form, message } from 'antd';
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';
import _ from 'lodash';

import { Mass } from './Mass';
import { Result } from './Result';
import { Posture } from './Posture';
import { Conditions } from './Conditions';
import { Information } from './Information';
import { DistanceOrTime } from './DistanceOrTime';

import Api from '@/services/api';
import { useFile, useSectors } from '@/hooks/v1';
import { useDebounce } from '@/hooks/useDebounce';
import Spinner from '@/components/Layout/Spinner';
import { HeaderStep, Footer } from '@/components/views/Report/Steps.js';
import { REPORT_COMMENT_DEBOUNCE_TIME as COMMENT_DEBOUNCE_TIME } from '@/constants/index.js';

import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useSessionStorage } from '@/hooks/useSessionStorage';
import { KimPushPullProvider } from './context';

const { useForm } = Form;

export function KimPushPull() {
	const queryClient = useQueryClient();
	const { file_id } = useParams();
	const history = useHistory();
	const [form] = useForm();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

	let existingReport = {};
	const [currentStep, setCurrentStep] = useState(0);

	const organization = useSelector((state) => state.organization.organization);
	const company = useSelector((state) => state.organization.company);

	const [cameFromPreliminary, setCameFromPreliminary] = useState(false);
	const [nameLocal] = useLocalStorage('kim-pp', false);
	const [nameSession, setNameSession] = useSessionStorage('kim-pp', false);

	const sectors = useSectors({
		organizationId: organization.id,
		companyId: company.id
	});

	const { file } = useFile({
		organizationId: organization.id,
		companyId: company.id,
		fileId: file_id
	});

	async function getReport({ organizationId, companyId, fileId }) {
		if (!organizationId || !companyId || !fileId) return [];
		const url = `/ergonomic-tool/kim-pp/${fileId}?organization_id=${organizationId}&company_id=${companyId}`;
		const { data } = await Api.get(url);
		return data;
	}

	const reportParams = {
		organizationId: organization?.id,
		companyId: company?.id,
		fileId: file?.data?.id
	};

	const report = useQuery(['kim_pp', reportParams], () => getReport(reportParams), {
		enabled: !!organization && !!company && !!file.data,
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: () => setCurrentStep(steps.length - 1)
	});

	const isLoading = sectors.isLoading || file.isLoading;
	const isError = sectors.isError || file.isError;

	useEffect(() => {
		if (!isLoading) {
			if (nameLocal || nameSession) {
				setCameFromPreliminary(true);
			}
		}
	}, [isLoading]);

	useEffect(() => {
		if (cameFromPreliminary) {
			localStorage.removeItem('kim-pp');
			setNameSession(true);
		}
	}, [cameFromPreliminary]);

	const updateReport = useMutation(
		({ body, reportExists }) => {
			const { report_id } = body.kim_pp_input;

			body.kim_pp_input = {
				...body.kim_pp_input,
				gender: body.kim_pp_input.gender_input,
				pair: body.kim_pp_input.pair_input,
				distance_or_duration: body.kim_pp_input.distance_or_duration_input,
				vehicle: body.kim_pp_input.vehicle_input,
				mass: body.kim_pp_input.mass_input,
				driveway_conditions: body.kim_pp_input.driveway_conditions_input,
				inclination_or_stairs: body.kim_pp_input.inclination_or_stairs_input,
				posture: body.kim_pp_input.posture_input,
				temporal_distribution: body.kim_pp_input.temporal_distribution_input
			};

			if (reportExists) {
				return Api.put(`/ergonomic-tool/kim-pp/${report_id}`, body);
			} else {
				return Api.post('/ergonomic-tool/kim-pp', body);
			}
		},
		{
			onError: (err) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: (response) => {
				queryClient.invalidateQueries(['file', 'kim_pp']);
				queryClient.setQueryData(['kim_pp', reportParams], response.data);
				message.success(I18n.get('Report created successfully'));
				onNext();
			}
		}
	);

	const updateComment = useMutation(
		({ body }) => {
			return Api.put(`/ergonomic-tool/kim-pp/comment/${body.report_id}`, body);
		},
		{
			onError: (err) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: (response) => {
				existingReport = {
					...response.data,
					collection_date: moment(response.data.collection_date)
				};
				queryClient.setQueryData(['kim_pp', reportParams], response.data);
				message.success(I18n.get('Comment updated successfully'));
			}
		}
	);

	const generatePDF = useMutation(
		({ body, opts }) => {
			return Api.post('/ergonomic-tool/kim-pp/document/pdf', body, opts);
		},
		{
			onSuccess: (res) => {
				const blob = new Blob([res.data], {
					type: 'application/pdf'
				});
				window.open(URL.createObjectURL(blob));
				message.success(I18n.get('Document created successfully'));
			},
			onError: (err) => {
				message.error(I18n.get(err.response.data.message));
			}
		}
	);

	function onNext() {
		if (currentStep < steps.length - 2) {
			form.validateFields()
				.then(() => {
					setCurrentStep((prev) => prev + 1);
				})
				.catch(() => message.error(I18n.get('Enter the required values')));
		} else {
			setCurrentStep((prev) => prev + 1);
		}
	}

	function onPrevious() {
		setCurrentStep((prev) => prev - 1);
	}

	function onFinish() {
		form.validateFields()
			.then(() => {
				const values = form.getFieldsValue(true);

				const { organization_id, ...rest } = values;
				const company_id = company?.id;
				const kim_pp_input = {
					...rest,
					file_id: file.data.id,
					...(!!report.data && { report_id: report.data.id })
				};

				if (isFormChanged(kim_pp_input, existingReport)) {
					const body = { organization_id, company_id, kim_pp_input };
					updateReport.mutate({ body, reportExists: !!report.data });
				} else {
					onNext();
				}
			})
			.catch(() => message.error(I18n.get('Enter the required values')));
	}

	function onClose() {
		const values = form.getFieldsValue(true);
		const { comment, organization_id } = values;
		if (report.data.comment !== comment) {
			const kim_pp_input = { report_id: report.data.id, comment };
			const body = { organization_id, kim_pp_input };
			updateComment.mutate({ body });
		}

		const custom_report = !!searchParams.get('custom_report');
		if (cameFromPreliminary) {
			window.close();
		}
		if (custom_report) {
			return history.push('/custom-reports/files');
		}
		history.push('/reporting');
	}

	const onChangeComment = useDebounce(saveComment, COMMENT_DEBOUNCE_TIME);

	async function saveComment() {
		const values = form.getFieldsValue(true);
		const { comment, organization_id } = values;
		const company_id = company?.id;
		if ((comment || comment === '') && report.data.comment !== comment) {
			const kim_pp_input = { report_id: report.data.id, comment };
			const body = { organization_id, company_id, kim_pp_input };
			await updateComment.mutateAsync({ body });
		}
	}

	async function onDownloadPDF(fileData) {
		await saveComment();
		const browserLanguage = window.navigator.language ?? 'en-US';

		const body = {
			organization_id: organization?.id,
			company_id: company?.id,
			file_id: fileData.id,
			locale: browserLanguage
		};

		const opts = { responseType: 'blob' };

		generatePDF.mutate({ body, opts });
	}

	function isFormChanged(kim_pp_input, existingReport) {
		const existingFile = {
			sector_id: file?.data?.workstations?.line?.sector?.id,
			line_id: file?.data?.workstations?.line?.id,
			workstation_id: file?.data?.workstations?.id
		};

		const formFile = {
			sector_id: kim_pp_input.sector_id,
			line_id: kim_pp_input.line_id,
			workstation_id: kim_pp_input.workstation_id
		};

		const existingKimPP = {
			collection_date: moment(existingReport.collection_date).format('L'),
			gender: existingReport.gender,
			pair: existingReport.pair,
			vehicle: existingReport.vehicle,
			driveway_conditions: existingReport.driveway_conditions,
			inclination_or_stairs: existingReport.inclination_or_stairs,
			mass: existingReport.mass,
			working_conditions: existingReport.working_conditions,
			properties: existingReport.properties,
			posture: existingReport.posture,
			temporal_distribution: existingReport.temporal_distribution
		};

		const formKimPP = {
			collection_date: moment(kim_pp_input.collection_date).format('L'),
			gender: kim_pp_input.gender,
			pair: kim_pp_input.pair,
			distance_or_duration: kim_pp_input.distance_or_duration,
			vehicle: kim_pp_input.vehicle,
			driveway_conditions: kim_pp_input.driveway_conditions,
			inclination_or_stairs: kim_pp_input.inclination_or_stairs,
			mass: kim_pp_input.mass,
			working_conditions: kim_pp_input.working_conditions,
			properties: kim_pp_input.properties,
			posture: kim_pp_input.posture,
			temporal_distribution: kim_pp_input.temporal_distribution
		};

		const isFileChanged = !_.isEqual(existingFile, formFile);

		const isKimPPChanged = !_.isEqual(existingKimPP, formKimPP);

		return isFileChanged || isKimPPChanged;
	}

	const steps = [
		{
			title: 'Information',
			content: <Information file={file} />,
			is_valid: true
		},
		{
			title: 'Cycle',
			content: <DistanceOrTime />,
			is_valid: true
		},
		{
			title: 'Mass',
			content: <Mass />,
			is_valid: true
		},
		{
			title: 'Conditions',
			content: <Conditions />,
			is_valid: true
		},
		{
			title: 'Posture',
			content: <Posture />,
			is_valid: true
		},
		{
			title: 'Result',
			content: (
				<Result
					file={file}
					report={report}
					sectors={sectors}
					onDownloadPDF={onDownloadPDF}
					isLoadingPDF={generatePDF.isLoading}
					onChangeComment={onChangeComment}
				/>
			)
		}
	];

	useEffect(() => {
		if (report.data) {
			if (report.data.gender === 0)
				form.setFieldsValue({
					...report.data,
					gender: I18n.get('Male'),
					collection_date: moment(report?.data?.collection_date)
				});
			if (report.data.gender === 1)
				form.setFieldsValue({
					...report.data,
					gender: I18n.get('Female'),
					collection_date: moment(report?.data?.collection_date)
				});
			if (report.data.pair === 0)
				form.setFieldsValue({
					...report.data,
					pair: I18n.get('No'),
					collection_date: moment(report?.data?.collection_date)
				});
			if (report.data.pair === 1)
				form.setFieldsValue({
					...report.data,
					pair: I18n.get('Yes'),
					collection_date: moment(report?.data?.collection_date)
				});
		}
	}, [report.data]);

	if (isLoading) {
		return <Spinner />;
	}

	// TODO: IMPROVE ERROR HANDLING
	if (isError) {
		return <h2>Internal server error </h2>;
	}

	existingReport = {
		...report?.data,
		collection_date: moment(report?.data?.collection_date)
	};

	const initialValues = {
		organization_id: organization.id,
		sector_id: file?.data?.workstations?.line?.sector?.id,
		line_id: file?.data?.workstations?.line?.id,
		workstation_id: file?.data?.workstations?.id,
		...(report.data && existingReport)
	};

	return (
		<KimPushPullProvider>
			<Form form={form} layout="vertical" initialValues={initialValues}>
				<Row justify="center">
					<Col sm={24} style={{ marginBottom: '16px', textAlign: 'center' }}>
						<h2>{I18n.get('KIM - Push and pull')}</h2>
					</Col>
					<Col sm={24} md={24} xxl={20} xl={22}>
						<Row justify="center" align="middle" style={{ marginBottom: '20px' }}>
							<Col sm={24} xxl={20}>
								<HeaderStep current={currentStep} steps={steps} />
							</Col>
						</Row>
						<Row justify="center">
							<Col sm={24} style={{ minHeight: '350px' }}>
								{isLoading ? <Spinner /> : steps[currentStep].content}
							</Col>
						</Row>
						<Row justify="center">
							<Col sm={24}>
								{!isLoading && (
									<Footer
										steps={steps}
										onNext={onNext}
										onClose={onClose}
										onFinish={onFinish}
										current={currentStep}
										isFetching={isLoading}
										onPrevious={onPrevious}
									/>
								)}
							</Col>
						</Row>
					</Col>
				</Row>
			</Form>
		</KimPushPullProvider>
	);
}
