import React from 'react';
import { Col, Divider, Row } from 'antd';

import { FieldsComparison } from './FieldsComparison';
import { ReviewTask } from '../hooks/types';
import { GrayCircle } from './styles';

interface ReviewFieldsProps {
	tasks: ReviewTask[];
}

export const ReviewFields: React.FC<ReviewFieldsProps> = ({ tasks }) => {
	return (
		<Col xs={24}>
			{tasks.map((task, index) => {
				const hasDivider = index !== tasks.length - 1;
				return (
					<>
						<Row gutter={[0, 0]} justify="space-between" align="middle">
							<Col xs={24}>
								<Row gutter={[0, 0]} align="middle">
									<Col span={1}>
										<GrayCircle />
									</Col>
									<Col span={23}>
										<span style={{ margin: '0 0 0 1rem', fontWeight: 600, fontSize: '1rem' }}>
											{task.name}
										</span>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col span={1} style={{ minHeight: '100%' }}>
								<Divider
									type="vertical"
									style={{
										margin: '0 0 0 0.7rem',
										borderLeft: '4px solid #e6e8e8',
										height: '100%'
									}}
								/>
							</Col>

							<Col span={21} style={{ marginLeft: hasDivider ? '0.8rem' : '3.8rem' }}>
								<FieldsComparison task={task} fields={task.changed_fields} />
							</Col>
						</Row>
					</>
				);
			})}
		</Col>
	);
};
