import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import * as Icons from '@ant-design/icons';
import { Row, Col, Form, Grid, Collapse } from 'antd';

import { StepKeyResult } from '@/hooks';
import { Title } from '@/components/Typography';
import { StepKeyDescription } from './StepKeyDescription';
import { CollapseStyled, Score } from './styles';

const { useBreakpoint } = Grid;

interface CollapseResultsProps {
	color: string;
	stepName: string;
	stepKey: StepKeyResult;
}

export function CollapseResults({ stepKey, color, stepName }: CollapseResultsProps) {
	const breakpoint = useBreakpoint();
	const [isCollapseOpen, setIsCollapseOpen] = useState<boolean>(false);

	const { score = 0, risk_scale = 0 } = stepKey;

	const style = {
		fontSize: 20,
		width: '1.75rem'
	};

	const icons = [
		<Icons.CloseCircleOutlined key="not_analyzed" style={{ ...style, color: '#b8b8b8' }} />,
		<Icons.CheckCircleOutlined key="acceptable_risk" style={{ ...style, color: '#2cc852' }} />,
		<Icons.ExclamationCircleOutlined key="moderate_risk" style={{ ...style, color: '#FFDE31' }} />,
		<Icons.ExclamationCircleOutlined key="high_risk" style={{ ...style, color: '#F78A38' }} />,
		<Icons.WarningOutlined key="very_high_risk" style={{ ...style, color: '#E74150' }} />,
		<Icons.WarningOutlined key="extreme_and_imminent_risk" style={{ ...style, color: '#9B54E2' }} />
	];

	return (
		<Form.Item name={[stepKey.id, 'collapse']} style={{ margin: 0 }}>
			<CollapseStyled
				ghost
				key={stepKey.id}
				onChange={() => {
					setIsCollapseOpen(!isCollapseOpen);
				}}
				collapsible={Number(score) > 0 ? undefined : 'disabled'}
			>
				<Collapse.Panel
					key={0}
					showArrow={score !== 0}
					header={
						<Row align="middle" className={score === 0 ? 'panel-is-disable' : undefined}>
							<Col span={1} style={{ display: 'flex', alignItems: 'center' }}>
								{icons[!risk_scale || risk_scale === 0 ? 0 : risk_scale]}
							</Col>
							<Col
								xs={{ offset: 1, span: 16 }}
								xl={{ offset: 0, span: 18 }}
								xxl={{ offset: 0, span: 19 }}
							>
								<Title style={{ margin: 0 }} level={!breakpoint.xl ? 5 : 4}>
									{stepName}
								</Title>
							</Col>
							{!isCollapseOpen && (
								<Col xs={{ offset: 1, span: 4 }} xl={4} xxl={{ offset: 1, span: 3 }}>
									{score ? (
										<Score $stepKeyId={stepKey.id} $backGroundColor={color}>
											RPN: {score ?? 0}
										</Score>
									) : (
										<Score $backGroundColor={'#b8b8b8'}>{I18n.get('Not applicable')}</Score>
									)}
								</Col>
							)}
						</Row>
					}
				>
					<Row>
						<Col span={23}>
							<Row style={{ marginLeft: '1.6rem' }}>
								<StepKeyDescription />
							</Row>
						</Col>
					</Row>
				</Collapse.Panel>
			</CollapseStyled>
		</Form.Item>
	);
}
