import { useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import Api from '@/services/api';
import type { BaseContext } from '@/types';

export type GetCustomReportResultStepKeyDefaultDataDTO = BaseContext & {
	step_key_id: string;
	file_id: string;
};

export interface GetCustomReportResultStepKeyDefaultDataResponse {
	step_key_result: StepKeyResult
	sub_step_key_results: SubStepKeyResult[]
	additional_item_results: AdditionalItemResult[]
}

export interface StepKeyResult {
	id: string
	score?: number
	result?: number
	exposure_id?: string;
	severity_id?: string;
	risk_damage_id?: string;
	risk_category_id?: string;
	vulnerability_id?: string;
	risk_description_id?: string;
}

export interface SubStepKeyResult {
	id: string
	score?: number
	result?: number
	severity_id?: string
	exposure_id?: string
	risk_damage_id?: string
	vulnerability_id?: string
	risk_category_id?: string
	risk_description_id?: string
	sub_step_key_id: string
}

export interface AdditionalItemResult {
	id: string
	result: number
	value_1?: number
	value_2?: number
	value_3?: number
	value_4?: number
	description?: string
	additional_item_id?: string
	additional_item_option_id_1: string
	additional_item_option_id_2: string
}

const getCustomReportResultStepKeyDefaultData = async (params: GetCustomReportResultStepKeyDefaultDataDTO) => {
	const url = '/custom-report/result/step-key/ewa-d86';
	let { data } = await Api.get<GetCustomReportResultStepKeyDefaultDataResponse>(url, {
		params
	});
	return data;
};

export const useGetCustomReportResultStepKeyDefaultData = ({
	company_id,
	organization_id,
	step_key_id,
	file_id,
}: GetCustomReportResultStepKeyDefaultDataDTO) => {
	const parameters = { organization_id, company_id, step_key_id, file_id };
	const report = useQuery<GetCustomReportResultStepKeyDefaultDataResponse, AxiosError<Response>>(
		['jds_d86_report', parameters],
		() => getCustomReportResultStepKeyDefaultData(parameters),
		{
			enabled: !!organization_id && !!company_id && !!step_key_id && !!file_id,
			retry: 0
		}
	);

	return {
		...report,
		data: report.data ?? null
	};
};
