import React, { useEffect, useState } from 'react';
import { Col, Form, InputNumber, Row, Select, Slider } from 'antd';
import { I18n } from '@aws-amplify/core';
import { v4 as uuidv4 } from 'uuid';

import { useCalculateAdditionalToolScore, useGetAdditionalItemsOptions } from '@/hooks';
import type { AdditionalItemsResults, GetAdditionalToolScore } from '@/hooks';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useStepKeysContext } from '../DefaultSelects/context';
import type { AditionalItemProps, Options } from './types';
import { fieldsUnitTexts } from './utils/FieldsUnitTexts';
import { ConditionCard } from '../../ConditionCard';
import { Text } from '@/components/Typography';

const { useWatch, useFormInstance } = Form;
const { formatLabelWithUnit } = fieldsUnitTexts;

export const SelectAndSlider: React.FC<AditionalItemProps> = ({ additionalItem }) => {
	const { id, title_1, title_2, unit_1, unit_2 } = additionalItem;

	const form = useFormInstance();
	const { formFieldName, stepKey } = useStepKeysContext();
	const { organization, company } = useApplicationContext();

	const [timer, setTimer] = useState<NodeJS.Timeout>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectStateValue, setSelectStateValue] = useState<string | undefined>(undefined);
	const [sliderStateValue, setSliderStateValue] = useState<number | undefined>(undefined);

	const selectFormValue = useWatch([
		...formFieldName,
		'additional_items',
		additionalItem.id,
		'additional_item_option_id_1'
	]);
	const sliderFormValue = useWatch([...formFieldName, 'additional_items', additionalItem.id, 'value_1']);

	const selectLabel = formatLabelWithUnit(title_1, unit_1);
	const sliderLabel = formatLabelWithUnit(title_2, unit_2);

	function handleDebounceSetValues(): void {
		clearTimeout(timer);

		const debounce = setTimeout(() => {
			setSelectStateValue(selectFormValue);
			setSliderStateValue(sliderFormValue);
			setIsLoading(false);
		}, 1200);

		setTimer(debounce);
	}

	const payload: GetAdditionalToolScore = {
		company_id: company?.id,
		value_1: sliderStateValue,
		organization_id: organization?.id,
		custom_report_step_key_id: stepKey.id,
		additional_item_option_id_1: selectStateValue
	};

	const { data: options, isLoading: isOptionsLoading } = useGetAdditionalItemsOptions({
		company_id: company?.id,
		organization_id: organization?.id,
		custom_report_step_key_id: stepKey.id
	});
	const { data: additionalToolScore, isFetching: isFetchingAdditionalToolScore } =
		useCalculateAdditionalToolScore(payload);

	useEffect(() => {
		if (selectFormValue && sliderFormValue) {
			setIsLoading(true);
			handleDebounceSetValues();
		}
	}, [selectFormValue, sliderFormValue]);

	useEffect(() => {
		const result: AdditionalItemsResults[] = [
			{
				id: uuidv4(),
				value_1: sliderStateValue,
				result: additionalToolScore?.result ?? 0,
				additional_item_option_id_1: selectStateValue
			}
		];
		form.setFieldValue([...formFieldName, 'additional_items', id, 'results'], result);
	}, [additionalToolScore]);

	function filterOption(input: string, option?: Options): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function hasSomeEmptyField(): boolean {
		return !selectFormValue || !sliderFormValue;
	}

	return (
		<Row gutter={[20, 15]}>
			<Col span={24}>
				<Row gutter={[0, 5]}>
					<Col span={24}>
						<Text strong>{selectLabel}</Text>
					</Col>
					<Col span={20}>
						<Form.Item name={[...formFieldName, 'additional_items', id, 'additional_item_option_id_1']}>
							<Select
								showSearch
								allowClear
								loading={isOptionsLoading}
								filterOption={filterOption}
								placeholder={I18n.get(title_1)}
								options={options?.map(
									({ id, description }): Options => ({
										label: I18n.get(`${description}`),
										value: id
									})
								).sort((a, b) => a.label.localeCompare(b.label))}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<Row gutter={[0, 5]}>
					<Col span={24}>
						<Text strong>{sliderLabel}</Text>
					</Col>
					<Col span={20}>
						<Row justify="space-between">
							<Col span={18}>
								<Form.Item name={[...formFieldName, 'additional_items', id, 'value_1']}>
									<Slider min={0} max={5} step={0.5} />
								</Form.Item>
							</Col>
							<Col span={4}>
								<Form.Item name={[...formFieldName, 'additional_items', id, 'value_1']}>
									<InputNumber min={0} max={5} step={0.5} placeholder="0" style={{ width: '100%' }} />
								</Form.Item>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<ConditionCard
					isLoading={isLoading || isFetchingAdditionalToolScore}
					result={hasSomeEmptyField() ? 0 : additionalToolScore.result}
				/>
			</Col>
		</Row>
	);
};
