import React from 'react';
import { Title } from '@/components/Typography';
import { Col, Row } from 'antd';

import { useLibertyMutualSubStepsContext } from '../../context';
import { TaskNameMapper } from '../../hooks/types/response';
import { LiftLowerSummary } from './LiftLowerSummary';
import { PushPullSummary } from './PushPullSummary';
import { CarrySummary } from './CarrySummary';

export const Summary = () => {
	const {
		libertyMutualResult: {
			informations: { task_name }
		}
	} = useLibertyMutualSubStepsContext();

	const taskToSummaryComponentMapper = {
		[TaskNameMapper.Lift]: <LiftLowerSummary />,
		[TaskNameMapper.Lower]: <LiftLowerSummary />,
		[TaskNameMapper.Push]: <PushPullSummary />,
		[TaskNameMapper.Pull]: <PushPullSummary />,
		[TaskNameMapper.Carry]: <CarrySummary />
	};

	return (
		<Row justify="start" gutter={[8, 8]}>
			<Col xs={24} xl={17} xxl={18}>
				<Title level={4}>Summary</Title>
			</Col>
			<Col xs={24}>{taskToSummaryComponentMapper[task_name]}</Col>
		</Row>
	);
};
