import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CardStyled, CustomSkeleton } from './styles';
import { Text } from '@/components/Typography';
import { InfoTooltip } from './InfoTooltip';

export const riskColors: { [key: number]: string } = {
	0: '#B3B3B3',
	1: '#17A93B',
	2: '#EFAC1E',
	3: '#E80707',
	4: '#000000'
};

export const ewaRiskColors: { [key: number]: string } = {
	0: '#B3B3B3',
	1: '#2CC852',
	2: '#F8D627',
	3: '#F78A38',
	4: '#E74150',
	5: '#9B54E2'
};

export const riskLabels: { [key: number]: string } = {
	0: 'Not evaluated',
	1: 'Evaluation not necessary',
	2: 'Evaluation necessary',
	3: 'Evaluation necessary',
	4: 'Evaluation necessary',
	5: 'Evaluation necessary'
};

interface ConditionCardProps {
	result?: number;
	isEwa?: boolean;
	isLoading: boolean;
}

export const ConditionCard: React.FC<ConditionCardProps> = ({ result = 0, isEwa, isLoading }) => {
	const message =
		"The risk assessment recommendation is based on the indicators in the JDS-86 guidelines. The color pattern follows the same pattern present in the document's tables.";

	function renderCard(): React.ReactNode {
		if (isLoading) {
			return (
				<Col span={20} style={{ height: '40px' }}>
					<CustomSkeleton paragraph={false} round active />
				</Col>
			);
		}

		return (
			<Col span={20}>
				<CardStyled $backgroundColor={isEwa ? ewaRiskColors[result] : riskColors[result]} risk={result}>
					{I18n.get(riskLabels[result])}
				</CardStyled>
			</Col>
		);
	}

	return (
		<Row justify="start" gutter={[0, 20]} style={{ marginTop: '10px' }}>
			<Col span={24}>
				<Text strong>{I18n.get('Condition') + ' :'}</Text>
				<InfoTooltip text={message} />
			</Col>
			{renderCard()}
		</Row>
	);
};
