import Api from '@/services/api';

import {
	CustomReportRequest,
	CustomReportStepsRequest,
	CustomReportsRequest
} from '@/components/ui/Inputs/types/request/customReport';
import { CustomReportDTO, CustomReportStepDTO } from '@/components/ui/Inputs/types/response/customReport';

export class CustomReportService {
	async getCustomReport({ organization_id, company_id, reportName }: CustomReportRequest): Promise<CustomReportDTO> {
		const url = `/custom-report/name/${reportName}?organization_id=${organization_id}&company_id=${company_id}`;
		const { data } = await Api.get<CustomReportDTO>(url);
		return data;
	}

	async getCustomReports({ organization_id, company_id }: CustomReportsRequest): Promise<CustomReportDTO[]> {
		const url = `/custom-report/list?organization_id=${organization_id}&company_id=${company_id}`;
		const { data } = await Api.get<CustomReportDTO[]>(url);
		return data;
	}

	async getCustomReportsSteps({
		organization_id,
		company_id,
		custom_report_id
	}: CustomReportStepsRequest): Promise<CustomReportStepDTO[]> {
		const url = `/custom-report/step/report/${custom_report_id}?organization_id=${organization_id}&company_id=${company_id}`;
		const { data } = await Api.get<CustomReportStepDTO[]>(url);
		return data;
	}
}
