export enum AdditionalItemTypes {
	RADIO = 'radio',
	SLIDER = 'slider',
	SELECT = 'select'
}

type SubSelectOption = {
	id: string;
	name: string;
	description: string;
};

export type CustomReportAdditionalItemOption = {
	id: string;
	name: string;
	score?: number;
	created_at: Date;
	updated_at: Date;
	deleted_at?: Date | null;
	description: string;
	type: AdditionalItemTypes;
	sub_selects?: SubSelectOption[];
	custom_report_additional_item_id: string;
};
