import { Tabs } from 'antd';
import styled from 'styled-components';

type CustomTabsProps = {
	last: number;
	showberajobresult: string;
};

export const CustomTabs = styled(Tabs)<CustomTabsProps>`
	display: flex;
	flex-direction: column;
	user-select: none;
	@media (min-width: 1024px) {
		flex-direction: row;
		justify-content: center;
	}
	.ant-tabs-nav {
		.ant-tabs-nav-wrap {
			.ant-tabs-nav-list {
				display: flex;
				flex-direction: row;
				@media (min-width: 1024px) {
					flex-direction: column;
				}
				.ant-tabs-ink-bar {
					display: none;
					.ant-tabs-ink-bar-animated {
						display: none;
					}
				}
				.ant-tabs-tab {
					cursor: ${({ showberajobresult }) => showberajobresult === 'true' && 'auto'};
					display: flex;
					justify-content: center;
					background-color: #cdd5f3;
					margin: 0;
					transition: all 0.2s;
					&:hover {
						filter: ${({ showberajobresult }) =>
							showberajobresult === 'true' ? 'brightness(1)' : 'brightness(0.9)'};
					}
					&:not(:nth-child(1))::before {
						content: '';
						display: block;
						position: absolute;
						background: white;
						height: 90%;
						width: 2px;
						top: 4%;
						left: -2%;
					}
					@media (min-width: 1024px) {
						&:not(:nth-child(1))::before {
							height: 2px;
							width: 94%;
							top: -5%;
							left: 3%;
						}
					}
					:nth-child(1) {
						border-radius: 0.5rem 0 0;
						@media (min-width: 1024px) {
							border-radius: 0.5rem 0 0;
						}
					}
					:nth-child(${(props) => props.last}) {
						border-radius: 0 0.5rem 0 0;
						@media (min-width: 1024px) {
							border-radius: 0 0 0 0.5rem;
						}
					}
					:nth-child(1):nth-child(${(props) => props.last}) {
						border-radius: 0.5rem 0.5rem 0 0;
						@media (min-width: 1024px) {
							border-radius: 0.5rem 0 0 0.5rem;
						}
					}
					.ant-tabs-tab-btn {
						color: #2f54eb;
						font-weight: 600;
						max-width: 4rem;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
				.ant-tabs-tab-active {
					transition: all 0.2s;
					&:hover {
						filter: brightness(1);
					}
					background-color: #2f54eb;
					.ant-tabs-tab-btn {
						color: #fff;
					}
				}
			}
		}
	}

	.ant-tabs-content-holder {
		max-width: 100%;
		background-color: #f8f8f8;
		border-radius: 0 1rem 1rem 1rem;

		.ant-tabs-content {
			padding: 2rem 0 2rem 1rem;
			.ant-tabs-tabpane {
			}
		}
	}
`;
