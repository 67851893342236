import { Topic } from './styles';
import { I18n } from '@aws-amplify/core'
import { Col, Form, Row, Select } from 'antd'
import React from 'react'

type Props = {
	options: { value: number, label: string }[],
	onChange: (id: number) => void;
}

export const ReviewSelect = ({ onChange, options }: Props) => {
	return (
		<Col>
			<Row align={'middle'}>
				<Col style={{ marginRight: '0.25rem' }}>
					<Topic>{I18n.get('Version')}</Topic>
				</Col>
				<Col>
					<Form.Item style={{ margin: 0, minWidth: 200, width: '100%' }} name="review" >
						<Select onChange={onChange} options={options} defaultValue={options[options.length - 1].value} />
					</Form.Item>
				</Col>
			</Row>
		</Col>
	)
}
