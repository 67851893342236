import React from 'react';
import { Row, Col } from 'antd';
import { useParams } from 'react-router-dom';

import { useApplicationContext } from '@/context/v1/Application/context';
import { useGetCustomReportByName, useGetCustomReport } from '@/hooks';
import { CUSTOM_REPORT_NAME } from '@/utils/customReport';
import { LoadingSkeleton } from './LoadingSkeleton';
import { EwaRouteParams } from './context/types';
import { Text } from '@/components/Typography';
import { EwaProvider } from './context';
import { Report } from './Report';

export const Ewa: React.FC = () => {
	const { file_id } = useParams<EwaRouteParams>();
	const { organization, company } = useApplicationContext();

	const {
		data: customReport,
		isError: errorGettingCustomReportByName,
		isFetching: isFetchingCustomReportByName
	} = useGetCustomReportByName({
		company_id: company?.id,
		organization_id: organization?.id,
		report_name: CUSTOM_REPORT_NAME.EWA
	});

	const {
		data: ewa,
		isLoading,
		isError,
		isFetching
	} = useGetCustomReport({
		file_id,
		company_id: company?.id,
		custom_report_id: customReport.id,
		organization_id: organization?.id
	});

	if (isError || errorGettingCustomReportByName) {
		return <Text>Oops... Something went wrong!</Text>;
	}

	if (isLoading || isFetching || isFetchingCustomReportByName) {
		return <LoadingSkeleton />;
	}

	return (
		<Row>
			<Col xs={24}>
				<EwaProvider ewa={ewa} customReport={customReport}>
					<Report />
				</EwaProvider>
			</Col>
		</Row>
	);
};
