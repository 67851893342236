import { FrequencyTimeFormatMapper, HandCouplingMapper, TaskMapper } from '../../context/types';

export enum HandCouplingDTO {
	'GOOD' = 'GOOD',
	'FAIR' = 'FAIR',
	'POOR' = 'POOR'
}

export enum TaskName {
	'lift' = 'lift',
	'lower' = 'lower',
	'push' = 'push',
	'pull' = 'pull',
	'carry' = 'carry'
}

export enum TaskNameMapper {
	'Lift' = 'Lift',
	'Lower' = 'Lower',
	'Push' = 'Push',
	'Pull' = 'Pull',
	'Carry' = 'Carry'
}

export enum FrequencyTimeFormatDTO {
	'MINUTE' = 'MINUTE',
	'HOUR' = 'HOUR'
}

export enum SystemOfUnitsName {
	'imperial' = 'imperial',
	'metric' = 'metric'
}

export enum SystemOfUnitsDescription {
	'Imperial' = 'Imperial',
	'Metric' = 'Metric'
}

export type CommonFields = {
	frequency: number;
	frequency_time_format: FrequencyTimeFormatDTO;
};

export type SystemOfUnitsDTO = {
	id: string;
	name: SystemOfUnitsName;
	description: SystemOfUnitsDescription;
};

export type LiftLowerFields = {
	object_weight: number;
	end_hand_height: number;
	end_hand_distance: number;
	start_hand_height: number;
	start_hand_distance: number;
	hand_coupling: HandCouplingDTO;
};

export type PushPullFields = {
	initial_force: number;
	sustained_force: number;
	horizontal_distance: number;
	vertical_hand_height: number;
};

export type CarryFields = {
	object_weight: number;
	horizontal_distance: number;
	vertical_hand_height: number;
};

export type LibertyMutualTaskDTO = {
	id: string;
	name: TaskName;
	sequence: number;
	description: string;
	plural_description: string;
};

export type LibertyMutualInputs = CommonFields & (LiftLowerFields | PushPullFields | CarryFields);

export type LibertyMutualReportDTO = {
	id: string;
	step: number;
	line_id: string;
	comment: string;
	duration: string;
	line_name: string;
	sector_id: string;
	company_id: string;
	sector_name: string;
	uploaded_date: Date;
	company_name: string;
	original_name: string;
	collection_date: Date;
	assessment_date: Date;
	workstation_id: string;
	percentile_man?: string;
	organization_id: string;
	workstation_name: string;
	percentile_woman?: string;
	organization_name: string;
	task: LibertyMutualTaskDTO;
	percentile_man_initial?: string;
	percentile_man_sustain?: string;
	percentile_woman_initial?: string;
	percentile_woman_sustain?: string;
	system_of_units: SystemOfUnitsDTO;
	liberty_mutual_inputs: LibertyMutualInputs;
};

export type SummaryFrequency = {
	value: number;
	type: TaskMapper;
	interval: FrequencyTimeFormatMapper;
};

export type Informations = {
	duration: string;
	line_name: string;
	sector_name: string;
	company_name: string;
	original_name: string;
	collection_date: Date;
	assessment_date: Date;
	workstation_name: string;
	task_name: TaskNameMapper;
	organization_name: string;
};

export type Summary = {
	unit: string;
	object_weight: string;
	initial_force: string;
	sustained_force: string;
	end_hand_height: string;
	start_hand_height: string;
	end_hand_distance: string;
	horizontal_distance: string;
	start_hand_distance: string;
	frequency: SummaryFrequency;
	vertical_hand_height: string;
	hand_coupling: HandCouplingMapper;
	system_of_units: SystemOfUnitsName;
};

export type Results = {
	percentile_man?: string;
	percentile_woman?: string;
	percentile_man_initial?: string;
	percentile_man_sustain?: string;
	percentile_woman_initial?: string;
	percentile_woman_sustain?: string;
};

export type LibertyMutualResultDTO = {
	id: string;
	comment: string;
	results: Results;
	summary: Summary;
	informations: Informations;
};
