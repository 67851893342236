import React from 'react';
import { Button, Tooltip } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SyncOutlined } from '@ant-design/icons';

import { useApplicationContext } from '@/context/v1/Application/context';
import { onChangeProjectSelected } from '@/redux/organization/actions';

export const ProjectSelect: React.FC = () => {
	const { organization, company } = useApplicationContext();
	const dispatch = useDispatch();
	const history = useHistory();

	const { clearProject } = useApplicationContext();

	function handleOnClick(): void {
		history.push('/');
		dispatch(onChangeProjectSelected());
		clearProject();
	}

	const DealingProject = () => {
		if (organization?.name && company?.name) {
			return `${organization?.name} / ${company?.name}`;
		}

		if (organization?.name) {
			return `${organization?.name}`;
		}

		return I18n.get('Select project');
	};

	return (
		<Tooltip title={I18n.get('Select company')}>
			<Button type="primary" onClick={handleOnClick} ghost>
				<DealingProject />
				<SyncOutlined style={{ paddingLeft: '5px' }} />
			</Button>
		</Tooltip>
	);
};
