import React from 'react';
import { BackCompressiveForceEstimationProvider } from './context';
import { Report } from './Report';

export function BackCompressiveForceEstimation() {
	return (
		<BackCompressiveForceEstimationProvider>
			<Report />
		</BackCompressiveForceEstimationProvider>
	);
}
