import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Button, Col, Row } from 'antd';
import { PreviousCol } from './styles';

interface FooterProps {
	loading: boolean;
	disabled: boolean;
	totalSteps: number;
	currentStep: number;
	onNextClick: () => void;
	onPreviousClick: () => void;
}

export const Footer: React.FC<FooterProps> = ({
	loading,
	disabled,
	totalSteps,
	currentStep,
	onNextClick,
	onPreviousClick
}) => {
	if (currentStep === totalSteps) {
		return <></>;
	}

	if (currentStep > 0) {
		return (
			<Col xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
				<Row justify="center" align="middle" gutter={[0, 8]}>
					<PreviousCol>
						<Button loading={loading} type="default" onClick={onPreviousClick}>
							{I18n.get('Previous')}
						</Button>
					</PreviousCol>
					<Col>
						<Button
							htmlType="submit"
							loading={loading}
							disabled={disabled}
							type="primary"
							onClick={onNextClick}
						>
							{I18n.get('Next')}
						</Button>
					</Col>
				</Row>
			</Col>
		);
	}

	return (
		<Col xs={24}>
			<Row justify="center">
				<Col>
					<Button htmlType="submit" loading={loading} type="primary" onClick={onNextClick}>
						{I18n.get('Next')}
					</Button>
				</Col>
			</Row>
		</Col>
	);
};
