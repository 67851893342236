import { useMutation } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { notification } from 'antd';
import { AxiosError } from 'axios';
import { Moment } from 'moment';

import type { BaseContext, Response } from '@/types';
import Api from '@/services/api';

export enum CreateREBAStatus {
	SUCCESS = 'success',
	FAILED = 'failed'
}

export type CreateRebaResponse = {
	status: CreateREBAStatus;
	message: string;
};

export type CreateREBADTO = BaseContext & {
	force: number;
	comment: string;
	coupling: number;
	file_id?: string;
	sector_id?: string;
	repetition: number;
	workstation_id?: string;
	collection_date: Moment;
};

async function createReport<T, Body>(parameters: Body): Promise<T> {
	const { data } = await Api.post('/ergonomic-tool/reba', parameters);
	return data;
}

export const useCreateREBA = () => {
	return useMutation<CreateRebaResponse, AxiosError<Response>, CreateREBADTO>(
		(body) => createReport<CreateRebaResponse, CreateREBADTO>(body),
		{
			onError: (err) => {
				const message = 'Oops... Something went wrong!';
				const description = err.response?.data?.message ?? 'Failed to create information';
				notification.error({
					message: I18n.get(message),
					description: I18n.get(description)
				});
			}
		}
	);
};
