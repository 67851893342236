import React, { ReactElement } from 'react';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { I18n } from '@aws-amplify/core';
import { Col } from 'antd';

import { RequiredLabel } from './RequiredLabel';
import { CustomFormInputs } from './styles';
import { NamePath } from 'antd/lib/form/interface';

interface ChildrenProps {
	size: SizeType;
	placeholder?: string;
}

interface FormItemProps {
	title: string;
	fontSize?: string;
	namePath: NamePath;
	isRequired?: boolean;
	errorMessage?: string;
	children: ReactElement<ChildrenProps>;
}

export const FormItem: React.FC<FormItemProps> = ({
	title,
	namePath,
	fontSize,
	children,
	errorMessage,
	isRequired = true
}) => {
	const error = errorMessage ?? `Select the ${title}`;
	return (
		<Col xs={24}>
			<CustomFormInputs
				fontSize={fontSize}
				name={namePath}
				label={<RequiredLabel title={I18n.get(title)} isRequired={isRequired} />}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: isRequired,
						message: I18n.get(error)
					}
				]}
			>
				{children}
			</CustomFormInputs>
		</Col>
	);
};
