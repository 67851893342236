import styled from 'styled-components';
import { Typography } from 'antd';

export const TypographyTitle = styled(Typography.Title)`
	color: #262626;
`;

export const TypographyText = styled(Typography.Text)`
	color: #262626;
`;

export const TypographyParagraph = styled(Typography.Paragraph)`
	color: #262626;
`;
