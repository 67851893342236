import React from 'react';
import { CheckOutlined, CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useApplicationContext } from '@/context/v1/Application/context';
import { Confirm } from './ManageCategoryModal/Manager/styles';
import { UpdateCategoryRequest } from './hooks/types/request';
import { CategoryResponse } from './hooks/types/response';
import { useUpdateCategory } from './hooks';

const { useForm, useWatch } = Form;

type EditCategoryProps = {
	category: CategoryResponse;
	categories: CategoryResponse[];
	onEditing: (isEditing: boolean) => void;
};

export const EditCategory: React.FC<EditCategoryProps> = ({ onEditing, category, categories }) => {
	const [form] = useForm();

	const { organization, company } = useApplicationContext();
	const { mutateAsync: updateCategory, isLoading } = useUpdateCategory();

	const categoryName = useWatch(['editing', 'risk_category_name'], form);

	async function handleUpdateCategory(body: UpdateCategoryRequest) {
		if (categories.find((elem) => elem.description === body.description)) {
			const content = I18n.get(`This name already exists. Do you wish to proceed ?`);
			Modal.confirm({
				title: I18n.get('Warning!'),
				icon: <ExclamationCircleOutlined />,
				content: content,
				okType: 'danger',
				okText: I18n.get('Yes'),
				cancelText: I18n.get('Cancel'),
				onOk: async () => {
					const updated = await updateCategory(body);
					if (updated.id) {
						onEditing(false);
					}
				}
			});
		} else {
			const updated = await updateCategory(body);
			if (updated.id) {
				onEditing(false);
			}
		}
	}

	function handleCancel() {
		onEditing(false);
	}

	function handleSubmit() {
		handleUpdateCategory({
			id: category?.id,
			company_id: company?.id,
			description: categoryName,
			organization_id: organization?.id
		});
		onEditing(false);
	}

	const initialValues = {
		editing: {
			risk_category_name: category.description
		}
	};

	return (
		<Form
			form={form}
			initialValues={initialValues}
			style={{
				width: '100%',
				margin: '0'
			}}
		>
			<Row align="middle" justify="center" style={{ width: '100%', position: 'relative', left: '24px' }}>
				<Col span={16} style={{ marginRight: '0.66rem' }}>
					<Form.Item
						name={['editing', 'risk_category_name']}
						style={{ margin: '0' }}
						rules={[
							{
								required: true,
								message: I18n.get('Please enter a valid risk category name')
							}
						]}
					>
						<Input
							style={{
								flex: 'auto',
								textAlign: 'center'
							}}
							placeholder={I18n.get('Risk category name')}
						/>
					</Form.Item>
				</Col>
				<Col span={2} style={{ marginRight: '0.66rem' }}>
					<Button
						size="small"
						type="primary"
						ghost={true}
						danger={true}
						onClick={() => handleCancel()}
						icon={<CloseOutlined />}
					/>
				</Col>
				<Confirm span={2}>
					<Button
						size="small"
						type="primary"
						ghost={true}
						htmlType="submit"
						icon={<CheckOutlined />}
						onClick={() => handleSubmit()}
						disabled={!categoryName || category.description === categoryName || isLoading}
					/>
				</Confirm>
			</Row>
		</Form>
	);
};
