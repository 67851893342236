import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useApplicationContext } from '@/context/v1/Application/context';
import { CompanySelect } from '@/components/ui/Inputs/CompanySelect';
import { useListCompanies } from '@/hooks/v1/useListCompanies';

const { useFormInstance, useWatch } = Form;

export const Companies: React.FC = () => {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();
	const { organization, company } = useApplicationContext();

	const searchingOrganizationId = useWatch('searching_organization_id', form);

	const companyId = useWatch('company_id_filter', form);
	const {
		data: companies,
		isLoading,
		isFetching,
		isError
	} = useListCompanies({
		company_id: company?.id,
		organization_id: organization?.id,
		searching_organization_id: searchingOrganizationId
	});

	function handleCompanySelect(id: string) {
		setFieldValue(['company_id_filter'], id);
		setFieldValue(['sector_id'], undefined);
		setFieldValue(['line_id'], undefined);
		setFieldValue(['workstation_id'], undefined);
		setFieldValue(['cycle_id'], undefined);
	}

	function handleClearCompany() {
		setFieldValue(['company_id_filter'], undefined);
		setFieldValue(['sector_id'], undefined);
		setFieldValue(['line_id'], undefined);
		setFieldValue(['workstation_id'], undefined);
		setFieldValue(['cycle_id'], undefined);
	}

	return (
		<Form.Item name={['company_id_filter']} label={I18n.get('Industrial site')} labelCol={{ span: 24 }}>
			<CompanySelect
				companies={companies}
				loading={isFetching}
				companyId={companyId}
				handleClearCompany={handleClearCompany}
				handleSelectCompany={handleCompanySelect}
				disabled={isLoading || isFetching || isError || !searchingOrganizationId}
			/>
		</Form.Item>
	);
};
