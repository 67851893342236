import React, { useEffect, useState, useContext } from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Row } from 'antd';

import { Title } from '@/components/Typography';

import { DescriptionTable } from './DescriptionTable';
import { WorkConditionsType } from '../types';
import { ResultsContext } from '../context';

export const WorkConditions = () => {
	const { work_conditions } = useContext(ResultsContext);
	const [showDescriptionTable, setShowDescriptionTable] = useState(false);

	function WorkScheduleText(workConditions: WorkConditionsType) {
		const work_hours = workConditions?.hours_work_schedule;
		const work_minutes = workConditions?.minutes_work_schedule;

		let message = `${work_hours} ${I18n.get('hours')}`;

		if (work_minutes !== 0) {
			message = `${work_hours} ${I18n.get('hours')} ${I18n.get('and')} ${work_minutes} ${I18n.get('minutes')}`;
		}

		return message;
	}

	useEffect(() => {
		setShowDescriptionTable(true);
	}, []);

	return (
		<Col offset={1}>
			<Row gutter={[0, 24]}>
				<Col span={23}>
					<Row>
						<Title style={{ margin: 0 }} level={3}>
							Work Conditions
						</Title>
					</Row>
				</Col>
				<Col offset={1} span={22}>
					<Row>
						<Col>
							<Title style={{ margin: 0 }} level={4}>
								{I18n.get('Hours worked in the day')}:
							</Title>
						</Col>
						<Col>
							<Title style={{ margin: 0, marginLeft: 5, fontWeight: 500 }} level={4}>
								{WorkScheduleText(work_conditions)}
							</Title>
						</Col>
					</Row>
				</Col>
				{showDescriptionTable && (
					<Col flex={1}>
						<DescriptionTable
							placeDescription={work_conditions?.place_description}
							expectedTaskDescription={work_conditions?.expected_task_description}
							performedTaskDescription={work_conditions?.performed_task_description}
						/>
					</Col>
				)}
			</Row>
		</Col>
	);
};
