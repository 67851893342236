import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import { Form, Modal } from 'antd';

import { DeleteBaseRequest, HierarchyEnum, CompanyResponse, UpdateBaseRequest } from '../hooks/types';
import { useApplicationContext } from '@/context/v1/Application/context';
import { CompanySelect } from '@/components/ui/Inputs/CompanySelect';
import { useDelete, useGetCompanies, useUpdate } from '../hooks';
import { useManageOrganizationContext } from '../context';
import { CompanyManager } from './CompanyManager';

const { useFormInstance, useWatch } = Form;

export const ManageCompany = () => {
	const form = useFormInstance();
	const { company, organization, setCompany } = useApplicationContext();
	const { isEditing, organizationId, handleIsEditing, treatFieldValidation } = useManageOrganizationContext();

	const companyId = useWatch(['company_id'], form);

	const {
		data: companies,
		isLoading: gettingCompanies,
		isFetching: fetchingCompanies,
		isError: errorGettingCompanies
	} = useGetCompanies({
		company_id: company?.id,
		organization_id: organization?.id,
		searching_organization_id: organizationId
	});
	const { mutateAsync: deleteData } = useDelete<DeleteBaseRequest, CompanyResponse>();
	const { mutateAsync: updateData } = useUpdate<UpdateBaseRequest, CompanyResponse>();

	const currentName = companies?.find(({ id }) => id === companyId)?.name;

	async function handleUpdateName() {
		try {
			const values = form.getFieldValue(['editing']);
			if (values) {
				const body: UpdateBaseRequest = {
					id: values?.currentId,
					company_id: values?.currentId,
					name: values.company_name,
					organization_id: organizationId,
					hierarchy_field: HierarchyEnum.company
				};
				const updated = await updateData(body);
				if (updated.id) {
					setCompany(updated);
					handleIsEditing(false);
					form.setFieldValue('company_id', updated.id);
					form.setFieldValue(['editing', 'line_name'], undefined);
				}
			}
		} catch (error: any) {
			treatFieldValidation(error);
		}
	}

	async function handleDeleteCompany() {
		Modal.confirm({
			title: I18n.get('Warning!'),
			content: I18n.get(
				'Heads up! Want to delete this industrial site? All sectors, lines and workstations related to it will be lost.'
			),
			icon: <ExclamationCircleOutlined />,
			okText: I18n.get('Confirm'),
			cancelText: I18n.get('Cancel'),
			okType: 'danger',
			onOk: async () => {
				const body: DeleteBaseRequest = {
					id: companyId,
					company_id: companyId,
					organization_id: organizationId,
					hierarchy_field: HierarchyEnum.company
				};
				const updated = await deleteData(body);
				if (updated.id) {
					handleClearCompany();
					handleIsEditing(false);
					form.setFieldValue(['editing', 'company_name'], undefined);
				}
			}
		});
	}

	function handleCompanySelect(id: string) {
		form.setFieldValue(['company_id'], id);
		form.setFieldValue(['line_id'], undefined);
		form.setFieldValue(['sector_id'], undefined);
		form.setFieldValue(['workstation_id'], undefined);
	}

	function handleClearCompany() {
		form.setFieldValue('line_id', undefined);
		form.setFieldValue('sector_id', undefined);
		form.setFieldValue('company_id', undefined);
		form.setFieldValue('workstation_id', undefined);
	}

	return (
		<CompanyManager
			key={organizationId}
			currentId={companyId}
			currentName={currentName}
			parentId={organizationId}
			fieldName={['company_id']}
			onDelete={handleDeleteCompany}
			onUpdateName={handleUpdateName}
			fieldLabel={'an industrial site'}
			allowDeletion={companies?.length > 1}
			editingNameField={['editing', 'company_name']}
		>
			<CompanySelect
				size="middle"
				companies={companies}
				companyId={companyId}
				fieldName={['company_id']}
				handleClearCompany={handleClearCompany}
				handleSelectCompany={handleCompanySelect}
				loading={isEditing || fetchingCompanies}
				disabled={errorGettingCompanies || gettingCompanies || fetchingCompanies || isEditing}
			/>
		</CompanyManager>
	);
};
