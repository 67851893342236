import React, { useEffect, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Divider, Row, Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';

import Hooks from '../hooks';
import { Text } from '@/components/Typography';
import { WorkstationScore } from './WorkstationScore';
import { SubTitle, Title, WorkstationScoreTitle } from './styles';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useDashboardContext } from '../context';

const { useDashboardWorkstations } = Hooks;

export const WorkstationsCard: React.FC = () => {
	const { queryParams } = useDashboardContext();

	const [offset, setOffset] = useState(0);
	const { organization, company } = useApplicationContext();
	const { isLoading, data, isError, isSuccess, fetchNextPage, hasNextPage, isFetchingNextPage } =
		useDashboardWorkstations(organization.id, company.id, queryParams, offset);

	useEffect(() => {
		if (data.pages?.length > 0) {
			setOffset(data.pages.length - 1);
		}
	}, []);

	async function loadMoreData() {
		if (hasNextPage) {
			await fetchNextPage();
			setOffset(offset + 1);
		}
	}

	if (isLoading) {
		return <Text>{I18n.get('Loading')}...</Text>;
	}

	if (isError) {
		return <Text>Oops... Something went wrong!</Text>;
	}

	return (
		<>
			<Row>
				<Col>
					<Title>{I18n.get('Workstations')}</Title>
				</Col>
			</Row>
			<Row gutter={[0, 8]}>
				<Col>
					<SubTitle>{I18n.get('Higher workstation risk score')}</SubTitle>
				</Col>
			</Row>
			<Row justify="end" style={{ marginBottom: '0.66rem' }}>
				<Col flex={0.01}>
					<WorkstationScoreTitle>{I18n.get('Workstation score')}:</WorkstationScoreTitle>
				</Col>
			</Row>
			{isError ? (
				<span>{I18n.get('Oops... Something went wrong!')}</span>
			) : (
				<Col id="workstationsList" span={24} style={{ overflowY: 'auto' }}>
					{data.pages && data.pages[0].length > 0 ? (
						<Row style={{ maxHeight: '350px' }}>
							<Col span={24}>
								{isSuccess &&
									data.pages.map(
										(workstations) =>
											workstations.length > 0 && (
												<InfiniteScroll
													key={workstations[0].id}
													dataLength={workstations.length}
													next={loadMoreData}
													hasMore={
														!isFetchingNextPage && hasNextPage !== undefined
															? hasNextPage
															: true
													}
													loader={<Skeleton paragraph={{ rows: 1 }} active />}
													scrollableTarget="workstationsList"
												>
													<Row align="middle" justify="center">
														{workstations.map((workstation) => {
															return (
																<Col span={24} key={workstation.id}>
																	<WorkstationScore workstation={workstation} />
																</Col>
															);
														})}
													</Row>
												</InfiniteScroll>
											)
									)}
							</Col>
							{!hasNextPage && <Divider plain>{I18n.get('No more workstations available')}</Divider>}
						</Row>
					) : (
						<Row justify="center">
							<Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
								{I18n.get('No data available')}
							</Col>
						</Row>
					)}
				</Col>
			)}
		</>
	);
};
