import React from 'react';
import { Row, Col, Button } from 'antd';
import { I18n } from '@aws-amplify/core';
import { DeleteOutlined } from '@ant-design/icons';

import { Paragraph } from '@/components/Typography';
import type { SelectedReport } from '../types';
import * as S from './styles';

interface ModalDeleteProps {
	open: boolean;
	report?: SelectedReport;
	onDeleteReport: (type: string) => void;
	onCancel: () => void;
}

export function ModalDelete({ open, report, onCancel, onDeleteReport }: ModalDeleteProps) {
	function handleOnDeleteConfirm(): void {
		onDeleteReport(report?.id as string);
	}

	return (
		<S.ModalStyled width={500} footer={null} open={open} onCancel={onCancel} centered>
			<Row justify="center">
				<DeleteOutlined
					style={{
						color: '#E74150',
						fontSize: 70
					}}
				/>
			</Row>
			<Row justify="center">
				<Col span={20} style={{ marginTop: 10 }}>
					<Paragraph className="paragraph-modal" style={{ fontSize: '24px', textAlign: 'center' }}>
						{I18n.get(`Are you sure you want to delete the evaluation`)}{' '}
						<strong>"{report?.report_name}"</strong> ?
					</Paragraph>
				</Col>
			</Row>
			<Row justify="center">
				<Button key="back" onClick={onCancel} style={{ marginRight: 10 }}>
					{I18n.get('Cancel')}
				</Button>
				<Button type="primary" onClick={handleOnDeleteConfirm} style={{ marginLeft: 10 }}>
					{I18n.get('Confirm')}
				</Button>
			</Row>
		</S.ModalStyled>
	);
}
