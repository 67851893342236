import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { WorkstationSelect } from '@/components/ui/Inputs/WorkstationSelect';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useWorkstations } from '@/hooks/v1';

const { useFormInstance, useWatch } = Form;

interface WorkstationsProps {
	onValueChange: (changed: boolean) => void;
}

export const Workstations: React.FC<WorkstationsProps> = ({ onValueChange }) => {
	const form = useFormInstance();
	const { company, organization } = useApplicationContext();

	const lineId = useWatch('line_id', form);
	const workstationId = useWatch('workstation_id', form);

	const {
		data: workstations,
		isLoading,
		isFetching,
		isError
	} = useWorkstations({
		organization_id: organization?.id,
		company_id: company?.id,
		line_id: lineId
	});

	function handleWorkstationSelect(id: string) {
		onValueChange(true);
		form.setFieldValue(['workstation_id'], id);
	}

	function handleClearWorkstations() {
		form.setFieldValue(['workstation_id'], undefined);
	}

	return (
		<Col xs={24} sm={12}>
			<Form.Item
				name={['workstation_id']}
				label={I18n.get('Workstation')}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: true,
						message: I18n.get('Select the workstation')
					}
				]}
			>
				<WorkstationSelect
					lineId={lineId}
					loading={isFetching}
					fieldName="workstation_id"
					workstations={workstations}
					workstationId={workstationId}
					handleSelectWorkstation={handleWorkstationSelect}
					handleClearWorkstations={handleClearWorkstations}
					disabled={isLoading || isFetching || isError || !lineId}
				/>
			</Form.Item>
		</Col>
	);
};
