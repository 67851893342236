import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form, Row, Select } from 'antd';

import { StatusFile } from '@/utils/enum';

type OptionType = {
	label: string;
	value: string;
};

export const Status: React.FC = () => {
	const options: OptionType[] = [
		{
			label: I18n.get('Corrupted file'),
			value: StatusFile.CORRUPTED_FILE
		},
		{
			label: I18n.get('In queue'),
			value: StatusFile.IN_QUEUE
		},
		{
			label: I18n.get('Not processing'),
			value: StatusFile.NOT_PROCESSED
		},
		{
			label: I18n.get('Processing'),
			value: StatusFile.PROCESSING
		},
		{
			label: I18n.get('Processed'),
			value: StatusFile.PROCESSED
		}
	];

	return (
		<Col sm={4}>
			<Row>
				<Col xs={24}>
					<Form.Item label="Status" name="status" labelCol={{ span: 24 }}>
						<Select mode="multiple" allowClear placeholder={I18n.get('Status')} options={options} />
					</Form.Item>
				</Col>
			</Row>
		</Col>
	);
};
