import React from 'react';
import { Form, Input, Checkbox, Button, Alert } from 'antd';
import { useDispatch } from 'react-redux';

import { AuthUserDTO, AuthUserRequest } from '@/types/AuthUser';
import { setSession } from '@/redux/auth/actions';
import { useAuth } from '@/hooks/v1/useAuth';
import * as Icons from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import Logo from '@/assets/img/logo.png';
import history from '@/store/history';

import { TwoFactorAuthenticatorModal } from './TwoFactorAuthenticatorModal';
import { Force2FAWarning } from './TwoFactorAuthenticator/Force2FAWarning';
import { RecoveryTokens } from './TwoFactorAuthenticator/RecoveryTokens';
import { Validate2FA } from './TwoFactorAuthenticator/Validate2FA';
import { TwoFactorAuthenticator } from './TwoFactorAuthenticator';
import { Container, ImageBox, ContainerAlerts } from './styles';
import { Check2FA } from './TwoFactorAuthenticator/Check2FA';
import { Reset2FA } from './TwoFactorAuthenticator/Reset2FA';
import { useCheck2FAContext } from './context';

export const LoginContent = () => {
	const dispatch = useDispatch();

	const { getSession, session } = useAuth();
	const { openCheck2FAModal, openReset2FAModal, openValidate2FAModal, openRecoveryTokenModal, openForce2FAWarning } =
		useCheck2FAContext();
	const {
		handleTypeChange,
		handleEnabledQRCodeChange,
		handleUserSessionChange,
		handleOpenCheck2FAModal,
		handleOpenForce2FAWarning
	} = useCheck2FAContext();

	async function onFinish({ email, password, remember }: AuthUserRequest) {
		const userSession = await getSession(email, password, remember);
		handleUserSessionChange(userSession);

		if (is2FALoginValidated(userSession)) {
			handleEnabledQRCodeChange(userSession.two_fa.qr_code_auth_enabled);
			handleTypeChange(userSession.two_fa.type);
			handleOpenCheck2FAModal(true);
		} else if (forced2FAIsEnabled(userSession)) {
			handleOpenForce2FAWarning(true);
		} else {
			localStorage.setItem(
				'info_user',
				JSON.stringify({
					user: userSession.user,
					token: userSession.token,
					refresh_token: userSession.refresh_token
				})
			);
			dispatch(
				setSession({
					user: userSession.user,
					token: userSession.token,
					refresh_token: userSession.refresh_token
				})
			);
			history.push('/');
		}
	}

	function is2FALoginValidated(userSession: AuthUserDTO) {
		return userSession?.two_fa?.is_2fa_login_enabled && userSession?.two_fa?.validated;
	}

	function forced2FAIsEnabled(userSession: AuthUserDTO) {
		return !!userSession.user.force_two_fa;
	}

	return (
		<Container>
			<ImageBox>
				<img alt="logo" src={Logo} />
			</ImageBox>
			{session.error && (
				<ContainerAlerts>
					<Alert
						showIcon
						type="error"
						style={{ textAlign: 'center' }}
						message={I18n.get(session.error.response?.data.message || session.error.message)}
					/>
				</ContainerAlerts>
			)}
			<Form onFinish={onFinish} initialValues={{ remember: true }}>
				<Form.Item
					name="email"
					hasFeedback
					rules={[
						{
							type: 'email',
							message: I18n.get('Enter a valid email')
						},
						{
							required: true,
							message: I18n.get('Enter your email')
						}
					]}
				>
					<Input placeholder="E-mail" prefix={<Icons.UserOutlined className="site-form-item-icon" />} />
				</Form.Item>
				<Form.Item
					name="password"
					hasFeedback
					rules={[
						{
							required: true,
							message: I18n.get('Enter your password')
						}
					]}
				>
					<Input.Password
						placeholder={I18n.get('Password')}
						prefix={<Icons.LockOutlined />}
						iconRender={(visible) => (visible ? <Icons.EyeTwoTone /> : <Icons.EyeInvisibleOutlined />)}
					/>
				</Form.Item>
				<Form.Item>
					<Form.Item name="remember" valuePropName="checked" noStyle>
						<Checkbox style={{ float: 'left' }}>{I18n.get('Remember me')}</Checkbox>
					</Form.Item>
					<a style={{ float: 'right' }} href="/forgot-password">
						{I18n.get('Forgot password')}
					</a>
				</Form.Item>
				<Form.Item>
					<Button type="primary" htmlType="submit" loading={session.isLoading} style={{ width: '100%' }}>
						{I18n.get('Login')}
					</Button>
				</Form.Item>
			</Form>
			<TwoFactorAuthenticatorModal>
				<TwoFactorAuthenticator>
					{openForce2FAWarning && <Force2FAWarning />}
					{openCheck2FAModal && <Check2FA />}
					{openReset2FAModal && <Reset2FA />}
					{openValidate2FAModal && <Validate2FA />}
					{openRecoveryTokenModal && <RecoveryTokens />}
				</TwoFactorAuthenticator>
			</TwoFactorAuthenticatorModal>
		</Container>
	);
};
