import React from 'react';
import { AntIcon, SpinnerLoading } from './styles';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 38 }} spin />;

const Spinner = () => {
	const style = {
		position: 'fixed',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)'
	};
	return (
		<div style={style}>
			<Spin indicator={antIcon} />
		</div>
	);
};

export default function Spinner2() {
	return (
		<SpinnerLoading indicator={<AntIcon size={38} spin />} />
	);
}

export { Spinner };
