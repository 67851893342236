import React, { useState, useEffect } from 'react';
import { Row, Col, Form, message, Input } from 'antd';
import _ from 'lodash';

import { useApplicationContext } from '@/context/v1/Application/context';
import { UpdateSeraSummaryRequest } from '../hooks/types/request';
import { useSeraStepsContext } from '../context';
import { Title } from '@/components/Typography';
import { useComments } from './hooks/hooks';

const { TextArea } = Input;

interface CommentProps {
	onCommentChange: (editingComment: boolean) => void;
}

export const Comment: React.FC<CommentProps> = ({ onCommentChange }) => {
	const [text, setText] = useState<string | null>(null);
	const { seraSummaryId } = useSeraStepsContext();
	const { organization, company } = useApplicationContext();

	const [, contextHolder] = message.useMessage();
	const { mutate: updateComment, isLoading } = useComments();

	const setComment = _.debounce((value) => {
		onCommentChange(true);
		setText(value);
	}, 1200);

	function onChange(value: string) {
		setComment(value);
	}

	function onSettled() {
		onCommentChange(false);
	}

	useEffect(() => {
		if (text !== null) {
			const parameters: UpdateSeraSummaryRequest = {
				organization_id: organization.id,
				company_id: company.id,
				id: seraSummaryId,
				comment: text
			};

			updateComment(parameters, {
				onSettled
			});
		}
	}, [text]);

	return (
		<Row gutter={[0, 24]}>
			<Col offset={1} span={23}>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Title style={{ margin: 0 }} level={3}>
							Comment
						</Title>
						{contextHolder}
					</Col>
					<Col span={16}>
						<Form.Item name="comment" labelCol={{ span: 24 }}>
							<TextArea
								rows={4}
								maxLength={500}
								showCount={true}
								onChange={(event) => onChange(event.target.value)}
								disabled={isLoading}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
