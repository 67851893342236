import React from 'react';
import { Pie } from 'react-chartjs-2';
import { diagnosticColors } from '@/constants/Reba';
import type { AnglesType } from './types';

interface PiechartProps {
	scores: AnglesType;
}

export function PieChart({ scores }: Readonly<PiechartProps>) {
	const data = {
		datasets: [
			{
				data: scores,
				borderWidth: 1,
				datalabels: {
					display: false
				},
				borderColor: diagnosticColors,
				backgroundColor: diagnosticColors
			}
		]
	};

	return <Pie data={data} />;
}
