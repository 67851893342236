import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useHistory } from 'react-router-dom';
import {
	LikeOutlined,
	ToTopOutlined,
	RetweetOutlined,
	PieChartOutlined,
	AreaChartOutlined,
	LineChartOutlined,
	ColumnWidthOutlined
} from '@ant-design/icons';

import { ScoreParts } from './ScoreParts';
import { ErgonomicToolsItem } from './types';
import { ErgonomicTools, FileDTO } from '@/components/ui/Inputs';
import { LibertyMutualIcon } from './LibertyMutualIcon';
import { Paragraph, Title } from '@/components/Typography';
import { ModalBorder, ModalCard, ModalColumn } from './styles';

interface ErgonomicToolsModalProps {
	file: FileDTO;
	visible: boolean;
	onClose: (visible?: boolean) => void;
}

export const ErgonomicToolsModal: React.FC<ErgonomicToolsModalProps> = (props) => {
	const history = useHistory();
	const { file, onClose, visible } = props;
	const [openScoreParts, setOpenScoreParts] = useState<boolean>(false);

	function handleCloseScoreParts() {
		setOpenScoreParts(false);
		onClose(true);
	}

	const onSelectReport = (type: ErgonomicTools) => {
		const ergonomicToolsWithoutRULA: ErgonomicTools[] = [
			'angle-time',
			'niosh',
			'strain-index',
			'reba',
			'kim-mho',
			'kim-pp',
			'liberty-mutual'
		];
		if (ergonomicToolsWithoutRULA.includes(type)) {
			history.push(`/reporting/${type}/${file.id}?custom_report=true`);
		} else {
			setOpenScoreParts(true);
		}
	};

	const biomechanicalReports: ErgonomicToolsItem[] = [
		{
			name: 'score-parts',
			title: I18n.get('Movement by score'),
			icon: <PieChartOutlined />
		},
		{
			name: 'reba',
			title: I18n.get('REBA'),
			icon: <LineChartOutlined />
		},
		{
			name: 'angle-time',
			title: I18n.get('Angle by time'),
			icon: <AreaChartOutlined />
		}
	];

	const load_reports: ErgonomicToolsItem[] = [
		{
			name: 'niosh',
			title: I18n.get('Manual lifting'),
			message: 'NIOSH/ISO 11228-1',
			icon: <ToTopOutlined />
		},
		{
			name: 'kim-pp',
			title: I18n.get('Push and pull'),
			message: 'KIM PUSH PULL',
			icon: <ColumnWidthOutlined />
		},
		{
			name: 'kim-mho',
			title: I18n.get('Manual Handling'),
			message: 'KIM MHO',
			icon: <LikeOutlined style={{ transform: 'rotate(270deg)' }} />
		},
		{
			name: 'liberty-mutual',
			title: I18n.get('Material Handling'),
			message: 'LIBERTY MUTUAL',
			icon: <LibertyMutualIcon />
		}
	];

	const repeatability_reports: ErgonomicToolsItem[] = [
		{
			name: 'strain-index',
			title: I18n.get('Revised Strain Index'),
			icon: <RetweetOutlined />
		}
	];

	if (openScoreParts) {
		return <ScoreParts onClose={handleCloseScoreParts} item={props.file} visible={openScoreParts} />;
	}

	return (
		<ModalBorder width={'60rem'} footer={null} open={visible} onCancel={() => onClose()} centered>
			<Row justify="center">
				<Col sm={24}>
					<Title className="title-modal" level={2}>
						{I18n.get('Ergonomic tools')}
					</Title>
				</Col>
				<Col sm={24}>
					<Paragraph className="paragraph-modal" style={{ fontSize: '20px', textAlign: 'center' }}>
						{I18n.get('Select the most suitable tool for your analysis')}
					</Paragraph>
				</Col>
			</Row>
			<Row justify="center" gutter={[20, 0]}>
				<Col sm={8} style={{ padding: '10px 0px 10px 0px', textAlign: 'center' }}>
					<Col sm={24}>
						<Title level={4} style={{ fontSize: '19px' }}>
							{I18n.get('Biomechanical analysis')}
						</Title>
					</Col>
					<ModalColumn sm={24}>
						{biomechanicalReports.map((item) => (
							<Row gutter={20} key={item.name} justify="center">
								<ModalCard onClick={() => onSelectReport(item.name)}>
									{item.icon}
									<p className="title-card">{item.title}</p>
								</ModalCard>
							</Row>
						))}
					</ModalColumn>
				</Col>
				<Col
					sm={8}
					style={{
						padding: '10px 0px 10px 0px',
						borderLeft: 'solid 2px #F5F5F5',
						borderRight: 'solid 2px #F5F5F5',
						textAlign: 'center'
					}}
				>
					<Col sm={24}>
						<Title level={4} style={{ fontSize: '19px' }}>
							{I18n.get('Load analysis')}
						</Title>
					</Col>
					<ModalColumn sm={24}>
						{load_reports.map((item, index) => (
							<Row
								justify="center"
								key={item.name}
								style={{ marginBottom: index === load_reports.length - 1 ? '1rem' : '' }}
							>
								<ModalCard onClick={() => onSelectReport(item.name)} hoverable>
									{item.icon}
									<p className="title-card">{item.title}</p>
									<p className="message-card">{item.message}</p>
								</ModalCard>
							</Row>
						))}
					</ModalColumn>
				</Col>
				<Col sm={8} style={{ padding: '10px 0px 10px 0px', textAlign: 'center' }}>
					<Col sm={24}>
						<Title level={4} style={{ fontSize: '19px' }}>
							{I18n.get('Repeatability analysis')}
						</Title>
					</Col>
					<ModalColumn sm={24}>
						{repeatability_reports.map((item) => (
							<Row justify="center" key={item.name}>
								<ModalCard onClick={() => onSelectReport(item.name)} hoverable>
									{item.icon}
									<p className="title-card">{item.title}</p>
								</ModalCard>
							</Row>
						))}
					</ModalColumn>
				</Col>
			</Row>
		</ModalBorder>
	);
};
