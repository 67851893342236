import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';

import {
	CreateCategoryRequest,
	DeleteCategoryRequest,
	GetCategoriesRequest,
	UpdateCategoryRequest
} from './types/request';
import { ManageCategoryService } from './services';
import { CategoryPaginated } from './types/response';
import { CustomReportStepDTO } from '@/components/ui/Inputs/types/response';
import { CustomReportRequest, CustomReportStepsRequest } from '@/components/ui/Inputs/types/request/customReport';

const service = new ManageCategoryService();

export function useGetCategories({
	limit,
	offset,
	filter,
	company_id,
	organization_id,
	custom_report_step_key_id
}: GetCategoriesRequest) {
	const payload = { limit, offset, filter, company_id, organization_id, custom_report_step_key_id };
	const categories = useQuery(['categories_list', filter], () => service.fetchCategories(payload), {
		enabled: !!organization_id && !!company_id && !!custom_report_step_key_id
	});
	return {
		...categories,
		data: categories.data ?? ({} as CategoryPaginated)
	};
}

export function useCreateCategory() {
	const queryClient = useQueryClient();
	return useMutation((body: CreateCategoryRequest) => service.createCategory(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message ?? err.response.statusText));
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['categories_list']);
			message.success(I18n.get('Category created successfully'));
		}
	});
}

export function useUpdateCategory() {
	const queryClient = useQueryClient();
	return useMutation((body: UpdateCategoryRequest) => service.updateCategory(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message ?? err.response.statusText));
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['categories_list']);
			message.success(I18n.get('Category updated successfully'));
		}
	});
}

export function useDeleteCategory() {
	const queryClient = useQueryClient();
	return useMutation((body: DeleteCategoryRequest) => service.deleteCategory(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message ?? err.response.statusText));
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['categories_list']);
			message.success(I18n.get('Category deleted successfully'));
		}
	});
}

export function useSeraSteps({ organization_id, company_id, custom_report_id }: CustomReportStepsRequest) {
	const enabled = !!organization_id && !!company_id && !!custom_report_id;
	const customReportsSteps = useQuery(
		['custom_reports_steps', [organization_id, company_id, custom_report_id]],
		() => service.getCustomReportsSteps({ organization_id, company_id, custom_report_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...customReportsSteps,
		data: customReportsSteps.data ?? ([] as CustomReportStepDTO[])
	};
}

export function useCustomReport({ organization_id, company_id, reportName }: CustomReportRequest) {
	const enabled = !!organization_id && !!company_id && !!reportName;
	const customReport = useQuery(
		['custom_report', { organization_id, company_id, reportName }],
		() => service.getCustomReport({ organization_id, company_id, reportName }),
		{
			enabled,
			retry: 0
		}
	);
	return customReport;
}
