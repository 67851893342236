import Api from '@/services/api';

export async function createReportPDF(parameters) {
	const { organizationId, companyId, fileId, parts, locale } = parameters;
	const url = '/ergonomic-tool/angle-time/report';
	const body = {
		organization_id: organizationId,
		company_id: companyId,
		file_id: fileId,
		locale: locale,
		parts: parts
	};
	const headers = { responseType: 'blob' };
	const { data } = await Api.post(url, body, headers);
	return data;
}

export async function getRiskRanges() {
	const url = '/customization/risk-ranges';
	const { data } = await Api.get(url);
	return data;
}

export async function getAngles(organizationId, companyId, fileId) {
	const url = `/upload/angles/${fileId}?organization_id=${organizationId}&company_id=${companyId}&normalize=true`;
	const { data } = await Api.get(url);
	return data;
}

export async function getRiskRangeSettings(riskRangeId) {
	const url = `/customization/risk-ranges/${riskRangeId}`;
	const { data } = await Api.get(url);
	return data;
}

export async function updateReport(payload) {
	const url = `/ergonomic-tool/angle-time`;
	const { data } = await Api.put(url, payload);
	return data;
}

export async function createReport(payload) {
	const url = `/ergonomic-tool/angle-time`;
	const { data } = await Api.post(url, payload);
	return data;
}

export async function getReport(organizationId, companyId, fileId) {
	const url = `/ergonomic-tool/angle-time/${fileId}?organization_id=${organizationId}&company_id=${companyId}`;
	const { data } = await Api.get(url);
	return data;
}

export async function getSectors(organizationId, companyId) {
	const url = `/sector/${organizationId}/${companyId}`;
	const { data } = await Api.get(url);
	return data;
}

export async function saveComment({ organizationId, companyId, fileId, comment }) {
	const url = `/ergonomic-tool/angle-time/save-comment`;
	const body = {
		organization_id: organizationId,
		company_id: companyId,
		file_id: fileId,
		comment: comment
	};
	const { data } = await Api.post(url, body);
	return data;
}
