import { useMutation, useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import type { AxiosError } from 'axios';
import { message } from 'antd';

import Api from '@/services/api';
import type { Response, WorkConditionResult } from '@/types';
import type { CreateWorkConditionDTO } from './useCreateWorkConditionResult';

export type UpdateWorkConditionDTO = CreateWorkConditionDTO & {
	work_condition_id?: string;
};

async function updateWorkCondition(body: UpdateWorkConditionDTO): Promise<WorkConditionResult> {
	const url = '/custom-report/result/work-condition';
	const { data } = await Api.put<WorkConditionResult>(url, body);
	return data;
}

export const useUpdateWorkConditionResult = () => {
	const queryClient = useQueryClient();
	return useMutation<WorkConditionResult, AxiosError<Response>, UpdateWorkConditionDTO>(
		(body: UpdateWorkConditionDTO) => updateWorkCondition(body),
		{
			onError: (err) => {
				message.error(I18n.get(err?.response?.data?.message ?? err?.message));
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['jds_d86_report']);
				queryClient.invalidateQueries(['jds_d86_result']);
			}
		}
	);
};
