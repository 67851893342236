import Api from '@/services/api';

import {
	OrganizationResponse,
	OrganizationPaginated,
	GetOrganizationsRequest,
	DeleteOrganizationRequest,
	CreateOrganizationRequest,
	UpdateOrganizationRequest
} from '../types';

export class ManageOrganizationService {
	public async fetchOrganizations({ limit, offset, company_id, filter, organization_id }: GetOrganizationsRequest) {
		const baseUrl = `/organization/find-all`;
		const { data } = await Api.get<OrganizationPaginated>(baseUrl, {
			params: {
				...filter,
				limit,
				offset,
				company_id,
				organization_id
			}
		});
		return data;
	}

	public async createOrganization(body: CreateOrganizationRequest) {
		const url = '/organization';
		const { data } = await Api.post<OrganizationResponse>(url, body);
		return data;
	}

	public async updateOrganization(body: UpdateOrganizationRequest) {
		const { organization_id } = body;
		const url = `/organization/${organization_id}`;
		const { data } = await Api.put<OrganizationResponse>(url, body);
		return data;
	}

	public async deleteOrganization({ organization_id }: DeleteOrganizationRequest) {
		const url = `/organization/${organization_id}`;
		const { data } = await Api.delete<OrganizationResponse>(url);
		return data;
	}
}
