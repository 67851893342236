import React from 'react';
import { Col, Divider, Row } from 'antd';

import { I18n } from '@aws-amplify/core';
import { Title } from '@/components/Typography';

import { Description, Score } from './styles';

type StepKeyDescriptionProps = {
	task_rpn: number;
	jobElement: string;
	stressLevel: {
		id: string;
		name: string;
		description: string;
	};
	frequency: {
		id: string;
		name: string;
		description: string;
	};
	totalTaskDuration: {
		id: string;
		name: string;
		description: string;
	};
};

export const StepKeyDescription: React.FC<StepKeyDescriptionProps> = ({
	task_rpn,
	jobElement,
	stressLevel,
	frequency,
	totalTaskDuration
}) => {
	return (
		<Row justify="space-between" style={{ margin: '0 0 1rem 1rem' }}>
			<Col span={4}>
				<Row>
					<Title style={{ margin: 0, fontSize: '0.8rem' }}>{I18n.get('Stress level')}</Title>
				</Row>
				<Row>
					<Description>{I18n.get(stressLevel?.description ?? 'N/A')}</Description>
				</Row>
			</Col>
			<Divider type="vertical" style={{ height: 'auto' }} />
			<Col span={4}>
				<Row>
					<Title style={{ margin: 0, fontSize: '0.8rem' }}>{I18n.get('Frequency')}</Title>
				</Row>
				<Row>
					<Description>{I18n.get(frequency?.description ?? 'N/A')}</Description>
				</Row>
			</Col>
			<Divider type="vertical" style={{ height: 'auto' }} />
			<Col span={5}>
				<Row>
					<Title style={{ margin: 0, fontSize: '0.8rem' }}>{I18n.get('Total task duration')}</Title>
				</Row>
				<Row>
					<Description>{I18n.get(totalTaskDuration?.description ?? 'N/A')}</Description>
				</Row>
			</Col>
			<Divider type="vertical" style={{ height: 'auto' }} />
			<Col span={4}>
				<Row>
					<Title style={{ margin: 0, fontSize: '0.8rem' }}>{I18n.get('Job element')}</Title>
				</Row>
				<Row>
					<Description>{jobElement ?? 'N/A'}</Description>
				</Row>
			</Col>
			<Divider type="vertical" style={{ height: 'auto' }} />
			<Col span={3}>
				<Row>
					<Title style={{ margin: 0, fontSize: '0.8rem' }}>{I18n.get('Task RPN')}</Title>
				</Row>
				<Row>
					<Score>{task_rpn}</Score>
				</Row>
			</Col>
		</Row>
	);
};
