import React from 'react';
import { I18n } from '@aws-amplify/core';
import { ColumnsType } from 'antd/es/table';
import { Col, Table, TableColumnsType } from 'antd';

import { KimManualHandling } from '@/hooks';
import { riskLevelToColorMapper } from '@/components/views/JdsD92/context/types';
import * as S from './styles';

interface KimManualHandlingProps {
	data: KimManualHandling;
}

interface DataType {
	risk: string;
	key: React.Key;
	duration: number;
	risk_score: number;
	force_transfer: string;
	force_exerted: string;
}

export const KimManualHandlingResults: React.FC<KimManualHandlingProps> = ({ data }) => {
	const {
		id,
		risk,
		duration,
		risk_load,
		risk_score,
		measurements,
		force_transfer,
		left_force_intensity,
		right_force_intensity,
		possible_health_consequences
	} = data;

	const force_exerted = `${I18n.get(left_force_intensity)} | ${I18n.get(right_force_intensity)}`;

	const getScore = () => {
		if (risk_score < 1) {
			return 0;
		}

		if (risk_score < 21) {
			return 1;
		}

		if (risk_score < 51) {
			return 3;
		}

		if (risk_score < 101) {
			return 4;
		}

		return 5;
	};

	const columns: ColumnsType<DataType> = [
		{
			title: I18n.get('Total duration per day worked'),
			dataIndex: 'duration',
			key: 'duration'
		},
		{
			title: `${I18n.get('Force exerted on hand')} / ${I18n.get('fingers (L|R)')}`,
			dataIndex: 'force_exerted',
			key: 'force_exerted'
		},
		{
			title: `${I18n.get('Power transfer')} / ${I18n.get('gripping conditions')}`,
			dataIndex: 'force_transfer',
			key: 'force_transfer'
		},
		{
			title: I18n.get('Risk range'),
			dataIndex: 'risk',
			key: 'risk',
			width: '15%'
		},
		{
			title: I18n.get('Score'),
			dataIndex: 'risk_score',
			key: 'risk_score',
			width: '15%'
		}
	];

	const dataColumn: DataType[] = [
		{
			key: id,
			duration,
			risk_score,
			risk: I18n.get(risk),
			force_exerted: I18n.get(force_exerted),
			force_transfer: I18n.get(force_transfer)
		}
	];

	return (
		<Col span={24} style={{ marginTop: '2rem' }}>
			<S.KimTableContainerFirstRow $riskColor={riskLevelToColorMapper[getScore()]}>
				<Table columns={columns} dataSource={dataColumn} pagination={false} />
			</S.KimTableContainerFirstRow>
			<S.KimTableContainerSecondRow>
				<ExpandedTable
					possible_health_consequences={possible_health_consequences}
					measurements={measurements}
					risk_load={risk_load}
				/>
			</S.KimTableContainerSecondRow>
		</Col>
	);
};

interface ExpandedTableType {
	key?: React.Key;
	possible_health_consequences: string;
	measurements: string;
	risk_load: string;
}

const ExpandedTable: React.FC<ExpandedTableType> = ({ possible_health_consequences, measurements, risk_load }) => {
	const columns: TableColumnsType<ExpandedTableType> = [
		{
			title: I18n.get('Probability of physical overload'),
			dataIndex: 'risk_load',
			key: 'risk_load',
			width: '33.3333%'
		},
		{
			title: I18n.get('Possible health consequences'),
			dataIndex: 'possible_health_consequences',
			key: 'possible_health_consequences',
			width: '33.3333%'
		},
		{
			title: I18n.get('Measurements'),
			dataIndex: 'measurements',
			key: 'measurements',
			width: '33.3333%'
		}
	];

	const data: ExpandedTableType[] = [
		{
			risk_load: I18n.get(risk_load),
			measurements: I18n.get(measurements),
			key: 'kim_manual_handling_second_row',
			possible_health_consequences: I18n.get(possible_health_consequences)
		}
	];

	return <Table columns={columns} dataSource={data} pagination={false} />;
};
