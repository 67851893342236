import React from 'react';
import moment from 'moment';
import { Row, Col, Divider } from 'antd';
import { I18n } from '@aws-amplify/core';

import { ActionPlanContent, ActionPlanTitle, ActioPlanContainer, DeadlineContainer, Title } from './styles';
import { ActionPlanTask } from './ActionPlanTask';
import { ActionPlan, Task } from '../../types';
import { Text } from '@/components/Typography';

type ActionPlanDataProps = {
	actionPlan: ActionPlan;
};

export const ActionPlanData: React.FC<ActionPlanDataProps> = ({ actionPlan }) => {
	function calculateDeadline(fullDate: Date): number {
		return moment(fullDate).diff(moment(), 'days');
	}

	function isPluralDays(fullDate: Date) {
		return calculateDeadline(fullDate) > 1 || calculateDeadline(fullDate) < -1;
	}

	function mountTasksList(tasks: Task[]): React.ReactNode {
		return tasks.length > 0
			? actionPlan.tasks.map((task) => <ActionPlanTask key={task.id} task={task} length={tasks.length} />)
			: I18n.get('No task available');
	}

	return (
		<ActioPlanContainer>
			<Col span={24}>
				<ActionPlanTitle>{I18n.get(actionPlan.title)}</ActionPlanTitle>
			</Col>
			<ActionPlanContent span={24}>
				<Row align="top">
					<Col xl={10} xxl={12}>
						<Row gutter={[0, 8]}>
							<Col span={24}>
								<Row>
									<Title>{I18n.get('Person responsible')}</Title>
								</Row>
								<Row>{actionPlan.responsible}</Row>
							</Col>

							<Divider style={{ margin: '0.25rem 0' }} />

							<Col span={24}>
								<Row align="middle">
									<Col span={12}>
										<Row>
											<Title>{I18n.get('Deadline')}</Title>
										</Row>
										<Row>
											<span>
												{calculateDeadline(actionPlan.deadline.fullDate)}{' '}
												{I18n.get(isPluralDays(actionPlan.deadline.fullDate) ? 'days' : 'day')}
											</span>
										</Row>
									</Col>
									<Col span={'auto'}>
										<DeadlineContainer>
											<Col>{actionPlan.deadline.day}</Col>
											<Divider type="vertical" style={{ height: 'auto', margin: '0 0.5rem' }} />
											<Col>{actionPlan.deadline.month}</Col>
											<Divider type="vertical" style={{ height: 'auto', margin: '0 0.5rem' }} />
											<Col>{actionPlan.deadline.year}</Col>
										</DeadlineContainer>
									</Col>
								</Row>
							</Col>

							<Divider style={{ margin: '0.25rem 0' }} />

							<Col span={24}>
								<Row>
									<Title>{I18n.get('Description')}</Title>
								</Row>
								<Row>
									<Col
										span={24}
										style={{
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis'
										}}
									>
										<Text>{actionPlan.description}</Text>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
					<Divider type="vertical" style={{ height: '200px', margin: '0 2rem' }} />
					<Col span={10}>
						<Row style={{ width: '100%' }} gutter={[0, 32]}>
							<Col span={24}>
								<Title>{I18n.get('Tasks')}</Title>
							</Col>

							<Col span={24} style={{ maxHeight: '180px', overflowY: 'auto' }}>
								{mountTasksList(actionPlan.tasks)}
							</Col>
						</Row>
					</Col>
				</Row>
			</ActionPlanContent>
		</ActioPlanContainer>
	);
};
