import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { CustomerPlan } from './CustomerPlan';

export function Plan() {
	const Title = () => (
		<Row>
			<Col sm={24}>
				<h2>{I18n.get('My plan')}</h2>
			</Col>
		</Row>
	);

	return (
		<div>
			<Title />
			<CustomerPlan />
		</div>
	);
}
