import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message, notification } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CreateWorkstationRequest } from '@/components/ui/Inputs/types/request/workstation';
import { WorkstationDTO } from '@/components/ui/Inputs/types/response/workstation';
import Api from '@/services/api';

export const useCreateWorkstation = () => {
	const queryClient = useQueryClient();
	return useMutation(({ body }: { body: CreateWorkstationRequest }) => Api.post<WorkstationDTO>('/workstation/create', body), {
		onError: (err: any) => {
			notification.error({
				message: I18n.get(err?.response?.data?.message || 'Ops... something happened!')
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['workstations']);
			queryClient.invalidateQueries(['workstations_list']);
			message.success(I18n.get('Workstation created successfully'));
		}
	});
};
