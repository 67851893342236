import React, { createContext } from 'react';
import { useFile } from '@/hooks/useFile';

const UploadContext = createContext();

function UploadProvider({ children }) {

	const methodsUpload = useFile();

	return (
		<UploadContext.Provider value={methodsUpload}>
			{children}
		</UploadContext.Provider>
	)
}

export { UploadContext, UploadProvider };
