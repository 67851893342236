import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Button, message } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined, ReconciliationOutlined } from '@ant-design/icons';

import { riskColors } from '@/utils/riskRange';
import { Title } from '@/components/Typography';
import { ActionPlan } from '../ActionPlanModal/types';
import { useStepKeysContext } from '../StepKeys/DefaultSelects/context';
import { LastStepKeyResultResponse } from '@/hooks/useGetLastStepKeyResult';
import { RiskTag, RiskTagContainer } from './styles';

interface CollpaseHeaderProps {
	title: string;
	isOpen: boolean;
	handleOpenModal?: (data: ActionPlan) => void;
}

const getActionPlan = (previousValue?: LastStepKeyResultResponse, subStepKeyId?: string) => {
	const hasSubStepKeysResults = previousValue?.sub_step_keys_results?.some((s) => Boolean(s.result));
	const hasStepKeyResults = !!previousValue?.step_key_results?.result;

	if (!previousValue || !hasStepKeyResults || !hasSubStepKeysResults) {
		return {} as ActionPlan;
	}

	if (hasSubStepKeysResults) {
		return previousValue?.sub_step_keys_results?.find((f) => f?.sub_step_key?.id === String(subStepKeyId))
			?.action_plan as ActionPlan;
	}

	return previousValue.step_key_results.action_plan;
};

export function CollpaseHeader({ title, isOpen, handleOpenModal }: Readonly<CollpaseHeaderProps>) {
	const { previousValue, stepKey, showHistory, handleChangeShowHistory, subStepKeySeleceted } = useStepKeysContext();

	const hasPreviousResult =
		!!stepKey.previous_result?.result || !!previousValue?.sub_step_keys_results?.some((s) => Boolean(s.result));

	function handleShowPreviousResult(e: React.MouseEvent<HTMLElement, MouseEvent>) {
		e.stopPropagation();
		handleChangeShowHistory(!showHistory);
	}

	function handleShowPreviousActionPlan(e: React.MouseEvent<HTMLElement, MouseEvent>) {
		e.stopPropagation();

		if (!handleOpenModal) {
			throw new Error('handleOpenModal not defined');
		}

		const result = getActionPlan(previousValue, subStepKeySeleceted);

		if (!result?.id) {
			message.warning(I18n.get('Action plan not defined'));
			return;
		}

		handleOpenModal(result);
	}

	return (
		<Row align="middle" justify="space-between">
			<Col span={20}>
				<Title level={5} style={{ margin: 0 }}>
					{title}
				</Title>
			</Col>
			{!isOpen && Number(stepKey?.previous_result?.result) > 0 && (
				<RiskTagContainer span={4}>
					<RiskTag color={riskColors[stepKey?.previous_result?.result as number]}>
						RPN: {stepKey?.previous_result?.score}
					</RiskTag>
				</RiskTagContainer>
			)}
			{isOpen && hasPreviousResult && (
				<Col span={2} style={{ marginRight: 10 }}>
					<Row align="middle" justify="space-between" gutter={[10, 0]}>
						<Col span={12}>
							<Button
								type="link"
								size="large"
								onClick={handleShowPreviousResult}
								icon={
									showHistory ? (
										<EyeInvisibleOutlined style={{ fontSize: '1.5rem' }} />
									) : (
										<EyeOutlined style={{ fontSize: '1.5rem' }} />
									)
								}
							/>
						</Col>
						<Col span={12}>
							<Button
								type="link"
								size="large"
								onClick={handleShowPreviousActionPlan}
								icon={<ReconciliationOutlined style={{ fontSize: '1.5rem' }} />}
							/>
						</Col>
					</Row>
				</Col>
			)}
		</Row>
	);
}
