import React from 'react';
import { Form, Select } from 'antd';

import { FrequencyDTO } from '@/components/ui/Inputs/types/response/frequency';
import { I18n } from '@aws-amplify/core';

import { NamePath } from 'antd/lib/form/interface';

const { useFormInstance } = Form;

type FrequencySelectProps = {
	frequencies: FrequencyDTO[] | undefined;
	loading: boolean;
	disabled: boolean;
	handleSelectFrequency?: (id: string) => void;
	handleClearFrequency?: () => void;
	frequencyId?: string;
	fieldName: NamePath;
};

type OptionType = { label: string; value: string } | undefined;

export function FrequencySelect({
	frequencies,
	loading,
	disabled,
	frequencyId,
	handleSelectFrequency,
	handleClearFrequency,
	fieldName
}: FrequencySelectProps) {
	const { validateFields } = useFormInstance();

	async function handleChange(id: string) {
		if (handleSelectFrequency) {
			handleSelectFrequency(id);
			await validateFields([fieldName]);
		}
	}

	function handleClear() {
		if (handleClearFrequency) {
			handleClearFrequency();
		}
	}

	function filterOption(option: OptionType, input: string): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	return (
		<Select
			showSearch
			allowClear
			value={frequencyId}
			onClear={handleClear}
			placeholder={I18n.get('Frequency')}
			filterOption={(input, option) => filterOption(option, input)}
			loading={loading}
			disabled={disabled}
			options={frequencies?.map(({ id, description }) => ({ label: I18n.get(description), value: id }))}
			onChange={handleChange}
		/>
	);
}
