import { message } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useQuery } from '@tanstack/react-query';
import { getRiskRangeSettings } from './services';

export const useRiskRangeSettings = (payload) => {
	const enabled = !!payload.organizationId && !!payload.companyId;
	return useQuery(
		['risk_ranges', 'risk_ranges_settings', payload],
		() => getRiskRangeSettings(payload),
		{
			enabled,
			retry: false,
			onError: (err) => {
				message.error(I18n.get(err.response.data.message));
			}
		}
	);
};
