import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import { Form, Modal } from 'antd';

import { DeleteBaseRequest, HierarchyEnum, UpdateBaseRequest } from '../hooks/types';
import { useApplicationContext } from '@/context/v1/Application/context';
import { RiskDamageDTO, RiskDamageSelect } from '@/components/ui/Inputs';
import { useDelete, useGetRiskDamages, useUpdate } from '../hooks';
import { DescriptionManager } from './DescriptionManager';
import { useManageCategoryContext } from '../context';

const { useFormInstance, useWatch } = Form;

interface ManageRiskDamageProps {
	stepKeyId: string;
}

export const ManageRiskDamage: React.FC<ManageRiskDamageProps> = ({ stepKeyId }) => {
	const form = useFormInstance();
	const { organization, company } = useApplicationContext();
	const { isEditing, handleIsEditing, treatFieldValidation } = useManageCategoryContext();

	const riskDamageId = useWatch(['risk_damage_id'], form);
	const riskDescriptionId = useWatch(['risk_description_id'], form);

	const {
		data: riskDamages,
		isLoading: gettingRiskDamages,
		isFetching: fetchingRiskDamages,
		isError: errorGettingRiskDamages
	} = useGetRiskDamages({
		step_key_id: stepKeyId,
		company_id: company?.id,
		organization_id: organization?.id,
		risk_description_id: riskDescriptionId
	});
	const { mutateAsync: deleteData } = useDelete<DeleteBaseRequest, RiskDamageDTO>();
	const { mutateAsync: updateData } = useUpdate<UpdateBaseRequest, RiskDamageDTO>();

	const riskDamage = riskDamages?.find(({ id }) => id === riskDamageId);
	const currentName = riskDamage?.description;

	async function handleUpdateName() {
		try {
			const values = await form.getFieldValue(['editing']);
			if (values) {
				const body: UpdateBaseRequest = {
					id: values?.currentId,
					company_id: company?.id,
					organization_id: organization?.id,
					description: values.risk_damage_name,
					hierarchy_field: HierarchyEnum.riskDamage
				};

				const updated = await updateData(body);
				if (updated.id) {
					handleIsEditing(false);
					form.setFieldValue('risk_damage_id', updated.id);
					form.setFieldValue(['editing', 'risk_damage_name'], undefined);
				}
			}
		} catch (error: any) {
			treatFieldValidation(error);
		}
	}

	async function handleDeleteRiskDamage() {
		Modal.confirm({
			title: I18n.get('Heads up!'),
			content: I18n.get('Want to delete this damage?'),
			icon: <ExclamationCircleOutlined />,
			okText: I18n.get('Confirm'),
			cancelText: I18n.get('Cancel'),
			okType: 'danger',
			onOk: async () => {
				const body: DeleteBaseRequest = {
					id: riskDamageId,
					company_id: company?.id,
					organization_id: organization?.id,
					hierarchy_field: HierarchyEnum.riskDamage
				};
				const updated = await deleteData(body);
				if (updated.id) {
					handleClearRiskDamage();
					handleIsEditing(false);
					form.setFieldValue(['editing', 'risk_damage_name'], undefined);
				}
			}
		});
	}

	function handleRiskDamageSelect(id: string) {
		form.setFieldValue(['risk_damage_id'], id);
		const riskDamage = riskDamages?.find((riskDamage) => riskDamage.id === id);
		form.setFieldValue(['severity_id'], riskDamage?.severity_id);
		form.setFieldValue(['current_severity_id'], riskDamage?.severity_id);
	}

	function handleClearRiskDamage() {
		form.setFieldValue(['risk_damage_id'], undefined);
		form.setFieldValue(['severity_id'], undefined);
		form.setFieldValue(['current_severity_id'], undefined);
	}

	return (
		<DescriptionManager
			key={riskDescriptionId}
			currentId={riskDamageId}
			currentName={currentName}
			parentId={riskDescriptionId}
			fieldLabel={'a risk damage'}
			fieldName={['risk_damage_id']}
			onDelete={handleDeleteRiskDamage}
			onUpdateName={handleUpdateName}
			editingNameField={['editing', 'risk_damage_name']}
		>
			<RiskDamageSelect
				size="middle"
				allowCreation={true}
				stepKeyId={stepKeyId}
				riskDamages={riskDamages}
				riskDamageId={riskDamageId}
				fieldName={'risk_damage_id'}
				riskDescriptionId={riskDescriptionId}
				handleClearRiskDamage={handleClearRiskDamage}
				handleSelectRiskDamage={handleRiskDamageSelect}
				loading={isEditing || fetchingRiskDamages}
				disabled={
					errorGettingRiskDamages ||
					gettingRiskDamages ||
					fetchingRiskDamages ||
					isEditing ||
					!riskDescriptionId
				}
			/>
		</DescriptionManager>
	);
};
