import Api from '@/services/api';
import moment from 'moment';
import { CompanyDTO, OrganizationDTO, SectorDTO, SuperPEAListDTO, SuperPEAQueryParams } from './types';
export class SuperPEAService {
	async generateUrlDownload(body: any) {
		const { data } = await Api.post<any>('/super-pea/download-pdf', body);
		return data;
	}

	async getSectors(organizationId: string, companyId: string) {
		const url = `/sector/${organizationId}/${companyId}?name=&limit=${30}`;
		const { data } = await Api.get<SectorDTO[]>(url);
		return data;
	}

	async getCompanies(organizationId: string) {
		const url = `/company/search/${organizationId}?name=&limit=${30}`;
		const { data } = await Api.get<CompanyDTO[]>(url);
		return data;
	}

	async getOrganizations() {
		const url = `/organization/search?name=&limit=${30}`;
		const { data } = await Api.get<OrganizationDTO[]>(url);
		return data;
	}

	async getPEAList(queryParams: SuperPEAQueryParams) {
		const { companyId, organizationId } = queryParams;
		const url = `/super-pea/${organizationId}/${companyId}?`;

		const filterParams = this.setFilterParams(queryParams);

		const { data } = await Api.get<SuperPEAListDTO[]>(url + filterParams);
		return data;
	}

	async deleteSuperPEA(superPEAId: React.Key, organizationId: string, companyId: string) {
		const url = `/super-pea/${superPEAId}?organization_id=${organizationId}&company_id=${companyId}`;

		const { data } = await Api.patch(url);

		return data;
	}

	private setFilterParams(queryParams: SuperPEAQueryParams) {
		const { analyst, collectionDate, createdAt, reportName, sectorId, workstation } = queryParams;

		let filterParams = '';

		if (sectorId) {
			filterParams = `${filterParams}sector_id=${sectorId}&`;
		}

		if (workstation) {
			filterParams = `${filterParams}workstation=${workstation}&`;
		}

		if (analyst) {
			filterParams = `${filterParams}analyst=${analyst}&`;
		}

		if (reportName) {
			filterParams = `${filterParams}report_name=${reportName}&`;
		}

		if (collectionDate && collectionDate.length > 0) {
			const [start, end] = collectionDate;
			const start_date = moment(start).format();
			const end_date = moment(end).format();
			filterParams = `${filterParams}start_date=${start_date}&end_date=${end_date}&`;
		}

		if (createdAt && createdAt.length > 0) {
			const [start, end] = createdAt;
			const start_date = moment(start).format();
			const end_date = moment(end).format();
			filterParams = `${filterParams}created_start_date=${start_date}&created_end_date=${end_date}&`;
		}

		return filterParams;
	}
}
