import React from 'react';
import { Text } from '@/components/Typography';
import { Col, Row } from 'antd';

import { FrequencyCard, HandCouplingCard, ObjectWeightCard, InitialStateCard, EndStateCard } from './Cards';
import { useLibertyMutualSubStepsContext } from '../../context';
import { TaskNameMapper } from '../../hooks/types/response';

const INITIAL_LIFT_END_LOWER =
	'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/result-initial-lift-end-lower.svg';
const INITIAL_LOWER_END_LIFT =
	'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/result-initial-lower-end-lift.svg';

export const LiftLowerSummary = () => {
	const {
		libertyMutualResult: {
			informations: { task_name }
		}
	} = useLibertyMutualSubStepsContext();

	if (!isLift(task_name) && !isLower(task_name)) {
		return <Text>Oops... Something went wrong!</Text>;
	}

	function isLift(task_name: TaskNameMapper): task_name is TaskNameMapper.Lift {
		return task_name === TaskNameMapper.Lift;
	}

	function isLower(task_name: TaskNameMapper): task_name is TaskNameMapper.Lower {
		return task_name === TaskNameMapper.Lower;
	}

	const svgMapper = {
		[TaskNameMapper.Lift]: {
			initial: INITIAL_LIFT_END_LOWER,
			end: INITIAL_LOWER_END_LIFT
		},
		[TaskNameMapper.Lower]: {
			initial: INITIAL_LOWER_END_LIFT,
			end: INITIAL_LIFT_END_LOWER
		}
	};

	return (
		<Row>
			<Col xs={24}>
				<Row justify="center">
					<Col xs={20} style={{ maxWidth: '700px' }}>
						<Row justify="space-between">
							<Col xs={11} style={{ maxWidth: '300px', marginBottom: '1rem' }}>
								<InitialStateCard svgMapper={svgMapper} task_name={task_name} />
							</Col>
							<Col xs={11} style={{ maxWidth: '300px', marginBottom: '1rem' }}>
								<EndStateCard svgMapper={svgMapper} task_name={task_name} />
							</Col>
						</Row>
					</Col>
				</Row>
				<Row justify="center">
					<Col xs={24} style={{ maxWidth: '1000px' }}>
						<Row justify="space-between">
							<Col xs={7} style={{ maxWidth: '250px' }}>
								<HandCouplingCard />
							</Col>
							<Col xs={7} style={{ maxWidth: '250px' }}>
								<FrequencyCard />
							</Col>
							<Col xs={7} style={{ maxWidth: '250px' }}>
								<ObjectWeightCard />
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
