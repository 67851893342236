import React, { createContext, ReactNode, useContext, useState } from 'react';

import { Context } from './index.types';
import { FileDTO } from '@/components/ui/Inputs/types/response/file';

type KimManualHandlingProviderProps = {
	children: ReactNode;
};

const KimManualHandlingContext = createContext<Context>({} as Context);

export function KimManualHandlingProvider({ children }: KimManualHandlingProviderProps) {
	const [file, setFile] = useState<FileDTO>({} as FileDTO);

	function handleFile(file: FileDTO | undefined) {
		if (file) {
			setFile(file);
		}
	}

	const contextValue: Context = {
		file,
		handleFile
	};

	return <KimManualHandlingContext.Provider value={contextValue}>{children}</KimManualHandlingContext.Provider>;
}

export function useKimManualHandlingContext() {
	const context = useContext(KimManualHandlingContext);
	return context;
}
