import React, { useLayoutEffect, useRef, useState } from 'react';
import { message } from 'antd';

import { I18n } from '@aws-amplify/core';

import { useTreemapContext } from '../../context';
import { TileContainer, Wrapper } from './styles';
import { Props } from './types';

export function Tile({ id, title, size, count, child, worst_score, treemapLines, ...props }: Props) {
	const { page, companies, sectors, lines, workstations } = useTreemapContext();
	const { handleSectorSelection, handleLineSelection, handleCompanySelection, handleWorkstationSelection } =
		useTreemapContext();

	const worst = worst_score;

	const mapMethodByPage = [
		handleCompanySelection,
		handleSectorSelection,
		handleLineSelection,
		handleWorkstationSelection
	];

	const mapDataByPage = [companies, sectors, lines, workstations];

	function handleTreemapSelection() {
		if (child.length === 0) {
			message.error(I18n.get('There is nothing to access.'));
			return;
		}
		const datas = mapDataByPage[page];
		const data = datas.find((data) => data.id === id);
		if (data) {
			const method = mapMethodByPage[page];
			method(id, data.title);
		}
	}

	const ref = useRef<HTMLDivElement>(null);
	const [vertical, setVertical] = useState<boolean>(false);
	const blockWidth = `calc(${(page === 4 ? 1 : size) * treemapLines}% + 4%)`;

	useLayoutEffect(() => {
		if (!ref.current) return;

		setVertical(ref.current.offsetWidth < ref.current.offsetHeight);
	}, [ref, title]);

	return (
		<TileContainer title={props.children} placement="bottom">
			<Wrapper
				step={page}
				size={size}
				lines={treemapLines}
				worst={worst}
				style={{
					width: blockWidth,
					height: treemapLines > 3 ? 100 : 170
				}}
				onClick={() => handleTreemapSelection()}
				className={vertical ? 'vertical' : ''}
				ref={ref}
			>
				<span>{props.children}</span>
			</Wrapper>
		</TileContainer>
	);
}
