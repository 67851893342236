import React from 'react';
import { Row, Form, FormInstance } from 'antd';

import { OrganizationName } from './OrganizationName';
import { CreatedAt } from './CreatedAt';
import { InitialValues } from './types';
import { Status } from './Status';

interface FormFilterProps {
	form: FormInstance;
	initialValues: InitialValues;
}

export const FormFilter: React.FC<FormFilterProps> = ({ form, initialValues }) => (
	<Form form={form} initialValues={initialValues} layout="vertical">
		<Row gutter={[12, 0]}>
			<OrganizationName />
			<CreatedAt />
			<Status />
		</Row>
	</Form>
);
