import { useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import Api from '@/services/api';
import type { BaseContext, CustomReportAdditionalItemOption, CustomReportAdditionalItemTypes, Response } from '@/types';

export type AdditionalItemOptions = Pick<
	CustomReportAdditionalItemOption,
	'id' | 'name' | 'description' | 'type' | 'score' | 'sub_selects'
>;

export type GetAdditionalItemsOptionsDTO = BaseContext & {
	custom_report_step_key_id: string;
};

export type GetAdditionalItemOptionsResponse = AdditionalItemOptions & {
	additional_item_type: Pick<CustomReportAdditionalItemTypes, 'id' | 'type'>;
};

async function getAdditionalItemsOptions(
	params: GetAdditionalItemsOptionsDTO
): Promise<GetAdditionalItemOptionsResponse[]> {
	const url = '/custom-report/additional-item/option/list-all';
	const { data } = await Api.get<GetAdditionalItemOptionsResponse[]>(url, {
		params
	});
	return data;
}

export const useGetAdditionalItemsOptions = ({
	company_id,
	organization_id,
	custom_report_step_key_id
}: GetAdditionalItemsOptionsDTO) => {
	const params = {
		company_id,
		organization_id,
		custom_report_step_key_id
	};
	const report = useQuery<GetAdditionalItemOptionsResponse[], AxiosError<Response>>(
		['jds_d86_result', 'options', custom_report_step_key_id],
		() => getAdditionalItemsOptions(params),
		{
			enabled: !!company_id || !!organization_id || !!custom_report_step_key_id
		}
	);
	return {
		...report,
		data: report.data ?? ([] as GetAdditionalItemOptionsResponse[])
	};
};
