import React from 'react';
import propTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';
import { Form, Modal, Button, Row, Col, InputNumber } from 'antd';

const base_url = 'https://kinebot.com.br';

function ModalMinutePurchase({ visible, onCancel }) {
	const [form] = Form.useForm();
	const plan = useSelector((state) => state.plan.type);

	function optionsPurchase(type) {
		const [part] = type.split('_');
		const options = {
			individual: 2822,
			team: 1295,
			business: 1296,
		};
		return options[part] || new Error('Invalid plan');
	}

	function onGoPurchase() {
		form.validateFields().then((values) => {
			const { minutes } = values;
			const purchaseItem = optionsPurchase(plan);
			const url = `${base_url}/carrinho/?add-to-cart=${purchaseItem}&quantity=${minutes}`;
			window.open(url);
			onCancel();
		});
	}

	return (
		<Modal footer={null} visible={visible} onCancel={onCancel}>
			<Form form={form} layout="vertical" initialValues={{ minutes: 1 }}>
				<Row>
					<Col>
						<h2>{I18n.get('Buy additional credits')}</h2>
					</Col>
					<Col style={{ paddingTop: '15px' }}>
						<p>
							{I18n.get(
								`Enter the amount of additional credits (minutes) below to continue using the platform`
							)}
						</p>
					</Col>
					<Col style={{ paddingTop: '10px' }}>
						<Form.Item label={I18n.get('Minutes')} name="minutes">
							<InputNumber min={1} max={5000} />
						</Form.Item>
					</Col>
					<Col sm={24}>
						<Row gutter={[5, 0]} justify="end">
							<Col>
								<Button onClick={onCancel}>
									{I18n.get('Cancel')}
								</Button>
							</Col>
							<Col>
								<Button type="primary" onClick={onGoPurchase}>
									{I18n.get('Purchase')}
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
}

ModalMinutePurchase.propTypes = {
	visible: propTypes.bool.isRequired,
	onCancel: propTypes.func.isRequired,
};

export { ModalMinutePurchase };
