import React from 'react';
import { Row, Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useStepKeysContext } from '../DefaultSelects/context';
import { diagnosticColors } from '@/constants/RebaJDS';
import { Text } from '@/components/Typography';
import { RebaBodyPartScores } from '@/hooks';
import { PieChart } from './Piechart';

type ExposuresReducer = {
	[key: string]: string;
};

interface ChartsProps {
	formFieldName: string[];
	scores?: RebaBodyPartScores[];
}

const { useWatch, useFormInstance } = Form;

export const Chart: React.FC<ChartsProps> = ({ scores, formFieldName }) => {
	const form = useFormInstance();
	const {
		exposuresData: { exposures }
	} = useStepKeysContext();

	const exposureId = useWatch([...formFieldName, 'exposure_id'], form);

	if (!scores) {
		return null;
	}

	function defineExposureIdBasedOnRebaAcceptablePercentage(acceptablePercentage: number) {
		if (!exposures) {
			return;
		}

		const scaleToExposureNameMapper = {
			ACCEPTBALE: 'one_to_ten',
			LOW: 'eleven_to_twenty',
			MEDIUM: 'twenty_one_to_thirty',
			MEDIUM_HIGH: 'thirty_one_to_fifty',
			HIGH: 'fifty_one_to_seventy_five',
			EXTREME: 'seventy_six_to_a_hundred'
		};

		const exposureNameToIdMapper = exposures.reduce((nameToId, currentExposure) => {
			const exposure = {
				...nameToId,
				[currentExposure.name]: currentExposure.id
			};
			return exposure;
		}, {} as ExposuresReducer);

		if (acceptablePercentage <= 9) {
			return exposureNameToIdMapper[scaleToExposureNameMapper.EXTREME];
		}
		if (acceptablePercentage <= 29) {
			return exposureNameToIdMapper[scaleToExposureNameMapper.HIGH];
		}
		if (acceptablePercentage <= 49) {
			return exposureNameToIdMapper[scaleToExposureNameMapper.MEDIUM_HIGH];
		}
		if (acceptablePercentage <= 79) {
			return exposureNameToIdMapper[scaleToExposureNameMapper.MEDIUM];
		}
		if (acceptablePercentage <= 89) {
			return exposureNameToIdMapper[scaleToExposureNameMapper.LOW];
		}
		return exposureNameToIdMapper[scaleToExposureNameMapper.ACCEPTBALE];
	}

	if (!exposureId) {
		const id = defineExposureIdBasedOnRebaAcceptablePercentage(scores[1].percentage);
		form.setFieldValue([...formFieldName, 'exposure_id'], id);
	}

	return (
		<Row gutter={[0, 10]}>
			<Col span={24}>
				<Text strong>{I18n.get('Exposure')}:</Text>
			</Col>
			<Col span={24}>
				<Row gutter={[20, 20]} align="middle">
					<Col span={8}>
						<PieChart scores={scores?.map(({ percentage }) => percentage ?? 0)} />
					</Col>
					<Col span={14}>
						<Row>
							{scores?.map(({ id, level, text, percentage }) => (
								<Col key={id} span={24}>
									<span style={{ fontSize: '12px' }}>
										{I18n.get(text)}:{' '}
										<Text strong style={{ color: diagnosticColors[level] }}>
											{percentage}%
										</Text>
									</span>
								</Col>
							))}
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
