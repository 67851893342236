import React from 'react';
import { Text, Title } from '@/components/Typography';
import { Col, Row } from 'antd';

import { useLibertyMutualSubStepsContext } from '../context';
import { LiftAndLowerForm } from './LiftAndLowerForm';
import { PushAndPullForm } from './PushAndPullForm';
import { TaskName } from '../hooks/types/response';
import { InputsContainer } from './styles';
import { CarryForm } from './CarryForm';

export const Variables = () => {
	const { libertyMutual } = useLibertyMutualSubStepsContext();

	const taskToFieldMapper = {
		[TaskName.lift]: <LiftAndLowerForm />,
		[TaskName.lower]: <LiftAndLowerForm />,
		[TaskName.push]: <PushAndPullForm />,
		[TaskName.pull]: <PushAndPullForm />,
		[TaskName.carry]: <CarryForm />
	};

	return (
		<Row justify="center" gutter={[5, 20]}>
			<Col xs={24} style={{ padding: '0 2rem' }}>
				<Row align="middle">
					<Col xs={12}>
						<Title level={4}>Variables</Title>
					</Col>
				</Row>
				<Row gutter={[0, 20]} justify="center">
					<Col xs={24} style={{ marginBottom: '20px' }}>
						<Text level={5}>Insert the fields data for the selected task</Text>
					</Col>
				</Row>
				<Row justify="center" style={{ marginTop: '10px' }}>
					<InputsContainer xs={24}>{taskToFieldMapper[libertyMutual?.task?.name]}</InputsContainer>
				</Row>
			</Col>
		</Row>
	);
};
