import React from 'react';
import { Col, Form, Select } from 'antd';
import { I18n } from '@aws-amplify/core';

import { ACTION_PLAN_DEADLINE } from '@/util/enum';

export const DeadlineDate: React.FC = () => {
	return (
		<Col xs={24}>
			<Form.Item name={['deadline_date']} label={I18n.get('Order by deadline')} labelCol={{ span: 24 }}>
				<Select
					allowClear
					style={{ width: '100%' }}
					placeholder={I18n.get('Select an option')}
					optionFilterProp="children"
				>
					{ACTION_PLAN_DEADLINE.map((option) => (
						<Select.Option key={option.value} value={option.value}>
							{I18n.get(option.description)}
						</Select.Option>
					))}
				</Select>
			</Form.Item>
		</Col>
	);
};
