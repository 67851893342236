import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Image, Row } from 'antd';

import { useLibertyMutualSubStepsContext } from '../../../context';
import { TaskNameMapper } from '../../../hooks/types/response';
import { Body, CardContainer, Header } from '../styles';

const PULL_INITIAL_FORCE_ICON = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/result-pull-initial-force.svg';
const PUSH_INITIAL_FORCE_ICON = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/result-push-initial-force.svg';

interface InitialForceCardProps {
	task_name: TaskNameMapper.Pull | TaskNameMapper.Push;
}

export const InitialForceCard: React.FC<InitialForceCardProps> = ({ task_name }) => {
	const {
		libertyMutualResult: { summary }
	} = useLibertyMutualSubStepsContext();

	const initialForceSVGMapper = {
		[TaskNameMapper.Pull]: PULL_INITIAL_FORCE_ICON,
		[TaskNameMapper.Push]: PUSH_INITIAL_FORCE_ICON
	};

	return (
		<CardContainer>
			<Header>{I18n.get('Initial force')}</Header>
			<Body>
				<Row justify="center" style={{ padding: '1rem 0 0 0' }}>
					<Col>
						<Row justify="space-between">
							<Col xs={24}>
								<Image
									preview={false}
									draggable={false}
									alt={I18n.get(`Initial force: ${summary.initial_force}`)}
									src={initialForceSVGMapper[task_name]}
									width="60px"
								/>
							</Col>
							<Col xs={24} style={{ padding: '0.75rem 0', fontSize: '1.1rem' }}>
								{I18n.get(summary.initial_force)}
							</Col>
						</Row>
					</Col>
				</Row>
			</Body>
		</CardContainer>
	);
};
