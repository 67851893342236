import Api from '@/services/api';

import { CustomReportFilesRequest } from '@/components/ui/Inputs/types/request/customReport';
import { CustomReportFilesDTO } from '@/components/ui/Inputs/types/response/customReport';

import {
	SeraReportRequest,
	DownloadFileRequest,
	SetTasksFilesRequest,
	GetTasksFilesRequest,
	UpdateSeraResultRequest,
	CreateSeraResultRequest,
	DeleteSeraReport
} from '../../hooks/types/request';
import { FileTaskDTO, SeraReportDTO, TasksListDTO } from '../../hooks/types/response';

export class SeraService {
	async getSeraReport({
		organization_id,
		company_id,
		file_id,
		sera_summary_id
	}: SeraReportRequest): Promise<SeraReportDTO> {
		const url = `/sera/report/file/${file_id}?sera_summary_id=${sera_summary_id}&organization_id=${organization_id}&company_id=${company_id}`;
		const { data } = await Api.get<SeraReportDTO>(url);
		return data;
	}

	async createSeraReport(body: CreateSeraResultRequest): Promise<SeraReportDTO[]> {
		const url = '/sera/report/';
		const { data } = await Api.post<SeraReportDTO[]>(url, body);
		return data;
	}

	async updateSeraReport(body: UpdateSeraResultRequest): Promise<SeraReportDTO[]> {
		const url = '/sera/report/';
		const { data } = await Api.put<SeraReportDTO[]>(url, body);
		return data;
	}

	async deleteSeraReport(params: DeleteSeraReport): Promise<SeraReportDTO> {
		const url = `/sera/report/${params.id}`;
		const { data } = await Api.delete<SeraReportDTO>(url, { params });
		return data;
	}

	async getSeraFiles({
		custom_report_id,
		organization_id,
		sera_summary_id,
		report_type,
		company_id,
		fileName,
		offset,
		limit
	}: CustomReportFilesRequest): Promise<CustomReportFilesDTO> {
		const url = `/custom-report/list/${custom_report_id}`;
		const { data } = await Api.get<CustomReportFilesDTO>(url, {
			params: {
				limit,
				offset,
				company_id,
				report_type,
				sera_summary_id,
				organization_id,
				file_name: fileName
			}
		});
		return data;
	}

	async downloadFile({ company_id, organization_id, file }: DownloadFileRequest): Promise<string> {
		const url = '/upload/create-url-download';
		const { data } = await Api.post<string>(url, {
			file_id: file.id,
			company_id,
			original_name: file.original_name,
			organization_id
		});
		return data;
	}

	async setTasksFiles(payload: SetTasksFilesRequest): Promise<FileTaskDTO[]> {
		const url = '/task/files';
		const { data } = await Api.post<FileTaskDTO[]>(url, payload);
		return data;
	}

	async getTasksFiles(payload: GetTasksFilesRequest): Promise<TasksListDTO[]> {
		const url = '/task/files';
		const { data } = await Api.get<TasksListDTO[]>(url, { params: payload });
		return data;
	}
}
