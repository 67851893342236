import styled from 'styled-components';
import { Card } from 'antd';

const ACTIVE_COLOR = '#2CC852';
const INATIVE_COLOR = '#E74150';

interface IContainerProps {
	$isActive: boolean;
}

export const Container = styled(Card)<IContainerProps>`
	.ant-card-body {
		padding: 10px 16px 10px 16px;
	}

	.ant-space {
		gap: 0 !important;
	}
	margin-top: 10px;
	width: fit-content;
	min-width: 55%;
	height: fit-content;
	border-radius: 5px;
	color: ${({ $isActive }) => ($isActive ? ACTIVE_COLOR : INATIVE_COLOR)};
	border-color: ${({ $isActive }) => ($isActive ? ACTIVE_COLOR : INATIVE_COLOR)};
	:hover {
		border-color: ${({ $isActive }) => ($isActive ? ACTIVE_COLOR : INATIVE_COLOR)};
	}
`;
