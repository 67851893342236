import React, { ReactNode } from 'react';
import { Avatar, Col, List } from 'antd';

import { QrcodeOutlined, RightOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';

import { Method, MethodListItem, MethodTitle, NextButton } from './styles';
import { useTFAContext } from '../context';
import { TFATypes } from '../types/request';

interface DataType {
	title: string;
	description: string;
	icon: ReactNode;
	type: TFATypes;
}

export const Methods = () => {
	const { handleSelectedType, handleNextStep } = useTFAContext();

	const list: DataType[] = [
		// {
		// 	title: 'E-mail',
		// 	description:
		// 		'With this method, you will receive an e-mail with your validation code every time you login. This code has an expiration date.',
		// 	icon: <MailOutlined />,
		// 	type: 'EMAIL'
		// },
		{
			title: 'Authenticator app',
			description:
				'With this method, you will scan a QRCode so you can use your favorite authenticator app (such as Google Authenticator or Microsoft Authenticator). Then, all you have to do is type the code shown in your app when you login. This code changes every few seconds.',
			icon: <QrcodeOutlined />,
			type: 'AUTHENTICATOR'
		}
	];

	function handleClick(type: TFATypes) {
		handleSelectedType(type);
		handleNextStep();
	}

	return (
		<Col span={24}>
			<List
				bordered
				itemLayout="horizontal"
				dataSource={list}
				renderItem={(item) => (
					<MethodListItem onClick={() => handleClick(item.type)}>
						<Method
							avatar={<Avatar size="large">{item.icon}</Avatar>}
							title={<MethodTitle>{I18n.get(item.title)}</MethodTitle>}
							description={I18n.get(item.description)}
						/>
						<NextButton type="text" icon={<RightOutlined />} />
					</MethodListItem>
				)}
			/>
		</Col>
	);
};
