import React, { useEffect, useState } from 'react';
import { Col, Form, InputNumber, Row, Select } from 'antd';
import { I18n } from '@aws-amplify/core';
import { v4 as uuidv4 } from 'uuid';

import { useCalculateAdditionalToolScore, useGetAdditionalItemsOptions } from '@/hooks';
import type { AdditionalItemsResults, GetAdditionalToolScore } from '@/hooks';
import { useApplicationContext } from '@/context/v1/Application/context';
import { getDecimalSeparator } from '@/utils/getDecimalSeparator';
import { useStepKeysContext } from '../DefaultSelects/context';
import type { AditionalItemProps, Options } from './types';
import { fieldsUnitTexts } from './utils/FieldsUnitTexts';
import { ConditionCard } from '../../ConditionCard';
import { Text } from '@/components/Typography';
import { StaticPostureActions } from './enum';

const { useFormInstance, useWatch } = Form;
const { formatLabelWithUnit, formatPlaceholderWithUnit } = fieldsUnitTexts;

export function SelectInputAndInput({ additionalItem }: Readonly<AditionalItemProps>) {
	const { id, title_1, title_2, title_3, title_4, unit_1, unit_2, unit_3, unit_4 } = additionalItem;

	const form = useFormInstance();
	const { formFieldName, stepKey } = useStepKeysContext();
	const { organization, company } = useApplicationContext();

	const [timer, setTimer] = useState<NodeJS.Timeout>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [valueOne, setValueOne] = useState<number | undefined>(undefined);
	const [valueTwo, setValueTwo] = useState<number | undefined>(undefined);
	const [valueThree, setValueThree] = useState<number | undefined>(undefined);
	const [selectValue, setSelectValue] = useState<string | undefined>(undefined);

	const payload: GetAdditionalToolScore = {
		value_1: valueOne,
		value_2: valueTwo,
		value_3: valueThree,
		company_id: company?.id,
		organization_id: organization?.id,
		custom_report_step_key_id: stepKey.id,
		additional_item_option_id_1: selectValue
	};

	const { data: additionalToolScore, isFetching: isFetchingAdditionalToolScore } =
		useCalculateAdditionalToolScore(payload);
	const { data: options, isLoading: isOptionsLoading } = useGetAdditionalItemsOptions({
		company_id: company?.id,
		organization_id: organization?.id,
		custom_report_step_key_id: stepKey.id
	});

	const formValueOne = useWatch([...formFieldName, 'additional_items', id, 'value_1']);
	const formValueTwo = useWatch([...formFieldName, 'additional_items', id, 'value_2']);
	const formValueThree = useWatch([...formFieldName, 'additional_items', id, 'value_3']);
	const selectFormValue = useWatch([...formFieldName, 'additional_items', id, 'additional_item_option_id_1']);

	const isDefaultFieldsFilled = !!selectFormValue && !!formValueTwo;

	const selectFieldName = options.find(({ id }) => id === selectFormValue)?.name;

	function filterOption(input: string, option?: Options): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function handleDebounce(): void {
		clearTimeout(timer);

		const debounce = setTimeout(() => {
			setValueOne(formValueOne);
			setValueTwo(formValueTwo);
			setValueThree(formValueThree);
			setSelectValue(selectFormValue);

			setIsLoading(false);
		}, 1200);

		setTimer(debounce);
	}

	function handleChangeSelect(): void {
		form.setFieldValue([...formFieldName, 'additional_items', id, 'value_1'], undefined);
		form.setFieldValue([...formFieldName, 'additional_items', id, 'value_2'], undefined);
		form.setFieldValue([...formFieldName, 'additional_items', id, 'value_3'], undefined);
	}

	function handleClearSelect(): void {
		form.resetFields([...formFieldName, 'additional_items', id]);
	}

	function isCrouchingSelected(): boolean {
		return selectFieldName === StaticPostureActions.CROUCHING;
	}

	function renderDynamicInputs(): JSX.Element {
		if (isCrouchingSelected()) {
			return (
				<Col span={10}>
					<Row gutter={[0, 5]}>
						<Col span={24}>
							<Text strong>{title_4}</Text>
						</Col>
						<Col span={19}>
							<Form.Item
								style={{ margin: 0 }}
								name={[...formFieldName, 'additional_items', id, 'value_3']}
							>
								<InputNumber
									min={0}
									style={{ width: '100%' }}
									placeholder={formatPlaceholderWithUnit('0', unit_4)}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Col>
			);
		}

		return (
			<Col span={10}>
				<Row gutter={[0, 5]}>
					<Row gutter={[5, 0]}>
						<Col>
							<Text strong>{title_2}</Text>
						</Col>
					</Row>
					<Col span={19}>
						<Form.Item style={{ margin: 0 }} name={[...formFieldName, 'additional_items', id, 'value_1']}>
							<InputNumber
								min={0}
								style={{ width: '100%' }}
								decimalSeparator={getDecimalSeparator()}
								placeholder={formatPlaceholderWithUnit('0', unit_2)}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Col>
		);
	}

	function areAllFieldsFilled() {
		if (isCrouchingSelected()) {
			return isDefaultFieldsFilled && !!formValueThree;
		}

		return isDefaultFieldsFilled && !!formValueOne;
	}

	useEffect(() => {
		if (areAllFieldsFilled()) {
			setIsLoading(true);
			handleDebounce();
		}
	}, [formValueOne, formValueTwo, formValueThree, selectFormValue]);

	useEffect(() => {
		const result: AdditionalItemsResults[] = [
			{
				id: uuidv4(),
				value_1: valueOne,
				value_2: valueTwo,
				value_3: valueThree,
				additional_item_option_id_1: selectValue,
				result: additionalToolScore?.result ?? 0
			}
		];

		form.setFieldValue([...formFieldName, 'additional_items', id, 'results'], result);
	}, [additionalToolScore]);

	return (
		<Row gutter={[0, 15]} key={id}>
			<Col span={24}>
				<Row align="bottom" gutter={[14, 20]}>
					<Col xs={24} xxl={24}>
						<Row gutter={[0, 5]}>
							<Col span={24}>
								<Text strong>{formatLabelWithUnit(title_1, unit_1)}</Text>
							</Col>
							<Col xs={18} xxl={18}>
								<Form.Item
									style={{ margin: 0 }}
									name={[...formFieldName, 'additional_items', id, 'additional_item_option_id_1']}
								>
									<Select
										showSearch
										allowClear
										loading={isOptionsLoading}
										disabled={isOptionsLoading}
										filterOption={filterOption}
										onClear={handleClearSelect}
										onChange={handleChangeSelect}
										placeholder={I18n.get(title_1)}
										options={options?.map(({ id, description }) => ({
											label: `${I18n.get(description)}`,
											value: id
										})).sort((a, b) => a.label.localeCompare(b.label))}
									/>
								</Form.Item>
							</Col>
						</Row>
					</Col>
					{renderDynamicInputs()}
					<Col span={10}>
						<Row gutter={[0, 5]}>
							<Col span={24}>
								<Text strong>{title_3}</Text>
							</Col>
							<Col span={19}>
								<Form.Item
									style={{ margin: 0 }}
									name={[...formFieldName, 'additional_items', id, 'value_2']}
								>
									<InputNumber
										min={0}
										style={{ width: '100%' }}
										placeholder={formatPlaceholderWithUnit('0', unit_3)}
									/>
								</Form.Item>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<ConditionCard
					result={areAllFieldsFilled() ? additionalToolScore.result : 0}
					isLoading={isLoading || isFetchingAdditionalToolScore}
				/>
			</Col>
		</Row>
	);
}
