import React from 'react';
import { TextInput } from './TextInput';
import { Col, InputNumber } from 'antd';

interface WorkingPopulationOthersProps {
	validate: () => Promise<void>;
	fieldName: string;
}

export const WorkingPopulationOthers: React.FC<WorkingPopulationOthersProps> = ({ fieldName, validate }) => {
	return (
		<Col xs={6}>
			<TextInput namePath={[fieldName, 'working_population_others']} title="Others">
				<InputNumber onChange={validate} min={0} />
			</TextInput>
		</Col>
	);
};
