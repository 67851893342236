import React from 'react';
import { Row, Col } from 'antd';
import { Information } from './information';

export function ReportInformation() {
	return (
		<Row justify="start" gutter={[8, 8]} style={{ marginTop: '2rem' }}>
			<Col xs={24}>
				<Information />
			</Col>
		</Row>
	);
}
