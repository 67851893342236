import React from 'react';

import { LibertyMutualSubStepsProvider } from './context';
import { Report } from './Report';

export const SubSteps = () => {
	return (
		<LibertyMutualSubStepsProvider>
			<Report />
		</LibertyMutualSubStepsProvider>
	);
};
