import { combineReducers } from 'redux';

import plan from './plan';
import auth from './auth';
import user from './user';
import upload from './upload';
import report from './report';
import reports from './reports';
import workspace from './workspace';
import brief_best from './brief_best';
import organization from './organization';

const reducers = (history) =>
	combineReducers({
		auth,
		user,
		plan,
		upload,
		report,
		reports,
		workspace,
		brief_best,
		organization
	});

export default reducers;
