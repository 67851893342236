import { useMutation, useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';
import Api from '@/services/api';
import {
	BackCompressiveForceEstimationReportDTO,
	CreateReport
} from '@/components/views/Report/BackCompressiveForceEstimation/types';

const DEFAULT_URL = '/ergonomic-tool/back-compressive-force-estimation';

async function createReport(payload: CreateReport) {
	const { data } = await Api.post<BackCompressiveForceEstimationReportDTO>(DEFAULT_URL, payload);
	return data;
}

export const useCreateReport = () => {
	const queryClient = useQueryClient();
	return useMutation((body: CreateReport) => createReport(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message));
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['back_compressive_force_estimation_report']);
		}
	});
};
