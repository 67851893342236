import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import Api from '../services/api';
import { PrivateRoutes } from './PrivateRoutes';
import Spinner from '../components/Layout/Spinner';
import { Container } from '../components/Container';
import { controlSpinnerRoute, isAuthenticated } from '../redux/auth/actions';

import { Login } from '../pages/Login';
import { ResetPassword } from '../pages/ResetPassword';
import { ForgotPassword } from '../pages/ForgotPassword';

const CustomRouter = ({ contextComponent, component, isPrivate, path, ...rest }) => {
	const isLoading = useSelector((state) => state.auth.spinnerRoute);
	const isAuthenticated = useSelector((state) => state.auth.authenticated);

	const Component = component;
	const Provider = contextComponent;

	if (isLoading) {
		return <Spinner />;
	}

	if (isPrivate && !isAuthenticated) {
		return <Redirect to="/login" />;
	}

	if (path !== '/' && isAuthenticated) {
		return <Redirect to="/" />;
	}

	return (
		<Route>
			{!contextComponent ? (
				<Component {...rest} />
			) : (
				<Provider>
					<Component {...rest} />
				</Provider>
			)}
		</Route>
	);
};

const Routes = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		/* Verificando se usuário já tem uma sessão  */

		const info_user = JSON.parse(localStorage.getItem('info_user'));

		if (info_user && info_user.token) {
			Api.defaults.headers.Authorization = `Bearer ${info_user.token}`;
			dispatch(isAuthenticated(true));
		}

		dispatch(controlSpinnerRoute());
	}, [dispatch]);

	return (
		<Switch>
			<CustomRouter exact path="/login" component={Login} />
			<CustomRouter exact path="/forgot-password" component={ForgotPassword} />
			<CustomRouter exact path="/password-reset/:token/:email" component={ResetPassword} />
			<CustomRouter isPrivate path="/" children={PrivateRoutes} component={Container} />
		</Switch>
	);
};

export default Routes;
