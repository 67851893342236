import React from 'react';
import { Form, Skeleton } from 'antd';
import { I18n } from '@aws-amplify/core';
import { CheckboxOptionType } from 'antd/es/checkbox';

import { CustomRadioGroup } from '../../../styles';
import { useGetWorkerSelfEvaluations } from '@/hooks';
import { useApplicationContext } from '@/context/v1/Application/context';

interface RadioInputProps {
	fieldName: string;
}

export const RadioInput: React.FC<RadioInputProps> = ({ fieldName }) => {
	const { organization, company } = useApplicationContext();
	const { data, isLoading } = useGetWorkerSelfEvaluations({
		company_id: company?.id,
		organization_id: organization?.id
	});

	function handleOptionsValues(): CheckboxOptionType[] {
		return data.map(({ id, description }) => ({
			value: id,
			label: `${I18n.get(description)}`
		}));
	}

	if (isLoading) {
		return <Skeleton active />;
	}

	return (
		<Form.Item name={[fieldName, 'worker_self_evaluation_id']}>
			<CustomRadioGroup options={handleOptionsValues()} />
		</Form.Item>
	);
};
