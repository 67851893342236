import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

import { LinesRequest } from '@/components/ui/Inputs/types/request/line';
import { LineDTO } from '@/components/ui/Inputs/types/response/line';

async function getLines({ organization_id, company_id, sector_id }: LinesRequest): Promise<LineDTO[]> {
	const url = `/line/${sector_id}?organization_id=${organization_id}&company_id=${company_id}`;
	const { data } = await Api.get<LineDTO[]>(url);
	return data;
}

export function useLines({ organization_id, company_id, sector_id }: LinesRequest) {
	const enabled = !!organization_id && !!company_id && !!sector_id;

	const lines = useQuery(
		['lines', { organization_id, company_id, sector_id }],
		() => getLines({ organization_id, company_id, sector_id }),
		{ enabled }
	);
	return lines;
}
