import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, InputNumber } from 'antd';

import { useBackCompressiveForceEstimationContext } from '../../context';
import { PersonIllustration } from './PersonIllustration';
import { Text, Title } from '@/components/Typography';
import { InputsContainer } from '../styles';

export function Variables() {
	const { measurements } = useBackCompressiveForceEstimationContext();

	return (
		<Row justify="center" gutter={[5, 20]}>
			<Col xs={24} style={{ padding: '0 2rem' }}>
				<Row align="middle">
					<Col xs={12}>
						<Title level={4}>Variables</Title>
					</Col>
				</Row>
				<Row gutter={[0, 20]} justify="center">
					<Col xs={24} style={{ marginBottom: '20px' }}>
						<Text>{I18n.get("Enter the operator's activity-related data below")}</Text>
					</Col>
				</Row>
				<Row justify="center" style={{ marginTop: '10px' }}>
					<InputsContainer xs={6}>
						<Form.Item
							name={['back_compressive_force_estimation_inputs', 'person_weight']}
							label={`${I18n.get("Person's weight")} (${measurements?.mass})`}
							labelCol={{ span: 24 }}
							rules={[
								{
									required: true,
									message: I18n.get('Insert the person weight')
								}
							]}
						>
							<InputNumber min={0} placeholder="0" decimalSeparator={measurements?.decimal_separator} />
						</Form.Item>
					</InputsContainer>
					<InputsContainer xs={6} offset={2}>
						<Form.Item
							name={['back_compressive_force_estimation_inputs', 'person_height']}
							label={`${I18n.get("Person's height")} (${measurements?.length})`}
							labelCol={{ span: 24 }}
							rules={[
								{
									required: true,
									message: I18n.get('Insert the person height')
								}
							]}
						>
							<InputNumber min={0} placeholder="0" decimalSeparator={measurements?.decimal_separator} />
						</Form.Item>
					</InputsContainer>
				</Row>
				<Row justify="center" align={'middle'}>
					<InputsContainer xs={6} style={{ marginTop: '-15%' }}>
						<Form.Item
							name={['back_compressive_force_estimation_inputs', 'trunk_angle_to_horizontal']}
							label={`${I18n.get('Trunk angle to Horizontal')} (°)`}
							labelCol={{ span: 24 }}
							rules={[
								{
									required: true,
									message: I18n.get('Insert a trunk angle')
								}
							]}
						>
							<InputNumber min={0} placeholder="0" decimalSeparator={measurements?.decimal_separator} />
						</Form.Item>
					</InputsContainer>
					<InputsContainer xs={6}>
						<PersonIllustration width={'100%'} />
					</InputsContainer>
					<InputsContainer offset={1} xs={6} style={{ marginTop: '-15%' }}>
						<Form.Item
							name={['back_compressive_force_estimation_inputs', 'load_in_hands']}
							label={`${I18n.get('Load in hands')} (${measurements?.mass})`}
							labelCol={{ span: 24 }}
							rules={[
								{
									required: true,
									message: I18n.get('Insert the load in hands')
								}
							]}
						>
							<InputNumber min={0} placeholder="0" decimalSeparator={measurements?.decimal_separator} />
						</Form.Item>
					</InputsContainer>
				</Row>
				<Row justify="center" align={'middle'}>
					<InputsContainer xs={6} style={{ marginTop: '-16%', marginLeft: '24%' }}>
						<Form.Item
							name={[
								'back_compressive_force_estimation_inputs',
								'horizontal_distance_from_hands_to_low_back'
							]}
							label={`${I18n.get('Horizontal distance from hands to low back')} (${
								measurements?.length
							})`}
							labelCol={{ span: 24 }}
							rules={[
								{
									required: true,
									message: I18n.get('Insert a horizontal distance')
								}
							]}
						>
							<InputNumber min={0} placeholder="0" decimalSeparator={measurements?.decimal_separator} />
						</Form.Item>
					</InputsContainer>
				</Row>
			</Col>
		</Row>
	);
}
