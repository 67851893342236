import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { useApplicationContext } from '@/context/v1/Application/context';
import { EvaluatorSelect } from '@/components/ui/Inputs/EvaluatorSelect';
import { useEvaluators } from '@/hooks/v1';

const { useFormInstance, useWatch } = Form;

interface EvaluatorProps {
	onValueChange: (changed: boolean) => void;
}

export const Evaluator: React.FC<EvaluatorProps> = ({ onValueChange }) => {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();
	const { company, organization } = useApplicationContext();

	const evaluatorId = useWatch(['evaluator_id'], form);

	const {
		data: evaluators,
		isLoading,
		isFetching,
		isError
	} = useEvaluators({
		organization_id: organization?.id,
		company_id: company?.id
	});

	function handleEvaluatorSelect(id: string) {
		setFieldValue(['evaluator_id'], id);
		onValueChange(true);
	}

	function handleClearEvaluators() {
		setFieldValue(['evaluator_id'], undefined);
	}

	return (
		<Col xs={24} sm={12}>
			<Form.Item
				name={['evaluator_id']}
				label={I18n.get('Evaluator')}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: true,
						message: I18n.get('Select the evaluator')
					}
				]}
			>
				<EvaluatorSelect
					allowCreation={true}
					loading={isFetching}
					evaluators={evaluators}
					evaluatorId={evaluatorId}
					fieldName={['evaluator_id']}
					disabled={isLoading || isFetching || isError}
					handleSelectEvaluator={handleEvaluatorSelect}
					handleClearEvaluators={handleClearEvaluators}
				/>
			</Form.Item>
		</Col>
	);
};
