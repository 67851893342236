import styled from 'styled-components';
import { Row, Col, Collapse } from 'antd';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';

export const ResultCard = styled.div`
	width: 7rem;
	height: fit-content;
	padding: 10px;
	border-radius: 4px;
	border: 1px solid #2f54eb;
	background: rgba(47, 84, 235, 0.05);
	color: #2f54eb;

	text-align: center;
	font-size: 22px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
`;

export const HighestRpn = styled(Row)`
	border-radius: 5px;
	border: 1px solid #e6e8e8;
	display: flex;
`;

export const RiskCard = styled(Col)<{ $cardColor: string }>`
	border-radius: 5px 0 0 5px;
	color: #fff;
	text-align: center;
	font-weight: bold;
	background-color: ${({ $cardColor }) => $cardColor};
	display: flex;
	align-items: center;
	justify-content: center;

	@media (min-width: 576px) {
		padding: 0 12px;
	}

	@media (min-width: 1200px) {
		padding: 0 22px;
	}
`;

export const RpnCard = styled(Col)`
	border-right: solid 1px #e6e8e8;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	font-size: 16px;
`;

export const DescriptionCard = styled(Col)`
	display: flex;
	align-items: center;
	padding: 15px;

	.ant-typography {
		min-width: fit-content;
		margin-right: 15px;
	}
`;

export const TableContainer = styled.div<{ $alignTitle?: 'center' | 'end' | 'start' }>`
	.ant-table {
		min-width: 320px;
		border-radius: 10px;
		border: 1px solid #e6e8e8;
		.ant-table-thead
			> tr
			> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
				[colspan]
			)::before {
			background-color: #fff;
			height: 80%;
		}

		.column-disabled {
			display: none;
		}

		.column-active {
			&::before {
				display: none;
			}
			border-radius: 0 10px 0 0;
		}

		.ant-table-cell {
			border-left: 1px solid #e6e8e8;
			padding-top: 0.4rem !important;
			padding-bottom: 0.4rem !important;
		}

		.ant-table-wrapper {
			display: flex;
			justify-content: center;
			.ant-spin-nested-loading {
				width: 25rem;
			}
		}
		.ant-table-tbody {
			tr {
				background-color: F8F8F8;
			}
		}
		.ant-table-thead {
			th {
				font-weight: bold;
				background-color: #e6e8e8;
				text-align: ${({ $alignTitle }) => $alignTitle ?? 'start'};
				padding-top: 0.4rem !important;
				padding-bottom: 0.4rem !important;
			}
			.ant-table-cell::before {
				background-color: #fff;
			}
			.ant-table-cell:first-child {
				border-radius: 10px 0 0 0;
			}
			.ant-table-cell:last-child {
				border-radius: 0 10px 0 0;
			}
		}
	}
`;
export const TableContainerHorizontal = styled.div`
	.ant-table {
		min-height: 350px;
		width: 100%;
		border-radius: 10px;
		border: 1px solid #e6e8e8;
		.ant-table-row:first-child {
			border-radius: 10px 0 0 0;
			vertical-align: top;
			.ant-table-cell:first-child {
				background-color: #e6e8e8;
				border-radius: 10px 0 0 0;
			}
		}
		.ant-table-row:last-child {
			border-radius: 10px 0 0 0;
			vertical-align: top;
			.ant-table-cell:first-child {
				background-color: #e6e8e8;
				border-radius: 0px 0 0 10px;
			}
		}

		.ant-table-cell:first-child {
			text-align: start;
			vertical-align: top;
			font-weight: bold;
			height: 175px;
			width: 150px;
			::after {
				content: ':';
			}
		}
	}
`;

export const RowCustom = styled(Row)<{ $isSmallScreen: Partial<Record<Breakpoint, boolean>> }>`
	.charts-container-worker {
		border-radius: 10px 10px 0 0;
		border-bottom: none;
		${({ $isSmallScreen }) =>
			!$isSmallScreen.xxl && 'border-radius:10px 0 0 10px; border-right:none ; border-bottom: 1px solid #e6e8e8'};
		${({ $isSmallScreen }) =>
			!$isSmallScreen.xl && 'border-radius:10px 10px 0 0; border-bottom:none ; border-right: 1px solid #e6e8e8'};
	}
	.charts-container-stress {
		border-radius: 0 0 10px 10px;
		${({ $isSmallScreen }) => !$isSmallScreen.xxl && 'border-radius:0 10px 10px 0'};
		${({ $isSmallScreen }) => !$isSmallScreen.xl && 'border-radius:0 0 10px 10px'};
	}
`;

export const ChartsContainer = styled.div<{ $isSmallScreen?: boolean }>`
	display: flex;
	justify-content: center;
	align-content: center;
	height: 100%;
	min-height: 160px;

	border: 1px solid #e6e8e8;
`;

type StressLevelStyle = {
	percentage?: number;
};

export const WorkingPopulationContainer = styled.div`
	padding: 1rem 0 0 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const StressLevelContainer = styled.div`
	padding: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 270px;
`;

export const ChartContainer = styled.div<StressLevelStyle>`
	height: fit-content;
	min-height: 40px;
	display: flex;
	justify-content: end;
	position: relative;

	#level {
		height: 100%;
		position: absolute;
		height: 1.5rem;
		z-index: 10;
		background: #e6e8e8;
		border: 1px solid e6e8e8;
		border-radius: ${(props) => (props.percentage === 100 ? '1rem' : '0rem 1rem 1rem 0')};
		width: ${(props) => props.percentage + '%'};
		margin-top: 1px;
	}
`;

export const StressLevelChart = styled.div<StressLevelStyle>`
	background: rgb(44, 200, 82);
	background: linear-gradient(
		90deg,
		rgba(44, 200, 82, 1) 0%,
		rgba(255, 222, 49, 1) 25%,
		rgba(247, 138, 56, 1) 50%,
		rgba(231, 65, 80, 1) 75%,
		rgba(155, 84, 226, 1) 100%
	);
	height: ${(props) => (props.percentage === 100 ? '1rem' : '1.5rem')};
	border-radius: 1rem;
	width: ${(props) => (props.percentage === 100 ? '90%' : '100%')};
	position: absolute;
	top: 1px;
`;

export const StressLevel = styled.div``;

export const Stress = styled.span`
	font-size: 1rem;
	font-weight: 600;
`;

export const ENUM_WORKPOPULATION_SCALE = {
	men: {
		caption: 'Men',
		fontColor: '#2F54EB',
		trailColor: '#2F54EB'
	},
	others: {
		caption: 'Others',
		fontColor: '#17A93B',
		trailColor: '#17A93B'
	},
	women: {
		caption: 'Women',
		fontColor: '#EFAC1E',
		trailColor: '#EFAC1E'
	}
};

export const ChartContainerWorking = styled.div<StressLevelStyle>`
	.risk-horizontal-chart {
		align-items: center;

		.risk-bar {
			max-height: 23px;
			height: 23px;
		}
	}
`;

export const LegendContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	width: 100%;
`;

export const ChartLegend = styled.div<{ $borderColor: string }>`
	width: 12px;
	height: 4px;
	border-radius: 2px;
	background-color: ${({ $borderColor }) => $borderColor};
`;

export const RiskCardResult = styled.div<{ $riskColor: string }>`
	display: flex;
	flex-wrap: wrap;
	width: 90%;
	height: 100%;
	text-align: center;
	align-items: center;
	font-weight: bold;
	margin-left: 10%;

	.ant-row {
		width: 100%;
		height: 100%;
	}

	.ant-col:first-child {
		height: 50%;
		width: 100%;
		padding-top: 8% !important;
		background-color: ${({ $riskColor }) => $riskColor};
		border-radius: 10px 10px 0 0;
		.ant-typography {
			color: #ffffff;
		}
	}
	.ant-col:last-child {
		width: 100%;
		height: 50%;
		padding-top: 10%;
		border: 1px solid #e6e8e8;
		border-top: none;
		border-radius: 0 0 10px 10px;
	}
`;
export const TableContainerRpn = styled.div<{ $riskColor: string }>`
	height: 100%;
	.ant-table {
		border-radius: 10px;
		border: 1px solid #e6e8e8;
		height: max-content !important;

		.ant-table-tbody {
			tr {
				font-weight: bold;
				text-align: center;
				background-color: F8F8F8;

				.ant-table-cell {
					padding: 5px !important;
					font-size: 17px;
				}
			}
		}
		.ant-table-thead {
			th {
				border-radius: 10px 0 0 0;
				font-weight: bold;
				color: #fff;
				font-size: 15px;
				padding: 1px !important;
				background-color: ${({ $riskColor }) => $riskColor};
				height: 100%;
			}

			.ant-table-cell {
				text-align: center;
				font-size: 17px;
				border-radius: 10px 10px 0 0;
			}
		}
	}
`;

interface RiskRowTableProps {
	$riskColor?: string;
	$isMoreThanOne?: boolean;
	$isFirstColumn?: boolean;
}

export const RiskRowTable = styled.div<RiskRowTableProps>`
	background-color: ${({ $riskColor }) => $riskColor};
	position: absolute;

	left: ${({ $isFirstColumn }) => ($isFirstColumn ? '101%' : '108%')};
	bottom: 0px;
	width: 25px;
	height: 170%;

	${({ $isMoreThanOne }) =>
		$isMoreThanOne &&
		`
		left: 105%;
		bottom: 5px;
		width: 25px;
		height: 30px;
	`}
	border-radius: 7px;
`;

export const FormContainer = styled(Collapse)`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: #ffffff;

	.ant-collapse-item {
		width: 100%;
		border: none;
		margin-bottom: 1rem;
		border-radius: 0.25rem;

		.ant-collapse-header {
			padding: 0;
			display: flex;
			align-items: center;
			flex-direction: row;
		}

		.ant-collapse-expand-icon {
		}

		.ant-collapse-content-active {
			padding: 0 0 1rem 0;
		}

		:last-child {
			border: none;
			border-radius: 0.25rem;
			margin-bottom: 1rem;
		}
	}

	.ant-collapse-item-active {
		border: none;

		:last-child {
			border: none;
		}
	}
`;
