import React, { createContext, ReactNode, useContext, useState } from 'react';

import { Context } from './index.types';
import { FileDTO } from '@/components/ui/Inputs/types/response/file';

type NioshProviderProps = {
	children: ReactNode;
};

const NioshContext = createContext<Context>({} as Context);

export function NioshProvider({ children }: NioshProviderProps) {
	const [file, setFile] = useState<FileDTO>({} as FileDTO);

	function handleFile(file: FileDTO | undefined) {
		if (file) {
			setFile(file);
		}
	}

	const contextValue: Context = {
		file,
		handleFile
	};

	return <NioshContext.Provider value={contextValue}>{children}</NioshContext.Provider>;
}

export function useNioshContext() {
	const context = useContext(NioshContext);
	return context;
}
