import { ReportEvent } from '@/hooks/useSocket';

import {
	ErgonomicToolMapper,
	ErgonomicToolsDTO,
	ErgonomicToolsName
} from '@/components/ui/Inputs/types/response/ergonomicTool';
import { IntegratedErgonomicToolDTO } from '@/components/ui/Inputs/types/response/customReport';
import { FileForCustomReport } from '@/components/ui/Inputs/types/response/file';

interface ErgonomicToolsInterface {
	mapErgonomicTools(file: FileForCustomReport, socketTools: SocketTools): ErgonomicToolMapper;
	mapTools(ergonomicTool: ErgonomicToolMapper, integratedTools: IntegratedErgonomicToolDTO[]): ErgonomicToolMapper;
}

type SocketTools = {
	niosh: ReportEvent;
	kimMHO: ReportEvent;
	kimPushPull: ReportEvent;
	strainIndex: ReportEvent;
};

export class ErgonomicToolsService implements ErgonomicToolsInterface {
	public mapErgonomicTools(file: FileForCustomReport, socketTools: SocketTools): ErgonomicToolMapper {
		const { kimMHO, kimPushPull, niosh, strainIndex } = socketTools;
		const ergonomicTool: ErgonomicToolMapper = {
			niosh: {
				isActive: this.checkToolByFile(niosh, file) ?? this.fileHasTool(file.niosh) ?? false,
				data: niosh.data ?? file.niosh ?? undefined
			},
			kim_mho: {
				isActive: this.checkToolByFile(kimMHO, file) ?? this.fileHasTool(file.kim_mho) ?? false,
				data: kimMHO.data ?? file.kim_mho ?? undefined
			},
			strain_index: {
				isActive: this.checkToolByFile(strainIndex, file) ?? this.fileHasTool(file.strain_index) ?? false,
				data: strainIndex.data ?? file.strain_index ?? undefined
			},
			kim_pp: {
				isActive: this.checkToolByFile(kimPushPull, file) ?? this.fileHasTool(file.kim_push_pull) ?? false,
				data: kimPushPull.data ?? file.kim_push_pull ?? undefined
			}
		};
		return ergonomicTool;
	}

	public mapTools(
		ergonomicTool: ErgonomicToolMapper,
		integratedTools: IntegratedErgonomicToolDTO[]
	): ErgonomicToolMapper {
		const mappedTools: ErgonomicToolMapper = {} as ErgonomicToolMapper;
		Object.keys(ergonomicTool).forEach((tool) => {
			const existingTool = integratedTools.find((integratedTool) => integratedTool.name === tool);
			if (existingTool) {
				mappedTools[tool as ErgonomicToolsName] = ergonomicTool[tool as ErgonomicToolsName];
			}
		});
		return mappedTools;
	}

	private checkToolByFile(tool: ReportEvent, file: FileForCustomReport) {
		const existingToolForFile = tool.data?.file_id === file.id;
		if (!existingToolForFile) {
			return undefined;
		}
		return tool.status;
	}

	private fileHasTool(tool: ErgonomicToolsDTO) {
		return !!tool?.id;
	}
}
