import React from 'react';
import { NamePath } from 'antd/lib/form/interface';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { StressLevelDTO } from '@/components/ui/Inputs/types/response/stressLevel';
import { StressLevelSelect } from '@/components/ui/Inputs/StressLevelSelect';
import { Spinner } from '@/components/Spinner';

import { CustomStepKeyFormItem, ScaleColor, scaleColorMapper } from './styles';

const { useFormInstance, useWatch } = Form;

interface StressLevelsProps {
	isError: boolean;
	disabled: boolean;
	isLoading: boolean;
	isFetching: boolean;
	fieldName: NamePath;
	fieldScoreName: NamePath;
	stressLevels: StressLevelDTO[];
}

export const StressLevels: React.FC<StressLevelsProps> = ({
	isError,
	disabled,
	fieldName,
	isLoading,
	isFetching,
	stressLevels,
	fieldScoreName
}) => {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();

	const stressLevelId = useWatch(fieldName, form);

	const stressLevel = stressLevels?.find((stressLevel) => stressLevelId === stressLevel.id);
	if (stressLevel) {
		setFieldValue(fieldScoreName, stressLevel.score);
	}
	const color = scaleColorMapper[stressLevel?.score ?? 0];

	async function handleStressLevelSelect(id: string) {
		setFieldValue(fieldName, id);
	}

	async function handleClearStressLevel() {
		setFieldValue(fieldName, undefined);
	}

	if (isLoading) {
		return (
			<Col xs={22} sm={18} md={19} lg={17} xl={20} xxl={21}>
				<Spinner size={12} />
			</Col>
		);
	}

	return stressLevels && stressLevels.length > 0 ? (
		<>
			<Col xs={22} sm={18} md={19} lg={17} xl={20} xxl={21}>
				<CustomStepKeyFormItem
					name={fieldName}
					label={I18n.get('Stress level')}
					labelCol={{ span: 24 }}
					rules={[
						{
							required: true,
							message: I18n.get('Select the stress level')
						}
					]}
				>
					<StressLevelSelect
						stressLevels={stressLevels}
						loading={isFetching}
						disabled={isLoading || isFetching || isError || disabled}
						stressLevelId={stressLevelId}
						fieldName={fieldName}
						handleClearStressLevel={handleClearStressLevel}
						handleSelectStressLevel={handleStressLevelSelect}
					/>
				</CustomStepKeyFormItem>
			</Col>
			<ScaleColor scale_color={color} />
		</>
	) : (
		<></>
	);
};
