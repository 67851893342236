import styled from 'styled-components';
import { Col, Modal } from 'antd';

export const CustomModal = styled(Modal)`
	.ant-modal-content {
		border-radius: 15px;
	}
`;

export const EditButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 1.5rem;
	height: 1.5rem;
	margin-left: 20px;
	margin-bottom: 2px;
	border-radius: 5px;
	padding: 5px;
	border: none;
	cursor: pointer;
	&:hover {
		background-color: #2874e0;
		color: white;
	}
`;

export const TitleContainer = styled.div`
	display: flex;
	justify-content: left;
	align-items: center;
	width: 90%;
	margin: 10px 20px;
`;

export const DescriptionContainer = styled.div`
	padding-inline: 20px;
	padding-bottom: 20px;
	border-bottom: 0.5px solid rgba(222, 222, 222, 1);
	margin-bottom: 15px;
`;

export const DescriptionTitle = styled.h3`
	background-color: #f0f2f5;
	width: 100%;
	border-radius: 5px;
	padding-top: 6px;
	padding-bottom: 2px;
	padding-inline: 10px;
	margin-bottom: 10px;
	margin-left: 0px;
`;

export const TaskManagerContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: left;
	padding: 10px;
	padding-top: 5px;
	height: fit-content;
	width: 100%;
`;

export const AddTask = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

export const TaskList = styled.div`
	width: 100%;
	height: 150px;
`;

export const TaskListContent = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

export const ActionButton = styled.div`
	width: 30px;
	height: 30px;
	line-height: 1.9rem;
	border-radius: 100%;
	transition: background-color ease 0.15s;
	.delete-btn&:hover {
		background-color: #ff7875;
		color: #a8071a;
	}
	.edit-btn&:hover {
		background-color: #5cbbff;
		color: #2e5e80;
	}
	.confirm-btn&:hover {
		background-color: #b7eb8f;
		color: #092b00;
	}
`;

export const taskIconStyle = {
	margin: '0px 8px',
	padding: '5px',
	cursor: 'pointer',
	fontSize: '16px'
};

export const SidebarContainer = styled.div`
	height: 100%;
	border-left: 0.5px solid rgba(222, 222, 222, 1);
	margin-left: 30px;
`;

export const SideItemTitle = styled.h3`
	background-color: #f0f2f5;
	width: 85%;
	border-radius: 5px;
	padding-top: 6px;
	padding-bottom: 2px;
	padding-inline: 10px;
	margin-bottom: 10px;
	margin-left: 20px;
`;

export const ScrollableContainer = styled.div`
	overflow-y: scroll;
	height: 100%;
	margin-top: 1rem;
`;

export const CustomList = styled(Col)`
	.ant-list-item {
		background-color: #f8f8f8;
		border-radius: 0.25rem;
		border: 1px solid #00000033;
		padding: 0.5rem 0.5rem;

		:last-child {
			background-color: #f8f8f8;
			border-radius: 0.25rem;
			border: 1px solid #00000033;
			padding: 0.5rem 0.5rem;
		}
	}
`;
