import { RiskDamageDTO, RiskDescriptionDTO } from '@/components/ui/Inputs';

interface IResponseDataTypeChecker {
	isRiskDamageType(data: unknown): data is RiskDamageDTO;
	isRiskDescriptionType(data: unknown): data is RiskDescriptionDTO;
}

export class ResponseDataTypeChecker implements IResponseDataTypeChecker {
	isRiskDamageType(data: unknown): data is RiskDamageDTO {
		return (data as RiskDamageDTO)?.description !== undefined;
	}

	isRiskDescriptionType(data: unknown): data is RiskDescriptionDTO {
		return (data as RiskDescriptionDTO)?.risk_category_id !== undefined;
	}
}
