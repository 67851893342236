import React from 'react';
import { Button, List, Modal } from 'antd';
import styled from 'styled-components';
import { I18n } from '@aws-amplify/core';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const Container = styled.div`
	background-color: white;
	div {
		padding: 5px 5px 5px 5px;
	}
`;

export function BandRiskList({ data, loading, onDelete }) {
	const onModalDelete = (id) => {
		Modal.confirm({
			title: I18n.get('Do you really want to delete these tracks?'),
			icon: <ExclamationCircleOutlined />,
			content: I18n.get('Heads up! the deletion is permanent'),
			okText: I18n.get('Confirm'),
			okType: 'danger',
			cancelText: I18n.get('Cancel'),
			onOk: () => onDelete(id)
		});
	};

	const itemList = (item) => (
		<List.Item
			actions={
				!item.standard && [
					<Button
						type="link"
						key="list-loadmore-more"
						onClick={() => onModalDelete(item.id)}
						style={{ color: 'red' }}
					>
						{I18n.get('delete')}
					</Button>
				]
			}
		>
			<List.Item.Meta title={<p>{item.name}</p>} description={item.description} />
		</List.Item>
	);

	return (
		<Container>
			<List itemLayout="horizontal" renderItem={itemList} dataSource={data} loading={loading} />
		</Container>
	);
}
