import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import moment from 'moment';

import { Paragraph, Text } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';

import { OperatorEvaluated } from './OperatorEvaluated';
import { Organization } from './Organizations';
import { Workstations } from './Workstations';
import { WorkCenter } from './WorkCenter';
import { ReportName } from './ReportName';
import { Evaluator } from './Evaluator';
import { Companies } from './Companies';
import { SubtitleCol } from './styles';
import { Sectors } from './Sectors';
import { Cycles } from './Cycles';
import { Lines } from './Lines';

interface InformationsProps {
	onValueChange: (changed: boolean) => void;
}

export const Informations: React.FC<InformationsProps> = ({ onValueChange }) => {
	const today = moment().format('L');

	useEffect(() => {
		onValueChange(false);
	}, []);

	return (
		<Col xs={24}>
			<Row gutter={[0, 10]} justify="center">
				<Col xs={24}>
					<Row justify="center">
						<SubtitleCol xs={24}>
							<Text strong>{I18n.get('General informations')}</Text>
							<Text>
								{I18n.get('Date')}: {today}
							</Text>
						</SubtitleCol>
						<Col xs={24}>
							<Paragraph>Insert below the informations for the cycle</Paragraph>
						</Col>
					</Row>
				</Col>
				<Col xs={24}>
					<Row style={{ marginBottom: '2rem' }}>
						<ReportName onValueChange={onValueChange} />
					</Row>
					<Row gutter={[10, 0]}>
						<Organization />
						<Companies />
						<Sectors />
						<Lines />
						<Workstations onValueChange={onValueChange} />
						<Cycles onValueChange={onValueChange} />
						<OperatorEvaluated onValueChange={onValueChange} />
						<WorkCenter onValueChange={onValueChange} />
						<Evaluator onValueChange={onValueChange} />
					</Row>
				</Col>
			</Row>
		</Col>
	);
};
