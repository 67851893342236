import styled from 'styled-components';
import { Button, Input, Radio } from 'antd';

export const CustomInput = styled(Input)<{ $borderRadius?: string }>`
	border-radius: ${({ $borderRadius }) => $borderRadius ?? '7px'};
	height: 40px;
	font-size: 15px;
	.ant-input {
		margin-left: 7px;
	}
`;

export const CustomButton = styled(Button)<{ $borderRadius?: string }>`
	border-radius: ${({ $borderRadius }) => $borderRadius ?? '7px'};
	min-width: 120px;
`;

export const IconButton = styled(Button)<{ $borderRadius?: string }>`
	border-radius: ${({ $borderRadius }) => $borderRadius ?? '7px'};
	width: 100%;

	svg {
		fill: #000;
	}

	svg:hover {
		fill: #2f54eb;
	}
`;

export const RadioGroupCustom = styled(Radio.Group)`
	display: flex;
	justify-content: space-evenly;
	.ant-radio-button-wrapper {
		border-radius: 7px !important;
		:last-child {
			border-left: 1px solid #d9d9d9;
		}
		::before {
			display: none;
		}
	}
	&::before {
		display: none;
	}
`;
