import React from 'react';
import { Form, Select } from 'antd';

import { TotalTaskDurationDTO } from '@/components/ui/Inputs/types/response/totalTaskDuration';
import { I18n } from '@aws-amplify/core';

import { NamePath } from 'antd/lib/form/interface';

const { useFormInstance } = Form;

type TotalTaskDurationSelectProps = {
	totalTaskDurations: TotalTaskDurationDTO[] | undefined;
	loading: boolean;
	disabled: boolean;
	handleSelectTotalTaskDuration?: (id: string) => void;
	handleClearTotalTaskDuration?: () => void;
	totalTaskDurationId?: string;
	fieldName: NamePath;
};

type OptionType = { label: string; value: string } | undefined;

export function TotalTaskDurationSelect({
	totalTaskDurations,
	loading,
	disabled,
	totalTaskDurationId,
	handleSelectTotalTaskDuration,
	handleClearTotalTaskDuration,
	fieldName
}: TotalTaskDurationSelectProps) {
	const { validateFields } = useFormInstance();

	async function handleChange(id: string) {
		if (handleSelectTotalTaskDuration) {
			handleSelectTotalTaskDuration(id);
			await validateFields([fieldName]);
		}
	}

	function handleClear() {
		if (handleClearTotalTaskDuration) {
			handleClearTotalTaskDuration();
		}
	}

	function filterOption(option: OptionType, input: string): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	return (
		<Select
			showSearch
			allowClear
			value={totalTaskDurationId}
			onClear={handleClear}
			placeholder={I18n.get('Total task duration')}
			filterOption={(input, option) => filterOption(option, input)}
			loading={loading}
			disabled={disabled}
			options={totalTaskDurations?.map(({ id, description }) => ({ label: I18n.get(description), value: id }))}
			onChange={handleChange}
		/>
	);
}
