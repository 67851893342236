import { RiskDamageDTO, RiskDescriptionDTO } from '@/components/ui/Inputs/types/response';
import { BaseContext } from '@/components/ui/Inputs/types/request';

export enum HierarchyEnum {
	riskDescription = 'riskDescription',
	riskDamage = 'riskDamage'
}

export type FetchSeveritiesRequest = BaseContext & {
	step_key_id: string;
};

export type FetchDamagesRequest = BaseContext & {
	step_key_id: string;
	risk_description_id: string;
};

export type CreateBaseRequest = BaseContext & {
	description: string;
	hierarchy_field: HierarchyEnum;
};

export type UpdateBaseRequest = BaseContext & {
	id?: string;
	description: string;
	hierarchy_field: HierarchyEnum;
};

export type DeleteBaseRequest = BaseContext & {
	id?: string;
	hierarchy_field: HierarchyEnum;
};

export type RiskDescriptionManagerResponse = (RiskDescriptionDTO | RiskDamageDTO)[];

export type CreateBodyData = BaseContext & {
	description: string;
	risk_category_id?: string;
};

export type ErrorField = {
	errors: string[];
};

export type ValidationError = {
	errorFields: ErrorField[];
};
