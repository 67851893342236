import React from 'react';
import { Col, Row, TabsProps } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useBeraReportStepsContext } from './context';
import { useBeraJobStepsContext } from '../context';
import { BeraStep } from './BeraStep';
import { CustomTabs } from './styles';

export const BeraFormSteps: React.FC = () => {
	const { handleTabClick } = useBeraReportStepsContext();
	const { currentActiveTabIndex } = useBeraReportStepsContext();
	const { selectedFiles, showBeraJobResult } = useBeraJobStepsContext();

	const items: TabsProps['items'] = selectedFiles.map((file, index) => {
		const key = String(index + 1);
		return {
			key,
			label: `${I18n.get('Task')} ${key}`,
			children: currentActiveTabIndex === key && <BeraStep key={file.id} file={file} />
		};
	});

	return (
		<Row justify="center">
			<Col xs={24}>
				<CustomTabs
					showberajobresult={String(showBeraJobResult)}
					onChange={handleTabClick}
					tabPosition="left"
					activeKey={currentActiveTabIndex}
					items={items}
					last={items.length}
				/>
			</Col>
		</Row>
	);
};
