import React from 'react';
import Icon, { ColumnWidthOutlined, LineChartOutlined, RetweetOutlined, ToTopOutlined } from '@ant-design/icons';
import { Row, Col, Space } from 'antd';

import { LibertyMutualIcon } from '@/assets/icons/liberty-mutual-icon';
import { NioshIcon } from '@/assets/icons/niosh-icon';
import { Container } from './styles';
import { BackCompressiveForceEstimationSVG } from '@/assets/icons/back-compressive-force-estimationSVG';

type Type = string;

interface ApplyToolProps {
	type: Type;
	isActive: boolean;
	onClick: React.MouseEventHandler<HTMLElement>;
	children: JSX.Element[];
}

const icons: Record<Type, JSX.Element> = {
	line: <LineChartOutlined style={{ fontSize: 30 }} />,
	toTop: <ToTopOutlined style={{ fontSize: 30 }} />,
	niosh: <Icon component={NioshIcon} style={{ fontSize: 30 }} />,
	'liberty-mutual': <Icon component={LibertyMutualIcon} style={{ fontSize: 30 }} />,
	'strain-index': <RetweetOutlined style={{ fontSize: 30, color: 'black', marginRight: 8 }} />,
	'kim-mho': <ColumnWidthOutlined style={{ fontSize: 30, color: 'black', marginRight: 8 }} />,
	'kim-pp': <ColumnWidthOutlined style={{ fontSize: 30, color: 'black', marginRight: 8 }} />,
	'back-compressive-force-estimation': <BackCompressiveForceEstimationSVG size={'30'} color="black" />
};

export const ApplyTool: React.FC<ApplyToolProps> = ({ type = 'line', onClick, children = [], isActive = false }) => (
	<Container hoverable onClick={onClick} $isActive={isActive}>
		<Row align="middle" justify="center" gutter={[15, 0]}>
			<Col>{icons[type]}</Col>
			<Col>
				<Space direction="vertical" align="start">
					{React.Children.map(children, (child) => (
						<>{child}</>
					))}
				</Space>
			</Col>
		</Row>
	</Container>
);
