import { I18n } from '@aws-amplify/core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { message, notification } from 'antd';

import { SeraSummaryListRequest, DeleteSeraSummaryRequest, DownloadFileRequest } from './types/request';
import { SeraSummaryListDTO } from './types/response';
import { SeraService } from './services';

const seraService = new SeraService();

export function useSeraSummaryList({ organization_id, company_id, filter }: SeraSummaryListRequest) {
	const enabled = !!organization_id && !!company_id;
	const summaryList = useQuery(
		['sera_summary_list', [organization_id, company_id, filter]],
		() => seraService.getSeraSummaryList({ organization_id, company_id, filter }),
		{
			enabled
		}
	);
	return {
		...summaryList,
		data: summaryList.data ?? ({} as SeraSummaryListDTO)
	};
}

export const useDeleteSeraSummary = () => {
	const queryClient = useQueryClient();
	return useMutation(({ body }: { body: DeleteSeraSummaryRequest }) => seraService.deleteSeraSummary(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0].split('" ');
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['sera_summary_list']);
			queryClient.invalidateQueries(['sera_files'], { refetchType: 'inactive' });
			message.success(I18n.get('S.E.R.A. deleted successfully'));
		}
	});
};

export const useDownloadFile = () => {
	return useMutation((body: DownloadFileRequest) => seraService.downloadFile(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0].split('" ');
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: (data) => {
			const archive = document.createElement('a');
			archive.target = '_blank';
			archive.href = data;
			archive.click();
			archive.remove();
		}
	});
};
