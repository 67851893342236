export const ENUM_TYPE_PLAN = {
	test: 'Test',
	team: 'Team',
	business: 'Business',
	individual: 'Individual'
};

export const ENUM_RECURRENCE_PLAN = {
	TEST: 'Test',
	YEARLY: 'Yearly',
	CREDIT: 'Credit',
	MONTHLY: 'Monthly'
};
export const ACTION_PLAN_DATE_STATUS = [
	{ value: 'ON_TIME', description: 'On time' },
	{ value: 'OVERDUE', description: 'Overdue' },
	{ value: 'THIS_WEEK', description: 'Finishing in a week' }
];

export const ACTION_PLAN_DEADLINE = [
	{ value: 'ASC', description: 'Ascending' },
	{ value: 'DESC', description: 'Descending' }
];
