import React from 'react';
import { Button, Col, Row } from 'antd';

import { I18n } from '@aws-amplify/core';

import { Force2FAContainer, Force2FADescription } from './styles';
import { useCheck2FAContext } from '../context';

export const Force2FAWarning = () => {
	const {
		handleGenerate2FA,
		handleOpenCheck2FAModal,
		handleOpenReset2FAModal,
		handleOpenValidate2FAModal,
		handleOpenForce2FAWarning
	} = useCheck2FAContext();

	async function handleConfirmClick() {
		const generated = await handleGenerate2FA();

		if (generated.id) {
			handleOpenCheck2FAModal(false);
			handleOpenReset2FAModal(false);
			handleOpenForce2FAWarning(false);
			handleOpenValidate2FAModal(true);
		}
	}

	return (
		<Force2FAContainer align="middle" justify="center">
			<Col span={24}>
				<Row justify="center">
					<Col span={24}>
						<Row justify="center">
							<Force2FADescription>
								{I18n.get(
									'Your company is requesting that all users must have two-factor authentication enabled. Continue to activate yours and login.'
								)}
							</Force2FADescription>
						</Row>
					</Col>
				</Row>
			</Col>
			<Col span={18}>
				<Row justify="center">
					<Button onClick={handleConfirmClick} type="primary">
						{I18n.get('Continue')}
					</Button>
				</Row>
			</Col>
		</Force2FAContainer>
	);
};
