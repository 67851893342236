import React, { useEffect, useState } from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Row, Col, Checkbox } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Container, Body, Title } from './styles';

interface CollapseCheckProps {
	title: string;
	children?: JSX.Element;
	value: boolean;
	anyOtherChecked: boolean;
	onChange: (isChecked: boolean) => void;
}

export const CollapseCheck: React.FC<CollapseCheckProps> = ({
	title,
	children,
	value = false,
	anyOtherChecked = false,
	onChange
}) => {
	const [checked, setChecked] = useState(value ?? false);

	useEffect(() => {
		onChange(value);
	}, [value]);

	function handleOpen() {
		if (anyOtherChecked) {
			return;
		}
		setChecked(!value);
		onChange(!value);
	}

	function handleChange(event: CheckboxChangeEvent) {
		const isChecked = event.target.checked;
		setChecked(isChecked);
		if (typeof onChange === 'function') {
			onChange(isChecked);
		}
	}

	return (
		<Container
			onClick={!checked || !children ? handleOpen : () => {}}
			anyOtherChecked={anyOtherChecked}
			hasBody={!!children}
			selected={checked}
		>
			<Col onClick={handleOpen} span={24} style={{ cursor: anyOtherChecked ? 'auto' : 'pointer' }}>
				<Row align="middle" gutter={[24, 0]}>
					<Col span={2}>
						<Checkbox checked={checked} onChange={handleChange} disabled={anyOtherChecked} />
					</Col>
					<Col span={20}>
						<Title onClick={handleOpen} anyOtherChecked={anyOtherChecked} selected={checked}>
							{I18n.get(title)}
						</Title>
					</Col>
				</Row>
			</Col>
			{children && (
				<Col span={24}>
					<Body open={checked}>{children}</Body>
				</Col>
			)}
		</Container>
	);
};
