import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '@/store/query';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';

import {
	BeraReportRequest,
	BeraReportResultDTO,
	PayloadDownloadPDF,
	URLDownloadDTO,
	UpdateBeraResultCommentRequest,
	UpdateBeraResultConsolidatedRequest
} from './types';
import { BeraResultService } from './services';

const beraResultService = new BeraResultService();

export function useBeraReportResult({ organization_id, company_id, file_id, bera_job_summary_id }: BeraReportRequest) {
	const enabled = !!organization_id && !!company_id && !!file_id && !!bera_job_summary_id;
	const report = useQuery(
		['bera_report_result', [organization_id, company_id, file_id, bera_job_summary_id]],
		() => beraResultService.getBeraReportResult({ organization_id, company_id, file_id, bera_job_summary_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...report,
		data: report.data ?? ({} as BeraReportResultDTO)
	};
}

export function useDownloadPDF() {
	return useMutation((payload: PayloadDownloadPDF) => beraResultService.downloadPDF(payload), {
		onSuccess: (data: URLDownloadDTO) => {
			let archive = document.createElement('a');
			archive.target = '_blank';
			archive.href = data.url;
			archive.click();
			archive.remove();
		},
		onError: () => {
			message.error(I18n.get('Failed to generate the report'));
		}
	});
}

export function useConsolidatePDF() {
	return useMutation((payload: UpdateBeraResultConsolidatedRequest) => beraResultService.consolidatePDF(payload), {
		onSuccess: () => {
			message.success(I18n.get('Report updated successfully'));
			queryClient.invalidateQueries(['bera_report_result']);
			queryClient.invalidateQueries(['bera_report']);
		},
		onError: () => {
			message.error(I18n.get('Failed to update report'));
		}
	});
}

export function useComments() {
	return useMutation((payload: UpdateBeraResultCommentRequest) => beraResultService.saveComments(payload));
}
