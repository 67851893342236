import React from 'react';
import { Col, Form, Input } from 'antd';
import { I18n } from '@aws-amplify/core';

export function ReportName() {
	return (
		<Col xs={24}>
			<Form.Item name={['name']} label={I18n.get('Report name')} labelCol={{ span: 24 }}>
				<Input placeholder={I18n.get('Report name')} />
			</Form.Item>
		</Col>
	);
}
