import React from 'react';
import { Col, Divider, Row } from 'antd';

import { CheckCircleOutlined, ExclamationCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Title } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';

import { AcceptableDescription } from './AcceptableDescription';
import { RiskDescription } from './RiskDescription';

type TopicDescriptionProps = {
	riskScale: number;
	topic: string;
	score: number;
	hasDivider?: boolean;
	consequence: {
		name: string;
		description: string;
	};
	probability: {
		name: string;
		description: string;
	};
	exhibition: {
		name: string;
		description: string;
	};
	injuries: Array<{ id: string; name: string; description: string }>;
};

export const TopicDescription: React.FC<TopicDescriptionProps> = ({
	riskScale,
	topic,
	score,
	hasDivider = true,
	consequence,
	probability,
	exhibition,
	injuries
}) => {
	const style = {
		fontSize: 20,
		width: '1.75rem'
	};
	const icons = [
		<CheckCircleOutlined style={{ ...style, color: '#2cc852' }} />,
		<ExclamationCircleOutlined style={{ ...style, color: '#FFDE31' }} />,
		<ExclamationCircleOutlined style={{ ...style, color: '#F78A38' }} />,
		<WarningOutlined style={{ ...style, color: '#E74150' }} />,
		<WarningOutlined style={{ ...style, color: '#9B54E2' }} />
	];

	return (
		<Col span={24}>
			<Row gutter={[0, 0]} align="middle">
				<Col span={1}>{icons[riskScale === 0 ? 0 : riskScale - 1]}</Col>
				<Col span={23}>
					<Title style={{ margin: 0 }} level={4}>
						{I18n.get(topic)}
					</Title>
				</Col>
			</Row>

			<Row style={{ marginTop: '0.5rem' }}>
				<Col span={1}>
					{hasDivider && (
						<Divider
							type="vertical"
							style={{
								margin: '0 0 0 0.7rem',
								borderLeft: '4px solid #e6e8e8',
								height: '100%'
							}}
						/>
					)}
				</Col>
				<Col span={22} style={{ marginLeft: '0.5rem' }}>
					{riskScale === 0 ? (
						<AcceptableDescription score={score} />
					) : (
						<RiskDescription
							riskScale={riskScale}
							score={score}
							consequence={consequence}
							probability={probability}
							exhibition={exhibition}
							injuries={injuries}
						/>
					)}
				</Col>
			</Row>
		</Col>
	);
};
