import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CustomReportFilesDTO } from '@/components/ui/Inputs/types/response/customReport';
import { FileForCustomReport } from '@/components/ui/Inputs/types/response/file';

import { BeraCommonInformations, BeraReportsStatus, Context, Methods, State } from './types';
import { BeraJobSummaryDTO } from '../hooks/types/response';

export type BeraJobStepsRouteParams = {
	bera_job_summary_id: string;
};

interface BeraJobStepsProviderProps {
	children: ReactNode;
}

export const BeraJobStepsContext = createContext<Context>({} as Context);

export const BeraJobStepsProvider = ({ children }: BeraJobStepsProviderProps) => {
	const params = useParams<BeraJobStepsRouteParams>();

	const [page, setPage] = useState(0);
	const [limit, setLimit] = useState(10);
	const [fileName, setFileName] = useState('');
	const [tabsLength, setTabsLength] = useState(0);
	const [allFinished, setAllFinished] = useState(false);
	const [beraJobSummaryId, setBeraJobSummaryId] = useState('');
	const [showBeraJobResult, setShowBeraJobResult] = useState(false);
	const [selectedFiles, setSelectedFiles] = useState<FileForCustomReport[]>([]);
	const [selectedFilesId, setSelectedFilesId] = useState<React.Key[] | string[]>([]);
	const [beraReportsStatus, setBeraReportsStatus] = useState<BeraReportsStatus[]>([]);
	const [beraFiles, setBeraFiles] = useState<CustomReportFilesDTO>({} as CustomReportFilesDTO);
	const [beraCommonInformations, setBeraCommonInformations] = useState<BeraCommonInformations>(
		{} as BeraCommonInformations
	);
	const [beraJobSummary, setBeraJobSummary] = useState<BeraJobSummaryDTO>({} as BeraJobSummaryDTO);

	useEffect(() => {
		const notAllFinished = beraReportsStatus
			?.filter((report) => selectedFilesId.includes(report?.fileId))
			.some((report) => !report?.completed);
		setAllFinished(!notAllFinished);
	}, [beraFiles]);

	function handleFileNameChange(name: string) {
		setFileName(name);
	}

	function handleSizeChange(size: number) {
		setLimit(size);
	}

	function handlePageChange(page: number) {
		setPage(page);
	}

	function handleFinishBeraReport(fileId: string, status = true) {
		const beraReportsStatusCopy = [
			...beraReportsStatus.filter((report) => selectedFilesId.includes(report?.fileId))
		];
		const beraReportIndex = beraReportsStatusCopy.findIndex((report) => report.fileId === fileId);
		if (beraReportIndex >= 0) {
			beraReportsStatusCopy[beraReportIndex].completed = status;
			setBeraReportsStatus(beraReportsStatusCopy);
			const notAllFinished = beraReportsStatusCopy.some((report) => !report.completed);
			setAllFinished(!notAllFinished);
		}
	}

	function handleShowBeraJobResultChange(show: boolean) {
		setShowBeraJobResult(show);
	}

	function handleBeraJobSummaryIdChange(id: string) {
		setBeraJobSummaryId(id);
	}

	function handleBeraFilesChange(data: CustomReportFilesDTO) {
		setBeraFiles(data);
		const beraReportsInitialStatus: BeraReportsStatus[] = defineBeraReportsStatus(data);
		setBeraReportsStatus(beraReportsInitialStatus);
	}

	function defineBeraReportsStatus(data: CustomReportFilesDTO): BeraReportsStatus[] {
		return data?.files?.rows.map((file) => {
			const report = file?.bera_report?.find(
				(report) => report.bera_job_summary_id === (beraJobSummaryId || params?.bera_job_summary_id)
			);
			return {
				fileId: file?.id,
				completed: !!report?.id
			};
		});
	}

	function handleBeraJobSummaryChange(data: BeraJobSummaryDTO) {
		setBeraJobSummary(data);
	}

	function handleBeraCommonInformationsChange(data: BeraCommonInformations) {
		setBeraCommonInformations(data);
	}

	function handleSelectedFilesChange(data: FileForCustomReport[]) {
		setSelectedFiles(data);
		const filesIds = data.map((file) => file.id);
		setSelectedFilesId(filesIds);
		setTabsLength(data.length);
	}

	function filterSelectedFiles(selectedRows: React.Key[], customReport: CustomReportFilesDTO): FileForCustomReport[] {
		const fileIds = [...selectedRows];
		const files: FileForCustomReport[] = [];
		fileIds.forEach((id) => {
			const selectedFile = customReport?.files.rows?.find((file) => file.id === id);
			if (selectedFile) {
				files.push(selectedFile);
			}
		});

		const selectedFilesCopy = [...selectedFiles];
		const selectedFilesToKeep = selectedFilesCopy
			.filter((file) => fileIds.includes(file.id))
			.filter((file) => !files.includes(file));
		files.push(...selectedFilesToKeep);
		return files.sort(compareByOriginalName);
	}

	function compareByOriginalName(a: FileForCustomReport, b: FileForCustomReport) {
		if (a.original_name < b.original_name) {
			return -1;
		}
		if (a.original_name > b.original_name) {
			return 1;
		}
		return 0;
	}

	const state: State = {
		page,
		limit,
		fileName,
		beraFiles,
		tabsLength,
		allFinished,
		selectedFiles,
		beraJobSummary,
		selectedFilesId,
		beraJobSummaryId,
		showBeraJobResult,
		beraReportsStatus,
		beraCommonInformations
	};

	const methods: Methods = {
		handleSizeChange,
		handlePageChange,
		filterSelectedFiles,
		handleFileNameChange,
		handleBeraFilesChange,
		handleFinishBeraReport,
		handleSelectedFilesChange,
		handleBeraJobSummaryChange,
		handleBeraJobSummaryIdChange,
		handleShowBeraJobResultChange,
		handleBeraCommonInformationsChange
	};

	const context: Context = {
		...state,
		...methods
	};

	return <BeraJobStepsContext.Provider value={context}>{children}</BeraJobStepsContext.Provider>;
};

export function useBeraJobStepsContext() {
	const context = useContext(BeraJobStepsContext);
	return context;
}
