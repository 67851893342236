import { useMutation, useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import type { AxiosError } from 'axios';
import { message } from 'antd';

import Api from '@/services/api';
import type { BaseContext, Response, WorkConditionResult } from '@/types';

export type CreateWorkConditionDTO = BaseContext & {
	step_id: string;
	work_hours: number;
	work_minutes: number;
	workstation_id?: string;
	custom_report_id: string;
	place_description?: string;
	custom_report_result_id?: string;
	expected_task_description?: string;
	performed_task_description?: string;
};

async function createWorkCondition(body: CreateWorkConditionDTO): Promise<WorkConditionResult> {
	const url = '/custom-report/result/work-condition';
	const { data } = await Api.post<WorkConditionResult>(url, body);
	return data;
}

export const useCreateWorkConditionResult = () => {
	const queryClient = useQueryClient();
	return useMutation<WorkConditionResult, AxiosError<Response>, CreateWorkConditionDTO>(
		(body: CreateWorkConditionDTO) => createWorkCondition(body),
		{
			onError: (err) => {
				message.error(I18n.get(err?.response?.data?.message ?? err?.message));
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['jds_d86_report']);
				queryClient.invalidateQueries(['jds_d86_result']);
			}
		}
	);
};
