import styled from 'styled-components';
import { Form, Skeleton } from 'antd';

const VIDEO_ICON = 'https://kinebot-statics.s3.amazonaws.com/download-video-black.svg';

const { Item } = Form;

export const CustomFormItem = styled(Item)`
	height: 2.25rem;
	@media (min-width: 1200px) {
		height: 3rem;
	}
`;

export const CustomImageSkeleton = styled(Skeleton.Image)`
	.ant-skeleton-image {
		background-image: url(${VIDEO_ICON});
		background-blend-mode: lighten;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: 40%;
		padding: 5rem;
		opacity: 0.6;

		svg {
			display: none;
		}
	}
`;
