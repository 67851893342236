import type { AxiosError } from 'axios';

import store from '../store';
import { Services } from '@/hooks/useAuth/services';
import { handleLogout } from '../redux/auth/actions';

const statusTypeDownload: Record<string, boolean> = {
	PROCESSING: true,
	NOT_PROCESSED: true,
	EXTRACTED_DATA: true
};

const statusTypeReports: Record<string, boolean> = {
	PROCESSING: true,
	NOT_PROCESSED: true
};

export const checkStatus = ({ status }: any) => {
	function download() {
		return statusTypeDownload[status] || false;
	}

	function reports() {
		return statusTypeReports[status] || false;
	}

	return { download, reports };
};

export function validationJSON(value: any) {
	try {
		let result = JSON.parse(value);
		return result;
	} catch (error) {
		return null;
	}
}

function setLocalStorage(key: string, payload: any): void {
	localStorage.setItem(key, JSON.stringify(payload));
}

function getLocalStorage(key: string): any {
	return JSON.parse(localStorage.getItem(key) || '');
}

async function setRefreshToken(error: Error | AxiosError): Promise<string> {
	try {
		const session = getLocalStorage('info_user');
		const { user, token, refresh_token } = await Services.refreshToken({ refresh_token: session.refresh_token });
		setLocalStorage('info_user', { user, token, refresh_token });
		return token;
	} catch (err) {
		store.dispatch(handleLogout());
		throw error;
	}
}

export const Utils = { setLocalStorage, getLocalStorage, setRefreshToken };
