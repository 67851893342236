import React from 'react';
import { Col, DatePicker, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { NamePath } from 'antd/lib/form/interface';

interface CollectionDateProps {
	fieldName: NamePath;
}

export const CollectionDate: React.FC<CollectionDateProps> = ({ fieldName }) => {
	return (
		<Col xs={24} sm={12}>
			<Form.Item
				name={fieldName}
				label={I18n.get('Collection date')}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: true,
						message: I18n.get('Insert the date of collection')
					}
				]}
			>
				<DatePicker
					format={'DD-MM-YYYY'}
					disabledDate={(current) => current && current.valueOf() >= Date.now()}
					placeholder={I18n.get('Collection date')}
				/>
			</Form.Item>
		</Col>
	);
};
