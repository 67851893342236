import styled from 'styled-components';
import { Button, Col, Form, Row, Tooltip } from 'antd';
import { Title } from '@/components/Typography';

export const HeaderRow = styled(Row)`
	margin-bottom: 1rem;
`;

export const HeaderTitle = styled(Title)`
	margin: 0 !important;
	text-align: 'center';
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	@media (min-width: 576px) {
		white-space: normal;
		overflow: visible;
		text-overflow: clip;
	}
`;

export const GeneralInformationsForm = styled(Form)`
	max-width: 1240px;
	display: flex;
	justify-content: center;
`;

export const PreviousCol = styled(Col)`
	padding: 0;

	margin-right: 1rem;
	@media (min-width: 576px) {
		margin-right: 4rem;
	}
`;

export const CustomTooltip = styled(Tooltip)`
	position: relative;
	padding: 0 0.7rem;
	background-color: #cdd5f3;
	border-radius: 0.25rem;
`;

export const CustomButton = styled(Button)`
	margin-bottom: 1rem;
	@media (min-width: 1470px) {
		margin-left: 0.8rem;
	}
	transition: all 0.2s;
	:hover {
		filter: brightness(0.9);
		background-color: #cdd5f3;
	}
`;
