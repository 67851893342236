import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form, Input } from 'antd';

interface ReportNameProps {
	onValueChange: (changed: boolean) => void;
}

export const ReportName: React.FC<ReportNameProps> = ({ onValueChange }) => {
	return (
		<Col xs={24} sm={12}>
			<Form.Item
				labelCol={{ span: 24 }}
				name={['report_name']}
				label={I18n.get('Report name')}
				rules={[
					{
						required: true,
						message: I18n.get('Enter a report name')
					}
				]}
			>
				<Input placeholder={I18n.get('Report name')} onChange={() => onValueChange(true)} />
			</Form.Item>
		</Col>
	);
};
