import React from 'react';
import { Col, Form, Row } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Paragraph, Title } from '@/components/Typography';

import { TextInput } from './TextInput';
import { PreviousCol } from '../../styles';
import { LoadingSkeleton } from './LoadingSkeleton';
import { TextArea } from '../../components/TextArea';
import type { UpdateWorkConditionDTO } from '@/hooks';
import { useEwaJdsD86Context } from '../../../context';
import { useApplicationContext } from '@/context/v1/Application/context';
import { CustomButton } from '@/components/ui/Buttons/CustomButton/styles';
import { useCreateWorkConditionResult, useUpdateWorkConditionResult } from '@/hooks';

const { useFormInstance } = Form;

type WorkConditionFields = {
	work_hours: number;
	work_minutes: number;
	place_description?: string;
	expected_task_description?: string;
	performed_task_description?: string;
};

export const WorkConditions: React.FC = () => {
	const form = useFormInstance();
	const { organization, company } = useApplicationContext();
	const { ewaJdsD86, handleNextStep, handlePreviousStep, scrollToError, currentStepId, customReport } =
		useEwaJdsD86Context();

	const { mutateAsync: createWorkCondition, isLoading: creatingWorkCondition } = useCreateWorkConditionResult();
	const { mutateAsync: updateWorkCondition, isLoading: updatingWorkCondition } = useUpdateWorkConditionResult();

	async function handleValidation() {
		try {
			await form.validateFields();

			const workConditions: WorkConditionFields = await form.getFieldValue(['work_conditions']);
			const handledBody: UpdateWorkConditionDTO = {
				...workConditions,
				step_id: currentStepId,
				company_id: company?.id,
				organization_id: organization?.id,
				custom_report_id: customReport.id,
				work_hours: workConditions.work_hours ?? 0,
				custom_report_result_id: ewaJdsD86.result_id,
				work_minutes: workConditions.work_minutes ?? 0,
				work_condition_id: ewaJdsD86.work_conditions?.id,
				workstation_id: ewaJdsD86?.informations?.workstation_id
			};

			if (ewaJdsD86.work_conditions?.id) {
				const updated = await updateWorkCondition(handledBody);
				if (updated?.id) {
					handleNextStep();
					return;
				}
				return;
			}

			const created = await createWorkCondition(handledBody);
			if (created?.id) {
				handleNextStep();
			}
		} catch (error) {
			scrollToError(error);
		}
	}

	if (creatingWorkCondition || updatingWorkCondition) {
		return <LoadingSkeleton />;
	}

	return (
		<>
			<Row justify="center" gutter={[5, 20]}>
				<Col xs={21}>
					<Row align="middle">
						<Col xs={24}>
							<Title level={4}>Work Conditions</Title>
						</Col>
						<Col xs={24} style={{ marginBottom: '20px' }}>
							<Paragraph level={5}>
								Select the restrictions perceived in the evaluated working condition
							</Paragraph>
						</Col>
					</Row>
					<Row justify="center">
						<Col span={23}>
							<Row justify="start">
								<Col span={24}>
									<Title level={5}>Work schedule</Title>
								</Col>
								<TextInput
									type="hours"
									isRequired={true}
									title="Work schedule hours"
									namePath={['work_conditions', 'work_hours']}
								/>
								<TextInput
									type="minutes"
									isRequired={false}
									title="Work schedule minute"
									namePath={['work_conditions', 'work_minutes']}
								/>
							</Row>
							<Row justify="center" gutter={[0, 10]}>
								<TextArea
									fontSize="16px"
									namePath={['work_conditions', 'place_description']}
									title="Summary description of the site"
								/>
								<TextArea
									fontSize="16px"
									namePath={['work_conditions', 'expected_task_description']}
									title="Simplified description of the planned task"
								/>
								<TextArea
									fontSize="16px"
									namePath={['work_conditions', 'performed_task_description']}
									title="Simplified description of the task performed"
								/>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row>
				<Col xs={24} style={{ marginTop: '2rem' }}>
					<Row justify="center" align="middle" gutter={[0, 8]}>
						<PreviousCol>
							<CustomButton type="default" size="large" onClick={handlePreviousStep}>
								{I18n.get('Previous')}
							</CustomButton>
						</PreviousCol>
						<Col>
							<CustomButton htmlType="submit" type="primary" size="large" onClick={handleValidation}>
								{I18n.get('Next')}
							</CustomButton>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};
