import React, { useState } from 'react';
import { Text, Title } from '@/components/Typography';
import { Col, Divider, Row } from 'antd';

import { useLibertyMutualSubStepsContext } from '../context';
import { ReportInformation } from './ReportInformation';
import { ResultsContainer } from './styles';
import { DownloadPDF } from './DownloadPDF';
import { Summary } from './Summary';
import { Header } from './Header';

export const Result = () => {
	const { libertyMutualResult } = useLibertyMutualSubStepsContext();
	const [isEditing, setIsEditing] = useState<boolean>(false);

	if (!libertyMutualResult?.id) {
		return (
			<Row justify="center">
				<Col>
					<Text>Oops... Something went wrong!</Text>
				</Col>
			</Row>
		);
	}

	function handleDisableFormEdition(editing: boolean) {
		setIsEditing(editing);
	}

	return (
		<Row justify="center">
			<Col span={24} style={{ marginBottom: '2rem', padding: '0 1rem' }}>
				<Title level={4}>Result</Title>
			</Col>
			<Col style={{ padding: '1rem' }}>
				<ResultsContainer>
					<Header />
					<ReportInformation />
					<Divider type="horizontal" />
					<Summary />
					<Divider type="horizontal" />
					<DownloadPDF onDownloadPDF={handleDisableFormEdition} disableDownload={isEditing} />
				</ResultsContainer>
			</Col>
		</Row>
	);
};
