import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, TreeDataNode } from 'antd';
import { DownloadOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';

import { FileDTO } from '@/components/ui/Inputs';
import { PdfModal } from './PdfModal';
import type { PDFSections } from '@/types';
import * as S from './styles';

type onConsolidatePDFFunction = {
	file: FileDTO;
	status: boolean;
};

interface ButtonsCustomProps {
	isLoadingPDF: boolean;
	consolidated: boolean;
	hasCustomModal: boolean;
	hasConsolidated: boolean;
	fileData: FileDTO | undefined;
	onDownloadPDF(file: FileDTO): Promise<void>;
	onConsolidatePDF(values: onConsolidatePDFFunction): void;
	component?: React.ReactNode;
	pdfProps?: {
		initialValues: PDFSections[];
		treeData: TreeDataNode[];
	};
}

export function ButtonsCustom({
	isLoadingPDF,
	consolidated,
	onDownloadPDF,
	hasConsolidated,
	onConsolidatePDF,
	hasCustomModal = false,
	fileData = {} as FileDTO,
	component,
	pdfProps
}: ButtonsCustomProps) {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	function handleConsolidatedButton() {
		if (hasCustomModal) {
			setIsModalOpen(true);
			return;
		}

		return onDownloadPDF(fileData);
	}

	return (
		<Row justify="end" align="middle" gutter={[30, 0]}>
			<Col>
				<S.DownloadButton
					ghost
					size="large"
					style={{ backgroundColor: '#fff' }}
					type="primary"
					loading={isLoadingPDF}
					icon={<DownloadOutlined />}
					onClick={handleConsolidatedButton}
				>
					{I18n.get('Download PDF')}
				</S.DownloadButton>
			</Col>
			{hasConsolidated && (
				<Col>
					{!consolidated ? (
						<S.ConsolidatedButton
							ghost
							size="large"
							type="primary"
							style={{ backgroundColor: '#fff' }}
							loading={isLoadingPDF}
							icon={<LockOutlined style={{ color: '#2cc852' }} />}
							onClick={() => onConsolidatePDF({ file: fileData, status: true })}
						>
							{I18n.get('Consolidate report')}
						</S.ConsolidatedButton>
					) : (
						<S.DesconsolidatedButton
							ghost
							size="large"
							type="primary"
							loading={isLoadingPDF}
							style={{ backgroundColor: '#fff' }}
							icon={<UnlockOutlined />}
							onClick={() => onConsolidatePDF({ file: fileData, status: false })}
						>
							{I18n.get('Deconsolidate')}
						</S.DesconsolidatedButton>
					)}
				</Col>
			)}
			{component}
			<PdfModal
				isLoadingPDF={isLoadingPDF}
				isModalOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
				onDownloadPDF={onDownloadPDF}
				fileData={fileData}
				{...pdfProps}
			/>
		</Row>
	);
}
