import React, { createContext, ReactNode, useContext, useState } from 'react';
import { Context, DashboardQueryParams } from './types';

type DashboardProviderProps = {
	children: ReactNode;
};

const initState = {
	lineId: undefined,
	sectorId: undefined,
	stressLevel: undefined,
	workstation: undefined,
	rsi: undefined,
	niosh: undefined,
	work_organization: undefined,
	workstations: undefined,
	reba: undefined,
	action_plan: undefined
};

export const DashboardContext = createContext<Context>({} as Context);

export const DashboardProvider = ({ children }: DashboardProviderProps) => {
	const [queryParams, setQueryParams] = useState<DashboardQueryParams>(initState);

	function handleFilterSelection(payload: DashboardQueryParams) {
		setQueryParams((prev) => ({
			...prev,
			...payload
		}));
	}

	function handleClearFilters() {
		setQueryParams(initState);
	}

	const context: Context = { handleFilterSelection, handleClearFilters, queryParams, setQueryParams };

	return <DashboardContext.Provider value={context}>{children}</DashboardContext.Provider>;
};

export function useDashboardContext() {
	const context = useContext(DashboardContext);
	return context;
}
