import Api from '@/services/api';

import {
	BeraJobSummaryRequest,
	CreateBeraJobSummaryRequest,
	UpdateBeraJobScoresRequest,
	UpdateBeraJobSummaryRequest
} from '../../hooks/types/request';
import { BeraJobSummaryDTO } from '../../hooks/types/response';

export class BeraJobService {
	async getBeraJobSummary({
		organization_id,
		company_id,
		bera_job_summary_id
	}: BeraJobSummaryRequest): Promise<BeraJobSummaryDTO> {
		const url = `/bera/job-summary/${bera_job_summary_id}?organization_id=${organization_id}&company_id=${company_id}`;
		const { data } = await Api.get<BeraJobSummaryDTO>(url);
		return data;
	}

	async createBeraJobSummary(body: CreateBeraJobSummaryRequest): Promise<BeraJobSummaryDTO> {
		const url = `/bera/job-summary`;
		const { data } = await Api.post<BeraJobSummaryDTO>(url, body);
		return data;
	}

	async updateBeraJobSummary(body: UpdateBeraJobSummaryRequest): Promise<BeraJobSummaryDTO> {
		const url = `/bera/job-summary/${body.id}`;
		const { data } = await Api.put<BeraJobSummaryDTO>(url, body);
		return data;
	}

	async updateBeraJobScores(body: UpdateBeraJobScoresRequest): Promise<BeraJobSummaryDTO> {
		const url = `/bera/job-summary/${body.bera_job_summary_id}`;
		const { data } = await Api.post<BeraJobSummaryDTO>(url, body);
		return data;
	}
}
