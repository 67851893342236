export enum PDFSections {
	REBA = 'reba',
	NIOSH = 'niosh',
	KIM_PP = 'kim_pp',
	KIM_MHO = 'kim_mho',
	ACTION_PLANS = 'action_plans',
	STRAIN_INDEX = 'strain_index',
	NOT_EVALUATED = 'not_evaluated',
	LIBERTY_MUTUAL = 'liberty_mutual',
	CHARACTERISTICS = 'characteristics',
	WORK_CONDITIONS = 'work_conditions',
	BACK_COMPRESSIVE_FORCE_ESTIMATION = 'back_compressive_force_estimation',
}

export type CustomReportResult = {
	id: string;
	interviewee_name: string;
	interviewer_name: string;
	worst_score: number;
	sum_score: number;
	average_score: number;
	result: number;
	comment: string;
	location: string;
	consolidated: boolean;
	collection_date: Date;
	evaluator_id: string;
	custom_report_id: string;
	current_step_id: string;
	created_by_user_id: string;
	file_id: string;
	created_at: Date;
	updated_at: Date;
	deleted_at?: Date | null;
};
