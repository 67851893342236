import React from 'react';
import { Row, Col } from 'antd';
import { Title } from '@/components/Typography';

export function Header() {
	return (
		<Row justify="center">
			<Col>
				<Title level={3}>Back compressive force estimation</Title>
			</Col>
		</Row>
	);
}
