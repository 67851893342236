import React from 'react';
import { Menu } from 'antd';
import { I18n } from '@aws-amplify/core';
import { LogoutOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { AvatarUser } from './AvatarUser';

interface MenuPopoverProps {
	onLogout: () => void;
	onMyAccount: () => void;
}

export const MenuPopover: React.FC<MenuPopoverProps> = ({ onMyAccount, onLogout }) => (
	<Menu key="user" mode="horizontal">
		<Menu.SubMenu title={<AvatarUser size="large" />}>
			<Menu.Item key="account" icon={<InfoCircleOutlined />} onClick={onMyAccount}>
				{I18n.get('My account')}
			</Menu.Item>
			<Menu.Item key="logout" icon={<LogoutOutlined />} onClick={onLogout}>
				{I18n.get('Logout')}
			</Menu.Item>
		</Menu.SubMenu>
	</Menu>
);
