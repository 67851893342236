import styled from 'styled-components';
import { Button, Form } from 'antd';

const { Item } = Form;

export const CustomFormItem = styled(Item)`
	width: 100%;
	margin-bottom: 0.75rem;
	.ant-form-item-label {
		font-weight: 600;
		overflow: visible;
	}
	.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
		&::before {
			display: none;
		}
	}
	.ant-form-item-control-input-content {
		.ant-select-selector {
			border-radius: 3px;
		}
	}
`;

export const Title = styled.span`
	font-weight: 600;
`;

export const ScoreTitle = styled.span`
	font-weight: 600;
	font-size: 0.8rem;
`;

export const Score = styled.span`
	color: #2f54eb;
	font-weight: 600;
	font-size: 0.9rem;
	margin-left: 0.25rem;
	border-radius: 0.25rem;
	padding: 0.05rem 0.5rem;
	border: 1px solid #2f54eb;
	background-color: #2f54eb0d;

	@media (min-width: 1600px) {
		font-size: 0.9rem;
	}
`;

export const CustomButton = styled(Button)`
	padding: 0.25rem;
	margin-bottom: 0.5rem;
	width: 6rem;
	height: auto;
	font-weight: 600;
	font-size: 0.8rem;
	white-space: break-spaces;
`;
