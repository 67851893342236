import type { EwaJdsD86ReportResponse, GetCustomReportByNameResponse } from '@/hooks';
import { Step } from '@/hooks';

export enum HandCoupling {
	'Good' = 'Good',
	'Fair' = 'Fair',
	'Poor' = 'Poor'
}

export enum HandCouplingMapper {
	'GOOD' = 'Good',
	'FAIR' = 'Fair',
	'POOR' = 'Poor'
}

export enum FrequencyTimeFormatMapper {
	'MINUTE' = 'minute',
	'HOUR' = 'hour'
}

export enum TaskMapper {
	'Lifts' = 'lifts',
	'Lowers' = 'lowers',
	'Pushes' = 'pushes',
	'Pulls' = 'pulls',
	'Carries' = 'carries'
}

type RiskLevelToColorMapper = {
	[key: number]: string;
};

export const riskLevelToColorMapper: RiskLevelToColorMapper = {
	0: '#8a8a8a',
	1: '#2cc852',
	2: '#FFDE31',
	3: '#F78A38',
	4: '#E74150',
	5: '#9B54E2'
};

export type OptionType = { label: string; value: string } | undefined;

export type EwaJdsD86RouteParams = {
	file_id: string;
	original_custom_report_result_id?: string;
};

export type SystemOfUnits = {
	[key: string]: string;
};

export interface ActionPlan {
	id: string
	title: string
	deadline: string
	description: string
	responsible_name: string
	tasks: Task[]
}

export interface Task {
	id: string
	description: string
	type: string
	is_completed: boolean
	is_active: boolean
	created_at: string
	updated_at: string
	createdAt: string
	updatedAt: string
	action_plan_id: string
}

export type FormStep = {
	icon?: JSX.Element;
	name?: string;
	description?: string;
	component: JSX.Element;
	step_keys?: { id: string; key: number; title: string; others_itens: any[] }[];
};

export type States = {
	isEwa: boolean;
	steps: Step[];
	file_id: string;
	currentStep: number;
	currentStepId: string;
	lastGenericStepNumber: number;
	ewaJdsD86: EwaJdsD86ReportResponse;
	customReport: GetCustomReportByNameResponse;
	original_custom_report_result_id?: string;
};

export type Methods = {
	handleNextStep: () => Promise<void>;
	handlePreviousStep: () => void;
	handleOpenModal: (data: ActionPlan) => void;
	handleStepNagivationClick: (step: number) => Promise<void>;
	scrollToError: (error: any) => void;
};

export type Context = States & Methods;
