import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { useApplicationContext } from '@/context/v1/Application/context';
import { CompanySelect } from '@/components/ui/Inputs/CompanySelect';
import { useListCompanies } from '@/hooks/v1/useListCompanies';

const { useFormInstance, useWatch } = Form;

export const Companies: React.FC = () => {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();
	const { organization, company } = useApplicationContext();

	const companyId = useWatch('company_id', form);
	const {
		data: companies,
		isLoading,
		isFetching,
		isError
	} = useListCompanies({ organization_id: organization?.id, company_id: company?.id });

	function handleCompanySelect(id: string) {
		setFieldValue(['company_id'], id);
		setFieldValue(['sector_id'], undefined);
		setFieldValue(['line_id'], undefined);
		setFieldValue(['workstation_id'], undefined);
	}

	function handleClearCompany() {
		setFieldValue(['company_id'], undefined);
		setFieldValue(['sector_id'], undefined);
		setFieldValue(['line_id'], undefined);
		setFieldValue(['workstation_id'], undefined);
	}

	return (
		<Col xs={24} sm={12}>
			<Form.Item
				name={['company_id']}
				label={I18n.get('Industrial site')}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: true,
						message: I18n.get('Select the industrial site')
					}
				]}
			>
				<CompanySelect
					companies={companies}
					loading={isFetching}
					disabled={isLoading || isFetching || isError}
					companyId={companyId}
					handleClearCompany={handleClearCompany}
					handleSelectCompany={handleCompanySelect}
				/>
			</Form.Item>
		</Col>
	);
};
