import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form, Input } from 'antd';

export const RiskCategory = () => {
	return (
		<Col xs={24} sm={24} md={12} lg={8} xxl={8}>
			<Form.Item label={I18n.get('Risk category')} name={['filter', 'name']}>
				<Input placeholder={I18n.get('Risk category')} />
			</Form.Item>
		</Col>
	);
};
