import React from 'react';
import { Col, Divider, Form } from 'antd';

import { riskLevelToColorMapper } from '@/components/views/JdsD92/context/types';
import { useStepKeysResultsContext } from './StepKeyDescription/context';
import { CollapseResults } from './CollapseResults';

const { useWatch, useFormInstance } = Form;

export const stressDescription = [
	'Not analyzed',
	'Acceptable Risk',
	'Moderate Risk',
	'High Risk',
	'Very High Risk',
	'Extreme and Imminent Risk'
];

interface StepsDescriptionProps {
	isLastStepKey: boolean;
}

export const StepsDescription: React.FC<StepsDescriptionProps> = ({ isLastStepKey }) => {
	const form = useFormInstance();

	const { stepKey } = useStepKeysResultsContext();

	const collapse = useWatch([stepKey.id, 'collapse'], form);

	function isCollapseClosed(): boolean {
		return !collapse || collapse.length === 0;
	}

	const color = riskLevelToColorMapper[stepKey.risk_scale ?? 0];

	return (
		<>
			<CollapseResults color={color} stepName={stepKey.description} stepKey={stepKey} />
			{isCollapseClosed() && !isLastStepKey && (
				<Col span={1}>
					<Divider
						type="vertical"
						style={{
							width: '4px',
							border: 'none',
							height: '40px',
							borderRadius: '2px',
							margin: '0 0 0 52px',
							backgroundColor: '#e6e8e8'
						}}
					/>
				</Col>
			)}
		</>
	);
};
