import React from 'react';
// import moment from 'moment';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Item, ItemValue } from './styles';

// const DATE_FORMAT = 'DD/MM/YYYY';

export const JobInformations = ({ rangeRisk, collectionDate, company, sector, line, workstation, repetition }) => (
	<Col sm={24} style={{ padding: '10px 0px 10px 0px' }}>
		<Row>
			<Col sm={24}>
				<h3>{I18n.get('Job information')}</h3>
			</Col>
			<Col sm={4}>
				<Item>{I18n.get('Range of Risk')}</Item>
				<ItemValue>{rangeRisk}</ItemValue>
			</Col>
			<Col sm={4}>
				<Item>{I18n.get('Collection date')}</Item>
				<ItemValue>{collectionDate}</ItemValue>
			</Col>
			<Col sm={4}>
				<Item>{I18n.get('Company')}</Item>
				<ItemValue>{company}</ItemValue>
			</Col>
			<Col sm={4}>
				<Item>{I18n.get('Sector')}</Item>
				<ItemValue>{sector}</ItemValue>
			</Col>
			<Col sm={4}>
				<Item>{I18n.get('Line')}</Item>
				<ItemValue>{line}</ItemValue>
			</Col>
			<Col sm={4}>
				<Item>{I18n.get('Workstation')}</Item>
				<ItemValue>{workstation}</ItemValue>
			</Col>
			{repetition && (
				<Col sm={4}>
					<Item>{I18n.get('Repetitions per minute')}</Item>
					<ItemValue>{repetition}</ItemValue>
				</Col>
			)}
		</Row>
	</Col>
);
