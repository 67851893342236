import React from 'react';
import { I18n } from '@aws-amplify/core';
import { useHistory } from 'react-router';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { CircularProgressbar } from 'react-circular-progressbar';
import { List, Popconfirm, Checkbox, Row, Col, Button, Select } from 'antd';
import { MdDeleteForever, MdCheckCircle, MdError, MdVideocam } from 'react-icons/md';

import { Container } from './styles';

const EWA_D86_REPORT_NAME = 'ewa_d86';

export default function ListUpload({ files, onDelete, inProcessUpload, onUpdate, customReports }) {
	const history = useHistory();

	const HeaderList = () => (
		<Row type="flex" align="middle" justify="center">
			<Col sm={hasCustomReport() ? 13 : 17}>
				<span style={{ marginLeft: '48px' }}>{I18n.get('Description')}</span>
			</Col>
			{hasCustomReport() && (
				<Col sm={4} style={{ textAlign: 'left' }}>
					<span>{I18n.get('Custom report')}</span>
				</Col>
			)}
			<Col sm={2} style={{ textAlign: 'center' }}>
				<span>{I18n.get('Blur')}</span>
			</Col>
			<Col sm={3} style={{ textAlign: 'center' }}>
				<span>{I18n.get('Actions')}</span>
			</Col>
		</Row>
	);

	function hasCustomReport() {
		return customReports?.data?.length > 0;
	}

	function showCustomReportDropdown(item) {
		return (
			<Col sm={5} style={{ textAlign: 'center' }}>
				<Select
					allowClear
					placeholder={I18n.get('Choose')}
					loading={customReports?.isLoading || customReports?.isFetching}
					options={filterAndMapCustomReports()}
					disabled={inProcessUpload}
					onChange={(value) =>
						onUpdate(item?.id, {
							custom_report: value
						})
					}
				/>
			</Col>
		);
	}

	function filterAndMapCustomReports() {
		return customReports?.data
			?.filter(({ name }) => name !== EWA_D86_REPORT_NAME)
			?.map(({ id, acronym }) => ({
				label: acronym,
				value: id
			}));
	}

	function handleRedirectReports() {
		history.push('/reporting');
	}

	return (
		<Container>
			<div style={{ height: '40px', width: '100%' }}>
				<Button type="link" onClick={handleRedirectReports} style={{ display: 'block', float: 'right' }}>
					{I18n.get('Reports')}
				</Button>
			</div>
			{!!files?.length > 0 && (
				<List
					header={<HeaderList />}
					dataSource={files}
					renderItem={(item) => (
						<List.Item key={item.id}>
							<Row type="flex" align="middle" justify="center" style={{ width: '100%' }}>
								<Col
									sm={customReports?.data?.length > 0 ? 12 : 17}
									style={{
										display: 'flex',
										flexDirection: 'row',
										flexWrap: 'wrap'
									}}
								>
									<MdVideocam size={30} color="#7159c1" style={{ textAlign: 'center' }} />
									<strong style={{ paddingLeft: '16px' }}>{item.name}</strong>
								</Col>
								{hasCustomReport() && showCustomReportDropdown(item)}
								<Col sm={2} style={{ textAlign: 'center' }}>
									<Checkbox
										checked={item.blur}
										disabled={item.blocking}
										onChange={({ target }) =>
											onUpdate(item.id, {
												blur: target.checked
											})
										}
									/>
								</Col>
								<Col sm={3} style={{ textAlign: 'center' }}>
									{!item.uploaded && !item.error && (
										<CircularProgressbar
											styles={{
												root: { width: 24 },
												path: { stroke: '#7159c1' }
											}}
											strokeWidth={10}
											value={item.progress}
										/>
									)}
									{!item.error && item.uploaded && <MdCheckCircle size={24} color="#78e5d5" />}
									{item.error && <MdError size={24} color="#e57878" />}
									{!item.progress && (
										<Popconfirm
											okText="Ok"
											placement="topRight"
											title={I18n.get('Are you sure?')}
											cancelText={I18n.get('Cancel')}
											onConfirm={() => onDelete(item.id)}
											icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
										>
											<MdDeleteForever size={24} color="#e57878" style={{ marginRight: 22 }} />
										</Popconfirm>
									)}
								</Col>
							</Row>
						</List.Item>
					)}
				/>
			)}
		</Container>
	);
}
