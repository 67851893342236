import React from 'react';
import { Row, Col, Table } from 'antd';
import { I18n } from '@aws-amplify/core';
import type { ColumnsType } from 'antd/es/table';

import { Text, Title } from '@/components/Typography';
import { WorkConditionsResult } from '@/hooks';
import { TableContainer } from './styles';

interface DataType {
	key: string;
	place_description: string;
	expected_task_description: string;
	performed_task_description: string;
}

interface WorkConditionsProps {
	workConditions: WorkConditionsResult;
}

export function WorkConditions({ workConditions }: WorkConditionsProps) {
	const { expected_task_description, performed_task_description, place_description, work_hours, work_minutes } =
		workConditions;
	const columns: ColumnsType<DataType> = [
		{
			title: I18n.get('Summary description of the place'),
			dataIndex: 'place_description',
			key: 'place_description'
		},
		{
			title: I18n.get('Simplified description of the expected task'),
			dataIndex: 'expected_task_description',
			key: 'expected_task_description'
		},
		{
			title: I18n.get('Simplified description of the task performed'),
			dataIndex: 'performed_task_description',
			key: 'performed_task_description'
		}
	];

	const data: DataType[] = [
		{
			key: '1',
			place_description: place_description ?? 'N/A',
			expected_task_description: expected_task_description ?? 'N/A',
			performed_task_description: performed_task_description ?? 'N/A'
		}
	];

	function formatWorkScheduleText(hours_work_schedule: number, minutes_work_schedule: number) {
		const work_hours = hours_work_schedule;
		const work_minutes = minutes_work_schedule;

		let message = `${work_hours} ${I18n.get('hours')}`;

		if (work_minutes !== 0) {
			message = `${work_hours} ${I18n.get('hours')} ${I18n.get('and')} ${work_minutes} ${I18n.get('minutes')}`;
		}

		return message;
	}

	return (
		<Row gutter={[0, 30]}>
			<Col style={{ marginLeft: '15px' }} span={22}>
				<Row align="middle" gutter={[5, 0]}>
					<Col>
						<Title style={{ margin: 0 }} level={5}>
							{I18n.get('Hours worked in the day')}:
						</Title>
					</Col>
					<Col>
						<Text style={{ fontSize: '16px' }}>{formatWorkScheduleText(work_hours, work_minutes)}</Text>
					</Col>
				</Row>
			</Col>
			<Col span={23}>
				<TableContainer>
					<Table columns={columns} dataSource={data} pagination={false} />
				</TableContainer>
			</Col>
		</Row>
	);
}
