import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, Form, Button, InputNumber, Input } from 'antd';

import { useApplicationContext } from '@/context/v1/Application/context';
import type { AdditionalItem, GetAdditionalToolScoreDTO } from '@/hooks';
import { useCalculateAdditionalToolScoreWithPostRoute } from '@/hooks';
import { worstRiskCalculator } from './utils/WorstResultCalculator';
import type { AditionalItemProps, FormFieldValues } from './types';
import { useStepKeysContext } from '../DefaultSelects/context';
import { fieldsUnitTexts } from './utils/FieldsUnitTexts';
import { ConditionCard } from '../../ConditionCard';
import { Text } from '@/components/Typography';
import { AdditionalItemsTypes } from './enum';
import { InfoList } from './InfoList';

const { useFormInstance, useWatch } = Form;
const { formatPlaceholderWithUnit } = fieldsUnitTexts;

export const Inputs: React.FC<AditionalItemProps> = ({ additionalItem }) => {
	const { id, name, title_1, title_2, title_3, unit_2, unit_3 } = additionalItem;

	const form = useFormInstance();
	const { formFieldName, stepKey } = useStepKeysContext();
	const { organization, company } = useApplicationContext();

	const [inputs, setInputs] = useState<AdditionalItem>(additionalItem);

	const formValueOne = useWatch([...formFieldName, 'additional_items', id, 'value_1']);
	const formValueTwo = useWatch([...formFieldName, 'additional_items', id, 'value_2']);
	const formValueThree = useWatch([...formFieldName, 'additional_items', id, 'value_3']);

	useEffect(() => {
		form.setFieldValue([...formFieldName, 'additional_items', 'local_values'], additionalItem);
	}, []);

	const { mutateAsync: calculateAdditionalToolScore, isLoading: isLoadingCalculateAdditionalToolScore } =
		useCalculateAdditionalToolScoreWithPostRoute();

	const worstRisk = worstRiskCalculator.getWorstResult(inputs.results);

	async function handleAddAdditionalItemValueClick(): Promise<void> {
		const payload: GetAdditionalToolScoreDTO = {
			value_1: formValueOne,
			value_2: formValueTwo,
			value_3: formValueThree,
			company_id: company?.id,
			organization_id: organization?.id,
			custom_report_step_key_id: stepKey.id
		};

		const additionalToolScore = await calculateAdditionalToolScore(payload);

		if (additionalToolScore?.result) {
			const formFieldValues: FormFieldValues = form.getFieldValue([...formFieldName, 'additional_items', id]);
			const newAdditionalItemValue = {
				...formFieldValues,
				id: uuidv4(),
				result: additionalToolScore.result
			};
			const results = inputs?.results ?? [];
			const additionalItemResultsCopy = [...results];
			additionalItemResultsCopy.push(newAdditionalItemValue);

			setInputs({
				...inputs,
				results: additionalItemResultsCopy
			});
			form.setFieldValue(
				[...formFieldName, 'additional_items', 'local_values', 'results'],
				additionalItemResultsCopy
			);
			form.setFieldValue([...formFieldName, 'additional_items', id], undefined);
		}
	}

	function handleRemoveAdditionalItemClick(id: string): void {
		const resultsWithoutRemovedItem = inputs.results.filter((item) => item.id !== id);
		const newResults = {
			...inputs,
			results: resultsWithoutRemovedItem
		};

		setInputs(newResults);
		form.setFieldValue(
			[...formFieldName, 'additional_items', 'local_values', 'results'],
			resultsWithoutRemovedItem
		);
	}

	function hasSomeEmptyField(): boolean | undefined {
		return !formValueOne || !formValueTwo || !formValueThree;
	}

	return (
		<Row gutter={[20, 15]} key={id}>
			<Col span={18}>
				<Row gutter={[14, 20]}>
					<Col span={24}>
						<Row gutter={[5, 0]}>
							<Col>
								<Text strong>{title_1}</Text>
							</Col>
							<Col span={24}>
								<Form.Item
									style={{ margin: 0 }}
									name={[...formFieldName, 'additional_items', id, 'value_1']}
								>
									<Input placeholder={title_1} style={{ width: '100%' }} />
								</Form.Item>
							</Col>
						</Row>
					</Col>
					<Col span={12}>
						<Row gutter={[5, 0]}>
							<Col>
								<Text strong>{title_2}</Text>
							</Col>
							<Col span={24}>
								<Form.Item
									style={{ margin: 0 }}
									name={[...formFieldName, 'additional_items', id, 'value_2']}
								>
									<InputNumber
										min={0}
										style={{ width: '100%' }}
										placeholder={formatPlaceholderWithUnit('0', unit_2)}
									/>
								</Form.Item>
							</Col>
						</Row>
					</Col>
					<Col span={12}>
						<Row gutter={[5, 0]}>
							<Col>
								<Text strong>{title_3}</Text>
							</Col>
							<Col span={24}>
								<Form.Item
									style={{ margin: 0 }}
									name={[...formFieldName, 'additional_items', id, 'value_3']}
								>
									<InputNumber
										min={0}
										style={{ width: '100%' }}
										placeholder={formatPlaceholderWithUnit('0', unit_3)}
									/>
								</Form.Item>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
			<Col style={{ paddingTop: 26 }}>
				<Button
					type="primary"
					icon={<PlusOutlined />}
					style={{ height: '100%', borderRadius: 5 }}
					onClick={handleAddAdditionalItemValueClick}
					disabled={hasSomeEmptyField() || isLoadingCalculateAdditionalToolScore}
				/>
			</Col>
			<Col span={24}>
				<InfoList
					items={inputs}
					onDeleteValue={handleRemoveAdditionalItemClick}
					isEwa={name === AdditionalItemsTypes.DISTANCES_AND_DIMENSIONS_INADEQUATE}
				/>
			</Col>
			<Col span={24}>
				<ConditionCard
					result={worstRisk}
					isLoading={isLoadingCalculateAdditionalToolScore}
					isEwa={name === AdditionalItemsTypes.DISTANCES_AND_DIMENSIONS_INADEQUATE}
				/>
			</Col>
		</Row>
	);
};
