import React, { ReactNode } from 'react';
import { I18n } from '@aws-amplify/core';

import { NamePath } from 'antd/lib/form/interface';
import { SelectId } from '../styles';

type SelectorProps = {
	disabled: boolean;
	currentId?: string;
	fieldLabel: string;
	isEditing: boolean;
	fieldName: NamePath;
	children: ReactNode;
};

export const Selector: React.FC<SelectorProps> = ({
	disabled,
	children,
	currentId,
	isEditing,
	fieldName,
	fieldLabel,
}) => {
	return (
		<SelectId
			selected={!!currentId || isEditing || !disabled}
			name={fieldName}
			label={I18n.get(`Select ${fieldLabel}`)}
			labelCol={{ span: 24 }}
			style={{ margin: '0' }}
			rules={[
				{
					message: I18n.get(`Please, select ${fieldLabel}`)
				}
			]}
		>
			{children}
		</SelectId>
	);
};
