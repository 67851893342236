import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { Form, notification } from 'antd';

import type { EwaJdsD86ReportResponse, GetCustomReportByNameResponse, Step } from '@/hooks';
import { Informations, GenericStep, SelectFile } from '../Report/Steps';
import { Results } from '../Report/Steps/Results';
import type { States, Context, Methods } from './types';

type JdsD92ProviderProps = {
	children: ReactNode;
	jdsD92: EwaJdsD86ReportResponse;
	customReport: GetCustomReportByNameResponse;
};

const { useForm, useWatch } = Form;
const JdsD92Context = createContext<Context>({} as Context);

type DefaultStepsMapper = {
	[key: string]: JSX.Element;
};

const defaultSteps: DefaultStepsMapper = {
	video_selection: <SelectFile />,
	informations: <Informations />
};

export const JdsD92Provider: React.FC<Readonly<JdsD92ProviderProps>> = ({ children, jdsD92, customReport }) => {
	const [form] = useForm();

	const [currentStep, setCurrentStep] = useState<number>(jdsD92.current_step ?? 0);
	const [steps, setSteps] = useState<Step[]>([]);
	const fileForm = useWatch(['file_id'], form) || {};

	const initialValues = {
		...jdsD92,
		informations: {
			...jdsD92.informations,
			collection_date: moment(jdsD92.informations.collection_date) ?? moment()
		}
	};

	useEffect(() => {
		const steps: Step[] = jdsD92.steps.map(({ id, name, description, sequence, step_keys }) => ({
			id,
			name,
			sequence,
			step_keys,
			description,
			component: defineComponent({ id, name, description, sequence, step_keys })
		}));

		setSteps(steps);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function defineComponent({ id, name, description, step_keys, sequence }: Step): JSX.Element {
		let defaultComponent = defaultSteps[name];

		if (!defaultComponent) {
			defaultComponent = (
				<GenericStep title={description} stepKeys={step_keys} stepId={id} stepNumber={sequence} />
			);
		}

		let component = defaultComponent;

		if (isResultStep(name)) {
			component = <Results />;
		}

		return component;
	}

	function isResultStep(name: string) {
		return name === 'results';
	}

	function scrollToError(error: any) {
		const errorName = error?.errorFields[0]?.name;
		form.scrollToField(errorName, { behavior: 'smooth', block: 'center', inline: 'center' });

		notification.error({
			message: I18n.get('Ops... something happened!'),
			description: I18n.get("Some required step wasn't filled"),
			duration: 5
		});
	}

	function handlePreviousStep() {
		setCurrentStep(currentStep - 1);
	}

	async function handleStepNagivationClick(step: number) {
		if ((!jdsD92?.is_completed || !jdsD92?.file_id) && step + 1 > currentStep + 1) {
			return;
		}

		if (jdsD92?.is_completed && step === 0) {
			return;
		}

		if (step > currentStep) {
			await form.validateFields();
		}

		setCurrentStep(step);
	}

	function handleNextStep() {
		setCurrentStep(currentStep + 1);
	}

	const currentStepId = steps[currentStep]?.id;

	const states: States = {
		steps,
		file_id: fileForm?.id ?? jdsD92?.file_id,
		jdsD92,
		currentStep,
		customReport,
		currentStepId,
		lastGenericStepNumber: jdsD92.total_steps - 1
	};

	const methods: Methods = {
		scrollToError,
		handleNextStep,
		handlePreviousStep,
		handleStepNagivationClick
	};

	const context: Context = {
		...states,
		...methods
	};

	return (
		<JdsD92Context.Provider value={context}>
			<Form form={form} initialValues={initialValues}>
				{children}
			</Form>
		</JdsD92Context.Provider>
	);
};

export function useJdsD92Context() {
	const context = useContext(JdsD92Context);
	return context;
}
