import React from 'react';
import { Breadcrumb } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Link, useLocation } from 'react-router-dom';
import { breadcrumbNameMap } from './constants';

export function WrapperBreadcrumb() {
	const location = useLocation();

	const pathSnippets = location.pathname.split('/').filter((i) => i);
	const extraBreadcrumbItems = pathSnippets.map((_, index) => {
		const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;

		if (pathSnippets.length === index + 1) {
			return (
				<Breadcrumb.Item key={url}>
					<Link to={url}>{I18n.get(breadcrumbNameMap[url]) || '-'}</Link>
				</Breadcrumb.Item>
			);
		}

		return (
			<Breadcrumb.Item key={url}>
				<Link to={url}>{I18n.get(breadcrumbNameMap[url])}</Link>
			</Breadcrumb.Item>
		);
	});

	const breadcrumbItems = [
		<Breadcrumb.Item key="home">
			<Link to="/">{I18n.get('Home')}</Link>
		</Breadcrumb.Item>
	].concat(extraBreadcrumbItems);

	return <Breadcrumb>{breadcrumbItems}</Breadcrumb>;
}
