import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, message, Button } from 'antd';
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';

import Api from '@/services/api';
import { CreateModal } from './CreateModal';
import { ListItens, EmptyList } from './List';
import { CreateForm } from './CreateForm';

export function Company({ data, onSelect, visibleCreate, closeCreate, filtered, specialPlan }) {
	const [form] = Form.useForm();
	const queryClient = useQueryClient();

	const organization = useSelector((state) => state.organization.organization);

	const createCompany = useMutation((body) => Api.post('/company/create', body), {
		onError: (err) => {
			const response = err.response.data.errors[0];
			const errorMessage = `${response.msg}: ${response.param}`;
			message.error(I18n.get(errorMessage));
		},
		onSuccess: (company) => {
			queryClient.invalidateQueries(['companies']);
			closeCreate();
			onSelect(company.data);
			message.success(I18n.get('Industrial site created successfully'));
		}
	});

	function onSubmit() {
		form.validateFields().then((values) => {
			createCompany.mutate({
				...values,
				organization_id: organization.id,
				url_logo: ''
			});
		});
	}

	if (data?.length === 0 && !filtered) {
		return (
			<>
				<EmptyList
					description={I18n.get(
						'Congratulations! Now create an industrial site that belongs to this company.'
					)}
				/>
				<div style={{ padding: '10px 20px 0' }}>
					<CreateForm form={form} type="organization" />
					<Button type="primary" onClick={onSubmit} loading={createCompany.isLoading} disabled={specialPlan}>
						{I18n.get('Create industrial site')}
					</Button>
				</div>
			</>
		);
	}

	return (
		<div>
			<CreateModal
				form={form}
				loading={createCompany.isLoading}
				type="company"
				onSubmit={onSubmit}
				onCancel={closeCreate}
				visible={visibleCreate}
			/>
			{data?.length === 0 ? (
				<EmptyList description={I18n.get('There are no registered industrial sites')} />
			) : (
				<ListItens data={data} onAction={onSelect} />
			)}
		</div>
	);
}
