import React from 'react';
import { Col, Select } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useApplicationContext } from '@/context/v1/Application/context';
import { CustomFormItem } from '@/components/CustomReports/styles';

interface OrganizationsProps {
	fieldName?: string;
}

export const Organizations: React.FC<OrganizationsProps> = () => {
	const { organization } = useApplicationContext();

	const organizations = [
		{
			label: organization?.name,
			value: organization?.id
		}
	];

	return (
		<Col xs={24}>
			<CustomFormItem labelCol={{ span: 24 }} label={I18n.get('Company')} style={{ marginBottom: '0rem' }}>
				<Select size="large" disabled={true} value={organization?.id} options={organizations} />
			</CustomFormItem>
		</Col>
	);
};
