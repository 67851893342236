import React from 'react';
import { Col } from 'antd';

import { CheckCircleTwoTone } from '@ant-design/icons';
import { Title } from '@/components/Typography';

export const Header = () => {
	return (
		<div>
			<Col span={24} style={{ marginBottom: '1rem' }}>
				<CheckCircleTwoTone style={{ fontSize: '4rem' }} />
			</Col>

			<Col span={24}>
				<Title level={4}>Two-factor authentication</Title>
			</Col>
			<Col span={24}>
				<Title style={{ fontWeight: '400', fontSize: '0.8rem', margin: '0 0 2rem 0' }}>
					For protection reasons, we need that you validate your 2FA
				</Title>
			</Col>
		</div>
	);
};
