import React, { useEffect, useState } from 'react';
import { Col, Divider, Form, Row, Select } from 'antd';
import { Title } from '@/components/Typography';
import { useParams } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import { useApplicationContext } from '@/context/v1/Application/context';
import { SeraStepsRouteParams, useSeraStepsContext } from '../context';
import { useSeraResults, useSeraReviews } from './hooks/hooks';
import { PayloadDownloadPDF } from './hooks/types';
import { Spinner } from '@/components/Spinner';

import { ResultsContainer, Topic } from './styles';
import { ReviewsHistory } from './ReviewsHistory';
import { TasksAnalyzed } from './TasksAnalyzed';
import { CycleSummary } from './CycleSummary';
import { Informations } from './Informations';
import { TaskSummary } from './TaskSummary';
import { DownloadPDF } from './DownloadPDF';
import { ActionPlans } from './ActionPlans';
import { HighestRPN } from './HighestRPN';
import { Comment } from './Comment';
import { Header } from './Header';

const { useFormInstance } = Form;

export const Results: React.FC = () => {
	const [selectedReview, setSelectedReview] = useState(0);
	const [isEditingComment, setIsEditingComment] = useState(false);

	const form = useFormInstance();
	const { seraSummaryId } = useSeraStepsContext();
	const { organization, company } = useApplicationContext();
	const { sera_summary_id } = useParams<SeraStepsRouteParams>();

	const {
		data: reviewSelector,
		isFetching: gettingReviews,
		isError: errorGettingReviews
	} = useSeraReviews({
		company_id: company?.id,
		organization_id: organization?.id,
		sera_summary_id: sera_summary_id ?? seraSummaryId
	});

	const { data, isLoading, isFetching, isError, refetch } = useSeraResults({
		company_id: company?.id,
		organization_id: organization?.id,
		sera_summary_id: sera_summary_id ?? seraSummaryId,
		review: selectedReview
	});

	const browserLanguage = window.navigator.language ?? 'en-US';

	const payloadDownloadPDF: PayloadDownloadPDF = {
		organization_id: organization?.id,
		company_id: company?.id,
		sera_summary_id: sera_summary_id ?? seraSummaryId,
		review: selectedReview,
		locale: browserLanguage
	};

	const versions = reviewSelector?.map((reviewOption) => {
		let label = I18n.get(reviewOption?.description);
		if (reviewOption.review >= 0) {
			const versionDifferentThanOriginal = reviewOption.review > 0 ? `${I18n.get('Review')} ` : '';
			label = `${versionDifferentThanOriginal}${I18n.get(reviewOption?.description)} (${moment(
				reviewOption?.updated_at
			).format('L')})`;
		}

		return {
			value: reviewOption?.id,
			label
		};
	});

	const lastReview = reviewSelector[reviewSelector.length - 1];

	useEffect(() => {
		if (reviewSelector.length > 0) {
			refetch();
			form.setFieldValue('review', versions[reviewSelector.length - 1]);
			setSelectedReview(lastReview?.review ?? 0);
		}
	}, [gettingReviews]);

	useEffect(() => {
		if (data?.comment) {
			form.setFieldValue('comment', data.comment);
		}
	}, [data]);

	if (isError || errorGettingReviews) {
		return (
			<Row justify="center" style={{ marginBottom: '1rem' }}>
				<Col span={24}>
					<span>{I18n.get('Oops... Something went wrong!')}</span>
				</Col>
			</Row>
		);
	}

	if (isLoading || isFetching || gettingReviews) {
		return (
			<Row justify="center" style={{ marginBottom: '1rem' }}>
				<Col span={24}>
					<Spinner size={24} />
				</Col>
			</Row>
		);
	}

	function handleCommentChange(editingComment: boolean) {
		setIsEditingComment(editingComment);
	}

	function handleVersionSelectorChange(id: string) {
		const review = reviewSelector.find((reviewOption) => reviewOption.id === id);
		setSelectedReview(review?.review ?? -1);
	}

	const review = selectedReview < 0 ? lastReview.review : selectedReview;

	return (
		<Row justify="center" style={{ marginBottom: '1rem' }}>
			<Col span={23} style={{ marginBottom: '2rem' }}>
				<Row align="middle" justify="space-between">
					<Col>
						<Row>
							<Title level={4}>Result S.E.R.A.</Title>
						</Row>
						<Row>
							<Title style={{ fontWeight: 400 }} level={5}>
								Cycle results
							</Title>
						</Row>
					</Col>
					<Col xs={3}>
						<DownloadPDF parameters={payloadDownloadPDF} disableDownload={isEditingComment} />
					</Col>
				</Row>
				<Row align="middle" justify="end">
					<Col style={{ marginRight: '0.25rem' }}>
						<Topic>{I18n.get('Version')}</Topic>
					</Col>
					<Col xs={4}>
						<Form.Item style={{ margin: 0 }} name="review" labelCol={{ span: 24 }}>
							<Select onChange={handleVersionSelectorChange} options={versions} />
						</Form.Item>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<ResultsContainer>
					<Header />
					<Divider type="horizontal" />

					<Informations review={review} informations={data.informations} />
					<Divider type="horizontal" />

					<CycleSummary cycleSummary={data.cycle_summary} />
					<Divider type="horizontal" />

					<TasksAnalyzed analyzedTasks={data.analyzed_tasks} />
					<Divider type="horizontal" />

					<TaskSummary taskSummary={data.task_summaries} />
					<Divider type="horizontal" />

					<HighestRPN highestRpnScores={data.highest_rpn_scores} />
					<Divider type="horizontal" />

					{data.action_plans && (
						<Row>
							<ActionPlans actionPlans={data?.action_plans} />
							<Divider type="horizontal" />
						</Row>
					)}

					{data.reviews_history && data.reviews_history.length > 0 && (
						<Row>
							<ReviewsHistory reviewsHistory={data.reviews_history} />
							<Divider type="horizontal" />
						</Row>
					)}

					<Comment onCommentChange={handleCommentChange} />
					<DownloadPDF parameters={payloadDownloadPDF} disableDownload={isEditingComment} />
				</ResultsContainer>
			</Col>
		</Row>
	);
};
