import React from 'react';
import { Paragraph, Text } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';
import { Col, Row } from 'antd';
import moment from 'moment';

import { SubtitleCol } from './styles';

export const SubTitle = () => {
	const today = moment().format('L');

	return (
		<Col xs={24}>
			<Row justify="start">
				<SubtitleCol xs={24}>
					<Text strong>{I18n.get('General informations')}</Text>
					<Text>
						{I18n.get('Date')}: {today}
					</Text>
				</SubtitleCol>
				<Col xs={16}>
					<Paragraph>Select below the video files referring to the tasks that make up the cycle</Paragraph>
				</Col>
			</Row>
		</Col>
	);
};
