import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

interface GetFilesParams {
	organization_id: string;
	company_id: string;
	sector_id: string;
	workstation_id?: string;
}

async function getFiles(params: GetFilesParams): Promise<any> {
	const { organization_id, company_id, sector_id, workstation_id } = params;
	const url = `/upload/${organization_id}/${company_id}/${sector_id}`;
	const { data } = await Api.get(url, { params: { workstation_id } });
	return data;
}

export function useGetFiles(params: GetFilesParams) {
	return useQuery(['files', params], () => getFiles(params), {
		enabled: !!params.organization_id && !!params.company_id && !!params.sector_id
	});
}
