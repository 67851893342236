import React from 'react';
import { NamePath } from 'antd/lib/form/interface';
import { I18n } from '@aws-amplify/core';
import { Col, Input } from 'antd';

import { CustomFormItem } from './styles';

const { TextArea } = Input;

interface ExistingPreventionMeasuresProps {
	fieldName: NamePath;
}

export const ExistingPreventionMeasures: React.FC<ExistingPreventionMeasuresProps> = ({ fieldName }) => {
	return (
		<Col xs={24} sm={12} lg={24}>
			<CustomFormItem name={fieldName} label={I18n.get('Existing prevention measures')} labelCol={{ span: 24 }}>
				<TextArea rows={4} maxLength={500} showCount />
			</CustomFormItem>
		</Col>
	);
};
