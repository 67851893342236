import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col } from 'antd';

import { CustomLabel } from '@/components/CustomLabel';
import { CustomFormItem, CustomRow } from './styles';
import { CustomIcon } from './CustomIcon';

interface SeverityProps {
	severity: string;
}

export const Severity: React.FC<SeverityProps> = ({ severity }) => {
	return (
		<Col xs={24} sm={12} lg={24}>
			<CustomFormItem
				label={<CustomLabel icon={<CustomIcon title="How severe could the injury be?" />} title="Severity" />}
				labelCol={{ span: 24 }}
			>
				<CustomRow>{I18n.get(severity)}</CustomRow>
			</CustomFormItem>
		</Col>
	);
};
