import React from 'react';
import { Paragraph, Title } from '@/components/Typography';
import { UseQueryResult } from '@tanstack/react-query';
import { Col, Row, Skeleton } from 'antd';
import { I18n } from '@aws-amplify/core';

import { FileForCustomReport } from '@/components/ui/Inputs/types/response/file';
import { TaskDTO } from '@/components/ui/Inputs/types/response/task';

import { useBeraReportStepsContext } from '../../context';
import { CollectionDate } from './CollectionDate';
import { HasKnownInjury } from './HasKnownInjury';
import { TaskTime } from './TaskTime';
import { Tasks } from './Tasks';

interface InformationsProps {
	file: FileForCustomReport;
	cycleId: string;
	tasks: UseQueryResult<TaskDTO[], unknown>;
}

export const Informations: React.FC<InformationsProps> = ({ file, tasks, cycleId }) => {
	const { isLoading } = useBeraReportStepsContext();

	return (
		<Row>
			<Col xs={24}>
				<Title level={4}>Task</Title>
			</Col>
			<Col xs={24}>
				<Paragraph>Insert below the informations related to the selected video</Paragraph>
			</Col>
			<Col xs={24} offset={1}>
				<Row gutter={[24, 24]}>
					<Col xs={24}>
						<span>
							{I18n.get('File')}: {file.original_name}
						</span>
					</Col>
					<Col xs={24}>
						{isLoading ? (
							<Row>
								<Col span={20}>
									<Skeleton active />
								</Col>
							</Row>
						) : (
							<Row gutter={[10, 0]}>
								<Col xs={24} sm={24}>
									<Row>
										<Tasks
											fileId={file.id}
											fieldName={[file.id, 'task_id']}
											tasks={tasks}
											cycleId={cycleId}
										/>
									</Row>
								</Col>
								<Col xs={24}>
									<Row>
										<TaskTime
											taskTimeFieldName={[file.id, 'task_time']}
											taskTimeFormatFieldName={[file.id, 'task_time_format']}
										/>
									</Row>
								</Col>
								<Col xs={24} sm={24}>
									<Row>
										<HasKnownInjury fieldName={[file.id, 'has_known_injury']} />
									</Row>
								</Col>
								<Col xs={24} sm={24}>
									<Row>
										<CollectionDate fieldName={[file.id, 'collection_date']} />
									</Row>
								</Col>
							</Row>
						)}
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
