import React from 'react';
import { Col, Form, Row, Select } from 'antd';

import { SeverityDTO } from '@/components/ui/Inputs';
import { I18n } from '@aws-amplify/core';

const { useFormInstance, useWatch } = Form;

interface CurrentSeverityProps {
	severities: SeverityDTO[];
}

export const CurrentSeverity: React.FC<CurrentSeverityProps> = ({ severities }) => {
	const form = useFormInstance();

	const currentSeverityId = useWatch(['current_severity_id'], form);

	const severitiesOptions = severities?.map(({ id, description, score }) => ({
		label: I18n.get(`[${score}] ${description}`),
		value: id
	}));

	return (
		<Col span={24}>
			<Row align="bottom" justify="space-between" gutter={[8, 0]}>
				<Col span={20}>
					<Form.Item
						label={I18n.get('Current severity')}
						name="current_severity_id"
						labelCol={{ span: 24 }}
						style={{ margin: '0' }}
					>
						<Select
							disabled={true}
							showArrow={false}
							value={currentSeverityId}
							options={severitiesOptions}
							placeholder={I18n.get('Severity not set')}
						/>
					</Form.Item>
				</Col>
			</Row>
		</Col>
	);
};
