import React from 'react';
import { I18n } from '@aws-amplify/core';
import { NamePath } from 'antd/lib/form/interface';
import { Row, Col, Form, Radio, Space, InputNumber, Input } from 'antd';

import { CustomItem } from './styles';
import { Title, Paragraph } from '@/components/Typography';
import { stepDescription, selfEvaluationMap } from '../contants';

const { useFormInstance } = Form;

const sumIsWrong = (getFieldValue: (name: NamePath) => any) => {
	const qtdMen: number = getFieldValue(['preliminary_analysis', 'working_population_men']);
	const qtdWomen: number = getFieldValue(['preliminary_analysis', 'working_population_women']);
	const qtdTransgenders: number = getFieldValue(['preliminary_analysis', 'working_population_others']);
	return qtdMen + qtdWomen + qtdTransgenders === 0;
};

export const Characters: React.FC = () => {
	const form = useFormInstance();

	const sumValues = (getFieldValue: (name: NamePath) => any) => {
		const men_quantities: number = getFieldValue(['preliminary_analysis', 'working_population_men']);
		const women_quantities: number = getFieldValue(['preliminary_analysis', 'working_population_women']);
		const transgenders_quantities: number = getFieldValue(['preliminary_analysis', 'working_population_others']);

		const sum_all_people = men_quantities + women_quantities + transgenders_quantities;

		form.setFieldValue(['preliminary_analysis', 'total_working_population'], sum_all_people);

		return sum_all_people;
	};

	const validateFormFields = (): void => {
		form.validateFields();
	};

	return (
		<Row gutter={[0, 10]}>
			<Col span={12} offset={4}>
				<Title level={4}>Characteristics</Title>
			</Col>
			<Col span={12} offset={4}>
				<Paragraph>{stepDescription}</Paragraph>
			</Col>
			<Col span={12} offset={4}>
				<Row gutter={[10, 10]}>
					<Col span={24}>
						<Title level={5}>Working population</Title>
						<Row gutter={[10, 0]}>
							<Col>
								<CustomItem
									label={I18n.get('Men')}
									name={['preliminary_analysis', 'working_population_men']}
									rules={[
										{
											required: true,
											message: I18n.get('')
										},
										({ getFieldValue }) => ({
											validator() {
												if (sumIsWrong(getFieldValue)) {
													return Promise.reject(
														I18n.get('Working population must be greater than 0')
													);
												}
												sumValues(getFieldValue);
												return Promise.resolve();
											}
										})
									]}
								>
									<InputNumber onChange={validateFormFields} min={0} />
								</CustomItem>
							</Col>
							<Col>
								<CustomItem
									label={I18n.get('Women')}
									name={['preliminary_analysis', 'working_population_women']}
									rules={[
										{
											required: true,
											message: I18n.get('')
										},
										({ getFieldValue }) => ({
											validator() {
												if (sumIsWrong(getFieldValue)) {
													return Promise.reject();
												}
												return Promise.resolve();
											}
										})
									]}
								>
									<InputNumber onChange={validateFormFields} min={0} />
								</CustomItem>
							</Col>
							<Col>
								<CustomItem
									label={I18n.get('Others')}
									name={['preliminary_analysis', 'working_population_others']}
									rules={[
										{
											required: true,
											message: I18n.get('')
										},
										({ getFieldValue }) => ({
											validator() {
												if (sumIsWrong(getFieldValue)) {
													return Promise.reject();
												}
												return Promise.resolve();
											}
										})
									]}
								>
									<InputNumber onChange={validateFormFields} min={0} />
								</CustomItem>
							</Col>
							<Col>
								<CustomItem
									label={I18n.get('Total')}
									name={['preliminary_analysis', 'total_working_population']}
								>
									<InputNumber
										disabled={true}
										value={form.getFieldValue(['preliminary_analysis', 'total_working_population'])}
										onChange={validateFormFields}
										min={0}
									/>
								</CustomItem>
							</Col>
						</Row>
					</Col>
					<Col span={24}>
						<Title level={5}>Particularities</Title>
						<Form.Item name={['preliminary_analysis', 'particularities_description']}>
							<Input.TextArea rows={4} maxLength={500} showCount allowClear />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Title level={5}>Worker's verbalization</Title>
						<Form.Item name={['preliminary_analysis', 'worker_verbalization_description']}>
							<Input.TextArea rows={4} maxLength={500} showCount allowClear />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Title level={5}>Self-evaluation</Title>
						<Paragraph>
							What is the level of stress perceived by the worker when performing the task?
						</Paragraph>
						<Form.Item name={['preliminary_analysis', 'self_evaluation']}>
							<Radio.Group>
								<Space direction="vertical">
									{selfEvaluationMap.map((item, index) => (
										<Radio key={index} value={item.value}>
											{I18n.get(item.description)}
										</Radio>
									))}
								</Space>
							</Radio.Group>
						</Form.Item>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
