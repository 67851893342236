import Api from '@/services/api';

import {
	SeraResultDTO,
	SeraSummaryRequest,
	PayloadDownloadPDF,
	URLDownloadDTO,
	SeraReviewRequest,
	SeraReviewSelectorDTO
} from './types';
import { UpdateSeraSummaryRequest } from '../../hooks/types/request';

export class SeraResultService {
	async getSeraResults(payload: SeraSummaryRequest): Promise<SeraResultDTO> {
		const url = `/sera/summary/result/${payload.sera_summary_id}`;
		const { data } = await Api.get<SeraResultDTO>(url, {
			params: payload
		});
		return data;
	}

	async getSeraReviews(payload: SeraReviewRequest): Promise<SeraReviewSelectorDTO[]> {
		const url = `/sera/review-selector/summary/${payload.sera_summary_id}`;
		const { data } = await Api.get<SeraReviewSelectorDTO[]>(url, {
			params: payload
		});
		return data;
	}

	public async downloadPDF(payload: PayloadDownloadPDF) {
		const url = `/sera/summary/download-pdf/${payload.sera_summary_id}`;
		const { data } = await Api.post<URLDownloadDTO>(url, payload);
		return data;
	}

	public async saveComments({ organization_id, company_id, id, comment }: UpdateSeraSummaryRequest) {
		const body = {
			organization_id,
			company_id,
			id,
			comment
		};
		const url = `/sera/summary/${id}`;
		const { data } = await Api.put<SeraResultDTO>(url, body);
		return data;
	}
}
