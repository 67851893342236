import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form, Input, Row } from 'antd';

export const FileName: React.FC = () => {
	return (
		<Col sm={4}>
			<Row>
				<Col xs={24}>
					<Form.Item labelCol={{ span: 24 }} name={['original_name']} label={I18n.get('File name')}>
						<Input placeholder={I18n.get('File name')} />
					</Form.Item>
				</Col>
			</Row>
		</Col>
	);
};
