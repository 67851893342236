import styled from 'styled-components';
import { Col } from 'antd';

export const ActionsInline = styled(Col)`
	display: none;
	@media (min-width: 768px) {
		display: flex;
		justify-content: center;
	}
`;

export const ActionsMenu = styled(Col)`
	display: flex;
	@media (min-width: 768px) {
		display: none;
	}
`;

export const CustomButton = styled.div`
	&:hover {
		span {
			color: white;
		}
	}
`;

export const CustomTable = styled(Col)`
	.ant-table-thead {
		user-select: none;
	}

	.ant-table-tbody {
		.ant-table-expanded-row > td:first-child {
			padding: 0 !important;
		}
	}
`;
