import React from 'react';
import { Paragraph, Title } from '@/components/Typography';
import { Col, Row } from 'antd';

import { NextButton } from './components/NextButton';
import { FileTable } from './components/FileTable';
import { Filter } from './components/Filter';
import { SelectFilesProvider } from './context';

export const SelectFile: React.FC = () => (
	<SelectFilesProvider>
		<Row gutter={[0, 30]} justify="center" align="middle">
			<Col offset={1} span={23}>
				<Row>
					<Col span={24}>
						<Title level={3}>Video selection</Title>
					</Col>
					<Col>
						<Paragraph>
							Select the video file below for the task you want to analyze
						</Paragraph>
					</Col>
				</Row>
			</Col>
			<Col offset={1} span={23}>
				<Filter />
			</Col>
			<Col offset={1} span={23}>
				<FileTable />
			</Col>
			<Col xs={24}>
				<NextButton />
			</Col>
		</Row>
	</SelectFilesProvider>
);
