import styled from 'styled-components';

type RPNScoreProps = {
	priority: string;
};

export const Description = styled.span`
	font-size: 0.75rem;
	font-weight: 400;
`;

export const Score = styled.span`
	color: #2f54eb;
	font-weight: 600;
	font-size: 0.8rem;
	margin-left: 0.25rem;
	border-radius: 0.25rem;
	padding: 0rem 0.5rem;
	border: 1px solid #2f54eb;
	background-color: #2f54eb0d;

	@media (min-width: 1600px) {
		font-size: 0.8rem;
	}
`;

export const RPNScore = styled.span<RPNScoreProps>`
	height: 2rem;
	display: flex;
	min-width: 3rem;
	font-weight: 600;
	padding: 0 0.5rem;
	font-size: 0.9rem;
	margin-top: 0.75rem;
	align-items: center;
	border-radius: 0.15rem;
	justify-content: center;
	background-color: ${({ priority }) => priority};

	@media (min-width: 1600px) {
		font-size: 0.9rem;
	}
`;
