import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Row } from 'antd';

import * as S from '../styles';
import { useSelectFilesContext } from '../../context';

export const NextButton: React.FC = () => {
	const { handleValidation } = useSelectFilesContext();

	return (
		<Row justify="center" align="middle">
			<Col>
				<S.CustomButton htmlType="submit" size="large" type="primary" onClick={() => handleValidation()}>
					{I18n.get('Next')}
				</S.CustomButton>
			</Col>
		</Row>
	);
};
