import { AdditionalItemsResults } from '@/hooks';

interface IWorstResultCalculator {
	getWorstResult(results: AdditionalItemsResults[]): number;
}

export class WorstResultCalculator implements IWorstResultCalculator {
	getWorstResult(results: AdditionalItemsResults[]): number {
		if (!results || results.length === 0) {
			return 0;
		}

		let maxValue = 1;
		results.forEach(({ result }) => {
			if (result > maxValue) {
				maxValue = result;
			}
		});

		return maxValue;
	}
}

export const worstRiskCalculator = new WorstResultCalculator();
