import Api from '@/services/api';

import { WorstScoreDTO } from '../hooks/types/response';
import { BaseContext, GetLinesRequest, GetWorkstationsRequest } from '../hooks/types/request';

export class TreemapServices {
	public async getCompanies({ organizationId, companyId }: BaseContext): Promise<WorstScoreDTO[]> {
		const url = `/company/mosaic/${organizationId}?company_id=${companyId}`;
		const { data } = await Api.get<WorstScoreDTO[]>(url);
		return data;
	}

	public async getSectors({ organizationId, companyId }: BaseContext): Promise<WorstScoreDTO[]> {
		const url = `/sector/mosaic/${companyId}?organization_id=${organizationId}`;
		const { data } = await Api.get<WorstScoreDTO[]>(url);
		return data;
	}

	public async getLines({ organizationId, companyId, sector_id }: GetLinesRequest): Promise<WorstScoreDTO[]> {
		const url = `/line/mosaic/${sector_id}?organization_id=${organizationId}&company_id=${companyId}`;
		const { data } = await Api.get<WorstScoreDTO[]>(url);
		return data;
	}

	public async getWorkstations({
		organizationId,
		companyId,
		line_id
	}: GetWorkstationsRequest): Promise<WorstScoreDTO[]> {
		const url = `/workstation/mosaic/${line_id}?organization_id=${organizationId}&company_id=${companyId}`;
		const { data } = await Api.get<WorstScoreDTO[]>(url);
		return data;
	}
}
