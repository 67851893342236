import { useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import Api from '@/services/api';
import type { BaseContext, Response, RiskDamage } from '@/types';

export type GetRiskDamagesDTO = BaseContext & {
	risk_description_id: string;
};

async function getRiskDamages(params: GetRiskDamagesDTO): Promise<RiskDamage[]> {
	const url = `/custom-report/risk-damage/${params.risk_description_id}`;
	const { data } = await Api.get<RiskDamage[]>(url, {
		params
	});
	return data;
}

export const useGetRiskDamages = ({ organization_id, company_id, risk_description_id }: GetRiskDamagesDTO) => {
	const enabled = !!organization_id && !!company_id && !!risk_description_id;
	const riskDamages = useQuery<RiskDamage[], AxiosError<Response>>(
		['risk_damages', [organization_id, company_id, risk_description_id]],
		() => getRiskDamages({ organization_id, company_id, risk_description_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...riskDamages,
		data: riskDamages.data ?? ([] as RiskDamage[])
	};
};
