import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalStyled = styled(Modal)`
	.ant-modal-content {
		padding: 30px 0;
		min-width: 1118px;
		max-width: 1118px;
		border-radius: 15px;
	}
`;

export const ListItem = styled.div`
	display: flex;
	padding-top: 3px;
	border-radius: 7px;
	padding-bottom: 1px;
	align-items: center;
	margin-bottom: 10px;
	padding-top: 3px;
	padding-bottom: 1px;
	background-color: #f8f8f8;
`;

export const Block = styled.div`
	width: 100%;
`;
