import { LibertyMutualReportDTO, LibertyMutualResultDTO } from '../hooks/types/response';
import { CreateReport, UpdateReportBasicInformation } from '../hooks/types/request';

export enum HandCoupling {
	'Good' = 'Good',
	'Fair' = 'Fair',
	'Poor' = 'Poor'
}

export enum HandCouplingMapper {
	'GOOD' = 'Good',
	'FAIR' = 'Fair',
	'POOR' = 'Poor'
}

export enum FrequencyTimeFormatMapper {
	'MINUTE' = 'minute',
	'HOUR' = 'hour'
}

export enum TaskMapper {
	'Lifts' = 'lifts',
	'Lowers' = 'lowers',
	'Pushes' = 'pushes',
	'Pulls' = 'pulls',
	'Carries' = 'carries'
}

export type CreateOrUpdate = CreateReport | UpdateReportBasicInformation;

export type OptionType = { label: string; value: string } | undefined;

export type LibertyMutualRouteParams = {
	file_id: string;
};

export type SystemOfUnits = {
	[key: string]: string;
};

export type FormStep = {
	title: string;
	component: JSX.Element;
};

export type States = {
	file_id: string;
	steps: FormStep[];
	gettingReport: boolean;
	custom_report: boolean;
	decimalSeparator: string;
	errorGettingReport: boolean;
	libertyMutual: LibertyMutualReportDTO;
	libertyMutualResult: LibertyMutualResultDTO;
};

export type Methods = {
	handleUpdateReport(report: LibertyMutualReportDTO): void;
};

export type Context = States & Methods;
