import React from 'react';
import { Row, Col, Divider } from 'antd';
import { I18n } from '@aws-amplify/core';

import Hooks from '../hooks';
import { Title } from './styles';
import { RiskBar } from '../RiskBar';
import { ActionPlans } from './ActionPlans';
import { PlansStatus } from './PlansStatus';
import { Text } from '@/components/Typography';
import { useDashboardContext } from '../context';
import { emptyDataRisks, Risk, RiskBarOptions } from '../RiskBar/types';
import { useApplicationContext } from '@/context/v1/Application/context';
import { DashboardActionPlansStatusDTO, ENUM_ACTION_PLANS_SCALE } from '../types';

const { useDashboardActionPlansStatus } = Hooks;

export const ActionPlanCard: React.FC = () => {
	const { queryParams } = useDashboardContext();
	const { organization, company } = useApplicationContext();
	const { isLoading, data, isError } = useDashboardActionPlansStatus(organization.id, company.id, queryParams);

	const riskBarOptions: RiskBarOptions = {
		caption: {
			align: 'center',
			display: 'right'
		},
		orientation: 'horizontal'
	};

	function risksMapper(actionPlans: DashboardActionPlansStatusDTO): Risk[] {
		const risks: Risk[] = actionPlans.statuses
			.filter(({ amount }) => amount > 0)
			.map(({ amount, status }, index) => {
				return {
					...ENUM_ACTION_PLANS_SCALE[status],
					id: index,
					percentage: calculatePercentage(amount, actionPlans.total_plans),
					status: status.toUpperCase().replaceAll('_', ' '),
					report: 'action_plan'
				};
			});

		return risks.sort((a, b) => a.id - b.id);
	}

	function calculatePercentage(amount: number, total_plans: number): string {
		return (amount / total_plans) * 100 + '%';
	}

	if (isLoading) {
		return <Text>{I18n.get('Loading')}...</Text>;
	}

	if (isError) {
		return <Text>Oops... Something went wrong!</Text>;
	}

	return (
		<Row gutter={[0, 16]} justify="center" align="middle">
			<Col span={24}>
				<Title>{I18n.get('Action plans')}</Title>
			</Col>
			<Col span={16}>
				{data.total_plans > 0 && data.statuses?.length > 0 ? (
					<RiskBar options={riskBarOptions} risks={risksMapper(data)} />
				) : (
					<RiskBar options={riskBarOptions} risks={emptyDataRisks} />
				)}
			</Col>
			<Col span={24}>
				<Row justify="center" gutter={[4, 16]}>
					<PlansStatus statuses={data?.statuses} />
				</Row>
			</Col>
			<Col span={24}>
				{!!data.total_plans ? (
					<ActionPlans queryParams={queryParams} />
				) : (
					<Divider plain>{I18n.get('There are no registered action plans')}</Divider>
				)}
			</Col>
		</Row>
	);
};
