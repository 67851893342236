import React from 'react';
import { Paragraph, Title } from '@/components/Typography';
import { Col, Row, Skeleton } from 'antd';

import { useApplicationContext } from '@/context/v1/Application/context';
import { useSeraStepsContext } from '../context';
import { useSeraReports } from '../hooks/hooks';
import { BackButton } from '../BackButton';
import { SeraReport } from './SeraReport';
import { CustomColumn } from './styles';

interface SeraFormProps {
	onPreviousClick: () => void;
}

export const SeraForm: React.FC<SeraFormProps> = ({ onPreviousClick }) => {
	const { seraSummary } = useSeraStepsContext();
	const { organization, company } = useApplicationContext();

	const { data: seraReports, isFetching: gettingSeraReports } = useSeraReports({
		organization_id: organization?.id,
		company_id: company.id,
		sera_summary_review_id: seraSummary?.sera_summary_review_id
	});

	return (
		<Col xs={24}>
			<BackButton onPreviousClick={onPreviousClick} />
			<Row>
				<CustomColumn xs={24}>
					<Row>
						<Col xs={24}>
							<Title level={4}>Task</Title>
						</Col>
						<Col xs={24}>
							<Paragraph>Review each task below</Paragraph>
						</Col>
					</Row>
					<Row justify="center">
						{gettingSeraReports ? <Skeleton active /> : <SeraReport seraReports={seraReports} />}
					</Row>
				</CustomColumn>
			</Row>
		</Col>
	);
};
