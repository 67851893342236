import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Divider, Row } from 'antd';
import { diagnosticsMap } from '../contants';
import { ParametersChildren } from '../types';
import { DetailDescription, Details, DetailTitle } from './styles';
interface IFirstRowProps {
	parameters: ParametersChildren;
}

export const FirstRow: React.FC<IFirstRowProps> = ({
	parameters: { distance, duration, mass, vehicle, risk_load }
}) => (
	<Row justify="space-between">
		<Col span={6} style={{ paddingLeft: '1rem' }}>
			<Details>
				<Row>
					<Col span={24}>
						<DetailTitle>
							{distance ? I18n.get('Distance') : duration ? I18n.get('Duration') : '-'}:
						</DetailTitle>
					</Col>
				</Row>
				<Row>
					<DetailDescription>
						{distance ? distance : duration ? duration : '-'}
						{distance ? I18n.get('meters') : duration ? I18n.get('minutes') : ''}
					</DetailDescription>
				</Row>
			</Details>
		</Col>
		<Divider style={{ margin: 0, height: 'auto' }} type={'vertical'} />
		<Col span={4}>
			<Details>
				<Row>
					<Col span={24}>
						<DetailTitle>{I18n.get('Mass')}:</DetailTitle>
					</Col>
				</Row>
				<Row>
					<DetailDescription>{mass} kg</DetailDescription>
				</Row>
			</Details>
		</Col>
		<Divider style={{ margin: 0, height: 'auto' }} type={'vertical'} />
		<Col span={4}>
			<Details>
				<Row>
					<Col span={24}>
						<DetailTitle>{I18n.get('Vehicle')}:</DetailTitle>
					</Col>
				</Row>
				<Row>
					<DetailDescription>{I18n.get(vehicle)}</DetailDescription>
				</Row>
			</Details>
		</Col>
		<Divider style={{ margin: 0, height: 'auto' }} type={'vertical'} />
		<Col span={6}>
			<Details>
				<Row>
					<Col span={24}>
						<DetailTitle>{I18n.get('Probability of physical overload')}</DetailTitle>
					</Col>
				</Row>
				<Row>
					<DetailDescription>{I18n.get(diagnosticsMap[risk_load]?.PHYSICAL_OVERLOAD)}</DetailDescription>
				</Row>
			</Details>
		</Col>
	</Row>
);
