import React from 'react';
import { Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CustomFormItem } from '../styles';
import { useStepKeysContext } from '../context';
import { RequiredLabel } from '../../../RequiredLabel';
import { SeveritySelect } from '@/components/ui/Inputs/SeveritySelect';

const { useFormInstance, useWatch } = Form;

interface SeveritiesProps {
	formFieldName: string[];
}

export const Severities: React.FC<SeveritiesProps> = ({ formFieldName }) => {
	const form = useFormInstance();
	const {
		severitiesData: { fetchingSeverities, gettingSeverities, severities }
	} = useStepKeysContext();

	const fieldName = [...formFieldName, 'severity_id'];
	const severityId = useWatch(fieldName, form);
	const riskDamageId = useWatch([...formFieldName, 'risk_damage_id'], form);

	async function handleSeveritySelect(id: string) {
		form.setFieldValue(fieldName, id);
	}

	async function handleClearSeverity() {
		form.setFieldValue(fieldName, undefined);
	}

	return (
		<Col xs={24} sm={12} lg={24}>
			<CustomFormItem
				name={fieldName}
				label={<RequiredLabel title="Severity" isRequired={true} />}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: true,
						message: I18n.get('Select the severity')
					}
				]}
			>
				<SeveritySelect
					fieldName={fieldName}
					severityId={severityId}
					severities={severities}
					loading={fetchingSeverities}
					handleClearSeverity={handleClearSeverity}
					handleSelectSeverity={handleSeveritySelect}
					disabled={gettingSeverities || fetchingSeverities || !riskDamageId}
				/>
			</CustomFormItem>
		</Col>
	);
};
