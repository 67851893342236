import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Skeleton, Steps } from 'antd';

import { CustomSteps, CurrentStep, StepsMenuCol } from './styles';
import { useBeraReportStepsContext } from '../context';
import { StepsProps } from './index';

const Step = Steps.Step;

interface StepsMenuProps {
	currentStep: number;
	stepMenu: StepsProps[];
	onStepMenuClick: (step: number) => void;
}

export const StepsMenu: React.FC<StepsMenuProps> = ({ currentStep, stepMenu, onStepMenuClick }) => {
	const { clickableResultStep, isLoading } = useBeraReportStepsContext();

	return currentStep < stepMenu.length - 1 ? (
		<StepsMenuCol sm={4}>
			{isLoading ? (
				<Skeleton active />
			) : (
				<CustomSteps
					current={currentStep}
					result={String(clickableResultStep)}
					active={currentStep}
					direction="vertical"
				>
					{stepMenu.map((step) => (
						<Step
							key={step.title}
							onClick={() => {
								onStepMenuClick(step.key);
							}}
							icon={step.key === currentStep ? <CurrentStep>{I18n.get(step.title)}</CurrentStep> : ''}
						/>
					))}
				</CustomSteps>
			)}
		</StepsMenuCol>
	) : (
		<></>
	);
};
