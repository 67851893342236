import React from 'react';
import { I18n } from '@aws-amplify/core';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Form, Image, InputNumber, Row } from 'antd';

import { SystemOfUnits } from '../../context/types';
import { TaskName } from '../../hooks/types/response';
import { CustomLabel, CustomTooltip } from './CustomLabel';
import { useLibertyMutualSubStepsContext } from '../../context';

const LIFT_END_HAND_HEIGHT = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/lift-end-hand-height.svg';
const LOWER_END_HAND_HEIGHT = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/lower-end-hand-height.svg';

const { useWatch, useFormInstance } = Form;

export const EndHandHeight = () => {
	const form = useFormInstance();
	const { libertyMutual, decimalSeparator } = useLibertyMutualSubStepsContext();

	const startHeight = useWatch(['liberty_mutual_inputs', 'start_hand_height'], form);

	const systemOfUnitsToSymbolMapper: SystemOfUnits = {
		metric: 'cm',
		imperial: 'in'
	};

	const labelTitle = `${I18n.get('End hand height')} (${
		systemOfUnitsToSymbolMapper[libertyMutual?.system_of_units?.name]
	})`;

	const tooltipSVGMapper = {
		[TaskName.lift]: LIFT_END_HAND_HEIGHT,
		[TaskName.lower]: LOWER_END_HAND_HEIGHT,
		[TaskName.push]: '',
		[TaskName.pull]: '',
		[TaskName.carry]: ''
	};

	const TooltipContent = (
		<Row align="bottom">
			<Col xs={7}>
				<Image src={tooltipSVGMapper[libertyMutual.task.name]} alt={labelTitle} preview={false} width="80px" />
			</Col>
		</Row>
	);

	const label = (
		<CustomLabel
			icon={
				<CustomTooltip top={-100} left={8} content={TooltipContent} placement="topLeft">
					<InfoCircleOutlined style={{ fontSize: '0.65rem', marginLeft: '0.1rem' }} />
				</CustomTooltip>
			}
			title={labelTitle}
		/>
	);

	function isLift() {
		return libertyMutual.task.name === TaskName.lift;
	}

	function isLower() {
		return libertyMutual.task.name === TaskName.lower;
	}

	function isEndLowerThanStart(value: any) {
		return startHeight > value;
	}

	function isEndHigherThanStart(value: any) {
		return startHeight < value;
	}

	return (
		<Row>
			<Col xs={24}>
				<Form.Item
					name={['liberty_mutual_inputs', 'end_hand_height']}
					label={label}
					labelCol={{ span: 24 }}
					rules={[
						{
							required: true,
							message: ''
						},
						() => ({
							validator(_, value) {
								if (value < 1) {
									return Promise.reject(I18n.get('Insert an end height'));
								}
								if (isLift()) {
									if (isEndLowerThanStart(value)) {
										return Promise.reject(Error(I18n.get('Cannot be lower than start height')));
									}
								} else if (isLower()) {
									if (isEndHigherThanStart(value)) {
										return Promise.reject(Error(I18n.get('Cannot be higher than start height')));
									}
								}

								return Promise.resolve();
							}
						})
					]}
				>
					<InputNumber min={1} placeholder="0" decimalSeparator={decimalSeparator} />
				</Form.Item>
			</Col>
		</Row>
	);
};
