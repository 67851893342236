import styled from 'styled-components';
import { Row, Col } from 'antd';

export const ColCustom = styled(Col)`
	border-left: #d9d9d9 1px solid;
	border-bottom: #d9d9d9 1px solid;

	&:first-child {
		border-radius: 10px 0 0 0;
	}

	&:nth-child(7) {
		border-radius: 0 0 0 10px;
	}

	&:last-child {
		border-radius: 0 0 10px 0;
	}
`;

export const RowCustom = styled(Row)`
	border-radius: 10px;
	border-top: #d9d9d9 1px solid;
	border-right: #d9d9d9 1px solid;
`;
