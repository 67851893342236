import React from 'react';
import { Col, Form, Radio, Space } from 'antd';
import { I18n } from '@aws-amplify/core';

import { NamePath } from 'antd/lib/form/interface';

interface HasKnownInjuryProps {
	fieldName: NamePath;
}

export const HasKnownInjury: React.FC<HasKnownInjuryProps> = ({ fieldName }) => {
	return (
		<Col xs={24} lg={16} xl={20} xxl={24}>
			<Form.Item
				name={fieldName}
				label={I18n.get('Has known injury?')}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: true,
						message: I18n.get('Select an option')
					}
				]}
			>
				<Radio.Group>
					<Space direction="horizontal">
						<Radio value={true}>{I18n.get('Yes')}</Radio>
						<Radio value={false}>{I18n.get('No')}</Radio>
					</Space>
				</Radio.Group>
			</Form.Item>
		</Col>
	);
};
