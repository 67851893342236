import React, { useState } from 'react';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Divider, message, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { I18n } from '@aws-amplify/core';

import hooks from '../hooks';
import Can from '@/components/Can';
import { PEAListModal } from './PEAListModal';
import { useApplicationContext } from '@/context/v1/Application/context';

const { useDownloadPDF } = hooks;

type DataType = {
	key: React.Key;
	id: string;
	dateOfCollection: string;
	reportName: string;
	worstScore: number;
	peaIds: string[];
	createdAt: string;
};

type TableListProps = {
	isLoading: boolean;
	data: DataType[];
	onDelete: (superPEAId: React.Key) => void;
};

export function TableList({ isLoading, data, onDelete }: TableListProps) {
	const { organization, company } = useApplicationContext();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [rowSelected, setRowSelected] = useState('');

	const pdf = useDownloadPDF();

	function handleIsModalOpen() {
		setIsModalOpen(false);
	}

	function openModal(rowId: string) {
		setIsModalOpen(true);
		setRowSelected(rowId);
	}

	function preliminaryAnalyzesList(peaIds: string[], rowData: DataType): JSX.Element {
		return peaIds.length === 0 || !peaIds[0] ? (
			<span>-</span>
		) : (
			<>
				<Button type="link" onClick={() => openModal(rowData.id)}>
					{I18n.get('Open File List')}
				</Button>
			</>
		);
	}

	function onDeleteFile(superPEAId: React.Key) {
		onDelete(superPEAId);
	}

	function onDownloadFile(payload: DataType) {
		const { id } = payload;
		const body: any = {
			super_pea_id: id,
			organization_id: organization?.id,
			company_id: company?.id,
		};

		pdf.mutate(body, {
			onSuccess: (data) => {
				let archive = document.createElement('a');
				archive.target = '_blank';
				archive.href = data.url;
				archive.click();
				archive.remove();
			},
			onError: () => {
				message.error(I18n.get('Failed to generate the report'));
			}
		});
	}

	const columns: ColumnsType<any> = [
		{
			title: I18n.get('ID'),
			dataIndex: 'key',
			key: 'key',
			align: 'center',
			render: (text: string) => text.split('-')[0]
		},
		{
			title: I18n.get('Report'),
			dataIndex: 'reportName',
			key: 'reportName',
			align: 'center'
		},
		{
			title: I18n.get('Selected Files'),
			dataIndex: 'peaIds',
			key: 'peaIds',
			align: 'center',
			render: (peaIds: string[], rowData: DataType) => preliminaryAnalyzesList(peaIds, rowData)
		},
		{
			title: I18n.get('Worst Score'),
			dataIndex: 'worstScore',
			key: 'worstScore',
			align: 'center'
		},
		{
			title: I18n.get('Date of collection'),
			dataIndex: 'dateOfCollection',
			key: 'dateOfCollection',
			align: 'center'
		},
		{
			title: I18n.get('Date of creation'),
			dataIndex: 'createdAt',
			key: 'createdAt',
			align: 'center'
		},
		{
			title: I18n.get('Actions'),
			dataIndex: 'url',
			key: 'url',
			align: 'center',
			render: (_, rowData) => (
				<>
					<Tooltip title={I18n.get('Download')}>
						<Button type="primary" icon={<DownloadOutlined />} onClick={() => onDownloadFile(rowData)} />
					</Tooltip>
					{Can('delete', 'upload') && (
						<>
							<Divider type="vertical" />
							<Tooltip title={I18n.get('Delete')}>
								<Button danger icon={<DeleteOutlined />} onClick={() => onDeleteFile(rowData.id)} />
							</Tooltip>
						</>
					)}
				</>
			)
		}
	];

	return (
		<>
			<Table rowKey="id" dataSource={data} columns={columns} loading={isLoading} />
			{isModalOpen && (
				<PEAListModal isModalOpen={isModalOpen} onIsModalOpen={handleIsModalOpen} rowSelected={rowSelected} />
			)}
		</>
	);
}
