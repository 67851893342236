import React from 'react';
import Table, { ColumnsType } from 'antd/lib/table';
import { Title } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';
import { Col, Row } from 'antd';

import { SeraResultAnalyzedTasks } from '../hooks/types';
import { CustomTable } from './styles';
import { Actions } from './Actions';

interface TasksAnalyzedProps {
	analyzedTasks: SeraResultAnalyzedTasks[];
}

export const TasksAnalyzed: React.FC<TasksAnalyzedProps> = ({ analyzedTasks }) => {
	const columns: ColumnsType<SeraResultAnalyzedTasks> = [
		{
			title: I18n.get('TLID'),
			dataIndex: 'task_name',
			key: 1,
			align: 'center'
		},
		{
			title: I18n.get('File'),
			dataIndex: 'file_name',
			key: 2,
			ellipsis: true,
			width: '20%',
			align: 'center'
		},
		{
			title: I18n.get('Highest RPN'),
			dataIndex: 'highest_rpn',
			key: 3,
			align: 'center'
		},
		{
			title: I18n.get('Sum of RPN'),
			dataIndex: 'sum_rpn',
			key: 4,
			align: 'center'
		},
		{
			title: I18n.get('Original version sum of RPN'),
			dataIndex: 'original_version_sum_rpn',
			key: 5,
			align: 'center'
		},
		{
			title: I18n.get('Actions'),
			key: 6,
			align: 'center',
			render: (_file, task) => <Actions file_id={task.file_id} file_name={task.file_name} />
		}
	];

	return (
		<Col span={24}>
			<Row>
				<Title level={5}>Analyzed tasks</Title>
			</Row>
			<Row justify="center">
				<CustomTable span={20}>
					<Table rowKey="id" pagination={false} dataSource={analyzedTasks} columns={columns} />
				</CustomTable>
			</Row>
		</Col>
	);
};
