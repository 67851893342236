import React from 'react';
import * as Icons from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import { Button, Col, Row } from 'antd';

import { PayloadDownloadPDF } from '../types';
import { useDownloadPDF } from '../hooks';

interface DownloadPDFProps {
	parameters: PayloadDownloadPDF;
	disableDownload: boolean;
}

export const DownloadPDF: React.FC<DownloadPDFProps> = ({ parameters, disableDownload }) => {
	const { mutateAsync: downloadPDF, isLoading: downloadingPDF } = useDownloadPDF();

	function onDownload(payload: PayloadDownloadPDF): void {
		downloadPDF(payload);
	}

	return (
		<Row justify="end">
			<Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Button
					style={{
						color: '#2F54EB',
						borderRadius: '0.25rem',
						border: '1px solid #2F54EB'
					}}
					loading={downloadingPDF}
					disabled={downloadingPDF || disableDownload}
					onClick={() => onDownload(parameters)}
					icon={<Icons.DownloadOutlined style={{ color: '#2F54EB' }} />}
				>
					{I18n.get('Download PDF')}
				</Button>
			</Col>
		</Row>
	);
};
