import React, { ReactNode, createContext, useContext, useState } from 'react';
import { Form } from 'antd';

import { FilterDTO } from '@/hooks/useGetFiles';
import type { Context, Methods, States } from './types';
import { useJdsD92Context } from '@/components/views/JdsD92/context';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useGetCustomReportResultFiles } from '@/hooks/useGetCustomReportResultFiles';

const { useFormInstance } = Form;

const SelectFilesContext = createContext<Context>({} as Context);

export const LIMIT = 8;

export type SelectedRows = {
	[key: string]: {
		operation_id: string;
	};
};

export type HandleSelectedRows = {
	newSelectedRows: SelectedRows;
	hasUndefinedOperation: boolean;
};

export type GetFieldsValue = SelectedRows & {
	[key: string]: Array<string> | Object | string;
} & { filter: FilterDTO };

interface SelectFilesProviderProps {
	children: ReactNode;
}

export function SelectFilesProvider({ children }: Readonly<SelectFilesProviderProps>) {
	const { getFieldValue, setFieldValue } = useFormInstance();
	const { handleNextStep, jdsD92, customReport } = useJdsD92Context();
	const { organization, company } = useApplicationContext();

	const initialSelectedRowKeys: React.Key[] = getFieldValue(['selectedRowKeys']);

	const [page, setPage] = useState<number>(1);
	const [filter, setFilter] = useState<FilterDTO>({} as FilterDTO);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(
		initialSelectedRowKeys ?? [jdsD92?.file_id] ?? ([] as React.Key[])
	);

	const { data: allFiles, isLoading: loadingAllFiles } = useGetCustomReportResultFiles({
		limit: LIMIT,
		offset: page - 1,
		organization_id: filter.organization_id ?? organization?.id ?? '',
		company_id: filter.company_id ?? company?.id ?? '',
		custom_report_id: customReport.id,
		filter
	});

	function handleFilter(value: FilterDTO): void {
		setFilter(value);
	}

	function handlePage(value: number): void {
		setPage(value);
	}

	function handlingSelectRowKeys(value: React.Key[]) {
		setFieldValue(['selectedRowKeys'], value);
		setSelectedRowKeys(value);
	}

	async function handleValidation() {
		const fileSelected = allFiles?.rows?.find((f) => f.id === selectedRowKeys[0]);
		if (!fileSelected) {
			return;
		}
		setFieldValue(['file', 'id'], fileSelected.id);
		setFieldValue(['file', 'original_name'], fileSelected.original_name);
		setFieldValue(['file', 'created_at'], fileSelected.createdAt);

		try {
			handleNextStep();
		} catch (error) {
			//
		}
	}
	const states: States = { allFiles, loadingAllFiles, page, selectedRowKeys };
	const methods: Methods = { handlePage, handleFilter, handleValidation, handlingSelectRowKeys };

	const contextValue = {
		...states,
		...methods
	};

	return <SelectFilesContext.Provider value={contextValue}>{children}</SelectFilesContext.Provider>;
}

export function useSelectFilesContext() {
	const context = useContext(SelectFilesContext);
	return context;
}
