import { useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import Api from '@/services/api';
import type { BaseContext, ActionPlan, ActionPlanTask, Response } from '@/types';

export type CustomReportActionPlanResponse = ActionPlan & {
	action_plan_task?: ActionPlanTask[];
};

export type GetCustomReportActionPlanDTO = BaseContext & {
	file_id: string;
	custom_report_step_key_id?: string;
	custom_report_sub_step_key_id?: string;
};

async function getCustomReportActionPlan(
	params: GetCustomReportActionPlanDTO
): Promise<CustomReportActionPlanResponse> {
	const url = '/action_plan/custom-report';
	const { data } = await Api.get<CustomReportActionPlanResponse>(url, {
		params
	});
	return data;
}

export const useGetCustomReportActionPlan = ({
	file_id,
	company_id,
	organization_id,
	custom_report_step_key_id,
	custom_report_sub_step_key_id
}: GetCustomReportActionPlanDTO) => {
	const params = { custom_report_step_key_id, custom_report_sub_step_key_id, file_id, company_id, organization_id };
	const actionPlan = useQuery<CustomReportActionPlanResponse, AxiosError<Response>>(
		['custom_report_action_plan', params],
		() => getCustomReportActionPlan(params),
		{
			retry: 0,
			enabled:
				!!file_id &&
				!!company_id &&
				!!organization_id &&
				!!(custom_report_step_key_id ?? custom_report_sub_step_key_id)
		}
	);

	return {
		...actionPlan,
		data: actionPlan.data ?? ({} as CustomReportActionPlanResponse)
	};
};
