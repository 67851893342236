import React from 'react';
import { NamePath } from 'antd/lib/form/interface';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { ExposureDTO } from '@/components/ui/Inputs/types/response/exposure';
import { ExposureSelect } from '@/components/ui/Inputs/ExposureSelect';
import { CustomLabel } from '@/components/CustomLabel';
import { CustomFormItem } from './styles';
import { CustomIcon } from './CustomIcon';

const { useFormInstance, useWatch } = Form;

interface ExposuresProps {
	isLoading: boolean;
	isFetching: boolean;
	fieldName: NamePath;
	exposures: ExposureDTO[];
}

export const Exposures: React.FC<ExposuresProps> = ({ isFetching, isLoading, fieldName, exposures }) => {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();

	const exposureId = useWatch(fieldName, form);

	async function handleExposureSelect(id: string) {
		setFieldValue(fieldName, id);
	}

	async function handleClearExposure() {
		setFieldValue(fieldName, undefined);
	}

	return (
		<Col xs={24} sm={12} lg={24}>
			<CustomFormItem
				name={fieldName}
				label={
					<CustomLabel
						icon={<CustomIcon title="How many times is the operator exposed to risk in the work cycle?" />}
						title="Exposure"
					/>
				}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: true,
						message: I18n.get('Select the exposure')
					}
				]}
			>
				<ExposureSelect
					fieldName={fieldName}
					allowCreation={false}
					exposureId={exposureId}
					exposures={exposures}
					loading={isFetching}
					handleClearExposure={handleClearExposure}
					handleSelectExposure={handleExposureSelect}
					disabled={isLoading || isFetching}
				/>
			</CustomFormItem>
		</Col>
	);
};
