import React from 'react';
import { Col, Input } from 'antd';
import { I18n } from '@aws-amplify/core';
import { NamePath } from 'antd/lib/form/interface';
import { CustomFormItem } from './styles';

interface TextAreaProps {
	title: string;
	namePath: NamePath;
	fontSize?: string;
	isRequired?: boolean;
}

export const TextArea: React.FC<TextAreaProps> = ({ title, namePath, fontSize, isRequired = false }) => {
	return (
		<Col xs={24}>
			<CustomFormItem
				fontSize={fontSize}
				name={namePath}
				label={I18n.get(title)}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: isRequired,
						message: I18n.get(`Select the ${title}`)
					}
				]}
			>
				<Input.TextArea
					rows={6}
					allowClear
					showCount
					maxLength={500}
					size="large"
					placeholder={I18n.get(title)}
				/>
			</CustomFormItem>
		</Col>
	);
};
