import moment from 'moment';

import { IMask } from './types';

export class Mask implements IMask {
	/**
	 * Response: 00KB; 00MB, ...
	 */
	public formatFileSize(size: number): string {
		if (size < 1024) return size + ' B';

		const magnitude_index = Math.floor(Math.log(size) / Math.log(1024));
		const bytes = size / Math.pow(1024, magnitude_index);

		const round = Math.round(bytes);

		let formattedBytes = String(round);

		if (round < 10) {
			formattedBytes = bytes.toFixed(2);
		}

		if (round < 100) {
			formattedBytes = bytes.toFixed(1);
		}

		const magnitude = 'KMGTPEZY';

		return `${formattedBytes} ${magnitude[magnitude_index - 1]}B`;
	}

	public convertSecondsToMinutes(seconds: number): string | undefined {
		if (!seconds) return undefined;

		return `${moment.unix(seconds).utc().format('mm:ss')}`;
	}
}

export const { convertSecondsToMinutes, formatFileSize } = new Mask();
