import React, { useState } from 'react';
import { Row, Button, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { ModalForm } from './Modal';
import { Title } from '@/components/Typography';

export const ChangePassword: React.FC = () => {
	const [visible, setVisible] = useState<boolean>(false);

	function handleChangePassword(): void {
		setVisible(!visible);
	}

	return (
		<Row gutter={[0, 10]} style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
			<Col span={24}>
				<Title level={5}>Change password</Title>
			</Col>
			<Col>
				<Button type="primary" ghost onClick={handleChangePassword} disabled={visible}>
					{I18n.get('Change')}
				</Button>
			</Col>
			<ModalForm visible={visible} onCloseModal={handleChangePassword} />
		</Row>
	);
};
