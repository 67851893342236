import { useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import Api from '@/services/api';
import type { BaseContext, Response, RiskDescription } from '@/types';

export type GetRiskDescriptionsDTO = BaseContext & {
	risk_category_id: string;
};

async function getRiskDescriptions(params: GetRiskDescriptionsDTO): Promise<RiskDescription[]> {
	const url = `/custom-report/risk-description/${params.risk_category_id}`;
	const { data } = await Api.get<RiskDescription[]>(url, {
		params
	});
	return data;
}

export const useGetRiskDescriptions = ({ organization_id, company_id, risk_category_id }: GetRiskDescriptionsDTO) => {
	const enabled = !!organization_id && !!company_id && !!risk_category_id;
	const riskDescriptions = useQuery<RiskDescription[], AxiosError<Response>>(
		['risk_descriptions', [organization_id, company_id, risk_category_id]],
		() => getRiskDescriptions({ organization_id, company_id, risk_category_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...riskDescriptions,
		data: riskDescriptions.data ?? ([] as RiskDescription[])
	};
};
