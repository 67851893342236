import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';
import { FileRequest } from '@/components/ui/Inputs/types/request/file';
import { FileDTO } from '@/components/ui/Inputs/types/response/file';

async function getFile({ organizationId, companyId, fileId }: FileRequest): Promise<FileDTO> {
	const url = `/upload/${fileId}?organization_id=${organizationId}&company_id=${companyId}`;
	const { data } = await Api.get<FileDTO>(url);
	return data;
}

export function useFile({ organizationId, companyId, fileId }: FileRequest) {
	const enabled = !!organizationId && !!companyId && !!fileId;
	const file = useQuery(
		['file', [organizationId, companyId, fileId]],
		() => getFile({ organizationId, companyId, fileId }),
		{ enabled }
	);
	return { file };
}
