import { Tooltip } from 'antd';
import styled from 'styled-components';

import { ENUM_RISK_BAR_BORDERS, ENUM_RISK_BAR_CAPTION, RiskBarStyles } from '../types';

export const Container = styled.div<RiskBarStyles>`
	display: flex;
	flex-direction: ${(props) => (props.orientation === 'horizontal' ? 'row' : 'column')};
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
`;

export const RiskBarContainer = styled.div<RiskBarStyles>`
	display: flex;
	flex-direction: ${(props) => (props.orientation === 'horizontal' ? 'column' : 'row-reverse')};

	width: ${(props) => (props.orientation === 'horizontal' ? props.percentage : '100%')};
	height: ${(props) => (props.orientation === 'vertical' ? props.percentage : '100%')};

	gap: ${(props) => (props.orientation === 'horizontal' ? '0.66rem' : props.valueDisplay ? '1rem' : '3rem')};
`;

export const RiskBarCaption = styled.div<RiskBarStyles>`
	display: flex;
	flex-direction: ${(props) => {
		const { orientation, captionDisplay, valueDisplay } = props;
		if (orientation && captionDisplay) {
			return setCaptionDisplay(orientation, captionDisplay, valueDisplay);
		}
	}};
	width: 100%;
	max-width: ${(props) => props.valueDisplay && 'fit-content'};
	height: 100%;

	text-align: ${(props) => (props.valueDisplay ? 'left' : 'center')};
	justify-content: ${(props) => (props.valueDisplay ? 'flex-start' : 'space-between')};
`;

export const RiskBarContent = styled.div<RiskBarStyles>`
	display: flex;
	flex-direction: ${(props) => (props.orientation === 'horizontal' ? 'row' : 'column')};
	width: ${(props) => (props.orientation === 'horizontal' ? '100%' : props.valueDisplay ? '2rem' : '1rem')};
	height: ${(props) => (props.orientation === 'horizontal' ? '1rem' : '100%')};
	justify-content: ${(props) => props.align};
	text-align: ${(props) => props.align};
`;

export const Caption = styled(Tooltip)`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const Value = styled.span`
	font-weight: 600;
`;

export const RiskScale = styled.div<RiskBarStyles>`
	width: 100%;
	height: 100%;
	max-width: ${(props) => (props.orientation === 'vertical' ? '1rem' : '100%')};
	max-height: ${(props) => (props.orientation === 'horizontal' ? '1rem' : '100%')};

	background: ${(props) => props.color};
	color: ${(props) => props.color};

	transition: filter 0.2s;
	&:hover {
		cursor: pointer;
		filter: brightness(0.8);
	}

	border-radius: ${(props) => {
		const { riskId, length, orientation } = props;
		if (riskId !== undefined && length && orientation) {
			return setBorders(riskId, length, orientation);
		} else {
			return '0';
		}
	}};
`;

function setBorders(riskId: number, length: number, orientation: 'horizontal' | 'vertical'): string {
	if (riskId === 0 && riskId === length - 1) {
		return '1rem 1rem';
	} else if (riskId === length - 1) {
		return ENUM_RISK_BAR_BORDERS['last'][orientation];
	} else if (riskId === 0) {
		return ENUM_RISK_BAR_BORDERS['first'][orientation];
	} else {
		return '0';
	}
}

function setCaptionDisplay(
	orientation: 'horizontal' | 'vertical',
	captionDisplay: 'top' | 'bottom' | 'left' | 'right',
	valueDisplay: 'top' | 'bottom' | 'left' | 'right' = 'right'
): string {
	return valueDisplay !== 'right'
		? ENUM_RISK_BAR_CAPTION[orientation][valueDisplay]
		: ENUM_RISK_BAR_CAPTION[orientation][captionDisplay];
}
