import React from 'react';
import { Row, Col, Skeleton } from 'antd';
import { CustomImageSkeleton } from './styles';

export const LoadingSkeleton = () => {
	return (
		<>
			<Row justify="center" gutter={[5, 20]}>
				<Col xs={21}>
					<Row align="middle">
						<Col xs={12}>
							<Skeleton.Input active block />
						</Col>
						<Col xs={12} style={{ textAlign: 'end' }}>
							<Skeleton.Input active block />
						</Col>
						<Col xs={24} style={{ marginBottom: '20px' }}>
							<Skeleton active paragraph />
						</Col>
					</Row>
					<Row justify="center">
						<CustomImageSkeleton active />
					</Row>
					<Row justify="center">
						<Col xs={24} lg={18} xxl={12} style={{ marginTop: '1rem' }}>
							<Row gutter={[0, { xs: 50, sm: 40, md: 40, lg: 30, xl: 26 }]}>
								<Col>
									<Skeleton.Input active block />
								</Col>
								<Col>
									<Skeleton.Input active block />
								</Col>
								<Col>
									<Skeleton.Input active block />
								</Col>
								<Col>
									<Skeleton.Input active block />
								</Col>
								<Col>
									<Skeleton.Input active block />
								</Col>
							</Row>
						</Col>
					</Row>
					<Row justify="center">
						<Col xs={24} lg={18} xxl={12} style={{ marginTop: '4rem' }}>
							<Row gutter={[0, { xs: 40, sm: 40, md: 40, lg: 30, xl: 26 }]}>
								<Col>
									<Skeleton.Input active block />
								</Col>
								<Col>
									<Skeleton.Input active block />
								</Col>
								<Col>
									<Skeleton.Input active block />
								</Col>
								<Col>
									<Skeleton.Input active block />
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row>
				<Col xs={24} style={{ marginTop: '2rem' }}>
					<Row justify="center">
						<Col>
							<Skeleton.Button active block />
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};
