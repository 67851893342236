import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Table, Tooltip } from 'antd';
import moment from 'moment';

import { calculateResultForScore, riskColors, riskLevels } from '@/utils/riskRange';
import ActionsForFile from './ActionsForFile';
import { formatFileSize } from '@/utils/mask';
import { enumStatusFile } from '@/utils/enum';
import { Text } from '../Typography';
import { TagRisk } from './styles';

export function List({
	data,
	isLoading,
	onDelete,
	onDownload,
	onSelect,
	onSeletEwa,
	onMaxReportsChange,
	maxReports,
	onPageChange,
	page
}) {
	const columns = [
		{
			title: I18n.get('Description'),
			dataIndex: 'original_name',
			key: 'original_name',
			width: '20%',
			render: (text) => (
				<Tooltip title={text}>
					<Text style={{ width: '150px' }} ellipsis={false}>
						{text}
					</Text>
				</Tooltip>
			)
		},
		{
			title: I18n.get('Size'),
			dataIndex: 'size',
			align: 'center',
			key: 'size',
			responsive: ['lg'],
			render: (size) => (size ? formatFileSize(size) : '-')
		},
		{
			title: I18n.get('Upload date'),
			dataIndex: 'createdAt',
			key: 'createdAt',
			align: 'center',
			responsive: ['lg'],
			render: (text) => moment(text).format('L - h:mm:ss A')
		},
		{
			title: I18n.get('Status'),
			dataIndex: 'status',
			key: 'status',
			align: 'center',
			responsive: ['md'],
			render: (text) => `${I18n.get(enumStatusFile[text] || '-')}`
		},
		{
			title: I18n.get('Sector'),
			dataIndex: 'sector',
			key: 'sector',
			responsive: ['lg'],
			align: 'center',
			render: (sector) => sector?.name ?? '-'
		},
		{
			title: I18n.get('Workstation'),
			dataIndex: 'workstations',
			key: 'workstations',
			align: 'center',
			render: (workstations) => workstations?.name ?? '-'
		},
		{
			title: I18n.get('Rating'),
			dataIndex: 'preliminary_analysis',
			key: 'preliminary_analysis',
			align: 'center',
			render: (num) =>
				num?.consolidated ? (
					<TagRisk
						brightcolors={calculateResultForScore(num.worst_score)}
						color={riskColors[calculateResultForScore(num.worst_score)]}
					>
						<Tooltip title={I18n.get(`${riskLevels[calculateResultForScore(num.worst_score)]}`)}>
							{calculateResultForScore(num.worst_score) === 5 ? (
								<Text ellipsis={true}>{I18n.get('Serious risk')}</Text>
							) : (
								<Text ellipsis={true}>
									{I18n.get(riskLevels[calculateResultForScore(num.worst_score)])}
								</Text>
							)}
						</Tooltip>
					</TagRisk>
				) : (
					I18n.get('Not assigned')
				)
		},

		{
			title: I18n.get('Actions'),
			dataIndex: 'url',
			key: 'url',
			align: 'center',
			render: (_, file) => (
				<ActionsForFile
					file={file}
					onDownload={onDownload}
					onDelete={onDelete}
					onShowOptionsReports={onSelect}
					onShowOptionsEwa={onSeletEwa}
				/>
			)
		}
	];

	function handlePaginationChange(page) {
		onPageChange(page);
	}

	function handleShowSizeChange(size) {
		onMaxReportsChange(size);
	}

	return (
		<Table
			rowKey="id"
			columns={columns}
			loading={isLoading}
			dataSource={data?.rows}
			pagination={{
				hideOnSinglePage: false,
				showSizeChanger: true,
				total: data?.count,
				current: page === 0 ? page + 1 : page,
				defaultPageSize: maxReports,
				pageSizeOptions: ['5', '10', '20', '30', '50', '100', '200'],
				pageSize: maxReports,
				onChange: (page) => {
					handlePaginationChange(page);
				},
				onShowSizeChange: (_, size) => handleShowSizeChange(size)
			}}
		/>
	);
}
