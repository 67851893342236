import styled from 'styled-components';

export const ResultsContainer = styled.div`
	padding: 2rem;
	margin-right: 2rem;
	background-color: white;
	border-radius: 0.5rem;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
	height: 100%;
`;

export const KinebotLogoContainer = styled.div``;

export const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 1.2rem;
	font-weight: 400;

	#title {
		font-weight: 600;
	}
`;

export const ClientLogoContainer = styled.div`
	background: #d9d9d9;
	width: 100%;
	height: 100%;
	border-radius: 0.5rem;
	padding: 0.75rem 0.5rem;
	text-align: center;

	span {
		color: #8c8c8c;
		font-size: 1rem;
		font-weight: 600;
	}
`;
