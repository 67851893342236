import React from 'react';
import { Input } from 'antd';
import { I18n } from '@aws-amplify/core';
import { FormItem } from '@/components/CustomReports';

interface RoleNameProps {
	fontSize?: string;
	fieldName: string;
	disabled?: boolean;
	isRequired?: boolean;
}

export function RoleName({ fieldName, disabled, fontSize, isRequired = false }: Readonly<RoleNameProps>) {
	return (
		<FormItem namePath={[fieldName, 'role_name']} title="Role" fontSize={fontSize} isRequired={isRequired}>
			<Input size="large" placeholder={I18n.get('Role')} disabled={disabled} />
		</FormItem>
	);
}
