import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col } from 'antd';

import { useLibertyMutualSubStepsContext } from '../../../context';
import { Topic, Value } from '../styles';

export const WorkstationName = () => {
	const {
		libertyMutualResult: {
			informations: { workstation_name }
		}
	} = useLibertyMutualSubStepsContext();

	return (
		<Col xs={24}>
			<Topic>{I18n.get('Workstation')}:</Topic>
			<Value>{workstation_name}</Value>
		</Col>
	);
};
