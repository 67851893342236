import React from 'react';
import { Col, Form, Row, Select } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useApplicationContext } from '@/context/v1/Application/context';

import Hooks from '../../hooks';

const { useGetLines } = Hooks;

interface FilterByLineProps {
	onLineChange: (line: string) => void;
	sectorId: string;
}

export const FilterByLine: React.FC<FilterByLineProps> = ({ onLineChange, sectorId }) => {
	const { organization, company } = useApplicationContext();
	const { isFetching, data, isError } = useGetLines(organization.id, company.id, sectorId);

	return (
		<Row gutter={[16, 8]}>
			<Col span={24}>
				<span>{I18n.get('Line')}</span>
			</Col>
			<Col span={24}>
				<Form.Item name="line">
					<Select
						showSearch
						allowClear
						loading={isFetching}
						onChange={onLineChange}
						placeholder={I18n.get('Line')}
						disabled={!sectorId || data.length === 0 || isError}
						options={data.length > 0 ? data?.map(({ id, name }) => ({ label: name, value: id })) : []}
						filterOption={(input, option) =>
							(option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
						}
					/>
				</Form.Item>
			</Col>
		</Row>
	);
};
