import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import { CreatedAtDatePicker, FilterOptions, StatusEnum, StatusOptions, InitialValues } from './types';
import { FormFilter } from './Form';

interface FilterProps {
	onFilter: (filter: FilterOptions | null) => void;
}

export const Filter: React.FC<FilterProps> = ({ onFilter }) => {
	const [form] = useForm();

	function handleSubmit() {
		const { name, created_at, status } = form.getFieldValue(['filter']);
		const date = handleDateFilter(created_at);
		const filter = {
			is_active: handleStatusFilter(status),
			created_start_date: date?.created_start_date,
			created_end_date: date?.created_end_date,
			name: name
		};
		onFilter(filter);
	}

	function handleStatusFilter(status: StatusOptions): 0 | 1 | 'all' {
		if (status === StatusEnum.active) {
			return 1;
		}
		if (status === StatusEnum.inactive) {
			return 0;
		}
		return 'all';
	}

	function handleDateFilter(created_at: CreatedAtDatePicker) {
		if (created_at && created_at.length > 0) {
			const [start, end] = created_at;
			const created_start_date = moment(start).format();
			const created_end_date = moment(end).format();
			return { created_start_date, created_end_date };
		}
	}

	function handleClear(): void {
		form.resetFields(['filter']);
		onFilter(null);
	}

	const initialValues: InitialValues = {
		filter: {
			status: StatusEnum.active
		}
	};

	return (
		<Collapse>
			<Collapse.Panel header={I18n.get('Filter')} key="1">
				<Row>
					<FormFilter form={form} initialValues={initialValues} />
				</Row>
				<Row gutter={[12, 10]}>
					<Col xs={24} sm={12} md={8} lg={6} xl={4}>
						<Button
							ghost
							type="primary"
							onClick={handleSubmit}
							icon={<SearchOutlined />}
							style={{ width: '100%' }}
						>
							{I18n.get('Search')}
						</Button>
					</Col>
					<Col xs={24} sm={12} md={8} lg={6} xl={4}>
						<Button type="ghost" style={{ width: '100%' }} onClick={handleClear}>
							{I18n.get('Clear')}
						</Button>
					</Col>
				</Row>
			</Collapse.Panel>
		</Collapse>
	);
};
