import React from 'react';
import { Col, Divider, Row } from 'antd';

import { usePreliminaryAnalysis } from '@/components/views/PreliminaryAnalysis/context';
import { useApplicationContext } from '@/context/v1/Application/context';
import { Title } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';

import { Container, Score, WorstScore } from './styles';
import { BodyPartsScores } from './BodyPartsScores';
import { RebaDTO, IUseBodyParts } from './types';
import { Hooks } from './hooks';

interface IRebaProps {
	data: RebaDTO;
}

export const Reba: React.FC<IRebaProps> = ({ data: { file_id, score_seconds } }) => {
	const { organization, company } = useApplicationContext();
	const { rebaAngles } = usePreliminaryAnalysis();

	const payload: IUseBodyParts = {
		organization_id: organization?.id,
		company_id: company?.id,
		file_id: file_id
	};

	const { isLoading, data, isError } = Hooks.useBodyParts(payload);

	if (isLoading) {
		return <p>Loading...</p>;
	}

	if (isError) {
		return <p>Error</p>;
	}

	return (
		<Row gutter={[0, 24]}>
			<Col offset={1} span={23}>
				<Row>
					<Title style={{ margin: 0 }} level={3}>
						{I18n.get('Movement by score (Kinebot/REBA)')}
					</Title>
				</Row>
			</Col>
			<Col offset={1} span={23}>
				<Row>
					<Container>
						<Col span={24}>
							<Row>
								<WorstScore>
									{I18n.get('Worst score')}: <Score>{score_seconds}</Score>
								</WorstScore>
							</Row>
						</Col>
						<Divider style={{ margin: 0 }} />
						<Col span={24}>
							<Row justify="space-evenly">
								<BodyPartsScores data={data} rebaAngles={rebaAngles.angles} />
							</Row>
						</Col>
					</Container>
				</Row>
			</Col>
		</Row>
	);
};
