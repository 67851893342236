import { Modal } from 'antd';
import styled from 'styled-components';

export const ModalContainer = styled(Modal)`
	.ant-modal-content {
		padding: 1rem;
		border-radius: 2rem;
		.ant-modal-close-x {
			padding: 0.5rem 0.5rem 0 0;
		}
	}
`;
