import React from 'react';
import { Col, Form, Input, Row } from 'antd';

import { Text } from '@/components/Typography';

const { TextArea } = Input;

interface CommentProps {
	disabled: boolean;
	onChangeComment: () => {};
}

export function Comment({ onChangeComment, disabled }: Readonly<CommentProps>) {
	return (
		<Row>
			<Col lg={6} xl={5} xxl={3}>
				<Text strong style={{ fontSize: '20px' }}>
					Comment:
				</Text>
			</Col>
			<Col lg={18} xl={19} xxl={21}>
				<Form.Item name="comment">
					<TextArea
						rows={4}
						showCount
						maxLength={3000}
						disabled={disabled}
						onChange={onChangeComment ?? null}
					/>
				</Form.Item>
			</Col>
		</Row>
	);
}
