import React, { useState, useEffect } from 'react';
import { Row, Col, Form, message, Input, Skeleton } from 'antd';
import { I18n } from '@aws-amplify/core';
import _ from 'lodash';

import { useApplicationContext } from '@/context/v1/Application/context';
import { UpdateBeraResultCommentRequest } from './types';
import { Title } from '@/components/Typography';
import { useComments } from './hooks';

const { TextArea } = Input;

interface CommentProps {
	isLoading: boolean;
	consolidated: boolean;
	bera_report_id: string;
	onEditComment(editingComment: boolean): void;
}

export const Comment: React.FC<CommentProps> = ({ isLoading, onEditComment, consolidated, bera_report_id }) => {
	const [text, setText] = useState<string | null>(null);
	const { organization, company } = useApplicationContext();

	const [messageApi, contextHolder] = message.useMessage();

	const { mutate, isLoading: updatingComments } = useComments();

	const setComment = _.debounce((value) => {
		onEditComment(true);
		setText(value);
	}, 1200);

	const onChange = (e: any) => setComment(e.target.value);

	const onSuccess = () => {
		messageApi.open({
			type: 'success',
			content: I18n.get('Updated comment successfully'),
			duration: 2.5
		});
	};

	const onError = () => {
		message.error(I18n.get('Fail to save the comments'));
	};

	const onSettled = () => onEditComment(false);

	useEffect(() => {
		if (text !== null) {
			const parameters: UpdateBeraResultCommentRequest = {
				organization_id: organization?.id,
				company_id: company?.id,
				id: bera_report_id,
				bera_report_data: {
					comment: text
				}
			};

			mutate(parameters, {
				onSuccess,
				onError,
				onSettled
			});
		}
	}, [text]);

	return (
		<Row gutter={[0, 24]}>
			<Col span={24}>
				<Row gutter={[0, 16]}>
					<Col span={24}>
						<Title style={{ margin: 0 }} level={5}>
							Comment
						</Title>
						{contextHolder}
					</Col>
					<Col span={24}>
						{isLoading ? (
							<Skeleton.Input block active />
						) : (
							<Form.Item name="comment" labelCol={{ span: 24 }}>
								<TextArea
									rows={4}
									maxLength={500}
									showCount={true}
									onChange={onChange}
									disabled={updatingComments || consolidated}
								/>
							</Form.Item>
						)}
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
