import React from 'react';
import * as Icons from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import { Col, Row } from 'antd';

import { PayloadDownloadPDF } from './hooks/types';
import { useDownloadPDF } from './hooks/hooks';
import { DownloadButton } from './styles';

interface DownloadPDFProps {
	parameters: PayloadDownloadPDF;
	disableDownload: boolean;
}

export const DownloadPDF: React.FC<DownloadPDFProps> = ({ parameters, disableDownload }) => {
	const { mutateAsync: downloadPDF, isLoading: downloadingPDF } = useDownloadPDF();

	function onDownload(): void {
		downloadPDF(parameters);
	}

	return (
		<Row justify="end">
			<Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<DownloadButton
					loading={downloadingPDF}
					disabled={downloadingPDF || disableDownload}
					onClick={onDownload}
					icon={<Icons.DownloadOutlined style={{ color: '#2F54EB' }} />}
				>
					{I18n.get('Download PDF')}
				</DownloadButton>
			</Col>
		</Row>
	);
};
