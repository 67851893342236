import React, { useEffect } from 'react';
import { Col, Form, Modal, Row } from 'antd';

import { Title } from '@/components/Typography';

import { ConfirmPasswordModal } from './ConfirmPasswordModal';
import { Deactivate } from './Deactivate';
import { useTFAContext } from './context';
import { ActionsForm } from './styles';

const { useForm } = Form;

export const DeactivateTokenModal = () => {
	const [form] = useForm();
	const { openDeactivateTokenModal, correctPassword } = useTFAContext();
	const { handleResetStep, handle2FAChecked, handleCorrectPassword, handleOpenDeactivateTokenModal } =
		useTFAContext();

	useEffect(() => {
		handleCorrectPassword(false);
	}, []);

	function handleClose() {
		form.resetFields();
		handleResetStep();
		handle2FAChecked();
		handleCorrectPassword(false);
		handleOpenDeactivateTokenModal(false);
	}

	return !!correctPassword ? (
		<Modal open={openDeactivateTokenModal} onCancel={handleClose} width={600} footer={false} centered>
			<ActionsForm form={form} layout="vertical">
				<Row align="middle" justify='center'>
					<Col span={24}>
						<Row align="middle" justify='center' gutter={[16, 16]}>
							<Col span='auto'>
								<Title style={{ margin: 0 }} level={3}>
									Deactivate 2FA
								</Title>
							</Col>
							<Col span={24}>
								<Deactivate />
							</Col>
						</Row>
					</Col>
				</Row>
			</ActionsForm>
		</Modal>
	) : (
		<ConfirmPasswordModal />
	);
};
