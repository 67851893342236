import React, { useEffect, useState } from 'react';
import { Row, Col, Checkbox } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Container, Body, Title } from './styles';

export const CollapseCheck = ({ title, children, value, onChange, ...props }) => {
	const [checked, setChecked] = useState(value || false);
	const [open, setOpen] = useState(checked);

	useEffect(() => {
		setChecked(value);
	}, [value]);

	const onOpen = () => {
		setOpen((prev) => !prev);
	};

	const setValues = (e) => {
		const _checked = e.target.checked;
		setChecked(_checked);
		setOpen(_checked ? true : false);
		if (typeof onChange === 'function') {
			onChange(_checked);
		}
	};

	return (
		<Container selected={checked}>
			<Col span={24}>
				<Row align="middle" gutter={[16, 0]}>
					<Col>
						<Checkbox checked={checked} onChange={setValues} />
					</Col>
					<Col onClick={onOpen} span={22}>
						<Title selected={checked}>{I18n.get(title)}</Title>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<Body open={open}>{children}</Body>
			</Col>
		</Container>
	);

	// return (
	// 	<Container selected={false}>
	// 		<Col span={24} onClick={() => {}}>
	// 			<Row align="middle" gutter={[16, 0]}>
	// 				<Col>
	// 					<Checkbox checked={false} onChange={() => {}} />
	// 				</Col>
	// 				<Col>
	// 					<Title selected={false}>{I18n.get(title)}</Title>
	// 				</Col>
	// 			</Row>
	// 		</Col>
	// 		<Col span={24}>
	// 			<Body open={true}>{children}</Body>
	// 		</Col>
	// 	</Container>
	// );
};
