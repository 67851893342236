import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';

import hooks from '../hooks';
import { RiskBar } from '../RiskBar';
import { DashboardCard } from '../styles';
import { DashboardService } from '../service';
import { ToolSubtitle, ToolTitle, Total, Value } from './styles';
import { emptyDataRisks, Risk, RiskBarOptions } from '../RiskBar/types';
import { useApplicationContext } from '@/context/v1/Application/context';
import { DashboardNioshDTO, ENUM_NIOSH_SCORE_SCALE } from '../types';
import { useDashboardContext } from '../context';

const { useDashboardNiosh } = hooks;
const dashboardService = new DashboardService();

export const CargoHandling: React.FC = () => {
	const { queryParams } = useDashboardContext();
	const { organization, company } = useApplicationContext();
	const { data: nioshReports } = useDashboardNiosh(organization.id, company.id, queryParams);

	const riskBarOptions: RiskBarOptions = {
		caption: {
			align: 'center',
			display: 'top'
		},
		orientation: 'horizontal'
	};

	function risksMapper(nioshReports: DashboardNioshDTO): Risk[] {
		if (nioshReports) {
			const risks: Risk[] = [];

			let id = 0;
			for (const risk in nioshReports.scores) {
				if (dashboardService.reportExistsAndHasType(nioshReports, risk)) {
					const percentage = (nioshReports.scores[risk].amount / nioshReports.total) * 100;
					const element: Risk = {
						...ENUM_NIOSH_SCORE_SCALE[nioshReports.scores[risk].type],
						id,
						value: nioshReports.scores[risk].amount,
						percentage: percentage + '%',
						score: nioshReports.scores[risk].score
					};
					element.report = 'niosh';
					id += 1;
					risks.push(element);
				}
			}

			return risks.map((risk) => {
				const hasCaption = risk.id === 0 || risk.id === risks.length - 1;
				return {
					...risk,
					caption: hasCaption ? risk.caption : ''
				};
			});
		}
		return [] as Risk[];
	}

	return (
		<DashboardCard>
			<Row gutter={[0, 12]} align="middle" justify="center">
				<Col span={24}>
					<Row gutter={[0, 2]}>
						<Col span={24}>
							<ToolTitle>{I18n.get('Cargo Handling')}</ToolTitle>
						</Col>

						<Col span={24}>
							<ToolSubtitle>{I18n.get('Score by workstation')}</ToolSubtitle>
						</Col>
					</Row>
				</Col>

				<Col span={8}>
					<Total>{I18n.get('Total evaluations')}:</Total>
				</Col>
				<Col span={16}>{nioshReports.total ? <Value>{nioshReports.total}</Value> : <Value>0</Value>}</Col>

				<Col span={20}>
					{nioshReports.total > 0 ? (
						<RiskBar risks={risksMapper(nioshReports)} options={riskBarOptions} />
					) : (
						<RiskBar risks={emptyDataRisks} options={riskBarOptions} />
					)}
				</Col>
			</Row>
		</DashboardCard>
	);
};
