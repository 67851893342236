import React, { useContext } from 'react';
import { Col, Row } from 'antd';
import moment from 'moment';

import { Title } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';

import { ResultsContext } from '../../context';
import { Topic, Value } from './styles';

export const Summary = () => {
	const {
		company_name,
		sector_name,
		workstation_name,
		analyst_name,
		file_name,
		collection_date,
		line_name,
		activity_name,
		role_name
	} = useContext(ResultsContext);

	return (
		<Col span={12} offset={1}>
			<Row>
				<Title level={3}>Company</Title>
			</Row>
			<Row align="middle" style={{ height: '224px' }}>
				<Row align="top" style={{ width: '100%' }}>
					<Col span={12}>
						<Row>
							<Topic>{I18n.get('Company')}:</Topic>
							<Value>{company_name}</Value>
						</Row>
						<Row>
							<Topic>{I18n.get('Sector')}:</Topic>
							<Value>{sector_name}</Value>
						</Row>
						<Row>
							<Topic>{I18n.get('Workstation')}:</Topic>
							<Value>{workstation_name}</Value>
						</Row>
						<Row>
							<Topic>{I18n.get('Analyst')}:</Topic>
							<Value>{analyst_name}</Value>
						</Row>
					</Col>
					<Col span={11}>
						<Row>
							<Topic>{I18n.get('File')}:</Topic>
							<Value>{file_name}</Value>
						</Row>
						<Row>
							<Topic>{I18n.get('Date')}:</Topic>
							<Value>{collection_date && moment(collection_date).format('L')}</Value>
						</Row>
						<Row>
							<Topic>{I18n.get('Line')}:</Topic>
							<Value>{line_name}</Value>
						</Row>
						<Row>
							<Topic>{I18n.get('Job')}:</Topic>
							<Value>{activity_name}</Value>
						</Row>
						<Row>
							<Topic>{I18n.get('Function')}:</Topic>
							<Value>{role_name}</Value>
						</Row>
					</Col>
				</Row>
			</Row>
		</Col>
	);
};
