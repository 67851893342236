import { Modal } from 'antd';
import styled from 'styled-components';

export const ModalStyled = styled(Modal)`
	.ant-modal-content {
		padding: 0;
		border-radius: 30px;
		width: 744px;
	}
`;


