import React from 'react';
import { Col, Form, Input } from 'antd';
import { I18n } from '@aws-amplify/core';

import { NamePath } from 'antd/lib/form/interface';

interface JobElementProps {
	fieldName: NamePath;
}

export const JobElement: React.FC<JobElementProps> = ({ fieldName }) => {
	return (
		<Col xs={22} sm={20}>
			<Form.Item name={fieldName} label={I18n.get('Job element')} labelCol={{ span: 24 }}>
				<Input.TextArea
					style={{ width: '100%' }}
					showCount
					allowClear
					maxLength={500}
					autoSize={{ minRows: 4, maxRows: 10 }}
					placeholder={I18n.get('Insert job element')}
				/>
			</Form.Item>
		</Col>
	);
};
