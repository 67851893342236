import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import { Col, Row } from 'antd';

import { CustomFormItem, CustomInput } from './styles';
import { useSeraStepsContext } from '../context';

interface FilterFileProps {
	onSearchFileNameChange(event: any): void;
}

export const FilterFile: React.FC<FilterFileProps> = ({ onSearchFileNameChange }) => {
	const { seraSummary, fileName } = useSeraStepsContext();

	return (
		<Col xs={24}>
			<Row justify="center">
				<Col xs={24} xl={16} xxl={12}>
					{!seraSummary.show_sera_result && (
						<CustomFormItem name={['file_name_filter']}>
							<CustomInput
								prefix={<SearchOutlined style={{ color: '#20202055', marginRight: '0.25rem' }} />}
								allowClear
								onChange={onSearchFileNameChange}
								bordered={false}
								value={fileName}
								placeholder={I18n.get('Search by file name...')}
							/>
						</CustomFormItem>
					)}
				</Col>
			</Row>
		</Col>
	);
};
