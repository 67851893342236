import React, { useContext, useEffect } from 'react';

import { useApplicationContext } from '@/context/v1/Application/context';
import { I18n } from '@aws-amplify/core';
import { Form, Select } from 'antd';
import { MergeAEPContext } from '../../context';
import hooks from '../../hooks';

const { useGetSectors } = hooks;

export const Sector: React.FC = () => {
	const { company } = useApplicationContext();
	const { organizationId, companyId, queryParams, setSectorId, setQueryParams } = useContext(MergeAEPContext);

	const {
		data: sectorsList,
		isLoading: gettingSectors,
		isError: sectorsError,
		refetch
	} = useGetSectors(organizationId, companyId || company.id);

	useEffect(() => {
		companyId.length && refetch();
	}, [organizationId, companyId]);

	function handleSectorChange(sectorId: string) {
		setSectorId(sectorId);
		setQueryParams({
			...queryParams,
			sectorId
		});
	}

	return (
		<Form.Item name="sectorId">
			<Select
				showSearch
				loading={gettingSectors}
				onChange={handleSectorChange}
				placeholder={I18n.get('Sector')}
				disabled={!companyId || sectorsError}
				filterOption={(input, option) => (option?.label ?? '').includes(input)}
				options={sectorsList.map(({ id, name }) => ({ label: name, value: id }))}
			/>
		</Form.Item>
	);
};
