import { useMutation, useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import type { AxiosError } from 'axios';
import type { Moment } from 'moment';
import { message } from 'antd';

import Api from '@/services/api';
import type { BaseContext, Response } from '@/types';
import type { EwaJdsD86ReportResponse } from './useGetCustomReport';

export type CreateReportDTO = BaseContext & {
	step_id: string;
	file_id: string;
	sector?: string;
	evaluator_id: string;
	workstation_id?: string;
	collection_date: Moment;
	interviewer_name?: string;
	interviewee_name?: string;
	custom_report_id: string;
	name?: string;
};

async function createReport(payload: CreateReportDTO): Promise<EwaJdsD86ReportResponse> {
	const url = '/custom-report/result';
	const { data } = await Api.post<EwaJdsD86ReportResponse>(url, payload);
	return data;
}

export const useCreateCustomReportResult = () => {
	const queryClient = useQueryClient();
	return useMutation<EwaJdsD86ReportResponse, AxiosError<Response>, CreateReportDTO>((body) => createReport(body), {
		onError: (err) => {
			message.error(I18n.get(err?.response?.data?.message ?? err?.message));
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['jds_d86_report']);
			queryClient.invalidateQueries(['jds_d86_results']);
		}
	});
};
