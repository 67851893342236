import React from 'react';
import { I18n } from '@aws-amplify/core';
import { ColumnsType } from 'antd/es/table';
import { Col, Collapse, Table, TableColumnsType } from 'antd';

import { KimPushPull } from '@/hooks';
import { riskLevelToColorMapper } from '../types';
import { KimTableContainerFirstRow, KimTableContainerSecondRow, PanelCustom } from './styles';

interface KimPushPullProps {
	data: KimPushPull;
}

interface DataType {
	note: number;
	mass: number;
	key: React.Key;
	vehicle: string;
	risk_range: string;
	duration_or_distance: string | null;
}

export const KimPushPullResults: React.FC<KimPushPullProps> = ({ data }) => {
	const {
		id,
		note,
		mass,
		score,
		vehicle,
		duration,
		distance,
		risk_range,
		measurements,
		possible_health_consequences,
		probability_of_physical_overload
	} = data;

	const durationOrDistance = duration ? I18n.get('Duration') : I18n.get('Distance');

	const columns: ColumnsType<DataType> = [
		{
			title: durationOrDistance,
			dataIndex: 'duration_or_distance',
			key: 'duration_or_distance'
		},
		{
			title: I18n.get('Mass'),
			dataIndex: 'mass',
			key: 'mass'
		},
		{
			title: I18n.get('Vehicle'),
			dataIndex: 'vehicle',
			key: 'vehicle'
		},
		{
			title: I18n.get('Risk range'),
			dataIndex: 'risk_range',
			key: 'risk_range',
			width: '15%'
		},
		{
			title: I18n.get('Score'),
			dataIndex: 'note',
			key: 'note',
			width: '15%'
		}
	];

	const dataColumn: DataType[] = [
		{
			key: id,
			duration_or_distance: distance ? `${distance} ${I18n.get('meters')}` : `${duration} ${I18n.get('minutes')}`,
			note,
			risk_range: I18n.get(risk_range),
			vehicle: I18n.get(vehicle),
			mass
		}
	];

	return (
		<Collapse ghost>
			<PanelCustom header={I18n.get('Push and Pull (KIM)')} key={'kim-pp'}>
				<Col span={24}>
					<KimTableContainerFirstRow $riskColor={riskLevelToColorMapper[score]}>
						<Table columns={columns} dataSource={dataColumn} pagination={false} />
					</KimTableContainerFirstRow>
					<KimTableContainerSecondRow>
						<ExpandedTable
							possible_health_consequences={possible_health_consequences}
							measurements={measurements}
							probability_of_physical_overload={probability_of_physical_overload}
						/>
					</KimTableContainerSecondRow>
				</Col>
			</PanelCustom>
		</Collapse>
	);
};

interface ExpandedTableType {
	key?: React.Key;
	possible_health_consequences: string;
	measurements: string;
	probability_of_physical_overload: string;
}
const ExpandedTable: React.FC<ExpandedTableType> = ({
	possible_health_consequences,
	measurements,
	probability_of_physical_overload
}) => {
	const columns: TableColumnsType<ExpandedTableType> = [
		{
			title: I18n.get('Probability of physical overload'),
			dataIndex: 'probability_of_physical_overload',
			key: 'probability_of_physical_overload',
			width: '33.3333%'
		},
		{
			title: I18n.get('Possible health consequences'),
			dataIndex: 'possible_health_consequences',
			key: 'possible_health_consequences',
			width: '33.3333%'
		},
		{
			title: I18n.get('Measurements'),
			dataIndex: 'measurements',
			key: 'measurements',
			width: '33.3333%'
		}
	];

	const data: ExpandedTableType[] = [
		{
			measurements: I18n.get(measurements),
			key: 'kim_manual_handling_second_row',
			possible_health_consequences: I18n.get(possible_health_consequences),
			probability_of_physical_overload: I18n.get(probability_of_physical_overload)
		}
	];

	return <Table columns={columns} dataSource={data} pagination={false} />;
};
