import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'antd';
import moment from 'moment';

import { useCountdown } from '@/hooks/v1/useCountdown';
import { I18n } from '@aws-amplify/core';

import { NamePath } from 'antd/lib/form/interface';
import { useTFAContext } from '../context';

interface ButtonsProps {
	resetFields: (fields?: NamePath[] | undefined) => void;
	totalSteps: number;
}

export const Buttons: React.FC<ButtonsProps> = ({ resetFields, totalSteps }) => {
	const { currentStep, disabled } = useTFAContext();
	const { handlePreviousStep, handleResetStep, handleOpenGenerateTokenModal, handleEnableButton } = useTFAContext();

	const [awaitTime, setAwaitTime] = useState<Date>();

	const timer = 12000; // 12 seconds
	const timeout = moment().add(timer, 'milliseconds').toDate();
	const { seconds } = useCountdown(awaitTime);
	const formattedSeconds = ('0' + seconds).slice(-2);

	useEffect(() => {
		setAwaitTime(timeout);
		if (currentStep === totalSteps) {
			setTimeout(() => handleEnableButton(), timer);
		}
	}, [currentStep, totalSteps]);

	function handlePreviousClick() {
		handlePreviousStep();
	}

	function handleOkClick() {
		resetFields();
		handleOpenGenerateTokenModal(false);
		handleResetStep();
	}

	return (
		<Col span={24}>
			<Row align="middle" justify="space-around">
				{currentStep === 1 && (
					<Col flex={1}>
						<Row>
							<Button onClick={handlePreviousClick}>{I18n.get('Back')}</Button>
						</Row>
					</Col>
				)}
				{currentStep === totalSteps && (
					<Col flex={1}>
						<Row style={{ float: 'right' }}>
							<Button
								htmlType="submit"
								disabled={seconds > 0 || disabled}
								onClick={handleOkClick}
								type="primary"
							>
								{seconds > 0 || disabled ? formattedSeconds : I18n.get('Ok')}
							</Button>
						</Row>
					</Col>
				)}
			</Row>
		</Col>
	);
};
