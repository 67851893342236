import React, { useState } from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { PlusCircleFilled } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';

import { DeleteJdsD92Request, FilterRequest } from './hooks/types/request';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useDeleteReportJdsD92, useJdsD92List } from './hooks';
import { JdsD92ListDTO } from './hooks/types/response';
import { Title } from '@/components/Typography';
import { Filter, FormFields } from './Filter';
import { ModalDelete } from './ModalDelete';
import { CustomButtonAntd } from './styles';
import { ReportList } from './ReportList';
import { SelectedReport } from './types';

export const JdsD92Reports: React.FC = () => {
	const history = useHistory();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const { organization, company } = useApplicationContext();

	const queryParams = convertURLParamToObject(searchParams);

	const [page, setPage] = useState<number>(0);
	const [maxReports, setMaxReports] = useState<number>(10);
	const [filter, setFilter] = useState<FilterRequest>(queryParams);
	const [selectedReport, setSelectedReport] = useState<SelectedReport>();

	const initialValues: FormFields = mapFilterInitialValues(queryParams) ?? {};

	const { mutateAsync: deleteReport, isLoading: deletingReport } = useDeleteReportJdsD92();

	const {
		data: jdsD92List,
		isLoading,
		isFetching
	} = useJdsD92List({
		custom_report_name: 'jds_d92',
		filter: {
			...filter,
			offset: page === 0 ? String(page) : String(page - 1),
			limit: String(maxReports)
		}
	});

	const dataSource: JdsD92ListDTO = jdsD92List;

	function handleClose() {
		setSelectedReport(undefined);
	}

	function handleOpen(report: SelectedReport) {
		setSelectedReport(report);
	}

	function handlePageChange(page: number) {
		setPage(page);
	}

	function handleMaxReportsChange(reports: number) {
		setMaxReports(reports);
	}

	function handleFilterClick(filterData: FilterRequest) {
		setFilter(filterData);
	}

	function mapFilterInitialValues(queryParams: FilterRequest) {
		if (Object.keys(queryParams)?.length > 0) {
			const { created_at_start, created_at_end } = queryParams;

			const created_at =
				created_at_start && created_at_end ? [moment(created_at_start), moment(created_at_end)] : undefined;

			const initialValues: FormFields = {
				...queryParams,
				created_at
			};
			return initialValues;
		}
	}

	async function handleDeleteReport(id: string) {
		const body: DeleteJdsD92Request = {
			company_id: company?.id,
			organization_id: organization?.id,
			id
		};
		await deleteReport({ body });
		handleClose();
	}

	function convertURLParamToObject(searchParams: URLSearchParams) {
		return Object.fromEntries(searchParams.entries());
	}

	function handleCreateJdsD92Click() {
		history.push('/custom-reports/jds-d92/report');
	}

	return (
		<>
			<Row gutter={[0, 10]} justify="center">
				<Col span={24}>
					<Row justify={'space-between'}>
						<Col>
							<Title level={4}>JDS-D92</Title>
						</Col>
						<Col span={0.1}>
							<CustomButtonAntd
								onClick={handleCreateJdsD92Click}
								type="primary"
								icon={<PlusCircleFilled />}
							>
								{I18n.get('New Report')}
							</CustomButtonAntd>
						</Col>
					</Row>
				</Col>
				<Col span={24}>
					<Filter loading={isFetching} onFilterClick={handleFilterClick} initialValues={initialValues} />
				</Col>
				<Col span={24}>
					<ReportList
						data={dataSource}
						page={page}
						maxReports={maxReports}
						onPageChange={handlePageChange}
						onDelete={handleOpen}
						onMaxReportsChange={handleMaxReportsChange}
						isLoading={isLoading || isFetching || deletingReport}
					/>
				</Col>
			</Row>
			<ModalDelete
				report={selectedReport}
				open={!!selectedReport}
				onDeleteReport={handleDeleteReport}
				onCancel={handleClose}
			/>
		</>
	);
};
