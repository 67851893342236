import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { message, notification } from 'antd';
import { I18n } from '@aws-amplify/core';

import {
	CustomReportStepsRequest,
	ExposuresRequest,
	SeveritiesRequest,
	VulnerabilitiesRequest,
	RiskDescriptionsRequest,
	RiskCategoriesRequest,
	RiskDamagesRequest
} from '@/components/ui/Inputs/types/request/customReport';
import {
	GetActionPlanRequest,
	UpdateSeraActionPlanRequest,
	CreateSeraActionPlanRequest,
	DeleteSeraActionPlanRequest,
	CreateSeraSummaryReviewRequest,
	SeraSummaryReviewRequest,
	DownloadFileRequest,
	DeleteSeraReport,
	CreateSeraResultReviewRequest
} from './types/request';
import { TasksRequest } from '@/components/ui/Inputs/types/request/task';

import { SeraSummaryReviewService } from '../services/api/seraSummaryReviewService';
import { SeraActionPlanService } from '../services/api/seraActionPlanService';
import { CustomReportService } from '../services/api/customReportService';
import { SeraStepsService } from '../services/api/seraStepsService';
import { SeraService } from '../services/api/seraService';

import { SeraActionPlanDTO, ActionPlanTaskDTO, SeraSummaryReviewDTO } from './types/response';
import { RiskDescriptionDTO } from '@/components/ui/Inputs/types/response/riskDescription';
import { CustomReportStepDTO } from '@/components/ui/Inputs/types/response/customReport';
import { VulnerabilityDTO } from '@/components/ui/Inputs/types/response/vulnerability';
import { RiskCategoryDTO } from '@/components/ui/Inputs/types/response/riskCategory';
import { RiskDamageDTO } from '@/components/ui/Inputs/types/response/riskDamage';
import { ExposureDTO } from '@/components/ui/Inputs/types/response/exposure';
import { SeverityDTO } from '@/components/ui/Inputs/types/response/severity';
import { UserDTO } from '@/types/AuthUser';

const seraService = new SeraService();
const seraStepsService = new SeraStepsService();
const customReportService = new CustomReportService();
const seraActionPlanService = new SeraActionPlanService();
const seraSummaryReviewService = new SeraSummaryReviewService();

export function useSeraSummaryReview({ organization_id, company_id, sera_summary_id }: SeraSummaryReviewRequest) {
	const enabled = !!organization_id && !!company_id && !!sera_summary_id;
	const summary = useQuery(
		['sera_summary_review', [organization_id, company_id, sera_summary_id]],
		() => seraSummaryReviewService.getSeraSummaryReview({ organization_id, company_id, sera_summary_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...summary,
		data: summary.data ?? ({} as SeraSummaryReviewDTO)
	};
}

export function useSeraActionPlans({
	organization_id,
	company_id,
	sera_summary_review_id,
	file_id
}: GetActionPlanRequest) {
	const enabled = !!organization_id && !!company_id && !!sera_summary_review_id && !!file_id;
	const action_plans = useQuery(
		['sera_action_plans', [organization_id, company_id, sera_summary_review_id, file_id]],
		() =>
			seraActionPlanService.getActionPlanBySeraSummaryReview({
				organization_id,
				company_id,
				sera_summary_review_id,
				file_id
			}),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...action_plans,
		data: action_plans.data ?? ([] as SeraActionPlanDTO[])
	};
}

export function useCreateSeraActionPlan() {
	const queryClient = useQueryClient();
	return useMutation((body: CreateSeraActionPlanRequest) => seraActionPlanService.createSeraActionPlan(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0]?.split('" ') ?? err;
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: () => {
			message.success(I18n.get('Acion plan created successfully'));
			queryClient.invalidateQueries(['sera_action_plans']);
		}
	});
}

export function useUpdateSeraActionPlan() {
	const queryClient = useQueryClient();
	return useMutation((body: UpdateSeraActionPlanRequest) => seraActionPlanService.updateSeraActionPlan(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0]?.split('" ') ?? err;
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: () => {
			message.success(I18n.get('Action plan updated successfully'));
			queryClient.invalidateQueries(['sera_action_plans']);
		}
	});
}

export function useDeleteSeraActionPlan() {
	const queryClient = useQueryClient();
	return useMutation((body: DeleteSeraActionPlanRequest) => seraActionPlanService.deleteSeraActionPlan(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0]?.split('" ') ?? err;
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: () => {
			message.success(I18n.get('Action plan deleted successfully'));
			queryClient.invalidateQueries(['sera_action_plans']);
		}
	});
}

export function useDeleteSeraReport() {
	const queryClient = useQueryClient();
	return useMutation((body: DeleteSeraReport) => seraService.deleteSeraReport(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0]?.split('" ') ?? err;
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: () => {
			message.success(I18n.get('Report deleted successfully'));
			queryClient.invalidateQueries(['sera_summary_review']);
		}
	});
}

export function useSeraSteps({ organization_id, company_id, custom_report_id }: CustomReportStepsRequest) {
	const enabled = !!organization_id && !!company_id && !!custom_report_id;
	const customReportsSteps = useQuery(
		['custom_reports_steps', [organization_id, company_id, custom_report_id]],
		() => customReportService.getCustomReportsSteps({ organization_id, company_id, custom_report_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...customReportsSteps,
		data: customReportsSteps.data ?? ([] as CustomReportStepDTO[])
	};
}

export function useExposures({ organization_id, company_id, step_key_id }: ExposuresRequest) {
	const enabled = !!organization_id && !!company_id && !!step_key_id;
	const exposures = useQuery(
		['exposures', [organization_id, company_id, step_key_id]],
		() => seraStepsService.getExposures({ organization_id, company_id, step_key_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...exposures,
		data: exposures.data ?? ([] as ExposureDTO[])
	};
}

export function useSeverities({ organization_id, company_id, step_key_id }: SeveritiesRequest) {
	const enabled = !!organization_id && !!company_id && !!step_key_id;
	const severities = useQuery(
		['severities', [organization_id, company_id, step_key_id]],
		() => seraStepsService.getSeverities({ organization_id, company_id, step_key_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...severities,
		data: severities.data ?? ([] as SeverityDTO[])
	};
}

export function useVulnerabilities({ organization_id, company_id, step_key_id }: VulnerabilitiesRequest) {
	const enabled = !!organization_id && !!company_id && !!step_key_id;
	const vulnerabilities = useQuery(
		['vulnerabilities', [organization_id, company_id, step_key_id]],
		() => seraStepsService.getVulnerabilities({ organization_id, company_id, step_key_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...vulnerabilities,
		data: vulnerabilities.data ?? ([] as VulnerabilityDTO[])
	};
}

export function useRiskDescriptions({ organization_id, company_id, risk_category_id }: RiskDescriptionsRequest) {
	const enabled = !!organization_id && !!company_id && !!risk_category_id;
	const riskDescriptions = useQuery(
		['risk_descriptions', [organization_id, company_id, risk_category_id]],
		() => seraStepsService.getRiskDescriptions({ organization_id, company_id, risk_category_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...riskDescriptions,
		data: riskDescriptions.data ?? ([] as RiskDescriptionDTO[])
	};
}

export function useRiskCategories({ organization_id, company_id, step_key_id }: RiskCategoriesRequest) {
	const enabled = !!organization_id && !!company_id && !!step_key_id;
	const riskCategories = useQuery(
		['risk_categories', [organization_id, company_id, step_key_id]],
		() => seraStepsService.getRiskCategories({ organization_id, company_id, step_key_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...riskCategories,
		data: riskCategories.data ?? ([] as RiskCategoryDTO[])
	};
}

export function useRiskDamages({ organization_id, company_id, risk_description_id }: RiskDamagesRequest) {
	const enabled = !!organization_id && !!company_id && !!risk_description_id;
	const riskDamages = useQuery(
		['risk_damages', [organization_id, company_id, risk_description_id]],
		() => seraStepsService.getRiskDamages({ organization_id, company_id, risk_description_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...riskDamages,
		data: riskDamages.data ?? ([] as RiskDamageDTO[])
	};
}

export function useTasks({ organization_id, company_id, cycle_id }: TasksRequest) {
	const enabled = !!organization_id && !!company_id && !!cycle_id;
	const tasks = useQuery(
		['tasks', [organization_id, company_id, cycle_id]],
		() => seraStepsService.getTasks({ organization_id, company_id, cycle_id }),
		{
			enabled,
			retry: 0
		}
	);
	return tasks;
}

export const useCreateSeraSummaryReview = () => {
	const queryClient = useQueryClient();
	return useMutation(
		(body: CreateSeraSummaryReviewRequest) => seraSummaryReviewService.createSeraSummaryReview(body),
		{
			onError: (err: any) => {
				const typeError = err.response.data.details[0]?.split('" ') ?? err;
				notification.error({
					message: I18n.get('Ops... something happened!'),
					description: I18n.get(typeError[1])
				});
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['sera_summary_list']);
				queryClient.invalidateQueries(['sera_report']);
			}
		}
	);
};

export const useCreateSeraResultReview = () => {
	const queryClient = useQueryClient();
	const history = useHistory();
	return useMutation((body: CreateSeraResultReviewRequest) => seraService.createSeraReportReview(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0]?.split('" ') ?? err;
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: (data) => {
			history.push(`/custom-reports/sera/report/${data.sera_summary_id}`);
			queryClient.invalidateQueries(['sera_summary_list']);
			queryClient.invalidateQueries(['sera_report']);
		}
	});
};

export const useGetUsers = (organization_id: string, company_id: string) => {
	const users = useQuery(
		['responsible_users', { organization_id, company_id }],
		() => seraActionPlanService.getUsers({ organization_id, company_id }),
		{
			enabled: !!organization_id && !!company_id
		}
	);
	return {
		...users,
		data: users.data ?? ([] as UserDTO[])
	};
};

export const useFindTasks = (action_plan_id: string | undefined, organization_id: string, company_id: string) => {
	const actionPlan = useQuery(
		['tasks', action_plan_id],
		() => seraActionPlanService.getTasks({ action_plan_id, organization_id, company_id }),
		{
			enabled: !!action_plan_id
		}
	);
	return {
		...actionPlan,
		data: actionPlan.data ?? ([] as ActionPlanTaskDTO[])
	};
};

export const useDownloadFile = () => {
	return useMutation((body: DownloadFileRequest) => seraService.downloadFile(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0].split('" ');
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: (data) => {
			const archive = document.createElement('a');
			archive.target = '_blank';
			archive.href = data;
			archive.click();
			archive.remove();
		}
	});
};
