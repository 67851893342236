import React, { ReactNode } from 'react';
import { Modal, Row, message } from 'antd';
import { useDispatch } from 'react-redux';

import { setSession } from '@/redux/auth/actions';
import { I18n } from '@aws-amplify/core';
import history from '@/store/history';

import { useCheck2FAContext } from './context';

interface TwoFactorAuthenticatorModalProps {
	children: ReactNode;
}

export const TwoFactorAuthenticatorModal: React.FC<TwoFactorAuthenticatorModalProps> = ({ children }) => {
	const dispatch = useDispatch();

	const {
		openCheck2FAModal,
		openReset2FAModal,
		openRecoveryTokenModal,
		openValidate2FAModal,
		openForce2FAWarning,
		userSession,
		disabled
	} = useCheck2FAContext();
	const {
		handleOpenCheck2FAModal,
		handleOpenRecoveryTokensModal,
		handleOpenReset2FAModal,
		handleOpenValidate2FAModal,
		handleOpenForce2FAWarning
	} = useCheck2FAContext();

	const openModal =
		openCheck2FAModal || openReset2FAModal || openRecoveryTokenModal || openValidate2FAModal || openForce2FAWarning;

	function handleCloseModal() {
		handleOpenCheck2FAModal(false);
		handleOpenReset2FAModal(false);
		handleOpenForce2FAWarning(false);
		handleOpenValidate2FAModal(false);
		if (openRecoveryTokenModal) {
			if (disabled) {
				return message.error(I18n.get("Can't close yet. Read the instructions."));
			}
			handleOpenRecoveryTokensModal(false);
			localStorage.setItem(
				'info_user',
				JSON.stringify({
					user: userSession.user,
					token: userSession.token,
					refresh_token: userSession.refresh_token
				})
			);
			dispatch(
				setSession({
					user: userSession.user,
					token: userSession.token,
					refresh_token: userSession.refresh_token
				})
			);
			history.push('/');
		}
	}

	return (
		<Modal open={openModal} onCancel={handleCloseModal} width={500} footer={false} centered>
			<Row justify="center">{children}</Row>
		</Modal>
	);
};
