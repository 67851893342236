import React from 'react';
import { Col, Form } from 'antd';

import { useApplicationContext } from '@/context/v1/Application/context';
import { CompanySelect } from '@/components/ui/Inputs/CompanySelect';
import { useListCompanies } from '@/hooks/v1/useListCompanies';

import { FormItem } from '../../../components/FormItem';

const { useFormInstance, useWatch } = Form;

interface CompaniesProps {
	fontSize?: string;
	isRequired?: boolean;
	fieldName: string;
}
export const Companies: React.FC<CompaniesProps> = ({ fontSize, isRequired, fieldName }) => {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();
	const { organization, company } = useApplicationContext();

	const companyId = useWatch([fieldName, 'company_id'], form);
	const {
		data: companies,
		isLoading,
		isFetching,
		isError
	} = useListCompanies({
		organization_id: organization?.id,
		company_id: company?.id
	});

	function handleCompanySelect(id: string) {
		setFieldValue([fieldName, 'company_id'], id);
		form.setFieldValue([fieldName, 'sector_id'], undefined);
		form.setFieldValue([fieldName, 'line_id'], undefined);
		form.setFieldValue([fieldName, 'workstation_id'], undefined);
	}

	function handleClearCompany() {
		setFieldValue([fieldName, 'company_id'], undefined);
		form.setFieldValue([fieldName, 'sector_id'], undefined);
		form.setFieldValue([fieldName, 'line_id'], undefined);
		form.setFieldValue([fieldName, 'workstation_id'], undefined);
	}

	return (
		<Col xs={24}>
			<FormItem
				fontSize={fontSize}
				isRequired={isRequired}
				title={'Industrial site'}
				namePath={[fieldName, 'company_id']}
			>
				<CompanySelect
					disabled
					size="large"
					loading={isFetching}
					companies={companies}
					companyId={companyId}
					fieldName={[fieldName, 'company_id']}
					handleClearCompany={handleClearCompany}
					handleSelectCompany={handleCompanySelect}
				/>
			</FormItem>
		</Col>
	);
};
