import React, { useContext } from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';
import { Row, Col, Form, Modal, Select, DatePicker, Button, Input } from 'antd';

import { ActionPlansContext } from './context';
import { useCreateActionPlan } from '@/hooks/v1/useActionPlanMutations';

const { TextArea } = Input;

export function ActionPlanCreate({ isOpen, onClose }) {
	const [form] = Form.useForm();

	const {
		companies,
		selectedCompanyId,
		setSelectedCompanyId,
		sectors,
		selectedSectorId,
		setSelectedSectorId,
		files,
		responsibleUsers,
		creationBoard
	} = useContext(ActionPlansContext);

	const organizationId = useSelector(({ organization }) => organization.organization?.id);

	const { mutateAsync: actionPlanCreate, isLoading } = useCreateActionPlan();

	function resetFieldsForm(field) {
		const options = {
			company_id: ['sector_id', 'file_id'],
			sector_id: ['file_id']
		};
		form.resetFields(options[field] || []);
	}

	function handleOnClose() {
		onClose();
		form.resetFields(['title', 'responsible_user_id', 'deadline', 'company_id', 'sector_id', 'file_id']);
	}

	function handleMountingPayload(values) {
		const { title, responsible_user_id, deadline, workstation_id, file_id } = values;
		const description = values.description ?? null;

		const action_plan = {
			title,
			description,
			responsible_user_id,
			deadline,
			board: creationBoard.toUpperCase(),
			workstation_id,
			file_id
		};

		const body = {
			action_plan,
			organization_id: organizationId
		};

		return body;
	}

	function handleCreateActionPlan(value) {
		const payload = handleMountingPayload(value);
		actionPlanCreate(payload).then(() => handleOnClose());
	}

	async function handleOnSubmit() {
		const values = await form.validateFields();
		handleCreateActionPlan(values);
	}

	const Footer = () => [
		<Button key="cancel" onClick={handleOnClose}>
			{I18n.get('Cancel')}
		</Button>,
		<Button key="submit" type="primary" onClick={handleOnSubmit} loading={isLoading}>
			{I18n.get('Save')}
		</Button>
	];

	return (
		<Modal
			open={isOpen}
			width="1000px"
			style={{ top: 20 }}
			footer={<Footer />}
			onCancel={handleOnClose}
			title={I18n.get('Create action plan')}
		>
			<Form form={form} layout="vertical" name="create_action_plan">
				<Row gutter={[50, 10]}>
					<Col span={12}>
						<Form.Item
							name="title"
							label={I18n.get('Title')}
							rules={[
								{
									required: true,
									message: I18n.get('Please enter a valid title')
								}
							]}
						>
							<Input placeholder={I18n.get('Action plan title')} />
						</Form.Item>
						<Form.Item
							name="description"
							label={I18n.get(
								'Describe the situation found and the preventive measures already implemented (if exist)'
							)}
							rules={[
								{
									required: false
								}
							]}
						>
							<TextArea
								rows={4}
								showCount
								maxLength={500}
								placeholder={I18n.get('Action plan description')}
							/>
						</Form.Item>
						<Row justify="space-between">
							<Form.Item
								name="responsible_user_id"
								label={I18n.get('Person responsible')}
								rules={[
									{
										message: I18n.get('Please enter a person responsible'),
										required: true
									}
								]}
								style={{
									width: '70%',
									display: 'inline-block'
								}}
							>
								<Select allowClear placeholder={I18n.get('Who is responsible for this action plan?')}>
									{responsibleUsers.data?.map((person, index) => (
										<Select.Option key={index} value={person.id}>
											{person.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								name="deadline"
								label={I18n.get('Deadline')}
								rules={[
									{
										required: true,
										message: I18n.get('Please enter a valid date')
									}
								]}
								style={{
									display: 'inline-block',
									width: '25%'
								}}
							>
								<DatePicker
									format={'L'}
									placeholder={I18n.get('Date')}
									disabledDate={(current) => current < moment().subtract(1, 'day')}
								/>
							</Form.Item>
						</Row>
					</Col>
					<Col span={12}>
						<Form.Item
							name="company_id"
							label={I18n.get('Industrial site')}
							rules={[
								{
									required: true,
									message: I18n.get('Select a industrial site')
								}
							]}
						>
							<Select
								style={{ width: '100%' }}
								onChange={(value) => {
									setSelectedCompanyId(value);
									resetFieldsForm('company_id');
								}}
								placeholder={I18n.get('Select a industrial site')}
							>
								{companies.data?.map((company, index) => (
									<Select.Option key={index} value={company.id}>
										{company.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item
							name="sector_id"
							label={I18n.get('Sector')}
							rules={[
								{
									required: true,
									message: I18n.get('Select a sector')
								}
							]}
						>
							<Select
								style={{ width: '100%' }}
								onChange={(value) => {
									setSelectedSectorId(value);
									resetFieldsForm('sector_id');
								}}
								placeholder={I18n.get('Select a industrial site')}
								disabled={!selectedCompanyId}
							>
								{sectors.data?.map((sector, index) => (
									<Select.Option key={index} value={sector.id}>
										{sector.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item
							name="file_id"
							label={I18n.get('File')}
							rules={[
								{
									required: true,
									message: I18n.get('Select a file')
								}
							]}
						>
							<Select
								loading={files.isLoading}
								style={{ width: '100%' }}
								disabled={!selectedSectorId}
								placeholder={I18n.get('Select a file')}
							>
								{files.data?.map((file) => (
									<Select.Option key={file.id} value={file.id}>
										{file.original_name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
}
