import { Card, Modal } from 'antd';
import styled from 'styled-components';

export const ModalCard = styled(Card)`
	text-align: center;
	margin-top: 10px;
	border-radius: 17px;
	height: 150px;
	background: #f5f5f5;
	color: #262626;
	margin-bottom: 0px;

	.ant-card-body {
		height: 100%;
		padding: 20px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		.ant-typography:first-child {
			width: 60%;
		}
		.card-report-0 {
			margin-top: 18px;
		}
	}
	.ant-card-body {
	}

	&:hover {
		background: #2f54eb;
		cursor: pointer;
		.ant-typography {
			color: #fff;
		}
	}
`;

export const ModalStyled = styled(Modal)`
	.ant-modal-content {
		padding: 30px 0;
		border-radius: 30px;
		min-width: 400px;

		/* @media (min-height: 800px) {
			padding-top: 25px;
			.title-modal {
				font-size: 35px;
				text-align: center;
				margin: 0 0 10px 0;
			}
			.paragraph-modal {
				margin: 0 0 45px 0;
			}
		} */
	}
`;
