import Api from '@/services/api';
import { IPlanResponse } from './types';

async function getPlan(): Promise<IPlanResponse> {
	const url = '/plan/customer/details';
	const { data } = await Api.get(url);
	return data;
}

export const Services = {
	getPlan
};
