import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Input, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import { useApplicationContext } from '@/context/v1/Application/context';
import { next, updateInformations, validationStep } from '@/redux/reports/actions';
import { useFile, useSectors } from '@/hooks/v1';
import { checkIfValidUUID } from '@/util';
import { I18n } from '@aws-amplify/core';

import { Workstations } from './Workstations';
import { useRulaContext } from './context';
import { Sectors } from './Sectors';
import { Lines } from './Lines';

export function Informations({ fileId }) {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const { handleFile } = useRulaContext();
	const { organization, company } = useApplicationContext();

	const [recoverySector, setRecoverySector] = useState(null);

	const { informations } = useSelector((state) => state.reports.customized);

	const { file } = useFile({
		organizationId: organization?.id,
		companyId: company?.id,
		fileId
	});

	useEffect(() => {
		handleFile(file.data);
	}, [file]);

	const { data: sectors } = useSectors({ organizationId: organization?.id, companyId: company?.id });

	const normalizeData = () => {
		const initialValues = {
			sector_id: null,
			line_id: null,
			workstation_id: null,
			company: null,
			collection_date: null
		};

		if (company?.name) {
			_.set(initialValues, 'company', company.name);
		}

		if (file?.data?.workstations?.id) {
			_.set(initialValues, 'workstation_id', file.data.workstations.id);
		}

		if (file?.data?.workstations?.line?.id) {
			_.set(initialValues, 'line_id', file.data.workstations.line.id);
		}

		if (file?.data?.workstations?.line?.sector) {
			// this function form.setFieldsValue not working with select options.
			const sector = file.data.workstations.line.sector;
			_.set(initialValues, 'sector_id', sector.id);
			setRecoverySector(sector);
		}

		if (informations.collection_date) {
			_.set(initialValues, 'collection_date', moment(informations.collection_date));
		}

		return initialValues;
	};

	const onFormatData = (values) => {
		let sectorId = values.sector_id;

		if (!checkIfValidUUID(sectorId)) {
			sectorId = recoverySector.id;
			setRecoverySector(null);
		}

		const [sector] = sectors?.filter((item) => sectorId === item.id && item);
		const dataForm = { ...values, sector: JSON.stringify(sector) };

		dispatch(updateInformations(dataForm));
		dispatch(validationStep('informations', true));
		dispatch(next('customized'));
	};

	const onFinish = () => {
		form.validateFields().then(onFormatData);
	};

	useEffect(() => {
		if (form && informations) {
			const dataForm = normalizeData();
			form.setFieldsValue(dataForm);
		}
	}, [form, informations]);

	useEffect(() => {
		if (informations) {
			const { collection_date, sector, workstation, is_valid } = informations;
			sector && workstation && collection_date && !is_valid && dispatch(validationStep('informations', true));
		}
	}, [informations]);

	return (
		<Form form={form} layout="vertical" style={{ padding: '25px 10px 10px 10px' }} onValuesChange={onFinish}>
			<Row gutter={[10, 10]}>
				<Col sm={12}>
					<Form.Item name="company" label={I18n.get('Company')}>
						<Input disabled={true} />
					</Form.Item>
				</Col>
				<Sectors />
				<Lines />
				<Workstations />
				<Col sm={12}>
					<Form.Item
						label={I18n.get('Collection date')}
						name="collection_date"
						rules={[
							() => ({
								validator(_, value) {
									if (!value) {
										return Promise.reject(I18n.get('Insert the date of collection'));
									}
									return Promise.resolve();
								}
							})
						]}
					>
						<DatePicker format={'L'} placeholder={I18n.get('Collection date')} style={{ width: '100%' }} />
					</Form.Item>
				</Col>
				<Col sm={24} style={{ textAlign: 'end' }}>
					<Form.Item shouldUpdate noStyle>
						{() => (
							<Button type="primary" onClick={onFinish} disabled={!form.isFieldsTouched(true)}>
								{I18n.get('Next')}
							</Button>
						)}
					</Form.Item>
				</Col>
			</Row>
		</Form>
	);
}
