import React, { useEffect, useState } from 'react';
import { TableRowSelection } from 'antd/lib/table/interface';
import { ColumnsType } from 'antd/lib/table';
import { Col, Form, Row, Table } from 'antd';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import { FileForCustomReport } from '@/components/ui/Inputs/types/response/file';
import { useApplicationContext } from '@/context/v1/Application/context';

import { useSeraStepsContext } from '../context';
import { CustomFormItem } from './styles';
import { FilterFile } from './FilterFile';
import { useTasks } from '../hooks/hooks';
import { SubTitle } from './SubTitle';
import { Tasks } from './Tasks';

const { useFormInstance, useWatch } = Form;

interface FilesSelectionProps {
	isLoading: boolean;
}

export const FilesSelection: React.FC<FilesSelectionProps> = ({ isLoading }) => {
	const [timer, setTimer] = useState<NodeJS.Timeout>();
	const [searchingFile, setSearchingFile] = useState(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

	const { seraFiles, seraSummary, seraCommonInformations, page, limit, fileName, filesTasks } = useSeraStepsContext();
	const { filterSelectedFiles, handleSelectedFilesChange, handlePageChange, handleFileNameChange, handleSizeChange } =
		useSeraStepsContext();
	const { organization, company } = useApplicationContext();

	const tasks = useTasks({
		organization_id: organization?.id,
		company_id: company.id,
		cycle_id: seraCommonInformations?.cycle_id ?? seraSummary?.cycle_id
	});

	const form = useFormInstance();
	const files_list = useWatch('files_list', form);

	useEffect(() => {
		setSearchingFile(false);
	}, [fileName]);

	useEffect(() => {
		if (Array.isArray(files_list)) {
			setSelectedRowKeys(files_list);
		}
	}, [files_list]);

	const columns: ColumnsType<FileForCustomReport> = [
		{
			title: I18n.get('File'),
			dataIndex: 'original_name',
			key: 'original_name',
			align: 'center'
		},
		{
			title: I18n.get('Date of creation'),
			dataIndex: 'updated_at',
			key: 'updated_at',
			align: 'center',
			render: (updated) => moment(updated).format('L')
		},
		{
			title: I18n.get('TLID'),
			dataIndex: 'tasks',
			key: 'tasks',
			align: 'center',
			width: '35%',
			render: (_tasks, file) => {
				const alreadySetFileTask = filesTasks.find(({ id }) => file.id === id);
				const disabled = !selectedRowKeys.includes(file.id) || !!alreadySetFileTask?.task_id;
				const fieldName = [file.id, 'task_id'];
				form.setFieldValue(fieldName, alreadySetFileTask?.task_id);

				return (
					<Row align="middle" justify="center">
						<Tasks
							key={file.id}
							tasks={tasks}
							disabled={disabled}
							fieldName={fieldName}
							cycleId={seraSummary.cycle_id}
						/>
					</Row>
				);
			}
		}
	];

	let dataSource = seraFiles?.files?.rows;

	if (seraSummary.show_sera_result) {
		dataSource = seraFiles?.files?.rows?.filter((file) =>
			file.task.find((task) =>
				task.sera_report?.find((report) => report?.review?.sera_summary_id === seraSummary?.id)
			)
		);
	}

	const rowSelection: TableRowSelection<FileForCustomReport> = {
		preserveSelectedRowKeys: true,
		selectedRowKeys,
		onChange: handleSelectChange
	};

	function handleSelectChange(selectedRows: React.Key[]) {
		setSelectedRowKeys(selectedRows);
		const files = filterSelectedFiles(selectedRows, seraFiles);
		handleSelectedFilesChange(files);
	}

	const setFileName = (name: string) => {
		clearTimeout(timer);

		const debounce = setTimeout(() => {
			if (!name || name.length === 0) {
				handleSizeChange(10);
			} else {
				handlePageChange(1);
				handleSizeChange(100);
			}
			handleFileNameChange(name);
		}, 1000);

		setTimer(debounce);
	};

	function handleSearchFileNameChange(event: any) {
		const name = event.target.value;
		setSearchingFile(true);
		setFileName(name);
	}

	const paginationOptions = !seraSummary.show_sera_result && {
		hideOnSinglePage: false,
		total: seraFiles?.files?.count,
		current: page === 0 ? page + 1 : page,
		defaultPageSize: limit,
		pageSize: limit,
		onChange: (page: number) => {
			handlePageChange(page);
		}
	};

	return (
		<Col xs={24}>
			<Row gutter={[0, 10]} justify="center">
				<SubTitle />
				<FilterFile onSearchFileNameChange={handleSearchFileNameChange} />
				<Col xs={24}>
					<Row justify="center">
						<Col xs={24} xl={16} xxl={12}>
							<CustomFormItem
								name={['files_list']}
								rules={[
									() => ({
										validator() {
											return !selectedRowKeys ||
												selectedRowKeys.length === 0 ||
												!selectedRowKeys[0]
												? Promise.reject(I18n.get('Select at least one file'))
												: Promise.resolve();
										}
									})
								]}
							>
								<Table
									rowKey="id"
									dataSource={dataSource}
									columns={columns}
									loading={isLoading || searchingFile}
									rowSelection={seraSummary.show_sera_result ? undefined : rowSelection ?? undefined}
									pagination={paginationOptions}
								/>
							</CustomFormItem>
						</Col>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};
