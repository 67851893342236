import React from 'react';
import { Title } from '@/components/Typography';
import { Col, Row, Skeleton } from 'antd';

import { RPNScore, RPNTopic, Score, Topic } from './styles';
import { I18n } from '@aws-amplify/core';

interface ScoresProps {
	rpn: number;
	severity: number;
	exposure: number;
	isLoading: boolean;
	vulnerability: number;
}

export const Scores: React.FC<ScoresProps> = ({ isLoading, exposure, severity, vulnerability, rpn }) => {
	return (
		<Col span={7}>
			<Row>
				<Col span={24}>
					<Title level={5}>Result</Title>
				</Col>
			</Row>
			{isLoading ? (
				<Skeleton active />
			) : (
				<Row style={{ marginTop: '1rem' }}>
					<Col span={23} offset={1}>
						<Row gutter={[0, 12]}>
							<Col span={16}>
								<Row justify="space-between">
									<Col>
										<Topic>{I18n.get('Exposure')}:</Topic>
									</Col>
									<Col>
										<Score>{exposure}</Score>
									</Col>
								</Row>
							</Col>
							<Col span={16}>
								<Row justify="space-between">
									<Col>
										<Topic>{I18n.get('Severity')}:</Topic>
									</Col>
									<Col>
										<Score>{severity}</Score>
									</Col>
								</Row>
							</Col>
							<Col span={16}>
								<Row justify="space-between">
									<Col>
										<Topic>{I18n.get('Vulnerability')}:</Topic>
									</Col>
									<Col>
										<Score>{vulnerability}</Score>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row style={{ marginTop: '2rem' }}>
							<Col span={20}>
								<Row justify="space-between" align="middle">
									<Col span={16}>
										<RPNTopic>{I18n.get('Risk Priority Number (RPN)')}:</RPNTopic>
									</Col>
									<Col span={3}>
										<RPNScore>{rpn}</RPNScore>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
			)}
		</Col>
	);
};
