import React from 'react';
import { NamePath } from 'antd/lib/form/interface';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { TaskDTO } from '@/components/ui/Inputs/types/response/task';
import { TaskSelect } from '@/components/ui/Inputs/TaskSelect';
import { CustomFormItem } from './styles';

const { useFormInstance, useWatch } = Form;

interface TasksProps {
	fieldName: NamePath;
	tasks: TaskDTO[];
}

export const Tasks: React.FC<TasksProps> = ({ fieldName, tasks }) => {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();

	const taskId = useWatch(fieldName, form);

	async function handleTaskSelect(id: string) {
		setFieldValue(fieldName, id);
	}

	async function handleClearTask() {
		setFieldValue(fieldName, undefined);
	}

	return (
		<Col xs={24} sm={12} lg={24}>
			<CustomFormItem
				name={fieldName}
				label={I18n.get('Task list ID (TLID)')}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: true,
						message: I18n.get('Select the task')
					}
				]}
			>
				<TaskSelect
					tasks={tasks}
					taskId={taskId}
					loading={false}
					disabled={true}
					allowCreation={false}
					fieldName={fieldName}
					handleClearTask={handleClearTask}
					handleSelectTask={handleTaskSelect}
				/>
			</CustomFormItem>
		</Col>
	);
};
