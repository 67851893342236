import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Button, Col, Form, Row } from 'antd';

import { SelectId } from '../styles';
import { useManageCategoryContext } from '../../context';
import { useUpdateRiskDamageSeverity } from '../../hooks';
import { SeverityDTO, SeveritySelect } from '@/components/ui/Inputs';
import { useApplicationContext } from '@/context/v1/Application/context';

interface SetSeverityProps {
	severities: SeverityDTO[];
	gettingSeverities: boolean;
	fetchingSeverities: boolean;
	errorGettingSeverities: boolean;
}

const { useFormInstance, useWatch } = Form;

export const SetSeverity: React.FC<SetSeverityProps> = ({
	severities,
	gettingSeverities,
	fetchingSeverities,
	errorGettingSeverities
}) => {
	const form = useFormInstance();

	const { isEditing } = useManageCategoryContext();
	const { company, organization } = useApplicationContext();
	const { mutateAsync: updateRiskDamageSeverity, isLoading: updatingRiskDamageSeverity } =
		useUpdateRiskDamageSeverity();

	const currentSeverityId = useWatch(['current_severity_id'], form);
	const riskDamageId = useWatch(['risk_damage_id'], form);
	const severityId = useWatch(['severity_id'], form);

	function handleSeveritySelect(id: string) {
		form.setFieldValue(['severity_id'], id);
	}

	function handleClearSeverity() {
		form.setFieldValue(['severity_id'], undefined);
	}

	async function handleSetSeverityToDamage() {
		const updated = await updateRiskDamageSeverity({
			id: riskDamageId,
			severity_id: severityId,
			company_id: company?.id,
			organization_id: organization?.id
		});
		if (updated?.severity_id) {
			form.setFieldValue(['current_severity_id'], updated.severity_id);
		}
	}
	return (
		<Col span={24}>
			<Row align="bottom" justify="space-between" gutter={[8, 0]}>
				<Col span={20}>
					<SelectId
						selected={!!severityId || isEditing || !riskDamageId}
						name="severity_id"
						label={I18n.get(`Select a severity`)}
						labelCol={{ span: 24 }}
						style={{ margin: '0' }}
						rules={[
							{
								message: I18n.get(`Please, select a severity`)
							}
						]}
					>
						<SeveritySelect
							size="middle"
							severities={severities}
							severityId={severityId}
							fieldName="severity_id"
							loading={isEditing || fetchingSeverities}
							handleClearSeverity={handleClearSeverity}
							handleSelectSeverity={handleSeveritySelect}
							disabled={
								errorGettingSeverities ||
								fetchingSeverities ||
								gettingSeverities ||
								!riskDamageId ||
								isEditing
							}
						/>
					</SelectId>
				</Col>
				<Col span={4}>
					<Row align="middle">
						<Col span={24}>
							<Button
								size="middle"
								type="primary"
								style={{ width: '100%' }}
								onClick={handleSetSeverityToDamage}
								loading={updatingRiskDamageSeverity}
								disabled={!severityId || currentSeverityId === severityId || isEditing}
							>
								{I18n.get('Set')}
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};
