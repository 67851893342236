import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Row, Col, Form, message } from 'antd';
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';
import _ from 'lodash';

import { REPORT_COMMENT_DEBOUNCE_TIME as COMMENT_DEBOUNCE_TIME } from '@/constants/index.js';
import { HeaderStep, Footer } from '@/components/views/Report/Steps.js';
import { useSessionStorage } from '@/hooks/useSessionStorage';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useDebounce } from '@/hooks/useDebounce';
import Spinner from '@/components/Layout/Spinner';
import { useFile, useSectors } from '@/hooks/v1';
import { RiskFactors2 } from './RiskFactors2';
import { RiskFactors1 } from './RiskFactors1';
import { Information } from './Information';
import { RSIProvider } from './context';
import { isNumeric } from '@/util';
import { Result } from './Result';
import Api from '@/services/api';

const { useForm } = Form;

export function StrainIndex() {
	const locale = moment.locale();

	const queryClient = useQueryClient();
	const history = useHistory();
	const [form] = useForm();
	const { file_id } = useParams();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

	const [currentStep, setCurrentStep] = useState(0);

	const organization = useSelector((state) => state.organization.organization);
	const company = useSelector((state) => state.organization.company);

	const [cameFromPreliminary, setCameFromPreliminary] = useState(false);
	const [nameLocal] = useLocalStorage('strain-index', false);
	const [nameSession, setNameSession] = useSessionStorage('strain-index', false);

	const sectors = useSectors({
		organizationId: organization?.id,
		companyId: company?.id
	});

	const { file } = useFile({
		organizationId: organization?.id,
		companyId: company?.id,
		fileId: file_id
	});

	async function getReport({ organizationId, companyId, fileId }) {
		if (!organizationId || !companyId || !fileId) return [];
		const url = `/ergonomic-tool/strain-index/${fileId}?organization_id=${organizationId}&company_id=${companyId}`;
		const { data } = await Api.get(url);
		if (data.status === 'failed') {
			return null;
		}

		const normalizedReport = normalizeReport(data);
		return normalizedReport;
	}

	const reportParams = {
		organizationId: organization?.id,
		companyId: company?.id,
		fileId: file?.data?.id
	};

	const report = useQuery(['strain_index', reportParams], () => getReport(reportParams), {
		enabled: !!organization && !!company && !!file.data,
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (data) => {
			if (data) {
				goToResults();
			}
		}
	});

	const isLoading = sectors.isLoading || file.isLoading || report.isLoading;
	const isError = sectors.isError || file.isError || report.isError;

	useEffect(() => {
		if (!isLoading) {
			if (nameLocal || nameSession) {
				setCameFromPreliminary(true);
			}
		}
	}, [isLoading]);

	useEffect(() => {
		if (cameFromPreliminary) {
			localStorage.removeItem('strain-index');
			setNameSession(true);
		}
	}, [cameFromPreliminary]);

	const updateReport = useMutation(
		({ body, reportExists }) => {
			const { report_id } = body.report_input;
			if (reportExists) {
				return Api.put(`/ergonomic-tool/strain-index/${report_id}`, body);
			} else {
				return Api.post('/ergonomic-tool/strain-index', body);
			}
		},
		{
			onError: (err) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: (response) => {
				const normalizedReport = normalizeReport(response.data);
				queryClient.setQueryData(['strain_index', reportParams], normalizedReport);
				queryClient.invalidateQueries(['file']);
				message.success(I18n.get('Report created successfully'));
				onNext();
			}
		}
	);

	const updateComment = useMutation(
		({ body }) => {
			const { report_id } = body.report_input;
			return Api.put(`/ergonomic-tool/strain-index/comment/${report_id}`, body);
		},
		{
			onError: (err) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: (response) => {
				const normalizedReport = normalizeReport(response.data);
				queryClient.setQueryData(['strain_index', reportParams], normalizedReport);
				message.success(I18n.get('Comment updated successfully'));
			}
		}
	);

	const generatePDF = useMutation(
		({ body, opts }) => {
			return Api.post('/ergonomic-tool/strain-index/document/pdf', body, opts);
		},
		{
			onError: (err) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: (response) => {
				const blob = new Blob([response.data], {
					type: 'application/pdf'
				});
				window.open(URL.createObjectURL(blob));
				message.success(I18n.get('Document created successfully'));
			}
		}
	);

	function goToResults() {
		const finalStep = steps.length - 1;
		setCurrentStep(finalStep);
	}

	function onNext() {
		if (currentStep < steps.length - 2) {
			form.validateFields()
				.then(() => {
					setCurrentStep((prev) => prev + 1);
				})
				.catch(() => message.error(I18n.get('Enter the required values')));
		} else {
			setCurrentStep((prev) => prev + 1);
		}
	}

	function onPrevious() {
		setCurrentStep((prev) => prev - 1);
	}

	function onFinish() {
		form.validateFields()
			.then(() => {
				const values = form.getFieldsValue(true);
				const { organization_id, ...rest } = values;
				const company_id = company?.id;
				const report_input = {
					...rest,
					file_id,
					...(!!report.data && { report_id: report.data.id })
				};

				if (!report.data || isFormChanged(report_input, report.data)) {
					const body = { organization_id, company_id, report_input };
					updateReport.mutate({ body, reportExists: !!report.data });
				} else {
					onNext();
				}
			})
			.catch(() => message.error(I18n.get('Enter the required values')));
	}

	async function onClose() {
		await saveComment();
		const custom_report = !!searchParams.get('custom_report');
		if (cameFromPreliminary) {
			window.close();
		}
		if (custom_report) {
			return history.push('/custom-reports/files');
		}
		history.push('/reporting');
	}

	const onChangeComment = useDebounce(saveComment, COMMENT_DEBOUNCE_TIME);

	async function saveComment() {
		const values = form.getFieldsValue(true);
		const { comment, organization_id } = values;
		const company_id = company?.id;
		if ((comment || comment === '') && report.data.comment !== comment) {
			const report_input = { report_id: report.data.id, comment };
			const body = { organization_id, company_id, report_input };
			await updateComment.mutateAsync({ body });
		}
	}

	async function onDownloadPDF(fileData) {
		await saveComment();
		const browserLanguage = window.navigator.language ?? 'en-US';

		const body = {
			organization_id: organization?.id,
			company_id: company?.id,
			file_id: fileData.id,
			locale: browserLanguage
		};

		const opts = { responseType: 'blob' };

		generatePDF.mutate({ body, opts });
	}

	function isFormChanged(report_input, previousReport) {
		const existingFile = {
			sector_id: file.data?.workstations?.line?.sector?.id,
			line_id: file.data?.workstations?.line?.id,
			workstation_id: file.data?.workstations?.id
		};

		const formFile = {
			sector_id: report_input.sector_id,
			line_id: report_input.line_id,
			workstation_id: report_input.workstation_id
		};

		const existingReport = {
			collection_date: moment(previousReport.collection_date).format('L'),
			input_left_borg_scale: previousReport.input_left_borg_scale,
			input_left_exertions: previousReport.input_left_exertions,
			input_left_observation_time: previousReport.input_left_observation_time,
			input_left_exertion_duration: previousReport.input_left_exertion_duration,
			input_left_wrist_posture: previousReport.input_left_wrist_posture,
			input_left_wrist_angle: previousReport.input_left_wrist_angle,
			input_left_daily_duration: previousReport.input_left_daily_duration,
			input_right_borg_scale: previousReport.input_right_borg_scale,
			input_right_exertions: previousReport.input_right_exertions,
			input_right_observation_time: previousReport.input_right_observation_time,
			input_right_exertion_duration: previousReport.input_right_exertion_duration,
			input_right_wrist_posture: previousReport.input_right_wrist_posture,
			input_right_wrist_angle: previousReport.input_right_wrist_angle,
			input_right_daily_duration: previousReport.input_right_daily_duration
		};

		const formReport = {
			collection_date: moment(report_input.collection_date).format('L'),
			input_left_borg_scale: report_input.input_left_borg_scale,
			input_left_exertions: report_input.input_left_exertions,
			input_left_observation_time: report_input.input_left_observation_time,
			input_left_exertion_duration: report_input.input_left_exertion_duration,
			input_left_wrist_posture: report_input.input_left_wrist_posture,
			input_left_wrist_angle: report_input.input_left_wrist_angle,
			input_left_daily_duration: report_input.input_left_daily_duration,
			input_right_borg_scale: report_input.input_right_borg_scale,
			input_right_exertions: report_input.input_right_exertions,
			input_right_observation_time: report_input.input_right_observation_time,
			input_right_exertion_duration: report_input.input_right_exertion_duration,
			input_right_wrist_posture: report_input.input_right_wrist_posture,
			input_right_wrist_angle: report_input.input_right_wrist_angle,
			input_right_daily_duration: report_input.input_right_daily_duration
		};

		const isFileChanged = !_.isEqual(existingFile, formFile);

		const isReportChanged = !_.isEqual(existingReport, formReport);

		return isFileChanged || isReportChanged;
	}

	// Normalize report when getting data from database
	function normalizeReport(reportData) {
		const normalizedReport = {
			...reportData,
			collection_date: moment(reportData.collection_date),
			score_left_borg_scale: Math.round(reportData.score_left_borg_scale * 100) / 100,
			score_left_efforts_per_minute: Math.round(reportData.score_left_efforts_per_minute * 100) / 100,
			score_left_exertion_duration: Math.round(reportData.score_left_exertion_duration * 100) / 100,
			score_left_wrist_posture: Math.round(reportData.score_left_wrist_posture * 100) / 100,
			score_left_daily_duration: Math.round(reportData.score_left_daily_duration * 100) / 100,
			score_left_rsi: Math.round(reportData.score_left_rsi * 100) / 100,
			score_right_borg_scale: Math.round(reportData.score_right_borg_scale * 100) / 100,
			score_right_efforts_per_minute: Math.round(reportData.score_right_efforts_per_minute * 100) / 100,
			score_right_exertion_duration: Math.round(reportData.score_right_exertion_duration * 100) / 100,
			score_right_wrist_posture: Math.round(reportData.score_right_wrist_posture * 100) / 100,
			score_right_daily_duration: Math.round(reportData.score_right_daily_duration * 100) / 100,
			score_right_rsi: Math.round(reportData.score_right_rsi * 100) / 100
		};

		return normalizedReport;
	}

	function formatReport(reportData) {
		let formattedReport = { ...reportData };

		const effortsPerMinute = {
			effortsPerMinuteLeft:
				Math.round(
					(formattedReport.input_left_exertions / (formattedReport.input_left_observation_time / 60)) * 100
				) / 100,
			effortsPerMinuteRight:
				Math.round(
					(formattedReport.input_right_exertions / (formattedReport.input_right_observation_time / 60)) * 100
				) / 100
		};

		formattedReport = { ...reportData, ...effortsPerMinute };

		for (const key in formattedReport) {
			if (typeof formattedReport[key] === 'number') {
				formattedReport[key] = new Intl.NumberFormat(locale).format(formattedReport[key]);
			}
			if (!isNumeric(formattedReport[key])) continue;

			formattedReport[key] = parseFloat(formattedReport[key]).toLocaleString();
		}

		return formattedReport;
	}

	const steps = [
		{
			title: I18n.get('Information'),
			content: <Information file={file} />,
			is_valid: true
		},
		{
			title: I18n.get('Risk factors 1'),
			content: <RiskFactors1 form={form} />,
			is_valid: true
		},
		{
			title: I18n.get('Risk factors 2'),
			content: <RiskFactors2 />,
			is_valid: true
		},
		{
			title: I18n.get('Result'),
			content: (
				<Result
					file={file}
					report={report}
					sectors={sectors}
					formatReport={formatReport}
					onDownloadPDF={onDownloadPDF}
					isLoadingPDF={generatePDF.isLoading}
					onChangeComment={onChangeComment}
				/>
			)
		}
	];

	if (isLoading) {
		return <Spinner />;
	}

	if (isError) {
		return <h2>Internal server error</h2>;
	}

	const formInitialValues = {
		organization_id: organization?.id,
		sector_id: file.data?.workstations?.line?.sector?.id,
		line_id: file.data?.workstations?.line?.id,
		workstation_id: file.data?.workstations?.id,
		...report?.data
	};

	return (
		<RSIProvider>
			<Row justify="center" align="middle">
				<Col sm={24} style={{ marginBottom: '16px', textAlign: 'center' }}>
					<h2>{I18n.get('Revised Strain Index (Moore and Garg)')}</h2>
				</Col>
				<Col xxl={20} xl={22} sm={24}>
					<Form form={form} layout="horizontal" initialValues={formInitialValues}>
						<Row justify="center" align="middle" style={{ marginBottom: '20px' }}>
							<Col sm={20} xxl={14}>
								<HeaderStep current={currentStep} steps={steps} />
							</Col>
						</Row>
						<Row justify="center">
							<Col sm={24} style={{ minHeight: '350px' }}>
								{steps[currentStep].content}
							</Col>
						</Row>
						<Row justify="center">
							<Col sm={24}>
								<Footer
									steps={steps}
									onPrevious={onPrevious}
									onNext={onNext}
									current={currentStep}
									onFinish={onFinish}
									onClose={onClose}
									isFetching={isLoading}
								/>
							</Col>
						</Row>
					</Form>
				</Col>
			</Row>
		</RSIProvider>
	);
}
