import { useMutation, useQuery } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';

import {
	SeraResultDTO,
	SeraSummaryRequest,
	PayloadDownloadPDF,
	URLDownloadDTO,
	SeraReviewSelectorDTO,
	SeraReviewRequest
} from './types';
import { UpdateSeraSummaryRequest } from '../../hooks/types/request';
import { SeraResultService } from './services';

const seraResultService = new SeraResultService();

export function useSeraResults({ organization_id, company_id, sera_summary_id, review }: SeraSummaryRequest) {
	const enabled = !!organization_id && !!company_id && !!sera_summary_id;
	const results = useQuery(
		['sera_results', [organization_id, company_id, sera_summary_id, review]],
		() => seraResultService.getSeraResults({ organization_id, company_id, sera_summary_id, review }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...results,
		data: results.data ?? ({} as SeraResultDTO)
	};
}

export function useSeraReviews({ organization_id, company_id, sera_summary_id }: SeraReviewRequest) {
	const enabled = !!organization_id && !!company_id && !!sera_summary_id;
	const results = useQuery(
		['sera_reviews', [organization_id, company_id, sera_summary_id]],
		() => seraResultService.getSeraReviews({ organization_id, company_id, sera_summary_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...results,
		data: results.data ?? ([] as SeraReviewSelectorDTO[])
	};
}

export function useDownloadPDF() {
	return useMutation((payload: PayloadDownloadPDF) => seraResultService.downloadPDF(payload), {
		onSuccess: (data: URLDownloadDTO) => {
			let archive = document.createElement('a');
			archive.target = '_blank';
			archive.href = data.url;
			archive.click();
			archive.remove();
		},
		onError: () => {
			message.error(I18n.get('Failed to generate the report'));
		}
	});
}

export function useComments() {
	return useMutation((payload: UpdateSeraSummaryRequest) => seraResultService.saveComments(payload), {
		onSuccess: () => {
			message.success(I18n.get('Comment updated successfully'));
		},
		onError: () => {
			message.error(I18n.get('Fail to save the comments'));
		}
	});
}
