import styled from 'styled-components';
import { Col, Button, Input } from 'antd';

export const Table = styled(Col)<{ $backGroundColor?: string }>`
	.ant-table {
		border-radius: 0 0 10px 10px !important;
		.ant-table-thead
			> tr
			> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
				[colspan]
			)::before {
			background-color: #ffffff;
		}
		.ant-table-wrapper {
			display: flex;
			justify-content: center;
			.ant-spin-nested-loading {
				width: 25rem;
			}
		}
		.ant-table-tbody {
			background-color: ${({ $backGroundColor }) => $backGroundColor ?? 'unset'};
			tr {
				background-color: #ffffff;
			}
			.file-columns {
				text-align: start !important;
			}
			.ant-table-selection-column {
				padding-left: 25px;
			}
			.ant-table-row:last-child {
				.ant-table-cell:first-child {
					border-radius: 0 0 0 10px;
				}
				.ant-table-cell:last-child {
					border-radius: 0 0 10px 0;
				}
			}
			td {
				border-top: 1px solid transparent;
			}
			td:first-child {
				border-left: 1px solid transparent;
			}
			td:last-child {
				border-right: 1px solid transparent;
			}
			.ant-table-row-selected {
				td:first-child {
					border-left: 1px solid #2f54eb;
					border-top-left-radius: 7px !important;
					border-bottom-left-radius: 7px;
				}
				td:last-child {
					border-right: 1px solid #2f54eb;
					border-top-right-radius: 7px !important;
					border-bottom-right-radius: 7px;
				}
				td {
					background-color: #2f54eb1a !important;
					border-top: 1px solid #2f54eb;
					border-bottom: 1px solid #2f54eb;
				}
			}
		}
		.ant-table-thead {
			th {
				background-color: #2f54eb;
				color: #fff;
				.ant-typography {
					color: #fff;
				}
			}
			.file-columns {
				padding-right: 60px;
			}
			.ant-table-cell::before {
				background-color: #f8f8f8;
			}
			.ant-table-cell:first-child {
				border-radius: 10px 0 0 0;
			}
			.ant-table-cell:last-child {
				border-radius: 0 10px 0 0;
			}
		}
	}
`;

export const CustomButton = styled(Button)<{ $borderRadius?: string }>`
	width: 100%;
	border-radius: ${({ $borderRadius }) => $borderRadius ?? '7px'};

	svg:hover {
		fill: #2f54eb;
	}
`;

export const CustomInput = styled(Input)<{ $borderRadius?: string }>`
	height: 40px;
	font-size: 15px;
	border-radius: ${({ $borderRadius }) => $borderRadius ?? '7px'};

	.ant-input {
		margin-left: 7px;
	}
`;
