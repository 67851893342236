import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col } from 'antd';

import { CustomFormItem, CustomRow } from './styles';

interface RiskCategoryProps {
	riskCategory: string;
}

export const RiskCategory: React.FC<RiskCategoryProps> = ({ riskCategory }) => {
	return (
		<Col xs={24} sm={12} lg={24}>
			<CustomFormItem label={I18n.get('Category')} labelCol={{ span: 24 }}>
				<CustomRow>{I18n.get(riskCategory)}</CustomRow>
			</CustomFormItem>
		</Col>
	);
};
