import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Form, Select, SelectProps } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { UserDTO } from '@/types/AuthUser';

const { useFormInstance } = Form;

interface UserSelectProps {
	users: UserDTO[];
	userId: string;
	loading: boolean;
	disabled: boolean;
	handleSelectUser?: (id: string) => void;
	handleClearUser?: () => void;
	fieldName: NamePath;
}

type OptionType = { label: string; value: string } | undefined;

export const UserSelect: React.FC<UserSelectProps & SelectProps> = ({
	users,
	userId,
	loading,
	disabled,
	handleSelectUser,
	handleClearUser,
	fieldName,
	...props
}) => {
	const { validateFields } = useFormInstance();

	async function handleChange(id: string): Promise<void> {
		if (handleSelectUser) {
			handleSelectUser(id);
			await validateFields([fieldName]);
		}
	}

	function handleClear(): void {
		if (handleClearUser) {
			handleClearUser();
		}
	}

	function filterOption(option: OptionType, input: string): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	return (
		<Select
			{...props}
			showSearch
			allowClear
			value={userId}
			loading={loading}
			disabled={disabled}
			onClear={handleClear}
			onChange={handleChange}
			placeholder={I18n.get('User')}
			filterOption={(input, option: any) => filterOption(option, input)}
			options={users?.map(({ id, name }) => ({ label: name, value: id }))}
		/>
	);
};
