import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col } from 'antd';

import { CustomLabel } from '@/components/CustomLabel';
import { CustomFormItem, CustomRow } from './styles';
import { CustomIcon } from './CustomIcon';

interface ExposureProps {
	exposure: string;
}

export const Exposure: React.FC<ExposureProps> = ({ exposure }) => {
	return (
		<Col xs={24} sm={12} lg={24}>
			<CustomFormItem
				label={
					<CustomLabel
						icon={<CustomIcon title="How many times is the operator exposed to risk in the work cycle?" />}
						title="Exposure"
					/>
				}
				labelCol={{ span: 24 }}
			>
				<CustomRow>{I18n.get(exposure)}</CustomRow>
			</CustomFormItem>
		</Col>
	);
};
