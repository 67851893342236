import React from 'react';
import { NamePath } from 'antd/lib/form/interface';
import { I18n } from '@aws-amplify/core';
import { Select, Form } from 'antd';

import { ExposureDTO } from '@/components/ui/Inputs/types/response/exposure';

const { useFormInstance } = Form;

type ExposureSelectProps = {
	loading: boolean;
	disabled: boolean;
	fieldName: NamePath;
	allowCreation?: boolean;
	exposureId?: string;
	handleClearExposure?: () => void;
	exposures: ExposureDTO[] | undefined;
	handleSelectExposure?: (id: string) => void;
};

type OptionType = { label: string; value: string } | undefined;

export function ExposureSelect({
	loading,
	disabled,
	fieldName,
	exposures,
	exposureId,
	handleClearExposure,
	handleSelectExposure
}: Readonly<ExposureSelectProps>) {
	const { validateFields } = useFormInstance();

	async function handleChange(id: string) {
		if (handleSelectExposure) {
			handleSelectExposure(id);
			await validateFields([fieldName]);
		}
	}

	function handleClear() {
		if (handleClearExposure) {
			handleClearExposure();
		}
	}

	function filterOption(option: OptionType, input: string): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	return (
		<Select
			showSearch
			allowClear
			loading={loading}
			disabled={disabled}
			onClear={handleClear}
			onChange={handleChange}
			value={exposureId}
			placeholder={I18n.get('Exposure')}
			filterOption={(input, option) => filterOption(option, input)}
			options={exposures?.map(({ id, description, score }) => ({
				label: I18n.get(`[${score}] ${description}`),
				value: id
			}))}
		/>
	);
}
