import React, { useMemo } from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Divider, Form } from 'antd';

import RiskResult from './RiskResult';
import { InfoTooltip } from './InfoTooltip';
import { Text } from '@/components/Typography';
import { AdditionalItems } from './AdditionalItems';
import { useStepKeysContext } from '../DefaultSelects/context';
import {
	AdditionalItem,
	LastStepKeyResultResponse,
	StepKeyResults,
	SubStepKeyResult
} from '@/hooks/useGetLastStepKeyResult';
import { Container, Topic } from './styles';

const tooltipsMessages = {
	severity: 'How severe could the injury be?',
	vulnerability: 'What is the probability of an incident happening?',
	exposure: 'How many times is the operator exposed to risk in the work cycle?'
};

const { useWatch } = Form;

const getResult = (previousValue?: LastStepKeyResultResponse, subStepKeyId?: string) => {
	if (!previousValue) {
		return {
			hasSubStepKeysResults: false,
			hasStepKeyResults: false,
			results: {} as StepKeyResults
		};
	}

	const hasSubStepKeysResults = previousValue?.sub_step_keys_results?.some((s) => Boolean(s.result));
	const hasStepKeyResults = !!previousValue?.step_key_results?.result;

	if (hasSubStepKeysResults) {
		return {
			hasSubStepKeysResults,
			hasStepKeyResults,
			results: previousValue?.sub_step_keys_results?.find(
				(f) => f?.sub_step_key?.id === String(subStepKeyId)
			) as SubStepKeyResult
		};
	}

	return {
		hasSubStepKeysResults,
		hasStepKeyResults,
		results: previousValue.step_key_results
	};
};

type GetResult = ReturnType<typeof getResult>;

type GenericRisk = {
	description: string;
	score: number;
};

const renderRisk = (risk?: GenericRisk | null) => {
	if (!risk) {
		return 'N/A';
	}

	return (
		<>
			<strong>({risk.score})</strong>
			{I18n.get(risk.description)}
		</>
	);
};

export const PreviousValues = ({ negative }: { negative?: boolean }) => {
	const { previousValue, showHistory, subStepKeySeleceted, formFieldName } = useStepKeysContext();
	const { results, hasSubStepKeysResults, hasStepKeyResults } = useMemo<GetResult>(
		() => getResult(previousValue, subStepKeySeleceted),
		[previousValue, subStepKeySeleceted]
	);
	const isOpen = useWatch(formFieldName);

	if ((!hasStepKeyResults && !hasSubStepKeysResults) || !isOpen || !showHistory || !results.result) {
		return null;
	}

	const { risk_category, risk_damage, risk_description, exposure, vulnerability, severity, result, score } = results;

	return (
		<Container negative={negative}>
			<Row justify="center">
				<Col sm={24} xxl={22}>
					<Row justify="center">
						<Col sm={24} xxl={22}>
							<Row justify={'space-between'}>
								<Col span={9}>
									<Topic>
										<Text className="title">Category</Text>
										<Text className="value">{risk_category?.description || 'N/A'}</Text>
									</Topic>
									<Topic>
										<Text className="title">Risk description</Text>
										<Text className="value">{risk_description?.description || 'N/A'}</Text>
									</Topic>
									<Topic>
										<Text className="title">Damage</Text>
										<Text className="value">{risk_damage?.description || 'N/A'}</Text>
									</Topic>
								</Col>
								<Col>
									<Divider
										type="vertical"
										style={{
											height: '100%'
										}}
									/>
								</Col>
								<Col span={9} className="mb-3">
									<Topic>
										<div className="d-flex">
											<span>
												<Text className="title">Exposure</Text>
											</span>
											<span>
												<InfoTooltip text={tooltipsMessages.exposure} />
											</span>
										</div>
										<Text className="value">{renderRisk(exposure)}</Text>
									</Topic>
									<Topic>
										<div className="d-flex">
											<span>
												<Text className="title">Probability</Text>
											</span>
											<span>
												<InfoTooltip text={tooltipsMessages.vulnerability} />
											</span>
										</div>
										<Text className="value">{renderRisk(vulnerability)}</Text>
									</Topic>
									<Topic>
										<div className="d-flex">
											<span>
												<Text className="title">Severity</Text>
											</span>
											<span>
												<InfoTooltip text={tooltipsMessages.severity} />
											</span>
										</div>
										<Text className="value">{renderRisk(severity)}</Text>
									</Topic>
								</Col>
								<Col>
									<RiskResult result={result as number} score={score as number} />
								</Col>
							</Row>
							{Boolean(previousValue?.step_key_results?.additional_items?.length) && (
								<Row>
									<Col span={24} className="mb-3">
										<AdditionalItems
											items={
												previousValue?.step_key_results?.additional_items as AdditionalItem[]
											}
										/>
									</Col>
								</Row>
							)}
							<Row justify={'end'} className="version">
								<Col>
									<Text className="bold">Version</Text>
									<Text className="bold">:</Text>
									<Text>
										{previousValue?.custom_report_review?.name} (
										{moment(previousValue?.custom_report_review?.created_at).format('L')})
									</Text>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);
};
