import Api from '@/services/api';
import moment from 'moment';
import {
	GetOrganizationDTO,
	OrganizationDTO,
	PEAQueryParams,
	CompanyDTO,
	PEADataDTO,
	PEAListDTO,
	SectorDTO
} from './types';

export class MergePeaService {
	public async getOrganizations(params: GetOrganizationDTO) {
		const url = '/organization/search';
		const { data } = await Api.get<OrganizationDTO[]>(url, { params });
		return data;
	}

	public async getPEAList(queryParams: PEAQueryParams, page = 0, limit = 10) {
		const baseUrl = `/ergonomic-tool/preliminary-analysis/list?`;

		const url = this.setUrl(baseUrl, queryParams, page, limit);

		const { data } = await Api.get<{ total: number; result: PEAListDTO[] }>(url);
		return data;
	}

	public async getSectors(organizationId: string, companyId: string) {
		const url = `/sector/${organizationId}/${companyId}`;
		const { data } = await Api.get<SectorDTO[]>(url);
		return data;
	}

	public async getCompanies(organizationId: string) {
		const url = `/company/search/${organizationId}?name=&limit=${30}`;
		const { data } = await Api.get<CompanyDTO[]>(url);
		return data;
	}

	public async createEWA(body: PEADataDTO) {
		const { data } = await Api.post<{ url: string }>('/super-pea', body);
		return data;
	}

	private setUrl(baseUrl: string, queryParams: PEAQueryParams, page: number, limit: number) {
		const { collectionDate, companyId, organizationId, sectorId, workstation, analyst, fileName, createdAt } =
			queryParams;

		let params = `organization_id=${organizationId}&offset=${page}&limit=${limit}`;

		if (companyId) {
			params = `${params}&company_id=${companyId}`;
		}

		if (sectorId) {
			params = `${params}&sector_id=${sectorId}`;
		}

		if (workstation) {
			params = `${params}&workstation=${workstation}`;
		}

		if (analyst) {
			params = `${params}&analyst=${analyst}`;
		}

		if (fileName) {
			params = `${params}&file_name=${fileName}`;
		}

		if (collectionDate) {
			const [start, end] = collectionDate;
			const start_date = moment(start).format();
			const end_date = moment(end).format();
			params = `${params}&start_date=${start_date}&end_date=${end_date}`;
		}

		if (createdAt) {
			const [start, end] = createdAt;
			const start_date = moment(start).format();
			const end_date = moment(end).format();
			params = `${params}&created_start_date=${start_date}&created_end_date=${end_date}`;
		}

		return baseUrl + params;
	}
}
