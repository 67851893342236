import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Select } from 'antd';

import { Less } from './Less';
import { Greater } from './Greater';

const { useFormInstance, useWatch } = Form;

export const RPN: React.FC = () => {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();
	const type_rpn = useWatch(['type_rpn'], form);

	function handleClear() {
		setFieldValue(['type_rpn'], undefined);
		setFieldValue(['min_rpn'], undefined);
		setFieldValue(['max_rpn'], undefined);
	}

	const options = [
		{
			value: 'gt',
			label: I18n.get('Greater than')
		},
		{
			value: 'gte',
			label: I18n.get('Greater than or equal')
		},
		{
			value: 'lt',
			label: I18n.get('Less than')
		},
		{
			value: 'lte',
			label: I18n.get('Less than or equal')
		},
		{
			value: 'between',
			label: I18n.get('Between')
		}
	];

	const inputMapper: { [key: string]: JSX.Element } = {
		gt: <Greater />,
		gte: <Greater />,
		lt: <Less />,
		lte: <Less />,
		between: (
			<Row gutter={[32, 6]}>
				<Greater />
				<Less />
			</Row>
		)
	};

	return (
		<Col xs={24}>
			<Form.Item
				name={['type_rpn']}
				labelCol={{ span: 24 }}
				label={I18n.get('Worst RPN')}
				style={{ marginBottom: '1rem' }}
			>
				<Select
					allowClear
					value={type_rpn}
					options={options}
					onClear={handleClear}
					placeholder={I18n.get('RPN')}
				></Select>
			</Form.Item>
			{inputMapper[type_rpn]}
		</Col>
	);
};
