import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { useApplicationContext } from '@/context/v1/Application/context';
import { useLines } from '@/components/views/PreliminaryAnalysis/hooks';
import { LineSelect } from '@/components/ui/Inputs/LineSelect';

const { useFormInstance, useWatch } = Form;

export const Lines = () => {
	const form = useFormInstance();
	const { company, organization } = useApplicationContext();

	const lineId = useWatch(['file', 'line_id'], form);
	const sectorId = useWatch(['file', 'sector_id'], form);

	const { data: lines, isLoading, isFetching, isError } = useLines(organization?.id, company?.id, sectorId);

	function handleLineSelect(id: string) {
		form.setFieldValue(['file', 'line_id'], id);
		form.setFieldValue(['file', 'workstation_id'], undefined);
	}

	function handleClearLines() {
		form.setFieldValue(['file', 'line_id'], undefined);
		form.setFieldValue(['file', 'workstation_id'], undefined);
	}

	return (
		<Col span={12}>
			<Form.Item
				name={['file', 'line_id']}
				label={I18n.get('Line')}
				rules={[
					{
						required: true,
						message: I18n.get('Select the line')
					}
				]}
			>
				<LineSelect
					lines={lines}
					lineId={lineId}
					sectorId={sectorId}
					loading={isFetching}
					fieldName={['file', 'line_id']}
					handleSelectLine={handleLineSelect}
					handleClearLines={handleClearLines}
					disabled={isLoading || isFetching || isError}
				/>
			</Form.Item>
		</Col>
	);
};
