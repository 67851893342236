import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Collapse, Table } from 'antd';
import { type ColumnsType } from 'antd/es/table';

import type { Niosh } from '@/hooks';
import { NioshTableContainer, PanelCustom } from './styles';

interface NioshProps {
	data: Niosh;
}

export interface DataType {
	key: React.Key;
	risk: string;
	lifting_index: string;
	reference_weight: string;
	conclusion: string;
	recommended_weight_limit: string;
}

const NioshRiskLevel: { [key: string]: string } = {
	VERY_LOW: '#2cc852',
	LOW: '#FFDE31',
	MODERATE: '#F78A38',
	HIGH: '#E74150',
	VERY_HIGH: '#9B54E2'
};

const riskLabels: { [key: string]: string } = {
	VERY_LOW: 'Very low',
	LOW: 'Low',
	MODERATE: 'Moderate',
	HIGH: 'High',
	VERY_HIGH: 'Very high'
};

export const NioshResults: React.FC<NioshProps> = ({ data }) => {
	const { lifting_index, recommended_weight_limit, reference_weight, risk, conclusion, id, risk_description } = data;

	const columns: ColumnsType<DataType> = [
		{
			title: `${risk_description.map((text) => I18n.get(text))}`,
			dataIndex: `${risk_description.map((text) => I18n.get(text))}`,
			key: 'conclusion_texts',
			align: 'left',
			children: [
				{
					title: I18n.get('Weight Limit recommended (WLR)'),
					dataIndex: 'recommended_weight_limit',
					key: 'recommended_weight_limit',
					width: '25%'
				},
				{
					title: I18n.get('Lifting index (LI)'),
					dataIndex: 'lifting_index',
					key: 'lifting_index',
					width: '20%'
				},
				{
					title: I18n.get('Reference weight'),
					dataIndex: 'reference_weight',
					key: 'reference_weight',
					width: '20%'
				},
				{
					title: `${I18n.get('Risk')} ${I18n.get(riskLabels[risk])}`,
					dataIndex: 'conclusion',
					key: 'conclusion'
				}
			]
		}
	];

	const dataColumn: DataType[] = [
		{
			risk,
			key: id,
			lifting_index,
			recommended_weight_limit,
			conclusion: `${I18n.get(conclusion)}` ?? '',
			reference_weight: String(reference_weight)
		}
	];

	return (
		<Collapse ghost>
			<PanelCustom header={I18n.get('Cargo Handling (NIOSH)')} key={'niosh'}>
				<Col span={24}>
					<NioshTableContainer $riskColor={NioshRiskLevel[risk]}>
						<Table columns={columns} dataSource={dataColumn} pagination={false} />
					</NioshTableContainer>
				</Col>
			</PanelCustom>
		</Collapse>
	);
};
