import React from 'react';

import { TreemapProvider } from './context';
import { Treemap } from './Treemap';

export const Mosaic = () => {
	return (
		<TreemapProvider>
			<Treemap />
		</TreemapProvider>
	);
};
