import styled from 'styled-components';
import { Tooltip } from 'antd';

interface IDescriptionProps {
	isactive: boolean;
}

export const Description = styled.span<IDescriptionProps>`
	height: 0;
	font-size: small;
	text-align: center;
	width: fit-content;
	color: rgba(0, 0, 0, 0.45);
`;

export const CustomTooltip = styled(Tooltip)`
	margin-top: 10px;

	.ant-tooltip {
		left: 5px !important;
		top: -60px !important;
	}

	.ant-tooltip-inner {
		width: 17rem;
		color: #262626;
		background-color: #fffa;
		border: 1px solid #262626;
		backdrop-filter: blur(4px);
		border-radius: 10px 10px 10px 0;
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	}
`;
