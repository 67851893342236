import React from 'react';
import styled from 'styled-components';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Radio, InputNumber, Typography } from 'antd';

const RadioButton = styled(Radio.Button)`
	text-align: center;
	width: ${({ length }) => 100 / length}%;
`;

const coupling = [
	{
		label: 'Good',
		value: 1
	},
	{
		label: 'Fair',
		value: 2
	},
	{
		label: 'Poor',
		value: 3
	},
	{
		label: 'Unacceptable',
		value: 4
	}
];

const force = [
	{
		label: 'Load less than 5kg (intermittent)',
		value: 1
	},
	{
		label: 'Load from 5kg to 20kg (intermittent)',
		value: 2
	},
	{
		label: 'Load from 5kg to 20kg (static or repetitive)',
		value: 3
	},
	{
		label: 'Bigger load 20kg of repetitive load or blows',
		value: 4
	}
];

const radioStyle = {
	height: '30px',
	display: 'block',
	lineHeight: '30px'
};

export const Parameters = () => (
	<Row type="flex" justify="center" align="middle" gutter={[10, 15]}>
		<Col xs={24} sm={24} md={24} lg={14}>
			<Col xs={24} sm={24} md={24}>
				<Typography.Title level={5}>
					{I18n.get('Repetitions per minute')}:
				</Typography.Title>
				<Form.Item
					name="repetition"
					rules={[
						{
							required: true,
							message: I18n.get('Select the number of repetitions')
						}
					]}
				>
					<InputNumber min={1} max={100} style={{ width: '60px' }} />
				</Form.Item>
			</Col>
			<Col xs={24} sm={24} md={24}>
				<Typography.Title level={5}>{I18n.get('Coupling')}:</Typography.Title>
				<Form.Item
					name="coupling"
					rules={[
						{
							required: true,
							message: I18n.get('Select the coupling quality')
						}
					]}
				>
					<Radio.Group buttonStyle="solid" style={{ width: '100%' }}>
						{coupling.map((item, index) => (
							<RadioButton
								key={index}
								value={item.value}
								length={coupling.length}
							>
								{I18n.get(item.label)}
							</RadioButton>
						))}
					</Radio.Group>
				</Form.Item>
			</Col>
			<Col xs={24} sm={24} md={24}>
				<Typography.Title level={5}>{I18n.get('Force')}:</Typography.Title>
				<Form.Item
					name="force"
					rules={[
						{
							required: true,
							message: I18n.get('Select the load')
						}
					]}
				>
					<Radio.Group buttonStyle="solid" style={{ width: '100%' }}>
						{force.map((item, index) => (
							<Radio key={index} value={item.value} style={radioStyle}>
								{I18n.get(item.label)}
							</Radio>
						))}
					</Radio.Group>
				</Form.Item>
			</Col>
		</Col>
	</Row>
);
