import React, { useEffect, useState } from 'react';
import { Col, Form, Row, notification } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';

import { useApplicationContext } from '@/context/v1/Application/context';
import { Text } from '@/components/Typography';
import { Spinner } from '@/components/Spinner';

import {
	useCreateSeraResult,
	useCreateSeraSummary,
	useCustomReport,
	useSeraFiles,
	useSeraSummary,
	useSetTasksFiles,
	useUpdateSeraResult,
	useUpdateSeraSummary
} from './hooks/hooks';
import { CreateSeraSummaryRequest, UpdateSeraSummaryRequest } from './hooks/types/request';
import { SeraStepsRouteParams, useSeraStepsContext } from './context';
import { GeneralInformationsForm, HeaderRow } from './styles';
import { FilesSelection } from './FilesSelection';
import { Informations } from './Informations';
import { SeraForm } from './SeraForm';
import { Results } from './Results';
import { Header } from './Header';
import { Footer } from './Footer';

const { useForm } = Form;

const REPORT_NAME = 'sera';
const REPORT_TYPE = 'SERA';

type FileIdInitialValue = {
	[key: string]: {
		task_id: string | undefined;
	};
};

type CreateOrUpdate = {
	cycle_id: string;
	report_name: string;
	company_id: string;
	evaluator_id: string;
};

export const SeraSteps: React.FC = () => {
	const [form] = useForm();
	const history = useHistory();
	const { organization, company } = useApplicationContext();
	const { sera_summary_id } = useParams<SeraStepsRouteParams>();
	const {
		selectedFiles,
		filesTasks,
		selectedFilesId,
		showSeraResult,
		seraSummaryId,
		page,
		limit,
		fileName,
		seraSummary
	} = useSeraStepsContext();
	const {
		mapFilesTasks,
		filterSelectedFiles,
		handleSeraFilesChange,
		handleFilesTasksChange,
		handleSeraSummaryChange,
		handleSelectedFilesChange,
		handleSeraSummaryIdChange,
		handleShowSeraResultChange,
		handleSeraCommonInformationsChange
	} = useSeraStepsContext();

	const initialStep = showSeraResult ? 2 : 0;

	const [step, setStep] = useState(initialStep);

	const companyId = form.getFieldValue('company_id');

	const { data: customReport } = useCustomReport({
		organization_id: organization?.id,
		company_id: company?.id,
		reportName: REPORT_NAME
	});
	const {
		data: seraFiles,
		isFetching: fetchingSeraFiles,
		isError: errorFetchingSeraFiles
	} = useSeraFiles({
		company_id: companyId ?? company?.id,
		organization_id: organization?.id,
		custom_report_id: customReport?.id,
		sera_summary_id: sera_summary_id ?? seraSummary?.id,
		report_type: REPORT_TYPE,
		offset: page === 0 ? page : page - 1,
		limit,
		fileName
	});
	const { data: seraSummaryData, isFetching: gettingSeraSummary } = useSeraSummary({
		organization_id: organization?.id,
		company_id: company?.id,
		sera_summary_id: sera_summary_id ?? seraSummaryId
	});
	const { mutateAsync: createSeraResult, isLoading: creatingSeraResult } = useCreateSeraResult();
	const { mutateAsync: updateSeraResult, isLoading: updatingSeraResult } = useUpdateSeraResult();
	const { mutateAsync: createSummary, isLoading: creatingSummary } = useCreateSeraSummary();
	const { mutateAsync: updateSummary, isLoading: updatingSummary } = useUpdateSeraSummary();
	const { mutateAsync: tasksFiles, isLoading: updatingTasksFiles } = useSetTasksFiles();

	const mainSteps = [
		{
			component: <FilesSelection isLoading={fetchingSeraFiles} />
		},
		{
			component: <Informations />
		},
		{
			component: <SeraForm onPreviousClick={handlePreviousStep} />
		},
		{
			component: <Results />
		}
	];

	useEffect(() => {
		const mappedFilesIds =
			seraSummaryData?.review && seraSummaryData?.review[0]?.sera_report?.map((report) => report.file_id);
		const currentFilesIds = selectedFilesId?.length > 0 ? selectedFilesId : mappedFilesIds;
		if (currentFilesIds?.length > 0 && selectedFiles.length === 0) {
			const files = filterSelectedFiles(currentFilesIds, seraFiles);
			form.setFieldValue('files_list', currentFilesIds);
			handleSelectedFilesChange(files);
		}

		if (seraSummaryData?.show_sera_result) {
			handleShowSeraResultChange(true);
			setStep(mainSteps.length - 1);
		}
	}, [seraSummaryData, seraFiles]);

	useEffect(() => {
		if (seraFiles?.id) {
			handleSeraFilesChange(seraFiles);
		}
		if (seraSummaryData?.id) {
			handleSeraSummaryChange(seraSummaryData);
		}
		const { mappedFiles, hierarchy } = mapFilesInitialValues();
		if (mappedFiles) {
			form.setFieldValue('workstation_id', hierarchy?.workstation_id);
			form.setFieldValue('line_id', hierarchy?.line_id);
			form.setFieldValue('sector_id', hierarchy?.sector_id);
			handleFilesTasksChange(mappedFiles);
		}
	}, [step, seraFiles, selectedFiles]);

	const { fileIdsInitialValue, hierarchy } = mapFilesInitialValues();

	const initialValues = {
		...fileIdsInitialValue,
		...hierarchy,
		organization_id: organization?.name,
		company_id: company?.id,
		report_name: seraSummaryData?.report_name,
		cycle_id: seraSummaryData?.cycle_id,
		evaluator_id: seraSummaryData?.evaluator_id
	};

	function mapFilesInitialValues() {
		const mappedFiles = seraFiles?.files.rows.map((file) => ({
			id: file.id,
			task_id: file?.task_id,
			task_name: file?.task_name
		}));

		const fileIdsInitialValue = mappedFiles?.reduce((files: FileIdInitialValue, currentFile) => {
			const initialValue: FileIdInitialValue = {
				...files,
				[currentFile.id]: {
					task_id: currentFile.task_id
				}
			};
			return initialValue;
		}, {} as FileIdInitialValue);

		const hierarchy = selectedFiles
			.filter(({ workstations }) => workstations)
			.map((file) => ({
				sector_id: seraSummaryData?.sector_id ?? file.workstations?.line.sector.id,
				workstation_id: seraSummaryData?.workstation_id ?? file.workstations?.id,
				line_id: seraSummaryData?.line_id ?? file.workstations?.line.id
			}))[0];

		return { mappedFiles, fileIdsInitialValue, hierarchy };
	}

	function handleCloseReportClick(): void {
		history.push('/custom-reports/sera');
	}

	function handlePreviousStep(): void {
		setStep(step - 1);
	}

	async function handleNextStep() {
		try {
			const values = await form.validateFields();
			if (step === 0) {
				await updateFilesTask(values);
			}

			if (step === 1) {
				const { cycle_id, report_name, company_id, evaluator_id } = values;
				handleSeraCommonInformationsChange(values);
				return await createOrUpdateSera({ cycle_id, report_name, company_id, evaluator_id });
			}

			if (step === 2) {
				return await createOrUpdateReports(values);
			}

			setStep(step + 1);
		} catch (error: any) {
			const errorName = error.errorFields ? error.errorFields[0].name : '';
			form.scrollToField(errorName, { behavior: 'smooth', block: 'center', inline: 'center' });
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get("Some required steps wasn't filled"),
				duration: 5
			});
		}
	}

	async function createOrUpdateReports(values: any) {
		const seraReportData = mapSeraReportData(values);
		const body = {
			company_id: company?.id,
			organization_id: organization?.id,
			sera_summary_review_id: seraSummary.sera_summary_review_id,
			sera_report_data: seraReportData
		};
		if (showSeraResult) {
			const updated = await updateSeraResult(body);
			if (updated?.length > 0) {
				setStep(step + 1);
				return updated;
			}
		} else {
			const created = await createSeraResult(body);
			if (created?.length > 0) {
				setStep(step + 1);
				return created;
			}
		}
	}

	async function updateFilesTask(values: any) {
		const fileIds = selectedFiles?.map((file) => file.id);
		form.setFieldValue('files_list', fileIds);
		const mappedFilesTasks = mapFilesTasks(fileIds, values);
		const updatedFilesTasks = await tasksFiles({
			company_id: company?.id,
			organization_id: organization?.id,
			tasks_files: mappedFilesTasks
		});
		if (updatedFilesTasks) {
			setStep(step + 1);
		}
	}

	function mapSeraReportData(values: any) {
		const sera_report_data = [];
		for (const key in values) {
			if (Object.hasOwn(values[key], 'exposure_id')) {
				const taskRPN = form.getFieldValue([Number(key), 'taskRPN']);
				const id = form.getFieldValue([Number(key), 'sera_report_id']);

				const [file] = filesTasks.filter((file) => file.task_id === values[key]?.task_id);
				const mapped_fields = {
					...values[key],
					id,
					file_id: file.id,
					rpn: taskRPN?.rpn,
					evaluator_id: seraSummary.evaluator_id
				};
				sera_report_data.push(mapped_fields);
			}
		}
		return sera_report_data;
	}

	async function createOrUpdateSera(values: CreateOrUpdate) {
		if (!sera_summary_id && !seraSummaryId) {
			return await createSeraSummary(values);
		}
		return await updateSeraSummary(values);
	}

	async function createSeraSummary(values: CreateOrUpdate): Promise<void> {
		const files = selectedFiles.map(({ id }) => id);
		const sector_id = form.getFieldValue('sector_id');
		const workstation_id = form.getFieldValue('workstation_id');
		const body: CreateSeraSummaryRequest = {
			...values,
			files,
			organization_id: organization.id,
			sector_id,
			workstation_id
		};
		const createdSummary = await createSummary(body);
		handleSeraSummaryChange(createdSummary);
		if (createdSummary?.id) {
			handleSeraSummaryIdChange(createdSummary.id);
			updateURI(createdSummary.id);
			setStep(step + 1);
		}
	}

	async function updateSeraSummary(values: CreateOrUpdate): Promise<void> {
		const files = selectedFiles.map(({ id }) => id);
		const sector_id = form.getFieldValue('sector_id');
		const workstation_id = form.getFieldValue('workstation_id');

		const body: UpdateSeraSummaryRequest = {
			...values,
			files,
			id: sera_summary_id ?? seraSummaryId,
			organization_id: organization.id,
			sector_id,
			workstation_id
		};
		const updatedSummary = await updateSummary(body);
		handleSeraSummaryChange(updatedSummary);
		if (updatedSummary?.id) {
			handleSeraSummaryIdChange(updatedSummary.id);
			updateURI(updatedSummary.id);
			setStep(step + 1);
		}
	}

	function updateURI(id: string) {
		if (sera_summary_id || seraSummaryId) {
			return;
		}
		const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + `/${id}`;
		window.history.pushState({ path: newurl }, '', newurl);
	}

	function showSeraReportStep(): JSX.Element {
		const isLoading =
			gettingSeraSummary ||
			creatingSummary ||
			updatingSummary ||
			creatingSeraResult ||
			updatingSeraResult ||
			updatingTasksFiles;

		if (isLoading) {
			return (
				<Row justify="center" style={{ margin: '5rem' }}>
					<Col span={24}>
						<Spinner size={48} />
					</Col>
				</Row>
			);
		}

		if (errorFetchingSeraFiles) {
			return (
				<Row justify="center" style={{ margin: '5rem' }}>
					<Col span={24}>
						<Text>Oops... Something went wrong!</Text>
					</Col>
				</Row>
			);
		}

		return (
			<Col xs={24}>
				<Row justify="center">
					<GeneralInformationsForm
						autoComplete="off"
						name="general_informations"
						form={form}
						initialValues={initialValues}
					>
						{mainSteps[step].component}
					</GeneralInformationsForm>{' '}
				</Row>
			</Col>
		);
	}

	function isNextButtonDisabled(): boolean {
		return (step === 0 && selectedFiles.length === 0 && selectedFilesId.length === 0) || errorFetchingSeraFiles;
	}

	return (
		<Col xs={24}>
			<HeaderRow justify="center">
				<Header />
			</HeaderRow>
			<Row justify="center">{showSeraReportStep()}</Row>
			<Row justify="center" style={{ minWidth: '190px', marginTop: '1rem' }}>
				<Footer
					currentStep={step}
					loading={gettingSeraSummary}
					onNextClick={handleNextStep}
					totalSteps={mainSteps.length}
					disabled={isNextButtonDisabled()}
					onPreviousClick={handlePreviousStep}
					onCloseClick={handleCloseReportClick}
				/>
			</Row>
		</Col>
	);
};
