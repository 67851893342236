import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { useApplicationContext } from '@/context/v1/Application/context';
import { SectorSelect } from '@/components/ui/Inputs/SectorSelect';
import { useSectors } from '@/hooks/v1';
import { CustomFormItem } from './styles';

const { useFormInstance, useWatch } = Form;

export const Sectors: React.FC = () => {
	const form = useFormInstance();
	const { company, organization } = useApplicationContext();

	const companyId = useWatch('company_id', form);
	const sectorId = useWatch('sector_id', form);

	const {
		data: sectors,
		isLoading,
		isFetching,
		isError
	} = useSectors({
		organizationId: organization?.id,
		companyId: company?.id
	});

	function handleSectorSelect(id: string) {
		form.setFieldValue(['sector_id'], id);
		form.setFieldValue(['line_id'], undefined);
		form.setFieldValue(['workstation_id'], undefined);
	}

	function handleClearSector() {
		form.setFieldValue(['sector_id'], undefined);
		form.setFieldValue(['line_id'], undefined);
		form.setFieldValue(['workstation_id'], undefined);
	}

	return (
		<Col xs={24}>
			<CustomFormItem
				name={['sector_id']}
				label={I18n.get('Sector')}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: true,
						message: I18n.get('Select the sector')
					}
				]}
			>
				<SectorSelect
					size="large"
					sectors={sectors}
					sectorId={sectorId}
					loading={isFetching}
					fieldName={'sector_id'}
					handleClearSector={handleClearSector}
					handleSelectSector={handleSectorSelect}
					disabled={isLoading || isFetching || isError || !companyId}
				/>
			</CustomFormItem>
		</Col>
	);
};
