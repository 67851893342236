import { CompanyDTO, LineDTO, SectorDTO, WorkstationDTO } from '@/components/ui/Inputs/types/response';
import { BaseContext } from '@/components/ui/Inputs/types/request';

export enum HierarchyEnum {
	workstation = 'workstation',
	line = 'line',
	sector = 'sector',
	company = 'company'
}

export type FetchCompaniesRequest = BaseContext & {
	searching_organization_id: string;
};

export type FetchLinesRequest = BaseContext & {
	sector_id: string;
};

export type FetchWorkstationsRequest = BaseContext & {
	line_id: string;
};

export type CreateBaseRequest = BaseContext & {
	name: string;
	hierarchy_field: HierarchyEnum;
};

export type UpdateBaseRequest = BaseContext & {
	id?: string;
	name: string;
	hierarchy_field: HierarchyEnum;
};

export type DeleteBaseRequest = BaseContext & {
	id?: string;
	hierarchy_field: HierarchyEnum;
};

export type CompanyResponse = Pick<CompanyDTO, 'id' | 'name' | 'organization_id' | 'url_logo'>;

export type SectorResponse = Pick<SectorDTO, 'id' | 'name' | 'company_id'>;

export type LineResponse = Pick<LineDTO, 'id' | 'name' | 'sector_id'>;

export type WorkstationResponse = Pick<WorkstationDTO, 'id' | 'name' | 'line_id'>;

export type CompanyManagerResponse = (CompanyResponse | SectorResponse | LineResponse | WorkstationResponse)[];

export type SubmitValues = {
	name: string;
	line_id?: string;
	sector_id?: string;
	company_id?: string;
	organization_id: string;
	workstation_id?: string;
};

export type CreateBodyData = {
	name: string;
	sector_id?: string;
	company_id?: string;
	organization_id: string;
	workstation_id?: string;
};

export type ErrorField = {
	errors: string[];
};

export type ValidationError = {
	errorFields: ErrorField[];
};
