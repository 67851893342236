import { SearchOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import { Button, Col, Collapse, Form, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { SuperPEAQueryParams } from '../types';
import { Analyst } from './Analyst';
import { CollectionDateSelect } from './CollectionDateSelect';
import { CompaniesSelect } from './CompaniesSelect';
import { CreatedAtSelect } from './CreatedAtSelect';

import { OrganizationsSelect } from './OrganizationsSelect';
import { ReportName } from './ReportName';
import { SectorSelect } from './SectorSelect';
import { Workstation } from './Workstation';

type FilterProps = {
	onFilter: (filters: SuperPEAQueryParams) => void;
	onClearFilter: () => void;
};

export function Filter({ onFilter, onClearFilter }: FilterProps) {
	const [organizationId, setOrganizationId] = useState('');
	const [companyId, setCompanyId] = useState('');
	const [sectorId, setSectorId] = useState('');
	const [analyst, setAnalyst] = useState('');
	const [workstation, setWorkstation] = useState('');
	const [reportName, setReportName] = useState('');
	const [collectionDate, setCollectionDate] = useState([]);
	const [createdAt, setCreatedAt] = useState([]);
	const [form] = useForm();

	function handleFormValuesChange(changedValues: any) {
		const formFieldName = Object.keys(changedValues)[0];
		if (formFieldName === 'organization') {
			form.resetFields([
				'company',
				'sector',
				'collectionDate',
				'workstation',
				'analyst',
				'reportName',
				'createdAt'
			]);
			setOrganizationId(changedValues[formFieldName]);
		}
		if (formFieldName === 'company') {
			setCompanyId(changedValues[formFieldName]);
		}
		if (formFieldName === 'sector') {
			setSectorId(changedValues[formFieldName]);
		}
		if (formFieldName === 'workstation') {
			setWorkstation(changedValues[formFieldName]);
		}
		if (formFieldName === 'analyst') {
			setAnalyst(changedValues[formFieldName]);
		}
		if (formFieldName === 'reportName') {
			setReportName(changedValues[formFieldName]);
		}
		if (formFieldName === 'collectionDate') {
			setCollectionDate(changedValues[formFieldName]);
		}
		if (formFieldName === 'createdAt') {
			setCreatedAt(changedValues[formFieldName]);
		}
	}

	function resetStates() {
		form.resetFields();
		setOrganizationId('');
		setCompanyId('');
		setSectorId('');
		setAnalyst('');
		setWorkstation('');
		setReportName('');
		setCollectionDate([]);
		setCreatedAt([]);
	}

	function handleSetFilters() {
		const filters: SuperPEAQueryParams = {
			organizationId,
			companyId,
			sectorId,
			analyst,
			workstation,
			reportName,
			collectionDate,
			createdAt
		};
		onFilter(filters);
	}

	return (
		<Collapse>
			<Collapse.Panel header={I18n.get('Filter')} key="1">
				<Form
					onValuesChange={handleFormValuesChange}
					form={form}
					layout="vertical"
					style={{ minHeight: '500px' }}
				>
					<Row justify="end" style={{ marginBottom: '1rem' }}>
						<Col span={'auto'}>
							<Button
								type="link"
								onClick={() => {
									resetStates();
									onClearFilter();
								}}
							>
								{I18n.get('Clear')}
							</Button>
						</Col>
					</Row>
					<Row justify="center" gutter={[16, 16]}>
						<Col span={10}>
							<OrganizationsSelect />
						</Col>
						<Col span={10}>
							<CompaniesSelect organizationId={organizationId} />
						</Col>
					</Row>
					<Row justify="center" gutter={[16, 16]}>
						<Col span={10}>
							<SectorSelect organizationId={organizationId} companyId={companyId} />
						</Col>
						<Col span={10}>
							<Workstation organizationId={organizationId} companyId={companyId} />
						</Col>
					</Row>
					<Row justify="center" gutter={[16, 16]}>
						<Col span={10}>
							<Analyst organizationId={organizationId} companyId={companyId} />
						</Col>
						<Col span={10}>
							<ReportName organizationId={organizationId} companyId={companyId} />
						</Col>
					</Row>
					<Row justify="center" gutter={[16, 16]}>
						<Col span={20}>
							<CollectionDateSelect organizationId={organizationId} companyId={companyId} />
						</Col>
					</Row>
					<Row justify="center" gutter={[16, 16]}>
						<Col span={20}>
							<CreatedAtSelect organizationId={organizationId} companyId={companyId} />
						</Col>
					</Row>
					<Row justify="end">
						<Col span={'auto'}>
							<Button
								disabled={!organizationId || !companyId}
								onClick={handleSetFilters}
								icon={<SearchOutlined />}
								type="primary"
							>
								{I18n.get('Search')}
							</Button>
						</Col>
					</Row>
				</Form>
			</Collapse.Panel>
		</Collapse>
	);
}
