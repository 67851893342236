import { I18n } from '@aws-amplify/core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { message, notification } from 'antd';

import { JdsD86ListRequest, DeleteJdsD86Request, DownloadFileRequest, GetReviewsJdsD86Request, DownloadPdfRequest } from './types/request';
import { JdsD86ListDTO } from './types/response';
import { JdsD86Service } from './services';

const jdsD86Service = new JdsD86Service();

export function useJdsD86List({ custom_report_name, filter }: JdsD86ListRequest) {
	const enabled = !!custom_report_name;
	const summaryList = useQuery(
		['jds_d86_list', [custom_report_name, filter]],
		() => jdsD86Service.getJdsD86List({ custom_report_name, filter }),
		{
			enabled,
			retry: false,
		}
	);
	return {
		...summaryList,
		data: summaryList.data ?? ({} as JdsD86ListDTO)
	};
}

export const useDeleteReportJdsD86 = () => {
	const queryClient = useQueryClient();
	return useMutation(({ body }: { body: DeleteJdsD86Request }) => jdsD86Service.deleteReportJdsD86(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0].split('" ');
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['jds_d86_list']);
			queryClient.invalidateQueries(['jds_d86_reviews']);
			queryClient.invalidateQueries(['jds_d86_files'], { refetchType: 'inactive' });
			message.success(I18n.get('JDS D86 deleted successfully'));
		}
	});
};

export const useDeleteLastReviewJdsD86 = () => {
	const queryClient = useQueryClient();
	return useMutation(({ body }: { body: DeleteJdsD86Request }) => jdsD86Service.deleteLastReviewJdsD86(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0].split('" ');
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['jds_d86']);
			queryClient.invalidateQueries(['jds_d86_list']);
			queryClient.invalidateQueries(['jds_d86_reviews']);
			queryClient.invalidateQueries(['jds_d86_files'], { refetchType: 'inactive' });
			message.success(I18n.get('JDS D86 last revaluation deleted successfully'));
		}
	});
};

export const useGetReviewsJdsD86 = ({ id, open, company_id, organization_id }: GetReviewsJdsD86Request) => {
	const enabled = !!(id && open);
	const reviews = useQuery(
		['jds_d86_reviews', [id, company_id, organization_id]],
		() => jdsD86Service.getReviewsJdsD86({ id, company_id, organization_id }),
		{
			enabled
		}
	);
	return reviews;
};

export const useDownloadFile = () => {
	return useMutation((body: DownloadFileRequest) => jdsD86Service.downloadFile(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0].split('" ');
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: (data) => {
			const archive = document.createElement('a');
			archive.target = '_blank';
			archive.href = data;
			archive.click();
			archive.remove();
		}
	});
};

export const useDownloadPdf = () => {
	return useMutation((body: DownloadPdfRequest) => jdsD86Service.downloadPdf(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0].split('" ');
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: (data) => {
			const archive = document.createElement('a');
			archive.target = '_blank';
			archive.href = data;
			archive.click();
			archive.remove();
		}
	});
};
