import React from 'react';
import { useHistory } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';
import { Button, Col, Row } from 'antd';

import { useBeraReportStepsContext } from '../context';
import { useBeraJobStepsContext } from '../../context';
import { PreviousCol } from './styles';

interface FooterProps {
	totalSteps: number;
	currentStep: number;
	disabledReturn: boolean;
	onNextClick: () => void;
	onPreviousClick: () => void;
	onFinishBeraClick: () => void;
	onCreateBeraReportClick: () => Promise<void>;
}

export const Footer: React.FC<FooterProps> = ({
	totalSteps,
	currentStep,
	onNextClick,
	disabledReturn,
	onPreviousClick,
	onFinishBeraClick,
	onCreateBeraReportClick
}) => {
	const history = useHistory();
	const { handleNextFileClick } = useBeraReportStepsContext();
	const { isLastTab, isLoading } = useBeraReportStepsContext();
	const { handleShowBeraJobResultChange } = useBeraJobStepsContext();
	const { showBeraJobResult, allFinished } = useBeraJobStepsContext();

	function handleCloseBeraJobResult() {
		history.push('/custom-reports/bera');
	}

	function isLastStep() {
		return currentStep === totalSteps - 2;
	}

	function isResultStep() {
		return currentStep === totalSteps - 1;
	}

	function isNotFirstStep() {
		return currentStep > 0;
	}

	function showFinishButton() {
		return allFinished ? (
			<Col>
				<Button htmlType="submit" loading={isLoading} type="primary" onClick={onFinishBeraClick}>
					{I18n.get('Finish B.E.R.A.')}
				</Button>
			</Col>
		) : (
			showNextFileButton()
		);
	}

	function showNextFileButton() {
		return (
			<Col>
				<Button htmlType="submit" loading={isLoading} type="primary" onClick={handleNextFileClick}>
					{I18n.get('Next file')}
				</Button>
			</Col>
		);
	}

	function showPreviousButton() {
		return (
			<PreviousCol>
				<Button loading={isLoading} disabled={disabledReturn} type="default" onClick={onPreviousClick}>
					{I18n.get('Previous')}
				</Button>
			</PreviousCol>
		);
	}

	function showNextStepButton(method: () => void | Promise<void>) {
		return (
			<Col>
				<Button htmlType="submit" loading={isLoading} type="primary" onClick={method}>
					{I18n.get('Next')}
				</Button>
			</Col>
		);
	}

	if (showBeraJobResult) {
		return (
			<Col xs={24}>
				<Row justify="center">
					<PreviousCol>
						<Button loading={isLoading} type="default" onClick={() => handleShowBeraJobResultChange(false)}>
							{I18n.get('Previous')}
						</Button>
					</PreviousCol>
					<Col>
						<Button htmlType="submit" loading={isLoading} type="primary" onClick={handleCloseBeraJobResult}>
							{I18n.get('Close')}
						</Button>
					</Col>
				</Row>
			</Col>
		);
	}

	if (isLastTab && isResultStep()) {
		return (
			<Col xs={24}>
				<Row justify="center">
					{showPreviousButton()}
					{showFinishButton()}
				</Row>
			</Col>
		);
	}

	if (isResultStep()) {
		return (
			<Col xs={24}>
				<Row justify="center">
					{showPreviousButton()}
					{showNextFileButton()}
				</Row>
			</Col>
		);
	}

	if (isLastStep()) {
		return (
			<Col xs={24}>
				<Row justify="center">
					{showPreviousButton()}
					{showNextStepButton(onCreateBeraReportClick)}
				</Row>
			</Col>
		);
	}

	if (isNotFirstStep()) {
		return (
			<Col xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
				<Row justify="center" align="middle" gutter={[0, 8]}>
					{showPreviousButton()}
					{showNextStepButton(onNextClick)}
				</Row>
			</Col>
		);
	}

	return (
		<Col xs={24}>
			<Row justify="center">{showNextStepButton(onNextClick)}</Row>
		</Col>
	);
};
