import { Moment } from 'moment';

export enum StatusEnum {
	'active' = 'active',
	'inactive' = 'inactive',
	'all' = 'all'
}

export type CreatedAtDatePicker = [start: string, end: string];

export type StatusOptions = StatusEnum;

export type FilterOptions = {
	name?: string;
	status?: StatusOptions;
	created_end_date: string | undefined;
	created_start_date: string | undefined;
};

export type RadioStatus = {
	key: number;
	value: StatusEnum;
	label: string;
};

export interface InitialValues {
	filter: {
		name?: string;
		status?: StatusOptions;
		created_at?: [Moment, Moment];
	};
}
