import React, { createContext, ReactNode, useContext, useState } from 'react';
import { Text } from '@/components/Typography';
import { useLocation, useParams } from 'react-router-dom';
import { Form } from 'antd';

import { States, Context, Methods, LibertyMutualSubStepsRouteParams, FormSubStep } from './types';
import { InitialValuesMapper } from '../hooks/services/InitialValuesMapper';
import { useApplicationContext } from '@/context/v1/Application/context';
import { LibertyMutualReportDTO } from '../hooks/types/response';
import { useGetReport, useGetResult } from '../hooks';
import { LoadingSkeleton } from '../LoadingSkeleton';
import { Variables } from '../Variables';
import { Result } from '../Result';
import { Task } from '../Task';

type LibertyMutualSubStepsProviderProps = {
	children: ReactNode;
};

const { useForm } = Form;
const LibertyMutualSubStepsContext = createContext<Context>({} as Context);

const subSteps: FormSubStep[] = [
	{
		icon: null,
		title: 'Task',
		component: <Task />
	},
	{
		icon: null,
		title: 'Variables',
		component: <Variables />
	},
	{
		icon: null,
		title: 'Result',
		component: <Result />
	}
];

export function LibertyMutualSubStepsProvider({ children }: Readonly<LibertyMutualSubStepsProviderProps>) {
	const [form] = useForm();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const custom_report = !!searchParams.get('custom_report');
	const { organization, company } = useApplicationContext();
	const { file_id, report_id, liberty_mutual_report_id } = useParams<LibertyMutualSubStepsRouteParams>();

	const [updatedLibertyMutual, setUpdatedLibertyMutual] = useState<LibertyMutualReportDTO | undefined>(undefined);

	const {
		data: libertyMutual,
		isFetching: fetchingReport,
		isError: errorGettingReport
	} = useGetReport({
		organization_id: organization?.id,
		company_id: company?.id,
		report_id,
		file_id
	});
	const {
		data: libertyMutualResult,
		isInitialLoading: fetchingResult,
		isError: errorGettingResult
	} = useGetResult({
		organization_id: organization?.id,
		finished: isReportFinished(),
		company_id: company?.id,
		file_id,
		report_id
	});

	const initialValuesMapper = new InitialValuesMapper(libertyMutual);
	const initialValues = initialValuesMapper.mapInitialValues();

	const decimalSeparatorMapper = {
		metric: ',',
		imperial: '.'
	};

	function isReportFinished(): boolean | undefined {
		return (
			hasLiftLowerCarryResult(updatedLibertyMutual, libertyMutual) ||
			hasPushPullResult(updatedLibertyMutual, libertyMutual)
		);
	}

	function hasLiftLowerCarryResult(
		updatedLibertyMutual: LibertyMutualReportDTO | undefined,
		libertyMutual: LibertyMutualReportDTO | undefined
	) {
		return !!updatedLibertyMutual?.percentile_woman || !!libertyMutual?.percentile_woman;
	}

	function hasPushPullResult(
		updatedLibertyMutual: LibertyMutualReportDTO | undefined,
		libertyMutual: LibertyMutualReportDTO | undefined
	) {
		return !!updatedLibertyMutual?.percentile_woman_initial || !!libertyMutual?.percentile_woman_initial;
	}

	function handleUpdateReport(report: LibertyMutualReportDTO) {
		setUpdatedLibertyMutual(report);
	}

	const loading = fetchingReport || fetchingResult;

	if (errorGettingResult) {
		return <Text>Oops... Something went wrong!</Text>;
	}

	if (loading) {
		return <LoadingSkeleton loading={loading} />;
	}

	const states: States = {
		subSteps,
		file_id,
		report_id,
		custom_report,
		errorGettingReport,
		libertyMutualResult,
		gettingReport: loading,
		liberty_mutual_report_id,
		libertyMutual: updatedLibertyMutual ?? libertyMutual,
		decimalSeparator: decimalSeparatorMapper[libertyMutual?.system_of_units?.name]
	};

	const methods: Methods = {
		handleUpdateReport
	};

	const context: Context = {
		...states,
		...methods
	};

	return (
		<LibertyMutualSubStepsContext.Provider value={context}>
			<Form form={form} initialValues={initialValues}>
				{children}
			</Form>
		</LibertyMutualSubStepsContext.Provider>
	);
}

export function useLibertyMutualSubStepsContext() {
	const context = useContext(LibertyMutualSubStepsContext);
	return context;
}
