import { Button, Collapse } from 'antd';
import styled from 'styled-components';

export const ResultsContainer = styled.div`
	padding: 2rem;
	margin-right: 2rem;
	background-color: white;
	border-radius: 0.5rem;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
	height: 100%;
`;

export const KinebotLogoContainer = styled.div``;

export const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 1.2rem;
	font-weight: 400;

	#title {
		font-weight: 600;
	}
`;

export const ClientLogoContainer = styled.div`
	background: #d9d9d9;
	width: 100%;
	height: 100%;
	border-radius: 0.5rem;
	padding: 0.75rem 0.5rem;
	text-align: center;

	span {
		color: #8c8c8c;
		font-size: 1rem;
		font-weight: 600;
	}
`;

export const DownloadButton = styled(Button)`
	color: #2f54eb;
	border-radius: 0.25rem;
	border: 1px solid #2f54eb;
`;

export const Topic = styled.span`
	font-size: 0.9rem;
	font-weight: 600;
	margin-right: 0.5rem;

	@media (min-width: 1600px) {
		font-size: 0.9rem;
	}
`;

export const FormContainer = styled(Collapse)`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: #ffffff;

	.ant-collapse-item {
		width: 100%;
		border: none;
		margin-bottom: 1rem;
		border-radius: 0.25rem;

		.ant-collapse-header {
			padding: 0;
			display: flex;
			align-items: center;
			flex-direction: row;
		}

		.ant-collapse-expand-icon {
		}

		.ant-collapse-content-active {
			padding: 0 0 1rem 0;
		}

		:last-child {
			border: none;
			border-radius: 0.25rem;
			margin-bottom: 1rem;
		}
	}

	.ant-collapse-item-active {
		border: none;

		:last-child {
			border: none;
		}
	}
`;
