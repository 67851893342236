import React from 'react';
import { Col, Divider, Row } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Doughnut } from 'react-chartjs-2';

import hooks from '../hooks';
import { RiskData } from './RiskData';
import { DashboardCard } from '../styles';
import { SummaryChart } from './SummaryChart';
import { useDashboardContext } from '../context';
import { SubTitle, Summary, Title, Value } from './styles';
import { useApplicationContext } from '@/context/v1/Application/context';

const { useDashboardSummary } = hooks;

export const SummaryCard: React.FC = () => {
	const { queryParams } = useDashboardContext();
	const { organization, company } = useApplicationContext();
	const { isLoading, data, isError } = useDashboardSummary(organization.id, company.id, queryParams);

	return (
		<DashboardCard>
			<Title>{I18n.get('Risk Quotation')}</Title>
			{isLoading ? (
				<span>{I18n.get('Loading')}...</span>
			) : isError ? (
				<span>{I18n.get('Oops... Something went wrong!')}</span>
			) : (
				<Summary>
					<Row gutter={[64, 0]} style={{ height: '100%' }}>
						<Col span={12}>
							<Row gutter={[0, 4]}>
								<Col flex={1}>
									<SubTitle>{I18n.get('Total pending evaluations')}</SubTitle>
								</Col>
								<Col flex={1}>
									<Value>{data.total_files_not_analyzed || 0}</Value>
								</Col>
							</Row>
							<Divider type="horizontal" style={{ margin: '0.3rem 0rem' }} />
							<Row>
								<Col flex={1}>
									<SubTitle>
										<span>{I18n.get('Total evaluations consolidated')}</span>
									</SubTitle>
								</Col>
								<Col flex={1}>
									<Value>
										<span>{data.total_files_analyzed}</span>
									</Value>
								</Col>
							</Row>
							<Divider type="horizontal" style={{ margin: '0.3rem 0rem' }} />
							<RiskData
								scores={data.scores}
								total={data.total_files_not_analyzed + data.total_files_analyzed}
							/>
						</Col>
						<Col span={12}>
							{data.scores.some((score) => score.amount > 0) ? (
								<SummaryChart
									scores={data.scores
										.filter((item) => item.amount > 0)
										.map((item, index, array) => {
											const sum = array.reduce((acc, value) => acc + value.amount, 0);

											const arrayValues = array.map(
												({ amount: item }: any) => (item / Number(sum)) * 100
											);

											const diff =
												100 -
												arrayValues.reduce((acc, cur) => {
													return acc + Math.floor(cur);
												}, 0);

											const roundedPercentages = arrayValues
												.map((value: any) => ({
													original: value,
													floor: Math.floor(value),
													diff: Math.floor(value) - value
												}))
												.map((value, index) => (index < diff ? value.floor + 1 : value.floor));

											return {
												...item,
												amount: roundedPercentages[index]
											};
										})}
								/>
							) : (
								<Doughnut
									data={{
										labels: [I18n.get('No data available')],
										datasets: [
											{
												backgroundColor: ['#D3D3D3'],
												data: [100]
											}
										]
									}}
								/>
							)}
						</Col>
					</Row>
				</Summary>
			)}
		</DashboardCard>
	);
};
