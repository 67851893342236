import React, { useEffect } from 'react';
import { Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useGetRiskDamages } from '@/hooks';
import { CustomFormItem } from '../styles';
import { useStepKeysContext } from '../context';
import { RequiredLabel } from '../../../RequiredLabel';
import { useApplicationContext } from '@/context/v1/Application/context';
import { RiskDamageSelect } from '@/components/ui/Inputs/RiskDamageSelect';

const { useFormInstance, useWatch } = Form;

interface RiskDamagesProps {
	formFieldName: string[];
}

export const RiskDamages: React.FC<RiskDamagesProps> = ({ formFieldName }) => {
	const form = useFormInstance();
	const { stepKey } = useStepKeysContext();
	const { setFieldValue } = useFormInstance();
	const { organization, company } = useApplicationContext();

	const fieldName = [...formFieldName, 'risk_damage_id'];
	const severityFieldName = [...formFieldName, 'severity_id'];
	const descriptionFieldName = [...formFieldName, 'risk_description_id'];
	const riskDamageId = useWatch(fieldName, form);
	const severityId = useWatch(severityFieldName, form);
	const riskDescriptionId = useWatch(descriptionFieldName, form);

	const {
		data: riskDamages,
		isLoading: gettingRiskDamages,
		isFetching: fetchingRiskDamages
	} = useGetRiskDamages({
		company_id: company?.id,
		organization_id: organization?.id,
		risk_description_id: riskDescriptionId
	});

	useEffect(() => {
		const selectedRiskDamage = riskDamages.find((riskDamage) => riskDamage.id === riskDamageId);

		if (selectedRiskDamage?.severity_id && !severityId) {
			setFieldValue(severityFieldName, selectedRiskDamage?.severity_id);
		}
	}, [riskDamageId]);

	async function handleRiskDamageSelect(id: string) {
		setFieldValue(fieldName, id);
	}

	async function handleClearRiskDamage() {
		setFieldValue(fieldName, undefined);
		setFieldValue(severityFieldName, undefined);
	}

	return (
		<Col xs={24} sm={12} lg={24}>
			<CustomFormItem
				name={fieldName}
				label={<RequiredLabel title="Damage" isRequired={true} />}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: true,
						message: I18n.get('Select the damage')
					}
				]}
			>
				<RiskDamageSelect
					allowCreation={false}
					fieldName={fieldName}
					stepKeyId={stepKey.id}
					riskDamages={riskDamages}
					riskDamageId={riskDamageId}
					loading={fetchingRiskDamages}
					handleClearRiskDamage={handleClearRiskDamage}
					handleSelectRiskDamage={handleRiskDamageSelect}
					disabled={gettingRiskDamages || fetchingRiskDamages}
				/>
			</CustomFormItem>
		</Col>
	);
};
