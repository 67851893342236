import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Skeleton, message } from 'antd';
import { useParams } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import { CustomReportStepDTO, CustomReportStepKeyDTO } from '@/components/ui/Inputs/types/response/customReport';
import { FileForCustomReport } from '@/components/ui/Inputs/types/response/file';
import { useApplicationContext } from '@/context/v1/Application/context';

import {
	useBeraReport,
	useBeraSteps,
	useCreateBeraResult,
	useTasks,
	useUpdateBeraJobScores,
	useUpdateBeraResult
} from '../../hooks/hooks';
import {
	CreateBeraResultRequest,
	CreateBeraStepKeyResultRequest,
	UpdateBeraJobScoresRequest,
	UpdateBeraResultRequest
} from '../../hooks/types/request';
import { BeraResultService, TasksRpn } from '../../services/beraResultService';
import { useBeraJobStepsContext } from '../../context';
import { useBeraReportStepsContext } from '../context';
import { BeraJobResult } from '../../Results';
import { Informations } from './Informations';
import { BeraStepKey } from './BeraStepKey';
import { BeraReportForm } from './styles';
import { BeraResult } from './BeraResult';
import { StepsMenu } from './StepsMenu';
import { Footer } from './Footer';

const { useForm } = Form;

const beraResultService = new BeraResultService();

interface BeraStepProps {
	file: FileForCustomReport;
}

export type BeraStepUrlParams = {
	bera_job_summary_id: string;
};

export type StepsProps = {
	key: number;
	title: string;
	content: JSX.Element;
	step: CustomReportStepKeyDTO[];
};

type InitialValues = {
	step_key: boolean;
	stress_level_id: string;
	frequency_id: string;
	total_task_duration_id: string;
	job_element: string;
	task_rpn: number;
};

export type StepKeysInitialValues = {
	[key: string]: {
		empty_step: boolean;
		[key: string]: boolean | InitialValues;
	};
};

export const BeraStep: React.FC<BeraStepProps> = ({ file }) => {
	const [form] = useForm();
	const { bera_job_summary_id } = useParams<BeraStepUrlParams>();

	const { organization, company } = useApplicationContext();
	const { clickableResultStep, currentActiveTabIndex } = useBeraReportStepsContext();
	const { handleClickableResultStepChange, handleIsLoading } = useBeraReportStepsContext();
	const { handleFinishBeraReport, handleShowBeraJobResultChange } = useBeraJobStepsContext();
	const { beraJobSummary, beraJobSummaryId, showBeraJobResult, beraReportsStatus, beraCommonInformations } =
		useBeraJobStepsContext();

	const [currentStep, setCurrentStep] = useState<number>(0);
	const [beraReportId, setBeraReportId] = useState<string>('');
	const [anyCollapseChecked, setAnyCollapseChecked] = useState<boolean>(false);

	const { data: report, isFetching: gettingReport } = useBeraReport({
		organization_id: organization?.id,
		company_id: company.id,
		file_id: file.id,
		bera_job_summary_id: bera_job_summary_id ?? beraJobSummaryId
	});
	const {
		data: stepsList,
		isLoading: gettingBeraSteps,
		isError
	} = useBeraSteps({
		organization_id: organization?.id,
		company_id: company.id,
		custom_report_id: file.CustomReportsFiles.custom_report_id
	});
	const tasks = useTasks({
		organization_id: organization?.id,
		company_id: company.id,
		cycle_id: beraCommonInformations?.cycle_id ?? beraJobSummary?.cycle_id
	});

	const { mutateAsync: createBeraResult, isLoading: creatingBeraResult } = useCreateBeraResult();
	const { mutateAsync: updateBeraResult, isLoading: updatingBeraResult } = useUpdateBeraResult();
	const { mutateAsync: updateBeraJobScores, isLoading: updatingBeraJobScores } = useUpdateBeraJobScores();

	const loading =
		gettingBeraSteps || gettingReport || creatingBeraResult || updatingBeraResult || updatingBeraJobScores;

	useEffect(() => {
		handleIsLoading(loading);
	}, [loading]);

	useEffect(() => {
		if (report?.id && !beraReportId) {
			setBeraReportId(report?.id);
		}

		if (report?.consolidated && !showBeraJobResult && currentStep !== stepsList?.length - 1) {
			setCurrentStep(stepsList.length === 0 ? 0 : stepsList.length - 1);
		}
	}, [report, showBeraJobResult, currentStep, stepsList]);

	useEffect(() => {
		const clickableResult = beraReportsStatus?.find((report) => report.fileId === file.id)?.completed ?? false;
		handleClickableResultStepChange(clickableResult);
	}, [currentActiveTabIndex]);

	if (isError || tasks.isError) {
		return (
			<Row justify="center">
				<Col span={24}>
					<span>{I18n.get('Oops... Something went wrong!')}</span>
				</Col>
			</Row>
		);
	}

	if (gettingBeraSteps) {
		return (
			<Row justify="center">
				<Col span={20}>
					<Skeleton active />
				</Col>
			</Row>
		);
	}

	const initialValues = formatStepsInitialValues();

	const steps: StepsProps[] = stepsList.map((step, index) => {
		return {
			key: index,
			title: step.description,
			content: mapStepContent(step),
			step: step.step_key
		};
	});

	function formatStepsInitialValues() {
		const stepKeysInitialValues = formatStepKeysInitialValues();

		const initialValues = {
			[file.id]: {
				file_id: report?.file_id,
				consolidated: report?.consolidated,
				task_id: report?.task_id,
				task_time: report?.task_time,
				task_time_format: report?.task_time_format ?? 'seconds',
				has_known_injury: report?.has_known_injury,
				collection_date: moment(report?.collection_date),
				...stepKeysInitialValues
			}
		};

		return initialValues;
	}

	function formatStepKeysInitialValues() {
		return report?.step_key_results?.reduce((partialInitialValue, currentResult) => {
			const prevEmptyStep = partialInitialValue[currentResult.step_id]?.empty_step;
			const empty_step = !(currentResult?.stress_level_id || prevEmptyStep === false);
			return {
				...partialInitialValue,
				[currentResult.step_id]: {
					...partialInitialValue[currentResult.step_id],
					empty_step,
					[currentResult.step_key_id]: {
						step_key: !!currentResult?.stress_level_id,
						stress_level_id: currentResult?.stress_level_id,
						stress_level_score: currentResult?.stress_level_score,
						frequency_id: currentResult?.frequency_id,
						frequency_score: currentResult?.frequency_score,
						total_task_duration_id: currentResult?.total_task_duration_id,
						total_task_duration_score: currentResult?.total_task_duration_score,
						job_element: currentResult?.job_element,
						task_rpn: currentResult?.task_rpn
					}
				}
			};
		}, {} as StepKeysInitialValues);
	}

	function mapStepContent(step: CustomReportStepDTO): JSX.Element {
		if (step.name === 'informations') {
			return <Informations key={step.id} file={file} tasks={tasks} cycleId={beraCommonInformations.cycle_id} />;
		}

		if (step.name === 'result') {
			return <BeraResult key={step.id} file={file} />;
		}

		return (
			<BeraStepKey
				key={step.id}
				file={file}
				stepId={step.id}
				stepKeys={step?.step_key}
				stepDescription={step.description}
				onCheckAnyCollapse={handleCheckAnyCollapse}
			/>
		);
	}

	const stepMenu = [...steps];

	async function handleCheckAnyCollapse(checked: boolean) {
		setAnyCollapseChecked(checked);
		if (!checked) {
			handleClickableResultStepChange(false);
		}
	}

	async function handleStepMenuClick(step: number) {
		if (step < currentStep || clickableResultStep) {
			setCurrentStep(step);
			return;
		}

		if ((step === steps.length - 1 || step > currentStep) && !clickableResultStep) {
			return;
		}

		if (currentStep === 0) {
			const values = await form.validateFields();
			if (values) {
				setCurrentStep(step);
				return;
			}
		}

		if (currentStep !== 0 && !anyCollapseChecked) {
			message.error(I18n.get('Select at least one option.'));
		}
	}

	function handlePreviousStep() {
		setCurrentStep(currentStep - 1);
		if (showBeraJobResult) {
			handleShowBeraJobResultChange(false);
		}
	}

	async function handleFinishBeraClick() {
		const beraJobId = bera_job_summary_id ?? beraJobSummaryId;
		const body: UpdateBeraJobScoresRequest = {
			organization_id: organization?.id,
			company_id: company.id,
			bera_job_summary_id: beraJobId
		};
		const updated = await updateBeraJobScores(body);
		if (updated) {
			handleShowBeraJobResultChange(true);
		}
	}

	async function handleNextStep() {
		if (currentStep > 0 && !anyCollapseChecked) {
			message.error(I18n.get('Select at least one option.'));
			return;
		}

		const validatedValues = await form.validateFields();
		if (validatedValues) {
			setCurrentStep(currentStep + 1);
		}
	}

	async function handleCreateBeraReportClick() {
		if (currentStep > 0 && !anyCollapseChecked) {
			message.error(I18n.get('Select at least one option.'));
			return;
		}

		const validatedValues = await form.validateFields();
		if (validatedValues) {
			const formValues = form.getFieldValue([file.id]);
			if (formValues) {
				const body = {
					...formValues,
					...beraCommonInformations
				};

				const { mappedStepKeysResults, mappedTasksRpn } = mapStepKeysResults(formValues);
				const { severity, exposure, vulnerability, rpn } = beraResultService.calculateRPN(
					mappedTasksRpn,
					body.has_known_injury
				);

				const createParams: CreateBeraResultRequest = {
					organization_id: organization?.id,
					company_id: company.id,
					bera_report_data: {
						operator_evaluated: body.operator_evaluated,
						work_center: body.work_center,
						comment: body.comment,
						evaluator_id: body.evaluator_id,
						task_time: body.task_time,
						task_time_format: body.task_time_format,
						has_known_injury: body.has_known_injury,
						collection_date: body.collection_date,
						task_id: body.task_id,
						file_id: file.id,
						workstation_id: body.workstation_id,
						bera_job_summary_id: bera_job_summary_id ?? beraJobSummaryId,
						severity,
						exposure,
						vulnerability,
						rpn
					},
					bera_step_key_result_data: mappedStepKeysResults
				};

				if (!beraReportId) {
					return await createBeraReport(createParams);
				}
				const updateParams: UpdateBeraResultRequest = {
					...createParams,
					bera_report_data: {
						...createParams.bera_report_data,
						id: beraReportId
					}
				};
				return await updateBeraReport(updateParams);
			}
		}
	}

	function mapStepKeysResults(formValues: any) {
		const mappedStepKeysResults: CreateBeraStepKeyResultRequest[] = [];
		const { stress_level, frequency, total_task_duration }: TasksRpn = {
			stress_level: [],
			frequency: [],
			total_task_duration: []
		};

		for (const step in formValues) {
			const currentStep = formValues[step];
			if (currentStep?.empty_step !== undefined) {
				for (const step_key in currentStep) {
					const currentStepKey = currentStep[step_key];
					if (currentStepKey?.step_key !== undefined) {
						if (!currentStepKey?.step_key) {
							const emptyResult = {
								frequency_id: null,
								job_element: undefined,
								stress_level_id: null,
								task_rpn: 0,
								total_task_duration_id: null,
								custom_report_step_key_id: step_key
							};
							mappedStepKeysResults.push(emptyResult);
						}
						if (currentStepKey?.step_key) {
							const task_rpn = typeof currentStepKey.task_rpn === 'string' ? 0 : currentStepKey.task_rpn;
							setTasksRpnScores(currentStepKey, stress_level, frequency, total_task_duration);
							const mappedResults: CreateBeraStepKeyResultRequest = {
								...currentStepKey,
								task_rpn,
								custom_report_step_key_id: step_key
							};
							mappedStepKeysResults.push(mappedResults);
						}
					}
				}
			}
		}

		const mappedTasksRpn = { stress_level, frequency, total_task_duration };

		return { mappedStepKeysResults, mappedTasksRpn };
	}

	function setTasksRpnScores(
		currentStepKey: any,
		stress_level: number[],
		frequency: number[],
		total_task_duration: number[]
	) {
		const stress_level_score = currentStepKey.stress_level_score;
		if (stress_level_score) {
			stress_level.push(stress_level_score);
		}
		const frequency_score = currentStepKey.frequency_score;
		if (frequency_score) {
			frequency.push(frequency_score);
		}
		const total_task_duration_score = currentStepKey.total_task_duration_score;
		if (total_task_duration_score) {
			total_task_duration.push(total_task_duration_score);
		}
	}

	async function createBeraReport(body: CreateBeraResultRequest): Promise<void> {
		const createdBeraReport = await createBeraResult(body);
		if (createdBeraReport) {
			handleFinishBeraReport(file.id);
			handleClickableResultStepChange(true);
			setBeraReportId(createdBeraReport?.id);
			setCurrentStep(currentStep + 1);
		}
	}

	async function updateBeraReport(body: UpdateBeraResultRequest): Promise<void> {
		const updatedBeraReport = await updateBeraResult(body);
		if (updatedBeraReport) {
			handleFinishBeraReport(file.id);
			handleClickableResultStepChange(true);
			setBeraReportId(updatedBeraReport?.id);
			setCurrentStep(currentStep + 1);
		}
	}

	return (
		<Row gutter={[0, 20]} justify="center">
			<Col xs={24}>
				{showBeraJobResult ? (
					<BeraJobResult />
				) : (
					<Row gutter={[64, 0]} justify="center">
						<StepsMenu
							stepMenu={stepMenu}
							currentStep={currentStep}
							onStepMenuClick={handleStepMenuClick}
						/>
						<Col sm={currentStep === steps.length - 1 ? 22 : 18} style={{ padding: 0 }}>
							<BeraReportForm
								initialValues={initialValues}
								onValuesChange={() => handleFinishBeraReport(file.id, false)}
								autoComplete="off"
								name="bera_report"
								form={form}
							>
								<Col xs={24} style={{ padding: 0 }}>
									{steps[currentStep].content}
								</Col>
							</BeraReportForm>
						</Col>
					</Row>
				)}
			</Col>
			<Col xs={24}>
				<Row justify="center" style={{ minWidth: '190px' }}>
					<Footer
						currentStep={currentStep}
						totalSteps={steps.length}
						onNextClick={handleNextStep}
						onPreviousClick={handlePreviousStep}
						disabledReturn={report?.consolidated}
						onFinishBeraClick={handleFinishBeraClick}
						onCreateBeraReportClick={handleCreateBeraReportClick}
					/>
				</Row>
			</Col>
		</Row>
	);
};
