import React from 'react';
import { Row, Col, Switch } from 'antd';

import { Title } from '@/components/Typography';
import Can from '@/components/Can';

import { DeactivateTokenModal } from './DeactivateTokenModal';
import { ForceTwoFA } from '../ForceTwoFA';
import { useTFAContext } from './context';
import { I18n } from '@aws-amplify/core';
import { Description } from '../styles';

export const TwoFactorAuthentication: React.FC = () => {
	const { handle2FAChecked, handleOpenGenerateTokenModal, handleOpenDeactivateTokenModal } = useTFAContext();
	const { checked2FA, user2FA, fecthingUser2FA, openGenerateTokenModal, openDeactivateTokenModal } = useTFAContext();

	function handleChange() {
		if (!user2FA.validated) {
			handle2FAChecked();
			handleOpenGenerateTokenModal(!openGenerateTokenModal);
		} else {
			handleOpenDeactivateTokenModal(!openDeactivateTokenModal);
		}
	}

	return (
		<Row gutter={[0, 10]} style={{ paddingTop: '2rem', paddingBottom: '1rem' }}>
			<Col span={24}>
				<Title style={{ margin: '0' }} level={5}>
					Two-factor authentication
				</Title>
			</Col>
			<Col span={16}>
				<Row align="middle" justify="space-between">
					<Description>
						{I18n.get('When activated, you will have to type a validation code every time you login.')}
					</Description>
					<Switch
						style={{ marginLeft: '1rem' }}
						loading={fecthingUser2FA}
						defaultChecked={!!user2FA.validated}
						checked={!!user2FA.validated || checked2FA}
						onChange={handleChange}
					/>
				</Row>
			</Col>
			{Can('settings', 'force_2fa') && (
				<Col offset={1} span={24}>
					{user2FA.validated && <ForceTwoFA />}
				</Col>
			)}

			{openDeactivateTokenModal && <DeactivateTokenModal />}
		</Row>
	);
};
