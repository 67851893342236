import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

import { WorkstationsRequest } from '@/components/ui/Inputs/types/request/workstation';
import { WorkstationDTO } from '@/components/ui/Inputs/types/response/workstation';

async function getWorkstations({
	organization_id,
	company_id,
	line_id
}: WorkstationsRequest): Promise<WorkstationDTO[]> {
	const url = `/workstation/${line_id}?organization_id=${organization_id}&company_id=${company_id}`;
	const { data } = await Api.get<WorkstationDTO[]>(url);
	return data;
}

export function useWorkstations({ organization_id, company_id, line_id }: WorkstationsRequest) {
	const enabled = !!organization_id && !!company_id && !!line_id;

	const workstations = useQuery(
		['workstations', { organization_id, company_id, line_id }],
		() => getWorkstations({ organization_id, company_id, line_id }),
		{ enabled }
	);
	return workstations;
}
