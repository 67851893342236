import React, { useEffect, useState } from 'react';
import { TableRowSelection } from 'antd/lib/table/interface';
import { ColumnsType } from 'antd/lib/table';
import { Col, Form, Row, Table } from 'antd';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import { FileForCustomReport } from '@/components/ui/Inputs/types/response/file';

import { useBeraJobStepsContext } from '../context';
import { CustomFormItem } from './styles';
import { FilterFile } from './FilterFile';
import { SubTitle } from './SubTitle';

const { useFormInstance, useWatch } = Form;

interface FilesSelectionProps {
	isLoading: boolean;
}

export const FilesSelection: React.FC<FilesSelectionProps> = ({ isLoading }) => {
	const [timer, setTimer] = useState<NodeJS.Timeout>();
	const [searchingFile, setSearchingFile] = useState(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

	const { beraFiles, beraJobSummary, page, limit, fileName, selectedFilesId } = useBeraJobStepsContext();
	const { filterSelectedFiles, handleSelectedFilesChange, handlePageChange, handleFileNameChange, handleSizeChange } =
		useBeraJobStepsContext();

	const form = useFormInstance();
	const files_list = useWatch('files_list', form);

	useEffect(() => {
		setSearchingFile(false);
	}, [fileName]);

	useEffect(() => {
		if (Array.isArray(files_list)) {
			setSelectedRowKeys(files_list);
		}
	}, [files_list]);

	function handleSelectChange(selectedRows: React.Key[]) {
		setSelectedRowKeys(selectedRows);
		const files = filterSelectedFiles(selectedRows, beraFiles);
		handleSelectedFilesChange(files);
	}

	const columns: ColumnsType<FileForCustomReport> = [
		{
			title: I18n.get('File'),
			dataIndex: 'original_name',
			key: 'original_name',
			align: 'center'
		},
		{
			title: I18n.get('Date of creation'),
			dataIndex: 'updated_at',
			key: 'updated_at',
			align: 'center',
			render: (updated) => moment(updated).format('L')
		}
	];

	let dataSource = beraFiles?.files?.rows;

	if (selectedFilesId.length > 0 && alreadyCreatedOneBeraReport()) {
		dataSource = beraFiles?.files?.rows.filter((file) => selectedFilesId?.includes(file?.id));
	}

	const rowSelection: TableRowSelection<FileForCustomReport> = {
		preserveSelectedRowKeys: true,
		selectedRowKeys,
		onChange: handleSelectChange
	};

	const setFileName = (name: string) => {
		clearTimeout(timer);

		const debounce = setTimeout(() => {
			if (!name || name.length === 0) {
				handleSizeChange(10);
			} else {
				handlePageChange(1);
				handleSizeChange(100);
			}
			handleFileNameChange(name);
		}, 1000);

		setTimer(debounce);
	};

	function handleSearchFileNameChange(event: any) {
		const name = event.target.value;
		setSearchingFile(true);
		setFileName(name);
	}

	function showRowSelection(): TableRowSelection<FileForCustomReport> | undefined {
		if (shouldNotEditSelectedFiles()) {
			return undefined;
		}

		return rowSelection ?? undefined;
	}

	function shouldNotEditSelectedFiles() {
		return beraJobSummary.show_bera_result || alreadyCreatedOneBeraReport();
	}

	function shouldEditSelectedFiles() {
		if (alreadyCreatedOneBeraReport()) {
			return false;
		}
		return !beraJobSummary.show_bera_result;
	}

	function alreadyCreatedOneBeraReport() {
		return beraFiles?.files?.rows.find(
			(file) =>
				file.bera_report && file.bera_report.find((report) => report.bera_job_summary_id === beraJobSummary?.id)
		);
	}

	const paginationOptions = {
		hideOnSinglePage: false,
		total: beraFiles?.files?.count,
		current: page === 0 ? page + 1 : page,
		defaultPageSize: limit,
		pageSize: limit,
		onChange: (page: number) => {
			handlePageChange(page);
		}
	};

	return (
		<Col xs={24}>
			<Row gutter={[0, 10]} justify="center">
				<SubTitle />
				<FilterFile
					onSearchFileNameChange={handleSearchFileNameChange}
					shouldEditSelectedFiles={shouldEditSelectedFiles()}
				/>
				<Col xs={24}>
					<Row justify="center">
						<Col xs={24}>
							<CustomFormItem
								name={['files_list']}
								rules={[
									() => ({
										validator() {
											return !selectedRowKeys ||
												selectedRowKeys.length === 0 ||
												!selectedRowKeys[0]
												? Promise.reject(I18n.get('Select at least one file'))
												: Promise.resolve();
										}
									})
								]}
							>
								<Table
									rowKey="id"
									dataSource={dataSource}
									columns={columns}
									loading={isLoading || searchingFile}
									rowSelection={showRowSelection()}
									pagination={paginationOptions}
								/>
							</CustomFormItem>
						</Col>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};
