import React from 'react';
import { Col, Collapse, Row } from 'antd';

import { Title } from '@/components/Typography';

import { ActionPlanData } from './ActionPlanData';
import { ActionPlanDTO } from '../../hooks/types';
import { FormContainer } from '../../styles';
import { Container } from './styles';

const { Panel } = Collapse;

type ActionPlansProps = {
	actionPlans: ActionPlanDTO[];
};

export const ActionPlans: React.FC<ActionPlansProps> = ({ actionPlans }) => {
	const panels = (
		<Panel
			key={'1'}
			header={
				<Title style={{ margin: 0 }} level={5}>
					Action plans
				</Title>
			}
		>
			{actionPlans.map((actionPlan) => {
				return (
					<Row justify="center" key={actionPlan.id} style={{ marginTop: '1rem' }}>
						<Col span={16}>
							<Container>
								<ActionPlanData actionPlan={actionPlan} />
							</Container>
						</Col>
					</Row>
				);
			})}
		</Panel>
	);

	return (
		<>
			<Col span={24} style={{ margin: '1rem 0 0 0' }}>
				<FormContainer bordered={false}>{panels}</FormContainer>
			</Col>
		</>
	);
};
