import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ManipulateUrlServices } from '../services/manipulateUrlServices';
import { Context, MosaicData } from './index.types';

type TreemapProviderProps = {
	children: ReactNode;
};

const TreemapContext = createContext<Context>({} as Context);

export function TreemapProvider({ children }: TreemapProviderProps) {
	const history = useHistory();

	const manipulateUrlServices = new ManipulateUrlServices();

	const [page, setPage] = useState(0);
	const [lineId, setLineId] = useState('');
	const [sectorId, setSectorId] = useState('');
	const [companyId, setCompanyId] = useState('');
	const [lines, setLines] = useState<MosaicData>([]);
	const [sectors, setSectors] = useState<MosaicData>([]);
	const [menuItems, setMenuItems] = useState<string[]>([]);
	const [isLoadingData, setIsLoadingData] = useState(false);
	const [companies, setCompanies] = useState<MosaicData>([]);
	const [errorGettingData, setErrorGettingData] = useState(false);
	const [workstations, setWorkstations] = useState<MosaicData>([]);

	const pageContentMapper = {
		company: 0,
		sector: 1,
		line: 2,
		workstation: 3
	};

	useEffect(() => {
		if (manipulateUrlServices.hasSearchParam('company')) {
			const id = manipulateUrlServices.getParamValue('company');
			if (id) {
				const company = companies?.find((company) => company.id === id);
				if (company) {
					menuItems[pageContentMapper['company']] = company.title;
					setMenuItems(menuItems);
				}
				setCompanyId(id);
			}
		}

		if (manipulateUrlServices.hasSearchParam('line')) {
			const id = manipulateUrlServices.getParamValue('line');
			if (id) {
				const line = lines?.find((line) => line.id === id);
				if (line) {
					menuItems[pageContentMapper['line']] = line.title;
					setMenuItems(menuItems);
				}
				setLineId(id);
			}
		}

		if (manipulateUrlServices.hasSearchParam('sector')) {
			const id = manipulateUrlServices.getParamValue('sector');
			if (id) {
				const sector = sectors?.find((sector) => sector.id === id);
				if (sector) {
					menuItems[pageContentMapper['sector']] = sector.title;
					setMenuItems(menuItems);
				}
				setSectorId(id);
			}
		}

		if (manipulateUrlServices.hasSearchParam('page')) {
			const value = manipulateUrlServices.getParamValue('page');
			if (value) {
				setPage(Number(value));
			}
		}
	}, [companies, lines, sectors]);

	useEffect(() => {
		if (page === 0 && !manipulateUrlServices.hasSearchParam('page')) {
			manipulateUrlServices.resetParams();
		}
	}, [lineId, sectorId, companyId]);

	function handleMenu(name: string, index: number) {
		menuItems[index] = name;
		setMenuItems(menuItems);
	}

	function handleMenuItemSelecion(pageNumber: number) {
		setPage(pageNumber);
		manipulateUrlServices.setNewUrl('page', String(pageNumber));
		manipulateUrlServices.removeUrlParam(pageNumber);
		menuItems.splice(pageNumber);
		setMenuItems(menuItems);
	}

	function handleFetchSectors(data: MosaicData, loading: boolean, error: boolean) {
		setSectors(data);
		setIsLoadingData(loading);
		setErrorGettingData(error);
	}

	function handleFetchLines(data: MosaicData, loading: boolean, error: boolean) {
		setLines(data);
		setIsLoadingData(loading);
		setErrorGettingData(error);
	}

	function handleFetchWorkstations(data: MosaicData, loading: boolean, error: boolean) {
		setWorkstations(data);
		setIsLoadingData(loading);
		setErrorGettingData(error);
	}

	function handleFetchCompanies(data: MosaicData, loading: boolean, error: boolean) {
		setCompanies(data);
		setIsLoadingData(loading);
		setErrorGettingData(error);
	}

	function handleSectorSelection(sector: string, name: string) {
		manipulateUrlServices.setNewUrl('sector', sector);
		manipulateUrlServices.setNewUrl('page', String(page + 1));
		setSectorId(sector);
		menuItems.push(name);
		setMenuItems(menuItems);
		setPage(page + 1);
	}

	function handleCompanySelection(company: string, name: string) {
		setCompanyId(company);
		manipulateUrlServices.setNewUrl('company', company);
		manipulateUrlServices.setNewUrl('page', String(page + 1));
		menuItems.push(name);
		setMenuItems(menuItems);
		setPage(page + 1);
	}

	function handleLineSelection(line: string, name: string) {
		setLineId(line);
		manipulateUrlServices.setNewUrl('line', line);
		manipulateUrlServices.setNewUrl('page', String(page + 1));
		menuItems.push(name);
		setMenuItems(menuItems);
		setPage(page + 1);
	}

	function handleWorkstationSelection(workstation: string, name: string) {
		history.push(`/reporting?workstation_id=${workstation}`);
	}

	const contextValue: Context = {
		page,
		lines,
		lineId,
		sectors,
		sectorId,
		companyId,
		menuItems,
		companies,
		workstations,
		isLoadingData,
		errorGettingData,
		handleMenu,
		handleFetchLines,
		handleFetchSectors,
		handleLineSelection,
		handleFetchCompanies,
		handleSectorSelection,
		handleCompanySelection,
		handleMenuItemSelecion,
		handleFetchWorkstations,
		handleWorkstationSelection
	};

	return <TreemapContext.Provider value={contextValue}>{children}</TreemapContext.Provider>;
}

export function useTreemapContext() {
	const context = useContext(TreemapContext);
	return context;
}
