import React from 'react';
import { Button, Col, Row } from 'antd';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useManageOrganizationContext } from '../../context';

interface ActionsProps {
	currentId?: string;
	onEdit: () => void;
	allowDeletion: boolean;
	onDelete: () => Promise<void>;
}

export const Actions: React.FC<ActionsProps> = ({ currentId, allowDeletion, onEdit, onDelete }) => {
	const { isEditing } = useManageOrganizationContext();

	function handleEdit(event: React.MouseEvent<HTMLElement, MouseEvent>) {
		event.preventDefault();
		event.stopPropagation();
		onEdit();
	}

	function handleDelete(event: React.MouseEvent<HTMLElement, MouseEvent>) {
		event.preventDefault();
		event.stopPropagation();
		onDelete();
	}

	return (
		<Col span={4}>
			<Row align="bottom" justify="space-between">
				<Col span={12}>
					<Button
						disabled={!currentId || isEditing}
						size="middle"
						type="primary"
						ghost={true}
						onClick={(event) => handleEdit(event)}
						icon={<EditOutlined />}
					/>
				</Col>
				<Col span={12}>
					<Button
						disabled={!currentId || isEditing || !allowDeletion}
						size="middle"
						type="primary"
						danger={true}
						ghost={true}
						onClick={(event) => handleDelete(event)}
						icon={<DeleteOutlined />}
					/>
				</Col>
			</Row>
		</Col>
	);
};
