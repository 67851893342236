import React, { ReactNode, useMemo, useState } from 'react';
import { Col, Divider, Row, Skeleton, TreeDataNode } from 'antd';
import { I18n } from '@aws-amplify/core';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

import type { ConsolidateReportDTO, CreateCommentsDTO, SubStepKeyResult, DownloadPDFDTO, StepResult } from '@/hooks';
import { StepKeysResultsProvider } from './components/Steps/StepKeyDescription/context';
import { ButtonsCustom } from '@/components/views/Report/ReportTemplate/ButtonsCustom';
import { CustomButton } from '@/components/ui/Buttons/CustomButton/styles';
import { ReportTemplate } from '@/components/views/Report/ReportTemplate';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useJdsD92Context } from '../../../context';
import { Title } from '@/components/Typography';
import { PreviousCol } from '../../styles';
import { useFile } from '@/hooks/v1';
import * as C from './components';
import {
	useUpdateCustomReportResultComment,
	useConsolidateCustomReport,
	useDownloadCustomReportPDF,
	useGetCustomReportResults
} from '@/hooks';
import { PDFSections } from '@/types';
import { useHistory } from 'react-router-dom';

type Sections = {
	id: string;
	title: string;
	component: ReactNode;
};

type ToolMapper = {
	[key: string]: {
		tool: string;
		component: ReactNode;
	};
};

type ToolMapperBottom = {
	[key: string]: Sections;
};

type InitialValues = {
	[key: string]: Sections;
};

export const Results: React.FC = () => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const history = useHistory();
	const { organization, company } = useApplicationContext();
	const { handlePreviousStep, jdsD92, customReport } = useJdsD92Context();

	const initialValues: PDFSections[] = [
		PDFSections.KIM_MHO,
		PDFSections.NIOSH,
		PDFSections.REBA,
		PDFSections.ACTION_PLANS,
		PDFSections.NOT_EVALUATED,
		PDFSections.LIBERTY_MUTUAL,
		PDFSections.BACK_COMPRESSIVE_FORCE_ESTIMATION
	];

	const { data: jdsD92Result, isLoading: resultLoading } = useGetCustomReportResults({
		company_id: company?.id,
		report_id: customReport.id,
		file_id: jdsD92?.file_id,
		organization_id: organization?.id,
		custom_report_result_id: jdsD92.result_id,
		selected_pdf_sections: initialValues
	});

	const informations = useMemo(() => jdsD92Result.informations, [resultLoading]);

	const {
		file: { data: fileInformations, isLoading: gettingFile }
	} = useFile({
		companyId: company?.id,
		fileId: jdsD92.file_id,
		organizationId: organization?.id
	});

	const { mutateAsync: downloadPDF, isLoading: downloadPDFLoading } = useDownloadCustomReportPDF();
	const { mutateAsync: updateComments, isLoading: commentsLoading } = useUpdateCustomReportResultComment();
	const { mutateAsync: consolidateReport, isLoading: consolidateReportLoading } = useConsolidateCustomReport();

	const loading = resultLoading || commentsLoading || downloadPDFLoading || consolidateReportLoading || gettingFile;
	const browserLanguage = window.navigator.language ?? 'en-US';
	const isReportConsolidated = jdsD92Result.consolidated;

	function handleNextStep(): void {
		if (isReportConsolidated) {
			const url = '/custom-reports/jds-d92';
			return history.push(url);
		}
		setIsModalOpen(true);
	}

	function hasErgonomicTool(nameSection: string): boolean {
		return !!jdsD92Result.tools_to_show.find((tool) => tool === nameSection);
	}

	function hasActionPlan(nameSection: string) {
		return nameSection === 'action_plans' && !!jdsD92Result?.action_plans;
	}

	function mapSectionArray(steps: StepResult[]) {
		if (!steps.length) {
			return []
		}

		const initialMapper: InitialValues = {
			informations: {
				id: jdsD92Result?.informations?.id,
				title: I18n.get('Results'),
				component: (
					<C.FinalResults
						worstScore={jdsD92Result?.informations?.sum_score}
						worstValues={jdsD92Result?.informations?.worst_values}
					/>
				)
			}
		};

		const toolsMapper: ToolMapperBottom = {
			action_plans: {
				title: '',
				id: uuidv4(),
				component: <C.ActionPlans data={jdsD92Result?.action_plans} />
			},
			reba: {
				title: '',
				id: uuidv4(),
				component: <C.RebaResults data={jdsD92Result?.reba} />
			}
		};

		const reportOrder = ['informations', 'reba', 'action_plans'];

		const sections = [] as Sections[];

		reportOrder.forEach((nameSection: string) => {
			if (initialMapper[nameSection]) {
				sections.push(initialMapper[nameSection]);
			}
		});

		steps.forEach((step) => {
			if (step.description === 'Video selection') {
				return;
			}
			sections.push({
				id: uuidv4(),
				title: I18n.get(step.description),
				component: (
					<>
						<Divider
							type="vertical"
							style={{
								width: '4px',
								border: 'none',
								height: '20px',
								borderRadius: '2px',
								margin: '0 0 0 52px',
								backgroundColor: '#e6e8e8'
							}}
						/>
						{renderStepDescription(step)}
					</>
				)
			});
		});

		reportOrder.forEach((nameSection: string) => {
			console.log(nameSection);
			if (toolsMapper[nameSection] && (hasActionPlan(nameSection) || hasErgonomicTool(nameSection))) {
				sections.push(toolsMapper[nameSection]);
			}
		});

		return sections;
	}

	function renderStepDescription(step: StepResult): React.ReactNode {
		const toolsMapper: ToolMapper = {
			manual_handling: {
				tool: 'kim_mho',
				component: <C.KimManualHandlingResults data={jdsD92Result?.kim_mho} />
			},
			manual_lifting: {
				tool: 'niosh',
				component: <C.NioshResults data={jdsD92Result?.niosh} />
			},
			liberty_mutual: {
				tool: 'liberty_mutual',
				component: <C.LibertyMutualResults data={jdsD92Result?.liberty_mutual} />
			},
			back_compressive_force_estimation: {
				tool: 'back_compressive_force_estimation',
				component: <C.BackCompressiveForceResults data={jdsD92Result?.back_compressive_force_estimation} />
			}
		};

		return step.step_keys.map((stepKey, index, stepKeys) => {
			if (hasSubStepKeyResult(stepKey.sub_step_keys)) {
				return mapSubStepKeys(stepKey.sub_step_keys);
			}

			let Tool = null;
			if (
				toolsMapper[stepKey.name] &&
				(hasActionPlan(stepKey.name) || hasErgonomicTool(toolsMapper[stepKey.name].tool))
			) {
				Tool = toolsMapper[stepKey.name].component;
			}

			return (
				<StepKeysResultsProvider key={stepKey.id} stepKey={stepKey} component={Tool}>
					<C.StepsDescription isLastStepKey={stepKeys.length - 1 === index} />
				</StepKeysResultsProvider>
			);
		});
	}

	function mapSubStepKeys(subStepKeys: SubStepKeyResult[]): JSX.Element[] {
		return subStepKeys.map((subStepKey, index) => {
			return (
				<StepKeysResultsProvider key={subStepKey.id} stepKey={subStepKey}>
					<C.StepsDescription isLastStepKey={subStepKeys.length - 1 === index} />
				</StepKeysResultsProvider>
			);
		});
	}

	function hasSubStepKeyResult(subStepKey: SubStepKeyResult[] | undefined): subStepKey is SubStepKeyResult[] {
		return !!subStepKey && subStepKey.length > 0;
	}

	async function updateComment(text: string): Promise<void> {
		const parameters: CreateCommentsDTO = {
			comment: text,
			company_id: company?.id,
			organization_id: organization?.id,
			custom_report_result_id: jdsD92.result_id
		};
		await updateComments(parameters);
	}

	async function onConsolidatePDF(): Promise<void> {
		const parameters: ConsolidateReportDTO = {
			locale: browserLanguage,
			company_id: company?.id,
			report_id: jdsD92.id,
			file_id: jdsD92.file_id,
			organization_id: organization?.id,
			custom_report_result_id: jdsD92?.result_id,
			selected_pdf_sections: initialValues
		};
		await consolidateReport(parameters);
	}

	async function handleDownloadPDF(file: any): Promise<void> {
		const parameters: DownloadPDFDTO = {
			report_id: jdsD92.id,
			company_id: company?.id,
			locale: browserLanguage,
			file_id: jdsD92.file_id,
			organization_id: organization?.id,
			selected_pdf_sections: file.pdf_custom,
			custom_report_result_id: jdsD92?.result_id
		};
		await downloadPDF(parameters);
	}

	async function handleChangeComment(event: React.ChangeEvent<HTMLTextAreaElement>) {
		setComment(event.target.value);
	}

	const setComment = _.debounce(async (value) => {
		await updateComment(value);
	}, 1200);

	const sections = useMemo(() => mapSectionArray(jdsD92Result?.steps || []), [jdsD92Result?.steps]);

	if (resultLoading || gettingFile) {
		return <Skeleton active />;
	}

	const treeData: TreeDataNode[] = [
		{
			selectable: false,
			key: 'not_evaluated',
			style: { fontWeight: 'bold' },
			title: I18n.get('Criteria not evaluated')
		},
		{
			title: I18n.get('Tools'),
			key: 'tools',
			selectable: false,
			style: { fontWeight: 'bold' },
			switcherIcon: <></>,
			children: [
				{
					selectable: false,
					key: 'niosh',
					title: I18n.get('Cargo Handling (NIOSH)')
				},
				{
					selectable: false,
					key: 'kim_mho',
					title: I18n.get('Manual Handling (KIM-MHO)')
				},
				{
					selectable: false,
					key: 'reba',
					title: I18n.get('Movement by score (Kinebot/JDS)')
				},
				{
					selectable: false,
					key: 'back_compressive_force_estimation',
					title: I18n.get('Back Compressive Force Estimation')
				},
				{
					selectable: false,
					key: 'liberty_mutual',
					title: I18n.get('Material Handling (Liberty Mutual)')
				}
			]
		},
		{
			selectable: false,
			key: 'action_plans',
			style: { fontWeight: 'bold' },
			title: I18n.get('Action plans')
		}
	];

	return (
		<Row justify={'center'}>
			<Col lg={21} xl={22} xxl={22}>
				<Row justify={'space-between'}>
					<Col>
						<Title level={4} style={{ marginBottom: '2.5rem' }}>
							Result
						</Title>
					</Col>
					<Col>
						<ButtonsCustom
							fileData={fileInformations}
							hasCustomModal={true}
							hasConsolidated={true}
							pdfProps={{
								initialValues,
								treeData
							}}
							isLoadingPDF={loading}
							onDownloadPDF={handleDownloadPDF}
							onConsolidatePDF={onConsolidatePDF}
							consolidated={isReportConsolidated}
						/>
					</Col>
				</Row>
				<ReportTemplate
					sections={sections}
					hasCustomModal={true}
					hasConsolidated={true}
					isLoadingPDF={loading}
					isLoading={gettingFile}
					fileData={fileInformations}
					onDownloadPDF={handleDownloadPDF}
					sector={fileInformations?.sector}
					onConsolidatePDF={onConsolidatePDF}
					onChangeComment={handleChangeComment}
					consolidated={isReportConsolidated}
					workstation={fileInformations?.workstation}
					reportData={{ jds_d86_report: informations }}
					title={I18n.get('Ergonomic evaluation') + ' JDS-D92'}
					pdfProps={{
						initialValues,
						treeData
					}}
				/>
			</Col>
			<Col span={24}>
				<Row>
					<Col xs={24} style={{ marginTop: '2rem' }}>
						<Row justify="center" align="middle" gutter={[0, 8]}>
							{!isReportConsolidated && (
								<PreviousCol>
									<CustomButton
										size="large"
										type="default"
										disabled={loading}
										onClick={handlePreviousStep}
									>
										{I18n.get('Previous')}
									</CustomButton>
								</PreviousCol>
							)}
							<Col>
								<CustomButton
									size="large"
									type="primary"
									htmlType="submit"
									loading={loading}
									disabled={loading}
									onClick={handleNextStep}
								>
									{I18n.get('Close')}
								</CustomButton>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
			<C.ConsolidatedModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
		</Row>
	);
};
