import { useMutation, useQuery } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';

import { BeraJobResultDTO, BeraJobSummaryRequest, PayloadDownloadPDF, URLDownloadDTO } from './types';
import { UpdateBeraJobSummaryRequest } from '../hooks/types/request';
import { BeraJobResultService } from './services';

const beraJobResultService = new BeraJobResultService();

export function useBeraJobResults({ organization_id, company_id, bera_job_summary_id }: BeraJobSummaryRequest) {
	const enabled = !!organization_id && !!company_id && !!bera_job_summary_id;
	const results = useQuery(
		['bera_job_results', [organization_id, company_id, bera_job_summary_id]],
		() => beraJobResultService.getBeraJobResults({ organization_id, company_id, bera_job_summary_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...results,
		data: results.data ?? ({} as BeraJobResultDTO)
	};
}

export function useDownloadPDF() {
	return useMutation((payload: PayloadDownloadPDF) => beraJobResultService.downloadPDF(payload), {
		onSuccess: (data: URLDownloadDTO) => {
			let archive = document.createElement('a');
			archive.target = '_blank';
			archive.href = data.url;
			archive.click();
			archive.remove();
		},
		onError: () => {
			message.error(I18n.get('Failed to generate the report'));
		}
	});
}

export function useComments() {
	return useMutation((payload: UpdateBeraJobSummaryRequest) => beraJobResultService.saveComments(payload));
}
