import React from 'react';
import { Col, Row } from 'antd';

import { DividerStyled } from './styles';

interface DividerCustomProps {
	type?: 'horizontal' | 'vertical';
}

export const DividerCustom: React.FC<DividerCustomProps> = ({ type = 'horizontal' }) => {
	return (
		<Row justify="center">
			<Col span={22}>
				<DividerStyled type={type} />
			</Col>
		</Row>
	);
};
