import React, { useState } from 'react';
import { Col, Row, Switch } from 'antd';

import { useApplicationContext } from '@/context/v1/Application/context';
import { Title } from '@/components/Typography';
import { Spinner } from '@/components/Spinner';
import { I18n } from '@aws-amplify/core';

import { useForce2FA, useGetForced2FA } from './hooks';
import { Container, Description } from './styles';

export const ForceTwoFA = () => {
	const { data: isForced, isFetched: fetchedForced2FA } = useGetForced2FA();
	const { mutateAsync: force2FA } = useForce2FA();

	const { company, organization } = useApplicationContext();

	const [checked, setChecked] = useState(isForced.force_two_fa);

	async function handleChange(isChecked: boolean) {
		const forced = await force2FA({
			company_id: company.id,
			organization_id: organization.id,
			force_two_fa: isChecked
		});

		if (forced) {
			setChecked(isChecked);
		}
	}

	return !!!fetchedForced2FA ? (
		<Spinner />
	) : (
		<Container gutter={[0, 10]} align="middle">
			<Col span={24}>
				<Title style={{ margin: '0' }} level={5}>
					Force two-factor authentication to all users
				</Title>
			</Col>
			<Col span={15}>
				<Row align="middle" justify='space-between'>
					<Description>
						{I18n.get(
							'When activated, all users will be forced to activate two-factor authentication on login.'
						)}
					</Description>
					<Switch
						style={{ marginLeft: '1rem' }}
						loading={!fetchedForced2FA}
						defaultChecked={!!isForced.force_two_fa}
						checked={checked}
						onChange={(isChecked) => handleChange(isChecked)}
					/>
				</Row>
			</Col>
		</Container>
	);
};
