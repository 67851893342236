import React from 'react';
import { Col, Form, Row, Select } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Greater } from './Greater';
import { Less } from './Less';

const { useFormInstance, useWatch } = Form;

export const RPN: React.FC = () => {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();
	const type_rpn = useWatch(['type_rpn'], form);

	function handleClear() {
		setFieldValue(['type_rpn'], undefined);
		setFieldValue(['min_rpn'], undefined);
		setFieldValue(['max_rpn'], undefined);
	}

	const options = [
		{
			value: 'gt',
			label: I18n.get('Greater than')
		},
		{
			value: 'gte',
			label: I18n.get('Greater than or equal')
		},
		{
			value: 'lt',
			label: I18n.get('Less than')
		},
		{
			value: 'lte',
			label: I18n.get('Less than or equal')
		},
		{
			value: 'between',
			label: I18n.get('Between')
		}
	];

	const inputMapper: { [key: string]: JSX.Element } = {
		gt: <Greater />,
		gte: <Greater />,
		lt: <Less />,
		lte: <Less />,
		between: (
			<Row gutter={[32, 6]}>
				<Greater />
				<Less />
			</Row>
		)
	};

	return (
		<Col xs={24}>
			<Form.Item
				style={{ marginBottom: '1rem' }}
				name={['type_rpn']}
				label={I18n.get('RPN')}
				labelCol={{ span: 24 }}
			>
				<Select
					allowClear
					value={type_rpn}
					onClear={handleClear}
					options={options}
					placeholder={I18n.get('RPN')}
				></Select>
			</Form.Item>
			{inputMapper[type_rpn]}
		</Col>
	);
};
