import { FileDTO } from '@/components/ui/Inputs/types/response/file';

export type SeraReportFile = Pick<FileDTO, 'id' | 'original_name' | 'generated_name'>;

export type SeraReport = {
	id: string;
	file: SeraReportFile[];
};

export type SeraSummaryStatus = 'UNCOMPLETED' | 'COMPLETED' | 'REVIEWED';

export enum SeraSummaryStatuses {
	UNCOMPLETED = 'UNCOMPLETED',
	COMPLETED = 'COMPLETED',
	REVIEWED = 'REVIEWED'
}

export type SeraSummaryList = {
	id: string;
	created_at: Date;
	reviewed_at: Date;
	report_name: string;
	cycle_name?: string;
	last_review: number;
	last_review_id: string;
	status: SeraSummaryStatus;
	workstation_name?: string;
	sera_report: SeraReport[];
};

export type SeraSummaryListDTO = {
	count: number;
	rows: SeraSummaryList[];
};

export type SeraSummaryDTO = {
	id: string;
	report_name: string;
	comment?: string;
	created_at?: Date;
	updated_at: Date;
	deleted_at?: Date;
	cycle_id: string;
};
