import React, { useEffect, useState } from 'react';
import { Col, Divider, Form, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { ExistingPreventionMeasures } from './ExistingPreventionMeasures';
import { useApplicationContext } from '@/context/v1/Application/context';
import { PreviousReview } from './PreviousReview/SeraReportForm';
import { RPNScore, Score, ScoreTitle, Title } from './styles';
import { SeraRPNService } from '../services/seraRPNService';
import { AvailableTasks } from '../hooks/types/response';
import { RiskDescriptions } from './RiskDescriptions';
import { useSeraReviewContext } from '../../context';
import { Vulnerabilities } from './Vulnerabilities';
import { Specifications } from './Specifications';
import { RiskCategories } from './RiskCategories';
import { RiskDamages } from './RiskDamages';
import { Evaluators } from './Evaluators';
import { Severities } from './Severities';
import { Exposures } from './Exposures';
import { Tasks } from './Tasks';
import {
	useExposures,
	useRiskCategories,
	useRiskDamages,
	useRiskDescriptions,
	useSeraSteps,
	useSeverities,
	useVulnerabilities
} from '../hooks/hooks';
import { CollapseCheck } from './CollapseCheck';
import { TaskCollapseHeader } from './TaskCollapseHeader';

const { useWatch, useFormInstance } = Form;

interface SeraReportFormProps {
	reportId: string;
	customReportId: string;
	onAnyCollapseChange: () => void;
	availableTasks: AvailableTasks[];
}

export const SeraReportForm: React.FC<SeraReportFormProps> = ({
	reportId,
	availableTasks,
	customReportId,
	onAnyCollapseChange
}) => {
	const [checked, setChecked] = useState(false);

	const form = useFormInstance();
	const { organization, company } = useApplicationContext();
	const { mapRPNToPriority, previousReviewStatus } = useSeraReviewContext();

	const { data: stepsList } = useSeraSteps({
		organization_id: organization?.id,
		company_id: company.id,
		custom_report_id: customReportId
	});

	const risk_category_id = useWatch(['true', reportId, 'risk_category_id'], form);
	const risk_description_id = useWatch(['true', reportId, 'risk_description_id'], form);
	const stepKeyId = stepsList[0]?.step_key[0]?.id;
	const {
		data: riskDescriptions,
		isLoading: gettingRiskDescriptions,
		isFetching: fetchingRiskDescriptions
	} = useRiskDescriptions({
		organization_id: organization?.id,
		company_id: company?.id,
		risk_category_id
	});
	const {
		data: riskCategories,
		isLoading: gettingRiskCategories,
		isFetching: fetchingRiskCategories
	} = useRiskCategories({
		organization_id: organization?.id,
		company_id: company?.id,
		step_key_id: stepKeyId
	});
	const {
		data: riskDamages,
		isLoading: gettingRiskDamages,
		isFetching: fetchingRiskDamages
	} = useRiskDamages({
		organization_id: organization?.id,
		company_id: company?.id,
		risk_description_id
	});
	const {
		data: exposures,
		isLoading: gettingExposures,
		isFetching: fetchingExposures
	} = useExposures({
		organization_id: organization?.id,
		company_id: company?.id,
		step_key_id: stepKeyId
	});
	const {
		data: severities,
		isLoading: gettingSeverities,
		isFetching: fetchingSeverities
	} = useSeverities({
		organization_id: organization?.id,
		company_id: company?.id,
		step_key_id: stepKeyId
	});
	const {
		data: vulnerabilities,
		isLoading: gettingVulnerabilities,
		isFetching: fetchingVulnerabilities
	} = useVulnerabilities({
		organization_id: organization?.id,
		company_id: company?.id,
		step_key_id: stepKeyId
	});

	const seraRPNService = new SeraRPNService({ exposures, severities, vulnerabilities });
	const status = previousReviewStatus?.find((report) => report.id === reportId);

	const exposureId = useWatch([`${checked}`, reportId, 'exposure_id'], form);
	const vulnerabilityId = useWatch([`${checked}`, reportId, 'vulnerability_id'], form);
	const severityId = useWatch([`${checked}`, reportId, 'severity_id'], form);

	const taskRPN = seraRPNService.calculateTaskRPN({ exposureId, severityId, vulnerabilityId });
	const rpnPriority = mapRPNToPriority(taskRPN?.rpn);
	const tasks = availableTasks.map(({ data }) => ({
		id: data.id,
		name: data.name
	}));

	useEffect(() => {
		if (exposureId && severityId && vulnerabilityId) {
			form.setFieldValue([`${checked}`, reportId, 'taskRPN'], taskRPN);
		}
	}, [reportId, exposureId, severityId, vulnerabilityId]);

	function renderHeader(id: string): JSX.Element {
		return <TaskCollapseHeader report_id={id} checked={checked} />;
	}

	function handleCollapseChange(value: boolean) {
		setChecked(value);
		onAnyCollapseChange();
	}

	return (
		<Row>
			<Col xs={24}>
				<Form.Item name={[reportId, 'checked']}>
					<CollapseCheck value={checked} onChange={handleCollapseChange} header={renderHeader(reportId)}>
						<Row>
							{status?.expanded && <PreviousReview reportId={reportId} />}
							<Col xs={24} style={{ padding: '1rem 0rem' }}>
								<Row gutter={48}>
									<Col offset={1} xs={24} lg={7}>
										<Row>
											<Tasks fieldName={[`${checked}`, reportId, 'task_id']} tasks={tasks} />
										</Row>
										<Row>
											<RiskCategories
												riskCategories={riskCategories}
												isLoading={gettingRiskCategories}
												isFetching={fetchingRiskCategories}
												collapseKey={[`${checked}`, reportId]}
												fieldName={[`${checked}`, reportId, 'risk_category_id']}
												damagesFieldName={[`${checked}`, reportId, 'risk_damage_id']}
												descriptionFieldName={[`${checked}`, reportId, 'risk_description_id']}
											/>
										</Row>
										<Row>
											<RiskDescriptions
												reportId={reportId}
												riskDescriptions={riskDescriptions}
												isLoading={gettingRiskDescriptions}
												isFetching={fetchingRiskDescriptions}
												collapseKey={[`${checked}`, reportId]}
												fieldName={[`${checked}`, reportId, 'risk_description_id']}
												damagesFieldName={[`${checked}`, reportId, 'risk_damage_id']}
											/>
										</Row>
										<Row>
											<RiskDamages
												stepKeyId={stepKeyId}
												riskDamages={riskDamages}
												isLoading={gettingRiskDamages}
												isFetching={fetchingRiskDamages}
												collapseKey={[`${checked}`, reportId]}
												fieldName={[`${checked}`, reportId, 'risk_damage_id']}
											/>
										</Row>
										<Row>
											<Evaluators fieldName={[`${checked}`, reportId, 'evaluator_id']} />
										</Row>
									</Col>
									<Col xs={24} lg={6}>
										<Row style={{ height: '80%' }} justify="space-between">
											<Col xs={24}>
												<Row>
													<Specifications
														fieldName={[`${checked}`, reportId, 'specifications']}
													/>
												</Row>
											</Col>
											<Col xs={24}>
												<Row style={{ marginTop: '2rem' }}>
													<ExistingPreventionMeasures
														fieldName={[
															`${checked}`,
															reportId,
															'existing_prevention_measures'
														]}
													/>
												</Row>
											</Col>
										</Row>
									</Col>
									<Col xs={24} lg={10}>
										<Row>
											<Col xs={12}>
												<Row>
													<Exposures
														fieldName={[`${checked}`, reportId, 'exposure_id']}
														exposures={exposures}
														isLoading={gettingExposures}
														isFetching={fetchingExposures}
													/>
												</Row>
												<Row>
													<Vulnerabilities
														fieldName={[`${checked}`, reportId, 'vulnerability_id']}
														vulnerabilities={vulnerabilities}
														isLoading={gettingVulnerabilities}
														isFetching={fetchingVulnerabilities}
													/>
												</Row>
												<Row>
													<Severities
														fieldName={[`${checked}`, reportId, 'severity_id']}
														severities={severities}
														isLoading={gettingSeverities}
														isFetching={fetchingSeverities}
													/>
												</Row>
											</Col>
											<Col>
												<Divider
													type="vertical"
													style={{
														height: '100%',
														borderColor: '#00000033',
														margin: '0rem 1.5rem'
													}}
												/>
											</Col>
											<Col xs={6}>
												<Row gutter={48} style={{ height: '100%' }} justify="space-between">
													<Col xs={24}>
														<Row>
															<Col xs={24}>
																<Title>{I18n.get('Task RPN')}:</Title>
															</Col>
															<Col xs={24}>
																{taskRPN?.rpn && rpnPriority && (
																	<RPNScore
																		style={{ fontSize: '1rem' }}
																		priority={rpnPriority.color}
																	>
																		{taskRPN?.rpn}
																	</RPNScore>
																)}
															</Col>
														</Row>
													</Col>
													<Col xs={24}>
														<Row>
															<Col xs={24}>
																<Title>{I18n.get('Priority')}:</Title>
															</Col>
															<Col xs={24}>
																{rpnPriority && (
																	<RPNScore priority={rpnPriority.color}>
																		{I18n.get(rpnPriority.priority)}
																	</RPNScore>
																)}
															</Col>
														</Row>
													</Col>
												</Row>
											</Col>
										</Row>
										<Divider
											type="horizontal"
											style={{ width: '100%', borderColor: '#00000033', margin: '1.5rem 0rem' }}
										/>
										<Row justify="space-between">
											<Col>
												<ScoreTitle>{I18n.get('Exposure')}</ScoreTitle>:
												{taskRPN && <Score>{taskRPN.exposureScore}</Score>}
											</Col>
											<Col>
												<ScoreTitle>{I18n.get('Vulnerability')}</ScoreTitle>:
												{taskRPN && <Score>{taskRPN.vulnerabilityScore}</Score>}
											</Col>
											<Col>
												<ScoreTitle>{I18n.get('Severity')}</ScoreTitle>:
												{taskRPN && <Score>{taskRPN.severityScore}</Score>}
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</CollapseCheck>
				</Form.Item>
			</Col>
		</Row>
	);
};
