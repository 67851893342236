import React from 'react';
import { Col, DatePicker, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

export const CreatedAt = () => {
	return (
		<Col xs={24} sm={24} md={12} lg={8} xxl={8}>
			<Form.Item label={I18n.get('Created At')} name={['filter', 'created_at']}>
				<DatePicker.RangePicker format="DD-MM-YYYY" style={{ width: '100%' }} />
			</Form.Item>
		</Col>
	);
};
