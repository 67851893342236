import { Col, Row } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';
import React from 'react';

import { useLibertyMutualSubStepsContext } from './context';
import { useLibertyMutualContext } from '../../context';
import { CustomButton, CustomTooltip } from './styles';

export const BackButton = () => {
	const history = useHistory();
	const { custom_report } = useLibertyMutualContext();
	const { file_id } = useLibertyMutualSubStepsContext();

	function handleButtonClick() {
		let url = `/reporting/liberty-mutual/${file_id}?current_step=${1}`;
		if (custom_report) {
			url = `${url}&custom_report=${custom_report}`;
		}
		history.push(url);
	}
	return (
		<Row justify="center">
			<Col xs={24}>
				<CustomTooltip title={I18n.get('Go back to report list')}>
					<CustomButton type="link" onClick={handleButtonClick} icon={<LeftOutlined />}>
						{I18n.get('Back')}
					</CustomButton>
				</CustomTooltip>
			</Col>
		</Row>
	);
};
