import React from 'react';
import { Col, Row } from 'antd';

import { RiskBar } from '../../RiskBar';
import { RiskDataContainer } from './styles';
import { ENUM_FIVE_SCORE_SCALE, ScoresScale } from '../../types';
import { emptyDataRisks, Risk, RiskBarOptions } from '../../RiskBar/types';

type RiskDataProps = {
	scores: ScoresScale[];
	total: number;
};

export function RiskData({ scores, total }: RiskDataProps) {
	const riskBarOptions: RiskBarOptions = {
		caption: {
			align: 'center',
			display: 'right'
		},
		orientation: 'vertical'
	};

	function risksMapper(scores: ScoresScale[]): Risk[] {
		const risks: Risk[] = scores
			.filter((score) => score.amount > 0)
			.map(({ amount, type, id, score }, index) => {
				const risk: Risk = {
					...ENUM_FIVE_SCORE_SCALE[type],
					id,
					score,
					value: amount,
					position: index,
					percentage: calculatePercentage(amount)
				};
				return risk;
			});
		return risks.sort((a, b) => b.position! - a.position!);
	}

	function calculatePercentage(amount: number): string {
		return (amount / total) * 100 + '%';
	}

	return (
		<RiskDataContainer>
			<Row>
				<Col span={24} style={{ height: '300px' }}>
					{scores && total ? (
						<RiskBar risks={risksMapper(scores)} options={riskBarOptions} />
					) : (
						<RiskBar risks={emptyDataRisks} options={riskBarOptions} />
					)}
				</Col>
			</Row>
		</RiskDataContainer>
	);
}
