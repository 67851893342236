import React from "react";
import { LoadingContainer } from './styles';
import { Col, Row } from 'antd';
import { Paragraph, Title } from '@/components/Typography';
import loadingImage from '../../../../../assets/img/loadingCircle.png';
import DocumentIcon from './DocumentIcon';


function Loading() {
	return (
		<Row justify="center" gutter={[0, 10]} style={{ marginTop: '0.5rem' }}>
			<Col style={{ textAlign: 'center' }} span={20}>
				<Title level={3}>Generating a document</Title>
				<Paragraph>The created document is being generated and will be downloaded</Paragraph>
			</Col>
			<LoadingContainer span={24}>
				<React.Fragment>
					<img src={loadingImage} width={150} alt="loading" className='rotate' />
					<DocumentIcon />
				</React.Fragment>
			</LoadingContainer>
		</Row >
	);
}

export default Loading;
