import React, { useEffect, useState } from 'react';
import { Button, Col, Row, message } from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { useCountdown } from '@/hooks/v1/useCountdown';
import { setSession } from '@/redux/auth/actions';
import { Title } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';
import history from '@/store/history';

import { RecoveryTokensContainer } from './styles';
import { useCheck2FAContext } from '../context';

export const RecoveryTokens = () => {
	const dispatch = useDispatch();

	const { recoveryTokens, userSession, disabled } = useCheck2FAContext();
	const { handleOpenRecoveryTokensModal, handleEnableButton } = useCheck2FAContext();

	const [awaitTime, setAwaitTime] = useState<Date>();

	const timer = 12000; // 12 seconds
	const timeout = moment().add(timer, 'milliseconds').toDate();
	const { seconds } = useCountdown(awaitTime);
	const formattedSeconds = ('0' + seconds).slice(-2);

	useEffect(() => {
		setAwaitTime(timeout);
		setTimeout(() => handleEnableButton(), timer);
	}, []);

	function handleOkClick() {
		if (disabled) {
			return message.error(I18n.get("Can't close yet. Read the instructions."));
		}
		handleOpenRecoveryTokensModal(false);
		localStorage.setItem(
			'info_user',
			JSON.stringify({
				user: userSession.user,
				token: userSession.token,
				refresh_token: userSession.refresh_token
			})
		);
		dispatch(
			setSession({
				user: userSession.user,
				token: userSession.token,
				refresh_token: userSession.refresh_token
			})
		);
		history.push('/');
	}

	return (
		<RecoveryTokensContainer align="middle" justify="center">
			<Col span={24}>
				<Row justify="center">
					<Col span={24}>
						<Row justify="center">
							<Title
								align="center"
								style={{ fontWeight: '400', fontSize: '0.8rem', marginBottom: '2rem' }}
							>
								Here are your recovery codes, which will be requested should you need to recover your
								account. Keep in mind that they will not be accessible again after this moment, so it's
								crucial that you save them securely.
							</Title>
						</Row>
						<Row justify="center" style={{ marginBottom: '2rem' }}>
							{recoveryTokens.map((token) => (
								<Col key={token} span={12}>
									<Title align="center" style={{ fontWeight: '600', fontSize: '1rem' }}>
										{token}
									</Title>
								</Col>
							))}
						</Row>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Button disabled={seconds > 0 || disabled} onClick={handleOkClick} type="primary">
							{seconds > 0 || disabled ? formattedSeconds : I18n.get('Ok')}
						</Button>
					</Col>
				</Row>
			</Col>
		</RecoveryTokensContainer>
	);
};
