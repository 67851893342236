import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { useApplicationContext } from '@/context/v1/Application/context';
import { SectorSelect } from '@/components/ui/Inputs/SectorSelect';
import { useSectors } from '@/hooks/v1';

const { useFormInstance, useWatch } = Form;

export const Sectors = () => {
	const form = useFormInstance();
	const { company, organization } = useApplicationContext();

	const sectorId = useWatch('sector_id', form);

	const {
		data: sectors,
		isLoading,
		isFetching,
		isError
	} = useSectors({ organizationId: organization?.id, companyId: company?.id });

	function handleClearSector() {
		form.setFieldValue(['sector_id'], undefined);
		form.setFieldValue(['line_id'], undefined);
		form.setFieldValue(['workstation_id'], undefined);
	}

	function handleSectorSelect(id: string) {
		form.setFieldValue('sector_id', id);
		form.setFieldValue('line_id', undefined);
		form.setFieldValue('workstation_id', undefined);
	}

	return (
		<Col span={12}>
			<Form.Item
				name="sector_id"
				label={I18n.get('Sector')}
				rules={[
					{
						required: true,
						message: I18n.get('Select the sector')
					}
				]}
			>
				<SectorSelect
					sectors={sectors}
					sectorId={sectorId}
					loading={isFetching}
					fieldName="sector_id"
					handleClearSector={handleClearSector}
					handleSelectSector={handleSectorSelect}
					disabled={isLoading || isFetching || isError}
				/>
			</Form.Item>
		</Col>
	);
};
