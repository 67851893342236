import React from 'react';
import { Col, Form, InputNumber, Radio, Row } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { I18n } from '@aws-amplify/core';

const { useFormInstance, useWatch } = Form;

interface TaskTimeProps {
	taskTimeFieldName: NamePath;
	taskTimeFormatFieldName: NamePath;
}

export const TaskTime: React.FC<TaskTimeProps> = ({ taskTimeFieldName, taskTimeFormatFieldName }) => {
	const form = useFormInstance();
	const task_time_format = useWatch(taskTimeFormatFieldName, form);

	const options = [
		{
			value: 'seconds',
			label: I18n.get('Seconds')
		},
		{
			value: 'minutes',
			label: I18n.get('Minutes')
		}
	];

	const placeHolder = options.find(({ value }) => value === task_time_format);

	return (
		<Col xs={24} sm={24} md={12} lg={8}>
			<Row align="middle">
				<Col span={24}>
					<Row align="middle">
						<Col span={20}>
							<Form.Item
								name={taskTimeFieldName}
								label={I18n.get('Task time')}
								labelCol={{ span: 24 }}
								rules={[
									{
										required: true,
										message: I18n.get('Insert a task duration')
									}
								]}
							>
								<InputNumber
									style={{ width: '90%' }}
									placeholder={I18n.get(placeHolder?.label ?? 'Seconds')}
									min={1}
								/>
							</Form.Item>
						</Col>
						<Col span={4}>
							<Form.Item name={taskTimeFormatFieldName} label={' '} labelCol={{ span: 24 }}>
								<Radio.Group
									style={{ display: 'flex' }}
									value={task_time_format}
									defaultValue={I18n.get('seconds')}
									options={options}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};
