import React from 'react';
import { Badge, Col, Row } from 'antd';

import { Paragraph, Text } from '@/components/Typography';
import { Description } from './styles';
import { Tasks } from '@/hooks';

interface TaskProps {
	task: Tasks;
}

export const Task: React.FC<TaskProps> = ({ task }) => {
	return (
		<Row align="middle" justify="space-between">
			<Badge status="default" />
			<Description lg={18} xl={20}>
				<Paragraph>{task.description}</Paragraph>
			</Description>
			<Col lg={5} xl={3}>
				<Text>{task.type}</Text>
			</Col>
		</Row>
	);
};
