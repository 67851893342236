import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row } from 'antd';

import { SummaryResult } from '../../../../hooks/types/response';
import { Body, CardContainer, Header } from '../styles';

interface FrequencyCardProps {
	summary: SummaryResult;
}

export const FrequencyCard: React.FC<FrequencyCardProps> = ({ summary }) => {
	return (
		<CardContainer>
			<Header>{I18n.get('Frequency')}</Header>
			<Body style={{ padding: '0 0 1rem 0' }}>
				<Row justify="center" style={{ fontSize: '1.5rem', padding: '1rem 0 0 0' }}>
					{summary.frequency.value}
				</Row>
				<Row justify="center" style={{ fontSize: '0.9rem', padding: '0rem 2rem' }}>
					{`${I18n.get(summary.frequency.type)} ${I18n.get('per')} ${I18n.get(summary.frequency.interval)}`}
				</Row>
			</Body>
		</CardContainer>
	);
};
