import React, { ReactElement, useState } from 'react';
import { Select, Form, Skeleton, Button, Input, Divider } from 'antd';
import type { SizeType } from 'antd/lib/config-provider/SizeContext';
import type { NamePath } from 'antd/lib/form/interface';
import { I18n } from '@aws-amplify/core';

import type { CreateRiskDescriptionRequest } from '../types/request/riskDescription';
import { useCreateRiskDescription } from '@/hooks/v1/useRiskDescriptionMutation';
import { useApplicationContext } from '@/context/v1/Application/context';
import type { RiskDescription } from '@/types';

const { useFormInstance } = Form;

type RiskDescriptionSelectProps = {
	size?: SizeType;
	loading: boolean;
	disabled: boolean;
	stepKeyId?: string;
	fieldName: NamePath;
	riskCategoryId: string;
	allowCreation?: boolean;
	riskDescriptionId?: string;
	handleClearRiskDescription?: () => void;
	riskDescriptions: RiskDescription[] | undefined;
	handleSelectRiskDescription?: (id: string) => void;
};

type OptionType = { label: string; value: string } | undefined;

export function RiskDescriptionSelect({
	loading,
	disabled,
	fieldName,
	stepKeyId,
	riskCategoryId,
	size = 'middle',
	riskDescriptions,
	riskDescriptionId,
	allowCreation = false,
	handleClearRiskDescription,
	handleSelectRiskDescription
}: Readonly<RiskDescriptionSelectProps>) {
	const [riskDescriptionName, setRiskDescriptionName] = useState<string>('');

	const { company, organization } = useApplicationContext();
	const { validateFields } = useFormInstance();

	const { mutateAsync: createRiskDescription, isLoading } = useCreateRiskDescription();

	async function onCreateRiskDescription() {
		const body: CreateRiskDescriptionRequest = {
			organization_id: organization.id,
			company_id: company.id,
			description: riskDescriptionName,
			risk_category_id: riskCategoryId,
			custom_report_step_key_id: stepKeyId
		};

		const { data: riskDescription } = await createRiskDescription({ body });
		const riskDescriptionId = riskDescription?.id;
		if (riskDescriptionId) {
			handleChange(riskDescriptionId);
		}
		setRiskDescriptionName('');
	}

	async function handleChange(id: string) {
		if (handleSelectRiskDescription) {
			handleSelectRiskDescription(id);
			await validateFields([fieldName]);
		}
	}

	function handleClear() {
		if (handleClearRiskDescription) {
			handleClearRiskDescription();
		}
	}

	function handleRiskDescriptionNameChange(name: string) {
		setRiskDescriptionName(name);
	}

	function filterOption(option: OptionType, input: string): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function showDropdownrender(menu: ReactElement): JSX.Element {
		if (!allowCreation) {
			return <div>{menu}</div>;
		}
		return (
			<div>
				{menu}
				<Divider style={{ margin: '4px 0' }} />
				<div
					style={{
						display: 'flex',
						flexWrap: 'nowrap',
						padding: 8
					}}
				>
					<Input
						style={{ flex: 'auto', borderRadius: '7px' }}
						value={riskDescriptionName}
						onChange={(e) => handleRiskDescriptionNameChange(e.target.value)}
					/>
					<Button
						onClick={onCreateRiskDescription}
						type="link"
						loading={isLoading}
						disabled={!riskDescriptionName || riskDescriptionName.length < 3}
					>
						{I18n.get('Add item')}
					</Button>
				</div>
			</div>
		);
	}

	if (loading) {
		return <Skeleton.Input block active size="small" />;
	}

	return (
		<Select
			showSearch
			allowClear
			size={size}
			loading={loading}
			disabled={disabled}
			onClear={handleClear}
			onChange={handleChange}
			value={riskDescriptionId}
			placeholder={I18n.get('Risk description')}
			dropdownRender={(menu) => showDropdownrender(menu)}
			filterOption={(input, option) => filterOption(option, input)}
			options={riskDescriptions?.map(({ id, description }) => ({ label: I18n.get(description), value: id }))}
		/>
	);
}
