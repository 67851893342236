import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Title } from '@/components/Typography';

import { useBackCompressiveForceEstimationContext } from '../../context';
import { Topic } from './ReportInformation/styles';
import { ResultBox } from './styles';

export const getTotalCompressiveForceColor = (resultForce: number) => {
	if (resultForce < 700) {
		return '#2cc852';
	}

	if (resultForce > 1400) {
		return '#e74150';
	}

	return '#f8d627';
};

export function ResultForce() {
	const {
		backCompressiveForceEstimationResult: { results }
	} = useBackCompressiveForceEstimationContext();

	const resultColor = getTotalCompressiveForceColor(results?.total_compressive_force_result || 0);

	return (
		<Row gutter={[0, 24]}>
			<Col xs={24}>
				<Row gutter={[16, 16]}>
					<Col xs={24}>
						<Title style={{ margin: 0 }} level={4}>
							Result
						</Title>
					</Col>
					<Col offset={1} xs={23}>
						<Topic>{I18n.get('Total compressive force')} (N):</Topic>
					</Col>
					<Col offset={1} xs={23}>
						<ResultBox color={resultColor}>
							<span>{results.total_compressive_force_result}</span>
						</ResultBox>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
