import React from 'react';
import { Row, Col, Grid } from 'antd';

import { Header } from './Header';
import { useJdsD92Context } from '../context';
import { DIRECTION } from '@/components/ui/StepsNavigation/types';
import { StepsNavigation } from '@/components/ui/StepsNavigation';
import * as S from './styles';

export function Report() {
	const breakpoints = Grid.useBreakpoint();
	const { steps, currentStep, jdsD92, handleStepNagivationClick } = useJdsD92Context();

	return (
		<Row justify="center">
			<Col xs={24}>
				<Header />
			</Col>
			<Col span={24} style={{ marginTop: '2rem' }}>
				<Row justify="center" gutter={[16, 0]}>
					<Col xs={24} xxl={4}>
						<StepsNavigation
							steps={steps}
							currentStep={currentStep}
							isComplete={jdsD92.is_completed}
							onStepNagivationClick={handleStepNagivationClick}
							direction={!breakpoints.xxl ? DIRECTION.HORIZONTAL : DIRECTION.VERTICAL}
						/>
					</Col>
					<S.CustomFormContainer xs={24} xxl={18} $isSmallScreen={`${breakpoints.lg}`}>
						{steps[currentStep]?.component}
					</S.CustomFormContainer>
				</Row>
			</Col>
		</Row>
	);
}
