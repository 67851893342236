import React from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { Col, Row, Skeleton } from 'antd';
import { Title } from '@/components/Typography';

import { Topic, Value } from './styles';
import { BeraResultInformations } from '../types';

interface SummaryProps {
	isLoading: boolean;
	data: Omit<BeraResultInformations, 'overall_score' | 'weighted_rsi' | 'total_time'>;
}

export const Summary: React.FC<SummaryProps> = ({ isLoading, data }) => {
	return (
		<Col span={14} offset={1}>
			<Row>
				<Title level={5}>Informations</Title>
			</Row>
			{isLoading ? (
				<Row>
					<Col span={22}>
						<Skeleton active />
					</Col>
				</Row>
			) : (
				<Row align="middle" gutter={[16, 4]} style={{ marginTop: '0.5rem' }}>
					<Col span={12}>
						<Row gutter={[0, 3]}>
							<Col span={24}>
								<Topic>{I18n.get('Company')}:</Topic>
								<Value>{data?.organization_name}</Value>
							</Col>
							<Col span={24}>
								<Topic>{I18n.get('Industrial site')}:</Topic>
								<Value>{data?.company_name}</Value>
							</Col>
							<Col span={24}>
								<Topic>{I18n.get('Sector')}:</Topic>
								<Value>{data?.sector_name}</Value>
							</Col>
							<Col span={24}>
								<Topic>{I18n.get('Line')}:</Topic>
								<Value>{data?.line_name}</Value>
							</Col>
							<Col span={24}>
								<Topic>{I18n.get('Workstation')}:</Topic>
								<Value>{data?.workstation_name}</Value>
							</Col>
						</Row>
					</Col>

					<Col span={11}>
						<Row gutter={[0, 3]}>
							<Col span={24}>
								<Topic>{I18n.get('Cycle')}:</Topic>
								<Value>{data?.cycle_name}</Value>
							</Col>
							<Col span={24}>
								<Topic>{I18n.get('Evaluator')}:</Topic>
								<Value>{data?.evaluator}</Value>
							</Col>
							<Col span={24}>
								<Topic>{I18n.get('Work center')}:</Topic>
								<Value>{data?.work_center ?? 'N/A'}</Value>
							</Col>
							<Col span={24}>
								<Topic>{I18n.get('Operator evaluated')}:</Topic>
								<Value>{data?.operator_evaluated ?? 'N/A'}</Value>
							</Col>
							<Col span={24}>
								<Topic>{I18n.get('Collection Date')}:</Topic>
								<Value>{data?.collection_date ? moment(data?.collection_date).format('L') : '-'}</Value>
							</Col>
						</Row>
					</Col>
				</Row>
			)}
		</Col>
	);
};
