import { useQuery } from '@tanstack/react-query';
import { DownloadVideoApiService } from './service';
import { GetDownloadVideoURLRequest } from './types';

const downloadVideoApiService = new DownloadVideoApiService();

export const useGetDownloadVideoURL = ({
	organization_id,
	company_id,
	file_id,
	original_name
}: GetDownloadVideoURLRequest) => {
	const parameters = { organization_id, company_id, file_id, original_name };
	const video = useQuery(
		['download_video_url', parameters],
		() => downloadVideoApiService.getDownloadVideoURL(parameters),
		{
			enabled: !!organization_id && !!company_id && !!file_id && !!original_name
		}
	);
	return video;
};
