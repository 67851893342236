import React, { useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';
import { Col, Modal, Row } from 'antd';
import moment from 'moment';

import { useApplicationContext } from '@/context/v1/Application/context';
import { Title } from '@/components/Typography';

import { DeleteSeraSummaryRequest, FilterRequest } from './hooks/types/request';
import { SeraSummaryListDTO } from './hooks/types/response';
import { useDeleteSeraSummary, useSeraSummaryList } from './hooks/hooks';
import { Filter, FormFields } from './Filter';
import { ReportList } from './ReportList';

export const SeraSummaryList: React.FC = () => {
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const { organization, company } = useApplicationContext();

	const queryParams = convertURLParamToObject(searchParams);

	const [page, setPage] = useState(0);
	const [maxReports, setMaxReports] = useState(10);
	const [filter, setFilter] = useState<FilterRequest>(queryParams);

	const initialValues: FormFields = mapFilterInitialValues(queryParams) ?? {};

	const { mutateAsync: deleteSummary, isLoading: deletingSeraSummary } = useDeleteSeraSummary();
	const {
		data: seraSummaries,
		isLoading,
		isFetching
	} = useSeraSummaryList({
		organization_id: organization?.id,
		company_id: company?.id,
		filter: {
			...filter,
			offset: page === 0 ? String(page) : String(page - 1),
			limit: String(maxReports)
		}
	});

	function handlePageChange(page: number) {
		setPage(page);
	}

	function handleMaxReportsChange(reports: number) {
		setMaxReports(reports);
	}

	function handleFilterClick(filterData: FilterRequest) {
		setFilter(filterData);
	}

	function mapFilterInitialValues(queryParams: FilterRequest) {
		if (Object.keys(queryParams)?.length > 0) {
			const { created_at_start, created_at_end, reviewed_at_start, reviewed_at_end } = queryParams;

			const created_at =
				created_at_start && created_at_end ? [moment(created_at_start), moment(created_at_end)] : undefined;
			const reviewed_at =
				reviewed_at_start && reviewed_at_end ? [moment(reviewed_at_start), moment(reviewed_at_end)] : undefined;
			const initialValues: FormFields = {
				...queryParams,
				created_at,
				reviewed_at
			};
			return initialValues;
		}
	}

	async function handleDeleteSummary(id: string) {
		Modal.confirm({
			title: I18n.get('Heads up! Want to delete this report?'),
			icon: <ExclamationCircleOutlined />,
			okText: I18n.get('Confirm'),
			cancelText: I18n.get('Cancel'),
			okType: 'danger',
			onOk: async () => {
				const body: DeleteSeraSummaryRequest = {
					company_id: company?.id,
					organization_id: organization?.id,
					id
				};
				await deleteSummary({ body });
			}
		});
	}

	function convertURLParamToObject(searchParams: URLSearchParams) {
		return Object.fromEntries(searchParams.entries());
	}

	const dataSource: SeraSummaryListDTO = seraSummaries;

	return (
		<Row gutter={[0, 10]} justify="center">
			<Col span={24}>
				<Title level={4}>Safety Ergonomic Risk Assessment (S.E.R.A.)</Title>
			</Col>
			<Col span={24}>
				<Filter loading={isFetching} onFilterClick={handleFilterClick} initialValues={initialValues} />
			</Col>
			<Col span={24}>
				<ReportList
					data={dataSource}
					page={page}
					maxReports={maxReports}
					onPageChange={handlePageChange}
					onDeleteSummary={handleDeleteSummary}
					onMaxReportsChange={handleMaxReportsChange}
					isLoading={isLoading || isFetching || deletingSeraSummary}
				/>
			</Col>
		</Row>
	);
};
