import React from 'react';
import { Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CustomFormItem } from '../styles';
import { useStepKeysContext } from '../context';
import { RequiredLabel } from '../../../RequiredLabel';
import { VulnerabilitySelect } from '@/components/ui/Inputs/VulnerabilitySelect';

const { useFormInstance, useWatch } = Form;

interface VulnerabilitiesProps {
	formFieldName: string[];
}

export const Vulnerabilities: React.FC<VulnerabilitiesProps> = ({ formFieldName }) => {
	const form = useFormInstance();
	const {
		vulnerabilitiesData: { fetchingVulnerabilities, gettingVulnerabilities, vulnerabilities }
	} = useStepKeysContext();

	const fieldName = [...formFieldName, 'vulnerability_id'];
	const vulnerabilityId = useWatch(fieldName, form);
	const riskDamageId = useWatch([...formFieldName, 'risk_damage_id'], form);

	async function handleVulnerabilitySelect(id: string) {
		form.setFieldValue(fieldName, id);
	}

	async function handleClearVulnerability() {
		form.setFieldValue(fieldName, undefined);
	}

	return (
		<Col xs={24} sm={12} lg={24}>
			<CustomFormItem
				name={fieldName}
				labelCol={{ span: 24 }}
				label={<RequiredLabel title="Vulnerability" isRequired={true} />}
				rules={[
					{
						required: true,
						message: I18n.get('Select the vulnerability')
					}
				]}
			>
				<VulnerabilitySelect
					allowCreation={false}
					fieldName={fieldName}
					vulnerabilityId={vulnerabilityId}
					loading={fetchingVulnerabilities}
					vulnerabilities={vulnerabilities}
					handleClearVulnerability={handleClearVulnerability}
					handleSelectVulnerability={handleVulnerabilitySelect}
					disabled={gettingVulnerabilities || fetchingVulnerabilities || !riskDamageId}
				/>
			</CustomFormItem>
		</Col>
	);
};
