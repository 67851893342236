import React from 'react';
import { Table, Row, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import { JdsD86List, JdsD86ListDTO } from './hooks/types/response';
import { CustomTable, TagRisk } from './styles';
import { Actions } from './Actions';
import { PaginationCustom } from '@/components/ui/Pagination';
import { Text } from '@/components/Typography';
import { riskColors, riskLevels } from '@/utils/riskRange';
import { SelectedReport } from './types';

type ReportListProps = {
	page: number;
	isLoading: boolean;
	maxReports: number;
	onReviewsClick: (id: string) => void
	data: JdsD86ListDTO;
	onPageChange: (page: number) => void;
	onDelete: (report: SelectedReport) => void;
	onMaxReportsChange: (size: number) => void;
};

export const ReportList: React.FC<ReportListProps> = ({
	data,
	page,
	isLoading,
	maxReports,
	onReviewsClick,
	onPageChange,
	onDelete,
	onMaxReportsChange
}: ReportListProps) => {
	const columns: ColumnsType<JdsD86List> = [
		{
			title: <Text style={{ fontWeight: 'bold' }}>{I18n.get('Evaluations')}</Text>,
			dataIndex: 'report_name',
			key: 'report_name',
			ellipsis: true,
			align: 'center',
			sorter: (a, b) => sortByName(a.report_name, b.report_name)
		},
		{
			title: <Text style={{ fontWeight: 'bold' }}>{I18n.get('Workstation')}</Text>,
			dataIndex: 'workstation',
			key: 'workstation',
			ellipsis: true,
			align: 'center',
			render: (workstation: string) => workstation ?? '-',
			responsive: ['lg'],
			sorter: (a, b) => sortByName(a.workstation, b.workstation)
		},
		{
			title: <Text style={{ fontWeight: 'bold' }}>{I18n.get('Created date')}</Text>,
			dataIndex: 'created_at',
			key: 'created_at',
			ellipsis: true,
			align: 'center',
			responsive: ['lg'],
			render: (created_at) => (created_at ? moment(created_at).format('L') : '--/--/----'),
			sorter: (a, b) => sortByDate(a.created_at, b.created_at)
		},
		{
			title: <Text style={{ fontWeight: 'bold' }}>{I18n.get('Last revaluation')}</Text>,
			dataIndex: 'last_review_date',
			key: 'last_review_date',
			ellipsis: true,
			align: 'center',
			responsive: ['lg'],
			render: (last_review_date) => (last_review_date ? moment(last_review_date).format('L') : '--/--/----'),
			sorter: (a, b) => sortByDate(a.last_review_date, b.last_review_date)
		},
		{
			title: <Text style={{ fontWeight: 'bold' }}>{I18n.get('Rating')}</Text>,
			dataIndex: 'result',
			ellipsis: true,
			key: 'result',
			align: 'center',
			responsive: ['lg'],
			render: (num) =>
				num ? (
					<TagRisk
						brightcolors={num}
						color={riskColors[num]}
					>
						<Tooltip title={I18n.get(`${riskLevels[num]}`)}>
							{num === 5 ? (
								<Text ellipsis={true}>{I18n.get('Serious risk')}</Text>
							) : (
								<Text ellipsis={true}>
									{I18n.get(riskLevels[num])}
								</Text>
							)}
						</Tooltip>
					</TagRisk>
				) : (
					I18n.get('Not assigned')
				)
		},
		{
			title: <Text style={{ fontWeight: 'bold' }}>{I18n.get('Actions')}</Text>,
			key: 'actions',
			align: 'center',
			render: (_value, rowData) => (
				<Actions
					rowData={rowData}
					onReviewsClick={onReviewsClick}
					onDeleteActionClick={onDelete}
				/>
			)
		}
	];

	function handlePaginationChange(page: number) {
		onPageChange(page);
	}

	function handleShowSizeChange(size: number) {
		onMaxReportsChange(size);
	}

	function sortByName(first: string | undefined, second: string | undefined) {
		if (!first || !second) {
			return -1;
		}
		return ('' + first).localeCompare(second);
	}

	const dataSource = data.rows
		?.map((report, index) => ({
			...report,
			key: String(index + 1)
		}))
		.sort((a, b) => sortByDate(a.created_at, b.created_at));

	function sortByDate(date_a: Date | null, date_b: Date | null): number {
		return moment.utc(date_b).diff(moment.utc(date_a));
	}

	return (
		<Row justify="center">
			<CustomTable span={24}>
				<Table
					showSorterTooltip={false}
					rowKey="key"
					dataSource={dataSource}
					columns={columns}
					loading={isLoading}
					pagination={false}
				/>
				<PaginationCustom
					loading={isLoading}
					maxPage={maxReports}
					page={page}
					setMaxPage={handleShowSizeChange}
					setPage={handlePaginationChange}
					total={data?.count}
				/>
			</CustomTable>
		</Row>
	);
};
