import React, { useEffect, useState } from 'react';
import { Title } from '@/components/Typography';
import { Col, Divider, Form, Row } from 'antd';
import { useParams } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';

import { BeraJobStepsRouteParams, useBeraJobStepsContext } from '../context';
import { useApplicationContext } from '@/context/v1/Application/context';
import { TasksAnalyzed } from './TasksAnalyzed';
import { Informations } from './Informations';
import { CycleSummary } from './CycleSummary';
import { PayloadDownloadPDF } from './types';
import { ResultsContainer } from './styles';
import { useBeraJobResults } from './hooks';
import { DownloadPDF } from './DownloadPDF';
import { HighestRPN } from './HighestRPN';
import { Comment } from './Comment';
import { Header } from './Header';

const { useForm } = Form;

export const BeraJobResult: React.FC = () => {
	const [form] = useForm();
	const { beraJobSummaryId } = useBeraJobStepsContext();
	const { organization, company } = useApplicationContext();
	const { bera_job_summary_id } = useParams<BeraJobStepsRouteParams>();

	const [isEditingComment, setIsEditingComment] = useState<boolean>(false);

	const { data, isLoading, isError } = useBeraJobResults({
		company_id: company?.id,
		organization_id: organization?.id,
		bera_job_summary_id: bera_job_summary_id ?? beraJobSummaryId
	});

	const browserLanguage = window.navigator.language ?? 'en-US';

	const payloadDownloadPDF: PayloadDownloadPDF = {
		organization_id: organization?.id,
		company_id: company?.id,
		bera_job_summary_id: bera_job_summary_id ?? beraJobSummaryId,
		locale: browserLanguage
	};

	useEffect(() => {
		if (data?.comment) {
			form.setFieldValue('comment', data?.comment);
		}
	}, [data]);

	if (isError) {
		return (
			<Row justify="center">
				<Col span={24}>
					<span>{I18n.get('Oops... Something went wrong!')}</span>
				</Col>
			</Row>
		);
	}

	function handleCommentChange(editingComment: boolean) {
		setIsEditingComment(editingComment);
	}

	return (
		<Row justify="center">
			<Col span={24} style={{ marginBottom: '2rem' }}>
				<Title level={4}>Result</Title>
			</Col>
			<Col xs={24}>
				<Form form={form}>
					<ResultsContainer>
						<Header />
						<Divider type="horizontal" />

						<Informations isLoading={isLoading} informations={data?.informations} />
						<Divider type="horizontal" />

						<TasksAnalyzed isLoading={isLoading} tasksAnalyzed={data?.analyzed_files} />
						<Divider type="horizontal" />

						<CycleSummary isLoading={isLoading} cycleSummary={data?.cycle_summary} />
						<Divider type="horizontal" />

						<HighestRPN isLoading={isLoading} data={data?.highest_rpn_scores} />
						<Divider type="horizontal" />

						<Comment isLoading={isLoading} onCommentChange={handleCommentChange} />
						<DownloadPDF parameters={payloadDownloadPDF} disableDownload={isEditingComment} />
					</ResultsContainer>
				</Form>
			</Col>
		</Row>
	);
};
