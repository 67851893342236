import styled from 'styled-components';
import { Col, Form, Steps } from 'antd';

type CustomStepsProps = {
	active: number;
	result: string;
};

export const BeraReportForm = styled(Form)`
	max-width: 1240px;
	display: flex;
	justify-content: center;
`;

export const CurrentStep = styled.div`
	padding: 0 1rem;
	text-align: center;
	text-decoration: none;
	border-radius: 16px;
	height: 30px;
	width: max-content;
	font-size: 1rem;
	color: #ffffff;
	background-color: #2f54eb;
`;

export const PreviousCol = styled(Col)`
	padding: 0;

	margin-right: 1rem;
	@media (min-width: 576px) {
		margin-right: 4rem;
	}
`;

export const StepsMenuCol = styled(Col)`
	padding: 0 !important;
	margin: 2rem 2rem 0 0;
`;

export const CustomSteps = styled(Steps)<CustomStepsProps>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;

	.ant-steps-item {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		user-select: none;
		:nth-child(${(props) => props.active + 1}) {
			.ant-steps-item-container {
				.ant-steps-item-icon {
					width: 100%;
				}
			}
		}
		:not(:nth-child(${(props) => props.active + 1})) {
			.ant-steps-item-container {
				transition: all 0.2s;
				&:hover {
					filter: brightness(0.9);
				}
				cursor: pointer;
			}
		}
		:last-child {
			.ant-steps-item-container {
				cursor: ${({ result }) => (result === 'true' ? 'pointer' : 'auto')};
				&:hover {
					filter: brightness(${({ result }) => (result === 'true' ? 0.9 : 1)});
				}
			}
		}
		.ant-steps-item-container {
			.ant-steps-item-icon {
				margin: 0;
				float: none;
			}
			.ant-steps-item-content {
				display: none;
			}
		}
	}
`;
