import React, { useState } from 'react';
import momentDurationFormat from 'moment-duration-format';
import { Row, Col, Button } from 'antd';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import { ENUM_RECURRENCE_PLAN } from '@/util/enum';
import { Container, Description, Value } from './styles.js';
import { ErrorRequest } from '@/components/Results/Errors';
import { SpinLoading } from '@/components/Spin';
import { ModalMinutePurchase } from './Modal';
import { usePlan } from '@/hooks/usePlan/index.ts';

momentDurationFormat(moment);

const base_url = 'https://kinebot.com.br';

export function CustomerPlan() {
	const [visibleModal, setVisibleModal] = useState(false);

	const { data, isLoading: loading, error } = usePlan();

	function translateRecurrence(type) {
		if (!type) return '-';
		const item = ENUM_RECURRENCE_PLAN[type];
		const translate = I18n.get(item);
		return translate;
	}

	function onControl() {
		setVisibleModal((prev) => !prev);
	}

	// function onChangeSignature() {
	// 	window.open(`${base_url}/dados-cadastrais-e-compras/my-subscription`);
	// }

	function onCancelSignature() {
		window.open(`${base_url}/dados-cadastrais-e-compras/my-subscription`);
	}

	if (loading) {
		return <SpinLoading />;
	}

	if (error) {
		return (
			<ErrorRequest title={I18n.get('Sorry, something went wrong :(')}>
				<Button type="primary" onClick={() => window.location.reload()}>
					{I18n.get('Retry')}
				</Button>
			</ErrorRequest>
		);
	}

	return (
		<Container>
			<Row gutter={[20, 20]} style={{ paddingBottom: '15px' }}>
				<Col xs={12} sm={6} md={6}>
					<Description>{I18n.get('Subscription type')}:</Description>
					<Value>{data.plan?.name}</Value>
				</Col>
				<Col xs={12} sm={6} md={6}>
					<Description>{I18n.get('Recurrence')}:</Description>
					<Value>{translateRecurrence(data.plan?.product?.type)}</Value>
				</Col>
				<Col xs={12} sm={6} md={6}>
					<Description>{I18n.get('Expiration date')}:</Description>
					<Value>{data.expiration_plan ? moment(data.expiration_plan).format('L') : ''}</Value>
				</Col>
				{data.canceled_on && (
					<Col xs={12} sm={6} md={8}>
						<Description>{I18n.get('Canceled on')}:</Description>
						<Value>{moment(data.canceled_on).format('L')}</Value>
					</Col>
				)}
			</Row>
			<Row gutter={[20, 20]} style={{ paddingBottom: '15px' }}>
				<Col xs={12} sm={6} md={6}>
					<Description>{I18n.get('Active users')}:</Description>
					<Value>
						{data.created_users ?? 0}/{data.plan?.max_users ?? 0}
					</Value>
				</Col>
				<Col xs={12} sm={6} md={6}>
					<Description>{I18n.get('Processed videos')}:</Description>
					<Value>{data.processed_videos ?? 0}</Value>
				</Col>
				<Col xs={12} sm={6} md={6}>
					<Description>{I18n.get('Processing time')}:</Description>
					<Value>
						{moment.duration(data.minutes_processed ?? 0, 'seconds').format('mm:ss', { trim: false })}{' '}
						{I18n.get('of')}{' '}
						{moment.duration(data.plan?.max_minutes ?? 0, 'seconds').format('m', {
							trim: false,
							useGrouping: false
						})}{' '}
						{I18n.get('minutes')}
					</Value>
				</Col>
			</Row>
			<Row style={{ paddingBottom: '15px' }}>
				<Col xs={12} md={24}>
					<Description>{I18n.get('Additional minutes')}:</Description>
					<Value>{moment.duration(data.credits?.minutes ?? 0, 'seconds').format('m:s')}</Value>
				</Col>
			</Row>
			<Row type="flex" gutter={16} style={{ paddingTop: '35px' }}>
				{/* {data.plan?.name !== 'test' && (
					<Col>
						<Button type="primary" onClick={onControl}>
							{I18n.get('Additional minutes')}
						</Button>
					</Col>
				)}
				<Col>
					<Button type="primary" onClick={onChangeSignature}>
						{data.canceled_on ? I18n.get('Renew subscription') : I18n.get('Change subscription')}
					</Button>
				</Col> */}
				<Col>
					<Button type="danger" onClick={onCancelSignature}>
						{I18n.get('Cancel subscription')}
					</Button>
				</Col>
			</Row>
			<ModalMinutePurchase visible={visibleModal} onCancel={onControl} />
		</Container>
	);
}
