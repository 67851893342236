import Api from '@/services/api';
import {
	TFATypes,
	AuthUserDTO,
	Check2FADTO,
	Reset2FADTO,
	SendTokenDTO,
	Validate2FADTO,
	Generate2FADTO,
	ResendTokenDTO,
	AuthUserRequest,
	Check2FARequest,
	Reset2FARequest,
	SendTokenRequest,
	ResendTokenRequest,
	Validate2FARequest
} from '@/types/AuthUser';

export class AuthService {
	public async authUser(payload: AuthUserRequest) {
		const url = '/auth/session';
		const { data } = await Api.post<AuthUserDTO>(url, payload);
		return data;
	}

	public async generate2FA(type: TFATypes): Promise<Generate2FADTO> {
		const url = '/2fa';
		const { data } = await Api.post<Generate2FADTO>(url, { data: { type } });
		return data;
	}

	public async resendToken(body: ResendTokenRequest): Promise<ResendTokenDTO> {
		const url = '/2fa/resend';
		const { data } = await Api.post<ResendTokenDTO>(url, body);
		return data;
	}

	public async sendToken(body: SendTokenRequest): Promise<SendTokenDTO> {
		const url = '/2fa/send-token';
		const { data } = await Api.post<SendTokenDTO>(url, body);
		return data;
	}

	public async reset2FA(body: Reset2FARequest): Promise<Reset2FADTO> {
		const url = '/2fa/reset';
		const { data } = await Api.put<Reset2FADTO>(url, body);
		return data;
	}

	public async check(body: Check2FARequest) {
		const url = '/2fa/check';
		const { data } = await Api.post<Check2FADTO>(url, body);
		return data;
	}

	public async validate2FA(body: Validate2FARequest): Promise<Validate2FADTO> {
		const url = '/2fa';
		const { data } = await Api.put<Validate2FADTO>(url, body);
		return data;
	}
}
