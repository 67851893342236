import React from 'react';
import { Row, Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Paragraph, Title } from '@/components/Typography';

import { UpdateCharacteristicsDTO, useCreateCharacteristicsResult, useUpdateCharacteristicsResult } from '@/hooks';
import { CustomButton } from '@/components/ui/Buttons/CustomButton/styles';
import { useApplicationContext } from '@/context/v1/Application/context';
import { TextArea } from '@/components/CustomReports';
import { LoadingSkeleton } from './LoadingSkeleton';
import { useEwaContext } from '../../../context';
import { PreviousCol } from '../../styles';
import * as I from './Inputs';

const { useFormInstance } = Form;

type CharacteristicsFields = {
	working_population_men: number;
	working_population_women: number;
	working_population_others: number;
	total_working_population: number;
	worker_self_evaluation_id: string;
	particularities_description?: string;
	worker_verbalization_description?: string;
};

export const Characteristics: React.FC = () => {
	const form = useFormInstance();
	const { organization, company } = useApplicationContext();
	const { ewa, handleNextStep, handlePreviousStep, scrollToError, currentStepId, customReport } = useEwaContext();

	const { mutateAsync: createCharacteristics, isLoading: creatingCharacteristics } = useCreateCharacteristicsResult();
	const { mutateAsync: updateCharacteristics, isLoading: updatingCharacteristics } = useUpdateCharacteristicsResult();

	async function handleValidation() {
		try {
			await form.validateFields();

			const characteristics: CharacteristicsFields = await form.getFieldValue(['characteristics']);
			const handledBody: UpdateCharacteristicsDTO = {
				...characteristics,
				step_id: currentStepId,
				company_id: company?.id,
				organization_id: organization?.id,
				custom_report_id: customReport.id,
				custom_report_result_id: ewa.result_id,
				characteristic_id: ewa.characteristics?.id,
				workstation_id: ewa?.informations?.workstation_id,
				working_population_men: characteristics.working_population_men ?? 0,
				total_working_population: characteristics.total_working_population ?? 0,
				working_population_women: characteristics.working_population_women ?? 0,
				working_population_others: characteristics.working_population_others ?? 0
			};

			if (ewa.characteristics?.id) {
				const updated = await updateCharacteristics(handledBody);
				if (updated?.id) {
					handleNextStep();
					return;
				}
				return;
			}

			const created = await createCharacteristics(handledBody);
			if (created?.id) {
				handleNextStep();
			}
		} catch (error) {
			scrollToError(error);
		}
	}

	const validateFormFields = async () => {
		await form.validateFields();
	};

	if (creatingCharacteristics || updatingCharacteristics) {
		return <LoadingSkeleton />;
	}

	return (
		<>
			<Row justify="center" gutter={[5, 20]}>
				<Col xs={21}>
					<Row align="middle">
						<Col xs={24}>
							<Title level={4}>Characteristics</Title>
						</Col>
						<Col xs={24} style={{ marginBottom: '20px' }}>
							<Paragraph>Select the restrictions perceived in the evaluated working condition</Paragraph>
						</Col>
					</Row>
					<Row justify="center">
						<Col span={23}>
							<Row justify="start" style={{ marginBottom: '2rem' }}>
								<Col span={24}>
									<Title level={5}>Working population</Title>
								</Col>
								<Col>
									<Row>
										<I.WorkingPopulationMen
											fieldName={'characteristics'}
											validate={validateFormFields}
										/>
										<I.WorkingPopulationWomen
											fieldName={'characteristics'}
											validate={validateFormFields}
										/>
										<I.WorkingPopulationOthers
											fieldName={'characteristics'}
											validate={validateFormFields}
										/>
										<I.TotalWorkingPopulation
											fieldName={'characteristics'}
											allFields={[
												'working_population_men',
												'working_population_women',
												'working_population_others'
											]}
										/>
									</Row>
								</Col>
							</Row>
							<Row justify="center" gutter={[0, 10]} style={{ marginBottom: '2rem' }}>
								<TextArea
									fontSize="16px"
									namePath={['characteristics', 'particularities_description']}
									title="Particularities / Production / Working regime"
								/>
								<TextArea
									fontSize="16px"
									namePath={['characteristics', 'worker_verbalization_description']}
									title="Worker's verbalization"
								/>
							</Row>
							<Row justify="start">
								<Col span={24}>
									<Title level={5}>Self-evaluation</Title>
								</Col>
								<Col span={24}>
									<Paragraph>
										What is the level of stress perceived by the worker when performing the task?
									</Paragraph>
								</Col>
								<I.RadioInput fieldName={'characteristics'} />
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row>
				<Col xs={24} style={{ marginTop: '2rem' }}>
					<Row justify="center" align="middle" gutter={[0, 8]}>
						<PreviousCol>
							<CustomButton size="large" type="default" onClick={handlePreviousStep}>
								{I18n.get('Previous')}
							</CustomButton>
						</PreviousCol>
						<Col>
							<CustomButton htmlType="submit" size="large" type="primary" onClick={handleValidation}>
								{I18n.get('Next')}
							</CustomButton>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};
