import React from 'react';
import { Text } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';
import { Col, Row } from 'antd';

interface RequiredLabelProps {
	title: string;
	fontSize?: string;
	isRequired?: boolean;
}

export const RequiredLabel: React.FC<RequiredLabelProps> = ({ title, isRequired = true, fontSize }) => (
	<Row justify={'start'} align={'middle'} gutter={[2, 0]}>
		<Col>
			<Text style={{ fontSize: `${fontSize}` }}>{I18n.get(title)}</Text>
		</Col>
		{isRequired && (
			<Col>
				<Text style={{ color: 'red' }}>*</Text>
			</Col>
		)}
	</Row>
);
