import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Text } from '@/components/Typography';

interface RequiredLabelProps {
	title: string;
	fontSize?: string;
	isRequired?: boolean;
}

export function RequiredLabel({ title, isRequired = true, fontSize }: RequiredLabelProps) {
	return (
		<Row justify="start" align="middle" gutter={[2, 0]}>
			<Col>
				<Text style={{ fontSize: `${fontSize}` }}>{I18n.get(title)}</Text>
			</Col>
			{isRequired && (
				<Col>
					<Text style={{ color: 'red' }}>*</Text>
				</Col>
			)}
		</Row>
	);
}
