import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { diagnosticColors } from '@/constants/Reba';

interface PieChartProps {
	scores: number[];
}

export const PieChart: React.FC<PieChartProps> = ({ scores }) => {
	const data = {
		datasets: [
			{
				backgroundColor: diagnosticColors,
				borderColor: diagnosticColors,
				borderWidth: 1,
				data: scores,
				datalabels: {
					display: false
				}
			}
		]
	};

	return <Doughnut data={data} />;
};
