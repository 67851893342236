import React from 'react';
import { DeleteOutlined, DownloadOutlined, FileAddOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { ActionsInline, ActionsMenu, CustomButton } from './styles';
import { BeraJobSummaryList } from './hooks/types/response';
import { useDownloadPDF } from '../BeraJob/Results/hooks';
import { useApplicationContext } from '@/context/v1/Application/context';

interface ActionsProps {
	rowData: BeraJobSummaryList;
	onDeleteActionClick: (id: string) => void;
	onEditActionClick: (rowData: BeraJobSummaryList) => void;
}

export const Actions: React.FC<ActionsProps> = ({ rowData, onEditActionClick, onDeleteActionClick }) => {
	const { organization, company } = useApplicationContext();
	const { mutateAsync: downloadPDF, isLoading: downloadingPDF } = useDownloadPDF();

	const disableDownload = !rowData.overall_score || rowData.overall_score === 0;
	const browserLanguage = window.navigator.language ?? 'en-US';

	async function handleDownloadJobSummary(jobSummaryData: BeraJobSummaryList) {
		if (jobSummaryData?.overall_score > 0) {
			await downloadPDF({
				organization_id: organization?.id,
				company_id: company?.id,
				bera_job_summary_id: jobSummaryData.id,
				locale: browserLanguage
			});
		}
	}

	const items = [
		{
			key: 1,
			icon: <FileAddOutlined />,
			label: (
				<Button type="link" onClick={() => onEditActionClick(rowData)}>
					{I18n.get('Edit')}
				</Button>
			)
		},
		{
			key: 2,
			icon: <DownloadOutlined />,
			label: (
				<Button
					disabled={disableDownload}
					loading={downloadingPDF}
					type="link"
					onClick={() => handleDownloadJobSummary(rowData)}
				>
					{I18n.get('Download')}
				</Button>
			)
		},
		{
			key: 3,
			danger: true,
			icon: <DeleteOutlined />,
			label: (
				<CustomButton>
					<Button type="link" onClick={() => onDeleteActionClick(rowData.id)}>
						{I18n.get('Delete')}
					</Button>
				</CustomButton>
			)
		}
	];

	return (
		<Row align="middle" justify="center">
			<ActionsInline xs={24}>
				<Row align="middle" justify="center">
					<Col span={8}>
						<Button
							onClick={() => {
								onEditActionClick(rowData);
							}}
							type="link"
							size="large"
							icon={<FileAddOutlined />}
						/>
					</Col>
					<Col span={8}>
						<Button
							disabled={disableDownload}
							onClick={() => handleDownloadJobSummary(rowData)}
							type="link"
							size="large"
							loading={downloadingPDF}
							icon={<DownloadOutlined />}
						/>
					</Col>
					<Col span={8}>
						<Button
							onClick={() => {
								onDeleteActionClick(rowData.id);
							}}
							type="link"
							size="large"
							icon={<DeleteOutlined style={{ color: '#E74150' }} />}
						/>
					</Col>
				</Row>
			</ActionsInline>
			<ActionsMenu xs={2}>
				<Dropdown menu={{ items }} trigger={['click']}>
					<Button type="link" icon={<MenuOutlined />} />
				</Dropdown>
			</ActionsMenu>
		</Row>
	);
};
