import React from 'react';
import { Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

export const PreviousButton = ({ onPrevious, style, iconStyle, disabled }) => (
	<Button
		icon={<LeftOutlined style={iconStyle} />}
		onClick={onPrevious}
		style={style}
		disabled={disabled}
	/>
);
