import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { useSectors } from '@/components/views/PreliminaryAnalysis/hooks';
import { useApplicationContext } from '@/context/v1/Application/context';
import { SectorSelect } from '@/components/ui/Inputs/SectorSelect';

const { useFormInstance, useWatch } = Form;

export const Sectors = () => {
	const form = useFormInstance();
	const { company, organization } = useApplicationContext();

	const sectorId = useWatch(['file', 'sector_id'], form);

	const { data: sectors, isLoading, isFetching, isError } = useSectors(organization?.id, company?.id);

	function handleClearSector() {
		form.setFieldValue(['file', 'sector_id'], undefined);
		form.setFieldValue(['file', 'line_id'], undefined);
		form.setFieldValue(['file', 'workstation_id'], undefined);
	}

	function handleSectorSelect(id: string) {
		form.setFieldValue(['file', 'sector_id'], id);
		form.setFieldValue(['file', 'line_id'], undefined);
		form.setFieldValue(['file', 'workstation_id'], undefined);
	}

	return (
		<Col span={12}>
			<Form.Item
				name={['file', 'sector_id']}
				label={I18n.get('Sector')}
				rules={[
					{
						required: true,
						message: I18n.get('Select the sector')
					}
				]}
			>
				<SectorSelect
					sectors={sectors}
					sectorId={sectorId}
					loading={isFetching}
					fieldName={['file', 'sector_id']}
					handleClearSector={handleClearSector}
					handleSelectSector={handleSectorSelect}
					disabled={isLoading || isFetching || isError}
				/>
			</Form.Item>
		</Col>
	);
};
