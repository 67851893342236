import styled from 'styled-components';
import { Button, Col } from 'antd';

export const CustomTable = styled(Col)`
	.ant-table-tbody {
		background-color: #f0f2f599;
		border: 1px solid red;
	}
`;

export const CustomDownloadButton = styled(Button)`
	padding: 0;
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	span {
		display: inline;
	}
`;
