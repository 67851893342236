import React, { useState } from 'react';
import { Col, Form } from 'antd';

import { FileForCustomReport } from '@/components/ui/Inputs/types/response/file';

import { CollapseCheck } from './CollapseCheck';

interface EmptyStepProps {
	stepDescription: string;
	stepId: string;
	file: FileForCustomReport;
	anyStepKeyChecked: boolean;
	onEmptyCheck: (value: boolean) => void;
}

export const EmptyStepKey: React.FC<EmptyStepProps> = ({
	stepDescription,
	stepId,
	file,
	anyStepKeyChecked,
	onEmptyCheck
}) => {
	const [checked, setChecked] = useState(false);

	function handleCheckEmptyStep(value: boolean) {
		setChecked(value);
		onEmptyCheck(value);
	}

	return (
		<Col span={22}>
			<Form.Item name={[file.id, stepId, 'empty_step']}>
				<CollapseCheck
					key={file.id}
					title={`There is no presence of "${stepDescription}" movements`}
					value={checked}
					anyOtherChecked={anyStepKeyChecked}
					onChange={handleCheckEmptyStep}
				/>
			</Form.Item>
		</Col>
	);
};
