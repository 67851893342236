import * as React from 'react';

export const NioshIcon: React.FC = () => {
	return (
		<svg width={27} height={28} viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M9.803 5.068h2.921v9.795c0 .127.142.232.316.232h2.372c.173 0 .316-.105.316-.232V5.068h2.928c.265 0 .411-.223.25-.374L14.478.59a.318.318 0 00-.11-.066.414.414 0 00-.278 0 .318.318 0 00-.11.066L9.554 4.692c-.162.153-.015.376.25.376z"
				fill="#262626"
			/>
			<rect x={1} y={19.0156} width={25} height={7.48649} rx={2} stroke="#262626" strokeWidth={2} />
		</svg>
	);
};
