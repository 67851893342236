import { Row, Col, Typography } from 'antd';
import * as Icons from '@ant-design/icons';
import styled from 'styled-components';

const { Text } = Typography;

export const Check = styled(Icons.CheckCircleOutlined)`
	margin: 2px;
	color: #52c41a;
	font-size: 1.3rem;
`;

export const Close = styled(Icons.CloseCircleOutlined)`
	margin: 2px;
	color: #ff4170;
	font-size: 1.3rem;
`;

export const Exclamation = styled(Icons.ExclamationCircleOutlined)`
	margin: 2px;
	color: #fdc365;
	font-size: 1.3rem;
`;

export const Title = styled(Text)`
	font-size: 1rem;
	font-weight: bold;
`;

export const Description = styled(Text)`
	font-size: 0.8rem;
`;

export const Container = styled(Col)`
	margin-top: 20px;
`;

export const InsideContainer = styled(Row)`
	margin-top: 20px;
`;
