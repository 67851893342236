import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col } from 'antd';

import { useLibertyMutualSubStepsContext } from '../../../context';
import { Topic, Value } from '../styles';

export const TaskName = () => {
	const {
		libertyMutualResult: {
			informations: { task_name }
		}
	} = useLibertyMutualSubStepsContext();

	return (
		<Col xs={24}>
			<Topic>{I18n.get('Task')}:</Topic>
			<Value>{I18n.get(task_name)}</Value>
		</Col>
	);
};
