import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form, Input } from 'antd';

export const ReportName: React.FC = () => {
	return (
		<Col xs={24} sm={12}>
			<Form.Item
				labelCol={{ span: 24 }}
				name={['report_name']}
				label={I18n.get('Report name')}
				rules={[
					{
						required: true,
						message: I18n.get('Enter a report name')
					}
				]}
			>
				<Input placeholder={I18n.get('Report name')} />
			</Form.Item>
		</Col>
	);
};
