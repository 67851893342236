import React from 'react';
import { Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useGetActivities } from '@/hooks';
import { FormItem } from '@/components/CustomReports';
import { ActivitySelect } from '@/components/ui/Inputs/ActivitySelect';
import { useApplicationContext } from '@/context/v1/Application/context';

const { useFormInstance, useWatch } = Form;

interface ActivitiesProps {
	fontSize?: string;
	fieldName: string;
	isRequired?: boolean;
}

export function Activities({ fontSize, fieldName, isRequired }: Readonly<ActivitiesProps>) {
	const form = useFormInstance();
	const { company, organization } = useApplicationContext();

	const activityId = useWatch([fieldName, 'activity_id'], form);
	const workstationId = useWatch([fieldName, 'workstation_id'], form);

	const {
		data: activities,
		isLoading,
		isFetching,
		isError
	} = useGetActivities({
		organization_id: organization?.id,
		company_id: company?.id,
		workstation_id: workstationId
	});

	function handleSelectActivities(id: string): void {
		form.setFieldValue([fieldName, 'activity_id'], id);
	}

	function handleClearActivities(): void {
		form.setFieldValue([fieldName, 'activity_id'], undefined);
	}

	return (
		<Col xs={24}>
			<FormItem
				title="Activity"
				fontSize={fontSize}
				namePath={fieldName}
				isRequired={isRequired}
				errorMessage={I18n.get('Select a activity')}
			>
				<ActivitySelect
					size="large"
					allowCreation
					loading={isFetching}
					options={activities}
					companyId={company.id}
					activityId={activityId}
					workstationId={workstationId}
					organizationId={organization.id}
					fieldName={[fieldName, 'activity_id']}
					handleSelectActivity={handleSelectActivities}
					handleClearActivities={handleClearActivities}
					disabled={isLoading || isFetching || isError || !workstationId}
				/>
			</FormItem>
		</Col>
	);
}
