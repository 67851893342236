import Api from '@/services/api';
import { BaseContext } from '@/types';
import { useQuery } from '@tanstack/react-query';

export type CustomReportReviewHistoryRequest = BaseContext & {
	review_id?: string;
	enabled?: boolean;
};

export interface Information {
	report_name: string
	organization_name: string
	company_name: string
	sector_name: string
	line_name: string
	workstation_name: string
}

export interface SumRpn {
	name: string
	created_at: string
	rpn: number
}

export interface HighestRpn {
	name: string
	created_at: string
	result: number
	worst_score: number
}

export interface ReviewHistory {
	id: string
	name: string
	created_at: string
	evaluator_name: string
	step_keys_history: StepKeysHistory[]
}

export interface StepKeysHistory {
	id: string
	name: string
	description: string
	changed_fields: ChangedField[]
	rpn: Rpn
}

export interface ChangedField {
	id: string
	field_name: string
	previous_field_value: string
	reviewed_field_value: string
}

export interface Rpn {
	previous: Previous
	reviewed: Reviewed
}

export interface Previous {
	score?: number
	result?: number
}

export interface Reviewed {
	score?: number
	result?: number
}

export interface CustomReportReviewHistoryDTO {
	informations: Information
	sum_rpns: SumRpn[]
	highest_rpns: HighestRpn[]
	reviews_history: ReviewHistory[]
	comment?: string
}

async function getCustomReportReviewHistory(payload: CustomReportReviewHistoryRequest): Promise<CustomReportReviewHistoryDTO> {
	const url = `/custom-report/review/history`;
	const { data } = await Api.get<CustomReportReviewHistoryDTO>(url, {
		params: payload
	});
	return data;
}

export function useGetCustomReportReviewHistory({ organization_id, company_id, review_id, enabled: fetch }: CustomReportReviewHistoryRequest) {
	const enabled = !!organization_id && !!company_id && !!review_id && fetch;
	const results = useQuery(
		['custom_report_reviews', [organization_id, company_id, review_id]],
		() => getCustomReportReviewHistory({ organization_id, company_id, review_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...results,
		data: results.data ?? ({} as CustomReportReviewHistoryDTO)
	};
}
