import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Title, Paragraph, Text } from '@/components/Typography';
import { useRiskLevel, useCreateStepDefault } from '@/components/views/PreliminaryAnalysis/hooks';

import { useApplicationContext } from '@/context/v1/Application/context';
import { usePreliminaryAnalysis } from '@/components/views/PreliminaryAnalysis/context';

import { SelectOption } from '../_components/SelectOption';
import { InjuresGroup } from '../_components/InjuresGroup';
import { CollapseCheck } from '../_components/CollapseCheck';
import { RiskLevelCard } from '../_components/RiskLevelCard';

import { baseFormName, injuresKeys, stepDescription } from '../contants';

const { useFormInstance } = Form;

export function Workday() {
	const { organization, company } = useApplicationContext();
	const { file, getLevelRiskFields } = usePreliminaryAnalysis();

	const form = useFormInstance();
	const formName = ['workday'];

	const stepDefault = useCreateStepDefault(organization?.id, company?.id, file?.id);
	const { isLoading, data, isError, ...riskLevel } = useRiskLevel(organization?.id, company?.id, file?.id);

	const [levelRisk, setLevelRisk] = useState({
		step: '',
		consequence_id: '',
		probability_id: '',
		exhibition_id: ''
	});

	useEffect(() => {
		stepDefault.mutate();
	}, []);

	useEffect(() => {
		const { step, consequence_id, probability_id, exhibition_id } = levelRisk;

		if (step && consequence_id && probability_id && exhibition_id) {
			const formatStep = Array.isArray(step) ? [...formName, ...step] : [...formName, step];

			const payload = {
				step: formatStep,
				consequence_id,
				probability_id,
				exhibition_id
			};

			riskLevel.mutate(payload, {
				onSuccess: (data) => {
					form.setFields([
						{
							name: [baseFormName, ...formatStep, 'result'],
							value: data?.risk_level
						}
					]);
				}
			});
		}
	}, [levelRisk.step, levelRisk.consequence_id, levelRisk.probability_id, levelRisk.exhibition_id]);

	function updateLevelRisk(step, attribute, value) {
		const stepName = Array.isArray(step) ? [...step] : [step];
		const { consequence, exhibition, probability } = getLevelRiskFields(form, baseFormName, formName, stepName);

		if (!levelRisk.step && !levelRisk.consequence_id && !levelRisk.exhibition_id && !levelRisk.probability_id) {
			const options = ['consequence_id', 'exhibition_id', 'probability_id'];
			const forms = options
				.filter((item) => item !== attribute)
				.map((value) => ({
					name: [baseFormName, ...formName, step, value],
					value: undefined
				}));

			form.setFields(forms);
		}

		setLevelRisk({
			exhibition_id: exhibition,
			consequence_id: consequence,
			probability_id: probability,
			step,
			[attribute]: value
		});
	}

	const {
		fatigue_complaints,
		absenteeism,
		musculoskeletal_complaints,
		complaints_of_mental_overload,
		complaints_of_irritability,
		non_conforming_products,
		others
	} = injuresKeys;

	function onResetFields(step) {
		form.setFields([
			{
				name: [baseFormName, ...formName, step, 'consequence_id'],
				value: undefined
			},
			{
				name: [baseFormName, ...formName, step, 'probability_id'],
				value: undefined
			},
			{
				name: [baseFormName, ...formName, step, 'exhibition_id'],
				value: undefined
			},

			{
				name: [baseFormName, ...formName, step, 'injuries'],
				value: undefined
			},
			{
				name: [baseFormName, ...formName, step, 'result'],
				value: undefined
			}
		]);
	}

	function setCheckedCollapse(step) {
		form.setFields([
			{
				name: [baseFormName, ...formName, step, 'checked'],
				value: true
			}
		]);
	}

	return (
		<Row gutter={[0, 10]}>
			<Col span={16} offset={2}>
				<Title level={4}>Workday and concentration at work</Title>
			</Col>
			<Col span={16} offset={2}>
				<Paragraph>{stepDescription}</Paragraph>
			</Col>
			<Col span={16} offset={2}>
				<Row gutter={[10, 10]}>
					{/* extended_workday */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'extended_workday', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('extended_workday');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Working hours of more than 8 hours a day">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[baseFormName, ...formName, 'extended_workday', 'injuries']}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'extended_workday',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuresGroup
												onChange={() => setCheckedCollapse('extended_workday')}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_mental_overload,
													complaints_of_irritability,
													non_conforming_products,
													others
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>{I18n.get('Consequence')}</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'extended_workday',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'extended_workday',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('extended_workday');
															updateLevelRisk(
																'extended_workday',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>{I18n.get('Probability')}</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'extended_workday',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'extended_workday',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('extended_workday');
															updateLevelRisk(
																'extended_workday',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>{I18n.get('Exposure')}</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'extended_workday',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'extended_workday',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('extended_workday');
															updateLevelRisk('extended_workday', 'exhibition_id', value);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item name={[baseFormName, ...formName, 'extended_workday', 'result']}>
											<RiskLevelCard
												key={[...formName, 'extended_workday']}
												step={[...formName, 'extended_workday']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					{/* long_overtime */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'long_overtime', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('long_overtime');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Existence of long and frequent overtime hours of work">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[baseFormName, ...formName, 'long_overtime', 'injuries']}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'long_overtime',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuresGroup
												onChange={() => setCheckedCollapse('long_overtime')}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_mental_overload,
													complaints_of_irritability,
													non_conforming_products,
													others
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'long_overtime',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'long_overtime',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('long_overtime');
															updateLevelRisk('long_overtime', 'consequence_id', value);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'long_overtime',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'long_overtime',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('long_overtime');
															updateLevelRisk('long_overtime', 'probability_id', value);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[baseFormName, ...formName, 'long_overtime', 'exhibition_id']}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'long_overtime',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('long_overtime');
															updateLevelRisk('long_overtime', 'exhibition_id', value);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item name={[baseFormName, ...formName, 'long_overtime', 'result']}>
											<RiskLevelCard
												key={[...formName, 'long_overtime']}
												step={[...formName, 'long_overtime']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					{/* continuous_operation */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'continuous_operation', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('continuous_operation');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Stays long time continuous operation">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[baseFormName, ...formName, 'continuous_operation', 'injuries']}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'continuous_operation',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuresGroup
												onChange={() => setCheckedCollapse('continuous_operation')}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_mental_overload,
													complaints_of_irritability,
													non_conforming_products,
													others
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'continuous_operation',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'continuous_operation',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('continuous_operation');
															updateLevelRisk(
																'continuous_operation',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'continuous_operation',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'continuous_operation',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('continuous_operation');
															updateLevelRisk(
																'continuous_operation',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'continuous_operation',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'continuous_operation',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('continuous_operation');
															updateLevelRisk(
																'continuous_operation',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item name={[baseFormName, ...formName, 'continuous_operation', 'result']}>
											<RiskLevelCard
												key={[...formName, 'continuous_operation']}
												step={[...formName, 'continuous_operation']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					{/* insufficient_rest_interval */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'insufficient_rest_interval', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('insufficient_rest_interval');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Insufficient rest interval">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[baseFormName, ...formName, 'insufficient_rest_interval', 'injuries']}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'insufficient_rest_interval',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuresGroup
												onChange={() => setCheckedCollapse('insufficient_rest_interval')}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_mental_overload,
													complaints_of_irritability,
													non_conforming_products,
													others
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'insufficient_rest_interval',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'insufficient_rest_interval',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('insufficient_rest_interval');
															updateLevelRisk(
																'insufficient_rest_interval',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'insufficient_rest_interval',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'insufficient_rest_interval',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('insufficient_rest_interval');
															updateLevelRisk(
																'insufficient_rest_interval',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'insufficient_rest_interval',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'insufficient_rest_interval',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('insufficient_rest_interval');
															updateLevelRisk(
																'insufficient_rest_interval',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item
											name={[baseFormName, ...formName, 'insufficient_rest_interval', 'result']}
										>
											<RiskLevelCard
												key={[...formName, 'insufficient_rest_interval']}
												step={[...formName, 'insufficient_rest_interval']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					{/* insufficient_rest_days */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'insufficient_rest_days', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('insufficient_rest_days');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Insufficient rest days">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[baseFormName, ...formName, 'insufficient_rest_days', 'injuries']}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'insufficient_rest_days',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuresGroup
												onChange={() => setCheckedCollapse('insufficient_rest_days')}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_mental_overload,
													complaints_of_irritability,
													non_conforming_products,
													others
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'insufficient_rest_days',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'insufficient_rest_days',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('insufficient_rest_days');
															updateLevelRisk(
																'insufficient_rest_days',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'insufficient_rest_days',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'insufficient_rest_days',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('insufficient_rest_days');
															updateLevelRisk(
																'insufficient_rest_days',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'insufficient_rest_days',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'insufficient_rest_days',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('insufficient_rest_days');
															updateLevelRisk(
																'insufficient_rest_days',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item
											name={[baseFormName, ...formName, 'insufficient_rest_days', 'result']}
										>
											<RiskLevelCard
												key={[...formName, 'insufficient_rest_days']}
												step={[...formName, 'insufficient_rest_days']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					{/* unbalanced_work_concentration */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'unbalanced_work_concentration', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('unbalanced_work_concentration');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Unbalanced concentrations of work in a day, week, month or year">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[
												baseFormName,
												...formName,
												'unbalanced_work_concentration',
												'injuries'
											]}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'unbalanced_work_concentration',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuresGroup
												onChange={() => setCheckedCollapse('unbalanced_work_concentration')}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_mental_overload,
													complaints_of_irritability,
													non_conforming_products,
													others
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'unbalanced_work_concentration',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'unbalanced_work_concentration',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('unbalanced_work_concentration');
															updateLevelRisk(
																'unbalanced_work_concentration',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'unbalanced_work_concentration',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'unbalanced_work_concentration',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('unbalanced_work_concentration');
															updateLevelRisk(
																'unbalanced_work_concentration',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'unbalanced_work_concentration',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'unbalanced_work_concentration',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('unbalanced_work_concentration');
															updateLevelRisk(
																'unbalanced_work_concentration',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item
											name={[
												baseFormName,
												...formName,
												'unbalanced_work_concentration',
												'result'
											]}
										>
											<RiskLevelCard
												key={[...formName, 'unbalanced_work_concentration']}
												step={[...formName, 'unbalanced_work_concentration']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					{/* unbalanced_concentrations_among_workers */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'unbalanced_concentrations_among_workers', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('unbalanced_concentrations_among_workers');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Unbalanced concentrations of work among workers">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[
												baseFormName,
												...formName,
												'unbalanced_concentrations_among_workers',
												'injuries'
											]}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'unbalanced_concentrations_among_workers',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuresGroup
												onChange={() =>
													setCheckedCollapse('unbalanced_concentrations_among_workers')
												}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_mental_overload,
													complaints_of_irritability,
													non_conforming_products,
													others
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'unbalanced_concentrations_among_workers',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'unbalanced_concentrations_among_workers',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse(
																'unbalanced_concentrations_among_workers'
															);
															updateLevelRisk(
																'unbalanced_concentrations_among_workers',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'unbalanced_concentrations_among_workers',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'unbalanced_concentrations_among_workers',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse(
																'unbalanced_concentrations_among_workers'
															);
															updateLevelRisk(
																'unbalanced_concentrations_among_workers',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'unbalanced_concentrations_among_workers',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'unbalanced_concentrations_among_workers',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse(
																'unbalanced_concentrations_among_workers'
															);
															updateLevelRisk(
																'unbalanced_concentrations_among_workers',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item
											name={[
												baseFormName,
												...formName,
												'unbalanced_concentrations_among_workers',
												'result'
											]}
										>
											<RiskLevelCard
												key={[...formName, 'unbalanced_concentrations_among_workers']}
												step={[...formName, 'unbalanced_concentrations_among_workers']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					{/* insufficient_rest_between_shifts */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'insufficient_rest_between_shifts', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('insufficient_rest_between_shifts');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Insufficient rest between shifts (less than 11 hours)">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[
												baseFormName,
												...formName,
												'insufficient_rest_between_shifts',
												'injuries'
											]}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'insufficient_rest_between_shifts',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuresGroup
												onChange={() => setCheckedCollapse('insufficient_rest_between_shifts')}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_mental_overload,
													complaints_of_irritability,
													non_conforming_products,
													others
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'insufficient_rest_between_shifts',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'insufficient_rest_between_shifts',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('insufficient_rest_between_shifts');
															updateLevelRisk(
																'insufficient_rest_between_shifts',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'insufficient_rest_between_shifts',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'insufficient_rest_between_shifts',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('insufficient_rest_between_shifts');
															updateLevelRisk(
																'insufficient_rest_between_shifts',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'insufficient_rest_between_shifts',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'insufficient_rest_between_shifts',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('insufficient_rest_between_shifts');
															updateLevelRisk(
																'insufficient_rest_between_shifts',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item
											name={[
												baseFormName,
												...formName,
												'insufficient_rest_between_shifts',
												'result'
											]}
										>
											<RiskLevelCard
												key={[...formName, 'insufficient_rest_between_shifts']}
												step={[...formName, 'insufficient_rest_between_shifts']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
