import React from 'react';
import * as Icons from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import { Button, Col, Row } from 'antd';

import { PayloadDownloadPDF, UpdateBeraResultConsolidatedRequest } from '../types';
import { useDownloadPDF, useConsolidatePDF } from '../hooks';

interface DownloadPDFProps {
	parameters: PayloadDownloadPDF;
	disableButtons: boolean;
	consolidated: boolean;
}

export const DownloadPDF: React.FC<DownloadPDFProps> = ({ parameters, consolidated, disableButtons }) => {
	const { mutateAsync: consolidatePDF, isLoading: consolidatingPDF } = useConsolidatePDF();
	const { mutateAsync: downloadPDF, isLoading: downloadingPDF } = useDownloadPDF();

	async function handleDownload(payload: PayloadDownloadPDF) {
		await downloadPDF(payload);
	}

	async function handleConsolidate(payload: PayloadDownloadPDF) {
		const body: UpdateBeraResultConsolidatedRequest = {
			id: payload.id,
			organization_id: payload.organization_id,
			company_id: payload.company_id,
			bera_report_data: {
				consolidated: !consolidated
			}
		};
		await consolidatePDF(body);
	}

	return (
		<Row justify="end" style={{ marginTop: '1rem' }}>
			<Col>
				<Row justify="space-between" gutter={[16, 16]}>
					<Col>
						<Button
							style={{
								color: '#2F54EB',
								borderRadius: '0.25rem',
								border: '1px solid #2F54EB'
							}}
							loading={downloadingPDF}
							disabled={downloadingPDF || disableButtons}
							onClick={() => handleDownload(parameters)}
							icon={<Icons.DownloadOutlined style={{ color: '#2F54EB' }} />}
						>
							{I18n.get('Download PDF')}
						</Button>
					</Col>
					<Col>
						{!consolidated ? (
							<Button
								style={{
									color: '#2cc852',
									borderRadius: '0.25rem',
									border: '1px solid #2cc852'
								}}
								loading={consolidatingPDF}
								disabled={consolidatingPDF || disableButtons}
								onClick={() => handleConsolidate({ ...parameters })}
								icon={<Icons.LockOutlined style={{ color: '#2cc852' }} />}
							>
								{I18n.get('Consolidate report')}
							</Button>
						) : (
							<Button
								style={{
									color: '#828282',
									borderRadius: '0.25rem',
									border: '1px solid #828282'
								}}
								loading={consolidatingPDF}
								disabled={consolidatingPDF}
								onClick={() => handleConsolidate({ ...parameters })}
								icon={<Icons.UnlockOutlined style={{ color: '#828282' }} />}
							>
								{I18n.get('Deconsolidate')}
							</Button>
						)}
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
