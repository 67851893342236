import { Col, Row } from 'antd';
import styled from 'styled-components';

export const ActioPlanContainer = styled(Row)`
	width: 100%;
	font-size: 0.8rem;
`;

export const ActionPlanTitle = styled.span`
	font-weight: 600;
	color: #ffffff;
	font-size: 1rem;
`;

export const ActionPlanContent = styled(Col)`
	padding: 1rem 0 1rem 1rem;
	width: 100%;
	background-color: #f8f8f8;
	border-radius: 0 0 0.5rem 0.5rem;
`;

export const Title = styled.span`
	font-weight: 600;
	font-size: 0.8rem;
`;

export const Description = styled.span`
	font-weight: 400;
	font-size: 0.8rem;
`;

export const DeadlineContainer = styled(Row)`
	border: 1px solid #d9d9d9;
	border-radius: 0.25rem;
	padding: 0.25rem 0.5rem;
`;

export const Header = styled(Row)`
	background-color: #2f54eb;
	border-radius: 0.5rem 0.5rem 0 0;
	padding: 1rem;
`;
