import { useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import Api from '@/services/api';
import type { BaseContext, Exposure, Response } from '@/types';

export type GetExposuresDTO = BaseContext & {
	step_key_id: string;
};

async function getExposures(params: GetExposuresDTO): Promise<Exposure[]> {
	const url = '/custom-report/exposure';
	const { data } = await Api.get<Exposure[]>(url, {
		params
	});
	return data;
}

export const useGetExposures = ({ organization_id, company_id, step_key_id }: GetExposuresDTO) => {
	const enabled = !!organization_id && !!company_id && !!step_key_id;
	const exposures = useQuery<Exposure[], AxiosError<Response>>(
		['exposures', [organization_id, company_id]],
		() => getExposures({ organization_id, company_id, step_key_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...exposures,
		data: exposures.data ?? ([] as Exposure[])
	};
};
