import React, { ReactNode } from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Row } from 'antd';

interface CustomLabelProps {
	title: string;
	icon: ReactNode;
}

export const CustomLabel: React.FC<CustomLabelProps> = ({ title, icon }) => {
	return (
		<Col xs={24}>
			<Row align="top">
				{I18n.get(title)}
				{icon}
			</Row>
		</Col>
	);
};
