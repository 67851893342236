import React from 'react';
import { NamePath } from 'antd/lib/form/interface';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { TotalTaskDurationDTO } from '@/components/ui/Inputs/types/response/totalTaskDuration';
import { TotalTaskDurationSelect } from '@/components/ui/Inputs/TotalTaskDurationSelect';
import { StepKeyNameList } from '@/components/ui/Inputs/types/response/customReport';
import { StressLevelDTO } from '@/components/ui/Inputs/types/response/stressLevel';
import { Spinner } from '@/components/Spinner';

import { CustomStepKeyFormItem, ScaleColor, scaleColorMapper } from './styles';

const { useFormInstance, useWatch } = Form;

interface TotalTaskDurationsProps {
	isError: boolean;
	disabled: boolean;
	isLoading: boolean;
	isFetching: boolean;
	fieldName: NamePath;
	fieldScoreName: NamePath;
	stepKeyName: StepKeyNameList;
	totalTaskDurations: TotalTaskDurationDTO[];
	selectedStressLevel: StressLevelDTO | undefined;
}

export const TotalTaskDurations: React.FC<TotalTaskDurationsProps> = ({
	isError,
	disabled,
	fieldName,
	isLoading,
	isFetching,
	stepKeyName,
	fieldScoreName,
	totalTaskDurations,
	selectedStressLevel
}) => {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();

	const totalTaskDurationId = useWatch(fieldName, form);

	const totalTaskDuration = totalTaskDurations?.find(
		(totalTaskDuration) => totalTaskDurationId === totalTaskDuration.id
	);
	const stepDefinedByStressLevel = isStepDefinedByStressLevel();
	const colorMapper = defineColorMapper();
	setFieldValue(fieldScoreName, colorMapper);
	const color = scaleColorMapper[colorMapper];

	function defineColorMapper() {
		return stepDefinedByStressLevel && selectedStressLevel?.score === 1 && totalTaskDurationId
			? 1
			: totalTaskDuration?.score ?? 0;
	}

	function isStepDefinedByStressLevel() {
		return stepKeyName === 'has_too_high' || stepKeyName === 'has_too_low' || stepKeyName === 'has_too_far';
	}

	async function handleTotalTaskDurationSelect(id: string) {
		setFieldValue(fieldName, id);
	}

	async function handleClearTotalTaskDuration() {
		setFieldValue(fieldName, undefined);
	}

	if (isLoading) {
		return (
			<Col xs={22} sm={18} md={19} lg={17} xl={20} xxl={21}>
				<Spinner size={12} />
			</Col>
		);
	}

	return totalTaskDurations && totalTaskDurations.length > 0 ? (
		<>
			<Col xs={22} sm={18} md={19} lg={17} xl={20} xxl={21}>
				<CustomStepKeyFormItem
					name={fieldName}
					label={I18n.get('Total task duration')}
					labelCol={{ span: 24 }}
					rules={[
						{
							required: true,
							message: I18n.get('Select the total task duration')
						}
					]}
				>
					<TotalTaskDurationSelect
						totalTaskDurations={totalTaskDurations}
						loading={isFetching}
						disabled={isLoading || isFetching || isError || disabled}
						totalTaskDurationId={totalTaskDurationId}
						fieldName={fieldName}
						handleClearTotalTaskDuration={handleClearTotalTaskDuration}
						handleSelectTotalTaskDuration={handleTotalTaskDurationSelect}
					/>
				</CustomStepKeyFormItem>
			</Col>
			<ScaleColor scale_color={color} />
		</>
	) : (
		<></>
	);
};
