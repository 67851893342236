import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row } from 'antd';

import { useLibertyMutualSubStepsContext } from '../../../context';
import { Body, CardContainer, Header } from '../styles';

export const FrequencyCard = () => {
	const {
		libertyMutualResult: {
			summary: { frequency }
		}
	} = useLibertyMutualSubStepsContext();

	return (
		<CardContainer>
			<Header>{I18n.get('Frequency')}</Header>
			<Body style={{ padding: '0 0 1rem 0' }}>
				<Row justify="center" style={{ fontSize: '1.5rem', padding: '1rem 0 0 0' }}>
					{frequency.value}
				</Row>
				<Row justify="center" style={{ fontSize: '0.9rem', padding: '0rem 2rem' }}>
					{`${I18n.get(frequency.type)} ${I18n.get('per')} ${I18n.get(frequency.interval)}`}
				</Row>
			</Body>
		</CardContainer>
	);
};
