import React from 'react';
import { NamePath } from 'antd/lib/form/interface';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { RPNScore } from './styles';

interface TaskRPNProps {
	fieldName: NamePath;
	task_rpn: number;
}

export const TaskRPN: React.FC<TaskRPNProps> = ({ fieldName, task_rpn }) => {
	return (
		<Col xs={22} sm={20}>
			<Form.Item name={fieldName} label={I18n.get('RPN')} labelAlign="left">
				{task_rpn === 0 ? 'N/A' : <RPNScore>{String(task_rpn)}</RPNScore>}
			</Form.Item>
		</Col>
	);
};
