import React, { ReactNode, useState } from 'react';
import { Col, Form, Row } from 'antd';

import { useStepKeysContext } from '../StepKeys/DefaultSelects/context';
import { CollapseCheck } from '@/components/ui/CollapseCheck';
import { CollpaseHeader } from './CollpaseHeader';
const { useFormInstance } = Form;

interface CollapseCustomProps {
	title: string;
	disabled?: boolean;
	children: ReactNode;
}

export const CollapseCustom: React.FC<CollapseCustomProps> = ({ title, disabled = false, children }) => {
	const form = useFormInstance();
	const { stepKey, formFieldName } = useStepKeysContext();
	const [open, setOpen] = useState<boolean>(stepKey.checked);

	function handleCheckStepKey(): void {
		form.setFieldValue([...formFieldName], !open);
		setOpen(!open);
	}

	function renderCollapseHeader(): JSX.Element {
		return (
			<CollpaseHeader
				title={title}
				isOpen={open}
			/>
		);
	}

	return (
		<Col sm={24}>
			<CollapseCheck
				value={open}
				disabled={disabled}
				onChange={handleCheckStepKey}
				header={renderCollapseHeader()}
			>
				<React.Fragment>
					<Row justify="center">
						<Col sm={24} xxl={22}>
							{children}
						</Col>
					</Row>
				</React.Fragment>
			</CollapseCheck>
		</Col>
	);
};
