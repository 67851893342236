import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

export const Icon = styled(LoadingOutlined)`
	font-size: ${(props) => props.size}px;
`;

export const Loading = styled(Spin)`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
`;

export const SpinLoading = ({ size = 38 }) => (
	<Loading indicator={<Icon size={size} spin />} />
);
