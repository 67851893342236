import { Col } from 'antd';
import styled, { keyframes } from 'styled-components';


const bounceInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  60% {
    opacity: 1;
    transform: translateY(-10%);
  }
  80% {
    transform: translateY(5%);
  }
  100% {
    transform: translateY(0);
  }
`;

const rotate360 = keyframes`
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;


export const LoadingContainer = styled(Col)`
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	margin: 2rem 0rem;
	img.rotate {
		animation: ${rotate360} 2s linear infinite;
	}

	svg.bounce-in-up {
		animation: ${bounceInUp} 1.5s cubic-bezier(0.4, -0.03, 0.63, 1.28) forwards;
	}

	.fade-in {
		opacity: 0;
		animation: ${fadeIn} 0.5s ease-out 1.3s forwards;
	}
`;
