import React from 'react';
import { Col, Divider, Row } from 'antd';

import { CloseCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Title } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';

import { StepKeyDescription } from './StepKeyDescription';

type StepKeyProps = {
	id: string;
	description: string;
	hasDivider?: boolean;
	task_rpn: number;
	jobElement: string;
	stressLevel: {
		id: string;
		name: string;
		description: string;
	};
	frequency: {
		id: string;
		name: string;
		description: string;
	};
	totalTaskDuration: {
		id: string;
		name: string;
		description: string;
	};
};

export const StepKey: React.FC<StepKeyProps> = ({
	id,
	description,
	task_rpn,
	stressLevel,
	hasDivider = false,
	frequency,
	totalTaskDuration,
	jobElement
}) => {
	const style = {
		fontSize: 20,
		width: '1.75rem'
	};
	const icons = [
		<WarningOutlined key={id} style={{ ...style, color: '#E74150' }} />,
		<CloseCircleOutlined key={id} style={{ ...style, color: '#D6D6D6' }} />
	];

	return (
		<Col span={24}>
			<Row gutter={[0, 0]} align="middle">
				<Col span={1}>{icons[stressLevel?.id ? 0 : 1]}</Col>
				<Col span={23}>
					<Title style={{ margin: '0 0 0 1rem' }} level={5}>
						{I18n.get(description)}
					</Title>
				</Col>
			</Row>

			<Row style={{ marginTop: '0.5rem' }}>
				{hasDivider && (
					<Col span={1}>
						<Divider
							type="vertical"
							style={{
								margin: '0 0 0 0.7rem',
								borderLeft: '4px solid #e6e8e8',
								height: '100%'
							}}
						/>
					</Col>
				)}
				<Col span={22} offset={hasDivider ? 1 : 2}>
					<StepKeyDescription
						task_rpn={task_rpn}
						stressLevel={stressLevel}
						frequency={frequency}
						totalTaskDuration={totalTaskDuration}
						jobElement={jobElement}
					/>
				</Col>
			</Row>
		</Col>
	);
};
