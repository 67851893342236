import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import { Tooltip } from 'antd';

interface CustomIconProps {
	title: string;
}

export const CustomIcon: React.FC<CustomIconProps> = ({ title }) => {
	return (
		<Tooltip placement="topLeft" title={I18n.get(title)}>
			<InfoCircleOutlined style={{ fontSize: '0.7rem', marginLeft: '0.1rem' }} />
		</Tooltip>
	);
};
