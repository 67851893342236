import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { Row, Col, Form, message } from 'antd';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import { REPORT_COMMENT_DEBOUNCE_TIME as COMMENT_DEBOUNCE_TIME } from '@/constants/index.js';
import { HeaderStep, Footer } from '@/components/views/Report/Steps.js';
import Spinner from '@/components/Layout/Spinner';
import { useDebounce } from '@/hooks/useDebounce';
import { useFile, useSectors } from '@/hooks/v1';
import { queryClient } from '@/store/query';
import Api from '@/services/api';

import { createReportService, updateReportService, setComments, getReport } from './services';
import { Information } from './Information';
import { Parameters } from './Parameters';
import { RebaProvider } from './context';
import { Result } from './Result';

const { useForm } = Form;

export function Reba() {
	let existingReport = {};

	const [form] = useForm();
	const history = useHistory();
	const { file_id } = useParams();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

	const [currentStep, setCurrentStep] = useState(0);

	const { organization, company } = useSelector(
		(state) => ({
			organization: state.organization.organization,
			company: state.organization.company
		}),
		shallowEqual
	);

	const sectors = useSectors({
		organizationId: organization?.id,
		companyId: company?.id
	});

	const { file } = useFile({
		organizationId: organization?.id,
		companyId: company?.id,
		fileId: file_id
	});

	const reportParams = {
		organizationId: organization?.id,
		companyId: company?.id,
		fileId: file?.data?.id
	};

	const report = useQuery(['reba-recovery', reportParams], () => getReport(reportParams), {
		enabled: !!organization && !!company && !!file.data,
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (data) => {
			if (data) {
				setCurrentStep(steps.length - 1);
			}
		}
	});

	const calculateAngles = useMutation({
		mutationFn: (body) => Api.post('/ergonomic-tool/reba/calculate-angles', body)
	});

	useEffect(() => {
		if (file.data) {
			calculateAngles.mutate({
				organization_id: organization?.id,
				company_id: company?.id,
				file_id: file?.data?.id
			});
		}
	}, [file.data]);

	const isLoading = sectors.isLoading || file.isLoading || report.isLoading;
	const isError = sectors.isError || file.isError;

	const createReport = useMutation((payload) => createReportService(payload), {
		onError: (err) => {
			message.error(I18n.get(err.response.data.message));
		},
		onSuccess: (res) => {
			queryClient.invalidateQueries(['file']);
			queryClient.invalidateQueries(['reba-recovery']);
			message.success(I18n.get('Report created successfully'));
			onNext();
		}
	});

	const putReport = useMutation((payload) => updateReportService(payload), {
		onError: (err) => {
			message.error(I18n.get(err.response.data.message));
		},
		onSuccess: (res) => {
			queryClient.invalidateQueries(['file']);
			queryClient.invalidateQueries(['reba-score']);
			queryClient.invalidateQueries(['reba-score-parts']);
			queryClient.invalidateQueries(['reba-note-per-second']);
			message.success(I18n.get('Report updated successfully'));
			onNext();
		}
	});

	const updateComment = useMutation(({ body }) => setComments(body), {
		onError: (err) => {
			message.error(I18n.get(err.response.data.message));
		},
		onSuccess: (res) => {
			queryClient.setQueryData(['reba', reportParams], existingReport);
			message.success(I18n.get('Comment updated successfully'));
		}
	});

	const generatePDF = useMutation(
		({ body, opts }) => {
			return Api.post('/ergonomic-tool/reba/document/pdf', body, opts);
		},
		{
			onError: (err) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: (response) => {
				const blob = new Blob([response.data], {
					type: 'application/pdf'
				});
				window.open(URL.createObjectURL(blob));
				message.success(I18n.get('Document created successfully'));
			}
		}
	);

	function validationStep() {
		form.validateFields()
			.then((values) => {
				setCurrentStep((prev) => prev + 1);
			})
			.catch((error) => {
				message.error(I18n.get('Enter the required values'));
			});
	}

	async function onNext() {
		currentStep < steps.length - 2 ? validationStep() : setCurrentStep((prev) => prev + 1);
	}

	function onPrevious() {
		setCurrentStep((prev) => prev - 1);
	}

	async function onFinish() {
		try {
			await form.validateFields();
			const values = form.getFieldsValue(true);
			const collectionDate = moment(values.collection_date).format();

			const payload = {
				...values,
				organization_id: organization.id,
				company_id: company.id,
				file_id: file_id,
				collection_date: collectionDate
			};

			report.data ? putReport.mutate(payload) : createReport.mutate(payload);
		} catch (error) {
			message.error(I18n.get('Enter the required values'));
		}
	}

	async function onClose() {
		await saveComment();
		const custom_report = !!searchParams.get('custom_report');
		if (custom_report) {
			return history.push('/custom-reports/files');
		}
		history.push('/reporting');
	}

	const onChangeComment = useDebounce(saveComment, COMMENT_DEBOUNCE_TIME);

	async function saveComment() {
		const { comment } = form.getFieldsValue(true);
		if ((comment || comment === '') && report.data.comment !== comment) {
			const body = {
				organization_id: organization.id,
				company_id: company.id,
				file_id,
				comment
			};
			await updateComment.mutateAsync({ body });
		}
	}

	async function onDownloadPDF(fileData) {
		await saveComment();
		const browserLanguage = window.navigator.language ?? 'en-US';

		const body = {
			organization_id: organization?.id,
			company_id: company?.id,
			file_id: fileData.id,
			locale: browserLanguage
		};

		const opts = { responseType: 'blob' };

		generatePDF.mutate({ body, opts });
	}

	const steps = [
		{
			title: I18n.get('Information'),
			content: <Information file={file} />,
			is_valid: true
		},
		{
			title: I18n.get('Parameters'),
			content: <Parameters />,
			is_valid: true
		},
		{
			title: I18n.get('Result'),
			content: (
				<Result
					file={file}
					report={report}
					sectors={sectors}
					onDownloadPDF={onDownloadPDF}
					isLoadingPDF={generatePDF.isLoading}
					onChangeComment={onChangeComment}
				/>
			),
			is_valid: true
		}
	];

	if (isLoading) {
		return <Spinner />;
	}

	if (isError) {
		return <h2>Internal server error</h2>;
	}

	const initialValues = {
		company_id: company?.id,
		sector_id: file?.data?.workstations?.line?.sector?.id,
		line_id: file?.data?.workstations?.line?.id,
		workstation_id: file?.data?.workstations?.id,
		force: report.data?.force || 1,
		coupling: report.data?.coupling || 1,
		repetition: report.data?.repetition || 1,
		comment: report.data?.comment || '',
		collection_date: !!report.data ? moment(report.data?.collection_date) : null
	};

	return (
		<RebaProvider>
			<Form layout="vertical" form={form} initialValues={initialValues}>
				<Row justify="center">
					<Col sm={24} style={{ marginBottom: '16px', textAlign: 'center' }}>
						<h2>{I18n.get('REBA - Rapid Entire Body Assessment')}</h2>
					</Col>
					<Col xxl={20} xl={22} sm={24}>
						<Row justify="center" align="middle" style={{ marginBottom: '20px' }}>
							<Col sm={20} xxl={14}>
								<HeaderStep current={currentStep} steps={steps} />
							</Col>
						</Row>
						<Row justify="center">
							<Col sm={24} style={{ minHeight: '350px' }}>
								{steps[currentStep]?.content}
							</Col>
						</Row>
						<Row justify="center">
							<Col span={24}>
								<Footer
									steps={steps}
									onPrevious={onPrevious}
									onNext={onNext}
									current={currentStep}
									onFinish={onFinish}
									onClose={onClose}
									isFetching={isLoading}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Form>
		</RebaProvider>
	);
}
