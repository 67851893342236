import React from 'react';
import { Avatar, Button, Col, Modal, Row } from 'antd';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Title } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';
import { roleEnum } from '@/utils/enum';

import { DeactivateContainer, DeactivateTokenDescription } from './styles';
import { Deactivate2FADTO } from './types/response';
import { useTFAContext } from './context';

export const Deactivate = () => {
	const { forced2FA, user } = useTFAContext();
	const { handleDelete2FA, handle2FAChecked } = useTFAContext();

	async function handleConfirmClick() {
		let deleted = {} as Deactivate2FADTO;
		if (forced2FA.force_two_fa && user?.role !== roleEnum.MASTER) {
			Modal.confirm({
				title: I18n.get(
					'Your company has asked for all users to keep two-factor ' +
						'authentication enabled for security reasons. ' +
						"You'll be asked to activate it again on your next login. " +
						'Want to deactivate it anyway?'
				),
				icon: <ExclamationCircleOutlined />,
				okText: I18n.get('Confirm'),
				cancelText: I18n.get('Cancel'),
				okType: 'danger',
				onOk: async () => {
					deleted = await dactivate2fa(deleted);
				}
			});
		} else {
			deleted = await dactivate2fa(deleted);
		}
	}

	async function dactivate2fa(deleted: Deactivate2FADTO) {
		deleted = await handleDelete2FA();
		if (deleted?.message) {
			handle2FAChecked();
			window.location.reload();
		}
		return deleted;
	}

	return (
		<DeactivateContainer align="top" justify="center">
			<Col span={24}>
				<Row justify="center">
					<Col span={'auto'}>
						<Avatar size={64} icon={<ExclamationCircleOutlined />} />
					</Col>
				</Row>
			</Col>
			<Col span={22}>
				<Row justify="center">
					<Col span={24}>
						<Row justify="center">
							<Title align="center" level={4}>
								You are about to deactivate your 2FA.
							</Title>
						</Row>
						<Row justify="center">
							<DeactivateTokenDescription>
								{I18n.get(
									'Confirming this action will delete your two-factor authentication. Are you sure?'
								)}
							</DeactivateTokenDescription>
						</Row>
					</Col>
				</Row>
			</Col>
			<Col span={18}>
				<Row justify="center">
					<Button
						style={{
							color: 'red'
						}}
						onClick={handleConfirmClick}
						type="default"
						htmlType="submit"
					>
						{I18n.get('Confirm')}
					</Button>
				</Row>
			</Col>
		</DeactivateContainer>
	);
};
