import React, { ReactElement, useState } from 'react';
import { Button, Divider, Form, Input, Select, Skeleton } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { NamePath } from 'antd/lib/form/interface';
import { I18n } from '@aws-amplify/core';

import { WorkstationDTO } from '@/components/ui/Inputs/types/response/workstation';
import { useCreateWorkstation } from '@/hooks/v1/useWorkstationMutations';
import { useApplicationContext } from '@/context/v1/Application/context';

import { CreateWorkstationRequest } from '../types/request/workstation';

const { useFormInstance } = Form;

type WorkstationSelectProps = {
	lineId: string;
	loading: boolean;
	size?: SizeType;
	disabled: boolean;
	fieldName: NamePath;
	workstationId?: string;
	allowCreation?: boolean;
	handleClearWorkstations?: () => void;
	workstations: WorkstationDTO[] | undefined;
	handleSelectWorkstation?: (id: string) => void;
};

type OptionType = { label: string; value: string } | undefined;

export function WorkstationSelect({
	lineId,
	loading,
	disabled,
	fieldName,
	workstations,
	workstationId,
	size = 'middle',
	allowCreation = true,
	handleSelectWorkstation,
	handleClearWorkstations
}: WorkstationSelectProps) {
	const [workstationName, setWorkstationName] = useState('');

	const { company, organization } = useApplicationContext();
	const { validateFields } = useFormInstance();

	const { mutateAsync: createWorkstation, isLoading } = useCreateWorkstation();

	async function onCreateWorkstation() {
		const body: CreateWorkstationRequest = {
			organization_id: organization.id,
			company_id: company.id,
			line_id: lineId,
			name: workstationName
		};

		const { data: workstation } = await createWorkstation({ body });
		const workstationId = workstation?.id;
		if (workstationId) {
			handleChange(workstationId);
		}
		setWorkstationName('');
	}

	async function handleChange(id: string) {
		if (handleSelectWorkstation) {
			handleSelectWorkstation(id);
			await validateFields([fieldName]);
		}
	}

	function handleClear() {
		if (handleClearWorkstations) {
			handleClearWorkstations();
		}
	}

	function handleWorkstationNameChange(name: string) {
		setWorkstationName(name);
	}

	function filterOption(option: OptionType, input: string): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function showDropdownRender(menu: ReactElement): JSX.Element {
		if (!allowCreation) {
			return <div>{menu}</div>;
		}
		return (
			<div>
				{menu}
				<Divider style={{ margin: '4px 0' }} />
				<div
					style={{
						display: 'flex',
						flexWrap: 'nowrap',
						padding: 8
					}}
				>
					<Input
						style={{ flex: 'auto', borderRadius: '7px' }}
						value={workstationName}
						onChange={(e) => handleWorkstationNameChange(e.target.value)}
					/>
					<Button
						onClick={onCreateWorkstation}
						type="link"
						loading={isLoading}
						disabled={!workstationName || workstationName.length < 3}
					>
						{I18n.get('Add item')}
					</Button>
				</div>
			</div>
		);
	}

	if (loading) {
		return <Skeleton.Input block active size="small" />;
	}

	return (
		<Select
			showSearch
			allowClear
			size={size}
			loading={loading}
			disabled={disabled}
			value={workstationId}
			onClear={handleClear}
			placeholder={I18n.get('Workstation')}
			onChange={(value: string) => handleChange(value)}
			dropdownRender={(menu) => showDropdownRender(menu)}
			filterOption={(input, option) => filterOption(option, input)}
			options={workstations?.map(({ id, name }) => ({ label: name, value: id }))}
		/>
	);
}
