import React from 'react';
import { DeleteOutlined, DownloadOutlined, ExclamationCircleOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Modal, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useApplicationContext } from '@/context/v1/Application/context';

import { ActionsInline, ActionsMenu, CustomButton } from './styles';
import { BeraReport } from '../hooks/types/response';
import { useDeleteBeraReport } from '../hooks/hooks';
import { useDownloadPDF } from '../../BeraJob/BeraFormSteps/BeraStep/BeraResult/hooks';

interface ActionsProps {
	rowData: BeraReport;
}

export const Actions: React.FC<ActionsProps> = ({ rowData }) => {
	const { organization, company } = useApplicationContext();
	const { mutateAsync: deleteReport } = useDeleteBeraReport();
	const { mutateAsync: downloadPDF, isLoading: downloadingPDF } = useDownloadPDF();

	const browserLanguage = window.navigator.language ?? 'en-US';

	async function handleDownloadClick(report: BeraReport) {
		await downloadPDF({
			organization_id: organization?.id,
			company_id: company?.id,
			file_id: report.file.id,
			bera_job_summary_id: report.bera_job_summary_id,
			id: report.id,
			locale: browserLanguage
		});
	}

	async function handleDeleteClick(id: string) {
		Modal.confirm({
			title: I18n.get('Heads up! Want to delete this report?'),
			icon: <ExclamationCircleOutlined />,
			okText: I18n.get('Confirm'),
			cancelText: I18n.get('Cancel'),
			okType: 'danger',
			onOk: async () => {
				await deleteReport({
					organization_id: organization?.id,
					company_id: company?.id,
					id
				});
			}
		});
	}
	const items = [
		{
			key: 1,
			icon: <DownloadOutlined />,
			label: <Button type="link">{I18n.get('Download')}</Button>
		},
		{
			key: 2,
			danger: true,
			icon: <DeleteOutlined />,
			label: (
				<CustomButton>
					<Button type="link" onClick={() => handleDeleteClick(rowData.id)}>
						{I18n.get('Delete')}
					</Button>
				</CustomButton>
			)
		}
	];

	return (
		<Row align="middle" justify="center">
			<ActionsInline xs={24} sm={16} md={24} xl={16} xxl={8}>
				<Row align="middle" justify="space-between">
					<Col span={12}>
						<Button
							onClick={() => {
								handleDownloadClick(rowData);
							}}
							type="link"
							size="middle"
							loading={downloadingPDF}
							icon={<DownloadOutlined />}
						/>
					</Col>
					<Col span={12}>
						<Button
							onClick={() => {
								handleDeleteClick(rowData.id);
							}}
							type="link"
							size="middle"
							icon={<DeleteOutlined style={{ color: '#E74150' }} />}
						/>
					</Col>
				</Row>
			</ActionsInline>
			<ActionsMenu xs={2}>
				<Dropdown menu={{ items }} trigger={['click']}>
					<Button type="link" icon={<MenuOutlined />} />
				</Dropdown>
			</ActionsMenu>
		</Row>
	);
};
