import styled from 'styled-components';

export const Topic = styled.span`
	font-size: 0.9rem;
	font-weight: 600;
	margin-right: 0.5rem;

	@media (min-width: 1600px) {
		font-size: 0.9rem;
	}
`;
