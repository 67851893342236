import React, { useState } from 'react';
import { Avatar, Button, Col, Form, Row } from 'antd';
import moment from 'moment';

import { CheckOutlined } from '@ant-design/icons';
import { Title } from '@/components/Typography';
import { Spinner } from '@/components/Spinner';
import { I18n } from '@aws-amplify/core';

import { Countdown, ValidationCode, ValidateInput, ValidateContainer } from './styles';
import { IconContainer, QRCode } from './Generate2FAModal/styles';
import { useCountdown } from '../../../../hooks/v1/useCountdown';
import { useTFAContext } from './context';

const { useFormInstance } = Form;

export const Validate = () => {
	const { validateFields } = useFormInstance();

	const { generated2FA, fecthingUser2FA, currentActionStep, selected2FAType } = useTFAContext();
	const {
		handleValidate2FA,
		handleResendToken,
		handleNextStep,
		handleOpenActionModal,
		handleOpenGenerateTokenModal
	} = useTFAContext();

	const awaitTime = generated2FA.last_email_sent
		? moment(generated2FA.last_email_sent).add(1, 'minute').toDate()
		: new Date();
	const { seconds } = useCountdown(awaitTime);
	const formattedSeconds = ('0' + seconds).slice(-2);

	const [validationCode, setValidationCode] = useState('');

	function handleValidationCodeChange(code: string) {
		setValidationCode(code);
	}

	async function handleValidateClick() {
		await validateFields(['validation_code']);
		const validated = await handleValidate2FA(selected2FAType, validationCode);

		if (validated.tokens_array.length > 0) {
			handleNextStep();
		} else {
			handleOpenGenerateTokenModal(false);
		}

		if (currentActionStep === 1) {
			handleOpenActionModal(false);
		}
	}

	async function handleResendClick() {
		await handleResendToken();
	}

	return fecthingUser2FA ? (
		<Spinner />
	) : (
		<ValidateContainer align="middle" justify="center">
			<Col span={18}>
				{generated2FA.message && (
					<IconContainer justify="center">
						<Col span={'auto'}>
							<Avatar size={64} icon={<CheckOutlined />} />
						</Col>
					</IconContainer>
				)}
				<Row justify="center">
					<Col span={20}>
						<Row align="middle" justify="center">
							<Title align="center" level={4}>
								{generated2FA.message || (
									<QRCode width={166} alt="QRCode" preview={false} src={generated2FA.qr_code_url} />
								)}
							</Title>
						</Row>
						<Row align="middle" justify="center">
							<ValidationCode
								name="validation_code"
								rules={[
									() => ({
										validator() {
											return validationCode.length !== 6
												? Promise.reject(I18n.get('Enter the 6 digit code.'))
												: Promise.resolve();
										}
									})
								]}
							>
								<ValidateInput
									maxLength={6}
									max={6}
									placeholder={I18n.get('Validation code')}
									onChange={(event) => handleValidationCodeChange(event.target.value)}
								/>
							</ValidationCode>
						</Row>
						{generated2FA.message && (
							<Row align="middle" justify="center">
								<Col span={24}>
									<Row align="middle" justify="center">
										<Col>
											<Button disabled={seconds > 0} onClick={handleResendClick} type="link">
												{I18n.get('Resend e-mail')}
											</Button>
										</Col>
									</Row>
									<Row align="middle" justify="center">
										<Col>
											<Countdown>{formattedSeconds}s</Countdown>
										</Col>
									</Row>
								</Col>
							</Row>
						)}
					</Col>
				</Row>
			</Col>
			<Col span={18}>
				<Row justify="center">
					<Button
						disabled={validationCode.length !== 6}
						onClick={handleValidateClick}
						type="primary"
						htmlType="submit"
					>
						{I18n.get('Validate')}
					</Button>
				</Row>
			</Col>
		</ValidateContainer>
	);
};
