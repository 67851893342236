import { useMutation, useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import type { AxiosError } from 'axios';
import { message } from 'antd';

import Api from '@/services/api';
import type { CustomReport, Response } from '@/types';
import type { CreateReportDTO } from './useCreateCustomReportResult';

export type UpdateReportDTO = CreateReportDTO & {
	custom_report_result_id?: string;
	original_custom_report_result_id?: string;
};

async function updateReport(payload: UpdateReportDTO): Promise<CustomReport> {
	const url = '/custom-report/result';
	const { data } = await Api.put<CustomReport>(url, payload);
	return data;
}

export const useUpdateCustomReportResult = () => {
	const queryClient = useQueryClient();
	return useMutation<CustomReport, AxiosError<Response>, UpdateReportDTO>((body) => updateReport(body), {
		onError: (err) => {
			message.error(I18n.get(err?.response?.data?.message ?? err?.message));
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['jds_d86_report']);
			queryClient.invalidateQueries(['jds_d86_results']);
		}
	});
};
