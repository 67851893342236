/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect } from 'react';
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';
import { Input } from 'antd';

import { useDebounce } from '@/hooks/useDebounce';
import { DescriptionContainer, DescriptionTitle } from './styles';
const { TextArea } = Input;

const DEBOUNCE_TIME = 1000;

export function Description({ actionPlan, triggerReloadHistory, updateActionPlan }) {
	const [input, setInput] = useState(actionPlan.description);
	const organization = useSelector(({ organization }) => organization.organization);

	const onChangeDescription = useCallback(useDebounce(saveDescription, DEBOUNCE_TIME), []);

	async function saveDescription(input) {
		if (input || input === '') {
			const action_plan = { ...actionPlan, description: input };
			const body = { action_plan, organizationId: organization?.id };
			updateActionPlan({ body }).then(() => {
				triggerReloadHistory();
			});
		}
	}

	useEffect(() => {
		onChangeDescription(input);
	}, [input]);

	return (
		<DescriptionContainer>
			<DescriptionTitle>{I18n.get('Description')}</DescriptionTitle>
			<TextArea
				showCount
				value={input}
				rows={2}
				maxLength={500}
				bordered={false}
				placeholder={I18n.get('Enter a description for your action plan.')}
				onChange={(e) => setInput(e.target.value)}
			/>
		</DescriptionContainer>
	);
}
