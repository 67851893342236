import { I18n } from '@aws-amplify/core';

export class FieldsUnitTexts {
	formatLabelWithUnit(title?: string, unit?: string): string {
		if (!title) {
			return '';
		}

		if (unit) {
			return `${I18n.get(title)} (${I18n.get(unit)})`;
		}

		return String(I18n.get(title));
	}

	formatPlaceholderWithUnit(textValue: string, unit?: string): string {
		if (!unit) {
			return textValue;
		}

		return `${textValue} ${I18n.get(unit)}`;
	}
}

export const fieldsUnitTexts = new FieldsUnitTexts();
