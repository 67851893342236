import { useMutation, useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import type { AxiosError } from 'axios';
import { message } from 'antd';

import type { BaseContext, Response, ActionPlan, BoardsType } from '@/types';
import Api from '@/services/api';

export type ActionPlanDTO = {
	title?: string;
	deadline?: Date;
	board?: BoardsType;
	description?: string;
	responsible_user_id?: string;
};

export type ActionPlanTaskDTO = {
	id?: string;
	type: string;
	task_id?: string;
	description: string;
	is_completed?: boolean;
};

export type CreateCustomReportActionPlanDTO = BaseContext & {
	file_id?: string;
	action_plan?: ActionPlanDTO;
	tasks?: ActionPlanTaskDTO[];
	custom_report_step_key_id?: string;
	custom_report_sub_step_key_id?: string;
};

async function createCustomReportActionPlan(payload: CreateCustomReportActionPlanDTO): Promise<ActionPlan> {
	const url = '/action_plan/checklist';
	const { data } = await Api.post<ActionPlan>(url, payload);
	return data;
}

export const useCreateCustomReportActionPlan = () => {
	const queryClient = useQueryClient();
	return useMutation<ActionPlan, AxiosError<Response>, CreateCustomReportActionPlanDTO>(
		(body) => createCustomReportActionPlan(body),
		{
			onError: (err) => {
				message.error(I18n.get(err?.response?.data?.message ?? err?.message));
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['custom_report_action_plan']);
				message.success(I18n.get('Action plan created successfully'));
			}
		}
	);
};
