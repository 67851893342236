
import { riskColors } from '@/utils/riskRange';
import { Tooltip } from 'antd';
import styled from 'styled-components';

type RiskCardHeaderProps = {
	result?: number;
};

type ContainerProps = {
	negative?: boolean;
};

export const Container = styled.div<ContainerProps>`
	box-shadow: 0px -2px 5px 0px #0000001A, 0px 2px 5px 0px #0000001A;
	background-color: #F3F3F3;
	padding: 2rem 1rem 2rem 1rem;

	@media (min-width: 1367px) {
		margin:  ${(props) => props?.negative ? '0rem -3rem 1rem' : '0px'};
	}

	@media (max-width: 1367px) {
		margin:  ${(props) => props?.negative ? '0rem' : '0px'};
	}

	.d-flex {
		display: flex;
	}

	.ant-divider {
		border-color: #00000033;
	}

	.relative {
		position: relative;
	}

	.version {
		margin-top: -1.5rem;
	}

	.bold {
		font-weight: 600;
	}

	.mb-3 {
		margin-bottom: 1rem;
	}
`;

export const Topic = styled.div`
	margin-bottom: 1.5rem;

	.title {
		width: 100%;
		margin-bottom: 0.25rem;
		display: block;
		font-weight: 600;
	}

	.value {
		width: 100%;
		display: block;
		font-weight: 400;
		padding-left: .5rem;
	}
`;

export const RiskCard = styled.span`
	min-width: 107px;
	max-width: 107px;
	display: block;
`;

export const RiskCardHeader = styled.div<RiskCardHeaderProps>`
	background-color:  ${(props) => props?.result ? riskColors[props.result as number] : '#fff'};
	border-radius: 10px 10px 0px 0px;
	padding: 5px;
	display: flex;
	text-align: center;
	font-weight: bold;
	justify-content: center;
`;

export const RiskCardBody = styled.div<RiskCardHeaderProps>`
	background-color: #fff;
	border-radius: 0px 0px 10px 10px;
	padding: 5px;
	font-weight: bold;
	display: flex;
	justify-content: center;
	text-align: center;
`;

export const CustomTooltip = styled(Tooltip)`
	.ant-tooltip-inner {
		width: 17rem;
		color: #262626;
		border: 1px solid #262626;
		backdrop-filter: blur(4px);
		background-color: #fffa;
		position: relative;
		border-radius: 10px 10px 10px 0;
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	}
`;
