import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

import { CycleRequest } from '@/components/ui/Inputs/types/request/cycle';
import { CycleDTO } from '@/components/ui/Inputs/types/response/cycle';

async function getCycles({ organization_id, company_id }: CycleRequest): Promise<CycleDTO[]> {
	const url = `/cycle/list?organization_id=${organization_id}&company_id=${company_id}`;
	const { data } = await Api.get<CycleDTO[]>(url);
	return data;
}

export function useCycles({ organization_id, company_id }: CycleRequest) {
	const enabled = !!organization_id && !!company_id;

	const cycles = useQuery(
		['cycles', { organization_id, company_id }],
		() => getCycles({ organization_id, company_id }),
		{
			enabled
		}
	);
	return cycles;
}
