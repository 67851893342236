import { Row } from 'antd';
import styled from 'styled-components';

export const Container = styled(Row)`
	margin: 0rem 0 1rem 0;
`;

export const Description = styled.label`
	margin: 0;
	opacity: 0.7;
`;
