import React from 'react';
import { useHistory } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';
import { Button, Col, Row } from 'antd';

import { useLibertyMutualSubStepsContext } from './context';
import { PreviousCol } from './styles';

interface FooterProps {
	currentStep: number;
	onStepChange(step: number): void;
}

export const Footer: React.FC<FooterProps> = ({ onStepChange, currentStep }) => {
	const history = useHistory();
	const { subSteps, gettingReport, file_id, custom_report } = useLibertyMutualSubStepsContext();

	function handleNextStep() {
		onStepChange(currentStep + 1);
	}

	function handlePreviousStep() {
		onStepChange(currentStep - 1);
	}

	function redirectToReports() {
		let url = `/reporting/liberty-mutual/${file_id}?current_step=${1}`;
		if (custom_report) {
			url = `${url}&custom_report=${custom_report}`;
		}
		history.push(url);
	}

	if (currentStep === subSteps.length - 1) {
		return (
			<Row>
				<Col xs={24} style={{ marginTop: '2rem' }}>
					<Row justify="center" align="middle" gutter={[0, 8]}>
						<PreviousCol>
							<Button loading={gettingReport} type="default" onClick={handlePreviousStep}>
								{I18n.get('Previous')}
							</Button>
						</PreviousCol>
						<Col>
							<Button
								loading={gettingReport}
								disabled={gettingReport}
								type="primary"
								onClick={redirectToReports}
							>
								{I18n.get('Return to task list')}
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}

	if (currentStep > 0) {
		return (
			<Row>
				<Col xs={24} style={{ marginTop: '2rem' }}>
					<Row justify="center" align="middle" gutter={[0, 8]}>
						<PreviousCol>
							<Button loading={gettingReport} type="default" onClick={handlePreviousStep}>
								{I18n.get('Previous')}
							</Button>
						</PreviousCol>
						<Col>
							<Button
								type="primary"
								htmlType="submit"
								loading={gettingReport}
								disabled={gettingReport}
								onClick={handleNextStep}
							>
								{I18n.get('Next')}
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}

	return (
		<Row>
			<Col xs={24} style={{ marginTop: '2rem' }}>
				<Row justify="center">
					<Col>
						<Button htmlType="submit" loading={gettingReport} type="primary" onClick={handleNextStep}>
							{I18n.get('Next')}
						</Button>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
