import React, { useEffect, useState } from 'react';
import { Col, Form, InputNumber, Row } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import { useCalculateAdditionalToolScore, AdditionalItemsResults, GetAdditionalToolScore } from '@/hooks';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useStepKeysContext } from '../DefaultSelects/context';
import { fieldsUnitTexts } from './utils/FieldsUnitTexts';
import { ConditionCard } from '../../ConditionCard';
import { Text } from '@/components/Typography';
import { AditionalItemProps } from './types';

const { useFormInstance, useWatch } = Form;
const { formatPlaceholderWithUnit } = fieldsUnitTexts;

export function Input({ additionalItem }: Readonly<AditionalItemProps>) {
	const { id, title_1, unit_1 } = additionalItem;

	const form = useFormInstance();
	const { formFieldName, stepKey } = useStepKeysContext();
	const { organization, company } = useApplicationContext();

	const [timer, setTimer] = useState<NodeJS.Timeout>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [valueOne, setValueOne] = useState<number | undefined>(undefined);

	const formValueOne = useWatch([...formFieldName, 'additional_items', id, 'value_1']);

	const payload: GetAdditionalToolScore = {
		value_1: valueOne,
		company_id: company?.id,
		organization_id: organization?.id,
		custom_report_step_key_id: stepKey.id
	};

	const { data: additionalToolScore, isFetching: isFetchingAdditionalToolScore } =
		useCalculateAdditionalToolScore(payload);

	function handleDebounceSetValues(): void {
		clearTimeout(timer);

		const debounce = setTimeout(() => {
			setValueOne(formValueOne);
			setIsLoading(false);
		}, 1200);

		setTimer(debounce);
	}

	useEffect(() => {
		if (formValueOne) {
			setIsLoading(true);
			handleDebounceSetValues();
		}
	}, [formValueOne]);

	useEffect(() => {
		const result: AdditionalItemsResults[] = [
			{
				id: uuidv4(),
				value_1: valueOne,
				result: additionalToolScore?.result ?? 0
			}
		];

		form.setFieldValue([...formFieldName, 'additional_items', id, 'results'], result);
	}, [additionalToolScore]);

	return (
		<Row gutter={[0, 15]} key={id}>
			<Col span={24}>
				<Row align="bottom" gutter={[14, 20]}>
					<Col span={10}>
						<Row gutter={[0, 5]}>
							<Row gutter={[5, 0]}>
								<Col>
									<Text strong>{title_1}</Text>
								</Col>
							</Row>
							<Col span={24}>
								<Form.Item
									style={{ margin: 0 }}
									name={[...formFieldName, 'additional_items', id, 'value_1']}
								>
									<InputNumber
										min={0}
										style={{ width: '100%' }}
										placeholder={formatPlaceholderWithUnit('0', unit_1)}
									/>
								</Form.Item>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<ConditionCard
					result={additionalToolScore.result}
					isLoading={isLoading || isFetchingAdditionalToolScore}
				/>
			</Col>
		</Row>
	);
}
