import Api from '@/services/api';

import {
	CreateSeraActionPlanRequest,
	DeleteSeraActionPlanRequest,
	GetActionPlanBySeraSummaryReviewIdRequest,
	GetTasksRequest,
	UpdateSeraActionPlanRequest
} from '../../hooks/types/request';
import { ActionPlanTaskDTO, SeraActionPlanDTO } from '../../hooks/types/response';
import { BaseContext } from '@/components/ui/Inputs/types/request';
import { UserDTO } from '@/types/AuthUser';

export class SeraActionPlanService {
	public async createSeraActionPlan(body: CreateSeraActionPlanRequest) {
		const url = `/action_plan/sera`;
		const { data } = await Api.post<{ newActionPlan: SeraActionPlanDTO }>(url, body);
		return data;
	}

	public async updateSeraActionPlan(body: UpdateSeraActionPlanRequest) {
		const url = `/action_plan/sera/${body.action_plan_id}`;
		const { data } = await Api.put<SeraActionPlanDTO>(url, body);
		return data;
	}

	public async deleteSeraActionPlan(body: DeleteSeraActionPlanRequest) {
		const url = `/action_plan/${body.action_plan_id}`;
		const { data } = await Api.delete<SeraActionPlanDTO>(url, { params: body });
		return data;
	}

	public async getActionPlanBySeraSummaryReview(payload: GetActionPlanBySeraSummaryReviewIdRequest) {
		const url = `/action_plan/sera/${payload.sera_summary_review_id}`;
		const { data } = await Api.get<SeraActionPlanDTO[]>(url, { params: payload });
		return data;
	}

	public async getUsers({ organization_id, company_id }: BaseContext) {
		const url = `/user/user-list/${organization_id}/${company_id}`;
		const { data } = await Api.get<UserDTO[]>(url);
		return data;
	}

	public async getTasks(payload: GetTasksRequest) {
		const url = `/action_plan/${payload.action_plan_id}/task`;
		const { data } = await Api.get<ActionPlanTaskDTO[]>(url, { params: payload });
		return data;
	}
}
