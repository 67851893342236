import { useMutation, useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import type { AxiosError } from 'axios';
import { message } from 'antd';

import type { BaseContext, Response } from '@/types';
import Api from '@/services/api';

export type DeleteCustomReportActionPlanDTO = BaseContext & {
	action_plan_id?: string;
};

export type DeleteActionPlanResponse = {
	status: string;
	message: string;
	data: {
		action_plan_id: string;
	};
};

async function deleteCustomReportActionPlan(body: DeleteCustomReportActionPlanDTO): Promise<DeleteActionPlanResponse> {
	const url = `/action_plan/${body.action_plan_id}?organization_id=${body.organization_id}`;
	const { data } = await Api.delete<DeleteActionPlanResponse>(url);
	return data;
}

export const useDeleteCustomReportActionPlan = () => {
	const queryClient = useQueryClient();

	return useMutation<DeleteActionPlanResponse, AxiosError<Response>, DeleteCustomReportActionPlanDTO>(
		(body) => deleteCustomReportActionPlan(body),
		{
			onError: (err) => {
				message.error(I18n.get(err.response?.data?.message));
			},
			onSuccess: () => {
				message.success(I18n.get('Action plan deleted successfully'));
				queryClient.invalidateQueries(['custom_report_action_plan']);
			}
		}
	);
};
