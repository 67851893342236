import React from 'react';
import { Tooltip } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Text } from '@/components/Typography';

export interface IconType {
	name: string;
	position: number;
	isComplete: boolean;
	currentStep: number;
	onStepNagivationClick: (step: number) => Promise<void>;
}

export const StepIcon: React.FC<IconType> = ({ currentStep, name, position, isComplete, onStepNagivationClick }) => {
	const bigName = name.length > 23 ? I18n.get(name) : undefined;

	if (currentStep === position) {
		return (
			<span className="step-name">
				<Tooltip title={bigName}>
					<Text ellipsis>{I18n.get(name)}</Text>
				</Tooltip>
			</span>
		);
	}

	function handleStepChange() {
		onStepNagivationClick(position);
	}

	const disabled = isComplete || position === currentStep + 1 ? '' : 'disabled-step';

	return (
		<div className="step-hover">
			<button
				className={currentStep > position ? 'step-checked' : `step-number ${disabled}`}
				onClick={() => handleStepChange()}
			>
				{position + 1}
			</button>
			<button
				className={currentStep < position ? `step-number-hovered ${disabled}` : 'step-checked-hovered'}
				onClick={() => handleStepChange()}
			>
				<Tooltip title={bigName}>
					<Text ellipsis>{I18n.get(name)}</Text>
				</Tooltip>
			</button>
		</div>
	);
};
