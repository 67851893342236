import * as React from 'react';

type Props = {
	width?: string;
	height?: string;
}

export const JdsD92Icon = ({ width = '1rem', height = '1rem' }: Props) => {
	return (
		<svg width={width} height={height} viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M4.32328 15.1328V20.8438H6.36544C7.2954 20.8438 8.0391 20.5782 8.59654 20.0472C9.15666 19.5162 9.43672 18.806 9.43672 17.9166C9.43672 16.0607 8.41296 15.1328 6.36544 15.1328H4.32328ZM5.62174 19.8003V16.1802H6.2569C6.83042 16.1802 7.2753 16.3368 7.59154 16.6501C7.91046 16.9608 8.06992 17.3882 8.06992 17.9325C8.06992 18.5087 7.90912 18.964 7.58752 19.2985C7.2686 19.6331 6.82774 19.8003 6.26494 19.8003H5.62174Z" fill="currentColor" />
			<path fill-rule="evenodd" clip-rule="evenodd" d="M15.6622 0.304241L22.6929 7.26942C22.8889 7.46362 23 7.72902 23 8.00413V27.9643C23 28.5372 22.5328 29 21.9545 29H1.04545C0.467187 29 0 28.5372 0 27.9643V1.03571C0 0.462835 0.467187 0 1.04545 0H14.9239C15.2016 0 15.4662 0.110045 15.6622 0.304241ZM14.4403 8.47991H20.5889L14.4403 2.38862V8.47991ZM2.35227 26.6696H20.6477V10.6808H13.5909C13.227 10.6808 12.878 10.5376 12.6206 10.2827C12.3633 10.0277 12.2188 9.68196 12.2188 9.32143V2.33036H2.35227V26.6696Z" fill="currentColor" />
			<path d="M14.3118 17.4997C14.3118 18.0406 14.2529 18.5248 14.1349 18.9525C14.017 19.3773 13.8444 19.737 13.6169 20.0315C13.3923 20.326 13.1172 20.5526 12.7915 20.7112C12.4658 20.8669 12.0966 20.9448 11.6839 20.9448C11.1898 20.9448 10.7644 20.8655 10.4078 20.7069V19.6024C10.7251 19.812 11.1069 19.9168 11.5533 19.9168C12.011 19.9168 12.369 19.7596 12.6273 19.4453C12.8856 19.1309 13.0161 18.6764 13.0189 18.0816L12.9937 18.0731C12.7269 18.4725 12.3212 18.6721 11.7766 18.6721C11.5295 18.6721 11.3007 18.6254 11.0901 18.5319C10.8823 18.4385 10.7012 18.3096 10.5468 18.1454C10.3952 17.9783 10.2759 17.78 10.1888 17.5506C10.1046 17.3212 10.0625 17.0692 10.0625 16.7945C10.0625 16.4745 10.1144 16.1828 10.2183 15.9194C10.3222 15.656 10.4668 15.4294 10.6521 15.2397C10.8402 15.0499 11.0648 14.9041 11.3259 14.8021C11.587 14.6974 11.8762 14.645 12.1935 14.645C12.522 14.645 12.8168 14.7101 13.0779 14.8404C13.339 14.9678 13.5608 15.1533 13.7433 15.3969C13.9286 15.6404 14.069 15.9392 14.1644 16.2932C14.2627 16.6472 14.3118 17.0494 14.3118 17.4997ZM12.9684 16.786C12.9684 16.6302 12.9487 16.4844 12.9094 16.3484C12.8701 16.2097 12.8154 16.0907 12.7452 15.9916C12.675 15.8925 12.5894 15.8146 12.4883 15.758C12.3872 15.6985 12.2763 15.6687 12.1556 15.6687C12.0405 15.6687 11.9338 15.6942 11.8355 15.7452C11.7401 15.7962 11.6572 15.867 11.587 15.9576C11.5168 16.0454 11.4621 16.1516 11.4228 16.2762C11.3835 16.4008 11.3638 16.5353 11.3638 16.6798C11.3638 16.8384 11.3835 16.98 11.4228 17.1046C11.4621 17.2264 11.5168 17.3297 11.587 17.4147C11.66 17.4997 11.7471 17.5648 11.8481 17.6101C11.9492 17.6526 12.0615 17.6738 12.1851 17.6738C12.303 17.6738 12.4097 17.6512 12.5051 17.6059C12.6006 17.5577 12.682 17.494 12.7494 17.4147C12.8196 17.3354 12.8729 17.2419 12.9094 17.1343C12.9487 17.0267 12.9684 16.9106 12.9684 16.786Z" fill="currentColor" />
			<path d="M16.5018 19.7256H19.0623V20.8386H15.0446V20.3798C15.0446 20.0683 15.0966 19.7894 15.2004 19.543C15.3043 19.2937 15.4349 19.0714 15.5921 18.876C15.7493 18.6778 15.9206 18.5036 16.1059 18.3535C16.294 18.2006 16.4723 18.0632 16.6407 17.9415C16.8176 17.814 16.972 17.6922 17.104 17.5761C17.2388 17.46 17.3511 17.3453 17.4409 17.232C17.5336 17.1159 17.6024 16.9998 17.6473 16.8837C17.6922 16.7647 17.7147 16.6387 17.7147 16.5056C17.7147 16.2451 17.6417 16.0482 17.4957 15.9151C17.3497 15.782 17.1265 15.7155 16.826 15.7155C16.3066 15.7155 15.8097 15.9236 15.3352 16.3399V15.159C15.8602 14.8163 16.4526 14.645 17.1124 14.645C17.4185 14.645 17.6922 14.686 17.9337 14.7682C18.1779 14.8475 18.3843 14.9622 18.5527 15.1123C18.7212 15.2623 18.8489 15.445 18.936 15.6602C19.0258 15.8727 19.0707 16.1105 19.0707 16.3739C19.0707 16.6543 19.0272 16.9035 18.9402 17.1216C18.856 17.3396 18.7422 17.5379 18.5991 17.7163C18.4587 17.8947 18.2958 18.059 18.1105 18.2091C17.9252 18.3563 17.7329 18.4994 17.5336 18.6381C17.3988 18.7344 17.2682 18.8307 17.1419 18.927C17.0184 19.0205 16.9089 19.1139 16.8134 19.2074C16.718 19.298 16.6422 19.3872 16.586 19.475C16.5298 19.5628 16.5018 19.6463 16.5018 19.7256Z" fill="currentColor" />
		</svg>

	);
};
