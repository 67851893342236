import React from 'react';
import Table, { ColumnsType } from 'antd/lib/table';
import { Title } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';
import { Col, Row } from 'antd';

import { SeraResultCycleSummary } from '../hooks/types';
import { CustomTable } from './styles';

interface CycleSummaryProps {
	cycleSummary: SeraResultCycleSummary;
}

export const CycleSummary: React.FC<CycleSummaryProps> = ({ cycleSummary }) => {
	const columns: ColumnsType<SeraResultCycleSummary> = [
		{
			title: I18n.get('Analyzed reports'),
			dataIndex: 'analyzed_reports',
			key: 1,
			align: 'center'
		},
		{
			title: I18n.get('Reviewed reports'),
			dataIndex: 'reviewed_reports',
			key: 2,
			align: 'center'
		},
		{
			title: I18n.get('Highest RPN'),
			dataIndex: 'highest_rpn',
			key: 3,
			align: 'center'
		},
		{
			title: I18n.get('Sum of RPN'),
			dataIndex: 'sum_rpn',
			key: 4,
			align: 'center'
		},
		{
			title: I18n.get('Original version sum of RPN'),
			dataIndex: 'original_version_sum_rpn',
			key: 5,
			align: 'center'
		},
		{
			title: I18n.get('Average RPN'),
			dataIndex: 'average_rpn',
			key: 6,
			align: 'center',
			render: (average_rpn) => average_rpn.toFixed(2)
		}
	];

	return (
		<Col span={24}>
			<Row>
				<Title level={5}>Cycle summary</Title>
			</Row>
			<Row justify="center">
				<CustomTable span={20}>
					<Table rowKey="id" pagination={false} dataSource={[cycleSummary]} columns={columns} />
				</CustomTable>
			</Row>
		</Col>
	);
};
