import styled, { css } from 'styled-components';

type CustomProps = {
	selected?: boolean;
	anyOtherChecked?: boolean;
	hasBody?: boolean;
	open?: boolean;
};

const colorSelected = '#2F54EB';

const borderSelected = css`
	box-shadow: lightgrey 2px 2px 10px;
	border: 1px solid ${colorSelected};
`;

export const Title = styled.strong<CustomProps>`
	margin: 0px;
	font-size: 15px;
	color: ${(props) => (props.anyOtherChecked ? '#4C4C4E80' : props.selected && colorSelected)};
`;

export const Container = styled.div<CustomProps>`
	border-radius: 8px;
	user-select: none;
	border: 1px solid #ddd;
	background: ${(props) => (props.anyOtherChecked ? '#f5f5f5' : '#fafafa')};
	cursor: ${(props) => (!props.anyOtherChecked && (!props.selected || !props.hasBody) ? 'pointer' : 'auto')};
	padding: 15px;
	margin-bottom: 0;

	${(props) => props.selected && borderSelected}
`;

export const Body = styled.div<CustomProps>`
	border-top: none;
	opacity: ${(props) => (props.open ? '1' : '0')};
	max-height: ${(props) => (props.open ? '100%' : '0')};
	overflow: hidden;
	padding: ${(props) => (props.open ? '1rem' : '0 1rem')};
	transition: all 0.3s;
	cursor: auto;
`;
