import Api from '@/services/api';

import { CustomReportFilesRequest } from '@/components/ui/Inputs/types/request/customReport';
import { CustomReportFilesDTO } from '@/components/ui/Inputs/types/response/customReport';

import {
	SeraReportRequest,
	CreateSeraResultReviewRequest,
	DownloadFileRequest,
	DeleteSeraReport
} from '../../hooks/types/request';
import { CreatedSeraReportDTO, SeraReportDTO } from '../../hooks/types/response';

export class SeraService {
	async getSeraReport({
		organization_id,
		company_id,
		file_id,
		sera_summary_id
	}: SeraReportRequest): Promise<SeraReportDTO> {
		const url = `/sera/report/file/${file_id}?sera_summary_id=${sera_summary_id}&organization_id=${organization_id}&company_id=${company_id}`;
		const { data } = await Api.get<SeraReportDTO>(url);
		return data;
	}

	async createSeraReportReview(body: CreateSeraResultReviewRequest): Promise<CreatedSeraReportDTO> {
		const url = '/sera/report/review';
		const { data } = await Api.post<CreatedSeraReportDTO>(url, body);
		return data;
	}

	async deleteSeraReport(params: DeleteSeraReport): Promise<SeraReportDTO> {
		const url = `/sera/report/${params.id}`;
		const { data } = await Api.delete<SeraReportDTO>(url, { params });
		return data;
	}

	async downloadFile({ company_id, organization_id, file }: DownloadFileRequest): Promise<string> {
		const url = '/upload/create-url-download';
		const { data } = await Api.post<string>(url, {
			file_id: file.id,
			company_id,
			original_name: file.original_name,
			organization_id
		});
		return data;
	}

	async getSeraFiles({
		organization_id,
		company_id,
		custom_report_id,
		report_type,
		fileName,
		offset,
		limit
	}: CustomReportFilesRequest): Promise<CustomReportFilesDTO> {
		const url = `/custom-report/list/${custom_report_id}`;
		const { data } = await Api.get<CustomReportFilesDTO>(url, {
			params: {
				limit,
				offset,
				company_id,
				organization_id,
				file_name: fileName,
				report_type
			}
		});
		return data;
	}
}
