import React from 'react';
import { Text } from '@/components/Typography';
import { Col, Row } from 'antd';

import { DisplacementCard, FrequencyCard, ObjectWeightCard } from './Cards';
import { useLibertyMutualSubStepsContext } from '../../context';
import { TaskNameMapper } from '../../hooks/types/response';

export const CarrySummary = () => {
	const {
		libertyMutualResult: {
			informations: { task_name }
		}
	} = useLibertyMutualSubStepsContext();

	if (!isCarry(task_name)) {
		return <Text>Oops... Something went wrong!</Text>;
	}

	function isCarry(task_name: TaskNameMapper): task_name is TaskNameMapper.Carry {
		return task_name === TaskNameMapper.Carry;
	}

	return (
		<Row>
			<Col xs={24}>
				<Row justify="center" style={{ marginBottom: '1rem' }}>
					<Col xs={20} style={{ maxWidth: '300px' }}>
						<Row justify="center">
							<Col xs={15} style={{ maxWidth: '300px' }}>
								<DisplacementCard task_name={task_name} />
							</Col>
						</Row>
					</Col>
				</Row>
				<Row justify="center">
					<Col xs={24} lg={12} style={{ maxWidth: '900px' }}>
						<Row justify="space-between">
							<Col xs={10} style={{ maxWidth: '300px' }}>
								<FrequencyCard />
							</Col>
							<Col xs={10} style={{ maxWidth: '300px' }}>
								<ObjectWeightCard />
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
