import React, { useCallback, useEffect, useState } from 'react';
import { CheckOutlined, DeleteOutlined, EditOutlined, PlusOutlined, StopOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputRef, List, message, Row, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { I18n } from '@aws-amplify/core';
import { v4 as uuidv4 } from 'uuid';

import { ActionButton, AddTask, ScrollableContainer, TaskList, TaskListContent, TaskManagerContainer } from './styles';
import { ActionPlanTask, ActionPlanTaskTypes } from '@/types';
import { TaskListProps } from '../types';

type TasksProps = {
	fetchedTasks?: ActionPlanTask[];
	onTasksChange: (tasks: TaskListProps[]) => void;
};

const conditionMapper = {
	ELIMINATE: 'Eliminate',
	CONTROL: 'Control',
	COMPENSATE: 'Compensate'
};

export const Tasks: React.FC<TasksProps> = ({ fetchedTasks, onTasksChange }: TasksProps) => {
	const [form] = useForm();

	const mappedTasks = fetchedTasks?.map((fetchedTask) => {
		return {
			id: uuidv4(),
			taskId: fetchedTask.id,
			description: fetchedTask.description ?? '',
			isCompleted: fetchedTask.is_completed ?? false,
			type: fetchedTask.type ?? ActionPlanTaskTypes.ELIMINATE
		};
	});

	const [editing, setEditing] = useState<boolean[]>([]);
	const [taskInput, setTaskInput] = useState<string>('');
	const [taskEditInput, setTaskEditInput] = useState<string>('');
	const [taskType, setTaskType] = useState<ActionPlanTaskTypes>();
	const [tasks, setTasks] = useState<TaskListProps[]>(mappedTasks ?? []);
	const [taskEditType, setTaskEditType] = useState<ActionPlanTaskTypes>();

	const selectOptions = [
		{
			value: 'ELIMINATE',
			label: I18n.get('Eliminate')
		},
		{
			value: 'CONTROL',
			label: I18n.get('Control')
		},
		{
			value: 'COMPENSATE',
			label: I18n.get('Compensate')
		}
	];

	const editInputRef = useCallback((node: InputRef) => {
		if (node) {
			node.focus();
		}
	}, []);

	const editSelectRef = useCallback((instance: any) => {
		if (instance) {
			instance.focus();
		}
	}, []);

	useEffect(() => {
		onTasksChange(tasks);
	}, [tasks]);

	function handleInputChange(
		event: React.ChangeEvent<HTMLInputElement>,
		setInput: React.Dispatch<React.SetStateAction<string>>
	) {
		setInput(event.target.value);
	}

	function onCreateTask() {
		if (!taskInput || !taskType) return;

		const task: TaskListProps = {
			id: uuidv4(),
			description: taskInput,
			taskId: uuidv4(),
			isCompleted: false,
			type: taskType
		};

		setTasks((prevTasks) => [...prevTasks, task]);

		setTaskEditInput(taskInput);
		setTaskEditType(taskType);
		setTaskInput('');
		setTaskType(undefined);
		form.resetFields(['task']);
	}

	function handleDeleteTask(
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		task: TaskListProps,
		taskIndex: number
	) {
		event.stopPropagation();
		if (editing[taskIndex] === false || !editing[taskIndex]) {
			onDeleteTask(task);
		} else {
			setEditing((prev) => [...prev.slice(0, taskIndex), false, ...prev.slice(taskIndex + 1)]);
		}
	}

	async function onDeleteTask(task: TaskListProps) {
		const taskIndex = tasks.findIndex((prevTask) => prevTask.id === task.id);

		const tasksCopy = [...tasks];

		tasksCopy.splice(taskIndex, 1);

		setTasks(tasksCopy);
	}

	function onStartEditTask(
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		task: TaskListProps,
		taskIndex: number
	) {
		event.stopPropagation();

		if (editing[taskIndex] === true) {
			onFinishEditTask(task, taskIndex);
			return;
		}

		if (editing.some((item) => item === true)) {
			message.warn(I18n.get('Finish editing your other task first'));
			return;
		}

		setTaskEditInput(task.description);
		setTaskEditType(task.type);

		setEditing((prev) => {
			prev = new Array(tasks.length).fill(false);
			return [...prev.slice(0, taskIndex), true, ...prev.slice(taskIndex + 1)];
		});
	}

	async function onFinishEditTask(task: TaskListProps, taskIndex: number) {
		if (!taskEditInput || !taskEditType) return;
		setEditing((prev) => [...prev.slice(0, taskIndex), false, ...prev.slice(taskIndex + 1)]);

		if (task.description === taskEditInput && task.type === taskEditType) {
			setTaskEditInput('');
			setTaskEditType(undefined);
			return;
		}

		const taskToEdit = tasks.findIndex((prevTask) => prevTask.id === task.id);
		const updatedTask: TaskListProps = {
			...tasks[taskToEdit],
			description: taskEditInput,
			type: taskEditType
		};

		const tasksCopy = [...tasks];

		tasksCopy[taskIndex] = updatedTask;

		setTasks(tasksCopy);
	}

	const EditButton: React.FC<{ task: TaskListProps; taskIndex: number }> = ({ task, taskIndex }) => (
		<ActionButton
			style={{ cursor: 'pointer' }}
			onClick={(e) => onStartEditTask(e, task, taskIndex)}
			className={editing[taskIndex] ? 'confirm-btn' : 'edit-btn'}
		>
			{editing[taskIndex] ? <CheckOutlined /> : <EditOutlined />}
		</ActionButton>
	);

	const DeleteButton: React.FC<{ task: TaskListProps; taskIndex: number }> = ({ task, taskIndex }) => (
		<ActionButton
			style={{ cursor: 'pointer' }}
			onClick={(e) => handleDeleteTask(e, task, taskIndex)}
			className="delete-btn"
		>
			{editing[taskIndex] ? <StopOutlined /> : <DeleteOutlined />}
		</ActionButton>
	);

	return (
		<TaskManagerContainer>
			<AddTask>
				<Row justify="center" gutter={[10, 0]} style={{ width: '100%' }}>
					<Col span={10}>
						<Form.Item name="task_title" style={{ margin: '0' }}>
							<Input
								value={taskInput}
								onChange={(e) => handleInputChange(e, setTaskInput)}
								onPressEnter={onCreateTask}
								placeholder={I18n.get('Add a new task')}
							/>
						</Form.Item>
					</Col>
					<Col span={10}>
						<Form.Item
							name="condition"
							style={{ margin: '0' }}
							rules={[{ required: taskInput?.length > 0, message: I18n.get('Select a condition') }]}
						>
							<Select
								allowClear
								onBlur={onCreateTask}
								onChange={(value) => setTaskType(value)}
								placeholder={I18n.get('Select a condition')}
								options={selectOptions}
							/>
						</Form.Item>
					</Col>
					<Col span={2}>
						<Button shape="circle" onClick={onCreateTask} icon={<PlusOutlined />}></Button>
					</Col>
				</Row>
			</AddTask>
			<TaskList>
				<ScrollableContainer>
					<List
						size="small"
						dataSource={tasks}
						renderItem={(task: TaskListProps, taskIndex: number) => (
							<List.Item
								onClick={(e) => onStartEditTask(e, task, taskIndex)}
								actions={[
									<EditButton key={taskIndex} task={task} taskIndex={taskIndex} />,
									<DeleteButton key={taskIndex} task={task} taskIndex={taskIndex} />
								]}
							>
								{editing[taskIndex] ? (
									<>
										<Col span={8}>
											<Form.Item
												name={`task_title_edit_${taskIndex}`}
												style={{ width: '100%', margin: '0' }}
												initialValue={tasks[taskIndex].description}
											>
												<Input
													className={`task_title_edit_input${taskIndex}`}
													ref={editInputRef}
													value={taskEditInput}
													onChange={(e) => handleInputChange(e, setTaskEditInput)}
													onClick={(e) => e.stopPropagation()}
													onPressEnter={() => onFinishEditTask(task, taskIndex)}
													style={{ width: '100%', marginRight: '1rem' }}
												/>
											</Form.Item>
										</Col>
										<Col span={8}>
											<Form.Item
												name={`condition_edit_${taskIndex}`}
												style={{ width: '100%', margin: '0' }}
												initialValue={tasks[taskIndex].type}
											>
												<Select
													className={`condition_edit_select_${taskIndex}`}
													ref={editSelectRef}
													value={taskEditType}
													onClick={(e) => e.stopPropagation()}
													onChange={(value) => setTaskEditType(value)}
													options={selectOptions}
													placeholder={I18n.get('Select a condition')}
													style={{ width: '100%', marginRight: '1rem' }}
												/>
											</Form.Item>
										</Col>
									</>
								) : (
									<TaskListContent>
										<Col span={8}>{task.description}</Col>
										<Col span={8}>{I18n.get(conditionMapper[task.type])}</Col>
									</TaskListContent>
								)}
							</List.Item>
						)}
					/>
				</ScrollableContainer>
			</TaskList>
		</TaskManagerContainer>
	);
};
