import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Button, Col, Dropdown, Grid, Row, Tooltip } from 'antd';
import Icon, {
	DeleteOutlined,
	DownloadOutlined,
	MenuOutlined,
	PlusCircleOutlined,
} from '@ant-design/icons';

import { useApplicationContext } from '@/context/v1/Application/context';
import { JdsD86List } from './hooks/types/response';
import { ActionsInline, ActionsMenu, CustomButton } from './styles';
import { JdsD86Icon } from '@/assets/icons/ewa-jds-d86-icon';
import Reviews from '@/assets/icons/reviews';
import { SelectedReport } from './types';
import { useDownloadPdf } from './hooks/hooks';
import { useHistory } from 'react-router-dom';

interface ActionsProps {
	rowData: JdsD86List;
	onReviewsClick: (id: string) => void;
	onDeleteActionClick: (report: SelectedReport) => void;
}

export const Actions: React.FC<ActionsProps> = ({ rowData, onReviewsClick, onDeleteActionClick }) => {
	const { organization, company } = useApplicationContext();
	const { mutateAsync: downloadPdf, isLoading: downloadingPDF } = useDownloadPdf();
	const breakpoints = Grid.useBreakpoint();
	const fontSize = breakpoints.xxl ? '25px' : '15px';
	const btnSize = breakpoints.xxl ? 'large' : 'small';

	const browserLanguage = window.navigator.language ?? 'en-US';

	const history = useHistory();

	function handleEditReport(data: JdsD86List) {
		history.push(`/custom-reports/jds-d86/report/${data.original_id}`);
	}

	function handleNewReview(data: JdsD86List) {
		history.push(`/custom-reports/jds-d86/review/${data.original_id}`);
	}

	async function handleDownloadPdf(data: JdsD86List) {
		await downloadPdf({
			organization_id: organization?.id,
			company_id: company?.id,
			locale: browserLanguage,
			file_id: data.original_id,
			report_id: data.id,
			original_custom_report_result_id: data.original_id,
			selected_pdf_sections: [
				"not_evaluated",
				"niosh",
				"kim_mho",
				"reba",
				"kim_pp",
				"strain_index",
				"liberty_mutual",
				"action_plans",
			]
		});
	}

	const handleJdsD86Icon = () => <Icon component={JdsD86Icon} style={{ fontSize }} />;

	const items = [
		{
			key: 1,
			icon: <Icon component={JdsD86Icon} style={{ fontSize: '12px' }} />,
			label: (
				<Button type="link"
					onClick={() => handleEditReport(rowData)}
				>
					{I18n.get('Edit')}
				</Button>
			)
		},
		{
			key: 2,
			icon: <PlusCircleOutlined style={{ fontSize: '12px' }} />,
			label: (
				<Button type="link"
					onClick={() => handleNewReview(rowData)}
				>
					{I18n.get('New revaluation')}
				</Button>
			)
		},
		{
			key: 3,
			icon: <Icon component={Reviews} style={{ fontSize: '12px' }} />,
			label: (
				<Button type="link"
					onClick={() => onReviewsClick(rowData.original_id)}
				>
					{I18n.get('Revaluations')}
				</Button >
			)
		},
		{
			key: 4,
			icon: <DownloadOutlined />,
			label: (
				<Button
					disabled={downloadingPDF}
					loading={downloadingPDF}
					type="link"
					onClick={() =>
						handleDownloadPdf(rowData)
					}
				>
					{I18n.get('Download')}
				</Button>
			)
		},
		{
			key: 5,
			danger: true,
			icon: <DeleteOutlined />,
			label: (
				<CustomButton>
					<Button type="link" onClick={() => onDeleteActionClick({
						id: rowData.id,
						report_name: rowData.report_name,
						last_review_date: rowData.last_review_date,
						original_id: rowData.original_id,
					})}>
						{I18n.get('Delete')}
					</Button>
				</CustomButton>
			)
		}
	];

	return (
		<Row align="middle" justify="center">
			<ActionsInline xs={24}>
				<Row align="middle" justify="center" gutter={[10, 0]}>
					<Col span={4}>
						<Button
							onClick={() => {
								handleEditReport(rowData);
							}}
							type="link"
							size={btnSize}
							icon={
								<Tooltip overlayStyle={{ position: 'fixed' }} title={I18n.get('Edit')}>
									{handleJdsD86Icon()}
								</Tooltip>
							}
						/>
					</Col>
					<Col span={4}>
						<Button
							onClick={() => {
								handleNewReview(rowData);
							}}
							type="link"
							size={btnSize}
							icon={
								<Tooltip overlayStyle={{ position: 'fixed' }} title={I18n.get('New revaluation')}>
									<PlusCircleOutlined
										style={{
											fontSize
										}}
									/>
								</Tooltip>
							}
						/>
					</Col>
					<Col span={4}>
						<Button
							onClick={() => onReviewsClick(rowData.original_id)}
							type="link"
							size={btnSize}
							icon={
								<Tooltip overlayStyle={{ position: 'fixed' }} title={I18n.get('Revaluations')}>
									<Reviews style={{ fontSize }} />
								</Tooltip>
							}
						/>
					</Col>
					<Col span={4}>
						<Button
							disabled={downloadingPDF}
							onClick={() => handleDownloadPdf(rowData)}
							type="link"
							size={btnSize}
							loading={downloadingPDF}
							icon={
								<Tooltip overlayStyle={{ position: 'fixed' }} title={I18n.get('Download')}>
									<DownloadOutlined style={{ fontSize }} />
								</Tooltip>
							}
						/>
					</Col>
					<Col span={4}>
						<Button
							onClick={() => {
								onDeleteActionClick(rowData);
							}}
							type="link"
							size={btnSize}
							icon={
								<Tooltip overlayStyle={{ position: 'fixed' }} title={I18n.get('Delete')}>
									<DeleteOutlined style={{ color: '#E74150', fontSize }} />
								</Tooltip>
							}
						/>
					</Col>
				</Row>
			</ActionsInline>
			<ActionsMenu xs={2}>
				<Dropdown menu={{ items }} trigger={['click']}>
					<Button type="link" icon={<MenuOutlined />} />
				</Dropdown>
			</ActionsMenu>
		</Row>
	);
};
