import React from 'react';
import { I18n } from '@aws-amplify/core';
import { TypographyTitle, TypographyText, TypographyParagraph } from './styles';

export const Title: React.FC<any> = (props) => (
	<TypographyTitle  {...props}>{I18n.get(props.children)}</TypographyTitle>
);

export const Paragraph: React.FC<any> = (props) => (
	<TypographyParagraph  {...props}>{I18n.get(props.children)}</TypographyParagraph>
);

export const Text: React.FC<any> = (props) => <TypographyText {...props}>{I18n.get(props.children)}</TypographyText>;
