import React from 'react';
import { Pie } from 'react-chartjs-2';
import { diagnosticColors } from '@/constants/Reba';

interface PieChartProps {
	scores?: number[];
}

export function PieChart({ scores }: PieChartProps) {
	const data = {
		datasets: [
			{
				data: scores,
				borderWidth: 1,
				datalabels: {
					display: false
				},
				borderColor: diagnosticColors,
				backgroundColor: diagnosticColors
			}
		]
	};

	return <Pie data={data} />;
}
