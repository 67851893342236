import React from 'react';
import { Col, Row, Skeleton } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Title } from '@/components/Typography';

import { CustomTable, EmptyHead, RPN, RiskFactor, TLID, TableContainer } from './styles';
import { BeraResultCycleSummary } from '../types';

interface CycleSummaryProps {
	isLoading: boolean;
	cycleSummary: BeraResultCycleSummary;
}

export const CycleSummary: React.FC<CycleSummaryProps> = ({ isLoading, cycleSummary }) => {
	const subColumnTitles = [
		'Striking parts',
		'Hand activities',
		'Lifting',
		'Push/Pull One Hand',
		'Push/Pull Two Hand',
		'Push/Pull Cart',
		'Vibration',
		'Inadequate Clearance',
		'Contact Stress'
	];
	return (
		<Col span={24}>
			<Row>
				<Title level={5}>Cycle summary</Title>
			</Row>
			<Row justify="center">
				{isLoading ? (
					<Col span={24}>
						<Skeleton active />
					</Col>
				) : (
					<TableContainer>
						<CustomTable>
							<thead>
								<tr>
									<EmptyHead rowSpan={2}>
										<div />
										<RiskFactor>{I18n.get('Risk Factors')}</RiskFactor>
										<TLID>{I18n.get('TLID')}</TLID>
									</EmptyHead>
									<th rowSpan={2}>
										<div>{I18n.get('Too High')}</div>
									</th>
									<th rowSpan={2}>
										<div>{I18n.get('Too Low')}</div>
									</th>
									<th rowSpan={2}>
										<div>{I18n.get('Too Far')}</div>
									</th>
									<th colSpan={2}>
										<div style={{ width: '5rem' }}>{I18n.get('Too Many')}</div>
									</th>
									<th colSpan={5}>
										<div style={{ width: '6rem' }}>{I18n.get('Too Much')}</div>
									</th>
									<th colSpan={2}>
										<div style={{ width: '5rem' }}>{I18n.get('Too Long')}</div>
									</th>
									<th rowSpan={2} colSpan={2}>
										<RPN>{I18n.get('Score RPN')}</RPN>
									</th>
								</tr>
								<tr>
									{subColumnTitles.map((title, index) => (
										<th key={title}>
											<div>
												<div>
													<span>{I18n.get(title)}</span>
												</div>
											</div>
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{cycleSummary.task_rpn_scores.map((task_scores) => (
									<tr key={cycleSummary.id + task_scores.id}>
										<td>{task_scores.task_name}</td>
										{task_scores.rpn_scores?.map((score) => (
											<td key={`${task_scores.task_id}-${score.step_key_id}`}>
												{score.task_rpn}
											</td>
										))}
										<td colSpan={2}>{task_scores.rpn}</td>
									</tr>
								))}
								<tr style={{ borderTop: '0.5rem solid #fff' }}>
									<td>{I18n.get('Weighted RPN')}</td>
									{cycleSummary?.step_keys_weighted_rpn?.map((score) => (
										<td key={`${cycleSummary.id}-${score.step_key_id}`}>
											{score.task_rpn.toFixed(0)}
										</td>
									))}
									<td colSpan={2}>
										{cycleSummary.overall_score.toFixed(2)}
										<div>{I18n.get('Total B.E.R.A.')}</div>
									</td>
								</tr>
							</tbody>
						</CustomTable>
					</TableContainer>
				)}
			</Row>
		</Col>
	);
};
