import Api from '@/services/api';

export async function createReportService(parameters) {
	const response = await Api.post('/ergonomic-tool/reba', parameters);
	return response;
}

export async function updateReportService(parameters) {
	const response = await Api.put('/ergonomic-tool/reba', parameters);
	return response;
}

export async function setComments(parameters) {
	const response = await Api.put(`/ergonomic-tool/reba/comment`, parameters);
	return response;
}

export async function getNotesPerSecond({ organization_id, company_id, file_id }) {
	const url = `/ergonomic-tool/reba/note-per-second/${file_id}?organization_id=${organization_id}&company_id=${company_id}`;
	const { data } = await Api.get(url);
	return data;
}

export async function getworstGrade({ organization_id, company_id, file_id }) {
	const url = `/ergonomic-tool/reba/worst-grade/${file_id}?organization_id=${organization_id}&company_id=${company_id}`;
	const { data } = await Api.get(url);
	return data;
}

export async function getScore({ organization_id, company_id, file_id }) {
	const url = `/ergonomic-tool/reba/score/${file_id}?organization_id=${organization_id}&company_id=${company_id}`;
	const { data } = await Api.get(url);
	return data;
}

export async function getScoreParts({ organization_id, company_id, file_id }) {
	const url = `/ergonomic-tool/reba/score-parts/${file_id}?organization_id=${organization_id}&company_id=${company_id}`;
	const { data } = await Api.get(url);
	return data;
}

export async function getReport({ organizationId, companyId, fileId }) {
	const url = `/ergonomic-tool/reba/${fileId}?organization_id=${organizationId}&company_id=${companyId}`;
	const { data } = await Api.get(url);
	if (data.status === 'failed') {
		return null;
	}
	return data;
}

export async function getRiskRangeSettings({ organizationId, companyId }) {
	const url = `/ergonomic-tool/reba/risk-range?organization_id=${organizationId}&company_id=${companyId}`;
	const { data } = await Api.get(url);
	return data;
}
