import { useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import Api from '@/services/api';
import type { BaseContext, Response } from '@/types';

type SelfEvaluationResponse = {
	id: string;
	name: string;
	score: number;
	description: string;
};

async function getWorkerSelfEvaluation(params: BaseContext): Promise<SelfEvaluationResponse[]> {
	const url = '/worker-self-evaluation/find-all';
	const { data } = await Api.get<SelfEvaluationResponse[]>(url, {
		params
	});
	return data;
}

export const useGetWorkerSelfEvaluations = ({ organization_id, company_id }: BaseContext) => {
	const params = { organization_id, company_id };
	const report = useQuery<SelfEvaluationResponse[], AxiosError<Response>>(
		['jds_d86_result', 'worker_self_evaluation', params],
		() => getWorkerSelfEvaluation(params),
		{
			enabled: !!organization_id && !!company_id
		}
	);
	return {
		...report,
		data: report.data ?? ([] as SelfEvaluationResponse[])
	};
};
