import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col } from 'antd';

import { CustomFormItem, CustomRow } from './styles';

interface RiskDamageProps {
	riskDamage: string;
}

export const RiskDamage: React.FC<RiskDamageProps> = ({ riskDamage }) => {
	return (
		<Col xs={24} sm={12} lg={24}>
			<CustomFormItem label={I18n.get('Damage')} labelCol={{ span: 24 }}>
				<CustomRow>{I18n.get(riskDamage)}</CustomRow>
			</CustomFormItem>
		</Col>
	);
};
