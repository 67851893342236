import React from 'react';
import { I18n } from '@aws-amplify/core';
import { ColumnsType } from 'antd/es/table';
import { Col, Table, TableColumnsType } from 'antd';

import { BackCompressiveForceEstimationResult } from '@/hooks';
import { getTotalCompressiveForceColor } from '@/components/views/Report/BackCompressiveForceEstimation/Report/Result/ResultForce';
import * as S from './styles';

interface BackCompressiveForceEstimationProps {
	data?: BackCompressiveForceEstimationResult;
}

interface DataType {
	key: React.Key;
	person_height: string;
	person_weight: string;
	total_compressive_force_result: string;
}

export const BackCompressiveForceResults: React.FC<BackCompressiveForceEstimationProps> = ({ data }) => {
	if (!data) {
		return null;
	}

	const {
		id,
		load_in_hands,
		person_height,
		person_weight,
		trunk_angle_to_horizontal,
		horizontal_distance_from_hands_to_low_back,
		total_compressive_force_result
	} = data;

	const columns: ColumnsType<DataType> = [
		{
			title: I18n.get('Person weight'),
			dataIndex: 'person_weight',
			key: 'person_weight'
		},
		{
			title: I18n.get('Person height'),
			dataIndex: 'person_height',
			key: 'person_height'
		},
		{
			title: I18n.get('Total compressive force'),
			dataIndex: 'total_compressive_force_result',
			key: 'total_compressive_force_result',
			width: '28%'
		}
	];

	const dataColumn: DataType[] = [
		{
			key: id,
			person_weight,
			person_height,
			total_compressive_force_result: `${total_compressive_force_result} N`
		}
	];

	return (
		<Col span={24} style={{ marginTop: '2rem' }}>
			<S.BackCompressiveForceEstimationTableContainerFirstRow
				$resultColor={getTotalCompressiveForceColor(total_compressive_force_result)}
			>
				<Table columns={columns} dataSource={dataColumn} pagination={false} />
			</S.BackCompressiveForceEstimationTableContainerFirstRow>
			<S.BackCompressiveForceEstimationTableContainerSecondRow>
				<ExpandedTable
					load_in_hands={load_in_hands}
					trunk_angle_to_horizontal={trunk_angle_to_horizontal}
					horizontal_distance_from_hands_to_low_back={horizontal_distance_from_hands_to_low_back}
				/>
			</S.BackCompressiveForceEstimationTableContainerSecondRow>
		</Col>
	);
};

interface ExpandedTableType {
	key?: React.Key;
	load_in_hands: string;
	trunk_angle_to_horizontal: number | string;
	horizontal_distance_from_hands_to_low_back: string;
}

const ExpandedTable: React.FC<ExpandedTableType> = ({
	load_in_hands,
	trunk_angle_to_horizontal,
	horizontal_distance_from_hands_to_low_back
}) => {
	const columns: TableColumnsType<ExpandedTableType> = [
		{
			title: I18n.get('Trunk angle to horizontal'),
			dataIndex: 'trunk_angle_to_horizontal',
			key: 'trunk_angle_to_horizontal',
			width: '33.3333%'
		},
		{
			title: I18n.get('Horizontal distance from hands to low back'),
			dataIndex: 'horizontal_distance_from_hands_to_low_back',
			key: 'horizontal_distance_from_hands_to_low_back',
			width: '33.3333%'
		},
		{
			title: I18n.get('Load in hands'),
			dataIndex: 'load_in_hands',
			key: 'load_in_hands',
			width: '33.3333%'
		}
	];

	const data: ExpandedTableType[] = [
		{
			trunk_angle_to_horizontal: `${trunk_angle_to_horizontal}°`,
			load_in_hands,
			horizontal_distance_from_hands_to_low_back,
			key: 'back_compressive_force_estimation_second_row'
		}
	];

	return <Table columns={columns} dataSource={data} pagination={false} />;
};
