import { useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import type {
	Niosh,
	StrainIndex,
	KimPushPull,
	KimManualHandling,
	LibertyMutualResponse,
	BodyPartsName
} from './useGetCustomReportResults';
import Api from '@/services/api';
import type {
	BaseContext,
	CustomReportStepKey,
	CustomReportStepKeyResult,
	CustomReportSubStepKey,
	CustomReportSubStepKeyResult,
	Reba
} from '@/types';

export type GetReportDTO = BaseContext & {
	file_id?: string;
	custom_report_id: string;
	action_type?: string;
	original_custom_report_result_id?: string;
};

export enum REPORT_TYPE {
	NIOSH = 'niosh',
	KIM_PP = 'kim-pp',
	STRAIN_INDEX = 'strain-index',
	KIM_MHO = 'kim-mho',
	LIBERTY_MUTUAL = 'liberty-mutual',
	BACK_COMPRESSIVE_FORCE_ESTIMATION = 'back-compressive-force-estimation'
}

export type ErgonomicTools = {
	id: string;
	title: string;
	subtitle: string;
	description: REPORT_TYPE;
};

export type AdditionalItemsResults = {
	id: string;
	result: number;
	value_1?: number;
	value_2?: number;
	value_3?: number;
	value_4?: number;
	description?: string;
	additional_item_option_id_1?: string;
	additional_item_option_id_2?: string;
};

export type AdditionalItemType = {
	id: string;
	type: string;
};

export type StepKeyAdditionalItem = {
	id: string;
	created_at: Date;
	updated_at: Date;
	deleted_atw: Date | null;
	additional_item_id: string;
	custom_report_step_key_id: string;
};

export type AdditionalItem = {
	id: string;
	name: string;
	title_1: string;
	title_2?: string;
	title_3?: string;
	title_4?: string;
	title_5?: string;
	title_6?: string;
	unit_1?: string;
	unit_2?: string;
	unit_3?: string;
	unit_4?: string;
	unit_5?: string;
	unit_6?: string;
	results: AdditionalItemsResults[];
	additional_item_type: AdditionalItemType;
	CustomReportStepKeysAdditionalItem: StepKeyAdditionalItem;
};

export type CustomReportStepKeyResultResponse = Pick<CustomReportStepKeyResult, 'id'>;
export type CustomReportSubStepKeyResultResponse = Pick<CustomReportSubStepKeyResult, 'id'>;

export type StepKeyResponse = Pick<CustomReportStepKey, 'id' | 'name' | 'description'>;
export type SubStepKeyResponse = Pick<CustomReportSubStepKey, 'id' | 'name' | 'description' | 'sequence'>;

export type SubStepKey = SubStepKeyResponse & {
	custom_report_step_key_id: string;
	custom_report_sub_step_key_result?: CustomReportSubStepKeyResultResponse;
};

export type StepKeyResultScore = {
	score: number;
	result: number;
};

export type StepKey = StepKeyResponse & {
	checked: boolean;
	sub_step_keys?: SubStepKey[];
	ergonomic_tool?: ErgonomicTools[];
	additional_items?: AdditionalItem[];
	previous_result?: StepKeyResultScore;
	custom_report_step_key_result?: CustomReportStepKeyResultResponse;
};

export type AdditionalItemsForm = {
	[key: string]: AdditionalItem | AdditionalItem[];
};

export type DefaultResult = {
	score?: number;
	result?: number;
	severity_id?: string;
	exposure_id?: string;
	risk_damage_id?: string;
	risk_category_id?: string;
	vulnerability_id?: string;
	risk_description_id?: string;
};

type SubStepKeysResultValues = DefaultResult & {
	sub_step_key_result_id: string;
	custom_report_step_key_id: string;
	custom_report_sub_step_key_id: string;
};

export type SubStepKeysResult = {
	[key: string]: SubStepKeysResultValues;
};

export type StepKeyResultValues = DefaultResult & {
	step_key_result_id?: string;
	custom_report_step_key_id?: string;
	additional_items?: AdditionalItemsForm;
	sub_step_keys_results?: SubStepKeysResult[];
};

export type StepKeysValues = {
	[key: string]: StepKeyResultValues | SubStepKeysResult;
};

export type Step = {
	id: string;
	name: string;
	sequence: number;
	icon?: JSX.Element;
	description: string;
	step_keys?: StepKey[];
	component?: JSX.Element;
};

export type Informations = {
	id: string;
	line_id?: string;
	line_name?: string;
	company_id: string;
	company_name: string;
	sector_id?: string;
	sector_name?: string;
	workstation_id?: string;
	workstation_name?: string;
	organization_id: string;
	organization_name: string;
	original_name: string;
	collection_date?: Date | string;
	evaluator_id?: string;
	evaluator_name?: string;
	interviewee_name?: string;
	interviewer_name?: string;
	uploaded_date: Date | string;
	created_date: Date | string;
	name?: string;
	duration: string;
};

export type Characteristics = {
	id: string;
	self_evaluation_id: string;
	working_population_men: number;
	total_working_population: number;
	working_population_women?: number;
	working_population_others?: number;
	particularities_description?: string;
	worker_verbalization_description?: string;
};

export type WorkConditions = {
	id: string;
	work_hours: number;
	work_minutes?: number;
	place_description?: string;
	expected_task_description?: string;
	performed_task_description?: string;
};

export type RebaBasicResponse = Pick<Reba, 'id' | 'file_id'>;
export type RebaBodyPartScores = {
	id: number;
	text: string;
	level: number;
	percentage: number;
};

export type RebaData = {
	id: number;
	name: BodyPartsName;
	scores: RebaBodyPartScores[];
};
export type RebaDataResponse = RebaData[];
export type RebaResponseReport = RebaBasicResponse & {
	data: RebaDataResponse[];
};

export type ToolsReponse = {
	niosh: Niosh | null;
	reba: RebaResponseReport | null;
	kim_pp: KimPushPull | null;
	strain_index: StrainIndex | null;
	kim_mho: KimManualHandling | null;
	liberty_mutual: LibertyMutualResponse | null;
	back_compressive_force_estimation?: any | null;
}

export type EwaJdsD86ReportResponse = {
	id: string;
	steps: Step[];
	file_id: string;
	comment: string;
	result_id?: string;
	total_steps: number;
	current_step: number;
	is_completed: boolean;
	current_step_name: string;
	informations: Informations;
	characteristics?: Characteristics;
	work_conditions?: WorkConditions;
	tools: ToolsReponse;
};

const getReport = async (params: GetReportDTO) => {
	const url = '/custom-report/fill-out';
	let { data } = await Api.get<EwaJdsD86ReportResponse>(url, {
		params
	});
	return data;
};

export const useGetCustomReport = ({
	file_id,
	company_id,
	organization_id,
	custom_report_id,
	original_custom_report_result_id,
	action_type
}: GetReportDTO) => {
	const parameters = { organization_id, company_id, file_id, custom_report_id, original_custom_report_result_id, action_type };
	const enabled = !!(organization_id && company_id && custom_report_id);

	const report = useQuery<EwaJdsD86ReportResponse, AxiosError<Response>>(
		['jds_d86_report', parameters],
		() => getReport(parameters),
		{
			enabled,
			retry: 0
		}
	);

	return {
		...report,
		data: report.data ?? ({} as EwaJdsD86ReportResponse)
	};
};
