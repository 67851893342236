import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import Api from '@/services/api';

export type GetActivitiesResponse = {
	id: string;
	name: string;
	created_at: string;
};

export type GetActivitiesError = AxiosError<any>;

export interface GetActivitiesDTO {
	organization_id: string;
	company_id: string;
	workstation_id: string;
}

async function getFile(params: GetActivitiesDTO) {
	const { data } = await Api.get('/activity', { params });
	return data;
}

export const useGetActivities = ({ workstation_id, company_id, organization_id }: GetActivitiesDTO) => {
	const payload = { organization_id, company_id, workstation_id };

	return useQuery<GetActivitiesDTO, GetActivitiesError, GetActivitiesResponse[]>(
		['get-activities', payload],
		() => getFile(payload),
		{
			enabled: !!organization_id && !!company_id && !!workstation_id
		}
	);
};
