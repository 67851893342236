import React from 'react';
import { Avatar, Button, Col, Row } from 'antd';

import { MailOutlined } from '@ant-design/icons';
import { Title } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';

import { GenerateContainer, IconContainer } from './styles';
import { useTFAContext } from '../context';

export const GenerateEmail2FA = () => {
	const { handleNextStep, handleGenerate2FA } = useTFAContext();

	async function handleGenerateClick() {
		const generated = await handleGenerate2FA();
		if (generated) {
			handleNextStep();
		}
	}

	return (
		<GenerateContainer align="middle" justify="center">
			<Col span={18}>
				<IconContainer justify="center">
					<Col span={'auto'}>
						<Avatar size={64} icon={<MailOutlined />} />
					</Col>
				</IconContainer>
				<Row justify="center">
					<Col span={20}>
						<Row>
							<Title align="center" level={4}>
								You are about to generate your 2FA with your email.
							</Title>
						</Row>
						<Row>
							<Title align="center" style={{ fontWeight: '400' }} level={5}>
								The e-mail you're logged in will be used. Be sure to check your inbox and spam folder.
							</Title>
						</Row>
					</Col>
				</Row>
			</Col>
			<Col span={18}>
				<Row justify="center">
					<Button onClick={handleGenerateClick} type="primary">
						{I18n.get('Generate')}
					</Button>
				</Row>
			</Col>
		</GenerateContainer>
	);
};
