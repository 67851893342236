import { message } from 'antd';

import { useMutation } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';

import history from '@/store/history';
import { AuthService } from '@/services/authService';
import { Check2FARequest, ResendTokenRequest, Reset2FARequest, TFATypes } from '@/types/AuthUser';
import { Validate2FARequest } from '@/components/Settings/Account/TwoFactorAuthentication/types/request';

const authService = new AuthService();

function useGenerate2FA() {
	return useMutation((type: TFATypes) => authService.generate2FA(type), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message));
		},
		onSuccess: () => {
			message.success(I18n.get('Two-factor authentication generated successfully'));
		}
	});
}

function useSendToken() {
	return useMutation(({ data }: ResendTokenRequest) => authService.sendToken({ data }), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message));
		},
		onSuccess: () => {
			message.success(I18n.get('Token sent successfully'));
		}
	});
}

function useResendToken() {
	return useMutation(({ data }: ResendTokenRequest) => authService.resendToken({ data }), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message));
		},
		onSuccess: () => {
			message.success(I18n.get('Token resent successfully'));
		}
	});
}

function useReset2FA() {
	return useMutation(({ data }: Reset2FARequest) => authService.reset2FA({ data }), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message));
		},
		onSuccess: () => {
			message.success(I18n.get('Two-factor authentication reset successfully'));
		}
	});
}

function useCheck2FA() {
	return useMutation(({ data }: Check2FARequest) => authService.check({ data }), {
		onSuccess: () => {
			history.push('/');
		},
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message));
		}
	});
}

function useValidate2FA() {
	return useMutation(({ data }: Validate2FARequest) => authService.validate2FA({ data }), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message));
		},
		onSuccess: () => {
			message.success(I18n.get('Two-factor authentication validated successfully'));
		}
	});
}

export { useReset2FA, useCheck2FA, useSendToken, useResendToken, useValidate2FA, useGenerate2FA };
