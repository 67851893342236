import React from 'react';
import { Row, Col } from 'antd';
import { Title } from '@/components/Typography';

function SuccessIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="118"
			height="107"
			fill="none"
			className="bounce-in-up"
			viewBox="0 0 118 107"
		>
			<path
				fill="#2F54EB"
				d="M.408 51.443a1 1 0 01.144-1.398l12.94-10.653a1 1 0 011.423.156L44.17 76.929a1 1 0 001.584-.01L103.557.948A1 1 0 01105.011.8l12.131 10.612a1 1 0 01.134 1.362l-71.533 93.149a1 1 0 01-1.573.017L.408 51.443z"
			></path>
		</svg>
	);
}

function SuccessTitle() {
	return (
		<Title style={{ color: '#2F54EB' }} className="fade-in" level={4}>
			Document generated!
		</Title>
	);
}

function DocumentGenerated() {
	return (
		<Row>
			<Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
				<SuccessIcon />
			</Col>
			<Col span={24} style={{ display: 'flex', justifyContent: 'center', marginTop: '1.5rem' }}>
				<SuccessTitle />
			</Col>
		</Row>
	);
}

export default DocumentGenerated;
