export type Mass = number;
export type Score = number;
export type Vehicle = number;
export type Distance = number;
export type Duration = number;

export interface KimPushPullDTO {
	mass: Mass;
	score: Score;
	vehicle: Vehicle;
	distance_or_duration: number;
}

export enum Vehicles {
	BARROW = 'Barrow',
	CARRIAGE = 'Carriage',
	CONVEYOR = 'Conveyor',
	CRANE = 'Crane'
}

export enum RiskLoad {
	LOW = 'LOW',
	MODERATE = 'MODERATE',
	HIGH = 'HIGH',
	VERY_HIGH = 'HIGH'
}

export enum ScratchsTitle {
	LOW = 'Low',
	MODERATE = 'Moderate',
	HIGH = 'High',
	VERY_HIGH = 'Very high'
}

export enum Scratchs {
	LOW = 'LOW',
	MODERATE = 'MODERATE',
	HIGH = 'HIGH',
	VERY_HIGH = 'VERY_HIGH'
}

export enum ScratchItems {
	PHYSICAL_OVERLOAD = 'PHYSICAL_OVERLOAD',
	HEALTH_CONSEQUENCES = 'HEALTH_CONSEQUENCES',
	MEASURES = 'MEASURES'
}

export enum PhysicalOverload {
	LOW = 'Physical overload is unlikely.',
	MODERATE = 'Physical overload is possible for less resilient persons.',
	HIGH = 'Physical overload is possible for normally resilient persons.',
	VERY_HIGH = 'Physical overload is likely.'
}

export enum HealthConsequence {
	LOW = 'No health risk is to be expected.',
	MODERATE = 'Fatigue, low-grade adaptation problems which can be compensated for during leisure time.',
	HIGH = 'Disorders (pain), possibly including dysfunctions, reversible in most cases, without morphological manifestation.',
	VERY_HIGH = 'More pronounced disorders and/or dysfunctions, structural damage with pathological significance.'
}

export enum Measure {
	LOW = 'No measures need to be taken.',
	MODERATE = 'For less resilient persons, workplace redesign and other prevention measures may be helpful.',
	HIGH = 'Workplace redesign and other prevention measures should be considered.',
	VERY_HIGH = 'Workplace redesign measures are necessary. Other prevention measures should be considered.'
}

export enum MassKey {
	LESS_THAN_OR_EQUAL_50 = '≤ 50',
	LESS_THAN_OR_EQUAL_100 = '≤ 100',
	LESS_THAN_OR_EQUAL_200 = '≤ 200',
	LESS_THAN_OR_EQUAL_300 = '≤ 300',
	LESS_THAN_OR_EQUAL_400 = '≤ 400',
	LESS_THAN_OR_EQUAL_600 = '≤ 600',
	LESS_THAN_OR_EQUAL_800 = '≤ 800',
	LESS_THAN_OR_EQUAL_1000 = '≤ 1,000',
	LESS_THAN_OR_EQUAL_1300 = '≤ 1,300',
	GREATER_THAN_1300 = '> 1,300'
}

export enum DistanceKey {
	LESS_THAN_OR_EQUAL_40 = '≤ 40',
	LESS_THAN_OR_EQUAL_200 = '≤ 200',
	LESS_THAN_OR_EQUAL_400 = '≤ 400',
	LESS_THAN_OR_EQUAL_800 = '≤ 800',
	LESS_THAN_OR_EQUAL_1200 = '≤ 1,200',
	LESS_THAN_OR_EQUAL_1800 = '≤ 1,800',
	LESS_THAN_OR_EQUAL_2500 = '≤ 2,500',
	LESS_THAN_OR_EQUAL_4200 = '≤ 4,200',
	LESS_THAN_OR_EQUAL_6300 = '≤ 6,300',
	LESS_THAN_OR_EQUAL_8400 = '≤ 8,400',
	LESS_THAN_OR_EQUAL_11000 = '≤ 11,000',
	LESS_THAN_OR_EQUAL_15000 = '≤ 15,000',
	LESS_THAN_OR_EQUAL_20000 = '≤ 20,000'
}

export enum DurationKey {
	LESS_THAN_OR_EQUAL_1 = '≤ 1',
	LESS_THAN_OR_EQUAL_5 = '≤ 5',
	LESS_THAN_OR_EQUAL_10 = '≤ 10',
	LESS_THAN_OR_EQUAL_20 = '≤ 20',
	LESS_THAN_OR_EQUAL_30 = '≤ 30',
	LESS_THAN_OR_EQUAL_45 = '≤ 45',
	LESS_THAN_OR_EQUAL_60 = '≤ 60',
	LESS_THAN_OR_EQUAL_100 = '≤ 100',
	LESS_THAN_OR_EQUAL_150 = '≤ 150',
	LESS_THAN_OR_EQUAL_210 = '≤ 210',
	LESS_THAN_OR_EQUAL_270 = '≤ 270',
	LESS_THAN_OR_EQUAL_360 = '≤ 360',
	LESS_THAN_OR_EQUAL_480 = '≤ 480'
}

export interface ParametersChildren {
	distance: string | null;
	duration: string | null;
	risk_load: RiskLoad;
	risk_score: Score;
	vehicle: Vehicles;
	mass: string;
}
