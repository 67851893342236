import React from 'react';
import { NamePath } from 'antd/lib/form/interface';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { VulnerabilityDTO } from '@/components/ui/Inputs/types/response/vulnerability';
import { VulnerabilitySelect } from '@/components/ui/Inputs/VulnerabilitySelect';
import { CustomLabel } from '@/components/CustomLabel';
import { CustomFormItem } from './styles';
import { CustomIcon } from './CustomIcon';

const { useFormInstance, useWatch } = Form;

interface VulnerabilitiesProps {
	isLoading: boolean;
	isFetching: boolean;
	fieldName: NamePath;
	vulnerabilities: VulnerabilityDTO[];
}

export const Vulnerabilities: React.FC<VulnerabilitiesProps> = ({
	isFetching,
	isLoading,
	fieldName,
	vulnerabilities
}) => {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();

	const vulnerabilityId = useWatch(fieldName, form);

	async function handleVulnerabilitySelect(id: string) {
		setFieldValue(fieldName, id);
	}

	async function handleClearVulnerability() {
		setFieldValue(fieldName, undefined);
	}

	return (
		<Col xs={24} sm={12} lg={24}>
			<CustomFormItem
				name={fieldName}
				label={
					<CustomLabel
						icon={<CustomIcon title="What is the probability of an incident happening?" />}
						title="Vulnerability"
					/>
				}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: true,
						message: I18n.get('Select the vulnerability')
					}
				]}
			>
				<VulnerabilitySelect
					allowCreation={false}
					fieldName={fieldName}
					vulnerabilityId={vulnerabilityId}
					loading={isFetching}
					vulnerabilities={vulnerabilities}
					handleClearVulnerability={handleClearVulnerability}
					handleSelectVulnerability={handleVulnerabilitySelect}
					disabled={isLoading || isFetching}
				/>
			</CustomFormItem>
		</Col>
	);
};
