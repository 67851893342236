import React from 'react';
import { Row, Col, Button } from 'antd';
import { I18n } from '@aws-amplify/core';
import { DeleteOutlined } from '@ant-design/icons';

import { AdditionalItem } from '@/hooks';
import { Text } from '@/components/Typography';
import { riskColors, ewaRiskColors } from '../../ConditionCard';
import { RowStyled, ListStyled, ColorScale, InfoContainer, ButtonWrapper } from '../../styles';

interface InfoListProps {
	isEwa?: boolean;
	items: AdditionalItem;
	onDeleteValue: (id: string) => void;
}

export const InfoList: React.FC<InfoListProps> = ({ items, onDeleteValue, isEwa }) => {
	if (!items?.results || items?.results?.length === 0) {
		return <></>;
	}

	return (
		<Row>
			<Col span={22}>
				<ListStyled>
					<Row gutter={[0, 10]}>
						{items?.results?.map(
							({ id, value_1, result, value_2, value_3, description, additional_item_option_id_1 }) => (
								<Col span={24} key={id ?? items.id}>
									<Row gutter={[8, 0]}>
										<Col span={2}>
											<ColorScale
												$riskColor={
													isEwa ? ewaRiskColors[result ?? 0] : riskColors[result ?? 0]
												}
											/>
										</Col>
										<Col span={22}>
											<RowStyled>
												<InfoContainer xs={20} xxl={22}>
													<Row gutter={[14, 10]}>
														<Col span={24}>
															<Text>
																<Text strong>{items.title_1}</Text>:{' '}
																{I18n.get(description) ?? value_1}
															</Text>
														</Col>
														<Col xs={24} xxl={12}>
															<Text>
																<Text strong>{items.title_2}</Text>:{' '}
																{additional_item_option_id_1 ? value_1 : value_2}
															</Text>
														</Col>
														{additional_item_option_id_1 && value_2 && (
															<Col xs={24} xxl={12}>
																<Text>
																	<Text strong>{items.title_3}</Text>: {value_2}
																</Text>
															</Col>
														)}
														{value_3 && (
															<Col xs={24} xxl={12}>
																<Text>
																	<Text strong>{items.title_3}</Text>: {value_3}
																</Text>
															</Col>
														)}
													</Row>
												</InfoContainer>
												<ButtonWrapper xs={4} xxl={2}>
													<Button
														danger
														type="primary"
														id={id ?? items.id}
														icon={<DeleteOutlined />}
														style={{ width: '100%', height: '100%' }}
														onClick={(e) => onDeleteValue(e.currentTarget.id)}
													/>
												</ButtonWrapper>
											</RowStyled>
										</Col>
									</Row>
								</Col>
							)
						)}
					</Row>
				</ListStyled>
			</Col>
		</Row>
	);
};
