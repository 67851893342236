import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Form, Row } from 'antd';

import { useCreateReport, useUpdateReportBasicInformation } from '../hooks';
import { useApplicationContext } from '@/context/v1/Application/context';
import { LibertyMutualReportDTO } from '../hooks/types/response';
import { LoadingSkeleton } from '../LoadingSkeleton';
import { useLibertyMutualContext } from '../context';
import { CreateOrUpdate } from '../context/types';
import { CustomFormContainer } from './styles';
import { Footer } from './Footer';
import { Header } from './Header';

const { useFormInstance } = Form;

export const Report = () => {
	const { organization, company } = useApplicationContext();
	const { validateFields, getFieldValue } = useFormInstance();
	const { steps, gettingReport, libertyMutual, file_id, handleUpdateReport } = useLibertyMutualContext();

	const { mutateAsync: createReport, isLoading: creatingReport } = useCreateReport();
	const { mutateAsync: updateBasicInfo, isLoading: updatingBasicInfo } = useUpdateReportBasicInformation();

	const { search } = useLocation();
	const params = new URLSearchParams(search);
	const current_step = params.get('current_step') ? Number(params.get('current_step')) : undefined;

	const [currentStep, setCurrentStep] = useState<number>(current_step ?? libertyMutual.step);

	async function handleStepChange(step: number) {
		if (step < currentStep || currentStep === 1) {
			setCurrentStep(step);
			return;
		}
		await createOrUpdate(step);
	}

	async function createOrUpdate(step: number) {
		const values = await validateFields();
		if (values) {
			const body: CreateOrUpdate = {
				...values,
				file_id,
				company_id: company?.id,
				report_id: libertyMutual?.id,
				organization_id: organization?.id,
				system_of_units_id: getFieldValue(['system_of_units_id'])
			};

			const report = await createOrUpdateBasicInfo(body);
			if (report?.id) {
				handleUpdateReport(report);
				setCurrentStep(step);
			}
		}
	}

	function createOrUpdateBasicInfo(body: any): Promise<LibertyMutualReportDTO> {
		return libertyMutual?.id ? updateBasicInfo(body) : createReport(body);
	}

	const loading = creatingReport || updatingBasicInfo;

	if (loading) {
		return <LoadingSkeleton loading={loading} />;
	}

	return (
		<Row justify="center">
			<Col xs={24} xxl={20}>
				<Row justify="center">
					<Col xs={24}>
						<Row justify="start" gutter={[16, 24]}>
							<Col xs={24}>
								<Header />
							</Col>
							<CustomFormContainer xs={24}>
								{!gettingReport && steps[currentStep]?.component}
								<Footer currentStep={currentStep} onStepChange={handleStepChange} />
							</CustomFormContainer>
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
