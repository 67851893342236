import React from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Divider } from 'antd';
import { Title } from '@/components/Typography';

import { useBackCompressiveForceEstimationContext } from '../../../context';
import { Topic, Value } from './styles';

export const Information = () => {
	const {
		backCompressiveForceEstimationResult: { informations }
	} = useBackCompressiveForceEstimationContext();

	return (
		<Row style={{ minHeight: '100%' }} justify="start">
			<Col xs={24}>
				<Title level={5}>Informations</Title>
			</Col>
			<Col offset={1} xs={23}>
				<Row style={{ minHeight: '100%' }} justify="space-between">
					<Col xs={11}>
						<Row style={{ minHeight: '100%' }} justify="space-evenly">
							<Col xs={24}>
								<Topic>{I18n.get('Company')}:</Topic>
								<Value>{informations.organization_name}</Value>
							</Col>
							<Col xs={24}>
								<Topic>{I18n.get('Industrial site')}:</Topic>
								<Value>{informations.company_name}</Value>
							</Col>
							<Col xs={24}>
								<Topic>{I18n.get('Sector')}:</Topic>
								<Value>{informations.sector_name}</Value>
							</Col>{' '}
							<Col xs={24}>
								<Topic>{I18n.get('Line')}:</Topic>
								<Value>{informations.line_name}</Value>
							</Col>
							<Col xs={24}>
								<Topic>{I18n.get('Workstation')}:</Topic>
								<Value>{informations.workstation_name}</Value>
							</Col>
						</Row>
					</Col>
					<Col xs={2} xl={2} style={{ minHeight: '100%' }}>
						<Divider
							style={{ borderLeft: '1px solid #E6E8E8', margin: 0, height: '100%' }}
							type="vertical"
						/>
					</Col>
					<Col xs={11}>
						<Row style={{ minHeight: '100%' }} justify="space-evenly">
							<Col xs={24}>
								<Topic>{I18n.get('File')}:</Topic>
								<Value>{informations.original_name}</Value>
							</Col>
							<Col xs={24}>
								<Topic>{I18n.get('Report date')}:</Topic>
								<Value>{moment(informations.assessment_date).format('L')}</Value>
							</Col>
							<Col xs={24}>
								<Topic>{I18n.get('System of unit')}:</Topic>
								<Value>{I18n.get(informations.system_of_units_name)}</Value>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
