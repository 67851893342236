import styled from 'styled-components';

function scaleReba(value) {
	if (value >= 0 && value <= 1) {
		return 'rgba(113, 113, 113, 1)';
	}

	if (value >= 2 && value <= 3) {
		return 'rgba(75, 192, 86, 1)';
	}

	if (value >= 4 && value <= 7) {
		return 'rgba(200, 150, 86, 1)';
	}

	if (value >= 8 && value <= 10) {
		return 'rgba(255, 40, 72, 1)';
	}

	return 'rgba(153, 102, 255, 1)';
}

export const Title = styled.div`
	* {
		text-align: center;
		font-weight: bold;
	}
`;

export const Label = styled.span`
	margin-right: 8px;
	font-weight: bold;
`;

export const ScoresContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const BarContainer = styled.div`
	margin-bottom: 10px;
	min-width: 600px;

	@media (max-width: 1460px) {
		min-width: 450px;
	}
`;

export const Grade = styled.h2`
	color: ${({ children }) => scaleReba(children)};
`;
