import React from 'react';
import { Col, DatePicker } from 'antd';
import { FormItem } from '@/components/CustomReports';

interface CollectionDateProps {
	fontSize?: string;
	isRequired?: boolean;
	fieldName: string;
}

export const CollectionDate: React.FC<CollectionDateProps> = ({ fontSize, isRequired, fieldName }) => {
	return (
		<Col xs={24}>
			<FormItem
				namePath={[fieldName, 'collection_date']}
				title={'Collection date'}
				fontSize={fontSize}
				isRequired={isRequired}
			>
				<DatePicker allowClear size="large" format={'L'} style={{ width: '100%' }} />
			</FormItem>
		</Col>
	);
};
