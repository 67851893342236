import React from 'react';
import { Row, Col, Grid } from 'antd';

import { Header } from './Header';
import { useEwaContext } from '../context';
import { DIRECTION } from '@/components/ui/StepsNavigation/types';
import { StepsNavigation } from '@/components/ui/StepsNavigation';
import { CustomFormContainer } from './styles';

export const Report = () => {
	const { steps, currentStep, handleStepNagivationClick, ewa } = useEwaContext();

	const breakpoints = Grid.useBreakpoint();

	return (
		<Row justify="center">
			<Col xs={24}>
				<Header />
			</Col>
			<Col span={24} style={{ marginTop: '2rem' }}>
				<Row justify="center" gutter={[16, 0]}>
					<Col xs={24} xxl={4}>
						<StepsNavigation
							steps={steps}
							currentStep={currentStep}
							isComplete={ewa.is_completed}
							onStepNagivationClick={handleStepNagivationClick}
							direction={!breakpoints.xxl ? DIRECTION.HORIZONTAL : DIRECTION.VERTICAL}
						/>
					</Col>
					<CustomFormContainer xs={24} xxl={18} $isSmallScreen={`${breakpoints.lg}`}>
						{steps[currentStep]?.component}
					</CustomFormContainer>
				</Row>
			</Col>
		</Row>
	);
};
