import { useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import Api from '@/services/api';
import type { BaseContext, Response, RiskCategory } from '@/types';

export type GetRiskCategoriesDTO = BaseContext & {
	step_key_id: string;
};

async function getRiskCategories(params: GetRiskCategoriesDTO): Promise<RiskCategory[]> {
	const url = '/custom-report/risk-category';
	const { data } = await Api.get<RiskCategory[]>(url, {
		params
	});
	return data;
}

export const useGetRiskCategories = ({ organization_id, company_id, step_key_id }: GetRiskCategoriesDTO) => {
	const enabled = !!organization_id && !!company_id && !!step_key_id;
	const riskCategories = useQuery<RiskCategory[], AxiosError<Response>>(
		['risk_categories', [organization_id, company_id]],
		() => getRiskCategories({ organization_id, company_id, step_key_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...riskCategories,
		data: riskCategories.data ?? ([] as RiskCategory[])
	};
};
