import React, { useEffect } from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form, Row } from 'antd';
import type { Moment } from 'moment';
import moment from 'moment';

import {
	CreateREBADTO,
	useCreateREBA,
	UpdateReportDTO,
	CreateRebaResponse,
	useCalculateAngles,
	EwaJdsD86ReportResponse,
	useCreateCustomReportResult,
	useUpdateCustomReportResult
} from '@/hooks';
import * as I from './Inputs';
import { LoadingSkeleton } from './LoadingSkeleton';
import { useJdsD92Context } from '../../../context';
import { Paragraph, Text, Title } from '@/components/Typography';
import { useApplicationContext } from '@/context/v1/Application/context';
import { CustomButton } from '@/components/ui/Buttons/CustomButton/styles';
import { useHistory } from 'react-router-dom';
import Api from '@/services/api';

const { useFormInstance } = Form;

type InformationsFields = {
	sector_id: string;
	evaluator_id: string;
	workstation_id: string;
	collection_date: Moment;
	interviewee_name: string;
	interviewer_name: string;
	organization_id?: string;
	company_id?: string;
	file_id?: string;
};


type GetRebaBody = {
	organization_id?: string;
	company_id?: string;
	file_id?: string;
}

async function getReba({ organization_id, company_id, file_id }: GetRebaBody) {
	const url = `/ergonomic-tool/reba/${file_id}?organization_id=${organization_id}&company_id=${company_id}`;
	const { data } = await Api.get<any>(url);
	return data;
}

export const Informations: React.FC = () => {
	const form = useFormInstance();
	const history = useHistory();
	const { organization, company } = useApplicationContext();
	const {
		jdsD92,
		handleNextStep,
		scrollToError,
		currentStepId,
		customReport,
		file_id: fileId,
	} = useJdsD92Context();
	const { mutateAsync: calculateAngles } = useCalculateAngles();
	const { mutateAsync: createREBA, isLoading: creatingREBAReport } = useCreateREBA();
	const { mutateAsync: createReport, isLoading: creatingReport } = useCreateCustomReportResult();
	const { mutateAsync: updateReport, isLoading: updatingReport } = useUpdateCustomReportResult();

	const file_id = (form.getFieldValue('file') || {})?.id || fileId;

	useEffect(() => {
		const file = form.getFieldValue(['file']);
		form.setFieldValue(['informations', 'company_id'], company?.id);
		form.setFieldValue(['informations', 'organization_id'], organization?.id);
		form.setFieldValue(['informations', 'file_id'], file?.id);
		const body = {
			file_id,
			company_id: company?.id,
			organization_id: organization?.id
		}
		const initialVerification = async () => {
			const doesNotHaveReba = await fileDoesNotHaveREBA(body as GetRebaBody);
			if (doesNotHaveReba) {
				calculateAngles(body);
			}
		}

		initialVerification();
	}, []);

	async function handleValidation() {
		try {
			await form.validateFields();

			const informations: InformationsFields = await form.getFieldValue(['informations']);
			informations.organization_id = organization?.id;
			informations.company_id = company?.id;
			informations.file_id = file_id;
			const handledBody: UpdateReportDTO = {
				...informations,
				file_id,
				step_id: currentStepId,
				company_id: company?.id,
				organization_id: organization?.id,
				custom_report_id: customReport.id,
				custom_report_result_id: jdsD92.result_id,
			};

			if (jdsD92.result_id && fileId) {
				const updated = await updateReport(handledBody);
				if (updated?.id) {
					return await handleCreateReba(informations);
				}
				return;
			}

			await handleCreateReba(informations);
			const created = await createReport(handledBody);

			if (!fileId) {
				history.replace(`/custom-reports/jds-d92/report/${file_id}`);
				return;
			}
			if (resultHasHierarchyIds(created)) {
				return handleNextStep();
			}
		} catch (error) {
			scrollToError(error);
		}
	}

	async function handleCreateReba(informations: InformationsFields): Promise<void> {
		const doesNotHaveReba = await fileDoesNotHaveREBA(informations);
		if (doesNotHaveReba) {
			await createREBAForFile(informations);
		}
	}

	async function createREBAForFile(informations: InformationsFields): Promise<CreateRebaResponse | void> {
		const defaultREBA: CreateREBADTO = {
			file_id,
			force: 1,
			coupling: 1,
			repetition: 1,
			collection_date: moment(),
			comment: 'Created from custom report',
			company_id: jdsD92?.informations?.company_id ?? informations.company_id,
			organization_id: jdsD92?.informations?.organization_id ?? informations.organization_id,
			sector_id: jdsD92.informations.sector_id ?? informations?.sector_id,
			workstation_id: jdsD92.informations.workstation_id ?? informations?.workstation_id
		};

		try {
			return await createREBA(defaultREBA);
		} catch (error) {
			console.log(error)
		}
	}

	async function fileDoesNotHaveREBA(informations: GetRebaBody): Promise<boolean> {
		const doesNotHaveReba = !jdsD92?.tools?.reba?.id;
		if (!doesNotHaveReba) {
			return doesNotHaveReba
		}

		const existReba = await getReba(informations);

		console.log({ existReba });

		return !existReba?.id;
	}

	function resultHasHierarchyIds(report: EwaJdsD86ReportResponse): boolean {
		return (
			!!report.id &&
			!!report.informations.sector_id &&
			!!report.informations.company_id &&
			!!report.informations.workstation_id &&
			!!report.informations.organization_id
		);
	}

	const create_date = moment(jdsD92.informations.created_date).format('L');

	if (creatingReport || updatingReport || creatingREBAReport) {
		return <LoadingSkeleton />;
	}

	return (
		<>
			<Row justify="center" gutter={[5, 20]}>
				<Col xs={21}>
					<Row align="middle">
						<Col xs={12}>
							<Title level={4}>General informations</Title>
						</Col>
						<Col xs={12} style={{ textAlign: 'end' }}>
							<Text>Date of creation</Text>: {create_date}
						</Col>
						<Col xs={24} style={{ marginBottom: '20px' }}>
							<Paragraph level={5}>Enter the data related to the selected video below</Paragraph>
						</Col>
					</Row>

					<Row justify="center">
						<I.VideoContainer />
					</Row>

					<Row justify="center">
						<Col xs={24} lg={18} xxl={12} style={{ marginTop: '1rem', marginBottom: '4rem' }}>
							<Row gutter={[0, { xs: 50, sm: 40, md: 40, lg: 30, xl: 26 }]}>
								<I.ReportName
									fieldName="informations"
									disabled={!!fileId}
								/>
							</Row>
						</Col>
					</Row>

					<Row justify="center">
						<Col xs={24} lg={18} xxl={12} style={{ marginTop: '1rem' }}>
							<Row gutter={[0, { xs: 50, sm: 40, md: 40, lg: 30, xl: 26 }]}>
								<I.Organizations />
								<I.Companies fieldName="informations" />
								<I.Sectors fieldName="informations" />
								<I.Lines fieldName="informations" />
								<I.Workstations fieldName="informations" />
							</Row>
						</Col>
					</Row>
					<Row justify="center">
						<Col xs={24} lg={18} xxl={12} style={{ marginTop: '4rem' }}>
							<Row gutter={[0, { xs: 40, sm: 40, md: 40, lg: 30, xl: 26 }]}>
								<I.Evaluator fieldName="informations" />
								<I.InterviewerName fieldName="informations" isRequired={false} />
								<I.IntervieweeName fieldName="informations" isRequired={false} />
							</Row>
						</Col>
					</Row>
					<Row justify="center">
						<Col xs={24} lg={18} xxl={12} style={{ marginTop: '4rem' }}>
							<Row gutter={[0, { xs: 40, sm: 40, md: 40, lg: 30, xl: 26 }]}>
								<I.CollectionDate fieldName="informations" />
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row>
				<Col xs={24} style={{ marginTop: '2rem' }}>
					<Row justify="center">
						<Col>
							<CustomButton htmlType="submit" size="large" type="primary" onClick={handleValidation}>
								{I18n.get('Next')}
							</CustomButton>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};
