import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { useApplicationContext } from '@/context/v1/Application/context';
import { CompanySelect } from '@/components/ui/Inputs/CompanySelect';
import { useListCompanies } from '@/hooks/v1/useListCompanies';

const { useFormInstance, useWatch } = Form;

export const Companies: React.FC = () => {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();
	const { organization, company } = useApplicationContext();

	const companyId = useWatch('company_id', form);
	const {
		data: companies,
		isLoading,
		isFetching,
		isError
	} = useListCompanies({ organization_id: organization?.id, company_id: company?.id });

	function handleCompanySelect(id: string) {
		setFieldValue(['company_id'], id);
		setFieldValue(['sector_id'], undefined);
		setFieldValue(['line_id'], undefined);
		setFieldValue(['workstation_id'], undefined);
	}

	function handleClearCompany() {
		setFieldValue(['company_id'], undefined);
		setFieldValue(['sector_id'], undefined);
		setFieldValue(['line_id'], undefined);
		setFieldValue(['workstation_id'], undefined);
	}

	return (
		<Col xs={24}>
			<Form.Item name={['company_id']} label={I18n.get('Company')} labelCol={{ span: 24 }}>
				<CompanySelect
					companies={companies}
					loading={isFetching}
					companyId={companyId}
					handleClearCompany={handleClearCompany}
					handleSelectCompany={handleCompanySelect}
					disabled={isLoading || isFetching || isError}
				/>
			</Form.Item>
		</Col>
	);
};
