import styled from 'styled-components';

export const TabsContainer = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
`;

export const tabStyle = { height: '100%', marginTop: 20 };

const tabBtnStyle = {
	height: '50%',
	position: 'absolute',
	width: 50,
	border: 0,
	top: 110,
	background: 'none'
};

export const nextBtnStyle = { ...tabBtnStyle, right: 10 };

export const prevBtnStyle = { ...tabBtnStyle, left: 10 };

export const iconStyle = { fontSize: '20px' };
