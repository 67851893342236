import React from 'react';
import { Form, Select } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useOrganization } from '@/hooks';

const { useFormInstance, useWatch } = Form;

export const Organization: React.FC = () => {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();

	const {
		organizations: { data: organizations }
	} = useOrganization();

	const organizationId = useWatch('searching_organization_id', form);

	function handleOrganizationSelect(id: string) {
		setFieldValue(['searching_organization_id'], id);
		setFieldValue(['company_id_filter'], undefined);
		setFieldValue(['sector_id'], undefined);
		setFieldValue(['line_id'], undefined);
		setFieldValue(['workstation_id'], undefined);
	}

	function handleClearOrganization() {
		setFieldValue(['searching_organization_id'], undefined);
		setFieldValue(['company_id_filter'], undefined);
		setFieldValue(['sector_id'], undefined);
		setFieldValue(['line_id'], undefined);
		setFieldValue(['workstation_id'], undefined);
	}
	return (
		<Form.Item labelCol={{ span: 24 }} name={['searching_organization_id']} label={I18n.get('Company')}>
			<Select
				showSearch
				allowClear
				value={organizationId ?? null}
				placeholder={I18n.get('Company')}
				onClear={handleClearOrganization}
				onChange={handleOrganizationSelect}
				disabled={organizations.length === 0}
				filterOption={(input, option) => (option?.label ?? '').includes(input)}
				options={organizations.map(({ id, name }) => ({ label: name, value: id }))}
			/>
		</Form.Item>
	);
};
