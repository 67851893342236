import Api from '@/services/api';

import {
	ExposuresRequest,
	RiskDescriptionsRequest,
	RiskCategoriesRequest,
	RiskDamagesRequest,
	SeveritiesRequest,
	VulnerabilitiesRequest
} from '@/components/ui/Inputs/types/request/customReport';
import { RiskDescriptionDTO } from '@/components/ui/Inputs/types/response/riskDescription';
import { VulnerabilityDTO } from '@/components/ui/Inputs/types/response/vulnerability';
import { RiskCategoryDTO } from '@/components/ui/Inputs/types/response/riskCategory';
import { ExposureDTO } from '@/components/ui/Inputs/types/response/exposure';
import { SeverityDTO } from '@/components/ui/Inputs/types/response/severity';
import { TasksRequest } from '@/components/ui/Inputs/types/request/task';
import { TaskDTO } from '@/components/ui/Inputs/types/response/task';
import { RiskDamageDTO } from '@/components/ui/Inputs/types/response/riskDamage';

export class SeraStepsService {
	async getRiskDescriptions(payload: RiskDescriptionsRequest): Promise<RiskDescriptionDTO[]> {
		const url = `/custom-report/risk-description/${payload.risk_category_id}`;
		const { data } = await Api.get<RiskDescriptionDTO[]>(url, {
			params: payload
		});
		return data;
	}
	async getRiskCategories(payload: RiskCategoriesRequest): Promise<RiskCategoryDTO[]> {
		const url = `/custom-report/risk-category/${payload.step_key_id}`;
		const { data } = await Api.get<RiskCategoryDTO[]>(url, {
			params: payload
		});
		return data;
	}
	async getRiskDamages(payload: RiskDamagesRequest): Promise<RiskDamageDTO[]> {
		const url = `/custom-report/risk-damage/${payload.risk_description_id}`;
		const { data } = await Api.get<RiskDamageDTO[]>(url, {
			params: payload
		});
		return data;
	}

	async getExposures(payload: ExposuresRequest): Promise<ExposureDTO[]> {
		const url = `/custom-report/exposure/${payload.step_key_id}`;
		const { data } = await Api.get<ExposureDTO[]>(url, {
			params: payload
		});
		return data;
	}

	async getSeverities(payload: SeveritiesRequest): Promise<SeverityDTO[]> {
		const url = `/custom-report/severity/step-key/${payload.step_key_id}`;
		const { data } = await Api.get<SeverityDTO[]>(url, {
			params: payload
		});
		return data;
	}

	async getVulnerabilities(payload: VulnerabilitiesRequest): Promise<VulnerabilityDTO[]> {
		const url = `/custom-report/vulnerability/${payload.step_key_id}`;
		const { data } = await Api.get<VulnerabilityDTO[]>(url, {
			params: payload
		});
		return data;
	}

	async getTasks(payload: TasksRequest): Promise<TaskDTO[]> {
		const url = '/task';
		const { data } = await Api.get<TaskDTO[]>(url, {
			params: payload
		});
		return data;
	}
}
