import React from 'react';
import { riskLevels } from '@/utils/riskRange';
import { Text } from '@/components/Typography';
import * as S from './styles';

interface RiskResultProps {
	score: number;
	result: number;
}

export default function RiskResult({ result, score }: RiskResultProps) {
	return (
		<S.RiskCard>
			<S.RiskCardHeader result={result}>
				<Text>{riskLevels[result] || 'Not Assigned'}</Text>
			</S.RiskCardHeader>
			<S.RiskCardBody>
				<Text>RPN: {score}</Text>
			</S.RiskCardBody>
		</S.RiskCard>
	);
}
