import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Divider, Row } from 'antd';
import moment from 'moment';

import { useSeraReviewContext } from '../../../context';
import { RPNScore, Title } from '../styles';
import { Actions } from './Actions';

interface TaskCollapseHeaderProps {
	checked: boolean;
	report_id: string;
}

export const TaskCollapseHeader: React.FC<TaskCollapseHeaderProps> = ({ report_id, checked }) => {
	const { mapRPNToPriority, seraSummaryReview } = useSeraReviewContext();

	const { sera_reports } = seraSummaryReview;
	const [currentReport] = sera_reports.filter((report) => report.id === report_id);
	const taskRPN = currentReport.rpn;
	const rpnPriority = mapRPNToPriority(taskRPN);
	const taskName = currentReport.task_name;
	const fileName = currentReport.file_name;
	const fileId = currentReport.file_id;

	return (
		<Row justify="center">
			<Col xs={24}>
				<Row align="middle" justify="space-evenly">
					<Col xs={5}>
						<Title>{I18n.get('TLID')}:</Title> {taskName}
					</Col>
					<Col>
						<Divider
							type="vertical"
							style={{ height: '2rem', borderColor: '#00000033', margin: '0rem 0.5rem' }}
						/>
					</Col>
					<Col xs={5} style={{ margin: 0 }}>
						<Row align="middle" justify="center" gutter={[8, 8]}>
							<Col>
								<Title style={{ margin: 0 }}>{I18n.get('Task RPN')}:</Title>
							</Col>
							<Col>
								<RPNScore style={{ margin: 0 }} priority={rpnPriority?.color}>
									{taskRPN}
								</RPNScore>
							</Col>
						</Row>
					</Col>
					<Col>
						<Divider
							type="vertical"
							style={{ height: '2rem', borderColor: '#00000033', margin: '0rem 0.5rem' }}
						/>
					</Col>
					<Col xs={6}>
						<Row justify="center">
							<Title style={{ marginRight: '0.5rem' }}>{I18n.get('Review date')}:</Title>{' '}
							{moment().format('L')}
						</Row>
					</Col>
					<Col>
						<Divider
							type="vertical"
							style={{ height: '2rem', borderColor: '#00000033', margin: '0rem 0.5rem' }}
						/>
					</Col>
					<Col xs={4}>
						<Row justify="center">
							<Actions fileId={fileId} fileName={fileName} reportId={report_id} checked={checked} />
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
