import React from 'react';
import { I18n } from '@aws-amplify/core';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Form, Image, InputNumber, Row } from 'antd';

import { useLibertyMutualSubStepsContext } from '../../context';
import { CustomLabel, CustomTooltip } from './CustomLabel';
import { TaskName } from '../../hooks/types/response';
import { SystemOfUnits } from '../../context/types';

const LIFT_END_HAND_DISTANCE = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/lift-end-hand-distance.svg';
const LOWER_END_HAND_DISTANCE = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/lower-end-hand-distance.svg';

export const EndHandDistance = () => {
	const { libertyMutual, decimalSeparator } = useLibertyMutualSubStepsContext();

	const systemOfUnitsToSymbolMapper: SystemOfUnits = {
		metric: 'cm',
		imperial: 'ft'
	};

	const labelTitle = `${I18n.get('End hand distance')} (${
		systemOfUnitsToSymbolMapper[libertyMutual?.system_of_units?.name]
	})`;

	const tooltipSVGMapper = {
		[TaskName.lift]: LIFT_END_HAND_DISTANCE,
		[TaskName.lower]: LOWER_END_HAND_DISTANCE,
		[TaskName.push]: '',
		[TaskName.pull]: '',
		[TaskName.carry]: ''
	};

	const TooltipContent = (
		<Row align="bottom">
			<Col xs={7}>
				<Image src={tooltipSVGMapper[libertyMutual.task.name]} alt={labelTitle} preview={false} width="80px" />
			</Col>
		</Row>
	);

	const label = (
		<CustomLabel
			icon={
				<CustomTooltip top={-110} left={12} content={TooltipContent} placement="topLeft">
					<InfoCircleOutlined style={{ fontSize: '0.65rem', marginLeft: '0.1rem' }} />
				</CustomTooltip>
			}
			title={labelTitle}
		/>
	);

	return (
		<Row>
			<Col xs={24}>
				<Form.Item
					name={['liberty_mutual_inputs', 'end_hand_distance']}
					label={label}
					labelCol={{ span: 24 }}
					rules={[
						{
							required: true,
							message: I18n.get('Insert an end distance')
						}
					]}
				>
					<InputNumber min={0.1} placeholder="0" decimalSeparator={decimalSeparator} />
				</Form.Item>
			</Col>
		</Row>
	);
};
