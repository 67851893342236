import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form, InputNumber } from 'antd';

export const Greater: React.FC = () => {
	return (
		<Col span={12}>
			<Form.Item label={I18n.get('Minimum')} name={['min_overall_score']} labelCol={{ span: 24 }}>
				<InputNumber controls={false} placeholder={I18n.get('Minimum')} />
			</Form.Item>
		</Col>
	);
};
