import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useSelectFilesContext } from '../../context';
import * as S from '../styles';

export function NextButton() {
	const { handleValidation } = useSelectFilesContext();
	return (
		<Row justify="center" align="middle">
			<Col>
				<S.CustomButton htmlType="submit" size="large" type="primary" onClick={() => handleValidation()}>
					{I18n.get('Next')}
				</S.CustomButton>
			</Col>
		</Row>
	);
}
