import styled from 'styled-components';
import { Col } from 'antd';

export const CustomList = styled(Col)`
	.ant-list-item {
		background-color: #fff;
		border-radius: 0.25rem;
		border: 1px solid #00000033;
		padding: 0.5rem 0.5rem;

		:last-child {
			background-color: #fff;
			border-radius: 0.25rem;
			border: 1px solid #00000033;
			padding: 0.5rem 0.5rem;
		}
	}
`;
