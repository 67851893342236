import React from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { TaskDTO } from '@/components/ui/Inputs/types/response/task';
import { TaskSelect } from '@/components/ui/Inputs/TaskSelect';
import { NamePath } from 'antd/lib/form/interface';

const { useFormInstance, useWatch } = Form;

interface TasksProps {
	fileId: string;
	cycleId: string;
	fieldName: NamePath;
	tasks: UseQueryResult<TaskDTO[], unknown>;
}

export const Tasks: React.FC<TasksProps> = ({ fieldName, tasks, cycleId, fileId }) => {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();

	const taskId = useWatch(fieldName, form);
	const existingTaskId = tasks.data?.find((task) => task.file && task.file.find(({ id }) => id === fileId))?.id;
	setFieldValue(fieldName, existingTaskId ?? taskId);

	async function handleTaskSelect(id: string) {
		setFieldValue(fieldName, id);
	}

	async function handleClearTask() {
		setFieldValue(fieldName, undefined);
	}

	return (
		<Col xs={24} sm={12}>
			<Form.Item
				name={fieldName}
				label={I18n.get('Task list ID (TLID)')}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: true,
						message: I18n.get('Select the task')
					}
				]}
			>
				<TaskSelect
					tasks={tasks.data}
					loading={tasks.isFetching}
					disabled={tasks.isLoading || tasks.isFetching || !!existingTaskId}
					taskId={taskId}
					cycleId={cycleId}
					fieldName={fieldName}
					handleClearTask={handleClearTask}
					handleSelectTask={handleTaskSelect}
				/>
			</Form.Item>
		</Col>
	);
};
