import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form, Input } from 'antd';

export const Less: React.FC = () => {
	return (
		<Col span={12}>
			<Form.Item label={I18n.get('Maximum')} name={['max_rpn']} labelCol={{ span: 24 }}>
				<Input placeholder={I18n.get('Maximum')} />
			</Form.Item>
		</Col>
	);
};
