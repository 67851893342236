import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import Api from '@/services/api';

export type FileStatus =
	| 'CORRUPTED_FILE'
	| 'EXTRACTED_DATA'
	| 'NOT_PROCESSED'
	| 'PROCESSING'
	| 'PROCESSED'
	| 'IN_QUEUE';

export type File = {
	id: string;
	url: string;
	size: number;
	tool: string;
	user_id: string;
	duration: number;
	rulaScore: number;
	sector_id: string;
	blurFace: boolean;
	isActive: boolean;
	updatedAt: string;
	created_at?: string;
	createdAt?: string;
	company_id: string;
	workstation: string;
	original_name: string;
	generated_name: string;
	file_processed: string;
	organization_id: string;
	status: FileStatus | boolean;
	workstation_id: string | null;
	workstations: {
		id: string;
		name: string;
		line: {
			id: string;
			name: string;
			sector: {
				id: string;
				name: string;
			};
		};
	};
	sector: {
		id: string;
		name: string;
	};
	operation?: {
		id: string;
		name: string;
	};
};

type FileDTO = Pick<File, 'organization_id' | 'company_id'> & {
	custom_report_id: string;
	offset: number;
	limit: number;
	filter?: FilterDTO;
};

export type FileResponse = {
	pages: number;
	count: number;
	rows: File[];
};

export type FilterDTO = {
	created_at?: Date[];
	created_at_end?: string | Date;
	created_at_start?: string | Date;
	offset?: number;
	line_id?: string;
	sector_id?: string;
	company_id?: string;
	file_ids?: React.Key[];
	original_name?: string;
	organization_id?: string;
};

const getCustomReportResultFiles = async ({
	organization_id,
	company_id,
	custom_report_id,
	filter,
	limit,
	offset
}: FileDTO): Promise<FileResponse> => {
	const params = { limit, offset, organization_id, company_id, custom_report_id };
	if (filter) {
		_.assign(params, filter);
	}

	const url = `/custom-report/result/files`;
	const { data } = await Api.get<FileResponse>(url, { params });
	return data;
};

export const useGetCustomReportResultFiles = ({
	organization_id,
	company_id,
	offset,
	limit,
	filter,
	custom_report_id
}: FileDTO) => {
	const enabled = !!organization_id && !!company_id;
	return useQuery(
		['custom_report_results_files', { organization_id, company_id, offset, limit, custom_report_id, ...filter }],
		() => getCustomReportResultFiles({ organization_id, company_id, filter, offset, limit, custom_report_id }),
		{ enabled }
	);
};
