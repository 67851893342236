import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';
import { Col, Form, Row } from 'antd';
import type { Moment } from 'moment';
import moment from 'moment';

import { useCreateCustomReportReview } from '@/hooks/useCreateCustomReportReview';
import { CustomButton } from '@/components/ui/Buttons/CustomButton/styles';
import { useApplicationContext } from '@/context/v1/Application/context';
import { Paragraph, Text, Title } from '@/components/Typography';
import { useEwaJdsD86Context } from '../../../context';
import { LoadingSkeleton } from './LoadingSkeleton';
import Api from '@/services/api';
import * as I from './Inputs';
import {
	useUpdateCustomReportResult,
	useCreateCustomReportResult,
	EwaJdsD86ReportResponse,
	useCalculateAngles,
	CreateRebaResponse,
	UpdateReportDTO,
	useCreateREBA,
	CreateREBADTO
} from '@/hooks';

const { useFormInstance } = Form;

type InformationsFields = {
	sector_id: string;
	evaluator_id: string;
	workstation_id: string;
	collection_date: Moment;
	interviewee_name: string;
	interviewer_name: string;
	organization_id?: string;
	company_id?: string;
	file_id?: string;
};

type GetRebaBody = {
	organization_id?: string;
	company_id?: string;
	file_id?: string;
};

async function getReba({ organization_id, company_id, file_id }: GetRebaBody) {
	const url = `/ergonomic-tool/reba/${file_id}?organization_id=${organization_id}&company_id=${company_id}`;
	const { data } = await Api.get<any>(url);
	return data;
}

export const Informations: React.FC = () => {
	const form = useFormInstance();
	const history = useHistory();
	const { organization, company } = useApplicationContext();
	const {
		ewaJdsD86,
		handleNextStep,
		scrollToError,
		currentStepId,
		customReport,
		file_id: fileId,
		isEwa,
		original_custom_report_result_id
	} = useEwaJdsD86Context();

	const { mutateAsync: calculateAngles } = useCalculateAngles();
	const { mutateAsync: createREBA, isLoading: creatingREBAReport } = useCreateREBA();
	const { mutateAsync: createReport, isLoading: creatingReport } = useCreateCustomReportResult();
	const { mutateAsync: createReview, isLoading: creatingReview } = useCreateCustomReportReview();
	const { mutateAsync: updateReport, isLoading: updatingReport } = useUpdateCustomReportResult();

	const file_id = (form.getFieldValue('file') || {})?.id || fileId;

	useEffect(() => {
		const initialVerification = async () => {
			const file = form.getFieldValue(['file']);
			if (file) {
				form.setFieldValue(['informations', 'company_id'], company?.id);
				form.setFieldValue(['informations', 'organization_id'], organization?.id);
				form.setFieldValue(['informations', 'file_id'], file?.id);
			}

			const body = {
				file_id: fileId || file?.id,
				company_id: company?.id,
				organization_id: organization?.id
			};

			const doesNotHaveReba = await fileDoesNotHaveREBA(body as GetRebaBody);
			if (doesNotHaveReba) {
				calculateAngles(body);
			}
		};

		initialVerification();
	}, [fileId]);

	async function handleValidation() {
		try {
			await form.validateFields();

			const informations: InformationsFields = await form.getFieldValue(['informations']);
			informations.organization_id = organization?.id;
			informations.company_id = company?.id;
			informations.file_id = file_id;
			const handledBody: UpdateReportDTO = {
				...informations,
				file_id,
				step_id: currentStepId,
				company_id: company?.id,
				organization_id: organization?.id,
				custom_report_id: customReport.id,
				custom_report_result_id: ewaJdsD86.result_id,
				original_custom_report_result_id
			};

			if (ewaJdsD86.result_id && fileId) {
				const updated = await updateReport(handledBody);
				if (updated?.id) {
					return await handleCreateReba(informations);
				}
				return;
			}

			const created = await createReviewOrResult(handledBody);

			if (resultHasHierarchyIds(created as EwaJdsD86ReportResponse)) {
				return handleNextStep();
			}
		} catch (error) {
			scrollToError(error);
		}
	}

	async function createReviewOrResult(handledBody: UpdateReportDTO) {
		let created;
		if (!original_custom_report_result_id) {
			created = await createReport(handledBody);
		} else {
			created = await createReview(handledBody);
		}
		const informations: InformationsFields = await form.getFieldValue(['informations']);
		informations.organization_id = organization?.id;
		informations.company_id = company?.id;
		informations.file_id = file_id;

		await handleCreateReba(informations);

		if (!isEwa) {
			history.replace(`/custom-reports/jds-d86/report/${created.id}`);
			return;
		}

		return created;
	}

	async function handleCreateReba(informations: InformationsFields): Promise<void> {
		const doesNotHaveRebaReport = await fileDoesNotHaveREBA(informations);

		if (doesNotHaveRebaReport) {
			await createREBAForFile(informations);
		}
	}

	async function createREBAForFile(informations: InformationsFields): Promise<CreateRebaResponse> {
		const defaultREBA: CreateREBADTO = {
			file_id,
			force: 1,
			coupling: 1,
			repetition: 1,
			collection_date: moment(),
			comment: 'Created from custom report',
			company_id: ewaJdsD86?.informations?.company_id ?? informations.company_id,
			organization_id: ewaJdsD86?.informations?.organization_id ?? informations.organization_id,
			sector_id: ewaJdsD86.informations.sector_id ?? informations?.sector_id,
			workstation_id: ewaJdsD86.informations.workstation_id ?? informations?.workstation_id
		};

		return await createREBA(defaultREBA);
	}

	async function fileDoesNotHaveREBA(informations: GetRebaBody): Promise<boolean> {
		const doesNotHaveReba = !!ewaJdsD86.tools && !ewaJdsD86.tools?.reba?.id;

		if (isEwa) {
			return doesNotHaveReba;
		}

		const existReba = await getReba(informations);

		return !existReba?.id;
	}

	function resultHasHierarchyIds(report: EwaJdsD86ReportResponse): boolean {
		return (
			!!report.id &&
			!!report.informations.sector_id &&
			!!report.informations.company_id &&
			!!report.informations.workstation_id &&
			!!report.informations.organization_id
		);
	}

	const create_date = moment(ewaJdsD86.informations.created_date).format('L');

	if (creatingReport || updatingReport || creatingREBAReport || creatingReview) {
		return <LoadingSkeleton />;
	}

	return (
		<>
			<Row justify="center" gutter={[5, 20]}>
				<Col xs={21}>
					<Row align="middle">
						<Col xs={12}>
							<Title level={4}>General informations</Title>
						</Col>
						<Col xs={12} style={{ textAlign: 'end' }}>
							<Text>Date of creation</Text>: {create_date}
						</Col>
						<Col xs={24} style={{ marginBottom: '20px' }}>
							<Paragraph level={5}>Enter the data related to the selected video below</Paragraph>
						</Col>
					</Row>

					<Row justify="center">
						<I.VideoContainer />
					</Row>
					{!isEwa && (
						<Row justify="center">
							<Col xs={24} lg={18} xxl={12} style={{ marginTop: '1rem', marginBottom: '4rem' }}>
								<Row gutter={[0, { xs: 50, sm: 40, md: 40, lg: 30, xl: 26 }]}>
									<I.ReportName
										fieldName="informations"
										disabled={!!original_custom_report_result_id}
									/>
								</Row>
							</Col>
						</Row>
					)}

					<Row justify="center">
						<Col xs={24} lg={18} xxl={12} style={{ marginTop: '1rem' }}>
							<Row gutter={[0, { xs: 50, sm: 40, md: 40, lg: 30, xl: 26 }]}>
								<I.Organizations />
								<I.Companies fieldName="informations" />
								<I.Sectors fieldName="informations" />
								<I.Lines fieldName="informations" />
								<I.Workstations fieldName="informations" />
							</Row>
						</Col>
					</Row>
					<Row justify="center">
						<Col xs={24} lg={18} xxl={12} style={{ marginTop: '4rem' }}>
							<Row gutter={[0, { xs: 40, sm: 40, md: 40, lg: 30, xl: 26 }]}>
								<I.Evaluator fieldName="informations" />
								<I.InterviewerName fieldName="informations" isRequired={false} />
								<I.IntervieweeName fieldName="informations" isRequired={false} />
							</Row>
						</Col>
					</Row>
					<Row justify="center">
						<Col xs={24} lg={18} xxl={12} style={{ marginTop: '4rem' }}>
							<Row gutter={[0, { xs: 40, sm: 40, md: 40, lg: 30, xl: 26 }]}>
								<I.CollectionDate fieldName="informations" />
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row>
				<Col xs={24} style={{ marginTop: '2rem' }}>
					<Row justify="center">
						<Col>
							<CustomButton htmlType="submit" size="large" type="primary" onClick={handleValidation}>
								{I18n.get('Next')}
							</CustomButton>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};
