import React, { ReactElement, useState } from 'react';
import { Button, Divider, Form, Input, Select, Skeleton } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { NamePath } from 'antd/lib/form/interface';
import { I18n } from '@aws-amplify/core';

import { CompanyDTO } from '@/components/ui/Inputs/types/response/company';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useCreateCompany } from '@/hooks/v1/useCompanyMutation';
import { CreateCompanyRequest } from '../types/request/company';

const { useFormInstance } = Form;

type CompanySelectProps = {
	size?: SizeType;
	loading: boolean;
	disabled: boolean;
	companyId?: string;
	fieldName?: NamePath;
	allowCreation?: boolean;
	handleClearCompany?: () => void;
	companies: CompanyDTO[] | undefined;
	handleSelectCompany?: (id: string) => void;
};

type OptionType = { label: string; value: string } | undefined;

export function CompanySelect({
	loading,
	disabled,
	companies,
	companyId,
	size = 'middle',
	handleClearCompany,
	handleSelectCompany,
	allowCreation = false,
	fieldName = 'company_id'
}: CompanySelectProps) {
	const [companyName, setCompanyName] = useState<string>('');

	const { company, organization } = useApplicationContext();
	const { validateFields } = useFormInstance();

	const { mutateAsync: createCompany, isLoading } = useCreateCompany();

	async function onCreateCompany() {
		const body: CreateCompanyRequest = {
			organization_id: organization.id,
			company_id: company.id,
			name: companyName
		};

		const { data: createdCompany } = await createCompany({ body });
		const companyId = createdCompany?.id;
		if (companyId && fieldName) {
			handleChange(companyId);
		}
		setCompanyName('');
	}

	async function handleChange(id: string) {
		if (handleSelectCompany) {
			handleSelectCompany(id);
			await validateFields(['company_id']);
		}
	}

	function handleClear() {
		if (handleClearCompany) {
			handleClearCompany();
		}
	}

	function handleCompanyNameChange(name: string) {
		setCompanyName(name);
	}

	function filterOption(option: OptionType, input: string): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function showDropdownrender(menu: ReactElement): JSX.Element {
		if (!allowCreation) {
			return <div>{menu}</div>;
		}
		return (
			<div>
				{menu}
				<Divider style={{ margin: '4px 0' }} />
				<div
					style={{
						display: 'flex',
						flexWrap: 'nowrap',
						padding: 8
					}}
				>
					<Input
						style={{ flex: 'auto', borderRadius: '7px' }}
						value={companyName}
						onChange={(e) => handleCompanyNameChange(e.target.value)}
					/>
					<Button
						type="link"
						loading={isLoading}
						onClick={onCreateCompany}
						disabled={!companyName || companyName.length < 3}
					>
						{I18n.get('Add item')}
					</Button>
				</div>
			</div>
		);
	}

	if (loading) {
		return <Skeleton.Input block active size="small" />;
	}

	return (
		<Select
			showSearch
			allowClear
			size={size}
			onClear={handleClear}
			onChange={handleChange}
			value={companyId ?? null}
			loading={loading || isLoading}
			disabled={disabled || isLoading}
			placeholder={I18n.get('Industrial site')}
			dropdownRender={(menu) => showDropdownrender(menu)}
			filterOption={(input, option) => filterOption(option, input)}
			options={companies?.map(({ id, name }) => ({ label: name, value: id }))}
		/>
	);
}
