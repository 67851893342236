import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { WorkstationSelect } from '@/components/ui/Inputs/WorkstationSelect';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useWorkstations } from '@/hooks/v1';

const { useFormInstance, useWatch } = Form;

export const Workstations: React.FC = () => {
	const form = useFormInstance();
	const { company, organization } = useApplicationContext();

	const fieldName = 'workstation_id';

	const lineId = useWatch('line_id', form);
	const workstationId = useWatch(fieldName, form);

	const {
		data: workstations,
		isLoading,
		isFetching,
		isError
	} = useWorkstations({
		organization_id: organization?.id,
		company_id: company?.id,
		line_id: lineId
	});

	function handleWorkstationSelect(id: string) {
		form.setFieldValue([fieldName], id);
	}

	function handleClearWorkstations() {
		form.setFieldValue([fieldName], undefined);
	}

	return (
		<Col xs={24}>
			<Form.Item name={[fieldName]} label={I18n.get('Workstation')} labelCol={{ span: 24 }}>
				<WorkstationSelect
					lineId={lineId}
					loading={isFetching}
					allowCreation={false}
					fieldName="workstation_id"
					workstations={workstations}
					workstationId={workstationId}
					handleSelectWorkstation={handleWorkstationSelect}
					handleClearWorkstations={handleClearWorkstations}
					disabled={isLoading || isFetching || isError || !lineId}
				/>
			</Form.Item>
		</Col>
	);
};
