import { I18n } from '@aws-amplify/core';
import { notification } from 'antd';
import React, { ReactNode, useContext, useState } from 'react';

import { ValidationError } from '../hooks/types';
import { Context } from './types';

type ManageOrganizationProviderProps = {
	children: ReactNode;
};

const ManageOrganizationContext = React.createContext<Context>({} as Context);

export function ManageOrganizationProvider({ children }: ManageOrganizationProviderProps) {
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [organizationId, setOrganizationId] = useState<string>('');

	function treatFieldValidation(error: ValidationError) {
		if (error?.errorFields?.length > 0) {
			error.errorFields.forEach((error) => {
				notification.error({
					message: I18n.get(error.errors[0])
				});
			});
		} else {
			notification.error({
				message: I18n.get('Oops... Something went wrong')
			});
		}
	}

	function handleIsEditing(editing: boolean): void {
		setIsEditing(editing);
	}

	function handleOrganizationId(id: string): void {
		setOrganizationId(id);
	}

	const contextValue: Context = {
		isEditing,
		organizationId,
		handleIsEditing,
		handleOrganizationId,
		treatFieldValidation
	};
	return <ManageOrganizationContext.Provider value={contextValue}>{children}</ManageOrganizationContext.Provider>;
}

export function useManageOrganizationContext() {
	const context = useContext(ManageOrganizationContext);
	return context;
}
