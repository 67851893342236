import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
	width: 220px;
	padding-inline: 30px;
	border-left: ${(props) => (props.side === 'right' ? '1px solid #c4c4c4' : 'none')};
`;

const Image = styled.img`
	width: 100%;
`;

export function Logo({ src, side }) {
	return (
		<Container side={side}>
			<Image src={src} />
		</Container>
	);
}

Logo.propTypes = {
	src: PropTypes.string.isRequired,
	side: PropTypes.oneOf(['left', 'right']).isRequired
};
