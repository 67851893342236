import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import { Form, Modal } from 'antd';

import { DeleteBaseRequest, HierarchyEnum, LineResponse, UpdateBaseRequest } from '../hooks/types';
import { LineSelect } from '@/components/ui/Inputs/LineSelect';
import { useDelete, useGetLines, useUpdate } from '../hooks';
import { useManageOrganizationContext } from '../context';
import { CompanyManager } from './CompanyManager';

const { useFormInstance, useWatch } = Form;

export const ManageLine = () => {
	const form = useFormInstance();
	const { isEditing, organizationId, handleIsEditing, treatFieldValidation } = useManageOrganizationContext();

	const lineId = useWatch(['line_id'], form);
	const sectorId = useWatch(['sector_id'], form);
	const companyId = useWatch(['company_id'], form);

	const {
		data: lines,
		isLoading: gettingLines,
		isFetching: fetchingLines,
		isError: errorGettingLines
	} = useGetLines({ company_id: companyId, organization_id: organizationId, sector_id: sectorId });
	const { mutateAsync: deleteData } = useDelete<DeleteBaseRequest, LineResponse>();
	const { mutateAsync: updateData } = useUpdate<UpdateBaseRequest, LineResponse>();

	const currentName = lines?.find(({ id }) => id === lineId)?.name;

	async function handleUpdateName() {
		try {
			const values = await form.getFieldValue(['editing']);
			if (values) {
				const body: UpdateBaseRequest = {
					id: values?.currentId,
					company_id: companyId,
					name: values.line_name,
					organization_id: organizationId,
					hierarchy_field: HierarchyEnum.line
				};
				const updated = await updateData(body);
				if (updated.id) {
					handleIsEditing(false);
					form.setFieldValue('line_id', updated.id);
					form.setFieldValue(['editing', 'line_name'], undefined);
				}
			}
		} catch (error: any) {
			treatFieldValidation(error);
		}
	}

	async function handleDeleteLine() {
		Modal.confirm({
			title: I18n.get('Warning!'),
			content: I18n.get('Heads up! Want to delete this line? All workstations related to it will be lost.'),
			icon: <ExclamationCircleOutlined />,
			okText: I18n.get('Confirm'),
			cancelText: I18n.get('Cancel'),
			okType: 'danger',
			onOk: async () => {
				const body: DeleteBaseRequest = {
					id: lineId,
					company_id: companyId,
					organization_id: organizationId,
					hierarchy_field: HierarchyEnum.line
				};
				const updated = await deleteData(body);
				if (updated.id) {
					handleClearLine();
					handleIsEditing(false);
					form.setFieldValue(['editing', 'company_name'], undefined);
				}
			}
		});
	}

	function handleLineSelect(id: string) {
		form.setFieldValue(['line_id'], id);
		form.setFieldValue(['workstation_id'], undefined);
	}

	function handleClearLine() {
		form.setFieldValue(['line_id'], undefined);
		form.setFieldValue(['workstation_id'], undefined);
	}

	return (
		<CompanyManager
			key={sectorId}
			currentId={lineId}
			parentId={sectorId}
			fieldLabel={'a line'}
			fieldName={['line_id']}
			currentName={currentName}
			onDelete={handleDeleteLine}
			onUpdateName={handleUpdateName}
			editingNameField={['editing', 'line_name']}
		>
			<LineSelect
				size="middle"
				lines={lines}
				lineId={lineId}
				sectorId={sectorId}
				allowCreation={true}
				fieldName={['line_id']}
				handleClearLines={handleClearLine}
				handleSelectLine={handleLineSelect}
				loading={isEditing || fetchingLines}
				disabled={errorGettingLines || gettingLines || fetchingLines || isEditing || !sectorId}
			/>
		</CompanyManager>
	);
};
