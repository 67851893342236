import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { useApplicationContext } from '@/context/v1/Application/context';
import { CycleSelect } from '@/components/ui/Inputs/CycleSelect';
import { useCycles } from '@/hooks/v1';

const { useFormInstance, useWatch } = Form;

interface CyclesProps {
	onValueChange: (changed: boolean) => void;
}

export const Cycles: React.FC<CyclesProps> = ({ onValueChange }) => {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();
	const { organization } = useApplicationContext();

	const companyId = useWatch('company_id', form);
	const cycleId = useWatch('cycle_id', form);

	const {
		data: cycles,
		isLoading,
		isFetching,
		isError
	} = useCycles({
		organization_id: organization?.id,
		company_id: companyId
	});

	function handleCycleSelect(id: string) {
		onValueChange(true);
		setFieldValue(['cycle_id'], id);
	}

	function handleClearCycles() {
		setFieldValue(['cycle_id'], undefined);
	}

	return (
		<Col xs={24} sm={12}>
			<Form.Item
				name={['cycle_id']}
				label={I18n.get('Cycle')}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: true,
						message: I18n.get('Select the cycle')
					}
				]}
			>
				<CycleSelect
					cycles={cycles}
					cycleId={cycleId}
					loading={isFetching}
					companyId={companyId}
					fieldName="cycle_id"
					handleSelectCycle={handleCycleSelect}
					handleClearCycles={handleClearCycles}
					disabled={isLoading || isFetching || isError || !companyId}
				/>
			</Form.Item>
		</Col>
	);
};
