import React from 'react';
import { Col, Form, Radio, Space } from 'antd';
import { I18n } from '@aws-amplify/core';

import { SeraSummaryStatuses } from '../hooks/types/response';

export const Status: React.FC = () => {
	const radioOptions = [
		{
			key: SeraSummaryStatuses.UNCOMPLETED,
			label: 'Show only uncompleted reports'
		},
		{
			key: SeraSummaryStatuses.COMPLETED,
			label: 'Show only completed reports'
		},
		{
			key: SeraSummaryStatuses.REVIEWED,
			label: 'Show only reviewed reports'
		}
	];

	return (
		<Col xs={24} sm={24} md={12} lg={16}>
			<Form.Item name={'status'} labelCol={{ span: 24 }}>
				<Radio.Group>
					<Space direction="horizontal">
						{radioOptions.map(({ key, label }) => (
							<Radio key={key} value={key} style={{ fontSize: '0.79rem' }}>
								{I18n.get(label)}
							</Radio>
						))}
					</Space>
				</Radio.Group>
			</Form.Item>
		</Col>
	);
};
