import React, { useState } from 'react';
import { Title } from '@/components/Typography';
import { Col, Collapse, Row } from 'antd';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import { SeraResultReviewHistory } from '../hooks/types';
import { ReviewFields } from './ReviewFields';
import { ActionPlans } from './ActionPlans';
import { ReviewTable } from './ReviewTable';
import { FormContainer } from '../styles';
import { Description } from './styles';

const { Panel } = Collapse;

type FormListItem = SeraResultReviewHistory & {
	key: number;
};

interface ReviewsHistoryProps {
	reviewsHistory: SeraResultReviewHistory[];
}

export const ReviewsHistory: React.FC<ReviewsHistoryProps> = ({ reviewsHistory }) => {
	const initialFormList = reviewsHistory.map((history, index) => {
		return {
			...history,
			key: index,
			active: false
		};
	});
	const [formList, setFormList] = useState<FormListItem[]>(initialFormList);

	function handleCollapseChange(key: string | string[]) {
		const [, selectedKey] = key;
		const numberKey = Number(selectedKey);
		const formListMapped = mapFormList();
		const index = formListMapped.findIndex((item) => item.key === numberKey);
		if (index < 0) {
			setFormList(formListMapped);
			return;
		}
		formListMapped[index].active = true;
		setFormList(formListMapped);
	}

	function mapFormList() {
		const formListCopy = [...formList];
		const formListMapped = formListCopy.map((item) => ({
			...item,
			active: false
		}));
		return formListMapped;
	}

	const tableData = reviewsHistory?.map((review) => ({
		id: review.id,
		review: review.review,
		created_at: review.created_at
	}));

	const panels = formList.map((history) => {
		return (
			<Panel
				key={`${history.key}`}
				header={
					<Col xs={24}>
						<Row gutter={[0, 0]} justify="space-between" align="middle">
							<Col span={20}>
								<Title style={{ margin: '0 0 0 1rem' }} level={5}>
									{I18n.get('Review')} {I18n.get(history.review)}
								</Title>
							</Col>
							<Col span={4}>
								<Row align="bottom">
									<Title style={{ margin: '0 0.25rem 0 0', fontSize: '0.8rem' }} level={5}>
										{I18n.get('Reviewed at')}:
									</Title>
									<Description>{moment(history.created_at).format('L')}</Description>
								</Row>
							</Col>
						</Row>
					</Col>
				}
			>
				<Row gutter={[0, 8]} justify="center" key={history.id}>
					<Col xs={24}>
						<Row>
							<ReviewFields tasks={history.tasks} />
						</Row>
						{history.action_plans?.length > 0 && (
							<Row justify="center">
								<Col offset={1} xs={24}>
									<Row justify="center">
										<ActionPlans actionPlans={history.action_plans} />
									</Row>
								</Col>
							</Row>
						)}
					</Col>
				</Row>
			</Panel>
		);
	});

	return (
		<Col span={24}>
			<Row>
				<Title level={5}>Reviews history</Title>
			</Row>
			<ReviewTable data={tableData} />
			<Row justify="center">
				<Col xs={24}>
					<FormContainer onChange={handleCollapseChange} bordered={false}>
						{panels}
					</FormContainer>
				</Col>
			</Row>
		</Col>
	);
};
