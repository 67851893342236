import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Divider, Row } from 'antd';

import { ParametersChildren } from '../types';
import { scratchMap, diagnosticsMap } from '../contants';
import { DetailDescription, Details, DetailTitle } from './styles';
interface ISecondRowProps {
	parameters: ParametersChildren;
}

export const SecondRow: React.FC<ISecondRowProps> = ({ parameters: { risk_score, risk_load } }) => (
	<Row justify="space-between">
		<Col span={6} style={{ paddingLeft: '1rem' }}>
			<Details>
				<Row>
					<Col span={24}>
						<DetailTitle>{I18n.get('Possible health consequences')}:</DetailTitle>
					</Col>
				</Row>
				<Row>
					<DetailDescription>{I18n.get(diagnosticsMap[risk_load].HEALTH_CONSEQUENCES)}</DetailDescription>
				</Row>
			</Details>
		</Col>
		<Divider style={{ margin: 0, height: 'auto' }} type={'vertical'} />
		<Col span={4}>
			<Details>
				<Row>
					<Col span={24}>
						<DetailTitle>{I18n.get('Risk Range')}:</DetailTitle>
					</Col>
				</Row>
				<Row>
					<DetailDescription style={{ fontSize: '1.2rem', fontWeight: 600 }}>
						{I18n.get(scratchMap[risk_load])}
					</DetailDescription>
				</Row>
			</Details>
		</Col>
		<Divider style={{ margin: 0, height: 'auto' }} type={'vertical'} />
		<Col span={4}>
			<Details>
				<Row>
					<Col span={24}>
						<DetailTitle>{I18n.get('Score')}:</DetailTitle>
					</Col>
				</Row>
				<Row>
					<DetailDescription style={{ fontSize: '1.2rem', fontWeight: 600 }}>{risk_score}</DetailDescription>
				</Row>
			</Details>
		</Col>
		<Divider style={{ margin: 0, height: 'auto' }} type={'vertical'} />
		<Col span={6}>
			<Details>
				<Row>
					<Col span={24}>
						<DetailTitle>{I18n.get('Measurements')}</DetailTitle>
					</Col>
				</Row>
				<Row>
					<DetailDescription>{I18n.get(diagnosticsMap[risk_load].MEASURES)}</DetailDescription>
				</Row>
			</Details>
		</Col>
	</Row>
);
