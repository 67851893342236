import React from 'react';
import moment from 'moment';
import { Row, Col, Divider } from 'antd';
import { I18n } from '@aws-amplify/core';

import { FileResultsProps } from './types';
import { StyledRow, Topic, Value } from './styles';
import { useApplicationContext } from '@/context/v1/Application/context';

const FORMAT_SECONDS_FILE: string = 'HH:mm:ss';

export const Information: React.FC<FileResultsProps> = ({ file, reportData }) => {
	const { organization, company } = useApplicationContext();

	function formatSeconds(seconds: number = 0): string {
		return moment.utc(seconds * 1000).format(FORMAT_SECONDS_FILE);
	}

	return (
		<Col span={24} style={{ margin: '0.5rem 0 1rem 60px' }}>
			<Row>
				<Col span={11}>
					{reportData?.jds_d86_report?.name && (
						<StyledRow>
							<Topic>{I18n.get('Report name')}:</Topic>
							<Value>{reportData?.jds_d86_report?.name}</Value>
						</StyledRow>
					)}
					<StyledRow>
						<Topic>{I18n.get('Company')}:</Topic>
						<Value>{organization?.name}</Value>
					</StyledRow>
					<StyledRow>
						<Topic>{I18n.get('Industrial site')}:</Topic>
						<Value>{company?.name}</Value>
					</StyledRow>
					<StyledRow>
						<Topic>{I18n.get('Line')}:</Topic>
						<Value>{file.workstations?.line.name}</Value>
					</StyledRow>
					<StyledRow>
						<Topic>{I18n.get('Sector')}:</Topic>
						<Value>{file.workstations?.line.sector.name}</Value>
					</StyledRow>
					<StyledRow>
						<Topic>{I18n.get('Workstation')}:</Topic>
						<Value>{file.workstations?.name}</Value>
					</StyledRow>
					{reportData?.jds_d86_report && (
						<StyledRow>
							<Topic>{I18n.get('Activity')}:</Topic>
							<Value>{reportData?.jds_d86_report.activity}</Value>
						</StyledRow>
					)}
				</Col>
				<Col span={1}>
					<Divider type="vertical" style={{ height: '100%', borderColor: '#D9D9D9' }} />
				</Col>
				<Col span={11}>
					<StyledRow>
						<Topic>{I18n.get('File')}:</Topic>
						<Value>{file.original_name}</Value>
					</StyledRow>
					{reportData?.jds_d86_report && (
						<StyledRow>
							<Topic>{I18n.get('Collection date')}:</Topic>
							<Value>{moment(reportData?.jds_d86_report.collection_date).format('DD/MM/YYYY')}</Value>
						</StyledRow>
					)}
					<StyledRow>
						<Topic>{I18n.get('Date')}:</Topic>
						<Value>{moment(file?.updatedAt).format('DD/MM/YYYY')}</Value>
					</StyledRow>
					{!reportData?.jds_d86_report && (
						<StyledRow>
							<Topic>{I18n.get('Duration')}:</Topic>
							<Value>{formatSeconds(file?.duration)}</Value>
						</StyledRow>
					)}
					{reportData?.jds_d86_report && (
						<>
							<StyledRow>
								<Topic>{I18n.get('Evaluator')}:</Topic>
								<Value>{reportData.jds_d86_report.evaluator_name}</Value>
							</StyledRow>
							<StyledRow>
								<Topic>{I18n.get('Interviewer')}:</Topic>
								<Value>{reportData.jds_d86_report.interviewer_name ?? 'N/A'}</Value>
							</StyledRow>
							<StyledRow>
								<Topic>{I18n.get('Interviewee name')}:</Topic>
								<Value>{reportData.jds_d86_report.interviewee_name ?? 'N/A'}</Value>
							</StyledRow>
							<StyledRow>
								<Topic>{I18n.get('Role')}:</Topic>
								<Value>{reportData.jds_d86_report.role_name ?? 'N/A'}</Value>
							</StyledRow>
						</>
					)}
					{reportData?.jds_d86_report?.version && (
						<>
							<StyledRow style={{ marginTop: '1rem' }}>
								<Topic>{I18n.get('Version')}:</Topic>
								<Value>{reportData?.jds_d86_report?.version}</Value>
							</StyledRow>
						</>
					)}
					{reportData?.duration_frequency_report && (
						<StyledRow>
							<Topic>{I18n.get('Risk range')}:</Topic>
							<Value>{reportData.duration_frequency_report.range_risk.name}</Value>
						</StyledRow>
					)}
					{file?.riskRangeName && (
						<StyledRow>
							<Topic>{I18n.get('Risk range')}:</Topic>
							<Value>{file.riskRangeName}</Value>
						</StyledRow>
					)}
				</Col>
			</Row>
		</Col>
	);
};
