import React from 'react';
import { Col, Divider, Row } from 'antd';

import { Title } from '@/components/Typography';

import { Container } from './styles';
import { Header } from './Header';
import { Niosh } from '../types';
import { Body } from './Body';

interface NioshProps {
	niosh: Niosh;
}

export const NioshResult: React.FC<NioshProps> = ({ niosh }) => {
	const conclusion = {
		risk: niosh.risk,
		mass_m: niosh.mass_m,
		lifting_index: niosh.lifting_index,
		recommended_weight_limit: niosh.recommended_weight_limit
	};

	const recommended = {
		risk: niosh.risk,
		lifting_index: niosh.lifting_index,
		reference_weight: niosh.reference_weight,
		recommended_weight_limit: niosh.recommended_weight_limit
	};

	return (
		<Row gutter={[0, 24]}>
			<Col offset={1} span={23}>
				<Row>
					<Title style={{ margin: 0 }} level={5}>
						Cargo Handling (NIOSH)
					</Title>
				</Row>
			</Col>
			<Col offset={1} span={23}>
				<Row>
					<Container>
						<Header conclusion={conclusion} />
						<Divider style={{ margin: 0 }} />
						<Body recommended={recommended} />
					</Container>
				</Row>
			</Col>
		</Row>
	);
};
