import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react';

import { CustomReportFilesDTO } from '@/components/ui/Inputs/types/response/customReport';
import { FileForCustomReport } from '@/components/ui/Inputs/types/response/file';

import {
	Context,
	Methods,
	State,
	RPNPriority,
	SCORE_COLLOR_MAPPER,
	PRIORITY_SCALE_MAPPER,
	SeraCommonInformations,
	FormListItem,
	FileTask,
	FilesTasks
} from './types';
import { SeraSummaryDTO } from '../hooks/types/response';
import { TaskFile } from '../hooks/types/request';

export type SeraStepsRouteParams = {
	sera_summary_id: string;
};

interface SeraStepsProviderProps {
	children: ReactNode;
}

export const SeraStepsContext = createContext<Context>({} as Context);

export const SeraStepsProvider = ({ children }: SeraStepsProviderProps) => {
	const [page, setPage] = useState(0);
	const [limit, setLimit] = useState(10);
	const [fileName, setFileName] = useState('');
	const [seraSummaryId, setSeraSummaryId] = useState('');
	const [showSeraResult, setShowSeraResult] = useState(false);
	const [filesTasks, setFilesTasks] = useState<FileTask[]>([]);
	const [selectedFiles, setSelectedFiles] = useState<FileForCustomReport[]>([]);
	const [selectedFilesId, setSelectedFilesId] = useState<React.Key[] | string[]>([]);
	const [seraSummary, setSeraSummary] = useState<SeraSummaryDTO>({} as SeraSummaryDTO);
	const [seraFiles, setSeraFiles] = useState<CustomReportFilesDTO>({} as CustomReportFilesDTO);
	const [seraCommonInformations, setSeraCommonInformations] = useState<SeraCommonInformations>(
		{} as SeraCommonInformations
	);

	const [seraFormList, setSeraFormList] = useState<FormListItem[]>([
		{
			deleted: false,
			key: 1,
			active: true
		}
	]);

	function handleFileNameChange(name: string) {
		setFileName(name);
	}

	function handleSizeChange(size: number) {
		setLimit(size);
	}

	function handlePageChange(page: number) {
		setPage(page);
	}

	function handleShowSeraResultChange(show: boolean) {
		setShowSeraResult(show);
	}

	function handleSeraSummaryIdChange(id: string) {
		setSeraSummaryId(id);
	}

	function handleSeraFilesChange(data: CustomReportFilesDTO) {
		setSeraFiles(data);
	}

	function handleSeraFormListChange(formList: FormListItem[]) {
		setSeraFormList(formList);
	}

	function handleSeraSummaryChange(data: SeraSummaryDTO) {
		setSeraSummary(data);
	}

	function handleSeraCommonInformationsChange(data: SeraCommonInformations) {
		setSeraCommonInformations(data);
	}

	function handleFilesTasksChange(files: FileTask[]) {
		setFilesTasks(files);
	}

	function mapRPNToPriority(rpn: number | undefined): RPNPriority {
		if (!rpn) {
			return {
				priority: PRIORITY_SCALE_MAPPER.none.priority,
				color: SCORE_COLLOR_MAPPER[PRIORITY_SCALE_MAPPER.none.scale]
			};
		}
		if (rpn <= 8) {
			return {
				priority: PRIORITY_SCALE_MAPPER.low.priority,
				color: SCORE_COLLOR_MAPPER[PRIORITY_SCALE_MAPPER.low.scale]
			};
		}
		if (rpn <= 35) {
			return {
				priority: PRIORITY_SCALE_MAPPER.medium.priority,
				color: SCORE_COLLOR_MAPPER[PRIORITY_SCALE_MAPPER.medium.scale]
			};
		}
		if (rpn <= 124) {
			return {
				priority: PRIORITY_SCALE_MAPPER.medium_high.priority,
				color: SCORE_COLLOR_MAPPER[PRIORITY_SCALE_MAPPER.medium_high.scale]
			};
		}
		if (rpn <= 179) {
			return {
				priority: PRIORITY_SCALE_MAPPER.high.priority,
				color: SCORE_COLLOR_MAPPER[PRIORITY_SCALE_MAPPER.high.scale]
			};
		}
		return {
			priority: PRIORITY_SCALE_MAPPER.very_high.priority,
			color: SCORE_COLLOR_MAPPER[PRIORITY_SCALE_MAPPER.very_high.scale]
		};
	}

	function handleSelectedFilesChange(data: FileForCustomReport[]) {
		setSelectedFiles(data);
		const filesIds = data.map((file) => file.id);
		setSelectedFilesId(filesIds);
	}

	function filterSelectedFiles(selectedRows: React.Key[], customReport: CustomReportFilesDTO): FileForCustomReport[] {
		const fileIds = [...selectedRows];
		const files: FileForCustomReport[] = [];
		fileIds.forEach((id) => {
			const selectedFile = customReport?.files.rows?.find((file) => file.id === id);
			if (selectedFile) {
				files.push(selectedFile);
			}
		});

		const selectedFilesCopy = [...selectedFiles];
		const selectedFilesToKeep = selectedFilesCopy
			.filter((file) => fileIds.includes(file.id))
			.filter((file) => !files.includes(file));
		files.push(...selectedFilesToKeep);
		return files.sort(compareByOriginalName);
	}

	function compareByOriginalName(a: FileForCustomReport, b: FileForCustomReport) {
		if (a.original_name < b.original_name) {
			return -1;
		}
		if (a.original_name > b.original_name) {
			return 1;
		}
		return 0;
	}

	function mapFilesTasks(files_list: string[], values: FilesTasks): TaskFile[] {
		const filesTasks: TaskFile[] = [];
		files_list.forEach((fileId) => {
			if (values[fileId]?.task_id) {
				const fileTask: TaskFile = {
					file_id: fileId,
					task_id: values[fileId]?.task_id
				};
				filesTasks.push(fileTask);
			}
		});
		return filesTasks;
	}

	const state: State = {
		page,
		limit,
		fileName,
		seraFiles,
		filesTasks,
		seraSummary,
		seraFormList,
		seraSummaryId,
		selectedFiles,
		showSeraResult,
		selectedFilesId,
		seraCommonInformations
	};

	const methods: Methods = {
		mapFilesTasks,
		handleSizeChange,
		handlePageChange,
		mapRPNToPriority,
		filterSelectedFiles,
		handleFileNameChange,
		handleSeraFilesChange,
		handleFilesTasksChange,
		handleSeraSummaryChange,
		handleSeraFormListChange,
		handleSelectedFilesChange,
		handleSeraSummaryIdChange,
		handleShowSeraResultChange,
		handleSeraCommonInformationsChange
	};

	const context: Context = useMemo(() => {
		return {
			...state,
			...methods
		};
	}, [state]);

	return <SeraStepsContext.Provider value={context}>{children}</SeraStepsContext.Provider>;
};

export function useSeraStepsContext() {
	const context = useContext(SeraStepsContext);
	return context;
}
