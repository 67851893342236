import React from 'react';
import { Row, Col, Divider } from 'antd';

import { I18n } from '@aws-amplify/core';

import { Mosaic } from './Mosaic';
import { FilterCard } from './FilterCard';
import { Spinner } from '../Layout/Spinner';
import { SummaryCard } from './SummaryCard';
import { CargoHandling } from './CargoHandling';
import { ActionPlanCard } from './ActionPlanCard';
import { Repetitiveness } from './Repetitiveness';
import { WorkOrganization } from './WorkOrganization';
import { WorkstationsCard } from './WorkstationsCard';
import { MovementByScoreCard } from './MovementByScoreCard';
import { DashboardCard, DashboardContainer, Title } from './styles';

import { DashboardProvider } from './context';
import { useApplicationContext } from '@/context/v1/Application/context';

export const Dashboard: React.FC = () => {
	const { organization, company } = useApplicationContext();

	if (!organization || !company) {
		return <Spinner />;
	}

	return (
		<DashboardProvider>
			<Row justify="center">
				<Col span="auto">
					<DashboardContainer>
						<Row gutter={[16, 16]}>
							<Col span={24}>
								<Title>{I18n.get('Unit overview')}</Title>
							</Col>
							<Col span={24}>
								<Mosaic />
							</Col>
							<Col span={24}>
								<Title>{I18n.get('Ergonomics Management')}</Title>
							</Col>
							<Col span={16}>
								<SummaryCard />
							</Col>
							<Col span={8}>
								<FilterCard />
							</Col>
							<Col span={24}>
								<Row gutter={[16, 0]}>
									<Col span={8}>
										<Repetitiveness />
									</Col>
									<Col span={8}>
										<CargoHandling />
									</Col>
									<Col span={8}>
										<WorkOrganization />
									</Col>
								</Row>
							</Col>
							<Col span={24}>
								<MovementByScoreCard />
							</Col>
							<Col span={24}>
								<DashboardCard>
									<Row justify="space-between">
										<Col span={11}>
											<WorkstationsCard />
										</Col>
										<Divider type="vertical" style={{ height: 'auto' }} />
										<Col span={11}>
											<ActionPlanCard />
										</Col>
									</Row>
								</DashboardCard>
							</Col>
						</Row>
					</DashboardContainer>
				</Col>
			</Row>
		</DashboardProvider>
	);
};
