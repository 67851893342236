import React, { useRef } from 'react';
import { Col, Row } from 'antd';

import { HorizontalButtons } from './components/HorizontalButtons';
import { Container, CustomSteps } from './styles';
import { StepIcon } from './components/StepIcon';
import { DIRECTION, Direction } from './types';
import { Step } from '@/hooks';

interface StepsNavigationProps {
	steps: Step[];
	currentStep: number;
	isComplete: boolean;
	direction?: Direction;
	onStepNagivationClick: (step: number) => Promise<void>;
}

export const StepsNavigation: React.FC<StepsNavigationProps> = ({
	steps,
	isComplete,
	currentStep,
	onStepNagivationClick,
	direction = DIRECTION.VERTICAL
}) => {
	const elementRef = useRef<HTMLDivElement | null>(null);

	const mappedSteps = steps.map((item, index) => {
		item.icon = (
			<StepIcon
				key={item.name}
				position={index}
				isComplete={isComplete}
				currentStep={currentStep}
				name={item.description}
				onStepNagivationClick={onStepNagivationClick}
			/>
		);
		return item;
	});

	if (direction === DIRECTION.VERTICAL || steps.length <= 12) {
		return (
			<Row justify="center">
				<Container xs={24}>
					<Row justify="center">
						<Col xs={20}>
							<CustomSteps size="small" items={mappedSteps} direction={direction} current={currentStep} />
						</Col>
					</Row>
				</Container>
			</Row>
		);
	}

	return (
		<Row justify="center">
			<Container xs={24}>
				<HorizontalButtons elementRef={elementRef}>
					<Col xs={20}>
						<div ref={elementRef} style={{ overflowX: 'hidden' }}>
							<CustomSteps size="small" items={mappedSteps} direction={direction} current={currentStep} />
						</div>
					</Col>
				</HorizontalButtons>
			</Container>
		</Row>
	);
};
