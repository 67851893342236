import React from 'react';
import { Col, Form, Input } from 'antd';
import { I18n } from '@aws-amplify/core';

export function Less() {
	return (
		<Col span={12}>
			<Form.Item name={['max_rpn']} label={I18n.get('Maximum')} labelCol={{ span: 24 }}>
				<Input placeholder={I18n.get('Maximum')} />
			</Form.Item>
		</Col>
	);
}
