import React, { createContext, ReactNode, useContext, useState } from 'react';

import { Context } from './index.types';
import { FileDTO } from '@/components/ui/Inputs/types/response/file';

type RebaProviderProps = {
	children: ReactNode;
};

const RebaContext = createContext<Context>({} as Context);

export function RebaProvider({ children }: RebaProviderProps) {
	const [file, setFile] = useState<FileDTO>({} as FileDTO);

	function handleFile(file: FileDTO | undefined) {
		if (file) {
			setFile(file);
		}
	}

	const contextValue: Context = {
		file,
		handleFile
	};

	return <RebaContext.Provider value={contextValue}>{children}</RebaContext.Provider>;
}

export function useRebaContext() {
	const context = useContext(RebaContext);
	return context;
}
