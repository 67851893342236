import React from 'react';
import { Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { WorkstationSelect } from '@/components/ui/Inputs/WorkstationSelect';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useWorkstations } from '@/hooks/v1';
import { CustomFormItem } from './styles';

const { useFormInstance, useWatch } = Form;

export const Workstations = () => {
	const form = useFormInstance();
	const { organization, company } = useApplicationContext();

	const fieldName = 'workstation_id';

	const lineId = useWatch('line_id', form);
	const workstationId = useWatch(fieldName, form);

	const {
		data: workstations,
		isLoading,
		isFetching,
		isError
	} = useWorkstations({
		organization_id: organization?.id,
		company_id: company?.id,
		line_id: lineId
	});

	function handleWorkstationSelect(id: string) {
		form.setFieldValue([fieldName], id);
	}

	function handleClearWorkstations() {
		form.setFieldValue([fieldName], undefined);
	}

	return (
		<Col xs={24}>
			<CustomFormItem
				name={[fieldName]}
				label={I18n.get('Workstation')}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: true,
						message: I18n.get('Select the workstation')
					}
				]}
			>
				<WorkstationSelect
					size="large"
					lineId={lineId}
					loading={isFetching}
					fieldName={fieldName}
					workstations={workstations}
					workstationId={workstationId}
					handleSelectWorkstation={handleWorkstationSelect}
					handleClearWorkstations={handleClearWorkstations}
					disabled={isLoading || isFetching || isError || !lineId}
				/>
			</CustomFormItem>
		</Col>
	);
};
