import styled from 'styled-components';
import { Collapse } from 'antd';

export const CollapseStyled = styled(Collapse)`
	.ant-collapse-item {
		.panel-is-disable {
			margin-left: 28px;
			cursor: default !important;
		}

		.ant-collapse-header {
			align-items: center;
			padding: 10px 16px;
		}

		.ant-collapse-content-box {
			padding-top: 0px !important;
		}
	}
`;

export const Score = styled.span<{ $backGroundColor?: string; $stepKeyId?: string | number }>`
	width: 100%;
	color: #fff;
	font-weight: 600;
	padding: 5px 10px;
	text-align: center;
	border-radius: 7px;
	font-size: 0.75rem;
	display: inline-block;
	background-color: ${({ $backGroundColor }) => $backGroundColor};

	&.${({ $stepKeyId }) => $stepKeyId}_disable {
		display: none;
	}
`;
