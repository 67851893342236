import { useEffect, useState, useRef } from 'react';
import { io } from 'socket.io-client';

import { SOCKET_EVENTS, RoomKey } from '@/types';
import { socketConfig, getRoom } from '@/utils/socket';
import { useApplicationContext } from '@/context/v1/Application/context';
import { ErgonomicToolsDTO } from '@/components/ui/Inputs/types/response/ergonomicTool';

export interface ReportEvent {
	status?: boolean;
	data?: ErgonomicToolsDTO;
}

export enum Tools {
	niosh = 'niosh',
	kimMHO = 'kimMHO',
	strainIndex = 'strainIndex',
	kimPushPull = 'kimPushPull',
	libertyMutual = 'libertyMutual',
	backCompressiveForceEstimation = 'backCompressiveForceEstimation'
}

export type IReportState = {
	[key in Tools]: ReportEvent;
};

const useSocketReport = () => {
	const socketRef = useRef<any>();
	const { organization, company } = useApplicationContext();

	const [state, setState] = useState<IReportState>({
		niosh: {},
		kimMHO: {},
		strainIndex: {},
		kimPushPull: {},
		libertyMutual: {},
		backCompressiveForceEstimation: {},
	});

	function setNiosh(msg: ReportEvent) {
		setState((prevState) => ({ ...prevState, niosh: msg }));
	}

	function setKimPushPull(msg: ReportEvent) {
		setState((prevState) => ({ ...prevState, kimPushPull: msg }));
	}

	function setKimMHO(msg: ReportEvent) {
		setState((prevState) => ({ ...prevState, kimMHO: msg }));
	}

	function setStrainIndex(msg: ReportEvent) {
		setState((prevState) => ({ ...prevState, strainIndex: msg }));
	}

	function setLibertyMutual(msg: ReportEvent) {
		setState((prevState) => ({ ...prevState, libertyMutual: msg }));
	}

	function setBackCompressiveForceEstimation(msg: ReportEvent) {
		setState((prevState) => ({ ...prevState, backCompressiveForceEstimation: msg }));
	}

	useEffect(() => {
		if (organization && company) {
			const room = getRoom(RoomKey.REPORT_STATUS);
			const config = socketConfig(organization.id, company.id);

			socketRef.current = io(room, config);
			socketRef.current.on(SOCKET_EVENTS.NIOSH_REPORT, setNiosh);
			socketRef.current.on(SOCKET_EVENTS.KIM_MHO_REPORT, setKimMHO);
			socketRef.current.on(SOCKET_EVENTS.KIM_PP_REPORT, setKimPushPull);
			socketRef.current.on(SOCKET_EVENTS.STRAIN_INDEX_REPORT, setStrainIndex);
			socketRef.current.on(SOCKET_EVENTS.LIBERTY_MUTUAL_REPORT, setLibertyMutual);
			socketRef.current.on(SOCKET_EVENTS.BACK_COMPRESSIVE_FORCE_ESTIMATION_REPORT, setBackCompressiveForceEstimation);
		}
		return () => socketRef.current?.disconnect();
	}, [organization, company, state]);

	return { ...state };
};

const useSocketFile = () => {
	const socketRef = useRef<any>();
	const [file, setFile] = useState<any>(null);
	const { organization, company } = useApplicationContext();

	function updateFile(file: any) {
		setFile(file);
	}

	useEffect(() => {
		if (organization && company) {
			const room = getRoom(RoomKey.FILE_STATUS);
			const config = socketConfig(organization.id, company.id);

			socketRef.current = io(room, config);
			socketRef.current.on(SOCKET_EVENTS.UPDATE_FILE_STATUS, updateFile);
		}
		return () => socketRef.current?.disconnect();
	}, [organization, company]);

	return { file };
};

export { useSocketFile, useSocketReport };
