import React, { useContext } from 'react';
import { I18n } from '@aws-amplify/core';

import { ReportTemplate } from '@/components/views/Report/ReportTemplate';
import Spinner from '@/components/Layout/Spinner';
import { Charts } from './Charts';

import { useSectors } from '../hooks';
import { AngleTimeContext } from '../context';

export const Result = ({ form, angles, settings, isLoadingPDF, onDownloadPDF, onChangeComment }) => {
	const { organizationId, companyId, file, riskRangeName } = useContext(AngleTimeContext);
	const sectors = useSectors(organizationId, companyId);
	const selectedSector = sectors.data?.find((sector) => sector.id === file.data.sector_id);

	const sections = [
		{
			title: I18n.get('Charts'),
			component: <Charts angles={angles.data} settings={settings.data} />
		}
	];

	const isLoading = angles.isLoading || settings.isLoading;

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<ReportTemplate
			title={I18n.get('Report Results - Angle by Time')}
			isLoading={false}
			sections={sections}
			fileData={{ ...file?.data, riskRangeName }}
			sector={selectedSector}
			isLoadingPDF={isLoadingPDF}
			onDownloadPDF={onDownloadPDF}
			onChangeComment={onChangeComment}
			reportData={{ collection_date: form.getFieldValue('collection_date') }}
		/>
	);
};
