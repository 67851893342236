import React, { useState, useCallback, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Row, Col, Form, message } from 'antd';
import { I18n } from '@aws-amplify/core';
import { isNumeric } from '@/util';
import moment from 'moment';
import _ from 'lodash';

import Api from '@/services/api';
import { useFile, useSectors } from '@/hooks/v1';
import Spinner from '@/components/Layout/Spinner';
import { useDebounce } from '@/hooks/useDebounce';
import { HeaderStep, Footer } from '@/components/views/Report/Steps.js';
import { REPORT_COMMENT_DEBOUNCE_TIME as COMMENT_DEBOUNCE_TIME } from '@/constants/index.js';

import { Characteristics } from './Characteristics';
import { NioshProvider } from './context';
import { Information } from './Information';
import { NioshInput } from './NioshInput';
import { Result } from './Result';

import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useSessionStorage } from '@/hooks/useSessionStorage';
import { useApplicationContext } from '@/context/v1/Application/context';

const { useForm } = Form;

export function Niosh() {
	const queryClient = useQueryClient();
	const history = useHistory();
	const [form] = useForm();
	const { file_id } = useParams();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

	const [currentStep, setCurrentStep] = useState(0);
	const [reportType, setReportType] = useState('NIOSH');
	const updateReportType = useCallback((type) => {
		setReportType(type);
	}, []);

	const { company, organization } = useApplicationContext();

	const [cameFromPreliminary, setCameFromPreliminary] = useState(false);
	const [nameLocal] = useLocalStorage('niosh', false);
	const [nameSession, setNameSession] = useSessionStorage('niosh', false);

	const sectors = useSectors({
		organizationId: organization?.id,
		companyId: company?.id
	});

	const { file } = useFile({
		organizationId: organization?.id,
		companyId: company?.id,
		fileId: file_id
	});

	async function getReport({ organizationId, companyId, fileId }) {
		if (!organizationId || !companyId || !fileId) return [];
		const url = `/ergonomic-tool/niosh/${fileId}?organization_id=${organizationId}&company_id=${companyId}`;
		const { data } = await Api.get(url);
		if (data.status === 'failed') {
			return null;
		}

		const normalizedReport = normalizeReport(data);
		return normalizedReport;
	}

	const reportParams = {
		organizationId: organization?.id,
		companyId: company?.id,
		fileId: file?.data?.id
	};

	const report = useQuery(['niosh', reportParams], () => getReport(reportParams), {
		enabled: !!organization && !!company && !!file.data,
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (data) => {
			if (data) {
				updateReportType(data.report_type);
				goToResults(data.report_type);
			}
		}
	});

	const isLoading = sectors.isLoading || file.isLoading || report.isLoading;
	const isError = sectors.isError || file.isError;

	useEffect(() => {
		if (!isLoading) {
			if (nameLocal || nameSession) {
				setCameFromPreliminary(true);
			}
		}
	}, [isLoading]);

	useEffect(() => {
		if (cameFromPreliminary) {
			localStorage.removeItem('niosh');
			setNameSession(true);
		}
	}, [cameFromPreliminary]);

	const updateReport = useMutation(
		({ body, reportExists }) => {
			const { report_id } = body.niosh_input;
			if (reportExists) {
				return Api.put(`/ergonomic-tool/niosh/${report_id}`, body);
			} else {
				return Api.post('/ergonomic-tool/niosh', body);
			}
		},
		{
			onError: (err) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: (response) => {
				const normalizedReport = normalizeReport(response.data);
				queryClient.setQueryData(['niosh', reportParams], normalizedReport);
				queryClient.invalidateQueries(['file']);
				message.success(I18n.get('Report created successfully'));
				onNext();
			}
		}
	);

	const updateComment = useMutation(
		({ body }) => {
			const { report_id } = body.niosh_input;
			return Api.put(`/ergonomic-tool/niosh/comment/${report_id}`, body);
		},
		{
			onError: (err) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: (response) => {
				const normalizedReport = normalizeReport(response.data);
				queryClient.setQueryData(['niosh', reportParams], normalizedReport);
				message.success(I18n.get('Comment updated successfully'));
			}
		}
	);

	const generatePDF = useMutation(
		({ body, opts }) => {
			return Api.post('/ergonomic-tool/niosh/document/pdf', body, opts);
		},
		{
			onError: (err) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: (response) => {
				const blob = new Blob([response.data], {
					type: 'application/pdf'
				});
				window.open(URL.createObjectURL(blob));
				message.success(I18n.get('Document created successfully'));
			}
		}
	);

	function goToResults(type) {
		const finalStep = type === 'ISO_11228' ? steps.length : steps.length - 1;
		setCurrentStep(finalStep);
	}

	function onNext() {
		if (currentStep < steps.length - 2) {
			form.validateFields()
				.then(() => {
					setCurrentStep((prev) => prev + 1);
				})
				.catch(() => message.error(I18n.get('Enter the required values')));
		} else {
			setCurrentStep((prev) => prev + 1);
		}
	}

	function onPrevious() {
		setCurrentStep((prev) => prev - 1);
	}

	function onFinish() {
		form.validateFields()
			.then(() => {
				const values = form.getFieldsValue(true);
				const { organization_id, ...rest } = values;
				const company_id = company?.id;
				const niosh_input = {
					...rest,
					file_id: file.data.id,
					...(!!report.data && { report_id: report.data.id })
				};

				if (!report.data || isFormChanged(niosh_input, report.data)) {
					const body = { organization_id, company_id, niosh_input };
					updateReport.mutate({ body, reportExists: !!report.data });
				} else {
					onNext();
				}
			})
			.catch(() => message.error(I18n.get('Enter the required values')));
	}

	async function onClose() {
		await saveComment();
		const custom_report = !!searchParams.get('custom_report');
		if (cameFromPreliminary) {
			window.close();
		}
		if (custom_report) {
			return history.push('/custom-reports/files');
		}
		history.push('/reporting');
	}

	const onChangeComment = useDebounce(saveComment, COMMENT_DEBOUNCE_TIME);

	async function saveComment() {
		const values = form.getFieldsValue(true);
		const { comment, organization_id } = values;
		const company_id = company?.id;
		if ((comment || comment === '') && report.data.comment !== comment) {
			const niosh_input = { report_id: report.data.id, comment };
			const body = { organization_id, company_id, niosh_input };
			await updateComment.mutateAsync({ body });
		}
	}

	async function onDownloadPDF(fileData) {
		await saveComment();
		const browserLanguage = window.navigator.language ?? 'en-US';

		const body = {
			organization_id: organization?.id,
			company_id: company?.id,
			file_id: fileData.id,
			locale: browserLanguage
		};

		const opts = { responseType: 'blob' };
		generatePDF.mutate({ body, opts });
	}

	function onChangeReportType(element) {
		const type = element.target.value;
		updateReportType(type);
		if (type === 'NIOSH') {
			form.setFieldsValue({ gender: null, age: null, hands: 2, workers: 1 });
		}
	}

	function isFormChanged(niosh_input, previousReport) {
		const previousFile = {
			sector_id: file.data.sector_id,
			workstation: file.data.workstation
		};

		const formFile = {
			sector_id: niosh_input.sector_id,
			line_id: niosh_input.line_id,
			workstation: niosh_input.workstation_id
		};

		const previousNiosh = {
			collection_date: moment(previousReport.collection_date).format('L'),
			report_type: previousReport.report_type,
			gender: previousReport.gender,
			age: previousReport.age,
			workers: previousReport.workers,
			hands: previousReport.hands,
			mass_m: previousReport.mass_m,
			distance_dc: previousReport.distance_dc,
			distance_vc: previousReport.distance_vc,
			distance_h: previousReport.distance_h,
			angle_a: previousReport.angle_a,
			coupling: previousReport.coupling,
			frequency: previousReport.frequency,
			duration: previousReport.duration
		};

		const formNiosh = {
			collection_date: moment(niosh_input.collection_date).format('L'),
			report_type: niosh_input.report_type,
			gender: niosh_input.gender,
			age: niosh_input.age,
			workers: niosh_input.workers,
			hands: niosh_input.hands,
			mass_m: niosh_input.mass_m,
			distance_dc: niosh_input.distance_dc,
			distance_vc: niosh_input.distance_vc,
			distance_h: niosh_input.distance_h,
			angle_a: niosh_input.angle_a,
			coupling: niosh_input.coupling,
			frequency: niosh_input.frequency,
			duration: niosh_input.duration
		};

		const isFileChanged = !_.isEqual(previousFile, formFile);
		const isNioshChanged = !_.isEqual(previousNiosh, formNiosh);

		return isFileChanged || isNioshChanged;
	}

	// Normalize report when getting data from database
	function normalizeReport(reportData) {
		return {
			...reportData,
			mass_m: parseFloat(reportData.mass_m),
			collection_date: moment(reportData.collection_date),
			frequency: parseFloat(reportData.frequency),
			dc_factor: Math.round(reportData.a_factor * 100) / 100,
			vc_factor: Math.round(reportData.a_factor * 100) / 100,
			h_factor: Math.round(reportData.a_factor * 100) / 100,
			a_factor: Math.round(reportData.a_factor * 100) / 100,
			recommended_weight_limit: Math.round(reportData.recommended_weight_limit * 10) / 10,
			lifting_index: Math.round(reportData.lifting_index * 100) / 100
		};
	}

	// Format report for displaying data (using locale settings for decimal separator)
	function formatReport(reportData) {
		const formattedReport = { ...reportData };
		const skippedKeys = ['frequency'];

		for (const key in formattedReport) {
			if (skippedKeys.includes(key)) continue;
			if (typeof formattedReport[key] === 'number') {
				formattedReport[key] = formattedReport[key].toString();
			}
			if (!isNumeric(formattedReport[key])) continue;

			formattedReport[key] = parseFloat(formattedReport[key]).toLocaleString();
		}

		return formattedReport;
	}

	const steps = [
		{
			title: I18n.get('Information'),
			content: <Information file={file} onChangeReportType={onChangeReportType} />,
			is_valid: true
		},
		...(reportType === 'ISO_11228'
			? [
					{
						title: I18n.get('Characteristics'),
						content: <Characteristics reportType={reportType} />,
						is_valid: true
					}
			  ]
			: []),
		{
			title: I18n.get('Variables'),
			content: <NioshInput />,
			is_valid: true
		},
		{
			title: I18n.get('Result'),
			content: (
				<Result
					file={file}
					sectors={sectors}
					report={report}
					formatReport={formatReport}
					onDownloadPDF={onDownloadPDF}
					isLoadingPDF={generatePDF.isLoading}
					onChangeComment={onChangeComment}
				/>
			)
		}
	];

	if (isLoading) {
		return <Spinner />;
	}

	if (isError) {
		return <h2>Internal server error</h2>;
	}

	const formInitialValues = {
		report_type: 'NIOSH',
		organization_id: organization?.id,
		sector_id: file?.data?.workstations?.line?.sector?.id,
		line_id: file?.data?.workstations?.line?.id,
		workstation_id: file?.data?.workstations?.id,
		hands: 2,
		workers: 1,
		...report?.data
	};

	return (
		<NioshProvider>
			<Row justify="center">
				<Col sm={24} style={{ marginBottom: '16px', textAlign: 'center' }}>
					<h2>{I18n.get('NIOSH / ISO 11228-1 - Manual lifting analysis')}</h2>
				</Col>
				<Col xxl={20} xl={22} sm={24}>
					<Form form={form} layout="vertical" initialValues={formInitialValues}>
						<Row justify="center" align="middle" style={{ marginBottom: '20px' }}>
							<Col sm={20} xxl={14}>
								<HeaderStep current={currentStep} steps={steps} />
							</Col>
						</Row>
						<Row justify="center">
							<Col sm={24} style={{ minHeight: '350px' }}>
								{steps[currentStep]?.content}
							</Col>
						</Row>
						<Row justify="center">
							<Col span={24}>
								<Footer
									steps={steps}
									onPrevious={onPrevious}
									onNext={onNext}
									current={currentStep}
									onFinish={onFinish}
									onClose={onClose}
									isFetching={isLoading}
								/>
							</Col>
						</Row>
					</Form>
				</Col>
			</Row>
		</NioshProvider>
	);
}
