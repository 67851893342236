import React from 'react';
import { Col, Collapse, Row } from 'antd';
import { Title } from '@/components/Typography';

import { ActionPlanData } from './ActionPlanData';
import { ActionPlanDTO } from '../hooks/types';
import { FormContainer } from '../styles';
import { Container } from './styles';

type ActionPlansProps = {
	actionPlans: ActionPlanDTO[];
};

export const ActionPlans: React.FC<ActionPlansProps> = ({ actionPlans }) => {
	const panels = (
		<Collapse.Panel
			key={'1'}
			header={
				<Title style={{ margin: 0 }} level={5}>
					Action plans
				</Title>
			}
		>
			{actionPlans.map((actionPlan) => {
				return (
					<Row justify="center" key={actionPlan.id} style={{ marginTop: '1rem' }}>
						<Col span={16}>
							<Container>
								<ActionPlanData actionPlan={actionPlan} />
							</Container>
						</Col>
					</Row>
				);
			})}
		</Collapse.Panel>
	);

	return (
		<>
			<Col span={24}>
				<FormContainer bordered={false}>{panels}</FormContainer>
			</Col>
		</>
	);
};
