import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Modal, Row } from 'antd';
import moment from 'moment';

import { useApplicationContext } from '@/context/v1/Application/context';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Title } from '@/components/Typography/index';

import { SuperPEAQueryParams } from './types';
import { TableList } from './TableList';
import { Filter } from './Filter';
import hooks from './hooks';

const { useGetSuperPEAList, useDeleteSuperPEA } = hooks;

export function SuperPreliminaryAnalysis() {
	const { organization, company } = useApplicationContext();
	const [queryParams, setQueryParams] = useState<SuperPEAQueryParams>({
		organizationId: organization.id,
		companyId: company.id
	});
	const { data, isLoading, refetch } = useGetSuperPEAList(queryParams);
	const { mutateAsync: deleteSuperPEA } = useDeleteSuperPEA();

	function handleDeleteSuperPEA(superPEAId: React.Key) {
		Modal.confirm({
			title: I18n.get('Heads up! Want to delete this report?'),
			icon: <ExclamationCircleOutlined />,
			okText: I18n.get('Confirm'),
			cancelText: I18n.get('Cancel'),
			okType: 'danger',
			onOk: async () => {
				deleteSuperPEA({ superPEAId, organizationId: organization.id, companyId: company.id });
				refetch();
			}
		});
	}

	function handleFilter(filters: SuperPEAQueryParams) {
		setQueryParams((prev) => ({
			...prev,
			...filters
		}));
	}

	function handleClearFilter() {
		setQueryParams({
			organizationId: organization.id,
			companyId: company.id
		});
	}

	return (
		<Row gutter={[0, 10]}>
			<Col span={24}>
				<Title level={4}>{I18n.get('Preliminary Ergonomic Analyzes')}</Title>
			</Col>
			<Col span={24}>
				<Filter onFilter={handleFilter} onClearFilter={handleClearFilter} />
			</Col>
			<Col span={24}>
				<TableList
					data={data.map((report) => ({
						key: report.id,
						id: report.id,
						dateOfCollection: moment(report.collection_date).format('DD-MM-YYYY'),
						reportName: report.name,
						worstScore: report.worst_score,
						peaIds: report.pea_ids,
						createdAt: moment(report.created_at).format('DD-MM-YYYY')
					}))}
					onDelete={handleDeleteSuperPEA}
					isLoading={isLoading}
				/>
			</Col>
		</Row>
	);
}
