import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message, notification } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CreateCompanyRequest } from '@/components/ui/Inputs/types/request/company';
import { CompanyDTO } from '@/components/ui/Inputs/types/response';
import Api from '@/services/api';

export const useCreateCompany = () => {
	const queryClient = useQueryClient();
	return useMutation(({ body }: { body: CreateCompanyRequest }) => Api.post<CompanyDTO>('/company/create', body), {
		onError: (err: any) => {
			notification.error({
				message: I18n.get(err?.response?.data?.message || 'Ops... something happened!')
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['companies']);
			queryClient.invalidateQueries(['companies_list']);
			message.success(I18n.get('Company created successfully'));
		}
	});
};
