import React from 'react';
import { Col, Row } from 'antd';

import { HeaderTitle } from './styles';

export const Header: React.FC = () => {
	return (
		<Col xs={24} md={20} xl={16} xxl={12}>
			<Row justify="center" align="middle">
				<Col>
					<HeaderTitle level={4}>Safety Ergonomic Risk Assessment (S.E.R.A.)</HeaderTitle>
				</Col>
			</Row>
		</Col>
	);
};
