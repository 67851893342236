import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';

import {
	CreateOrganizationRequest,
	DeleteOrganizationRequest,
	GetOrganizationsRequest,
	OrganizationPaginated,
	UpdateOrganizationRequest
} from './types';
import { ManageOrganizationService } from './services';

const service = new ManageOrganizationService();

export function useGetOrganizations({ limit, offset, filter, company_id, organization_id }: GetOrganizationsRequest) {
	const payload = { limit, offset, filter, company_id, organization_id };
	const organizations = useQuery(['organizations_list', filter], () => service.fetchOrganizations(payload), {
		enabled: !!organization_id
	});
	return {
		...organizations,
		data: organizations.data ?? ({} as OrganizationPaginated)
	};
}

export function useCreateOrganization() {
	const queryClient = useQueryClient();
	return useMutation((body: CreateOrganizationRequest) => service.createOrganization(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message ?? err.response.statusText));
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['organizations_list']);
			queryClient.invalidateQueries(['organizations']);
			message.success(I18n.get('Company created successfully'));
		}
	});
}

export function useUpdateOrganization() {
	const queryClient = useQueryClient();
	return useMutation((body: UpdateOrganizationRequest) => service.updateOrganization(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message ?? err.response.statusText));
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['organizations_list']);
			queryClient.invalidateQueries(['organizations']);
			message.success(I18n.get('Company updated successfully'));
		}
	});
}

export function useDeleteOrganization() {
	const queryClient = useQueryClient();
	return useMutation((body: DeleteOrganizationRequest) => service.deleteOrganization(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message ?? err.response.statusText));
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['organizations_list']);
			queryClient.invalidateQueries(['organizations']);
			message.success(I18n.get('Company deleted successfully'));
		}
	});
}
