import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Radio, Space } from 'antd';
import { I18n } from '@aws-amplify/core';

import { HandCouplingTooltipContent } from './HandCouplingTooltipContent';
import { HandCouplingDTO } from '../../../hooks/types/response';
import { HandCouplingMapper } from '../../../context/types';
import { CustomLabel, CustomTooltip } from '../CustomLabel';

type Coupling = {
	value: HandCouplingDTO;
	description: HandCouplingMapper;
};

export const HandCoupling = () => {
	const couplings: Coupling[] = [
		{
			value: HandCouplingDTO.GOOD,
			description: HandCouplingMapper.GOOD
		},
		{
			value: HandCouplingDTO.FAIR,
			description: HandCouplingMapper.FAIR
		},
		{
			value: HandCouplingDTO.POOR,
			description: HandCouplingMapper.POOR
		}
	];

	return (
		<Form.Item
			name={['liberty_mutual_inputs', 'hand_coupling']}
			label={
				<CustomLabel
					icon={
						<CustomTooltip top={-120} left={8} content={<HandCouplingTooltipContent />} placement="topLeft">
							<InfoCircleOutlined style={{ fontSize: '0.65rem', marginLeft: '0.1rem' }} />
						</CustomTooltip>
					}
					title="Hand coupling"
				/>
			}
			labelCol={{ span: 24 }}
			rules={[
				{
					required: true,
					message: I18n.get('Select an hand coupling')
				}
			]}
		>
			<Radio.Group>
				<Space direction="horizontal">
					{couplings.map((coupling) => {
						return (
							<Radio key={coupling.value} value={coupling.value}>
								{I18n.get(coupling.description)}
							</Radio>
						);
					})}
				</Space>
			</Radio.Group>
		</Form.Item>
	);
};
