import React from 'react';
import { NamePath } from 'antd/lib/form/interface';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { RiskCategoryDTO } from '@/components/ui/Inputs/types/response/riskCategory';
import { RiskCategorySelect } from '@/components/ui/Inputs/RiskCategorySelect';
import { CustomFormItem } from './styles';

const { useFormInstance, useWatch } = Form;

interface RiskCategoriesProps {
	isLoading: boolean;
	isFetching: boolean;
	collapseKey: number;
	fieldName: NamePath;
	damagesFieldName: NamePath;
	descriptionFieldName: NamePath;
	riskCategories: RiskCategoryDTO[];
}

export const RiskCategories: React.FC<RiskCategoriesProps> = ({
	isLoading,
	fieldName,
	isFetching,
	collapseKey,
	riskCategories,
	damagesFieldName,
	descriptionFieldName
}) => {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();

	const riskCategoryId = useWatch(fieldName, form);

	async function handleRiskCategorySelect(id: string) {
		setFieldValue(fieldName, id);
		setFieldValue(damagesFieldName, undefined);
		setFieldValue(descriptionFieldName, undefined);
		setFieldValue([collapseKey, 'severity_id'], undefined);
	}

	async function handleClearRiskCategory() {
		setFieldValue(fieldName, undefined);
		setFieldValue(damagesFieldName, undefined);
		setFieldValue(descriptionFieldName, undefined);
		setFieldValue([collapseKey, 'severity_id'], undefined);
	}

	return (
		<Col xs={24} sm={12} lg={24}>
			<CustomFormItem
				name={fieldName}
				label={I18n.get('Category')}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: true,
						message: I18n.get('Select the category')
					}
				]}
			>
				<RiskCategorySelect
					allowCreation={false}
					fieldName={fieldName}
					riskCategoryId={riskCategoryId}
					loading={isFetching}
					riskCategories={riskCategories}
					handleClearRiskCategory={handleClearRiskCategory}
					handleSelectRiskCategory={handleRiskCategorySelect}
					disabled={isLoading || isFetching}
				/>
			</CustomFormItem>
		</Col>
	);
};
