import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form, Input } from 'antd';

export const Organization: React.FC = () => {
	return (
		<Col xs={24} sm={12}>
			<Form.Item labelCol={{ span: 24 }} name={['organization_id']} label={I18n.get('Company')}>
				<Input disabled />
			</Form.Item>
		</Col>
	);
};
