import React from 'react';
import { Col, Row, Skeleton, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { I18n } from '@aws-amplify/core';

import { Title } from '@/components/Typography';

import { BeraResultRPNScoresByStepKey, BeraResultRPNScoresByTask } from '../types';
import { CustomTable } from './styles';

interface HighestRPNProps {
	isLoading: boolean;
	data: {
		by_task: BeraResultRPNScoresByTask[];
		by_step_key: BeraResultRPNScoresByStepKey[];
	};
}

export const HighestRPN: React.FC<HighestRPNProps> = ({ isLoading, data }) => {
	const byTaskColumns: ColumnsType<BeraResultRPNScoresByTask> = [
		{
			title: I18n.get('Position'),
			dataIndex: 'order',
			key: 1,
			align: 'center'
		},
		{
			title: I18n.get('TLID'),
			dataIndex: 'task_name',
			key: 2,
			ellipsis: true,
			align: 'center'
		},
		{
			title: I18n.get('RPN'),
			dataIndex: 'rpn',
			key: 3,
			align: 'center'
		}
	];

	const byStepKeyColumns: ColumnsType<BeraResultRPNScoresByStepKey> = [
		{
			title: I18n.get('Position'),
			dataIndex: 'order',
			key: 1,
			align: 'center'
		},
		{
			title: I18n.get('TLID'),
			dataIndex: 'task_name',
			key: 2,
			ellipsis: true,
			align: 'center'
		},
		{
			title: I18n.get('Risk factor'),
			dataIndex: 'step_key_description',
			key: 3,
			ellipsis: true,
			align: 'center',
			responsive: ['md'],
			render: (step_key_description) => I18n.get(step_key_description)
		},
		{
			title: I18n.get('RPN'),
			dataIndex: 'rpn',
			key: 4,
			align: 'center'
		}
	];

	return (
		<Col span={24}>
			<Row>
				<Title level={5}>Highest RPN</Title>
			</Row>
			<Row justify="center">
				{isLoading ? (
					<Col span={24}>
						<Skeleton active />
					</Col>
				) : (
					<CustomTable span={12}>
						<Table rowKey="id" pagination={false} dataSource={data?.by_task} columns={byTaskColumns} />
					</CustomTable>
				)}
			</Row>
			<Row justify="center" style={{ marginTop: '1rem' }}>
				{isLoading ? (
					<Col span={24}>
						<Skeleton active />
					</Col>
				) : (
					<CustomTable span={16}>
						<Table
							rowKey="id"
							pagination={false}
							dataSource={data?.by_step_key}
							columns={byStepKeyColumns}
						/>
					</CustomTable>
				)}
			</Row>
		</Col>
	);
};
