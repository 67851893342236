import React from 'react';
import { NamePath } from 'antd/lib/form/interface';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { StepKeyNameList } from '@/components/ui/Inputs/types/response/customReport';
import { StressLevelDTO } from '@/components/ui/Inputs/types/response/stressLevel';
import { FrequencyDTO } from '@/components/ui/Inputs/types/response/frequency';
import { FrequencySelect } from '@/components/ui/Inputs/FrequencySelect';
import { Spinner } from '@/components/Spinner';

import { CustomStepKeyFormItem, ScaleColor, scaleColorMapper } from './styles';

const { useFormInstance, useWatch } = Form;

interface FrequenciesProps {
	isError: boolean;
	disabled: boolean;
	isLoading: boolean;
	isFetching: boolean;
	fieldName: NamePath;
	fieldScoreName: NamePath;
	frequencies: FrequencyDTO[];
	stepKeyName: StepKeyNameList;
	selectedStressLevel: StressLevelDTO | undefined;
}

export const Frequencies: React.FC<FrequenciesProps> = ({
	isError,
	disabled,
	fieldName,
	isLoading,
	isFetching,
	stepKeyName,
	frequencies,
	fieldScoreName,
	selectedStressLevel
}) => {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();

	const frequencyId = useWatch(fieldName, form);

	const frequency = frequencies?.find((frequency) => frequencyId === frequency.id);
	const stepDefinedByStressLevel = isStepDefinedByStressLevel();
	const colorMapper = defineColorMapper();
	setFieldValue(fieldScoreName, colorMapper);
	const color = scaleColorMapper[colorMapper];

	function defineColorMapper() {
		return stepDefinedByStressLevel && selectedStressLevel?.score === 1 && frequencyId ? 1 : frequency?.score ?? 0;
	}

	function isStepDefinedByStressLevel() {
		return stepKeyName === 'has_too_high' || stepKeyName === 'has_too_low' || stepKeyName === 'has_too_far';
	}

	async function handleFrequencySelect(id: string) {
		setFieldValue(fieldName, id);
	}

	async function handleClearFrequency() {
		setFieldValue(fieldName, undefined);
	}

	if (isLoading) {
		return (
			<Col xs={22} sm={18} md={19} lg={17} xl={20} xxl={21}>
				<Spinner size={12} />
			</Col>
		);
	}

	return frequencies && frequencies.length > 0 ? (
		<>
			<Col xs={22} sm={18} md={19} lg={17} xl={20} xxl={21}>
				<CustomStepKeyFormItem
					name={fieldName}
					label={I18n.get('Frequency')}
					labelCol={{ span: 24 }}
					rules={[
						{
							required: true,
							message: I18n.get('Select the frequency')
						}
					]}
				>
					<FrequencySelect
						frequencies={frequencies}
						loading={isFetching}
						disabled={isLoading || isFetching || isError || disabled}
						frequencyId={frequencyId}
						fieldName={fieldName}
						handleClearFrequency={handleClearFrequency}
						handleSelectFrequency={handleFrequencySelect}
					/>
				</CustomStepKeyFormItem>
			</Col>
			<ScaleColor scale_color={color} />
		</>
	) : (
		<></>
	);
};
