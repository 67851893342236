import React from 'react';
import { Col, Row } from 'antd';
import { Title } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';

import { OverallDetailedParameters, OverallScoreParameters } from './types';
import { OverallScore } from './OverallScore';
import { StrainIndex } from '../types';
import { Detailed } from './Detailed';
import { Container } from './styles';

interface StrainIndexResultProps {
	strain_index: StrainIndex;
}

export const StrainIndexResult: React.FC<StrainIndexResultProps> = ({ strain_index }) => {
	const {
		left_risk,
		right_risk,
		score_left_rsi,
		score_right_rsi,
		score_left_borg_scale,
		score_right_borg_scale,
		score_left_exertion_duration,
		score_right_exertion_duration,
		score_left_efforts_per_minute,
		score_right_efforts_per_minute,
		score_left_wrist_posture,
		score_right_wrist_posture,
		score_left_daily_duration,
		score_right_daily_duration
	} = strain_index;

	const detailed: OverallDetailedParameters = {
		intensity_of_exertion: {
			left: score_left_borg_scale,
			right: score_right_borg_scale
		},
		exertion_duration: {
			left: score_left_exertion_duration,
			right: score_right_exertion_duration
		},
		efforts_per_minute: {
			left: score_left_efforts_per_minute,
			right: score_right_efforts_per_minute
		},
		wrist_posture: {
			left: score_left_wrist_posture,
			right: score_right_wrist_posture
		},
		daily_duration: {
			left: score_left_daily_duration,
			right: score_right_daily_duration
		}
	};

	const overallScore: OverallScoreParameters = {
		left: score_left_rsi,
		right: score_right_rsi,
		risk: {
			left: left_risk,
			right: right_risk
		}
	};

	return (
		<Row gutter={[0, 24]}>
			<Col offset={1} span={23}>
				<Row>
					<Title style={{ margin: 0 }} level={5}>
						{I18n.get('Revised Strain Index (RSI)')}
					</Title>
				</Row>
			</Col>

			<Col offset={1} span={23}>
				<Container>
					<Row>
						<Col span={12}>
							<Detailed parameters={detailed} />
						</Col>
						<Col span={12}>
							<OverallScore parameters={overallScore} />
						</Col>
					</Row>
				</Container>
			</Col>
		</Row>
	);
};
