import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Button, Col, Dropdown, Image, Space } from 'antd';
import { DownOutlined, LineChartOutlined, FileTextOutlined, DeleteOutlined } from '@ant-design/icons';

import Can from '@/components/Can';
import { CustomButton } from './styles';

const DOWNLOAD_ICON = 'https://kinebot-statics.s3.amazonaws.com/download-video-black.svg';

enum Status {
	IN_QUEUE = 'IN_QUEUE',
	NOT_PROCESSED = 'NOT_PROCESSED',
	EXTRACTED_DATA = 'EXTRACTED_DATA',
	PROCESSED = 'PROCESSED',
	CORRUPTED_FILE = 'CORRUPTED_FILE'
}
interface FileType {
	id: string;
	original_name: string;
	status?: Status;
}

interface ActionsForFileProps {
	file: any;
	onDownload: (file: FileType) => void;
	onShowOptionsReports: (file: FileType) => void;
	onShowOptionsEwa: (file: FileType) => void;
	onDelete: (id: string) => void;
}

interface OptionDropdownProps {
	children: string;
	disabled?: boolean;
	onClick: React.MouseEventHandler<HTMLElement>;
}

export default function ActionsForFile({
	file,
	onDownload,
	onShowOptionsReports,
	onShowOptionsEwa,
	onDelete
}: Readonly<ActionsForFileProps>) {
	function onReports(): void {
		onShowOptionsReports(file);
	}

	function onEwaReports(): void {
		onShowOptionsEwa(file);
	}

	function onDownloadFile(): void {
		onDownload(file);
	}

	function onDeleteFile(): void {
		onDelete(file.id);
	}

	const OptionDropdown: React.FC<OptionDropdownProps> = ({ children, disabled = false, onClick }): JSX.Element => (
		<Button type="link" disabled={disabled} onClick={onClick}>
			<span>{I18n.get(children)}</span>
		</Button>
	);

	const processedFile = file.status === Status.PROCESSED;
	const extractedData = file.status === Status.EXTRACTED_DATA;
	const dataAvailable = processedFile || extractedData;

	const permissionRole = Can('delete', 'upload');

	const downloadVideoIcon = (
		<Col span={1} style={{ width: '30px' }}>
			<Image src={DOWNLOAD_ICON} preview={false} alt={I18n.get('Download video')} />
		</Col>
	);

	const items = [
		{
			key: 1,
			disabled: !dataAvailable,
			icon: <LineChartOutlined />,
			label: (
				<OptionDropdown disabled={!dataAvailable} onClick={onReports}>
					Reports
				</OptionDropdown>
			)
		},
		{
			key: 2,
			disabled: !dataAvailable,
			icon: <FileTextOutlined />,
			label: (
				<OptionDropdown disabled={!dataAvailable} onClick={onEwaReports}>
					{I18n.get('EWA')}
				</OptionDropdown>
			)
		},
		{
			key: 3,
			disabled: !processedFile,
			icon: downloadVideoIcon,
			label: (
				<OptionDropdown disabled={!processedFile} onClick={onDownloadFile}>
					Download
				</OptionDropdown>
			)
		},
		{
			key: 4,
			danger: true,
			disabled: !permissionRole,
			icon: <DeleteOutlined />,
			label: (
				<CustomButton>
					<OptionDropdown disabled={!permissionRole} onClick={onDeleteFile}>
						Delete
					</OptionDropdown>
				</CustomButton>
			)
		}
	];

	return (
		<Dropdown menu={{ items }} placement="bottom" trigger={['click']}>
			<Button type="link">
				<Space>
					{I18n.get('Action')}
					<DownOutlined />
				</Space>
			</Button>
		</Dropdown>
	);
}
