import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';
import { BaseContext } from '@/types';

type StepKeyDTO = BaseContext & {
	original_custom_report_result_id?: string;
	step_key_id: string;
	hasPreviousValue?: boolean;
}

interface RiskCategory {
	id: string;
	name: string;
	description: string;
}

interface RiskDamage {
	id: string;
	name: string;
	description: string;
}

interface RiskDescription {
	id: string;
	name: string;
	description: string;
}

interface Severity {
	id: string;
	score: number;
	name: string;
	description: string;
}

interface Vulnerability {
	id: string;
	name: string;
	score: number;
	description: string;
}

interface Exposure {
	id: string;
	name: string;
	score: number;
	description: string;
}

export interface AdditionalItem {
	id: string;
	result: number;
	values: AdditionalItemValue[];
}

interface AdditionalItemValue {
	title: string;
	unit?: string | null;
	value: string;
}

export interface SubStepKeyResult {
	id: string;
	score: number | null;
	result: number | null;
	severity?: Severity | null;
	vulnerability?: Vulnerability | null;
	exposure?: Exposure | null;
	risk_category?: RiskCategory | null;
	risk_description?: RiskDescription | null;
	risk_damage?: RiskDamage | null;
	sub_step_key: SubStepKey;
	action_plan: ActionPlan;
}

interface SubStepKey {
	id: string;
	name: string;
	description: string;
	sequence: number;
}

interface CustomReportReview {
	id: string;
	name: string;
	created_at: Date;
}

export interface ActionPlan {
	id: string
	title: string
	deadline: string
	description: any
	responsible_name: string
	tasks: Task[]
}

export interface Task {
	id: string
	description: string
	type: string
	is_completed: boolean
	is_active: boolean
	created_at: string
	updated_at: string
	createdAt: string
	updatedAt: string
	action_plan_id: string
}


export interface StepKeyResults {
	id: string;
	score: number;
	result: number;
	severity?: Severity | null;
	vulnerability?: Vulnerability | null;
	exposure?: Exposure | null;
	risk_category?: RiskCategory | null;
	risk_description?: RiskDescription | null;
	risk_damage?: RiskDamage | null;
	additional_items: AdditionalItem[];
	action_plan: ActionPlan;
}

export interface LastStepKeyResultResponse {
	custom_report_review: CustomReportReview;
	step_key_results: StepKeyResults;
	sub_step_keys_results: SubStepKeyResult[];
}


const findLastStepKeyResult = async (params: StepKeyDTO): Promise<LastStepKeyResultResponse> => {
	const url = `/custom-report/result/last-step-key-result`;
	const { data } = await Api.get<LastStepKeyResultResponse>(url, { params });

	return data;
};

export const useGetLastStepKeyResult = ({ organization_id, company_id, hasPreviousValue, original_custom_report_result_id, step_key_id }: StepKeyDTO) => {
	const enabled = !!(organization_id && company_id && hasPreviousValue && original_custom_report_result_id && step_key_id);
	const result = useQuery(
		['upload', { organization_id, company_id, original_custom_report_result_id, step_key_id }],
		() => findLastStepKeyResult({ organization_id, company_id, original_custom_report_result_id, step_key_id }),
		{ enabled, retry: false }
	);

	return result;
};
