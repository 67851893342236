import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, Form, Input, message } from 'antd';
import { I18n } from '@aws-amplify/core';
import Api from '@/services/api';

const { useForm } = Form;

export function CreateOrganization({ visible, onClose, triggerReload }) {
	const [form] = useForm();
	const inputName = useRef(null);
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState(null);

	useEffect(() => {
		if (errors) {
			const result = errors.response?.data?.message || errors.message;
			const description = I18n.get(result);
			message.error(description);
			setErrors(null);
		}
	}, [errors]);

	useEffect(() => {
		if (!inputName.current) return;
		inputName.current.focus();
	}, [visible]);

	useEffect(() => {
		if (!visible) return;
		const keyDownHandler = (event) => {
			if (event.key === 'Enter') {
				event.preventDefault();
				onSubmit();
			}
		};
		document.addEventListener('keydown', keyDownHandler);

		return () => {
			document.removeEventListener('keydown', keyDownHandler);
		};
	}, [onSubmit, visible]);

	function onCreate({ name }) {
		Api.post('/organization', { name })
			.then(setLoading(true))
			.then((res) => {
				onClose();
				form.resetFields();
				triggerReload(res.data);
				message.success(I18n.get('Company created successfully'));
			})
			.catch((error) => setErrors(error))
			.finally(() => setLoading(false));
	}

	function onSubmit() {
		form.validateFields()
			.then(onCreate)
			.catch((errorInfo) => {});
	}

	return (
		<Modal
			visible={visible}
			onCancel={onClose}
			title={I18n.get('Create a new company')}
			footer={[
				<Button key="cancel" onClick={onClose}>
					{I18n.get('Cancel')}
				</Button>,
				<Button
					key="save"
					type="primary"
					loading={loading}
					onClick={onSubmit}
				>
					{I18n.get('Save')}
				</Button>,
			]}
		>
			<Form form={form} layout="vertical" name="create_user">
				<Form.Item
					name="name"
					label={I18n.get('Name')}
					rules={[
						{
							required: true,
							message: I18n.get('Please enter a valid name'),
						},
					]}
				>
					<Input placeholder={I18n.get('Name')} ref={inputName} />
				</Form.Item>
			</Form>
		</Modal>
	);
}
