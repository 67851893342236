import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Link } from 'react-router-dom';
import { Modal, Table } from 'antd';

import { useGetPEAList } from './hooks';
import { ColumnsType } from 'antd/lib/table';
import { Title } from '@/components/Typography';
import { useApplicationContext } from '@/context/v1/Application/context';

type PEAListModalProps = {
	isModalOpen: boolean;
	onIsModalOpen: () => void;
	rowSelected: string;
};

export function PEAListModal({ isModalOpen, onIsModalOpen, rowSelected }: PEAListModalProps) {
	const { organization, company } = useApplicationContext();
	const { data, isLoading } = useGetPEAList(rowSelected, organization.id, company.id);

	const columns: ColumnsType<any> = [
		{
			title: I18n.get('Name'),
			dataIndex: 'name',
			key: 'name',
			align: 'center',
			ellipsis: true,
			render: (obj: { file_name: string; file_id: string }) => (
				<Link to={`/reporting/preliminary-ergonomic-analysis/${obj.file_id}`} target="_blank" rel="noreferrer">
					{obj.file_name}
				</Link>
			)
		},
		{
			title: I18n.get('Analyst'),
			dataIndex: 'analyst',
			key: 'analyst',
			align: 'center',
			ellipsis: true
		},
		{
			title: I18n.get('Score'),
			dataIndex: 'score',
			key: 'score',
			align: 'center',
			ellipsis: true
		}
	];

	return (
		<Modal bodyStyle={{ height: '500px' }} open={isModalOpen} onCancel={onIsModalOpen} footer={null}>
			<Title level={3}>{I18n.get('Files List')}</Title>
			<Table
				pagination={{
					pageSize: 5
				}}
				rowKey="key"
				dataSource={data.map((pea) => ({
					key: pea.id,
					name: { file_name: pea.file_name, file_id: pea.file_id },
					analyst: pea.analyst_name,
					score: pea.score
				}))}
				columns={columns}
				loading={isLoading}
			/>
		</Modal>
	);
}
