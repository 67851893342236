import Api from '@/services/api';

import {
	SeveritiesRequest,
	RiskDamagesRequest,
	RiskDescriptionsRequest,
	UpdateRiskDamageSeverityRequest
} from '@/components/ui/Inputs/types/request/customReport';
import { UpdateBaseRequest, CreateBaseRequest, DeleteBaseRequest } from '../types';
import { RiskDamageDTO, RiskDescriptionDTO, SeverityDTO } from '@/components/ui/Inputs';

export class ManageRiskService {
	private urlMapper = {
		riskDescription: '/custom-report/risk-description',
		riskDamage: '/custom-report/risk-damage'
	};

	public async fetchRiskDescriptions({ organization_id, company_id, risk_category_id }: RiskDescriptionsRequest) {
		const baseUrl = `/custom-report/risk-description/${risk_category_id}`;
		const { data } = await Api.get<RiskDescriptionDTO[]>(baseUrl, {
			params: {
				risk_category_id,
				organization_id,
				company_id
			}
		});
		return data;
	}

	public async fetchRiskDamages({ organization_id, company_id, risk_description_id }: RiskDamagesRequest) {
		const baseUrl = `/custom-report/risk-damage/${risk_description_id}`;
		const { data } = await Api.get<RiskDamageDTO[]>(baseUrl, {
			params: {
				organization_id,
				company_id
			}
		});
		return data;
	}

	public async fetchSeverities({ organization_id, company_id, step_key_id }: SeveritiesRequest) {
		const baseUrl = `/custom-report/severity/step-key/${step_key_id}`;
		const { data } = await Api.get<SeverityDTO[]>(baseUrl, {
			params: {
				organization_id,
				company_id
			}
		});
		return data;
	}

	public async updateRiskDamageSeverity(body: UpdateRiskDamageSeverityRequest): Promise<RiskDamageDTO> {
		const url: string = `/custom-report/risk-damage/severity/${body.id}`;
		const { data } = await Api.put<RiskDamageDTO>(url, body);
		return data;
	}

	public async create<Body extends CreateBaseRequest, T>(body: Body): Promise<T> {
		const url = `${this.urlMapper[body.hierarchy_field]}/create`;
		const { data } = await Api.post<T>(url, body);
		return data;
	}

	public async update<Body extends UpdateBaseRequest, T>(body: Body): Promise<T> {
		const url: string = `${this.urlMapper[body.hierarchy_field]}/${body.id}`;
		const { data } = await Api.put<T>(url, body);
		return data;
	}

	public async delete<Body extends DeleteBaseRequest, T>(body: Body): Promise<T> {
		const url: string = this.urlMapper[body.hierarchy_field];
		const { data } = await Api.delete<T>(url, {
			params: body
		});
		return data;
	}
}
