import React from 'react';
import { Row, Col } from 'antd';
import { Title } from '@/components/Typography';

export const Header: React.FC = () => {
	return (
		<Row justify="center" style={{ marginTop: '1rem' }}>
			<Col>
				<Title level={2}>Preliminary Ergonomic Analysis</Title>
			</Col>
		</Row>
	);
};
