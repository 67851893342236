import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';

import { diagnosticColors } from '@/constants/RebaJDS';
import { Text } from '@/components/Typography';
import { Diagnostics } from '@/types/RebaJDS';
import { BodyPartsName } from '@/hooks';
import { PieChart } from './PieChart';

type PercentageItemProps = {
	index: number;
	value: number;
};

interface ChartsProps {
	angles: number[];
	member: BodyPartsName;
}

export function Chart({ angles, member }: ChartsProps) {
	const mappedAngle = angles || [0, 0, 0, 0, 0];

	function calculatePercentage(array: number[]): number[] {
		const total = array.reduce((acc, value) => acc + value, 0);
		return array.map((item) => Math.round((item / total) * 100));
	}

	const scales = Object.values(Diagnostics);
	const percentages = calculatePercentage(mappedAngle);

	const PercentageItem = ({ value, index }: PercentageItemProps) => (
		<Col key={index} span={24}>
			<span style={{ fontSize: '12px' }}>
				{I18n.get(scales[index])}:{' '}
				<Text strong style={{ color: diagnosticColors[index] }}>
					{value}%
				</Text>
			</span>
		</Col>
	);

	return (
		<Row align="middle" justify="space-between" style={{ padding: '20px' }}>
			<Col span={24}>
				<Text style={{ fontSize: '16px' }} strong>
					{member}
				</Text>
			</Col>
			<Col span={12}>
				<Row>
					{percentages?.map((value: number, i: number) => (
						<PercentageItem key={i} value={value} index={i} />
					))}
				</Row>
			</Col>
			<Col span={8}>
				<PieChart scores={mappedAngle} />
			</Col>
		</Row>
	);
}
