import { DataItem } from '../context/index.types';

export function calculateTilesWeight(globalQuantity: number) {
	return (item: DataItem) => {
		return {
			...item,
			size: Math.floor((item.count * 100) / globalQuantity)
		};
	};
}

export function sort(a: DataItem, b: DataItem) {
	if (a.count > b.count) {
		return -1;
	}

	if (a.count < b.count) {
		return 1;
	}

	return 0;
}

export function splitInLines(linesCount: number, items: Array<DataItem>): Array<Array<DataItem>> {
	const lines: Array<Array<DataItem>> = Array.from({
		length: linesCount
	}).map(() => []);

	const perLine = items.length / linesCount;

	let referenceIndex = 0;

	lines.forEach((line) => {
		for (let auxPerline = 0; auxPerline <= perLine; auxPerline++) {
			if (items[referenceIndex]) {
				line.push(items[referenceIndex]);
				referenceIndex++;
			}
		}
	});

	return lines;
}
