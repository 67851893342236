import styled, { css } from 'styled-components';
import { Col } from 'antd';

type CustomProps = {
	disabled?: boolean;
	selected?: boolean;
	hasBody?: boolean;
	open?: boolean;
	noPadding?: boolean;
};

const colorSelected = '#2F54EB';

const borderSelected = css`
	box-shadow: lightgrey 2px 2px 10px;
	border: 1px solid ${colorSelected};
`;

export const Header = styled(Col) <CustomProps>`
	margin: 0px;
	font-size: 15px;
`;

export const Container = styled.div<CustomProps>`
	border-radius: 8px;
	user-select: none;
	border: 1px solid #ddd;
	background: ${(props) => (props.disabled ? '#f5f5f5' : '#ffffff')};
	cursor: ${(props) => setContainerCursor(props)};
	padding: ${(props) => props.noPadding ? '0px' : '15px'};
	margin-bottom: 0;
	transition: all 0.2s;
	.collapse-check-custom {
		.ant-typography {
			margin: 0;
			color: ${({ selected }) => (selected ? colorSelected : 'black')};
		}
	}

	h5 {
		color: ${(props) => (props.disabled ? '#4C4C4E80' : '#000000')};
	}

	:hover {
		filter: ${(props) => setContainerBrightness(props)};
	}

	${(props) => props.selected && borderSelected}
`;

export const Body = styled.div<CustomProps>`
	border-top: none;
	opacity: ${(props) => (props.open ? '1' : '0')};
	max-height: ${(props) => (props.open ? '100%' : '0')};
	overflow: hidden;
	transition: all 0.3s;
	cursor: auto;
`;

export const BodyPadding = styled.div<CustomProps>`
	/* padding: ${(props) => (props.open ? '1rem' : '0 1rem')}; */
	padding: 1rem;
`;

function setContainerCursor({ disabled, selected, hasBody }: CustomProps): string {
	if (disabled) {
		return 'not-allowed';
	}

	if (!selected || !hasBody) {
		return 'pointer';
	}

	return 'auto';
}

function setContainerBrightness({ disabled, selected, hasBody }: CustomProps): string {
	if (disabled) {
		return 'brightness(1)';
	}

	if (!selected || !hasBody) {
		return 'brightness(0.9)';
	}

	return 'brightness(1)';
}
