import thunk from 'redux-thunk'
import { logger } from 'redux-logger'
import { createStore, applyMiddleware } from 'redux'

import history from './history'
import rootReducer from '../redux'

const middlewares = [thunk]

if (process.env.NODE_ENV === `development`) {
	middlewares.push(logger)
}

const store = createStore(rootReducer(history), applyMiddleware(...middlewares))

export default store
