import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'antd';

import { I18n } from '@aws-amplify/core';
import { SearchOutlined } from '@ant-design/icons';

import { ClearFilter } from './styles';
import { DashboardCard } from '../styles';
import { FilterByLine } from './FilterByLine';
import { FilterBySector } from './FilterBySector';
import { FilterByStressLevel } from './FilterByStressLevel';
import { FilterByWorkstation } from './FilterByWorkstation';
import { useDashboardContext } from '../context';

const { useForm } = Form;

export const FilterCard: React.FC = () => {
	const { handleFilterSelection, handleClearFilters } = useDashboardContext();
	const [form] = useForm();
	const [sectorId, setSectorId] = useState('');
	const [workstationId, setWorkstationId] = useState('');
	const [lineId, setLineId] = useState('');
	const [clearFilter, setClearFilter] = useState(false);
	const [stressLevel, setStressLevel] = useState<number[]>([]);

	function handleFormValuesChange(changedValues: any) {
		const formFieldName = Object.keys(changedValues)[0];

		if (formFieldName === 'sector') {
			setSectorId(changedValues[formFieldName]);
		}
		if (formFieldName === 'line') {
			setLineId(changedValues[formFieldName]);
		}
		if (formFieldName === 'workstation') {
			setWorkstationId(changedValues[formFieldName]);
		}
	}

	function handleSectorChange(sector: string) {
		setSectorId(sector);
	}

	function handleLineChange(line: string) {
		setLineId(line);
	}

	function handleWorkstationChange(workstationChanged: string) {
		setWorkstationId(workstationChanged);
	}

	function handleSelectStressLevel(level: number) {
		setClearFilter(false);
		const selected = stressLevel.includes(level) ? removeStressLevel(level) : addStressLevel(level);
		setStressLevel(selected);
	}

	function addStressLevel(level: number) {
		const levels = [...stressLevel];
		levels.push(level);

		return levels;
	}

	function removeStressLevel(level: number) {
		const levels = [...stressLevel];
		const index = levels.indexOf(level);
		levels.splice(index, 1);

		return levels;
	}

	function resetStates() {
		form.resetFields();
		setSectorId('');
		setLineId('');
		setWorkstationId('');
		setStressLevel([]);
		handleClearFilters();
		setClearFilter(true);
	}

	function handleFilter() {
		handleFilterSelection({
			lineId,
			sectorId,
			stressLevel,
			workstationId
		});
	}

	return (
		<DashboardCard>
			<Form onValuesChange={handleFormValuesChange} form={form} layout="vertical" style={{ minHeight: '500px' }}>
				<Row justify="end">
					<Col>
						<ClearFilter onClick={resetStates}>{I18n.get('Clear')}</ClearFilter>
					</Col>
				</Row>
				<Row gutter={[8, 12]}>
					<Col span={24}>
						<FilterBySector onSectorChange={handleSectorChange} />
					</Col>
					<Col span={24}>
						<FilterByLine onLineChange={handleLineChange} sectorId={sectorId} />
					</Col>
					<Col span={24}>
						<FilterByWorkstation onWorkstationChange={handleWorkstationChange} lineId={lineId} />
					</Col>
					<Col span={24}>
						<FilterByStressLevel onSelectStressLevel={handleSelectStressLevel} clearFilter={clearFilter} />
					</Col>
					<Col span={24}>
						<Button
							disabled={!lineId && !sectorId && stressLevel.length === 0 && !workstationId}
							onClick={handleFilter}
							icon={<SearchOutlined />}
							type="primary"
						>
							{I18n.get('Search')}
						</Button>
					</Col>
				</Row>
			</Form>
		</DashboardCard>
	);
};
