import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Form, message } from 'antd';
import { I18n } from '@aws-amplify/core';

import Api from '@/services/api';
import { CreateModal } from './CreateModal';
import { ListItens, EmptyList } from './List';

import { CreateForm } from './CreateForm';

export function Organization({ data, onSelect, visibleCreate, closeCreate, filtered, specialPlan }) {
	const [form] = Form.useForm();
	const queryClient = useQueryClient();

	const createOrganization = useMutation((values) => Api.post('/organization/create', { ...values, url_logo: '' }), {
		onError: (err) => {
			const response = err.response.data.errors[0];
			const errorMessage = `${response.msg}: ${response.param}`;
			message.error(I18n.get(errorMessage));
		},
		onSuccess: (organization) => {
			queryClient.invalidateQueries(['organizations']);
			closeCreate();
			onSelect(organization.data);
			message.success(I18n.get('Company created successfully'));
		}
	});

	function onSubmit() {
		form.validateFields().then((values) => {
			createOrganization.mutate(values);
		});
	}

	if (data?.length === 0 && !filtered) {
		return (
			<>
				<EmptyList description={I18n.get('Welcome to Kinebot, create your first company')} />
				<div style={{ padding: '10px 20px 0' }}>
					<CreateForm form={form} type="organization" />
					<Button
						type="primary"
						onClick={onSubmit}
						disabled={specialPlan}
						loading={createOrganization.isLoading}
					>
						{I18n.get('Create company')}
					</Button>
				</div>
			</>
		);
	}

	return (
		<div>
			<CreateModal
				form={form}
				loading={createOrganization.isLoading}
				type="organization"
				onSubmit={onSubmit}
				onCancel={closeCreate}
				visible={visibleCreate}
			/>
			<ListItens data={data} onAction={onSelect} />
		</div>
	);
}
