import React from 'react';
import { Row, Col, Form, Input, DatePicker } from 'antd';

import { usePreliminaryAnalysis } from '@/components/views/PreliminaryAnalysis/context';
import { useApplicationContext } from '@/context/v1/Application/context';
import { Title, Paragraph, Text } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';

import { Workstations } from './Workstations';
import { Sectors } from './Sectors';
import { Lines } from './Lines';

export const Informations: React.FC = () => {
	const { organization } = useApplicationContext();

	const { file } = usePreliminaryAnalysis();

	return (
		<Row gutter={[0, 10]}>
			<Col span={12} offset={4}>
				<Title level={4}>Informations</Title>
			</Col>
			<Col span={12} offset={4}>
				<Paragraph>Enter the data related to the selected video below</Paragraph>
			</Col>
			<Col span={12} offset={4}>
				<Text strong style={{ display: 'block', padding: '5px 0' }}>
					{I18n.get('Company')}: {organization?.name}
				</Text>
				<Text strong style={{ display: 'block', padding: '5px 0' }}>
					{I18n.get('File')}: {file?.original_name}
				</Text>
			</Col>
			<Col span={12} offset={4}>
				<Row gutter={[10, 0]}>
					<Sectors />
					<Lines />
					<Workstations />
					<Col span={12}>
						<Form.Item
							name={['preliminary_analysis', 'activity_name']}
							label={I18n.get('Activity')}
							rules={[
								() => ({
									validator(_, value) {
										if (!value) {
											return Promise.reject(I18n.get('Insert the activity'));
										}

										return Promise.resolve();
									}
								})
							]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name={['preliminary_analysis', 'role_name']}
							label={I18n.get('Responsibility')}
							rules={[
								() => ({
									validator(_, value) {
										if (!value) {
											return Promise.reject(I18n.get('Insert the responsibility'));
										}

										return Promise.resolve();
									}
								})
							]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label={I18n.get('Collection date')}
							name={['preliminary_analysis', 'collection_date']}
							rules={[
								() => ({
									validator(_, value) {
										if (!value) {
											return Promise.reject(I18n.get('Enter the collection date'));
										}

										return Promise.resolve();
									}
								})
							]}
						>
							<DatePicker
								format={'L'}
								style={{ width: '100%' }}
								placeholder={''}
								disabledDate={(current) => current && current.valueOf() >= Date.now()}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name={['preliminary_analysis', 'analyst_name']}
							label={I18n.get('Analyst')}
							rules={[
								() => ({
									validator(_, value) {
										if (!value) {
											return Promise.reject(I18n.get('Insert the analyst'));
										}

										return Promise.resolve();
									}
								})
							]}
						>
							<Input />
						</Form.Item>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
