import React from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { Form, Table, notification } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import type { TableRowSelection } from 'antd/lib/table/interface';

import { File } from '@/hooks/useGetFiles';
import { LIMIT, useSelectFilesContext } from '../../context';
import { PaginationCustom } from '@/components/ui/Pagination';
import { useJdsD92Context } from '@/components/views/JdsD92/context';
import * as S from '../styles';

export type TableValues = Pick<File, 'id' | 'original_name' | 'created_at' | 'organization_id' | 'company_id'>;

const { useFormInstance } = Form;

export function FileTable() {
	const { allFiles, loadingAllFiles, page, selectedRowKeys, handlePage, handlingSelectRowKeys } =
		useSelectFilesContext();
	const { jdsD92 } = useJdsD92Context();
	const { setFieldValue } = useFormInstance();

	const columns: ColumnsType<TableValues> = [
		{
			align: 'center',
			key: 'original_name',
			title: I18n.get('Files'),
			className: 'file-columns',
			dataIndex: 'original_name'
		},
		{
			width: '20%',
			align: 'center',
			key: 'createdAt',
			responsive: ['lg'],
			dataIndex: 'createdAt',
			title: I18n.get('Upload date'),
			render: (date: string) => {
				return moment(date).format('L');
			}
		}
	];

	const rowSelection: TableRowSelection<TableValues & { createdAt?: string }> = {
		selectedRowKeys,
		preserveSelectedRowKeys: true,
		onChange: (selectedRowKeys: React.Key[]) => {
			if (jdsD92?.is_completed) {
				return;
			}
			const file = allFiles?.rows?.find((f) => f.id === selectedRowKeys[0]);
			setFieldValue(['file'], file);

			handlingSelectRowKeys(selectedRowKeys);
			setFieldValue(['selectedRowKey'], selectedRowKeys);
		}
	};

	return (
		<S.Table span={23}>
			<Form.Item
				name="items"
				rules={[
					() => ({
						validator() {
							if (!selectedRowKeys || !selectedRowKeys.length) {
								notification.error({
									message: I18n.get("Some required step wasn't filled"),
									description: I18n.get('Select a file'),
									duration: 5
								});
								return Promise.reject();
							}
							return Promise.resolve();
						}
					})
				]}
			>
				<Table
					rowKey="id"
					size={'large'}
					columns={columns}
					pagination={false}
					dataSource={allFiles?.rows}
					className={jdsD92?.file_id ? 'disabled' : ''}
					rowSelection={{ type: 'radio', hideSelectAll: true, ...rowSelection }}
				/>
			</Form.Item>
			<PaginationCustom
				page={page}
				maxPage={LIMIT}
				setPage={handlePage}
				loading={loadingAllFiles}
				total={allFiles?.count || 0}
			/>
		</S.Table>
	);
}
