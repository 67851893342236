import React, { useContext, useEffect } from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Checkbox, Typography } from 'antd';

import { bodyPartsEnum } from '@/utils/enum';
import { AngleTimeContext } from '../context';

const { Title } = Typography;

export const BodyParts = () => {
	const { form, checkAllParts, setCheckAllParts, setParts, savedParts } = useContext(AngleTimeContext);
	const parts = bodyPartsEnum.map((item) => item.value);

	function setFieldsForm(key, value) {
		form.setFieldsValue({ [key]: value });
	}

	function handleSetBodyPartsCheckBox(values) {
		setFieldsForm('parts', values);
		setParts(values);
	}

	function setValuesForm(checked, bodyParts) {
		checked ? handleSetBodyPartsCheckBox(bodyParts) : handleSetBodyPartsCheckBox([]);
		setCheckAllParts(checked);
	}

	function onCheckAllChange(e) {
		const checked = e.target.checked;
		setValuesForm(checked, parts);
	}

	function handleIsSelectedAllParts() {
		savedParts.length === parts.length && setFieldsForm('all_parts', true);
	}

	useEffect(() => {
		if (savedParts.length > 0) {
			setFieldsForm('parts', savedParts);
			setParts(savedParts);
			handleIsSelectedAllParts();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [savedParts]);

	return (
		<Row justify="center" gutter={[5, 20]}>
			<Col sm={18}>
				<Title level={5}>{I18n.get('Select the body parts you are interested in')}</Title>
			</Col>
			<Col sm={18}>
				<Form.Item
					name="parts"
					label={I18n.get('Body parts')}
					rules={[
						{
							required: true,
							message: I18n.get('Enter the workstation')
						}
					]}
				>
					<Checkbox.Group style={{ width: '100%' }}>
						<Row>
							{bodyPartsEnum.map((item) => (
								<Col span={8} key={item.id}>
									<Checkbox value={item.value}>{I18n.get(item.name)}</Checkbox>
								</Col>
							))}
						</Row>
					</Checkbox.Group>
				</Form.Item>
			</Col>
			<Col sm={18}>
				<Form.Item name="all_parts" valuePropName="checked">
					<Checkbox value="all" onChange={onCheckAllChange} checked={checkAllParts}>
						<a href={undefined}>{I18n.get('Select all')}</a>
					</Checkbox>
				</Form.Item>
			</Col>
		</Row>
	);
};
