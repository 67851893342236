import React, { ReactElement, useState } from 'react';
import { Button, Divider, Form, Input, Select, SelectProps } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { I18n } from '@aws-amplify/core';

import { EvaluatorDTO } from '@/components/ui/Inputs/types/response/evaluator';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useCreateEvaluator } from '@/hooks/v1/useEvaluatorMutations';

import { CreateEvaluatorRequest } from '../types/request/evaluator';
import { DefaultOptionType } from 'antd/lib/select';

const { useFormInstance } = Form;

type EvaluatorSelectProps = {
	loading: boolean;
	disabled: boolean;
	fieldName: NamePath;
	evaluatorId?: string;
	allowCreation?: boolean;
	handleClearEvaluators?: () => void;
	evaluators: EvaluatorDTO[] | undefined;
	handleSelectEvaluator?: (id: string) => void;
};

type OptionType = { label: string; value: string } | undefined;

export const EvaluatorSelect: React.FC<EvaluatorSelectProps & SelectProps> = ({
	loading,
	disabled,
	fieldName,
	evaluators,
	evaluatorId,
	allowCreation = true,
	handleSelectEvaluator,
	handleClearEvaluators,
	...restProps
}) => {
	const [evaluatorName, setEvaluatorName] = useState('');

	const { company, organization } = useApplicationContext();
	const { validateFields, setFieldValue } = useFormInstance();

	const { mutateAsync: createEvaluator, isLoading } = useCreateEvaluator();

	async function onCreateEvaluator() {
		const body: CreateEvaluatorRequest = {
			organization_id: organization.id,
			company_id: company.id,
			name: evaluatorName
		};

		const created = await createEvaluator({ body });
		const evaluatorId = created?.data?.id;
		if (evaluatorId) {
			setFieldValue(fieldName, evaluatorId);
		}
		setEvaluatorName('');
	}

	async function handleChange(id: string) {
		if (handleSelectEvaluator) {
			handleSelectEvaluator(id);
			await validateFields([fieldName]);
		}
	}

	function handleClear() {
		if (handleClearEvaluators) {
			handleClearEvaluators();
		}
	}

	function handleEvaluatorNameChange(name: string) {
		setEvaluatorName(name);
	}

	function filterOption(option: OptionType | DefaultOptionType | undefined, input: string): boolean {
		return (option?.label?.toLocaleString().toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function showDropdownRender(menu: ReactElement): JSX.Element {
		if (!allowCreation) {
			return <div>{menu}</div>;
		}
		return (
			<div>
				{menu}
				<Divider style={{ margin: '4px 0' }} />
				<div
					style={{
						display: 'flex',
						flexWrap: 'nowrap',
						padding: 8
					}}
				>
					<Input
						style={{ flex: 'auto', borderRadius: '7px' }}
						value={evaluatorName}
						onChange={(e) => handleEvaluatorNameChange(e.target.value)}
					/>
					<Button
						onClick={onCreateEvaluator}
						type="link"
						loading={isLoading}
						disabled={!evaluatorName || evaluatorName.length < 3}
					>
						{I18n.get('Add item')}
					</Button>
				</div>
			</div>
		);
	}

	return (
		<Select
			{...restProps}
			showSearch
			allowClear
			value={evaluatorId}
			onClear={handleClear}
			placeholder={I18n.get('Evaluator')}
			filterOption={(input, option) => filterOption(option, input)}
			loading={loading}
			disabled={disabled}
			options={evaluators?.map(({ id, name }) => ({ label: name, value: id }))}
			onChange={(value: string) => handleChange(value)}
			dropdownRender={(menu) => showDropdownRender(menu)}
		/>
	);
};
