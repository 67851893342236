import React from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { I18n } from '@aws-amplify/core';

import { Confirm, NameInput } from '../styles';

const { useFormInstance, useWatch } = Form;

interface EditorProps {
	fieldName: NamePath;
	onUpdate(): Promise<void>;
	onEditingChange: () => void;
	parentId: string | undefined;
	currentName: string | undefined;
}

export const Editor: React.FC<EditorProps> = ({ onUpdate, parentId, fieldName, currentName, onEditingChange }) => {
	const form = useFormInstance();
	const editingSectorName = useWatch(fieldName, form);

	function handleCancel() {
		onEditingChange();
	}

	function handleUpdate() {
		onEditingChange();
		onUpdate();
	}

	return (
		<Col span={24}>
			<Row align="bottom" justify="space-between" gutter={[8, 0]}>
				<Col span={20}>
					<NameInput
						name={fieldName}
						label={I18n.get('Edit')}
						labelCol={{ span: 24 }}
						style={{ margin: '0' }}
						rules={[
							{
								message: I18n.get('Please, enter a valid name')
							}
						]}
					>
						<Input
							style={{
								flex: 'auto'
							}}
							value={currentName}
						/>
					</NameInput>
				</Col>
				<Col span={4}>
					<Row align="bottom" justify="space-between">
						<Col span={12}>
							<Button
								ghost
								danger
								size="middle"
								type="primary"
								onClick={handleCancel}
								icon={<CloseOutlined />}
								disabled={!parentId}
							/>
						</Col>
						<Confirm span={12}>
							<Button
								ghost={true}
								size="middle"
								type="primary"
								onClick={handleUpdate}
								icon={<CheckOutlined />}
								disabled={!editingSectorName || !parentId || editingSectorName === currentName}
							/>
						</Confirm>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};
