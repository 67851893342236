import React from 'react';
import moment from 'moment';
import { Col, Row } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Title } from '@/components/Typography';

import { Topic, Value } from './styles';
import { SeraResultInformations } from '../hooks/types';

interface InformationsProps {
	review: number;
	informations: SeraResultInformations;
}

export const Informations: React.FC<InformationsProps> = ({ review, informations }) => {
	const {
		id,
		line_name,
		cycle_name,
		sector_name,
		report_name,
		company_name,
		evaluator_name,
		workstation_name,
		last_review_date,
		responsible_user,
		organization_name,
		original_version_date
	} = informations;

	function setReviewText(review: number, last_review_date: string): React.ReactNode {
		return (review <= 0 ? I18n.get('Original') : `${I18n.get('Review')} ${review}`) + ` (${last_review_date})`;
	}

	return (
		<Col key={id} span={24} offset={1}>
			<Row>
				<Title level={5}>Informations</Title>
			</Row>
			<Row justify="space-between" align="middle" style={{ marginTop: '0.5rem' }}>
				<Col span={8}>
					<Row gutter={[0, 3]}>
						<Col span={24}>
							<Topic>{I18n.get('Organization')}:</Topic>
							<Value>{organization_name}</Value>
						</Col>
						<Col span={24}>
							<Topic>{I18n.get('Company')}:</Topic>
							<Value>{company_name}</Value>
						</Col>
						<Col span={24}>
							<Topic>{I18n.get('Sector')}:</Topic>
							<Value>{sector_name}</Value>
						</Col>
						<Col span={24}>
							<Topic>{I18n.get('Line')}:</Topic>
							<Value>{line_name}</Value>
						</Col>
					</Row>
				</Col>
				<Col span={8}>
					<Row gutter={[0, 3]}>
						<Col span={24}>
							<Topic>{I18n.get('Workstation')}:</Topic>
							<Value>{workstation_name}</Value>
						</Col>
						<Col span={24}>
							<Topic>{I18n.get('Cycle')}:</Topic>
							<Value>{cycle_name}</Value>
						</Col>
						{evaluator_name && (
							<Col span={24}>
								<Topic>{I18n.get('Evaluator')}:</Topic>
								<Value>{evaluator_name}</Value>
							</Col>
						)}
						<Col span={24}>
							<Topic>{I18n.get('Responsible')}:</Topic>
							<Value>{responsible_user}</Value>
						</Col>
					</Row>
				</Col>
				<Col span={7}>
					<Row gutter={[0, 3]}>
						<Col span={24}>
							<Topic>{I18n.get('Report name')}:</Topic>
							<Value>{report_name}</Value>
						</Col>
						<Col span={24}>
							<Topic>{I18n.get('Original version date')}:</Topic>
							<Value>{moment(original_version_date).format('L')}</Value>
						</Col>
						<Col span={24}>
							<Topic>{I18n.get('Last review')}:</Topic>
							<Value>{moment(last_review_date).format('L')}</Value>
						</Col>
						<Col span={24}>
							<Topic>{I18n.get('Version')}:</Topic>
							<Value>{setReviewText(review, moment(last_review_date).format('L'))}</Value>
						</Col>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};
