import React from 'react';
import { Title } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';
import { Col, Divider, Row } from 'antd';

import { Description, RPNScore } from './styles';
import { ChangedField, StepKeysHistory } from '@/hooks/useGetCustomReportReviewHistory';
import { riskColors } from '@/utils/riskRange';

interface FieldsComparisonProps {
	stepKeyHistory: StepKeysHistory;
	fields: ChangedField[];
}

export const FieldsComparison: React.FC<FieldsComparisonProps> = ({ stepKeyHistory: task, fields }) => {
	const reviewedColor = riskColors[task.rpn.reviewed.result as number];
	const previousColor = riskColors[task.rpn.previous.result as number];

	function mapFieldsComparison(value: string | string[]) {
		return Array.isArray(value) ? value?.map((field) => I18n.get(field) + `; `) : I18n.get(value);
	}

	function renderFieldsChanged(): React.ReactNode {
		return fields.map(({ field_name, previous_field_value, reviewed_field_value }) => {
			return (
				<Col xs={24} key={task.id} style={{ marginBottom: '1rem' }}>
					<Row>
						<Col style={{ height: '50px' }}>
							<Divider style={{ height: '100%' }} type="vertical" />
						</Col>
						<Col span={7}>
							<Row align="middle">
								<Title style={{ margin: '0 0 0.75rem 0', fontSize: '0.8rem' }}>
									{I18n.get('Changed field')}
								</Title>
							</Row>
							<Row align="middle">
								<Description>{I18n.get(field_name)}</Description>
							</Row>
						</Col>
						<Col style={{ height: '50px' }}>
							<Divider style={{ height: '100%' }} type="vertical" />
						</Col>
						<Col span={7}>
							<Row align="middle">
								<Title style={{ margin: '0 0 0.75rem 0', fontSize: '0.8rem' }}>
									{I18n.get('Original value')}
								</Title>
							</Row>
							<Row align="middle">
								<Description>{mapFieldsComparison(previous_field_value)}</Description>
							</Row>
						</Col>
						<Col style={{ height: '50px' }}>
							<Divider style={{ height: '100%' }} type="vertical" />
						</Col>
						<Col span={7}>
							<Row align="middle">
								<Title style={{ margin: '0 0 0.75rem 0', fontSize: '0.8rem' }}>
									{I18n.get('Reviewed value')}
								</Title>
							</Row>
							<Row align="middle">
								<Description>{mapFieldsComparison(reviewed_field_value)}</Description>
							</Row>
						</Col>
					</Row>
				</Col>
			);
		});
	}

	return (
		<Row>
			<Col xs={24}>
				<Row align="middle" style={{ marginTop: '1rem' }}>
					{fields?.length > 0 && renderFieldsChanged()}
				</Row>
			</Col>
			<Col xs={24}>
				<Row align="middle">
					<Col xs={24}>
						<Row justify="end">
							<Col xs={10} style={{ marginRight: '1rem' }}>
								<Title level={5}>{I18n.get('RPN')}</Title>
							</Col>
						</Row>
						<Row justify="end">
							<Col xs={24}>
								<Row justify="end" align="middle">
									{!fields?.length && (
										<Col span={10}>{I18n.get('No fields were changed')}</Col>
									)}
									<Col style={{ height: '50px' }}>
										<Divider style={{ height: '100%' }} type="vertical" />
									</Col>
									<Col span={5}>
										<Row align="middle">
											<Title style={{ margin: 0, fontSize: '0.8rem' }}>
												{I18n.get('Original')}
											</Title>
										</Row>
										<Row align="middle">
											<RPNScore priority={previousColor}>{task.rpn.previous.score || '-'}</RPNScore>
										</Row>
									</Col>
									<Col style={{ height: '50px' }}>
										<Divider style={{ height: '100%' }} type="vertical" />
									</Col>
									<Col span={5}>
										<Row align="middle">
											<Title style={{ margin: 0, fontSize: '0.8rem' }}>
												{I18n.get('Reviewed')}
											</Title>
										</Row>
										<Row align="middle">
											<RPNScore priority={reviewedColor}>{task.rpn.reviewed.score || '-'}</RPNScore>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
