import React, { createContext, useContext, useState } from 'react';
import { Form } from 'antd';
import { useParams } from 'react-router-dom';

import { useGetResult } from '@/hooks/useGetResultBackCompressiveForceEstimation';
import { useGetReport } from '@/hooks/useGetBackCompressiveForceEstimation';
import { useApplicationContext } from '@/context/v1/Application/context';
import { Informations } from './Report/Informations';
import { LoadingSkeleton } from './LoadingSkeleton';
import { Variables } from './Report/Variables';
import { Text } from '@/components/Typography';
import { Result } from './Report/Result';
import type {
	BackCompressiveForceEstimationProviderProps,
	BackCompressiveForceEstimationRouteParams,
	BackCompressiveForceEstimationReportDTO,
	InitialValuesMapperInterface,
	SystemOfUnitMeasurements,
	InitialValues,
	FormStep,
	Context,
	Methods,
	States
} from './types';

const { useForm } = Form;
const BackCompressiveForceEstimationContext = createContext<Context>({} as Context);

const steps: FormStep[] = [
	{
		icon: null,
		title: 'Informations',
		component: <Informations />
	},
	{
		icon: null,
		title: 'Variables',
		component: <Variables />
	},
	{
		icon: null,
		title: 'Result',
		component: <Result />
	}
];

class InitialValuesMapper implements InitialValuesMapperInterface {
	constructor(private backCompressiveForceEstimation: BackCompressiveForceEstimationReportDTO | undefined) {}

	public mapInitialValues(): InitialValues | undefined {
		if (!this.backCompressiveForceEstimation) {
			return undefined;
		}

		const { comment, line_id, sector_id, company_id, workstation_id, system_of_units } =
			this.backCompressiveForceEstimation;

		return {
			line_id,
			comment,
			sector_id,
			company_id,
			workstation_id,
			system_of_units_id: system_of_units?.id,
			back_compressive_force_estimation_inputs:
				this.backCompressiveForceEstimation.back_compressive_force_estimation_inputs
		};
	}
}

const measurements: { imperial: SystemOfUnitMeasurements; metric: SystemOfUnitMeasurements } = {
	imperial: {
		mass: 'lb',
		length: 'in',
		decimal_separator: '.'
	},
	metric: {
		mass: 'kg',
		length: 'cm',
		decimal_separator: ','
	}
};

export function BackCompressiveForceEstimationProvider({ children }: BackCompressiveForceEstimationProviderProps) {
	const [form] = useForm();
	const { file_id } = useParams<BackCompressiveForceEstimationRouteParams>();
	const { organization, company } = useApplicationContext();

	const [updatedBackCompressiveForceEstimation, setUpdatedBackCompressiveForceEstimation] = useState<
		BackCompressiveForceEstimationReportDTO | undefined
	>(undefined);

	const {
		data: backCompressiveForceEstimation,
		isFetching: fetchingReport,
		isError: errorGettingReport
	} = useGetReport({
		organization_id: organization?.id,
		company_id: company?.id,
		file_id
	});
	const {
		data: backCompressiveForceEstimationResult,
		isInitialLoading: fetchingResult,
		isError: errorGettingResult
	} = useGetResult({
		organization_id: organization?.id,
		company_id: company?.id,
		file_id,
		finished: isReportFinished()
	});

	const initialValuesMapper = new InitialValuesMapper(backCompressiveForceEstimation);
	const initialValues = initialValuesMapper.mapInitialValues();

	function isReportFinished(): boolean | undefined {
		return !!(
			updatedBackCompressiveForceEstimation?.total_compressive_force_result ||
			backCompressiveForceEstimation?.total_compressive_force_result
		);
	}

	function handleUpdateReport(report: BackCompressiveForceEstimationReportDTO) {
		setUpdatedBackCompressiveForceEstimation(report);
	}

	const loading = fetchingReport || fetchingResult;

	if (errorGettingResult) {
		return <Text>Oops... Something went wrong!</Text>;
	}

	if (loading) {
		return <LoadingSkeleton loading={loading} />;
	}

	const states: States = {
		steps,
		file_id,
		errorGettingReport,
		backCompressiveForceEstimationResult,
		gettingReport: loading,
		backCompressiveForceEstimation: updatedBackCompressiveForceEstimation ?? backCompressiveForceEstimation,
		measurements:
			measurements[
				(updatedBackCompressiveForceEstimation ?? backCompressiveForceEstimation)?.system_of_units?.name
			] || null
	};

	const methods: Methods = {
		handleUpdateReport
	};

	const context: Context = {
		...states,
		...methods
	};

	return (
		<BackCompressiveForceEstimationContext.Provider value={context}>
			<Form form={form} initialValues={initialValues}>
				{children}
			</Form>
		</BackCompressiveForceEstimationContext.Provider>
	);
}

export function useBackCompressiveForceEstimationContext() {
	const context = useContext(BackCompressiveForceEstimationContext);
	return context;
}
