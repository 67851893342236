import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, TableColumnsType } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';

import { FrequencyLibertyMutual, LibertyMutualResponse } from '@/hooks';
import { Text } from '@/components/Typography';
import * as S from './styles';

interface LibertyMutualProps {
	data: LibertyMutualResponse[];
}

interface DataType {
	id: string;
	unit: string;
	key: React.Key;
	task_name: string;
	system_of_units: string;
	object_weight?: string | null;
	hand_coupling?: string | null;
	initial_force?: string | null;
	percentile_man?: string | null;
	sustained_force?: string | null;
	percentile_woman?: string | null;
	frequency: FrequencyLibertyMutual;
	horizontal_distance?: string | null;
	vertical_hand_height?: string | null;
	percentile_man_initial?: string | null;
	percentile_man_sustain?: string | null;
	percentile_woman_initial?: string | null;
	percentile_woman_sustain?: string | null;
}

export const LibertyMutualResults: React.FC<LibertyMutualProps> = ({ data }) => {
	const columns: ColumnsType<DataType> = [
		{
			title: I18n.get('Movement'),
			dataIndex: 'task_name',
			key: 'task_name',
			width: '70%',
			render: (task_name) => I18n.get(task_name)
		},
		{
			title: '',
			dataIndex: 'conclusion',
			key: 'conclusion'
		}
	];

	function conclusionText(values: LibertyMutualResponse) {
		return (
			<Row>
				<Col span={24}>
					<Text>{I18n.get('The movement is considered safe for')}</Text>
				</Col>
				<Col span={24}>
					<Text strong>
						{`${values.percentile_man} ${I18n.get('of man')} ${values.percentile_woman} ${I18n.get(
							'of woman'
						)}`}
					</Text>
				</Col>
			</Row>
		);
	}

	const addKeyInArray = data.map((item) => ({
		...item,
		key: 'liberty_mutual_first_row',
		conclusion: conclusionText(item)
	}));

	const dataColumn: DataType[] = [...addKeyInArray];

	return (
		<Row gutter={[0, 20]} justify="center" style={{ marginTop: '2rem' }}>
			{data.map((report, index) => (
				<Col span={24} key={report.id}>
					<S.LibertyMutualContainerFirstRow>
						<Table columns={columns} dataSource={[dataColumn[index]]} pagination={false} />
					</S.LibertyMutualContainerFirstRow>
					<S.LibertyMutualContainerSecondRow>
						<ExpandedTable infos={dataColumn[index]} />
					</S.LibertyMutualContainerSecondRow>
				</Col>
			))}
		</Row>
	);
};

type ExpandedTableType = {
	key?: React.Key;
	task_name: string;
	system_of_units: string;
	unit: string;
	frequency: FrequencyLibertyMutual;
	object_weight?: string | null;
	horizontal_distance?: string | null;
	vertical_hand_height?: string | null;
	hand_coupling?: string | null;
	initial_force?: string | null;
	sustained_force?: string | null;
	percentile_man?: string | null;
	percentile_woman?: string | null;
	value?: string;
};
interface ExpandedTableProps {
	infos: ExpandedTableType;
}

const ExpandedTable: React.FC<ExpandedTableProps> = ({ infos }) => {
	const {
		task_name,
		system_of_units,
		unit,
		frequency,
		object_weight,
		horizontal_distance,
		vertical_hand_height,
		hand_coupling,
		initial_force,
		sustained_force,
		percentile_man,
		percentile_woman
	} = infos;

	const { interval, type, value } = frequency;

	const weight_value = {
		title: I18n.get('Weight'),
		dataIndex: 'object_weight',
		key: 'object_weight',
		width: hand_coupling ? '23.333%' : '35%'
	};

	const frequencyText = `${I18n.get('Frequency')} (${I18n.get(type)} ${I18n.get('per')} ${I18n.get(interval)})`;

	const frequency_value = {
		title: frequencyText,
		dataIndex: 'value',
		key: 'value'
	};

	const hand_coupling_value = {
		title: I18n.get('Hand coupling'),
		dataIndex: 'hand_coupling',
		key: 'hand_coupling',
		width: '23.333%'
	};

	const initial_force_value = {
		title: I18n.get('Initial force'),
		dataIndex: 'initial_force',
		key: 'initial_force',
		width: '23.333%'
	};
	const sustained_force_value = {
		title: I18n.get('Sustained force'),
		dataIndex: 'sustained_force',
		key: 'sustained_force',
		width: '23.333%'
	};

	const columns: TableColumnsType<ExpandedTableType> = [];

	object_weight && columns.push(weight_value);
	columns.push(frequency_value);
	hand_coupling && columns.push(hand_coupling_value);
	initial_force && columns.push(initial_force_value);
	sustained_force && columns.push(sustained_force_value);

	const score_columns: TableColumnsType<ExpandedTableType> = [
		...columns,
		{
			title: I18n.get('Men'),
			dataIndex: 'percentile_man',
			key: 'percentile_man',
			width: '15%',
			className: 'men-percentage'
		},
		{
			title: I18n.get('Women'),
			dataIndex: 'percentile_woman',
			key: 'percentile_woman',
			width: '15%',
			className: 'women-percentage'
		}
	];

	const data: ExpandedTableType[] = [
		{
			unit,
			value,
			frequency,
			initial_force,
			object_weight,
			percentile_man,
			sustained_force,
			system_of_units,
			percentile_woman,
			horizontal_distance,
			vertical_hand_height,
			task_name: I18n.get(task_name),
			key: 'liberty_mutual_second_row',
			hand_coupling: I18n.get(hand_coupling)
		}
	];

	return <Table columns={score_columns} dataSource={data} pagination={false} />;
};
