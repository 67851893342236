import Api from '@/services/api';
import { I18n } from '@aws-amplify/core';
import { message, notification } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { Activity } from '@/types';
import type { CreateActivityRequest } from '@/components/ui/Inputs/types/request/activity';

async function createActivity(body: CreateActivityRequest): Promise<Activity> {
	const { data } = await Api.post<Activity>('/activity', body);
	return data;
}

export const useCreateActivity = () => {
	const queryClient = useQueryClient();
	return useMutation((body: CreateActivityRequest) => createActivity(body), {
		onError: (err: any) => {
			notification.error({
				message: I18n.get(err?.response?.data?.message || 'Ops... something happened!')
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['get-activities']);
			message.success(I18n.get('Activity created successfully'));
		}
	});
};
