import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { useApplicationContext } from '@/context/v1/Application/context';
import { EvaluatorSelect } from '@/components/ui/Inputs/EvaluatorSelect';
import { useEvaluators } from '@/hooks/v1';
import { FormItem } from '../../../components/FormItem';

const { useFormInstance, useWatch } = Form;

interface EvaluatorProps {
	fieldName: string;
}

export const Evaluator: React.FC<EvaluatorProps> = ({ fieldName }) => {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();
	const { company, organization } = useApplicationContext();

	const evaluatorId = useWatch([fieldName, 'evaluator_id'], form);

	const {
		data: evaluators,
		isLoading,
		isFetching,
		isError
	} = useEvaluators({
		organization_id: organization?.id,
		company_id: company?.id
	});

	function handleEvaluatorSelect(id: string) {
		setFieldValue([fieldName, 'evaluator_id'], id);
	}

	function handleClearEvaluators() {
		setFieldValue([fieldName, 'evaluator_id'], undefined);
	}

	return (
		<Col xs={24} sm={24}>
			<FormItem
				isRequired
				title={'Evaluator'}
				namePath={[fieldName, 'evaluator_id']}
				errorMessage={I18n.get('Select the evaluator')}
			>
				<EvaluatorSelect
					size="large"
					allowCreation
					loading={isFetching}
					evaluators={evaluators}
					evaluatorId={evaluatorId}
					fieldName={[fieldName, 'evaluator_id']}
					disabled={isLoading || isFetching || isError}
					handleSelectEvaluator={handleEvaluatorSelect}
					handleClearEvaluators={handleClearEvaluators}
				/>
			</FormItem>
		</Col>
	);
};
