import React from 'react';
import { DatePicker, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

export const CreatedAt: React.FC = () => {
	return (
		<Form.Item name={['created_at']} label={I18n.get('Upload date')} labelCol={{ span: 24 }}>
			<DatePicker.RangePicker
				style={{ width: '100%' }}
				format="L"
				disabledDate={(current) => current && current.valueOf() >= Date.now()}
			/>
		</Form.Item>
	);
};
