import React from 'react';
import { Input } from 'antd';
import { I18n } from '@aws-amplify/core';
import { FormItem } from '@/components/CustomReports';

interface IntervieweeNameProps {
	fontSize?: string;
	isRequired?: boolean;
	fieldName: string;
}

export const IntervieweeName: React.FC<IntervieweeNameProps> = ({ fontSize, isRequired, fieldName }) => {
	return (
		<FormItem
			fontSize={fontSize}
			title="Interviewee name"
			isRequired={isRequired}
			namePath={[fieldName, 'interviewee_name']}
			errorMessage="Intervewed name cannot be empty"
		>
			<Input size="large" placeholder={I18n.get('Interviewee name')} />
		</FormItem>
	);
};
