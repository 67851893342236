import React, { useState, useEffect } from 'react';
import { Row, Col, Form, message, Input, Skeleton } from 'antd';
import { useParams } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';
import _ from 'lodash';

import { useApplicationContext } from '@/context/v1/Application/context';
import { UpdateBeraJobSummaryRequest } from '../hooks/types/request';
import { Title } from '@/components/Typography';
import { useComments } from './hooks';

const { TextArea } = Input;

type ResultsParams = {
	bera_job_summary_id: string;
};

interface CommentProps {
	isLoading: boolean;
	onCommentChange: (editingComment: boolean) => void;
}

export const Comment: React.FC<CommentProps> = ({ isLoading, onCommentChange }) => {
	const [text, setText] = useState<string | null>(null);
	const { bera_job_summary_id } = useParams<ResultsParams>();
	const { organization, company } = useApplicationContext();

	const [messageApi, contextHolder] = message.useMessage();

	const { mutate, isLoading: updatingComments } = useComments();

	const setComment = _.debounce((value) => {
		onCommentChange(true);
		setText(value);
	}, 1200);

	const onChange = (e: any) => setComment(e.target.value);

	const onSuccess = () => {
		messageApi.open({
			type: 'success',
			content: I18n.get('Comment updated successfully'),
			duration: 2.5
		});
	};

	const onError = () => {
		message.error(I18n.get('Fail to save the comments'));
	};

	const onSettled = () => onCommentChange(false);

	useEffect(() => {
		if (text !== null) {
			const parameters: UpdateBeraJobSummaryRequest = {
				organization_id: organization.id,
				company_id: company.id,
				id: bera_job_summary_id,
				comment: text
			};

			mutate(parameters, {
				onSuccess,
				onError,
				onSettled
			});
		}
	}, [text]);

	return (
		<Row gutter={[0, 24]}>
			<Col span={24}>
				<Row>
					<Col span={24}>
						<Title style={{ margin: 0 }} level={5}>
							Comment
						</Title>
						{contextHolder}
					</Col>
					<Col span={24} style={{ margin: '1rem 0' }}>
						{isLoading ? (
							<Skeleton.Input block active />
						) : (
							<Form.Item name="comment" labelCol={{ span: 24 }}>
								<TextArea
									rows={4}
									maxLength={500}
									showCount={true}
									onChange={onChange}
									disabled={updatingComments || isLoading}
								/>
							</Form.Item>
						)}
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
