import React from 'react';
import { Col, Image, Row } from 'antd';

import { KinebotLogoContainer, TitleContainer } from './styles';
import { I18n } from '@aws-amplify/core';

export const Header = () => {
	return (
		<Row>
			<Col span={24}>
				<Row align="middle" justify="space-between">
					<Col span={4}>
						<KinebotLogoContainer>
							<Image
								preview={{ visible: false, mask: false }}
								src="https://kinebot-statics.s3.amazonaws.com/kinebot_logo.png"
							/>
						</KinebotLogoContainer>
					</Col>
					<Col span={16}>
						<TitleContainer>
							<span>{I18n.get('Result')}</span>
							<span id="title">{I18n.get('Safety Ergonomic Risk Assessment (S.E.R.A.)')}</span>
						</TitleContainer>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
