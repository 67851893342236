import React, { useEffect, useState } from 'react';
import { Col, Form, InputNumber, Row, Slider } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import { AdditionalItemsResults, GetAdditionalToolScore, useCalculateAdditionalToolScore } from '@/hooks';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useStepKeysContext } from '../DefaultSelects/context';
import { fieldsUnitTexts } from './utils/FieldsUnitTexts';
import { ConditionCard } from '../../ConditionCard';
import { Text } from '@/components/Typography';
import { AditionalItemProps } from './types';

const { useFormInstance } = Form;
const { formatLabelWithUnit } = fieldsUnitTexts;

export const SliderOnly: React.FC<AditionalItemProps> = ({ additionalItem }) => {
	const { id, title_1, unit_1 } = additionalItem;

	const form = useFormInstance();
	const { formFieldName, stepKey } = useStepKeysContext();
	const { company, organization } = useApplicationContext();

	const [timer, setTimer] = useState<NodeJS.Timeout>();
	const [sliderValue, setSliderValue] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const payload: GetAdditionalToolScore = {
		value_1: sliderValue,
		company_id: company?.id,
		organization_id: organization?.id,
		custom_report_step_key_id: stepKey.id
	};

	const { data: additionalToolScore, isFetching: isFetchingAdditionalToolScore } =
		useCalculateAdditionalToolScore(payload);

	const sliderLabel = formatLabelWithUnit(title_1, unit_1);

	useEffect(() => {
		const result: AdditionalItemsResults[] = [
			{
				id: uuidv4(),
				value_1: sliderValue,
				result: additionalToolScore?.result ?? 0
			}
		];

		form.setFieldValue([...formFieldName, 'additional_items', id, 'results'], result);
	}, [additionalToolScore]);

	function handleDebounceSetSliderValue(value: number): void {
		clearTimeout(timer);

		const debounce = setTimeout(() => {
			setSliderValue(value);
			setIsLoading(false);
		}, 1200);

		setTimer(debounce);
	}

	function handleAfterChange(value: number | null): void {
		setIsLoading(true);
		handleDebounceSetSliderValue(value ?? 0);
	}

	return (
		<Row gutter={[0, 5]}>
			<Row gutter={[5, 0]}>
				<Col>
					<Text strong>{sliderLabel}</Text>
				</Col>
			</Row>
			<Col span={20}>
				<Row justify="space-between">
					<Col span={18}>
						<Form.Item name={[...formFieldName, 'additional_items', id, 'value_1']}>
							<Slider min={0} max={10} step={0.5} onAfterChange={handleAfterChange} />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item name={[...formFieldName, 'additional_items', id, 'value_1']}>
							<InputNumber min={0} max={10} step={0.5} style={{ width: '100%' }} />
						</Form.Item>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<ConditionCard
					result={additionalToolScore.result}
					isLoading={isLoading || isFetchingAdditionalToolScore}
				/>
			</Col>
		</Row>
	);
};
