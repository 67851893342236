import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Tree, Form, Input, Select, Button, message } from 'antd';
import { useIsMounted } from '@/hooks/useIsMounted';
import { I18n } from '@aws-amplify/core';
import Api from '@/services/api';

export function EditUser({ user, visible, onClose, triggerReload }) {
	const isMounted = useIsMounted();
	const [form] = Form.useForm();

	const [treeData, setTreeData] = useState([]);
	const [expandedKeys, setExpandedKeys] = useState([]);
	const [checkedKeys, setCheckedKeys] = useState([]);
	const [selectedKeys, setSelectedKeys] = useState([]);
	const [autoExpandParent, setAutoExpandParent] = useState(true);

	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [errors, setErrors] = useState(null);

	const onCloseModal = () => {
		onClose();
		form.resetFields(['name', 'email', 'role']);
		setTreeData([]);
	};

	const selectedChildren = (child, id) => {
		if (child.key === id) {
			return {
				company_id: child.key,
				company_name: child.title,
			};
		}
	};

	const formatPermissions = (checkedList) => {
		let permissions = [];

		treeData.forEach((object) => {
			let result = {
				organization_id: object.key,
				organization_name: object.title,
				companies: [],
			};

			const filterUndefined = (permission) =>
				permission !== undefined && result.companies.push(permission);

			checkedList.map((id) =>
				object.children
					.map((child) => selectedChildren(child, id))
					.forEach(filterUndefined)
			);

			if (result.companies.length > 0) {
				permissions.push(result);
			}
		});

		return permissions;
	};

	const getAllPermission = useCallback(() => {
		if (visible) {
			Api.get('/user_management/permissions_list')
				.then((res) => {
					let tree = [];
					if (isMounted()) {
						res.data.forEach((item) => {
							let permission = {
								title: item.organization_name,
								key: item.organization_id,
								children: [
									...item.companies?.map((company) => ({
										key: company.company_id,
										title: company.company_name,
									})),
								],
							};
							tree.push(permission);
						});
						setTreeData(tree);
					}
				})
				.catch((err) => {});
		}
	}, [visible]);

	const getPermissionUser = useCallback(() => {
		if (treeData.length > 0 && user?.id && visible) {
			Api.post('/user_management/user/permissions', { user_id: user.id })
				.then((res) => {
					if (isMounted()) {
						let expandKeys = [],
							checked = [];
						res.data.forEach((value) => {
							if (value.companies?.length > 0) {
								expandKeys.push(value.organization_id);
								value.companies.forEach((company) =>
									checked.push(company.company_id)
								);
							}
						});
						setExpandedKeys(expandKeys);
						setCheckedKeys(checked);
					}
				})
				.catch((err) => {});
		}
	}, [treeData, visible, user]);

	const setFormData = useCallback(() => {
		if (!visible || !user) return;
		form.setFieldsValue({
			name: user.name,
			email: user.email,
			role: user.role,
		});
	}, [visible, user]);

	useEffect(() => {
		getAllPermission();
		setFormData();
	}, [visible, user]);

	useEffect(() => {
		if (treeData.length > 0) {
			getPermissionUser();
		}
	}, [treeData]);

	useEffect(() => {
		if (errors) {
			const result = errors.response?.data?.message || errors.message;
			const description = I18n.get(result);
			message.error(description);
			setErrors(null);
		}
	}, [errors]);

	const onExpand = (expandedKeysValue) => {
		setExpandedKeys(expandedKeysValue);
		setAutoExpandParent(false);
	};

	const onCheck = (checkedKeysValue) => {
		setCheckedKeys(checkedKeysValue);
	};

	const onSelect = (selectedKeysValue, info) => {
		setSelectedKeys(selectedKeysValue);
	};

	const onEditUser = (body) => {
		Api.put('/user_management/user_update', body)
			.then(setLoadingSubmit(true))
			.then((res) => {
				onCloseModal();
				message.success(I18n.get('User changed successfully'));
				form.resetFields();
				triggerReload();
			})
			.catch((err) => setErrors(err))
			.finally(() => setLoadingSubmit(false));
	};

	const onSave = ({ name, email, role }) => {
		const body = {
			id: user.id,
			name: name,
			email: email,
			role: role,
			permissions: formatPermissions(checkedKeys),
		};

		onEditUser(body);
	};

	const onSubmit = () => {
		form.validateFields()
			.then(onSave)
			.catch((errInfo) => {});
	};

	return (
		<Modal
			title={I18n.get('Edit user')}
			visible={visible}
			onCancel={onCloseModal}
			destroyOnClose={true}
			onOk={onSubmit}
			footer={[
				<Button key="cancel" onClick={onCloseModal}>
					{I18n.get('Cancel')}
				</Button>,
				<Button
					type="primary"
					key="save"
					onClick={onSubmit}
					loading={loadingSubmit}
				>
					{I18n.get('Save')}
				</Button>,
			]}
		>
			<Form form={form} layout="vertical" name="update_user">
				<Form.Item
					name="name"
					label={I18n.get('Name')}
					rules={[
						{
							required: true,
							message: I18n.get('Please enter a valid name'),
						},
					]}
				>
					<Input disabled placeholder={I18n.get('Enter a name')} />
				</Form.Item>
				<Form.Item
					name="email"
					label={I18n.get('E-mail')}
					rules={[
						{
							type: 'email',
							message: I18n.get(
								'Please enter a valid e-mail address'
							),
						},
						{
							required: true,
							message: I18n.get('Enter an e-mail'),
						},
					]}
				>
					<Input disabled placeholder={I18n.get('Enter an e-mail')} />
				</Form.Item>
				<Form.Item
					name="role"
					label={I18n.get('Permission')}
					rules={[
						{
							required: true,
							message: I18n.get('Enter a permission'),
						},
					]}
				>
					<Select
						showSearch
						style={{ width: '100%' }}
						placeholder={I18n.get('Select a permission')}
						optionFilterProp="children"
					>
						<Select.Option value="USER">
							{I18n.get('User')}
						</Select.Option>
						<Select.Option value="SUPERVISOR">
							{I18n.get('Supervisor')}
						</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item
					name="permissions"
					label={I18n.get('Companies - Industrial sites')}
				>
					<Tree
						checkable
						onExpand={onExpand}
						expandedKeys={expandedKeys}
						autoExpandParent={autoExpandParent}
						onCheck={onCheck}
						checkedKeys={checkedKeys}
						onSelect={onSelect}
						selectedKeys={selectedKeys}
						treeData={treeData}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
}
