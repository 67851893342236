import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { message } from 'antd';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { useIsMountedRef } from './useIsMountedRef';
import Api from '@/services/api';

function useFile() {

	const initialState = { loading: false, data: [], errors: null };

	const [uploads, setUploads] = useState(initialState);
	const [deletedFile, setDeletedFile] = useState(null);
	const [errors, setErrors] = useState(null);

	const isMountedRef = useIsMountedRef();

	const organizationId = useSelector(state => state.organization?.organization?.id);
	const companyId = useSelector(state => state.organization?.company?.id);

	useEffect(() => {
		async function listFile(organizationId, companyId) {
			try {
				isMountedRef.current && setUploads(current => ({ ...current, loading: !current.loading }));
				let url = `/file/${organizationId}/${companyId}/?`;
				const { data } = await Api.get(url);
				isMountedRef.current && setUploads(current => ({ ...current, data: data }));
			} catch (error) {
				isMountedRef.current && setUploads(current => ({ ...current, errors: error }));
				setErrors(error);
				throw error;
			} finally {
				isMountedRef.current && setUploads(current => ({ ...current, loading: !current.loading }));
			}
		}
		if (organizationId && companyId) {
			listFile(organizationId, companyId);
		}
	}, [organizationId, companyId]);

	useEffect(() => {
		if (errors) {
			onErrors(errors);
		}
		return () => setErrors(null);
	}, [errors]);

	const onErrors = (data) => {
		const { response, message: description } = data || null;
		const error = response?.data?.message || description;
		message.error(I18n.get(error));
	}

	const deleteFile = async (fileId) => {
		try {
			const config = {
				url: '/file/delete',
				method: 'post',
				data: {
					organization_id: organizationId,
					company_id: companyId,
					file_id: fileId
				}
			};
			const { data } = await Api(config);
			removeItemUpload(data.file_id);
			setDeletedFile(data.file_id);
		} catch (error) {
			setErrors(error);
			throw error;
		} finally {
			setDeletedFile(null);
		}
	}

	const removeItemUpload = async (fileId) => {
		setUploads(current => ({
			...current,
			data: current.data.filter(item => item.id !== fileId)
		}));
	}

	const updateFile = async (file) => {
		setUploads(current => ({
			...current,
			data: current.data.map(item => item.id === file.id ? { ...item, status: file.status } : item)
		}));
	}

	const getUploads = async () => {
		try {
			isMountedRef.current && setUploads(current => ({ ...current, loading: !current.loading }));
			let url = `/file/${organizationId}/${companyId}/?`;
			const { data } = await Api.get(url);
			isMountedRef.current && setUploads(current => ({ ...current, data: data }));
		} catch (error) {
			isMountedRef.current && setUploads(current => ({ ...current, errors: error }));
			setErrors(error);
			throw error;
		} finally {
			isMountedRef.current && setUploads(current => ({ ...current, loading: !current.loading }));
		}
	}

	const filterUploads = async (parameters) => {

		const { organization_id, company_id, original_name, date, sector, workstation } = parameters;

		try {

			isMountedRef.current && setUploads(current => ({ ...current, loading: !current.loading }));

			let url = `/file/${organization_id}/${company_id}/?`;

			if (original_name) {
				url = `${url}original_name=${original_name}&`;
			}

			if (date) {
				const [start, end] = date;
				const start_date = moment(start).format();
				const end_date = moment(end).format();
				url = `${url}start_date=${start_date}&end_date=${end_date}&`;
			}

			if (sector) {
				url = `${url}sector=${sector}&`;
			}

			if (workstation) {
				url = `${url}workstation=${workstation}&`;
			}

			const { data } = await Api.get(url);
			isMountedRef.current && setUploads(current => ({ ...current, data: data }));
		} catch (error) {
			isMountedRef.current && setUploads(current => ({ ...current, errors: error }));
			setErrors(error);
			throw error;
		} finally {
			isMountedRef.current && setUploads(current => ({ ...current, loading: !current.loading }));
		}
	}

	return { uploads, deletedFile, deleteFile, updateFile, getUploads, filterUploads };
}

export { useFile };
