import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';

import {
	RiskDescriptionsRequest,
	UpdateRiskDamageSeverityRequest
} from '@/components/ui/Inputs/types/request/customReport';
import { ManageRiskService, ResponseDataTypeChecker } from './services';
import { RiskDamageDTO, RiskDescriptionDTO, SeverityDTO } from '@/components/ui/Inputs';
import { UpdateBaseRequest, DeleteBaseRequest, FetchDamagesRequest, FetchSeveritiesRequest } from './types';

const service = new ManageRiskService();
const { isRiskDamageType, isRiskDescriptionType } = new ResponseDataTypeChecker();

export function useGetRiskDescriptions({ organization_id, company_id, risk_category_id }: RiskDescriptionsRequest) {
	const riskDescriptions = useQuery(
		['risk_descriptions_list', organization_id],
		() => service.fetchRiskDescriptions({ organization_id, company_id, risk_category_id }),
		{
			enabled: !!organization_id && !!company_id && !!risk_category_id
		}
	);
	return {
		...riskDescriptions,
		data: riskDescriptions.data ?? ([] as RiskDescriptionDTO[])
	};
}

export function useGetRiskDamages({
	organization_id,
	company_id,
	risk_description_id,
	step_key_id
}: FetchDamagesRequest) {
	const riskDamages = useQuery(
		['risk_damages_list', organization_id, company_id, risk_description_id],
		() => service.fetchRiskDamages({ organization_id, company_id, risk_description_id }),
		{
			enabled: !!organization_id && !!company_id && !!risk_description_id && !!step_key_id
		}
	);
	return {
		...riskDamages,
		data: riskDamages.data ?? ([] as RiskDamageDTO[])
	};
}

export function useGetSeverities({ organization_id, company_id, step_key_id }: FetchSeveritiesRequest) {
	const severity = useQuery(
		['severities_list', organization_id, company_id],
		() => service.fetchSeverities({ organization_id, company_id, step_key_id }),
		{
			enabled: !!organization_id && !!company_id && !!step_key_id
		}
	);
	return {
		...severity,
		data: severity.data ?? ([] as SeverityDTO[])
	};
}

export function useUpdateRiskDamageSeverity() {
	const queryClient = useQueryClient();
	return useMutation((body: UpdateRiskDamageSeverityRequest) => service.updateRiskDamageSeverity(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message ?? err.response.statusText));
		},
		onSuccess: () => {
			message.success(I18n.get('Severity set successfully'));
			queryClient.invalidateQueries(['risk_damages_list']);
		}
	});
}

export function useUpdate<Body extends UpdateBaseRequest, T>() {
	const queryClient = useQueryClient();
	return useMutation((body: Body) => service.update<Body, T>(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message ?? err.response.statusText));
		},
		onSuccess: (data) => {
			message.success(I18n.get('Data updated successfully'));
			if (isRiskDescriptionType(data)) {
				queryClient.invalidateQueries(['risk_descriptions_list']);
				return;
			}
			if (isRiskDamageType(data)) {
				queryClient.invalidateQueries(['risk_damages_list']);
			}
		}
	});
}

export function useDelete<Body extends DeleteBaseRequest, T>() {
	const queryClient = useQueryClient();
	return useMutation((body: Body) => service.delete<Body, T>(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message ?? err.response.statusText));
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries(['categories_list']);
			message.success(I18n.get('Data deleted successfully'));
			if (isRiskDescriptionType(data)) {
				queryClient.invalidateQueries(['risk_descriptions_list']);
				return;
			}
			if (isRiskDamageType(data)) {
				queryClient.invalidateQueries(['risk_damages_list']);
			}
		}
	});
}
