export type Steps = {
	id: string;
	name: string;
	sequence: number;
	step_keys?: any[];
	icon?: JSX.Element;
	description: string;
	component: JSX.Element;
};

export type Side = SIDE.LEFT | SIDE.RIGHT;

export type Direction = DIRECTION.VERTICAL | DIRECTION.HORIZONTAL;

export enum SIDE {
	LEFT = 'left',
	RIGHT = 'right'
}

export enum DIRECTION {
	VERTICAL = 'vertical',
	HORIZONTAL = 'horizontal'
}
