import React from 'react';
import { Form, FormInstance, Row } from 'antd';

import { RiskCategory } from './RiskCategory';
import { CreatedAt } from './CreatedAt';
import { InitialValues } from './types';

interface FormFilterProps {
	form: FormInstance;

	initialValues: InitialValues;
}

export const FormFilter: React.FC<FormFilterProps> = ({ form, initialValues }) => (
	<Form form={form} initialValues={initialValues} layout="vertical">
		<Row gutter={[12, 0]}>
			<RiskCategory />
			<CreatedAt />
		</Row>
	</Form>
);
