export enum MovimentsGripHandResistance {
	POWER_GRIP_FORCE = 'power_grip_force',
	POWER_GRIP_PRESS = 'power_grip_press',
	PINCH_GRIP = 'pinch_grip',
	LATERAL_PINCH_KEY_GRIP = 'lateral_pinch_key_grip',
	TIP_PINCH_GRIP = 'tip_pinch_grip',
	HOOK_GRIP = 'hook_grip',
	PUSH_WITH_INDEX_FINGER = 'push_with_index_finger',
	PUSH_WITH_THUMB = 'push_with_thumb',
	WRIST_FLEXION = 'wrist_flexion',
	ULNAR_RADIAL_DEVIATION = 'ulnar_radial_deviation',
	SCREWDRIVING = 'screwdriving'
}

export enum GloveClothingType {
	ASSEMBLER = 'assembler',
	WELDER = 'welder'
}

export enum HandAndArmClearanceActions {
	ONE_FINGER = 'one_finger',
	TWO_FINGERS = 'two_fingers',
	ARM_TO_ELBOW = 'arm_to_elbow',
	HAND_PLUS_X_MM = 'hand_plus_x_mm',
	ARM_TO_SHOULDER = 'arm_to_shoulder',
	HAND_PLUS_25_MM = 'hand_plus_25_mm',
	EMPTY_HAND_FLAT = 'empty_hand_flat',
	CLENCHED_HAND_FIST = 'clenched_hand_fist',
	BOTH_ARMS_TO_SHOULDER = 'both_arms_to_shoulder',
	EMPTY_HAND_THUMB_TUCKED = 'empty_hand_thumb_tucked'
}

export enum StaticPostureActions {
	KNEELING = 'kneeling',
	CROUCHING = 'crouching',
	STANDING = 'standing'
}

export enum AdditionalItemsTypes {
	SHARP_EDGES = 'sharp_edges',
	STATIC_POSTURE = 'static_posture',
	NOISE_LEVEL_VERY_HIGH = 'noise_level_very_high',
	STANDING_ARM_STRENGTH = 'standing_arm_strength',
	GRIP_AND_HAND_STRENGTH = 'grip_and_hand_strength',
	SEATED_WORKSTATION_LAYOUT_ADJUSTABLE = 'seated_workstation_layout_adjustable',
	VISUAL_TASKS = 'visual_tasks',
	WRENCH_LENGTH_FOR_TWO_HAND_FORCE = 'wrench_length_for_two_hand_force',
	HAND_ARM_VIBRATION_EXPOSURE = 'hand_arm_vibration_exposure',
	DISTANCES_AND_DIMENSIONS_INADEQUATE = 'distances_and_dimensions_inadequate',
	HORIZONTAL_REACH = 'horizontal_reach',
	WRENCH_LENGTH_FOR_ONE_HAND_FORCE_BACKUP_WRENCH = 'wrench_length_for_one_hand_force_backup_wrench',
	MANUAL_WRENCH_MAXIMUM_FORCE = 'manual_wrench_maximum_force',
	WHOLE_BODY_VIBRATION_EXPOSURE = 'whole_body_vibration_exposure',
	WRENCH_LENGTH_FOR_ONE_HAND_FORCE = 'wrench_length_for_one_hand_force',
	HAND_AND_ARM_CLEARANCE = 'hand_and_arm_clearance',
	CLEARANCE_FOR_SAFETY = 'clearance_for_safety',
	SEATED_WORKSTATION_LAYOUT_FIXED = 'seated_workstation_layout_fixed'
}
