export interface RebaDTO {
	file_id: string;
	score_seconds: number;
}

export enum BodyPartsKey {
	trunk = 'trunk',
	neck = 'neck',
	left_lower_arm = 'left_lower_arm',
	right_lower_arm = 'right_lower_arm',
	left_upper_arm = 'left_upper_arm',
	right_upper_arm = 'right_upper_arm',
	left_knee = 'left_knee',
	right_knee = 'right_knee'
}

export enum BodyPartsDescription {
	trunk = 'Trunk',
	neck = 'Neck',
	left_lower_arm = 'Left lower arm',
	right_lower_arm = 'Right lower arm',
	left_upper_arm = 'Left upper arm',
	right_upper_arm = 'Right upper arm',
	left_knee = 'Left knee',
	right_knee = 'Right knee'
}

export interface BodyPartsDTO {
	[BodyPartsKey.trunk]: Array<number | null>;
	[BodyPartsKey.neck]: Array<number | null>;
	[BodyPartsKey.left_lower_arm]: Array<number | null>;
	[BodyPartsKey.right_lower_arm]: Array<number | null>;
	[BodyPartsKey.left_upper_arm]: Array<number | null>;
	[BodyPartsKey.right_upper_arm]: Array<number | null>;
	[BodyPartsKey.left_knee]: Array<number | null>;
	[BodyPartsKey.right_knee]: Array<number | null>;
	score_second?: Array<number | null>;
}

export interface IUseBodyParts {
	organization_id: string;
	company_id: string;
	file_id: string;
}

// export type BodyParts = {
// 	[];
// };
