import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Context, Methods, State, RPNPriority, SCORE_COLLOR_MAPPER, PRIORITY_SCALE_MAPPER, Expanded } from './types';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useSeraSummaryReview } from '../SeraForm/hooks/hooks';

export type SeraReviewRouteParams = {
	sera_summary_id: string;
};

interface SeraReviewProviderProps {
	children: ReactNode;
}

export const SeraReviewContext = createContext<Context>({} as Context);

export const SeraReviewProvider = ({ children }: SeraReviewProviderProps) => {
	const [visible, setVisible] = useState(false);
	const [previousReviewStatus, setPreviousReviewStatus] = useState<Expanded[]>([] as Expanded[]);

	const { company, organization } = useApplicationContext();
	const { sera_summary_id } = useParams<SeraReviewRouteParams>();
	const {
		data: seraSummaryReview,
		isLoading: gettingSeraSummaryReview,
		isFetching: fetchingSeraSummaryReview,
		isError: errorGettingSeraSummaryReview
	} = useSeraSummaryReview({
		company_id: company?.id,
		organization_id: organization?.id,
		sera_summary_id
	});

	useEffect(() => {
		if (seraSummaryReview) {
			const mappedReviewStatus = seraSummaryReview?.sera_reports?.map((report) => ({
				id: report.id,
				expanded: false
			}));
			setPreviousReviewStatus(mappedReviewStatus);
		}
	}, [seraSummaryReview]);

	function mapRPNToPriority(rpn: number | undefined): RPNPriority {
		if (!rpn) {
			return {
				priority: PRIORITY_SCALE_MAPPER.none.priority,
				color: SCORE_COLLOR_MAPPER[PRIORITY_SCALE_MAPPER.none.scale]
			};
		}
		if (rpn <= 8) {
			return {
				priority: PRIORITY_SCALE_MAPPER.low.priority,
				color: SCORE_COLLOR_MAPPER[PRIORITY_SCALE_MAPPER.low.scale]
			};
		}
		if (rpn <= 35) {
			return {
				priority: PRIORITY_SCALE_MAPPER.medium.priority,
				color: SCORE_COLLOR_MAPPER[PRIORITY_SCALE_MAPPER.medium.scale]
			};
		}
		if (rpn <= 124) {
			return {
				priority: PRIORITY_SCALE_MAPPER.medium_high.priority,
				color: SCORE_COLLOR_MAPPER[PRIORITY_SCALE_MAPPER.medium_high.scale]
			};
		}
		if (rpn <= 179) {
			return {
				priority: PRIORITY_SCALE_MAPPER.high.priority,
				color: SCORE_COLLOR_MAPPER[PRIORITY_SCALE_MAPPER.high.scale]
			};
		}
		return {
			priority: PRIORITY_SCALE_MAPPER.very_high.priority,
			color: SCORE_COLLOR_MAPPER[PRIORITY_SCALE_MAPPER.very_high.scale]
		};
	}

	function handleShowActionPlanModalChange() {
		setVisible(!visible);
	}

	function handleExpandPreviousReview(id: string, event: React.MouseEvent<HTMLElement, MouseEvent>) {
		event.stopPropagation();
		const previousReviewStatusCopy = [...previousReviewStatus];
		const index = previousReviewStatusCopy.findIndex((status) => status.id === id);
		if (index < 0) {
			return;
		}
		previousReviewStatusCopy[index].expanded = !previousReviewStatusCopy[index].expanded;
		setPreviousReviewStatus(previousReviewStatusCopy);
	}

	const state: State = {
		visible,
		seraSummaryReview,
		previousReviewStatus,
		gettingSeraSummaryReview,
		fetchingSeraSummaryReview,
		errorGettingSeraSummaryReview
	};

	const methods: Methods = {
		mapRPNToPriority,
		handleExpandPreviousReview,
		handleShowActionPlanModalChange
	};

	const context: Context = useMemo(() => {
		return {
			...state,
			...methods
		};
	}, [state]);

	return <SeraReviewContext.Provider value={context}>{children}</SeraReviewContext.Provider>;
};

export function useSeraReviewContext() {
	const context = useContext(SeraReviewContext);
	return context;
}
