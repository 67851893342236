import React from 'react';
import { Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { RiskCategorySelect } from '@/components/ui/Inputs/RiskCategorySelect';
import { RequiredLabel } from '../../../RequiredLabel';
import { useStepKeysContext } from '../context';
import { CustomFormItem } from '../styles';

const { useFormInstance, useWatch } = Form;

interface RiskCategoriesProps {
	formFieldName: string[];
}

export const RiskCategories: React.FC<RiskCategoriesProps> = ({ formFieldName }) => {
	const form = useFormInstance();
	const {
		riskCategoriesData: { riskCategories, fetchingRiskCategories, gettingRiskCategories }
	} = useStepKeysContext();

	const fieldName = [...formFieldName, 'risk_category_id'];
	const severityFieldName = [...formFieldName, 'severity_id'];
	const damageFieldName = [...formFieldName, 'risk_damage_id'];
	const descriptionFieldName = [...formFieldName, 'risk_description_id'];

	const riskCategoryId = useWatch(fieldName, form);

	async function handleRiskCategorySelect(id: string) {
		form.setFieldValue(fieldName, id);
		form.setFieldValue(damageFieldName, undefined);
		form.setFieldValue(descriptionFieldName, undefined);
		form.setFieldValue(severityFieldName, undefined);
	}

	async function handleClearRiskCategory() {
		form.setFieldValue(fieldName, undefined);
		form.setFieldValue(damageFieldName, undefined);
		form.setFieldValue(descriptionFieldName, undefined);
		form.setFieldValue(severityFieldName, undefined);
	}

	return (
		<Col xs={24} sm={12} lg={24}>
			<CustomFormItem
				name={fieldName}
				label={<RequiredLabel title="Category" isRequired={true} />}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: true,
						message: I18n.get('Select the category')
					}
				]}
			>
				<RiskCategorySelect
					allowCreation={false}
					fieldName={fieldName}
					riskCategories={riskCategories}
					riskCategoryId={riskCategoryId}
					loading={fetchingRiskCategories}
					handleClearRiskCategory={handleClearRiskCategory}
					handleSelectRiskCategory={handleRiskCategorySelect}
					disabled={gettingRiskCategories || fetchingRiskCategories}
				/>
			</CustomFormItem>
		</Col>
	);
};
