import * as React from 'react';

export const LibertyMutualIcon: React.FC = () => {
	return (
		<svg width={29} height={25} viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x={-1.5}
				y={1.5}
				width={17.3636}
				height={10.0909}
				rx={1.5}
				transform="matrix(-1 0 0 1 25.406 11.91)"
				stroke="#262626"
				strokeWidth={3}
			/>
			<path
				d="M3.779 8.388a13.11 13.11 0 0110.285-5.041l.007-2.093c0-.212.248-.33.412-.2l4.185 3.303a.257.257 0 010 .402L14.48 8.065c-.167.13-.416.013-.412-.2V5.776c-.422.003-.847.03-1.269.082a10.66 10.66 0 00-3.89 1.265A10.702 10.702 0 005.69 9.879a10.65 10.65 0 00-2.112 8.393H1.13A13.03 13.03 0 013.78 8.388z"
				fill="#262626"
				stroke="#262626"
			/>
		</svg>
	);
};
