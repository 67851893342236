import { Scratchs, ScratchsTitle, ScratchItems, PhysicalOverload, HealthConsequence, Measure } from './types';

export const scratchMap: { [key in string]: ScratchsTitle } = {
	[Scratchs.LOW]: ScratchsTitle.LOW,
	[Scratchs.MODERATE]: ScratchsTitle.MODERATE,
	[Scratchs.HIGH]: ScratchsTitle.HIGH,
	[Scratchs.VERY_HIGH]: ScratchsTitle.VERY_HIGH
};

export const diagnosticsMap: { [key in string]: any } = {
	[Scratchs.LOW]: {
		[ScratchItems.PHYSICAL_OVERLOAD]: [PhysicalOverload.LOW],
		[ScratchItems.HEALTH_CONSEQUENCES]: [HealthConsequence.LOW],
		[ScratchItems.MEASURES]: [Measure.LOW]
	},
	[Scratchs.MODERATE]: {
		[ScratchItems.PHYSICAL_OVERLOAD]: [PhysicalOverload.MODERATE],
		[ScratchItems.HEALTH_CONSEQUENCES]: [HealthConsequence.MODERATE],
		[ScratchItems.MEASURES]: [Measure.MODERATE]
	},
	[Scratchs.HIGH]: {
		[ScratchItems.PHYSICAL_OVERLOAD]: [PhysicalOverload.HIGH],
		[ScratchItems.HEALTH_CONSEQUENCES]: [HealthConsequence.HIGH],
		[ScratchItems.MEASURES]: [Measure.HIGH]
	},
	[Scratchs.VERY_HIGH]: {
		[ScratchItems.PHYSICAL_OVERLOAD]: [PhysicalOverload.VERY_HIGH],
		[ScratchItems.HEALTH_CONSEQUENCES]: [HealthConsequence.VERY_HIGH],
		[ScratchItems.MEASURES]: [Measure.VERY_HIGH]
	}
};
