import React from 'react';
import { I18n } from '@aws-amplify/core';
import Dropzone from 'react-dropzone';
import { AiOutlineCloudUpload, AiOutlineWarning, AiOutlineSmile } from 'react-icons/ai';
import { DropContainer, ContainerMessage, TitleUpload, MaxSize } from './styles';

const FILE_MAX_SIZE = process.env.REACT_APP_MAX_FILE_UPLOAD_SIZE_GB || 2;
const LIMIT_SIZE = FILE_MAX_SIZE * 1024 ** 3;

export function Drop({ onUpload, disabled, rejectMessage }) {
	const acceptMimeType = {
		'video/mp4': [],
		'video/avi': [],
		'video/MP2T': [],
		'video/mpeg': [],
		'video/3gpp': [],
		'video/x-flv': [],
		'video/x-ms-wmv': [],
		'video/quicktime': [],
		'video/x-msvideo': [],
		'video/x-matroska': [],
		'application/x-mpegURL': []
	};

	function renderFeedback(isDragActive, isDragReject) {
		if (!isDragActive) {
			return (
				<ContainerMessage style={{ flexDirection: 'column' }}>
					<AiOutlineCloudUpload
						size={40}
						color={!disabled ? '#3956f8' : '#999999'}
						style={{ alignSelf: 'center' }}
					/>
					{I18n.get('Add files or drag')}
					<MaxSize>{`${I18n.get('Max size')} : ${FILE_MAX_SIZE} GB`}</MaxSize>
				</ContainerMessage>
			);
		}

		if (isDragReject && !disabled) {
			return (
				<ContainerMessage>
					<AiOutlineWarning size={40} color="#deb009" style={{ alignSelf: 'center' }} />
					{I18n.get('Unsupported file')}
				</ContainerMessage>
			);
		}

		return (
			<ContainerMessage>
				<AiOutlineSmile size={40} color="#3AC430" style={{ alignSelf: 'center' }} />
				{I18n.get('Drop files here')}
			</ContainerMessage>
		);
	}

	return (
		<>
			<TitleUpload>{I18n.get('Send video')}</TitleUpload>
			<Dropzone
				minSize={0}
				onDrop={onUpload}
				disabled={disabled}
				maxSize={LIMIT_SIZE}
				accept={acceptMimeType}
				onDropRejected={(error) => rejectMessage(error)}
			>
				{({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
					<DropContainer {...getRootProps()} disabled={disabled}>
						<input {...getInputProps()} />
						{renderFeedback(isDragActive, isDragReject, getRootProps)}
					</DropContainer>
				)}
			</Dropzone>
		</>
	);
}
