import Api from '@/services/api';
import { BaseContext } from '@/types';
import { useQuery } from '@tanstack/react-query';

export type CustomReportReviewsRequest = BaseContext & {
	original_custom_report_result_id?: string;
};

export type CustomReportReviewsSelectorDTO = {
	id: string;
	name: string;
	version: number;
	created_at: string | Date;
};

async function getCustomReportReviews(payload: CustomReportReviewsRequest): Promise<CustomReportReviewsSelectorDTO[]> {
	const url = `/custom-report/review/selector-options`;
	const { data } = await Api.get<CustomReportReviewsSelectorDTO[]>(url, {
		params: payload
	});
	return data;
}

export function useGetCustomReportReviews({ organization_id, company_id, original_custom_report_result_id }: CustomReportReviewsRequest) {
	const enabled = !!organization_id && !!company_id && !!original_custom_report_result_id;
	const results = useQuery(
		['custom_report_reviews', [organization_id, company_id, original_custom_report_result_id]],
		() => getCustomReportReviews({ organization_id, company_id, original_custom_report_result_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...results,
		data: results.data ?? ([] as CustomReportReviewsSelectorDTO[])
	};
}
