import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Image, Row } from 'antd';

import { TaskNameMapper } from '../../../../hooks/types/response';
import { SummaryResult } from '../../../../hooks/types/response';
import { Body, CardContainer, Header } from '../styles';
import { SVGMapper, Style } from './types';

interface InitialStateCardProps {
	task_name: TaskNameMapper.Lift | TaskNameMapper.Lower;
	svgMapper: SVGMapper;
	summary: SummaryResult;
}

export const InitialStateCard: React.FC<InitialStateCardProps> = ({ svgMapper, task_name, summary }) => {
	const startStateSVGAbsoluteValues: Style = {
		[TaskNameMapper.Lift]: {
			distance: {
				fontWeight: 400,
				position: 'absolute',
				bottom: '2%',
				left: '46%'
			},
			height: {
				fontWeight: 400,
				position: 'absolute',
				bottom: '22%',
				left: '75%'
			}
		},
		[TaskNameMapper.Lower]: {
			distance: {
				fontWeight: 400,
				position: 'absolute',
				bottom: '2%',
				left: '46%'
			},
			height: {
				fontWeight: 400,
				position: 'absolute',
				bottom: '35%',
				left: '75%'
			}
		}
	};

	return (
		<CardContainer
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between'
			}}
		>
			<Header>{I18n.get('Initial state')}</Header>
			<Body style={{ padding: '1rem 3rem 2rem 3rem' }}>
				<Row justify="center" align="middle">
					<Image
						style={{ position: 'relative' }}
						preview={false}
						draggable={false}
						alt={`${I18n.get('Start hand distance')} ${I18n.get('Start hand height')}`}
						src={svgMapper[task_name].initial}
						width="140px"
					/>
					<span style={startStateSVGAbsoluteValues[task_name].distance}>
						{summary.start_hand_distance ?? 0}
					</span>
					<span style={startStateSVGAbsoluteValues[task_name].height}>{summary.start_hand_height ?? 0}</span>
				</Row>
			</Body>
		</CardContainer>
	);
};
