import React from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Descriptions } from 'antd';
import { Text } from '@/components/Typography';

import { useApplicationContext } from '@/context/v1/Application/context';
import { useBackCompressiveForceEstimationContext } from '../../context';
import { useGetDownloadVideoURL } from '@/hooks';
import { CustomImageSkeleton } from './styles';

const { Item } = Descriptions;

export const VideoContainer = () => {
	const { organization, company } = useApplicationContext();
	const { backCompressiveForceEstimation, file_id } = useBackCompressiveForceEstimationContext();

	const {
		data: video,
		isInitialLoading: gettingVideo,
		isError: errorGettingVideo
	} = useGetDownloadVideoURL({
		file_id,
		company_id: company?.id,
		organization_id: organization?.id,
		original_name: backCompressiveForceEstimation?.original_name
	});

	if (gettingVideo) {
		return (
			<Row justify="center">
				<CustomImageSkeleton active />
			</Row>
		);
	}

	function renderVideoContent() {
		if (errorGettingVideo) {
			return (
				<Row justify="center">
					<Text>Oops... Something went wrong!</Text>
				</Row>
			);
		}

		return (
			<Row justify="center">
				<video loop width="486" height="274" controls>
					<source src={video} type="video/webm" />
				</video>
			</Row>
		);
	}

	return (
		<Col xs={12}>
			{renderVideoContent()}
			<Row style={{ marginTop: '1rem' }}>
				<Descriptions column={1} size="small">
					<Item label={I18n.get('File')} labelStyle={{ fontWeight: 'bold' }}>
						{backCompressiveForceEstimation?.original_name}
					</Item>
					<Item label={I18n.get('Date')} labelStyle={{ fontWeight: 'bold' }}>
						{moment(backCompressiveForceEstimation?.uploaded_date).format('L')}
					</Item>
				</Descriptions>
			</Row>
		</Col>
	);
};
