export enum ActionPlanTaskTypes {
	ELIMINATE = 'ELIMINATE',
	CONTROL = 'CONTROL',
	COMPENSATE = 'COMPENSATE'
}

export type ActionPlanTask = {
	id: string;
	description?: string;
	type?: ActionPlanTaskTypes;
	is_completed: boolean;
	action_plan_id: string;
	is_active: boolean;
	created_at: Date;
	updated_at: Date;
};
