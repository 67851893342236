import styled from 'styled-components';
import { Col, Radio } from 'antd';

export const PreviousCol = styled(Col)`
	padding: 0;
	margin-right: 1rem;

	@media (min-width: 576px) {
		margin-right: 4rem;
	}

	#work_schedule_hours_help {
		position: absolute;
	}
`;

export const CustomFormContainer = styled(Col)<{ $isSmallScreen?: string }>`
	padding: 2rem 1rem;
	border-radius: 1rem;
	background-color: #f8f8f8;
	margin-left: ${({ $isSmallScreen }) => ($isSmallScreen ? '0' : '2rem')};
`;

export const CustomRadioGroup = styled(Radio.Group)`
	gap: 1rem;
	display: flex;
	flex-direction: column;
`;
