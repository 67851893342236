const Types = {
	SET_BRIEF_BEST_PARAMETERS_HANDS: 'SET_BRIEF_BEST_PARAMETERS_HANDS',
	SET_BRIEF_BEST_PARAMETERS_ELBOWS: 'SET_BRIEF_BEST_PARAMETERS_ELBOWS',
	SET_BRIEF_BEST_PARAMETERS_SHOULDERS: 'SET_BRIEF_BEST_PARAMETERS_SHOULDERS',
	SET_BRIEF_BEST_PARAMETERS_NECK: 'SET_BRIEF_BEST_PARAMETERS_NECK',
	SET_BRIEF_BEST_PARAMETERS_TRUNK: 'SET_BRIEF_BEST_PARAMETERS_TRUNK',
	SET_BRIEF_BEST_PARAMETERS_LEGS: 'SET_BRIEF_BEST_PARAMETERS_LEGS',
	SET_BRIEF_BEST_PARAMETERS_EXTERNALS: 'SET_BRIEF_BEST_PARAMETERS_EXTERNALS',

	GET_BRIEF_BEST_SCORE_LOADING: 'GET_BRIEF_BEST_SCORE_LOADING',
	GET_BRIEF_BEST_SCORE_SUCCESS: 'GET_BRIEF_BEST_SCORE_SUCCESS',
	GET_BRIEF_BEST_SCORE_ERROR: 'GET_BRIEF_BEST_SCORE_ERROR',

	CLEAR_ERROR_REQUEST: 'CLEAR_ERROR_REQUEST',

	GET_ANGLE_SCORE_LOADING: 'GET_ANGLE_SCORE_LOADING',
	GET_ANGLE_SCORE_SUCCESS: 'GET_ANGLE_SCORE_SUCCESS',
	GET_ANGLE_SCORE_ERROR: 'GET_ANGLE_SCORE_ERROR',

	VALIDATE_BODY_PARTS_STEP: 'VALIDATE_BODY_PARTS_STEP',

	VALIDATION_STEP: 'VALIDATION_STEP',
	SET_ITENS_FORM_BODY_PARTS: 'SET_ITENS_FORM_BODY_PARTS',
};

export default Types;
