import styled from 'styled-components';
import { Col } from 'antd';

export const ErrorGettingData = styled(Col)`
	min-height: 250px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
`;

export const GettingData = styled(Col)`
	min-height: 250px;
`;

export const TreemapContent = styled(Col)`
	height: 100%;
`;
