import React from 'react';
import { FormItem } from '../../../components/FormItem';
import { I18n } from '@aws-amplify/core';
import { Input } from 'antd';

interface InterviewerNameProps {
	fontSize?: string;
	isRequired?: boolean;
	fieldName: string;
}

export const InterviewerName: React.FC<InterviewerNameProps> = ({ fontSize, isRequired, fieldName }) => {
	return (
		<FormItem
			fontSize={fontSize}
			title="Interviewer name"
			isRequired={isRequired}
			namePath={[fieldName, 'interviewer_name']}
			errorMessage="Interviewer name cannot be empty"
		>
			<Input size="large" placeholder={I18n.get('Interviewer name')} />
		</FormItem>
	);
};
