import { Button, Form, Image, List, Row } from 'antd';
import styled from 'styled-components';

const { Item } = List;
const { Meta } = Item;

export const Generate2FAForm = styled(Form)`
	min-height: '500px';
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

export const Subtitle = styled(Row)`
	font-weight: 600;
	font-size: 1rem;
	margin-bottom: 1rem;
`;

export const MethodsContainer = styled(Row)`
	height: 100%;
`;

export const MethodTitle = styled.span`
	font-weight: 600;
	font-size: 1rem;
	margin-bottom: 0.66rem;
`;

export const MethodListItem = styled(Item)`
	margin-bottom: 1rem;
	padding: 2rem;
	cursor: pointer;

	transition: filter 0.2s;
	&:hover {
		filter: brightness(0.8);
	}
`;

export const Method = styled(Meta)`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const NextButton = styled(Button)`
	background: #d9d9d9;
	border-radius: 50%;
	margin-left: 1rem;
`;

export const GenerateContainer = styled(Row)`
	min-height: 40vh;
`;

export const RecoveryTokensContainer = styled(Row)`
	min-height: 40vh;
`;

export const IconContainer = styled(Row)`
	margin-bottom: 1rem;
`;

export const QRCode = styled(Image)`
	border-radius: 0.25rem;
`;

export const Tip = styled.span`
	font-size: '1rem';
	font-weight: 400;
`;
