import React, { useState } from 'react';
import { Row, Col } from 'antd';

import { Header } from './Header';
import { useCustomReport, useSeraSteps } from './hooks';
import { CreateCategory } from './CreateCategory';
import { CategoriesTable } from './CategoriesTable';
import { useApplicationContext } from '@/context/v1/Application/context';

const REPORT_NAME = 'sera';

export const ManageRisk: React.FC = () => {
	const { organization, company } = useApplicationContext();
	const [open, setOpen] = useState<boolean>(false);

	const { data: customReport } = useCustomReport({
		organization_id: organization?.id,
		company_id: company?.id,
		reportName: REPORT_NAME
	});

	const customReportId = customReport?.id;
	const { data: stepsList } = useSeraSteps({
		organization_id: organization?.id,
		company_id: company.id,
		custom_report_id: customReportId
	});
	const stepKeyId = stepsList[0]?.step_key[0]?.id;

	function onShowCreateModal(): void {
		setOpen(true);
	}

	function onCloseCreateModal(): void {
		setOpen(false);
	}

	return (
		<Row gutter={[0, 10]}>
			<Col span={24}>
				<Header onClick={onShowCreateModal} />
			</Col>
			<Col span={24}>
				<CategoriesTable stepKeyId={stepKeyId} />
			</Col>
			<Col span={24}>
				<CreateCategory stepKeyId={stepKeyId} open={open} onClose={onCloseCreateModal} />
			</Col>
		</Row>
	);
};
