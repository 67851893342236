import React from 'react';
import { Col, Divider, Row } from 'antd';

import { I18n } from '@aws-amplify/core';

import { IRecommended, RiskDescription } from '../types';
import { DetailDescription, Details, DetailTitle } from './styles';

interface BodyProps {
	recommended: IRecommended;
}

export const Body: React.FC<BodyProps> = ({
	recommended: { recommended_weight_limit, lifting_index, reference_weight, risk }
}) => {
	return (
		<Col span={24}>
			<Row justify="space-between">
				<Col span={5} style={{ paddingLeft: '1rem' }}>
					<Details>
						<Row>
							<Col span={24}>
								<DetailTitle>{I18n.get('Weight Limit recommended (WLR)')}:</DetailTitle>
							</Col>
						</Row>
						<Row>
							<DetailDescription>
								{recommended_weight_limit} {I18n.get('kg')}.
							</DetailDescription>
						</Row>
					</Details>
				</Col>
				<Divider style={{ margin: 0, height: 'auto' }} type={'vertical'} />
				<Col span={5}>
					<Details>
						<Row>
							<Col span={24}>
								<DetailTitle>{I18n.get('Lifting index (LI)')}:</DetailTitle>
							</Col>
						</Row>
						<Row>
							<DetailDescription>{lifting_index}</DetailDescription>
						</Row>
					</Details>
				</Col>
				<Divider style={{ margin: 0, height: 'auto' }} type={'vertical'} />
				<Col span={5}>
					<Details>
						<Row>
							<Col span={24}>
								<DetailTitle>{I18n.get('Reference weight')}:</DetailTitle>
							</Col>
						</Row>
						<Row>
							<DetailDescription>
								{reference_weight} {I18n.get('kg')}.
							</DetailDescription>
						</Row>
					</Details>
				</Col>
				<Divider style={{ margin: 0, height: 'auto' }} type={'vertical'} />
				<Col span={5}>
					<Details>
						<Row>
							<DetailTitle>{I18n.get('Risk')}</DetailTitle>
						</Row>
						<Row>
							<Col span={24}>
								<DetailDescription>{I18n.get(RiskDescription[risk])}</DetailDescription>
							</Col>
						</Row>
					</Details>
				</Col>
			</Row>
		</Col>
	);
};
