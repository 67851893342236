import Api from '@/services/api';

import { SeraSummaryReviewRequest, CreateSeraSummaryReviewRequest } from '../../hooks/types/request';
import { SeraSummaryReviewDTO } from '../../hooks/types/response';

export class SeraSummaryReviewService {
	async getSeraSummaryReview(payload: SeraSummaryReviewRequest): Promise<SeraSummaryReviewDTO> {
		const url = `/sera/review/summary/${payload.sera_summary_id}`;
		const { data } = await Api.get<SeraSummaryReviewDTO>(url, {
			params: payload
		});
		return data;
	}

	async createSeraSummaryReview(body: CreateSeraSummaryReviewRequest): Promise<SeraSummaryReviewDTO> {
		const url = `/sera/review`;
		const { data } = await Api.post<SeraSummaryReviewDTO>(url, body);
		return data;
	}
}
