import React from 'react';
import { Col, Form } from 'antd';

import { useApplicationContext } from '@/context/v1/Application/context';
import { SectorSelect } from '@/components/ui/Inputs/SectorSelect';
import { FormItem } from '@/components/CustomReports';
import { useSectors } from '@/hooks/v1';

const { useFormInstance, useWatch } = Form;

interface SectorsProps {
	fontSize?: string;
	isRequired?: boolean;
	fieldName: string;
}

export const Sectors: React.FC<SectorsProps> = ({ fontSize, isRequired, fieldName }) => {
	const form = useFormInstance();
	const { company, organization } = useApplicationContext();

	const companyId = useWatch([fieldName, 'company_id'], form);
	const sectorId = useWatch([fieldName, 'sector_id'], form);

	const {
		data: sectors,
		isLoading,
		isFetching,
		isError
	} = useSectors({
		organizationId: organization?.id,
		companyId: company?.id
	});

	function handleSectorSelect(id: string) {
		form.setFieldValue([fieldName, 'sector_id'], id);
		form.setFieldValue([fieldName, 'line_id'], undefined);
		form.setFieldValue([fieldName, 'workstation_id'], undefined);
	}

	function handleClearSector() {
		form.setFieldValue([fieldName, 'sector_id'], undefined);
		form.setFieldValue([fieldName, 'line_id'], undefined);
		form.setFieldValue([fieldName, 'workstation_id'], undefined);
	}

	return (
		<Col xs={24}>
			<FormItem namePath={[fieldName, 'sector_id']} title={'Sector'} fontSize={fontSize} isRequired={isRequired}>
				<SectorSelect
					size="large"
					allowCreation
					sectors={sectors}
					sectorId={sectorId}
					loading={isFetching}
					fieldName={[fieldName, 'sector_id']}
					handleClearSector={handleClearSector}
					handleSelectSector={handleSectorSelect}
					disabled={isLoading || isFetching || isError || (!companyId && fieldName !== 'filter')}
				/>
			</FormItem>
		</Col>
	);
};
