import React, { Fragment, useState } from 'react';
import { Row, Col, Divider, Form } from 'antd';
import { useParams } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';

import { useApplicationContext } from '@/context/v1/Application/context';
import { useGetResults } from '@/components/views/PreliminaryAnalysis/hooks';
import { usePreliminaryAnalysis } from '../../../context';
import { AnthropometryTable } from './AnthropometryTable';
import { SummaryInformation } from './SummaryInformation';
import { PayloadDownloadPDF } from './DownloadPDF/types';
import { ResultsContext, defaultState } from './context';
import { KimManualHandling } from './KimManualHandling';
import { StepsDescription } from './StepsDescription';
import { WorkConditions } from './WorkConditions';
import RenderWhen from '@/components/RenderWhen';
import { Title } from '@/components/Typography';
import { ResultsContainer } from './styles';
import { DownloadPDF } from './DownloadPDF';
import { KimPushPull } from './KimPushPull';
import { StrainIndex } from './StrainIndex';
import { ActionPlans } from './ActionPlans';
import { Characters } from './Characters';
import { Comment } from './Comment';
import { Header } from './Header';
import { steps } from './Steps';
import { Niosh } from './Niosh';
import { Reba } from './Reba';

type ResultsParams = {
	file_id: string;
};

const { useForm } = Form;

export function Result() {
	const [form] = useForm();
	const { file_id } = useParams<ResultsParams>();
	const [state, setState] = useState(defaultState);
	const { preliminaryAnalysis } = usePreliminaryAnalysis();
	const { organization, company } = useApplicationContext();
	const { isLoading, data } = useGetResults(organization?.id, company?.id, file_id);
	const { isLoading: preliminaryAnalysisIsLoading, data: preliminaryAnalysisData } = preliminaryAnalysis;

	const browserLanguage = window.navigator.language ?? 'en-US';

	const payloadDownloadPDF: PayloadDownloadPDF = {
		organization_id: organization.id,
		company_id: company.id,
		file_id: file_id,
		locale: browserLanguage
	};

	const changeTransaction = (payload: boolean) => {
		setState((current) => ({
			...current,
			hasTransaction: payload
		}));
	};

	if (isLoading || preliminaryAnalysisIsLoading) {
		return <p>{I18n.get('Loading')}...</p>;
	}

	const context = {
		...data,
		...state,
		changeTransaction,
		...preliminaryAnalysisData
	};

	const initialValues = {
		comments: data?.comments
	};

	return (
		<ResultsContext.Provider value={context}>
			<Form form={form} layout="vertical" initialValues={initialValues}>
				<Row gutter={[0, 10]}>
					<Col span={24} style={{ marginLeft: '1rem' }}>
						<Title level={4}>Result</Title>
					</Col>
					<Col span={24}>
						<ResultsContainer>
							<Header />
							<Divider type="horizontal" />

							<SummaryInformation />
							<Divider type="horizontal" />

							<WorkConditions />
							<Divider type="horizontal" />

							<Characters />
							<Divider type="horizontal" />

							{data?.steps?.map((step: any, index: number) => (
								<Fragment key={index}>
									<StepsDescription stepName={steps[step.key]} topics={step.topics} />
									<Divider type="horizontal" />
								</Fragment>
							))}

							{/* OPCIONAIS */}
							{!!data?.niosh && (
								<>
									<Niosh data={data?.niosh} />
									<Divider type="horizontal" />
								</>
							)}
							{!!data?.kim_mho && (
								<>
									<KimManualHandling data={data?.kim_mho} />
									<Divider type="horizontal" />
								</>
							)}
							{!!data?.reba && (
								<>
									<Reba data={data?.reba} />
									<Divider type="horizontal" />
								</>
							)}
							{!!data?.kim_push_pull && (
								<>
									<KimPushPull data={data?.kim_push_pull} />
									<Divider type="horizontal" />
								</>
							)}
							{!!data?.strain_index && (
								<>
									<StrainIndex data={data?.strain_index} />
									<Divider type="horizontal" />
								</>
							)}
							<RenderWhen>
								<RenderWhen.If isTrue={data?.anthropometries && data.anthropometries.length > 0}>
									<AnthropometryTable anthropometries={data?.anthropometries} />
									<Divider type="horizontal" />
								</RenderWhen.If>
							</RenderWhen>
							{!!data?.action_plans && data?.action_plans?.length > 0 && (
								<>
									<ActionPlans actionPlans={data?.action_plans} />
									<Divider type="horizontal" />
								</>
							)}
							<Comment />
							<DownloadPDF parameters={payloadDownloadPDF} />
						</ResultsContainer>
					</Col>
				</Row>
			</Form>
		</ResultsContext.Provider>
	);
}
