import React from 'react';
import { Row, Col, Divider } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Text } from '@/components/Typography';
import { HeaderTitle, TableBodyContainer, TableContainer, TableHeaderContainer } from './styles';

type DescriptionTableProps = {
	placeDescription?: string;
	expectedTaskDescription?: string;
	performedTaskDescription?: string;
};

export const DescriptionTable: React.FC<DescriptionTableProps> = ({
	placeDescription,
	expectedTaskDescription,
	performedTaskDescription
}) => {
	return (
		<TableContainer>
			<TableHeaderContainer>
				<Row justify="space-between">
					<Col span={7}>
						<HeaderTitle>{I18n.get('Summary description of the place')}</HeaderTitle>
					</Col>
					<Col span={7}>
						<HeaderTitle>{I18n.get('Simplified description of the expected task')}</HeaderTitle>
					</Col>
					<Col span={7}>
						<HeaderTitle>{I18n.get('Simplified description of the task performed')}</HeaderTitle>
					</Col>
				</Row>
			</TableHeaderContainer>
			<TableBodyContainer>
				<Row justify="space-between">
					<Col span={7} style={{ margin: '1rem 0' }}>
						<Text>{placeDescription}</Text>
					</Col>
					<Divider style={{ margin: 0, borderLeft: '1px solid #e6e8e8', height: 'auto' }} type={'vertical'} />
					<Col span={7} style={{ margin: '1rem 0' }}>
						<Text>{expectedTaskDescription}</Text>
					</Col>
					<Divider style={{ margin: 0, borderLeft: '1px solid #e6e8e8', height: 'auto' }} type={'vertical'} />
					<Col span={7} style={{ margin: '1rem 0' }}>
						<Text>{performedTaskDescription}</Text>
					</Col>
				</Row>
			</TableBodyContainer>
		</TableContainer>
	);
};
