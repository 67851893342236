import { useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import Api from '@/services/api';
import type { BaseContext, Response, Vulnerability } from '@/types';

export type GetVulnerabilitiesDTO = BaseContext & {
	step_key_id: string;
};

async function getVulnerabilities(params: GetVulnerabilitiesDTO): Promise<Vulnerability[]> {
	const url = '/custom-report/vulnerability';
	const { data } = await Api.get<Vulnerability[]>(url, {
		params
	});
	return data;
}

export const useGetVulnerabilities = ({ organization_id, company_id, step_key_id }: GetVulnerabilitiesDTO) => {
	const enabled = !!organization_id && !!company_id && !!step_key_id;
	const vulnerabilities = useQuery<Vulnerability[], AxiosError<Response>>(
		['vulnerabilities', [organization_id, company_id]],
		() => getVulnerabilities({ organization_id, company_id, step_key_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...vulnerabilities,
		data: vulnerabilities.data ?? ([] as Vulnerability[])
	};
};
