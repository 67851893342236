import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message, notification } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CreateEvaluatorRequest } from '@/components/ui/Inputs/types/request/evaluator';
import { EvaluatorDTO } from '@/components/ui/Inputs/types/response/evaluator';
import Api from '@/services/api';

export const useCreateEvaluator = () => {
	const queryClient = useQueryClient();
	return useMutation(
		({ body }: { body: CreateEvaluatorRequest }) => Api.post<EvaluatorDTO>('/evaluator/create', body),
		{
			onError: (err: any) => {
				notification.error({
					message: I18n.get(err?.response?.data?.message || 'Ops... something happened!')
				});
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['evaluators']);
				message.success(I18n.get('Evaluator created successfully'));
			}
		}
	);
};
