import Types from './types';

const initState = {
	parameters: {
		force: {
			hands: {
				left: 0,
				right: 0
			},
			trunk: 0,
			legs: 0,
			neck: 0,
			left_elbow: 0,
			right_elbow: 0,
			left_shoulder: 0,
			right_shoulder: 0
		},
		hands_duration: {
			left: 0,
			right: 0
		},
		hands_frequency: {
			left: 0,
			right: 0
		},
		hands_position: {
			left: 0,
			right: 0
		},
		externals_factors: {
			impact: 0,
			gloves: 0,
			vibration: 0,
			temperature: 0,
			compression: 0
		},
		factor_exposition: {
			more_forty: 0,
			twenty_forty: 0,
			four_nineteen: 0,
			less_four: 0
		}
	},
	scoreBriefBest: {
		loading: false,
		data: null,
		error: null
	},
	bodyParts: {
		hands: {
			status: 'waiting',
			validate: false,
			form: {
				left: [],
				right: []
			}
		},
		elbows: {
			status: 'waiting',
			validate: false,
			form: {
				left: [],
				right: []
			}
		},
		shoulders: {
			status: 'waiting',
			validate: false
		},
		neck: {
			status: 'waiting',
			validate: false
		},
		back: {
			status: 'waiting',
			validate: false
		},
		legs: {
			status: 'waiting',
			validate: false
		}
	},
	angleScore: {
		loading: false,
		data: null,
		error: null
	},
	validateStep: false
};

const briefBestReducer = (state = initState, action) => {
	const { type, payload } = action;

	switch (type) {
		case Types.SET_ITENS_FORM_BODY_PARTS: {
			return {
				...state,
				bodyParts: {
					...state.bodyParts,
					[payload.part]: {
						...state.bodyParts[payload.part],
						form: action.payload.form
					}
				}
			};
		}

		case Types.VALIDATION_STEP: {
			return {
				...state,
				validateStep: payload
			};
		}

		case Types.VALIDATE_BODY_PARTS_STEP: {
			return {
				...state,
				bodyParts: {
					...state.bodyParts,
					[payload.part]: {
						...state.bodyParts[payload.part],
						...payload.step
					}
				}
			};
			// return {
			// 	...state,
			// 	bodyParts: {
			// 		...state.bodyParts,
			// 		...payload
			// 	}
			// };
		}

		case Types.GET_ANGLE_SCORE_LOADING: {
			return {
				...state,
				angleScore: {
					...state.angleScore,
					loading: !state.angleScore.loading
				}
			};
		}

		case Types.GET_ANGLE_SCORE_SUCCESS: {
			return {
				...state,
				angleScore: {
					...state.angleScore,
					data: action.payload
				}
			};
		}

		case Types.GET_ANGLE_SCORE_ERROR: {
			return {
				...state,
				angleScore: {
					...state.angleScore,
					error: action.payload
				}
			};
		}

		case Types.SET_BRIEF_BEST_PARAMETERS_HANDS: {
			const { force, ...others } = payload;
			return {
				...state,
				parameters: {
					...state.parameters,
					force: {
						...state.parameters.force,
						...force
					},
					...others
				}
			};
		}

		case Types.SET_BRIEF_BEST_PARAMETERS_ELBOWS: {
			return {
				...state,
				parameters: {
					...state.parameters,
					force: {
						...state.parameters.force,
						...payload.force
					}
				}
			};
		}

		case Types.SET_BRIEF_BEST_PARAMETERS_SHOULDERS: {
			return {
				...state,
				parameters: {
					...state.parameters,
					force: {
						...state.parameters.force,
						...payload.force
					}
				}
			};
		}

		case Types.SET_BRIEF_BEST_PARAMETERS_NECK: {
			return {
				...state,
				parameters: {
					...state.parameters,
					force: {
						...state.parameters.force,
						...payload.force
					}
				}
			};
		}

		case Types.SET_BRIEF_BEST_PARAMETERS_TRUNK: {
			return {
				...state,
				parameters: {
					...state.parameters,
					force: {
						...state.parameters.force,
						...payload.force
					}
				}
			};
		}

		case Types.SET_BRIEF_BEST_PARAMETERS_LEGS: {
			return {
				...state,
				parameters: {
					...state.parameters,
					force: {
						...state.parameters.force,
						...payload.force
					}
				}
			};
		}

		case Types.SET_BRIEF_BEST_PARAMETERS_EXTERNALS: {
			return {
				...state,
				parameters: { ...state.parameters, ...payload }
			};
		}

		case Types.GET_BRIEF_BEST_SCORE_LOADING: {
			return {
				...state,
				scoreBriefBest: {
					...state.scoreBriefBest,
					loading: !state.scoreBriefBest.loading
				}
			};
		}

		case Types.GET_BRIEF_BEST_SCORE_SUCCESS: {
			return {
				...state,
				scoreBriefBest: {
					...state.scoreBriefBest,
					data: {
						score: payload?.meanSeconds && Number.parseFloat(payload.meanSeconds).toFixed(2),
						seconds: payload?.seconds
					}
				}
			};
		}

		case Types.GET_BRIEF_BEST_SCORE_ERROR: {
			return {
				...state,
				scoreBriefBest: {
					...state.scoreBriefBest,
					error: action.payload
				}
			};
		}

		case Types.CLEAR_ERROR_REQUEST: {
			return {
				...state,
				scoreBriefBest: {
					...state.scoreBriefBest,
					error: null
				}
			};
		}

		default:
			return state;
	}
};

export default briefBestReducer;
