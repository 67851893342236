import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message, notification } from 'antd';
import { I18n } from '@aws-amplify/core';
import Api from '@/services/api';

import { CreateRiskDescriptionRequest } from '@/components/ui/Inputs/types/request/riskDescription';

export const useCreateRiskDescription = () => {
	const queryClient = useQueryClient();
	return useMutation(
		({ body }: { body: CreateRiskDescriptionRequest }) => Api.post('/custom-report/risk-description/create', body),
		{
			onError: (err: any) => {
				notification.error({
					message: I18n.get(err?.response?.data?.message || 'Ops... something happened!')
				});
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['risk_descriptions']);
				queryClient.invalidateQueries(['risk_descriptions_list']);
				message.success(I18n.get('Risk description created successfully'));
			}
		}
	);
};
