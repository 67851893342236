import React from 'react';
import { Col, Row } from 'antd';

import { useSocketReport } from '@/hooks';
import { ToolCard } from '@/components/ToolCard';
import { useEwaJdsD86Context } from '../../../context';
import type { ErgonomicTools, REPORT_TYPE } from '@/hooks';
import { useStepKeysContext } from './DefaultSelects/context';
import { AdditionalComponents, DefaultSelects, SubStepKeys } from './index';
import { PreviousValues } from './PreviousValues';
import { BodyPadding } from '@/components/ui/CollapseCheck/styles';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useGetCustomReportResultStepKeyDefaultData } from '@/hooks/useGetCustomReportResultStepKeyDefaultData';

enum Tools {
	niosh = 'niosh',
	kim_pp = 'kim_pp',
	kim_mho = 'kim_mho',
	strain_index = 'strain_index',
	liberty_mutual = 'liberty_mutual',
	back_compressive_force_estimation = 'back_compressive_force_estimation',
}

const reports: { [key in REPORT_TYPE]: Tools } = {
	niosh: Tools.niosh,
	'kim-pp': Tools.kim_pp,
	'strain-index': Tools.strain_index,
	'kim-mho': Tools.kim_mho,
	'liberty-mutual': Tools.liberty_mutual,
	'back-compressive-force-estimation': Tools.back_compressive_force_estimation
};

export const StepKey: React.FC = () => {
	const { niosh, kimPushPull, kimMHO, strainIndex, libertyMutual } = useSocketReport();
	const { file_id, stepKey, formFieldName } = useStepKeysContext();
	const { ewaJdsD86 } = useEwaJdsD86Context();
	const { organization, company } = useApplicationContext();

	const { data: customReportDefaultData, isFetching: gettingCustomReportDefaultData } = useGetCustomReportResultStepKeyDefaultData({
		company_id: company?.id,
		organization_id: organization?.id,
		step_key_id: stepKey.id,
		file_id,
	});

	const socketsValues: { [key: string]: boolean | undefined } = {
		niosh: niosh.status,
		'kim-mho': kimMHO.status,
		'kim-pp': kimPushPull.status,
		'strain-index': strainIndex.status,
		'liberty-mutual': libertyMutual.status,
		'back-compressive-force-estimation': false
	};

	function isActive(tool: ErgonomicTools): boolean {
		const reportTool = ewaJdsD86.tools[reports[tool.description]];

		return !!reportTool || !!socketsValues[tool.description];
	}

	if (stepKey?.sub_step_keys && stepKey.sub_step_keys.length > 0) {
		return <SubStepKeys subStepKeys={stepKey?.sub_step_keys} customReportDefaultData={customReportDefaultData} gettingCustomReportDefaultData={gettingCustomReportDefaultData} />;
	}

	return (
		<>
			<PreviousValues negative={true} />
			<BodyPadding>
				<Row justify="center">
					<Col sm={24} xxl={22}>
						<DefaultSelects stepKeyId={stepKey.id} formFieldName={formFieldName} customReportDefaultData={customReportDefaultData} gettingCustomReportDefaultData={gettingCustomReportDefaultData}>
							<Row gutter={[0, 20]}>
								{stepKey.ergonomic_tool?.map((tool) => (
									<Col key={tool.id}>
										<ToolCard
											fileId={file_id}
											title={tool.title}
											type={tool.description}
											isActive={isActive(tool)}
											subTitle={tool.subtitle}
											reportSelected={tool.description}
										/>
									</Col>
								))}
							</Row>

							{stepKey.additional_items && <AdditionalComponents additionalItems={stepKey.additional_items} defaultData={customReportDefaultData?.additional_item_results ?? []} />}
						</DefaultSelects>
					</Col>
				</Row>
			</BodyPadding>
		</>
	);
};
