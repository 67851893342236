import React, { useState, useEffect, useCallback } from 'react';
import { Row as Container, Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';

import { Header } from './Header';
import { List } from './List';
import { CreateUser } from './Create.js';
import { EditUser } from './Edit';

import Api from '@/services/api';
import Spinner from '@/components/Layout/Spinner';
import { useIsMounted } from '@/hooks/useIsMounted';

export function ManageUsers() {
	const isMounted = useIsMounted();

	const [users, setUsers] = useState(null);
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState(null);

	const [visible, setVisible] = useState(false);
	const [editable, setEditable] = useState(false);

	const [trigger, setTrigger] = useState(null);
	const [user, setUser] = useState(null);

	const triggerReload = () => setTrigger(!trigger);

	useEffect(() => {
		if (errors) {
			const result = errors.response?.data?.message || errors.message;
			const description = I18n.get(result);
			message.error(description);
			setErrors(null);
		}
	}, [errors]);

	const getUsers = useCallback(() => {
		Api.get('/user_management/user_list')
			.then((res) => isMounted() && setUsers(res.data))
			.catch((err) => isMounted() && setErrors(err))
			.finally(() => isMounted() && setLoading(false));
	}, [isMounted]);

	const onDelete = (id) => {
		Modal.confirm({
			title: I18n.get('Warning!'),
			icon: <ExclamationCircleOutlined />,
			content: I18n.get('Do you wish to delete this user?'),
			okText: I18n.get('Yes'),
			okType: 'danger',
			cancelText: I18n.get('No'),
			onOk() {
				return new Promise((resolve, reject) => {
					Api.post('/user_management/user_delete', { user_id: id })
						.then((res) => {
							triggerReload();
							resolve();
						})
						.catch((err) => {
							reject();
						});
				});
			},
		});
	};

	const onEdit = ({ id, name, email, role }) => {
		setVisible(true);
		setEditable(true);
		setUser({ id, name, email, role });
	};

	const onCreate = () => {
		setVisible(true);
		setEditable(false);
	};

	const onClose = () => {
		setVisible(false);
		setEditable(false);
		setUser(null);
	};

	useEffect(() => {
		getUsers();
	}, [getUsers, trigger]);

	if (loading) {
		return <Spinner />;
	}

	return (
		<Container>
			<Header onCreate={onCreate} />
			<List data={users} onEdit={onEdit} onDelete={onDelete} />
			<CreateUser
				onClose={onClose}
				visible={!editable && visible}
				triggerReload={triggerReload}
			/>
			<EditUser
				user={user}
				onClose={onClose}
				visible={editable && visible}
				triggerReload={triggerReload}
			/>
		</Container>
	);
}
