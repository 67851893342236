import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useApplicationContext } from '@/context/v1/Application/context';
import { LineSelect } from '@/components/ui/Inputs/LineSelect';
import { useLines } from '@/hooks/v1';

const { useFormInstance, useWatch } = Form;

export const Lines: React.FC = () => {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();
	const { company, organization } = useApplicationContext();

	const sectorId = useWatch(['sector_id'], form);
	const lineId = useWatch(['line_id'], form);

	const {
		data: lines,
		isLoading,
		isFetching,
		isError
	} = useLines({ organization_id: organization?.id, company_id: company?.id, sector_id: sectorId });

	function handleSelectLine(id: string) {
		setFieldValue(['line_id'], id);
		setFieldValue(['workstation_id'], undefined);
	}

	function handleClearLines() {
		setFieldValue(['line_id'], undefined);
		setFieldValue(['workstation_id'], undefined);
	}

	return (
		<Form.Item name={['line_id']} label={I18n.get('Line')} labelCol={{ span: 24 }}>
			<LineSelect
				lines={lines}
				lineId={lineId}
				sectorId={sectorId}
				loading={isFetching}
				allowCreation={false}
				fieldName={['line_id']}
				handleSelectLine={handleSelectLine}
				handleClearLines={handleClearLines}
				disabled={isLoading || isFetching || isError || !sectorId}
			/>
		</Form.Item>
	);
};
