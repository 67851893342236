import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form, message, notification } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import { Prompt, useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import FileSize from 'filesize';
import axios from 'axios';
import _ from 'lodash';

import { useApplicationContext } from '@/context/v1/Application/context';
import { useCustomReports } from '../BeraJob/hooks/hooks';
import { UploadedFiles } from './types';
import Api from '@/services/api';
import ListUpload from './List';
import { Drop } from './Drop';

const { useForm } = Form;
const TIMEOUT_REQUEST = 1800000;
const FILE_TOO_LARGE = 'file-too-large';
const TOO_MANY_FILES = 'too-many-files';
const FILE_MAX_SIZE = process.env.REACT_APP_MAX_FILE_UPLOAD_SIZE_GB || 2;
const MAX_FILES = Number(process.env.REACT_APP_MAX_FILES) || 10;

export const Upload: React.FC = () => {
	const { organization, company } = useApplicationContext();

	const [form] = useForm();
	const history = useHistory();
	const [errors, setErrors] = useState<any>(null);

	const { expired, canceled } = useSelector((state: any) => ({
		expired: state.plan.expired,
		canceled: state.plan.canceled
	}));

	const [uploadedFiles, setUploadedFiles] = useState([] as UploadedFiles[]);
	const [inProcessUpload, setInProcessUpload] = useState<boolean>(false);

	const [fileNotProcessed, setFileNotProcessed] = useState<boolean>(false);
	const [redirectOnSuccess, setRedirectOnSuccess] = useState<boolean>(false);

	const customReports = useCustomReports({
		organization_id: organization?.id,
		company_id: company?.id
	});

	useEffect(() => {
		if (errors) {
			const stack = errors.response?.data?.message;
			message.error(I18n.get(stack || 'Error loading video'));
			setErrors(null);
		}
	}, [errors]);

	useEffect(() => {
		uploadedFiles.forEach((file) => {
			if (!file.blocking) {
				setFileNotProcessed(true);
			}
			setRedirectOnSuccess(file.uploaded ? true : false);
		});
	}, [uploadedFiles, fileNotProcessed]);

	useEffect(() => {
		let checkAllFiles = 0;
		uploadedFiles.forEach((file) => {
			file.uploaded && checkAllFiles++;
		});
		const compareInAllFiles = checkAllFiles === uploadedFiles.length;

		if (redirectOnSuccess && compareInAllFiles) {
			const filesAmountWithCustomReport = [...uploadedFiles].filter((file) => file.custom_report);
			const filesAmountWithoutCustomReport = [...uploadedFiles].filter((file) => !file.custom_report);
			let url = '/reporting';
			if (mostVideosHaveCustomReport(filesAmountWithCustomReport, filesAmountWithoutCustomReport)) {
				url = '/custom-reports/files';
			}
			message
				.loading(I18n.get('You are being redirected'), 5)
				.then(() => setInProcessUpload(false))
				.then(() => history.push(url));
		}
	}, [redirectOnSuccess, uploadedFiles, history]);

	function mostVideosHaveCustomReport(
		filesAmountWithCustomReport: UploadedFiles[],
		filesAmountWithoutCustomReport: UploadedFiles[]
	) {
		return filesAmountWithCustomReport.length > filesAmountWithoutCustomReport.length;
	}

	const errorFormater = (errors: any) => {
		return errors
			.map(({ code }: any) => {
				if (code === FILE_TOO_LARGE) {
					return `${I18n.get('is larger than')} ${FILE_MAX_SIZE} Gigabytes`;
				}
				if (code === TOO_MANY_FILES) {
					return I18n.get('Files number exceeded');
				}

				return I18n.get('Unsupported video format');
			})
			.join(` ${I18n.get('and')} `);
	};

	const rejectMessage = (error: any) => {
		error.forEach((elem: any, index: any, array: any) => {
			if (array.length - 1 === index) {
				notification.error({
					message: `${elem.file.name}`,
					description: `${I18n.get(errorFormater(elem.errors))}`
				});
			}
		});
	};

	const hasDuplicate = ({ files, uploadedFiles }: { files: File[]; uploadedFiles: UploadedFiles[] }) => {
		let sameName;
		let sameSize;
		files.forEach((file) => {
			sameName = _.some(uploadedFiles, { name: file.name });
			sameSize = _.some(uploadedFiles, { size_number: file.size });
		});
		if (sameName && sameSize) {
			notification.error({
				message: `${I18n.get('...Ops!')}`,
				description: `${I18n.get('File duplicated')}`
			});
			return true;
		}
		return false;
	};

	const handleUpload = (files: File[]) => {
		const sumFiles = uploadedFiles.length + files.length;
		let isDuplicated = false;

		if (uploadedFiles.length > 0) {
			isDuplicated = hasDuplicate({ files, uploadedFiles });
		}

		if (sumFiles <= MAX_FILES && !isDuplicated) {
			const arrayFiles = files.map((file) => ({
				file,
				id: _.uniqueId(),
				name: file.name,
				readableSize: FileSize(file.size),
				size_number: file.size,
				preview: URL.createObjectURL(file),
				progress: 0,
				uploaded: false,
				error: false,
				url: null,
				blur: false,
				custom_report: null,
				tool_type: 'rula',
				blocking: false
			}));
			setUploadedFiles((current) => current.concat(arrayFiles));
		} else if (!isDuplicated) {
			notification.error({
				message: `${I18n.get('...Ops!')}`,
				description: `${I18n.get('Files number exceeded')}`
			});
		}
	};

	const onDelete = (id: string) => {
		setUploadedFiles((current) => current.filter((file) => file.id !== id));
	};

	const updateFile = (id: string, data: any) => {
		setUploadedFiles((current: any) =>
			current.map((uploadedFile: any) => (id === uploadedFile.id ? { ...uploadedFile, ...data } : uploadedFile))
		);
	};

	const onSubmit = async () => {
		await Promise.all(uploadedFiles.map(processUpload));
	};

	const onClear = () => {
		form.setFieldsValue({ tool: 'rula' });
		setUploadedFiles([]);
	};

	const updateProgressUpload = (e: any, id: any) => {
		const progress = Math.round((e.loaded * 100) / e.total);
		updateFile(id, { progress, blocking: true });
	};

	const processUpload = async (uploadedFile: any) => {
		if (!uploadedFile) return;

		const { id, file, blur, custom_report, size_number } = uploadedFile;

		try {
			setInProcessUpload(true);
			updateFile(id, { progress: 1, blocking: true });

			const { data } = await Api.post('/upload/create-url-signed', {
				blur: blur,
				custom_report,
				file_name: file.name,
				company_id: company.id,
				content_type: file.type,
				organization_id: organization.id,
				size_number: size_number
			});

			const headers = {
				onUploadProgress: (e: any) => updateProgressUpload(e, id),
				timeout: TIMEOUT_REQUEST,
				headers: {
					'Content-Type': file.type
				}
			};

			const upload = await axios.put(data.url, file, headers);

			if (upload.status === 200) {
				await Api.post('/upload/add-queue', {
					organization_id: organization.id,
					company_id: company.id,
					file_id: data.id
				});
				updateFile(id, { uploaded: true, blocking: true });
				return;
			}

			throw new Error(I18n.get('Failed to upload the file'));
		} catch (error) {
			updateFile(id, { error: true, blocking: false });
			setErrors(error);
		}
	};

	return (
		<Row align="middle" justify="center">
			<Col span={24}>
				<Prompt
					when={inProcessUpload && !redirectOnSuccess}
					message={I18n.get('Are you sure you want to exit? You have an uploads in process.')}
				/>
			</Col>
			<Col sm={24} md={18} lg={18}>
				<Drop
					onUpload={handleUpload}
					disabled={expired || canceled || inProcessUpload}
					rejectMessage={rejectMessage}
				/>
			</Col>
			<Col sm={24} md={18} lg={18}>
				<ListUpload
					onDelete={onDelete}
					files={uploadedFiles}
					onUpdate={updateFile}
					customReports={customReports}
					inProcessUpload={inProcessUpload}
				/>
				{!!uploadedFiles.length && (
					<Button
						type="primary"
						onClick={onSubmit}
						style={{ float: 'right' }}
						icon={<CloudUploadOutlined />}
						disabled={inProcessUpload || !fileNotProcessed}
					>
						{I18n.get('Send')}
					</Button>
				)}
				{!!uploadedFiles.length && (
					<Button
						onClick={onClear}
						disabled={inProcessUpload || !fileNotProcessed}
						style={{ float: 'right', marginRight: '5px' }}
					>
						{I18n.get('Clear')}
					</Button>
				)}
			</Col>
		</Row>
	);
};
