import React from 'react';
import {
	Chart as ChartJS,
	LinearScale,
	BarElement,
	Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { SumRpn } from '@/hooks/useGetCustomReportReviewHistory';
import { riskColors } from '@/utils/riskRange';
import { Col, Grid, Row } from 'antd';
import { Title } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

ChartJS.register(
	LinearScale,
	BarElement,
	Tooltip,
);

type Props = {
	data: SumRpn[]
}

export function SumRPN({ data }: Props) {
	const dataConfig = {
		labels: data.map(m => [m.name.split(' ').map(m => I18n.get(m)).join(' '), moment(m.created_at).format('L')]),
		datasets: [
			{
				label: '',
				backgroundColor: '#2F54EB',
				data: data.map(m => m.rpn),
				barThickness: 79,
			}
		]
	}

	const breakpoints = Grid.useBreakpoint();

	return (
		<>
			<Col xs={24} style={{ marginTop: '1rem' }}>
				<Title level={3} align="center">
					{I18n.get('Sum of RPN')}
				</Title>
			</Col>
			<Col xs={24} style={{ marginTop: '1rem' }}>
				<Bar options={{
					responsive: true,
					plugins: {
						tooltip: {
							yAlign: 'center',
							xAlign: 'center',
						},
						legend: {
							display: false
						},
						title: {
							display: true,
							align: 'center',
							text: I18n.get('Sum of RPN'),
							position: 'left',
							color: '#000',
							font: {
								size: 16,
								weight: '500',
							}
						},
						datalabels: {
							display: false
						},
					},
					scales: {
						x: {
							ticks: {
								maxRotation: 90,
								minRotation: breakpoints.xl ? 0 : 90,
								font: {
									size: 14,
									weight: 'medium',
								},
								color: '#000',
							}
						}
					}
				}} data={dataConfig} />
			</Col>
			<Col xs={24} style={{ marginTop: '1rem' }}>
				<Title level={5} style={{ fontWeight: '500' }} align="center">
					{I18n.get('Version')}
				</Title>
			</Col>
		</>
	);
}
