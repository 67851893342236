import Types from './types';

const companyStorage = JSON.parse(localStorage.getItem('company') || null);
const organizationStorage = JSON.parse(localStorage.getItem('organization') || null);

const initState = {
	loading: false,
	company: companyStorage,
	organization: organizationStorage,
	modalSelect: false,
	triggerGetCompany: null,
	errors: null,
	errorsSelect: null,
	loadingSelectOrg: false,
	loadingSelectCompany: false,

	// new
	modal: {
		visible: false,
		loading: false
	},
	companiesFilter: {
		loading: false,
		error: null,
		data: []
	},
	newOrganization: {
		loading: false,
		data: null,
		error: null
	},
	newCompany: {
		loading: false,
		data: null,
		error: null
	},
	listCompany: {
		loading: false,
		data: null,
		error: null
	},

	// *******
	listOrganization: {
		loading: false,
		data: null,
		error: null
	},
	listCompanies: {
		loading: false,
		data: null,
		error: null
	},
	listSectors: {
		loading: false,
		data: null,
		error: null
	}
};

const organizationReducer = (state = initState, action) => {
	switch (action.type) {
		case Types.CHANGE_PROJECT_SELECTED: {
			return {
				...state,
				organization: null,
				company: null,
				triggerGetCompany: null
			};
		}

		// ******************* //

		case Types.LIST_ORGANIZATION_LOADING: {
			return {
				...state,
				listOrganization: {
					...state.listOrganization,
					loading: !state.listOrganization.loading
				}
			};
		}

		case Types.LIST_ORGANIZATION_SUCCESS: {
			return {
				...state,
				listOrganization: {
					...state.listOrganization,
					data: action.payload
				}
			};
		}

		case Types.LIST_ORGANIZATION_ERROR: {
			return {
				...state,
				listOrganization: {
					...state.listOrganization,
					error: action.payload
				}
			};
		}

		case Types.LIST_COMPANIES_LOADING: {
			return {
				...state,
				listCompanies: {
					...state.listCompanies,
					loading: !state.listCompanies.loading
				}
			};
		}

		case Types.LIST_COMPANIES_SUCCESS: {
			return {
				...state,
				listCompanies: {
					...state.listCompanies,
					data: action.payload
				}
			};
		}

		case Types.LIST_COMPANIES_ERROR: {
			return {
				...state,
				listCompanies: {
					...state.listCompanies,
					error: action.payload
				}
			};
		}

		case Types.LIST_SECTORS_LOADING: {
			return {
				...state,
				listSectors: {
					...state.listSectors,
					loading: !state.listSectors.loading
				}
			};
		}

		case Types.LIST_SECTORS_SUCCESS: {
			return {
				...state,
				listSectors: {
					...state.listSectors,
					data: action.payload
				}
			};
		}

		case Types.LIST_SECTORS_ERROR: {
			return {
				...state,
				listSectors: {
					...state.listSectors,
					error: action.payload
				}
			};
		}

		// ************************ //

		case Types.CLEAR_NEW_ORGANIZATION: {
			return {
				...state,
				newOrganization: {
					loading: false,
					data: null,
					error: null
				}
			};
		}

		case Types.CLEAR_NEW_COMPANY: {
			return {
				...state,
				newCompany: {
					...state.newCompany,
					data: null,
					error: null
					// loading: false,
					// data: null,
					// error: null
				}
			};
		}

		case Types.CLEAR_ORGANIZATION: {
			return {
				...state,
				organization: initState.organization
			};
		}

		case Types.CLEAR_LIST_COMPANIES: {
			return {
				...state,
				listCompany: initState.listCompany
			};
		}

		case Types.GET_ORGANIZATION_LOADING: {
			return {
				...state,
				listOrganization: {
					...state.listOrganization,
					loading: !state.listOrganization.loading
				}
			};
		}

		case Types.GET_ORGANIZATION_SUCCESS: {
			return {
				...state,
				listOrganization: {
					...state.listOrganization,
					data: action.payload
				}
			};
		}

		case Types.GET_ORGANIZATION_ERROR: {
			return {
				...state,
				listOrganization: {
					...state.listOrganization,
					error: action.payload
				}
			};
		}

		case Types.CREATE_COMPANY_LOADING: {
			return {
				...state,
				newCompany: {
					...state.newCompany,
					loading: !state.newCompany.loading
				}
			};
		}

		case Types.CREATE_COMPANY_SUCCESS: {
			return {
				...state,
				newCompany: {
					...state.newCompany,
					data: action.payload
				}
			};
		}

		case Types.CREATE_COMPANY_ERROR: {
			return {
				...state,
				newCompany: {
					...state.newCompany,
					error: action.payload
				}
			};
		}

		case Types.GET_LIST_COMPANIES_LOADING: {
			return {
				...state,
				listCompany: {
					...state.listCompany,
					loading: !state.listCompany.loading
				}
			};
		}

		case Types.GET_LIST_COMPANIES_SUCCESS: {
			return {
				...state,
				listCompany: {
					...state.listCompany,
					data: action.payload
				}
			};
		}

		case Types.GET_LIST_COMPANIES_ERROR: {
			return {
				...state,
				listCompany: {
					...state.listCompany,
					error: action.payload
				}
			};
		}

		case Types.CREATE_ORGANIZATION_LOADING: {
			return {
				...state,
				newOrganization: {
					...state.newOrganization,
					loading: !state.newOrganization.loading
				}
			};
		}

		case Types.CREATE_ORGANIZATION_SUCCESS: {
			return {
				...state,
				newOrganization: {
					...state.newOrganization,
					data: action.payload
				}
			};
		}

		case Types.CREATE_ORGANIZATION_ERROR: {
			return {
				...state,
				newOrganization: {
					...state.newOrganization,
					error: action.payload
				}
			};
		}

		case Types.REQUEST_LOADING: {
			return { ...state, loading: !state.loading };
		}

		case Types.REQUEST_LOADING_SELECT: {
			return { ...state, loadingSelectOrg: !state.loadingSelectOrg };
		}

		case Types.REQUEST_LOADING_SELECT_COMPANY: {
			return {
				...state,
				loadingSelectCompany: !state.loadingSelectCompany
			};
		}

		case Types.LIST_SELECT_FAILURE: {
			return { ...state, errorsSelect: action.payload };
		}

		case Types.MODAL_SELECT: {
			return { ...state, modalSelect: !state.modalSelect };
		}

		case Types.LIST_ORG_SUCCESS: {
			return { ...state, listOrganization: action.payload };
		}

		case Types.LIST_ORG_FAILURE: {
			return { ...state, errors: action.payload };
		}

		case Types.SET_ORGANIZATION: {
			return {
				...state,
				triggerGetCompany: action.payload.id,
				organization: action.payload
			};
		}

		case Types.SET_COMPANY: {
			return {
				...state,
				company: action.payload,
				modalSelect: false
			};
		}

		case Types.LIST_COMPANY_SUCCESS: {
			return { ...state, listCompany: action.payload };
		}

		case Types.LIST_COMPANY_FAILURE: {
			return { ...state, errors: action.payload };
		}

		case Types.MODAL_SELECT_PROJECT: {
			return {
				...state,
				modal: {
					visible: !state.modal.visible
				}
			};
		}

		case Types.MODAL_SELECT_PROJECT_LOADING: {
			return {
				...state,
				modal: {
					loading: !state.modal.loading
				}
			};
		}

		case Types.CHANGE_PROJECT: {
			return {
				...state,
				organization: null,
				company: null,
				triggerGetCompany: null
			};
		}

		case Types.COMPANIES_FILTER_LOADING: {
			return {
				...state,
				companiesFilter: {
					...state.companiesFilter,
					loading: !state.companiesFilter.loading
				}
			};
		}

		case Types.COMPANIES_FILTER: {
			return {
				...state,
				companiesFilter: {
					...state.companiesFilter,
					data: action.payload
				}
			};
		}

		case Types.COMPANIES_FILTER_ERROR: {
			return {
				...state,
				companiesFilter: {
					...state.companiesFilter,
					error: action.payload
				}
			};
		}

		default:
			return state;
	}
};

export default organizationReducer;
