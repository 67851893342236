import React, { ReactNode } from 'react';

import { Container } from './styles';
import { Header } from './Header';

interface TwoFactoAuthenticatorProps {
	children: ReactNode;
}

export const TwoFactorAuthenticator: React.FC<TwoFactoAuthenticatorProps> = ({ children }) => {
	return (
		<Container justify="center">
			<Header />
			{children}
		</Container>
	);
};
