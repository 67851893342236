import React from 'react';
import { Text } from '@/components/Typography';
import { Col, Row, Skeleton } from 'antd';
import { I18n } from '@aws-amplify/core';

import { PaginationStyled, SelectStyled } from './styles';

interface PaginationStyledProps {
	loading: boolean;
	page: number;
	maxPage: number;
	setMaxPage?: (size: number) => void;
	setPage: (size: number) => void;
	total: number;
}

type SelectOption = {
	value: number;
	label: string;
};

export const PaginationCustom: React.FC<PaginationStyledProps> = ({
	page,
	total,
	loading,
	maxPage,
	setMaxPage,
	setPage
}) => {
	const selectOptions: SelectOption[] = [5, 10, 20, 30, 50, 100, 200].map((option) => ({
		label: `${option}`,
		value: option
	}));

	const handleChange = (value: any) => {
		if (setMaxPage) {
			setMaxPage(value);
		}
		setPage(1);
	};

	return (
		<>
			{loading ? (
				<Row justify={'space-between'} style={{ marginTop: '1rem', padding: '0 1rem' }}>
					<Col>
						<Skeleton.Input active />
					</Col>
					<Col>
						<Skeleton.Input active />
					</Col>
				</Row>
			) : (
				<Row justify={'space-between'} style={{ marginTop: '1rem', padding: '0 1rem' }}>
					<Col>
						{setMaxPage &&
							<>
								<Text>{I18n.get('Items per page')}</Text>
								<SelectStyled defaultValue={maxPage} options={selectOptions} onChange={handleChange} />
							</>
						}
					</Col>
					<Col>
						<PaginationStyled
							hideOnSinglePage={false}
							showSizeChanger={false}
							total={total}
							current={page === 0 ? page + 1 : page}
							pageSize={maxPage}
							onChange={(page) => {
								setPage(page);
							}}
						/>
					</Col>
				</Row>
			)}
		</>
	);
};
