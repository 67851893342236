import Api from '@/services/api';

import {
	DownloadPDF,
	CreateReport,
	UpdateComment,
	GetReportRequest,
	GetResultRequest,
	GetSystemsOfUnitsRequest,
	GetDownloadVideoURLRequest,
	UpdateReportBasicInformation,
	DeleteReportInput
} from '../types/request';
import { SystemOfUnitsDTO, LibertyMutualReportDTO, LibertyMutualResultDTO } from '../types/response';
import { LibertyMutualApiServiceInterface } from './servicesInterfaces';

export class LibertyMutualApiService implements LibertyMutualApiServiceInterface {
	private libertyMutualBaseUrl = '/ergonomic-tool/liberty-mutual';

	public async getResults(payload: GetResultRequest): Promise<LibertyMutualResultDTO> {
		const url = this.libertyMutualBaseUrl + `/result/${payload.file_id}`;
		const { data } = await Api.get<LibertyMutualResultDTO>(url, {
			params: payload
		});
		return data;
	}

	public async getReport(payload: GetReportRequest) {
		const url = this.libertyMutualBaseUrl + `/${payload.file_id}`;
		const { data } = await Api.get<LibertyMutualReportDTO>(url, {
			params: payload
		});
		return data;
	}

	public async getSystemsOfUnits(payload: GetSystemsOfUnitsRequest) {
		const url = '/system-of-units';
		const { data } = await Api.get<SystemOfUnitsDTO[]>(url, {
			params: payload
		});
		return data;
	}

	public async createReport(payload: CreateReport) {
		const url = this.libertyMutualBaseUrl;
		const { data } = await Api.post<LibertyMutualReportDTO>(url, payload);
		return data;
	}

	public async deleteReportInput(payload: DeleteReportInput) {
		const url = this.libertyMutualBaseUrl + `/report/input/${payload.report_id}`;
		const { data } = await Api.delete<LibertyMutualReportDTO>(url, {
			params: payload
		});
		return data;
	}

	public async updateReportBasicInformation(payload: UpdateReportBasicInformation) {
		const url = this.libertyMutualBaseUrl + `/basic-info/${payload.report_id}`;
		const { data } = await Api.put<LibertyMutualReportDTO>(url, payload);
		return data;
	}

	public async updateComment(payload: UpdateComment) {
		const url = this.libertyMutualBaseUrl + `/comment/${payload.file_id}`;
		const { data } = await Api.put<LibertyMutualReportDTO>(url, payload);
		return data;
	}

	public async getDownloadVideoURL(payload: GetDownloadVideoURLRequest) {
		const url = '/upload/create-url-download';
		const { data } = await Api.post<string>(url, payload);
		return data;
	}

	public async downloadPDF(payload: DownloadPDF) {
		const url = this.libertyMutualBaseUrl + '/document/pdf';
		const { data } = await Api.post<string>(url, payload);
		return data;
	}
}
