import Types from './types.js';

const initState = {
	max_users: 0,
	expiration_plan: null,
	canceled_on: null,
	type: null,
	expired: false,
	canceled: false
};

const planReducer = (state = initState, action) => {
	switch (action.type) {
		case Types.SET_PLAN_DETAILS: {
			return {
				...state,
				...action.payload
			};
		}

		default:
			return state;
	}
};

export default planReducer;
