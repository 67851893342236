import React from 'react';
import { Avatar, Col, Row } from 'antd';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Title } from '@/components/Typography';

import { IconContainer, RecoveryTokensContainer } from './styles';
import { useTFAContext } from '../context';

export const RecoveryTokens = () => {
	const { recoveryTokens } = useTFAContext();

	return (
		<RecoveryTokensContainer align="middle" justify="center">
			<Col span={22}>
				<IconContainer justify="center">
					<Col span={'auto'}>
						<Avatar size={64} icon={<ExclamationCircleOutlined />} />
					</Col>
				</IconContainer>
				<Row justify="center">
					<Col span={24}>
						<Row justify="center">
							<Title align="center" level={5}>
								Recovery tokens
							</Title>
						</Row>
						<Row justify="center">
							<Title align="center" style={{ fontWeight: '400' }} level={5}>
								Here are your recovery codes, which will be requested should you need to recover your
								account. Keep in mind that they will not be accessible again after this moment, so it's
								crucial that you save them securely.
							</Title>
						</Row>
						<Row justify="center" style={{ margin: '2rem 0 1rem 0' }}>
							{recoveryTokens.map((token) => (
								<Col key={token} span={12}>
									<Title align="center" style={{ fontWeight: '600', fontSize: '1rem' }}>
										{token}
									</Title>
								</Col>
							))}
						</Row>
					</Col>
				</Row>
			</Col>
		</RecoveryTokensContainer>
	);
};
