import React, { useState } from 'react';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, Modal, Row, Table } from 'antd';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import { useApplicationContext } from '@/context/v1/Application/context';
import { ManageCategoryProvider } from './ManageCategoryModal/context';
import { useDeleteCategory, useGetCategories } from './hooks';
import { ManageCategoryModal } from './ManageCategoryModal';
import { CategoryResponse } from './hooks/types/response';
import { EditCategory } from './EditCategory';
import { FilterOptions } from './Filter/types';
import { Actions } from './Actions';
import { Filter } from './Filter';

interface CategoriesTableProps {
	stepKeyId: string;
}

export const CategoriesTable: React.FC<CategoriesTableProps> = ({ stepKeyId }) => {
	const { company, organization } = useApplicationContext();

	const [limit, setLimit] = useState<number>(10);
	const [offset, setOffset] = useState<number>(0);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [filter, setFilter] = useState<FilterOptions | null>(null);
	const [editingCategoryId, setEditingCategoryId] = useState<string>('');
	const [openManageCategoryModal, setOpenManageCategoryModal] = useState<boolean>(false);
	const [categoryClicked, setCategoryClicked] = useState<CategoryResponse>({} as CategoryResponse);

	const {
		data: categories,
		isFetching: gettingCategories,
		isError: errorGettingCategories
	} = useGetCategories({
		limit,
		offset,
		filter,
		company_id: company?.id,
		organization_id: organization?.id,
		custom_report_step_key_id: stepKeyId
	});

	const { mutateAsync: deleteCategory } = useDeleteCategory();

	if (errorGettingCategories) {
		return <div>{I18n.get('Unable to show results')}</div>;
	}

	function handleManageCategoryModal(category: CategoryResponse) {
		setCategoryClicked(category);
		setOpenManageCategoryModal(!openManageCategoryModal);
	}

	function handleIsEditing(editing: boolean) {
		setIsEditing(editing);
	}

	function updateCategory(e: React.MouseEvent<HTMLElement, MouseEvent>, id: string) {
		e.stopPropagation();
		e.preventDefault();

		const editingCategory = categories.rows.find((category) => category?.id === id);

		if (editingCategory) {
			setIsEditing(true);
			setEditingCategoryId(id);
		}
	}

	async function destroyCategory(e: React.MouseEvent<HTMLElement, MouseEvent>, id: string) {
		e.stopPropagation();
		e.preventDefault();

		const content = I18n.get('All risk descriptions related to it will be deleted. Do you wish to proceed?');

		Modal.confirm({
			title: I18n.get('Warning!'),
			icon: <ExclamationCircleOutlined />,
			content: content,
			okType: 'danger',
			okText: I18n.get('Yes'),
			cancelText: I18n.get('Cancel'),
			onOk: async () => {
				await deleteCategory({ id, company_id: company?.id, organization_id: organization?.id });
			}
		});
	}

	const columns: ColumnsType<CategoryResponse> = [
		{
			title: I18n.get('Name'),
			dataIndex: 'description',
			key: 'description',
			align: 'center',
			width: '300px',
			sorter: (a, b) => a.description.localeCompare(b.description),
			render: (description: string, category) => {
				return isEditing && category?.id === editingCategoryId ? (
					<EditCategory onEditing={handleIsEditing} category={category} categories={categories.rows} />
				) : (
					I18n.get(description)
				);
			}
		},
		{
			title: I18n.get('Total risk descriptions'),
			dataIndex: 'risk_description',
			key: 'risk_description',
			align: 'center',
			render: (risk_description: string[]) => risk_description?.length || 0,
			sorter: (a, b) =>
				(a.risk_description ? a.risk_description.length : 0) -
				(b.risk_description ? b.risk_description.length : 0)
		},
		{
			title: I18n.get('Date of creation'),
			dataIndex: 'created_at',
			key: 'created_at',
			align: 'center',
			render: (created_at: string) => moment(created_at).format('L'),
			sorter: (a, b) => moment(a.created_at).valueOf() - moment(b.created_at).valueOf()
		},
		{
			title: I18n.get('Actions'),
			dataIndex: 'url',
			key: 'url',
			align: 'center',
			render: (_, category) => (
				<Actions
					isEditing={isEditing}
					category={category}
					onUpdate={updateCategory}
					onDelete={destroyCategory}
					categories={categories.rows}
					onManageCategoryModal={() => handleManageCategoryModal(category)}
				/>
			)
		}
	];

	function handleFilter(filters: FilterOptions | null) {
		setFilter(filters);
	}

	const paginationConfig: TablePaginationConfig = {
		hideOnSinglePage: false,
		showSizeChanger: true,
		total: categories.count,
		current: offset === 0 ? offset + 1 : offset,
		defaultPageSize: limit,
		pageSizeOptions: ['5', '10', '20', '30', '50', '100'],
		pageSize: limit,
		onChange: (page) => {
			setOffset(page);
		},
		onShowSizeChange: (_, size) => setLimit(size)
	};

	return (
		<Row gutter={[0, 10]}>
			<Col span={24}>
				<Filter onFilter={handleFilter} />
			</Col>
			<Col span={24}>
				<Table
					rowKey="id"
					columns={columns}
					showSorterTooltip={false}
					loading={gettingCategories}
					dataSource={categories.rows}
					pagination={paginationConfig}
				/>
			</Col>
			{openManageCategoryModal && (
				<ManageCategoryProvider>
					<ManageCategoryModal
						stepKeyId={stepKeyId}
						category={categoryClicked}
						isModalOpen={openManageCategoryModal}
						onModalOpen={setOpenManageCategoryModal}
					/>
				</ManageCategoryProvider>
			)}
		</Row>
	);
};
