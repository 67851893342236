import React, { ReactElement, useState } from 'react';
import { Select, Form, Skeleton, Button, Input, Divider } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { NamePath } from 'antd/lib/form/interface';
import { I18n } from '@aws-amplify/core';

import { RiskCategory } from '@/types';
import { useApplicationContext } from '@/context/v1/Application/context';
import { CreateRiskCategoryRequest } from '../types/request/riskCategory';
import { useCreateRiskCategory } from '@/hooks/v1/useRiskCategoryMutation';

const { useFormInstance } = Form;

type RiskCategorySelectProps = {
	size?: SizeType;
	loading: boolean;
	disabled: boolean;
	stepKeyId?: string;
	fieldName: NamePath;
	allowCreation?: boolean;
	riskCategoryId?: string;
	handleClearRiskCategory?: () => void;
	riskCategories: RiskCategory[] | undefined;
	handleSelectRiskCategory?: (id: string) => void;
};

type OptionType = { label: string; value: string } | undefined;

export function RiskCategorySelect({
	loading,
	disabled,
	fieldName,
	stepKeyId,
	riskCategories,
	riskCategoryId,
	size = 'middle',
	allowCreation = false,
	handleClearRiskCategory,
	handleSelectRiskCategory
}: Readonly<RiskCategorySelectProps>) {
	const [riskCategoryName, setRiskCategoryName] = useState<string>('');

	const { company, organization } = useApplicationContext();
	const { validateFields } = useFormInstance();

	const { mutateAsync: createRiskCategory, isLoading } = useCreateRiskCategory();

	async function onCreateRiskCategory() {
		const body: CreateRiskCategoryRequest = {
			company_id: company.id,
			description: riskCategoryName,
			organization_id: organization.id,
			custom_report_step_key_id: stepKeyId
		};

		const { data: riskCategory } = await createRiskCategory({ body });
		const riskCategoryId = riskCategory?.id;
		if (riskCategoryId) {
			handleChange(riskCategoryId);
		}
		setRiskCategoryName('');
	}

	async function handleChange(id: string) {
		if (handleSelectRiskCategory) {
			handleSelectRiskCategory(id);
			await validateFields([fieldName]);
		}
	}

	function handleClear() {
		if (handleClearRiskCategory) {
			handleClearRiskCategory();
		}
	}

	function handleRiskCategoryNameChange(name: string) {
		setRiskCategoryName(name);
	}

	function filterOption(option: OptionType, input: string): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function showDropdownrender(menu: ReactElement): JSX.Element {
		if (!allowCreation) {
			return <div>{menu}</div>;
		}
		return (
			<div>
				{menu}
				<Divider style={{ margin: '4px 0' }} />
				<div
					style={{
						display: 'flex',
						flexWrap: 'nowrap',
						padding: 8
					}}
				>
					<Input
						style={{ flex: 'auto', borderRadius: '7px' }}
						value={riskCategoryName}
						onChange={(e) => handleRiskCategoryNameChange(e.target.value)}
					/>
					<Button
						onClick={onCreateRiskCategory}
						type="link"
						loading={isLoading}
						disabled={!riskCategoryName || riskCategoryName.length < 3}
					>
						{I18n.get('Add item')}
					</Button>
				</div>
			</div>
		);
	}

	if (loading) {
		return <Skeleton.Input block active size="small" />;
	}

	return (
		<Select
			showSearch
			allowClear
			size={size}
			loading={loading}
			disabled={disabled}
			onClear={handleClear}
			onChange={handleChange}
			value={riskCategoryId}
			placeholder={I18n.get('Risk category')}
			dropdownRender={(menu) => showDropdownrender(menu)}
			filterOption={(input, option) => filterOption(option, input)}
			options={riskCategories?.map(({ id, description }) => ({ label: I18n.get(description), value: id }))}
		/>
	);
}
