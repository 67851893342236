import React from 'react';
import { Row, Col, Skeleton } from 'antd';

interface LoadingSkeletonProps {
	loading: boolean;
}

export function LoadingSkeleton({ loading }: LoadingSkeletonProps) {
	return (
		<Row justify="center">
			<Col xs={24} xxl={20}>
				<Row justify="center" gutter={[32, 32]}>
					<Col xs={6} lg={4} xxl={3} style={{ marginTop: '3.5rem' }}>
						<Skeleton active loading={loading} />
					</Col>
					<Col xs={18}>
						<Row justify="start" gutter={[16, 24]}>
							<Col xs={24} xl={20}>
								<Row justify="center">
									<Skeleton.Input active />
								</Row>
							</Col>
							<Col xs={24} xl={20}>
								<Row justify="center">
									<Skeleton active loading={loading} />
								</Row>
								<Row justify="center" gutter={[16, 16]}>
									<Col>
										<Skeleton.Button active />
									</Col>
									<Col>
										<Skeleton.Button active />
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
