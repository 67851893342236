import Api from '@/services/api';

import {
	DeleteCategoryRequest,
	CreateCategoryRequest,
	UpdateCategoryRequest,
	GetCategoriesRequest
} from '../types/request';
import { CustomReportRequest, CustomReportStepsRequest } from '@/components/ui/Inputs/types/request/customReport';
import { CustomReportDTO, CustomReportStepDTO, RiskCategoryDTO } from '@/components/ui/Inputs/types/response';
import { CategoryPaginated } from '../types/response';

export class ManageCategoryService {
	public async fetchCategories(params: GetCategoriesRequest) {
		const baseUrl = `/custom-report/risk-category/find-all/${params.custom_report_step_key_id}`;
		const { data } = await Api.get<CategoryPaginated>(baseUrl, {
			params: {
				...params.filter,
				...params
			}
		});
		return data;
	}

	public async createCategory(body: CreateCategoryRequest) {
		const url = '/custom-report/risk-category/create';
		const { data } = await Api.post<RiskCategoryDTO>(url, body);
		return data;
	}

	public async updateCategory(body: UpdateCategoryRequest) {
		const { id } = body;
		const url = `/custom-report/risk-category/${id}`;
		const { data } = await Api.put<RiskCategoryDTO>(url, body);
		return data;
	}

	public async deleteCategory(params: DeleteCategoryRequest) {
		const url = '/custom-report/risk-category';
		const { data } = await Api.delete<RiskCategoryDTO>(url, {
			params
		});
		return data;
	}

	public async getCustomReportsSteps(params: CustomReportStepsRequest): Promise<CustomReportStepDTO[]> {
		const url = `/custom-report/step/report/${params.custom_report_id}`;
		const { data } = await Api.get<CustomReportStepDTO[]>(url, {
			params
		});
		return data;
	}

	public async getCustomReport(params: CustomReportRequest): Promise<CustomReportDTO> {
		const url = `/custom-report/name/${params.reportName}`;
		const { data } = await Api.get<CustomReportDTO>(url, {
			params
		});
		return data;
	}
}
