export const Types = {
	CLEAR_PROJECT: 'CLEAR_PROJECT',
	SET_ORGANIZATION: 'SET_ORGANIZATION',
	SET_COMPANY: 'SET_COMPANY',
	SET_USER: 'SET_USER'
};

export const AppReducer = (state, action) => {
	switch (action.type) {
		case Types.CLEAR_PROJECT:
			return {
				organization: null,
				company: null,
				user: null,
				setOrganization: () => {},
				setCompany: () => {},
				setUser: () => {},
				clearProject: () => {}
			};

		case Types.SET_ORGANIZATION:
			return {
				...state,
				organization: action.payload
			};

		case Types.SET_COMPANY:
			return {
				...state,
				company: action.payload
			};

		case Types.SET_USER:
			return {
				...state,
				USER: action.payload
			};

		default:
			return state;
	}
};
