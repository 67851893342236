const Types = {
	SET_TYPE: 'SET_TYPE',
	SET_AUTHOR: 'SET_AUTHOR',
	SET_INFORMATION: 'SET_INFORMATION',
	SET_PARAMETERS: 'SET_PARAMETERS',
	SET_CONCLUSION: 'SET_CONCLUSION',

	GET_SECTORS_LOADING: 'GET_SECTORS_LOADING',
	GET_SECTORS_SUCCESS: 'GET_SECTORS_SUCCESS',
	GET_SECTORS_ERROR: 'GET_SECTORS_ERROR',

	GET_DATA_CHART_LOADING: 'GET_DATA_CHART_LOADING',
	GET_DATA_CHART_SUCCESS: 'GET_DATA_CHART_SUCCESS',
	GET_DATA_CHART_ERROR: 'GET_DATA_CHART_ERROR',

	GENERATOR_DOCUMENT_LOADING: 'GENERATOR_DOCUMENT_LOADING',
	GENERATOR_DOCUMENT_SUCCESS: 'GENERATOR_DOCUMENT_SUCCESS',
	GENERATOR_DOCUMENT_ERROR: 'GENERATOR_DOCUMENT_ERROR',

	SECTOR_CREATE_LOADING: 'SECTOR_CREATE_LOADING',
	SECTOR_CREATE_SUCCESS: 'SECTOR_CREATE_SUCCESS',
	SECTOR_CREATE_ERROR: 'SECTOR_CREATE_ERROR',
	SECTOR_CLEAR_MESSAGE: 'SECTOR_CLEAR_MESSAGE',

	SECTOR_DELETE_LOADING: 'SECTOR_DELETE_LOADING',
	SECTOR_DELETE_SUCCESS: 'SECTOR_DELETE_SUCCESS',
	SECTOR_DELETE_ERROR: 'SECTOR_DELETE_ERROR',
	SECTOR_CLEAR_MESSAGE_ERROR: 'SECTOR_CLEAR_MESSAGE_ERROR',

	GET_RECOVERY_REPORT_SUCCESS: 'GET_RECOVERY_REPORT_SUCCESS',
	GET_RECOVERY_REPORT_ERROR: 'GET_RECOVERY_REPORT_ERROR',

	CHECK_USAGE_SECTOR_LOADING: 'CHECK_USAGE_SECTOR_LOADING',
	CHECK_USAGE_SECTOR_SUCCESS: 'CHECK_USAGE_SECTOR_SUCCESS',
	CHECK_USAGE_SECTOR_ERROR: 'CHECK_USAGE_SECTOR_ERROR',

	GET_DEGREE_OF_RISK_LOADING: 'GET_DEGREE_OF_RISK_LOADING',
	GET_DEGREE_OF_RISK_SUCCESS: 'GET_DEGREE_OF_RISK_SUCCESS',
	GET_DEGREE_OF_RISK_ERROR: 'GET_DEGREE_OF_RISK_ERROR',

	GET_ANGLE_TIME_LOADING: 'GET_ANGLE_TIME_LOADING',
	GET_ANGLE_TIME_SUCCESS: 'GET_ANGLE_TIME_SUCCESS',
	GET_ANGLE_TIME_ERROR: 'GET_ANGLE_TIME_ERROR',

	GET_TOTAL_VIDEO_LOADING: 'GET_TOTAL_VIDEO_LOADING',
	GET_TOTAL_VIDEO_SUCCESS: 'GET_TOTAL_VIDEO_SUCCESS',
	GET_TOTAL_VIDEO_ERROR: 'GET_TOTAL_VIDEO_ERROR',

	GET_PROCESSED_MINUTES_LOADING: 'GET_PROCESSED_MINUTES_LOADING',
	GET_PROCESSED_MINUTES_SUCCESS: 'GET_PROCESSED_MINUTES_SUCCESS',
	GET_PROCESSED_MINUTES_ERROR: 'GET_PROCESSED_MINUTES_ERROR',

	GET_CRITICAL_SECTOR_LOADING: 'GET_CRITICAL_SECTOR_LOADING',
	GET_CRITICAL_SECTOR_SUCCESS: 'GET_CRITICAL_SECTOR_SUCCESS',
	GET_CRITICAL_SECTOR_ERROR: 'GET_CRITICAL_SECTOR_ERROR',

	GET_PERCENT_VIDEO_RISK_LOADING: 'GET_PERCENT_VIDEO_RISK_LOADING',
	GET_PERCENT_VIDEO_RISK_SUCCESS: 'GET_PERCENT_VIDEO_RISK_SUCCESS',
	GET_PERCENT_VIDEO_RISK_ERROR: 'GET_PERCENT_VIDEO_RISK_ERROR',

	GET_VIDEOS_BY_SERCTORS_LOADING: 'GET_VIDEOS_BY_SERCTORS_LOADING',
	GET_VIDEOS_BY_SERCTORS_SUCCESS: 'GET_VIDEOS_BY_SERCTORS_SUCCESS',
	GET_VIDEOS_BY_SERCTORS_ERROR: 'GET_VIDEOS_BY_SERCTORS_ERROR',

	GET_VIDEOS_RISK_BY_TIME_LOADING: 'GET_VIDEOS_RISK_BY_TIME_LOADING',
	GET_VIDEOS_RISK_BY_TIME_SUCCESS: 'GET_VIDEOS_RISK_BY_TIME_SUCCESS',
	GET_VIDEOS_RISK_BY_TIME_ERROR: 'GET_VIDEOS_RISK_BY_TIME_ERROR',

	GET_SECTORS_REGISTER_LOADING: 'GET_SECTORS_REGISTER_LOADING',
	GET_SECTORS_REGISTER_SUCCESS: 'GET_SECTORS_REGISTER_SUCCESS',
	GET_SECTORS_REGISTER_ERROR: 'GET_SECTORS_REGISTER_ERROR',

	GET_EXTRACTED_REPORTS_LOADING: 'GET_EXTRACTED_REPORTS_LOADING',
	GET_EXTRACTED_REPORTS_SUCCESS: 'GET_EXTRACTED_REPORTS_SUCCESS',
	GET_EXTRACTED_REPORTS_ERROR: 'GET_EXTRACTED_REPORTS_ERROR',

	GET_ALL_SECTORS_CRITICAL_LOADING: 'GET_ALL_SECTORS_CRITICAL_LOADING',
	GET_ALL_SECTORS_CRITICAL_SUCCESS: 'GET_ALL_SECTORS_CRITICAL_SUCCESS',
	GET_ALL_SECTORS_CRITICAL_ERROR: 'GET_ALL_SECTORS_CRITICAL_ERROR',

	GET_RISK_STATUS_LOADING: 'GET_RISK_STATUS_LOADING',
	GET_RISK_STATUS_SUCCESS: 'GET_RISK_STATUS_SUCCESS',
	GET_RISK_STATUS_ERROR: 'GET_RISK_STATUS_ERROR',

	SAVE_PARAMETERS_REPORT_SUCCESS: 'SAVE_PARAMETERS_REPORT_SUCCESS',
	SAVE_PARAMETERS_REPORT_ERROR: 'SAVE_PARAMETERS_REPORT_ERROR',

	CLEAR_DASHBOARD: 'CLEAR_DASHBOARD',
	CLEAR_REPORT: 'CLEAR_REPORT'
};

export default Types;
