import styled from 'styled-components';
import { Collapse, Row } from 'antd';

import { Text } from '@/components/Typography';

interface StrainIndexTableContainerProps {
	$riskColorLeft: number;
	$riskColorRight: number;
}

interface CustomTextProps {
	$score: string;
}

function getColorByRsiScore(rsi: number): string {
	return rsi <= 10 ? '#2CC852' : '#E74150';
}

export const PanelCustom = styled(Collapse.Panel)`
	.ant-collapse-header-text {
		font-size: 20px;
		font-weight: bold;
	}
	.ant-collapse-expand-icon {
		padding-top: 5px;
	}
`;

export const NioshTableContainer = styled.div<{ $riskColor: string }>`
	.ant-table {
		min-width: 320px;
		border-radius: 10px;
		border: 1px solid #e6e8e8;
		.ant-table-thead
			> tr
			> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
				[colspan]
			)::before {
			background-color: #fff;
			height: 80%;
		}

		.ant-table-cell {
			border-left: 1px solid #e6e8e8;
			padding-top: 0.4rem !important;
			padding-bottom: 0.4rem !important;
		}

		.ant-table-wrapper {
			display: flex;
			justify-content: center;
			.ant-spin-nested-loading {
				width: 25rem;
			}
		}

		.ant-table-thead {
			th {
				font-weight: bold;
				background-color: #e6e8e8;
				padding-top: 0.4rem !important;
				padding-bottom: 0.4rem !important;
			}

			tr:first-child {
				th:first-child {
					background: none;
					border: none;
					padding: 20px 20px !important;
					font-weight: 500;
				}
			}

			tr:last-child {
				.ant-table-cell:first-child {
					border-radius: 0;
				}

				.ant-table-cell:nth-child(3) {
					&::before {
						display: none;
					}
				}
				.ant-table-cell:last-child {
					font-size: 18px;
					border-radius: 0;
					color: #fff;
					background-color: ${({ $riskColor }) => $riskColor};
					border-bottom: none;
					border-left-color: #e6e8e8;
				}
			}
		}

		.ant-table-tbody {
			tr {
				background-color: F8F8F8;
				.ant-table-cell {
					font-size: 16px;
				}
			}

			tr:last-child {
				.ant-table-cell:last-child {
					font-size: 12px;
					color: #fff;
					background-color: ${({ $riskColor }) => $riskColor};
					border-radius: 0 0 10px 0;
					padding-top: 0 !important;
				}
			}
		}
	}
`;


export const BackCompressiveForceEstimationTableContainerFirstRow = styled.div<{ $resultColor: string }>`
	.ant-table {
		min-width: 320px;
		border-radius: 10px 10px 0 0;
		border: 1px solid #e6e8e8;
		.ant-table-thead
			> tr
			> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
				[colspan]
			)::before {
			background-color: #fff;
			height: 80%;
		}

		.ant-table-cell {
			border-left: 1px solid #e6e8e8;
			padding-top: 0.4rem !important;
			padding-bottom: 0.4rem !important;
		}

		.ant-table-wrapper {
			display: flex;
			justify-content: center;
			.ant-spin-nested-loading {
				width: 25rem;
			}
		}

		.ant-table-thead {
			th {
				font-weight: bold;
				background-color: #e6e8e8;
				padding-top: 0.4rem !important;
				padding-bottom: 0.4rem !important;
			}

			tr:last-child {
				.ant-table-cell:first-child {
					border-radius: 10px 0 0 0;
				}
				.ant-table-cell:nth-child(2),
				.ant-table-cell:nth-child(3) {
					&::before {
						display: none;
					}
				}
				.ant-table-cell:last-child {
					font-size: 14px;
					color: #fff;
					background-color: ${({ $resultColor }) => $resultColor};
					border-bottom: none;
					vertical-align: bottom;
					padding-bottom: 10px !important;
				}
				.ant-table-cell:last-child {
					border-radius: 0 10px 0 0;
				}
			}
		}

		.ant-table-tbody {
			tr {
				background-color: F8F8F8;
				.ant-table-cell {
					font-size: 16px;
				}
			}

			tr:last-child {
				.ant-table-cell:last-child {
					font-size: 20px;
					color: #fff;
					font-weight: bold;
					background-color: ${({ $resultColor }) => $resultColor};
					border-radius: 0;
					padding-top: 0 !important;
					padding-bottom: 15px !important;
					vertical-align: top;
				}
			}
		}
	}
`;

export const BackCompressiveForceEstimationTableContainerSecondRow = styled.div`
	.ant-table {
		min-width: 320px;
		border-radius: 0 0 10px 10px;
		border: 1px solid #e6e8e8;
		.ant-table-thead
			> tr
			> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
				[colspan]
			)::before {
			background-color: #fff;
			height: 80%;
		}

		.ant-table-cell {
			border-left: 1px solid #e6e8e8;
			padding-top: 0.4rem !important;
			padding-bottom: 0.4rem !important;
			font-size: 12px;
		}

		.ant-table-wrapper {
			display: flex;
			justify-content: center;
			.ant-spin-nested-loading {
				width: 25rem;
			}
		}

		.ant-table-thead {
			th {
				font-weight: bold;
				background-color: #e6e8e8;
				padding-top: 0.4rem !important;
				padding-bottom: 0.4rem !important;
			}

			tr:last-child {
				.ant-table-cell:first-child {
					border-radius: 0;
				}
				.ant-table-cell:last-child {
					border-radius: 0;
					border-bottom: none;
					border-left-color: #e6e8e8;
				}
			}
		}

		.ant-table-tbody {
			tr {
				background-color: F8F8F8;
				.ant-table-cell {
					font-size: 12px;
				}
			}

			tr:last-child {
				.ant-table-cell:first-child {
					border-radius: 0 0 0 10px;
				}
				.ant-table-cell:last-child {
					border-radius: 0 0 10px 0;
				}
			}
		}
	}
`;

export const KimTableContainerFirstRow = styled.div<{ $riskColor: string }>`
	.ant-table {
		min-width: 320px;
		border-radius: 10px 10px 0 0;
		border: 1px solid #e6e8e8;
		.ant-table-thead
			> tr
			> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
				[colspan]
			)::before {
			background-color: #fff;
			height: 80%;
		}

		.ant-table-cell {
			border-left: 1px solid #e6e8e8;
			padding-top: 0.4rem !important;
			padding-bottom: 0.4rem !important;
		}

		.ant-table-wrapper {
			display: flex;
			justify-content: center;
			.ant-spin-nested-loading {
				width: 25rem;
			}
		}

		.ant-table-thead {
			th {
				font-weight: bold;
				background-color: #e6e8e8;
				padding-top: 0.4rem !important;
				padding-bottom: 0.4rem !important;
			}

			tr:last-child {
				.ant-table-cell:first-child {
					border-radius: 10px 0 0 0;
				}
				.ant-table-cell:nth-child(3),
				.ant-table-cell:nth-child(4) {
					&::before {
						display: none;
					}
				}
				.ant-table-cell:nth-child(4),
				.ant-table-cell:last-child {
					font-size: 14px;
					color: #fff;
					background-color: ${({ $riskColor }) => $riskColor};
					border-bottom: none;
					vertical-align: bottom;
					padding-bottom: 10px !important;
				}
				.ant-table-cell:last-child {
					border-radius: 0 10px 0 0;
				}
			}
		}

		.ant-table-tbody {
			tr {
				background-color: F8F8F8;
				.ant-table-cell {
					font-size: 16px;
				}
			}

			tr:last-child {
				.ant-table-cell:nth-child(4),
				.ant-table-cell:last-child {
					font-size: 20px;
					color: #fff;
					font-weight: bold;
					background-color: ${({ $riskColor }) => $riskColor};
					border-radius: 0;
					padding-top: 0 !important;
					padding-bottom: 15px !important;
					vertical-align: top;
				}
			}
		}
	}
`;

export const KimTableContainerSecondRow = styled.div`
	.ant-table {
		min-width: 320px;
		border-radius: 0 0 10px 10px;
		border: 1px solid #e6e8e8;
		.ant-table-thead
			> tr
			> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
				[colspan]
			)::before {
			background-color: #fff;
			height: 80%;
		}

		.ant-table-cell {
			border-left: 1px solid #e6e8e8;
			padding-top: 0.4rem !important;
			padding-bottom: 0.4rem !important;
			font-size: 12px;
		}

		.ant-table-wrapper {
			display: flex;
			justify-content: center;
			.ant-spin-nested-loading {
				width: 25rem;
			}
		}

		.ant-table-thead {
			th {
				font-weight: bold;
				background-color: #e6e8e8;
				padding-top: 0.4rem !important;
				padding-bottom: 0.4rem !important;
			}

			tr:last-child {
				.ant-table-cell:first-child {
					border-radius: 0;
				}
				.ant-table-cell:last-child {
					border-radius: 0;
					border-bottom: none;
					border-left-color: #e6e8e8;
				}
			}
		}

		.ant-table-tbody {
			tr {
				background-color: F8F8F8;
				.ant-table-cell {
					font-size: 12px;
				}
			}

			tr:last-child {
				.ant-table-cell:first-child {
					border-radius: 0 0 0 10px;
				}
				.ant-table-cell:last-child {
					border-radius: 0 0 10px 0;
				}
			}
		}
	}
`;

export const StrainIndexTableContainer = styled.div<StrainIndexTableContainerProps>`
	.ant-table {
		min-width: 320px;
		border-radius: 10px;
		border: 1px solid #e6e8e8;
		.ant-table-thead
			> tr
			> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
				[colspan]
			)::before {
			background-color: #fff;
			height: 80%;
		}

		.ant-table-cell {
			border-left: 1px solid #e6e8e8;
			padding-top: 0.4rem !important;
			padding-bottom: 0.4rem !important;
			font-size: 12px;
			border-left: none;
			border-right: none;
			border-bottom-color: #d9d9d9 !important;
		}

		.ant-table-wrapper {
			display: flex;
			justify-content: center;
			.ant-spin-nested-loading {
				width: 25rem;
			}
		}

		.ant-table-thead {
			th {
				font-weight: bold;
				background-color: #e6e8e8;
				padding-top: 0.4rem !important;
				padding-bottom: 0.4rem !important;
			}

			tr:last-child {
				.ant-table-cell:first-child {
					border-radius: 10px 0 0 0;
				}

				.ant-table-cell:nth-child(4) {
					border-radius: 0 10px 0 0;
					border-bottom: none;
					border-left-color: #e6e8e8;
				}

				.ant-table-cell:last-child {
					display: none;
					border-radius: 0 10px 0 0;
					border-bottom: none;
					border-left-color: #e6e8e8;
				}
			}
		}

		.ant-table-tbody {
			tr {
				background-color: F8F8F8;
				.ant-table-cell {
					font-size: 12px;
				}
				.ant-table-cell:first-child {
					background-color: #e6e8e8;
				}
				.ant-table-cell:nth-child(4) {
					border-bottom: none;
					background-color: ${({ $riskColorLeft }) => getColorByRsiScore($riskColorLeft)};
					border-right: #fff solid 1px;
					color: #fff;
					padding-right: 0 !important;
				}
				.ant-table-cell:nth-child(5) {
					background-color: ${({ $riskColorRight }) => getColorByRsiScore($riskColorRight)};
					border-radius: 0 0 10px 0;
					color: #fff;
					padding-right: 0 !important;
					//padding-left: 25px !important;
				}
			}

			tr:last-child {
				.ant-table-cell:first-child {
					border-radius: 0 0 0 10px;
				}
				.ant-table-cell:last-child {
					border-bottom: none;
				}
				.ant-table-cell {
					border-bottom: none;
				}
			}
		}
	}
`;

export const RowCustomCardContainer = styled(Row)`
	.ant-typography {
		color: #fff;
	}
	.ant-col:first-child {
		font-size: 16px;
	}

	.ant-col:nth-child(2) {
		font-size: 26px;
	}

	.ant-col:last-child {
		font-size: 14px;
	}
`;

export const LibertyMutualContainerFirstRow = styled.div`
	.ant-table {
		min-width: 320px;
		border-radius: 10px 10px 0 0;
		border: 1px solid #e6e8e8;
		.ant-table-thead
			> tr
			> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
				[colspan]
			)::before {
			background-color: #fff;
			height: 80%;
		}

		.ant-table-cell {
			border-left: 1px solid #e6e8e8;
			padding-top: 0.4rem !important;
			padding-bottom: 0.4rem !important;
		}

		.ant-table-wrapper {
			display: flex;
			justify-content: center;
			.ant-spin-nested-loading {
				width: 25rem;
			}
		}

		.ant-table-thead {
			th {
				font-weight: bold;
				background-color: #e6e8e8;
				padding-top: 0.4rem !important;
				padding-bottom: 0.4rem !important;
			}

			tr:last-child {
				.ant-table-cell:first-child {
					border-radius: 10px 0 0 0;
					font-size: 12px;
					&::before {
						display: none;
					}
					&::after {
						content: ':';
					}
				}

				.ant-table-cell:last-child {
					border-radius: 0 10px 0 0;
					background-color: #fff;
					border-bottom: none;
				}
			}
		}

		.ant-table-tbody {
			tr {
				background-color: F8F8F8;
				.ant-table-cell {
					font-size: 16px;
				}
				.ant-table-cell:first-child {
					border-right: 1px solid #e6e8e8;
				}
				.ant-table-cell:last-child {
					position: relative;
					bottom: 20px;
					border-left: none;
					border-bottom: none;
					font-size: 15px;
				}
			}
		}
	}
`;

export const LibertyMutualContainerSecondRow = styled.div`
	.ant-table {
		min-width: 320px;
		border-radius: 0 0 10px 10px;
		border: 1px solid #e6e8e8;
		.ant-table-thead
			> tr
			> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
				[colspan]
			)::before {
			background-color: #fff;
			height: 80%;
		}

		.ant-table-cell {
			border-left: 1px solid #e6e8e8;
			padding-top: 0.6rem !important;
			padding-bottom: 0.6rem !important;
			font-size: 12px;
			border-bottom-color: #d9d9d9 !important;
		}

		.ant-table-wrapper {
			display: flex;
			justify-content: center;
			.ant-spin-nested-loading {
				width: 25rem;
			}
		}

		.ant-table-thead {
			th {
				font-weight: bold;
				background-color: #e6e8e8;
				padding-top: 0.4rem !important;
				padding-bottom: 0.4rem !important;
			}

			tr:last-child {
				.ant-table-cell {
					&::after {
						content: ':';
					}
				}

				.women-percentage,
				.men-percentage {
					background-color: #2f54eb;
					color: #fff;
					border-bottom: none;
					padding-bottom: 0 !important;
					vertical-align: bottom;
					&::after {
						content: '';
					}
				}

				.men-percentage {
					border-right: #e6e8e8 solid 1px;
					&::before {
						display: none;
					}
				}

				.ant-table-cell:first-child {
					border-radius: 0;
				}

				.ant-table-cell:last-child {
					border-radius: 0;
				}
			}
		}

		.ant-table-tbody {
			tr {
				background-color: F8F8F8;
				.ant-table-cell {
					font-size: 16px;
				}
			}

			tr:last-child {
				.ant-table-cell:first-child {
					border-radius: 0 0 0 10px;
				}

				.ant-table-cell {
					border-bottom: none;
				}
				.men-percentage,
				.women-percentage {
					background-color: #2f54eb !important;
					color: #fff;
					border-bottom: none;
					border-right: #e6e8e8 solid 1px;
					font-size: 20px;
					font-weight: bold;
				}
				.women-percentage {
					border-radius: 0 0 10px 0;
					border-right: #2f54eb solid 1px;
				}
			}
		}
	}
`;

export const CustomText = styled(Text) <CustomTextProps>`
	color: ${({ $score }) => getColorByRsiScore($score)};
`;
