import React from 'react';
import { Col, Form } from 'antd';
import { useApplicationContext } from '@/context/v1/Application/context';
import { LineSelect } from '@/components/ui/Inputs/LineSelect';
import { FormItem } from '@/components/CustomReports';
import { useLines } from '@/hooks/v1';

const { useFormInstance, useWatch } = Form;

interface LinesProps {
	fontSize?: string;
	isRequired?: boolean;
	fieldName: string;
}

export const Lines: React.FC<LinesProps> = ({ fontSize, isRequired, fieldName }) => {
	const form = useFormInstance();
	const { company, organization } = useApplicationContext();

	const lineId = useWatch([fieldName, 'line_id'], form);
	const sectorId = useWatch([fieldName, 'sector_id'], form);

	const {
		data: lines,
		isLoading,
		isFetching,
		isError
	} = useLines({ organization_id: organization?.id, company_id: company?.id, sector_id: sectorId });

	function handleSelectLine(id: string) {
		form.setFieldValue([fieldName, 'line_id'], id);
		form.setFieldValue([fieldName, 'workstation_id'], undefined);
	}

	function handleClearLines() {
		form.setFieldValue([fieldName, 'line_id'], undefined);
		form.setFieldValue([fieldName, 'workstation_id'], undefined);
	}

	return (
		<Col xs={24}>
			<FormItem namePath={[fieldName, 'line_id']} title={'Line'} fontSize={fontSize} isRequired={isRequired}>
				<LineSelect
					size="large"
					lines={lines}
					allowCreation
					lineId={lineId}
					sectorId={sectorId}
					loading={isFetching}
					fieldName={[fieldName, 'line_id']}
					handleSelectLine={handleSelectLine}
					handleClearLines={handleClearLines}
					disabled={isLoading || isFetching || isError || !sectorId}
				/>
			</FormItem>
		</Col>
	);
};
