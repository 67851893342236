import React, { useEffect, useState } from 'react';
import { Button, Col, Form, InputNumber, Row, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import { v4 as uuidv4 } from 'uuid';

import { useCalculateAdditionalToolScoreWithPostRoute, useGetAdditionalItemsOptions } from '@/hooks';
import type { AditionalItemProps, FormFieldValues, Options } from './types';
import { useApplicationContext } from '@/context/v1/Application/context';
import type { AdditionalItem, GetAdditionalToolScoreDTO } from '@/hooks';
import { worstRiskCalculator } from './utils/WorstResultCalculator';
import { getDecimalSeparator } from '@/utils/getDecimalSeparator';
import { useStepKeysContext } from '../DefaultSelects/context';
import { fieldsUnitTexts } from './utils/FieldsUnitTexts';
import { MovimentsGripHandResistance } from './enum';
import { ConditionCard } from '../../ConditionCard';
import { Text } from '@/components/Typography';
import { InfoList } from './InfoList';

const { useFormInstance, useWatch } = Form;
const { formatLabelWithUnit, formatPlaceholderWithUnit } = fieldsUnitTexts;

export const SelectAndInput: React.FC<AditionalItemProps> = ({ additionalItem }) => {
	const { id, title_1, title_2, title_3, unit_1, unit_2, unit_3 } = additionalItem;

	const form = useFormInstance();
	const { formFieldName, stepKey } = useStepKeysContext();
	const { organization, company } = useApplicationContext();

	const [selectAndInput, setSelectAndInput] = useState<AdditionalItem>(additionalItem);

	const formValueOne = useWatch([...formFieldName, 'additional_items', id, 'value_1']);
	const formValueTwo = useWatch([...formFieldName, 'additional_items', id, 'value_2']);
	const selectFormValue = useWatch([...formFieldName, 'additional_items', id, 'additional_item_option_id_1']);

	useEffect(() => {
		form.setFieldValue([...formFieldName, 'additional_items', 'local_values'], additionalItem);
	}, []);

	const { mutateAsync: calculateAdditionalToolScore, isLoading: isLoadingCalculateAdditionalToolScore } =
		useCalculateAdditionalToolScoreWithPostRoute();
	const { data: options, isLoading: isGettingAdditionalItemsOptions } = useGetAdditionalItemsOptions({
		company_id: company?.id,
		organization_id: organization?.id,
		custom_report_step_key_id: stepKey.id
	});

	const worstRisk = worstRiskCalculator.getWorstResult(selectAndInput.results);

	const selectFieldName = options.find(({ id }) => id === selectFormValue)?.name;

	const selectLabel = formatLabelWithUnit(title_1, unit_1);
	const firstInputPlaceholder = formatPlaceholderWithUnit('0', unit_2);
	const secondInputPlaceholder = formatPlaceholderWithUnit('0', unit_3);

	function handleChangeSelect(): void {
		form.setFieldValue([...formFieldName, 'additional_items', id, 'value_1'], undefined);
		form.setFieldValue([...formFieldName, 'additional_items', id, 'value_2'], undefined);
	}

	async function handleAddAdditionalItemValueClick(): Promise<void> {
		const payload: GetAdditionalToolScoreDTO = {
			value_1: formValueOne,
			value_2: formValueTwo,
			company_id: company?.id,
			organization_id: organization?.id,
			custom_report_step_key_id: stepKey.id,
			additional_item_option_id_1: selectFormValue
		};

		const additionalToolScore = await calculateAdditionalToolScore(payload);

		if (additionalToolScore?.result) {
			const formFieldValues: FormFieldValues = form.getFieldValue([...formFieldName, 'additional_items', id]);
			const newAdditionalItemValue = {
				...formFieldValues,
				id: uuidv4(),
				result: additionalToolScore.result,
				description: options.find(({ id }) => id === formFieldValues.additional_item_option_id_1)?.description
			};
			const results = selectAndInput?.results ?? [];
			const additionalItemResultsCopy = [...results];
			additionalItemResultsCopy.push(newAdditionalItemValue);

			setSelectAndInput({
				...selectAndInput,
				results: additionalItemResultsCopy
			});
			form.setFieldValue(
				[...formFieldName, 'additional_items', 'local_values', 'results'],
				additionalItemResultsCopy
			);
			form.setFieldValue([...formFieldName, 'additional_items', id], undefined);
		}
	}

	function handleRemoveAdditionalItemClick(id: string): void {
		const resultsWithoutRemovedItem = selectAndInput.results.filter((item) => item.id !== id);
		const newResults = {
			...selectAndInput,
			results: resultsWithoutRemovedItem
		};

		setSelectAndInput(newResults);
		form.setFieldValue(
			[...formFieldName, 'additional_items', 'local_values', 'results'],
			resultsWithoutRemovedItem
		);
	}

	function filterOption(input: string, option?: Options): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function hasExtraFormField(): boolean {
		const isTipPinchGripSelected = selectFieldName === MovimentsGripHandResistance.TIP_PINCH_GRIP;
		const isHookGripSelected = selectFieldName === MovimentsGripHandResistance.HOOK_GRIP;

		return isTipPinchGripSelected || isHookGripSelected;
	}

	function hasSomeEmptyField(): boolean | undefined {
		return !formValueOne || !selectFormValue || (hasExtraFormField() && !formValueTwo);
	}

	return (
		<Row gutter={[0, 25]} key={id}>
			<Col span={24}>
				<Row align="bottom" gutter={[14, 20]}>
					<Col span={18}>
						<Row gutter={[0, 5]}>
							<Col span={24}>
								<Text strong>{selectLabel}</Text>
							</Col>
							<Col span={24}>
								<Form.Item
									style={{ margin: 0 }}
									name={[...formFieldName, 'additional_items', id, 'additional_item_option_id_1']}
								>
									<Select
										showSearch
										allowClear
										filterOption={filterOption}
										onChange={handleChangeSelect}
										placeholder={I18n.get(title_1)}
										loading={isGettingAdditionalItemsOptions}
										disabled={isGettingAdditionalItemsOptions}
										options={options?.map(({ id, description }) => ({
											label: `${I18n.get(description)}`,
											value: id
										})).sort((a, b) => a.label.localeCompare(b.label))}
									/>
								</Form.Item>
							</Col>
						</Row>
					</Col>
					<Col span={hasExtraFormField() ? 9 : 18}>
						<Row gutter={[0, 5]}>
							<Row gutter={[5, 0]}>
								<Col>
									<Text strong>{title_2}</Text>
								</Col>
							</Row>
							<Col span={24}>
								<Form.Item
									style={{ margin: 0 }}
									name={[...formFieldName, 'additional_items', id, 'value_1']}
								>
									<InputNumber
										min={0}
										style={{ width: '100%' }}
										placeholder={firstInputPlaceholder}
										decimalSeparator={getDecimalSeparator()}
									/>
								</Form.Item>
							</Col>
						</Row>
					</Col>
					{hasExtraFormField() && (
						<Col span={9}>
							<Row gutter={[0, 5]}>
								<Col span={24}>
									<Text strong>{title_3}</Text>
								</Col>
								<Col span={24}>
									<Form.Item
										style={{ margin: 0 }}
										name={[...formFieldName, 'additional_items', id, 'value_2']}
									>
										<InputNumber
											min={0}
											style={{ width: '100%' }}
											placeholder={secondInputPlaceholder}
										/>
									</Form.Item>
								</Col>
							</Row>
						</Col>
					)}
					<Col>
						<Button
							shape="circle"
							type="primary"
							icon={<PlusOutlined />}
							onClick={handleAddAdditionalItemValueClick}
							disabled={hasSomeEmptyField() || isLoadingCalculateAdditionalToolScore}
						/>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<InfoList items={selectAndInput} onDeleteValue={handleRemoveAdditionalItemClick} />
			</Col>
			<Col span={24}>
				<ConditionCard result={worstRisk} isLoading={isGettingAdditionalItemsOptions} />
			</Col>
		</Row>
	);
};
