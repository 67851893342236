import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Tabs, Button } from 'antd';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
} from 'chart.js';

import { HumanBody } from '@/components/ui/HumanBody';
import { NextButton } from '@/components/ui/Buttons/NextButton';
import { PreviousButton } from '@/components/ui/Buttons/PreviousButton';
import { ReportLegend } from './ReportLegend';
import { ExposureAverage } from './ExposureAverage';
import { PieChartCollection } from './PieChartCollection';
import { LineChartCollection } from './LineChartCollection';
import { ErrorMessage } from '../ErrorMessage';
import Spinner from '@/components/Layout/Spinner';

import { iconStyle, nextBtnStyle, prevBtnStyle, TabsContainer, tabStyle } from './styles';
import { useRiskRangeSettings } from '../../hooks';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

const enumDefault = {
	neck: {},
	trunk: {},
	left_upper_arm: {},
	right_upper_arm: {},
	left_lower_arm: {},
	right_lower_arm: {},
	left_knee: {},
	right_knee: {}
};

const tabNames = {
	score_seconds: I18n.get('REBA'),
	neck: I18n.get('Neck'),
	trunk: I18n.get('Trunk'),
	left_upper_arm: I18n.get('Shoulder L'),
	right_upper_arm: I18n.get('Shoulder R'),
	left_lower_arm: I18n.get('Elbow L'),
	right_lower_arm: I18n.get('Elbow R'),
	left_knee: I18n.get('Leg L'),
	right_knee: I18n.get('Leg R')
};

const REBA = 'REBA';

const reportTypes = {
	Score: 'Score',
	Angle: 'Angle'
};

export function Charts({ parts = enumDefault, score, scorePerSecond }) {
	const startingPartIndex = '0';
	const [activeTabIndex, setActiveTabIndex] = useState(startingPartIndex);

	const { score_seconds, ...restScores } = scorePerSecond;
	const tabs = { score_seconds, ...restScores };
	const prevBtnDisabled = activeTabIndex === '0';
	const nextBtnDisabled = activeTabIndex === (Object.keys(tabs).length - 1).toString();

	const activeTabLabel = Object.keys(tabs)[activeTabIndex];
	const activeTabName = tabNames[Object.keys(tabs)[activeTabIndex]];
	const reportType = activeTabName === REBA ? reportTypes.Score : reportTypes.Angle;

	const { organization, company } = useSelector(
		(state) => ({
			organization: state.organization.organization,
			company: state.organization.company
		}),
		shallowEqual
	);

	const settings = useRiskRangeSettings({
		organizationId: organization?.id,
		companyId: company?.id
	});

	const isError = settings?.isError || !settings;
	const isLoading = settings?.isLoading;

	const onRetry = () => window.location.reload();

	function onSelectItem(itemIndex) {
		if (typeof itemIndex === 'number') {
			itemIndex += 1;
			itemIndex = itemIndex.toString();
		}
		setActiveTabIndex(itemIndex);
	}

	function onSelectBodyImage(partName) {
		if (Object.keys(parts).includes(partName)) {
			const index = Object.keys(tabs).indexOf(partName);
			onSelectItem(index.toString());
		}
	}

	function onClear() {
		setActiveTabIndex(startingPartIndex);
	}

	function onNext() {
		if (activeTabIndex < Object.keys(tabs).length) {
			setActiveTabIndex((prev) => (parseInt(prev) + 1).toString());
		}
	}

	function onPrevious() {
		if (activeTabIndex > 0) {
			setActiveTabIndex((prev) => (parseInt(prev) - 1).toString());
		}
	}

	const ClearButton = () => <Button onClick={onClear}>{I18n.get('View score')}</Button>;

	if (isLoading) {
		return <Spinner />;
	}

	if (isError) {
		return <ErrorMessage onRetry={onRetry} />;
	}

	return (
		<>
			<Row
				type="flex"
				align="middle"
				justify="center"
				gutter={[0, 10]}
				style={{ height: 520 }}
			>
				<Col
					span={6}
					style={{ display: 'flex', justifyContent: 'right', position: 'relative' }}
				>
					<HumanBody
						height={380}
						onSelect={onSelectBodyImage}
						selectedPartName={activeTabLabel}
						bodyPartScores={parts}
					/>
				</Col>
				<Col
					span={18}
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'left',
						alignItems: 'center',
						gap: 20
					}}
				>
					<ExposureAverage scores={score} />
					<PieChartCollection
						parts={parts}
						onClick={onSelectBodyImage}
						style={{ marginInline: 30 }}
						activeTabLabel={activeTabLabel}
					/>
				</Col>
				<Col span={24} style={{ padding: 0, margin: 0 }}>
					<ReportLegend />
				</Col>
			</Row>
			<Row type="flex" justify="center" align="middle" gutter={[0, 14]}>
				<TabsContainer>
					<PreviousButton
						onPrevious={onPrevious}
						style={prevBtnStyle}
						iconStyle={iconStyle}
						disabled={prevBtnDisabled}
					/>
					<NextButton
						onNext={onNext}
						style={nextBtnStyle}
						iconStyle={iconStyle}
						disabled={nextBtnDisabled}
					/>
					<Tabs
						centered
						type="card"
						size="small"
						style={tabStyle}
						onTabClick={onSelectItem}
						activeKey={activeTabIndex}
						defaultActiveKey={startingPartIndex}
						tabBarExtraContent={activeTabIndex === '0' ? null : <ClearButton />}
					>
						{Object.keys(tabs).map((item, index) => (
							<Tabs.TabPane
								key={index}
								animated={false}
								tab={I18n.get(tabNames[item])}
							>
								<LineChartCollection
									selected={item}
									reportType={reportType}
									data={scorePerSecond[item]}
									activeTabName={activeTabName}
									settings={settings.data[item]}
								/>
							</Tabs.TabPane>
						))}
					</Tabs>
				</TabsContainer>
			</Row>
			<Row type="flex" align="middle" justify="center">
				<Col>
					<p style={{ opacity: '0.6' }}>
						(*) HIGNETT, Sue; MCATAMNEY, Lynn. Rapid entire body assessment (REBA).
						Applied ergonomics, v. 31, n. 2, p. 201-205, 2000.
					</p>
				</Col>
			</Row>
		</>
	);
}
