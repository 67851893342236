import Api from '@/services/api';

import { SeraSummaryRequest, CreateSeraSummaryRequest, UpdateSeraSummaryRequest } from '../../hooks/types/request';
import { SeraSummaryDTO } from '../../hooks/types/response';

export class SeraSummaryService {
	async getSeraSummary(payload: SeraSummaryRequest): Promise<SeraSummaryDTO> {
		const url = `/sera/summary/${payload.sera_summary_id}`;
		const { data } = await Api.get<SeraSummaryDTO>(url, {
			params: payload
		});
		return data;
	}

	async createSeraSummary(body: CreateSeraSummaryRequest): Promise<SeraSummaryDTO> {
		const url = `/sera/summary`;
		const { data } = await Api.post<SeraSummaryDTO>(url, body);
		return data;
	}

	async updateSeraSummary(body: UpdateSeraSummaryRequest): Promise<SeraSummaryDTO> {
		const url = `/sera/summary/${body.id}`;
		const { data } = await Api.put<SeraSummaryDTO>(url, body);
		return data;
	}
}
