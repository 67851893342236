import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { notification } from 'antd';

import {
	CustomReportFilesRequest,
	CustomReportStepsRequest,
	CustomReportsRequest,
	FrequenciesRequest,
	StressLevelsRequest,
	TotalTaskDurationsRequest,
	CustomReportRequest
} from '@/components/ui/Inputs/types/request/customReport';
import { TotalTaskDurationDTO } from '@/components/ui/Inputs/types/response/totalTaskDuration';
import { CustomReportStepDTO } from '@/components/ui/Inputs/types/response/customReport';
import { StressLevelDTO } from '@/components/ui/Inputs/types/response/stressLevel';
import { FrequencyDTO } from '@/components/ui/Inputs/types/response/frequency';
import { TasksRequest } from '@/components/ui/Inputs/types/request/task';

import { CustomReportService } from '../services/api/customReportService';
import { BeraStepsService } from '../services/api/beraStepsService';
import { BeraJobService } from '../services/api/beraJobService';
import { BeraService } from '../services/api/beraService';

import {
	BeraReportRequest,
	BeraJobSummaryRequest,
	CreateBeraJobSummaryRequest,
	UpdateBeraJobSummaryRequest,
	UpdateBeraResultRequest,
	CreateBeraResultRequest,
	UpdateBeraJobScoresRequest
} from './types/request';
import { BeraJobSummaryDTO, BeraReportDTO } from './types/response';

const beraService = new BeraService();
const beraJobService = new BeraJobService();
const beraStepsService = new BeraStepsService();
const customReportService = new CustomReportService();

export function useBeraJobSummary({ organization_id, company_id, bera_job_summary_id }: BeraJobSummaryRequest) {
	const enabled = !!organization_id && !!company_id;
	const summary = useQuery(
		['bera_job_summary', [organization_id, company_id, bera_job_summary_id]],
		() => beraJobService.getBeraJobSummary({ organization_id, company_id, bera_job_summary_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...summary,
		data: summary.data ?? ({} as BeraJobSummaryDTO)
	};
}

export function useUpdateBeraJobScores() {
	const queryClient = useQueryClient();
	return useMutation((body: UpdateBeraJobScoresRequest) => beraJobService.updateBeraJobScores(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0]?.split('" ') ?? err;
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['bera_job_summary']);
			queryClient.invalidateQueries(['bera_job_summary_list']);
			queryClient.invalidateQueries(['bera_report_result']);
			queryClient.invalidateQueries(['bera_report']);
		}
	});
}

export function useBeraReport({ organization_id, company_id, file_id, bera_job_summary_id }: BeraReportRequest) {
	const enabled = !!organization_id && !!company_id && !!file_id && !!bera_job_summary_id;
	const report = useQuery(
		['bera_report', [organization_id, company_id, file_id, bera_job_summary_id]],
		() => beraService.getBeraReport({ organization_id, company_id, file_id, bera_job_summary_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...report,
		data: report.data ?? ({} as BeraReportDTO)
	};
}

export function useCustomReport({ organization_id, company_id, reportName }: CustomReportRequest) {
	const enabled = !!organization_id && !!company_id && !!reportName;
	const beraFiles = useQuery(
		['custom_report', [organization_id, company_id, reportName]],
		() => customReportService.getCustomReport({ organization_id, company_id, reportName }),
		{
			enabled,
			retry: 0
		}
	);
	return beraFiles;
}

export function useBeraFiles({
	bera_job_summary_id,
	custom_report_id,
	organization_id,
	report_type,
	company_id,
	fileName,
	offset,
	limit
}: CustomReportFilesRequest) {
	const enabled = !!organization_id && !!company_id && !!custom_report_id;
	const beraFiles = useQuery(
		[
			'bera_files',
			[organization_id, company_id, custom_report_id, bera_job_summary_id, report_type, offset, limit, fileName]
		],
		() =>
			beraService.getBeraFiles({
				bera_job_summary_id,
				custom_report_id,
				organization_id,
				report_type,
				company_id,
				fileName,
				offset,
				limit
			}),
		{
			enabled,
			retry: 0
		}
	);
	return beraFiles;
}

export function useCustomReports({ organization_id, company_id }: CustomReportsRequest) {
	const enabled = !!organization_id && !!company_id;
	const customReports = useQuery(
		['custom_reports', [organization_id, company_id]],
		() => customReportService.getCustomReports({ organization_id, company_id }),
		{
			enabled,
			retry: 0
		}
	);
	return customReports;
}

export function useBeraSteps({ organization_id, company_id, custom_report_id }: CustomReportStepsRequest) {
	const enabled = !!organization_id && !!company_id && !!custom_report_id;
	const customReportsSteps = useQuery(
		['custom_reports_steps', [organization_id, company_id, custom_report_id]],
		() => customReportService.getCustomReportsSteps({ organization_id, company_id, custom_report_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...customReportsSteps,
		data: customReportsSteps.data ?? ([] as CustomReportStepDTO[])
	};
}

export function useFrequencies({ organization_id, company_id, step_key_id }: FrequenciesRequest) {
	const enabled = !!organization_id && !!company_id && !!step_key_id;
	const frequencies = useQuery(
		['frequencies', [organization_id, company_id, step_key_id]],
		() => beraStepsService.getFrequencies({ organization_id, company_id, step_key_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...frequencies,
		data: frequencies.data ?? ([] as FrequencyDTO[])
	};
}

export function useStressLevels({ organization_id, company_id, step_key_id }: StressLevelsRequest) {
	const enabled = !!organization_id && !!company_id && !!step_key_id;
	const stressLevels = useQuery(
		['stress_levels', [organization_id, company_id, step_key_id]],
		() => beraStepsService.getStressLevels({ organization_id, company_id, step_key_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...stressLevels,
		data: stressLevels.data ?? ([] as StressLevelDTO[])
	};
}

export function useTotalTaskDurations({ organization_id, company_id, step_key_id }: TotalTaskDurationsRequest) {
	const enabled = !!organization_id && !!company_id && !!step_key_id;
	const totalTaskDurations = useQuery(
		['total_task_durations', [organization_id, company_id, step_key_id]],
		() => beraStepsService.getTotalTaskDurations({ organization_id, company_id, step_key_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...totalTaskDurations,
		data: totalTaskDurations.data ?? ([] as TotalTaskDurationDTO[])
	};
}

export function useTasks({ organization_id, company_id, cycle_id }: TasksRequest) {
	const enabled = !!organization_id && !!company_id && !!cycle_id;
	const tasks = useQuery(
		['tasks', [organization_id, company_id, cycle_id]],
		() => beraStepsService.getTasks({ organization_id, company_id, cycle_id }),
		{
			enabled,
			retry: 0
		}
	);
	return tasks;
}

export const useCreateBeraJobSummary = () => {
	const queryClient = useQueryClient();
	return useMutation((body: CreateBeraJobSummaryRequest) => beraJobService.createBeraJobSummary(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0]?.split('" ') ?? err;
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['bera_report_result']);
			queryClient.invalidateQueries(['bera_job_summary_list']);
			queryClient.invalidateQueries(['bera_report']);
		}
	});
};

export const useUpdateBeraJobSummary = () => {
	const queryClient = useQueryClient();
	return useMutation((body: UpdateBeraJobSummaryRequest) => beraJobService.updateBeraJobSummary(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0]?.split('" ') ?? err;
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['bera_report_result']);
			queryClient.invalidateQueries(['bera_job_summary_list']);
			queryClient.invalidateQueries(['bera_report']);
		}
	});
};

export const useCreateBeraResult = () => {
	const queryClient = useQueryClient();
	return useMutation((body: CreateBeraResultRequest) => beraService.createBeraReport(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0]?.split('" ') ?? err;
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['bera_report_result']);
			queryClient.invalidateQueries(['bera_job_summary_list']);
			queryClient.invalidateQueries(['bera_report']);
			queryClient.invalidateQueries(['tasks']);
			queryClient.invalidateQueries(['bera_files']);
		}
	});
};

export const useUpdateBeraResult = () => {
	const queryClient = useQueryClient();
	return useMutation((body: UpdateBeraResultRequest) => beraService.updateBeraReport(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0]?.split('" ') ?? err;
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['bera_report_result']);
			queryClient.invalidateQueries(['bera_report']);
			queryClient.invalidateQueries(['tasks']);
			queryClient.invalidateQueries(['bera_files']);
		}
	});
};
