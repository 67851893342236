import React from 'react';
import moment from 'moment';
import { Row, Col, Table } from 'antd';
import { I18n } from '@aws-amplify/core';
import { ColumnsType } from 'antd/es/table';
import { Text } from '@/components/Typography';

import { riskLevelToColorMapper } from '@/components/views/JdsD92/context/types';
import { StepKeyResultAdditionalItemTitles } from '@/hooks';
import { riskColors } from '@/components/CustomReports';
import { useStepKeysResultsContext } from '../context';
import { stressDescription } from '../../index';
import { NOT_APPLICABLE } from '@/constants';
import { RiskRowTable, TableContainer, TableContainerRpn } from '../../../styles';

interface DataTypeDynamic {
	key: string;
	color: string;
	title_1: string | number;
	title_2?: string | number;
	title_3?: string | number;
	title_4?: string | number;
	title_5?: string | number;
	title_6?: string | number;
}

interface DataTypeRisk {
	key: string;
	risk_category?: string;
	risk_damage?: string;
	risk_description?: string;
}

interface DataType {
	key: string;
	severity?: string;
	exposure?: string;
	vulnerability?: string;
}

interface DataTypeRpn {
	key: string;
	score: string;
}

const SHARED_RESULTS = ['shoulder_moment_calculator'];

export function RiskDescription() {
	const { stepKey } = useStepKeysResultsContext();

	const {
		score,
		severity,
		exposure,
		vulnerability,
		risk_scale: riskScale,
		risk_damage: riskDamage,
		risk_category: riskCategory,
		risk_description: riskDescription,
		additional_items: additionalComponent,
		name
	} = stepKey;

	const verifyIfResultIsShared = () => {
		return SHARED_RESULTS.includes(name);
	};

	const locale = moment.locale();

	const columns_risk: ColumnsType<DataTypeRisk> = [
		{
			key: riskCategory?.id,
			dataIndex: 'risk_category',
			title: I18n.get('Risk category'),
			render: (risk_category) => I18n.get(risk_category)
		},
		{
			key: riskDescription?.id,
			dataIndex: 'risk_description',
			title: I18n.get('Risk description'),
			render: (risk_description) => I18n.get(risk_description)
		},
		{
			key: riskDamage?.id,
			dataIndex: 'risk_damage',
			title: I18n.get('Risk damage'),
			render: (risk_damage) => I18n.get(risk_damage)
		}
	];

	const columns: ColumnsType<DataType> = [
		{
			width: '33.3333%',
			key: severity?.id,
			dataIndex: 'severity',
			title: I18n.get('Severity'),
			render: (severity) => I18n.get(severity)
		},
		{
			width: '33.3333%',
			key: vulnerability?.id,
			dataIndex: 'vulnerability',
			title: I18n.get('Vulnerability'),
			render: (vulnerability) => I18n.get(vulnerability)
		},
		{
			key: exposure?.id,
			width: '33.3333%',
			dataIndex: 'exposure',
			title: I18n.get('Exposure'),
			render: (exposure) => I18n.get(exposure)
		}
	];
	const columns_rpn: ColumnsType<DataTypeRpn> = [
		{
			title: I18n.get(stressDescription[riskScale ?? 0]),
			dataIndex: 'score',
			key: 'score'
		}
	];

	const data_risk: DataTypeRisk[] = [
		{
			key: '1',
			risk_category: riskCategory?.description ?? 'N/A',
			risk_damage: riskDamage?.description ?? 'N/A',
			risk_description: riskDescription?.description ?? 'N/A'
		}
	];

	const data: DataType[] = [
		{
			key: '1',
			severity: severity?.description ?? 'N/A',
			exposure: exposure?.description ?? 'N/A',
			vulnerability: vulnerability?.description ?? 'N/A'
		}
	];

	const data_rpn: DataTypeRpn[] = [
		{
			key: '1',
			score: `RPN: ${score ?? 0}`
		}
	];

	const getColumns = (): ColumnsType<DataTypeDynamic> | undefined => {
		if (name !== 'manually_operated_carts') {
			const columns = additionalComponent?.titles.map(({ title, unit }, index, titles) => {
				const unitLabel = unit ? ` ${I18n.get(unit)}` : '';

				return {
					title: `${I18n.get(title)}`,
					dataIndex: `title_${index + 1}`,
					key: `title_${index + 1}`,
					align: 'center',
					className: isLastColumn(index, titles) ? 'column-active' : '',
					onCell: (_: any, i: number) => {
						if (!verifyIfResultIsShared()) {
							return { rowSpan: 1, colSpan: 1 };
						}
						if (i === 0 && index + 1 === additionalComponent?.titles.length) {
							return { rowSpan: 100 };
						}
						if (i !== 0 && index + 1 === additionalComponent?.titles.length) {
							return { rowSpan: 0, colSpan: 0 };
						}

						return {};
					},
					render: (value: string | number, allObject: DataTypeDynamic) => (
						<>
							<Text>
								{I18n.get(value)} {unitLabel}
							</Text>
							{addRiskColorColumn(index, allObject, titles)}
						</>
					)
				};
			});

			return columns as ColumnsType<DataTypeDynamic>;
		}

		return [
			{
				title: I18n.get('Informations'),
				dataIndex: `title_1`,
				key: `title_1`,
				align: 'center',
				render: (value) => (
					<>
						<Text>{value}</Text>
					</>
				)
			},
			{
				title: I18n.get('Condition found'),
				dataIndex: `title_2`,
				key: `title_2`,
				align: 'center',
				className: 'column-active',
				render: (value, allObject) => (
					<>
						<Text>{value === '1' ? 'Yes' : 'No'}</Text>
						<RiskRowTable $isFirstColumn={false} $riskColor={allObject.color} $isMoreThanOne={true} />
					</>
				)
			}
		];
	};

	const columns_dynamic = getColumns();

	const data_dynamic: DataTypeDynamic[] | undefined = additionalComponent?.results.map(
		({ id, result = 0, value_1, value_2, value_3, value_4, value_5, value_6 }) => {
			if (!result) {
				return {
					key: id,
					title_1: NOT_APPLICABLE,
					title_2: NOT_APPLICABLE,
					title_3: NOT_APPLICABLE,
					title_4: NOT_APPLICABLE,
					title_5: NOT_APPLICABLE,
					title_6: NOT_APPLICABLE,
					color: riskColors[result]
				};
			}

			return {
				key: id,
				title_1: formatNumberByLocale(value_1) ?? NOT_APPLICABLE,
				title_2: formatNumberByLocale(value_2),
				title_3: formatNumberByLocale(value_3),
				title_4: formatNumberByLocale(value_4),
				title_5: formatNumberByLocale(value_5),
				title_6: formatNumberByLocale(value_6),
				color: riskColors[result]
			};
		}
	);

	function formatNumberByLocale(value?: string | number) {
		if (!value) {
			return NOT_APPLICABLE;
		}

		if (value && typeof value === 'number') {
			const transformedValue = Number(value.toFixed(2));

			return new Intl.NumberFormat(locale).format(transformedValue);
		}

		return value;
	}

	function addRiskColorColumn(
		index: number,
		allObject: DataTypeDynamic,
		titles: StepKeyResultAdditionalItemTitles[]
	): JSX.Element | undefined {
		if (isLastColumn(index, titles)) {
			const isMoreThanOne = additionalComponent ? additionalComponent.results.length > 1 : false;

			const isSharedResult = verifyIfResultIsShared();

			return (
				<RiskRowTable
					$isFirstColumn={index === 0}
					$riskColor={allObject.color}
					$top={isSharedResult ? '-58px' : null}
					$isMoreThanOne={isSharedResult ? false : isMoreThanOne}
				/>
			);
		}
	}

	function isLastColumn(index: number, titles: StepKeyResultAdditionalItemTitles[]): boolean {
		return index === titles.length - 1;
	}

	return (
		<Col span={24}>
			<Row gutter={[0, 20]}>
				<Col span={24}>
					<TableContainer $alignTitle="center">
						<Table columns={columns_risk} dataSource={data_risk} pagination={false} />
					</TableContainer>
				</Col>
				<Col span={24}>
					<Row justify="start" align="middle" gutter={[10, 20]}>
						{data_dynamic && (
							<Col lg={22} xl={20} xxl={19}>
								<TableContainer $alignTitle="center">
									<Table columns={columns_dynamic} dataSource={data_dynamic} pagination={false} />
								</TableContainer>
							</Col>
						)}
					</Row>
					<Row justify="start" style={{ marginTop: 30 }} gutter={[10, 20]}>
						<Col lg={24} xl={18} xxl={20}>
							<TableContainer $alignTitle="center">
								<Table columns={columns} dataSource={data} pagination={false} />
							</TableContainer>
						</Col>
						<Col lg={10} xl={6} xxl={4} >
							<TableContainerRpn $riskColor={riskLevelToColorMapper[riskScale ?? 0]} >
								<Table columns={columns_rpn} dataSource={data_rpn} pagination={false} />
							</TableContainerRpn>
						</Col>
					</Row>
				</Col>
			</Row>
		</Col>
	);
}
