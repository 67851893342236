import React, { useContext } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CollectionDate } from './CollectionDate';
import { MergeAEPContext } from '../../context';
import { Organization } from './Organization';
import { Workstation } from './Workstation';
import { CreatedAt } from './CreatedAt';
import { FileName } from './FileName';
import { Company } from './Company';
import { Analyst } from './Analyst';
import { Sector } from './Sector';

type FilterProps = {
	onFilter: () => void;
	onClearFilter: () => void;
};

export const Filter: React.FC<FilterProps> = ({ onFilter, onClearFilter }) => {
	const { organizationId, companyId } = useContext(MergeAEPContext);

	return (
		<Collapse defaultActiveKey={['1']}>
			<Collapse.Panel header={I18n.get('Filter')} key="1">
				<Row justify="end" style={{ marginBottom: '1rem' }}>
					<Col span={'auto'}>
						<Button type="link" onClick={() => onClearFilter()}>
							{I18n.get('Clear')}
						</Button>
					</Col>
				</Row>
				<Row justify={'center'} gutter={[16, 16]}>
					<Col xs={20} lg={10}>
						<Organization />
					</Col>
					<Col xs={20} lg={10}>
						<Company />
					</Col>
				</Row>
				<Row justify={'center'} gutter={[16, 16]}>
					<Col xs={20} lg={10}>
						<Sector />
					</Col>
					<Col xs={20} lg={10}>
						<Workstation />
					</Col>
				</Row>
				<Row justify={'center'} gutter={[16, 16]}>
					<Col xs={20} lg={10}>
						<Analyst />
					</Col>
					<Col xs={20} lg={10}>
						<FileName />
					</Col>
				</Row>
				<Row justify="center" gutter={[16, 16]}>
					<Col span={20}>
						<CollectionDate />
					</Col>
				</Row>
				<Row justify="center" gutter={[16, 16]}>
					<Col span={20}>
						<CreatedAt />
					</Col>
				</Row>
				<Row justify="end">
					<Col span={'auto'}>
						<Button
							disabled={!organizationId || !companyId}
							onClick={onFilter}
							icon={<SearchOutlined />}
							type="primary"
						>
							{I18n.get('Search')}
						</Button>
					</Col>
				</Row>
			</Collapse.Panel>
		</Collapse>
	);
};
