import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Title } from '@/components/Typography';
import { useEwaJdsD86Context } from '../context';
import { useLocation } from 'react-router-dom';

const getTitle = ({ isEwa, isReview }: { isEwa: boolean, isReview: boolean }) => {
	if (isEwa) {
		return I18n.get('Preliminary Ergonomic Analysis') + ' | NR-17 + JDS-D86';
	}

	if (isReview) {
		return I18n.get('Ergonomic revaluation') + ' JDS-D86'
	}

	return I18n.get('Evaluation') + ' JDS-D86'
}

export const Header: React.FC = () => {
	const { isEwa } = useEwaJdsD86Context();
	const isReview = useLocation().pathname.includes('review');
	const title = getTitle({ isEwa, isReview });
	return (
		<Row justify="center" style={{ marginTop: '1rem' }}>
			<Col>
				<Title level={2}>{title}</Title>
			</Col>
		</Row>
	);
}
