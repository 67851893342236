import React, { ReactElement, useState } from 'react';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { Button, Divider, Form, Input, Select } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { I18n } from '@aws-amplify/core';

import { useApplicationContext } from '@/context/v1/Application/context';
import { GetActivitiesResponse, useCreateActivity } from '@/hooks';
import { CreateActivityRequest } from '../types/request/activity';

const { useFormInstance } = Form;

interface ActivitySelectProps {
	size?: SizeType;
	loading: boolean;
	disabled: boolean;
	companyId?: string;
	fieldName: NamePath;
	activityId?: string;
	workstationId?: string;
	organizationId?: string;
	allowCreation?: boolean;
	options: GetActivitiesResponse[] | undefined;
	handleClearActivities?: () => void;
	handleSelectActivity?: (id: string) => void;
}

type OptionType = { label: string; value: string } | undefined;

export function ActivitySelect({
	loading,
	disabled,
	fieldName,
	companyId,
	activityId,
	options = [],
	workstationId,
	organizationId,
	size = 'middle',
	allowCreation = true,
	handleSelectActivity,
	handleClearActivities
}: Readonly<ActivitySelectProps>) {
	const [activityName, setActivityName] = useState('');

	const { validateFields } = useFormInstance();
	const { company, organization } = useApplicationContext();

	const { mutateAsync: createActivity, isLoading } = useCreateActivity();

	async function onCreateActivity() {
		const body: CreateActivityRequest = {
			company_id: companyId ?? company.id,
			organization_id: organizationId ?? organization.id,
			workstation_id: workstationId as string,
			name: activityName
		};

		const createdActivity = await createActivity(body);

		const activityId = createdActivity?.id;

		if (activityId) {
			handleChange(activityId);
		}
		setActivityName('');
	}

	async function handleChange(id: string) {
		if (handleSelectActivity) {
			handleSelectActivity(id);
			await validateFields([fieldName]);
		}
	}

	function handleClear() {
		if (handleClearActivities) {
			handleClearActivities();
		}
	}

	function handleActivityNameChange(name: string) {
		setActivityName(name);
	}

	function filterOption(option: OptionType, input: string): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function showDropdownRender(menu: ReactElement): JSX.Element {
		if (!allowCreation) {
			return <div>{menu}</div>;
		}
		return (
			<div>
				{menu}
				<Divider style={{ margin: '4px 0' }} />
				<div
					style={{
						display: 'flex',
						flexWrap: 'nowrap',
						padding: 8
					}}
				>
					<Input
						style={{ flex: 'auto', borderRadius: '7px' }}
						value={activityName}
						onChange={(e) => handleActivityNameChange(e.target.value)}
					/>
					<Button
						type="link"
						loading={isLoading}
						onClick={onCreateActivity}
						disabled={!activityName || activityName.length < 3}
					>
						{I18n.get('Add item')}
					</Button>
				</div>
			</div>
		);
	}

	return (
		<Select
			showSearch
			allowClear
			size={size}
			loading={loading}
			disabled={disabled}
			value={activityId}
			onClear={handleClear}
			placeholder={I18n.get('Activity')}
			onChange={(value: string) => handleChange(value)}
			dropdownRender={(menu) => showDropdownRender(menu)}
			filterOption={(input, option) => filterOption(option, input)}
			options={options?.map(({ id, name }) => ({ label: name, value: id }))}
		/>
	);
}
