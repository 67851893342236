import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';

import { useApplicationContext } from '@/context/v1/Application/context';
import { Spinner } from '@/components/Spinner';
import { I18n } from '@aws-amplify/core';

import { ErrorGettingData, GettingData, TreemapContent } from '../styles';
import { DataByStepType, MosaicData } from '../context/index.types';
import { WorstScoreDTO } from '../hooks/types/response';
import { useTreemapContext } from '../context';
import { TreemapContainer } from './styles';
import { Chart } from './Chart';
import { Menu } from './Menu';
import {
	useGetCompaniesWorstScore,
	useGetLinesWorstScore,
	useGetSectorsWorstScore,
	useGetWorkstationsWorstScore
} from '../hooks/hooks';

export interface TreemapProps {
	data: MosaicData;
}

export const Treemap: React.FC = () => {
	const { company, organization } = useApplicationContext();
	const { page, lineId, sectorId, companyId, isLoadingData, errorGettingData } = useTreemapContext();
	const { handleFetchLines, handleFetchCompanies, handleFetchSectors, handleFetchWorkstations } = useTreemapContext();

	const [data, setData] = useState<MosaicData>([]);

	const {
		data: companiesData,
		isFetching: fetchingCompanies,
		isError: errorGettingCompanies
	} = useGetCompaniesWorstScore(organization?.id, company?.id, page);

	const {
		data: workstationsData,
		isFetching: fetchingWorkstations,
		isError: errorGettingWorkstations
	} = useGetWorkstationsWorstScore(organization?.id, company?.id, lineId, page);

	const {
		data: sectorsData,
		isFetching: fetchingSectors,
		isError: errorGettingSectors
	} = useGetSectorsWorstScore(organization?.id, companyId, page);

	const {
		data: linesData,
		isFetching: fetchingLines,
		isError: errorGettingLines
	} = useGetLinesWorstScore(organization?.id, company?.id, sectorId, page);

	function mapDataToTreemap(dataToMap: WorstScoreDTO[]): MosaicData {
		return dataToMap.map((data) => ({
			count: data.size,
			id: data.id,
			title: data.name,
			worst_score: data.worst_score,
			child: data.child
		}));
	}

	useEffect(() => {
		handleFetchLines(mapDataToTreemap(linesData), fetchingLines, errorGettingLines);
		handleFetchSectors(mapDataToTreemap(sectorsData), fetchingSectors, errorGettingSectors);
		handleFetchWorkstations(mapDataToTreemap(workstationsData), fetchingWorkstations, errorGettingWorkstations);
		handleFetchCompanies(mapDataToTreemap(companiesData), fetchingCompanies, errorGettingCompanies);
	}, [fetchingCompanies, fetchingWorkstations, fetchingSectors, fetchingLines, data]);

	useEffect(() => {
		const dataByStepMapper: DataByStepType = {
			0: companiesData,
			1: sectorsData,
			2: linesData,
			3: workstationsData
		};
		const mappedData = mapDataToTreemap(dataByStepMapper[page]);
		setData(mappedData);
	}, [page, fetchingCompanies, fetchingWorkstations, fetchingSectors, fetchingLines]);

	if (errorGettingData) {
		return (
			<Row justify="center">
				<Col span={24}>
					<TreemapContainer page={page} justify="center">
						<ErrorGettingData span={24}>
							<span>{I18n.get('Oops... Something went wrong!')}</span>
						</ErrorGettingData>
					</TreemapContainer>
				</Col>
			</Row>
		);
	}

	if (isLoadingData) {
		return (
			<Row justify="center">
				<Col span={24}>
					<TreemapContainer page={page} justify="center">
						<GettingData span={24}>
							<Spinner size={48} />
						</GettingData>
					</TreemapContainer>
				</Col>
			</Row>
		);
	}

	return (
		<Row justify="center" style={{ marginBottom: '1rem' }}>
			<Col span={24}>
				<Row justify="start" align="middle">
					{page > 0 && <Menu />}
				</Row>

				<TreemapContainer page={page} justify="center">
					<TreemapContent span={24}>
						<Chart data={data} />
					</TreemapContent>
				</TreemapContainer>
			</Col>
		</Row>
	);
};
