export const CONDITION_INPUT_CONSTANTS = {
	DESCRIPTION: 'Select the appropriate options',
	UNFAVORABLE_WORKING_CONDITIONS:
		'Unfavorable working conditions - more than one can be selected',
	UNFAVORABLE_VEHICLE_PROPERTIES:
		'Unfavorable vehicle properties - more than one can be selected',
	REGULARLY_SIGNIFICANTLY:
		'Regularly significantly increased starting forces, because transport devices sink into the ground or get wedged',
	FREQUENT_STOPS_WITH_BREAKING: 'Frequent stops with breaking',
	FREQUENT_STOPS_WITHOUT_BREAKING: 'Frequent stops without breaking',
	MANY_CHANGES_DIRECTION: 'Many changes of direction or curves, frequent manouvering',
	LOAD_MUST_POSITIONED:
		'Load must be positioned precisely and stopped, driveway must be adhered to precisely',
	INCREASED_MOVEMENT_SPEED: 'Increased movement speed (approx. 1.0 up to 1.3 m/s)',
	THRE_IS_NOT: 'None: there are no unfavorable working conditions',

	NO_SUITABLE_HANDLES: 'No suitable handles or construction parts for applying force',
	NO_BRAKE_WHEN_DRIVING: 'No brake when driving on inclinations > 2° (> 3%)',
	UNADJUSTED_CASTORS: 'Unadjusted castors (e.g. too small on soft or uneven floor)',
	DEFECTIVE_CASTORS: 'Defective castors (worn-out, rubbing, stiff, air pressure too low)',
	THRE_IS_NOT_TRANSPORT_DEVICES:
		'None: there are no unfavorable properties of the transport devices'
};

export const POSTURE_INPUT_CONSTANTS = {
	DESCRIPTION: 'Select the appropriate options',
	POSTURES: 'Postures',
	WORK_ORGANIZATION: 'Work organization / temporal distribution',
	TRUNK_UPRIGHT:
		'Trunk upright or slightly inclined forward, no twisting / Force application height can be selected freely / No hindrance for the legs',
	BODY_INCLINED:
		'Body inclined towards the direction of movement or slight twisting when pulling the load on one side / Fixed force application height ranging from 0.9 – 1.2 m / No or only slight hindrance for the legs / Predominantly pulling',
	AWKWARD_BODY:
		'Awkward body postures caused by fixed force application height < 0.9 or > 1.2 m , lateral force application on one side or significantly obstructed view / Significant hindrance for the legs / Constant twisting and/or lateral inclination of the trunk',
	FREQUENT_VARIATION:
		'Frequent variation of the physical workload situation due to other activities (including other types of physical workload) / without a tight sequence of higher physical workloads within one type of physical workload during a single working day.',
	RARE_VARIATION:
		'Rare variation of the physical workload situation due to other activities (including other types of physical workload) / occasional tight sequence of higher physical workloads within one type of physical workload during a single working day.',
	NO_HARDLY:
		'No/hardly any variation of the physical workload situation due to other activities (including other types of physical workload) / frequent tight sequence of higher physical workloads within one type of physical workload during a single working day with concurrent high load peaks.',
	UNFAVORABLE: 'Unfavorable'
};
