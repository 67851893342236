import Api from '@/services/api';

import { BaseContext } from '@/components/ui/Inputs/types/request';
import {
	UpdateBaseRequest,
	CompanyResponse,
	FetchLinesRequest,
	FetchWorkstationsRequest,
	LineResponse,
	SectorResponse,
	WorkstationResponse,
	DeleteBaseRequest,
	CreateBaseRequest,
	FetchCompaniesRequest
} from '../types';

export class ManageCompanyService {
	private urlMapper = {
		line: '/line',
		sector: '/sector',
		company: '/company',
		workstation: '/workstation'
	};

	public async fetchCompanies({ organization_id, company_id, searching_organization_id }: FetchCompaniesRequest) {
		const baseUrl = '/company/list';
		const { data } = await Api.get<CompanyResponse[]>(baseUrl, {
			params: {
				searching_organization_id,
				organization_id,
				company_id
			}
		});
		return data;
	}

	public async fetchSectors({ organization_id, company_id }: BaseContext) {
		const baseUrl = '/sector/list';
		const { data } = await Api.get<SectorResponse[]>(baseUrl, {
			params: {
				organization_id,
				company_id
			}
		});
		return data;
	}

	public async fetchLines({ organization_id, company_id, sector_id }: FetchLinesRequest) {
		const baseUrl = '/line/list';
		const { data } = await Api.get<LineResponse[]>(baseUrl, {
			params: {
				organization_id,
				company_id,
				sector_id
			}
		});
		return data;
	}

	public async fetchWorkstations({ organization_id, company_id, line_id }: FetchWorkstationsRequest) {
		const baseUrl = '/workstation/list';
		const { data } = await Api.get<WorkstationResponse[]>(baseUrl, {
			params: {
				organization_id,
				company_id,
				line_id
			}
		});
		return data;
	}

	public async create<Body extends CreateBaseRequest, T>(body: Body): Promise<T> {
		const url = `${this.urlMapper[body.hierarchy_field]}/create`;
		const { data } = await Api.post<T>(url, body);
		return data;
	}

	public async update<Body extends UpdateBaseRequest, T>(body: Body): Promise<T> {
		const url: string = this.urlMapper[body.hierarchy_field];
		const { data } = await Api.put<T>(url, body);
		return data;
	}

	public async delete<Body extends DeleteBaseRequest, T>(body: Body): Promise<T> {
		const url: string = this.urlMapper[body.hierarchy_field];
		const { data } = await Api.delete<T>(url, {
			params: body
		});
		return data;
	}
}
