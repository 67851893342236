import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Tooltip } from 'antd';

import { Caption, Container, RiskBarCaption, RiskBarContainer, RiskBarContent, RiskScale, Value } from './styles';
import { defaultOptions, Risk, RiskBarOptions } from '../types';
import { useDashboardContext } from '../../context';
import { DashboardQueryParams } from '../../types';

type HorizontalProps = {
	risks: Risk[];
	options?: RiskBarOptions;
};

export const Horizontal: React.FC<HorizontalProps> = ({ risks, options = defaultOptions }) => {
	const { handleFilterSelection } = useDashboardContext();

	const handleTypes = (risk: Risk) => {
		const NIOSH_RISK = ['VERY_LOW', 'LOW', 'MODERATE', 'HIGH', 'VERY_HIGH'];

		const types = [
			{ rsi: risk.score! },
			{ niosh: NIOSH_RISK[risk.score! - 1] },
			{ work_organization: risk.score! },
			{ workstations: { score: risk.score!, workstation: risk.workstation! } },
			{ reba: { score: risk.score!, body_part: risk.body_part! } },
			{ action_plan: risk.status }
		];
		const getTypes = types.find((elem) => Object.keys(elem)[0] === risk.report);
		return getTypes ? getTypes : {};
	};
	return (
		<Container orientation={options.orientation}>
			{risks.map((risk) => {
				return (
					risk.percentage !== '0%' && (
						<RiskBarContainer
							className="risk-horizontal-chart"
							percentage={risk.percentage}
							orientation={options.orientation}
							key={risk.id}
							valueDisplay={options.value?.display}
						>
							{options.isNewDesign ? (
								<>
									<NewRiskBarDisplay risks={risks} risk={risk} options={options} />
									{(risk.caption || risk.value) && (
										<NewCaptionDisplay options={options} risk={risk} />
									)}
								</>
							) : (
								<>
									{(risk.caption || risk.value) && <CaptionDisplay options={options} risk={risk} />}
									<RiskBarDisplay
										risks={risks}
										risk={risk}
										options={options}
										handleTypes={handleTypes}
										handleFilterSelection={handleFilterSelection}
									/>
								</>
							)}
						</RiskBarContainer>
					)
				);
			})}
		</Container>
	);
};

type HorizontalSingleProps = {
	risk: Risk;
	options: RiskBarOptions;
};

const CaptionDisplay: React.FC<HorizontalSingleProps> = ({ options, risk }) => {
	return (
		<RiskBarCaption
			orientation={options.orientation}
			captionDisplay={options.caption.display}
			valueDisplay={options.value?.display}
		>
			{risk.caption && risk.value ? (
				<Caption
					title={I18n.get(risk.caption)}
					style={{
						fontWeight: risk.trailColor === '#d6d6d6' ? 600 : 400
					}}
				>
					{Number(risk.percentage.split('%')[0]) > 20 ? I18n.get(risk.caption) : <br />}
				</Caption>
			) : (
				<Caption>
					<span>
						<br />
					</span>
				</Caption>
			)}
			{risk.value ? (
				<Value style={{ color: risk.fontColor }}>
					{Number(risk.percentage.split('%')[0]) < 20 ? (
						<Tooltip title={risk.caption}>{risk.value}</Tooltip>
					) : (
						risk.value
					)}
				</Value>
			) : (
				<Value>
					<span>
						<br />
					</span>
				</Value>
			)}
		</RiskBarCaption>
	);
};

const NewCaptionDisplay: React.FC<HorizontalSingleProps> = ({ options, risk }) => {
	return (
		<RiskBarCaption
			orientation={options.orientation}
			captionDisplay={options.caption.display}
			valueDisplay={options.value?.display}
		>
			{risk.value ? (
				<Value style={{ color: risk.fontColor }}>
					{Number(risk.percentage.split('%')[0]) < 20 ? (
						<Tooltip title={risk.caption}>{risk.value}</Tooltip>
					) : (
						risk.value
					)}
				</Value>
			) : (
				<Value>
					<span>
						<br />
					</span>
				</Value>
			)}
		</RiskBarCaption>
	);
};

type RiskBarDisplayProps = {
	risk: Risk;
	risks: Risk[];
	options: RiskBarOptions;
	handleFilterSelection: ({ lineId, sectorId, stressLevel, workstationId, rsi }: DashboardQueryParams) => void;
	handleTypes: (risk: Risk) => {};
};

const RiskBarDisplay: React.FC<RiskBarDisplayProps> = ({
	options,
	risk,
	risks,
	handleFilterSelection,
	handleTypes
}) => {
	return (
		<RiskBarContent
			orientation={options.orientation}
			align={options.caption.align}
			percentage={risk.percentage}
			captionDisplay={options.caption?.display}
			valueDisplay={options.value?.display}
		>
			{Number(risk.percentage.split('%')[0]) < 20 ? (
				<Tooltip title={risk.caption}>
					<RiskScale
						className="risk-bar"
						onClick={() => {
							handleFilterSelection(handleTypes(risk)!);
						}}
						riskId={risk.id}
						length={risks.length}
						orientation={options.orientation}
						color={risk.trailColor}
					/>
				</Tooltip>
			) : (
				<RiskScale
					className="risk-bar"
					onClick={() => {
						handleFilterSelection(handleTypes(risk)!);
					}}
					riskId={risk.id}
					length={risks.length}
					orientation={options.orientation}
					color={risk.trailColor}
				/>
			)}
		</RiskBarContent>
	);
};

type NewRiskBarDisplayProps = {
	risk: Risk;
	risks: Risk[];
	options: RiskBarOptions;
};

const NewRiskBarDisplay: React.FC<NewRiskBarDisplayProps> = ({ options, risk, risks }) => {
	return (
		<RiskBarContent
			orientation={options.orientation}
			align={options.caption.align}
			percentage={risk.percentage}
			captionDisplay={options.caption?.display}
			valueDisplay={options.value?.display}
		>
			<RiskScale
				className="risk-bar"
				riskId={risk.id}
				length={risks.length}
				orientation={options.orientation}
				color={risk.trailColor}
				style={{ cursor: 'default' }}
			/>
		</RiskBarContent>
	);
};
