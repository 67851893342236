import React, { ReactElement, useState } from 'react';
import { Button, Divider, Input, Select, Form } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { I18n } from '@aws-amplify/core';

import { useApplicationContext } from '@/context/v1/Application/context';
import { TaskDTO } from '@/components/ui/Inputs/types/response/task';
import { useCreateTLID } from '@/hooks/v1/useTLIDMutations';

import { CreateTaskRequest } from '../types/request/task';

const { useFormInstance } = Form;

interface TaskSelectProps {
	taskId?: string;
	loading: boolean;
	cycleId?: string;
	disabled: boolean;
	fieldName: NamePath;
	allowCreation?: boolean;
	handleClearTask?: () => void;
	tasks: TaskDTO[] | undefined;
	handleSelectTask?: (id: string, option?: OptionType) => void;
}

type OptionType = { label: string; value: string } | undefined;

export function TaskSelect({
	tasks,
	taskId,
	loading,
	cycleId,
	disabled,
	fieldName,
	handleClearTask,
	handleSelectTask,
	allowCreation = true
}: TaskSelectProps) {
	const [taskName, setTaskName] = useState('');

	const { company, organization } = useApplicationContext();
	const { validateFields, setFieldValue } = useFormInstance();

	const { mutateAsync: createTLID, isLoading } = useCreateTLID();

	async function onCreateTask() {
		const body: CreateTaskRequest = {
			organization_id: organization.id,
			company_id: company.id,
			name: taskName,
			description: taskName,
			cycle_id: cycleId
		};

		const created = await createTLID({ body });
		const taskId = created?.data?.id;
		if (taskId) {
			setFieldValue(fieldName, taskId);
		}
		setTaskName('');
	}

	async function handleChange(id: string, option: OptionType | OptionType[]) {
		option = Array.isArray(option) ? option[0] : option;
		if (handleSelectTask) {
			handleSelectTask(id, option);
			await validateFields([fieldName]);
		}
	}

	function handleClear() {
		if (handleClearTask) {
			handleClearTask();
		}
	}

	function handleTaskNameChange(name: string) {
		setTaskName(name);
	}

	function filterOption(option: OptionType, input: string): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function showDropdownrender(menu: ReactElement): JSX.Element {
		if (!allowCreation) {
			return <div>{menu}</div>;
		}
		return (
			<div>
				{menu}
				<Divider style={{ margin: '4px 0' }} />
				<div
					style={{
						display: 'flex',
						flexWrap: 'nowrap',
						padding: 8
					}}
				>
					<Input
						style={{ flex: 'auto', borderRadius: '7px' }}
						value={taskName}
						onChange={(e) => handleTaskNameChange(e.target.value)}
					/>
					<Button
						onClick={onCreateTask}
						type="link"
						loading={isLoading}
						disabled={!taskName || taskName.length < 3}
					>
						{I18n.get('Add item')}
					</Button>
				</div>
			</div>
		);
	}

	return (
		<Select
			showSearch
			allowClear
			value={taskId}
			onClear={handleClear}
			placeholder={I18n.get('Task')}
			filterOption={(input, option) => filterOption(option, input)}
			loading={loading}
			disabled={disabled}
			options={tasks?.map(({ id, name }) => ({ label: name, value: id }))}
			onChange={(id, option) => handleChange(id, option)}
			dropdownRender={(menu) => showDropdownrender(menu)}
		/>
	);
}
