import React from 'react';
import { Row, Col, Image, Tooltip } from 'antd';
import { I18n } from '@aws-amplify/core';
import * as Icons from '@ant-design/icons';

import { ScoreParts } from './ScoreParts';
import { Paragraph, Title } from '@/components/Typography';
import { ModalBorder, ModalCard, ModalColumn } from './styles';
import { BackCompressiveForceEstimationSVG } from '../../../assets/icons/back-compressive-force-estimationSVG.tsx';

const LIBERTY_MUTUAL_ICON = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/liberty-mutual-icon.png';
const LIBERTY_MUTUAL_ICON_HOVERED =
	'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/liberty-mutual-icon-hovered.png';

export const SelectReports = (props) => {
	const { report, visible, onSelectReport, onClose } = props;

	if (report) {
		const components = {
			score_parts: <ScoreParts {...props} />
		};

		return components[report];
	}

	const biomechanicalReports = [
		{
			name: 'score_parts',
			title: I18n.get('Movement by score'),
			icon: <Icons.PieChartOutlined />
		},
		{
			name: 'reba',
			title: I18n.get('REBA'),
			icon: <Icons.LineChartOutlined />
		},
		{
			name: 'angle-time',
			title: I18n.get('Angle by time'),
			icon: <Icons.AreaChartOutlined />
		}
	];

	const libertyMutualIcon = (
		<Col xs={24}>
			<Row justify="center">
				<Col className="liberty-mutual-icon" span={24}>
					<Image src={LIBERTY_MUTUAL_ICON} width="25%" preview={false} alt={I18n.get('Material handling')} />
				</Col>
				<Col className="liberty-mutual-icon-hovered" span={24}>
					<Image
						width="25%"
						preview={false}
						src={LIBERTY_MUTUAL_ICON_HOVERED}
						alt={I18n.get('Material handling')}
					/>
				</Col>
			</Row>
		</Col>
	);

	const load_reports = [
		{
			name: 'niosh',
			title: I18n.get('Manual lifting'),
			message: 'NIOSH/ISO 11228-1',
			icon: <Icons.ToTopOutlined />
		},
		{
			name: 'kim-pp',
			title: I18n.get('Push and pull'),
			message: 'KIM PUSH PULL',
			icon: <Icons.ColumnWidthOutlined />
		},
		{
			name: 'kim-mho',
			title: I18n.get('Manual Handling'),
			message: 'KIM MHO',
			icon: <Icons.LikeOutlined style={{ transform: 'rotate(270deg)' }} />
		},
		{
			name: 'liberty-mutual',
			title: I18n.get('Material Handling'),
			message: 'LIBERTY MUTUAL',
			icon: libertyMutualIcon
		},
		{
			name: 'back-compressive-force-estimation',
			title: I18n.get('Back compressive force estimation'),
			icon: <BackCompressiveForceEstimationSVG />
		}
	];

	const repeatability_reports = [
		{
			name: 'strain-index',
			title: I18n.get('Revised Strain Index'),
			icon: <Icons.RetweetOutlined />
		}
	];

	return (
		<ModalBorder width={'60rem'} footer={null} open={visible} onCancel={onClose} centered>
			<Row justify="center">
				<Col sm={24}>
					<Title className="title-modal" level={2}>
						{I18n.get('Ergonomic tools')}
					</Title>
				</Col>
				<Col sm={24}>
					<Paragraph className="paragraph-modal" style={{ fontSize: '20px', textAlign: 'center' }}>
						{I18n.get('Select the most suitable tool for your analysis')}
					</Paragraph>
				</Col>
			</Row>
			<Row justify="center" gutter={[20, 0]}>
				<Col sm={8} style={{ padding: '10px 0px 10px 0px', textAlign: 'center' }}>
					<Col sm={24}>
						<Title level={4} style={{ fontSize: '19px' }}>
							{I18n.get('Biomechanical analysis')}
						</Title>
					</Col>
					<ModalColumn sm={24}>
						{biomechanicalReports.map((item) => (
							<Row gutter={20} key={item.name} justify="center" onClick={() => onSelectReport(item.name)}>
								<ModalCard>
									{item.icon}
									<Tooltip title={item.title}>
										<p className="title-card">{I18n.get(item.title)}</p>
									</Tooltip>
									{!!item.message && (
										<Tooltip title={item.message}>
											<p className="message-card">{I18n.get(item.message)}</p>
										</Tooltip>
									)}
								</ModalCard>
							</Row>
						))}
					</ModalColumn>
				</Col>
				<Col
					sm={8}
					style={{
						padding: '10px 0px 10px 0px',
						borderLeft: 'solid 2px #F5F5F5',
						borderRight: 'solid 2px #F5F5F5',
						textAlign: 'center'
					}}
				>
					<Col sm={24}>
						<Title level={4} style={{ fontSize: '19px' }}>
							{I18n.get('Load analysis')}
						</Title>
					</Col>
					<ModalColumn sm={24}>
						{load_reports.map((item) => (
							<Row
								justify={'center'}
								gutter={20}
								key={item.id}
								onClick={() => onSelectReport(item.name, item.custom_report_id)}
							>
								<ModalCard>
									{item.icon}
									<Tooltip title={item.title}>
										<p className="title-card">{I18n.get(item.title)}</p>
									</Tooltip>
									{!!item.message && (
										<Tooltip title={item.message}>
											<p className="message-card">{I18n.get(item.message)}</p>
										</Tooltip>
									)}
								</ModalCard>
							</Row>
						))}
					</ModalColumn>
				</Col>
				<Col sm={8} style={{ padding: '10px 0px 10px 0px', textAlign: 'center' }}>
					<Col sm={24}>
						<Title level={4} style={{ fontSize: '19px' }}>
							{I18n.get('Repeatability analysis')}
						</Title>
					</Col>
					<ModalColumn sm={24}>
						{repeatability_reports.map((item) => (
							<Row justify="center" key={item.name} onClick={() => onSelectReport(item.name)}>
								<ModalCard>
									{item.icon}
									<Tooltip title={item.title}>
										<p className="title-card">{I18n.get(item.title)}</p>
									</Tooltip>
									{!!item.message && (
										<Tooltip title={item.message}>
											<p className="message-card">{I18n.get(item.message)}</p>
										</Tooltip>
									)}
								</ModalCard>
							</Row>
						))}
					</ModalColumn>
				</Col>
			</Row>
		</ModalBorder>
	);
};
