import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form, Input } from 'antd';

interface WorkCenterProps {
	onValueChange: (changed: boolean) => void;
}

export const WorkCenter: React.FC<WorkCenterProps> = ({ onValueChange }) => {
	return (
		<Col xs={24} sm={12}>
			<Form.Item labelCol={{ span: 24 }} name={['work_center']} label={I18n.get('Work center')}>
				<Input placeholder={I18n.get('Work center')} onChange={() => onValueChange(true)} />
			</Form.Item>
		</Col>
	);
};
