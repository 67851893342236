import React from 'react';
import {
	EyeOutlined,
	DeleteOutlined,
	ExceptionOutlined,
	EyeInvisibleOutlined,
	ExclamationCircleOutlined
} from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import { Button, Col, Image, Modal, Row, Tooltip } from 'antd';

import { useApplicationContext } from '@/context/v1/Application/context';
import { useDeleteSeraReport, useDownloadFile } from '../../hooks/hooks';
import { CreateActionPlanModal } from '../CreateActionPlanModal';
import { DeleteSeraReport } from '../../hooks/types/request';
import { useSeraReviewContext } from '../../../context';
import { ActionsInline } from './styles';

const DOWNLOAD_ICON = 'https://kinebot-statics.s3.amazonaws.com/download-video-link.svg';

interface ActionsProps {
	fileId: string;
	checked: boolean;
	reportId: string;
	fileName: string;
}

export const Actions: React.FC<ActionsProps> = ({ fileId, fileName, reportId, checked }) => {
	const { organization, company } = useApplicationContext();
	const {
		visible,
		seraSummaryReview,
		previousReviewStatus,
		handleExpandPreviousReview,
		handleShowActionPlanModalChange
	} = useSeraReviewContext();
	const { mutateAsync: downloadFile, isLoading: downloadingFile } = useDownloadFile();
	const { mutateAsync: deleteSeraReport, isLoading: deletingSeraReport } = useDeleteSeraReport();

	const status = previousReviewStatus?.find((report) => report.id === reportId);
	const disabledDelete = seraSummaryReview?.sera_reports?.length === 1;

	async function handleDownloadFile(event: React.MouseEvent<HTMLElement, MouseEvent>) {
		event.stopPropagation();
		await downloadFile({
			company_id: company?.id,
			organization_id: organization?.id,
			file: {
				id: fileId,
				original_name: fileName
			}
		});
	}

	async function handleDeleteSeraReport(event: React.MouseEvent<HTMLElement, MouseEvent>) {
		event.stopPropagation();
		Modal.confirm({
			title: I18n.get('Heads up! Want to delete this report?'),
			icon: <ExclamationCircleOutlined />,
			okText: I18n.get('Confirm'),
			cancelText: I18n.get('Cancel'),
			okType: 'danger',
			onOk: async () => {
				const body: DeleteSeraReport = {
					company_id: company?.id,
					organization_id: organization?.id,
					id: reportId
				};
				await deleteSeraReport(body);
			}
		});
	}

	function handleShowActionPlans(event: React.MouseEvent<HTMLElement, MouseEvent>) {
		event.stopPropagation();
		handleShowActionPlanModalChange();
	}

	function renderShowReviewButton(): React.ReactNode {
		return status?.expanded ? (
			<Tooltip title={I18n.get('Hide')}>
				<EyeInvisibleOutlined style={{ fontSize: '1.5rem' }} />
			</Tooltip>
		) : (
			<Tooltip title={I18n.get('Show')}>
				<EyeOutlined style={{ fontSize: '1.5rem' }} />
			</Tooltip>
		);
	}

	return (
		<Row align="middle" justify="center">
			<ActionsInline xs={24}>
				<Row align="middle" justify="center">
					<Col span={6}>
						<Button
							disabled={!checked}
							onClick={(event) => handleExpandPreviousReview(reportId, event)}
							type="link"
							size="large"
							icon={renderShowReviewButton()}
						/>
					</Col>
					<Col span={6}>
						<Button
							disabled={!checked}
							onClick={handleShowActionPlans}
							type="link"
							size="large"
							icon={
								<Tooltip title={I18n.get('Action plans')}>
									<ExceptionOutlined />
								</Tooltip>
							}
						/>
					</Col>
					<Col span={6}>
						<Button
							onClick={handleDownloadFile}
							type="link"
							size="large"
							loading={downloadingFile}
							icon={
								<Tooltip title={I18n.get('Download')}>
									<Image
										src={DOWNLOAD_ICON}
										preview={false}
										style={{ width: '45%', marginBottom: '0.2rem' }}
										alt={I18n.get('Download video')}
									/>
								</Tooltip>
							}
						/>
					</Col>
					<Col span={6}>
						<Button
							onClick={handleDeleteSeraReport}
							loading={deletingSeraReport}
							disabled={disabledDelete}
							type="link"
							size="large"
							icon={
								<Tooltip title={I18n.get('Delete')}>
									<DeleteOutlined style={{ color: disabledDelete ? '#00000040' : '#E74150' }} />
								</Tooltip>
							}
						/>
					</Col>
				</Row>
			</ActionsInline>
			<CreateActionPlanModal file_id={fileId} visible={visible} onCloseModal={handleShowActionPlans} />
		</Row>
	);
};
