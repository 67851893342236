import { TotalTaskDurationDTO } from '@/components/ui/Inputs/types/response/totalTaskDuration';
import { StressLevelDTO } from '@/components/ui/Inputs/types/response/stressLevel';
import { FrequencyDTO } from '@/components/ui/Inputs/types/response/frequency';

interface TaskRPNFactorsInterface {
	defineDefault(
		stressLevel: StressLevelDTO | undefined,
		frequency: FrequencyDTO | undefined,
		totalTaskDuration: TotalTaskDurationDTO | undefined
	): StepKeyCalculate;
	defineDefaultWithConstantFrequency(
		stressLevel: StressLevelDTO | undefined,
		totalTaskDuration: TotalTaskDurationDTO | undefined
	): StepKeyCalculate;
	defineDefaultBasedOnStressLevel(
		stressLevel: StressLevelDTO | undefined,
		frequency: FrequencyDTO | undefined,
		totalTaskDuration: TotalTaskDurationDTO | undefined
	): StepKeyCalculate;
	defineWithConditionalFrequency(
		stressLevel: StressLevelDTO | undefined,
		frequency: FrequencyDTO | undefined,
		totalTaskDuration: TotalTaskDurationDTO | undefined
	): StepKeyCalculate;
	defineHandActivities(stressLevel: StressLevelDTO | undefined): StepKeyCalculate;
	defineLifting(stressLevel: StressLevelDTO | undefined): StepKeyCalculate;
	defineVibration(stressLevel: StressLevelDTO | undefined): StepKeyCalculate;
}

export type StepKeyCalculate = {
	stressLevelScore: number;
	frequencyScore: number;
	totalTaskDurationScore: number;
};

export class TaskRPNFactorsService implements TaskRPNFactorsInterface {
	public defineDefault(
		stressLevel: StressLevelDTO | undefined,
		frequency: FrequencyDTO | undefined,
		totalTaskDuration: TotalTaskDurationDTO | undefined
	): StepKeyCalculate {
		if (!stressLevel || !frequency || !totalTaskDuration) {
			return this.setNullFactors();
		}

		return {
			stressLevelScore: stressLevel.score,
			frequencyScore: frequency.score,
			totalTaskDurationScore: totalTaskDuration.score
		};
	}

	public defineDefaultWithConstantFrequency(
		stressLevel: StressLevelDTO | undefined,
		totalTaskDuration: TotalTaskDurationDTO | undefined
	): StepKeyCalculate {
		if (!stressLevel || !totalTaskDuration) {
			return this.setNullFactors();
		}

		return {
			stressLevelScore: stressLevel.score,
			frequencyScore: 1,
			totalTaskDurationScore: totalTaskDuration.score
		};
	}

	public defineDefaultBasedOnStressLevel(
		stressLevel: StressLevelDTO | undefined,
		frequency: FrequencyDTO | undefined,
		totalTaskDuration: TotalTaskDurationDTO | undefined
	): StepKeyCalculate {
		if (!stressLevel || !frequency || !totalTaskDuration) {
			return this.setNullFactors();
		}

		if (stressLevel.score === 1) {
			return {
				stressLevelScore: 1,
				frequencyScore: 1,
				totalTaskDurationScore: 1
			};
		}

		return {
			stressLevelScore: stressLevel.score,
			frequencyScore: frequency.score,
			totalTaskDurationScore: totalTaskDuration.score
		};
	}

	public defineWithConditionalFrequency(
		stressLevel: StressLevelDTO | undefined,
		frequency: FrequencyDTO | undefined,
		totalTaskDuration: TotalTaskDurationDTO | undefined
	): StepKeyCalculate {
		if (!stressLevel || !frequency || !totalTaskDuration) {
			return this.setNullFactors();
		}

		if (stressLevel.score === 1) {
			return {
				stressLevelScore: 1,
				frequencyScore: 1,
				totalTaskDurationScore: totalTaskDuration.score
			};
		}

		return {
			stressLevelScore: stressLevel.score,
			frequencyScore: frequency.score,
			totalTaskDurationScore: totalTaskDuration.score
		};
	}

	public defineHandActivities(stressLevel: StressLevelDTO | undefined): StepKeyCalculate {
		if (!stressLevel) {
			return this.setNullFactors();
		}
		const frequencies = [0, 1, 2, 3, 3, 4];
		const taskDurations = [0, 1, 1, 2, 2, 2];

		return {
			stressLevelScore: stressLevel.score,
			frequencyScore: frequencies[stressLevel.score],
			totalTaskDurationScore: taskDurations[stressLevel.score]
		};
	}

	public defineLifting(stressLevel: StressLevelDTO | undefined): StepKeyCalculate {
		if (!stressLevel) {
			return this.setNullFactors();
		}
		const taskDurations = [0, 1, 1, 2, 3, 4];

		return {
			stressLevelScore: stressLevel.score,
			frequencyScore: stressLevel.score === 5 ? 2 : 1,
			totalTaskDurationScore: taskDurations[stressLevel.score]
		};
	}

	public defineVibration(stressLevel: StressLevelDTO | undefined): StepKeyCalculate {
		if (!stressLevel) {
			return this.setNullFactors();
		}
		const taskDurations = [0, 1, 1, 3, 4, 1];

		return {
			stressLevelScore: stressLevel.score,
			frequencyScore: 1,
			totalTaskDurationScore: taskDurations[stressLevel.score]
		};
	}

	private setNullFactors(): StepKeyCalculate {
		return {
			stressLevelScore: 0,
			frequencyScore: 0,
			totalTaskDurationScore: 0
		};
	}
}
