import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Item, ItemValue } from './styles';

export function FileData({ originalName, duration, size }) {
	return (
		<Col span={24}>
			<Row gutter={[0, 4]}>
				<Col sm={24}>
					<h3>{I18n.get('File data')}</h3>
				</Col>
				<Col span={24}>
					<Item>{I18n.get('File')}</Item>
					<ItemValue>{originalName}</ItemValue>
				</Col>
				<Col sm={4}>
					<Item>{I18n.get('Duration')}</Item>
					<ItemValue>{duration}</ItemValue>
				</Col>
				<Col sm={4}>
					<Item>{I18n.get('Size')}</Item>
					<ItemValue>{size}</ItemValue>
				</Col>
			</Row>
		</Col>
	);
}
