import React from 'react';
import { Row } from 'antd';
import { I18n } from '@aws-amplify/core';
import styled from 'styled-components';

const Container = styled(Row)`
	background-color: #ededed;
	border-radius: 5px;
	margin-inline: 10px;
	padding-inline: 6px;
	padding-left: 10px;
	padding-block: 10px;
	justify-content: space-between;
`;

const LegendItem = styled.div`
	display: flex;
	text-align: center;
`;

const Color = styled.div`
	width: 40px;
	height: 20px;
	border: 1px solid white;
	background-color: ${(props) => props.color};
`;

const Label = styled.span`
	font-size: 12px;
	font-weight: bold;
	margin-left: 4px;
`;

const defaultLegend = [
	{ title: 'Not identified', color: 'rgba(113, 113, 113, 0.2)' },
	{ title: 'Acceptable', color: 'rgba(75, 192, 86, 0.5)' },
	{ title: 'Investigate', color: 'rgba(255, 222, 49, 0.5)' },
	{ title: 'Investigate and change soon', color: 'rgba(247, 138, 56, 0.5)' },
	{ title: 'Investigate and implement change', color: 'rgba(255, 40, 72, 0.5)' },
	{ title: 'Implement changes', color: 'rgba(153, 102, 255, 0.5)' }
];

export const ReportLegend = ({ items = defaultLegend }) => (
	<Container gutter={[0, 0]} type="flex">
		{items.map((item) => (
			<LegendItem key={item.title}>
				<Color color={item.color} />
				<Label>{I18n.get(item.title)}</Label>
			</LegendItem>
		))}
	</Container>
);
