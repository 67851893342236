import React from 'react';
import { Title } from '@/components/Typography';
import { Col, Divider, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Description, RPNScore, Score } from './styles';
import { SeraResultTaskSummary } from '../hooks/types';
import { useSeraStepsContext } from '../../context';

type SecondRow = Pick<
	SeraResultTaskSummary,
	'exposure' | 'exposure_score' | 'rpn' | 'severity' | 'severity_score' | 'vulnerability' | 'vulnerability_score'
>;

type TaskResultSecondRowProps = {
	summary: SecondRow;
};

export const TaskResultSecondRow: React.FC<TaskResultSecondRowProps> = ({ summary }) => {
	const { mapRPNToPriority } = useSeraStepsContext();

	const { exposure, exposure_score, rpn, severity, severity_score, vulnerability, vulnerability_score } = summary;

	const rpnPriority = mapRPNToPriority(rpn);

	return (
		<Row justify="space-between" style={{ margin: '0 0 1rem 1rem' }}>
			<Col span={4}>
				<Row align="middle">
					<Title style={{ margin: 0, fontSize: '0.8rem' }}>{I18n.get('Exposure')}</Title>
				</Row>
				<Row align="middle">
					<Description>{I18n.get(exposure ?? 'N/A')}</Description>
				</Row>
			</Col>
			<Col style={{ minHeight: '100%' }}>
				<Divider style={{ height: '100%' }} type="vertical" />
			</Col>
			<Col span={4}>
				<Row align="middle">
					<Title style={{ margin: 0, fontSize: '0.8rem' }}>{I18n.get('Vulnerability')}</Title>
				</Row>
				<Row align="middle">
					<Description>{I18n.get(vulnerability ?? 'N/A')}</Description>
				</Row>
			</Col>
			<Col style={{ minHeight: '100%' }}>
				<Divider style={{ height: '100%' }} type="vertical" />
			</Col>
			<Col span={4}>
				<Row align="middle">
					<Title style={{ margin: 0, fontSize: '0.8rem' }}>{I18n.get('Severity')}</Title>
				</Row>
				<Row align="middle">
					<Description>{I18n.get(severity ?? 'N/A')}</Description>
				</Row>
			</Col>
			<Col style={{ minHeight: '100%' }}>
				<Divider style={{ height: '100%' }} type="vertical" />
			</Col>
			<Col span={4}>
				<Row align="middle" justify="space-between" style={{ marginBottom: '0.25rem' }}>
					<Title style={{ margin: 0, fontSize: '0.8rem' }}>{I18n.get('Exposure')}</Title>
					<Score>{exposure_score ?? 'N/A'}</Score>
				</Row>
				<Row align="middle" justify="space-between" style={{ marginBottom: '0.25rem' }}>
					<Title style={{ margin: 0, fontSize: '0.8rem' }}>{I18n.get('Vulnerability')}</Title>
					<Score>{vulnerability_score ?? 'N/A'}</Score>
				</Row>
				<Row align="middle" justify="space-between" style={{ marginBottom: '0.25rem' }}>
					<Title style={{ margin: 0, fontSize: '0.8rem' }}>{I18n.get('Severity')}</Title>
					<Score>{severity_score ?? 'N/A'}</Score>
				</Row>
			</Col>
			<Col style={{ minHeight: '100%' }}>
				<Divider style={{ height: '100%' }} type="vertical" />
			</Col>
			<Col span={4}>
				<Row align="middle">
					<Title style={{ margin: 0, fontSize: '0.8rem' }}>{I18n.get('RPN and Priority')}</Title>
				</Row>
				<Row align="middle" gutter={[16, 16]}>
					<Col>
						<RPNScore priority={rpnPriority.color}>{rpn ?? 'N/A'}</RPNScore>
					</Col>
					<Col>
						<RPNScore priority={rpnPriority.color}>{I18n.get(rpnPriority.priority) ?? 'N/A'}</RPNScore>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
