import React, { useContext, useEffect } from 'react';

import { I18n } from '@aws-amplify/core';
import { Form, Select } from 'antd';
import { MergeAEPContext } from '../../context';
import hooks from '../../hooks';

const { useGetCompanies } = hooks;
const { useWatch } = Form;

export const Company: React.FC = () => {
	const { organizationId, setCompanyId, queryParams, setQueryParams } = useContext(MergeAEPContext);
	const {
		data: companiesList,
		isLoading: gettingCompanies,
		isError: companiesError,
		refetch
	} = useGetCompanies(organizationId);

	const company = useWatch('companyId');

	useEffect(() => {
		!company && refetch();
	}, [company]);

	function handleCompanyChange(companyId: string) {
		setCompanyId(companyId);
		setQueryParams({
			...queryParams,
			companyId
		});
	}

	return (
		<Form.Item name="companyId">
			<Select
				showSearch
				loading={gettingCompanies}
				placeholder={I18n.get('Industrial Site')}
				onChange={(value) => handleCompanyChange(value)}
				disabled={companiesList.length === 0 || companiesError}
				filterOption={(input, option) => (option?.label ?? '').includes(input)}
				options={companiesList.map(({ id, name }) => ({ label: name, value: id }))}
			/>
		</Form.Item>
	);
};
