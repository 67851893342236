import React from 'react';
import { Row } from 'antd';

import { ManageWorkstation } from './ManageWorkstation';
import { ManageCompany } from './ManageCompany';
import { ManageSector } from './ManageSector';
import { ManageLine } from './ManageLine';

export const Manager = () => (
	<Row style={{ width: '100%' }} gutter={[0, 16]}>
		<ManageCompany />
		<ManageSector />
		<ManageLine />
		<ManageWorkstation />
	</Row>
);
