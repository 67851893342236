import React, { useEffect } from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { Select, Row, Col, DatePicker, Form, Descriptions } from 'antd';

import { useApplicationContext } from '@/context/v1/Application/context';

import { useKimPushPullContext } from './context';
import { Workstations } from './Workstations';
import { Sectors } from './Sectors';
import { Lines } from './Lines';

export const Information = ({ file }) => {
	const { organization } = useApplicationContext();
	const { handleFile } = useKimPushPullContext();

	useEffect(() => {
		handleFile(file.data);
	}, [file]);

	return (
		<Row justify="center" gutter={[5, 20]}>
			<Col sm={18}>
				<Row>
					<Col sm={24}>
						<label>{I18n.get('Enter the data related to the selected video below')}</label>
					</Col>
				</Row>
				<Row align="middle">
					<Col sm={8} style={{ marginTop: '20px', paddingLeft: '60px' }}>
						<Descriptions column={1} size="small">
							<Descriptions.Item
								label={I18n.get('File')}
								contentStyle={{ fontWeight: 'bold' }}
								labelStyle={{ fontWeight: 'bold' }}
							>
								{file?.data.original_name}
							</Descriptions.Item>
							<Descriptions.Item label={I18n.get('Date')}>
								{moment(file?.data.createdAt).format('L')}
							</Descriptions.Item>
						</Descriptions>
					</Col>
				</Row>
				<Row gutter={[10, 20]} style={{ marginTop: '10px', marginLeft: '20px' }}>
					<Col sm={12}>
						<Form.Item name="organization_id" label={I18n.get('Company')} labelCol={{ span: 24 }}>
							<Select disabled={true} placeholder={I18n.get('Company')}>
								<Select.Option value={organization.id}>{organization.name}</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Sectors />
					<Lines />
					<Workstations />
					<Col sm={12}>
						<Form.Item
							label={I18n.get('Collection date')}
							name="collection_date"
							rules={[
								{
									required: true,
									message: I18n.get('Insert the date of collection')
								}
							]}
							labelCol={{ span: 24 }}
						>
							<DatePicker
								format={'L'}
								placeholder={I18n.get('Collection date')}
								style={{ width: '100%' }}
								disabledDate={(current) => current && current.valueOf() >= Date.now()}
							/>
						</Form.Item>
					</Col>
					<Col sm={12}>
						<Form.Item
							name="gender_input"
							label={I18n.get('Worker gender')}
							rules={[
								{
									required: true,
									message: I18n.get('Choose a gender')
								}
							]}
						>
							<Select
								placeholder={I18n.get('Gender')}
								options={[
									{
										value: 0,
										label: I18n.get('Male')
									},
									{
										value: 1,
										label: I18n.get('Female')
									}
								]}
							/>
						</Form.Item>
					</Col>
					<Col sm={12}>
						<Form.Item
							name="pair_input"
							label={I18n.get('Pushing / Pulling in pairs')}
							rules={[
								{
									required: true,
									message: I18n.get('Choose an option')
								}
							]}
						>
							<Select
								placeholder={I18n.get('Pairs')}
								options={[
									{
										value: 0,
										label: I18n.get('No')
									},
									{
										value: 1,
										label: I18n.get('Yes')
									}
								]}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
