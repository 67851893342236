import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Row, Col, Form, message } from 'antd';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';
import _ from 'lodash';

import { AngleTimeContext } from './context';
import { useFile } from '@/hooks/v1';

import { HeaderStep, Footer } from '@/components/views/Report/Steps.js';
import Spinner from '@/components/Layout/Spinner';
import { Information } from './Information';
import { BodyParts } from './BodyParts';
import { Result } from './Result';

import {
	useRiskRangeSettings,
	useRecoveryReport,
	useCreateReport,
	useUpdateReport,
	useSaveComment,
	useGeneratePDF,
	useGetAngles,
	useRiskRanges
} from './hooks';
import { useApplicationContext } from '@/context/v1/Application/context';

export function AngleTime() {
	const [currentStep, setCurrentStep] = useState(0);
	const [riskRangeId, setRiskRangeId] = useState('');
	const [riskRangeName, setRiskRangeName] = useState('');
	const [checkAllParts, setCheckAllParts] = useState(false);
	const [parts, setParts] = useState([]);

	const [isRendered, setIsRendered] = useState(false);

	const [form] = Form.useForm();
	const history = useHistory();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

	const { file_id: fileId } = useParams();
	const locale = window.navigator.language ?? 'en-US';

	const { organization, company } = useApplicationContext();

	const createReport = useCreateReport();
	const updateReport = useUpdateReport();
	const reportRecovery = useRecoveryReport(organization.id, company.id, fileId);

	const pdf = useGeneratePDF();
	const riskRanges = useRiskRanges();
	const updateComment = useSaveComment();
	const settings = useRiskRangeSettings(riskRangeId);
	const angles = useGetAngles(organization.id, company.id, fileId);

	const { file } = useFile({
		organizationId: organization.id,
		companyId: company.id,
		fileId
	});

	useEffect(() => form.resetFields(), [reportRecovery.data]);

	async function onNext() {
		if (currentStep < steps.length - 1) {
			try {
				await form.validateFields();
				setCurrentStep((prev) => prev + 1);
			} catch (error) {
				message.error(I18n.get('Enter the required values'));
			}
		} else {
			setCurrentStep((prev) => prev + 1);
		}
	}

	function onPrevious() {
		setCurrentStep((prev) => prev - 1);
	}

	const selectRangeRisk = (id) => () => {
		setRiskRangeId(id);
	};

	async function onClose() {
		await saveComment();
		message.loading(I18n.get('Comment updated successfully'), 2).then(() => {
			const custom_report = !!searchParams.get('custom_report');
			if (custom_report) {
				return history.push('/custom-reports/files');
			}
			history.push('/reporting');
		});
	}

	async function saveComment() {
		const { comment } = form.getFieldsValue(true);
		const body = { organizationId: organization.id, companyId: company.id, fileId, comment };
		if (comment !== reportRecovery.data.comment) {
			await updateComment.mutateAsync(body);
		}
	}

	async function onDownloadPDF() {
		await saveComment();
		pdf.mutate({ organizationId: organization.id, companyId: company.id, fileId, parts, locale });
	}

	async function onFinish() {
		try {
			await form.validateFields();

			const { sector_id, line_id, workstation_id, range_risk_id, collection_date, parts } =
				form.getFieldsValue(true);

			const payload = {
				file_id: fileId,
				sector_id,
				line_id,
				workstation_id,
				company_id: company.id,
				range_risk_id: range_risk_id,
				organization_id: organization.id,
				collection_date: moment(collection_date).format(),
				parts
			};

			const [range_risk_selected] = riskRanges.data.filter((item) => item.id === range_risk_id);

			setParts(parts);
			setRiskRangeId(range_risk_id);
			setRiskRangeName(range_risk_selected.name);

			_.isEmpty(reportRecovery.data) ? createReport.mutate(payload) : updateReport.mutate(payload);

			onNext();
		} catch (error) {
			message.error(I18n.get('Enter the required values'));
		}
	}

	const steps = [
		{
			title: I18n.get('Information'),
			content: <Information riskRanges={riskRanges} />,
			is_valid: true
		},
		{
			title: I18n.get('Body parts'),
			content: <BodyParts />,
			is_valid: true
		},
		{
			title: I18n.get('Result'),
			content: (
				<Result
					form={form}
					file={file}
					angles={angles}
					settings={settings}
					isLoadingPDF={pdf.isLoading}
					onDownloadPDF={onDownloadPDF}
				/>
			)
		}
	];

	function validateNotNullObjects(body_parts) {
		return body_parts && settings?.data;
	}

	function setRenderedLastStep() {
		setCurrentStep(2);
		setIsRendered(true);
	}

	function verifySavedBodyParts() {
		setRiskRangeId(reportRecovery?.data?.range_risk_id);
		!parts?.length && setParts(reportRecovery?.data?.body_parts);
		validateNotNullObjects(reportRecovery?.data?.body_parts) && setRenderedLastStep();
	}

	useEffect(() => {
		!isRendered && verifySavedBodyParts();
	}, [reportRecovery, settings]);

	const isLoading = file.isLoading || reportRecovery.isLoading || createReport.isLoading || updateReport.isLoading;

	const isError = angles.isError || settings.isError || file.isError;

	const collection_date = reportRecovery.data?.collection_date && moment(reportRecovery.data.collection_date);

	const savedParts = reportRecovery.data?.body_parts || [];

	const initValues = {
		organization_id: organization.id,
		sector_id: file.data?.workstations?.line?.sector?.id,
		line_id: file.data?.workstations?.line?.id,
		workstation_id: file.data?.workstations?.id,
		range_risk_id: reportRecovery.data?.range_risk_id,
		comment: reportRecovery.data?.comment,
		collection_date: collection_date
	};

	const context = {
		organizationId: organization.id,
		organizationName: organization.name,
		companyId: company.id,
		riskRangeId,
		fileId,
		file,
		selectRangeRisk,
		riskRangeName,
		form,
		checkAllParts,
		setCheckAllParts,
		parts,
		setParts,
		savedParts
	};

	if (isError) {
		return <h2>Internal server error</h2>;
	}

	return (
		<AngleTimeContext.Provider value={context}>
			<Form form={form} layout="vertical" initialValues={initValues}>
				<Row justify="center">
					<Col sm={24} style={{ marginBottom: '16px', textAlign: 'center' }}>
						<h2>{I18n.get('Angle by time')}</h2>
					</Col>
					<Col xxl={20} xl={22} sm={24}>
						<Row justify="center" align="middle" style={{ marginBottom: '20px' }}>
							<Col sm={14} xxl={12}>
								<HeaderStep current={currentStep} steps={steps} />
							</Col>
						</Row>
						<Row justify="center">
							<Col sm={24} style={{ minHeight: '350px' }}>
								{isLoading ? <Spinner /> : steps[currentStep].content}
							</Col>
						</Row>
						<Row justify="center" style={{ marginTop: '30px' }}>
							<Col sm={24}>
								{!isLoading && (
									<Footer
										steps={steps}
										onNext={onNext}
										onClose={onClose}
										onFinish={onFinish}
										current={currentStep}
										isFetching={isLoading}
										onPrevious={onPrevious}
									/>
								)}
							</Col>
						</Row>
					</Col>
				</Row>
			</Form>
		</AngleTimeContext.Provider>
	);
}
