import styled from 'styled-components';

export const CardContainer = styled.div`
	min-height: 100%;
	max-width: 300px;
	background-color: #f8f8f8;
	border-radius: 0 0 0.25rem 0.25rem;
`;

export const CardContainerImage = styled.div`
	height: 100%;
	background-color: #f8f8f8;
	border-radius: 0 0 0.25rem 0.25rem;
`;

export const Header = styled.div`
	display: flex;
	color: #ffffff;
	padding: 0.5rem 0;
	font-weight: 600;
	text-align: center;
	flex-direction: column;
	justify-content: center;
	background-color: #2f54eb;
	border-radius: 0.25rem 0.25rem 0 0;
`;

export const SummaryText = styled.span`
	font-size: 20px;
	font-weight: 600;
`

export const Body = styled.div`
	display: flex;
	color: #000000;
	font-weight: 600;
	text-align: center;
	flex-direction: column;
	justify-content: center;
	max-height: 100%;
`;

export const UnitContainer = styled.span`
	top: 50%;
	color: #565656;
	font-size: 0.7rem;
	position: absolute;

	@media (min-width: 1248px) {
		top: 45%;
		font-size: 1rem;
	}
`;

export const TrunkAngleText = styled.span`
	position: absolute;
	top: 36%;
	left: 24%;

	@media (max-width: 1248px) {
		left: 10%;
	}
`;
export const HorizontalDistanceText = styled.span`
	position: absolute;
	bottom: 30%;
	margin-left: 10px;
`;
