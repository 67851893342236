import React from 'react';
import { Form, Input } from 'antd';
import { I18n } from '@aws-amplify/core';

export const ReportName: React.FC = () => {
	return (
		<Form.Item labelCol={{ span: 24 }} name={['report_name']} label={I18n.get('Report name')}>
			<Input placeholder={I18n.get('Report name')} />
		</Form.Item>
	);
};
