import styled from 'styled-components';
import { Button, Col, Table, Tag } from 'antd';

export const ActionsInline = styled(Col)`
	display: none;
	@media (min-width: 768px) {
		display: flex;
		justify-content: center;
	}
`;

export const ActionsMenu = styled(Col)`
	display: flex;
	@media (min-width: 768px) {
		display: none;
	}
`;

export const CustomButton = styled.div`
	&:hover {
		span {
			color: white;
		}
	}
`;

export const CustomTable = styled(Col)`
	.ant-table {
		border-radius: 10px;
		.ant-table-thead {
			.ant-table-cell:first-child {
				border-radius: 10px 0 0 0;
			}
			.ant-table-cell:last-child {
				border-radius: 0 10px 0 0;
			}
		}
	}
`;

export const CustomButtonAntd = styled(Button)`
	display: flex;
	align-items: center;
	border-radius: 5px;
	background: #2f54eb;
	font-size: 15px;
	span:first-child {
		font-size: 20px;
		margin-top: 3px;
	}
`;

interface TagRiskProps {
	brightcolors?: number;
}

export const TagRisk = styled(Tag) <TagRiskProps>`
	border-radius: 5px;
	width: 68%;
	max-width: 8rem;
	text-align: center;
	span {
		color: ${({ brightcolors }) => (brightcolors === 2 ? 'black' : 'white')};
	}
`;

export const TableCustom = styled(Table)``;
