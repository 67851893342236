import styled from 'styled-components';
import { Col } from 'antd';

type RiskTagProps = {
	color: string;
};

export const RiskTagContainer = styled(Col)`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

export const RiskTag = styled.div<RiskTagProps>`
	color: #fff;
	width: 80%;
	display: flex;
	padding: 4px 0;
	font-size: 14px;
	font-weight: 600;
	border-radius: 7px;
	align-items: center;
	justify-content: center;
	background-color: ${({ color }) => color};
`;
