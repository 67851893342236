import { FrequencyTimeFormatMapper, HandCouplingMapper, TaskMapper } from '../../context/types';

export enum TaskNameMapper {
	Lift = 'Lift',
	Lower = 'Lower',
	Push = 'Push',
	Pull = 'Pull',
	Carry = 'Carry'
}

export enum FrequencyTimeFormatDTO {
	MINUTE = 'MINUTE',
	HOUR = 'HOUR'
}

export enum SystemOfUnitsName {
	imperial = 'imperial',
	metric = 'metric'
}

export enum SystemOfUnitsDescription {
	Imperial = 'Imperial',
	Metric = 'Metric'
}

export type SystemOfUnitsDTO = {
	id: string;
	name: SystemOfUnitsName;
	description: SystemOfUnitsDescription;
};

export type Report = {
	report: {
		id: string;
		report_name: string;
	};
	sub_step: number;
};

export type LibertyMutualReportDTO = {
	id: string;
	step: number;
	line_id: string;
	comment: string;
	duration: string;
	reports: Report[];
	sector_id: string;
	line_name: string;
	company_id: string;
	sector_name: string;
	uploaded_date: Date;
	company_name: string;
	original_name: string;
	workstation_id: string;
	organization_id: string;
	workstation_name: string;
	organization_name: string;
	system_of_units_id: string;
	system_of_units: SystemOfUnitsDTO;
};

export type SummaryFrequency = {
	value: number;
	type: TaskMapper;
	interval: FrequencyTimeFormatMapper;
};

export type Informations = {
	duration: string;
	line_name: string;
	sector_name: string;
	company_name: string;
	collection_date: Date;
	original_name: string;
	assessment_date: Date;
	workstation_name: string;
	task_name: TaskNameMapper;
	organization_name: string;
};

export type Results = {
	percentile_man?: string;
	percentile_woman?: string;
	percentile_man_initial?: string;
	percentile_man_sustain?: string;
	percentile_woman_initial?: string;
	percentile_woman_sustain?: string;
};

export type SummaryResult = {
	unit: string;
	object_weight: string;
	initial_force: string;
	sustained_force: string;
	end_hand_height: string;
	start_hand_height: string;
	end_hand_distance: string;
	horizontal_distance: string;
	start_hand_distance: string;
	frequency: SummaryFrequency;
	vertical_hand_height: string;
	hand_coupling: HandCouplingMapper;
	system_of_units: SystemOfUnitsName;
};

export type ReportResult = {
	id: string;
	results: Results;
	report_name: string;
	summary: SummaryResult;
	task_name: TaskNameMapper;
};

export type LibertyMutualResultDTO = {
	id: string;
	comment: string;
	reports: ReportResult[];
	informations: Informations;
};
