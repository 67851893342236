import React from 'react';
import { Col, InputNumber } from 'antd';
import { TextInput } from './TextInput';

interface WorkingPopulationMenProps {
	validate: () => Promise<void>;
	fieldName: string;
}

export const WorkingPopulationMen: React.FC<WorkingPopulationMenProps> = ({ fieldName, validate }) => {
	return (
		<Col xs={6}>
			<TextInput
				title="Men"
				namePath={[fieldName, 'working_population_men']}
				message={'Working population must be greater than 0'}
			>
				<InputNumber onChange={validate} min={0} />
			</TextInput>
		</Col>
	);
};
