import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Select } from 'antd';
import { I18n } from '@aws-amplify/core';
import { v4 as uuidv4 } from 'uuid';

import { AdditionalItemTypes } from '@/types/entities/CustomReportAdditionalItemOption';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useStepKeysContext } from '../DefaultSelects/context';
import { fieldsUnitTexts } from './utils/FieldsUnitTexts';
import { AditionalItemProps, Options } from './types';
import { ConditionCard } from '../../ConditionCard';
import { Text } from '@/components/Typography';
import {
	useCalculateAdditionalToolScore,
	useGetAdditionalItemsOptions,
	AdditionalItemsResults,
	GetAdditionalToolScore
} from '@/hooks';

const { useWatch, useFormInstance } = Form;
const { formatLabelWithUnit } = fieldsUnitTexts;

export const SelectAndSubSelect: React.FC<AditionalItemProps> = ({ additionalItem }) => {
	const { id, title_1, title_2, unit_1, unit_2 } = additionalItem;

	const form = useFormInstance();
	const { formFieldName, stepKey } = useStepKeysContext();
	const { organization, company } = useApplicationContext();

	const [timer, setTimer] = useState<NodeJS.Timeout>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [subSelectValue, setSubSelectValue] = useState<string | undefined>(undefined);

	const selectFieldValue = useWatch([...formFieldName, 'additional_items', id, 'additional_item_option_id_1']);

	const payload: GetAdditionalToolScore = {
		company_id: company?.id,
		organization_id: organization?.id,
		custom_report_step_key_id: stepKey.id,
		additional_item_option_id_2: subSelectValue,
		additional_item_option_id_1: selectFieldValue
	};
	const {
		data: options,
		isLoading: isOptionsLoading,
		isFetching: isOptionsFetching
	} = useGetAdditionalItemsOptions({
		company_id: company?.id,
		organization_id: organization?.id,
		custom_report_step_key_id: stepKey.id
	});
	const { data: additionalToolScore, isFetching: isFetchingAdditionalToolScore } =
		useCalculateAdditionalToolScore(payload);

	const selectOptions = options.filter((option) => option.type === AdditionalItemTypes.SELECT);
	const subSelectOptions = options.find(({ id }) => id === selectFieldValue)?.sub_selects ?? [];

	const firstSelectLabel = formatLabelWithUnit(title_1, unit_1);
	const secondSelectLabel = formatLabelWithUnit(title_2, unit_2);

	useEffect(() => {
		const result: AdditionalItemsResults[] = [
			{
				id: uuidv4(),
				additional_item_option_id_1: selectFieldValue,
				result: additionalToolScore?.result ?? 0,
				additional_item_option_id_2: subSelectValue
			}
		];

		form.setFieldValue([...formFieldName, 'additional_items', id, 'results'], result);
	}, [additionalToolScore]);

	function handleDebounceSetSubSelectValue(value: string): void {
		clearTimeout(timer);

		const debounce = setTimeout(() => {
			setSubSelectValue(value);
			setIsLoading(false);
		}, 1200);

		setTimer(debounce);
	}

	function handleChangeSubSelect(value: string | null): void {
		setIsLoading(true);
		handleDebounceSetSubSelectValue(value ?? '');
	}

	function handleChangeSelect(value: string | null): void {
		setSubSelectValue(undefined);
		form.setFieldValue([...formFieldName, 'additional_items', id, 'additional_item_option_id_2'], undefined);
	}

	function filterOption(input: string, option?: Options): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function areValuesSelected(): boolean {
		return selectFieldValue && subSelectValue;
	}

	return (
		<Row gutter={[20, 15]}>
			<Col span={24}>
				<Row gutter={[0, 5]}>
					<Col span={24}>
						<Text strong>{firstSelectLabel}</Text>
					</Col>
					<Col span={20}>
						<Form.Item name={[...formFieldName, 'additional_items', id, 'additional_item_option_id_1']}>
							<Select
								showSearch
								allowClear
								filterOption={filterOption}
								onChange={handleChangeSelect}
								placeholder={I18n.get(title_1)}
								loading={isOptionsLoading || isOptionsFetching}
								options={selectOptions?.map(
									({ id, description }): Options => ({
										label: I18n.get(`${description}`),
										value: id
									})
								).sort((a, b) => a.label.localeCompare(b.label))}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<Row gutter={[0, 5]}>
					<Col span={24}>
						<Text strong>{secondSelectLabel}</Text>
					</Col>
					<Col span={20}>
						<Form.Item name={[...formFieldName, 'additional_items', id, 'additional_item_option_id_2']}>
							<Select
								showSearch
								allowClear
								loading={isOptionsLoading}
								filterOption={filterOption}
								disabled={!selectFieldValue}
								placeholder={I18n.get(title_2)}
								onChange={handleChangeSubSelect}
								options={subSelectOptions?.map(
									({ id, description }): Options => ({
										label: I18n.get(`${description}`),
										value: id
									})
								)}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<ConditionCard
					result={areValuesSelected() ? additionalToolScore?.result : 0}
					isLoading={isLoading || isFetchingAdditionalToolScore}
				/>
			</Col>
		</Row>
	);
};
