import { Col, Form } from 'antd';
import styled from 'styled-components';

type ScaleColorProps = {
	scale_color: string;
};

export const scaleColorMapper: { [key: number]: string } = {
	0: '#FFFFFF',
	1: '#2CC852',
	2: '#F8D627',
	3: '#F78A38',
	4: '#E74150',
	5: '#9B54E2'
};

export const DropdownCol = styled(Col)`
	.ant-row {
		:not(:first-child) {
			.ant-col {
				.ant-form-item {
					margin-bottom: 0;
					margin-top: 2rem;
					@media (min-width: 1200px) {
						margin-top: 1rem;
					}
				}
			}
		}
	}
`;

export const CustomStepKeyFormItem = styled(Form.Item)`
	margin-bottom: 0;
	.ant-row {
		.ant-form-item-control {
			.ant-form-item-explain {
				.ant-form-item-explain-error {
					position: absolute;
				}
			}
		}
	}
`;

export const ScaleColor = styled(Col)<ScaleColorProps>`
	margin-left: 0.5rem;
	width: 1rem;
	height: 2rem;
	border: 1px solid #d9d9d9;
	border-radius: 0.25rem;
	background-color: ${(props) => props.scale_color};
`;

export const Description = styled.label`
	margin: 0;
	opacity: 0.7;
`;

export const RPNScore = styled.span`
	color: #2f54eb;
	font-weight: 600;
	font-size: 0.9rem;
	padding: 0.25rem 0.5rem;
	border-radius: 0.25rem;
	border: 1px solid #2f54eb;
	background-color: #2f54eb0d;

	@media (min-width: 1600px) {
		font-size: 0.9rem;
	}
`;
