import Api from '@/services/api';

import {
	FrequenciesRequest,
	StressLevelsRequest,
	TotalTaskDurationsRequest
} from '@/components/ui/Inputs/types/request/customReport';
import { TotalTaskDurationDTO } from '@/components/ui/Inputs/types/response/totalTaskDuration';
import { StressLevelDTO } from '@/components/ui/Inputs/types/response/stressLevel';
import { FrequencyDTO } from '@/components/ui/Inputs/types/response/frequency';
import { TasksRequest } from '@/components/ui/Inputs/types/request/task';
import { TaskDTO } from '@/components/ui/Inputs/types/response/task';

export class BeraStepsService {
	async getFrequencies({ organization_id, company_id, step_key_id }: FrequenciesRequest): Promise<FrequencyDTO[]> {
		const url = `/custom-report/frequency/${step_key_id}?organization_id=${organization_id}&company_id=${company_id}`;
		const { data } = await Api.get<FrequencyDTO[]>(url);
		return data;
	}

	async getStressLevels({
		organization_id,
		company_id,
		step_key_id
	}: StressLevelsRequest): Promise<StressLevelDTO[]> {
		const url = `/custom-report/stress-level/${step_key_id}?organization_id=${organization_id}&company_id=${company_id}`;
		const { data } = await Api.get<StressLevelDTO[]>(url);
		return data;
	}

	async getTotalTaskDurations({
		organization_id,
		company_id,
		step_key_id
	}: TotalTaskDurationsRequest): Promise<TotalTaskDurationDTO[]> {
		const url = `/custom-report/total-task-duration/${step_key_id}?organization_id=${organization_id}&company_id=${company_id}`;
		const { data } = await Api.get<TotalTaskDurationDTO[]>(url);
		return data;
	}

	async getTasks({ organization_id, company_id, cycle_id }: TasksRequest): Promise<TaskDTO[]> {
		const url = `/task?cycle_id=${cycle_id}&organization_id=${organization_id}&company_id=${company_id}`;
		const { data } = await Api.get<TaskDTO[]>(url);
		return data;
	}
}
