import React from 'react';
import { Row } from 'antd';

import { InformationsCycle, InformationsOrganization } from '../types';
import { Summary } from './Summary';
import { Scores } from './Scores';

interface InformationsProps {
	isLoading: boolean;
	cycle: InformationsCycle;
	organization: InformationsOrganization;
}

export const Informations: React.FC<InformationsProps> = ({ isLoading, organization, cycle }) => {
	return (
		<Row gutter={[16, 16]}>
			<Summary isLoading={isLoading} organization={organization} cycle={cycle} />
			<Scores
				isLoading={isLoading}
				rpn={cycle?.rpn?.rpn}
				severity={cycle?.rpn?.severity}
				exposure={cycle?.rpn?.exposure}
				vulnerability={cycle?.rpn?.vulnerability}
			/>
		</Row>
	);
};
