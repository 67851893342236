import styled from 'styled-components';

export const ActionDropdown = styled.button`
	border: none;
	color: #2f54eb;
	cursor: pointer;
	background: none;
	min-width: 150px;
	transition: filter 0.2s;

	&:hover {
		filter: brightness(1.2);
	}

	&:focus {
		border: none;
		background: none;
	}
`;
