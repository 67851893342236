import React from 'react';
import { Col, Divider, Row } from 'antd';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import { ExistingPreventionMeasures } from './ExistingPreventionMeasures';
import { useSeraReviewContext } from '../../../context';
import { RiskDescription } from './RiskDescription';
import { Vulnerability } from './Vulnerability';
import { Specifications } from './Specifications';
import { RiskCategory } from './RiskCategory';
import { RPNScore, Title } from './styles';
import { RiskDamage } from './RiskDamage';
import { Evaluator } from './Evaluator';
import { Severity } from './Severity';
import { Exposure } from './Exposure';
import { Version } from './Version';
import { Task } from './Task';

interface PreviousReviewProps {
	reportId: string;
}

export const PreviousReview: React.FC<PreviousReviewProps> = ({ reportId }) => {
	const { mapRPNToPriority, seraSummaryReview } = useSeraReviewContext();

	const [previousReview] = seraSummaryReview.sera_reports.filter(({ id }) => id === reportId);

	const { review, updated_at } = seraSummaryReview;
	const {
		rpn,
		evaluator_name,
		existing_prevention_measures,
		exposure_name,
		risk_category_name,
		risk_damage_name,
		risk_description_name,
		severity_name,
		vulnerability_name,
		specifications,
		task_name
	} = previousReview;

	const version = `${review === 0 ? 'Original' : `${I18n.get('Review')} ${review}`} (${moment(updated_at).format(
		'L'
	)})`;

	const rpnPriority = mapRPNToPriority(rpn);

	return (
		<Col xs={24} style={{ backgroundColor: '#f8f8f8', padding: '1rem 0' }}>
			<Row gutter={48}>
				<Col offset={1} xs={24} lg={7}>
					<Row>
						<Task taskName={task_name} />
					</Row>
					<Row>
						<RiskCategory riskCategory={risk_category_name} />
					</Row>
					<Row>
						<RiskDescription riskDescription={risk_description_name} />
					</Row>
					<Row>
						<RiskDamage riskDamage={risk_damage_name} />
					</Row>
				</Col>
				<Col xs={24} lg={6}>
					<Row style={{ height: '80%' }} justify="space-between">
						<Col xs={24}>
							<Row>
								<Specifications specifications={specifications.length > 0 ? specifications : 'N/A'} />
							</Row>
						</Col>
						<Col xs={24}>
							<Row style={{ marginTop: '2rem' }}>
								<ExistingPreventionMeasures
									existingPreventionMeasures={
										existing_prevention_measures.length > 0 ? existing_prevention_measures : 'N/A'
									}
								/>
							</Row>
						</Col>
					</Row>
				</Col>
				<Col xs={24} lg={10}>
					<Row>
						<Col xs={12}>
							<Row>
								<Exposure exposure={exposure_name} />
							</Row>
							<Row>
								<Vulnerability vulnerability={vulnerability_name} />
							</Row>
							<Row>
								<Severity severity={severity_name} />
							</Row>
						</Col>
						<Col>
							<Divider
								type="vertical"
								style={{ height: '100%', borderColor: '#00000033', margin: '0rem 1.5rem' }}
							/>
						</Col>
						<Col xs={6}>
							<Row gutter={48} style={{ height: '100%' }}>
								<Col xs={24}>
									<Row>
										<Col xs={24}>
											<Title>{I18n.get('Task RPN')}:</Title>
										</Col>
										<Col xs={24}>
											<RPNScore priority={rpnPriority.color}>{rpn}</RPNScore>
										</Col>
									</Row>
								</Col>
								<Col xs={24}>
									<Row>
										<Col xs={24}>
											<Title>{I18n.get('Priority')}:</Title>
										</Col>
										<Col xs={24}>
											<RPNScore priority={rpnPriority.color}>
												{I18n.get(rpnPriority.priority)}
											</RPNScore>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row style={{ padding: '1rem 0 0 0' }} justify="space-between">
						<Col xs={12}>
							<Evaluator evaluator={evaluator_name} />
						</Col>
						<Col xs={12}>
							<Version version={version} />
						</Col>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};
