import React from 'react';
import { I18n } from '@aws-amplify/core';
import TextArea from 'antd/lib/input/TextArea';
import { Row, Col, Form, InputNumber } from 'antd';
import { Title, Paragraph, Text } from '@/components/Typography';
import { stepDescription } from '../contants';

export function WorkConditions() {
	return (
		<Row gutter={[0, 10]}>
			<Col span={12} offset={4}>
				<Title level={4}>Work Conditions</Title>
			</Col>
			<Col span={12} offset={4}>
				<Paragraph>{stepDescription}</Paragraph>
			</Col>
			<Col span={12} offset={4}>
				<Row gutter={[10, 10]}>
					<Col span={24}>
						<Title level={5}>Work schedule</Title>
						<Row>
							<Col>
								<Form.Item
									name={['preliminary_analysis', 'hours_work_schedule']}
									rules={[
										() => ({
											validator(_, value) {
												if (!value) {
													return Promise.reject(I18n.get('Invalid'));
												}

												return Promise.resolve();
											}
										})
									]}
								>
									<InputNumber
										className="input-schedule"
										min={1}
										max={24}
										style={{ width: '3.7rem' }}
									/>
								</Form.Item>
							</Col>
							<Col style={{ padding: '0.4rem 1rem 0 0.3rem' }}>
								<Text style={{ color: 'gray' }}>hours</Text>
							</Col>
							<Col>
								<Form.Item
									name={['preliminary_analysis', 'minutes_work_schedule']}
									rules={[
										() => ({
											validator(_, value) {
												if (!value && value !== 0) {
													return Promise.reject(I18n.get('Invalid'));
												}

												return Promise.resolve();
											}
										})
									]}
								>
									<InputNumber
										className="input-schedule"
										min={0}
										max={59}
										style={{ width: '3.7rem' }}
									/>
								</Form.Item>
							</Col>
							<Col style={{ padding: '0.4rem 1rem 0 0.3rem' }}>
								<Text style={{ color: 'gray' }}>minutes</Text>
							</Col>
						</Row>
					</Col>
					<Col span={24}>
						<Title level={5}>Summary description of the place</Title>
						<Form.Item name={['preliminary_analysis', 'place_description']}>
							<TextArea rows={4} maxLength={500} allowClear showCount />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Title level={5}>Simplified description of the expected task</Title>
						<Form.Item name={['preliminary_analysis', 'expected_task_description']}>
							<TextArea rows={4} maxLength={500} allowClear showCount />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Title level={5}>Simplified description of the task performed</Title>
						<Form.Item name={['preliminary_analysis', 'performed_task_description']}>
							<TextArea rows={4} maxLength={500} allowClear showCount />
						</Form.Item>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
