import { useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import Api from '@/services/api';
import type { BaseContext, Response } from '@/types';

type AdditionalItemToolScoreResult = {
	result: number;
};

export type GetAdditionalToolScore = BaseContext & {
	value_1?: number;
	value_2?: number;
	value_3?: number;
	value_4?: number;
	custom_report_step_key_id: string;
	additional_item_option_id_1?: string;
	additional_item_option_id_2?: string;
};

async function calculateAdditionalToolScore(params: GetAdditionalToolScore): Promise<AdditionalItemToolScoreResult> {
	const url = '/custom-report/result/step-key/calculate-additional-item-risk';
	const { data } = await Api.get<AdditionalItemToolScoreResult>(url, {
		params
	});
	return data;
}

export const useCalculateAdditionalToolScore = ({
	value_1,
	value_2,
	value_3,
	value_4,
	company_id,
	organization_id,
	custom_report_step_key_id,
	additional_item_option_id_1,
	additional_item_option_id_2
}: GetAdditionalToolScore) => {
	const params = {
		value_1,
		value_2,
		value_3,
		value_4,
		company_id,
		organization_id,
		custom_report_step_key_id,
		additional_item_option_id_1,
		additional_item_option_id_2
	};
	const hasAdditionalItemValues =
		!!value_1 ||
		!!value_2 ||
		!!value_3 ||
		!!value_4 ||
		!!additional_item_option_id_1 ||
		!!additional_item_option_id_2;
	const report = useQuery<AdditionalItemToolScoreResult, AxiosError<Response>>(
		['jds_d86_report', 'additional_tools', params],
		() => calculateAdditionalToolScore(params),
		{
			enabled: !!company_id && !!organization_id && !!custom_report_step_key_id && !!hasAdditionalItemValues,
			retry: 0
		}
	);
	return {
		...report,
		data: report.data ?? ({} as AdditionalItemToolScoreResult)
	};
};
