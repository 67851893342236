import React from 'react';
import { Button, Col, Form, Input, Modal, Row } from 'antd';

import { CheckOutlined, CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';

import { Confirm } from './ManageOrganizationModal/Manager/styles';
import { useUpdateOrganization } from './hooks';
import { UpdateOrganizationRequest } from './hooks/types';
import { OrganizationDTO } from '@/components/ui/Inputs/types/response';
import { useApplicationContext } from '@/context/v1/Application/context';

const { useForm, useWatch } = Form;

type EditOrganizationProps = {
	organization: OrganizationDTO;
	organizations: OrganizationDTO[];
	onEditing: (isEditing: boolean) => void;
};

export const EditOrganization: React.FC<EditOrganizationProps> = ({ onEditing, organization, organizations }) => {
	const [form] = useForm();

	const { setOrganization } = useApplicationContext();
	const { mutateAsync: updateOrganization, isLoading } = useUpdateOrganization();

	const organizationName = useWatch(['editing', 'organization_name'], form);

	async function handleUpdateOrganization(body: UpdateOrganizationRequest) {
		if (organizations.find((elem) => elem.name === body.name)) {
			const content = I18n.get(`This name already exists. Do you wish to proceed ?`);
			Modal.confirm({
				title: I18n.get('Warning!'),
				icon: <ExclamationCircleOutlined />,
				content: content,
				okType: 'danger',
				okText: I18n.get('Yes'),
				cancelText: I18n.get('Cancel'),
				onOk: async () => {
					const updated = await updateOrganization(body);
					if (updated.id) {
						onEditing(false);
						setOrganization(updated);
					}
				}
			});
		} else {
			const updated = await updateOrganization(body);
			if (updated.id) {
				onEditing(false);
				setOrganization(updated);
			}
		}
	}

	function handleCancel() {
		onEditing(false);
	}

	function handleSubmit() {
		handleUpdateOrganization({
			name: organizationName,
			organization_id: organization.id
		});
		onEditing(false);
	}

	const initialValues = {
		editing: {
			organization_name: organization.name
		}
	};

	return (
		<Form
			form={form}
			initialValues={initialValues}
			style={{
				width: '100%',
				margin: '0'
			}}
		>
			<Row align="middle" justify="center" style={{ width: '100%', position: 'relative', left: '24px' }}>
				<Col span={16} style={{ marginRight: '0.66rem' }}>
					<Form.Item
						name={['editing', 'organization_name']}
						style={{ margin: '0' }}
						rules={[
							{
								required: true,
								message: I18n.get('Please enter a valid organization name')
							}
						]}
					>
						<Input
							style={{
								flex: 'auto',
								textAlign: 'center'
							}}
							placeholder={I18n.get('Organization name')}
						/>
					</Form.Item>
				</Col>
				<Col span={2} style={{ marginRight: '0.66rem' }}>
					<Button
						size="small"
						type="primary"
						ghost={true}
						danger={true}
						onClick={() => handleCancel()}
						icon={<CloseOutlined />}
					/>
				</Col>
				<Confirm span={2}>
					<Button
						size="small"
						type="primary"
						ghost={true}
						htmlType="submit"
						icon={<CheckOutlined />}
						onClick={() => handleSubmit()}
						disabled={!organizationName || organization.name === organizationName || isLoading}
					/>
				</Confirm>
			</Row>
		</Form>
	);
};
