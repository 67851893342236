import React, { useState } from 'react';
import { debounce } from 'lodash';
import { Row, Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import { SearchOutlined } from '@ant-design/icons';

import { FilterModal } from './components/FilterModal';
import { useSelectFilesContext } from '../../context';
import { FilterIcon } from '../../icons/filterIcon';
import * as C from '../styles';
import * as S from './styles';

const { useFormInstance } = Form;

export function Filter() {
	const [openModal, setOpenModal] = useState<boolean>(false);
	const { handleFilter } = useSelectFilesContext();

	const { getFieldValue } = useFormInstance();

	const allFilters = getFieldValue(['filter']);

	const handleDebounced = debounce((value) => {
		const newFilter = { ...allFilters, original_name: value, offset: 0 };

		if (value.length <= 0) {
			delete newFilter.original_name;
		}

		handleFilter(newFilter);
	}, 1000);

	function handleOpenModal() {
		setOpenModal(!openModal);
	}

	return (
		<Row justify="start" align="middle" gutter={[20, 20]}>
			<Col span={21}>
				<Form.Item name={['filter', 'original_name']} style={{ margin: 0 }}>
					<C.CustomInput
						size="large"
						prefix={<SearchOutlined />}
						placeholder={I18n.get('Search for a file')}
						onChange={() => handleDebounced(getFieldValue(['filter', 'original_name']))}
					/>
				</Form.Item>
			</Col>
			<Col
				span={2}
				style={{ marginLeft: '10px', display: 'flex', alignItems: 'center', justifyContent: 'start' }}
			>
				<S.IconButton
					type="link"
					icon={<FilterIcon style={{ fontSize: '35px', marginTop: '0px' }} />}
					onClick={() => setOpenModal(!openModal)}
				/>
			</Col>
			<FilterModal onOpenModal={handleOpenModal} openModal={openModal} />
		</Row>
	);
}
