import styled from 'styled-components';
import { Col } from 'antd';

export const CustomTable = styled(Col)`
	.ant-table-thead {
		.ant-table-cell {
			color: #fff;
			font-weight: 600;
			background-color: #2f54eb;

			&::before {
				background-color: #fff !important;
			}

			:first-child {
				border-radius: 0.5rem 0 0 0;
			}

			:last-child {
				border-radius: 0 0.5rem 0 0;
			}
		}
	}

	.ant-table-row {
		.ant-table-cell {
			background-color: #f8f8f8;
		}

		:last-child {
			.ant-table-cell {
				box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.09) !important;
				:first-child {
					border-radius: 0 0 0 0.5rem;
				}

				:last-child {
					border-radius: 0 0 0.5rem 0;
				}
			}
		}
	}
`;

export const GrayCircle = styled.div`
	width: 16px;
	height: 16px;
	border-radius: 50%;
	margin-left: 0.3rem;
	background-color: #f9f9f9;
	border: 3px solid #d9d9d9;
`;

type RPNScoreProps = {
	priority: string;
};

export const Description = styled.span`
	font-size: 0.8rem;
	font-weight: 400;
`;

export const RPNScore = styled.span<RPNScoreProps>`
	height: 2rem;
	display: flex;
	min-width: 3rem;
	font-weight: 600;
	padding: 0 0.5rem;
	font-size: 0.9rem;
	margin: 0.75rem 0.75rem 0 0;
	align-items: center;
	border-radius: 0.15rem;
	justify-content: center;
	background-color: ${({ priority }) => priority};

	@media (min-width: 1600px) {
		font-size: 0.9rem;
	}
`;
