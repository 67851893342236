import React from 'react';
import { Avatar, Button, Col, Row } from 'antd';

import { QrcodeOutlined } from '@ant-design/icons';
import { Title } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';

import { GenerateContainer, IconContainer, Tip } from './styles';
import { useTFAContext } from '../context';

export const GenerateAuthApp2FA = () => {
	const { handleNextStep, handleGenerate2FA } = useTFAContext();

	async function handleGenerateClick() {
		const generated = await handleGenerate2FA();
		if (generated) {
			handleNextStep();
		}
	}

	return (
		<GenerateContainer align="middle" justify="center">
			<Col span={22}>
				<IconContainer justify="center">
					<Col span={'auto'}>
						<Avatar size={64} icon={<QrcodeOutlined />} />
					</Col>
				</IconContainer>
				<Row justify="center">
					<Col span={24}>
						<Row>
							<Title align="center" level={5}>
								You are about to generate your 2FA with your authenticator app.
							</Title>
						</Row>
						<Row justify="center">
							<Tip style={{ fontWeight: '400' }}>
								{I18n.get('Be sure to have your mobile phone with you ready to scan your code.')}
							</Tip>
						</Row>
					</Col>
				</Row>
			</Col>
			<Col span={18}>
				<Row justify="center">
					<Button onClick={handleGenerateClick} type="primary">
						{I18n.get('Generate')}
					</Button>
				</Row>
			</Col>
		</GenerateContainer>
	);
};
