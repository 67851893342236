import { useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import Api from '@/services/api';
import type { BaseContext, Response, Severity } from '@/types';

export type GetSeveritiesDTO = BaseContext & {
	step_key_id: string;
};

async function getSeverities(params: GetSeveritiesDTO): Promise<Severity[]> {
	const url = '/custom-report/severity';
	const { data } = await Api.get<Severity[]>(url, {
		params
	});
	return data;
}

export const useGetSeverities = ({ organization_id, company_id, step_key_id }: GetSeveritiesDTO) => {
	const enabled = !!organization_id && !!company_id && !!step_key_id;
	const severities = useQuery<Severity[], AxiosError<Response>>(
		['severities', [organization_id, company_id]],
		() => getSeverities({ organization_id, company_id, step_key_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...severities,
		data: severities.data ?? ([] as Severity[])
	};
};
