import { useMutation, useQuery } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';
import Api from '@/services/api';
import {
	BackCompressiveForceEstimationResultDTO,
	DownloadPDF,
	GetResultRequest
} from '@/components/views/Report/BackCompressiveForceEstimation/types';

const DEFAULT_URL = '/ergonomic-tool/back-compressive-force-estimation';

async function getResults(payload: GetResultRequest): Promise<BackCompressiveForceEstimationResultDTO> {
	const url = `${DEFAULT_URL}/result/${payload.file_id}`;

	const { data } = await Api.get<BackCompressiveForceEstimationResultDTO>(url, {
		params: payload
	});
	return data;
}

async function downloadPDF(payload: DownloadPDF) {
	const url = `${DEFAULT_URL}/document/pdf`;
	const { data } = await Api.post<string>(url, payload);
	return data;
}

export const useGetResult = ({ organization_id, company_id, file_id, finished }: GetResultRequest) => {
	const parameters = { organization_id, company_id, file_id };
	const report = useQuery(['back_compressive_force_estimation_result', parameters], () => getResults(parameters), {
		enabled: !!organization_id && !!company_id && !!file_id && !!finished
	});
	return {
		...report,
		data: report.data ?? ({} as BackCompressiveForceEstimationResultDTO)
	};
};

export const useDownloadPDF = () => {
	return useMutation((body: DownloadPDF) => downloadPDF(body), {
		onSuccess: (url: string) => {
			let archive = document.createElement('a');
			archive.target = '_blank';
			archive.href = url;
			archive.click();
			archive.remove();
		},
		onError: () => {
			message.error(I18n.get('Failed to generate the PDF'));
		}
	});
};
