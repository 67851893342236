import { Pagination, Select } from 'antd';
import styled from 'styled-components';

export const PaginationStyled = styled(Pagination)`
	.ant-pagination-item-link {
		border: none;
		background-color: #f0f2f5;
		font-size: 20px;
	}
	.ant-pagination-item-active a {
		color: #2F54EB;
	}
	.ant-pagination-item {
		border: none;
		border-radius: 5px;
	}
	.ant-pagination-item-active {
		background-color: #eaeefd;
	}
`;
export const SelectStyled = styled(Select)`
	margin-left: 1rem;
	.ant-select-selector {
		border: none !important;
		border-radius: 5px !important;
	}
	.ant-select-arrow {
		width: 10px;
		color: #262626;
	}
`;
