import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { Context, Methods, State } from './types';
import { useBeraJobStepsContext } from '../../context';

interface BeraReportStepsProviderProps {
	children: ReactNode;
}

export const BeraReportStepsContext = createContext<Context>({} as Context);

export const BeraReportStepsProvider = ({ children }: BeraReportStepsProviderProps) => {
	const { showBeraJobResult, allFinished, beraReportsStatus, selectedFilesId, tabsLength } = useBeraJobStepsContext();

	const [isLastTab, setIsLastTab] = useState<boolean>(allFinished);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [clickableResultStep, setClickableResultStep] = useState<boolean>(false);
	const [currentActiveTabIndex, setCurrentActiveTabIndex] = useState<string>('1');

	useEffect(() => {
		const currentTabIndexNumber = convertTabIndexToNumber(currentActiveTabIndex);
		if (allFinished && currentTabIndexNumber === selectedFilesId.length) {
			setIsLastTab(true);
		}
	}, [allFinished, currentActiveTabIndex]);

	function handleIsLoading(loading: boolean) {
		setIsLoading(loading)
	}

	function handleTabClick(tabIndex: string) {
		if (showBeraJobResult) {
			return;
		}
		const tabIndexNumber = convertTabIndexToNumber(tabIndex);
		if (tabIndexNumber === tabsLength) {
			setIsLastTab(true);
		}
		setCurrentActiveTabIndex(tabIndex);
	}

	function handleNextFileClick() {
		const tabIndexNumber = convertTabIndexToNumber(currentActiveTabIndex);
		let newTabIndexNumber = tabIndexNumber + 1;
		if (allFinished && !isLastTab) {
			setIsLastTab(true);
			newTabIndexNumber = tabsLength - 1;
		}

		if (!allFinished && isLastTab) {
			const fileNotFinished = beraReportsStatus.find((report) => !report.completed);
			if (!fileNotFinished) {
				return;
			}
			const fileIndex = selectedFilesId.findIndex((id) => id === fileNotFinished.fileId);
			newTabIndexNumber = fileIndex + 1;
		}

		const newTabIndex = convertTabNumberToString(newTabIndexNumber);
		setCurrentActiveTabIndex(newTabIndex);
	}

	function convertTabIndexToNumber(tabIndex: string): number {
		return Number(tabIndex);
	}

	function convertTabNumberToString(tabIndexNumber: number): string {
		return String(tabIndexNumber);
	}

	function handleClickableResultStepChange(clickable: boolean) {
		setClickableResultStep(clickable);
	}

	const state: State = {
		isLastTab,
		isLoading,
		clickableResultStep,
		currentActiveTabIndex
	};

	const methods: Methods = {
		handleTabClick,
		handleIsLoading,
		handleNextFileClick,
		handleClickableResultStepChange
	};

	const context: Context = {
		...state,
		...methods
	};

	return <BeraReportStepsContext.Provider value={context}>{children}</BeraReportStepsContext.Provider>;
};

export function useBeraReportStepsContext() {
	const context = useContext(BeraReportStepsContext);
	return context;
}
