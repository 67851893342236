import React, { useEffect, useState } from 'react';
import { Button, Col, Form, InputNumber, Row, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import { v4 as uuidv4 } from 'uuid';

import { useGetAdditionalItemsOptions } from '@/hooks';
import type { AditionalItemProps, Options } from './types';
import { useApplicationContext } from '@/context/v1/Application/context';
import type { AdditionalItem } from '@/hooks';
import { getDecimalSeparator } from '@/utils/getDecimalSeparator';
import { useStepKeysContext } from '../DefaultSelects/context';
import { fieldsUnitTexts } from './utils/FieldsUnitTexts';
import { ConditionCard } from '../../ConditionCard';
import { Text } from '@/components/Typography';
import { InfoListSameResult } from './InfoListSameResult';
import { InfoTooltip } from './InfoTooltip';
import { AdditionalItemsToolsDto, GetAdditionalToolSharedScoreDTO, useCalculateAdditionalToolSharedScore } from '@/hooks/useCalculateAdditionalToolSharedScore';

const { useFormInstance, useWatch } = Form;
const { formatLabelWithUnit, formatPlaceholderWithUnit } = fieldsUnitTexts;

const tooltipMessages = {
	'Lever Arm': 'Distance from the acromion to the center of the load (greatest distance)',
	'Repetitions': 'Repetitions in a working day',
	undefined: '',
}

export const SelectAndInputs: React.FC<AditionalItemProps> = ({ additionalItem }) => {
	const { id, title_1, title_2, title_3, title_4, unit_1, unit_2, unit_3, unit_4 } = additionalItem;
	const tooltipTitle3: string | undefined = tooltipMessages[title_3 as keyof typeof tooltipMessages];
	const tooltipTitle4: string | undefined = tooltipMessages[title_4 as keyof typeof tooltipMessages];
	const form = useFormInstance();
	const { formFieldName, stepKey } = useStepKeysContext();
	const { organization, company } = useApplicationContext();
	const [worstRisk, setWorstRisk] = useState<number>(additionalItem.results?.[0]?.result);

	const [selectAndInput, setSelectAndInput] = useState<AdditionalItem>(additionalItem);

	const formValueOne = useWatch([...formFieldName, 'additional_items', id, 'value_1']);
	const formValueTwo = useWatch([...formFieldName, 'additional_items', id, 'value_2']);
	const formValueThree = useWatch([...formFieldName, 'additional_items', id, 'value_3']);
	const selectFormValue = useWatch([...formFieldName, 'additional_items', id, 'additional_item_option_id_1']);

	useEffect(() => {
		form.setFieldValue([...formFieldName, 'additional_items', 'local_values'], additionalItem);
	}, []);

	const { mutateAsync: calculateAdditionalToolScore, isLoading: isLoadingCalculateAdditionalToolScore } =
		useCalculateAdditionalToolSharedScore();
	const { data: options, isLoading: isGettingAdditionalItemsOptions } = useGetAdditionalItemsOptions({
		company_id: company?.id,
		organization_id: organization?.id,
		custom_report_step_key_id: stepKey.id
	});

	const selectLabel = formatLabelWithUnit(title_1, unit_1);
	const firstInputPlaceholder = formatPlaceholderWithUnit('0', unit_2);
	const secondInputPlaceholder = formatPlaceholderWithUnit('0', unit_3);
	const thirdInputPlaceholder = formatPlaceholderWithUnit('0', unit_4);

	function handleChangeSelect(): void {
		form.setFieldValue([...formFieldName, 'additional_items', id, 'value_1'], undefined);
		form.setFieldValue([...formFieldName, 'additional_items', id, 'value_2'], undefined);
		form.setFieldValue([...formFieldName, 'additional_items', id, 'value_3'], undefined);
	}

	async function handleAddAdditionalItemValueClick(): Promise<void> {
		const formFieldValues: AdditionalItemsToolsDto = form.getFieldValue([...formFieldName, 'additional_items', id]);

		const results = selectAndInput?.results ?? [];
		const additionalItemResultsCopy = [...results];

		const payload: GetAdditionalToolSharedScoreDTO = {
			additional_items: (additionalItemResultsCopy as AdditionalItemsToolsDto[]).concat([formFieldValues]),
			company_id: company?.id,
			organization_id: organization?.id,
			custom_report_step_key_id: stepKey.id,
		};

		const additionalToolScore = await calculateAdditionalToolScore(payload);

		if (!additionalToolScore?.result) {
			return
		}

		const additionalItemResult = additionalToolScore.additional_items_results[additionalToolScore.additional_items_results.length - 1];

		const newAdditionalItemValue = {
			...formFieldValues,
			id: uuidv4(),
			value_4: additionalItemResult.value_4,
			result: additionalItemResult.result,
			description: options.find(({ id }) => id === formFieldValues.additional_item_option_id_1)?.description
		};

		additionalItemResultsCopy.push(newAdditionalItemValue);

		additionalItemResultsCopy.forEach(f => {
			f.result = additionalToolScore?.result
			f.value_4 = additionalToolScore?.result_value
		})

		setWorstRisk(additionalToolScore?.result);

		setSelectAndInput({
			...selectAndInput,
			results: additionalItemResultsCopy
		});

		form.setFieldValue(
			[...formFieldName, 'additional_items', 'local_values', 'results'],
			additionalItemResultsCopy
		);
		form.setFieldValue([...formFieldName, 'additional_items', id], undefined);
	}

	async function handleRemoveAdditionalItemClick(id: string): Promise<void> {
		const resultsWithoutRemovedItem = selectAndInput.results.filter((item) => item.id !== id);

		const payload: GetAdditionalToolSharedScoreDTO = {
			additional_items: resultsWithoutRemovedItem as AdditionalItemsToolsDto[],
			company_id: company?.id,
			organization_id: organization?.id,
			custom_report_step_key_id: stepKey.id,
		};

		if (resultsWithoutRemovedItem.length === 0) {
			setWorstRisk(0);
			setSelectAndInput({
				...selectAndInput,
				results: resultsWithoutRemovedItem
			});
			form.setFieldValue(
				[...formFieldName, 'additional_items', 'local_values', 'results'],
				resultsWithoutRemovedItem
			);

			return
		}

		const additionalToolScore = await calculateAdditionalToolScore(payload);
		setWorstRisk(additionalToolScore?.result);
		resultsWithoutRemovedItem.forEach(f => {
			f.result = additionalToolScore?.result;
			f.value_4 = additionalToolScore?.result_value;
		})
		setSelectAndInput({
			...selectAndInput,
			results: resultsWithoutRemovedItem
		});
		form.setFieldValue(
			[...formFieldName, 'additional_items', 'local_values', 'results'],
			resultsWithoutRemovedItem
		);
	}

	function filterOption(input: string, option?: Options): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function hasSomeEmptyField(): boolean | undefined {
		return !selectFormValue || !formValueOne || !formValueTwo || !formValueThree;
	}

	return (
		<Row gutter={[0, 15]} key={id}>
			<Col span={19}>
				<Row align="bottom" gutter={[14, 20]}>
					<Col xs={24} xxl={24}>
						<Row gutter={[0, 5]}>
							<Col span={24}>
								<Text strong>{selectLabel}</Text>
							</Col>
							<Col xxl={24}>
								<Form.Item
									style={{ margin: 0 }}
									name={[...formFieldName, 'additional_items', id, 'additional_item_option_id_1']}
								>
									<Select
										showSearch
										allowClear
										filterOption={filterOption}
										onChange={handleChangeSelect}
										placeholder={I18n.get(title_1)}
										loading={isGettingAdditionalItemsOptions}
										disabled={isGettingAdditionalItemsOptions}
										options={options?.map(({ id, description }) => ({
											label: `${I18n.get(description)}`,
											value: id
										})).sort((a, b) => a.label.localeCompare(b.label))}
									/>
								</Form.Item>
							</Col>
						</Row>
					</Col>
					<Col xxl={6}>
						<Row gutter={[0, 5]}>
							<Row gutter={[5, 0]}>
								<Col>
									<Text strong>{title_2}</Text>
								</Col>
							</Row>
							<Col span={24}>
								<Form.Item
									style={{ margin: 0 }}
									name={[...formFieldName, 'additional_items', id, 'value_1']}
								>
									<InputNumber
										min={0}
										style={{ width: '100%' }}
										placeholder={firstInputPlaceholder}
										decimalSeparator={getDecimalSeparator()}
									/>
								</Form.Item>
							</Col>
						</Row>
					</Col>
					<Col xxl={10}>
						<Row gutter={[0, 5]}>
							<Col span={24}>
								<Text strong>{title_3}</Text>
								{tooltipTitle3 && <InfoTooltip text={tooltipTitle3} />}
							</Col>
							<Col span={24}>
								<Form.Item
									style={{ margin: 0 }}
									name={[...formFieldName, 'additional_items', id, 'value_2']}
								>
									<InputNumber
										min={0}
										style={{ width: '100%' }}
										placeholder={secondInputPlaceholder}
									/>
								</Form.Item>
							</Col>
						</Row>
					</Col>
					<Col xxl={8}>
						<Row gutter={[0, 5]}>
							<Col span={24}>
								<Text strong>{title_4}</Text>
								{tooltipTitle4 && <InfoTooltip text={tooltipTitle4} />}
							</Col>
							<Col span={24}>
								<Form.Item
									style={{ margin: 0 }}
									name={[...formFieldName, 'additional_items', id, 'value_3']}
								>
									<InputNumber
										min={0}
										style={{ width: '100%' }}
										placeholder={thirdInputPlaceholder}
									/>
								</Form.Item>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
			<Col span={3} style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				paddingTop: 26
			}}
			>
				<Button
					type="primary"
					style={{
						height: '100%',
					}}
					icon={<PlusOutlined />}
					onClick={handleAddAdditionalItemValueClick}
					disabled={hasSomeEmptyField() || isLoadingCalculateAdditionalToolScore}
				/>
			</Col>
			<Col span={24}>
				<InfoListSameResult items={selectAndInput} result={worstRisk} onDeleteValue={handleRemoveAdditionalItemClick} />
			</Col>
			<Col span={24}>
				<ConditionCard result={worstRisk} isLoading={isGettingAdditionalItemsOptions || isLoadingCalculateAdditionalToolScore} />
			</Col>
		</Row>
	);
};
