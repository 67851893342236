import React from 'react';
import { Col, Divider, Row } from 'antd';

import { I18n } from '@aws-amplify/core';

import {
	ActionPlanContent,
	ActionPlanTitle,
	ActioPlanContainer,
	DeadlineContainer,
	Description,
	Header,
	Title
} from './styles';
import { ActionPlanDTO, TaskDTO } from '../../hooks/types';
import { ActionPlanTask } from './ActionPlanTask';

type ActionPlanDataProps = {
	actionPlan: ActionPlanDTO;
};

export const ActionPlanData: React.FC<ActionPlanDataProps> = ({ actionPlan }) => {
	function mountTasksList(tasks: TaskDTO[] | null): React.ReactNode {
		return tasks
			? actionPlan.tasks.map((task) => <ActionPlanTask key={task.id} task={task} length={tasks.length} />)
			: I18n.get('No task available');
	}

	return (
		<ActioPlanContainer justify="center">
			<Col span={24}>
				<Header justify="space-between" align="middle">
					<ActionPlanTitle>{I18n.get(actionPlan.title)}</ActionPlanTitle>
					<Description style={{ color: '#ffffff', fontWeight: '600' }}>TLID: {actionPlan.tlid}</Description>
				</Header>
			</Col>
			<ActionPlanContent span={24}>
				<Row align="top">
					<Col xs={22} xl={10} xxl={12}>
						<Row gutter={[0, 8]}>
							<Col span={24}>
								<Row>
									<Title>{I18n.get('Person responsible')}</Title>
								</Row>
								<Row>
									<Description>{actionPlan.responsible}</Description>
								</Row>
							</Col>

							<Col xs={24}>
								<Divider type="horizontal" style={{ width: '100%', margin: '0.5rem 0' }} />
							</Col>

							<Col span={24}>
								<Row align="middle">
									<Col span={12}>
										<Row>
											<Title>{I18n.get('Deadline')}</Title>
										</Row>
										<Row>
											<Description>
												{actionPlan.deadline.remaining_days}{' '}
												{I18n.get(actionPlan.deadline.remaining_days_text)}
											</Description>
										</Row>
									</Col>
									<Col span={'auto'}>
										<DeadlineContainer>
											<Col>
												<Description>{actionPlan.deadline.day}</Description>
											</Col>
											<Divider type="vertical" style={{ height: 'auto', margin: '0 0.5rem' }} />
											<Col>
												<Description>{actionPlan.deadline.month}</Description>
											</Col>
											<Divider type="vertical" style={{ height: 'auto', margin: '0 0.5rem' }} />
											<Col>
												<Description>{actionPlan.deadline.year}</Description>
											</Col>
										</DeadlineContainer>
									</Col>
								</Row>
							</Col>

							<Col xs={24}>
								<Divider type="horizontal" style={{ width: '100%', margin: '0.5rem 0' }} />
							</Col>
							<Col span={24}>
								<Row>
									<Title>{I18n.get('Description')}</Title>
								</Row>
								<Row>
									<Description>{actionPlan.description ?? 'N/A'}</Description>
								</Row>
							</Col>
						</Row>
					</Col>
					<Col xs={22} xl={0}>
						<Divider type="horizontal" style={{ width: '100%', margin: '0.5rem 0' }} />
					</Col>
					<Col xs={0} xl={1} style={{ minHeight: '100%' }}>
						<Divider type={'vertical'} style={{ height: '100%', margin: '0 2rem' }} />
					</Col>
					<Col xs={24} lg={8}>
						<Row gutter={[0, 32]}>
							<Col span={24}>
								<Title>{I18n.get('Tasks')}</Title>
							</Col>

							<Col span={24} style={{ maxHeight: '180px', overflowY: 'auto' }}>
								{mountTasksList(actionPlan.tasks)}
							</Col>
						</Row>
					</Col>
				</Row>
			</ActionPlanContent>
		</ActioPlanContainer>
	);
};
