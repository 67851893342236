import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message, notification } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CreateSectorRequest } from '@/components/ui/Inputs/types/request/sector';
import { SectorDTO } from '@/components/Dashboard/types';
import Api from '@/services/api';

export const useCreateSector = () => {
	const queryClient = useQueryClient();
	return useMutation(
		({ payload }: { payload: CreateSectorRequest }) => Api.post<SectorDTO>('/sector/create', payload),
		{
			onError: (err: any) => {
				notification.error({
					message: I18n.get(err?.response?.data?.message || 'Ops... something happened!')
				});
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['sectors']);
				queryClient.invalidateQueries(['sectors_list']);
				message.success(I18n.get('Sector created successfully'));
			}
		}
	);
};
