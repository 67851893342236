import React from 'react';
import { Row, Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { PieChart } from './PieChart';
import type { AnglesType } from './types';
import { Diagnostics } from '@/types/Reba';
import { Text } from '@/components/Typography';
import { diagnosticColors } from '@/constants/Reba';
import { useStepKeysContext } from '@/components/CustomReports/StepKeys/DefaultSelects/context';
import { Utils } from '@/components/views/PreliminaryAnalysis/Form/Steps/PosturesAndMovements/utils';

type PercentageItem = {
	value: string;
	index: number;
};

interface ChartsProps {
	data: AnglesType;
	formFieldName: string[];
}

const { useWatch, useFormInstance } = Form;

export const Chart: React.FC<ChartsProps> = ({ data, formFieldName }) => {
	const form = useFormInstance();

	const {
		exposuresData: { exposures }
	} = useStepKeysContext();

	const scales = Object.values(Diagnostics);
	const percentages = Utils.calculatePercentage(data);

	const exposureId = useWatch([...formFieldName, 'exposure_id'], form);

	const PercentageItem = ({ value, index }: PercentageItem) => (
		<Col key={index} span={24}>
			<span style={{ fontSize: '12px' }}>
				{I18n.get(scales[index])}:{' '}
				<Text strong style={{ color: diagnosticColors[index] }}>
					{value}%
				</Text>
			</span>
		</Col>
	);

	function defineExposureIdBasedOnRebaAcceptablePercentage(acceptablePercentage: number) {
		if (!exposures) {
			return;
		}

		const scaleToExposureNameMapper = {
			ACCEPTBALE: 'one_to_ten',
			LOW: 'eleven_to_twenty',
			MEDIUM: 'twenty_one_to_thirty',
			MEDIUM_HIGH: 'thirty_one_to_fifty',
			HIGH: 'fifty_one_to_seventy_five',
			EXTREME: 'seventy_six_to_a_hundred'
		};

		const exposureNameToIdMapper = exposures.reduce((nameToId, currentExposure) => {
			const exposure = {
				...nameToId,
				[currentExposure.name]: currentExposure.id
			};
			return exposure;
		}, {} as any);

		if (acceptablePercentage <= 9) {
			return exposureNameToIdMapper[scaleToExposureNameMapper.EXTREME];
		}
		if (acceptablePercentage <= 29) {
			return exposureNameToIdMapper[scaleToExposureNameMapper.HIGH];
		}
		if (acceptablePercentage <= 49) {
			return exposureNameToIdMapper[scaleToExposureNameMapper.MEDIUM_HIGH];
		}
		if (acceptablePercentage <= 79) {
			return exposureNameToIdMapper[scaleToExposureNameMapper.MEDIUM];
		}
		if (acceptablePercentage <= 89) {
			return exposureNameToIdMapper[scaleToExposureNameMapper.LOW];
		}
		return exposureNameToIdMapper[scaleToExposureNameMapper.ACCEPTBALE];
	}

	if (!exposureId) {
		const id = defineExposureIdBasedOnRebaAcceptablePercentage(Number(percentages[1]));
		form.setFieldValue([...formFieldName, 'exposure_id'], id);
	}

	return (
		<Row gutter={[0, 10]}>
			<Col span={24}>
				<Text strong>{I18n.get('Exposure')}:</Text>
			</Col>
			<Col span={24}>
				<Row gutter={[20, 20]}>
					<Col span={8}>
						<PieChart scores={data} />
					</Col>
					<Col span={14}>
						<Row>
							{percentages?.map((value: string, i: number) => (
								<PercentageItem key={i} value={value} index={i} />
							))}
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
