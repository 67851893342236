import React, { ReactElement, useState } from 'react';
import { Button, Divider, Input, Select, Form, Skeleton } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { NamePath } from 'antd/lib/form/interface';
import { I18n } from '@aws-amplify/core';

import { CreateSectorRequest } from '../types/request/sector';
import { useCreateSector } from '@/hooks/v1/useSectorMutations';
import { SectorDTO } from '@/components/ui/Inputs/types/response/sector';
import { useApplicationContext } from '@/context/v1/Application/context';

const { useFormInstance } = Form;

type SectorSelectProps = {
	size?: SizeType;
	loading: boolean;
	disabled: boolean;
	sectorId?: string;
	companyId?: string;
	fieldName: NamePath;
	allowCreation?: boolean;
	handleClearSector?: () => void;
	sectors: SectorDTO[] | undefined;
	handleSelectSector?: (id: string) => void;
};

type OptionType = { label: string; value: string } | undefined;

export function SectorSelect({
	sectors,
	loading,
	disabled,
	sectorId,
	companyId,
	size = 'middle',
	handleClearSector,
	handleSelectSector,
	allowCreation = true,
	fieldName = 'sector_id'
}: SectorSelectProps) {
	const [sectorName, setSectorName] = useState('');

	const { company, organization } = useApplicationContext();
	const { validateFields } = useFormInstance();

	const { mutateAsync: createSector, isLoading } = useCreateSector();

	async function onCreateSector() {
		const payload: CreateSectorRequest = {
			organization_id: organization.id,
			company_id_creation: companyId ?? company?.id,
			company_id: company?.id,
			name: sectorName
		};

		const { data: sector } = await createSector({ payload });
		const sectorId = sector?.id;
		if (sectorId) {
			handleChange(sectorId);
		}
		setSectorName('');
	}

	async function handleChange(id: string) {
		if (handleSelectSector) {
			handleSelectSector(id);
			await validateFields([fieldName]);
		}
	}

	function handleClear() {
		if (handleClearSector) {
			handleClearSector();
		}
	}

	function handleSectorNameChange(name: string) {
		setSectorName(name);
	}

	function filterOption(option: OptionType, input: string): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function showDropdownrender(menu: ReactElement): JSX.Element {
		if (!allowCreation) {
			return <div>{menu}</div>;
		}
		return (
			<div>
				{menu}
				<Divider style={{ margin: '4px 0' }} />
				<div
					style={{
						display: 'flex',
						flexWrap: 'nowrap',
						padding: 8
					}}
				>
					<Input
						style={{ flex: 'auto', borderRadius: '7px' }}
						value={sectorName}
						onChange={(e) => handleSectorNameChange(e.target.value)}
					/>
					<Button
						onClick={onCreateSector}
						type="link"
						loading={isLoading}
						disabled={!sectorName || sectorName.length < 3}
					>
						{I18n.get('Add item')}
					</Button>
				</div>
			</div>
		);
	}

	if (loading) {
		return <Skeleton.Input block active size="small" />;
	}

	return (
		<Select
			allowClear
			showSearch
			size={size}
			value={sectorId}
			loading={loading}
			disabled={disabled}
			onClear={handleClear}
			onChange={handleChange}
			placeholder={I18n.get('Sector')}
			dropdownRender={(menu) => showDropdownrender(menu)}
			filterOption={(input, option) => filterOption(option, input)}
			options={sectors?.map(({ id, name }) => ({ label: name, value: id }))}
		/>
	);
}
