import React from 'react';
import { I18n } from '@aws-amplify/core';
import { useDashboardContext } from '../../context';
import { defaultOptions, Risk, RiskBarOptions } from '../types';
import {
	Caption,
	Container,
	RiskBarCaption,
	RiskBarContainer,
	RiskBarContent,
	RiskScale,
	Value,
	VerticalCaptionContainer,
	VerticalContent,
	VerticalRiskBarContainer
} from './styles';

interface VerticalProps {
	risks: Risk[];
	options?: RiskBarOptions;
	total?: number;
}

export const Vertical: React.FC<VerticalProps> = ({ risks, options = defaultOptions, total }) => {
	const { handleFilterSelection } = useDashboardContext();
	return (
		<Container orientation={options.orientation}>
			<VerticalContent>
				<VerticalRiskBarContainer valueDisplay={options.value?.display}>
					{risks.map((risk) => {
						return (
							risk.percentage !== '0%' && (
								<RiskBarContainer
									percentage={risk.percentage}
									orientation={options.orientation}
									key={risk.id}
									valueDisplay={options.value?.display}
								>
									<RiskBarContent
										orientation={options.orientation}
										align={options.caption.align}
										percentage={risk.percentage}
										captionDisplay={options.caption?.display}
										valueDisplay={options.value?.display}
									>
										<RiskScale
											onClick={() => {
												handleFilterSelection({
													stressLevel: [risk.id!]
												});
											}}
											riskId={risk.position ?? risk.id}
											length={risks.length}
											orientation={options.orientation}
											color={risk.trailColor}
										/>
									</RiskBarContent>
								</RiskBarContainer>
							)
						);
					})}
				</VerticalRiskBarContainer>
				<VerticalCaptionContainer valueDisplay={options.value?.display}>
					{risks.map((risk) => {
						return (
							risk.percentage !== '0%' &&
							(risk.caption || risk.value) && (
								<RiskBarCaption
									orientation={options.orientation}
									captionDisplay={options.caption.display}
									valueDisplay={options.value?.display}
									key={risk.id}
								>
									{risk.caption && (
										<Caption
											style={{
												fontWeight: risk.trailColor === '#d6d6d6' ? 600 : 400
											}}
										>
											{I18n.get(risk.caption)}
										</Caption>
									)}
									{risk.value && (
										<Value
											style={{
												color: risk.fontColor,
												marginLeft: options.value?.display ? '0' : '2rem'
											}}
										>
											{total
												? `${Math.round(
														(Number(risk.value.toString().split('%')[0]) * total) / 100
												  )} (${risk.value})`
												: risk.value}
										</Value>
									)}
								</RiskBarCaption>
							)
						);
					})}
				</VerticalCaptionContainer>
			</VerticalContent>
		</Container>
	);
};
