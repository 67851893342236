import React from 'react';
import { Col } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CustomLabel } from '@/components/CustomLabel';
import { CustomFormItem, CustomRow } from './styles';
import { CustomIcon } from './CustomIcon';

interface VulnerabilityProps {
	vulnerability: string;
}

export const Vulnerability: React.FC<VulnerabilityProps> = ({ vulnerability }) => {
	return (
		<Col xs={24} sm={12} lg={24}>
			<CustomFormItem
				label={
					<CustomLabel
						icon={<CustomIcon title="What is the probability of an incident happening?" />}
						title="Vulnerability"
					/>
				}
				labelCol={{ span: 24 }}
			>
				<CustomRow>{I18n.get(vulnerability)}</CustomRow>
			</CustomFormItem>
		</Col>
	);
};
