import React from 'react';
import { NamePath } from 'antd/lib/form/interface';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { RiskDescriptionDTO } from '@/components/ui/Inputs/types/response/riskDescription';
import { RiskDescriptionSelect } from '@/components/ui/Inputs/RiskDescriptionSelect';
import { CustomFormItem } from './styles';

const { useFormInstance, useWatch } = Form;

interface RiskDescriptionsProps {
	reportId: string;
	isLoading: boolean;
	isFetching: boolean;
	fieldName: NamePath;
	collapseKey: string[];
	damagesFieldName: NamePath;
	riskDescriptions: RiskDescriptionDTO[];
}

export const RiskDescriptions: React.FC<RiskDescriptionsProps> = ({
	reportId,
	fieldName,
	isLoading,
	isFetching,
	collapseKey,
	damagesFieldName,
	riskDescriptions
}) => {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();

	const riskCategoryId = useWatch(['true', reportId, 'risk_category_id'], form);
	const riskDescriptionId = useWatch(fieldName, form);

	async function handleRiskDescriptionSelect(id: string) {
		setFieldValue(fieldName, id);
		setFieldValue(damagesFieldName, undefined);
		setFieldValue([...collapseKey, 'severity_id'], undefined);
	}

	async function handleClearRiskDescription() {
		setFieldValue(fieldName, undefined);
		setFieldValue(damagesFieldName, undefined);
		setFieldValue([...collapseKey, 'severity_id'], undefined);
	}

	return (
		<Col xs={24} sm={12} lg={24}>
			<CustomFormItem
				name={fieldName}
				label={I18n.get('Risk description')}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: true,
						message: I18n.get('Select the description')
					}
				]}
			>
				<RiskDescriptionSelect
					disabled={true}
					loading={isFetching}
					allowCreation={false}
					fieldName={fieldName}
					riskCategoryId={riskCategoryId}
					riskDescriptions={riskDescriptions}
					riskDescriptionId={riskDescriptionId}
					handleClearRiskDescription={handleClearRiskDescription}
					handleSelectRiskDescription={handleRiskDescriptionSelect}
				/>
			</CustomFormItem>
		</Col>
	);
};
