import { Ability, AbilityBuilder } from '@casl/ability';
import { roleEnum } from '@/utils/enum';
import store from '@/store';

const { ADMIN, MASTER, SUPERVISOR } = roleEnum;

const ability = new Ability();

store.subscribe(() => {
	let auth = store.getState().auth;
	ability.update(defineRulesFor(auth));
});

const defineRulesFor = (auth) => {
	// const permissions = auth.permissions;
	// const { can, rules } = new AbilityBuilder();

	// if (permissions) {
	//   permissions.forEach((p) => {
	//     let per = p.split("_");
	//     can(per[0], per[1]);
	//   });
	// }

	const user = auth?.user?.user;
	const { can, rules } = new AbilityBuilder();

	if (user?.role === ADMIN) {
		can('create', 'organization');
		can('create', 'company');
		can('custom-report', 'management');
		can('settings', 'management');
		can('settings', 'preferences');
		can('delete', 'upload');
		can('management', 'action_plans');
		can('settings', 'plan');
	}

	if (user?.role === MASTER) {
		can('delete', 'upload');
		can('create', 'organization');
		can('create', 'company');
		can('cancel', 'payment');
		can('management', 'users');
		can('custom-report', 'management');
		can('settings', 'management');
		can('settings', 'force_2fa');
		can('settings', 'preferences');
		can('management', 'action_plans');
		can('settings', 'plan');
	}

	if (user?.role === SUPERVISOR) {
		can('delete', 'upload');
	}

	// if (permissions) {
	//   permissions.forEach((p) => {
	//     let per = p.split("_");
	//     can(per[0], per[1]);
	//   });
	// }

	return rules;
};

const isAuthorized = (action, subject) => {
	return ability.can(action, subject);
};

export default isAuthorized;
