import { useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import Api from '@/services/api';
import type { BaseContext, CustomReportSubStepKeysDefaultRisk } from '@/types';

export type GetCustomReportSubStepKeyDefaultRiskDTO = BaseContext & {
	custom_report_sub_step_key_id?: string;
};

const getCustomReportSubStepKeysDefaultRisks = async (params: GetCustomReportSubStepKeyDefaultRiskDTO) => {
	const url = '/custom-report/sub-step-key/risks/default';
	let { data } = await Api.get<CustomReportSubStepKeysDefaultRisk>(url, {
		params
	});
	return data;
};

export const useGetCustomReportSubStepKeysDefaultRisks = ({
	company_id,
	organization_id,
	custom_report_sub_step_key_id
}: GetCustomReportSubStepKeyDefaultRiskDTO) => {
	const parameters = { organization_id, company_id, custom_report_sub_step_key_id };
	const report = useQuery<CustomReportSubStepKeysDefaultRisk, AxiosError<Response>>(
		['jds_d86_report', parameters],
		() => getCustomReportSubStepKeysDefaultRisks(parameters),
		{
			enabled: !!organization_id && !!company_id && !!custom_report_sub_step_key_id,
			retry: 0
		}
	);

	return {
		...report,
		data: report.data ?? ({} as CustomReportSubStepKeysDefaultRisk)
	};
};
