import React from 'react';
import {
	DeleteOutlined,
	DownloadOutlined,
	ExceptionOutlined,
	FileAddOutlined,
	MenuOutlined,
	PlusCircleOutlined
} from '@ant-design/icons';
import { Button, Col, Dropdown, Row, Tooltip } from 'antd';
import { I18n } from '@aws-amplify/core';

import { SeraSummaryList, SeraSummaryStatuses } from './hooks/types/response';
import { useApplicationContext } from '@/context/v1/Application/context';
import { ActionsInline, ActionsMenu, CustomButton } from './styles';
import { useDownloadPDF } from '../Sera/Results/hooks/hooks';

interface ActionsProps {
	rowData: SeraSummaryList;
	onDeleteActionClick: (id: string) => void;
	onEditActionClick: (rowData: SeraSummaryList) => void;
	onReviewActionClick: (rowData: SeraSummaryList) => void;
}

export const Actions: React.FC<ActionsProps> = ({
	rowData,
	onEditActionClick,
	onReviewActionClick,
	onDeleteActionClick
}) => {
	const { organization, company } = useApplicationContext();
	const { mutateAsync: downloadPDF, isLoading: downloadingPDF } = useDownloadPDF();

	const disableReview = rowData.status === SeraSummaryStatuses.UNCOMPLETED;
	const disableDownload = rowData.status === SeraSummaryStatuses.UNCOMPLETED;
	const actionTitle = rowData.status === SeraSummaryStatuses.UNCOMPLETED ? 'Edit' : 'Show';
	const browserLanguage = window.navigator.language ?? 'en-US';

	async function handleDownloadJobSummary(summaryData: SeraSummaryList) {
		if (summaryData.status !== SeraSummaryStatuses.UNCOMPLETED) {
			await downloadPDF({
				organization_id: organization?.id,
				company_id: company?.id,
				sera_summary_id: summaryData.id,
				locale: browserLanguage,
				review: rowData.last_review
			});
		}
	}

	const items = [
		{
			key: 1,
			icon: <FileAddOutlined />,
			label: (
				<Button type="link" onClick={() => onEditActionClick(rowData)}>
					{I18n.get(actionTitle)}
				</Button>
			)
		},
		{
			key: 2,
			icon: <ExceptionOutlined />,
			label: (
				<Button disabled={disableReview} type="link" onClick={() => onReviewActionClick(rowData)}>
					{I18n.get('Create review')}
				</Button>
			)
		},
		{
			key: 3,
			icon: <DownloadOutlined />,
			label: (
				<Button
					disabled={disableDownload}
					loading={downloadingPDF}
					type="link"
					onClick={() => handleDownloadJobSummary(rowData)}
				>
					{I18n.get('Download')}
				</Button>
			)
		},
		{
			key: 4,
			danger: true,
			icon: <DeleteOutlined />,
			label: (
				<CustomButton>
					<Button type="link" onClick={() => onDeleteActionClick(rowData.id)}>
						{I18n.get('Delete')}
					</Button>
				</CustomButton>
			)
		}
	];

	return (
		<Row align="middle" justify="center">
			<ActionsInline xs={24}>
				<Row align="middle" justify="center">
					<Col span={6}>
						<Button
							onClick={() => {
								onEditActionClick(rowData);
							}}
							type="link"
							size="large"
							icon={
								<Tooltip overlayStyle={{ position: 'fixed' }} title={I18n.get(actionTitle)}>
									<ExceptionOutlined />
								</Tooltip>
							}
						/>
					</Col>
					<Col span={6}>
						<Button
							disabled={disableReview}
							onClick={() => {
								onReviewActionClick(rowData);
							}}
							type="link"
							size="large"
							icon={
								<Tooltip overlayStyle={{ position: 'fixed' }} title={I18n.get('Create review')}>
									<PlusCircleOutlined />
								</Tooltip>
							}
						/>
					</Col>
					<Col span={6}>
						<Button
							disabled={disableDownload}
							onClick={() => handleDownloadJobSummary(rowData)}
							type="link"
							size="large"
							loading={downloadingPDF}
							icon={
								<Tooltip overlayStyle={{ position: 'fixed' }} title={I18n.get('Download')}>
									<DownloadOutlined />
								</Tooltip>
							}
						/>
					</Col>
					<Col span={6}>
						<Button
							onClick={() => {
								onDeleteActionClick(rowData.id);
							}}
							type="link"
							size="large"
							icon={
								<Tooltip overlayStyle={{ position: 'fixed' }} title={I18n.get('Delete')}>
									<DeleteOutlined style={{ color: '#E74150' }} />
								</Tooltip>
							}
						/>
					</Col>
				</Row>
			</ActionsInline>
			<ActionsMenu xs={2}>
				<Dropdown menu={{ items }} trigger={['click']}>
					<Button type="link" icon={<MenuOutlined />} />
				</Dropdown>
			</ActionsMenu>
		</Row>
	);
};
