import React from 'react';
import { Col, Row, Tabs } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Chart } from './Chart';
import { ColCustom } from './styles';
import type { SubStepKey } from '@/hooks';
import { DefaultSelects } from '../DefaultSelects';
import { useStepKeysContext } from '../DefaultSelects/context';
import { useEwaJdsD86Context } from '@/components/views/EwaJdsD86/context';
import { PreviousValues } from '../PreviousValues';
import { BodyPadding } from '@/components/ui/CollapseCheck/styles';
import { GetCustomReportResultStepKeyDefaultDataResponse } from '@/hooks/useGetCustomReportResultStepKeyDefaultData';
interface SubStepKeyProps {
	subStepKeys: SubStepKey[];
	customReportDefaultData?: GetCustomReportResultStepKeyDefaultDataResponse | null;
	gettingCustomReportDefaultData?: boolean;
}

const memberToTitleMapper: { [key: string]: string } = {
	neck: 'Neck',
	trunk: 'Trunk',
	left_knee: 'Left knee',
	right_knee: 'Right knee',
	left_elbow: 'Left lower arm',
	right_elbow: 'Right lower arm',
	left_shoulder: 'Left upper arm',
	right_shoulder: 'Right upper arm'
};

export const SubStepKeys: React.FC<SubStepKeyProps> = ({ subStepKeys, customReportDefaultData, gettingCustomReportDefaultData }) => {
	const { ewaJdsD86 } = useEwaJdsD86Context();
	const { formFieldName, stepKey, handleChangeSubStepKey } = useStepKeysContext();

	function handleChangeSubStepKeyTab(key: string) {
		handleChangeSubStepKey(subStepKeys[Number(key)].id)
	}

	function renderRebaChart(subStepKey: SubStepKey): React.ReactNode {
		const { tools } = ewaJdsD86;
		const reba = tools?.reba;
		if (!!reba && reba.data) {
			const currentScore = reba.data
				.flatMap((data) => data)
				.find(({ name }) => name === memberToTitleMapper[subStepKey?.name]);

			return (
				<>
					<div style={{ margin: '-1rem 0rem 0rem' }}>
						<PreviousValues negative={true} />
					</div>
					<BodyPadding>
						<DefaultSelects
							customReportDefaultData={customReportDefaultData}
							gettingCustomReportDefaultData={gettingCustomReportDefaultData}
							disableExposure={true}
							stepKeyId={stepKey.id}
							subStepKeyId={subStepKey.id}
							formFieldName={[...formFieldName, subStepKey.id]}
						>
							<Row justify="start" align="middle" gutter={[0, 15]}>
								<Col span={24}>
									<Chart scores={currentScore?.scores} formFieldName={[...formFieldName, subStepKey.id]} />
								</Col>
							</Row>
						</DefaultSelects>
					</BodyPadding>
				</>
			);
		}
	}

	return (
		<Row>
			<ColCustom span={24}>
				<Tabs
					defaultActiveKey="0"
					type="card"
					onChange={handleChangeSubStepKeyTab}
					items={subStepKeys.map((subStepKey, index) => {
						return {
							forceRender: true,
							key: String(index),
							label: I18n.get(subStepKey?.description),
							children: renderRebaChart(subStepKey)
						};
					})}
				/>
			</ColCustom>
		</Row>
	);
};
