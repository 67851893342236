import React, { useEffect, useState } from 'react';
import { Col, Divider, Form, Row, Skeleton } from 'antd';
import { Title } from '@/components/Typography';
import { useParams } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';

import { FileForCustomReport } from '@/components/ui/Inputs/types/response/file';
import { useApplicationContext } from '@/context/v1/Application/context';

import { KimManualHandlingResult } from './KimManualHandling';
import { useBeraJobStepsContext } from '../../../context';
import { StrainIndexResult } from './StrainIndex';
import { Informations } from './Informations';
import { useBeraReportResult } from './hooks';
import { PayloadDownloadPDF } from './types';
import { DownloadPDF } from './DownloadPDF';
import { ResultsContainer } from './styles';
import { NioshResult } from './Niosh';
import { Comment } from './Comment';
import { Header } from './Header';
import { Steps } from './Steps';

const { useFormInstance } = Form;

interface BeraResultProps {
	file: FileForCustomReport;
}

export type BeraResultUrlParams = {
	bera_job_summary_id: string;
};

export const BeraResult: React.FC<BeraResultProps> = ({ file }) => {
	const [isEditingComment, setIsEditingComment] = useState(false);

	const { bera_job_summary_id } = useParams<BeraResultUrlParams>();
	const { organization, company } = useApplicationContext();
	const { beraJobSummaryId } = useBeraJobStepsContext();
	const { setFieldValue } = useFormInstance();

	const { data, isLoading, isError } = useBeraReportResult({
		file_id: file.id,
		company_id: company?.id,
		organization_id: organization?.id,
		bera_job_summary_id: bera_job_summary_id ?? beraJobSummaryId
	});

	const browserLanguage = window.navigator.language ?? 'en-US';

	const payloadDownloadPDF: PayloadDownloadPDF = {
		organization_id: organization?.id,
		company_id: company?.id,
		id: data?.bera_report_id,
		file_id: file.id,
		locale: browserLanguage,
		bera_job_summary_id: bera_job_summary_id ?? beraJobSummaryId
	};

	useEffect(() => {
		const comment = data?.cycle?.comment;
		if (comment) {
			setFieldValue('comment', comment);
		}
	}, [data]);

	if (isError) {
		return (
			<Row justify="center">
				<Col span={24}>
					<span>{I18n.get('Oops... Something went wrong!')}</span>
				</Col>
			</Row>
		);
	}

	function handleEditComment(editingComment: boolean) {
		setIsEditingComment(editingComment);
	}

	return (
		<Row justify="center">
			<Col span={24} style={{ marginBottom: '2rem' }}>
				<Title level={4}>Result</Title>
			</Col>
			<Col span={24}>
				<ResultsContainer>
					<Header />
					<Divider type="horizontal" />

					<Informations isLoading={isLoading} organization={data?.organization} cycle={data?.cycle} />
					<Divider type="horizontal" />

					{isLoading ? (
						<Skeleton active />
					) : (
						data?.bera_steps_result?.map((step) => (
							<Row key={step.id}>
								<Col span={24}>
									<Steps stepName={step.description} stepKeys={step.step_key} />
									<Divider type="horizontal" />
								</Col>
							</Row>
						))
					)}

					{isLoading ? (
						<Skeleton active />
					) : (
						!!data?.tools?.niosh && (
							<>
								<NioshResult niosh={data?.tools.niosh} />
								<Divider type="horizontal" />
							</>
						)
					)}
					{isLoading ? (
						<Skeleton active />
					) : (
						!!data?.tools?.kim_mho && (
							<>
								<KimManualHandlingResult kim_mho={data?.tools.kim_mho} />
								<Divider type="horizontal" />
							</>
						)
					)}
					{isLoading ? (
						<Skeleton active />
					) : (
						!!data?.tools?.strain_index && (
							<>
								<StrainIndexResult strain_index={data?.tools.strain_index} />
								<Divider type="horizontal" />
							</>
						)
					)}

					<Comment
						isLoading={isLoading}
						onEditComment={handleEditComment}
						bera_report_id={data?.bera_report_id}
						consolidated={data?.cycle?.consolidated}
					/>
					<DownloadPDF
						parameters={payloadDownloadPDF}
						consolidated={data?.cycle?.consolidated}
						disableButtons={isEditingComment || isLoading}
					/>
				</ResultsContainer>
			</Col>
		</Row>
	);
};
