import { useMutation } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import type { AxiosError } from 'axios';
import { message } from 'antd';

import Api from '@/services/api';
import type { Response, BaseContext } from '@/types';

export type DownloadPDFHistoryDTO = BaseContext & {
	organization_id: string,
	company_id: string,
	locale: string,
	review_id: string
};

export type DownloadHistoryPDFResponse = {
	url: string;
};

async function downloadHistoryPDF(payload: DownloadPDFHistoryDTO): Promise<DownloadHistoryPDFResponse> {
	const url = '/custom-report/review/history/pdf';
	const { data } = await Api.post<DownloadHistoryPDFResponse>(url, payload);
	return data;
}

export const useDownloadCustomReportHistoryPDF = () => {
	return useMutation<DownloadHistoryPDFResponse, AxiosError<Response>, DownloadPDFHistoryDTO>((body) => downloadHistoryPDF(body), {
		onError: (err) => {
			message.error(I18n.get(err?.response?.data?.message ?? err?.message));
		},
		onSuccess: (data) => {
			let archive = document.createElement('a');
			archive.target = '_blank';
			archive.href = data.url;
			archive.click();
			archive.remove();
		}
	});
};
