import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Col, Collapse, Form, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import moment, { Moment } from 'moment';
import _ from 'lodash';

import { FilterRequest } from '../hooks/types/request';
import { Workstations } from './Workstations';
import { UploadedAt } from './UploadedAt';
import { ReportType } from './ReportType';
import { Companies } from './Companies';
import { FileName } from './FileName';
import { Sectors } from './Sectors';
import { Status } from './Status';
import { Lines } from './Lines';

const { useForm } = Form;

const { Panel } = Collapse;

export type FormFields = {
	status?: string;
	line_id?: string;
	sector_id?: string;
	original_name?: string;
	uploaded_at?: Moment[];
	report_type_id?: string;
	workstation_id?: string;
	company_id_filter?: string;
};

interface FilterProps {
	initialValues: FormFields;
	onFilterClick(filterData: FilterRequest): void;
}

export const Filter: React.FC<FilterProps> = ({ onFilterClick, initialValues }) => {
	const [form] = useForm();
	const history = useHistory();
	const { search } = useLocation();
	const [disabled, setDisabled] = useState(true);
	const searchParams = new URLSearchParams(search);

	function handleFieldsChange(_changedFields: any, allFields: any[]) {
		const allFieldsEmpty = allFields?.filter((field) => !!field.value);
		if (allFieldsEmpty.length === 0) {
			setDisabled(true);
			return;
		}
		setDisabled(false);
	}

	function handleClear() {
		history.push({
			pathname: '/custom-reports/files',
			search: '?cleared=true'
		});
		form.resetFields();
	}

	function handleFinish(values: FormFields) {
		const {
			status,
			line_id,
			sector_id,
			uploaded_at,
			original_name,
			report_type_id,
			workstation_id,
			company_id_filter
		} = values;
		const params: FilterRequest = {
			status,
			line_id,
			sector_id,
			original_name,
			workstation_id,
			report_type_id,
			company_id_filter,
			created_at_end: undefined,
			created_at_start: undefined
		};

		if (uploaded_at) {
			const [start_date, end_date] = uploaded_at;
			const start = moment(start_date).format();
			const end = moment(end_date).format();
			params.created_at_start = start;
			params.created_at_end = end;
		}

		updateURLParams(params);

		onFilterClick(params);
	}

	function updateURLParams(params: FilterRequest) {
		for (const key in params) {
			searchParams.delete('cleared');
			searchParams.delete(key);
			if (Object.hasOwn(params, key)) {
				const typedKey: keyof FilterRequest = key as any;
				const param = params[typedKey];
				if (param && !_.isArray(param)) {
					searchParams.set(key, param);
				} else if (_.isArray(param)) {
					searchParams.set(key, JSON.stringify([...param]));
				}
			}
		}

		history.push({
			pathname: '/custom-reports/files',
			search: searchParams.toString()
		});
	}

	return (
		<Collapse defaultActiveKey={search ? '1' : '0'}>
			<Panel key={'1'} header={I18n.get('Filter')}>
				<Form
					form={form}
					layout="vertical"
					onFinish={handleFinish}
					initialValues={initialValues}
					onFieldsChange={handleFieldsChange}
				>
					<Col xs={24}>
						<Row gutter={8}>
							<Companies />
							<Sectors />
							<Lines />
							<Workstations />
						</Row>
					</Col>
					<Col xs={24}>
						<Row gutter={8}>
							<FileName />
							<UploadedAt />
							<Status />
							<ReportType />
						</Row>
					</Col>
					<Row gutter={8} style={{ paddingTop: '20px' }}>
						<Col sm={4}>
							<Button
								type="primary"
								htmlType="submit"
								disabled={disabled}
								icon={<SearchOutlined />}
								style={{ width: '100%' }}
							>
								{I18n.get('Search')}
							</Button>
						</Col>
						<Col sm={4}>
							<Button style={{ width: '100%' }} onClick={handleClear}>
								{I18n.get('Clear')}
							</Button>
						</Col>
					</Row>
				</Form>
			</Panel>
		</Collapse>
	);
};
