import { I18n } from '@aws-amplify/core';
import { message, notification } from 'antd';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { JdsD92ListRequest, DeleteJdsD92Request, DownloadFileRequest, DownloadPdfRequest } from './types/request';
import { JdsD92ListDTO } from './types/response';
import { JdsD92Service } from './services';

const jdsD92Service = new JdsD92Service();

export function useJdsD92List({ custom_report_name, filter }: JdsD92ListRequest) {
	const enabled = !!custom_report_name;
	const summaryList = useQuery(
		['jds_d92_list', [custom_report_name, filter]],
		() => jdsD92Service.getJdsD92List({ custom_report_name, filter }),
		{
			enabled,
			retry: false
		}
	);
	return {
		...summaryList,
		data: summaryList.data ?? ({} as JdsD92ListDTO)
	};
}

export const useDeleteReportJdsD92 = () => {
	const queryClient = useQueryClient();
	return useMutation(({ body }: { body: DeleteJdsD92Request }) => jdsD92Service.deleteReportJdsD92(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0].split('" ');
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['jds_d92_list']);
			queryClient.invalidateQueries(['jds_d92_reviews']);
			queryClient.invalidateQueries(['jds_d92_files'], { refetchType: 'inactive' });
			message.success(I18n.get('JDS D92 deleted successfully'));
		}
	});
};

export const useDownloadFile = () => {
	return useMutation((body: DownloadFileRequest) => jdsD92Service.downloadFile(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0].split('" ');
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: (data) => {
			const archive = document.createElement('a');
			archive.target = '_blank';
			archive.href = data;
			archive.click();
			archive.remove();
		}
	});
};

export const useDownloadPdf = () => {
	return useMutation((body: DownloadPdfRequest) => jdsD92Service.downloadPdf(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0].split('" ');
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: (data) => {
			const archive = document.createElement('a');
			archive.target = '_blank';
			archive.href = data;
			archive.click();
			archive.remove();
		}
	});
};
