import React, { useState } from 'react';
import { Row, Col } from 'antd';

import { Header } from './Header';
import { usePlan } from '@/hooks/usePlan';
import { CreateOrganization } from './CreateOrganization';
import { OrganizationsTable } from './OrganizationsTable';

export const Organizations: React.FC = () => {
	const { data } = usePlan();
	const [open, setOpen] = useState<boolean>(false);

	function onShowCreateModal(): void {
		setOpen(true);
	}

	function onCloseCreateModal(): void {
		setOpen(false);
	}

	return (
		<Row gutter={[0, 10]}>
			<Col span={24}>
				<Header onClick={onShowCreateModal} isDisabled={!!data?.specialPlan} />
			</Col>
			<Col span={24}>
				<OrganizationsTable />
			</Col>
			<Col span={24}>
				<CreateOrganization open={open} onClose={onCloseCreateModal} />
			</Col>
		</Row>
	);
};
