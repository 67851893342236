import Api from '@/services/api';

import {
	BeraReportRequest,
	BeraReportResultDTO,
	URLDownloadDTO,
	UpdateBeraResultCommentRequest,
	UpdateBeraResultConsolidatedRequest
} from './types';
import { BeraJobResultDTO } from '../../../Results/types';

export class BeraResultService {
	public async getBeraReportResult({
		organization_id,
		company_id,
		file_id,
		bera_job_summary_id
	}: BeraReportRequest): Promise<BeraReportResultDTO> {
		const url = `/bera/report/result/${file_id}?bera_job_summary_id=${bera_job_summary_id}&organization_id=${organization_id}&company_id=${company_id}`;
		const { data } = await Api.get<BeraReportResultDTO>(url);
		return data;
	}

	public async downloadPDF(payload: any) {
		const url = `/bera/report/download-pdf/${payload.id}`;
		const { data } = await Api.post<URLDownloadDTO>(url, payload);
		return data;
	}

	public async consolidatePDF({
		organization_id,
		company_id,
		id,
		bera_report_data
	}: UpdateBeraResultConsolidatedRequest) {
		const body = {
			organization_id,
			company_id,
			id,
			bera_report_data
		};
		const url = `/bera/report/${id}`;
		const { data } = await Api.put<URLDownloadDTO>(url, body);
		return data;
	}

	public async saveComments({ organization_id, company_id, id, bera_report_data }: UpdateBeraResultCommentRequest) {
		const body = {
			organization_id,
			company_id,
			id,
			bera_report_data
		};
		const url = `/bera/report/${id}`;
		const { data } = await Api.put<BeraJobResultDTO>(url, body);
		return data;
	}
}
