import React from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { Col, Descriptions, Form, Row } from 'antd';

import { Text } from '@/components/Typography';
import { useGetDownloadVideoURL } from '@/hooks';
import { useJdsD92Context } from '../../../../context';
import { useApplicationContext } from '@/context/v1/Application/context';
import { CustomImageSkeleton } from '@/components/CustomReports/styles';

const { Item } = Descriptions;
const { useFormInstance } = Form;

export const VideoContainer = () => {
	const { organization, company } = useApplicationContext();
	const { jdsD92, file_id } = useJdsD92Context();
	const { getFieldValue } = useFormInstance();
	const file = getFieldValue('file') || {};

	const update_date = moment(file?.createdAt || jdsD92.informations.uploaded_date).format('L');
	const original_name = file?.original_name || jdsD92.informations.original_name;

	const {
		data: video,
		isInitialLoading: gettingVideo,
		isError: errorGettingVideo
	} = useGetDownloadVideoURL({
		file_id: file?.id || file_id,
		company_id: company?.id,
		organization_id: organization?.id,
		original_name: original_name
	});

	if (gettingVideo) {
		return (
			<Row justify="center">
				<CustomImageSkeleton active />
			</Row>
		);
	}

	function renderVideoContent() {
		if (errorGettingVideo) {
			return (
				<Row justify="center">
					<Text>Oops... Something went wrong!</Text>
				</Row>
			);
		}
		return (
			<Row justify="center">
				<Col span={24}>
					<video style={{ borderRadius: '15px', margin: '1rem 0' }} loop width="100%" controls>
						<source src={video} type="video/webm" />
					</video>
				</Col>
			</Row>
		);
	}
	return (
		<Col xs={24} lg={18} xxl={12}>
			{renderVideoContent()}
			<Row style={{ marginTop: '1rem' }}>
				<Descriptions column={1} size="small">
					<Item label={I18n.get('File')} labelStyle={{ fontWeight: 'bold' }}>
						{original_name}
					</Item>
					<Item label={I18n.get('Date')} labelStyle={{ fontWeight: 'bold' }}>
						{update_date}
					</Item>
				</Descriptions>
			</Row>
		</Col>
	);
};
