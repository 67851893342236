import { I18n } from '@aws-amplify/core';
import { Form, Input } from 'antd';
import React from 'react';

type WorkstationProps = {
	organizationId: string;
	companyId: string;
};

export function Workstation({ organizationId, companyId }: WorkstationProps) {
	return (
		<Form.Item name="workstation">
			<Input disabled={!organizationId || !companyId} placeholder={I18n.get('Workstation')} />
		</Form.Item>
	);
}
