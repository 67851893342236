import React, { useContext } from 'react';

import { I18n } from '@aws-amplify/core';
import { Form, Input } from 'antd';
import { MergeAEPContext } from '../../context';

export const Workstation: React.FC = () => {
	const { organizationId, companyId, setWorkstation, queryParams, setQueryParams } = useContext(MergeAEPContext);

	function handleWorkstationChange(workstation: string) {
		setWorkstation(workstation);
		setQueryParams({
			...queryParams,
			workstation
		});
	}

	return (
		<Form.Item name="workstation">
			<Input
				disabled={!organizationId || !companyId}
				placeholder={I18n.get('Workstation')}
				onChange={(event) => handleWorkstationChange(event.target.value)}
			/>
		</Form.Item>
	);
};
