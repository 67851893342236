import React, { useState } from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useLocation } from 'react-router-dom';
import { FieldData } from 'rc-field-form/lib/interface';

import { FilterForm } from './Form';
import { Collapse } from './Components';
import { FilterRequestDTO, FormFields } from './types/request';

const { useForm } = Form;

interface FilterProps {
	loading: boolean;
	initialValues: FormFields;
	onFilterClick(filterData: FilterRequestDTO): void;
	onClearFilter: () => {};
}

export const Filter: React.FC<FilterProps> = ({ onFilterClick, loading, initialValues, onClearFilter }) => {
	const [form] = useForm();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const queryParams = convertURLParamToObject(searchParams);
	const [filter, setFilter] = useState<FilterRequestDTO | undefined>(queryParams);
	const [disabled, setDisabled] = useState<boolean>(true);

	async function handleOnFinish(): Promise<void> {
		const values = await form.validateFields();
		setFilter(values);
		onFilterClick(values);
	}

	function convertURLParamToObject(searchParams: URLSearchParams) {
		return Object.fromEntries(searchParams.entries());
	}

	function handleOnClear(): void {
		const filterValues = form.getFieldsValue();
		const isExistFields = Object.keys(filterValues).find((elem) => filterValues[elem]);
		isExistFields && filter && onClearFilter();
		form.resetFields();
		setDisabled(true);
	}

	function handleOnFieldsChange(_changedFields: FieldData[], allFields: FieldData[]): void {
		const allFieldsEmpty = allFields?.filter((field) => !!field.value);
		if (allFieldsEmpty.length === 0) {
			setDisabled(true);
			return;
		}
		setDisabled(false);
		setFilter(undefined);
	}

	return (
		<Collapse header={I18n.get('Filter')} isOpen={!!search}>
			<FilterForm
				form={form}
				isLoading={loading}
				isDisabled={disabled}
				onClear={handleOnClear}
				onFinish={handleOnFinish}
				initialValues={initialValues}
				onFieldsChange={handleOnFieldsChange}
			/>
		</Collapse>
	);
};
