import { useMutation } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import type { AxiosError } from 'axios';
import { message } from 'antd';

import Api from '@/services/api';
import type { Response, BaseContext } from '@/types';

export type DownloadPDFDTO = BaseContext & {
	locale: string;
	file_id: string;
	report_id: string;
	selected_pdf_sections: string[];
	custom_report_result_id?: string;
};

export type DownloadPDFResponse = {
	url: string;
};

async function downloadPDF(payload: DownloadPDFDTO): Promise<DownloadPDFResponse> {
	const url = '/custom-report/result/pdf';
	const { data } = await Api.post<DownloadPDFResponse>(url, payload);
	return data;
}

export const useDownloadCustomReportPDF = () => {
	return useMutation<DownloadPDFResponse, AxiosError<Response>, DownloadPDFDTO>((body) => downloadPDF(body), {
		onError: (err) => {
			message.error(I18n.get(err?.response?.data?.message ?? err?.message));
		},
		onSuccess: (data) => {
			let archive = document.createElement('a');
			archive.target = '_blank';
			archive.href = data.url;
			archive.click();
			archive.remove();
		}
	});
};
