import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useApplicationContext } from '@/context/v1/Application/context';
import { Paragraph, Title } from '@/components/Typography';
import { useGetReviewsJdsD86 } from '../hooks/hooks';
import { LoadingSkeleton } from './LoadingSkeleton';
import CustomTable from './CustomTable';
import { ModalStyled } from './styles';

interface ModalReviewsProps {
	id: string;
	open: boolean;
	onSelect: (type: string) => void;
	onCancel: () => void;
}

export function ModalReviews({ id, open, onCancel }: ModalReviewsProps) {
	const { organization, company } = useApplicationContext();

	const { data, isLoading, isFetching } = useGetReviewsJdsD86({
		organization_id: organization?.id,
		company_id: company?.id,
		id,
		open
	});

	return (
		<ModalStyled
			centered
			open={open}
			width={1118}
			footer={null}
			title={<Title level={3}>{I18n.get('Revaluations')}</Title>}
			onCancel={onCancel}
		>
			<Row justify="space-between">
				<Col>
					<Paragraph strong>{I18n.get('Version')}</Paragraph>
				</Col>
				<Col>&nbsp;</Col>
				<Col xs={8} style={{ margin: 0 }}>
					<Row justify="center">
						<Paragraph strong>File</Paragraph>
					</Row>
				</Col>
				<Col>&nbsp;</Col>
				<Col xs={4}>
					<Row justify="center">
						<Paragraph strong>Evaluator</Paragraph>
					</Row>
				</Col>
				<Col>&nbsp;</Col>
				<Col xs={3}>
					<Row justify="center">
						<Paragraph strong>Date</Paragraph>
					</Row>
				</Col>
				<Col>&nbsp;</Col>
				<Col xs={4}>
					<Row justify="center">
						<Paragraph strong>Risk</Paragraph>
					</Row>
				</Col>
				<Col>&nbsp;</Col>
				<Col xs={1} />
			</Row>
			<LoadingSkeleton loading={isFetching || isLoading} />
			<CustomTable data={data} />
		</ModalStyled>
	);
}
