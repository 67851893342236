import React from 'react';
import { Title } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';
import { Col, Divider, Row } from 'antd';

import { ReviewTask, SeraResultChangedField } from '../hooks/types';
import { useSeraStepsContext } from '../../context';
import { Description, RPNScore } from './styles';

interface FieldsComparisonProps {
	task: ReviewTask;
	fields: SeraResultChangedField[];
}

export const FieldsComparison: React.FC<FieldsComparisonProps> = ({ task, fields }) => {
	const { mapRPNToPriority } = useSeraStepsContext();
	const { color: reviewedColor, priority: reviewedPriority } = mapRPNToPriority(task.review_rpn);
	const { color: previousColor, priority: previousPriority } = mapRPNToPriority(task.previous_rpn);

	function mapFieldsComparison(value: string | string[]) {
		return Array.isArray(value) ? value?.map((field) => I18n.get(field) + `; `) : I18n.get(value);
	}

	function renderFieldsChanged(): React.ReactNode {
		return fields.map(({ field_name, previous_field_value, reviewed_field_value }) => {
			return (
				<Col xs={24} key={task.id} style={{ marginBottom: '1rem' }}>
					<Row>
						<Col style={{ height: '50px' }}>
							<Divider style={{ height: '100%' }} type="vertical" />
						</Col>
						<Col span={7}>
							<Row align="middle">
								<Title style={{ margin: '0 0 0.75rem 0', fontSize: '0.8rem' }}>
									{I18n.get('Changed field')}
								</Title>
							</Row>
							<Row align="middle">
								<Description>{I18n.get(field_name)}</Description>
							</Row>
						</Col>
						<Col style={{ height: '50px' }}>
							<Divider style={{ height: '100%' }} type="vertical" />
						</Col>
						<Col span={7}>
							<Row align="middle">
								<Title style={{ margin: '0 0 0.75rem 0', fontSize: '0.8rem' }}>
									{I18n.get('Original value')}
								</Title>
							</Row>
							<Row align="middle">
								<Description>{mapFieldsComparison(previous_field_value)}</Description>
							</Row>
						</Col>
						<Col style={{ height: '50px' }}>
							<Divider style={{ height: '100%' }} type="vertical" />
						</Col>
						<Col span={7}>
							<Row align="middle">
								<Title style={{ margin: '0 0 0.75rem 0', fontSize: '0.8rem' }}>
									{I18n.get('Reviewed value')}
								</Title>
							</Row>
							<Row align="middle">
								<Description>{mapFieldsComparison(reviewed_field_value)}</Description>
							</Row>
						</Col>
					</Row>
				</Col>
			);
		});
	}

	return (
		<Row>
			<Col xs={24}>
				<Row align="middle" style={{ marginTop: '1rem' }}>
					{fields?.length > 0 && renderFieldsChanged()}
				</Row>
			</Col>
			<Col xs={24}>
				<Row align="middle">
					<Col xs={24}>
						<Row justify="end">
							<Col xs={10} style={{ marginRight: '1rem' }}>
								<Title level={5}>{I18n.get('RPN and Priority')}</Title>
							</Col>
						</Row>
						<Row justify="end">
							<Col xs={24}>
								<Row justify="end" align="middle">
									{(!fields || fields.length === 0) && (
										<Col span={10}>{I18n.get('No fields were changed')}</Col>
									)}
									<Col style={{ height: '50px' }}>
										<Divider style={{ height: '100%' }} type="vertical" />
									</Col>
									<Col span={5}>
										<Row align="middle">
											<Title style={{ margin: 0, fontSize: '0.8rem' }}>
												{I18n.get('Original')}
											</Title>
										</Row>
										<Row align="middle">
											<RPNScore priority={previousColor}>{task.previous_rpn}</RPNScore>
											<RPNScore priority={previousColor}>{I18n.get(previousPriority)}</RPNScore>
										</Row>
									</Col>
									<Col style={{ height: '50px' }}>
										<Divider style={{ height: '100%' }} type="vertical" />
									</Col>
									<Col span={5}>
										<Row align="middle">
											<Title style={{ margin: 0, fontSize: '0.8rem' }}>
												{I18n.get('Reviewed')}
											</Title>
										</Row>
										<Row align="middle">
											<RPNScore priority={reviewedColor}>{task.review_rpn}</RPNScore>
											<RPNScore priority={reviewedColor}>{I18n.get(reviewedPriority)}</RPNScore>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
