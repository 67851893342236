import { I18n } from '@aws-amplify/core';
import { Col, Divider, Form, Row } from 'antd';
import React from 'react';

import { TaskDTO } from '@/components/ui/Inputs/types/response/task';
import { useSeraStepsContext } from '../../context';
import { RPNScore, Title } from './styles';

const { useFormInstance, useWatch } = Form;

interface TaskCollapseHeaderProps {
	collapseKey: number;
	tasks: TaskDTO[];
}

export const TaskCollapseHeader: React.FC<TaskCollapseHeaderProps> = ({ collapseKey, tasks }) => {
	const form = useFormInstance();
	const { mapRPNToPriority } = useSeraStepsContext();

	const taskId = useWatch([collapseKey, 'task_id'], form);
	const taskRPN = form.getFieldValue([collapseKey, 'taskRPN']);
	const rpnPriority = mapRPNToPriority(taskRPN?.rpn);

	const task = tasks.find((task) => task.id === taskId);

	const taskName = task?.name;

	return (
		<Row align="middle">
			<Col style={{ marginLeft: '0.75rem' }} xs={17}>
				<Title>{I18n.get('TLID')}:</Title> {taskName}
			</Col>
			<Divider type="vertical" style={{ height: '2rem', borderColor: '#00000033', margin: '0rem 0.5rem' }} />
			<Col xs={6} style={{ margin: 0 }}>
				<Row align="middle" gutter={[8, 8]}>
					<Col>
						<Title style={{ margin: 0 }}>{I18n.get('Task RPN')}:</Title>
					</Col>
					<Col>
						<RPNScore style={{ margin: 0 }} priority={rpnPriority?.color}>
							{taskRPN?.rpn}
						</RPNScore>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
