import styled from 'styled-components';
import { Button, Col } from 'antd';

export const ActionsInline = styled(Col)`
	display: none;
	@media (min-width: 768px) {
		display: flex;
		justify-content: space-between;
	}
`;

export const ActionsMenu = styled(Col)`
	display: flex;
	@media (min-width: 768px) {
		display: none;
	}
`;

export const CustomButton = styled.div`
	&:hover {
		span {
			color: white;
		}
	}
`;

export const CustomDownloadButton = styled(Button)`
	padding: 0;
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	span {
		display: inline;
	}
`;

export const CustomTable = styled(Col)`
	.ant-table-tbody {
		background-color: #f0f2f599;
		border: 1px solid red;
	}
`;
