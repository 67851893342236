import { VehiclesImages } from '../../constants';

export const vehicles = [
	{
		id: 0,
		src: VehiclesImages.Barrow1,
		style: { width: '100px' }
	},
	{
		id: 1,
		src: VehiclesImages.Barrow2,
		style: { width: '75px' }
	},
	{
		id: 2,
		src: VehiclesImages.Barrow3,
		style: { width: '75px' }
	},
	{
		id: 3,
		src: VehiclesImages.Carriage1,
		style: { width: '100px' }
	},
	{
		id: 4,
		src: VehiclesImages.Carriage2,
		style: { width: '100px' }
	},
	{
		id: 5,
		src: VehiclesImages.Carriage3,
		srcExtra: VehiclesImages.Carriage4,
		style: { width: '100px', height: '130px', marginRight: '5px' }
	},
	{
		id: 6,
		src: VehiclesImages.Carriage3,
		srcExtra: VehiclesImages.Carriage4,
		srcExtraTwo: VehiclesImages.Carriage5,
		style: { width: '60px', height: '90px', marginRight: '5px' }
	},
	{
		id: 7,
		src: VehiclesImages.Carriage6,
		style: { width: '100px' }
	},
	{
		id: 8,
		src: VehiclesImages.Conveyor,
		style: { width: '100px' }
	},
	{
		id: 9,
		src: VehiclesImages.Crane,
		style: { width: '100px', marginTop: '8px' }
	}
];
