import React from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { useCreateCategory } from './hooks';
import { I18n } from '@aws-amplify/core';
import { useApplicationContext } from '@/context/v1/Application/context';

const { useForm } = Form;

interface CreateCategoryProps {
	open: boolean;
	stepKeyId: string;
	onClose: () => void;
}

export const CreateCategory: React.FC<CreateCategoryProps> = ({ open, stepKeyId, onClose }) => {
	const [form] = useForm();

	const { organization, company } = useApplicationContext();
	const { mutateAsync: createCategory } = useCreateCategory();

	async function handleSubmit() {
		const values = await form.validateFields();
		const created = await createCategory({
			company_id: company?.id,
			description: values?.name,
			organization_id: organization?.id,
			custom_report_step_key_id: stepKeyId
		});

		if (created?.id) {
			onClose();
			form.resetFields();
		}
	}

	return (
		<Modal
			open={open}
			onCancel={onClose}
			title={I18n.get('Create new risk category')}
			footer={[
				<Button key="cancel" onClick={onClose}>
					{I18n.get('Cancel')}
				</Button>,
				<Button key="save" type="primary" onClick={handleSubmit}>
					{I18n.get('Save')}
				</Button>
			]}
		>
			<Form form={form} layout="vertical" name="create_category">
				<Form.Item
					name="name"
					label={I18n.get('Name')}
					rules={[
						{
							required: true,
							message: I18n.get('Please enter a valid name')
						}
					]}
				>
					<Input placeholder={I18n.get('Name')} />
				</Form.Item>
			</Form>
		</Modal>
	);
};
