import React, { ReactElement } from 'react';
import { NamePath } from 'antd/lib/form/interface';
import { I18n } from '@aws-amplify/core';
import { InputNumberProps } from 'antd';

import { CustomFormItem } from '@/components/CustomReports/styles';
import { RequiredLabel } from '@/components/CustomReports';

interface TextInputProps {
	title: string;
	namePath: NamePath;
	message?: string;
	children?: ReactElement<InputNumberProps>;
}

export const TextInput: React.FC<TextInputProps> = ({ title, namePath, message, children }) => {
	return (
		<CustomFormItem
			label={<RequiredLabel title={title} />}
			labelCol={{ span: 24 }}
			name={namePath}
			rules={[
				{
					required: true,
					message: ''
				},
				({ getFieldValue }) => ({
					validator() {
						if (getFieldValue(['characteristics', 'total_working_population']) === 0) {
							return Promise.reject(I18n.get(message));
						}

						return Promise.resolve();
					}
				})
			]}
		>
			{children}
		</CustomFormItem>
	);
};
