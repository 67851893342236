import React, { useState } from 'react';
import { Button, Col, Form, Input, Modal, Row } from 'antd';

import { Title } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';

import { useTFAContext } from './context';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

export const ConfirmPasswordModal = () => {
	const [password, setPassword] = useState('');

	const { openGenerateTokenModal, openActionModal, openDeactivateTokenModal } = useTFAContext();
	const {
		handleConfirmPassword,
		handleOpenActionModal,
		handleOpenGenerateTokenModal,
		handle2FAChecked,
		handleCorrectPassword,
		handleOpenDeactivateTokenModal
	} = useTFAContext();

	function handlePasswordChange(value: string) {
		setPassword(value);
	}

	function handleClose() {
		handle2FAChecked();
		handleCorrectPassword(false);
		handleOpenActionModal(false);
		handleOpenGenerateTokenModal(false);
		handleOpenDeactivateTokenModal(false);
	}

	async function handleConfirmPasswordClick() {
		const user_string = localStorage.getItem('info_user') || '';
		const user = JSON.parse(user_string)?.user;
		if (user) {
			await handleConfirmPassword(user.email, password);
		}
	}

	return (
		<Modal
			open={openGenerateTokenModal || openActionModal || openDeactivateTokenModal}
			onCancel={handleClose}
			width={350}
			footer={false}
			centered
		>
			<Form layout="vertical" style={{ minHeight: '100px' }}>
				<Row align="middle" gutter={[32, 32]}>
					<Col span={24}>
						<Row gutter={[16, 16]} justify="center">
							<Col>
								<Title level={4}>Confirm password</Title>
							</Col>
						</Row>
					</Col>

					<Col span={24}>
						<Row align="middle" justify="center">
							<Form.Item name="password">
								<Input.Password
									type="password"
									placeholder={I18n.get('Password')}
									iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
									onChange={(event) => handlePasswordChange(event.target.value)}
								/>
							</Form.Item>
						</Row>
					</Col>

					<Col span={24}>
						<Row style={{ float: 'right' }}>
							<Button
								disabled={password.length === 0}
								onClick={handleConfirmPasswordClick}
								type="primary"
								htmlType="submit"
							>
								{I18n.get('Confirm')}
							</Button>
						</Row>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};
