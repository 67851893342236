import React from 'react';
import { Col, Form, InputNumber, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useLibertyMutualSubStepsContext } from '../../context';
import { SystemOfUnits } from '../../context/types';

export const ObjectWeight = () => {
	const { libertyMutual, decimalSeparator } = useLibertyMutualSubStepsContext();

	const systemOfUnitsToSymbolMapper: SystemOfUnits = {
		metric: 'kg',
		imperial: 'lb'
	};

	return (
		<Row>
			<Col xs={24}>
				<Form.Item
					name={['liberty_mutual_inputs', 'object_weight']}
					label={`${I18n.get('Object weight')} (${
						systemOfUnitsToSymbolMapper[libertyMutual?.system_of_units?.name]
					})`}
					labelCol={{ span: 24 }}
					rules={[
						{
							required: true,
							message: I18n.get('Insert an object weight')
						}
					]}
				>
					<InputNumber min={0.1} placeholder="0" decimalSeparator={decimalSeparator} />
				</Form.Item>
			</Col>
		</Row>
	);
};
