import Api from '@/services/api';

import { JdsD92ListRequest, DeleteJdsD92Request, DownloadFileRequest, DownloadPdfRequest } from './types/request';
import { JdsD92ListDTO } from './types/response';

export class JdsD92Service {
	async getJdsD92List({ custom_report_name, filter }: JdsD92ListRequest): Promise<JdsD92ListDTO> {
		try {
			const url = `/custom-report/result?custom_report_name=${custom_report_name}`;
			const { data } = await Api.get<JdsD92ListDTO>(url, {
				params: filter,
			});

			return data;
		} catch (error) {
			return { count: 0, rows: [] } as JdsD92ListDTO;
		}
	}

	async deleteReportJdsD92({ organization_id, company_id, id }: DeleteJdsD92Request): Promise<void> {
		const url = `/custom-report/result/${id}?organization_id=${organization_id}&company_id=${company_id}`;
		await Api.delete<void>(url);
	}


	async downloadPdf({ company_id, organization_id, file_id, custom_report_result_id, locale, report_id, selected_pdf_sections }: DownloadPdfRequest): Promise<string> {
		const url = '/custom-report/result/pdf';
		const { data } = await Api.post<string>(url, {
			file_id: file_id,
			organization_id,
			company_id,
			custom_report_result_id,
			locale,
			report_id,
			selected_pdf_sections,
		});
		return data;
	}

	async downloadFile({ company_id, organization_id, file_id, original_name }: DownloadFileRequest): Promise<string> {
		const url = '/upload/create-url-download';
		const { data } = await Api.post<string>(url, {
			file_id,
			organization_id,
			company_id,
			original_name,
		});
		return data;
	}
}
