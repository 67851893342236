import React, { useState } from 'react';
import { ExclamationCircleOutlined, StopOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import { useApplicationContext } from '@/context/v1/Application/context';
import { OrganizationDTO } from '@/components/ui/Inputs/types/response';
import { useDeleteOrganization, useGetOrganizations } from './hooks';
import { ManageOrganizationModal } from './ManageOrganizationModal';
import { EditOrganization } from './EditOrganization';
import { FilterOptions } from './Filter/types';
import { Actions } from './Actions';
import { Filter } from './Filter';
import { ManageOrganizationProvider } from './ManageOrganizationModal/context';

export const OrganizationsTable: React.FC = () => {
	const { company, organization } = useApplicationContext();

	const [limit, setLimit] = useState<number>(10);
	const [offset, setOffset] = useState<number>(0);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [filter, setFilter] = useState<FilterOptions | null>(null);
	const [editingOrganizationId, setEditingOrganizationId] = useState<string>('');
	const [openManageOrganizationModal, setOpenManageOrganizationModal] = useState<boolean>(false);
	const [organizationClicked, setOrganizationClicked] = useState<OrganizationDTO>({} as OrganizationDTO);

	const {
		data: organizations,
		isFetching: gettingOrganizations,
		isError: errorGettingOrganizations
	} = useGetOrganizations({
		limit,
		offset,
		filter,
		company_id: company?.id,
		organization_id: organization?.id
	});

	const { mutateAsync: deleteOrganization } = useDeleteOrganization();

	if (errorGettingOrganizations) {
		return <div>{I18n.get('Unable to show results')}</div>;
	}

	function handleManageOrganizationModal(organization: OrganizationDTO) {
		setOrganizationClicked(organization);
		setOpenManageOrganizationModal(!openManageOrganizationModal);
	}

	function handleIsEditing(editing: boolean) {
		setIsEditing(editing);
	}

	function updateOrganization(e: React.MouseEvent<HTMLElement, MouseEvent>, id: string) {
		e.stopPropagation();
		e.preventDefault();

		const editingOrganization = organizations.rows.find((organization) => organization?.id === id);

		if (editingOrganization) {
			setIsEditing(true);
			setEditingOrganizationId(id);
		}
	}

	async function destroyOrganization(e: React.MouseEvent<HTMLElement, MouseEvent>, id: string) {
		e.stopPropagation();
		e.preventDefault();

		const content = I18n.get(
			'All industrial sites in this company will be deleted, along with their respective uploads and reports. Do you wish to proceed?'
		);

		Modal.confirm({
			title: I18n.get('Warning!'),
			icon: <ExclamationCircleOutlined />,
			content: content,
			okType: 'danger',
			okText: I18n.get('Yes'),
			cancelText: I18n.get('Cancel'),
			onOk: async () => {
				await deleteOrganization({ organization_id: id });
			}
		});
	}

	const columns: ColumnsType<OrganizationDTO> = [
		{
			title: I18n.get('Name'),
			dataIndex: 'name',
			key: 'name',
			align: 'center',
			width: '300px',
			sorter: (a, b) => a.name.localeCompare(b.name),
			render: (name: string, organization) => {
				return isEditing && organization?.id === editingOrganizationId ? (
					<EditOrganization
						onEditing={handleIsEditing}
						organization={organization}
						organizations={organizations.rows}
					/>
				) : (
					name
				);
			}
		},
		{
			title: I18n.get('Total Industrial Sites'),
			dataIndex: 'companies',
			key: 'companies',
			align: 'center',
			render: (companies: string[]) => companies?.length || 0,
			sorter: (a, b) => (a.companies ? a.companies.length : 0) - (b.companies ? b.companies.length : 0)
		},
		{
			title: I18n.get('Date of creation'),
			dataIndex: 'created_at',
			key: 'created_at',
			align: 'center',
			render: (created_at: string) => moment(created_at).format('L'),
			sorter: (a, b) => moment(a.created_at).valueOf() - moment(b.created_at).valueOf()
		},
		{
			title: I18n.get('Actions'),
			dataIndex: 'url',
			key: 'url',
			align: 'center',
			render: (_, organization) =>
				organization.isActive ? (
					<Actions
						isEditing={isEditing}
						organization={organization}
						onUpdate={updateOrganization}
						onDelete={destroyOrganization}
						organizations={organizations.rows}
						onManageOrganizationModal={() => handleManageOrganizationModal(organization)}
					/>
				) : (
					<Tooltip
						overlayStyle={{ position: 'fixed' }}
						title={I18n.get('Please contact support to reactivate it')}
					>
						<Button type="text">
							{I18n.get('Inactive')}
							<StopOutlined style={{ marginLeft: '8px' }} />
						</Button>
					</Tooltip>
				)
		}
	];

	function handleFilter(filters: FilterOptions | null) {
		setFilter(filters);
	}

	return (
		<Row gutter={[0, 10]}>
			<Col span={24}>
				<Filter onFilter={handleFilter} />
			</Col>
			<Col span={24}>
				<Table
					rowKey="id"
					loading={gettingOrganizations}
					dataSource={organizations.rows}
					columns={columns}
					pagination={{
						hideOnSinglePage: false,
						showSizeChanger: true,
						total: organizations.count,
						current: offset === 0 ? offset + 1 : offset,
						defaultPageSize: limit,
						pageSizeOptions: ['5', '10', '20', '30', '50', '100'],
						pageSize: limit,
						onChange: (page) => {
							setOffset(page);
						},
						onShowSizeChange: (_, size) => setLimit(size)
					}}
				/>
			</Col>
			{openManageOrganizationModal && (
				<ManageOrganizationProvider>
					<ManageOrganizationModal
						organization={organizationClicked}
						isModalOpen={openManageOrganizationModal}
						onModalOpen={setOpenManageOrganizationModal}
					/>
				</ManageOrganizationProvider>
			)}
		</Row>
	);
};
