import React, { useState } from 'react';
import { Col, Form, Row, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';

import { useCreateReport, useUpdateReportInputs, useUpdateSelectedTask } from './hooks';
import { UpdateReportInputs, UpdateSelectedTask } from './hooks/types/request';
import { LibertyMutualReportDTO, TaskName } from './hooks/types/response';
import { useApplicationContext } from '@/context/v1/Application/context';
import { CreateOrUpdate, StepToMethodMapper } from './context/types';
import { useLibertyMutualSubStepsContext } from './context';
import { LoadingSkeleton } from './LoadingSkeleton';
import { StepsNavigation } from './StepsNavigation';
import { CustomFormContainer } from './styles';
import { BackButton } from './BackButton';
import { Header } from './Header';
import { Footer } from './Footer';

const { useFormInstance } = Form;

export const Report = () => {
	const history = useHistory();
	const { organization, company } = useApplicationContext();
	const { validateFields, getFieldValue } = useFormInstance();
	const {
		file_id,
		subSteps,
		gettingReport,
		libertyMutual,
		custom_report,
		handleUpdateReport,
		liberty_mutual_report_id
	} = useLibertyMutualSubStepsContext();

	const { mutateAsync: createReport, isLoading: creatingReport } = useCreateReport();
	const { mutateAsync: updateTask, isLoading: updatingTask } = useUpdateSelectedTask();
	const { mutateAsync: updateInputs, isLoading: updatingInputs } = useUpdateReportInputs();

	const [currentStep, setCurrentStep] = useState<number>(libertyMutual.step ?? 0);

	async function handleStepChange(step: number) {
		if (step < currentStep) {
			setCurrentStep(step);
			return;
		}
		await createOrUpdate(step);
	}

	async function createOrUpdate(step: number) {
		try {
			const values = await validateFields();
			if (values) {
				const body: CreateOrUpdate = setBodyData(values);

				const stepToMethodMapper: StepToMethodMapper = {
					0: (body) => {
						if (isUpdateSelectedTask(body)) {
							return createReportOrUpdateTask(body);
						}
					},
					1: (body) => {
						if (isUpdateInputs(body)) {
							return updateInputs(body);
						}
					}
				};

				const report = await stepToMethodMapper[currentStep](body);
				if (report?.id) {
					let url = `/reporting/report/liberty-mutual/${file_id}/${liberty_mutual_report_id}/${report.id}`;
					if (custom_report) {
						url = `${url}?custom_report=${custom_report}`;
					}
					history.push(url);
					handleUpdateReport(report);
					setCurrentStep(step);
				}
			}
		} catch (error: any) {
			showErrorMessage(error);
		}
	}

	function setBodyData(values: any): CreateOrUpdate {
		return {
			...values,
			file_id,
			company_id: company?.id,
			report_id: libertyMutual?.id,
			organization_id: organization?.id,
			task_name: getFieldValue(['task_name']),
			liberty_mutual_report_id: liberty_mutual_report_id,
			system_of_units_id: libertyMutual?.system_of_units?.id
		};
	}

	function showErrorMessage(error: any) {
		let message = "Some required steps wasn't filled";
		const liberty_mutual_inputs = error?.values?.liberty_mutual_inputs;
		message = checkInputsValue(liberty_mutual_inputs, message);

		notification.error({
			message: I18n.get('Ops... something happened!'),
			description: I18n.get(message),
			duration: 6
		});
	}

	function checkInputsValue(liberty_mutual_inputs: any, message: string) {
		if (libertyMutual.task?.name === TaskName.lift) {
			if (isStartHigherThanEnd(liberty_mutual_inputs)) {
				message =
					'Data entered for Start hand height and End hand height indicates that the task type is Lower, not Lift';
			}
		} else if (libertyMutual.task?.name === TaskName.lower) {
			if (isEndHigherThanStart(liberty_mutual_inputs)) {
				message =
					'Data entered for Start hand height and End hand height indicates that the task type is Lift, not Lower';
			}
		}
		return message;
	}

	function isStartHigherThanEnd(liberty_mutual_inputs: any) {
		return liberty_mutual_inputs?.start_hand_height > liberty_mutual_inputs?.end_hand_height;
	}

	function isEndHigherThanStart(liberty_mutual_inputs: any) {
		return liberty_mutual_inputs?.start_hand_height < liberty_mutual_inputs?.end_hand_height;
	}

	function createReportOrUpdateTask(body: any): Promise<LibertyMutualReportDTO> {
		return libertyMutual?.id ? updateTask(body) : createReport(body);
	}

	function isUpdateSelectedTask(body: CreateOrUpdate): body is UpdateSelectedTask {
		return (body as UpdateSelectedTask).task_id !== undefined;
	}

	function isUpdateInputs(body: CreateOrUpdate): body is UpdateReportInputs {
		return (body as UpdateReportInputs).liberty_mutual_inputs !== undefined;
	}

	const loading = creatingReport || updatingTask || updatingInputs;

	if (loading) {
		return <LoadingSkeleton loading={loading} />;
	}

	return (
		<Row justify="center">
			<Col xs={24} xxl={20}>
				<Row justify="center">
					<Col xs={6} lg={4} xxl={3} style={{ marginTop: '2rem' }}>
						<StepsNavigation currentStep={currentStep} onStepChange={handleStepChange} />
					</Col>
					<Col xs={18}>
						<Row justify="start" gutter={[16, 24]}>
							<Col xs={24}>
								<Header />
								<BackButton />
							</Col>
							<CustomFormContainer xs={24}>
								{!gettingReport && subSteps[currentStep]?.component}
								<Footer currentStep={currentStep} onStepChange={handleStepChange} />
							</CustomFormContainer>
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
