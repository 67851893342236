import React from 'react';
import { NamePath } from 'antd/lib/form/interface';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { EvaluatorSelect } from '@/components/ui/Inputs/EvaluatorSelect';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useEvaluators } from '@/hooks/v1';

const { useFormInstance, useWatch } = Form;

export const Evaluators: React.FC = () => {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();
	const { company, organization } = useApplicationContext();

	const fieldName: NamePath = 'evaluator_id';

	const companyId = useWatch('company_id', form);
	const evaluatorId = useWatch(fieldName, form);

	const {
		data: evaluators,
		isLoading,
		isFetching,
		isError
	} = useEvaluators({
		organization_id: organization?.id,
		company_id: company?.id
	});

	function handleEvaluatorSelect(id: string) {
		setFieldValue(fieldName, id);
	}

	function handleClearEvaluators() {
		setFieldValue(fieldName, undefined);
	}

	return (
		<Col xs={24} sm={12}>
			<Form.Item name={fieldName} label={I18n.get('Evaluator')} labelCol={{ span: 24 }}>
				<EvaluatorSelect
					loading={isFetching}
					allowCreation={true}
					fieldName={fieldName}
					evaluators={evaluators}
					evaluatorId={evaluatorId}
					handleSelectEvaluator={handleEvaluatorSelect}
					handleClearEvaluators={handleClearEvaluators}
					disabled={isLoading || isFetching || isError || !companyId}
				/>
			</Form.Item>
		</Col>
	);
};
