import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'antd';

import { Title } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';

import { ValidationCode, ValidateInput, ValidateContainer } from './styles';
import { useCheck2FAContext } from '../context';
import { QRCode } from './styles';
import history from '@/store/history';

const { useForm } = Form;

export const Validate2FA = () => {
	const [form] = useForm();

	const { type, QRCodeUrl, validate2FALoading } = useCheck2FAContext();
	const {
		handleValidate2FA,
		handleOpenCheck2FAModal,
		handleOpenReset2FAModal,
		handleOpenValidate2FAModal,
		handleOpenRecoveryTokensModal
	} = useCheck2FAContext();

	const [validationCode, setValidationCode] = useState('');

	function handleValidationCodeChange(code: string) {
		setValidationCode(code);
	}

	async function handleValidateClick() {
		const { validated, tokens_array } = await handleValidate2FA(type, validationCode);
		if (validated) {
			handleOpenCheck2FAModal(false);
			handleOpenReset2FAModal(false);
			handleOpenValidate2FAModal(false);
			if (tokens_array.length > 1) {
				handleOpenRecoveryTokensModal(true);
			} else {
				history.push('/login');
			}
		}
	}

	return (
		<Form form={form}>
			<ValidateContainer align="middle" justify="center">
				<Col span={18}>
					<Row justify="center">
						<Col span={20}>
							<Row align="middle" justify="center">
								<Title align="center" level={4}>
									<QRCode alt="QRCode" preview={false} src={QRCodeUrl} />
								</Title>
							</Row>
							<Row align="middle" justify="center">
								<ValidationCode
									name="validation_code"
									rules={[
										() => ({
											validator() {
												return validationCode.length !== 6
													? Promise.reject(I18n.get('Enter the 6 digit code.'))
													: Promise.resolve();
											}
										})
									]}
								>
									<ValidateInput
										maxLength={6}
										max={6}
										placeholder={I18n.get('Validation code')}
										onChange={(event) => handleValidationCodeChange(event.target.value)}
									/>
								</ValidationCode>
							</Row>
						</Col>
					</Row>
				</Col>
				<Col span={18}>
					<Row justify="center">
						<Button
							disabled={validationCode.length !== 6}
							loading={validate2FALoading}
							onClick={handleValidateClick}
							htmlType="submit"
							type="primary"
						>
							{I18n.get('Validate')}
						</Button>
					</Row>
				</Col>
			</ValidateContainer>
		</Form>
	);
};
