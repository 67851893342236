import React from 'react';
import { Row } from 'antd';

import { useLibertyMutualSubStepsContext } from '../context';
import { Container, CustomSteps } from './styles';
import { StepIcon } from './StepIcon';

interface StepsNavigationProps {
	currentStep: number;
	onStepChange(step: number): void;
}

export const StepsNavigation: React.FC<StepsNavigationProps> = ({ onStepChange, currentStep }) => {
	const { subSteps } = useLibertyMutualSubStepsContext();

	const color = '#2f54eb';

	const mappedSteps = subSteps.map((item, index) => {
		item.icon = (
			<StepIcon
				key={item.title}
				name={item.title}
				position={index}
				currentStep={currentStep}
				onStepChange={onStepChange}
			/>
		);
		return item;
	});

	return (
		<Row justify="center">
			<Container xs={24}>
				<CustomSteps
					size="small"
					color={color}
					items={mappedSteps}
					direction="vertical"
					current={currentStep}
				/>
			</Container>
		</Row>
	);
};
