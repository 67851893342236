import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form, Row, Select } from 'antd';
import Hooks from '../../hooks';
import { useApplicationContext } from '@/context/v1/Application/context';

const { useGetSectors } = Hooks;

interface FilterBySectorProps {
	onSectorChange: (sector: string) => void;
}

export const FilterBySector: React.FC<FilterBySectorProps> = ({ onSectorChange }) => {
	const { organization, company } = useApplicationContext();
	const { isLoading, data, isError } = useGetSectors(organization.id, company.id);

	return (
		<Row gutter={[16, 8]}>
			<Col span={24}>
				<span>{I18n.get('Sector')}</span>
			</Col>
			<Col span={24}>
				<Form.Item name="sector">
					<Select
						showSearch
						allowClear
						loading={isLoading}
						onChange={onSectorChange}
						placeholder={I18n.get('Sector')}
						disabled={data.length === 0 || isError}
						options={data.map(({ id, name }) => ({ label: name, value: id }))}
						filterOption={(input, option) =>
							(option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
						}
					/>
				</Form.Item>
			</Col>
		</Row>
	);
};
