import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Divider } from 'antd';
import * as Icons from '@ant-design/icons';
import styled from 'styled-components';

import weightImg from '@/assets/img/ergonomic_tools/weight.png';

const HandIcons = styled.div`
	display: flex;
	margin-block: 4px;
`;

const Item = styled.div`
	height: 100px;
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	margin-inline: 10px;
	margin-bottom: 12px;
`;

const ItemImage = styled.img`
	height: 50px;
	opacity: 0.5;
`;

const iconStyle = { color: 'grey', fontSize: '3rem', marginBottom: '6px' };

export function ReferenceWeight({ formattedReport }) {
	const { gender, age, hands, workers, report_type } = formattedReport;

	const labels = {
		report_type: {
			NIOSH: 'NIOSH',
			ISO_11228: 'ISO 11228-1'
		},
		gender: {
			MALE: 'Man',
			FEMALE: 'Woman'
		},
		age: {
			LESS_THAN_20: '< 20 years old',
			BETWEEN_20_AND_45: '20 to 45 years old',
			MORE_THAN_45: '> 45 years old'
		},
		workers: {
			1: '1 worker',
			2: '2 workers',
			3: '3 workers'
		},
		hands: {
			1: 'only one hand',
			2: 'both hands'
		}
	};

	return (
		<Row justify="center">
			<Item>
				{report_type === 'ISO_11228' && (
					<>
						{gender === 'MALE' ? (
							<Icons.ManOutlined style={{ ...iconStyle, color: 'lightblue' }} />
						) : (
							<Icons.WomanOutlined
								style={{ ...iconStyle, color: 'lightpink' }}
							/>
						)}
						<span>{`${I18n.get(labels.gender[gender])}, ${I18n.get(
							labels.age[age]
						)}`}</span>
					</>
				)}
			</Item>
			<Item>
				{workers > 1 ? (
					<Icons.TeamOutlined style={iconStyle} />
				) : (
					<Icons.UserOutlined style={iconStyle} />
				)}
				<span>
					{I18n.get('Lifted by ')}
					{I18n.get(labels.workers[workers])}
				</span>
			</Item>
			<Item>
				<HandIcons>
					{parseInt(hands) === 2 && (
						<Icons.LikeOutlined
							style={{
								...iconStyle,
								fontSize: '2.3rem',
								transform: 'rotate(90deg) scaleX(-1)'
							}}
						/>
					)}
					<Icons.LikeOutlined
						style={{
							...iconStyle,
							fontSize: '2.3rem',
							transform: 'rotate(270deg)'
						}}
					/>
				</HandIcons>
				<span>
					{I18n.get('Using')} {I18n.get(labels.hands[hands])}
				</span>
			</Item>
			<Divider type="vertical" style={{ height: 100, marginInline: 20 }} />
			<Item>
				<ItemImage src={weightImg} style={iconStyle} />
				<span>
					<strong>
						{`${I18n.get('Reference weight')}: ${parseFloat(
							formattedReport.reference_weight
						)} kg`}
					</strong>
				</span>
				<span>{labels.report_type[report_type]}</span>
			</Item>
		</Row>
	);
}
