import * as Icons from '@ant-design/icons';
import { Row, Typography, Button } from 'antd';
import styled, { css } from 'styled-components';

import { riskLevelToColorMapper } from '@/components/views/EwaJdsD86/context/types';

const { Title, Text } = Typography;

export const riskLevelList: { [key: string]: number } = {
	low: 1,
	moderate: 2,
	high: 3,
	very_high: 4,
	extreme: 5
};

const darkColor = '#4C4C4E';
const emptyColor = '#B3B3B3';

export const Container = styled(Row)<{ $levelRisk: number }>`
	background: ${({ $levelRisk }) => ($levelRisk ? riskLevelToColorMapper[$levelRisk] : emptyColor)};
	width: 95%;
	border-radius: 5px;
	padding: 5%;
`;

export const stylesIcon = css<{ $isModerate?: boolean }>`
	color: ${({ $isModerate }) => ($isModerate ? darkColor : 'white')};
	font-size: 20;
`;

export const styleModerate = css<{ $levelRisk: number }>`
	color: ${({ $levelRisk }) => ($levelRisk === riskLevelList.moderate ? darkColor : 'white')} !important;
`;

export const CircleFilledIcon = styled(Icons.CheckCircleFilled)`
	${stylesIcon}
`;

export const ExclamationFilledIcon = styled(Icons.ExclamationCircleFilled)`
	${stylesIcon}
`;

export const WarningFilledIcon = styled(Icons.WarningFilled)`
	${stylesIcon}
`;

export const TitleCard = styled(Title)`
	${styleModerate}
`;

export const ResumeCard = styled(Text)`
	${styleModerate}
`;

export const ButtonCard = styled(Button)<{ $levelRisk: number }>`
	float: right;

	span {
		color: ${({ $levelRisk }) =>
			$levelRisk === riskLevelList.moderate ? darkColor : riskLevelToColorMapper[$levelRisk]};
	}
`;
