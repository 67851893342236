export enum Gender {
	MALE = 'MALE',
	FEMALE = 'FEMALE'
}

export enum Age {
	LESS_THAN_20 = 'LESS_THAN_20',
	BETWEEN_20_AND_45 = 'BETWEEN_20_AND_45',
	MORE_THAN_45 = 'MORE_THAN_45'
}

export enum Coupling {
	POOR = 'POOR',
	FAIR = 'FAIR',
	GOOD = 'GOOD'
}

export enum Duration {
	LESS_THAN_1H = 'LESS_THAN_1H',
	BETWEEN_1_AND_2H = 'BETWEEN_1_AND_2H',
	BETWEEN_2_AND_8H = 'BETWEEN_2_AND_8H'
}

export enum Risk {
	LOW = 'LOW',
	HIGH = 'HIGH',
	VERY_LOW = 'VERY_LOW',
	MODERATE = 'MODERATE',
	VERY_HIGH = 'VERY_HIGH'
}

export enum ReportType {
	NIOSH = 'NIOSH',
	ISO_11228 = 'ISO_11228'
}

export type Niosh = {
	id: string;
	frequency: number;
	mass_m: number;
	recommended_weight_limit: number;
	dc_factor: number;
	vc_factor: number;
	h_factor: number;
	a_factor: number;
	coupling_factor: number;
	frequency_factor: number;
	one_handed_factor: number;
	multiple_workers_factor: number;
	lifting_index: number;
	workers: number;
	hands: number;
	distance_dc: number;
	distance_vc: number;
	distance_h: number;
	angle_a: number;
	reference_weight: number;
	gender: Gender;
	age: Age;
	coupling: Coupling;
	duration: Duration;
	risk: Risk;
	collection_date: Date;
	report_type: ReportType;
	comment: string;
	is_active: boolean;
	file_id: string;
	report_user_id: string;
	createdAt: Date;
	updatedAt: Date;
};
