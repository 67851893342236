import React from 'react';
import { Col, Row } from 'antd';

import { BeraJobSteps } from './BeraJobSteps';
import { BeraJobStepsProvider } from './context';

export const BeraJob: React.FC = () => {
	return (
		<Row>
			<Col xs={24}>
				<BeraJobStepsProvider>
					<BeraJobSteps />
				</BeraJobStepsProvider>
			</Col>
		</Row>
	);
};
