import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Row } from 'antd';
import { Check, Close, Title, Container, Description, Exclamation, InsideContainer } from './styles';

export const Details = ({ reportData }) => {
	const posture_options = {
		posture1: {
			text_one: 'Trunk upright or slightly inclined forward, no twisting',
			text_two: 'Force application height can be selected freely',
			text_three: 'No hindrance for the legs'
		},
		posture2: {
			text_one:
				'Body inclined towards the direction of movement or slight twisting when pulling the load on one side',
			text_two: 'Fixed force application height ranging from 0.9 – 1.2 m',
			text_three: 'No or only slight hindrance for the legs',
			text_four: 'Predominantly pulling'
		},
		posture3: {
			text_one:
				'Awkward body postures caused by fixed force application height < 0.9 or > 1.2 m , lateral force application on one side or significantly obstructed view',
			text_two: 'Significant hindrance for the legs',
			text_three: 'Constant twisting and/or lateral inclination of the trunk'
		}
	};

	return (
		<Row gutter={[20, 20]} justify="center" style={{ marginBottom: '40px' }}>
			<Container sm={10}>
				<Title level={4}>{I18n.get('Driveway conditions')}</Title>
				{reportData?.vehicle_input < 8 ? (
					<InsideContainer>
						<Col>
							{reportData?.driveway_conditions_input < 2 ? (
								<Check />
							) : reportData?.driveway_conditions_input < 4 ? (
								<Exclamation />
							) : (
								<Close />
							)}
						</Col>
						<Col sm={21} offset={1}>
							<Description>{I18n.get(reportData?.labels.driveway_conditions.description)}</Description>
						</Col>
					</InsideContainer>
				) : (
					<InsideContainer>
						<Col>
							<Check />
						</Col>
						<Col sm={21} offset={1}>
							<Description>
								{I18n.get('Driveway conditions are not applied for the selected vehicle')}
							</Description>
						</Col>
					</InsideContainer>
				)}
			</Container>
			<Container sm={10} offset={1}>
				<Title level={4}>{I18n.get('Unfavorable vehicle properties')}</Title>
				{reportData?.labels.properties.map((option, index) => (
					<InsideContainer key={index}>
						<Col sm={2}>
							{reportData?.properties[index] === 1 ? (
								<Close />
							) : reportData?.properties[index] === 4 ? (
								<Check />
							) : (
								<Exclamation />
							)}
						</Col>
						<Col sm={21} offset={1}>
							<Description>{I18n.get(option.description)}</Description>
						</Col>
					</InsideContainer>
				))}
			</Container>
			<Container sm={10}>
				<Title level={4}>{I18n.get('Unfavorable working conditions')}</Title>
				{reportData?.labels.working_conditions.map((option, index) => (
					<InsideContainer key={index}>
						<Col sm={2}>
							{reportData?.working_conditions[index] === 6 ? (
								<Check />
							) : reportData?.working_conditions[index] <= 3 ? (
								<Close />
							) : (
								<Exclamation />
							)}
						</Col>
						<Col sm={21} offset={1}>
							<Description>{I18n.get(option.description)}</Description>
						</Col>
					</InsideContainer>
				))}
			</Container>
			<Container sm={10} offset={1}>
				<Title level={4}>{I18n.get('Posture')}</Title>
				<InsideContainer>
					<Col sm={2}>
						{reportData?.posture === 0 ? (
							<Check />
						) : reportData?.posture === 1 ? (
							<Exclamation />
						) : (
							<Close />
						)}
					</Col>
					<Col sm={21} offset={1}>
						<Description>
							{I18n.get(posture_options[reportData?.labels.posture.value].text_one)}
						</Description>
					</Col>
				</InsideContainer>
				<InsideContainer>
					<Col sm={2}>
						{reportData?.posture === 0 ? (
							<Check />
						) : reportData?.posture === 1 ? (
							<Exclamation />
						) : (
							<Close />
						)}
					</Col>
					<Col sm={21} offset={1}>
						<Description>
							{I18n.get(posture_options[reportData?.labels.posture.value].text_two)}
						</Description>
					</Col>
				</InsideContainer>
				<InsideContainer>
					<Col sm={2}>
						{reportData?.posture === 0 ? (
							<Check />
						) : reportData?.posture === 1 ? (
							<Exclamation />
						) : (
							<Close />
						)}
					</Col>
					<Col sm={21} offset={1}>
						<Description>
							{I18n.get(posture_options[reportData?.labels.posture.value].text_three)}
						</Description>
					</Col>
				</InsideContainer>
				{posture_options[reportData?.labels.posture.value].text_four ? (
					<InsideContainer>
						<Col sm={2}>
							{reportData?.posture === 0 ? (
								<Check />
							) : reportData?.posture === 1 ? (
								<Exclamation />
							) : (
								<Close />
							)}
						</Col>
						<Col sm={21} offset={1}>
							<Description>
								{I18n.get(posture_options[reportData?.labels.posture.value].text_four)}
							</Description>
						</Col>
					</InsideContainer>
				) : (
					''
				)}
			</Container>
		</Row>
	);
};
