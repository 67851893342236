import { AdditionalItem } from '@/hooks/useGetLastStepKeyResult'
import { Col, Divider, Row } from 'antd';
import React from 'react'
import { Topic } from './styles';
import { Text } from '@/components/Typography';
import { riskColors } from '../../ConditionCard';
import { CardStyled } from '../../styles';
import { ConditionalCard } from './ConditionalCard';
import { I18n } from '@aws-amplify/core';

type Props = {
	items: AdditionalItem[]
}

export const AdditionalItems = ({ items }: Props) => {
	const showItemConditional = items.length > 1
	const spanItemsCols = Math.floor(24 / items[0]?.values?.length);
	const spanRowCols = showItemConditional ? { xxl: 23, xs: 22 } : { xxl: 24, xs: 24 };
	const maxResult = items.reduce((max, item) => Math.max(max, item.result), 0);

	return (
		<React.Fragment>
			<Divider />
			{items.map(additionalItem =>
				<Row>
					<Col {...spanRowCols}>
						<Row>
							{additionalItem.values.map(item =>
								<Col span={spanItemsCols}>
									<Topic>
										<Text className="title">{I18n.get(item.title)}{item.unit && ` (${item.unit})`}</Text>
										<Text className="value">{I18n.get(item.value)}</Text>
									</Topic>
								</Col>
							)}
						</Row>
					</Col>
					{showItemConditional && <Col>
						<CardStyled $backgroundColor={riskColors[additionalItem.result]} risk={additionalItem.result} style={{ width: 29, height: 54, borderRadius: 10, }} />
					</Col>}
				</Row>
			)}
			<div style={{ maxWidth: 320 }}>
				<ConditionalCard result={maxResult} />
			</div>
		</React.Fragment>
	)
}
