import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Button, notification } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import Api from '@/services/api';
import Logo from '@/assets/img/logo.png';
import { Container, ContainerImage } from './styles';

export function ForgotPassword() {

	const initState = { loading: false, data: null, errors: null };
	const [form] = Form.useForm();
	const [state, setState] = useState(initState);
	const [submited, setSubmited] = useState(false);

	useEffect(() => {
		if (state.errors) {
			const params = {
				message: I18n.get('Oops... Something went wrong!'),
				description: I18n.get(state.errors.message)
			};
			notification.error(params);
		}

		if (state.data) {
			const params = {
				message: I18n.get('Operation performed successfully'),
				description: I18n.get('Check your email inbox'),
				duration: 0
			};
			setSubmited(true);
			notification.success(params);
		}

		return () => setState(initState);
	}, [state.errors, state.data]);

	const onLoading = (loading) => {
		setState(current => ({ ...current, loading }));
	}

	const onSuccess = (data) => {
		setState(current => ({ ...current, data }));
	}

	const onErrors = (errors) => {
		const parseErrors = errors.response?.data || errors;
		setState(current => ({ ...current, errors: parseErrors }));
	}

	async function onFinish() {
		const { email } = await form.validateFields();
		try {
			onLoading(true);
			const { data } = await Api.post('/auth/forgot-password', { email });
			onSuccess(data);
		} catch (error) {
			onErrors(error);
		} finally {
			onLoading(false);
		}
	}

	return (
		<Container>
			<ContainerImage>
				<img alt="logo" src={Logo} />
			</ContainerImage>
			<Form form={form} onFinish={onFinish}>
				<Row>
					<Col sm={24}>
						<h1>{I18n.get('Forgot your password?')}</h1>
					</Col>
					<Col sm={24}>
						<p>
							{I18n.get('We will send you an email to retrieve your password, rest assured :)')}
						</p>
					</Col>
					<Col sm={24}>
						<Form.Item
							name="email"
							style={{ paddingTop: '25px' }}
							rules={[
								{
									type: 'email',
									message: I18n.get('Please enter a valid e-mail address'),
								},
								{
									required: true,
									message: I18n.get('Enter an e-mail')
								}
							]}
						>
							<Input
								size="large"
								placeholder="E-mail"
								prefix={<UserOutlined />}
							/>
						</Form.Item>
					</Col>
					<Col sm={24}>
						<Form.Item style={{ paddingTop: '10px' }}>
							<Button
								size="large"
								type="primary"
								htmlType="submit"
								disabled={submited}
								loading={state.loading}
							>
								{I18n.get('Send')}
							</Button>
						</Form.Item>
					</Col>
					<Col sm={24}>
						<p style={{ fontSize: '17px' }}>
							<a style={{ float: 'right' }} href="/login">
								{I18n.get('Back to login')}
							</a>
						</p>
					</Col>
				</Row>
			</Form>
		</Container>
	);
}




// import React, { useContext } from 'react';
// import { Row, Col, Form, Input, Button, Alert } from 'antd';
// import { UserOutlined } from '@ant-design/icons';
// import pathLogo from '../../assets/img/logo.png';
// import { Context } from '../../context/ForgotPasswordContext';
// import { I18n } from '@aws-amplify/core';
// import { Logo, Title } from './styles';

// export default function ForgotPassword() {

//   const { sendEmailForgotPassword, loading, onSuccess, onError } = useContext(Context);

//   const styles = {
//     container: {
//       height: '100vh',
//       margin: '10px'
//     },
//     alignLogo: {
//       textAlign: 'center'
//     }
//   };


//   return (
//     <>
//       <Row type="flex" justify="center" gutter={[16, 280]} style={styles.container}>
//         <Col xs={24} sm={24} md={14} lg={14} xl={6}>
//           <Row style={{ textAlign: 'center' }}>
//             <Col style={styles.alignLogo}>
//               <Logo alt="Kinebot Analytics" src={pathLogo} />
//             </Col>
//             <Col style={{ textAlign: 'center', width: '100%' }}>
//               <Title>{I18n.get('Enter your email')}</Title>
//             </Col>
//             {onSuccess &&
//               <Col style={{ textAlign: 'center', width: '100%', paddingBottom: '15px' }}>
//                 <Alert message={I18n.get(onSuccess.message)} type="success" showIcon />
//               </Col>
//             }
//             {onError &&
//               <Col style={{ textAlign: 'center', width: '100%', paddingBottom: '15px' }}>
//                 <Alert message={I18n.get(onError.error)} type="error" showIcon />
//               </Col>
//             }
//             <Col style={{ width: '100%' }}>
//               <Form
//                 name="reset_password"
//                 onFinish={sendEmailForgotPassword}
//               >
                // <Form.Item name="email" rules={[
                //   {
                //     type: 'email',
                //     message: I18n.get('The input is not valid E-mail!'),
                //   },
                //   {
                //     required: true,
                //     message: I18n.get('Please input your E-mail!'),
                //   },
                // ]}>
//                   <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="E-mail" />
//                 </Form.Item>
//                 <Form.Item>
//                   <Button type="primary" loading={loading} htmlType="submit" style={{ width: '100%' }}>
//                     {I18n.get('Send')}
//                   </Button>
//                 </Form.Item>
//               </Form>
//             </Col>
//           </Row>
//         </Col>
//       </Row>
//     </>
//   );
// }
