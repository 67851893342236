import styled from 'styled-components';

export const Container = styled.div`
	padding-inline: 20px;
	margin-bottom: 15px;
`;

export const ParameterItem = styled.span`
	margin-left: 12px;
`;
