import React, { useEffect } from 'react';
import { Button, Col, Form, Modal, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import { I18n } from '@aws-amplify/core';

import { OrganizationDTO } from '@/components/ui/Inputs/types/response';
import { useManageOrganizationContext } from './context';
import { Manager } from './Manager';

const { useForm } = Form;

type InitialValues = {
	line_id?: string;
	sector_id?: string;
	company_id?: string;
	workstation_id?: string;
	editing: {
		line_name?: string;
		sector_name?: string;
		company_name?: string;
		workstation_name?: string;
	};
};

type ManageOrganizationModalProps = {
	isModalOpen: boolean;
	organization: OrganizationDTO;
	onModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ManageOrganizationModal: React.FC<ManageOrganizationModalProps> = ({
	organization,
	isModalOpen,
	onModalOpen
}) => {
	const [form] = useForm();
	const { handleIsEditing, handleOrganizationId } = useManageOrganizationContext();

	useEffect(() => {
		handleOrganizationId(organization.id);
	}, []);

	const initialValues: InitialValues = {
		line_id: undefined,
		sector_id: undefined,
		company_id: undefined,
		workstation_id: undefined,
		editing: {
			sector_name: undefined,
			company_name: undefined,
			line_name: undefined,
			workstation_name: undefined
		}
	};

	function handleModalClose() {
		form.resetFields();
		handleIsEditing(false);
		onModalOpen(!isModalOpen);
	}

	return (
		<Form form={form} initialValues={initialValues} layout="vertical">
			<Modal
				bodyStyle={{
					display: 'flex',
					height: 'max-content',
					flexDirection: 'column',
					justifyContent: 'space-between'
				}}
				open={isModalOpen}
				onCancel={() => handleModalClose()}
				footer={null}
			>
				<Row justify="center" gutter={[24, 24]}>
					<Col span={24}>
						<Row justify="start" align="middle">
							<Col span="auto">
								<Title style={{ fontWeight: '100' }} level={3}>
									{I18n.get(organization.name)}
								</Title>
							</Col>
						</Row>
					</Col>
					<Col span={24}>
						<Manager />
					</Col>
				</Row>
				<Row style={{ marginTop: '2rem' }}>
					<Col span={24}>
						<Row justify="end">
							<Col>
								<Button type="default" htmlType="submit" onClick={() => handleModalClose()}>
									{I18n.get('Close')}
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</Modal>
		</Form>
	);
};
