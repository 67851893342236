import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Collapse } from 'antd';

import { Chart } from './Chart';
import { RebaResponse, RebaData } from '@/hooks';
import { ColCustom, RowCustom } from './styles';
import { PanelCustom } from '../styles';

interface RebaResultsProps {
	data: RebaResponse;
}

export function RebaResults({ data }: RebaResultsProps) {
	const { data: RebaDataRows } = data;
	const bodyPartsDataArray: RebaData[] = [];

	RebaDataRows.forEach((row) => bodyPartsDataArray.push(...row));

	const bodyPartPercentages = bodyPartsDataArray.map(({ scores, name }) => ({
		percentage: scores.map(({ percentage }) => percentage),
		name
	}));

	return (
		<Collapse ghost>
			<PanelCustom key="reba-chart" header={I18n.get('Movement by score (Kinebot/JDS)')}>
				<Row justify="center">
					<Col span={23}>
						<RowCustom>
							{bodyPartPercentages.map(({ name, percentage }) => (
								<ColCustom xxl={12} key={`${name}_chart`}>
									<Chart angles={percentage} member={name} />
								</ColCustom>
							))}
						</RowCustom>
					</Col>
				</Row>
			</PanelCustom>
		</Collapse>
	);
}
