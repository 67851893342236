import styled from 'styled-components';
import { Col } from 'antd';

export const PreviousCol = styled(Col)`
	padding: 0;

	margin-right: 1rem;
	@media (min-width: 576px) {
		margin-right: 4rem;
	}
`;

export const CustomFormContainer = styled(Col)`
	padding: 2rem 1rem;
	margin-left: 2rem;
	border-radius: 1rem;
	background-color: #f8f8f8;
`;

export const InputsContainer = styled(Col)`
	.ant-form-item-label {
		label {
			font-weight: 600;
			font-size: 0.8rem;

			@media (min-width: 1366px) {
				font-size: 0.9rem;
			}
		}
	}

	.ant-form-item-explain-error {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.ant-input-number,
	.ant-input-number-in-form-item {
		width: 100%;
	}
`;
