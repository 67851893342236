import React from 'react';
import moment from 'moment';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { Router } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';

import 'moment/locale/pt';
import 'moment/locale/es';
import antdPtBr from 'antd/es/locale/pt_BR';
import antdEsEs from 'antd/es/locale/es_ES';

import store from './store';
import Routes from './routes';
import dictionary from './i18n';
import history from './store/history';
import { queryClient } from '@/store/query';
import { LocalesOptions, LocaleSelected } from './types';
import { AppContextProvider } from '@/context/AppContextProvider';
import { ApplicationProvider } from './context/v1/Application/context';

const language: string = navigator.language;


moment.locale(language);
I18n.setLanguage(language);
I18n.putVocabularies(dictionary);

const antdLocales: LocalesOptions = {
	en: undefined,
	pt: antdPtBr,
	es: antdEsEs
};

const antdLocale: LocaleSelected = antdLocales[language.substring(0, 2)] ?? antdLocales.en;

export const App = () => (
	<ConfigProvider locale={antdLocale}>
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<Router history={history}>
					<ApplicationProvider>
						<AppContextProvider>
							<Routes />
						</AppContextProvider>
					</ApplicationProvider>
				</Router>
			</Provider>
		</QueryClientProvider>
	</ConfigProvider>
);
