export enum MovimentsGripHandResistance {
	POWER_GRIP_FORCE = 'power_grip_force',
	POWER_GRIP_PRESS = 'power_grip_press',
	PINCH_GRIP = 'pinch_grip',
	LATERAL_PINCH_KEY_GRIP = 'lateral_pinch_key_grip',
	TIP_PINCH_GRIP = 'tip_pinch_grip',
	HOOK_GRIP = 'hook_grip',
	PUSH_WITH_INDEX_FINGER = 'push_with_index_finger',
	PUSH_WITH_THUMB = 'push_with_thumb',
	WRIST_FLEXION = 'wrist_flexion',
	ULNAR_RADIAL_DEVIATION = 'ulnar_radial_deviation',
	SCREWDRIVING = 'screwdriving'
}

export enum GloveClothingType {
	ASSEMBLER = 'assembler',
	WELDER = 'welder'
}

export enum HandAndArmClearanceActions {
	ONE_FINGER = 'one_finger',
	TWO_FINGERS = 'two_fingers',
	ARM_TO_ELBOW = 'arm_to_elbow',
	HAND_PLUS_X_MM = 'hand_plus_x_mm',
	ARM_TO_SHOULDER = 'arm_to_shoulder',
	HAND_PLUS_25_MM = 'hand_plus_25_mm',
	EMPTY_HAND_FLAT = 'empty_hand_flat',
	CLENCHED_HAND_FIST = 'clenched_hand_fist',
	BOTH_ARMS_TO_SHOULDER = 'both_arms_to_shoulder',
	EMPTY_HAND_THUMB_TUCKED = 'empty_hand_thumb_tucked'
}

export enum StaticPostureActions {
	KNEELING = 'kneeling',
	CROUCHING = 'crouching',
	STANDING = 'standing'
}
