import React from 'react';
import { Col, Row } from 'antd';
import { useLocation, useParams } from 'react-router-dom';

import { useApplicationContext } from '@/context/v1/Application/context';
import { useGetCustomReportByName, useGetCustomReport } from '@/hooks';
import { EwaJdsD86RouteParams } from './context/types';
import { LoadingSkeleton } from './LoadingSkeleton';
import { Text } from '@/components/Typography';
import { EwaJdsD86Provider, useEwaJdsD86Context } from './context';
import { Report } from './Report';

const REPORT_NAME_EWA = 'ewa_d86';
const REPORT_NAME = 'jds_d86';

export const EwaJdsD86: React.FC = () => {
	const { file_id, original_custom_report_result_id } = useParams<EwaJdsD86RouteParams>();
	const { organization, company } = useApplicationContext();
	const location = useLocation();

	const isEwa = location.pathname.includes('ewa-jds-d86')

	const {
		data: customReport,
		isFetching: isFetchingCustomReportByName,
		isError: errorGettingCustomReportByName
	} = useGetCustomReportByName({
		company_id: company?.id,
		report_name: isEwa ? REPORT_NAME_EWA : REPORT_NAME,
		organization_id: organization?.id
	});

	const {
		data: ewaJdsD86,
		isLoading,
		isError,
		isFetching
	} = useGetCustomReport({
		action_type: isEwa ? undefined : location.pathname.split('/')[3],
		file_id,
		original_custom_report_result_id,
		company_id: company?.id,
		custom_report_id: customReport.id,
		organization_id: organization?.id
	});

	if (isError || errorGettingCustomReportByName) {
		return <Text>Oops... Something went wrong!</Text>;
	}

	if (isLoading || isFetching || isFetchingCustomReportByName) {
		return <LoadingSkeleton />;
	}


	return (
		<Row>
			<Col xs={24}>
				<EwaJdsD86Provider ewaJdsD86={ewaJdsD86} customReport={customReport}>
					<Report />
				</EwaJdsD86Provider>
			</Col>
		</Row>
	);
};
