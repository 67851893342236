import React from 'react';
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { Charts } from './Charts';
import { Parameters } from './Parameters';
import { ErrorMessage } from './ErrorMessage';
import Spinner from '@/components/Layout/Spinner';
import { getScore, getScoreParts, getNotesPerSecond } from '../services';
import { ReportTemplate } from '@/components/views/Report/ReportTemplate';

export function Result({
	file,
	report,
	sectors,
	onDownloadPDF,
	isLoadingPDF,
	onChangeComment
}) {
	const { file_id } = useParams();

	const organization = useSelector((state) => state.organization.organization);
	const company = useSelector((state) => state.organization.company);

	const parameters = {
		organization_id: organization.id,
		company_id: company.id,
		file_id: file_id
	};

	const score = useQuery(['reba-score', parameters], () => getScore(parameters));
	const scorePerSecond = useQuery(['reba-note-per-second', parameters], () =>
		getNotesPerSecond(parameters)
	);
	const scoreParts = useQuery(['reba-score-parts', parameters], () =>
		getScoreParts(parameters)
	);

	const isError = score.isError || scoreParts.isError || scorePerSecond.isError;
	const isLoading = score.isLoading || scoreParts.isLoading || scorePerSecond.isLoading;

	const selectedSector = sectors.data.find((sector) => sector.id === file.data.sector_id);

	const onRetry = () => window.location.reload();

	const sections = [
		{ title: I18n.get('Parameters'), component: <Parameters report={report.data} /> },
		{
			title: I18n.get('Analysis results'),
			component: (
				<Charts
					score={score.data}
					parts={scoreParts.data}
					scorePerSecond={scorePerSecond.data}
				/>
			)
		}
	];

	if (isLoading) {
		return <Spinner />;
	}

	if (isError) {
		return <ErrorMessage onRetry={onRetry} />;
	}

	return (
		<ReportTemplate
			title={I18n.get('REBA')}
			sections={sections}
			reportData={report.data}
			fileData={file.data}
			sector={selectedSector}
			isLoading={isLoading}
			isLoadingPDF={isLoadingPDF}
			onDownloadPDF={onDownloadPDF}
			onChangeComment={onChangeComment}
		/>
	);
}
