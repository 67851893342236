import React from 'react';
import { Col, Form } from 'antd';

import { WorkstationSelect } from '@/components/ui/Inputs/WorkstationSelect';
import { useApplicationContext } from '@/context/v1/Application/context';
import { FormItem } from '../../../components/FormItem';
import { useWorkstations } from '@/hooks/v1';

const { useFormInstance, useWatch } = Form;

interface WorkstationsProps {
	fontSize?: string;
	isRequired?: boolean;
	fieldName: string;
}

export const Workstations: React.FC<WorkstationsProps> = ({ fontSize, isRequired, fieldName }) => {
	const form = useFormInstance();
	const { company, organization } = useApplicationContext();

	const lineId = useWatch([fieldName, 'line_id'], form);
	const workstationId = useWatch([fieldName, 'workstation_id'], form);

	const {
		data: workstations,
		isLoading,
		isFetching,
		isError
	} = useWorkstations({
		organization_id: organization?.id,
		company_id: company?.id,
		line_id: lineId
	});

	function handleWorkstationSelect(id: string) {
		form.setFieldValue([fieldName, 'workstation_id'], id);
	}

	function handleClearWorkstations() {
		form.setFieldValue([fieldName, 'workstation_id'], undefined);
	}

	return (
		<Col xs={24}>
			<FormItem
				namePath={[fieldName, 'workstation_id']}
				title={'Workstation'}
				fontSize={fontSize}
				isRequired={isRequired}
			>
				<WorkstationSelect
					size="large"
					allowCreation
					lineId={lineId}
					loading={isFetching}
					workstations={workstations}
					workstationId={workstationId}
					fieldName={[fieldName, 'workstation_id']}
					handleSelectWorkstation={handleWorkstationSelect}
					handleClearWorkstations={handleClearWorkstations}
					disabled={isLoading || isFetching || isError || !lineId}
				/>
			</FormItem>
		</Col>
	);
};
