import React from 'react';
import { Row } from 'antd';

import { ManageRiskDescription } from './ManageRiskDescription';
import { ManageRiskDamage } from './ManageRiskDamage';
import { ManageSeverity } from './ManageSeverity';

interface ManagerProps {
	stepKeyId: string;
}

export const Manager: React.FC<ManagerProps> = ({ stepKeyId }) => {
	return (
		<Row style={{ width: '100%' }} gutter={[0, 16]}>
			<ManageRiskDescription stepKeyId={stepKeyId} />
			<ManageRiskDamage stepKeyId={stepKeyId} />
			<ManageSeverity stepKeyId={stepKeyId} />
		</Row>
	);
};
