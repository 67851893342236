import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, notification } from 'antd';

import { useCreateReport } from '@/hooks/useCreateReportBackCompressiveForceEstimation';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useBackCompressiveForceEstimationContext } from '../context';
import { LoadingSkeleton } from '../LoadingSkeleton';
import { StepsNavigation } from './StepsNavigation';
import { Header } from './Header';
import { Footer } from './Footer';
import {
	useUpdateReportBasicInformation,
	useUpdateReportInputs
} from '@/hooks/useUpdateBackCompressiveForceEstimation';
import {
	CreateOrUpdate,
	StepToMethodMapper,
	BackCompressiveForceEstimationReportDTO,
	UpdateReportInputs
} from '../types';
import { CustomFormContainer } from './styles';

const { useFormInstance } = Form;

export function Report() {
	const { organization, company } = useApplicationContext();
	const { validateFields, getFieldValue } = useFormInstance();
	const { steps, gettingReport, backCompressiveForceEstimation, file_id, handleUpdateReport } =
		useBackCompressiveForceEstimationContext();

	const { mutateAsync: createReport, isLoading: creatingReport } = useCreateReport();
	const { mutateAsync: updateInputs, isLoading: updatingInputs } = useUpdateReportInputs();
	const { mutateAsync: updateBasicInfo, isLoading: updatingBasicInfo } = useUpdateReportBasicInformation();

	const [currentStep, setCurrentStep] = useState<number>(backCompressiveForceEstimation.step);

	async function handleStepChange(step: number) {
		if (step < currentStep) {
			setCurrentStep(step);
			return;
		}
		await createOrUpdate(step);
	}

	async function createOrUpdate(step: number) {
		try {
			const values = await validateFields();
			if (values) {
				const body: CreateOrUpdate = {
					...values,
					file_id,
					company_id: company?.id,
					report_id: backCompressiveForceEstimation?.id,
					organization_id: organization?.id,
					system_of_units_id: getFieldValue(['system_of_units_id'])
				};

				const stepToMethodMapper: StepToMethodMapper = {
					0: (body) => createOrUpdateBasicInfo(body),
					1: (body) => {
						if (isUpdateInputs(body)) {
							return updateInputs(body as UpdateReportInputs);
						}
					}
				};

				const report = await stepToMethodMapper[currentStep](body);
				if (report?.id) {
					handleUpdateReport(report);
					setCurrentStep(step);
				}
			}
		} catch (_) {
			showErrorMessage();
		}
	}

	function showErrorMessage() {
		let message = "Some required steps wasn't filled";

		notification.error({
			message: I18n.get('Ops... something happened!'),
			description: I18n.get(message),
			duration: 6
		});
	}

	function createOrUpdateBasicInfo(body: any): Promise<BackCompressiveForceEstimationReportDTO> {
		return backCompressiveForceEstimation?.id ? updateBasicInfo(body) : createReport(body);
	}

	function isUpdateInputs(body: CreateOrUpdate): body is UpdateReportInputs {
		return (body as UpdateReportInputs).back_compressive_force_estimation_inputs !== undefined;
	}

	const loading = creatingReport || updatingInputs || updatingBasicInfo;

	if (loading) {
		return <LoadingSkeleton loading={loading} />;
	}

	return (
		<Row justify="center">
			<Col xs={24} xxl={20}>
				<Row justify="center">
					<Col xl={24} xs={24} xxl={3}>
						<StepsNavigation currentStep={currentStep} onStepChange={handleStepChange} />
					</Col>
					<Col xl={24} lg={24} xxl={18}>
						<Row justify="start" gutter={[16, 24]}>
							<Col xs={24}>
								<Header />
							</Col>
							<CustomFormContainer xs={24}>
								{!gettingReport && steps[currentStep]?.component}
								<Footer currentStep={currentStep} onStepChange={handleStepChange} />
							</CustomFormContainer>
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
