import React from 'react';
import { Image, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { SummaryResult } from '../../../../hooks/types/response';
import { HandCoupling } from '../../../../context/types';
import { Body, CardContainer, Header } from '../styles';

const FAIR_IMAGE = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/coupling-fair.svg';
const GOOD_IMAGE = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/coupling-good.svg';
const POOR_IMAGE = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/coupling-poor.svg';

interface HandCouplingCardProps {
	summary: SummaryResult;
}

export const HandCouplingCard: React.FC<HandCouplingCardProps> = ({ summary }) => {
	const couplingSVGMapper = {
		[HandCoupling.Fair]: FAIR_IMAGE,
		[HandCoupling.Good]: GOOD_IMAGE,
		[HandCoupling.Poor]: POOR_IMAGE
	};

	return (
		<CardContainer>
			<Header>{I18n.get('Hand coupling')}</Header>
			<Body>
				<Row justify="center" style={{ padding: '1rem 0 0 0' }}>
					<Image
						preview={false}
						draggable={false}
						alt={I18n.get(summary.hand_coupling)}
						src={couplingSVGMapper[summary.hand_coupling]}
						width="50px"
					/>
				</Row>
				<Row justify="center">{I18n.get(summary.hand_coupling)}</Row>
			</Body>
		</CardContainer>
	);
};
