import styled from 'styled-components';

export const ResultsContainer = styled.div`
	height: 100%;
	display: flex;
	padding: 2rem;
	padding-top: 0;
	border-radius: 0.5rem;
	flex-direction: column;
	justify-content: center;
	background-color: white;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
`;

export const KinebotLogoContainer = styled.div``;

export const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 1rem;
	font-weight: 400;

	#title {
		font-weight: 600;
	}
`;

export const ClientLogoContainer = styled.div`
	background: #d9d9d9;
	width: 100%;
	height: 100%;
	border-radius: 0.5rem;
	padding: 0.75rem 0.5rem;
	text-align: center;

	span {
		color: #8c8c8c;
		font-size: 1rem;
		font-weight: 600;
	}
`;

export const ResultBox = styled.span`
	background-color: ${props => props.color + '1a'};
	padding: 0.5rem 2rem;
	text-align: center;
	border: 1px solid currentColor;
	border-radius: 4px;
	color: ${props => props.color};
	font-size: 1rem;
	font-weight: 600;
`;
