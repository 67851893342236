import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Image, Row } from 'antd';

import { Body, CardContainer, Header, UnitContainer } from '../styles';
import { SummaryResult } from '../../../../hooks/types/response';

const WEIGHT_IMAGE = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/result-weight.svg';

interface ObjectWeightCardProps {
	summary: SummaryResult;
}

export const ObjectWeightCard: React.FC<ObjectWeightCardProps> = ({ summary }) => {
	return (
		<CardContainer>
			<Header>{I18n.get('Weight')}</Header>
			<Body>
				<Row justify="center" style={{ padding: '1rem 0 0.5rem 0' }}>
					<Image
						style={{ position: 'relative' }}
						preview={false}
						draggable={false}
						alt={I18n.get(`Object weight: ${I18n.get(summary.object_weight)}`)}
						src={WEIGHT_IMAGE}
						width="40px"
					/>
					<UnitContainer>{summary.unit}</UnitContainer>
				</Row>
				<Row justify="center" style={{ fontSize: '1.1rem' }}>
					{I18n.get(summary.object_weight)}
				</Row>
			</Body>
		</CardContainer>
	);
};
