import React, { useEffect } from 'react';
import { Button, Col, Form, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import { I18n } from '@aws-amplify/core';

import { CategoryResponse } from '../hooks/types/response';
import { useManageCategoryContext } from './context';
import { ModalContainer } from './styles';
import { Manager } from './Manager';

const { useForm } = Form;

type InitialValues = {
	risk_damage_id?: string;
	risk_description_id?: string;
	editing: {
		risk_damage_name?: string;
		risk_description_name?: string;
	};
};

type ManageCategoryModalProps = {
	stepKeyId: string;
	isModalOpen: boolean;
	category: CategoryResponse;
	onModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ManageCategoryModal: React.FC<ManageCategoryModalProps> = ({
	category,
	stepKeyId,
	isModalOpen,
	onModalOpen
}) => {
	const [form] = useForm();
	const { handleIsEditing, handleCategoryId } = useManageCategoryContext();

	useEffect(() => {
		handleCategoryId(category.id);
	}, []);

	const initialValues: InitialValues = {
		risk_damage_id: undefined,
		risk_description_id: undefined,
		editing: {
			risk_damage_name: undefined,
			risk_description_name: undefined
		}
	};

	function handleModalClose() {
		form.resetFields();
		handleIsEditing(false);
		onModalOpen(!isModalOpen);
	}

	return (
		<Form form={form} initialValues={initialValues} layout="vertical">
			<ModalContainer
				bodyStyle={{
					display: 'flex',
					height: 'max-content',
					flexDirection: 'column',
					justifyContent: 'space-between'
				}}
				open={isModalOpen}
				onCancel={() => handleModalClose()}
				footer={null}
			>
				<Row justify="center" gutter={[24, 24]}>
					<Col span={24}>
						<Row justify="start" align="middle">
							<Col span="auto">
								<Title style={{ fontWeight: '100' }} level={3}>
									{I18n.get(category.description)}
								</Title>
							</Col>
						</Row>
					</Col>
					<Col span={24}>
						<Manager stepKeyId={stepKeyId} />
					</Col>
				</Row>
				<Row style={{ marginTop: '2rem' }}>
					<Col span={24}>
						<Row justify="end">
							<Col>
								<Button type="default" htmlType="submit" onClick={() => handleModalClose()}>
									{I18n.get('Close')}
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</ModalContainer>
		</Form>
	);
};
