import React from 'react';
import { Paragraph, Title } from '@/components/Typography';
import { Col, Row } from 'antd';

import { BackButton } from './BackButton';
import { SeraReport } from './SeraReport';
import { CustomColumn } from './styles';

interface SeraFormProps {}

export const SeraForm: React.FC<SeraFormProps> = () => {
	return (
		<Row justify="center">
			<Col xs={24}>
				<Row justify="center">
					<Title level={3}>Safety Ergonomic Risk Assessment (S.E.R.A.)</Title>
				</Row>
				<Row>
					<Col xs={24} lg={20}>
						<BackButton />
					</Col>
				</Row>
				<Row justify="center">
					<CustomColumn xs={24} xxl={20}>
						<Row>
							<Col xs={24}>
								<Title level={4}>Task review</Title>
							</Col>
							<Col xs={24}>
								<Paragraph>Select tasks below to review</Paragraph>
							</Col>
						</Row>
						<Row justify="center" style={{ marginTop: '2rem' }}>
							<SeraReport />
						</Row>
					</CustomColumn>
				</Row>
			</Col>
		</Row>
	);
};
