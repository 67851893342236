import React from 'react';
import { I18n } from '@aws-amplify/core';
import { InfoCircleOutlined } from '@ant-design/icons';

import { CustomTooltip } from './styles';

interface InfoTooltipProps {
	text: string;
}

export const InfoTooltip: React.FC<InfoTooltipProps> = ({ text }) => {
	return (
		<CustomTooltip
			showArrow={false}
			placement="topLeft"
			title={I18n.get(text)}
			getPopupContainer={(triggerNode) => triggerNode}
		>
			<InfoCircleOutlined style={{ position: 'absolute', fontSize: '0.7rem', marginLeft: '0.3rem' }} />
		</CustomTooltip>
	);
};
