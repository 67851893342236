import React from 'react';
import { Paragraph, Text } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';
import { Row, Col } from 'antd';
import moment from 'moment';

import { Organization } from './Organizations';
import { Workstations } from './Workstations';
import { ReportName } from './ReportName';
import { Evaluators } from './Evaluator';
import { Companies } from './Companies';
import { SubtitleCol } from './styles';
import { Sectors } from './Sectors';
import { Cycles } from './Cycles';
import { Lines } from './Lines';

export const Informations: React.FC = () => {
	const today = moment().format('L');

	return (
		<Col xs={24} md={20} xl={16} xxl={12}>
			<Row gutter={[0, 10]} justify="center">
				<Col xs={24}>
					<Row justify="center">
						<SubtitleCol xs={24}>
							<Text strong>{I18n.get('General informations')}</Text>
							<Text>
								{I18n.get('Date')}: {today}
							</Text>
						</SubtitleCol>
						<Col xs={24}>
							<Paragraph>Insert below the informations for the cycle</Paragraph>
						</Col>
					</Row>
				</Col>
				<Col xs={24}>
					<Row style={{ marginBottom: '2rem' }}>
						<ReportName />
					</Row>
					<Row gutter={[10, 0]}>
						<Organization />
						<Companies />
						<Sectors />
						<Lines />
						<Workstations />
						<Cycles />
						<Evaluators />
					</Row>
				</Col>
			</Row>
		</Col>
	);
};
