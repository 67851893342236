import Types from './types';
import _set from 'lodash/set';
import { validationJSON } from '@/utils';

const initState = {
	type: {
		tool: undefined,
		report: undefined,
		isValid: false
	},
	information: {
		company: null,
		sector: undefined,
		workstation: null,
		collectionDate: null,
		isValid: false
	},
	parameters: {
		legs: 2,
		force: 0,
		repetition: 1
	},
	conclusion: {
		text: null,
		author: null
	},
	sectorList: {
		loading: false,
		error: null,
		data: []
	},
	dataChart: {
		loading: false,
		error: null,
		data: []
	},
	document: {
		loading: false,
		data: null,
		error: null
	},
	sector: {
		loading: false,
		data: null,
		error: null
	},
	checkUsageSector: {
		result: null,
		count: 0,
		id: null
	},
	degreeOfRisk: {
		loading: false,
		data: null,
		error: null
	},
	angleTime: {
		loading: false,
		data: null,
		error: null
	},
	totalVideo: {
		loading: false,
		data: null,
		error: null
	},
	processedMinutes: {
		loading: false,
		data: null,
		error: null
	},
	percentVideoRisk: {
		loading: false,
		data: null,
		error: null
	},
	criticalSector: {
		loading: false,
		data: null,
		error: null
	},
	videosBySectors: {
		loading: false,
		data: null,
		error: null
	},
	videosRiskByTime: {
		loading: false,
		data: null,
		error: null
	},
	sectorsRegister: {
		loading: false,
		data: null,
		error: null
	},
	extractedReports: {
		loading: false,
		data: null,
		error: null
	},
	allSectorsCritical: {
		loading: false,
		data: null,
		error: null
	},
	statusRisk: {
		loading: false,
		data: null,
		error: null
	},
	saveParameterReport: {
		data: null,
		error: null
	}
};

const reportReducer = (state = initState, action) => {
	switch (action.type) {
		case Types.CLEAR_DASHBOARD: {
			const {
				totalVideo,
				statusRisk,
				criticalSector,
				videosBySectors,
				sectorsRegister,
				processedMinutes,
				extractedReports,
				allSectorsCritical
			} = initState;

			return {
				...state,
				totalVideo,
				statusRisk,
				criticalSector,
				videosBySectors,
				sectorsRegister,
				processedMinutes,
				extractedReports,
				allSectorsCritical
			};
		}

		case Types.SAVE_PARAMETERS_REPORT_SUCCESS: {
			return {
				...state,
				saveParameterReport: {
					...state.saveParameterReport,
					data: action.payload
				}
			};
		}

		case Types.SAVE_PARAMETERS_REPORT_ERROR: {
			return {
				...state,
				saveParameterReport: {
					...state.saveParameterReport,
					error: action.payload
				}
			};
		}

		case Types.GET_RISK_STATUS_LOADING: {
			return {
				...state,
				statusRisk: {
					...state.statusRisk,
					loading: !state.statusRisk.loading
				}
			};
		}

		case Types.GET_RISK_STATUS_SUCCESS: {
			return {
				...state,
				statusRisk: {
					...state.statusRisk,
					data: action.payload
				}
			};
		}

		case Types.GET_RISK_STATUS_ERROR: {
			return {
				...state,
				statusRisk: {
					...state.statusRisk,
					error: action.payload
				}
			};
		}

		case Types.GET_SECTORS_REGISTER_LOADING: {
			return {
				...state,
				sectorsRegister: {
					...state.sectorsRegister,
					loading: !state.sectorsRegister.loading
				}
			};
		}

		case Types.GET_SECTORS_REGISTER_SUCCESS: {
			return {
				...state,
				sectorsRegister: {
					...state.sectorsRegister,
					data: action.payload
				}
			};
		}

		case Types.GET_SECTORS_REGISTER_ERROR: {
			return {
				...state,
				sectorsRegister: {
					...state.sectorsRegister,
					error: action.payload
				}
			};
		}

		case Types.GET_EXTRACTED_REPORTS_LOADING: {
			return {
				...state,
				extractedReports: {
					...state.extractedReports,
					loading: !state.extractedReports.loading
				}
			};
		}

		case Types.GET_EXTRACTED_REPORTS_SUCCESS: {
			return {
				...state,
				extractedReports: {
					...state.extractedReports,
					data: action.payload
				}
			};
		}

		case Types.GET_EXTRACTED_REPORTS_ERROR: {
			return {
				...state,
				extractedReports: {
					...state.extractedReports,
					error: action.payload
				}
			};
		}

		case Types.GET_ALL_SECTORS_CRITICAL_LOADING: {
			return {
				...state,
				allSectorsCritical: {
					...state.allSectorsCritical,
					loading: !state.allSectorsCritical.loading
				}
			};
		}

		case Types.GET_ALL_SECTORS_CRITICAL_SUCCESS: {
			return {
				...state,
				allSectorsCritical: {
					...state.allSectorsCritical,
					data: action.payload
				}
			};
		}

		case Types.GET_ALL_SECTORS_CRITICAL_ERROR: {
			return {
				...state,
				allSectorsCritical: {
					...state.allSectorsCritical,
					error: action.payload
				}
			};
		}

		// Down

		case Types.GET_VIDEOS_RISK_BY_TIME_LOADING: {
			return {
				...state,
				videosRiskByTime: {
					...state.videosRiskByTime,
					loading: !state.videosRiskByTime.loading
				}
			};
		}

		case Types.GET_VIDEOS_RISK_BY_TIME_SUCCESS: {
			return {
				...state,
				videosRiskByTime: {
					...state.videosRiskByTime,
					data: action.payload
				}
			};
		}

		case Types.GET_VIDEOS_RISK_BY_TIME_ERROR: {
			return {
				...state,
				videosRiskByTime: {
					...state.videosRiskByTime,
					error: action.payload
				}
			};
		}

		case Types.GET_VIDEOS_BY_SERCTORS_LOADING: {
			return {
				...state,
				videosBySectors: {
					...state.videosBySectors,
					loading: !state.videosBySectors.loading
				}
			};
		}

		case Types.GET_VIDEOS_BY_SERCTORS_SUCCESS: {
			return {
				...state,
				videosBySectors: {
					...state.videosBySectors,
					data: action.payload
				}
			};
		}

		case Types.GET_VIDEOS_BY_SERCTORS_ERROR: {
			return {
				...state,
				videosBySectors: {
					...state.videosBySectors,
					error: action.payload
				}
			};
		}

		case Types.GET_PERCENT_VIDEO_RISK_LOADING: {
			return {
				...state,
				percentVideoRisk: {
					...state.percentVideoRisk,
					loading: !state.percentVideoRisk.loading
				}
			};
		}

		case Types.GET_PERCENT_VIDEO_RISK_SUCCESS: {
			return {
				...state,
				percentVideoRisk: {
					...state.percentVideoRisk,
					data: action.payload
				}
			};
		}

		case Types.GET_PERCENT_VIDEO_RISK_ERROR: {
			return {
				...state,
				percentVideoRisk: {
					...state.percentVideoRisk,
					error: action.payload
				}
			};
		}

		case Types.GET_CRITICAL_SECTOR_LOADING: {
			return {
				...state,
				criticalSector: {
					...state.criticalSector,
					loading: !state.criticalSector.loading
				}
			};
		}

		case Types.GET_CRITICAL_SECTOR_SUCCESS: {
			return {
				...state,
				criticalSector: {
					...state.criticalSector,
					data: action.payload
				}
			};
		}

		case Types.GET_CRITICAL_SECTOR_ERROR: {
			return {
				...state,
				criticalSector: {
					...state.criticalSector,
					error: action.payload
				}
			};
		}

		case Types.GET_PROCESSED_MINUTES_LOADING: {
			return {
				...state,
				processedMinutes: {
					...state.processedMinutes,
					loading: !state.processedMinutes.loading
				}
			};
		}

		case Types.GET_PROCESSED_MINUTES_SUCCESS: {
			return {
				...state,
				processedMinutes: {
					...state.processedMinutes,
					data: action.payload
				}
			};
		}

		case Types.GET_PROCESSED_MINUTES_ERROR: {
			return {
				...state,
				processedMinutes: {
					...state.processedMinutes,
					error: action.payload
				}
			};
		}

		case Types.GET_TOTAL_VIDEO_LOADING: {
			return {
				...state,
				totalVideo: {
					...state.totalVideo,
					loading: !state.totalVideo.loading
				}
			};
		}

		case Types.GET_TOTAL_VIDEO_SUCCESS: {
			return {
				...state,
				totalVideo: {
					...state.totalVideo,
					data: action.payload
				}
			};
		}

		case Types.GET_TOTAL_VIDEO_ERROR: {
			return {
				...state,
				totalVideo: {
					...state.totalVideo,
					error: action.payload
				}
			};
		}

		case Types.GET_ANGLE_TIME_LOADING: {
			return {
				...state,
				angleTime: {
					...state.angleTime,
					loading: !state.angleTime.loading
				}
			};
		}

		case Types.GET_ANGLE_TIME_SUCCESS: {
			return {
				...state,
				angleTime: {
					...state.angleTime,
					data: action.payload
				}
			};
		}

		case Types.GET_ANGLE_TIME_ERROR: {
			return {
				...state,
				angleTime: {
					...state.angleTime,
					error: action.payload
				}
			};
		}

		case Types.GET_DEGREE_OF_RISK_LOADING: {
			return {
				...state,
				degreeOfRisk: {
					...state.degreeOfRisk,
					loading: !state.degreeOfRisk.loading
				}
			};
		}

		case Types.GET_DEGREE_OF_RISK_SUCCESS: {
			return {
				...state,
				degreeOfRisk: {
					...state.degreeOfRisk,
					data: action.payload
				}
			};
		}

		case Types.GET_DEGREE_OF_RISK_ERROR: {
			return {
				...state,
				degreeOfRisk: {
					...state.degreeOfRisk,
					error: action.payload
				}
			};
		}

		case Types.GENERATOR_DOCUMENT_LOADING: {
			return {
				...state,
				document: {
					...state.document,
					loading: !state.document.loading
				}
			};
		}

		case Types.SET_TYPE: {
			return {
				...state,
				type: action.payload
			};
		}

		case Types.SET_AUTHOR: {
			return {
				...state,
				conclusion: {
					...state.conclusion,
					author: action.payload
				}
			};
		}

		case Types.SET_INFORMATION: {
			/*
        Responsavel pelo tratamento do setor selecionado
        recuperado do banco de dados.
      */

			const { sector } = action.payload;

			let parseSector = validationJSON(sector);

			if (!parseSector) {
				let [item] = state.sectorList.data.filter((item) => item.name === sector);
				_set(action.payload, 'sector', JSON.stringify(item) || undefined);
			}

			return {
				...state,
				information: action.payload
			};
		}

		case Types.SET_PARAMETERS: {
			return {
				...state,
				parameters: action.payload
			};
		}

		case Types.SET_CONCLUSION: {
			return {
				...state,
				conclusion: {
					...state.conclusion,
					text: action.payload.text
				}
			};
		}

		case Types.GET_SECTORS_LOADING: {
			return {
				...state,
				sectorList: {
					...state.sectorList,
					loading: !state.sectorList.loading
				}
			};
		}

		case Types.GET_SECTORS_SUCCESS: {
			return {
				...state,
				sectorList: {
					...state.sectorList,
					data: action.payload
				}
			};
		}

		case Types.GET_SECTORS_ERROR: {
			return {
				...state,
				sectorList: {
					...state.sectorList,
					error: action.payload
				}
			};
		}

		case Types.GET_DATA_CHART_LOADING: {
			return {
				...state,
				dataChart: {
					...state.dataChart,
					loading: !state.dataChart.loading
				}
			};
		}

		case Types.GET_DATA_CHART_SUCCESS: {
			return {
				...state,
				dataChart: {
					...state.dataChart,
					data: action.payload
				}
			};
		}

		// case Types.CLEAR_REPORT: {
		//   console.log('CLEAR_REPORT', ...initState);
		//   return {
		//     ...initState
		//   }
		// }

		case Types.CLEAR_REPORT: {
			return initState;
		}

		case Types.SECTOR_CREATE_LOADING: {
			return {
				...state,
				sector: {
					...state.sector,
					loading: !state.sector.loading
				}
			};
		}

		case Types.SECTOR_CREATE_SUCCESS: {
			return {
				...state,
				sector: {
					...state.sector,
					data: action.payload
				}
			};
		}

		case Types.SECTOR_CREATE_ERROR: {
			return {
				...state,
				sector: {
					...state.sector,
					error: action.payload
				}
			};
		}

		case Types.SECTOR_CLEAR_MESSAGE: {
			return {
				...state,
				sector: {
					...state.sector,
					data: null
				}
			};
		}

		case Types.SECTOR_DELETE_SUCCESS: {
			/*
        Este estado, está diretamente associado à busca
        de dados relacionados ao relatório do usuário.
      */

			const sectorState = state.information.sector;

			const sector = sectorState ? JSON.parse(sectorState) : undefined;

			const equalSector = sector && sector.id === action.payload.id;

			return {
				...state,
				sectorList: {
					...state.sectorList,
					data: state.sectorList.data.filter((item) => action.payload.id !== item.id)
				},
				information: {
					...state.information,
					sector: equalSector ? undefined : state.information.sector,
					isValid: equalSector ? false : true
				},
				checkUsageSector: {
					result: null,
					count: 0,
					id: null
				}
			};
		}

		case Types.SECTOR_DELETE_ERROR: {
			return {
				...state,
				sector: {
					...state.sector,
					error: action.payload
				}
			};
		}

		case Types.SECTOR_CLEAR_MESSAGE_ERROR: {
			return {
				...state,
				sector: {
					...state.sector,
					error: null
				}
			};
		}

		case Types.GET_RECOVERY_REPORT_SUCCESS: {
			const { workstation, sector, isValid, company, conclusion, collectionDate } = action.payload;

			// const { id } = JSON.parse(sector);

			// const verify = state.sectorList.data((item) => item.id === id);

			// console.log(verify, 'verify');

			return {
				...state,
				information: {
					...state.information,
					sector,
					isValid,
					company,
					workstation,
					collectionDate
				},
				conclusion: {
					...state.conclusion,
					text: conclusion
				}
			};
		}

		case Types.CHECK_USAGE_SECTOR_SUCCESS: {
			return {
				...state,
				checkUsageSector: action.payload
			};
		}

		default:
			return state;
	}
};

export default reportReducer;
