import React from 'react';
import { Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useApplicationContext } from '@/context/v1/Application/context';
import { LineSelect } from '@/components/ui/Inputs/LineSelect';
import { useLines } from '@/hooks/v1';

const { useFormInstance, useWatch } = Form;

export function Lines() {
	const form = useFormInstance();
	const { organization, company } = useApplicationContext();

	const sectorId = useWatch('sector_id', form);
	const lineId = useWatch('line_id', form);

	const {
		data: lines,
		isLoading,
		isFetching,
		isError
	} = useLines({ organization_id: organization?.id, company_id: company?.id, sector_id: sectorId });

	function handleSelectLine(id: string): void {
		form.setFieldValue(['line_id'], id);
		form.setFieldValue(['workstation_id'], undefined);
	}

	function handleClearLines(): void {
		form.setFieldValue(['line_id'], undefined);
		form.setFieldValue(['workstation_id'], undefined);
	}

	return (
		<Col xs={24}>
			<Form.Item name={['line_id']} label={I18n.get('Line')} labelCol={{ span: 24 }}>
				<LineSelect
					lines={lines}
					lineId={lineId}
					fieldName="line_id"
					sectorId={sectorId}
					loading={isFetching}
					allowCreation={false}
					handleSelectLine={handleSelectLine}
					handleClearLines={handleClearLines}
					disabled={isLoading || isFetching || isError || !sectorId}
				/>
			</Form.Item>
		</Col>
	);
}
