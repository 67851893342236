import React from 'react';
import { Col, Form, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { WorkstationSelect } from '@/components/ui/Inputs/WorkstationSelect';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useWorkstations } from '@/hooks/v1';

const { useFormInstance, useWatch } = Form;

export const Workstations: React.FC = () => {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();
	const { company, organization } = useApplicationContext();

	const lineId = useWatch('line_id', form);
	const workstationId = useWatch('workstation_id', form);

	const {
		data: workstations,
		isLoading,
		isFetching,
		isError
	} = useWorkstations({
		organization_id: organization?.id,
		company_id: company?.id,
		line_id: lineId
	});

	function handleWorkstationSelect(id: string) {
		setFieldValue(['workstation_id'], id);
	}

	function handleClearWorkstations() {
		setFieldValue(['workstation_id'], undefined);
	}

	return (
		<Col sm={4}>
			<Row>
				<Col xs={24}>
					<Form.Item name={['workstation_id']} label={I18n.get('Workstation')} labelCol={{ span: 24 }}>
						<WorkstationSelect
							lineId={lineId}
							loading={isFetching}
							allowCreation={false}
							workstations={workstations}
							fieldName="workstation_id"
							workstationId={workstationId}
							handleSelectWorkstation={handleWorkstationSelect}
							handleClearWorkstations={handleClearWorkstations}
							disabled={isLoading || isFetching || isError || !lineId}
						/>
					</Form.Item>
				</Col>
			</Row>
		</Col>
	);
};
