import axios from 'axios';
import store from '../store';
import config from '../config';
import { Utils } from '../utils';
import { handleLogout } from '../redux/auth/actions';

const FORBIDDEN = 403;
const UNAUTHORIZED = 401;
const ECONNABORTED = 'ECONNABORTED';

const Api = axios.create({
	baseURL: config.api.url
	// timeout: 10000
});

const onRequestSuccess = (request) => {
	const headers = request.headers ?? {};
	const token = JSON.parse(localStorage.getItem('info_user'))?.token;

	if (token) {
		headers['Authorization'] = `Bearer ${token}`;
	}

	request.headers = headers;
	return request;
};

const onResponseSuccess = (response) => response;

const onResponseFail = async (error) => {
	const status = error.status || error.response?.status;
	const originalRequest = error.config;

	if (error.code === ECONNABORTED) {
		let customError = new Error('Sorry time exceeded, try again.');
		return Promise.reject(customError);
	}

	if (status === FORBIDDEN && !originalRequest?._retry) {
		const token = await Utils.setRefreshToken(error);
		originalRequest._retry = true;
		originalRequest.headers.Authorization = `Bearer ${token}`;
		return Api(originalRequest);
	}

	if (status === UNAUTHORIZED) {
		store.dispatch(handleLogout());
	}

	return Promise.reject(error);
};

Api.interceptors.request.use(onRequestSuccess);
Api.interceptors.response.use(onResponseSuccess, onResponseFail);

export default Api;
