import React from 'react';
import { Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useApplicationContext } from '@/context/v1/Application/context';
import { FileSelect } from '@/components/ui/Inputs/FileSelect';
import { useGetFiles } from '@/hooks/v1/useGetFiles';

const { useFormInstance, useWatch } = Form;

export function Files() {
	const form = useFormInstance();
	const file_id = useWatch('file_id', form);
	const sector_id = useWatch('sector_id', form);
	const workstation_id = useWatch('workstation_id', form);

	const { organization, company } = useApplicationContext();

	const { isFetching, isLoading, data, isError } = useGetFiles({
		organization_id: organization?.id,
		company_id: company?.id,
		sector_id: sector_id,
		workstation_id: workstation_id
	});

	function handleFileSelect(id: string): void {
		form.setFieldValue(['file_id'], id);
	}

	function handleClearFile(): void {
		form.setFieldValue(['file_id'], undefined);
	}

	return (
		<Col xs={24}>
			<Form.Item name={['file_id']} label={I18n.get('File')} labelCol={{ span: 24 }}>
				<FileSelect
					files={data}
					fileId={file_id}
					sectorId={sector_id}
					loading={isFetching}
					handleClearFile={handleClearFile}
					handleSelectFile={handleFileSelect}
					disabled={isLoading || isFetching || isError || !workstation_id}
				/>
			</Form.Item>
		</Col>
	);
}
