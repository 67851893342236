import { Col, Row } from 'antd';
import React from 'react';

import { Title } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';
import { TableList } from './TableList';

export const Conclusion: React.FC = () => {
	return (
		<Col span={12}>
			<Row gutter={[10, 10]}>
				<Col span={24}>
					<Title level={5}>{I18n.get('Selected EWA')}</Title>
				</Col>
				<TableList />
			</Row>
		</Col>
	);
};
