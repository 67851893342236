import React from 'react';
import { Col, Row } from 'antd';

import { I18n } from '@aws-amplify/core';

import { Description, Score } from './styles';

type AcceptableDescriptionType = {
	score: number;
};

export const AcceptableDescription: React.FC<AcceptableDescriptionType> = ({ score }) => {
	return (
		<Row align="middle" style={{ marginBottom: '1rem' }}>
			<Col span={5}>
				<Description>{I18n.get('Acceptable Risk')}</Description>
			</Col>

			<Col>
				<Score>
					{I18n.get('Score')}: {score}
				</Score>
			</Col>
		</Row>
	);
};
