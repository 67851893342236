import { InitialValues, InitialValuesMapperInterface } from './servicesInterfaces';
import { LibertyMutualReportDTO } from '../types/response';

export type MappedFields = {
	[key: string]: string | number;
};

export class InitialValuesMapper implements InitialValuesMapperInterface {
	constructor(private libertyMutual: LibertyMutualReportDTO | undefined) {}

	public mapInitialValues(): InitialValues | undefined {
		if (!this.libertyMutual) {
			return undefined;
		}

		const { comment, line_id, sector_id, company_id, workstation_id, system_of_units } = this.libertyMutual;

		return {
			line_id,
			comment,
			sector_id,
			company_id,
			workstation_id,
			system_of_units_id: system_of_units?.id
		};
	}
}
