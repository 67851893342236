import { I18n } from '@aws-amplify/core';
import { Button, Col, Row } from 'antd';
import React from 'react';
import { useCheck2FAContext } from '../context';

export const ResetToken = () => {
	const { handleOpenCheck2FAModal, handleOpenReset2FAModal } = useCheck2FAContext();

	function handleResetClick() {
		handleOpenCheck2FAModal(false);
		handleOpenReset2FAModal(true);
	}

	return (
		<Col span={'auto'}>
			<Row align="middle">
				<Col>
					<Button onClick={handleResetClick} type="default">
						{I18n.get('Reset token')}
					</Button>
				</Col>
			</Row>
		</Col>
	);
};
