import { useMutation, useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import type { AxiosError } from 'axios';
import { message } from 'antd';

import Api from '@/services/api';
import type { BaseContext, CustomReportStepKeyResult, Response } from '@/types';

export type UpdateOrCreateSubStepKeyResult = {
	score?: number;
	result?: number;
	severity_id?: string;
	exposure_id?: string;
	risk_damage_id?: string;
	risk_category_id?: string;
	vulnerability_id?: string;
	risk_description_id?: string;
	sub_step_key_result_id?: string;
	custom_report_step_key_id?: string;
	custom_report_sub_step_key_id?: string;
};

export type UpdateOrCreateAdditionalItemResult = {
	result?: number;
	value_1?: number;
	value_2?: number;
	value_3?: number;
	value_4?: number;
	custom_report_result_id?: string;
	additional_item_option_id_1?: string;
	additional_item_option_id_2?: string;
	custom_report_step_key_additional_item_id?: string;
};

export type CreateStepKeyResultDTO = {
	score?: number;
	result?: number;
	severity_id?: string;
	exposure_id?: string;
	risk_damage_id?: string;
	risk_category_id?: string;
	vulnerability_id?: string;
	risk_description_id?: string;
	custom_report_step_key_id?: string;
	additional_items?: UpdateOrCreateAdditionalItemResult[];
	sub_step_keys_results?: UpdateOrCreateSubStepKeyResult[];
};

export type CreateStepKeysDTO = BaseContext & {
	step_id: string;
	custom_report_id: string;
	custom_report_result_id?: string;
	step_keys_results: CreateStepKeyResultDTO[];
};

async function createStepKeyResults(body: CreateStepKeysDTO): Promise<CustomReportStepKeyResult[]> {
	const url = '/custom-report/result/step-key';
	const { data } = await Api.post<CustomReportStepKeyResult[]>(url, body);
	return data;
}

export const useCreateStepKeyResults = () => {
	const queryClient = useQueryClient();
	return useMutation<CustomReportStepKeyResult[], AxiosError<Response>, CreateStepKeysDTO>(
		(body: CreateStepKeysDTO) => createStepKeyResults(body),
		{
			onError: (err) => {
				message.error(I18n.get(err?.response?.data?.message ?? err?.message));
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['jds_d86_report']);
				queryClient.invalidateQueries(['jds_d86_result']);
			}
		}
	);
};
