import React from 'react';
import PropTypes from 'prop-types';
import couplingImage from '@/assets/img/ergonomic_tools/coupling-niosh.png';
import { Col, Row } from 'antd';
import styled from 'styled-components';

const Indicator = styled.div`
	position: absolute;
	top: 61px;
	left: 30px;
	width: 56px;
	height: 52px;
	border-radius: 40%;
	background-color: ${(props) => props.color};
`;

const Image = styled.img`
	border-radius: 50%;
	border: 1px solid grey;
	width: 120px;
`;

export function CouplingDetail({ coupling }) {
	const indicatorColors = {
		POOR: 'rgba(255, 40, 40, 0.3)',
		FAIR: 'rgba(200, 200, 40, 0.3)',
		GOOD: 'rgba(40, 200, 40, 0.3)'
	};

	const color = indicatorColors[coupling];

	return (
		<Row>
			<Col>
				<Indicator color={color} />
				<Image src={couplingImage} alt="" />
			</Col>
		</Row>
	);
}

CouplingDetail.propTypes = {
	coupling: PropTypes.oneOf(['POOR', 'FAIR', 'GOOD'])
};
