import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';

import { ResponseDataTypeChecker } from './services/ResponseDataTypeChecker';
import { BaseContext } from '@/components/ui/Inputs/types/request';
import { ManageCompanyService } from './services';
import {
	UpdateBaseRequest,
	CompanyResponse,
	FetchLinesRequest,
	FetchWorkstationsRequest,
	LineResponse,
	SectorResponse,
	WorkstationResponse,
	DeleteBaseRequest,
	CreateBaseRequest,
	FetchCompaniesRequest
} from './types';

const service = new ManageCompanyService();
const { isCompanyType, isLineType, isSectorType, isWorkstationType } = new ResponseDataTypeChecker();

export function useGetCompanies({ organization_id, company_id, searching_organization_id }: FetchCompaniesRequest) {
	const companies = useQuery(
		['companies_list', organization_id],
		() => service.fetchCompanies({ organization_id, company_id, searching_organization_id }),
		{
			enabled: !!organization_id && !!company_id && !!searching_organization_id
		}
	);
	return {
		...companies,
		data: companies.data ?? ([] as CompanyResponse[])
	};
}

export function useGetSectors({ organization_id, company_id }: BaseContext) {
	const sectors = useQuery(
		['sectors_list', organization_id, company_id],
		() => service.fetchSectors({ organization_id, company_id }),
		{
			enabled: !!organization_id && !!company_id
		}
	);
	return {
		...sectors,
		data: sectors.data ?? ([] as SectorResponse[])
	};
}

export function useGetLines({ organization_id, company_id, sector_id }: FetchLinesRequest) {
	const workstations = useQuery(
		['lines_list', organization_id, company_id, sector_id],
		() => service.fetchLines({ organization_id, company_id, sector_id }),
		{
			enabled: !!organization_id && !!company_id && !!sector_id
		}
	);
	return {
		...workstations,
		data: workstations.data ?? ([] as LineResponse[])
	};
}

export function useGetWorkstations({ organization_id, company_id, line_id }: FetchWorkstationsRequest) {
	const workstations = useQuery(
		['workstations_list', organization_id, company_id, line_id],
		() => service.fetchWorkstations({ organization_id, company_id, line_id }),
		{
			enabled: !!organization_id && !!company_id && !!line_id
		}
	);
	return {
		...workstations,
		data: workstations.data ?? ([] as WorkstationResponse[])
	};
}

export function useCreate<Body extends CreateBaseRequest, T>() {
	const queryClient = useQueryClient();
	return useMutation((body: Body) => service.create<Body, T>(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message ?? err.response.statusText));
		},
		onSuccess: (data) => {
			if (isCompanyType(data)) {
				queryClient.invalidateQueries(['companies']);
				queryClient.invalidateQueries(['companies_list']);
			}
			if (isSectorType(data)) {
				queryClient.invalidateQueries(['sectors_list']);
			}
			if (isLineType(data)) {
				queryClient.invalidateQueries(['lines_list']);
			}
			if (isWorkstationType(data)) {
				queryClient.invalidateQueries(['workstations_list']);
			}
			message.success(I18n.get('Data created successfully'));
		}
	});
}

export function useUpdate<Body extends UpdateBaseRequest, T>() {
	const queryClient = useQueryClient();
	return useMutation((body: Body) => service.update<Body, T>(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message ?? err.response.statusText));
		},
		onSuccess: (data) => {
			if (isCompanyType(data)) {
				queryClient.invalidateQueries(['companies']);
				queryClient.invalidateQueries(['companies_list']);
			}
			if (isSectorType(data)) {
				queryClient.invalidateQueries(['sectors_list']);
			}
			if (isLineType(data)) {
				queryClient.invalidateQueries(['lines_list']);
			}
			if (isWorkstationType(data)) {
				queryClient.invalidateQueries(['workstations_list']);
			}
			message.success(I18n.get('Data updated successfully'));
		}
	});
}

export function useDelete<Body extends DeleteBaseRequest, T>() {
	const queryClient = useQueryClient();
	return useMutation((body: Body) => service.delete<Body, T>(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message ?? err.response.statusText));
		},
		onSuccess: (data) => {
			if (isCompanyType(data)) {
				queryClient.invalidateQueries(['companies']);
				queryClient.invalidateQueries(['companies_list']);
			}
			if (isSectorType(data)) {
				queryClient.invalidateQueries(['sectors_list']);
			}
			if (isLineType(data)) {
				queryClient.invalidateQueries(['lines_list']);
			}
			if (isWorkstationType(data)) {
				queryClient.invalidateQueries(['workstations_list']);
			}
			message.success(I18n.get('Data deleted successfully'));
		}
	});
}
