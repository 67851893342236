import React, { useState } from 'react';
import { Col, Form, Image, Row, Skeleton } from 'antd';
import { Text, Title } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';

import { CustomImageContainer, CustomTaskContainer, TaskTitle } from './styles';
import { useApplicationContext } from '@/context/v1/Application/context';
import { LibertyMutualTaskDTO } from '../hooks/types/response';
import { useLibertyMutualSubStepsContext } from '../context';
import { tasksContentList } from '../tasksContentList';
import { useGetTasks } from '../hooks';

const { useFormInstance } = Form;

export const Task = () => {
	const { setFieldValue } = useFormInstance();
	const { libertyMutual } = useLibertyMutualSubStepsContext();
	const { organization, company } = useApplicationContext();

	const [selectedTask, setSelectedTask] = useState<string>(libertyMutual?.task?.id);

	const {
		data: tasks,
		isFetching,
		isError
	} = useGetTasks({
		organization_id: organization?.id,
		company_id: company?.id
	});

	if (isError) {
		return (
			<Row justify="center">
				<Col span={24}>
					<span>{I18n.get('Oops... Something went wrong!')}</span>
				</Col>
			</Row>
		);
	}

	function handleSelectTask(task: LibertyMutualTaskDTO) {
		if (task?.id === selectedTask) {
			return;
		}
		setSelectedTask(task?.id);
		setFieldValue('liberty_mutual_inputs', undefined);
		setFieldValue('task_id', task?.id);
		setFieldValue('task_name', task?.name);
	}

	const tasksCopy = [...tasks];
	const firstRow = tasksCopy.splice(0, 3);
	const secondRow = tasksCopy;

	return (
		<Row justify="center" gutter={[5, 20]}>
			<Col xs={20}>
				<Row align="middle">
					<Col xs={24}>
						<Title level={4}>Task</Title>
					</Col>
				</Row>
				<Row gutter={[0, 20]} justify="center">
					<Col xs={24} style={{ marginBottom: '20px' }}>
						<Text level={5}>Select the movement of the task being evaluated</Text>
					</Col>
				</Row>
				<Row justify="center" style={{ marginTop: '10px' }}>
					<Col xs={24} style={{ marginTop: '2rem' }}>
						<Row align="middle" justify="space-evenly" gutter={[0, 32]}>
							<Skeleton active loading={isFetching} />
							{!isFetching && (
								<Form.Item
									name={['task_id']}
									rules={[
										() => ({
											validator(_, value) {
												if (!value) {
													return Promise.reject(I18n.get('Select a task'));
												}
												return Promise.resolve();
											}
										})
									]}
								>
									<Row align="middle" justify="space-evenly" gutter={[0, 32]}>
										{firstRow.map((task) => {
											return (
												<CustomTaskContainer
													xs={7}
													key={task.id}
													selected={selectedTask === task.id}
													onClick={() => handleSelectTask(task)}
												>
													<Row justify="center" align="middle">
														<CustomImageContainer className="task">
															<Image
																preview={false}
																draggable={false}
																alt={I18n.get(task.description)}
																src={tasksContentList[task.name].img}
																width={tasksContentList[task.name].width}
															/>
															<TaskTitle>{I18n.get(task.description)}</TaskTitle>
														</CustomImageContainer>
													</Row>
													<Row justify="center">
														<CustomImageContainer className="task-hovered">
															<Image
																preview={false}
																draggable={false}
																alt={I18n.get(task.description)}
																width={tasksContentList[task.name].width}
																src={tasksContentList[task.name].img_hover}
															/>
															<TaskTitle>{I18n.get(task.description)}</TaskTitle>
														</CustomImageContainer>
													</Row>
												</CustomTaskContainer>
											);
										})}
									</Row>
									<Row align="middle" justify="center">
										{secondRow.map((task) => {
											return (
												<CustomTaskContainer
													xs={7}
													key={task.id}
													selected={selectedTask === task.id}
													style={{ margin: '1rem 1.5rem 0 0' }}
													onClick={() => handleSelectTask(task)}
												>
													<Row justify="center" align="middle">
														<CustomImageContainer className="task">
															<Image
																preview={false}
																draggable={false}
																alt={I18n.get(task.description)}
																src={tasksContentList[task.name].img}
																width={tasksContentList[task.name].width}
															/>
															<TaskTitle>{I18n.get(task.description)}</TaskTitle>
														</CustomImageContainer>
													</Row>
													<Row justify="center">
														<CustomImageContainer className="task-hovered">
															<Image
																preview={false}
																draggable={false}
																alt={I18n.get(task.description)}
																width={tasksContentList[task.name].width}
																src={tasksContentList[task.name].img_hover}
															/>
															<TaskTitle>{I18n.get(task.description)}</TaskTitle>
														</CustomImageContainer>
													</Row>
												</CustomTaskContainer>
											);
										})}
									</Row>
								</Form.Item>
							)}
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
