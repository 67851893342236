import { useMutation, useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import type { AxiosError } from 'axios';
import { message } from 'antd';

import Api from '@/services/api';
import type { CharacteristicResult, Response } from '@/types';
import type { CreateCharacteristicsDTO } from './useCreateCharacteristicsResult';

export type UpdateCharacteristicsDTO = CreateCharacteristicsDTO & {
	characteristic_id?: string;
};

async function updateCharacteristics(body: UpdateCharacteristicsDTO): Promise<CharacteristicResult> {
	const url = '/custom-report/result/characteristic';
	const { data } = await Api.put<CharacteristicResult>(url, body);
	return data;
}

export const useUpdateCharacteristicsResult = () => {
	const queryClient = useQueryClient();
	return useMutation<CharacteristicResult, AxiosError<Response>, UpdateCharacteristicsDTO>(
		(body: UpdateCharacteristicsDTO) => updateCharacteristics(body),
		{
			onError: (err) => {
				message.error(I18n.get(err?.response?.data?.message ?? err?.message));
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['jds_d86_report']);
				queryClient.invalidateQueries(['jds_d86_result']);
			}
		}
	);
};
