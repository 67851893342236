import styled from 'styled-components';

export const TableContainer = styled.div`
	max-width: 550px;
	margin-bottom: 2rem;

	@media (min-width: 1248px) {
		max-width: 750px;
	}

	@media (min-width: 1366px) {
		max-width: 900px;
	}
`;

export const CustomTable = styled.table`
	width: 100%;
	font-size: 0.8rem;
	margin-left: auto;
	font-weight: bold;
	margin-right: auto;
	border-collapse: collapse;

	thead {
		tr {
			color: white;
			:first-child {
				th {
					border-top: none;
					text-align: center;
					border: 2px solid #d4d4d4;

					:first-child() {
						border-right: 0.5rem solid red;
					}

					:nth-child(7) {
						border-right: 0.5rem solid #fff;
					}

					:nth-child(5),
					:nth-child(6),
					:nth-child(7) {
						border-bottom: none;
					}

					:not(:first-child) {
						padding: 0.25rem;
						background-color: #2242c8;
						div {
							height: 1rem;
							margin: auto;
							width: 1.5rem;
							display: flex;
							font-size: 0.5rem;
							text-align: center;
							justify-content: center;
							align-items: center;

							@media (min-width: 1248px) {
								width: 2rem;
								font-size: 0.7rem;
							}

							@media (min-width: 1366px) {
								width: 3rem;
								height: 3rem;
								font-size: 0.8rem;
							}
						}
					}

					:last-child {
						border-right: none;
						div {
							width: 2rem;
							font-size: 0.6rem;

							@media (min-width: 1248px) {
								width: 3rem;
								font-size: 0.8rem;
							}
						}
					}
				}
			}

			:last-child {
				th {
					height: 9rem;
					border-top: none;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					border: 2px solid #d4d4d4;
					background-color: #4260da;

					@media (min-width: 1248px) {
						height: 10rem;
					}

					@media (min-width: 1366px) {
						height: 11rem;
					}

					div {
						width: 2.2rem;
						height: 7rem;
						border: rgba(248, 2, 2, 0) solid 1px;

						@media (min-width: 1248px) {
							width: 3rem;
						}

						@media (min-width: 1366px) {
							width: 3.5rem;
						}

						@media (min-width: 1536px) {
							width: 4rem;
						}

						div {
							width: 8rem;
							height: 1rem;
							display: flex;
							padding: 0 0 0 0;
							overflow: visible;
							font-size: 0.6rem;
							justify-content: center;
							white-space: break-spaces;
							transform: rotate(-90deg);
							transform-origin: top left;
							margin: 7.5rem auto auto 0.3rem;
							border: rgba(0, 0, 0, 0) solid 1px;

							@media (min-width: 1248px) {
								font-size: 0.7rem;
								margin: 7.5rem auto auto 0.6rem;
							}

							@media (min-width: 1366px) {
								width: 9.5rem;
								font-size: 0.8rem;
								margin: 8rem auto auto 0.75rem;
							}

							@media (min-width: 1536px) {
								width: 9.5rem;
								font-size: 0.8rem;
								margin: 8rem auto auto 1rem;
							}
						}
					}

					:nth-child(1),
					:nth-child(3),
					:nth-child(4),
					:nth-child(5),
					:nth-child(6),
					:nth-child(8) {
						border-right: 2px solid #fff;
					}

					:last-child {
						border-right: 0.5rem solid #fff;
					}
				}
			}
		}
	}

	tbody {
		tr {
			td {
				width: 1rem;
				padding: 0.25rem;
				font-size: 0.6rem;
				text-align: center;
				border: 2px solid #d4d4d4;

				@media (min-width: 1248px) {
					font-size: 0.8rem;
				}

				:first-child {
					color: white;
					max-width: 3rem;
					overflow: hidden;
					border-left: none;
					font-weight: bold;
					white-space: nowrap;
					text-overflow: ellipsis;
					background-color: #2f54eb;
				}

				:last-child {
					border-right: none;
				}

				:nth-child(3),
				:nth-child(5),
				:nth-child(6),
				:nth-child(12),
				:nth-child(13) {
					background-color: #f1f1f1;
				}

				:nth-child(13) {
					border-right: 0.5rem solid #fff;
				}
			}

			:last-child {
				border-bottom: none;
				td {
					overflow: visible;
					position: relative;
					white-space: normal;
					border-bottom: none;

					:first-child {
						font-size: 0.7rem;
					}

					:last-child {
						background-color: #d5ddfb;
						div {
							left: 0;
							color: #fff;
							bottom: -80%;
							width: 2.5rem;
							font-size: 0.6rem;
							position: absolute;
							padding: 0.1rem 0.1rem;
							background-color: #2242c8;
							border-radius: 0 0 0.25rem 0.25rem;

							@media (min-width: 1248px) {
								width: 3.5rem;
								bottom: -75%;
							}
						}
					}
				}
			}
		}
	}
`;

export const EmptyHead = styled.th`
	margin: 0;
	padding: 0;
	text-align: center;
	position: relative;
	background-color: #4260da;
	border-left: none !important;

	div:first-child {
		margin: 0;
		padding: 0;
		width: 3rem;
		height: 11rem;
		background-color: #2242c8;
		clip-path: polygon(0 0, 100% 0, 100% 100%);

		@media (min-width: 1248px) {
			width: 4rem;
			height: 12rem;
		}

		@media (min-width: 1366px) {
			width: 5rem;
			height: 14.5rem;
		}
	}
`;

export const RiskFactor = styled.div`
	top: 5%;
	left: 25%;
	font-size: 0.5rem;
	position: absolute;

	@media (min-width: 1248px) {
		font-size: 0.6rem;
	}

	@media (min-width: 1366px) {
		font-size: 0.8rem;
	}
`;

export const TLID = styled.div`
	left: 15%;
	bottom: 5%;
	font-size: 0.5rem;
	position: absolute;

	@media (min-width: 1248px) {
		font-size: 0.6rem;
	}

	@media (min-width: 1366px) {
		font-size: 0.8rem;
	}
`;

export const RPN = styled.div`
	display: flex;
	margin-left: 1rem;
	align-items: center;
	flex-direction: column;
	height: 100% !important;
	justify-content: center;
`;
