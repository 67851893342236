import React from 'react';
import { Image, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useLibertyMutualSubStepsContext } from '../../../context';
import { TaskNameMapper } from '../../../hooks/types/response';
import { Body, CardContainer, Header } from '../styles';

const PUSH_IMAGE = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/result-push.svg';
const PULL_IMAGE = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/result-pull.svg';
const CARRY_IMAGE = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/result-carry.svg';

type Style = {
	[key: string]: {
		distance: React.CSSProperties;
		height: React.CSSProperties;
	};
};

interface DisplacementCardProps {
	task_name: TaskNameMapper.Pull | TaskNameMapper.Push | TaskNameMapper.Carry;
}

export const DisplacementCard: React.FC<DisplacementCardProps> = ({ task_name }) => {
	const {
		libertyMutualResult: { summary }
	} = useLibertyMutualSubStepsContext();

	const taskSVGMapper = {
		[TaskNameMapper.Push]: PUSH_IMAGE,
		[TaskNameMapper.Pull]: PULL_IMAGE,
		[TaskNameMapper.Carry]: CARRY_IMAGE
	};

	const stateSVGAbsoluteValues: Style = {
		[TaskNameMapper.Pull]: {
			distance: {
				fontWeight: 400,
				position: 'absolute',
				bottom: '5%',
				left: '43%'
			},
			height: {
				fontWeight: 400,
				position: 'absolute',
				bottom: '35%',
				left: '83%'
			}
		},
		[TaskNameMapper.Push]: {
			distance: {
				fontWeight: 400,
				position: 'absolute',
				bottom: '5%',
				right: '26%'
			},
			height: {
				fontWeight: 400,
				position: 'absolute',
				bottom: '33%',
				left: '58%'
			}
		},
		[TaskNameMapper.Carry]: {
			distance: {
				fontWeight: 400,
				position: 'absolute',
				bottom: '5%',
				right: '26%'
			},
			height: {
				fontWeight: 400,
				position: 'absolute',
				bottom: '33%',
				left: '50%'
			}
		}
	};

	return (
		<CardContainer
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between'
			}}
		>
			<Header>{I18n.get('Displacement')}</Header>
			<Body style={{ padding: '1rem 3rem 2rem 3rem' }}>
				<Row justify="center" align="middle">
					<Image
						width="180px"
						preview={false}
						draggable={false}
						alt={I18n.get(task_name)}
						src={taskSVGMapper[task_name]}
					/>
					<span style={stateSVGAbsoluteValues[task_name].distance}>{summary.horizontal_distance ?? 0}</span>
					<span style={stateSVGAbsoluteValues[task_name].height}>{summary.vertical_hand_height ?? 0}</span>
				</Row>
			</Body>
		</CardContainer>
	);
};
