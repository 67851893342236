import { useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import Api from '@/services/api';
import { RebaDataResponse } from './useGetCustomReport';
import type { Response, Vulnerability, BaseContext, PDFSections } from '@/types';

export type GetResultDTO = BaseContext & {
	file_id: string;
	version?: number;
	report_id: string;
	custom_report_result_id?: string;
	selected_pdf_sections?: PDFSections[];
	original_custom_report_result_id?: string;
};

export type Risk = {
	id: string;
	score: number;
	risk_name: string;
	risk_scale: number;
	description: string;
};

export type AdditionalItemResults = {
	id: string;
	result: number;
	value_1: string | number;
	value_2?: string | number;
	value_3?: string | number;
	value_4?: string | number;
	value_5?: string | number;
	value_6?: string | number;
};

export type Severity = {
	id: string;
	name: string;
	description: string;
};

export type Probability = {
	id: string;
	name: string;
	description: string;
};

export type Exposure = {
	id: string;
	name: string;
	description: string;
};

export type RiskCategory = {
	id: string;
	name: string;
	description: string;
};

export type RiskDescription = {
	id: string;
	name: string;
	description: string;
};

export type RiskDamage = {
	id: string;
	name: string;
	description: string;
};

export type StepKeyResultAdditionalItemTitles = {
	title: string;
	unit?: string;
};

export type StepKeyResultAdditionalItem = {
	titles: StepKeyResultAdditionalItemTitles[];
	results: AdditionalItemResults[];
};

export type SubStepKeyResult = {
	id: string;
	name: string;
	score?: number;
	description: string;
	risk_scale?: number;
	severity?: Severity;
	exposure?: Exposure;
	risk_damage?: RiskDamage;
	risk_category?: RiskCategory;
	vulnerability?: Vulnerability;
	risk_description?: RiskDescription;
};

export type StepKeyResult = {
	id: string;
	name: string;
	score?: number;
	description: string;
	risk_scale?: number;
	severity?: Severity;
	exposure?: Exposure;
	risk_damage?: RiskDamage;
	risk_category?: RiskCategory;
	vulnerability?: Vulnerability;
	sub_step_keys?: SubStepKeyResult[];
	risk_description?: RiskDescription;
	additional_items?: StepKeyResultAdditionalItem;
};

export type StepResult = {
	id: string;
	name: string;
	sequence: number;
	description: string;
	step_keys: StepKeyResult[];
};

export type WorkerSelfEvaluation = {
	id: string;
	name: string;
	description: string;
};

export type CharacteristicsResults = {
	id: string;
	working_population_men: number;
	working_population_women: number;
	working_population_others: number;
	total_working_population: number;
	particularities_description: string;
	worker_verbalization_description: string;
	working_population_men_percentage: number;
	working_population_women_percentage: number;
	working_population_others_percentage: number;
	worker_self_evaluation: WorkerSelfEvaluation;
};

export type WorkConditionsResult = {
	id: string;
	work_hours: number;
	work_minutes: number;
	place_description: string;
	expected_task_description: string;
	performed_task_description: string;
};

export type Deadline = {
	day: string;
	month: string;
	year: string;
	full_date: Date;
	remaining_days: number;
	remaining_days_text: string;
};

export type Tasks = {
	id: string;
	description: string;
	type: string;
};

export type ActionPlan = {
	id: string;
	title: string;
	risk_score: number;
	score: number;
	responsible: string;
	deadline: Deadline;
	description: string;
	tasks: Tasks[];
};

export type Niosh = {
	id: string;
	risk: string;
	mass_m: string;
	file_id: string;
	updatedAt: Date;
	conclusion: string;
	lifting_index: string;
	reference_weight: number;
	risk_description: string[];
	recommended_weight_limit: string;
};

export type KimManualHandling = {
	id: string;
	risk: string;
	score: number;
	duration: number;
	risk_load: string;
	risk_score: number;
	measurements: string;
	force_transfer: string;
	left_force_intensity: string;
	right_force_intensity: string;
	possible_health_consequences: string;
};

export type KimPushPull = {
	id: string;
	mass: number;
	note: number;
	score: number;
	vehicle: string;
	risk_range: string;
	measurements: string;
	distance: string | null;
	duration: number | null;
	possible_health_consequences: string;
	probability_of_physical_overload: string;
};

export type StrainIndex = {
	id: string;
	left_risk: string;
	right_risk: string;
	score_left_rsi: number;
	score_right_rsi: number;
	score_left_borg_scale: number;
	score_right_borg_scale: number;
	score_left_wrist_posture: number;
	score_right_wrist_posture: number;
	score_left_daily_duration: number;
	score_right_daily_duration: number;
	score_left_exertion_duration: number;
	score_right_exertion_duration: number;
	score_left_efforts_per_minute: number;
	score_right_efforts_per_minute: number;
};

export type FrequencyLibertyMutual = {
	type: string;
	value: string;
	interval: string;
};

export type LibertyMutualResponse = {
	id: string;
	task_name: string;
	system_of_units: string;
	unit: string;
	frequency: FrequencyLibertyMutual;
	object_weight?: string | null;
	horizontal_distance?: string | null;
	vertical_hand_height?: string | null;
	hand_coupling?: string | null;
	initial_force?: string | null;
	sustained_force?: string | null;
	percentile_man?: string | null;
	percentile_woman?: string | null;
	percentile_man_initial?: string | null;
	percentile_man_sustain?: string | null;
	percentile_woman_initial?: string | null;
	percentile_woman_sustain?: string | null;
};

export type ScoreBodyParts = {
	id: number;
	level: number;
	percentage: number;
	text: string;
};

export type BodyPartsName =
	| 'Trunk'
	| 'Neck'
	| 'Left lower arm'
	| 'Right lower arm'
	| 'Left upper arm'
	| 'Right upper arm'
	| 'Left knee'
	| 'Right knee';

export type RebaResponse = {
	id: string;
	file_id: string;
	force: number;
	coupling: number;
	repetition: number;
	score_seconds: number;
	data: RebaDataResponse[];
};

export type InformationsResult = {
	id: string;
	line_id: string;
	line_name: string;
	company_id: string;
	company_name: string;
	sector_id: string;
	sector_name: string;
	workstation_id: string;
	workstation_name: string;
	organization_id: string;
	organization_name: string;
	original_name: string;
	collection_date: string;
	evaluator_id: string;
	evaluator_name: string;
	interviewee_name: string;
	interviewer_name: string;
	uploaded_date: string;
	created_date: string;
	duration: string;
	sum_score: number;
	worst_values: Risk[];
};

export type BackCompressiveForceEstimationResult = {
	id: string;
	load_in_hands: string;
	person_height: string;
	person_weight: string;
	trunk_angle_to_horizontal: number;
	total_compressive_force_result: number;
	horizontal_distance_from_hands_to_low_back: string;
};

export type EwaJdsD86ResultResponse = {
	id: string;
	file_id: string;
	step: number;
	step_name: string;
	is_completed: boolean;
	consolidated: boolean;
	total_steps: number;
	comment: string;
	informations: InformationsResult;
	characteristics: CharacteristicsResults;
	work_conditions: WorkConditionsResult;
	steps: StepResult[];
	niosh: Niosh;
	kim_mho: KimManualHandling;
	kim_pp: KimPushPull;
	strain_index: StrainIndex;
	back_compressive_force_estimation?: BackCompressiveForceEstimationResult;
	liberty_mutual: LibertyMutualResponse[];
	reba: RebaResponse;
	action_plans: ActionPlan[];
	tools_to_show: string[];
};

async function getResults(payload: GetResultDTO): Promise<EwaJdsD86ResultResponse> {
	const url = '/custom-report/result/find-one';
	const { data } = await Api.get<EwaJdsD86ResultResponse>(url, {
		params: payload
	});
	return data;
}

export const useGetCustomReportResults = ({
	version,
	file_id,
	report_id,
	company_id,
	organization_id,
	selected_pdf_sections,
	custom_report_result_id,
	original_custom_report_result_id
}: GetResultDTO) => {
	const parameters = {
		version,
		file_id,
		report_id,
		company_id,
		organization_id,
		selected_pdf_sections,
		custom_report_result_id,
		original_custom_report_result_id
	};

	if (Number(parameters.version) < 1) {
		delete parameters.version;
	}

	const report = useQuery<EwaJdsD86ResultResponse, AxiosError<Response>>(
		['jds_d86_result', parameters],
		() => getResults(parameters),
		{
			enabled: !!organization_id && !!company_id && !!file_id && !!report_id && !!custom_report_result_id
		}
	);

	return {
		...report,
		data: report.data ?? ({} as EwaJdsD86ResultResponse)
	};
};
