import { useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import Api from '@/services/api';
import type { BaseContext, Response, User } from '@/types';

async function getUsers(params: BaseContext): Promise<User[]> {
	const url = `/user/user-list/${params.organization_id}/${params.company_id}`;
	const { data } = await Api.get<User[]>(url, {
		params
	});
	return data;
}

export const useGetUsers = ({ organization_id, company_id }: BaseContext) => {
	const users = useQuery<User[], AxiosError<Response>>(
		['responsible_users', { organization_id, company_id }],
		() => getUsers({ organization_id, company_id }),
		{
			enabled: !!organization_id && !!company_id
		}
	);
	return {
		...users,
		data: users.data || ([] as User[])
	};
};
