import React from 'react';
import { Row, Col, Skeleton } from 'antd';
import { PreviousCol } from '../../styles';

export const LoadingSkeleton = () => (
	<>
		<Row justify="center" gutter={[5, 20]}>
			<Col xs={21}>
				<Row align="middle">
					<Col xs={24}>
						<Skeleton.Input active />
					</Col>
					<Col xs={24} style={{ marginBottom: '20px' }}>
						<Skeleton active paragraph />
					</Col>
				</Row>
				<Row justify="center">
					<Col span={23}>
						<Row justify="start" style={{ marginBottom: '2rem' }}>
							<Col span={24}>
								<Skeleton.Input active />
							</Col>
							<Col>
								<Row gutter={[16, 16]}>
									<Col>
										<Skeleton.Input active block />
									</Col>
									<Col>
										<Skeleton.Input active block />
									</Col>
									<Col>
										<Skeleton.Input active block />
									</Col>
									<Col>
										<Skeleton.Input active block />
									</Col>
								</Row>
							</Col>
						</Row>
						<Row justify="center" gutter={[16, 16]} style={{ marginBottom: '2rem' }}>
							<Skeleton active paragraph />
							<Skeleton active paragraph />
						</Row>
						<Row justify="start">
							<Col span={24}>
								<Skeleton.Input active />
							</Col>
							<Col span={24}>
								<Skeleton active />
							</Col>
							<Skeleton.Input active block />
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
		<Row>
			<Col xs={24} style={{ marginTop: '2rem' }}>
				<Row justify="center" align="middle" gutter={[0, 8]}>
					<PreviousCol>
						<Skeleton.Button active block />
					</PreviousCol>
					<Col>
						<Skeleton.Button active block />
					</Col>
				</Row>
			</Col>
		</Row>
	</>
);
