import React, { useState } from 'react';
import { EyeInvisibleOutlined, EyeOutlined, PlusCircleFilled } from '@ant-design/icons';
import { Table, Button, Row, Col, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import { SeraSummaryList, SeraSummaryListDTO, SeraReport } from './hooks/types/response';
import { CustomTable } from './styles';
import { SubTable } from './SubTable';
import { Actions } from './Actions';
import { useHistory } from 'react-router-dom';

type ReportListProps = {
	page: number;
	isLoading: boolean;
	maxReports: number;
	data: SeraSummaryListDTO;
	onPageChange: (page: number) => void;
	onDeleteSummary: (id: string) => void;
	onMaxReportsChange: (size: number) => void;
};

export const ReportList: React.FC<ReportListProps> = ({
	data,
	page,
	isLoading,
	maxReports,
	onPageChange,
	onDeleteSummary,
	onMaxReportsChange
}: ReportListProps) => {
	const history = useHistory();
	const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

	const columns: ColumnsType<SeraSummaryList> = [
		{
			title: I18n.get('Report'),
			dataIndex: 'report_name',
			key: 'report_name',
			ellipsis: true,
			align: 'center',
			sorter: (a, b) => sortByName(a.report_name, b.report_name)
		},
		{
			title: I18n.get('Workstation'),
			dataIndex: 'workstation_name',
			key: 'workstation_name',
			ellipsis: true,
			align: 'center',
			render: (workstation_name: string) => workstation_name ?? '-',
			responsive: ['lg'],
			sorter: (a, b) => sortByName(a.workstation_name, b.workstation_name)
		},
		{
			title: I18n.get('Cycle'),
			dataIndex: 'cycle_name',
			key: 'cycle_name',
			ellipsis: true,
			align: 'center',
			render: (cycle_name: string) => cycle_name ?? '-',
			responsive: ['md'],
			sorter: (a, b) => sortByName(a.cycle_name, b.cycle_name)
		},
		{
			title: I18n.get('Selected Files'),
			dataIndex: 'sera_report',
			key: 'sera_report',
			ellipsis: true,
			align: 'center',
			render: (reports: SeraReport[], data) => (reports ? reportsList(reports, data) : []),
			responsive: ['lg']
		},
		{
			title: I18n.get('Created date'),
			dataIndex: 'created_at',
			key: 'created_at',
			ellipsis: true,
			align: 'center',
			responsive: ['lg'],
			render: (created_at) => (created_at ? moment(created_at).format('L - h:mm:ss A') : '--/--/----'),
			sorter: (a, b) => sortByDate(a.created_at, b.created_at)
		},
		{
			title: I18n.get('Reviewed date'),
			dataIndex: 'reviewed_at',
			key: 'reviewed_at',
			ellipsis: true,
			align: 'center',
			responsive: ['xl'],
			render: (reviewed_at) => (reviewed_at ? moment(reviewed_at).format('L - h:mm:ss A') : '--/--/----'),
			sorter: (a, b) => sortByDate(a.reviewed_at, b.reviewed_at)
		},
		{
			title: I18n.get('Actions'),
			key: 'actions',
			align: 'center',
			render: (_value, rowData) => (
				<Actions
					rowData={rowData}
					onDeleteActionClick={onDeleteSummary}
					onEditActionClick={handleEditSummary}
					onReviewActionClick={handleReviewSummary}
				/>
			)
		}
	];

	function handlePaginationChange(page: number) {
		onPageChange(page);
	}

	function handleShowSizeChange(size: number) {
		onMaxReportsChange(size);
	}

	function sortByName(first: string | undefined, second: string | undefined) {
		if (!first || !second) {
			return -1;
		}
		return ('' + first).localeCompare(second);
	}

	function reportsList(reports: SeraReport[], data: any): JSX.Element {
		if (reports?.length === 0 || !reports[0]) {
			return <span>-</span>;
		}
		return (
			<Button
				type="link"
				onClick={() => handleExpand(data)}
				icon={
					expandedRowKeys.includes(data.key) ? (
						<EyeInvisibleOutlined style={{ fontSize: '1.5rem' }} />
					) : (
						<EyeOutlined style={{ fontSize: '1.5rem' }} />
					)
				}
			/>
		);
	}

	function handleExpand(record: any) {
		const rowIndex = expandedRowKeys.indexOf(record.key);
		const updateKeys = [...expandedRowKeys];
		if (rowIndex < 0) {
			updateKeys.push(record.key);
			setExpandedRowKeys(updateKeys);
			return;
		}
		updateKeys.splice(rowIndex, 1);
		setExpandedRowKeys(updateKeys);
	}

	function handleRowClick(sera_report: SeraReport[]) {
		return <SubTable data={sera_report} />;
	}

	function handleReviewSummary(summaryData: SeraSummaryList) {
		history.push(`/custom-reports/sera/review/${summaryData.id}`);
	}

	function handleEditSummary(summaryData: SeraSummaryList) {
		history.push(`/custom-reports/sera/report/${summaryData.id}`);
	}

	function handleCreateSeraClick() {
		history.push('/custom-reports/sera/report');
	}

	const dataSource = data.rows
		?.map((report, index) => ({
			...report,
			key: String(index + 1)
		}))
		.sort((a, b) => sortByDate(a.created_at, b.created_at));

	function sortByDate(date_a: Date, date_b: Date): number {
		return moment.utc(date_b).diff(moment.utc(date_a));
	}

	return (
		<Row justify="center">
			<CustomTable span={24}>
				<Table
					showSorterTooltip={false}
					rowKey="key"
					expandable={{
						onExpand: () => {},
						expandedRowKeys,
						expandRowByClick: true,
						expandIcon: () => undefined,
						expandIconColumnIndex: -1,
						expandedRowRender: (reports) => handleRowClick(reports.sera_report),
						rowExpandable: (reports) => reports?.sera_report?.length > 0
					}}
					dataSource={dataSource}
					columns={columns}
					loading={isLoading}
					pagination={{
						hideOnSinglePage: false,
						showSizeChanger: true,
						total: data?.count,
						current: page === 0 ? page + 1 : page,
						defaultPageSize: maxReports,
						pageSizeOptions: ['5', '10', '20', '30', '50', '100', '200'],
						pageSize: maxReports,
						onChange: (page) => {
							handlePaginationChange(page);
						},
						onShowSizeChange: (_, size) => handleShowSizeChange(size)
					}}
				/>
			</CustomTable>
			<Col span={2}>
				<Row justify="center">
					<Tooltip title={I18n.get('Create S.E.R.A. report')}>
						<PlusCircleFilled
							onClick={handleCreateSeraClick}
							style={{
								fontSize: '32px',
								color: '#2F54EB',
								marginTop: data?.rows?.length > 0 ? 0 : '1rem'
							}}
						/>
					</Tooltip>
				</Row>
			</Col>
		</Row>
	);
};
