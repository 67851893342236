import Api from '@/services/api';

import { CustomReportStepsRequest } from '@/components/ui/Inputs/types/request/customReport';
import { CustomReportStepDTO } from '@/components/ui/Inputs/types/response/customReport';

export class CustomReportService {
	async getCustomReportsSteps({
		organization_id,
		company_id,
		custom_report_id
	}: CustomReportStepsRequest): Promise<CustomReportStepDTO[]> {
		const url = `/custom-report/step/report/${custom_report_id}?organization_id=${organization_id}&company_id=${company_id}`;
		const { data } = await Api.get<CustomReportStepDTO[]>(url);
		return data;
	}
}
