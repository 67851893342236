import React, { createContext, ReactNode, useContext } from 'react';
import { StepKeyResult } from '@/hooks';
import type { Context, Methods, States } from './types';

type StepKeysResultsProviderProps = {
	children: ReactNode;
	stepKey: StepKeyResult;
};

const StepKeysResultsContext = createContext<Context>({} as Context);

export const StepKeysResultsProvider: React.FC<Readonly<StepKeysResultsProviderProps>> = ({ children, stepKey }) => {
	const states: States = { stepKey };
	const methods: Methods = {};
	const context: Context = {
		...states,
		...methods
	};

	return <StepKeysResultsContext.Provider value={context}>{children}</StepKeysResultsContext.Provider>;
};

export function useStepKeysResultsContext() {
	const context = useContext(StepKeysResultsContext);
	return context;
}
