import React from 'react';
import { Text } from '@/components/Typography';
import { Col, Row } from 'antd';

import { DisplacementCard, FrequencyCard, InitialForceCard, SustainedForceCard } from './Cards';
import { SummaryResult, TaskNameMapper } from '../../../hooks/types/response';

interface PushPullSummaryProps {
	summary: SummaryResult;
	task_name: TaskNameMapper;
}

export const PushPullSummary: React.FC<PushPullSummaryProps> = ({ summary, task_name }) => {
	if (!isPull(task_name) && !isPush(task_name)) {
		return <Text>Oops... Something went wrong!</Text>;
	}

	function isPull(task_name: TaskNameMapper): task_name is TaskNameMapper.Pull {
		return task_name === TaskNameMapper.Pull;
	}

	function isPush(task_name: TaskNameMapper): task_name is TaskNameMapper.Push {
		return task_name === TaskNameMapper.Push;
	}

	return (
		<Row>
			<Col xs={24}>
				<Row justify="center" style={{ marginBottom: '1rem' }}>
					<Col xs={20} style={{ maxWidth: '300px' }}>
						<Row justify="center">
							<Col xs={15} style={{ maxWidth: '300px' }}>
								<DisplacementCard summary={summary} task_name={task_name} />
							</Col>
						</Row>
					</Col>
				</Row>
				<Row justify="center">
					<Col xs={24} lg={20} style={{ maxWidth: '900px' }}>
						<Row justify="space-between">
							<Col xs={7} style={{ maxWidth: '250px' }}>
								<FrequencyCard summary={summary} />
							</Col>
							<Col xs={7} style={{ maxWidth: '250px' }}>
								<InitialForceCard summary={summary} task_name={task_name} />
							</Col>
							<Col xs={7} style={{ maxWidth: '250px' }}>
								<SustainedForceCard summary={summary} task_name={task_name} />
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
