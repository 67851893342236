import styled from 'styled-components';
import { Button, Col, Tooltip } from 'antd';

export const PreviousCol = styled(Col)`
	padding: 0;

	margin-right: 1rem;
	@media (min-width: 576px) {
		margin-right: 4rem;
	}
`;

export const CustomFormContainer = styled(Col)`
	padding: 2rem 1rem;
	margin-left: 2rem;
	border-radius: 1rem;
	background-color: #f8f8f8;
`;

export const CustomTooltip = styled(Tooltip)`
	position: relative;
	padding: 0 0.7rem;
	background-color: #cdd5f3;
	border-radius: 0.25rem;
`;

export const CustomButton = styled(Button)`
	left: -9rem;
	bottom: -0.25rem;
	position: absolute;
	margin-bottom: 1rem;
	transition: all 0.2s;

	:hover {
		filter: brightness(0.9);
		background-color: #cdd5f3;
	}

	@media (min-width: 1470px) {
		margin-left: 0.8rem;
	}
`;
