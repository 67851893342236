import React, { useEffect, useState } from 'react';
import { Button, Col, Form, InputNumber, Row, Slider, SliderSingleProps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';

import { useCalculateAdditionalToolScoreWithPostRoute, useGetAdditionalItemsOptions } from '@/hooks';
import { useApplicationContext } from '@/context/v1/Application/context';
import type { AdditionalItem, GetAdditionalToolScoreDTO } from '@/hooks';
import { worstRiskCalculator } from './utils/WorstResultCalculator';
import { useStepKeysContext } from '../DefaultSelects/context';
import { AditionalItemProps, FormFieldValues } from './types';
import { fieldsUnitTexts } from './utils/FieldsUnitTexts';
import { ConditionCard } from '../../ConditionCard';
import { Text } from '@/components/Typography';
import { InfoList } from './InfoList';

const { useFormInstance, useWatch } = Form;
const { formatLabelWithUnit, formatPlaceholderWithUnit } = fieldsUnitTexts;

export const SliderAndInput: React.FC<AditionalItemProps> = ({ additionalItem }) => {
	const { id, title_1, title_2, unit_1, unit_2 } = additionalItem;

	const form = useFormInstance();
	const { formFieldName, stepKey } = useStepKeysContext();
	const { company, organization } = useApplicationContext();

	const [sliderAndInput, setSliderAndInput] = useState<AdditionalItem>(additionalItem);

	const formValueOne = useWatch([...formFieldName, 'additional_items', id, 'value_1']);
	const formValueTwo = useWatch([...formFieldName, 'additional_items', id, 'value_2']);

	useEffect(() => {
		form.setFieldValue([...formFieldName, 'additional_items', 'local_values'], additionalItem);
	}, []);

	const { mutateAsync: calculateAdditionalToolScore, isLoading: isLoadingCalculateAdditionalToolScore } =
		useCalculateAdditionalToolScoreWithPostRoute();
	const { data: options, isFetching: isGettingAdditionalItemsOptions } = useGetAdditionalItemsOptions({
		company_id: company?.id,
		organization_id: organization?.id,
		custom_report_step_key_id: stepKey.id
	});

	const marks: SliderSingleProps['marks'] = options.reduce(
		(optionsMapped, { name }) => ({
			...optionsMapped,
			[name]: ' '
		}),
		{} as Record<string, string>
	);

	const marksKeys = Object.keys(marks);
	const lastMark = marksKeys[options.length - 1];

	const worstRisk = worstRiskCalculator.getWorstResult(sliderAndInput.results);

	const sliderLabel = formatLabelWithUnit(title_1, unit_1);
	const inputPlaceholder = formatPlaceholderWithUnit('0', unit_2);

	async function handleAddAdditionalItemValueClick(): Promise<void> {
		const payload: GetAdditionalToolScoreDTO = {
			value_1: formValueOne,
			value_2: formValueTwo,
			company_id: company?.id,
			organization_id: organization?.id,
			custom_report_step_key_id: stepKey.id
		};

		const additionalToolScore = await calculateAdditionalToolScore(payload);

		if (additionalToolScore?.result) {
			const formFieldValues: FormFieldValues = form.getFieldValue([...formFieldName, 'additional_items', id]);
			const newAdditionalItemValue = {
				...formFieldValues,
				id: uuidv4(),
				result: additionalToolScore.result
			};

			const results = sliderAndInput?.results ?? [];
			const additionalItemResultsCopy = [...results];
			additionalItemResultsCopy.push(newAdditionalItemValue);

			setSliderAndInput({
				...sliderAndInput,
				results: additionalItemResultsCopy
			});
			form.setFieldValue(
				[...formFieldName, 'additional_items', 'local_values', 'results'],
				additionalItemResultsCopy
			);
			form.setFieldValue([...formFieldName, 'additional_items', additionalItem.id], undefined);
		}
	}

	function handleRemoveAdditionalItemClick(id: string): void {
		const resultsWithoutRemovedItem = sliderAndInput.results.filter((item) => item.id !== id);
		const newResults = {
			...sliderAndInput,
			results: resultsWithoutRemovedItem
		};

		setSliderAndInput(newResults);
		form.setFieldValue(
			[...formFieldName, 'additional_items', 'local_values', 'results'],
			resultsWithoutRemovedItem
		);
	}

	return (
		<Row gutter={[0, 15]}>
			<Col span={22}>
				<Row gutter={[16, 0]}>
					<Col xs={22} xxl={14}>
						<Row gutter={[5, 0]}>
							<Col>
								<Text strong>{sliderLabel}</Text>
							</Col>
						</Row>
						<Row gutter={[14, 0]} style={{ marginTop: '10px' }}>
							<Col span={16}>
								<Form.Item
									style={{ margin: 0 }}
									name={[...formFieldName, 'additional_items', id, 'value_1']}
								>
									<Slider min={0} marks={marks} max={Number(lastMark)} step={null} />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item
									style={{ margin: 0 }}
									name={[...formFieldName, 'additional_items', id, 'value_1']}
								>
									<InputNumber disabled placeholder="0" style={{ width: '60px' }} />
								</Form.Item>
							</Col>
						</Row>
					</Col>
					<Col xs={24} xxl={10}>
						<Row gutter={[5, 0]}>
							<Col>
								<Text strong>{title_2}</Text>
							</Col>
						</Row>
						<Row style={{ marginTop: '10px' }}>
							<Col span={16}>
								<Form.Item
									style={{ margin: 0 }}
									name={[...formFieldName, 'additional_items', id, 'value_2']}
								>
									<InputNumber placeholder={inputPlaceholder} min={0} style={{ width: '80%' }} />
								</Form.Item>
							</Col>
							<Col>
								<Button
									type="primary"
									shape="circle"
									icon={<PlusOutlined />}
									onClick={handleAddAdditionalItemValueClick}
									disabled={!formValueOne || !formValueTwo || isLoadingCalculateAdditionalToolScore}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<InfoList items={sliderAndInput} onDeleteValue={handleRemoveAdditionalItemClick} />
			</Col>
			<Col span={24}>
				<ConditionCard result={worstRisk} isLoading={isGettingAdditionalItemsOptions} />
			</Col>
		</Row>
	);
};
