import Api from '@/services/api';

import { SeraSummaryListRequest, DeleteSeraSummaryRequest, DownloadFileRequest } from './types/request';
import { SeraSummaryDTO, SeraSummaryListDTO } from './types/response';

export class SeraService {
	async getSeraSummaryList({
		organization_id,
		company_id,
		filter
	}: SeraSummaryListRequest): Promise<SeraSummaryListDTO> {
		const url = `/sera/summary/list?organization_id=${organization_id}&company_id=${company_id}`;
		const { data } = await Api.get<SeraSummaryListDTO>(url, {
			params: filter
		});
		return data;
	}

	async deleteSeraSummary({ organization_id, company_id, id }: DeleteSeraSummaryRequest): Promise<SeraSummaryDTO> {
		const url = `/sera/summary/${id}?organization_id=${organization_id}&company_id=${company_id}`;
		const { data } = await Api.delete<SeraSummaryDTO>(url);
		return data;
	}

	async downloadFile({ company_id, organization_id, file }: DownloadFileRequest): Promise<string> {
		const url = '/upload/create-url-download';
		const { data } = await Api.post<string>(url, {
			file_id: file.id,
			company_id,
			original_name: file.original_name,
			organization_id
		});
		return data;
	}
}
