import React, { useEffect } from 'react';
import { Col, Form, Row, Skeleton } from 'antd';
import { I18n } from '@aws-amplify/core';
import { v4 as uuidv4 } from 'uuid';

import type { AdditionalItemsResults } from '@/hooks';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useStepKeysContext } from '../DefaultSelects/context';
import { useGetAdditionalItemsOptions } from '@/hooks';
import { ConditionCard } from '../../ConditionCard';
import { CustomRadioGroupOptions } from '../../styles';
import { Text } from '@/components/Typography';
import { AditionalItemProps } from './types';

const { useWatch, useFormInstance } = Form;

type AdditionalItemIds = {
	[key: string]: number | undefined
}

const getResult = (filteredValues: [string, number][], totalLength: number) => {
	if (totalLength !== filteredValues.length) {
		return 0
	}

	const result = filteredValues?.some(([, value]) => value === 1) ? 3 : 1

	return result
}

export const GroupRadio: React.FC<AditionalItemProps> = ({ additionalItem }) => {
	const { id } = additionalItem;

	const form = useFormInstance();
	const { formFieldName, stepKey } = useStepKeysContext();
	const { organization, company } = useApplicationContext();

	const defaultOptions = [
		{
			value: 1,
			label: I18n.get('Yes')
		},
		{
			value: 0,
			label: I18n.get('No')
		}
	]

	const additionalItemOptionIds: AdditionalItemIds = useWatch([...formFieldName, 'additional_items', id, 'additional_item_option_id_1']) || {};
	const checkedValues = Object.entries(additionalItemOptionIds).filter(([, value]) => typeof value !== 'undefined') as [string, number][];

	const {
		data: options,
		isLoading: isGettingRadioOptions,
		isFetching: isFetchingRadioOptions
	} = useGetAdditionalItemsOptions({
		company_id: company?.id,
		organization_id: organization?.id,
		custom_report_step_key_id: stepKey.id
	});

	useEffect(() => {
		if (!additionalItem.results.length) {
			return
		}
		const previousValues: AdditionalItemIds = additionalItem.results.reduce((a, b) => {
			const key = b.additional_item_option_id_1 as string;
			a[key] = b.value_1

			return a
		}, {} as AdditionalItemIds)

		form.setFieldValue([...formFieldName, 'additional_items', id, 'additional_item_option_id_1'], previousValues);
	}, [additionalItem.results]);

	useEffect(() => {
		const hasChangedValues = checkedValues.some(([key, value]) => {
			const previousValues = additionalItem.results.find(f => {
				return f.additional_item_option_id_1 === key && f.value_1 === value
			});

			return !previousValues
		})

		if (!hasChangedValues || checkedValues.length !== options.length) {
			return
		}


		const result: AdditionalItemsResults[] = checkedValues.map(([key, value]) => {
			const item = options.find(f => f.id === key);
			const previousValue = additionalItem.results.find(f => f.additional_item_option_id_1 === key);

			const id = previousValue?.value_1 === value ? previousValue.id : uuidv4();
			const score = item?.score || 0;

			return {
				id,
				value_1: value,
				additional_item_option_id_1: key,
				result: value === 1 ? 1 : score,
			}
		})

		form.setFieldValue([...formFieldName, 'additional_items', id, 'results'], result);
	}, [additionalItemOptionIds]);

	const renderOptions = () => {
		return options.map(m => {
			const defaultValues = additionalItem.results.find(f => f.additional_item_option_id_1 === m.id);

			return <>
				<Col span={18}>
					<Text strong>{I18n.get(`${m.description}`)}</Text>
				</Col>
				<Col span={16}>
					<Form.Item name={[...formFieldName, 'additional_items', id, 'additional_item_option_id_1', m.id]}>
						<CustomRadioGroupOptions defaultValue={defaultValues?.value_1} options={defaultOptions} />
					</Form.Item>
				</Col>
			</>
		})
	}

	if (isGettingRadioOptions) {
		return (
			<Row gutter={[0, 15]}>
				<Col span={18}>
					<Skeleton active />
				</Col>
				<Col span={16}>
					<Skeleton active paragraph />
				</Col>
				<Col span={22}>
					<Skeleton active />
				</Col>
			</Row>
		);
	}

	return (
		<Row gutter={[0, 15]} key={id}>
			{renderOptions()}
			<Col span={22}>
				<ConditionCard
					result={getResult(checkedValues, options.length)}
					isLoading={isGettingRadioOptions || isFetchingRadioOptions}
				/>
			</Col>
		</Row>
	);
};
