import React from 'react';
import { PlusCircleFilled } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'antd';

import { useLibertyMutualContext } from '../../context';
import { CustomList } from './styles';
import { Reports } from './Reports';
import { Text, Title } from '@/components/Typography';

export const TaskList = () => {
	const history = useHistory();
	const { file_id, libertyMutual, custom_report } = useLibertyMutualContext();

	function handleCreateReportClick() {
		let url = `/reporting/report/liberty-mutual/${file_id}/${libertyMutual.id}`;
		if (custom_report) {
			url = `${url}?custom_report=${custom_report}`;
		}
		history.push(url);
	}

	return (
		<Row justify="center">
			<Col xs={20}>
				<Row align="middle">
					<Col xs={24}>
						<Title level={4}>Movements</Title>
					</Col>
					<Col xs={24} style={{ textAlign: 'start' }}>
						<Text>Individually evaluate the movements present in the task</Text>
					</Col>
				</Row>
			</Col>
			<Col xs={24} style={{ marginTop: '2rem' }}>
				{libertyMutual.reports?.length > 0 && (
					<Row justify="center">
						<Col xs={10}>
							<CustomList xs={22}>
								<Reports />
							</CustomList>
						</Col>
					</Row>
				)}
				<Row justify="center">
					<PlusCircleFilled
						onClick={handleCreateReportClick}
						style={{
							fontSize: '32px',
							color: '#2F54EB',
							marginRight: '0.5rem',
							marginTop: libertyMutual.reports?.length === 0 ? 0 : '1rem'
						}}
					/>
				</Row>
			</Col>
		</Row>
	);
};
