import React, { useEffect } from 'react';
import { Form, InputNumber } from 'antd';
import { TextInput } from './TextInput';

const { useFormInstance, useWatch } = Form;

interface TotalWorkingPopulationProps {
	allFields: string[];
	fieldName: string;
}

export const TotalWorkingPopulation: React.FC<TotalWorkingPopulationProps> = ({ allFields, fieldName }) => {
	const { setFieldValue } = useFormInstance();
	const getAllFields = useWatch([fieldName]);

	useEffect(() => {
		const count = allFields.reduce((acc, values) => getAllFields?.[values] + acc, 0);
		setFieldValue([fieldName, 'total_working_population'], count);
	}, [allFields, getAllFields]);

	return (
		<TextInput namePath={[fieldName, 'total_working_population']} title="Total">
			<InputNumber disabled={true} min={0} />
		</TextInput>
	);
};
