import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message, notification } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CreateLineRequest } from '@/components/ui/Inputs/types/request/line';
import Api from '@/services/api';

export const useCreateLine = () => {
	const queryClient = useQueryClient();
	return useMutation(({ body }: { body: CreateLineRequest }) => Api.post('/line/create', body), {
		onError: (err: any) => {
			notification.error({
				message: I18n.get(err?.response?.data?.message || 'Ops... something happened!')
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['lines']);
			queryClient.invalidateQueries(['lines_list']);
			message.success(I18n.get('Line created successfully'));
		}
	});
};
