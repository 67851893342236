import React, { useEffect, useState } from 'react';
import { Grid, Layout } from 'antd';

interface SiderProps {
	children: React.ReactNode;
}

export const Sider: React.FC<SiderProps> = ({ children }) => {
	const [collapsed, setCollapsed] = useState<boolean>(false);

	const breakpoints = Grid.useBreakpoint();

	useEffect(() => {
		!breakpoints.xl ? setCollapsed(true) : setCollapsed(false);
	}, [breakpoints]);

	function handleCollapse(): void {
		setCollapsed((prev) => !prev);
	}

	return (
		<Layout.Sider
			id="sider"
			width={250}
			collapsible
			theme="light"
			collapsed={collapsed}
			onCollapse={handleCollapse}
		>
			{children}
		</Layout.Sider>
	);
};
