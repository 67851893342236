import React, { ReactElement } from 'react';
import { Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

import { RequiredLabel } from './RequiredLabel';
import { NamePath } from 'antd/lib/form/interface';
import { CustomFormInputs } from './styles';

interface ChildrenProps {
	size: SizeType;
	placeholder?: string;
}

interface FormItemProps {
	title: string;
	fontSize?: string;
	namePath: NamePath;
	isRequired?: boolean;
	errorMessage?: string;
	children: ReactElement<ChildrenProps>;
}

export function FormItem({ title, namePath, fontSize, children, errorMessage, isRequired = true }: FormItemProps) {
	const error = errorMessage ?? `Select the ${title}`;
	return (
		<Col xs={24}>
			<CustomFormInputs
				name={namePath}
				fontSize={fontSize}
				labelCol={{ span: 24 }}
				label={<RequiredLabel title={I18n.get(title)} isRequired={isRequired} />}
				rules={[
					{
						required: isRequired,
						message: I18n.get(error)
					}
				]}
			>
				{children}
			</CustomFormInputs>
		</Col>
	);
}
