import styled from 'styled-components'

// export const Card = styled.div`
// 	background-color: white;
// 	div {
// 		padding: 5px 5px 5px 5px;
// 	}
// `

export const Title = styled.div`
	display: inline-block;
	margin-right: 5px;
`

export const Icon = styled.div`
	display: inline-block;
`
