import React from 'react';
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';
import { Row, Col, Card, Space } from 'antd';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { getworstGrade } from '../../../services';
import { ScoreBar } from '@/components/ui/ScoreBar';
import { Title, Grade, ScoresContainer, BarContainer, Label } from './styles';

const grades = [
	{ title: 'Not identified', color: 'rgba(113, 113, 113, 0.5)' },
	{ title: 'Safe', color: 'rgba(75, 192, 86, 0.5)' },
	{ title: 'Info', color: 'rgba(255, 206, 86, 0.5)' },
	{ title: 'Warning', color: 'rgba(255, 40, 72, 0.5)' },
	{ title: 'Danger', color: 'rgba(153, 102, 255, 0.5)' }
];

export const ExposureAverage = ({ scores }) => {
	const { file_id } = useParams();
	const organization = useSelector((state) => state.organization.organization);
	const company = useSelector((state) => state.organization.company);

	const parameters = {
		organization_id: organization.id,
		company_id: company.id,
		file_id: file_id
	};

	const { isLoading, data, isError } = useQuery(['reba-worst-grade', parameters], () => getworstGrade(parameters));

	const WorstGrade = ({ isLoading, isError, children }) => {
		return (
			<Space size={[5, 10]} direction="horizontal" style={{ display: 'flex', justifyContent: 'center' }}>
				<Card size="small" title={I18n.get('REBA Score')} headStyle={{ backgroundColor: '#fafafa' }}>
					<Grade style={{ margin: 0 }}>{children?.worst_grade}</Grade>
				</Card>
				<Card
					size="small"
					title={I18n.get('Worst grade found in time')}
					headStyle={{ backgroundColor: '#fafafa' }}
				>
					<h2 style={{ margin: 0 }}>{children?.time}</h2>
				</Card>
			</Space>
		);
	};

	return (
		<Row justify="center">
			<Col span={24}>
				<Title>
					<WorstGrade isLoading={isLoading} isError={isError}>
						{data}
					</WorstGrade>
				</Title>
			</Col>
			<Col span={24}>
				<ScoresContainer>
					<BarContainer>
						<Label>{I18n.get('Left')}</Label>
						<ScoreBar divisions={grades} scores={scores?.left} />
					</BarContainer>
					<BarContainer>
						<Label>{I18n.get('Right')}</Label>
						<ScoreBar divisions={grades} scores={scores?.right} />
					</BarContainer>
				</ScoresContainer>
			</Col>
		</Row>
	);
};
