import React from "react";

interface BackCompressiveForceEstimationSVGProps {
	size?: number | string;
	color?: string;
}

export const BackCompressiveForceEstimationSVG: React.FC<BackCompressiveForceEstimationSVGProps> = ({ size = '1em', ...rest }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={size}
		height={size}
		fill="none"
		viewBox="0 0 33 34"
		{...rest}
	>
		<path
			fill="currentColor"
			d="M11.297 6.019L9.556 7.76 3.86 2.065c-.074-.074-.22-.05-.323.054L2.124 3.533c-.104.104-.128.25-.054.323l5.695 5.695-1.747 1.746c-.158.158-.115.375.07.366l5.026-.252a.267.267 0 00.103-.028.356.356 0 00.166-.165.268.268 0 00.028-.104l.254-5.025c.007-.186-.21-.228-.368-.07zM26.124 11.297l-1.742-1.741 5.695-5.695c.074-.074.05-.22-.054-.323L28.61 2.124c-.104-.104-.25-.128-.323-.054L22.59 7.765l-1.746-1.747c-.158-.158-.375-.115-.366.07l.253 5.026a.27.27 0 00.027.103.355.355 0 00.166.166c.034.017.07.026.104.028l5.024.254c.186.007.229-.21.07-.368zM6.018 21.845l1.742 1.741-5.695 5.695c-.074.074-.05.22.054.323l1.414 1.414c.104.104.25.128.323.054l5.695-5.694 1.746 1.746c.158.158.375.115.366-.069l-.252-5.026a.267.267 0 00-.028-.104.358.358 0 00-.165-.166.267.267 0 00-.104-.027l-5.025-.255c-.186-.007-.229.21-.07.368zM20.845 27.124l1.741-1.742 5.695 5.695c.074.074.22.05.323-.054l1.414-1.414c.104-.104.128-.25.054-.323l-5.695-5.695 1.747-1.746c.158-.158.115-.375-.069-.366l-5.026.253a.268.268 0 00-.104.027.358.358 0 00-.166.166.27.27 0 00-.027.104l-.255 5.024c-.007.186.21.229.368.07z"
		/>
		<rect
			width="19"
			height="4"
			x="6.5"
			y="14.5"
			stroke="currentColor"
			rx="0.5"
		/>
	</svg>
);
