import React, { useState } from 'react';
import { Text, Title } from '@/components/Typography';
import { Col, Divider, Row, Tabs } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useLibertyMutualContext } from '../../context';
import { ReportInformation } from './ReportInformation';
import { ResultsContainer } from './styles';
import { DownloadPDF } from './DownloadPDF';
import { Summary } from './Summary';
import { Comment } from './Comment';
import { Header } from './Header';
import { Score } from './Score';

export const ReportResult = () => {
	const { libertyMutualResult } = useLibertyMutualContext();
	const [isEditing, setIsEditing] = useState<boolean>(false);

	if (!libertyMutualResult?.id) {
		return (
			<Row justify="center">
				<Col>
					<Text>Oops... Something went wrong!</Text>
				</Col>
			</Row>
		);
	}

	function handleDisableFormEdition(editing: boolean) {
		setIsEditing(editing);
	}

	const tabs = libertyMutualResult.reports.map(({ id, report_name, results, summary, task_name }) => {
		return {
			key: id,
			label: I18n.get(report_name),
			children: (
				<>
					<Col xs={24}>
						<Title level={5}>Summary</Title>
					</Col>
					<Score task_name={task_name} results={results} />
					<Summary summary={summary} report_name={report_name} task_name={task_name} />
					<Divider type="horizontal" />
				</>
			)
		};
	});

	return (
		<Row justify="center">
			<Col span={24} style={{ marginBottom: '2rem', padding: '0 1rem' }}>
				<Title level={4}>Result</Title>
			</Col>
			<Col style={{ padding: '1rem' }}>
				<ResultsContainer>
					<Header />
					<ReportInformation />
					<Divider type="horizontal" />
					<Tabs items={tabs} />
					<Comment onCommentChange={handleDisableFormEdition} disableEditComment={isEditing} />
					<DownloadPDF onDownloadPDF={handleDisableFormEdition} disableDownload={isEditing} />
				</ResultsContainer>
			</Col>
		</Row>
	);
};
