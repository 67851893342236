import React from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { Col, Collapse, Table } from 'antd';
import { type ColumnsType } from 'antd/es/table';

import { StrainIndex } from '@/hooks';
import { RiskCard } from './RiskCard';
import * as S from '../styles';

interface DataType {
	key: number;
	left: JSX.Element;
	right: JSX.Element;
	title: string;
	risk_range_left?: JSX.Element;
	risk_range_right?: JSX.Element;
}

interface StrainIndexProps {
	data: StrainIndex;
}

export const StrainIndexResults: React.FC<StrainIndexProps> = ({ data }) => {
	const locale = moment.locale();

	const {
		left_risk,
		right_risk,
		score_left_rsi,
		score_right_rsi,
		score_left_borg_scale,
		score_right_borg_scale,
		score_left_exertion_duration,
		score_right_exertion_duration,
		score_left_efforts_per_minute,
		score_right_efforts_per_minute,
		score_left_wrist_posture,
		score_right_wrist_posture,
		score_left_daily_duration,
		score_right_daily_duration
	} = data;

	const columns: ColumnsType<DataType> = [
		{
			title: '',
			dataIndex: 'title',
			key: 'title',
			align: 'left',
			width: '30%'
		},
		{
			title: I18n.get('Left'),
			dataIndex: 'left',
			key: 'left',
			width: '10%',
			align: 'center'
		},
		{
			title: I18n.get('Right'),
			dataIndex: 'right',
			key: 'right',
			width: '10%',
			align: 'center'
		},
		{
			title: I18n.get('Risk range'),
			dataIndex: 'risk_range_left',
			key: 'risk_range_left',
			align: 'left',
			colSpan: 2,
			onCell: (_, index) => {
				if (index === 0) {
					return { rowSpan: 5 };
				}
				if (index && index > 0) {
					return { rowSpan: 0, colSpan: 0 };
				}

				return {};
			}
		},
		{
			dataIndex: 'risk_range_right',
			key: 'risk_range_right',
			onCell: (_, index) => {
				if (index === 0) {
					return { rowSpan: 5 };
				}
				if (index && index > 0) {
					return { rowSpan: 0, colSpan: 0 };
				}

				return {};
			}
		}
	];

	const dataColumn: DataType[] = [
		{
			key: 1,
			title: I18n.get('Intensity of exertion'),
			risk_range_left: <RiskCard risk={left_risk} score={score_left_rsi} side="Left" />,
			risk_range_right: <RiskCard risk={right_risk} score={score_right_rsi} side="Right" />,
			left: renderTextWithRiskColor(score_left_borg_scale),
			right: renderTextWithRiskColor(score_right_borg_scale)
		},
		{
			key: 2,
			title: I18n.get('Efforts per minute'),
			left: renderTextWithRiskColor(score_left_exertion_duration),
			right: renderTextWithRiskColor(score_right_exertion_duration)
		},
		{
			key: 3,
			title: I18n.get('Duration per exertion'),
			left: renderTextWithRiskColor(score_left_efforts_per_minute),
			right: renderTextWithRiskColor(score_right_efforts_per_minute)
		},
		{
			key: 4,
			title: I18n.get('Hand/wrist posture'),
			left: renderTextWithRiskColor(score_left_wrist_posture),
			right: renderTextWithRiskColor(score_right_wrist_posture)
		},
		{
			key: 5,
			title: I18n.get('Duration of task per day'),
			left: renderTextWithRiskColor(score_left_daily_duration),
			right: renderTextWithRiskColor(score_right_daily_duration)
		}
	];

	function renderTextWithRiskColor(score: number): JSX.Element {
		const transformedScore = Number(score.toFixed(2));
		const value = new Intl.NumberFormat(locale).format(transformedScore);
		return <S.CustomText $score={score}>{value}</S.CustomText>;
	}

	return (
		<Collapse ghost>
			<S.PanelCustom header={I18n.get('Revised Strain Index (RSI)')} key={'strain-index'}>
				<Col span={24}>
					<S.StrainIndexTableContainer $riskColorLeft={score_left_rsi} $riskColorRight={score_right_rsi}>
						<Table columns={columns} dataSource={dataColumn} pagination={false} />
					</S.StrainIndexTableContainer>
				</Col>
			</S.PanelCustom>
		</Collapse>
	);
};
