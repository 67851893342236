import React from 'react';
import { Form, Select } from 'antd';

import { StressLevelDTO } from '@/components/ui/Inputs/types/response/stressLevel';
import { I18n } from '@aws-amplify/core';

import { NamePath } from 'antd/lib/form/interface';

const { useFormInstance } = Form;

type StressLevelSelectProps = {
	stressLevels: StressLevelDTO[] | undefined;
	loading: boolean;
	disabled: boolean;
	handleSelectStressLevel?: (id: string) => void;
	handleClearStressLevel?: () => void;
	stressLevelId?: string;
	fieldName: NamePath;
};

type OptionType = { label: string; value: string } | undefined;

export function StressLevelSelect({
	stressLevels,
	loading,
	disabled,
	stressLevelId,
	handleSelectStressLevel,
	handleClearStressLevel,
	fieldName
}: StressLevelSelectProps) {
	const { validateFields } = useFormInstance();

	async function handleChange(id: string) {
		if (handleSelectStressLevel) {
			handleSelectStressLevel(id);
			await validateFields([fieldName]);
		}
	}

	function handleClear() {
		if (handleClearStressLevel) {
			handleClearStressLevel();
		}
	}

	function filterOption(option: OptionType, input: string): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	return (
		<Select
			showSearch
			allowClear
			value={stressLevelId}
			onClear={handleClear}
			placeholder={I18n.get('Stress level')}
			filterOption={(input, option) => filterOption(option, input)}
			loading={loading}
			disabled={disabled}
			options={stressLevels?.map(({ id, description }) => ({ label: I18n.get(description), value: id }))}
			onChange={handleChange}
		/>
	);
}
