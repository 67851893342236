import React from 'react';
import { Row, Col, Divider } from 'antd';

import { AdditionalItem } from '@/hooks/useGetLastStepKeyResult';
import { ConditionalCard } from './ConditionalCard';
import { riskColors } from '../../ConditionCard';
import { Text } from '@/components/Typography';
import { CardStyled } from '../../styles';
import { Topic } from './styles';

interface AdditionalItemsProps {
	items: AdditionalItem[];
}

export function AdditionalItems({ items }: AdditionalItemsProps) {
	const showItemConditional = items.length > 1;
	const spanItemsCols = Math.floor(24 / items[0]?.values?.length);
	const spanRowCols = showItemConditional ? { xxl: 23, xs: 22 } : { xxl: 24, xs: 24 };
	const maxResult = items.reduce((max, item) => Math.max(max, item.result), 0);

	return (
		<React.Fragment>
			<Divider />
			{items.map((additionalItem) => (
				<Row>
					<Col {...spanRowCols}>
						<Row>
							{additionalItem.values.map((item) => (
								<Col span={spanItemsCols}>
									<Topic>
										<Text className="title">
											{item.title}
											{item.unit && ` (${item.unit})`}
										</Text>
										<Text className="value">{item.value}</Text>
									</Topic>
								</Col>
							))}
						</Row>
					</Col>
					{showItemConditional && (
						<Col>
							<CardStyled
								$backgroundColor={riskColors[additionalItem.result]}
								risk={additionalItem.result}
								style={{ width: 29, height: 54, borderRadius: 10 }}
							/>
						</Col>
					)}
				</Row>
			))}
			<div style={{ maxWidth: 320 }}>
				<ConditionalCard result={maxResult} />
			</div>
		</React.Fragment>
	);
}
