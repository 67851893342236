import Api from '@/services/api';

import { JdsD86ListRequest, DeleteJdsD86Request, DownloadFileRequest, GetReviewsJdsD86Request, DownloadPdfRequest } from './types/request';
import { JdsD86DTO, JdsD86ListDTO, JdsD86ReviewDTO } from './types/response';

export class JdsD86Service {
	async getJdsD86List({ custom_report_name, filter }: JdsD86ListRequest): Promise<JdsD86ListDTO> {
		try {
			const url = `/custom-report/review?custom_report_name=${custom_report_name}`;
			const { data } = await Api.get<JdsD86ListDTO>(url, {
				params: filter,
			});

			return data;
		} catch (error) {
			return { count: 0, rows: [] } as JdsD86ListDTO;
		}
	}

	async deleteReportJdsD86({ organization_id, company_id, id }: DeleteJdsD86Request): Promise<JdsD86DTO> {
		const url = `/custom-report/result/${id}?organization_id=${organization_id}&company_id=${company_id}`;
		const { data } = await Api.delete<JdsD86DTO>(url);

		return data;
	}

	async deleteLastReviewJdsD86({ organization_id, company_id, id }: DeleteJdsD86Request): Promise<JdsD86DTO> {
		const url = `/custom-report/review/${id}?organization_id=${organization_id}&company_id=${company_id}`;
		const { data } = await Api.delete<JdsD86DTO>(url);
		return data;
	}

	async getReviewsJdsD86({ id, organization_id, company_id }: Partial<GetReviewsJdsD86Request>): Promise<JdsD86ReviewDTO[]> {
		const url = `/custom-report/review/${id}?organization_id=${organization_id}&company_id=${company_id}`;
		const { data } = await Api.get<JdsD86ReviewDTO[]>(url);

		return data;
	}

	async downloadPdf({ company_id, organization_id, file_id, report_id, original_custom_report_result_id, locale }: DownloadPdfRequest): Promise<string> {
		const url = '/custom-report/result/pdf';
		const { data } = await Api.post<string>(url, {
			file_id: file_id,
			organization_id,
			company_id,
			report_id,
			original_custom_report_result_id,
			locale,
		});
		return data;
	}

	async downloadFile({ company_id, organization_id, file_id, original_name }: DownloadFileRequest): Promise<string> {
		const url = '/upload/create-url-download';
		const { data } = await Api.post<string>(url, {
			file_id: file_id,
			organization_id,
			company_id,
			original_name,
		});
		return data;
	}
}
