import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message, notification } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CreateRiskDamageRequest } from '@/components/ui/Inputs/types/request/riskDamage';
import Api from '@/services/api';

export const useCreateRiskDamage = () => {
	const queryClient = useQueryClient();
	return useMutation(
		({ body }: { body: CreateRiskDamageRequest }) => Api.post('/custom-report/risk-damage/create', body),
		{
			onError: (err: any) => {
				notification.error({
					message: I18n.get(err?.response?.data?.message || 'Ops... something happened!')
				});
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['risk_damages']);
				queryClient.invalidateQueries(['risk_damages_list']);
				message.success(I18n.get('Damage created successfully'));
			}
		}
	);
};
