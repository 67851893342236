import React from 'react';

import { SelectRadioAndInputs } from './SelectRadioAndInputs';
import { SelectInputAndInput } from './SelectInputAndInput';
import { SelectAndSubSelect } from './SelectAndSubSelect';
import { SelectAndInputs } from './SelectAndInputs';
import { SelectAndSlider } from './SelectAndSlider';
import { SelectAndInput } from './SelectAndInput';
import { SliderAndInput } from './SliderAndInput';
import { InputAndInput } from './InputAndInput';
import { AditionalItemsMapper } from './types';
import { GroupRadio } from './GroupRadio';
import { SliderOnly } from './SliderOnly';
import { AdditionalItem } from '@/hooks';
import { Options } from './Options';
import { Inputs } from './Inputs';
import { Input } from './Input';

interface AdditionalComponentsProps {
	additionalItems: AdditionalItem[];
}

export const AdditionalComponents: React.FC<AdditionalComponentsProps> = ({ additionalItems }) => {
	const components: AditionalItemsMapper = {
		inputs: Inputs,
		input_only: Input,
		radio_options: Options,
		slider_only: SliderOnly,
		input_and_input: InputAndInput,
		select_and_input: SelectAndInput,
		slider_and_input: SliderAndInput,
		select_and_inputs: SelectAndInputs,
		select_and_slider: SelectAndSlider,
		select_and_sub_select: SelectAndSubSelect,
		select_input_and_input: SelectInputAndInput,
		select_radio_and_inputs: SelectRadioAndInputs,
		group_radio_options: GroupRadio
	};

	return (
		<>
			{additionalItems.map((additionalItem) => {
				const AditionalItem = components[additionalItem.additional_item_type.type];
				if (!AditionalItem) {
					return <></>;
				}
				return <AditionalItem key={additionalItem.id} additionalItem={additionalItem} />;
			})}
		</>
	);
};
