import { useMutation, useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';
import Api from '@/services/api';
import type {
	BackCompressiveForceEstimationReportDTO,
	UpdateComment,
	UpdateReportBasicInformation,
	UpdateReportInputs
} from '@/components/views/Report/BackCompressiveForceEstimation/types';

const DEFAULT_URL = '/ergonomic-tool/back-compressive-force-estimation';

async function updateReportBasicInformation(payload: UpdateReportBasicInformation) {
	const url = `${DEFAULT_URL}/basic-info/${payload.report_id}`;
	const { data } = await Api.put<BackCompressiveForceEstimationReportDTO>(url, payload);
	return data;
}

async function updateReportInputs(payload: UpdateReportInputs) {
	const url = `${DEFAULT_URL}/inputs/${payload.report_id}`;
	const { data } = await Api.put<BackCompressiveForceEstimationReportDTO>(url, payload);
	return data;
}

async function updateComment(payload: UpdateComment) {
	const url = `${DEFAULT_URL}/comment/${payload.report_id}`;
	const { data } = await Api.put<BackCompressiveForceEstimationReportDTO>(url, payload);
	return data;
}

export const useUpdateReportBasicInformation = () => {
	const queryClient = useQueryClient();
	return useMutation((body: UpdateReportBasicInformation) => updateReportBasicInformation(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message));
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['back_compressive_force_estimation_result']);
		}
	});
};

export const useUpdateReportInputs = () => {
	const queryClient = useQueryClient();
	return useMutation((body: UpdateReportInputs) => updateReportInputs(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message));
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['back_compressive_force_estimation_result']);
			// TODO: I18n message success
			message.success(I18n.get('Back compressive force estimation report updated successfully!'));
		}
	});
};

export const useUpdateComment = () => {
	return useMutation((body: UpdateComment) => updateComment(body), {
		onSuccess: () => {
			message.success(I18n.get('Back compressive force estimation report updated successfully!'));
		},
		onError: () => {
			message.error(I18n.get('Fail to save the comments'));
		}
	});
};
