import React from 'react';
import { Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CustomFormItem } from '../styles';
import { useStepKeysContext } from '../context';
import { RequiredLabel } from '../../../RequiredLabel';
import { ExposureSelect } from '@/components/ui/Inputs/ExposureSelect';

const { useFormInstance, useWatch } = Form;

interface ExposuresProps {
	disabled: boolean;
	formFieldName: string[];
}

export const Exposures: React.FC<ExposuresProps> = ({ disabled, formFieldName }) => {
	const form = useFormInstance();
	const {
		exposuresData: { exposures, fetchingExposures, gettingExposures }
	} = useStepKeysContext();

	const fieldName = [...formFieldName, 'exposure_id'];
	const exposureId = useWatch(fieldName, form);
	const riskDamageId = useWatch([...formFieldName, 'risk_damage_id'], form);

	async function handleExposureSelect(id: string) {
		form.setFieldValue(fieldName, id);
	}

	async function handleClearExposure() {
		form.setFieldValue(fieldName, undefined);
	}

	return (
		<Col xs={24} sm={12} lg={24}>
			<CustomFormItem
				name={fieldName}
				label={<RequiredLabel title="Exposure" isRequired={true} />}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: true,
						message: I18n.get('Select the exposure')
					}
				]}
			>
				<ExposureSelect
					fieldName={fieldName}
					allowCreation={false}
					exposureId={exposureId}
					exposures={exposures}
					loading={fetchingExposures}
					handleClearExposure={handleClearExposure}
					handleSelectExposure={handleExposureSelect}
					disabled={disabled || gettingExposures || fetchingExposures || !riskDamageId}
				/>
			</CustomFormItem>
		</Col>
	);
};
