import React, { useState } from 'react';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { Row } from 'antd';

import { useApplicationContext } from '@/context/v1/Application/context';
import { BeraReport, BeraReportFile } from '../hooks/types/response';
import { CustomDownloadButton, CustomTable } from './styles';
import { useDownloadFile } from '../hooks/hooks';
import { Actions } from './Actions';

interface SubTableProps {
	data: BeraReport[];
}

export const SubTable: React.FC<SubTableProps> = ({ data }) => {
	const [clickedFileId, setClickedFileId] = useState<string>('');

	const { organization, company } = useApplicationContext();
	const { mutateAsync: downloadFile, isLoading: downloadingFile } = useDownloadFile();

	async function handleDownloadFile(file: BeraReportFile) {
		setClickedFileId(file.id);
		await downloadFile({
			company_id: company?.id,
			organization_id: organization?.id,
			file
		});
	}

	const columns: ColumnsType<BeraReport> = [
		{
			title: '',
			dataIndex: '',
			key: '',
			align: 'center'
		},
		{
			title: '',
			dataIndex: '',
			key: '',
			align: 'center'
		},
		{
			title: '',
			dataIndex: '',
			key: '',
			align: 'center'
		},
		{
			title: '',
			dataIndex: 'file',
			key: 'file',
			ellipsis: true,
			align: 'center',
			render: (file: BeraReportFile) => (
				<CustomDownloadButton
					type="link"
					key={file.id}
					loading={file.id === clickedFileId && downloadingFile}
					onClick={() => handleDownloadFile(file)}
				>
					{file.original_name}
				</CustomDownloadButton>
			),
			responsive: ['lg']
		},
		{
			title: '',
			dataIndex: 'rpn',
			key: 'rpn',
			align: 'center',
			render: (score: number | undefined) => score?.toFixed(2),
			responsive: ['sm']
		},
		{
			title: '',
			dataIndex: 'updated_at',
			key: 'updated_at',
			ellipsis: true,
			align: 'center',
			render: (updated) => moment(updated).format('L'),
			responsive: ['lg']
		},
		{
			title: '',
			dataIndex: 'url',
			key: 'url',
			align: 'center',
			render: (_value, rowData) => <Actions rowData={rowData} />
		}
	];

	return (
		<Row justify="center">
			<CustomTable span={24}>
				<Table
					style={{ cursor: 'auto' }}
					showHeader={false}
					pagination={false}
					rowKey="id"
					dataSource={data}
					columns={columns}
				/>
			</CustomTable>
		</Row>
	);
};
