import React from 'react';
import { Col, Divider, Row } from 'antd';
import { Title } from '@/components/Typography';

import { KimManualHandling } from '../types';
import { SecondRow } from './SecondRow';
import { FirstRow } from './FirstRow';
import { Container } from './styles';

interface KimManualHandlingResultProps {
	kim_mho: KimManualHandling;
}

export const KimManualHandlingResult: React.FC<KimManualHandlingResultProps> = ({ kim_mho }) => {
	const parameters = {
		duration: kim_mho.duration,
		risk_load: kim_mho.risk_load,
		risk_score: kim_mho.risk_score,
		force_transfer: kim_mho.force_transfer,
		left_force_intensity: kim_mho.left_force_intensity,
		right_force_intensity: kim_mho.right_force_intensity
	};

	return (
		<Row gutter={[0, 24]}>
			<Col offset={1} span={23}>
				<Row>
					<Title style={{ margin: 0 }} level={5}>
						Manual Handling (KIM)
					</Title>
				</Row>
			</Col>
			<Col offset={1} span={23}>
				<Row>
					<Container>
						<Col span={24}>
							<FirstRow parameters={parameters} />
						</Col>
						<Divider style={{ margin: 0 }} />
						<Col span={24}>
							<SecondRow parameters={parameters} />
						</Col>
					</Container>
				</Row>
			</Col>
		</Row>
	);
};
