import Api from '@/services/api';

import { BeraJobResultDTO, BeraJobSummaryRequest, PayloadDownloadPDF, URLDownloadDTO } from './types';
import { UpdateBeraJobSummaryRequest } from '../hooks/types/request';

export class BeraJobResultService {
	async getBeraJobResults({
		organization_id,
		company_id,
		bera_job_summary_id
	}: BeraJobSummaryRequest): Promise<BeraJobResultDTO> {
		const url = `/bera/job-summary/result/${bera_job_summary_id}?organization_id=${organization_id}&company_id=${company_id}`;
		const { data } = await Api.get<BeraJobResultDTO>(url);
		return data;
	}

	public async downloadPDF(payload: PayloadDownloadPDF) {
		const url = `/bera/job-summary/download-pdf/${payload.bera_job_summary_id}`;
		const { data } = await Api.post<URLDownloadDTO>(url, payload);
		return data;
	}

	public async saveComments({ organization_id, company_id, id, comment }: UpdateBeraJobSummaryRequest) {
		const body = {
			organization_id,
			company_id,
			id,
			comment
		};
		const url = `/bera/job-summary/${id}`;
		const { data } = await Api.put<BeraJobResultDTO>(url, body);
		return data;
	}
}
