import React from 'react';

import { AdditionalItemResult } from '@/hooks/useGetCustomReportResultStepKeyDefaultData';
import { AdditionalItem, AdditionalItemsResults } from '@/hooks';
import { SelectRadioAndInputs } from './SelectRadioAndInputs';
import { SelectInputAndInput } from './SelectInputAndInput';
import { SelectAndSubSelect } from './SelectAndSubSelect';
import { SelectAndSlider } from './SelectAndSlider';
import { SelectAndInput } from './SelectAndInput';
import { SliderAndInput } from './SliderAndInput';
import { InputAndInput } from './InputAndInput';
import { AditionalItemsMapper } from './types';
import { SliderOnly } from './SliderOnly';
import { Options } from './Options';
import { Inputs } from './Inputs';
import { Input } from './Input';

interface AdditionalComponentsProps {
	additionalItems: AdditionalItem[];
	defaultData: AdditionalItemResult[];
}

export const AdditionalComponents: React.FC<AdditionalComponentsProps> = ({ additionalItems, defaultData }) => {
	const components: AditionalItemsMapper = {
		inputs: Inputs,
		input_only: Input,
		radio_options: Options,
		slider_only: SliderOnly,
		input_and_input: InputAndInput,
		select_and_input: SelectAndInput,
		slider_and_input: SliderAndInput,
		select_and_slider: SelectAndSlider,
		select_and_sub_select: SelectAndSubSelect,
		select_input_and_input: SelectInputAndInput,
		select_radio_and_inputs: SelectRadioAndInputs
	};

	return (
		<>
			{additionalItems.map((additionalItem) => {
				const AditionalItem = components[additionalItem.additional_item_type.type];
				const defaultItemResult: AdditionalItemsResults[] =
					additionalItem.results?.length > 0
						? additionalItem.results
						: defaultData.filter((f) => f.additional_item_id === additionalItem.id);

				if (!AditionalItem) {
					return <></>;
				}
				return (
					<AditionalItem
						key={additionalItem.id}
						additionalItem={{
							...additionalItem,
							results: defaultItemResult
						}}
					/>
				);
			})}
		</>
	);
};
