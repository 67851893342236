import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { message, notification } from 'antd';
import { I18n } from '@aws-amplify/core';

import {
	CustomReportFilesRequest,
	CustomReportStepsRequest,
	CustomReportsRequest,
	CustomReportRequest,
	ExposuresRequest,
	SeveritiesRequest,
	VulnerabilitiesRequest,
	RiskDescriptionsRequest,
	RiskCategoriesRequest,
	RiskDamagesRequest
} from '@/components/ui/Inputs/types/request/customReport';
import {
	SeraSummaryRequest,
	CreateSeraSummaryRequest,
	UpdateSeraSummaryRequest,
	CreateSeraResultRequest,
	GetActionPlanBySeraSummaryReviewIdRequest,
	UpdateSeraActionPlanRequest,
	CreateSeraActionPlanRequest,
	DeleteSeraActionPlanRequest,
	DownloadFileRequest,
	SetTasksFilesRequest,
	GetTasksFilesRequest,
	SeraReportsRequest,
	UpdateSeraResultRequest,
	DeleteSeraReport
} from './types/request';
import { TasksRequest } from '@/components/ui/Inputs/types/request/task';

import { SeraActionPlanService } from '../services/api/seraActionPlanService';
import { CustomReportService } from '../services/api/customReportService';
import { SeraSummaryService } from '../services/api/seraSummaryService';
import { SeraStepsService } from '../services/api/seraStepsService';
import { SeraService } from '../services/api/seraService';

import { SeraSummaryDTO, SeraActionPlanDTO, ActionPlanTaskDTO, TasksListDTO, SeraReportDTO } from './types/response';
import { RiskDescriptionDTO } from '@/components/ui/Inputs/types/response/riskDescription';
import { CustomReportStepDTO } from '@/components/ui/Inputs/types/response/customReport';
import { VulnerabilityDTO } from '@/components/ui/Inputs/types/response/vulnerability';
import { RiskCategoryDTO } from '@/components/ui/Inputs/types/response/riskCategory';
import { RiskDamageDTO } from '@/components/ui/Inputs/types/response/riskDamage';
import { ExposureDTO } from '@/components/ui/Inputs/types/response/exposure';
import { SeverityDTO } from '@/components/ui/Inputs/types/response/severity';
import { UserDTO } from '@/types/AuthUser';

const seraService = new SeraService();
const seraStepsService = new SeraStepsService();
const seraSummaryService = new SeraSummaryService();
const customReportService = new CustomReportService();
const seraActionPlanService = new SeraActionPlanService();

export function useSeraSummary({ organization_id, company_id, sera_summary_id }: SeraSummaryRequest) {
	const enabled = !!organization_id && !!company_id && !!sera_summary_id;
	const summary = useQuery(
		['sera_summary', [organization_id, company_id, sera_summary_id]],
		() => seraSummaryService.getSeraSummary({ organization_id, company_id, sera_summary_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...summary,
		data: summary.data ?? ({} as SeraSummaryDTO)
	};
}

export function useSeraActionPlans({
	organization_id,
	company_id,
	sera_summary_review_id,
	file_id
}: GetActionPlanBySeraSummaryReviewIdRequest) {
	const enabled = !!organization_id && !!company_id && !!sera_summary_review_id && !!file_id;
	const action_plans = useQuery(
		['sera_action_plans', [organization_id, company_id, sera_summary_review_id, file_id]],
		() =>
			seraActionPlanService.getActionPlanBySeraSummaryReview({
				organization_id,
				company_id,
				sera_summary_review_id,
				file_id
			}),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...action_plans,
		data: action_plans.data ?? ([] as SeraActionPlanDTO[])
	};
}

export function useCreateSeraActionPlan() {
	const queryClient = useQueryClient();
	return useMutation((body: CreateSeraActionPlanRequest) => seraActionPlanService.createSeraActionPlan(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0]?.split('" ') ?? err;
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: () => {
			message.success(I18n.get('Acion plan created successfully'));
			queryClient.invalidateQueries(['sera_action_plans']);
		}
	});
}

export function useUpdateSeraActionPlan() {
	const queryClient = useQueryClient();
	return useMutation((body: UpdateSeraActionPlanRequest) => seraActionPlanService.updateSeraActionPlan(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0]?.split('" ') ?? err;
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: () => {
			message.success(I18n.get('Action plan updated successfully'));
			queryClient.invalidateQueries(['sera_action_plans']);
		}
	});
}

export function useDeleteSeraActionPlan() {
	const queryClient = useQueryClient();
	return useMutation((body: DeleteSeraActionPlanRequest) => seraActionPlanService.deleteSeraActionPlan(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0]?.split('" ') ?? err;
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: () => {
			message.success(I18n.get('Action plan deleted successfully'));
			queryClient.invalidateQueries(['sera_action_plans']);
		}
	});
}

export function useCustomReport({ organization_id, company_id, reportName }: CustomReportRequest) {
	const enabled = !!organization_id && !!company_id && !!reportName;
	const customReport = useQuery(
		['custom_report', [organization_id, company_id, reportName]],
		() => customReportService.getCustomReport({ organization_id, company_id, reportName }),
		{
			enabled,
			retry: 0
		}
	);
	return customReport;
}

export function useSeraFiles({
	custom_report_id,
	sera_summary_id,
	organization_id,
	company_id,
	report_type,
	offset,
	limit,
	fileName
}: CustomReportFilesRequest) {
	const enabled = !!organization_id && !!company_id && !!custom_report_id;
	const seraFiles = useQuery(
		[
			'sera_files',
			[organization_id, company_id, custom_report_id, sera_summary_id, report_type, offset, limit, fileName]
		],
		() =>
			seraService.getSeraFiles({
				custom_report_id,
				organization_id,
				sera_summary_id,
				report_type,
				company_id,
				fileName,
				offset,
				limit
			}),
		{
			enabled,
			retry: 0
		}
	);
	return seraFiles;
}

export function useCustomReports({ organization_id, company_id }: CustomReportsRequest) {
	const enabled = !!organization_id && !!company_id;
	const customReports = useQuery(
		['custom_reports', [organization_id, company_id]],
		() => customReportService.getCustomReports({ organization_id, company_id }),
		{
			enabled,
			retry: 0
		}
	);
	return customReports;
}

export function useSeraSteps({ organization_id, company_id, custom_report_id }: CustomReportStepsRequest) {
	const enabled = !!organization_id && !!company_id && !!custom_report_id;
	const customReportsSteps = useQuery(
		['custom_reports_steps', [organization_id, company_id, custom_report_id]],
		() => customReportService.getCustomReportsSteps({ organization_id, company_id, custom_report_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...customReportsSteps,
		data: customReportsSteps.data ?? ([] as CustomReportStepDTO[])
	};
}

export function useSeraReports({ organization_id, company_id, sera_summary_review_id }: SeraReportsRequest) {
	const enabled = !!organization_id && !!company_id && !!sera_summary_review_id;
	const reports = useQuery(
		['sera_reports', [organization_id, company_id, sera_summary_review_id]],
		() => seraStepsService.getSeraReports({ organization_id, company_id, sera_summary_review_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...reports,
		data: reports.data ?? ([] as SeraReportDTO[])
	};
}

export function useExposures({ organization_id, company_id, step_key_id }: ExposuresRequest) {
	const enabled = !!organization_id && !!company_id && !!step_key_id;
	const exposures = useQuery(
		['exposures', [organization_id, company_id, step_key_id]],
		() => seraStepsService.getExposures({ organization_id, company_id, step_key_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...exposures,
		data: exposures.data ?? ([] as ExposureDTO[])
	};
}

export function useSeverities({ organization_id, company_id, step_key_id }: SeveritiesRequest) {
	const enabled = !!organization_id && !!company_id && !!step_key_id;
	const severities = useQuery(
		['severities', [organization_id, company_id, step_key_id]],
		() => seraStepsService.getSeverities({ organization_id, company_id, step_key_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...severities,
		data: severities.data ?? ([] as SeverityDTO[])
	};
}

export function useVulnerabilities({ organization_id, company_id, step_key_id }: VulnerabilitiesRequest) {
	const enabled = !!organization_id && !!company_id && !!step_key_id;
	const vulnerabilities = useQuery(
		['vulnerabilities', [organization_id, company_id, step_key_id]],
		() => seraStepsService.getVulnerabilities({ organization_id, company_id, step_key_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...vulnerabilities,
		data: vulnerabilities.data ?? ([] as VulnerabilityDTO[])
	};
}

export function useRiskDescriptions({ organization_id, company_id, risk_category_id }: RiskDescriptionsRequest) {
	const enabled = !!organization_id && !!company_id && !!risk_category_id;
	const riskDescriptions = useQuery(
		['risk_descriptions', [organization_id, company_id, risk_category_id]],
		() => seraStepsService.getRiskDescriptions({ organization_id, company_id, risk_category_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...riskDescriptions,
		data: riskDescriptions.data ?? ([] as RiskDescriptionDTO[])
	};
}

export function useRiskCategories({ organization_id, company_id, step_key_id }: RiskCategoriesRequest) {
	const enabled = !!organization_id && !!company_id && !!step_key_id;
	const riskCategories = useQuery(
		['risk_categories', [organization_id, company_id, step_key_id]],
		() => seraStepsService.getRiskCategories({ organization_id, company_id, step_key_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...riskCategories,
		data: riskCategories.data ?? ([] as RiskCategoryDTO[])
	};
}

export function useRiskDamages({ organization_id, company_id, risk_description_id }: RiskDamagesRequest) {
	const enabled = !!organization_id && !!company_id && !!risk_description_id;
	const riskDamages = useQuery(
		['risk_damages', [organization_id, company_id, risk_description_id]],
		() => seraStepsService.getRiskDamages({ organization_id, company_id, risk_description_id }),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...riskDamages,
		data: riskDamages.data ?? ([] as RiskDamageDTO[])
	};
}

export function useTasks({ organization_id, company_id, cycle_id }: TasksRequest) {
	const enabled = !!organization_id && !!company_id;
	const tasks = useQuery(
		['tasks', [organization_id, company_id, cycle_id]],
		() => seraStepsService.getTasks({ organization_id, company_id, cycle_id }),
		{
			enabled,
			retry: 0
		}
	);
	return tasks;
}

export const useCreateSeraSummary = () => {
	const queryClient = useQueryClient();
	return useMutation((body: CreateSeraSummaryRequest) => seraSummaryService.createSeraSummary(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0]?.split('" ') ?? err;
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['sera_summary_list']);
			queryClient.invalidateQueries(['sera_files']);
			queryClient.invalidateQueries(['tasks_files']);
			queryClient.invalidateQueries(['sera_report']);
			queryClient.invalidateQueries(['sera_reports']);
		}
	});
};

export const useUpdateSeraSummary = () => {
	const queryClient = useQueryClient();
	return useMutation((body: UpdateSeraSummaryRequest) => seraSummaryService.updateSeraSummary(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0]?.split('" ') ?? err;
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['sera_summary_list']);
			queryClient.invalidateQueries(['sera_report']);
			queryClient.invalidateQueries(['sera_reports']);
		}
	});
};

export const useCreateSeraResult = () => {
	const queryClient = useQueryClient();
	return useMutation((body: CreateSeraResultRequest) => seraService.createSeraReport(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0]?.split('" ') ?? err;
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['sera_summary_list']);
			queryClient.invalidateQueries(['sera_summary']);
			queryClient.invalidateQueries(['sera_report']);
			queryClient.invalidateQueries(['sera_reports']);
		}
	});
};

export const useUpdateSeraResult = () => {
	const queryClient = useQueryClient();
	return useMutation((body: UpdateSeraResultRequest) => seraService.updateSeraReport(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0]?.split('" ') ?? err;
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['sera_summary_list']);
			queryClient.invalidateQueries(['sera_summary']);
			queryClient.invalidateQueries(['sera_report']);
			queryClient.invalidateQueries(['sera_reports']);
		}
	});
};

export function useDeleteSeraReport() {
	const queryClient = useQueryClient();
	return useMutation((body: DeleteSeraReport) => seraService.deleteSeraReport(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0]?.split('" ') ?? err;
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: () => {
			message.success(I18n.get('Report deleted successfully'));
			queryClient.invalidateQueries(['sera_summary_list']);
			queryClient.invalidateQueries(['sera_summary']);
			queryClient.invalidateQueries(['sera_report']);
			queryClient.invalidateQueries(['sera_reports']);
		}
	});
}

export const useGetUsers = (organization_id: string, company_id: string) => {
	const enabled = !!organization_id && !!company_id;
	const users = useQuery(
		['responsible_users', { organization_id, company_id }],
		() => seraActionPlanService.getUsers({ organization_id, company_id }),
		{
			enabled
		}
	);
	return {
		...users,
		data: users.data ?? ([] as UserDTO[])
	};
};

export const useFindTasks = (action_plan_id: string | undefined, organization_id: string, company_id: string) => {
	const enabled = !!organization_id && !!company_id && !!action_plan_id;
	const actionPlan = useQuery(
		['action_plan_tasks', action_plan_id],
		() => seraActionPlanService.getTasks({ action_plan_id, organization_id, company_id }),
		{
			enabled
		}
	);
	return {
		...actionPlan,
		data: actionPlan.data ?? ([] as ActionPlanTaskDTO[])
	};
};

export const useDownloadFile = () => {
	return useMutation((body: DownloadFileRequest) => seraService.downloadFile(body), {
		onError: (err: any) => {
			const message = err.response.data.message;
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(message)
			});
		},
		onSuccess: (data) => {
			const archive = document.createElement('a');
			archive.target = '_blank';
			archive.href = data;
			archive.click();
			archive.remove();
		}
	});
};

export const useSetTasksFiles = () => {
	const queryClient = useQueryClient();
	return useMutation((body: SetTasksFilesRequest) => seraService.setTasksFiles(body), {
		onError: (err: any) => {
			if (err?.response?.status === 409) {
				return;
			}
			const message = err.response.data.message;
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(message)
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['sera_files']);
		}
	});
};

export const useGetTasksFiles = ({ company_id, organization_id, files }: GetTasksFilesRequest) => {
	const enabled = !!organization_id && !!company_id && !!files;
	const tasksFiles = useQuery(
		['tasks_files', company_id, organization_id, files],
		() =>
			seraService.getTasksFiles({
				company_id,
				files,
				organization_id
			}),
		{
			enabled
		}
	);
	return {
		...tasksFiles,
		data: tasksFiles.data ?? ([] as TasksListDTO[])
	};
};
