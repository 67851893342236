import { Input, Row, Form, Image } from 'antd';
import styled from 'styled-components';

const { Item } = Form;

export const Container = styled(Row)`
	padding: 32px;
	max-width: 400px;
	text-align: center;
`;

export const NumberContainer = styled(Input)`
	text-align: center;
	border-radius: 4px;
`;

export const Countdown = styled.span`
	color: #2c2c2c77;
`;

export const ResetTokenContainer = styled(Row)``;

export const ResetTokenDescription = styled.span`
	font-size: 0.8rem;
	font-weight: 400;
	text-align: center;
`;

export const RecoveryToken = styled(Item)`
	margin-top: 1rem;
	min-width: 10rem;
	text-align: center;

	.ant-form-item-explain-error {
		text-align: center;
	}
`;

export const RecoveryTokenInput = styled(Input)`
	text-align: center;
`;

export const IconContainer = styled(Row)`
	margin-bottom: 1rem;
`;

export const QRCode = styled(Image)`
	border-radius: 0.25rem;
`;

export const ValidateContainer = styled(Row)``;

export const ValidationCode = styled(Item)`
	.ant-form-item-explain-error {
		text-align: center;
	}
`;

export const ValidateInput = styled(Input)`
	text-align: center;
`;

export const RecoveryTokensContainer = styled(Row)``;

export const Force2FAContainer = styled(Row)``;

export const Force2FADescription = styled.span`
	font-size: 0.8rem;
	font-weight: 400;
	text-align: center;
	margin-bottom: 2rem;
`;
