import React from 'react';
import { Image, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useLibertyMutualSubStepsContext } from '../../../context';
import { Body, CardContainer, Header } from '../styles';
import { HandCoupling } from '../../../context/types';

const FAIR_IMAGE = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/coupling-fair.svg';
const GOOD_IMAGE = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/coupling-good.svg';
const POOR_IMAGE = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/coupling-poor.svg';

export const HandCouplingCard = () => {
	const {
		libertyMutualResult: {
			summary: { hand_coupling }
		}
	} = useLibertyMutualSubStepsContext();

	const couplingSVGMapper = {
		[HandCoupling.Fair]: FAIR_IMAGE,
		[HandCoupling.Good]: GOOD_IMAGE,
		[HandCoupling.Poor]: POOR_IMAGE
	};

	return (
		<CardContainer>
			<Header>{I18n.get('Hand coupling')}</Header>
			<Body>
				<Row justify="center" style={{ padding: '1rem 0 0 0' }}>
					<Image
						preview={false}
						draggable={false}
						alt={I18n.get(hand_coupling)}
						src={couplingSVGMapper[hand_coupling]}
						width="50px"
					/>
				</Row>
				<Row justify="center">{I18n.get(hand_coupling)}</Row>
			</Body>
		</CardContainer>
	);
};
