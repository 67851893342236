import React from 'react';
import { Row, Col, Divider } from 'antd';
import { RiskDescription } from './RiskDescription';
import { useStepKeysResultsContext } from './context';

export const StepKeyDescription: React.FC = () => {
	const { component } = useStepKeysResultsContext();

	return (
		<Col span={24}>
			<Row gutter={[0, 0]} align="middle"></Row>
			<Row wrap={false} style={{ marginTop: '0.5rem' }}>
				<Col span={1}>
					<Divider
						type="vertical"
						style={{
							margin: '0 0 0 10px',
							borderRadius: '2px',
							width: '4px',
							backgroundColor: '#e6e8e8',
							height: '105%',
							border: 'none'
						}}
					/>
				</Col>
				<Col lg={23} xl={22} xxl={22} style={{ marginLeft: '0.5rem', paddingTop: '0.7rem' }}>
					<RiskDescription />
				</Col>
			</Row>
			{component}
		</Col>
	);
};
