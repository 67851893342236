import React from 'react';
import { I18n } from '@aws-amplify/core';
import { MenuOutlined } from '@ant-design/icons';
import { Button, Dropdown, Image, Row } from 'antd';

import { useApplicationContext } from '@/context/v1/Application/context';
import { ActionsInline, ActionsMenu } from './styles';
import { useDownloadFile } from '../../hooks/hooks';

const DOWNLOAD_ICON = 'https://kinebot-statics.s3.amazonaws.com/download-video-link.svg';

interface ActionsProps {
	file_id: string;
	file_name: string;
}

export const Actions: React.FC<ActionsProps> = ({ file_id, file_name }) => {
	const { organization, company } = useApplicationContext();
	const { mutateAsync: downloadFile, isLoading: downloadingPDF } = useDownloadFile();

	async function handleDownloadFile() {
		await downloadFile({
			organization_id: organization?.id,
			company_id: company?.id,
			file: {
				id: file_id,
				original_name: file_name
			}
		});
	}

	const items = [
		{
			key: 1,
			icon: (
				<Image src={DOWNLOAD_ICON} preview={false} style={{ width: '50%' }} alt={I18n.get('Download video')} />
			),
			label: (
				<Button loading={downloadingPDF} type="link" onClick={handleDownloadFile}>
					{I18n.get('Edit')}
				</Button>
			)
		}
	];

	return (
		<Row align="middle" justify="center">
			<ActionsInline xs={24} sm={16} md={24} xl={16} xxl={8}>
				<Row align="middle" justify="center">
					<Button
						type="link"
						size="large"
						loading={downloadingPDF}
						onClick={handleDownloadFile}
						icon={
							<Image
								src={DOWNLOAD_ICON}
								preview={false}
								style={{ width: '75%' }}
								alt={I18n.get('Download video')}
							/>
						}
					/>
				</Row>
			</ActionsInline>
			<ActionsMenu xs={2}>
				<Dropdown menu={{ items }} trigger={['click']}>
					<Button type="link" icon={<MenuOutlined />} />
				</Dropdown>
			</ActionsMenu>
		</Row>
	);
};
