import styled from 'styled-components';
import { Col, Divider, Form, Row, Skeleton, Tooltip } from 'antd';

const VIDEO_ICON = 'https://kinebot-statics.s3.amazonaws.com/download-video-black.svg';

const { Item } = Form;

export const CustomFormInputs = styled(Item) <{ fontSize?: string }>`
	label {
		font-weight: bold;
		font-size: ${({ fontSize }) => fontSize ?? ''};
	}

	height: 2.25rem;
	@media (min-width: 1200px) {
		height: 3rem;
	}
	.ant-form-item-explain-error {
		position: absolute;
		white-space: nowrap;
	}

	.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
		&::before {
			display: none;
		}
	}
`;

export const CustomFormItem = styled(Item) <{ fontSize?: string }>`
	label {
		font-weight: bold;
		font-size: ${({ fontSize }) => fontSize ?? ''};
	}
	.ant-form-item-explain-error {
		position: absolute;
		margin-top: 3px;
		width: 40vw;
	}
	.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
		display: none;
	}
`;

export const CustomImageSkeleton = styled(Skeleton.Image)`
	.ant-skeleton-image {
		background-image: url(${VIDEO_ICON});
		background-blend-mode: lighten;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: 40%;
		padding: 5rem;
		opacity: 0.6;

		svg {
			display: none;
		}
	}
`;

export const CardStyled = styled.div<{ $backgroundColor: string; risk: number, width?: string }>`
	background-color: ${({ $backgroundColor }) => $backgroundColor};
	color: ${({ risk }) => (risk === 2 ? '#000' : '#fff')};
	height: 30px;
	border-radius: 15px;
	padding-top: 3px;
	text-align: center;
	font-weight: bold;
	box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.25);
	width: ${({ width }) => width};
`;

export const ListStyled = styled.div`
	border-radius: 15px;
	border: 2px solid #e6e8e8;
	background: #f2f2f2;
	padding: 15px 10px;
	position: relative;
	right: 16px;
`;

export const RowStyled = styled(Row)`
	background: #ffffff;
	border: 1px solid #e6e6e6;
	border-radius: 5px;
	width: 100%;

	.ant-btn-dangerous {
		border-radius: 0 5px 5px 0;
	}
`;

export const ColorScale = styled.div<{ $riskColor?: string }>`
	width: 100%;
	height: 100%;
	border-radius: 7.5px;
	border: 1px solid #e6e8e8;
	background: ${({ $riskColor }) => $riskColor ?? '#17a93b'};
`;

export const CustomTooltip = styled(Tooltip)`
	.ant-tooltip {
		top: -135px !important;
		left: 5px !important;
	}

	.ant-tooltip-inner {
		width: 17rem;
		color: #262626;
		border: 1px solid #262626;
		backdrop-filter: blur(4px);
		background-color: #fffa;
		border-radius: 10px 10px 10px 0;
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	}
`;

export const InfoContainer = styled(Col)`
	padding: 10px 0 10px 10px;
`;

export const ListDivider = styled(Divider)`
	margin: 0;
	height: 20px;
	border-color: #e6e8e8;
`;

export const ButtonWrapper = styled(Col)`
	min-height: 40px;
	width: 100%;
`;

export const CustomSkeleton = styled(Skeleton)`
	.ant-skeleton-title {
		height: 30px;
	}
`;
