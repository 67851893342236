import React from 'react';
import { NamePath } from 'antd/lib/form/interface';
import { I18n } from '@aws-amplify/core';
import { Select, Form } from 'antd';

import { VulnerabilityDTO } from '@/components/ui/Inputs/types/response/vulnerability';

const { useFormInstance } = Form;

type VulnerabilitySelectProps = {
	loading: boolean;
	cycleId?: string;
	disabled: boolean;
	fieldName: NamePath;
	allowCreation?: boolean;
	vulnerabilityId?: string;
	handleClearVulnerability?: () => void;
	vulnerabilities: VulnerabilityDTO[] | undefined;
	handleSelectVulnerability?: (id: string) => void;
};

type OptionType = { label: string; value: string } | undefined;

export function VulnerabilitySelect({
	loading,
	disabled,
	fieldName,
	vulnerabilityId,
	vulnerabilities,
	handleClearVulnerability,
	handleSelectVulnerability
}: Readonly<VulnerabilitySelectProps>) {
	const { validateFields } = useFormInstance();

	async function handleChange(id: string) {
		if (handleSelectVulnerability) {
			handleSelectVulnerability(id);
			await validateFields([fieldName]);
		}
	}

	function handleClear() {
		if (handleClearVulnerability) {
			handleClearVulnerability();
		}
	}

	function filterOption(option: OptionType, input: string): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	const mappedVulnerabilities = vulnerabilities?.map(({ id, description, score }) => ({
		label: I18n.get(`[${score}] ${description}`),
		value: id
	}));

	return (
		<Select
			showSearch
			allowClear
			loading={loading}
			disabled={disabled}
			onClear={handleClear}
			value={vulnerabilityId}
			onChange={handleChange}
			options={mappedVulnerabilities}
			placeholder={I18n.get('Vulnerability')}
			filterOption={(input, option) => filterOption(option, input)}
		/>
	);
}
