import React from 'react';
import { Col, Divider, Row } from 'antd';

import { I18n } from '@aws-amplify/core';

import { TaskContainer, Title, Topic } from './style';
import { TaskDTO } from '../../../../hooks/types';

type ActionPlanTaskProps = {
	task: TaskDTO;
	length: number;
};

export const ActionPlanTask: React.FC<ActionPlanTaskProps> = ({ task, length }) => {
	function hasDivider() {
		return length > 0;
	}

	return (
		<TaskContainer>
			<Row justify="space-between">
				<Col
					span={14}
					style={{
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis'
					}}
				>
					<Topic>•</Topic>
					<Title>{task.description}</Title>
				</Col>
				<Col span={'auto'}>{I18n.get(task.type)}</Col>
			</Row>
			{hasDivider() && <Divider style={{ margin: '0.25rem 0' }} />}
		</TaskContainer>
	);
};
