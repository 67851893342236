import React from 'react';
import { Col, Row } from 'antd';

import { SeraSteps } from './SeraSteps';
import { SeraStepsProvider } from './context';

export const Sera: React.FC = () => {
	return (
		<Row>
			<Col xs={24}>
				<SeraStepsProvider>
					<SeraSteps />
				</SeraStepsProvider>
			</Col>
		</Row>
	);
};
