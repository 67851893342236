import React, { Fragment, useState } from 'react';
import { SketchPicker } from 'react-color'
import { ButtonColor, ColorSelect, PopoverColor, CoverColor } from './styles';

const OPACITY_COLOR_BANDS = 0.3;

export const normalizeRGBOpacity = ({ rgb }) => `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${OPACITY_COLOR_BANDS})`;

export function ColorPicker({ value, onChange }) {

	const [state, setState] = useState({
		displayColorPicker: false,
		color: value || '#000'
	});

	const handleClick = () => {
		setState(current => ({ ...current, displayColorPicker: !current.displayColorPicker }));
	};

	const handleClose = () => {
		setState(current => ({ ...current, displayColorPicker: false }));
	};

	const handleChange = (color) => {
		// if (onChange) onChange(color);
		// setState(current => ({ ...current, color: color.hex }));

		if (onChange) onChange(color);
		setState(current => ({ ...current, color: color.hex }));
	};

	return (
		<Fragment>
			<ButtonColor onClick={handleClick}>
				<ColorSelect color={state.color} />
			</ButtonColor>
			{state.displayColorPicker ?
				<PopoverColor>
					<CoverColor onClick={handleClose} />
					<SketchPicker color={state.color} onChange={handleChange} />
				</PopoverColor> : null
			}
		</Fragment>
	);
}
