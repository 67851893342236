import React from 'react';
import { Row, Col } from 'antd';

import { useSocketReport } from '@/hooks';
import { ToolCard } from '@/components/ToolCard';
import { PreviousValues } from './PreviousValues';
import type { ErgonomicTools, REPORT_TYPE } from '@/hooks';
import { useStepKeysContext } from './DefaultSelects/context';
import { BodyPadding } from '@/components/ui/CollapseCheck/styles';
import { AdditionalComponents, DefaultSelects, SubStepKeys } from './index';

enum Tools {
	niosh = 'niosh',
	kim_pp = 'kim_pp',
	kim_mho = 'kim_mho',
	strain_index = 'strain_index',
	liberty_mutual = 'liberty_mutual',
	back_compressive_force_estimation = 'back_compressive_force_estimation'
}

const reports: { [key in REPORT_TYPE]: Tools } = {
	niosh: Tools.niosh,
	'kim-pp': Tools.kim_pp,
	'strain-index': Tools.strain_index,
	'kim-mho': Tools.kim_mho,
	'liberty-mutual': Tools.liberty_mutual,
	'back-compressive-force-estimation': Tools.back_compressive_force_estimation
};

interface StepKeyPros {
	subStepKeysComponent?: JSX.Element;
}

export const StepKey: React.FC<StepKeyPros> = ({ subStepKeysComponent }) => {
	const { niosh, kimPushPull, kimMHO, strainIndex, libertyMutual, backCompressiveForceEstimation } =
		useSocketReport();
	const { file_id, stepKey, formFieldName, tools, tools_tooltip } = useStepKeysContext();

	const socketsValues: { [key: string]: boolean | undefined } = {
		niosh: niosh.status,
		'kim-mho': kimMHO.status,
		'kim-pp': kimPushPull.status,
		'strain-index': strainIndex.status,
		'liberty-mutual': libertyMutual.status,
		'back-compressive-force-estimation': backCompressiveForceEstimation.status
	};

	function isActive(tool: ErgonomicTools): boolean {
		const reportTool = tools[reports[tool.description]];
		return !!reportTool || !!socketsValues[tool.description];
	}

	if (stepKey?.sub_step_keys && stepKey.sub_step_keys.length > 0) {
		return subStepKeysComponent ?? <SubStepKeys subStepKeys={stepKey?.sub_step_keys} />;
	}

	return (
		<>
			<PreviousValues negative={true} />
			<BodyPadding>
				<Row justify="center">
					<Col sm={24} xxl={22}>
						<DefaultSelects stepKeyId={stepKey.id} formFieldName={formFieldName}>
							<Row gutter={[0, 20]}>
								{stepKey.ergonomic_tool?.map((tool) => (
									<Col key={tool.id}>
										<ToolCard
											fileId={file_id}
											title={tool.title}
											type={tool.description}
											isActive={isActive(tool)}
											subTitle={tool.subtitle}
											reportSelected={tool.description}
											toolTip={tools_tooltip?.[tool.description]?.tooltip}
											toolTipPosition={tools_tooltip?.[tool.description]?.tooltip_position}
										/>
									</Col>
								))}
							</Row>
							{stepKey.additional_items && (
								<AdditionalComponents additionalItems={stepKey.additional_items} />
							)}
						</DefaultSelects>
					</Col>
				</Row>
			</BodyPadding>
		</>
	);
};
