import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Skeleton, Switch, message } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CustomReportStepKeyDTO, IntegratedErgonomicToolDTO } from '@/components/ui/Inputs/types/response/customReport';
import { ErgonomicToolMapper } from '@/components/ui/Inputs/types/response/ergonomicTool';
import { FileForCustomReport } from '@/components/ui/Inputs/types/response/file';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useSocketReport } from '@/hooks/useSocket';
import { ToolCard } from '@/components/ToolCard';

import { useFrequencies, useStressLevels, useTotalTaskDurations } from '../../../hooks/hooks';
import { StepKeyToolsService } from '../../../services/stepKeyToolsService';
import { ErgonomicToolsService } from '../../../services/ergonomicToolsService';
import { StepKeyService } from '../../../services/stepKeyService';

import { TotalTaskDurations } from './TotalTaskDuration';
import { Description, DropdownCol } from './styles';
import { CollapseCheck } from './CollapseCheck';
import { StressLevels } from './StressLevels';
import { Frequencies } from './Frequencies';
import { JobElement } from './JobElement';
import { TaskRPN } from './TaskRPN';

const { useFormInstance, useWatch } = Form;
const stepKeyToolsService = new StepKeyToolsService();
const ergonomicToolsService = new ErgonomicToolsService();

interface StepProps {
	stepId: string;
	emptyChecked: boolean;
	file: FileForCustomReport;
	stepKey: CustomReportStepKeyDTO;
	integratedTools: IntegratedErgonomicToolDTO[];
	onAnyStepKeyCheck: (stepKeyId: string, value: boolean) => void;
}

export const StepKey: React.FC<StepProps> = ({
	file,
	stepId,
	stepKey,
	emptyChecked,
	integratedTools,
	onAnyStepKeyCheck
}) => {
	const [taskRPN, setTaskRPN] = useState(0);
	const [checked, setChecked] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [populate, setPopulate] = useState(false);

	const { organization, company } = useApplicationContext();
	const { setFieldValue, validateFields } = useFormInstance();

	const stressLevels = useStressLevels({
		organization_id: organization?.id,
		company_id: company?.id,
		step_key_id: stepKey.id
	});

	const totalTaskDurations = useTotalTaskDurations({
		organization_id: organization?.id,
		company_id: company?.id,
		step_key_id: stepKey.id
	});

	const frequencies = useFrequencies({
		organization_id: organization?.id,
		company_id: company?.id,
		step_key_id: stepKey.id
	});

	const stepKeyService = new StepKeyService(stepKey, stressLevels.data, frequencies.data, totalTaskDurations.data);

	const totalTaskDurationId = useWatch([file.id, stepId, stepKey.id, 'total_task_duration_id']);
	const stressLevelId = useWatch([file.id, stepId, stepKey.id, 'stress_level_id']);
	const frequencyId = useWatch([file.id, stepId, stepKey.id, 'frequency_id']);

	const selectedStressLevel = stressLevels.data.find((stressLevel) => stressLevel.id === stressLevelId);

	const { niosh, kimMHO, kimPushPull, strainIndex } = useSocketReport();
	const ergonomicTool: ErgonomicToolMapper = ergonomicToolsService.mapErgonomicTools(file, {
		niosh,
		kimMHO,
		kimPushPull,
		strainIndex
	});

	const mappedTools: ErgonomicToolMapper = ergonomicToolsService.mapTools(ergonomicTool, integratedTools);

	useEffect(() => {
		const task_rpn = stepKeyService.calculateTaskRPN(stressLevelId, frequencyId, totalTaskDurationId);
		if (task_rpn) {
			setFieldValue([file.id, stepId, stepKey.id, 'task_rpn'], task_rpn);
			setTaskRPN(task_rpn);
			return;
		}
		setFieldValue([file.id, stepId, stepKey.id, 'task_rpn'], 'N/A');
		setTaskRPN(0);
	}, [stressLevelId, frequencyId, totalTaskDurationId]);

	useEffect(() => {
		integratedTools.forEach((tool) => {
			if (!ergonomicTool[tool?.name].isActive) {
				setDisabled(true);
				return;
			}
			setDisabled(false);
		});
	}, [ergonomicTool]);

	function handleCheckStepKey(value: boolean) {
		if (emptyChecked) {
			return;
		}
		setPopulate(false);
		setChecked(value);
		onAnyStepKeyCheck(stepKey.id, value);
	}

	function handleSwitchChange() {
		if (populate) {
			setPopulate(false);
			setFieldValue([file.id, stepId, stepKey.id, 'stress_level_id'], undefined);
			return;
		}
		const calculatedId = stepKeyToolsService.calculateStressLevelScore(stressLevels.data, mappedTools);
		if (calculatedId) {
			handlePopulate(calculatedId);
			return;
		}
		message.error(I18n.get('No matching option with integrated tool score.'));
	}

	function handlePopulate(id: string) {
		setFieldValue([file.id, stepId, stepKey.id, 'stress_level_id'], id);
		validateFields([[file.id, stepId, stepKey.id, 'stress_level_id']]);
		setPopulate(true);
	}

	const showToolCard = (): React.ReactNode => {
		return integratedTools?.map((integratedTool) => {
			if (populate) {
				return null;
			}
			return (
				<Row key={integratedTool?.id} align="bottom" style={{ marginTop: '1rem' }}>
					<ToolCard
						key={integratedTool?.id}
						isActive={ergonomicTool[integratedTool?.name].isActive}
						reportSelected={integratedTool?.description}
						title={integratedTool?.title}
						fileId={file.id}
					/>
				</Row>
			);
		});
	};

	const showPopulateSwitch = (): React.ReactNode => {
		return (
			integratedTools?.length > 0 && (
				<Row align="bottom">
					<Col span={24} style={{ marginTop: '1rem' }}>
						<Description>{I18n.get('Populate using tool')}</Description>
					</Col>
					<Col span={24}>
						<Switch disabled={disabled} checked={populate} onChange={() => handleSwitchChange()} />
					</Col>
				</Row>
			)
		);
	};

	const loading = stressLevels.isLoading || totalTaskDurations.isLoading || frequencies.isLoading;

	return (
		<Col xs={22}>
			<Form.Item name={[file.id, stepId, stepKey.id, 'step_key']}>
				{loading ? (
					<Skeleton.Input block active />
				) : (
					<CollapseCheck
						key={stepKey.id}
						value={checked}
						anyOtherChecked={emptyChecked}
						title={stepKey.description}
						onChange={handleCheckStepKey}
					>
						<Row justify="space-between" gutter={[64, 12]}>
							{checked && (
								<>
									<DropdownCol xs={24} sm={24} md={24} lg={12}>
										<Row align="bottom">
											<StressLevels
												key={stepKey.id}
												disabled={populate}
												isError={stressLevels.isError}
												stressLevels={stressLevels.data}
												isLoading={stressLevels.isLoading}
												isFetching={stressLevels.isFetching}
												fieldName={[file.id, stepId, stepKey.id, 'stress_level_id']}
												fieldScoreName={[file.id, stepId, stepKey.id, 'stress_level_score']}
											/>
										</Row>
										<Row align="bottom">
											<Frequencies
												key={stepKey.id}
												disabled={populate}
												stepKeyName={stepKey.name}
												isError={frequencies.isError}
												frequencies={frequencies.data}
												isLoading={frequencies.isLoading}
												isFetching={frequencies.isFetching}
												selectedStressLevel={selectedStressLevel}
												fieldName={[file.id, stepId, stepKey.id, 'frequency_id']}
												fieldScoreName={[file.id, stepId, stepKey.id, 'frequency_score']}
											/>
										</Row>
										<Row align="bottom">
											<TotalTaskDurations
												key={stepKey.id}
												disabled={populate}
												stepKeyName={stepKey.name}
												isError={totalTaskDurations.isError}
												isLoading={totalTaskDurations.isLoading}
												selectedStressLevel={selectedStressLevel}
												isFetching={totalTaskDurations.isFetching}
												totalTaskDurations={totalTaskDurations.data}
												fieldName={[file.id, stepId, stepKey.id, 'total_task_duration_id']}
												fieldScoreName={[
													file.id,
													stepId,
													stepKey.id,
													'total_task_duration_score'
												]}
											/>
										</Row>
										{showPopulateSwitch()}
										{showToolCard()}
									</DropdownCol>
									<Col xs={24} sm={24} md={24} lg={12}>
										<Row>
											<JobElement fieldName={[file.id, stepId, stepKey.id, 'job_element']} />
										</Row>
										<Row>
											<TaskRPN
												fieldName={[file.id, stepId, stepKey.id, 'task_rpn']}
												task_rpn={taskRPN}
											/>
										</Row>
									</Col>
								</>
							)}
						</Row>
					</CollapseCheck>
				)}
			</Form.Item>
		</Col>
	);
};
