import { CustomReportFilesDTO } from '@/components/ui/Inputs/types/response/customReport';
import { FileForCustomReport } from '@/components/ui/Inputs/types/response/file';
import { SeraSummaryDTO } from '../hooks/types/response';
import { TaskFile } from '../hooks/types/request';

export type SeraPrioritiesScale = 'none' | 'low' | 'medium' | 'medium_high' | 'high' | 'very_high';
export type SeraPriorities = '' | 'Low' | 'Medium' | 'High';

export type PriorityScaleMapper = {
	[key in keyof typeof SCALE_MAPPER]: {
		priority: SeraPriorities;
		scale: SeraPrioritiesScale;
	};
};

export type FileTask = {
	id: string;
	task_id: string | undefined;
	task_name: string | undefined;
};

export type TaskListProps = {
	id: string;
	taskId?: string;
	type: 'ELIMINATE' | 'CONTROL' | 'COMPENSATE';
	description: string;
	isCompleted: boolean;
};

export const PRIORITY_SCALE_MAPPER: PriorityScaleMapper = {
	none: {
		priority: '',
		scale: 'none'
	},
	low: {
		priority: 'Low',
		scale: 'low'
	},
	medium: {
		priority: 'Medium',
		scale: 'medium'
	},
	medium_high: {
		priority: 'Medium',
		scale: 'medium_high'
	},
	high: {
		priority: 'High',
		scale: 'high'
	},
	very_high: {
		priority: 'High',
		scale: 'very_high'
	}
};

export const SCALE_MAPPER = {
	none: 'none',
	low: 'low',
	medium: 'medium',
	medium_high: 'medium_high',
	high: 'high',
	very_high: 'very_high'
};

export const SCORE_COLLOR_MAPPER = {
	none: '',
	low: '#2CC852',
	medium: '#F8D627',
	medium_high: '#F78A38',
	high: '#E74150',
	very_high: '#9B54E2'
};

export type RPNPriority = {
	color: string;
	priority: string;
};

export type SeraCommonInformations = {
	line_id: string;
	cycle_id: string;
	sector_id: string;
	company_id: string;
	report_name: string;
	files_list: string[];
	evaluator_id: string;
	workstation_id: string;
	organization_id: string;
};

export type FormListItem = {
	deleted: boolean;
	key: number;
	active: boolean;
};

export type FilesTasks = {
	[fileId: string]: {
		task_id: string;
	};
};

export type State = {
	page: number;
	limit: number;
	fileName: string;
	seraSummaryId: string;
	filesTasks: FileTask[];
	showSeraResult: boolean;
	seraSummary: SeraSummaryDTO;
	seraFormList: FormListItem[];
	seraFiles: CustomReportFilesDTO;
	selectedFiles: FileForCustomReport[];
	selectedFilesId: React.Key[] | string[];
	seraCommonInformations: SeraCommonInformations;
};

export type Methods = {
	filterSelectedFiles(
		selectedRowKeys: React.Key[],
		seraFiles: CustomReportFilesDTO | undefined
	): FileForCustomReport[];
	handleSizeChange(size: number): void;
	handlePageChange(page: number): void;
	handleSeraSummaryIdChange(id: string): void;
	handleFileNameChange: (name: string) => void;
	handleShowSeraResultChange(show: boolean): void;
	handleFilesTasksChange: (files: FileTask[]) => void;
	handleSeraSummaryChange: (data: SeraSummaryDTO) => void;
	handleSeraFormListChange(formList: FormListItem[]): void;
	mapRPNToPriority: (rpn: number | undefined) => RPNPriority;
	handleSelectedFilesChange(data: FileForCustomReport[]): void;
	mapFilesTasks(files_list: string[], values: FilesTasks): TaskFile[];
	handleSeraFilesChange(data: CustomReportFilesDTO | undefined): void;
	handleSeraCommonInformationsChange: (data: SeraCommonInformations) => void;
};

export type Context = State & Methods;
