import { Divider, Row } from 'antd'
import React from 'react'
import { ReviewsHistory } from '../ReviewsHistory'
import { CustomReportReviewHistoryDTO } from '@/hooks/useGetCustomReportReviewHistory'
import { SumRPN, HighestRPN } from '../ReviewsHistory/Chart'

export const GeneralHistory = (reviewHistory: CustomReportReviewHistoryDTO) => {
	return (
		<Row style={{
			marginLeft: 45,
			marginRight: 45,
		}}>
			<SumRPN data={reviewHistory.sum_rpns} />
			<Divider type="horizontal" />
			<HighestRPN data={reviewHistory.highest_rpns} />
			<Divider type="horizontal" />
			<ReviewsHistory reviewsHistory={reviewHistory?.reviews_history || []} />
			<Divider type="horizontal" />
		</Row>
	)
}
