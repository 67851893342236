import React from 'react';
import { DeleteOutlined, DownOutlined, EditOutlined, ToolOutlined } from '@ant-design/icons';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Button, Dropdown, Space } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CategoryResponse } from './hooks/types/response';
import { ActionDropdown } from './styles';

type ActionsProps = {
	isEditing: boolean;
	category: CategoryResponse;
	categories: CategoryResponse[];
	onManageCategoryModal: (category: CategoryResponse) => void;
	onDelete: (event: React.MouseEvent<HTMLElement, MouseEvent>, categoryId: string) => void;
	onUpdate: (event: React.MouseEvent<HTMLElement, MouseEvent>, categoryId: string) => void;
};

export const Actions: React.FC<ActionsProps> = ({
	onDelete,
	onUpdate,
	isEditing,
	category,
	categories,
	onManageCategoryModal
}) => {
	function handleManageCategory() {
		onManageCategoryModal(category);
	}

	function handleUpdate(event: React.MouseEvent<HTMLElement, MouseEvent>) {
		onUpdate(event, category?.id);
	}

	function handleDelete(event: React.MouseEvent<HTMLElement, MouseEvent>) {
		if (categories.length === 1) {
			return;
		}
		onDelete(event, category?.id);
	}

	const disabled = categories.length === 1 || category?.risk_description?.length > 0;

	const items: ItemType[] = [
		{
			key: 1,
			icon: <ToolOutlined />,
			label: (
				<Button style={{ padding: 0 }} type="link" onClick={handleManageCategory}>
					{I18n.get('Manage risk description')}
				</Button>
			)
		},
		{
			key: 2,
			icon: <EditOutlined />,
			label: (
				<Button type="link" style={{ padding: 0 }} onClick={(e) => handleUpdate(e)}>
					{I18n.get('Update category name')}
				</Button>
			)
		},
		{
			key: 3,
			icon: <DeleteOutlined />,
			danger: true,
			label: (
				<Button type="link" style={{ padding: 0 }} disabled={disabled} onClick={(e) => handleDelete(e)}>
					{I18n.get('Delete category')}
				</Button>
			),
			disabled
		}
	];

	return (
		<Dropdown menu={{ items }} disabled={isEditing} placement="bottom" trigger={['click']}>
			<ActionDropdown>
				<Space>
					{I18n.get('Action')}
					<DownOutlined />
				</Space>
			</ActionDropdown>
		</Dropdown>
	);
};
