type ParamsOptions = 'sector' | 'line' | 'page' | 'company';

export class ManipulateUrlServices {
	public hasSearchParam(param: string) {
		return window.location?.search.indexOf(param) > 0;
	}

	public setNewUrl(param: ParamsOptions, value: string): void {
		const url =
			window.location.protocol + '//' + window.location.host + window.location.pathname + window.location.search;
		let newUrl = '';
		if (window.location.search.indexOf(`${param}`) > 0) {
			newUrl = this.updateQueryStringParameter(url, param, value);
			window.history.pushState({ path: newUrl }, '', newUrl);
			return;
		}
		const specialCharacter = param === 'company' ? '?' : '&';
		newUrl = url + `${specialCharacter}${param}=${value}`;
		window.history.pushState({ path: newUrl }, '', newUrl);
	}

	public removeUrlParam(pageNumber: number): void {
		const char = pageNumber === 0 ? '?' : '&';
		const url =
			window.location.protocol + '//' + window.location.host + window.location.pathname + window.location.search;
		const position = url.split(char, pageNumber + 1).join(char).length;
		if (!position) {
			return;
		}
		const newUrl = url.slice(0, position);
		window.history.pushState({ path: newUrl }, '', newUrl);
	}

	public getParamValue(param: string): string | undefined {
		const paramIndex = window.location.search.indexOf(`${param}=`);
		if (paramIndex < 0) {
			return;
		}
		const [value] = window.location.search.slice(paramIndex).split('=')[1].split('&');
		return value;
	}

	public resetParams(): void {
		const url = window.location.protocol + '//' + window.location.host + window.location.pathname;
		window.history.pushState({ path: url }, '', url);
	}

	private updateQueryStringParameter(url: string, key: ParamsOptions, value: string) {
		var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
		var separator = url.indexOf('?') !== -1 ? '&' : '?';
		if (url.match(re)) {
			return url.replace(re, '$1' + key + '=' + value + '$2');
		} else {
			return url + separator + key + '=' + value;
		}
	}
}
