import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Divider, Row } from 'antd';

import { Container } from './styles';
import { FirstRow } from './FirstRow';
import { SecondRow } from './SecondRow';
import { Title } from '@/components/Typography';

import {
	Mass,
	Score,
	Vehicle,
	MassKey,
	Vehicles,
	RiskLoad,
	Distance,
	Duration,
	DistanceKey,
	DurationKey,
	KimPushPullDTO,
	ParametersChildren
} from './types';
export interface IKimPushPullProps {
	data: KimPushPullDTO;
}

export const KimPushPull: React.FC<IKimPushPullProps> = ({ data }) => {
	function getRisk(score: Score): RiskLoad {
		if (score < 20) {
			return RiskLoad.LOW;
		}

		if (score < 50) {
			return RiskLoad.MODERATE;
		}

		if (score < 100) {
			return RiskLoad.VERY_HIGH;
		}

		return RiskLoad.VERY_HIGH;
	}

	function getMass(mass: Mass): MassKey {
		switch (mass) {
			case 0: {
				return MassKey.LESS_THAN_OR_EQUAL_50;
			}
			case 1: {
				return MassKey.LESS_THAN_OR_EQUAL_100;
			}
			case 2: {
				return MassKey.LESS_THAN_OR_EQUAL_200;
			}
			case 3: {
				return MassKey.LESS_THAN_OR_EQUAL_300;
			}
			case 4: {
				return MassKey.LESS_THAN_OR_EQUAL_400;
			}
			case 5: {
				return MassKey.LESS_THAN_OR_EQUAL_600;
			}
			case 6: {
				return MassKey.LESS_THAN_OR_EQUAL_800;
			}
			case 7: {
				return MassKey.LESS_THAN_OR_EQUAL_1000;
			}
			case 8: {
				return MassKey.LESS_THAN_OR_EQUAL_1300;
			}
			case 9: {
				return MassKey.GREATER_THAN_1300;
			}
			default:
				throw new Error('Mass not registered');
		}
	}

	function getVehicle(vehicle: Vehicle) {
		if (vehicle >= 0 && vehicle <= 2) {
			return Vehicles.BARROW;
		}

		if (vehicle >= 3 && vehicle <= 7) {
			return Vehicles.CARRIAGE;
		}

		if (vehicle === 8) {
			return Vehicles.CONVEYOR;
		}

		return Vehicles.CRANE;
	}

	function getDistance(distance: Distance | null) {
		switch (distance) {
			case 0:
				return DistanceKey.LESS_THAN_OR_EQUAL_40;
			case 1:
				return DistanceKey.LESS_THAN_OR_EQUAL_200;
			case 2:
				return DistanceKey.LESS_THAN_OR_EQUAL_400;
			case 3:
				return DistanceKey.LESS_THAN_OR_EQUAL_800;
			case 4:
				return DistanceKey.LESS_THAN_OR_EQUAL_1200;
			case 5:
				return DistanceKey.LESS_THAN_OR_EQUAL_1800;
			case 6:
				return DistanceKey.LESS_THAN_OR_EQUAL_2500;
			case 7:
				return DistanceKey.LESS_THAN_OR_EQUAL_4200;
			case 8:
				return DistanceKey.LESS_THAN_OR_EQUAL_6300;
			case 9:
				return DistanceKey.LESS_THAN_OR_EQUAL_8400;
			case 10:
				return DistanceKey.LESS_THAN_OR_EQUAL_11000;
			case 11:
				return DistanceKey.LESS_THAN_OR_EQUAL_15000;
			case 12:
				return DistanceKey.LESS_THAN_OR_EQUAL_20000;
			default:
				return null;
		}
	}

	function getDuration(duration: Duration | null) {
		switch (duration) {
			case 0:
				return DurationKey.LESS_THAN_OR_EQUAL_1;
			case 1:
				return DurationKey.LESS_THAN_OR_EQUAL_5;
			case 2:
				return DurationKey.LESS_THAN_OR_EQUAL_10;
			case 3:
				return DurationKey.LESS_THAN_OR_EQUAL_20;
			case 4:
				return DurationKey.LESS_THAN_OR_EQUAL_30;
			case 5:
				return DurationKey.LESS_THAN_OR_EQUAL_45;
			case 6:
				return DurationKey.LESS_THAN_OR_EQUAL_60;
			case 7:
				return DurationKey.LESS_THAN_OR_EQUAL_100;
			case 8:
				return DurationKey.LESS_THAN_OR_EQUAL_150;
			case 9:
				return DurationKey.LESS_THAN_OR_EQUAL_210;
			case 10:
				return DurationKey.LESS_THAN_OR_EQUAL_270;
			case 11:
				return DurationKey.LESS_THAN_OR_EQUAL_360;
			case 12:
				return DurationKey.LESS_THAN_OR_EQUAL_480;
			default:
				return null;
		}
	}

	function normalizeInputs(data: KimPushPullDTO) {
		const risk_load = getRisk(data.score);
		const vehicle = getVehicle(data.vehicle);
		const score = data.score ? Math.round(data.score) : 0;

		const distance = data.distance_or_duration < 13 ? data.distance_or_duration : null;
		const duration = data.distance_or_duration >= 13 ? data.distance_or_duration : null;

		const distance_format = getDistance(distance);
		const duration_format = getDuration(duration);
		const mass = getMass(data.mass);

		return {
			distance: distance_format,
			duration: duration_format,
			risk_load: risk_load,
			risk_score: score,
			vehicle: vehicle,
			mass
		};
	}

	const parameters: ParametersChildren = normalizeInputs(data);

	return (
		<Row gutter={[0, 24]}>
			<Col offset={1} span={23}>
				<Row>
					<Title style={{ margin: 0 }} level={3}>
						{I18n.get('Push and Pull (KIM)')}
					</Title>
				</Row>
			</Col>

			<Col offset={1} span={23}>
				<Row>
					<Container>
						<Col span={24}>
							<FirstRow parameters={parameters} />
						</Col>
						<Divider style={{ margin: 0 }} />
						<Col span={24}>
							<SecondRow parameters={parameters} />
						</Col>
					</Container>
				</Row>
			</Col>
		</Row>
	);
};
