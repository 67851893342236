import { useMutation, useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import type { AxiosError } from 'axios';
import { message } from 'antd';

import Api from '@/services/api';
import type { BaseContext, CharacteristicResult, Response } from '@/types';

export type CreateCharacteristicsDTO = BaseContext & {
	step_id: string;
	workstation_id?: string;
	custom_report_id: string;
	custom_report_result_id?: string;
	working_population_men?: number;
	total_working_population: number;
	worker_self_evaluation_id: string;
	working_population_women?: number;
	working_population_others?: number;
	particularities_description?: string;
	worker_verbalization_description?: string;
};

async function createCharacteristics(body: CreateCharacteristicsDTO): Promise<CharacteristicResult> {
	const url = '/custom-report/result/characteristic';
	const { data } = await Api.post<CharacteristicResult>(url, body);
	return data;
}

export const useCreateCharacteristicsResult = () => {
	const queryClient = useQueryClient();
	return useMutation<CharacteristicResult, AxiosError<Response>, CreateCharacteristicsDTO>(
		(body: CreateCharacteristicsDTO) => createCharacteristics(body),
		{
			onError: (err) => {
				message.error(I18n.get(err?.response?.data?.message ?? err?.message));
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['jds_d86_report']);
				queryClient.invalidateQueries(['jds_d86_result']);
			}
		}
	);
};
