import { ExposureDTO } from '@/components/ui/Inputs/types/response/exposure';
import { SeverityDTO } from '@/components/ui/Inputs/types/response/severity';
import { VulnerabilityDTO } from '@/components/ui/Inputs/types/response/vulnerability';

type CalculateTaskRPN = {
	exposureId: string;
	severityId: string;
	vulnerabilityId: string;
};

type SeraRPNData = {
	exposures: ExposureDTO[];
	severities: SeverityDTO[];
	vulnerabilities: VulnerabilityDTO[];
};

type CalculateRPN = {
	exposureScore: number;
	severityScore: number;
	vulnerabilityScore: number;
};

type CalculateTaskRPNResponse = {
	rpn: number;
	exposureScore: number;
	severityScore: number;
	vulnerabilityScore: number;
};

interface SeraRPNInterface {
	calculateTaskRPN(data: CalculateTaskRPN): CalculateTaskRPNResponse | undefined;
}

export class SeraRPNService implements SeraRPNInterface {
	constructor(private data: SeraRPNData) {}

	public calculateTaskRPN({
		exposureId,
		severityId,
		vulnerabilityId
	}: CalculateTaskRPN): CalculateTaskRPNResponse | undefined {
		const { exposures, severities, vulnerabilities } = this.data;
		const exposure = exposures.find((exposure) => exposure.id === exposureId);
		const severity = severities.find((severity) => severity.id === severityId);
		const vulnerability = vulnerabilities.find((vulnerability) => vulnerability.id === vulnerabilityId);

		if (!exposure || !severity || !vulnerability) {
			return undefined;
		}

		const exposureScore = exposure.score;
		const severityScore = severity.score;
		const vulnerabilityScore = vulnerability.score;
		const rpn = this.calculate({
			exposureScore,
			severityScore,
			vulnerabilityScore
		});
		return {
			rpn,
			exposureScore,
			severityScore,
			vulnerabilityScore
		};
	}

	private calculate({ exposureScore, severityScore, vulnerabilityScore }: CalculateRPN) {
		return exposureScore * severityScore * vulnerabilityScore;
	}
}
