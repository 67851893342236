import React from 'react';
import * as Icons from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import { Button, Col, Row } from 'antd';

import { useApplicationContext } from '@/context/v1/Application/context';
import { useLibertyMutualContext } from '../../context';
import { useDownloadPDF } from '../../hooks';

interface DownloadPDFProps {
	disableDownload: boolean;
	onDownloadPDF: (editing: boolean) => void;
}

export const DownloadPDF: React.FC<DownloadPDFProps> = ({ onDownloadPDF, disableDownload }) => {
	const { file_id } = useLibertyMutualContext();
	const { organization, company } = useApplicationContext();

	const { mutateAsync: downloadPDF, isLoading: downloadingPDF } = useDownloadPDF();

	const locale = window.navigator.language ?? 'en-US';

	async function handleDownload() {
		onDownloadPDF(true);
		await downloadPDF({
			organization_id: organization?.id,
			company_id: company?.id,
			file_id,
			locale
		});
		onDownloadPDF(false);
	}

	return (
		<Row justify={'end'}>
			<Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Button
					style={{
						color: '#2F54EB',
						borderRadius: '0.25rem',
						border: '1px solid #2F54EB'
					}}
					loading={downloadingPDF}
					disabled={downloadingPDF || disableDownload}
					onClick={() => handleDownload()}
					icon={<Icons.DownloadOutlined style={{ color: '#2F54EB' }} />}
				>
					{I18n.get('Download PDF')}
				</Button>
			</Col>
		</Row>
	);
};
