import React from 'react';
import { Col, Row, Skeleton } from 'antd';

interface LoadingSkeletonProps {
	loading: boolean;
}

export const LoadingSkeleton: React.FC<LoadingSkeletonProps> = ({ loading }) => {
	if (!loading) {
		return null
	}

	return (
		<Row justify="center">
			<Col xs={23}>
				<Skeleton active title={false} paragraph={{
					rows: 3, width: '100%'
				}} />
			</Col>
		</Row>
	);
};
