export enum RISK_MAP {
	low = 1,
	moderate = 2,
	high = 3,
	very_high = 4,
	extreme = 5
}

export const riskTitleMapper = {
	[RISK_MAP.low]: 'Acceptable',
	[RISK_MAP.moderate]: 'Moderate',
	[RISK_MAP.high]: 'High',
	[RISK_MAP.very_high]: 'Very High',
	[RISK_MAP.extreme]: 'Serious and Imminent'
};

export const riskDescriptionMapper = {
	[RISK_MAP.low]: 'Ok',
	[RISK_MAP.moderate]: 'Improvement action',
	[RISK_MAP.high]: 'Immediate action for improvement and reassess',
	[RISK_MAP.very_high]: 'Perform Ergonomic Work Analysis',
	[RISK_MAP.extreme]: 'Stop the activity and carry out an Ergonomic Work Analysis'
};
