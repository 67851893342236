import React from 'react';
import { Col, Row, Skeleton } from 'antd';

import { PreviousCol } from '../../styles';

export const LoadingSkeleton = () => {
	return (
		<>
			<Row justify="center" gutter={[5, 20]}>
				<Col xs={21}>
					<Row align="middle">
						<Col xs={24}>
							<Skeleton.Input active block />
						</Col>
						<Col xs={24} style={{ marginBottom: '20px' }}>
							<Skeleton active paragraph />
						</Col>
					</Row>
					<Row justify="center">
						<Col span={23}>
							<Row justify="start">
								<Col span={24}>
									<Skeleton.Input active block />
								</Col>
								<Col>
									<Skeleton.Input active block />
								</Col>
								<Col>
									<Skeleton.Input active block />
								</Col>
							</Row>
							<Row justify="center" gutter={[0, 10]}>
								<Skeleton active paragraph />
								<Skeleton active paragraph />
								<Skeleton active paragraph />
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row>
				<Col xs={24} style={{ marginTop: '2rem' }}>
					<Row justify="center" align="middle" gutter={[0, 8]}>
						<PreviousCol>
							<Skeleton.Button active block />
						</PreviousCol>
						<Col>
							<Skeleton.Button active block />
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};
