import { TaskName } from './hooks/types/response';

const LIFT = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/lift.png';
const PUSH = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/push.png';
const PULL = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/pull.png';
const LOWER = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/lower.png';
const CARRY = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/carry.png';
const LIFT_HOVER = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/lift-hover.png';
const PUSH_HOVER = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/push-hover.png';
const PULL_HOVER = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/pull-hover.png';
const LOWER_HOVER = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/lower-hover.png';
const CARRY_HOVER = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/carry-hover.png';

export type TaskContent = {
	img: string;
	width: string;
	name: TaskName;
	img_hover: string;
};

export type TasksContentList = {
	[key: string]: TaskContent;
};

export const tasksContentList: TasksContentList = {
	[TaskName.lift]: {
		img: LIFT,
		width: '100%',
		name: TaskName.lift,
		img_hover: LIFT_HOVER
	},
	[TaskName.lower]: {
		img: LOWER,
		width: '100%',
		name: TaskName.lower,
		img_hover: LOWER_HOVER
	},
	[TaskName.push]: {
		img: PUSH,
		width: '110%',
		name: TaskName.push,
		img_hover: PUSH_HOVER
	},
	[TaskName.pull]: {
		img: PULL,
		width: '90%',
		name: TaskName.pull,
		img_hover: PULL_HOVER
	},
	[TaskName.carry]: {
		img: CARRY,
		width: '80%',
		name: TaskName.carry,
		img_hover: CARRY_HOVER
	}
};
