import styled from 'styled-components';
import { Form, Input, Row } from 'antd';

const { Item } = Form;

export const ActionsForm = styled(Form)`
	min-height: '250px';
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

export const ChangeDefaultContainer = styled(Row)`
	min-height: 40vh;
`;

export const EnableAuthContainer = styled(Row)`
	min-height: 40vh;
`;

export const EnableAuthDescription = styled.span`
	font-size: 1rem;
	font-weight: 400;
	text-align: center;
`;

export const ResetTokenContainer = styled(Row)`
	min-height: 40vh;
`;

export const ResetTokenDescription = styled.span`
	font-size: 1rem;
	font-weight: 400;
	text-align: center;
`;

export const RecoveryToken = styled(Item)`
	margin-top: 1rem;
	min-width: 10rem;
	text-align: center;

	.ant-form-item-explain-error {
		text-align: center;
	}
`;
export const RecoveryTokenInput = styled(Input)`
	text-align: center;
`;

export const DeactivateContainer = styled(Row)`
	min-height: 40vh;
`;

export const DeactivateTokenDescription = styled.span`
	font-size: 1rem;
	font-weight: 400;
	text-align: center;
`;

export const ValidateContainer = styled(Row)`
	min-height: 40vh;
`;

export const ValidationCode = styled(Item)`
	.ant-form-item-explain-error {
		text-align: center;
	}
`;

export const ValidateInput = styled(Input)`
	text-align: center;
`;

export const Countdown = styled.span`
	color: #2c2c2c77;
`;
