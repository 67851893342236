import React from 'react';
import Table, { ColumnsType } from 'antd/lib/table';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';
import { Row } from 'antd';

import { SeraResultReviewHistory } from '../hooks/types';
import { CustomTable } from './styles';

type TableData = Pick<SeraResultReviewHistory, 'id' | 'review' | 'created_at'>;

interface ReviewTableProps {
	data: TableData[];
}

export const ReviewTable: React.FC<ReviewTableProps> = ({ data }) => {
	const columns: ColumnsType<TableData> = [
		{
			title: I18n.get('Review'),
			dataIndex: 'review',
			key: 1,
			align: 'center'
		},
		{
			title: I18n.get('Created date'),
			dataIndex: 'created_at',
			key: 2,
			render: (created_at: Date) => moment(created_at).format('L'),
			align: 'center'
		}
	];

	return (
		<Row justify="center" style={{ marginBottom: '2rem' }}>
			<CustomTable span={12}>
				<Table rowKey="id" pagination={false} dataSource={data} columns={columns} />
			</CustomTable>
		</Row>
	);
};
