import React, { createContext, ReactNode, useContext, useState } from 'react';
import { useGetLastStepKeyResult } from '@/hooks/useGetLastStepKeyResult';
import { useApplicationContext } from '@/context/v1/Application/context';
import {
	StepKey,
	useGetExposures,
	useGetSeverities,
	useGetRiskCategories,
	useGetVulnerabilities,
	ToolsReponse
} from '@/hooks';
import type { ToolsToolTip, Context, Methods, States } from './types';

type StepKeysProviderProps = {
	stepKey: StepKey;
	children: ReactNode;
	file_id: string;
	original_custom_report_result_id?: string;
	formFieldName: string[];
	tools: ToolsReponse;
	tools_tooltip?: ToolsToolTip;
};

const StepKeysContext = createContext<Context>({} as Context);

export function StepKeysProvider({
	children,
	formFieldName,
	stepKey,
	file_id,
	original_custom_report_result_id,
	tools,
	tools_tooltip
}: Readonly<StepKeysProviderProps>) {
	const { organization, company } = useApplicationContext();
	const [showHistory, setShowHistory] = useState(false);
	const [subStepKeySeleceted, setSubStepKeySeleceted] = useState(stepKey?.sub_step_keys?.[0]?.id || '');

	const {
		data: exposures,
		isLoading: gettingExposures,
		isFetching: fetchingExposures
	} = useGetExposures({
		organization_id: organization?.id,
		company_id: company?.id,
		step_key_id: stepKey.id
	});

	const {
		data: severities,
		isLoading: gettingSeverities,
		isFetching: fetchingSeverities
	} = useGetSeverities({
		organization_id: organization?.id,
		company_id: company?.id,
		step_key_id: stepKey.id
	});

	const {
		data: vulnerabilities,
		isLoading: gettingVulnerabilities,
		isFetching: fetchingVulnerabilities
	} = useGetVulnerabilities({
		organization_id: organization?.id,
		company_id: company?.id,
		step_key_id: stepKey.id
	});

	const {
		data: riskCategories,
		isLoading: gettingRiskCategories,
		isFetching: fetchingRiskCategories
	} = useGetRiskCategories({
		organization_id: organization?.id,
		company_id: company?.id,
		step_key_id: stepKey.id
	});

	const { data: previousValue } = useGetLastStepKeyResult({
		organization_id: organization?.id,
		company_id: company?.id,
		step_key_id: stepKey.id,
		original_custom_report_result_id,
		hasPreviousValue: !!stepKey.previous_result?.result || Number(stepKey?.sub_step_keys?.length) > 0
	});

	const handleChangeShowHistory = (val: boolean) => {
		setShowHistory(val);
	};

	const handleChangeSubStepKey = (val: string) => {
		setSubStepKeySeleceted(val);
	};

	const states: States = {
		formFieldName,
		file_id,
		tools,
		stepKey,
		tools_tooltip,
		exposuresData: {
			exposures,
			gettingExposures,
			fetchingExposures
		},
		severitiesData: {
			severities,
			gettingSeverities,
			fetchingSeverities
		},
		vulnerabilitiesData: {
			vulnerabilities,
			gettingVulnerabilities,
			fetchingVulnerabilities
		},
		riskCategoriesData: {
			riskCategories,
			gettingRiskCategories,
			fetchingRiskCategories
		},
		previousValue,
		showHistory,
		subStepKeySeleceted
	};

	const methods: Methods = {
		handleChangeShowHistory,
		handleChangeSubStepKey
	};

	const context: Context = {
		...states,
		...methods
	};

	return <StepKeysContext.Provider value={context}>{children}</StepKeysContext.Provider>;
}

export function useStepKeysContext() {
	const context = useContext(StepKeysContext);
	return context;
}
