import React, { ReactNode, useState } from 'react';
import { NamePath } from 'antd/lib/form/interface';
import { Col, Form, Row } from 'antd';

import { useManageCategoryContext } from '../../context';
import { Selector } from './Selector';
import { Actions } from './Actions';
import { Editor } from './Editor';

const { useFormInstance } = Form;

interface DescriptionManagerProps {
	parentId: string;
	currentId?: string;
	fieldLabel: string;
	children: ReactNode;
	fieldName: NamePath;
	allowDeletion?: boolean;
	onDelete(): Promise<void>;
	editingNameField: NamePath;
	onUpdateName(): Promise<void>;
	currentName: string | undefined;
}

export const DescriptionManager: React.FC<DescriptionManagerProps> = ({
	onDelete,
	children,
	parentId,
	fieldName,
	currentId,
	fieldLabel,
	currentName,
	onUpdateName,
	editingNameField,
	allowDeletion = true
}) => {
	const { setFieldValue } = useFormInstance();
	const [editing, setEditing] = useState<boolean>(false);
	const { handleIsEditing } = useManageCategoryContext();

	function handleEditingChange() {
		if (currentId) {
			setFieldValue(['editing', 'currentId'], currentId);
		}
		if (!editing) {
			setFieldValue(editingNameField, currentName);
		}
		handleIsEditing(!editing);
		setEditing(!editing);
	}

	if (editing) {
		return (
			<Editor
				parentId={parentId}
				onUpdate={onUpdateName}
				currentName={currentName}
				fieldName={editingNameField}
				onEditingChange={handleEditingChange}
			/>
		);
	}

	return (
		<Col span={24}>
			<Row align="bottom" justify="space-between" gutter={[8, 0]}>
				<Col span={20}>
					<Selector
						fieldLabel={fieldLabel}
						disabled={!!parentId}
						isEditing={editing}
						currentId={currentId}
						fieldName={fieldName}
					>
						{children}
					</Selector>
				</Col>
				<Actions
					onDelete={onDelete}
					currentId={currentId}
					onEdit={handleEditingChange}
					allowDeletion={allowDeletion}
				/>
			</Row>
		</Col>
	);
};
