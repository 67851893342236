import React from 'react';
import { Col, Collapse, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { RebaResponse, RebaData } from '@/hooks';
import { ColCustom, RowCustom } from './styles';
import { PanelCustom } from '../styles';
import { Chart } from './Chart';

interface IBodyPartsScoresProps {
	reba: RebaResponse;
}

export const RebaResults: React.FC<IBodyPartsScoresProps> = ({ reba }) => {
	const { data: RebaDataRows } = reba;
	const bodyPartsDataArray: RebaData[] = [];

	RebaDataRows.forEach((row) => bodyPartsDataArray.push(...row));

	const bodyPartPercentages = bodyPartsDataArray.map(({ scores, name }) => ({
		percentage: scores.map(({ percentage }) => percentage),
		name
	}));

	return (
		<Collapse ghost>
			<PanelCustom header={I18n.get('Movement by score (Kinebot/JDS)')} key={'reba-chart'}>
				<Row justify={'center'}>
					<Col span={23}>
						<RowCustom>
							{bodyPartPercentages.map(({ name, percentage }) => (
								<ColCustom xxl={12} key={`${name}_chart`}>
									<Chart angles={percentage} member={name} />
								</ColCustom>
							))}
						</RowCustom>
					</Col>
				</Row>
			</PanelCustom>
		</Collapse>
	);
};
