import React from 'react';
import { Col } from 'antd';
import moment from 'moment';

import { RowCustomCardContainer } from '../styles';
import { Text } from '@/components/Typography';

interface RiskCardProps {
	side: string;
	score: number;
	risk: string;
}

export const RiskCard: React.FC<RiskCardProps> = ({ side, score, risk }) => {
	const locale = moment.locale();

	const transformedScore = Number(score.toFixed(2));
	const formattedScoreByLocale = new Intl.NumberFormat(locale).format(transformedScore);

	return (
		<RowCustomCardContainer wrap>
			<Col span={24}>
				<Text strong>{side}</Text>
			</Col>
			<Col span={24}>
				<Text strong>{formattedScoreByLocale}</Text>
			</Col>
			<Col span={17}>
				<Text strong>{risk}</Text>
			</Col>
		</RowCustomCardContainer>
	);
};
