import React from 'react';
import { Row } from 'antd';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

import { Container, CustomSteps } from './styles';
import { StepIcon } from './StepIcon';
import { useBackCompressiveForceEstimationContext } from '../../context';

interface StepsNavigationProps {
	currentStep: number;
	onStepChange(step: number): void;
}

export const StepsNavigation: React.FC<StepsNavigationProps> = ({ onStepChange, currentStep }) => {
	const { steps } = useBackCompressiveForceEstimationContext();
	const breakpoints = useBreakpoint();
	const color = '#2f54eb';

	const mappedSteps = steps.map((item, index) => {
		item.icon = (
			<StepIcon
				key={item.title}
				name={item.title}
				position={index}
				currentStep={currentStep}
				onStepChange={onStepChange}
			/>
		);
		return item;
	});

	return (
		<Row justify="center">
			<Container xs={24}>
				<CustomSteps
					size="small"
					color={color}
					items={mappedSteps}
					direction={breakpoints.xxl ? 'vertical' : "horizontal"}
					current={currentStep}
				/>
			</Container>
		</Row>
	);
};
