export const breadcrumbNameMap = {
	'/upload': 'Upload',
	'/reporting': 'Reports',
	'/reporting/angle-time': 'Angle by time',
	'/reporting/back-compressive-force-estimation': 'Back compressive force estimation',
	'/reporting/niosh': 'NIOSH',
	'/reporting/kim-pp': 'KIM - Push and pull',
	'/reporting/strain-index': 'Strain Index',
	'/reporting/kim-mho': 'Manual Handling Operations',
	'/reporting/reba': 'REBA',
	'/reporting/liberty-mutual': 'Liberty Mutual',
	'/reporting/preliminary-ergonomic-analysis': 'Preliminary Ergonomic Analysis',
	'/reporting/report': 'Report',
	'/reporting/report/liberty-mutual': 'Liberty Mutual',
	'/reporting/ewa-jds-d86': 'NR-17 + JDS-D86',
	'/preliminary-ergonomic-analysis': 'Preliminary Ergonomic Analysis',
	'/ewa': 'AEP',
	'/ewa/new-document': 'New document',
	'/ewa/file-history': 'File history',
	'/custom-reports': 'Custom report',
	'/custom-reports/bera': 'B.E.R.A.',
	'/custom-reports/files': 'Files',
	'/custom-reports/bera/report': 'Report',
	'/custom-reports/sera': 'S.E.R.A.',
	'/custom-reports/jds-d86': 'JDS-D86',
	'/custom-reports/jds-d92': 'JDS-D92',
	'/custom-reports/jds-d92/report': 'Report',
	'/custom-reports/jds-d86/report': 'Evaluation',
	'/custom-reports/jds-d86/review': 'Revaluation',
	'/custom-reports/manage-risk': 'Manage risks',
	'/action-plans': 'Action plans',
	'/settings': 'Settings',
	'/settings/information': 'Information',
	'/settings/payments': 'Payments',
	'/settings/plan': 'Plan',
	'/settings/account': 'My account',
	'/settings/manage-users': 'Manage users',
	'/settings/preferences': 'Preferences',
	'/settings/companies': 'Companies'
};
