import { useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import Api from '@/services/api';
import type { Response, BaseContext } from '@/types';

export type GetRpnDTO = BaseContext & {
	exposure_id: string;
	severity_id: string;
	vulnerability_id: string;
	custom_report_step_key_id: string;
};

export type RpnResultResponse = {
	result: 1 | 2 | 3 | 4 | 5;
	score: 1 | 2 | 3 | 4 | 5;
};

async function calculateRpn(params: GetRpnDTO): Promise<RpnResultResponse> {
	const url = '/custom-report/result/step-key/calculate-risk';
	const { data } = await Api.get<RpnResultResponse>(url, {
		params
	});

	return data;
}

export const useCalculateStepKeyRpn = (params: GetRpnDTO) => {
	const { exposure_id, severity_id, vulnerability_id, custom_report_step_key_id, company_id, organization_id } =
		params;
	const report = useQuery<RpnResultResponse, AxiosError<Response>>(
		['calculate_step_key_rpn', params],
		() => calculateRpn(params),
		{
			enabled:
				!!company_id &&
				!!exposure_id &&
				!!severity_id &&
				!!organization_id &&
				!!vulnerability_id &&
				!!custom_report_step_key_id
		}
	);
	return {
		...report,
		data: report.data ?? ({} as RpnResultResponse)
	};
};
