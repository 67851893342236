import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Form, notification } from 'antd';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import type { EwaJdsD86ReportResponse, GetCustomReportByNameResponse, Step } from '@/hooks';
import { Informations, WorkConditions, GenericStep, Characteristics, SelectFile } from '../Report/Steps';
import { States, Context, Methods, EwaJdsD86RouteParams, ActionPlan } from './types';
import { Results } from '../Report/Steps/Results';
import { ActionPlanModal } from '../Report/components/ActionPlanModal';

type EwaJdsD86ProviderProps = {
	children: ReactNode;
	ewaJdsD86: EwaJdsD86ReportResponse;
	customReport: GetCustomReportByNameResponse;
};

const { useForm, useWatch } = Form;
const EwaJdsD86Context = createContext<Context>({} as Context);

type DefaultStepsMapper = {
	[key: string]: JSX.Element;
};

const defaultSteps: DefaultStepsMapper = {
	video_selection: <SelectFile />,
	informations: <Informations />,
	work_conditions: <WorkConditions />,
	characteristics: <Characteristics />
};

export const EwaJdsD86Provider: React.FC<Readonly<EwaJdsD86ProviderProps>> = ({
	children,
	ewaJdsD86,
	customReport
}) => {
	const [form] = useForm();

	const { file_id, original_custom_report_result_id } = useParams<EwaJdsD86RouteParams>();
	const [currentStep, setCurrentStep] = useState<number>(ewaJdsD86.current_step ?? 0);
	const [steps, setSteps] = useState<Step[]>([]);
	const [modal, setModal] = useState<ActionPlan>();
	const fileForm = useWatch(['file_id'], form) || {};
	const isEwa = useLocation().pathname.includes('ewa-jds-d86');
	const history = useHistory();

	const initialValues = {
		...ewaJdsD86,
		informations: {
			...ewaJdsD86.informations,
			collection_date: moment(ewaJdsD86.informations.collection_date) ?? moment()
		}
	};

	useEffect(() => {
		const steps: Step[] = ewaJdsD86.steps.map(({ id, name, description, sequence, step_keys }) => ({
			id,
			name,
			sequence,
			step_keys,
			description,
			component: defineComponent({ id, name, description, sequence, step_keys })
		}));

		setSteps(steps);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function defineComponent({ id, name, description, step_keys, sequence }: Step): JSX.Element {
		let defaultComponent = defaultSteps[name];
		if (!defaultComponent) {
			defaultComponent = (
				<GenericStep title={description} stepKeys={step_keys} stepId={id} stepNumber={sequence} />
			);
		}
		let component = defaultComponent;
		if (isResultStep(name)) {
			component = <Results />;
		}
		return component;
	}

	function isResultStep(name: string) {
		return name === 'results';
	}

	function scrollToError(error: any) {
		const errorName = error?.errorFields[0]?.name;
		form.scrollToField(errorName, { behavior: 'smooth', block: 'center', inline: 'center' });

		notification.error({
			message: I18n.get('Ops... something happened!'),
			description: I18n.get("Some required step wasn't filled"),
			duration: 5
		});
	}

	function handlePreviousStep() {
		setCurrentStep(currentStep - 1);
	}

	async function handleStepNagivationClick(step: number) {
		if (!ewaJdsD86?.is_completed && step > currentStep + 1) {
			return;
		}

		if (ewaJdsD86?.is_completed && !isEwa && step === 0) {
			return;
		}

		if (step > currentStep) {
			await form.validateFields();
		}
		setCurrentStep(step);
	}

	async function handleNextStep() {
		await form.validateFields();
		const isReview = history.location.pathname.includes('review');
		if (!isEwa && isReview && steps.length - 1 === currentStep + 1) {
			history.push(`/custom-reports/jds-d86/report/${original_custom_report_result_id}`);
			return;
		}
		setCurrentStep(currentStep + 1);
	}

	async function handleCloseModal() {
		setModal(undefined);
	}

	async function handleOpenModal(data: ActionPlan) {
		setModal(data);
	}

	const currentStepId = steps[currentStep]?.id;

	const states: States = {
		isEwa,
		steps,
		file_id: file_id ?? fileForm?.id ?? ewaJdsD86?.file_id,
		original_custom_report_result_id,
		ewaJdsD86,
		currentStep,
		customReport,
		currentStepId,
		lastGenericStepNumber: ewaJdsD86.total_steps - 1
	};

	const methods: Methods = {
		scrollToError,
		handleNextStep,
		handlePreviousStep,
		handleStepNagivationClick,
		handleOpenModal,
	};

	const context: Context = {
		...states,
		...methods
	};

	return (
		<EwaJdsD86Context.Provider value={context}>
			<Form form={form} initialValues={initialValues}>
				{children}
			</Form>
			<ActionPlanModal isOpen={!!modal} data={modal} handleModalClose={handleCloseModal} />
		</EwaJdsD86Context.Provider>
	);
};

export function useEwaJdsD86Context() {
	const context = useContext(EwaJdsD86Context);
	return context;
}
