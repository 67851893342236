import { useEffect, useState } from 'react';
import { useIsMountedRef } from './useIsMountedRef';
import Api from '@/services/api';

function useOrganization() {

	const initState = { loading: false, data: [], errors: null };

	const isMountedRef = useIsMountedRef();

	const [organizations, setOrganizations] = useState(initState);

	useEffect(() => {
		async function fetchOrganizations() {
			try {
				isMountedRef.current && setOrganizations(current => ({ ...current, loading: !current.loading }));
				const { data } = await Api.get('/organization/user_list');
				isMountedRef.current && setOrganizations(current => ({ ...current, data: data }));
			} catch (error) {
				isMountedRef.current && setOrganizations(current => ({ ...current, errors: error }));
			} finally {
				isMountedRef.current && setOrganizations(current => ({ ...current, loading: !current.loading }));
			}
		}
		fetchOrganizations();
	}, [isMountedRef]);

	return { organizations };
}

export { useOrganization };
