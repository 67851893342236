import Api from '@/services/api';

import { BeraJobSummaryListRequest, DeleteBeraJobSummaryRequest, DownloadFileRequest } from './types/request';
import { BeraJobSummaryDTO, BeraJobSummaryListDTO } from './types/response';
import { BeraReportDTO } from '../../BeraJob/hooks/types/response';

export class BeraService {
	async getBeraJobSummaryList({
		organization_id,
		company_id,
		filter
	}: BeraJobSummaryListRequest): Promise<BeraJobSummaryListDTO> {
		const url = `/bera/job-summary/list?organization_id=${organization_id}&company_id=${company_id}`;
		const { data } = await Api.get<BeraJobSummaryListDTO>(url, {
			params: filter
		});
		return data;
	}

	async deleteBeraJobSummary({
		organization_id,
		company_id,
		id
	}: DeleteBeraJobSummaryRequest): Promise<BeraJobSummaryDTO> {
		const url = `/bera/job-summary/${id}?organization_id=${organization_id}&company_id=${company_id}`;
		const { data } = await Api.delete<BeraJobSummaryDTO>(url);
		return data;
	}

	async deleteBeraReport({ organization_id, company_id, id }: DeleteBeraJobSummaryRequest): Promise<BeraReportDTO> {
		const url = `/bera/report/${id}?organization_id=${organization_id}&company_id=${company_id}`;
		const { data } = await Api.delete<BeraReportDTO>(url);
		return data;
	}

	async downloadFile({ company_id, organization_id, file }: DownloadFileRequest): Promise<string> {
		const url = '/upload/create-url-download';
		const { data } = await Api.post<string>(url, {
			file_id: file.id,
			company_id,
			original_name: file.original_name,
			organization_id
		});
		return data;
	}
}
