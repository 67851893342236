import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form, InputNumber, Row, Space } from 'antd';

import { RequiredLabel } from '../../components/RequiredLabel';
import { CustomFormItem } from '../../components/styles';
import { NamePath } from 'antd/lib/form/interface';
import { FieldTypes } from './types';

const inputLimits: FieldTypes = {
	minutes: {
		min: 0,
		max: 59
	},
	hours: {
		min: 1,
		max: 24
	}
};

interface TextInputProps {
	type: string;
	title: string;
	namePath: NamePath;
	isRequired?: boolean;
}

export const TextInput: React.FC<TextInputProps> = ({ title, namePath, type, isRequired = false }) => {
	return (
		<Col sm={24} md={10} lg={9} xl={7} xxl={6}>
			<Row justify="start" align="middle">
				<Col>
					<CustomFormItem>
						<Space align={'baseline'} size="middle" wrap={true} style={{ height: '38px' }}>
							<Form.Item
								name={namePath}
								rules={[
									{
										required: isRequired,
										message: I18n.get(`Select the ${title}`)
									}
								]}
							>
								<InputNumber
									size="large"
									placeholder="0"
									min={inputLimits[type].min}
									max={inputLimits[type].max}
									style={{ width: '5rem' }}
								/>
							</Form.Item>
							<RequiredLabel title={type} fontSize={'1rem'} />
						</Space>
					</CustomFormItem>
				</Col>
			</Row>
		</Col>
	);
};
