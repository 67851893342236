import React from 'react';

import { useApplicationContext } from '@/context/v1/Application/context';
import { CurrentSeverity } from './CurrentSeverity';
import { useGetSeverities } from '../../hooks';
import { SetSeverity } from './SetSeverity';

interface ManageSeverityProps {
	stepKeyId: string;
}

export const ManageSeverity: React.FC<ManageSeverityProps> = ({ stepKeyId }) => {
	const { organization, company } = useApplicationContext();

	const {
		data: severities,
		isLoading: gettingSeverities,
		isFetching: fetchingSeverities,
		isError: errorGettingSeverities
	} = useGetSeverities({
		step_key_id: stepKeyId,
		company_id: company?.id,
		organization_id: organization?.id
	});

	return (
		<>
			<SetSeverity
				severities={severities}
				gettingSeverities={gettingSeverities}
				fetchingSeverities={fetchingSeverities}
				errorGettingSeverities={errorGettingSeverities}
			/>
			<CurrentSeverity severities={severities} />
		</>
	);
};
