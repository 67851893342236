import React from 'react';
import { Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CustomFormItem } from '../styles';
import { useGetRiskDescriptions } from '@/hooks';
import { RequiredLabel } from '../../../RequiredLabel';
import { useApplicationContext } from '@/context/v1/Application/context';
import { RiskDescriptionSelect } from '@/components/ui/Inputs/RiskDescriptionSelect';

const { useFormInstance, useWatch } = Form;

interface RiskDescriptionsProps {
	formFieldName: string[];
}

export const RiskDescriptions: React.FC<RiskDescriptionsProps> = ({ formFieldName }) => {
	const form = useFormInstance();
	const { organization, company } = useApplicationContext();

	const fieldName = [...formFieldName, 'risk_description_id'];
	const severityFieldName = [...formFieldName, 'severity_id'];
	const riskDamageFieldName = [...formFieldName, 'risk_damage_id'];
	const riskCategoryFieldName = [...formFieldName, 'risk_category_id'];
	const riskCategoryId = useWatch(riskCategoryFieldName, form);
	const riskDescriptionId = useWatch(fieldName, form);

	const {
		data: riskDescriptions,
		isLoading: gettingRiskDescriptions,
		isFetching: fetchingRiskDescriptions
	} = useGetRiskDescriptions({
		organization_id: organization?.id,
		company_id: company?.id,
		risk_category_id: riskCategoryId
	});

	async function handleRiskDescriptionSelect(id: string) {
		form.setFieldValue(fieldName, id);
		form.setFieldValue(riskDamageFieldName, undefined);
		form.setFieldValue(severityFieldName, undefined);
	}

	async function handleClearRiskDescription() {
		form.setFieldValue(fieldName, undefined);
		form.setFieldValue(riskDamageFieldName, undefined);
		form.setFieldValue(severityFieldName, undefined);
	}

	return (
		<Col xs={24} sm={12} lg={24}>
			<CustomFormItem
				name={fieldName}
				label={<RequiredLabel title="Risk description" isRequired={true} />}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: true,
						message: I18n.get('Select the description')
					}
				]}
			>
				<RiskDescriptionSelect
					fieldName={fieldName}
					allowCreation={false}
					riskCategoryId={riskCategoryId}
					loading={fetchingRiskDescriptions}
					riskDescriptions={riskDescriptions}
					riskDescriptionId={riskDescriptionId}
					handleClearRiskDescription={handleClearRiskDescription}
					handleSelectRiskDescription={handleRiskDescriptionSelect}
					disabled={gettingRiskDescriptions || fetchingRiskDescriptions || !riskCategoryId}
				/>
			</CustomFormItem>
		</Col>
	);
};
