import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { SectorSelect } from '@/components/ui/Inputs/SectorSelect';
import { useSectors } from '@/hooks/v1';

const { useFormInstance, useWatch } = Form;

export const Sectors: React.FC = () => {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();

	const searchingOrganizationId = useWatch('searching_organization_id', form);
	const companyId = useWatch(['company_id_filter'], form);
	const sectorId = useWatch(['sector_id'], form);

	const {
		data: sectors,
		isLoading,
		isFetching,
		isError
	} = useSectors({
		organizationId: searchingOrganizationId,
		companyId
	});

	function handleSectorSelect(id: string) {
		setFieldValue(['sector_id'], id);
		setFieldValue(['line_id'], undefined);
		setFieldValue(['workstation_id'], undefined);
	}

	function handleClearSector() {
		setFieldValue(['sector_id'], undefined);
		setFieldValue(['line_id'], undefined);
		setFieldValue(['workstation_id'], undefined);
	}

	return (
		<Form.Item name={['sector_id']} label={I18n.get('Sector')} labelCol={{ span: 24 }}>
			<SectorSelect
				sectors={sectors}
				sectorId={sectorId}
				loading={isFetching}
				allowCreation={false}
				fieldName={['sector_id']}
				handleClearSector={handleClearSector}
				handleSelectSector={handleSectorSelect}
				disabled={isLoading || isFetching || isError || !companyId}
			/>
		</Form.Item>
	);
};
