import { Col, Row } from 'antd';
import React from 'react';
import { CustomButton, CustomTooltip } from '../styles';
import { I18n } from '@aws-amplify/core';
import { LeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

export const BackButton: React.FC = () => {
	const history = useHistory();

	function handleButtonClick() {
		history.push('/custom-reports/sera');
	}

	return (
		<Row justify="center">
			<Col xs={24}>
				<CustomTooltip title={I18n.get('Go back to files selection')}>
					<CustomButton type="link" onClick={handleButtonClick} icon={<LeftOutlined />}>
						{I18n.get('Back')}
					</CustomButton>
				</CustomTooltip>
			</Col>
		</Row>
	);
};
