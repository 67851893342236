import React from 'react';
import { I18n } from '@aws-amplify/core';
import { riskColors, riskLabels } from '../../ConditionCard';
import { CardStyled } from '../../styles';

interface ConditionalCardProps {
	result: number;
}

export function ConditionalCard({ result }: ConditionalCardProps) {
	return (
		<CardStyled $backgroundColor={riskColors[result]} risk={result}>
			{I18n.get(riskLabels[result])}
		</CardStyled>
	);
}
