import styled from 'styled-components';
import { Button, Row, Form } from 'antd';

const { Item } = Form;

type RPNScoreProps = {
	priority: string;
};

export const CustomFormItem = styled(Item)`
	width: 100%;
	margin-bottom: 0.75rem;
	.ant-form-item-label {
		font-weight: 600;
		overflow: visible;
	}
`;

export const Title = styled.span`
	font-weight: 600;
`;

export const CustomRow = styled(Row)`
	margin-left: 1rem;
`;

export const RPNScore = styled.span<RPNScoreProps>`
	width: 5rem;
	height: 2rem;
	display: flex;
	font-weight: 600;
	margin-top: 0.75rem;
	font-size: 0.9rem;
	align-items: center;
	border-radius: 0.15rem;
	justify-content: center;
	background-color: ${({ priority }) => priority};

	@media (min-width: 1600px) {
		font-size: 0.9rem;
	}
`;

export const CustomButton = styled(Button)`
	padding: 0.25rem;
	margin-bottom: 0.5rem;
	width: 6rem;
	height: auto;
	font-weight: 600;
	font-size: 0.8rem;
	white-space: break-spaces;
`;
