import { Col } from 'antd';
import styled from 'styled-components';

export const Topic = styled.span`
	font-size: 0.9rem;
	font-weight: 600;
	margin-right: 0.5rem;

	@media (min-width: 1600px) {
		font-size: 0.9rem;
	}
`;

export const RPNTopic = styled.span`
	font-size: 0.9rem;
	font-weight: 600;
	margin-right: 0.5rem;

	@media (min-width: 1600px) {
		font-size: 0.9rem;
	}
`;

export const CustomCol = styled(Col)`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	@media (min-width: 1248px) {
		overflow: hidden;
		word-break: normal;
		white-space: break-spaces;
	}
`;

export const Value = styled.span`
	font-weight: 400;
	font-size: 0.9rem;

	@media (min-width: 1600px) {
		font-size: 0.9rem;
	}
`;

export const Score = styled.span`
	color: #2f54eb;
	font-size: 0.9rem;
	font-weight: 600;
	border-radius: 0.25rem;
	border: 1px solid #2f54eb;
	background-color: #2f54eb0d;
	padding: 0 0.5rem;

	@media (min-width: 1600px) {
		font-size: 0.9rem;
	}
`;

export const RPNScore = styled.span`
	color: #2f54eb;
	font-size: 0.9rem;
	font-weight: 600;
	border-radius: 0.25rem;
	border: 1px solid #2f54eb;
	background-color: #2f54eb0d;
	padding: 0.5rem 0.7rem;

	@media (min-width: 1600px) {
		font-size: 0.9rem;
	}
`;
