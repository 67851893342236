import moment from 'moment';

const DATE_FORMAT = 'L';

export const selectBodyParts = state => (
	Object
		.keys(state.reports.customized.body_parts)
		.filter(part => state.reports.customized.body_parts[part] === true && part !== 'is_valid')
);

export const fileDurationParse = state => {
	const seconds = state.reports.file?.duration || 0;
	const format = val => `0${Math.floor(val)}`.slice(-2);
	const hours = seconds / 3600;
	const minutes = (seconds % 3600) / 60;
	return [hours, minutes, seconds % 60].map(format).join(':');
}

export const rangeRiskJSON = state => {
	try {
		return JSON.parse(state.reports.customized.informations.range_risk)
	} catch (err) {
		return null;
	}
}

export const collectionDateParse = state => {
	const date = state.reports.customized.informations.collection_date;
	return date ? moment(date).format(DATE_FORMAT) : null;
}

export const sectorJSON = state => {
	try {
		return JSON.parse(state.reports.customized.informations.sector);
	} catch (err) {
		return null;
	}
}

export const fileSizeFormat = state => {
	const size = state.reports.file?.size || 0;
	if (size < 1024) return size + ' B';
	let i = Math.floor(Math.log(size) / Math.log(1024));
	let num = (size / Math.pow(1024, i));
	let round = Math.round(num);
	num = round < 10 ? num.toFixed(2) : round < 100 ? num.toFixed(1) : round;
	return `${num} ${'KMGTPEZY'[i - 1]}B`;
}

export const angleTimeDataParse = state => {
	let object = state.reports.data ? {} : null;
	const data = state.reports.data || {};
	Object
		.keys(data)
		.map(item => object[item] = data[item][0].angles);
	return object;
}
