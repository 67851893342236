import { useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import Api from '@/services/api';
import type { BaseContext, CustomReportStepKeysDefaultRisk } from '@/types';

export type GetCustomReportStepKeyDefaultRiskDTO = BaseContext & {
	custom_report_step_key_id: string;
};

const getCustomReportStepKeysDefaultRisks = async (params: GetCustomReportStepKeyDefaultRiskDTO) => {
	const url = '/custom-report/step-key/risks/default';
	let { data } = await Api.get<CustomReportStepKeysDefaultRisk>(url, {
		params
	});
	return data;
};

export const useGetCustomReportStepKeysDefaultRisks = ({
	company_id,
	organization_id,
	custom_report_step_key_id
}: GetCustomReportStepKeyDefaultRiskDTO) => {
	const parameters = { organization_id, company_id, custom_report_step_key_id };
	const report = useQuery<CustomReportStepKeysDefaultRisk, AxiosError<Response>>(
		['jds_d86_report', parameters],
		() => getCustomReportStepKeysDefaultRisks(parameters),
		{
			enabled: !!organization_id && !!company_id && !!custom_report_step_key_id,
			retry: 0
		}
	);

	return {
		...report,
		data: report.data ?? ({} as CustomReportStepKeysDefaultRisk)
	};
};
