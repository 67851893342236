import React, { useState } from 'react';
import Table, { ColumnsType } from 'antd/lib/table';
import { Col, Row } from 'antd';

import { useApplicationContext } from '@/context/v1/Application/context';
import { SeraReport, SeraReportFile } from '../hooks/types/response';
import { CustomTable, CustomDownloadButton } from './styles';
import { useDownloadFile } from '../hooks/hooks';

interface SubTableProps {
	data: SeraReport[];
}

export const SubTable: React.FC<SubTableProps> = ({ data }) => {
	const [clickedFileId, setClickedFileId] = useState('');

	const { company, organization } = useApplicationContext();
	const { mutateAsync: downloadFile, isLoading: downloadingFile } = useDownloadFile();

	async function handleDownloadFile(file: SeraReportFile) {
		setClickedFileId(file.id);
		await downloadFile({
			company_id: company?.id,
			organization_id: organization?.id,
			file
		});
	}

	function renderFileList(file: SeraReportFile[]) {
		return file.map((file) => {
			return (
				<Col key={file.id} xs={24}>
					<CustomDownloadButton
						type="link"
						loading={file.id === clickedFileId && downloadingFile}
						onClick={() => handleDownloadFile(file)}
					>
						{file.original_name}
					</CustomDownloadButton>
				</Col>
			);
		});
	}

	const columns: ColumnsType<SeraReport> = [
		{
			title: '',
			dataIndex: '',
			key: '',
			align: 'center'
		},
		{
			title: '',
			dataIndex: '',
			key: '',
			align: 'center'
		},
		{
			title: '',
			dataIndex: '',
			key: '',
			align: 'center'
		},
		{
			title: '',
			dataIndex: 'file',
			key: 'file',
			ellipsis: true,
			align: 'center',
			render: (file: SeraReportFile[]) => renderFileList(file),
			responsive: ['lg']
		},
		{
			title: '',
			dataIndex: '',
			key: '',
			align: 'center'
		},
		{
			title: '',
			dataIndex: '',
			key: '',
			align: 'center'
		},
		{
			title: '',
			dataIndex: '',
			key: '',
			align: 'center'
		}
	];

	return (
		<Row justify="center">
			<CustomTable span={24}>
				<Table
					style={{ cursor: 'auto' }}
					showHeader={false}
					pagination={false}
					rowKey="id"
					dataSource={data}
					columns={columns}
				/>
			</CustomTable>
		</Row>
	);
};
