import React, { useEffect, useState } from 'react';
import { Col, Row, Skeleton } from 'antd';

import { CustomReportStepKeyDTO } from '@/components/ui/Inputs/types/response/customReport';
import { FileForCustomReport } from '@/components/ui/Inputs/types/response/file';

import { useBeraReportStepsContext } from '../../context';
import { EmptyStepKey } from './EmptyStepKey';
import { StepKey } from './StepKey';

export type AnyStepKeyChecked = {
	[key: string]: boolean;
};

interface BeraStepKeyProps {
	stepId: string;
	stepDescription: string;
	file: FileForCustomReport;
	stepKeys: CustomReportStepKeyDTO[];
	onCheckAnyCollapse(checked: boolean): Promise<void>;
}

export const BeraStepKey: React.FC<BeraStepKeyProps> = ({
	file,
	stepId,
	stepKeys,
	stepDescription,
	onCheckAnyCollapse
}) => {
	const { isLoading } = useBeraReportStepsContext();

	const [emptyChecked, setEmptyChecked] = useState(false);
	const [anyStepKeyChecked, setAnyStepKeyChecked] = useState(false);
	const [stepKeysChecked, setStepKeysChecked] = useState<AnyStepKeyChecked[]>([]);

	function handleEmptyCheck(value: boolean) {
		setEmptyChecked(value);
	}

	function handleAnyStepKeyCheck(stepKeyId: string, value: boolean) {
		setStepKeysChecked((prev) => ({
			...prev,
			[stepKeyId]: value
		}));
	}

	useEffect(() => {
		for (const stepKey in stepKeysChecked) {
			const checked = stepKeysChecked[stepKey];
			if (checked) {
				setAnyStepKeyChecked(true);
				return;
			}
			setAnyStepKeyChecked(false);
		}
	}, [stepKeysChecked]);

	useEffect(() => {
		const anyCollapseChecked = emptyChecked || anyStepKeyChecked;
		onCheckAnyCollapse(anyCollapseChecked);
	}, [emptyChecked, anyStepKeyChecked]);

	return (
		<Row>
			{isLoading ? (
				<Col span={20}>
					<Skeleton active />
				</Col>
			) : (
				<EmptyStepKey
					key={stepId}
					file={file}
					stepId={stepId}
					stepDescription={stepDescription}
					anyStepKeyChecked={anyStepKeyChecked}
					onEmptyCheck={handleEmptyCheck}
				/>
			)}
			{stepKeys?.map((stepKey) => {
				return isLoading ? (
					<Col span={20}>
						<Skeleton active />
					</Col>
				) : (
					<StepKey
						key={stepKey.id}
						file={file}
						stepKey={stepKey}
						stepId={stepId}
						emptyChecked={emptyChecked}
						integratedTools={stepKey.ergonomic_tool}
						onAnyStepKeyCheck={handleAnyStepKeyCheck}
					/>
				);
			})}
		</Row>
	);
};
