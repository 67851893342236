import React from 'react';
import { Col, Button } from 'antd';
import styled from 'styled-components';
import { I18n } from '@aws-amplify/core';
import { FileAddOutlined } from '@ant-design/icons';
import { usePlan } from '@/hooks/usePlan';

export const Title = styled.div`
	display: inline-block;
	margin-right: 5px;
`;

export const Header = ({ onCreate }) => {
	const { data } = usePlan();
	return (
		<Col sm={24} style={{ paddingBottom: '15px' }}>
			<Title>
				<h2>{I18n.get('Manage companies')}</h2>
			</Title>
			<Button
				size="large"
				type="primary"
				disabled={data?.specialPlan}
				onClick={onCreate}
				icon={<FileAddOutlined />}
				style={{ display: 'inline', float: 'right' }}
			>
				{I18n.get('Create')}
			</Button>
		</Col>
	);
};
