import styled from 'styled-components';
import { Col } from 'antd';

export const CustomColumn = styled(Col)`
	background-color: #f8f8f8;
	padding: 2rem;
	border-radius: 1rem;
	width: 500px;
	@media (min-width: 992px) {
		width: 600px;
	}
	@media (min-width: 1440px) {
		width: 1000px;
	}
`;
