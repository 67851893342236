import React, { useCallback, useLayoutEffect, useEffect } from 'react';
import { I18n } from '@aws-amplify/core';
import { Layout, notification } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { Logo } from './Logo';
import { Sider } from './Sider';
import { Header } from './Header';
import { Content } from './Content';
import { MenuOptions } from './Menu';

import Api from '@/services/api';
import { setPlanDetails } from '@/redux/plan/actions';

interface ContainerProps {
	children: () => JSX.Element;
}

export const Container: React.FC<ContainerProps> = ({ children }) => {
	const dispatch = useDispatch();
	const isExpired = useSelector((state: any) => state.plan.expired);
	const isCanceled = useSelector((state: any) => state.plan.canceled);

	const getPlan = useCallback(() => {
		Api.get('/plan/customer/details')
			.then((res) => {
				dispatch(setPlanDetails(res.data));
			})
			.catch((err) => {
				console.log(err, 'data');
			});
	}, [dispatch]);

	const alertAboutPlan = useCallback(() => {
		if (isCanceled) {
			const message = I18n.get('Your plan has been canceled');
			const description = I18n.get('Upgrade your plan and continue using one platform');
			notification.error({ message, description });
		}

		if (isExpired) {
			const message = I18n.get('Your plan has expired');
			const description = I18n.get('Renew it to continue using');
			notification.warning({ message, description });
		}
	}, [isExpired, isCanceled]);

	useLayoutEffect(() => {
		getPlan();
	}, [getPlan]);

	useEffect(() => {
		alertAboutPlan();
	}, [alertAboutPlan]);

	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Sider>
				<Logo />
				<MenuOptions />
			</Sider>
			<Layout>
				<Header />
				<Content>{children}</Content>
			</Layout>
		</Layout>
	);
};
