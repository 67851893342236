import React, { useState } from 'react';
import Can from '@/components/Can';
import { I18n } from '@aws-amplify/core';
import { Button, Col, Dropdown, Image, Modal, Space } from 'antd';
import { DownOutlined, LineChartOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { CustomButton } from './styles';
import { FileDTO } from '@/components/ui/Inputs';
import { OptionDropdown } from './OptionDropdown';
import { useDeleteFile, useDownloadFile } from './hooks';
import { ErgonomicToolsModal } from './ErgonomicToolsModal';
import { useApplicationContext } from '@/context/v1/Application/context';

const DOWNLOAD_ICON = 'https://kinebot-statics.s3.amazonaws.com/download-video-black.svg';

enum Status {
	IN_QUEUE = 'IN_QUEUE',
	PROCESSED = 'PROCESSED',
	NOT_PROCESSED = 'NOT_PROCESSED',
	EXTRACTED_DATA = 'EXTRACTED_DATA',
	CORRUPTED_FILE = 'CORRUPTED_FILE'
}

interface ActionsProps {
	file: FileDTO;
}

export const Actions: React.FC<ActionsProps> = ({ file }) => {
	const [isErgonomicToolsModalVisible, setIsErgonomicToolsModalVisible] = useState(false);

	const { organization, company } = useApplicationContext();
	const { mutateAsync: deleteFile, isLoading: deletingFile } = useDeleteFile();
	const { mutateAsync: downloadFile, isLoading: downloadingFile } = useDownloadFile();

	function handleReportsClick(): void {
		setIsErgonomicToolsModalVisible(true);
	}

	function handleCloseModalClick(visible = false): void {
		setIsErgonomicToolsModalVisible(visible);
	}

	async function handleDownloadFile() {
		await downloadFile({
			company_id: company?.id,
			organization_id: organization?.id,
			file
		});
	}

	function handleDeleteFile(): void {
		Modal.confirm({
			okType: 'danger',
			onOk: () =>
				deleteFile({
					company_id: company?.id,
					organization_id: organization?.id,
					file_id: file.id
				}),
			okText: I18n.get('Confirm'),
			cancelText: I18n.get('Cancel'),
			icon: <ExclamationCircleOutlined />,
			title: I18n.get('Heads up! Want to delete this file?')
		});
	}

	const processedFile = file.status === Status.PROCESSED;
	const extractedData = file.status === Status.EXTRACTED_DATA;
	const dataAvailable = processedFile || extractedData;

	const permissionRole = Can('delete', 'upload');

	const downloadVideoIcon = (
		<Col span={1} style={{ width: '30px' }}>
			<Image src={DOWNLOAD_ICON} preview={false} alt={I18n.get('Download video')} />
		</Col>
	);

	const items = [
		{
			key: 1,
			disabled: !dataAvailable,
			icon: <LineChartOutlined />,
			label: (
				<OptionDropdown
					loading={downloadingFile || deletingFile}
					disabled={!dataAvailable}
					onClick={handleReportsClick}
				>
					Reports
				</OptionDropdown>
			)
		},
		{
			key: 2,
			disabled: !processedFile,
			icon: downloadVideoIcon,
			label: (
				<OptionDropdown
					loading={downloadingFile || deletingFile}
					disabled={!processedFile}
					onClick={handleDownloadFile}
				>
					Download
				</OptionDropdown>
			)
		},
		{
			key: 3,
			danger: true,
			disabled: !permissionRole,
			icon: <DeleteOutlined />,
			label: (
				<CustomButton>
					<OptionDropdown
						loading={downloadingFile || deletingFile}
						disabled={!permissionRole}
						onClick={handleDeleteFile}
					>
						Delete
					</OptionDropdown>
				</CustomButton>
			)
		}
	];

	return (
		<>
			<ErgonomicToolsModal file={file} onClose={handleCloseModalClick} visible={isErgonomicToolsModalVisible} />
			<Dropdown menu={{ items }} placement="bottom" trigger={['click']}>
				<Button type="link">
					<Space>
						{I18n.get('Action')}
						<DownOutlined />
					</Space>
				</Button>
			</Dropdown>
		</>
	);
};
