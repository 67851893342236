import Api from '@/services/api';

import { CustomReportDTO, FileDTO } from '@/components/ui/Inputs';
import { CustomReportsFilesPaginated } from './types/response';
import { CustomReportsRequest } from '@/components/ui/Inputs/types/request/customReport';
import { DownloadFileRequest, DeleteFileRequest, GetFilesRequest } from './types/request';

export class CustomReportsListService {
	async fetchFiles(params: GetFilesRequest): Promise<CustomReportsFilesPaginated> {
		const url = `/file/custom-reports`;
		const { data } = await Api.get<CustomReportsFilesPaginated>(url, {
			params: {
				...params,
				...params.filter
			}
		});
		return data;
	}

	async fetchCustomReports({ organization_id, company_id }: CustomReportsRequest): Promise<CustomReportDTO[]> {
		const url = `/custom-report/list?organization_id=${organization_id}&company_id=${company_id}`;
		const { data } = await Api.get<CustomReportDTO[]>(url);
		return data;
	}

	async deleteFile({ company_id, organization_id, file_id }: DeleteFileRequest): Promise<FileDTO> {
		const url = `/file/${file_id}`;
		const { data } = await Api.delete<FileDTO>(url, {
			params: {
				company_id,
				organization_id,
				file_id: file_id
			}
		});
		return data;
	}

	async downloadFile({ company_id, organization_id, file }: DownloadFileRequest): Promise<string> {
		const url = '/upload/create-url-download';
		const { data } = await Api.post<string>(url, {
			company_id,
			organization_id,
			file_id: file.id,
			original_name: file.original_name
		});
		return data;
	}
}

export const customReportsListService = new CustomReportsListService();
