import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Skeleton, notification } from 'antd';
import { useParams } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import { useApplicationContext } from '@/context/v1/Application/context';

import {
	useBeraFiles,
	useBeraJobSummary,
	useCreateBeraJobSummary,
	useCustomReport,
	useUpdateBeraJobSummary
} from './hooks/hooks';
import { CreateBeraJobSummaryRequest, UpdateBeraJobSummaryRequest } from './hooks/types/request';

import { BeraJobStepsRouteParams, useBeraJobStepsContext } from './context';
import { BeraReportStepsProvider } from './BeraFormSteps/context';
import { GeneralInformationsForm, HeaderRow } from './styles';
import { TaskTimeFormat } from './hooks/types/response';
import { FilesSelection } from './FilesSelection';
import { BeraFormSteps } from './BeraFormSteps';
import { Informations } from './Informations';
import { BackButton } from './BackButton';
import { Header } from './Header';
import { Footer } from './Footer';

const { useForm } = Form;

const REPORT_NAME = 'bera';
const REPORT_TYPE = 'BERA';

type CreateOrUpdate = {
	cycle_id: string;
	report_name: string;
	company_id: string;
};

export type StepsInitialValues = {
	[key: string]: {
		file_id: string;
		task_id: string;
		task_time: number;
		task_time_format: TaskTimeFormat;
		has_known_injury: boolean;
		collection_date: moment.Moment;
	};
};

export const BeraJobSteps: React.FC = () => {
	const [form] = useForm();
	const { organization, company } = useApplicationContext();
	const { bera_job_summary_id } = useParams<BeraJobStepsRouteParams>();
	const { selectedFiles, selectedFilesId, showBeraJobResult, beraJobSummaryId, page, limit, fileName } =
		useBeraJobStepsContext();
	const {
		filterSelectedFiles,
		handleBeraFilesChange,
		handleSelectedFilesChange,
		handleBeraJobSummaryChange,
		handleBeraJobSummaryIdChange,
		handleShowBeraJobResultChange,
		handleBeraCommonInformationsChange
	} = useBeraJobStepsContext();

	const [step, setStep] = useState(0);
	const [valuesChanged, setValuesChanged] = useState(false);

	const companyId = form.getFieldValue('company_id');

	const { data: customReport, isError: errorGettingCustomReport } = useCustomReport({
		organization_id: organization?.id,
		company_id: company?.id,
		reportName: REPORT_NAME
	});
	const {
		data: beraFiles,
		isLoading: gettingBeraFiles,
		isFetching: fetchingBeraFiles,
		isError: errorGettingBerafiles
	} = useBeraFiles({
		company_id: companyId,
		bera_job_summary_id: bera_job_summary_id ?? beraJobSummaryId,
		offset: page === 0 ? page : page - 1,
		custom_report_id: customReport?.id,
		organization_id: organization?.id,
		report_type: REPORT_TYPE,
		fileName,
		limit
	});
	const {
		data: jobSummary,
		isLoading,
		isError
	} = useBeraJobSummary({
		organization_id: organization?.id,
		company_id: company?.id,
		bera_job_summary_id: bera_job_summary_id ?? beraJobSummaryId
	});
	const { mutateAsync: createJobSummary, isLoading: creatingJobSummary } = useCreateBeraJobSummary();
	const { mutateAsync: updateJobSummary, isLoading: updatingJobSummary } = useUpdateBeraJobSummary();

	useEffect(() => {
		const mappedFilesIds = jobSummary?.bera_report?.map((report) => report.file_id);
		const currentFilesIds = selectedFilesId?.length > 0 ? selectedFilesId : mappedFilesIds;
		if (currentFilesIds?.length > 0 && selectedFiles.length === 0) {
			const files = filterSelectedFiles(currentFilesIds, beraFiles);
			form.setFieldValue('files_list', currentFilesIds);
			handleSelectedFilesChange(files);
		}

		if (jobSummary?.show_bera_result && step === 0) {
			handleShowBeraJobResultChange(true);
			setStep(2);
		}
	}, [jobSummary, beraFiles]);

	useEffect(() => {
		handleBeraFilesChange(beraFiles);
		handleBeraJobSummaryChange(jobSummary);
		if (step === 1) {
			const fileIds = selectedFiles?.map((file) => file.id);
			form.setFieldValue('files_list', fileIds);
		}
	}, [step, beraFiles]);

	const generalInformationsInitialValues = {
		organization_id: organization?.name,
		company_id: company?.id,
		report_name: jobSummary?.report_name,
		cycle_id: jobSummary?.cycle_id,
		sector_id: jobSummary?.sector_id,
		workstation_id: jobSummary?.workstation_id,
		line_id: jobSummary?.line_id,
		evaluator_id: jobSummary?.bera_report ? jobSummary?.bera_report[0]?.evaluator_id : undefined,
		operator_evaluated: jobSummary?.bera_report ? jobSummary?.bera_report[0]?.operator_evaluated : undefined,
		work_center: jobSummary?.bera_report ? jobSummary?.bera_report[0]?.work_center : undefined
	};

	function handleValueChange(changed: boolean): void {
		setValuesChanged(changed);
	}

	function handlePreviousStep(): void {
		setStep(step - 1);
	}

	async function handleNextStep() {
		try {
			if (jobSummary.show_bera_result && step === mainSteps.length - 1) {
				setStep(step + 1);
				return;
			}
			const values = await form.validateFields();
			if (step === mainSteps.length - 1) {
				const values = await form.getFieldValue([]);
				if (values) {
					handleBeraCommonInformationsChange(values);
					setStep(step + 1);
				}
				return;
			}
			if (step === 0) {
				const { cycle_id, report_name, company_id } = values;
				return createOrUpdateBeraJob({ cycle_id, report_name, company_id });
			}
			setStep(step + 1);
		} catch (error: any) {
			const errorName = error.errorFields[0].name;
			form.scrollToField(errorName, { behavior: 'smooth', block: 'center', inline: 'center' });
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get("Some required steps wasn't filled"),
				duration: 5
			});
		}
	}

	async function createOrUpdateBeraJob(values: CreateOrUpdate) {
		if (!valuesChanged) {
			setStep(step + 1);
			return;
		}
		if (!beraJobSummaryId) {
			return await createBeraJobSummary(values);
		}
		return await updateBeraJobSummary(values);
	}

	async function createBeraJobSummary(values: CreateOrUpdate): Promise<void> {
		const body: CreateBeraJobSummaryRequest = {
			...values,
			organization_id: organization.id
		};
		const createdJobSummary = await createJobSummary(body);
		if (createdJobSummary) {
			handleBeraJobSummaryIdChange(createdJobSummary.id);
			setStep(step + 1);
			updateURI(createdJobSummary.id);
		}
	}

	async function updateBeraJobSummary(values: CreateOrUpdate): Promise<void> {
		const body: UpdateBeraJobSummaryRequest = {
			...values,
			id: beraJobSummaryId,
			organization_id: organization.id
		};
		const updatedJobSummary = await updateJobSummary(body);
		if (updatedJobSummary) {
			handleBeraJobSummaryIdChange(updatedJobSummary.id);
			setStep(step + 1);
			updateURI(updatedJobSummary.id);
		}
	}

	function updateURI(id: string) {
		if (bera_job_summary_id) {
			return;
		}
		const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + `/${id}`;
		window.history.pushState({ path: newurl }, '', newurl);
	}

	function showBeraReportStep(): JSX.Element {
		const loadingFiles = fetchingBeraFiles && jobSummary.show_bera_result;
		if (isLoading || loadingFiles || creatingJobSummary || updatingJobSummary) {
			return (
				<Col span={16}>
					<Skeleton active />
				</Col>
			);
		}

		if (step === 2) {
			return (
				<Col xs={24} style={{ maxWidth: '1240px' }}>
					{step === mainSteps.length && !showBeraJobResult && (
						<BackButton onPreviousClick={handlePreviousStep} />
					)}
					{
						<BeraReportStepsProvider>
							<BeraFormSteps />
						</BeraReportStepsProvider>
					}
				</Col>
			);
		}

		return (
			<Col xs={24} md={20} xl={16} xxl={12}>
				<GeneralInformationsForm
					autoComplete="off"
					name="general_informations"
					form={form}
					initialValues={generalInformationsInitialValues}
				>
					{mainSteps[step].component}
				</GeneralInformationsForm>
			</Col>
		);
	}

	function isNextButtonDisabled(): boolean {
		return step === 1 && selectedFiles.length === 0 && selectedFilesId.length === 0;
	}

	const mainSteps = [
		{
			component: <Informations onValueChange={handleValueChange} />
		},
		{
			component: <FilesSelection isLoading={gettingBeraFiles || fetchingBeraFiles} />
		}
	];

	if (isError || errorGettingCustomReport || errorGettingBerafiles) {
		return (
			<Row justify="center">
				<Col span={24}>
					<span>{I18n.get('Oops... Something went wrong!')}</span>
				</Col>
			</Row>
		);
	}

	return (
		<Col xs={24}>
			<HeaderRow justify="center">
				<Header />
			</HeaderRow>
			<Row justify="center">{showBeraReportStep()}</Row>
			<Row justify="center" style={{ minWidth: '190px' }}>
				<Footer
					currentStep={step}
					loading={isLoading}
					onNextClick={handleNextStep}
					totalSteps={mainSteps.length}
					disabled={isNextButtonDisabled()}
					onPreviousClick={handlePreviousStep}
				/>
			</Row>
		</Col>
	);
};
