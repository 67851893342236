import React, { useEffect, useState } from 'react';
import { Col, Form, InputNumber, Row, Select } from 'antd';
import { CheckboxOptionType } from 'antd/es/checkbox';
import { I18n } from '@aws-amplify/core';
import { v4 as uuidv4 } from 'uuid';

import type { GetAdditionalToolScore, GetAdditionalItemOptionsResponse, AdditionalItemsResults } from '@/hooks';
import { useCalculateAdditionalToolScore, useGetAdditionalItemsOptions } from '@/hooks';
import { useApplicationContext } from '@/context/v1/Application/context';
import { getDecimalSeparator } from '@/utils/getDecimalSeparator';
import { useStepKeysContext } from '../DefaultSelects/context';
import { fieldsUnitTexts } from './utils/FieldsUnitTexts';
import { AditionalItemProps, Options } from './types';
import { ConditionCard } from '../../ConditionCard';
import { HandAndArmClearanceActions } from './enum';
import { Text } from '@/components/Typography';
import { AdditionalItemTypes } from '@/types';
import { CustomRadioGroup } from '../../styles';

const { useWatch, useFormInstance } = Form;
const { formatLabelWithUnit, formatPlaceholderWithUnit } = fieldsUnitTexts;

export const SelectRadioAndInputs: React.FC<AditionalItemProps> = ({ additionalItem }) => {
	const { id, title_1, title_2, title_3, title_4, title_5, title_6, unit_1, unit_2, unit_3, unit_4, unit_5, unit_6 } =
		additionalItem;

	const form = useFormInstance();
	const { formFieldName, stepKey } = useStepKeysContext();
	const { organization, company } = useApplicationContext();

	const [timer, setTimer] = useState<NodeJS.Timeout>();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [valueOne, setValueOne] = useState<number | undefined>(undefined);
	const [valueTwo, setValueTwo] = useState<number | undefined>(undefined);
	const [valueFour, setValueFour] = useState<number | undefined>(undefined);
	const [valueThree, setValueThree] = useState<number | undefined>(undefined);
	const [radioValue, setRadioValue] = useState<string | undefined>(undefined);
	const [selectValue, setSelectValue] = useState<string | undefined>(undefined);

	const formValueOne = useWatch([...formFieldName, 'additional_items', id, 'value_1']);
	const formValueTwo = useWatch([...formFieldName, 'additional_items', id, 'value_2']);
	const formValueThree = useWatch([...formFieldName, 'additional_items', id, 'value_3']);
	const formValueFour = useWatch([...formFieldName, 'additional_items', id, 'value_4']);
	const selectFormValue = useWatch([...formFieldName, 'additional_items', id, 'additional_item_option_id_1']);
	const radioFormValue = useWatch([...formFieldName, 'additional_items', id, 'additional_item_option_id_2']);

	const isDefaultFieldsFilled =
		!!formValueOne && !!formValueTwo && !!formValueThree && !!selectFormValue && !!radioFormValue;

	const payload: GetAdditionalToolScore = {
		value_1: valueOne,
		value_2: valueTwo,
		value_3: valueThree,
		value_4: valueFour,
		company_id: company?.id,
		organization_id: organization?.id,
		custom_report_step_key_id: stepKey.id,
		additional_item_option_id_1: selectValue,
		additional_item_option_id_2: radioValue
	};

	const { data: additionalToolScore, isFetching: isFetchingAdditionalToolScore } =
		useCalculateAdditionalToolScore(payload);
	const { data: options, isLoading: isOptionsLoading } = useGetAdditionalItemsOptions({
		company_id: company?.id,
		organization_id: organization?.id,
		custom_report_step_key_id: stepKey.id
	});

	useEffect(() => {
		if (areAllFieldsFilled()) {
			setIsLoading(true);
			handleDebounce();
		}
	}, [formValueOne, formValueTwo, formValueThree, formValueFour, selectFormValue, radioFormValue]);

	useEffect(() => {
		const result: AdditionalItemsResults[] = [
			{
				id: uuidv4(),
				value_1: valueOne,
				value_2: valueTwo,
				value_3: valueThree,
				value_4: valueFour,
				additional_item_option_id_2: radioValue,
				additional_item_option_id_1: selectValue,
				result: additionalToolScore?.result ?? 0
			}
		];

		form.setFieldValue([...formFieldName, 'additional_items', id, 'results'], result);
	}, [additionalToolScore]);

	const radioOptions = options.filter((option) => option.type === AdditionalItemTypes.RADIO);
	const selectOptions = options.filter((option) => option.type === AdditionalItemTypes.SELECT);

	const selectFieldName = options.find(({ id }) => id === selectFormValue)?.name;

	const selectLabel = formatLabelWithUnit(title_1, unit_1);
	const radioLabel = formatLabelWithUnit(title_2, unit_2);
	const firstInputPlaceholder = formatPlaceholderWithUnit('0,0', unit_3);
	const secondInputPlaceholder = formatPlaceholderWithUnit('0,0', unit_4);
	const thirdInputPlaceholder = formatPlaceholderWithUnit('0,0', unit_5);
	const fourthInputPlaceholder = formatPlaceholderWithUnit('0,0', unit_6);

	function filterOption(input: string, option?: Options): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function handleDebounce(): void {
		clearTimeout(timer);

		const debounce = setTimeout(() => {
			setValueOne(formValueOne);
			setValueTwo(formValueTwo);
			setValueThree(formValueThree);
			setSelectValue(selectFormValue);
			setRadioValue(radioFormValue);

			if (hasExtraFormField()) {
				setValueFour(formValueFour);
			}

			setIsLoading(false);
		}, 1200);

		setTimer(debounce);
	}

	function areAllFieldsFilled(): boolean {
		if (hasExtraFormField()) {
			return isDefaultFieldsFilled && !!formValueFour;
		}

		return isDefaultFieldsFilled;
	}

	function handleOptions(data: GetAdditionalItemOptionsResponse[]): CheckboxOptionType[] {
		return data.map(({ description, id }) => ({
			value: id,
			label: I18n.get(`${description}`)
		}));
	}

	function handleClearSelect(): void {
		form.resetFields([...formFieldName, 'additional_items', id]);
	}

	function handleChangeSelect() {
		form.setFieldValue([...formFieldName, 'additional_items', id, 'value_4'], undefined);
	}

	function hasExtraFormField(): boolean {
		const isTwoFingersSelected = selectFieldName === HandAndArmClearanceActions.TWO_FINGERS;
		const isHandPlusXSelected = selectFieldName === HandAndArmClearanceActions.HAND_PLUS_X_MM;

		return isTwoFingersSelected || isHandPlusXSelected;
	}

	return (
		<Row>
			<Col span={24}>
				<Row gutter={[0, 15]}>
					<Col span={18}>
						<Text strong>{selectLabel}</Text>
					</Col>
					<Col span={18}>
						<Form.Item name={[...formFieldName, 'additional_items', id, 'additional_item_option_id_1']}>
							<Select
								showSearch
								allowClear
								loading={isOptionsLoading}
								filterOption={filterOption}
								disabled={isOptionsLoading}
								onClear={handleClearSelect}
								onChange={handleChangeSelect}
								placeholder={I18n.get(title_1)}
								options={selectOptions?.map(({ id, description }) => ({
									label: `${I18n.get(description)}`,
									value: id
								})).sort((a, b) => a.label.localeCompare(b.label))}
							/>
						</Form.Item>
					</Col>
				</Row>
				{hasExtraFormField() && (
					<Row gutter={[0, 15]}>
						<Col span={24}>
							<Text strong>{title_6}</Text>
						</Col>
						<Col span={6}>
							<Form.Item name={[...formFieldName, 'additional_items', id, 'value_4']}>
								<InputNumber
									min={0}
									style={{ width: '100%' }}
									placeholder={fourthInputPlaceholder}
									decimalSeparator={getDecimalSeparator()}
								/>
							</Form.Item>
						</Col>
					</Row>
				)}
				<Row gutter={[0, 15]}>
					<Col span={18}>
						<Text strong>{radioLabel}</Text>
					</Col>
					<Col span={18}>
						<Form.Item name={[...formFieldName, 'additional_items', id, 'additional_item_option_id_2']}>
							<CustomRadioGroup options={handleOptions(radioOptions)} />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[0, 10]}>
					<Col span={18}>
						<Row justify="space-between" gutter={[10, 15]}>
							<Col xs={24} xxl={8}>
								<Row gutter={[0, 10]}>
									<Col span={24}>
										<Text strong>{title_3}</Text>
									</Col>
									<Col span={24}>
										<Form.Item
											style={{ margin: 0 }}
											name={[...formFieldName, 'additional_items', id, 'value_1']}
										>
											<InputNumber
												min={0}
												style={{ width: '100%' }}
												placeholder={firstInputPlaceholder}
												decimalSeparator={getDecimalSeparator()}
											/>
										</Form.Item>
									</Col>
								</Row>
							</Col>
							<Col xs={24} xxl={8}>
								<Row gutter={[0, 10]}>
									<Col span={24}>
										<Text strong>{title_4}</Text>
									</Col>
									<Col span={24}>
										<Form.Item
											style={{ margin: 0 }}
											name={[...formFieldName, 'additional_items', id, 'value_2']}
										>
											<InputNumber
												min={0}
												style={{ width: '100%' }}
												placeholder={secondInputPlaceholder}
												decimalSeparator={getDecimalSeparator()}
											/>
										</Form.Item>
									</Col>
								</Row>
							</Col>
							<Col xs={24} xxl={8}>
								<Row gutter={[0, 10]}>
									<Col span={24}>
										<Text strong>{title_5}</Text>
									</Col>
									<Col span={24}>
										<Form.Item
											style={{ margin: 0 }}
											name={[...formFieldName, 'additional_items', id, 'value_3']}
										>
											<InputNumber
												min={0}
												style={{ width: '100%' }}
												placeholder={thirdInputPlaceholder}
												decimalSeparator={getDecimalSeparator()}
											/>
										</Form.Item>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
					<Col span={24}>
						<ConditionCard
							isLoading={isLoading || isFetchingAdditionalToolScore}
							result={areAllFieldsFilled() ? additionalToolScore.result : 0}
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
