import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, DatePicker, Form } from 'antd';

export const CreatedAt = ({ fieldName }: { fieldName: string }) => {
	return (
		<Col xs={24}>
			<Form.Item
				name={[fieldName, 'created_at']}
				label={I18n.get('Period')} labelCol={{ span: 24 }}>
				<DatePicker.RangePicker
					style={{ width: '100%' }}
					format="DD-MM-YYYY"
					disabledDate={(current) => current && current.valueOf() >= Date.now()}
				/>
			</Form.Item>
		</Col>
	);
};
