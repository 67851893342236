import React from 'react'
import { riskColors, riskLevels } from '@/utils/riskRange';
import { Text } from '@/components/Typography';
import { RiskCard, RiskCardBody, RiskCardHeader } from './styles';

type Props = {
	result: number;
	score: number;
}

const RiskResult = ({ result, score }: Props) => {
	return (
		<RiskCard>
			<RiskCardHeader result={result}>
				<Text>
					{riskLevels[result] || 'Not Assigned'}
				</Text>
			</RiskCardHeader>
			<RiskCardBody>
				<Text>
					RPN: {score}
				</Text>
			</RiskCardBody>
		</RiskCard>
	)
}

export default RiskResult
