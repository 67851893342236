import React from 'react';
import moment from 'moment';
import { Row, Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import { ModalBorder } from '@/components/ui';

import { Title } from '@/components/Typography';
import { FilterDTO } from '@/hooks/useGetFiles';
import { CreatedAt } from '../../../DateRangePicker';
import { useSelectFilesContext } from '../../../../context';
import { useJdsD92Context } from '@/components/views/JdsD92/context';
import { Companies, Organizations, Sectors, Lines, Workstations } from '../../../../../Informations/Inputs';
import * as S from '../../styles';

const { useFormInstance } = Form;

interface FilterModalProps {
	openModal: boolean;
	onOpenModal: () => void;
}

export function FilterModal({ openModal, onOpenModal }: FilterModalProps) {
	const { jdsD92 } = useJdsD92Context();
	const { getFieldsValue, resetFields } = useFormInstance();
	const { handleFilter, handlePage, handlingSelectRowKeys } = useSelectFilesContext();

	function handleOnSubmit(): void {
		const values: { filter: FilterDTO } = getFieldsValue(true);
		const { original_name, created_at, ...rest } = values.filter || {};

		if (created_at?.length === 2) {
			rest.created_at_start = moment(created_at[0]).startOf('day').format();
			rest.created_at_end = moment(created_at[1]).endOf('day').format();
		}

		handleFilter(rest);

		if (!jdsD92?.file_id) {
			handlingSelectRowKeys([]);
		}

		resetFields([
			['informations', 'sector_id'],
			['informations', 'line_id']
		]);

		onOpenModal();
	}

	function handleOnClear(): void {
		if (!jdsD92?.file_id) {
			handlingSelectRowKeys([]);
			resetFields(['filter']);
		}
		handleFilter({});
		handlePage(1);
		onOpenModal();
	}

	return (
		<ModalBorder $borderRadius="25px" open={openModal} onCancel={() => onOpenModal()} footer={false}>
			<Row gutter={[0, 20]}>
				<Col span={24}>
					<Row>
						<Col>
							<Title level={3}>Filter</Title>
						</Col>
					</Row>
				</Col>
				<Col span={24}>
					<Row justify="center">
						<Organizations fieldName="filter" />
						<Companies fieldName="filter" isRequired={false} />
						<Col xs={24} style={{ marginTop: 20 }} />
						<Sectors fieldName="filter" isRequired={false} />
						<Col xs={24} style={{ marginTop: 20 }} />
						<Lines fieldName="filter" isRequired={false} />
						<Col xs={24} style={{ marginTop: 20 }} />
						<Workstations fieldName="filter" isRequired={false} />
						<Col xs={24} style={{ marginTop: 20 }} />
						<CreatedAt fieldName="filter" />
					</Row>
				</Col>
				<Col span={24}>
					<Row justify="center" gutter={[10, 0]}>
						<Col>
							<S.CustomButton size="large" onClick={handleOnClear} type="default">
								{I18n.get('Clear')}
							</S.CustomButton>
						</Col>
						<Col>
							<S.CustomButton size="large" onClick={handleOnSubmit} type="primary">
								{I18n.get('Filter')}
							</S.CustomButton>
						</Col>
					</Row>
				</Col>
			</Row>
		</ModalBorder>
	);
}
