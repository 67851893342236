import React from 'react';
import { Row, Col, Button } from 'antd';
import { I18n } from '@aws-amplify/core';
import { FileAddOutlined } from '@ant-design/icons';
import { Title } from '@/components/Typography';

interface HeaderProps {
	onClick: () => void;
}

export const Header: React.FC<HeaderProps> = ({ onClick }) => (
	<Row justify="center">
		<Col span={12}>
			<Title level={3}>Manage risk categories</Title>
		</Col>
		<Col span={12}>
			<Button ghost type="primary" onClick={onClick} icon={<FileAddOutlined />} style={{ float: 'right' }}>
				{I18n.get('Create')}
			</Button>
		</Col>
	</Row>
);
