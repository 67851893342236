import { I18n } from '@aws-amplify/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';

import Api from '@/services/api';

import {
	addInCacheArray,
	checkWhichType,
	filterQueries,
	filterTypes,
	handleCacheArray,
	removeFromCacheArray,
	sortArrayType
} from '@/services/actionPlansService';
import { ACTION_PLANS_MAPPER, ACTION_PLANS_TYPES } from '@/utils/enum';

export const useCreateActionPlan = () => {
	const queryClient = useQueryClient();
	return useMutation((data) => Api.post('/action_plan', data), {
		onSuccess: () => {
			queryClient.invalidateQueries(['action_plans']);
			message.success(I18n.get('Action plan created successfully'));
		},
		onError: (err) => {
			message.error(I18n.get(err.response.data.message));
		}
	});
};

export const useDeleteActionPlan = () => {
	const queryClient = useQueryClient();

	return useMutation(
		({ body }) => {
			const { actionPlanId, organizationId } = body;
			const url = `/action_plan/${actionPlanId}?organization_id=${organizationId}`;
			return Api.delete(url);
		},
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries(['action_plans']);
				message.success(I18n.get('Action plan deleted successfully'));
			},
			onError: (err) => {
				message.error(I18n.get(err.response.data.message));
			}
		}
	);
};

export const useUpdateActionPlan = () => {
	const queryClient = useQueryClient();

	return useMutation(
		({ body }) => {
			const { action_plan, organizationId } = body;
			const url = `/action_plan/${action_plan.id}?organization_id=${organizationId}`;
			return Api.put(url, { action_plan });
		},
		{
			onError: (err, variables, context) => {
				queryClient.setQueryData(['action_plans', context?.previousValue]);
				message.error(I18n.get(err.response.data.message));
			},
			onMutate: async (variables) => {
				//This code is necessary to change front end when request is change, so it change the state without refresh a page

				await queryClient.cancelQueries(['action_plans']);
				const previousValue = queryClient.getQueryData(['action_plans'], {
					exact: false
				});

				//The idea is get the cache from query and move the action card from source to final destination manually

				const cache = queryClient.getQueryCache();

				//Get a last query to each action board type
				const filtered_queries_to_do = filterQueries(ACTION_PLANS_MAPPER.TO_DO, cache);
				const filtered_queries_doing = filterQueries(ACTION_PLANS_MAPPER.DOING, cache);
				const filtered_queries_done = filterQueries(ACTION_PLANS_MAPPER.DONE, cache);

				//Putting de modified card into an empty array and the rest in new array,if it was find in filtered_queries
				const to_do_filtered_type_different = filterTypes(ACTION_PLANS_MAPPER.TO_DO, filtered_queries_to_do);
				const to_do_filtered_type_equal = filterTypes(ACTION_PLANS_MAPPER.TO_DO, filtered_queries_to_do);
				const doing_filtered_type_different = filterTypes(ACTION_PLANS_MAPPER.DOING, filtered_queries_doing);
				const doing_filtered_type_equal = filterTypes(ACTION_PLANS_MAPPER.DOING, filtered_queries_doing);
				const done_filtered_type_different = filterTypes(ACTION_PLANS_MAPPER.DONE, filtered_queries_done);
				const done_filtered_type_equal = filterTypes(ACTION_PLANS_MAPPER.DONE, filtered_queries_done);

				//Set a queries keys
				const key_to_do = filtered_queries_to_do[0].queryKey;
				const key_doing = filtered_queries_doing[0].queryKey;
				const key_done = filtered_queries_done[0].queryKey;

				//Get a query data
				const to_do = queryClient.getQueryData(key_to_do);
				const doing = queryClient.getQueryData(key_doing);
				const done = queryClient.getQueryData(key_done);

				//Set a total plans for each action board type
				const num_sum_to_do = to_do.pages[0].total_plans;
				const num_sum_doing = doing.pages[0].total_plans;
				const num_sum_done = done.pages[0].total_plans;

				const parameters_to_set_mapper = {
					to_do: {
						key: key_to_do,
						query_data: to_do,
						total_plans: num_sum_to_do
					},
					doing: {
						key: key_doing,
						query_data: doing,
						total_plans: num_sum_doing
					},
					done: {
						key: key_done,
						query_data: done,
						total_plans: num_sum_done
					}
				};

				const all_types = {
					[ACTION_PLANS_MAPPER.TO_DO]: filtered_queries_to_do[0],
					[ACTION_PLANS_MAPPER.DOING]: filtered_queries_doing[0],
					[ACTION_PLANS_MAPPER.DONE]: filtered_queries_done[0]
				};

				const parameters_to_type_mapper = {
					to_do: {
						filtered_type_different: to_do_filtered_type_different,
						filtered_type_equal: to_do_filtered_type_equal,
						total_plans: num_sum_to_do,
						total_type_destination: 0,
						query_data: to_do,
						query_data_type_destination: {},
						cache_key: key_to_do,
						cache_key_type_destination: ''
					},
					doing: {
						filtered_type_different: doing_filtered_type_different,
						filtered_type_equal: doing_filtered_type_equal,
						total_plans: num_sum_doing,
						total_type_destination: 0,
						query_data: doing,
						query_data_type_destination: {},
						cache_key: key_doing,
						cache_key_type_destination: ''
					},
					done: {
						filtered_type_different: done_filtered_type_different,
						filtered_type_equal: done_filtered_type_equal,
						total_plans: num_sum_done,
						total_type_destination: 0,
						query_data: done,
						query_data_type_destination: {},
						cache_key: key_done,
						cache_key_type_destination: ''
					}
				};

				const board_type = checkWhichType(to_do_filtered_type_different, doing_filtered_type_different);

				const types_differents_array = [
					to_do_filtered_type_different,
					doing_filtered_type_different,
					done_filtered_type_different
				];

				//This "for" set the card destination parameters in parameters_to_type_mapper
				for (const type_different of types_differents_array) {
					if (type_different.length > 0) {
						const card_action_card_destination = type_different[0].board;

						const card_action_card_destination_fixed =
							ACTION_PLANS_TYPES[card_action_card_destination.replace(' ', '_')];

						parameters_to_type_mapper[board_type].total_type_destination =
							parameters_to_set_mapper[card_action_card_destination_fixed].total_plans;

						parameters_to_type_mapper[board_type].query_data_type_destination =
							parameters_to_set_mapper[card_action_card_destination_fixed].query_data;

						parameters_to_type_mapper[board_type].cache_key_type_destination =
							parameters_to_set_mapper[card_action_card_destination_fixed].key;
					}
				}

				// This function is responsible to get a card and set together the others of same type and remove it from original place
				const { new_array, remove_old_card_from_array } = handleCacheArray(
					parameters_to_type_mapper[board_type].filtered_type_different,
					parameters_to_type_mapper[board_type].filtered_type_equal,
					all_types
				);

				// This function is responsible to replace the old array action cards by modified array action cards in query cache from source
				const { queryData: query_data } = removeFromCacheArray(
					parameters_to_type_mapper[board_type].total_plans,
					remove_old_card_from_array,
					parameters_to_type_mapper[board_type].query_data
				);

				// This function is responsible to replace the old array action cards by modified array action cards in query cache from destination
				const { queryData: query_data_type } = addInCacheArray(
					new_array,
					parameters_to_type_mapper[board_type].total_type_destination,
					parameters_to_type_mapper[board_type].query_data_type_destination
				);

				queryClient.setQueryData(
					parameters_to_type_mapper[board_type].cache_key_type_destination,
					query_data_type
				);

				queryClient.setQueryData(parameters_to_type_mapper[board_type].cache_key, query_data);

				const sortToDoArray = sortArrayType(filtered_queries_to_do);
				const sortDoingArray = sortArrayType(filtered_queries_doing);
				const sortDoneArray = sortArrayType(filtered_queries_done);

				to_do.pages[0].action_cards = sortToDoArray;
				doing.pages[0].action_cards = sortDoingArray;
				done.pages[0].action_cards = sortDoneArray;

				queryClient.setQueryData(key_to_do, to_do);
				queryClient.setQueryData(key_doing, doing);
				queryClient.setQueryData(key_done, done);

				const updatedValue = previousValue;

				//This part od code is responsible to modifier inside infos of action cards
				updatedValue.pages.forEach((element, i) => {
					const index = element.action_cards.findIndex(
						(actionPlan) => actionPlan.id === variables.body.action_plan.id
					);

					if (index !== -1) {
						updatedValue.pages[i].action_cards[index] = {
							...updatedValue.pages[i].action_cards[index],
							...variables.body.action_plan
						};

						queryClient.setQueryData(['action_plans'], updatedValue);
					}
				});

				return updatedValue;
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['action_plans']);
			}
		}
	);
};

export const useCreateTask = () => {
	const queryClient = useQueryClient();
	return useMutation(
		({ body }) => {
			const { task, actionPlanId, organizationId } = body;
			const url = `/action_plan/${actionPlanId}/task?organization_id=${organizationId}`;
			return Api.post(url, { task });
		},
		{
			onError: (err) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['tasks']);
				queryClient.invalidateQueries(['action_plans']);
				message.success(I18n.get('Task created successfully'));
			}
		}
	);
};

export const useUpdateTask = () => {
	const queryClient = useQueryClient();
	return useMutation(
		({ body }) => {
			const { task, actionPlanId, organizationId } = body;
			const url = `/action_plan/${actionPlanId}/task/${task.id}?organization_id=${organizationId}`;
			return Api.put(url, { task });
		},
		{
			onError: (err) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['tasks']);
				queryClient.invalidateQueries(['action_plans']);
				message.success(I18n.get('Task updated successfully'));
			}
		}
	);
};

export const useDeleteTask = () => {
	const queryClient = useQueryClient();
	return useMutation(
		({ body }) => {
			const { taskId, actionPlanId, organizationId } = body;
			const url = `/action_plan/${actionPlanId}/task/${taskId}?organization_id=${organizationId}`;
			return Api.delete(url);
		},
		{
			onError: (err) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['tasks']);
				queryClient.invalidateQueries(['action_plans']);
				message.success(I18n.get('Task deleted successfully'));
			}
		}
	);
};
