import Api from '@/services/api';

import { FileListRequest, DeleteFileRequest, DownloadFileRequest } from './types/request';
import { FileListDTO } from './types/response';

export class FileService {
	async getFileSummaryList({ organization_id, company_id, filter }: FileListRequest): Promise<FileListDTO> {
		const url = `upload/files`;
		const params = { ...filter, organization_id, company_id };

		const { data } = await Api.get<FileListDTO>(url, {
			params
		});
		return data;
	}

	async downloadFile(body: DownloadFileRequest): Promise<string> {
		const url = `upload/create-url-download`;
		const { data } = await Api.post<string>(url, body);
		return data;
	}

	async deleteFileReport(body: DeleteFileRequest): Promise<{ file_id: string }> {
		const url = `upload/files/delete`;
		const { data } = await Api.delete<{ file_id: string }>(url, { params: body });
		return data;
	}
}
