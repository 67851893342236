import { I18n } from '@aws-amplify/core';
import { Col, Form, Row, Select } from 'antd';
import React from 'react';
import { useCustomReports } from '../hooks';
import { useApplicationContext } from '@/context/v1/Application/context';

type OptionType = {
	label: string;
	value: string;
};

export const ReportType = () => {
	const { company, organization } = useApplicationContext();
	const {
		data: customReports,
		isLoading,
		isFetching
	} = useCustomReports({
		organization_id: organization?.id,
		company_id: company?.id
	});

	const options: OptionType[] | undefined = customReports?.map(({ id, acronym }) => ({
		label: acronym,
		value: id
	}));
	return (
		<Col sm={4}>
			<Row>
				<Col xs={24}>
					<Form.Item label={I18n.get('Report')} name="report_type_id" labelCol={{ span: 24 }}>
						<Select
							allowClear
							placeholder={I18n.get('Report type')}
							loading={isLoading || isFetching}
							options={options}
						/>
					</Form.Item>
				</Col>
			</Row>
		</Col>
	);
};
