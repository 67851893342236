import React from 'react';
import { Col, Form, Row, Select } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useApplicationContext } from '@/context/v1/Application/context';

import Hooks from '../../hooks';

const { useGetWorkstations } = Hooks;

interface FilterByWorkstationProps {
	onWorkstationChange: (workstation: string) => void;
	lineId: string;
}

export const FilterByWorkstation: React.FC<FilterByWorkstationProps> = ({ onWorkstationChange, lineId }) => {
	const { organization, company } = useApplicationContext();
	const { isFetching, data, isError } = useGetWorkstations(organization.id, company.id, lineId);

	return (
		<Row gutter={[16, 8]}>
			<Col span={24}>
				<span>{I18n.get('Workstation')}</span>
			</Col>
			<Col span={24}>
				<Form.Item name="workstation">
					<Select
						showSearch
						allowClear
						loading={isFetching}
						onChange={onWorkstationChange}
						placeholder={I18n.get('Workstation')}
						disabled={!lineId || data.length === 0 || isError}
						options={data.length > 0 ? data?.map(({ id, name }) => ({ label: name, value: id })) : []}
						filterOption={(input, option) =>
							(option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
						}
					/>
				</Form.Item>
			</Col>
		</Row>
	);
};
