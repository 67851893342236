import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Text } from '@/components/Typography';

interface ITitleProps {
	isActive: boolean;
}

export const Title: React.FC<ITitleProps> = ({ isActive = false }) => {
	const text: string = 'Check the tool for conducting risk assessments';
	return <Text strong>{I18n.get(text)}:</Text>;
};
