import { useMutation } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { notification } from 'antd';
import { AxiosError } from 'axios';

import Api from '@/services/api';
import type { BaseContext, Response } from '@/types';

export enum CalculateRebaAnglesStatus {
	SUCCESS = 'success',
	FAILED = 'failed'
}

export type CalculateRebaAnglesResponse = {
	status: CalculateRebaAnglesStatus;
	message: string;
};

export type CalculateRebaAnglesDTO = BaseContext & {
	file_id?: string;
};

async function calculateAngles(payload: CalculateRebaAnglesDTO): Promise<CalculateRebaAnglesResponse> {
	const url: string = '/ergonomic-tool/reba/calculate-angles';
	const { data } = await Api.post<CalculateRebaAnglesResponse>(url, payload);
	return data;
}

export const useCalculateAngles = () => {
	return useMutation<CalculateRebaAnglesResponse, AxiosError<Response>, CalculateRebaAnglesDTO>(
		(body) => calculateAngles(body),
		{
			onError: (err) => {
				const message = 'Oops! Something happened.';
				const description = err.response?.data?.message ?? 'Failed to create information';
				notification.error({
					message: I18n.get(message),
					description: I18n.get(description)
				});
			}
		}
	);
};
