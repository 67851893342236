import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import { Form, Modal } from 'antd';

import { DeleteBaseRequest, HierarchyEnum, WorkstationResponse, UpdateBaseRequest } from '../hooks/types';
import { WorkstationSelect } from '@/components/ui/Inputs/WorkstationSelect';
import { useDelete, useGetWorkstations, useUpdate } from '../hooks';
import { useManageOrganizationContext } from '../context';
import { CompanyManager } from './CompanyManager';

const { useFormInstance, useWatch } = Form;

export const ManageWorkstation = () => {
	const form = useFormInstance();
	const { isEditing, organizationId, handleIsEditing, treatFieldValidation } = useManageOrganizationContext();

	const lineId = useWatch(['line_id'], form);
	const companyId = useWatch(['company_id'], form);
	const workstationId = useWatch(['workstation_id'], form);

	const {
		data: workstations,
		isLoading: gettingWorkstations,
		isFetching: fetchingWorkstations,
		isError: errorGettingWorkstations
	} = useGetWorkstations({ company_id: companyId, organization_id: organizationId, line_id: lineId });
	const { mutateAsync: deleteData } = useDelete<DeleteBaseRequest, WorkstationResponse>();
	const { mutateAsync: updateData } = useUpdate<UpdateBaseRequest, WorkstationResponse>();

	const currentName = workstations?.find(({ id }) => id === workstationId)?.name;

	async function handleUpdateName() {
		try {
			const values = await form.getFieldValue(['editing']);
			if (values) {
				const body: UpdateBaseRequest = {
					id: values?.currentId,
					company_id: companyId,
					name: values.workstation_name,
					organization_id: organizationId,
					hierarchy_field: HierarchyEnum.workstation
				};
				const updated = await updateData(body);
				if (updated.id) {
					handleIsEditing(false);
					form.setFieldValue('workstation_id', updated.id);
					form.setFieldValue(['editing', 'workstation_name'], undefined);
				}
			}
		} catch (error: any) {
			treatFieldValidation(error);
		}
	}

	async function handleDeleteWorkstation() {
		Modal.confirm({
			title: I18n.get('Warning!'),
			content: I18n.get('Heads up! Want to delete this workstation?'),
			icon: <ExclamationCircleOutlined />,
			okText: I18n.get('Confirm'),
			cancelText: I18n.get('Cancel'),
			okType: 'danger',
			onOk: async () => {
				const body: DeleteBaseRequest = {
					id: workstationId,
					company_id: companyId,
					organization_id: organizationId,
					hierarchy_field: HierarchyEnum.workstation
				};
				const updated = await deleteData(body);
				if (updated.id) {
					handleClearWorkstation();
					handleIsEditing(false);
					form.setFieldValue(['editing', 'company_name'], undefined);
				}
			}
		});
	}

	function handleWorkstationSelect(id: string) {
		form.setFieldValue(['workstation_id'], id);
	}

	function handleClearWorkstation() {
		form.setFieldValue(['workstation_id'], undefined);
	}

	return (
		<CompanyManager
			key={lineId}
			parentId={lineId}
			currentId={workstationId}
			currentName={currentName}
			fieldLabel={'a workstation'}
			fieldName={['workstation_id']}
			onUpdateName={handleUpdateName}
			onDelete={handleDeleteWorkstation}
			editingNameField={['editing', 'workstation_name']}
		>
			<WorkstationSelect
				size="middle"
				lineId={lineId}
				allowCreation={true}
				workstations={workstations}
				workstationId={workstationId}
				fieldName={['workstation_id']}
				handleClearWorkstations={handleClearWorkstation}
				handleSelectWorkstation={handleWorkstationSelect}
				loading={isEditing || fetchingWorkstations}
				disabled={
					errorGettingWorkstations || gettingWorkstations || fetchingWorkstations || isEditing || !lineId
				}
			/>
		</CompanyManager>
	);
};
