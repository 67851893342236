import React from 'react';
import { Title } from '@/components/Typography';
import { Col, Row } from 'antd';

import { IndustrialSiteName } from './IndustrialSiteName';
import { FileOriginalName } from './FileOriginalName';
import { WorkstationName } from './WorkstationName';
import { CollectionDate } from './CollectionDate';
import { AssessmentDate } from './AssessmentDate';
import { VideoDuration } from './VideoDuration';
import { CompanyName } from './CompanyName';
import { SectorName } from './SectorName';
import { LineName } from './LineName';

export const ReportInformation = () => {
	return (
		<Row justify="start" style={{ marginTop: '2rem' }}>
			<Col xs={24}>
				<Title level={5}>Informations</Title>
			</Col>
			<Col offset={1} xs={15}>
				<Row justify="space-between">
					<Col xs={10}>
						<Row justify="space-evenly">
							<CompanyName />
							<IndustrialSiteName />
							<SectorName />
							<LineName />
							<WorkstationName />
						</Row>
					</Col>
					<Col xs={10}>
						<Row justify="space-evenly">
							<FileOriginalName />
							<CollectionDate />
							<VideoDuration />
							<AssessmentDate />
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
