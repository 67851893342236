import React, { Fragment } from 'react';
import { Divider } from 'antd';

import { LeftOutlined } from '@ant-design/icons';

import { CurrentName, MenuContainer, MenuContent } from './styles';
import { useTreemapContext } from '../context';

export const Menu: React.FC = () => {
	const { page, menuItems } = useTreemapContext();
	const { handleMenuItemSelecion } = useTreemapContext();

	function handleMenuClick(pageNumber: number) {
		handleMenuItemSelecion(pageNumber);
	}

	function shouldShowDivider(item: number) {
		return item !== 0 && item !== menuItems.length;
	}

	return page === 1 ? (
		<MenuContainer>
			<MenuContent page={page} onClick={() => handleMenuClick(0)}>
				<LeftOutlined style={{ fontSize: '12px', marginRight: '0.1rem' }} />
			</MenuContent>
			{menuItems.map((item, index) => {
				return <CurrentName key={index}>{item}</CurrentName>;
			})}
		</MenuContainer>
	) : (
		<MenuContainer>
			{menuItems.map((item, index) => {
				return index === page - 1 ? (
					<CurrentName key={index}>{item}</CurrentName>
				) : (
					<Fragment key={index}>
						{shouldShowDivider(index) && (
							<div style={{ backgroundColor: '#2f54eb33' }}>
								<Divider
									type="vertical"
									style={{
										margin: '0.5rem 0 0 0',
										height: '1rem',
										border: '1px solid #2F54EB',
										borderRadius: '0.1rem'
									}}
								/>
							</div>
						)}
						<MenuContent
							index={index}
							length={menuItems.length}
							page={page}
							onClick={() => handleMenuClick(index + 1)}
						>
							{item}
						</MenuContent>
					</Fragment>
				);
			})}
		</MenuContainer>
	);
};
