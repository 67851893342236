import { CustomReportStepKeyDTO, StepKeyNameList } from '@/components/ui/Inputs/types/response/customReport';
import { TotalTaskDurationDTO } from '@/components/ui/Inputs/types/response/totalTaskDuration';
import { StressLevelDTO } from '@/components/ui/Inputs/types/response/stressLevel';
import { FrequencyDTO } from '@/components/ui/Inputs/types/response/frequency';
import { TaskRPNFactorsService, StepKeyCalculate } from './rpnFactorsService';

interface StepKeyInterface {
	calculateTaskRPN(stressLevelId: string, frequencyId: string, totalTaskDurationId: string): number | undefined;
}

type StepKeyCalculateMapper = { [key in StepKeyNameList]: () => StepKeyCalculate };

export class StepKeyService extends TaskRPNFactorsService implements StepKeyInterface {
	constructor(
		private stepKey: CustomReportStepKeyDTO,
		private stressLevels: StressLevelDTO[],
		private frequencies: FrequencyDTO[],
		private totalTaskDurations: TotalTaskDurationDTO[]
	) {
		super();
	}

	public calculateTaskRPN(stressLevelId: string, frequencyId: string, totalTaskDurationId: string) {
		const stressLevel = this.stressLevels.find((stressLevel) => stressLevel.id === stressLevelId);
		const frequency = this.frequencies.find((frequency) => frequency.id === frequencyId);
		const totalTaskDuration = this.totalTaskDurations.find(
			(totalTaskDuration) => totalTaskDuration.id === totalTaskDurationId
		);

		if (!stressLevel && !frequency && !totalTaskDuration) {
			return undefined;
		}

		const stepKeyCalculateMapper: StepKeyCalculateMapper = {
			has_too_high: () => this.defineDefaultBasedOnStressLevel(stressLevel, frequency, totalTaskDuration),
			has_too_low: () => this.defineDefaultBasedOnStressLevel(stressLevel, frequency, totalTaskDuration),
			has_too_far: () => this.defineDefaultBasedOnStressLevel(stressLevel, frequency, totalTaskDuration),
			striking_parts: () => this.defineWithConditionalFrequency(stressLevel, frequency, totalTaskDuration),
			hand_activities: () => this.defineHandActivities(stressLevel),
			lifting: () => this.defineLifting(stressLevel),
			one_hand_push_pull: () => this.defineDefault(stressLevel, frequency, totalTaskDuration),
			two_hand_push_pull: () => this.defineDefault(stressLevel, frequency, totalTaskDuration),
			cart_push_pull: () => this.defineDefault(stressLevel, frequency, totalTaskDuration),
			vibration: () => this.defineVibration(stressLevel),
			inadequate_clearance: () => this.defineDefaultWithConstantFrequency(stressLevel, totalTaskDuration),
			contact_stress: () => this.defineDefaultWithConstantFrequency(stressLevel, totalTaskDuration)
		};

		const setRPNScores = stepKeyCalculateMapper[this.stepKey.name];

		const { stressLevelScore, frequencyScore, totalTaskDurationScore } = setRPNScores();

		return this.calculate({
			frequencyScore,
			stressLevelScore,
			totalTaskDurationScore
		});
	}

	private calculate({ stressLevelScore, frequencyScore, totalTaskDurationScore }: StepKeyCalculate) {
		return stressLevelScore * frequencyScore * totalTaskDurationScore;
	}
}
