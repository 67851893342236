import React from "react";

function DocumentIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="42"
			height="50"
			fill="none"
			style={{ position: 'absolute' }}
			viewBox="0 0 42 50"
		>
			<path
				fill="#2F54EB"
				d="M28.411 34.014a.446.446 0 00-.363-.19H25.58c-.363 0-.575.425-.363.729l4.122 5.854a.437.437 0 00.72 0l6.378-9.06c.213-.303 0-.728-.363-.728H33.6a.438.438 0 00-.363.19l-3.547 5.034-1.279-1.83zm2.419-20.826v-2.752a.454.454 0 00-.447-.459H8.936a.454.454 0 00-.447.459v2.752c0 .252.201.459.447.459h21.447a.454.454 0 00.447-.459zM8.936 18.234a.454.454 0 00-.447.459v2.752c0 .252.201.459.447.459h10.277a.454.454 0 00.447-.459v-2.752a.454.454 0 00-.447-.46H8.936zm10.277 26.261H4.02V4.128h31.277v16.973c0 .252.2.459.447.459h3.127a.454.454 0 00.447-.46V1.836C39.32.82 38.52 0 37.532 0H1.787C.8 0 0 .82 0 1.835v44.954c0 1.015.799 1.835 1.787 1.835h17.426a.454.454 0 00.447-.459v-3.21a.454.454 0 00-.447-.46zm22.485-18.394l-10.723-3.825a.427.427 0 00-.145-.023c-.05 0-.1.006-.145.023L19.96 26.101a.446.446 0 00-.218.165.464.464 0 00-.083.265v14.398a.45.45 0 00.173.361l10.723 8.613a.445.445 0 00.274.097.445.445 0 00.274-.097l10.723-8.613A.467.467 0 0042 40.93V26.53a.456.456 0 00-.302-.43zm-3.16 13.331L30.83 45.62l-7.708-6.187V28.727l7.708-2.752 7.707 2.752v10.705z"
			></path>
		</svg>
	);
}

export default DocumentIcon;
