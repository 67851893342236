import React, { useState } from 'react';
import { DeleteOutlined, ExclamationCircleOutlined, EyeTwoTone, PlusCircleFilled } from '@ant-design/icons';
import { Form, Row, Col, Tooltip, List, Divider, Button, Modal } from 'antd';
import { Title } from '@/components/Typography';
import { Spinner } from '@/components/Spinner';
import { I18n } from '@aws-amplify/core';

import { useDeleteSeraActionPlan, useSeraActionPlans } from '../../../hooks/hooks';
import { useApplicationContext } from '@/context/v1/Application/context';
import { SeraActionPlanDTO } from '../../../hooks/types/response';
import { useSeraStepsContext } from '../../../context';
import { CreateOrUpdate } from './CreateOrUpdate';
import { CustomList, CustomModal } from './styles';

interface CreateActionPlanModalProps {
	file_id: string;
	visible: boolean;
	onCloseModal: () => void;
}

const { useFormInstance } = Form;

export const CreateActionPlanModal: React.FC<CreateActionPlanModalProps> = ({ file_id, visible, onCloseModal }) => {
	const [showList, setShowList] = useState(true);
	const [showCreateActionPlan, setShowCreateActionPlan] = useState(false);
	const [showUpdateActionPlan, setShowUpdateActionPlan] = useState(false);
	const [actionPlan, setActionPlan] = useState<SeraActionPlanDTO>({} as SeraActionPlanDTO);

	const form = useFormInstance();
	const { seraSummary } = useSeraStepsContext();
	const { company, organization } = useApplicationContext();

	const { sera_summary_review_id } = seraSummary;

	const {
		data: actionPlans,
		isLoading,
		isFetching
	} = useSeraActionPlans({
		company_id: company?.id,
		organization_id: organization?.id,
		sera_summary_review_id,
		file_id
	});
	const { mutateAsync: deleteActionPlan } = useDeleteSeraActionPlan();

	function handleClose() {
		setShowList(true);
		setActionPlan({} as SeraActionPlanDTO);
		setShowCreateActionPlan(false);
		setShowUpdateActionPlan(false);
		onCloseModal();
	}

	function handleAddActionPlan() {
		setActionPlan({} as SeraActionPlanDTO);
		setShowCreateActionPlan(true);
		setShowList(false);
	}

	function handleCancelClick() {
		form.resetFields(['action_plan']);
		setActionPlan({} as SeraActionPlanDTO);
		setShowCreateActionPlan(false);
		setShowUpdateActionPlan(false);
		setShowList(true);
	}

	function handleSelectActionPlan(actionPlan: SeraActionPlanDTO) {
		setActionPlan(actionPlan);
		setShowUpdateActionPlan(true);
		setShowList(false);
	}

	async function handleDeleteActionClick(id: string) {
		Modal.confirm({
			title: I18n.get('Heads up! Want to delete this action plan?'),
			icon: <ExclamationCircleOutlined />,
			okText: I18n.get('Confirm'),
			cancelText: I18n.get('Cancel'),
			okType: 'danger',
			onOk: async () => {
				await deleteActionPlan({
					action_plan_id: id,
					company_id: company?.id,
					organization_id: organization?.id
				});
			}
		});
	}

	const modalBody = () => {
		const actionPlanList = (
			<Row justify="center">
				<CustomList xs={22}>
					{isLoading || isFetching ? (
						<Spinner size={24} />
					) : (
						<List
							size="small"
							dataSource={actionPlans}
							renderItem={(actionPlan: SeraActionPlanDTO) => (
								<List.Item style={{ marginBottom: '1rem' }}>
									<Col xs={24}>
										<Row justify="center">
											<Col xs={22}>
												<Row align="middle">
													<Col
														style={{
															margin: 0,
															padding: 0
														}}
														xs={20}
													>
														<Row>
															<Title
																style={{
																	margin: 0,
																	padding: 0
																}}
																level={5}
															>
																{actionPlan.title}
															</Title>
														</Row>
													</Col>
													<Col xs={4}>
														<Row align="middle" justify="space-between">
															<Col
																xs={1}
																style={{
																	height: '2rem',
																	margin: 0,
																	padding: 0
																}}
															>
																<Divider
																	type="vertical"
																	style={{
																		height: '100%',
																		margin: 0,
																		padding: 0
																	}}
																/>
															</Col>
															<Col xs={8}>
																<Button
																	onClick={() => handleSelectActionPlan(actionPlan)}
																	type="link"
																	icon={
																		<EyeTwoTone
																			style={{
																				fontSize: '1.2rem'
																			}}
																		/>
																	}
																/>
															</Col>
															<Col xs={8}>
																<Button
																	onClick={() => {
																		handleDeleteActionClick(actionPlan.id);
																	}}
																	type="link"
																	icon={
																		<DeleteOutlined
																			style={{
																				fontSize: '1.2rem',
																				color: '#E74150'
																			}}
																		/>
																	}
																/>
															</Col>
														</Row>
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
								</List.Item>
							)}
						/>
					)}
				</CustomList>
			</Row>
		);

		return (
			<Row>
				<Col xs={24}>
					{showList && (
						<>
							<Row justify="center">
								<Title level={4}>S.E.R.A. action plans</Title>
							</Row>
							<Row justify="center">
								<Col xs={21}>
									<span>{I18n.get('Action plans list')}</span>
								</Col>
							</Row>
							<Row justify="center" style={{ marginTop: '1rem' }}>
								<Col xs={24}>{actionPlans.length > 0 && actionPlanList}</Col>
								<Col span={2}>
									<Row justify="center">
										<Tooltip title={I18n.get('Add action plan')}>
											<PlusCircleFilled
												onClick={handleAddActionPlan}
												style={{
													fontSize: '32px',
													color: '#2F54EB',
													marginTop: '1rem'
												}}
											/>
										</Tooltip>
									</Row>
								</Col>
							</Row>
						</>
					)}
					{showCreateActionPlan && (
						<CreateOrUpdate
							file_id={file_id}
							actionPlan={actionPlan}
							onCreateOrUpdate={handleCancelClick}
						/>
					)}
					{showUpdateActionPlan && (
						<CreateOrUpdate
							file_id={file_id}
							actionPlan={actionPlan}
							onCreateOrUpdate={handleCancelClick}
						/>
					)}
				</Col>
			</Row>
		);
	};

	return (
		<CustomModal footer={false} open={visible} onCancel={handleClose}>
			{modalBody()}
		</CustomModal>
	);
};
