import Api from '@/services/api';
import { GetDownloadVideoURLRequest } from './types';

export class DownloadVideoApiService {
	public async getDownloadVideoURL(payload: GetDownloadVideoURLRequest) {
		const url = '/upload/create-url-download';
		const { data } = await Api.post<string>(url, payload);
		return data;
	}
}
