import { ListCompaniesRequest } from '@/components/ui/Inputs/types/request/company';
import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

async function getCompanies({ organization_id, company_id, searching_organization_id }: ListCompaniesRequest) {
	const url = '/company/list';
	const { data } = await Api.get(url, {
		params: {
			searching_organization_id,
			organization_id,
			company_id
		}
	});
	return data;
}

export function useListCompanies({ organization_id, company_id, searching_organization_id }: ListCompaniesRequest) {
	const enabled = !!organization_id && !!company_id;

	const companies = useQuery(
		['companies_list', searching_organization_id ?? organization_id],
		() => getCompanies({ organization_id, company_id, searching_organization_id }),
		{
			enabled
		}
	);
	return companies;
}
