import React from 'react';
import { I18n } from '@aws-amplify/core';
import * as Icons from '@ant-design/icons';
import { Row, Col, Form, Button } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { FieldData } from 'rc-field-form/lib/interface';

import { PersonResponsible } from './PersonResponsible';
import { DeadlineStatus } from './DeadlineStatus';
import { Organization } from './Organizations';
import { DeadlineDate } from './DeadlineDate';
import { Workstations } from './Workstations';
import { FormFields } from './types/request';
import { Companies } from './Companies';
import { Sectors } from './Sectors';
import { Title } from './Title';
import { Lines } from './Lines';
import { Score } from './Score';
import { Files } from './Files';

interface FilterFormProps {
	form: FormInstance;
	initialValues: FormFields;
	isLoading: boolean;
	isDisabled: boolean;
	onClear: () => void;
	onFinish: () => void;
	onFieldsChange: (changeFields: FieldData[], allFields: FieldData[]) => void;
}

export const FilterForm: React.FC<FilterFormProps> = ({
	form,
	initialValues,
	isLoading = false,
	isDisabled = false,
	onClear,
	onFinish,
	onFieldsChange
}) => {
	return (
		<Form
			form={form}
			layout="vertical"
			onFinish={onFinish}
			initialValues={initialValues}
			onFieldsChange={onFieldsChange}
		>
			<Row gutter={8}>
				<Col sm={24} lg={12} xl={16} xxl={8}>
					<Title />
				</Col>
				<Col sm={24} lg={12} xl={8} xxl={4}>
					<PersonResponsible />
				</Col>
				<Col sm={24} lg={12} xl={8} xxl={4}>
					<DeadlineStatus />
				</Col>
				<Col sm={24} lg={12} xl={8} xxl={4}>
					<Score />
				</Col>
				<Col sm={24} lg={12} xl={8} xxl={4}>
					<DeadlineDate />
				</Col>
			</Row>
			<Row gutter={8}>
				<Col sm={24} lg={12} xl={8} xxl={4}>
					<Organization />
				</Col>
				<Col sm={24} lg={12} xl={8} xxl={4}>
					<Companies />
				</Col>
				<Col sm={24} lg={12} xl={8} xxl={4}>
					<Sectors />
				</Col>
				<Col sm={24} lg={12} xl={8} xxl={4}>
					<Lines />
				</Col>
				<Col sm={24} lg={12} xl={8} xxl={4}>
					<Workstations />
				</Col>
				<Col sm={24} lg={12} xl={8} xxl={4}>
					<Files />
				</Col>
			</Row>
			<Row gutter={16} style={{ paddingTop: '20px' }}>
				<Col sm={24} lg={12} xl={8} xxl={4}>
					<Button
						type="primary"
						htmlType="submit"
						loading={isLoading}
						disabled={isDisabled}
						style={{ width: '100%' }}
						icon={<Icons.SearchOutlined />}
					>
						{I18n.get('Search')}
					</Button>
				</Col>
				<Col sm={24} lg={12} xl={8} xxl={4}>
					<Button style={{ width: '100%' }} onClick={onClear}>
						{I18n.get('Clear')}
					</Button>
				</Col>
			</Row>
		</Form>
	);
};
