export enum Diagnostics {
	NOT_IDENTIFIED = 'Not identified',
	SAFE = 'Target condition',
	INFO = 'Conditionally acceptable',
	WARNING = 'Actions must be implemented'
}

export type NotIdentified = Diagnostics.NOT_IDENTIFIED;
export type Safe = Diagnostics.SAFE;
export type Info = Diagnostics.INFO;
export type Warning = Diagnostics.WARNING;
