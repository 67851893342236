import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { Services } from './services';
import { IPlanResponse } from './types';

const { getPlan } = Services;

export const usePlan = (): UseQueryResult<IPlanResponse> => {
	const response = useQuery(['plans'], () => getPlan(), {
		retry: 0
	});
	return response;
};
