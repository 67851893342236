import { useMutation } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';
import type { AxiosError } from 'axios';

import type { BaseContext, Response } from '@/types';
import Api from '@/services/api';

type AdditionalItemToolScoreResult = {
	result: number;
	result_value?: number;
	additional_items_results: AdditionalItemsToolsResult[];
};

type AdditionalItemsToolsResult = {
	result: number;
	value_4?: number;
};

export type AdditionalItemsToolsDto = {
	value_1?: number;
	value_2?: number;
	value_3?: number;
	value_4?: number;
	additional_item_option_id_1?: string;
	additional_item_option_id_2?: string;
};

export type GetAdditionalToolSharedScoreDTO = BaseContext & {
	additional_items: AdditionalItemsToolsDto[];
	custom_report_step_key_id: string;
};

async function calculateAdditionalToolSharedScore(
	params: GetAdditionalToolSharedScoreDTO
): Promise<AdditionalItemToolScoreResult> {
	const url = '/custom-report/result/step-key/calculate-additional-items-risk';
	const { data } = await Api.post<AdditionalItemToolScoreResult>(url, params);
	return data;
}

export const useCalculateAdditionalToolSharedScore = () => {
	return useMutation<AdditionalItemToolScoreResult, AxiosError<Response>, GetAdditionalToolSharedScoreDTO>(
		(body: GetAdditionalToolSharedScoreDTO) => calculateAdditionalToolSharedScore(body),
		{
			onError: (err) => {
				message.error(I18n.get(err?.response?.data?.message ?? err?.message));
			}
		}
	);
};
