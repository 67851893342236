import React from 'react';

import { Col, Row } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Paragraph, Title } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';
import { CustomButton } from '@/components/ui/Buttons/CustomButton/styles';
import { useHistory } from 'react-router-dom';
import { ModalBorder } from '@/components/ui';
import { useEwaJdsD86Context } from '@/components/views/EwaJdsD86/context';

interface ConsolidatedModalProps {
	isModalOpen: boolean;
	setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ConsolidatedModal: React.FC<ConsolidatedModalProps> = ({ isModalOpen, setIsModalOpen }) => {
	const history = useHistory();
	const { isEwa } = useEwaJdsD86Context();

	const handleCancel = () => {
		setIsModalOpen(false);
	};
	const handleOk = () => {
		const url = isEwa ? '/reporting' : '/custom-reports/jds-d86';
		history.push(url)
	};

	return (
		<ModalBorder $borderRadius="30px" open={isModalOpen} footer={false} onCancel={handleCancel}>
			<Row justify={'center'} gutter={[0, 40]} style={{ marginTop: '2rem' }}>
				<Col span={24}>
					<ExclamationCircleFilled style={{ color: '#2F54EB', fontSize: 72, width: '100%' }} />
				</Col>
				<Col style={{ textAlign: 'center' }} span={18}>
					<Title level={3}>Analysis not consolidated!</Title>
					<Paragraph style={{ marginTop: '2rem' }}>
						If you continue, the filled-in data will be saved but not taken into account in your dashboard
					</Paragraph>
				</Col>
				<Col>
					<CustomButton size="large" onClick={handleCancel} style={{ marginRight: '2rem' }}>
						{I18n.get('Cancel')}
					</CustomButton>
					<CustomButton size="large" onClick={handleOk} type="primary">
						{I18n.get('Proceed')}
					</CustomButton>
				</Col>
			</Row>
		</ModalBorder>
	);
};
