import React from 'react';
import { Col, Divider, Row } from 'antd';

import { BeraResultInformations } from '../types';
import { Summary } from './Summary';
import { Scores } from './Scores';

interface InformationsProps {
	isLoading: boolean;
	informations: BeraResultInformations;
}

export const Informations: React.FC<InformationsProps> = ({ isLoading, informations }) => {
	const summaryData = {
		overall_score: informations?.overall_score,
		weighted_rsi: informations?.weighted_rsi,
		total_time: informations?.total_time
	};

	return (
		<Row>
			<Summary isLoading={isLoading} data={informations} />
			<Col span={1} style={{ height: '12rem' }}>
				<Divider type="vertical" style={{ height: '100%' }} />
			</Col>
			<Scores isLoading={isLoading} data={summaryData} />
		</Row>
	);
};
