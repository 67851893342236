import React from 'react';
import { Row, Col, Divider } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Information } from './Information';
import { Title, Text } from '@/components/Typography';

import { useAccountInformation } from '@/hooks/useAccountInformation';

import { Generate2FAModal } from './TwoFactorAuthentication/Generate2FAModal';
import { TwoFactorAuthentication } from './TwoFactorAuthentication';
import { TFAProvider } from './TwoFactorAuthentication/context';
import { ChangePassword } from './ChangePassword/index';

interface TopicProps {
	title: string;
	description: string;
	level?: number;
}

export const Account: React.FC = () => {
	const { isLoading, data, isError } = useAccountInformation();

	if (isLoading) {
		return <span>Loading...</span>;
	}

	if (isError) {
		return <h2>isError...</h2>;
	}

	const Topic = ({ title = '', description = '', level = 3 }: TopicProps) => (
		<Col span={24}>
			<Title level={level}>{title}</Title>
			<Text>{description}</Text>
		</Col>
	);

	return (
		<TFAProvider>
			<Row gutter={[0, 0]}>
				<Col span={24}>
					<Topic title={I18n.get('Information')} description={I18n.get('Account information summary')} />
					{data && <Information data={data} />}
				</Col>
				<Divider />
				<Col span={24}>
					<Topic title={I18n.get('Security')} description={I18n.get('Account security settings')} level={4} />
					<TwoFactorAuthentication />
					<Generate2FAModal />
					<ChangePassword />
				</Col>
			</Row>
		</TFAProvider>
	);
};
