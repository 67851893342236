import React, { useState } from 'react';
import { EyeInvisibleOutlined, EyeOutlined, PlusCircleFilled } from '@ant-design/icons';
import { Table, Button, Row, Col, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import { BeraJobSummaryList, BeraJobSummaryListDTO, BeraReport } from './hooks/types/response';
import { CustomTable } from './styles';
import { SubTable } from './SubTable';
import { Actions } from './Actions';

type ReportListProps = {
	page: number;
	isLoading: boolean;
	maxReports: number;
	onCreateReport: () => void;
	data: BeraJobSummaryListDTO;
	onPageChange: (page: number) => void;
	onDeleteJobSummary: (id: string) => void;
	onMaxReportsChange: (size: number) => void;
	onEditJobSummary: (beraJobSummary: BeraJobSummaryList) => void;
};

export const ReportList: React.FC<ReportListProps> = ({
	data,
	page,
	isLoading,
	maxReports,
	onPageChange,
	onCreateReport,
	onEditJobSummary,
	onDeleteJobSummary,
	onMaxReportsChange
}: ReportListProps) => {
	const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

	const columns: ColumnsType<BeraJobSummaryList> = [
		{
			title: I18n.get('Report'),
			dataIndex: 'report_name',
			key: 'report_name',
			ellipsis: true,
			align: 'center',
			sorter: (a, b) => sortByName(a.report_name, b.report_name)
		},
		{
			title: I18n.get('Workstation'),
			dataIndex: 'workstation_name',
			key: 'workstation_name',
			ellipsis: true,
			align: 'center',
			render: (workstation_name: string) => workstation_name ?? '-',
			responsive: ['lg'],
			sorter: (a, b) => sortByName(a.workstation_name, b.workstation_name)
		},
		{
			title: I18n.get('Cycle'),
			dataIndex: 'cycle_name',
			key: 'cycle_name',
			ellipsis: true,
			align: 'center',
			render: (cycle_name: string) => cycle_name ?? '-',
			responsive: ['md'],
			sorter: (a, b) => sortByName(a.cycle_name, b.cycle_name)
		},
		{
			title: I18n.get('Selected Files'),
			dataIndex: 'bera_report',
			key: 'bera_report',
			align: 'center',
			render: (reports: BeraReport[], data) => reportsList(reports, data),
			responsive: ['lg']
		},
		{
			title: I18n.get('RPN Score'),
			dataIndex: 'overall_score',
			key: 'overall_score',
			ellipsis: true,
			align: 'center',
			render: (score: number | undefined) => (score ? score?.toFixed(2) : '-'),
			responsive: ['sm'],
			sorter: (a, b) => a.overall_score - b.overall_score
		},
		{
			title: I18n.get('Last update'),
			dataIndex: 'updated_at',
			key: 'updated_at',
			ellipsis: true,
			align: 'center',
			responsive: ['lg'],
			render: (updated) => moment(updated).format('L - h:mm:ss A'),
			sorter: (a, b) => moment.utc(a.updated_at).diff(moment.utc(b.updated_at))
		},
		{
			title: I18n.get('Actions'),
			dataIndex: 'url',
			key: 'url',
			align: 'center',
			render: (_value, rowData) => (
				<Actions
					rowData={rowData}
					onDeleteActionClick={onDeleteJobSummary}
					onEditActionClick={onEditJobSummary}
				/>
			)
		}
	];

	function handlePaginationChange(page: number) {
		onPageChange(page);
	}

	function handleShowSizeChange(size: number) {
		onMaxReportsChange(size);
	}

	function sortByName(first?: string, second?: string) {
		if (!first || !second) {
			return -1;
		}
		return ('' + first).localeCompare(second);
	}

	function reportsList(reports: BeraReport[], data: any): JSX.Element {
		return reports.length === 0 || !reports[0] ? (
			<span>-</span>
		) : (
			<Button type="link" onClick={() => handleExpand(data)}>
				{I18n.get(
					expandedRowKeys.includes(data.key) ? (
						<EyeInvisibleOutlined style={{ fontSize: '1.5rem' }} />
					) : (
						<EyeOutlined style={{ fontSize: '1.5rem' }} />
					)
				)}
			</Button>
		);
	}

	function handleExpand(record: any) {
		const rowIndex = expandedRowKeys.indexOf(record.key);
		const updateKeys = [...expandedRowKeys];
		if (rowIndex < 0) {
			updateKeys.push(record.key);
			setExpandedRowKeys(updateKeys);
			return;
		}
		updateKeys.splice(rowIndex, 1);
		setExpandedRowKeys(updateKeys);
	}

	function handleRowClick(bera_report: BeraReport[]) {
		return <SubTable data={bera_report} />;
	}

	function handleCreateBeraJobClick() {
		onCreateReport();
	}

	const dataSource = data.rows?.map((report, index) => ({
		...report,
		key: String(index + 1)
	}));

	return (
		<Row justify="center">
			<CustomTable span={24}>
				<Table
					showSorterTooltip={false}
					rowKey="key"
					expandable={{
						onExpand: () => {},
						expandedRowKeys,
						expandRowByClick: true,
						expandIcon: () => undefined,
						expandIconColumnIndex: -1,
						expandedRowRender: (reports) => handleRowClick(reports.bera_report),
						rowExpandable: (reports) => reports?.bera_report?.length > 0
					}}
					dataSource={dataSource}
					columns={columns}
					loading={isLoading}
					pagination={{
						hideOnSinglePage: false,
						showSizeChanger: true,
						total: data?.count,
						current: page === 0 ? page + 1 : page,
						defaultPageSize: maxReports,
						pageSizeOptions: ['5', '10', '20', '30', '50', '100', '200'],
						pageSize: maxReports,
						onChange: (page) => {
							handlePaginationChange(page);
						},
						onShowSizeChange: (_, size) => handleShowSizeChange(size)
					}}
				/>
			</CustomTable>
			<Col span={2}>
				<Row justify="center">
					<Tooltip title={I18n.get('Create B.E.R.A. report')}>
						<PlusCircleFilled
							onClick={handleCreateBeraJobClick}
							style={{
								fontSize: '32px',
								color: '#2F54EB',
								marginTop: data?.rows?.length > 0 ? 0 : '1rem'
							}}
						/>
					</Tooltip>
				</Row>
			</Col>
		</Row>
	);
};
