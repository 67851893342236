import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form, Radio } from 'antd';

import { RadioStatus, StatusEnum } from './types';

export const Status = () => {
	const options: RadioStatus[] = [
		{
			key: 0,
			value: StatusEnum.active,
			label: 'Active'
		},
		{
			key: 1,
			value: StatusEnum.inactive,
			label: 'Inactive'
		},
		{
			key: 2,
			value: StatusEnum.all,
			label: 'All'
		}
	];

	return (
		<Col xs={24} sm={24} md={24} lg={8} xxl={8}>
			<Form.Item label={I18n.get('Status')} name={['filter', 'status']}>
				<Radio.Group>
					{options.map(({ value, label, key }) => (
						<Radio.Button key={key} value={value}>
							{I18n.get(label)}
						</Radio.Button>
					))}
				</Radio.Group>
			</Form.Item>
		</Col>
	);
};
