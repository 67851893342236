import React from 'react';
import { DeleteOutlined, CheckCircleOutlined, ExclamationCircleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Col, Divider, List, Modal, Row, Tooltip } from 'antd';
import { Title } from '@/components/Typography';
import { useHistory } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';

import { useApplicationContext } from '@/context/v1/Application/context';
import { useLibertyMutualContext } from '../../context';
import { Report } from '../../hooks/types/response';
import { useDeleteReportInput } from '../../hooks';

export const Reports = () => {
	const history = useHistory();
	const { company, organization } = useApplicationContext();
	const { libertyMutual, file_id } = useLibertyMutualContext();
	const { mutateAsync: deleteReportInput } = useDeleteReportInput();

	function handleSelectReport(report_id: string) {
		history.push(`/reporting/report/liberty-mutual/${file_id}/${libertyMutual.id}/${report_id}`);
	}

	async function handleDeleteReport(report_id: string) {
		Modal.confirm({
			title: I18n.get('Heads up! Want to delete this report?'),
			icon: <ExclamationCircleOutlined />,
			okText: I18n.get('Confirm'),
			cancelText: I18n.get('Cancel'),
			okType: 'danger',
			onOk: async () => {
				await deleteReportInput({
					report_id,
					company_id: company?.id,
					organization_id: organization?.id
				});
			}
		});
	}

	return (
		<List
			size="large"
			dataSource={libertyMutual.reports}
			renderItem={({ report, sub_step }: Report) => (
				<List.Item style={{ marginBottom: '1rem' }}>
					<Col xs={24}>
						<Row justify="center">
							<Col xs={22}>
								<Row align="middle">
									<Col style={{ margin: 0, padding: 0 }} xs={18}>
										<Row>
											<Title style={{ margin: 0, padding: 0 }} level={5}>
												{report.report_name}
											</Title>
										</Row>
									</Col>
									<Col xs={2}>
										<Row align="middle" justify="start">
											{sub_step < 2 ? (
												<Tooltip title={I18n.get('Uncompleted')}>
													<ExclamationCircleOutlined
														style={{ color: '#e74150', fontSize: '1.25rem' }}
													/>
												</Tooltip>
											) : (
												<CheckCircleOutlined
													style={{ color: '#17a93b', fontSize: '1.25rem' }}
												/>
											)}
										</Row>
									</Col>
									<Col xs={4}>
										<Row align="middle" justify="space-between">
											<Col xs={1} style={{ height: '2rem', margin: 0, padding: 0 }}>
												<Divider
													type="vertical"
													style={{ height: '100%', margin: 0, padding: 0 }}
												/>
											</Col>
											<Col xs={8}>
												<Button
													onClick={() => handleSelectReport(report.id)}
													type="link"
													icon={<EyeTwoTone style={{ fontSize: '1.2rem' }} />}
												/>
											</Col>
											<Col xs={8}>
												<Button
													onClick={() => {
														handleDeleteReport(report.id);
													}}
													type="link"
													icon={
														<DeleteOutlined
															style={{ fontSize: '1.2rem', color: '#E74150' }}
														/>
													}
												/>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</List.Item>
			)}
		/>
	);
};
