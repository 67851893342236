import React, { useState } from 'react';
import { Form, Button, Col, Row } from 'antd';
import { useDispatch } from 'react-redux';

import { AuthUserDTO, Check2FADTO } from '@/types/AuthUser';
import { setSession } from '@/redux/auth/actions';
import { I18n } from '@aws-amplify/core';
import history from '@/store/history';

import { useCheck2FAContext } from '../context';
import { ResendEmail } from './ResendEmail';
import { NumberContainer } from './styles';
import { ResetToken } from './ResetToken';

const { useForm } = Form;

export const Check2FA = () => {
	const [form] = useForm();
	const dispatch = useDispatch();

	const [authToken, setAuthToken] = useState('');

	const { type, userSession, check2FALoading } = useCheck2FAContext();
	const { handleCheck2FA } = useCheck2FAContext();

	async function handleConfirmClick() {
		await form.validateFields(['verification_code']);
		const checked = await handleCheck2FA(type, authToken.toString());
		if (tokenIsCorrect(checked)) {
			loginUser(userSession);
		}
	}

	function tokenIsCorrect(checked: Check2FADTO) {
		return checked.correct_token;
	}

	function loginUser(userSession: AuthUserDTO) {
		localStorage.setItem('info_user', JSON.stringify({ user: userSession.user, token: userSession.token }));
		dispatch(setSession({ user: userSession.user, token: userSession.token }));
		history.push('/');
	}

	function handleTokenChange(token: string) {
		setAuthToken(token);
	}

	return (
		<Form form={form}>
			<Row align="middle" justify="center" gutter={[16, 32]}>
				<Col span={'auto'}>
					<Form.Item
						style={{ margin: 0 }}
						name="verification_code"
						rules={[
							() => ({
								validator() {
									return authToken.length !== 6
										? Promise.reject(I18n.get('Enter the 6 digit code.'))
										: Promise.resolve();
								}
							})
						]}
					>
						<NumberContainer
							maxLength={6}
							max={6}
							value={authToken}
							onChange={(event) => handleTokenChange(event.target.value)}
						/>
					</Form.Item>
				</Col>

				{type === 'EMAIL' && <ResendEmail />}

				{/* <SaveDevice /> */}

				{/* {enabledTypes.length > 0 && <TryOtherMethod />} */}

				<Col span={24}>
					<Row align="middle" justify="space-between">
						<ResetToken />

						<Col span={'auto'}>
							<Row align="middle">
								<Col>
									<Button
										loading={check2FALoading}
										disabled={authToken.length !== 6}
										onClick={handleConfirmClick}
										htmlType="submit"
										type="primary"
									>
										{I18n.get('Confirm')}
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		</Form>
	);
};
