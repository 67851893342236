import React from 'react';
import { NamePath } from 'antd/lib/form/interface';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { SeverityDTO } from '@/components/ui/Inputs/types/response/severity';
import { SeveritySelect } from '@/components/ui/Inputs/SeveritySelect';
import { CustomLabel } from '@/components/CustomLabel';
import { CustomFormItem } from './styles';
import { CustomIcon } from './CustomIcon';

const { useFormInstance, useWatch } = Form;

interface SeveritiesProps {
	isLoading: boolean;
	isFetching: boolean;
	fieldName: NamePath;
	severities: SeverityDTO[];
}

export const Severities: React.FC<SeveritiesProps> = ({ isFetching, isLoading, fieldName, severities }) => {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();

	const severityId = useWatch(fieldName, form);

	async function handleSeveritySelect(id: string) {
		setFieldValue(fieldName, id);
	}

	async function handleClearSeverity() {
		setFieldValue(fieldName, undefined);
	}

	return (
		<Col xs={24} sm={12} lg={24}>
			<CustomFormItem
				name={fieldName}
				label={<CustomLabel icon={<CustomIcon title="How severe could the injury be?" />} title="Severity" />}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: true,
						message: I18n.get('Select the severity')
					}
				]}
			>
				<SeveritySelect
					fieldName={fieldName}
					loading={isFetching}
					severityId={severityId}
					severities={severities}
					disabled={isLoading || isFetching}
					handleClearSeverity={handleClearSeverity}
					handleSelectSeverity={handleSeveritySelect}
				/>
			</CustomFormItem>
		</Col>
	);
};
