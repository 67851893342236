import styled from 'styled-components';

export const Container = styled.div`
	padding-top: 2rem;
`;

export const Description = styled.label`
	margin: 2px;
	opacity: 0.7;
`;

export const Value = styled.label`
	margin: 2px;
	font-weight: 600;
`;
