import { I18n } from '@aws-amplify/core';
import { notification } from 'antd';
import React, { ReactNode, useContext, useState } from 'react';

import { ValidationError } from '../hooks/types';
import { Context } from './types';

type ManageCategoryProviderProps = {
	children: ReactNode;
};

const ManageCategoryContext = React.createContext<Context>({} as Context);

export function ManageCategoryProvider({ children }: ManageCategoryProviderProps) {
	const [categoryId, setCategoryId] = useState<string>('');
	const [isEditing, setIsEditing] = useState<boolean>(false);

	function treatFieldValidation(error: ValidationError) {
		if (error?.errorFields?.length > 0) {
			error.errorFields.forEach((error) => {
				notification.error({
					message: I18n.get(error.errors[0])
				});
			});
		} else {
			notification.error({
				message: I18n.get('Oops... Something went wrong')
			});
		}
	}

	function handleIsEditing(editing: boolean): void {
		setIsEditing(editing);
	}

	function handleCategoryId(id: string): void {
		setCategoryId(id);
	}

	const contextValue: Context = {
		isEditing,
		categoryId,
		handleIsEditing,
		handleCategoryId,
		treatFieldValidation
	};
	return <ManageCategoryContext.Provider value={contextValue}>{children}</ManageCategoryContext.Provider>;
}

export function useManageCategoryContext() {
	const context = useContext(ManageCategoryContext);
	return context;
}
