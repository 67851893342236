import React from 'react';
import { DeleteOutlined, DownOutlined, EditOutlined, ToolOutlined } from '@ant-design/icons';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Button, Dropdown, Space } from 'antd';
import { I18n } from '@aws-amplify/core';

import { OrganizationDTO } from '@/components/ui/Inputs/types/response';
import { ActionDropdown } from './styles';
import { usePlan } from '@/hooks/usePlan';

type ActionsProps = {
	isEditing: boolean;
	organization: OrganizationDTO;
	organizations: OrganizationDTO[];
	onManageOrganizationModal: (organization: OrganizationDTO) => void;
	onDelete: (event: React.MouseEvent<HTMLElement, MouseEvent>, organizationId: string) => void;
	onUpdate: (event: React.MouseEvent<HTMLElement, MouseEvent>, organizationId: string) => void;
};

export const Actions: React.FC<ActionsProps> = ({
	onDelete,
	onUpdate,
	isEditing,
	organization,
	organizations,
	onManageOrganizationModal
}) => {
	const { data } = usePlan();
	function handleManageOrganization() {
		onManageOrganizationModal(organization);
	}

	function handleUpdate(event: React.MouseEvent<HTMLElement, MouseEvent>) {
		onUpdate(event, organization?.id);
	}

	function handleDelete(event: React.MouseEvent<HTMLElement, MouseEvent>) {
		if (organizations.length === 1) {
			return;
		}
		onDelete(event, organization?.id);
	}

	const disabled = organizations.length === 1 || organization?.companies_id.length > 0 || data?.specialPlan;

	const items: ItemType[] = [
		{
			key: 1,
			icon: <ToolOutlined />,
			label: (
				<Button style={{ padding: 0 }} type="link" onClick={handleManageOrganization}>
					{I18n.get('Manage industrial site')}
				</Button>
			)
		},
		{
			key: 2,
			icon: <EditOutlined />,
			disabled: data?.specialPlan,
			label: (
				<Button
					style={{ padding: 0 }}
					type="link"
					disabled={data?.specialPlan}
					onClick={(e) => handleUpdate(e)}
				>
					{I18n.get('Update company name')}
				</Button>
			)
		},
		{
			key: 3,
			icon: <DeleteOutlined />,
			danger: true,
			label: (
				<Button type="link" style={{ padding: 0 }} disabled={disabled} onClick={(e) => handleDelete(e)}>
					{I18n.get('Delete company')}
				</Button>
			),
			disabled
		}
	];

	return (
		<Dropdown menu={{ items }} disabled={isEditing} placement="bottom" trigger={['click']}>
			<ActionDropdown>
				<Space>
					{I18n.get('Action')}
					<DownOutlined />
				</Space>
			</ActionDropdown>
		</Dropdown>
	);
};
