import React from 'react';
import { Title } from '@/components/Typography';
import { Col, Row, Skeleton } from 'antd';

import { RPNScore, RPNTopic, RSIScore, Topic } from './styles';
import { BeraResultInformations } from '../types';
import { I18n } from '@aws-amplify/core';

interface ScoresProps {
	isLoading: boolean;
	data: Pick<BeraResultInformations, 'overall_score' | 'weighted_rsi' | 'total_time'>;
}

export const Scores: React.FC<ScoresProps> = ({ isLoading, data }) => {
	const RSI_SCALE = {
		SAFE: 'Safe',
		UNCERTAIN: 'Uncertain',
		SOME_RISK: 'Some risk',
		HAZARDOUS: 'Hazardous',
		EXTREME: 'Extreme'
	};

	function convertRSIToScale(weighted_rsi: number | undefined) {
		if (!weighted_rsi || weighted_rsi < 3) {
			return RSI_SCALE.SAFE;
		}

		if (weighted_rsi < 5) {
			return RSI_SCALE.UNCERTAIN;
		}

		if (weighted_rsi < 7) {
			return RSI_SCALE.SOME_RISK;
		}

		if (weighted_rsi < 12) {
			return RSI_SCALE.HAZARDOUS;
		}

		return RSI_SCALE.EXTREME;
	}

	const weighted_rsi_scale = convertRSIToScale(data?.weighted_rsi);

	return (
		<Col span={8}>
			<Row>
				<Col span={24}>
					<Title level={5}>Results</Title>
				</Col>
			</Row>
			{isLoading ? (
				<Skeleton active />
			) : (
				<Row style={{ marginTop: '0.5rem' }}>
					<Col span={24}>
						<Row justify="center">
							<Col span={24}>
								<Topic>{I18n.get('Total evaluated time')}</Topic>
							</Col>
							<Col span={24}>
								<span>{data?.total_time}</span>
							</Col>
						</Row>
						<Row justify="center" style={{ marginTop: '0.5rem' }}>
							<Col span={12}>
								<Row justify="center">
									<Col span={24}>
										<RPNTopic>{I18n.get('Total B.E.R.A.')}</RPNTopic>
									</Col>
									<Col span={24} style={{ marginTop: '1.25rem' }}>
										<RPNScore>{data?.overall_score.toFixed(1) ?? 0}</RPNScore>
									</Col>
								</Row>
							</Col>
							<Col span={12}>
								<Row justify="center">
									<Col span={24}>
										<RPNTopic>{I18n.get('Weighted RSI')}</RPNTopic>
									</Col>
									<Col span={24}>
										<Row justify="center" gutter={[0, 8]}>
											<Col span={24} style={{ marginTop: '0.2rem' }}>
												<Row justify="start">
													<Col span={20}>
														<RSIScore>{data?.weighted_rsi ?? 0}</RSIScore>
													</Col>
												</Row>
											</Col>
											<Col span={24}>
												<Row justify="start">
													<Col span={20}>
														<RSIScore style={{ fontWeight: '400' }}>
															{I18n.get(weighted_rsi_scale)}
														</RSIScore>
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
			)}
		</Col>
	);
};
