import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { ReportTemplate } from '@/components/views/Report/ReportTemplate';
import { Conclusion } from './Conclusion';
import { ReferenceWeight } from './ReferenceWeight';
import { Diagnostics } from './Diagnostics';

export function Result({
	file,
	report,
	sectors,
	formatReport,
	onDownloadPDF,
	isLoadingPDF,
	onChangeComment
}) {
	const formattedReport = formatReport(report?.data);
	const isLoading = report?.isLoading || sectors?.isLoading || !formattedReport;

	const selectedSector = sectors?.data.find((sector) => sector.id === file?.data.sector_id);

	const sections = [
		{
			title: I18n.get('Conclusion'),
			component: <Conclusion formattedReport={formattedReport} />
		},
		{
			title: I18n.get('Reference Weight'),
			component: <ReferenceWeight formattedReport={formattedReport} />
		},
		{
			title: I18n.get('Results'),
			component: <Diagnostics formattedReport={formattedReport} />
		}
	];

	if (isLoading) {
		return (
			<Col span={24}>
				<LoadingOutlined />
			</Col>
		);
	}

	return (
		<ReportTemplate
			title={I18n.get('Manual Lifting Report')}
			sections={sections}
			sector={selectedSector}
			fileData={file?.data}
			reportData={report?.data}
			isLoading={isLoading}
			onDownloadPDF={onDownloadPDF}
			isLoadingPDF={isLoadingPDF}
			onChangeComment={onChangeComment}
		/>
	);
}
