import React from 'react';
import { Col, Image, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { KinebotLogoContainer, TitleContainer } from './styles';

export const Header = () => {
	return (
		<Row>
			<Col span={24}>
				<Row align={'middle'} justify="space-between">
					<Col span={5}>
						<KinebotLogoContainer>
							<Image
								preview={{ visible: false, mask: false }}
								src="https://kinebot-statics.s3.amazonaws.com/kinebot_logo.png"
							/>
						</KinebotLogoContainer>
					</Col>
					<Col span={17}>
						<TitleContainer>
							<span>{I18n.get('Result')}</span>
							<span id="title">{I18n.get('Material handling')}</span>
						</TitleContainer>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
