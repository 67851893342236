import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Title } from '@/components/Typography';

import { useBackCompressiveForceEstimationContext } from '../../../context';
import { PersonIllustration } from '../../Variables/PersonIllustration';
import * as S from './styles';

export function Summary() {
	const {
		backCompressiveForceEstimationResult: { summary }
	} = useBackCompressiveForceEstimationContext();

	return (
		<Row justify="start" gutter={[8, 8]}>
			<Col xs={24}>
				<Title level={4}>Summary</Title>
			</Col>
			<Col offset={2} xs={7}>
				<Row>
					<Col xs={24} style={{ marginTop: 20 }}>
						<S.CardContainer>
							<S.Header>{I18n.get("Person's weight")}</S.Header>
							<S.Body>
								<Row justify="center" style={{ padding: '1rem 0' }}>
									<S.SummaryText>{summary.person_weight}</S.SummaryText>
								</Row>
							</S.Body>
						</S.CardContainer>
					</Col>

					<Col xs={24} style={{ marginTop: 20 }}>
						<S.CardContainer>
							<S.Header>{I18n.get("Person's height")}</S.Header>
							<S.Body>
								<Row justify="center" style={{ padding: '1rem 0' }}>
									<S.SummaryText>{summary.person_height}</S.SummaryText>
								</Row>
							</S.Body>
						</S.CardContainer>
					</Col>

					<Col xs={24} style={{ marginTop: 20 }}>
						<S.CardContainer>
							<S.Header>{I18n.get('Load in hands')}</S.Header>
							<S.Body>
								<Row justify="center" style={{ padding: '1rem 0' }}>
									<S.SummaryText>{summary.load_in_hands}</S.SummaryText>
								</Row>
							</S.Body>
						</S.CardContainer>
					</Col>
				</Row>
			</Col>
			<Col offset={2} xs={11} style={{ marginTop: 20 }}>
				<S.CardContainerImage>
					<S.Header>{I18n.get('Dimensions')}</S.Header>
					<S.Body>
						<Row justify="center" style={{ padding: '1rem 0', position: 'relative' }}>
							<S.TrunkAngleText>{summary.trunk_angle_to_horizontal}°</S.TrunkAngleText>
							<S.HorizontalDistanceText>
								{summary.horizontal_distance_from_hands_to_low_back}
							</S.HorizontalDistanceText>
							<PersonIllustration height={260} />
						</Row>
					</S.Body>
				</S.CardContainerImage>
			</Col>
		</Row>
	);
}
