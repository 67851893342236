import styled from 'styled-components';
import { Col, Form, Input } from 'antd';

export const CustomFormItem = styled(Form.Item)`
	margin: 0 0 1rem 0;
	min-width: 10rem;

	.ant-pagination {
		position: relative;
	}

	.ant-form-item-explain {
		position: absolute;
		bottom: 5%;
	}

	@media (min-width: 768px) {
		min-width: 20rem;
	}
	@media (min-width: 992px) {
		min-width: 30rem;
	}
	.ant-table {
		border-radius: 1rem;
		min-width: 10rem;
		@media (min-width: 768px) {
			min-width: 20rem;
		}
		@media (min-width: 992px) {
			min-width: 30rem;
		}
		.ant-table-thead {
			.ant-table-cell {
				padding: 1rem;
				:first-child {
					padding: 0 1rem;
					@media (min-width: 992px) {
						padding: 0 2rem;
					}
					border-radius: 1rem 0 0 0;
				}
				:last-child {
					border-radius: 0 1rem 0 0;
				}
			}
		}
	}
`;

export const SubtitleCol = styled(Col)`
	display: flex;
	justify-content: space-between;
`;

export const CustomInput = styled(Input)`
	border: 1px solid #20202033;
	padding: 0.5rem 1rem 0.5rem 0.5rem;
	background-color: #ffffffe6;
	transition: all 0.2s;
	.ant-input-affix-wrapper ::placeholder {
		color: #20202055 !important;
		opacity: 1 !important;
	}
	:hover {
		border: 1px solid #20202033 !important;
		padding: 0.5rem 1rem;
		background-color: #ffffffe6;
		filter: brightness(0.98);
	}
`;
