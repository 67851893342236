import { CompanyResponse, SectorResponse, LineResponse, WorkstationResponse } from '../types';

interface IResponseDataTypeChecker {
	isCompanyType(data: unknown): data is CompanyResponse;
	isSectorType(data: unknown): data is SectorResponse;
	isLineType(data: unknown): data is LineResponse;
	isWorkstationType(data: unknown): data is WorkstationResponse;
}

export class ResponseDataTypeChecker implements IResponseDataTypeChecker {
	public isCompanyType(data: unknown): data is CompanyResponse {
		return (data as CompanyResponse)?.organization_id !== undefined;
	}

	public isSectorType(data: unknown): data is SectorResponse {
		return (data as SectorResponse)?.company_id !== undefined;
	}

	public isLineType(data: unknown): data is LineResponse {
		return (data as LineResponse)?.sector_id !== undefined;
	}

	public isWorkstationType(data: unknown): data is WorkstationResponse {
		return (data as WorkstationResponse)?.line_id !== undefined;
	}
}
