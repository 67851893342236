import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Avatar, Descriptions } from 'antd';

const AVATAR_DEFAULT = 'https://kinebot-statics.s3.amazonaws.com/logo_kb_header.png';

export const Information: React.FC<any> = ({ data }) => (
	<Row gutter={[12, 0]} style={{ paddingTop: '2rem', paddingBottom: '1rem' }}>
		<Col span={12}>
			<Descriptions bordered>
				<Descriptions.Item label="Name" span={4}>
					{data.name}
				</Descriptions.Item>
				<Descriptions.Item label="Email" span={4}>
					{data.email}
				</Descriptions.Item>
				<Descriptions.Item label={I18n.get('Phone number')} span={2}>
					{data.customer.phone_number}
				</Descriptions.Item>
				<Descriptions.Item label={I18n.get('Account type')} span={2}>
					{data.role}
				</Descriptions.Item>
				{data.customer && (
					<>
						<Descriptions.Item label={I18n.get('Address')} span={4}>
							{data.customer?.address}
						</Descriptions.Item>
						<Descriptions.Item label={I18n.get('City')} span={2}>
							{data.customer?.city}
						</Descriptions.Item>
						<Descriptions.Item label={I18n.get('State')} span={2}>
							{data.customer?.state}
						</Descriptions.Item>
						<Descriptions.Item label={I18n.get('Country')} span={2}>
							{data.customer?.country}
						</Descriptions.Item>
					</>
				)}
			</Descriptions>
		</Col>
		<Col span={12}>
			<Avatar size={120} src={AVATAR_DEFAULT} />
		</Col>
	</Row>
);
