import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';

import { CreateSeraReport, CreateSeraResultReviewRequest } from '../hooks/types/request';
import { useApplicationContext } from '@/context/v1/Application/context';
import { CalculateTaskRPNResponse } from '../services/seraRPNService';
import { useCreateSeraResultReview } from '../hooks/hooks';
import { useSeraReviewContext } from '../../context';
import { SeraReportForm } from './SeraReportForm';
import { Spinner } from '@/components/Spinner';

const { useForm } = Form;

type InitialValue = {
	[reportId: string]: {
		task_id: string | undefined;
		exposure_id: string | undefined;
		severity_id: string | undefined;
		evaluator_id: string | undefined;
		specifications: string | undefined;
		risk_damage_id: string | undefined;
		risk_category_id: string | undefined;
		vulnerability_id: string | undefined;
		risk_description_id: string | undefined;
		taskRPN: CalculateTaskRPNResponse | undefined;
		existing_prevention_measures: string | undefined;
	};
};

interface SeraReportProps {}

export const SeraReport: React.FC<SeraReportProps> = () => {
	const history = useHistory();
	const [anyCollapseChecked, setAnyCollapseChecked] = useState(false);

	const [form] = useForm();
	const { company, organization } = useApplicationContext();
	const { seraSummaryReview, fetchingSeraSummaryReview, gettingSeraSummaryReview, errorGettingSeraSummaryReview } =
		useSeraReviewContext();

	const { mutateAsync: createSeraResult, isLoading: creatingSeraResult } = useCreateSeraResultReview();

	const { sera_reports, available_tasks, custom_report_id } = seraSummaryReview;

	useEffect(() => {
		handleCollapseChange();
	}, [seraSummaryReview]);

	function handleCollapseChange() {
		const isAnyChecked = mapCheckedCollapses();
		setAnyCollapseChecked(isAnyChecked);
	}

	function mapCheckedCollapses() {
		let anyChecked = false;
		sera_reports?.forEach((report) => {
			const isChecked = form.getFieldValue([report.id, 'checked']);
			if (isChecked) {
				anyChecked = true;
			}
		});
		return anyChecked;
	}

	function handleBackButtonClick() {
		history.push('/custom-reports/sera');
	}

	async function handleFinishClick() {
		const checkedCollapsesStatus = await form.getFieldsValue();
		const validated = await form.validateFields(['true']);
		if (validated) {
			const { seraReportData, reviewedReports } = mapSeraReportData(validated['true'], checkedCollapsesStatus);

			const body: CreateSeraResultReviewRequest = {
				company_id: company?.id,
				organization_id: organization?.id,
				reviewed_reports: reviewedReports,
				sera_summary_id: seraSummaryReview.sera_summary_id,
				sera_report_data: seraReportData
			};
			if (reviewedReports === 0) {
				return;
			}
			await createSeraResult(body);
		}
	}

	type NewType = {
		[id: string]: {
			checked: boolean;
		};
	};

	function mapSeraReportData(values: any, checkedCollapsesStatus: NewType) {
		const seraReportData: CreateSeraReport[] = [];
		for (const key in values) {
			if (Object.hasOwn(values[key], 'exposure_id') && checkedCollapsesStatus[key].checked) {
				const taskRPN = values[key]?.taskRPN;
				const mapped_fields: CreateSeraReport = {
					...values[key],
					rpn: taskRPN?.rpn,
					previous_sera_report_id: key
				};
				seraReportData.push(mapped_fields);
			}
		}
		const mappedExistingReports: CreateSeraReport[] = mapNotEditedReport(seraReportData);
		const reviewedReports = seraReportData.length;
		seraReportData.push(...mappedExistingReports);
		return { seraReportData, reviewedReports };
	}

	if (errorGettingSeraSummaryReview) {
		return (
			<Row justify="center">
				<Col span={24}>
					<span>{I18n.get('Oops... Something went wrong!')}</span>
				</Col>
			</Row>
		);
	}

	if (gettingSeraSummaryReview || fetchingSeraSummaryReview || creatingSeraResult) {
		return (
			<Row justify="center">
				<Col span={24}>
					<Spinner size={48} />
				</Col>
			</Row>
		);
	}

	const initialValues = sera_reports?.reduce((values: InitialValue, currentReport) => {
		const initial: InitialValue = {
			...values,
			[currentReport.id]: {
				task_id: currentReport.task_id,
				risk_category_id: currentReport.risk_category_id,
				risk_description_id: currentReport.risk_description_id,
				risk_damage_id: currentReport.risk_damage_id,
				evaluator_id: currentReport.evaluator_id,
				specifications: currentReport.specifications,
				existing_prevention_measures: currentReport.existing_prevention_measures,
				exposure_id: currentReport.exposure_id,
				vulnerability_id: currentReport.vulnerability_id,
				severity_id: currentReport.severity_id,
				taskRPN: undefined
			}
		};
		return initial;
	}, {} as InitialValue);

	function mapNotEditedReport(sera_report_data: CreateSeraReport[]) {
		const selectedSeraReportsIds = sera_report_data.map((report) => report.previous_sera_report_id);
		const filteredReports = sera_reports.filter((report) => !selectedSeraReportsIds.includes(report.id));
		const mappedExistingReports: CreateSeraReport[] = filteredReports.map((report) => {
			return {
				rpn: report.rpn,
				task_id: report.task_id,
				exposure_id: report.exposure_id,
				severity_id: report.severity_id,
				evaluator_id: report.evaluator_id,
				specifications: report.specifications,
				risk_category_id: report.risk_category_id,
				vulnerability_id: report.vulnerability_id,
				risk_damage_id: report.risk_damage_id,
				risk_description_id: report.risk_description_id,
				existing_prevention_measures: report.existing_prevention_measures
			};
		});
		return mappedExistingReports;
	}

	function renderSeraForm() {
		return sera_reports.map((report) => (
			<SeraReportForm
				key={report.id}
				reportId={report.id}
				availableTasks={available_tasks}
				customReportId={custom_report_id}
				onAnyCollapseChange={handleCollapseChange}
			/>
		));
	}

	return (
		<Col xs={22}>
			<Form form={form} initialValues={{ true: initialValues }}>
				{renderSeraForm()}
				<Row justify="center" gutter={[32, 0]}>
					<Col>
						<Button type="default" onClick={handleBackButtonClick}>
							{I18n.get('Back')}
						</Button>
					</Col>
					<Col>
						<Form.Item>
							<Button
								htmlType="submit"
								onClick={handleFinishClick}
								type="primary"
								disabled={!anyCollapseChecked}
							>
								{I18n.get('Finish')}
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Col>
	);
};
