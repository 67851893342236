import React from 'react';
import { I18n } from '@aws-amplify/core';
import { ColumnsType } from 'antd/es/table';
import { Row, Col, Grid, Table } from 'antd';

import { Risk, RiskBarOptions } from '@/components/Dashboard/RiskBar/types';
import { RiskBar } from '@/components/Dashboard/RiskBar';
import { Text, Title } from '@/components/Typography';
import type { CharacteristicsResults } from '@/hooks';
import {
	ENUM_WORKPOPULATION_SCALE,
	TableContainerHorizontal,
	ChartContainerWorking,
	StressLevelChart,
	ChartsContainer,
	LegendContainer,
	ChartContainer,
	StressLevel,
	ChartLegend,
	RowCustom,
	Stress
} from './styles';

const { useBreakpoint } = Grid;

type DataType = {
	key: string;
	title: string;
	descriptions: string;
};

type WorkingPopulation = {
	men: number;
	women: number;
	others: number;
	total: number;
};

interface CharacteristicsProps {
	characteristics: CharacteristicsResults;
}

export function Characteristics({ characteristics }: CharacteristicsProps) {
	const {
		worker_self_evaluation,
		total_working_population,
		particularities_description,
		worker_verbalization_description,
		working_population_men_percentage,
		working_population_women_percentage,
		working_population_others_percentage,
		working_population_men,
		working_population_women,
		working_population_others
	} = characteristics;

	const { name: stress_level } = worker_self_evaluation;

	const breakpoint = useBreakpoint();

	const percentage = 100;

	const stressLevelPercentage: { [key: string]: number } = {
		nothing_stressful: 20,
		little_stressful: 40,
		stressful: 60,
		very_stressful: 80,
		extremely_stressful: 100
	};

	const stressPercentage = percentage - (stress_level !== null ? stressLevelPercentage[stress_level] : 0);

	const columns: ColumnsType<DataType> = [
		{
			title: '',
			dataIndex: 'title',
			key: 'title'
		},
		{
			title: '',
			dataIndex: 'descriptions',
			key: 'descriptions'
		}
	];

	const data: DataType[] = [
		{
			key: '1',
			title: I18n.get('Particularities'),
			descriptions: particularities_description ?? 'N/A'
		},
		{
			key: '2',
			title: I18n.get(`Worker's verbalization`),
			descriptions: worker_verbalization_description ?? 'N/A'
		}
	];

	const riskBarOptions: RiskBarOptions = {
		caption: {
			align: 'center',
			display: 'right'
		},
		value: {
			align: 'center',
			display: 'bottom'
		},
		orientation: 'horizontal',
		isNewDesign: true
	};

	enum Gender {
		MEN = 'men',
		WOMEN = 'women',
		OTHERS = 'others'
	}

	const WORKING_POPULATION = {
		TOTAL: 'total',
		MEN: Gender.MEN,
		WOMEN: Gender.WOMEN,
		OTHERS: Gender.OTHERS
	};

	const workingPopulationPercentage: WorkingPopulation = {
		men: working_population_men_percentage,
		women: working_population_women_percentage,
		others: working_population_others_percentage,
		total: total_working_population
	};

	const workingPopulation: WorkingPopulation = {
		men: working_population_men,
		women: working_population_women,
		others: working_population_others,
		total: total_working_population
	};

	function riskMapper(workingPopulationObject = {} as WorkingPopulation) {
		let risks: Risk[] = [];
		let id = 0;
		for (const worker in workingPopulationObject) {
			if (worker !== WORKING_POPULATION.TOTAL) {
				const gender = worker as Gender;

				if (workingPopulationObject[gender] > 0) {
					const mappedWorkingPopulation: Risk = {
						id,
						...ENUM_WORKPOPULATION_SCALE[gender],
						percentage: workingPopulationPercentage[gender] + '%',
						value: workingPopulationObject[gender]
					};

					risks.push(mappedWorkingPopulation);
					id += 1;
				}
			}
		}

		return risks.sort((a, b) => a.id - b.id);
	}

	return (
		<Row gutter={[20, 20]}>
			<Col lg={24} xl={24} xxl={17}>
				<TableContainerHorizontal>
					<Table columns={columns} showHeader={false} dataSource={data} pagination={false} />
				</TableContainerHorizontal>
			</Col>
			<Col xl={24} xxl={6}>
				<RowCustom style={{ height: '100%' }} $isSmallScreen={breakpoint}>
					<Col lg={24} xl={12} xxl={24}>
						<ChartsContainer className="charts-container-worker">
							<Row justify="center" align="middle">
								<Col span={24}>
									<Row justify="center">
										<Col xl={10} xxl={16}>
											<Title style={{ marginBottom: 10, textAlign: 'center' }} level={5}>
												{I18n.get('Working Population')}
											</Title>
										</Col>
									</Row>
								</Col>
								<Col span={15}>
									<ChartContainerWorking>
										<RiskBar
											options={riskBarOptions}
											total={total_working_population}
											risks={riskMapper(workingPopulation)}
										/>
									</ChartContainerWorking>
								</Col>
								<Col span={24}>
									<Row justify="center">
										{riskMapper(workingPopulation).map(({ id, caption, trailColor }) => (
											<Col span={8} key={id}>
												<LegendContainer>
													<ChartLegend $borderColor={trailColor} />
													<Text>{caption}</Text>
												</LegendContainer>
											</Col>
										))}
									</Row>
								</Col>
							</Row>
						</ChartsContainer>
					</Col>
					<Col lg={24} xl={12} xxl={24}>
						<ChartsContainer className="charts-container-stress">
							<Row style={{ alignContent: 'center' }} justify="center" align="middle" gutter={[0, 10]}>
								<Col span={24}>
									<Row justify="center">
										<Col xl={8} xxl={8}>
											<Title style={{ marginBottom: 10, textAlign: 'center' }} level={5}>
												{I18n.get('Stress Level')}
											</Title>
										</Col>
									</Row>
								</Col>

								<Col span={15}>
									<ChartContainer percentage={stressPercentage}>
										<StressLevelChart id="chart" percentage={stressPercentage} />
										<StressLevel id="level" />
									</ChartContainer>
								</Col>

								<Col span={24} style={{ textAlign: 'center' }}>
									<Stress>{I18n.get(worker_self_evaluation.description)}</Stress>
								</Col>
							</Row>
						</ChartsContainer>
					</Col>
				</RowCustom>
			</Col>
		</Row>
	);
}
