import React from 'react';
import { Collapse as WrapperCollapse } from 'antd';

interface CollapseProps {
	header?: string;
	isOpen?: boolean;
	children: JSX.Element | JSX.Element[];
}

const { Panel } = WrapperCollapse;

export const Collapse: React.FC<CollapseProps> = ({ header = '', isOpen, children }) => {
	const defaultActiveKey: string = isOpen === true ? '1' : '0';
	return (
		<WrapperCollapse defaultActiveKey={defaultActiveKey}>
			<Panel key="1" header={header}>
				{children}
			</Panel>
		</WrapperCollapse>
	);
};
