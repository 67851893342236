import React from 'react';
import { Row, Col, Steps, Button } from 'antd';
import styled from 'styled-components';
import { I18n } from '@aws-amplify/core';

const { Step } = Steps;

const Container = styled(Col)`
	padding: 10px 0px 10px 0px;
`;

export const Title = ({ children }) => <h2>{I18n.get(children)}</h2>;

export const HeaderStep = ({ current, steps }) => (
	<Container sm={24}>
		<Steps progressDot size="small" current={current}>
			{steps.map((item) => (
				<Step progressDot key={item.title} title={I18n.get(item.title)} />
			))}
		</Steps>
	</Container>
);

export const Main = ({ children }) => <Col sm={24}>{children}</Col>;

export const Footer = ({ current, steps, onNext, onPrevious, onFinish, onClose, isLoading, isFetching = false }) => (
	<Col sm={24}>
		<Row justify="center">
			<Col>
				{current > 0 && (
					<Button style={{ margin: '0 8px' }} onClick={onPrevious}>
						{I18n.get('Previous')}
					</Button>
				)}
			</Col>
			<Col>
				{current < steps.length - 2 && (
					<Button type="primary" onClick={onNext} disabled={!steps[current].is_valid}>
						{I18n.get('Next')}
					</Button>
				)}
			</Col>
			<Col>
				{current === steps.length - 2 && (
					<Button
						type="primary"
						onClick={onFinish}
						loading={isFetching}
						{...(isLoading && { loading: isLoading })}
					>
						{I18n.get('Finish')}
					</Button>
				)}
				{current === steps.length - 1 && (
					<Button type="primary" onClick={onClose}>
						{I18n.get('Close')}
					</Button>
				)}
			</Col>
		</Row>
	</Col>
);
