export type BeraReportRPN = {
	severity: number;
	exposure: number;
	vulnerability: number;
	rpn: number;
};

export type TasksRpn = {
	stress_level: number[];
	frequency: number[];
	total_task_duration: number[];
};

interface BeraResultInterface {
	calculateRPN(tasksRpn: TasksRpn, has_known_injury: boolean): BeraReportRPN;
}

export class BeraResultService implements BeraResultInterface {
	public calculateRPN(tasksRpn: TasksRpn, has_known_injury: boolean): BeraReportRPN {
		const severity = this.hasScores(tasksRpn.stress_level, () => this.calculateSeverity(tasksRpn.stress_level));
		const exposure = this.hasScores(tasksRpn.total_task_duration, () =>
			this.calculateExposure(tasksRpn.total_task_duration)
		);
		const vulnerability = has_known_injury
			? 6
			: this.hasScores(tasksRpn.frequency, () => this.calculateVulnerability(tasksRpn.frequency));
		const rpn = this.calculate(severity, exposure, vulnerability);

		return {
			severity,
			exposure,
			vulnerability,
			rpn
		};
	}

	private hasScores(scores: number[], calculateScore: (scores: number[]) => number) {
		return scores?.length > 0 ? calculateScore(scores) : 1;
	}

	private calculateSeverity(stressLevelScores: number[]): number {
		const sum = stressLevelScores?.reduce((sum, currentScore) => sum + currentScore);
		const max = Math.max(...stressLevelScores);
		const highScore = sum === 0 ? 1 : max;
		const higherThanOne = stressLevelScores.filter((score) => score > 1);
		const additionalScore = (sum > 1 ? higherThanOne.length : 0) - 1;
		const aux_sum = highScore + additionalScore < 1 ? 1 : highScore + additionalScore;
		const severity = aux_sum > 5 ? 5 : aux_sum;
		return severity;
	}

	private calculateExposure(totalTaskDurationscores: number[]): number {
		const max = Math.max(...totalTaskDurationscores);
		const highScore = max;
		const higherThanOne = totalTaskDurationscores.filter((score) => score > 1);
		const additionalScore = higherThanOne.length - 1;
		const aux_sum = highScore + additionalScore < 1 ? 1 : highScore + additionalScore;
		const severity = aux_sum > 5 ? 5 : aux_sum;
		return severity;
	}

	private calculateVulnerability(frequencyScores: number[]): number {
		const max = Math.max(...frequencyScores);
		const highScore = max;
		const higherThanOne = frequencyScores.filter((score) => score > 1);
		const additionalScore = higherThanOne.length - 1;
		const aux_sum = highScore + additionalScore < 1 ? 1 : highScore + additionalScore;
		const severity = aux_sum > 4 ? 4 : aux_sum;
		return severity;
	}

	private calculate(severity: number, exposure: number, vulnerability: number): number {
		return severity * exposure * vulnerability;
	}
}
