import Types from './types';
import organizationService from './services';
import Service from './services';

export function onChangeProjectSelected() {
	localStorage.removeItem('organization');
	localStorage.removeItem('company');
	return (dispatch) => dispatch({ type: Types.CHANGE_PROJECT_SELECTED });
}
// ********************************** //

export const listOrganization = () => async (dispatch) => {
	try {
		dispatch(loading());
		const data = await Service.getOrganization();
		return dispatch(success(data));
	} catch (error) {
		dispatch(errors(error));
	} finally {
		dispatch(loading());
	}
	function loading() {
		return { type: Types.LIST_ORGANIZATION_LOADING };
	}
	function success(data) {
		return { type: Types.LIST_ORGANIZATION_SUCCESS, payload: data };
	}
	function errors(error) {
		return { type: Types.LIST_ORGANIZATION_ERROR, payload: error };
	}
};

export const listCompanies = (parameters) => async (dispatch) => {
	try {
		dispatch(loading());
		const data = await Service.getCompanies(parameters);
		return dispatch(success(data));
	} catch (error) {
		dispatch(errors(error));
	} finally {
		dispatch(loading());
	}
	function loading() {
		return { type: Types.LIST_COMPANIES_LOADING };
	}
	function success(data) {
		return { type: Types.LIST_COMPANIES_SUCCESS, payload: data };
	}
	function errors(error) {
		return { type: Types.LIST_COMPANIES_ERROR, payload: error };
	}
};

export const listSectors = (parameters) => async (dispatch) => {
	try {
		dispatch(loading());
		const data = await Service.getSectors(parameters);
		return dispatch(success(data));
	} catch (error) {
		dispatch(errors(error));
	} finally {
		dispatch(loading());
	}
	function loading() {
		return { type: Types.LIST_SECTORS_LOADING };
	}
	function success(data) {
		return { type: Types.LIST_SECTORS_SUCCESS, payload: data };
	}
	function errors(error) {
		return { type: Types.LIST_SECTORS_ERROR, payload: error };
	}
};

// OLDS

export const clearNewOrganization = () => {
	return { type: Types.CLEAR_NEW_ORGANIZATION };
};

export const clearNewCompany = () => {
	return { type: Types.CLEAR_NEW_COMPANY };
};

export const createOrganization = (parameters) => {
	return async (dispatch) => {
		try {
			dispatch(onLoading());
			const result = await Service.createOrganization(parameters);
			return dispatch(onSuccess(result));
		} catch (err) {
			dispatch(onError(err));
		} finally {
			dispatch(onLoading());
		}
	};
	function onLoading() {
		console.log('1');
		return { type: Types.CREATE_ORGANIZATION_LOADING };
	}
	function onSuccess(data) {
		return { type: Types.CREATE_ORGANIZATION_SUCCESS, payload: data };
	}
	function onError(error) {
		return { type: Types.CREATE_ORGANIZATION_ERROR, payload: error };
	}
};

export const createCompany = (parameters) => {
	return async (dispatch) => {
		try {
			dispatch(onLoading());
			const result = await Service.createCompany(parameters);
			return dispatch(onSuccess(result));
		} catch (err) {
			dispatch(onError(err));
		} finally {
			dispatch(onLoading());
		}
	};
	function onLoading() {
		return { type: Types.CREATE_COMPANY_LOADING };
	}
	function onSuccess(data) {
		return { type: Types.CREATE_COMPANY_SUCCESS, payload: data };
	}
	function onError(error) {
		return { type: Types.CREATE_COMPANY_ERROR, payload: error };
	}
};

export const getOrganizations = () => {
	return async (dispatch) => {
		try {
			dispatch(onLoading());
			const result = await organizationService.listOrganizationByUser();
			return dispatch(onSuccess(result));
		} catch (err) {
			dispatch(onError(err));
		} finally {
			dispatch(onLoading());
		}
	};
	function onLoading() {
		return { type: Types.GET_ORGANIZATION_LOADING };
	}
	function onSuccess(data) {
		return { type: Types.GET_ORGANIZATION_SUCCESS, payload: data };
	}
	function onError(error) {
		return { type: Types.GET_ORGANIZATION_ERROR, payload: error };
	}

	// return async (dispatch) => {
	//   try {
	//     dispatch(openModal());
	//     dispatch(onLoading());
	// const result = await organizationService.listOrganizationByUser();
	// return dispatch(onSuccess(result));
	//   } catch (err) {
	//     dispatch(onError(err));
	//   } finally {
	//     dispatch(onLoading());
	//   }
	// }
	// function onLoading() {
	//   return { type: Types.REQUEST_LOADING }
	// }
	// function onSuccess(data) {
	//   return { type: Types.LIST_ORG_SUCCESS, payload: data }
	// }
	// function onError(err) {
	//   const { response } = err;
	//   return { type: Types.LIST_ORG_FAILURE, payload: response.data }
	// }
	// function openModal() {
	//   return { type: Types.MODAL_SELECT }
	// }
};

export const clearOrganization = () => {
	return { type: Types.CLEAR_ORGANIZATION };
};

export const clearListCompanies = () => {
	return { type: Types.CLEAR_LIST_COMPANIES };
};

export const setOrganization = (parameters) => {
	return { type: Types.SET_ORGANIZATION, payload: parameters };
};

export const getCompanies = (parameters) => {
	return async (dispatch) => {
		try {
			dispatch(onLoading());
			const response = await organizationService.listCompanyByUser(parameters);
			return dispatch(onSuccess(response));
		} catch (error) {
			dispatch(onError(error));
		} finally {
			dispatch(onLoading());
		}
	};
	function onLoading() {
		return { type: Types.GET_LIST_COMPANIES_LOADING };
	}
	function onSuccess(data) {
		return { type: Types.GET_LIST_COMPANIES_SUCCESS, payload: data };
	}
	function onError(error) {
		return { type: Types.GET_LIST_COMPANIES_ERROR, payload: error };
	}
	// function onLoading() {
	//   return { type: Types.REQUEST_LOADING }
	// }
	// function onSuccess(data) {
	//   return { type: Types.LIST_COMPANY_SUCCESS, payload: data }
	// }
	// function onError(error) {
	//   return { type: Types.LIST_COMPANY_FAILURE, payload: error }
	// }
};

export const setCompany = (parameters) => {
	const { company, organization } = parameters;
	if (company && organization) {
		localStorage.setItem('company', JSON.stringify(company));
		localStorage.setItem('organization', JSON.stringify(organization));
	}
	return { type: Types.SET_COMPANY, payload: company };
};

// Deprecado.
export const getOrganizationsList = () => {
	return async (dispatch) => {
		try {
			dispatch(onLoading());

			const response = await organizationService.listOrganizationByUser();

			dispatch(onSuccess(response));
		} catch (error) {
			dispatch(onError(error));
		} finally {
			dispatch(onLoading());
		}
	};
	function onLoading() {
		return { type: Types.REQUEST_LOADING_SELECT };
	}
	function onSuccess(response) {
		return { type: Types.LIST_ORG_SUCCESS, payload: response?.data || [] };
	}
	function onError(error) {
		const { response } = error;
		return { type: Types.LIST_SELECT_FAILURE, payload: response.data };
	}
};
// Deprecado
export const getCompaniesList = (parameters) => {
	return async (dispatch) => {
		try {
			dispatch(onLoading());

			const response = await organizationService.listCompanyByUser(parameters);

			dispatch(onSuccess(response));
		} catch (error) {
			dispatch(onError());
		} finally {
			dispatch(onLoading());
		}
	};
	function onLoading() {
		return { type: Types.REQUEST_LOADING_SELECT_COMPANY };
	}
	function onSuccess(response) {
		const { data } = response;
		return { type: Types.LIST_COMPANY_SUCCESS, payload: data };
	}
	function onError(error) {
		const { response } = error;
		return { type: Types.LIST_SELECT_FAILURE, payload: response.data };
	}
};

const getAllCompany = (parameters) => {
	return async (dispatch) => {
		try {
			dispatch(onLoading());

			const data = await organizationService.listCompanyByUser(parameters);

			dispatch(onSuccess(data));
		} catch (error) {
			dispatch(onError());
		} finally {
			dispatch(onLoading());
		}
	};
	function onLoading() {
		return { type: Types.REQUEST_LOADING_SELECT_COMPANY };
	}
	function onSuccess(response) {
		const { data } = response;
		return { type: Types.LIST_COMPANY_SUCCESS, payload: data };
	}
	function onError(error) {
		const { response } = error;
		return { type: Types.LIST_SELECT_FAILURE, payload: response.data };
	}
};

const getAll = () => {
	return async (dispatch) => {
		try {
			dispatch(onLoading());

			const response = await organizationService.listOrganizationByUser();

			dispatch(onSuccess(response));

			return Promise.resolve({});
		} catch (error) {
			dispatch(onError(error));
		} finally {
			dispatch(onLoading());
		}
	};
	function onLoading() {
		return { type: Types.REQUEST_LOADING_SELECT };
	}
	function onSuccess(response) {
		return { type: Types.LIST_ORG_SUCCESS, payload: response?.data || [] };
	}
	function onError(error) {
		const { response } = error;
		return { type: Types.LIST_SELECT_FAILURE, payload: response.data };
	}
};

function modalSelectProject() {
	return { type: Types.MODAL_SELECT_PROJECT };
}

const organizationActions = {
	getAll,
	getAllCompany,
	getOrganizations,
	getCompanies,
	modalSelectProject,
	setOrganization,
	setCompany,
	changeProject: () => {
		localStorage.removeItem('organization');
		localStorage.removeItem('company');
		return { type: Types.CHANGE_PROJECT };
	},
	companiesFilterReports: (parameters) => {
		return async (dispatch) => {
			try {
				dispatch(onLoading());

				const response = await organizationService.listCompanyByUser(parameters);

				response?.data && dispatch(onSuccess(response.data));
			} catch (error) {
				dispatch(onError(error));
			} finally {
				dispatch(onLoading());
			}
		};
		function onLoading() {
			return { type: Types.COMPANIES_FILTER_LOADING };
		}
		function onSuccess(data) {
			return { type: Types.COMPANIES_FILTER, payload: data };
		}
		function onError(error) {
			return { type: Types.COMPANIES_FILTER_ERROR, payload: error };
		}
	}
};

export default organizationActions;
