import { I18n } from '@aws-amplify/core';
import { message, notification } from 'antd';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { customReportsListService } from './services';
import { CustomReportsFilesPaginated } from './types/response';
import { CustomReportsRequest } from '@/components/ui/Inputs/types/request/customReport';
import { DeleteFileRequest, DownloadFileRequest, GetFilesRequest } from './types/request';

export function useCustomReports({ organization_id, company_id }: CustomReportsRequest) {
	const enabled = !!organization_id && !!company_id;
	const customReports = useQuery(
		['custom_reports', [organization_id, company_id]],
		() => customReportsListService.fetchCustomReports({ organization_id, company_id }),
		{
			enabled,
			retry: 0
		}
	);
	return customReports;
}

export const useGetCustomReportsFiles = (params: GetFilesRequest) => {
	const files = useQuery({
		retry: 0,
		enabled: !!params.organization_id && !!params.company_id,
		queryKey: ['custom_report_file_list', { ...params }],
		queryFn: () => customReportsListService.fetchFiles(params)
	});
	return {
		...files,
		data: files.data ?? ({} as CustomReportsFilesPaginated)
	};
};

export const useDownloadFile = () => {
	return useMutation((body: DownloadFileRequest) => customReportsListService.downloadFile(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0].split('" ');
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: (data) => {
			const archive = document.createElement('a');
			archive.target = '_blank';
			archive.href = data;
			archive.click();
			archive.remove();
		}
	});
};

export const useDeleteFile = () => {
	const queryClient = useQueryClient();
	return useMutation((body: DeleteFileRequest) => customReportsListService.deleteFile(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0].split('" ');
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['custom_report_file_list']);
			message.success(I18n.get('File deleted successfully'));
		}
	});
};
