import React from 'react';
import { Title } from '@/components/Typography';
import { Col, Divider, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { SeraResultTaskSummary } from '../hooks/types';
import { Description } from './styles';

type FirstRow = Pick<
	SeraResultTaskSummary,
	'existing_prevention_measures' | 'risk_category' | 'risk_damage' | 'risk_description' | 'specifications'
>;

type TaskResultFirstRowProps = {
	summary: FirstRow;
};

export const TaskResultFirstRow: React.FC<TaskResultFirstRowProps> = ({ summary }) => {
	const { existing_prevention_measures, risk_category, risk_damage, risk_description, specifications } = summary;

	return (
		<Row justify="space-between" style={{ margin: '1rem 0 1rem 1rem' }}>
			<Col span={4}>
				<Row>
					<Title style={{ margin: 0, fontSize: '0.8rem' }}>{I18n.get('Category')}</Title>
				</Row>
				<Row>
					<Description>{I18n.get(risk_category ?? 'N/A')}</Description>
				</Row>
			</Col>
			<Col style={{ minHeight: '100%' }}>
				<Divider style={{ height: '100%' }} type="vertical" />
			</Col>
			<Col span={4}>
				<Row>
					<Title style={{ margin: 0, fontSize: '0.8rem' }}>{I18n.get('Risk description')}</Title>
				</Row>
				<Row>
					<Description>{I18n.get(risk_description ?? 'N/A')}</Description>
				</Row>
			</Col>
			<Col style={{ minHeight: '100%' }}>
				<Divider style={{ height: '100%' }} type="vertical" />
			</Col>
			<Col span={4}>
				<Row>
					<Title style={{ margin: 0, fontSize: '0.8rem' }}>{I18n.get('Damage')}</Title>
				</Row>
				<Row>
					<Description>{I18n.get(risk_damage ?? 'N/A')}</Description>
				</Row>
			</Col>
			<Col style={{ minHeight: '100%' }}>
				<Divider style={{ height: '100%' }} type="vertical" />
			</Col>
			<Col span={4}>
				<Row>
					<Title style={{ margin: 0, fontSize: '0.8rem' }}>{I18n.get('Specification')}</Title>
				</Row>
				<Row>
					<Description>{specifications?.length > 0 ? specifications : 'N/A'}</Description>
				</Row>
			</Col>
			<Col style={{ minHeight: '100%' }}>
				<Divider style={{ height: '100%' }} type="vertical" />
			</Col>
			<Col span={4}>
				<Row>
					<Title style={{ margin: 0, fontSize: '0.8rem' }}>{I18n.get('Existing prevention measures')}</Title>
				</Row>
				<Row>
					<Description>
						{existing_prevention_measures?.length > 0 ? existing_prevention_measures : 'N/A'}
					</Description>
				</Row>
			</Col>
		</Row>
	);
};
