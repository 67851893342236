import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col } from 'antd';

import { CustomFormItem, CustomRow } from './styles';

interface VersionProps {
	version: string;
}

export const Version: React.FC<VersionProps> = ({ version }) => {
	return (
		<Col xs={24} sm={12} lg={24}>
			<CustomFormItem label={I18n.get('Version')}>
				<CustomRow>{version}</CustomRow>
			</CustomFormItem>
		</Col>
	);
};
