import { useMutation, useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import type { AxiosError } from 'axios';
import { message } from 'antd';

import Api from '@/services/api';
import type { BaseContext, CustomReportResult, Response } from '@/types';

export type ConsolidateReportDTO = BaseContext & {
	locale: string;
	file_id: string;
	report_id: string;
	custom_report_result_id?: string;
	selected_pdf_sections?: string[];
};

async function consolidateReport(payload: ConsolidateReportDTO): Promise<CustomReportResult> {
	const url = '/custom-report/result/consolidate';
	const { data } = await Api.post<CustomReportResult>(url, payload);
	return data;
}

export const useConsolidateCustomReport = () => {
	const queryClient = useQueryClient();
	return useMutation<CustomReportResult, AxiosError<Response>, ConsolidateReportDTO>(
		(body) => consolidateReport(body),
		{
			onError: (err) => {
				message.error(I18n.get(err?.response?.data?.message ?? err?.message));
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['jds_d86_result']);
			}
		}
	);
};
