import React, { useContext } from 'react';
import { Row } from 'antd';

import { Score } from './Score';
import { Summary } from './Summary';
import { ResultsContext } from '../context';

export const SummaryInformation: React.FC = () => {
	const { final_grade, sum_grade } = useContext(ResultsContext);
	return (
		<Row>
			<Summary />
			<Score score={final_grade?.score} sum={sum_grade} result={final_grade?.result} />
		</Row>
	);
};
