import React from 'react';
import { Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { CustomFormItem, CustomRow } from './styles';

interface SpecificationsProps {
	specifications: string;
}

export const Specifications: React.FC<SpecificationsProps> = ({ specifications }) => {
	return (
		<Col xs={24} sm={12} lg={24}>
			<CustomFormItem label={I18n.get('Specifications')} labelCol={{ span: 24 }}>
				<CustomRow>{specifications}</CustomRow>
			</CustomFormItem>
		</Col>
	);
};
