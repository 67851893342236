import Api from '@/services/api';

import { CustomReportFilesRequest } from '@/components/ui/Inputs/types/request/customReport';
import { CustomReportFilesDTO } from '@/components/ui/Inputs/types/response/customReport';

import { BeraReportRequest, CreateBeraResultRequest, UpdateBeraResultRequest } from '../../hooks/types/request';
import { BeraReportDTO } from '../../hooks/types/response';

export class BeraService {
	async getBeraReport({
		organization_id,
		company_id,
		file_id,
		bera_job_summary_id
	}: BeraReportRequest): Promise<BeraReportDTO> {
		const url = `/bera/report/file/${file_id}?bera_job_summary_id=${bera_job_summary_id}&organization_id=${organization_id}&company_id=${company_id}`;
		const { data } = await Api.get<BeraReportDTO>(url);
		return data;
	}

	async createBeraReport(body: CreateBeraResultRequest): Promise<BeraReportDTO> {
		const url = '/bera/report/';
		const { data } = await Api.post<BeraReportDTO>(url, body);
		return data;
	}

	async updateBeraReport(body: UpdateBeraResultRequest): Promise<BeraReportDTO> {
		const url = `/bera/report/${body.bera_report_data.id}`;
		const { data } = await Api.put<BeraReportDTO>(url, body);
		return data;
	}

	async getBeraFiles({
		bera_job_summary_id,
		custom_report_id,
		organization_id,
		report_type,
		company_id,
		fileName,
		offset,
		limit
	}: CustomReportFilesRequest): Promise<CustomReportFilesDTO> {
		const url = `/custom-report/list/${custom_report_id}`;
		const { data } = await Api.get<CustomReportFilesDTO>(url, {
			params: {
				limit,
				offset,
				company_id,
				report_type,
				organization_id,
				file_name: fileName,
				bera_job_summary_id
			}
		});
		return data;
	}
}
