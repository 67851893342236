import React from 'react';
import { Pie } from 'react-chartjs-2';

import { AnglesType } from '../../../../PosturesAndMovements/types';
import { diagnosticColors } from '@/constants/Reba';

interface PieChartProps {
	scores: AnglesType;
}

export const PieChart: React.FC<PieChartProps> = ({ scores }) => {
	const data = {
		datasets: [
			{
				backgroundColor: diagnosticColors,
				borderColor: diagnosticColors,
				borderWidth: 1,
				data: scores,
				datalabels: {
					display: false
				}
			}
		]
	};

	const options = {
		plugins: {
			tooltip: {
				enabled: false // Hide tooltip
			}
		}
	};

	return <Pie data={data} options={options} />;
};
