import React from 'react';
import { TextInput } from './TextInput';
import { Col, InputNumber } from 'antd';

interface WorkingPopulationWomenProps {
	validate: () => Promise<void>;
	fieldName: string;
}

export const WorkingPopulationWomen: React.FC<WorkingPopulationWomenProps> = ({ fieldName, validate }) => {
	return (
		<Col xs={6}>
			<TextInput namePath={[fieldName, 'working_population_women']} title="Women">
				<InputNumber onChange={validate} min={0} />
			</TextInput>
		</Col>
	);
};
