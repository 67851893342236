import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

import { BackCompressiveForceEstimationReportDTO, GetReportRequest } from '@/components/views/Report/BackCompressiveForceEstimation/types';

const DEFAULT_URL = '/ergonomic-tool/back-compressive-force-estimation'

async function getReport(payload: GetReportRequest) {
	const url = `${DEFAULT_URL}/${payload.file_id}`;
	const { data } = await Api.get<BackCompressiveForceEstimationReportDTO>(url, {
		params: payload
	});
	return data;
}

export const useGetReport = ({ organization_id, company_id, file_id }: GetReportRequest) => {
	const parameters = { organization_id, company_id, file_id };
	const report = useQuery(
		['back_compressive_force_report', parameters],
		() => getReport(parameters),
		{
			enabled: !!organization_id && !!company_id && !!file_id,
			retry: 0
		}
	);
	return {
		...report,
		data: report.data ?? ({} as BackCompressiveForceEstimationReportDTO)
	};
};
