import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';
import { BaseContext } from '@/types';

export type GetSystemsOfUnitsRequest = BaseContext;

export enum SystemOfUnitsName {
	'imperial' = 'imperial',
	'metric' = 'metric'
}

export enum SystemOfUnitsDescription {
	'Imperial' = 'Imperial',
	'Metric' = 'Metric'
}

export type SystemOfUnitsDTO = {
	id: string;
	name: SystemOfUnitsName;
	description: SystemOfUnitsDescription;
};

async function getSystemsOfUnits(payload: GetSystemsOfUnitsRequest) {
	const url = '/system-of-units';
	const { data } = await Api.get<SystemOfUnitsDTO[]>(url, {
		params: payload
	});
	return data;
}

export const useGetSystemOfUnits = ({ organization_id, company_id }: GetSystemsOfUnitsRequest) => {
	const parameters = { organization_id, company_id };
	const systemOfUnits = useQuery(['systems_of_units', parameters], () => getSystemsOfUnits(parameters), {
		enabled: !!organization_id && !!company_id
	});
	return {
		...systemOfUnits,
		data: systemOfUnits.data ?? ([] as SystemOfUnitsDTO[])
	};
};
