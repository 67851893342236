import { Col, Row } from 'antd';
import React from 'react';
import { CustomButton, CustomTooltip } from './styles';
import { I18n } from '@aws-amplify/core';
import { LeftOutlined } from '@ant-design/icons';

interface BackButtonProps {
	onPreviousClick: () => void;
}

export const BackButton: React.FC<BackButtonProps> = ({ onPreviousClick }) => {
	function handleButtonClick() {
		onPreviousClick();
	}
	return (
		<Row justify="center">
			<Col xs={24} xl={24}>
				<CustomTooltip title={I18n.get('Go back to files selection')}>
					<CustomButton type="link" onClick={handleButtonClick} icon={<LeftOutlined />}>
						{I18n.get('Back')}
					</CustomButton>
				</CustomTooltip>
			</Col>
		</Row>
	);
};
