import { useQuery } from '@tanstack/react-query';

import { TreemapServices } from '../services/apiServices';
import { WorstScoreDTO } from './types/response';

const treemapServices = new TreemapServices();

const useGetCompaniesWorstScore = (organizationId: string, companyId: string, page: number) => {
	const parameters = { organizationId, companyId };
	const refetchTrigger = { ...parameters, page };
	const companies = useQuery(
		['companies_worst_score', refetchTrigger],
		() => treemapServices.getCompanies(parameters),
		{
			enabled: !!organizationId && !!companyId,
			retry: 0
		}
	);
	return {
		...companies,
		data: companies.data || ([] as WorstScoreDTO[])
	};
};

const useGetSectorsWorstScore = (organizationId: string, companyId: string, page: number) => {
	const parameters = { organizationId, companyId };
	const refetchTrigger = { ...parameters, page };
	const sectors = useQuery(['sectors_worst_score', refetchTrigger], () => treemapServices.getSectors(parameters), {
		enabled: !!organizationId && !!companyId,
		retry: 0
	});
	return {
		...sectors,
		data: sectors.data || ([] as WorstScoreDTO[])
	};
};

const useGetLinesWorstScore = (organizationId: string, companyId: string, sector_id: string, page: number) => {
	const parameters = { organizationId, companyId, sector_id };
	const refetchTrigger = { ...parameters, page };
	const lines = useQuery(['lines_worst_score', refetchTrigger], () => treemapServices.getLines(parameters), {
		enabled: !!organizationId && !!companyId && !!sector_id,
		retry: 0
	});
	return {
		...lines,
		data: lines.data || ([] as WorstScoreDTO[])
	};
};

const useGetWorkstationsWorstScore = (organizationId: string, companyId: string, line_id: string, page: number) => {
	const parameters = { organizationId, companyId, line_id };
	const refetchTrigger = { ...parameters, page };
	const workstations = useQuery(
		['workstations_worst_score', refetchTrigger],
		() => treemapServices.getWorkstations(parameters),
		{
			enabled: !!organizationId && !!companyId && !!line_id,
			retry: 0
		}
	);
	return {
		...workstations,
		data: workstations.data || ([] as WorstScoreDTO[])
	};
};

export { useGetCompaniesWorstScore, useGetSectorsWorstScore, useGetLinesWorstScore, useGetWorkstationsWorstScore };
