import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

import { EvaluatorsRequest } from '@/components/ui/Inputs/types/request/evaluator';
import { EvaluatorDTO } from '@/components/ui/Inputs/types/response/evaluator';

async function getEvaluators({ organization_id, company_id }: EvaluatorsRequest): Promise<EvaluatorDTO[]> {
	const url = '/evaluator';
	const { data } = await Api.get<EvaluatorDTO[]>(url, {
		params: {
			company_id,
			organization_id
		}
	});
	return data;
}

export function useEvaluators({ organization_id, company_id }: EvaluatorsRequest) {
	const enabled = !!organization_id && !!company_id;

	const evaluators = useQuery(
		['evaluators', { organization_id, company_id }],
		() => getEvaluators({ organization_id, company_id }),
		{ enabled }
	);
	return evaluators;
}
