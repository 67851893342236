import React, { ReactElement, useState } from 'react';
import { Button, Divider, Form, Input, Select, Skeleton } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { NamePath } from 'antd/lib/form/interface';
import { I18n } from '@aws-amplify/core';

import { useApplicationContext } from '@/context/v1/Application/context';
import { LineDTO } from '@/components/ui/Inputs/types/response/line';
import { useCreateLine } from '@/hooks/v1/useLineMutations';

import { CreateLineRequest } from '../types/request/line';

const { useFormInstance } = Form;

type LineSelectProps = {
	size?: SizeType;
	lineId?: string;
	loading: boolean;
	sectorId: string;
	disabled: boolean;
	fieldName: NamePath;
	allowCreation?: boolean;
	lines: LineDTO[] | undefined;
	handleClearLines?: () => void;
	handleSelectLine?: (id: string) => void;
};

type OptionType = { label: string; value: string } | undefined;

export function LineSelect({
	lines,
	lineId,
	loading,
	disabled,
	sectorId,
	fieldName,
	size = 'middle',
	handleClearLines,
	handleSelectLine,
	allowCreation = true
}: LineSelectProps) {
	const [lineName, setLineName] = useState('');

	const { company, organization } = useApplicationContext();
	const { validateFields } = useFormInstance();

	const { mutateAsync: createLine, isLoading } = useCreateLine();

	async function onCreateLine() {
		const body: CreateLineRequest = {
			organization_id: organization.id,
			company_id: company.id,
			sector_id: sectorId,
			name: lineName
		};

		const { data: line } = await createLine({ body });
		const lineId = line?.id;
		if (lineId) {
			handleChange(lineId);
		}
		setLineName('');
	}

	async function handleChange(id: string) {
		if (handleSelectLine) {
			handleSelectLine(id);
			await validateFields([fieldName]);
		}
	}

	function handleClear() {
		if (handleClearLines) {
			handleClearLines();
		}
	}

	function handleLineNameChange(name: string) {
		setLineName(name);
	}

	function filterOption(option: OptionType, input: string): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function showDropdownRender(menu: ReactElement): JSX.Element {
		if (!allowCreation) {
			return <div>{menu}</div>;
		}
		return (
			<div>
				{menu}
				<Divider style={{ margin: '4px 0' }} />
				<div
					style={{
						display: 'flex',
						flexWrap: 'nowrap',
						padding: 8
					}}
				>
					<Input
						style={{ flex: 'auto', borderRadius: '7px' }}
						value={lineName}
						onChange={(e) => handleLineNameChange(e.target.value)}
					/>
					<Button
						onClick={onCreateLine}
						type="link"
						loading={isLoading}
						disabled={!lineName || lineName.length < 3}
					>
						{I18n.get('Add item')}
					</Button>
				</div>
			</div>
		);
	}

	if (loading) {
		return <Skeleton.Input block active size="small" />;
	}

	return (
		<Select
			allowClear
			showSearch
			size={size}
			value={lineId}
			disabled={disabled}
			onClear={handleClear}
			placeholder={I18n.get('Line')}
			onChange={(value: string) => handleChange(value)}
			dropdownRender={(menu) => showDropdownRender(menu)}
			filterOption={(input, option) => filterOption(option, input)}
			options={lines?.map(({ id, name }) => ({ label: name, value: id }))}
		/>
	);
}
