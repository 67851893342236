import React from 'react'
import { ModalStyled } from './styles';
import { ActionPlan, Task } from '../../../context/types';
import { Title } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';
import { Col, Divider, Progress, Row } from 'antd';
import moment from 'moment';

type Props = {
	isOpen: boolean;
	handleModalClose: () => void;
	data?: ActionPlan;
}

function calculateCompletedTasksPercentage(tasks: Task[]) {
	const completedTasks = tasks.filter(task => task.is_completed === true);
	const totalTasks = tasks.length;
	const completedTasksPercentage = (completedTasks.length / totalTasks) * 100;
	return completedTasksPercentage || 0;
}

const conditionMapper = {
	ELIMINATE: 'Eliminate',
	CONTROL: 'Control',
	COMPENSATE: 'Compensate',
	'default': 'N/A'
};

export const ActionPlanModal = ({ isOpen, handleModalClose, data }: Props) => {
	return (
		<ModalStyled
			open={isOpen}
			onCancel={() => handleModalClose()}
			footer={null}
		>
			<Title level={3}>{I18n.get('Plano de ação')} JDS-D86</Title>

			<Row justify={'center'}>
				<Col span={22}>
					<Title level={3}>{data?.title || 'N/A'}</Title>
					<Row style={{ marginBottom: '1rem' }}>
						<Col span={12}>
							<div>
								<Title level={4} style={{ marginBottom: '0rem' }}>{I18n.get('Responsible')}</Title>
								<Title level={5} style={{ marginBottom: '0rem', marginTop: '0.5rem', marginLeft: '1rem' }}>{data?.responsible_name || 'N/A'}</Title>
							</div>
						</Col>
						<Col span={12}>
							<div>
								<Title level={4} style={{ marginBottom: '0rem' }}>{I18n.get('Deadline')}</Title>
								<Title level={5} style={{ marginBottom: '0rem', marginTop: '0.5rem', marginLeft: '1rem' }}>{moment(data?.deadline).format('L') || 'N/A'}</Title>
							</div>
						</Col>
						<Col span={12}>
							<div>
								<Title level={4} style={{ marginTop: '1rem' }}>{I18n.get('Description')}</Title>
								<Title level={5} style={{ marginBottom: '0rem', marginTop: '0.5rem', marginLeft: '1rem' }}>{data?.description || 'N/A'}</Title>
							</div>
						</Col>
					</Row>
					{Boolean(data?.tasks?.length) &&
						<Row>
							<Col span={24} style={{ marginBottom: '2rem', marginTop: '2rem' }}>
								<Title level={4} style={{ marginBottom: '0rem' }}>{I18n.get('Tasks')}</Title>
							</Col>
							<Col span={22} style={{ marginBottom: '2rem' }}>
								<Progress
									showInfo={false}
									trailColor="#00000033"
									percent={calculateCompletedTasksPercentage(data?.tasks || [])}
									strokeColor={'#4f00eb'}
								/>
							</Col>
							<Col span={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<Title level={5} >
									{calculateCompletedTasksPercentage(data?.tasks || []).toFixed(2)}%
								</Title>
							</Col>
							{data?.tasks?.map(task => <>
								<Col span={18}>
									<Title level={5} style={{ marginBottom: '0rem', marginTop: '0.5rem', marginLeft: '1rem' }}>{task?.description || 'N/A'}</Title>
								</Col>
								<Col span={6}>
									<Title level={5} style={{ marginBottom: '0rem', marginTop: '0.5rem', marginLeft: '1rem' }}>{conditionMapper[(task?.type as '') || 'default']}</Title>
								</Col>
								<Col span={24}>
									<Divider style={{ margin: '12px 0' }} />
								</Col>
							</>)}
						</Row>
					}
				</Col>
			</Row>
		</ModalStyled>
	)
}
