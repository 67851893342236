import { DeleteOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { I18n } from '@aws-amplify/core';
import { ModalStyled } from './styles';
import { Button, Col, Row } from 'antd';
import { Paragraph, Title, Text } from '@/components/Typography';
import { SelectedReport } from '../types';

type ModalDeleteProps = {
	report?: SelectedReport;
	open: boolean;
	onDeleteReport: (type: string) => void;
	handleDeleteLastRevaluation: (type: string) => void;
	onCancel: () => void;
}

export const ModalDelete: React.FC<ModalDeleteProps> = ({ report, open, onCancel, onDeleteReport, handleDeleteLastRevaluation }) => {
	const [confirmType, setConfirmType] = useState('');

	function handleDeleteConfirm() {
		if (confirmType === 'last revaluation') {
			handleDeleteLastRevaluation(report?.original_id as string);
			return;
		}
		onDeleteReport(report?.original_id as string)
	}

	useEffect(() => {
		if (report?.original_id) {
			setConfirmType('');
		}
	}, [report?.original_id])

	if (!report?.last_review_date || confirmType) {
		return (
			<ModalStyled width={500} footer={null} open={open} onCancel={onCancel} centered>
				<>
					<Row justify="center">
						<DeleteOutlined style={{
							color: '#E74150',
							fontSize: 70,
						}}
						/>
					</Row>
					<Row justify="center">
						<Col span={20} style={{ marginTop: 10, }}>
							<Paragraph className="paragraph-modal" style={{ fontSize: '24px', textAlign: 'center' }}>
								{I18n.get(`Are you sure you want to delete the ${confirmType || 'review'}`)} <strong>
									"{report?.report_name}"
								</strong> ?
							</Paragraph>
						</Col>
					</Row>
					<Row justify="center">
						<Button key="back" onClick={onCancel} style={{ marginRight: 10 }}>
							{I18n.get('Cancel')}
						</Button>
						<Button type="primary" style={{ marginLeft: 10 }} onClick={() => handleDeleteConfirm()}>
							{I18n.get('Confirm')}
						</Button>
					</Row>
				</>
			</ModalStyled>
		);
	}

	return (
		<ModalStyled width={480} footer={null} open={open} onCancel={onCancel} centered>
			<>
				<Row justify="center">
					<DeleteOutlined style={{
						color: '#E74150',
						fontSize: 70,
					}} />

				</Row>
				<Row justify="center">
					<Col span={20} style={{ marginTop: 10, }}>
						<Title className="paragraph-modal" style={{ fontSize: '24px', textAlign: 'center' }}>
							{I18n.get('Delete review')}
						</Title>
					</Col>
				</Row>
				<Row justify="center">
					<Col span={20} style={{ marginTop: 10, }}>
						<Text style={{ fontSize: '18px', width: '100%', display: 'flex', textAlign: 'center' }}>
							{I18n.get('You can delete the entire report or just the last revaluation')}
						</Text>
					</Col>
				</Row>
				<Row justify="center">
					<Col span={20} style={{ marginTop: 10, }}>
						<Button block danger type='primary' onClick={() => setConfirmType('review')} style={{ marginTop: 10 }}>
							{I18n.get('Delete report')}
						</Button>
						<Button block danger type='primary' onClick={() => setConfirmType('last revaluation')} style={{ marginTop: 10 }}>
							{I18n.get('Delete the last revaluation')}
						</Button>
						<Button block key="back" onClick={onCancel} style={{ marginTop: 10 }}>
							{I18n.get('Cancel')}
						</Button>
					</Col>
				</Row></>
		</ModalStyled>
	);
};
