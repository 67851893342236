import React from 'react';
import moment from 'moment';
import { Col, Row } from 'antd';

import { Text, Title } from '@/components/Typography';
import { VideoContainer } from './VideoContainer';
import { SystemOfUnits } from './SystemsOfUnits';
import { Organizations } from './Organizations';
import { Workstations } from './Workstations';
import { Companies } from './Companies';
import { Sectors } from './Sectors';
import { Lines } from './Lines';

export const Informations: React.FC = () => {
	const today = moment().format('L');
	return (
		<Row justify="center" gutter={[5, 20]}>
			<Col xs={20}>
				<Row align="middle">
					<Col xs={12}>
						<Title level={4}>General informations</Title>
					</Col>
					<Col xs={12} style={{ textAlign: 'end' }}>
						<Text>Date of creation</Text>: {today}
					</Col>
				</Row>
				<Row justify="center">
					<Col xs={24} style={{ marginBottom: '20px' }}>
						<Text level={5}>Enter the data related to the selected video below</Text>
					</Col>
				</Row>
				<Row justify="center">
					<VideoContainer />
				</Row>
				<Row justify="center">
					<Col xs={12} style={{ marginTop: '1rem' }}>
						<Row gutter={[0, 20]}>
							<Organizations />
							<Companies />
							<Sectors />
							<Lines />
							<Workstations />
							<SystemOfUnits />
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
