export enum SOCKET_EVENTS {
	LIBERTY_MUTUAL_REPORT = 'LIBERTY_MUTUAL_REPORT',
	STRAIN_INDEX_REPORT = 'STRAIN_INDEX_REPORT',
	UPDATE_FILE_STATUS = 'UPDATE_FILE_STATUS',
	KIM_MHO_REPORT = 'KIM_MHO_REPORT',
	KIM_PP_REPORT = 'KIM_PP_REPORT',
	NIOSH_REPORT = 'NIOSH_REPORT',
	BACK_COMPRESSIVE_FORCE_ESTIMATION_REPORT = 'BACK_COMPRESSIVE_FORCE_ESTIMATION_REPORT'
}

export enum RoomKey {
	REPORT_STATUS = 'REPORT_STATUS',
	FILE_STATUS = 'FILE_STATUS'
}

export enum RoomValue {
	FILE_STATUS = '/file',
	REPORT_STATUS = '/report-status'
}
