import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form, Input } from 'antd';

export const Title: React.FC = () => {
	return (
		<Col xs={24}>
			<Form.Item labelCol={{ span: 24 }} name={['title']} label={I18n.get('Action plan title')}>
				<Input placeholder={I18n.get('Title')} />
			</Form.Item>
		</Col>
	);
};
