import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, Form, Input, message } from 'antd';

import { I18n } from '@aws-amplify/core';
import Api from '@/services/api';
import { useDispatch } from 'react-redux';
import { addCompanyListOrganization } from '@/redux/workspace/actions';

const { useForm } = Form;

export function CreateCompany({ visible, onClose, onCreate, organizationId }) {
	const [form] = useForm();
	const inputName = useRef(null);
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState(null);

	useEffect(() => {
		if (!inputName.current) return;
		inputName.current.focus();
	}, [visible]);

	useEffect(() => {
		if (!visible) return;
		const keyDownHandler = (event) => {
			if (event.key === 'Enter') {
				event.preventDefault();
				onSubmit();
			}
		};

		document.addEventListener('keydown', keyDownHandler);

		return () => {
			document.removeEventListener('keydown', keyDownHandler);
		};
	}, [onSubmit, visible]);

	useEffect(() => {
		if (errors) {
			const result = errors.response?.data?.message || errors.message;
			const description = I18n.get(result);
			message.error(description);
			setErrors(null);
		}
	}, [errors]);

	function onCreateCompany({ name }) {
		Api.post('/company/create', { name, organization_id: organizationId })
			.then(setLoading(true))
			.then((res) => {
				message.success(
					I18n.get('Industrial site created successfully')
				);

				onClose();
				onCreate(res.data);
				form.resetFields();

				const company_id = res.data.id;
				const parameters = {
					organization_id: organizationId,
					company_id,
				};

				dispatch(addCompanyListOrganization(parameters));
			})
			.catch((error) => setErrors(error))
			.finally(() => setLoading(false));
	}

	function onSubmit() {
		form.validateFields()
			.then(onCreateCompany)
			.catch((err) => {});
	}

	return (
		<Modal
			title={I18n.get('Create a new industrial site')}
			visible={visible}
			onCancel={onClose}
			footer={[
				<Button key="cancel" onClick={onClose}>
					{I18n.get('Cancel')}
				</Button>,
				<Button
					key="save"
					type="primary"
					loading={loading}
					onClick={onSubmit}
				>
					{I18n.get('Save')}
				</Button>,
			]}
		>
			<Form form={form} layout="vertical" name="create_user">
				<Form.Item
					name="name"
					label={I18n.get('Industrial site name')}
					rules={[
						{
							required: true,
							message: I18n.get('Please enter a valid name'),
						},
					]}
				>
					<Input placeholder={I18n.get('Name')} ref={inputName} />
				</Form.Item>
			</Form>
		</Modal>
	);
}
