import React from 'react';
import { Col, Image, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CustomDivider } from './styles';

const GOOD_IMAGE = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/coupling-good.svg';
const FAIR_IMAGE = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/coupling-fair.svg';
const POOR_IMAGE = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/coupling-poor.svg';

export const HandCouplingTooltipContent = () => {
	return (
		<Row justify="space-between" align="middle">
			<Col xs={24}>
				<Row align="bottom">
					<Col xs={7} style={{ marginBottom: '1rem' }}>
						<Image src={GOOD_IMAGE} alt={I18n.get('Good')} preview={false} width="40px" />
					</Col>
					<Col xs={1}>
						<CustomDivider type="vertical" />
					</Col>
					<Col xs={7} style={{ marginBottom: '1rem' }}>
						<Image src={FAIR_IMAGE} alt={I18n.get('Fair')} preview={false} width="45px" />
					</Col>
					<Col xs={1}>
						<CustomDivider type="vertical" />
					</Col>
					<Col xs={7} style={{ marginBottom: '1rem' }}>
						<Image src={POOR_IMAGE} alt={I18n.get('Poor')} preview={false} width="50px" />
					</Col>
				</Row>
			</Col>
			<Col xs={24}>
				<Row align="middle">
					<Col xs={8}>
						<span>{I18n.get('Good')}</span>
					</Col>
					<Col xs={8}>
						<span>{I18n.get('Fair')}</span>
					</Col>
					<Col xs={8}>
						<span>{I18n.get('Poor')}</span>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
