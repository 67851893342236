import React from 'react';
import { Row, Col, Image } from 'antd';
import { I18n } from '@aws-amplify/core';

const LIBERTY_MUTUAL_ICON = 'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/liberty-mutual-icon.png';
const LIBERTY_MUTUAL_HOVERED_ICON =
	'https://kinebot-statics.s3.amazonaws.com/liberty-mutual/liberty-mutual-icon-hovered.png';

export const LibertyMutualIcon = () => {
	return (
		<Col xs={24}>
			<Row justify="center">
				<Col className="liberty-mutual-icon" span={24}>
					<Image src={LIBERTY_MUTUAL_ICON} width="25%" preview={false} alt={I18n.get('Material handling')} />
				</Col>
				<Col className="liberty-mutual-icon-hovered" span={24}>
					<Image
						src={LIBERTY_MUTUAL_HOVERED_ICON}
						width="25%"
						preview={false}
						alt={I18n.get('Material handling')}
					/>
				</Col>
			</Row>
		</Col>
	);
};
