import React, { ReactElement, useState } from 'react';
import { Button, Divider, Form, Input, Select } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { I18n } from '@aws-amplify/core';

import { useApplicationContext } from '@/context/v1/Application/context';
import { CycleDTO } from '@/components/ui/Inputs/types/response/cycle';
import { useCreateCycle } from '@/hooks/v1/useCycleMutations';

import { CreateCycleRequest } from '../types/request/cycle';

const { useFormInstance } = Form;

type CycleSelectProps = {
	loading: boolean;
	cycleId?: string;
	disabled: boolean;
	companyId: string;
	fieldName: NamePath;
	allowCreation?: boolean;
	cycles: CycleDTO[] | undefined;
	handleClearCycles?: () => void;
	handleSelectCycle?: (id: string) => void;
};

type OptionType = { label: string; value: string } | undefined;

export function CycleSelect({
	cycles,
	loading,
	cycleId,
	disabled,
	companyId,
	fieldName,
	handleSelectCycle,
	handleClearCycles,
	allowCreation = true
}: CycleSelectProps) {
	const [cycleName, setCycleName] = useState('');

	const { organization } = useApplicationContext();
	const { validateFields, setFieldValue } = useFormInstance();

	const { mutateAsync: createCycle, isLoading } = useCreateCycle();

	async function onCreateCycle() {
		const body: CreateCycleRequest = {
			organization_id: organization.id,
			company_id: companyId,
			name: cycleName
		};

		const created = await createCycle({ body });
		const cycleId = created?.data?.id;
		if (cycleId) {
			setFieldValue(fieldName, cycleId);
		}
		setCycleName('');
	}

	async function handleChange(id: string) {
		if (handleSelectCycle) {
			handleSelectCycle(id);
			await validateFields([fieldName]);
		}
	}

	function handleClear() {
		if (handleClearCycles) {
			handleClearCycles();
		}
	}

	function handleCycleNameChange(name: string) {
		setCycleName(name);
	}

	function filterOption(option: OptionType, input: string): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function showDropdownRender(menu: ReactElement): JSX.Element {
		if (!allowCreation) {
			return <div>{menu}</div>;
		}
		return (
			<div>
				{menu}
				<Divider style={{ margin: '4px 0' }} />
				<div
					style={{
						display: 'flex',
						flexWrap: 'nowrap',
						padding: 8
					}}
				>
					<Input
						style={{ flex: 'auto', borderRadius: '7px' }}
						value={cycleName}
						onChange={(e) => handleCycleNameChange(e.target.value)}
					/>
					<Button
						onClick={onCreateCycle}
						type="link"
						loading={isLoading}
						disabled={!cycleName || cycleName.length < 3}
					>
						{I18n.get('Add item')}
					</Button>
				</div>
			</div>
		);
	}

	return (
		<Select
			showSearch
			allowClear
			value={cycleId}
			onClear={handleClear}
			placeholder={I18n.get('Cycle')}
			filterOption={(input, option) => filterOption(option, input)}
			loading={loading}
			disabled={disabled}
			options={cycles?.map(({ id, name }) => ({ label: name, value: id }))}
			onChange={(value: string) => handleChange(value)}
			dropdownRender={(menu) => showDropdownRender(menu)}
		/>
	);
}
