import React from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { Table, Row, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';

import type { JdsD92List, JdsD92ListDTO } from './hooks/types/response';
import { PaginationCustom } from '@/components/ui/Pagination';
import { riskColors, riskLevels } from '@/utils/riskRange';
import { Text } from '@/components/Typography';
import type { SelectedReport } from './types';
import { Actions } from './Actions';
import * as S from './styles';

interface ReportListProps {
	page: number;
	isLoading: boolean;
	maxReports: number;
	data: JdsD92ListDTO;
	onPageChange: (page: number) => void;
	onDelete: (report: SelectedReport) => void;
	onMaxReportsChange: (size: number) => void;
}

export function ReportList({
	data,
	page,
	isLoading,
	maxReports,
	onPageChange,
	onDelete,
	onMaxReportsChange
}: ReportListProps) {
	const columns: ColumnsType<JdsD92List> = [
		{
			title: <Text style={{ fontWeight: 'bold' }}>{I18n.get('Analysis')}</Text>,
			dataIndex: 'name',
			key: 'name',
			ellipsis: true,
			align: 'center',
			sorter: (a, b) => sortByName(a.name, b.name)
		},
		{
			title: <Text style={{ fontWeight: 'bold' }}>{I18n.get('Workstation')}</Text>,
			dataIndex: 'file',
			key: 'file',
			ellipsis: true,
			align: 'center',
			render: (file: { workstations: { name: string } }) => file?.workstations?.name ?? '-',
			responsive: ['lg'],
			sorter: (a, b) => sortByName(a.file?.workstations?.name, b.file?.workstations?.name)
		},
		{
			title: <Text style={{ fontWeight: 'bold' }}>{I18n.get('Evaluator')}</Text>,
			dataIndex: 'evaluator',
			key: 'evaluator',
			ellipsis: true,
			align: 'center',
			render: (evaluator: { name: string }) => evaluator?.name ?? '-',
			responsive: ['lg'],
			sorter: (a, b) => sortByName(a.evaluator?.name, b.evaluator?.name)
		},
		{
			title: <Text style={{ fontWeight: 'bold' }}>{I18n.get('Rating')}</Text>,
			dataIndex: 'result',
			ellipsis: true,
			key: 'result',
			align: 'center',
			responsive: ['lg'],
			render: (num) =>
				num ? (
					<S.TagRisk brightcolors={num} color={riskColors[num]}>
						<Tooltip title={I18n.get(`${riskLevels[num]}`)}>
							{num === 5 ? (
								<Text ellipsis={true}>{I18n.get('Serious risk')}</Text>
							) : (
								<Text ellipsis={true}>{I18n.get(riskLevels[num])}</Text>
							)}
						</Tooltip>
					</S.TagRisk>
				) : (
					I18n.get('Not assigned')
				)
		},
		{
			title: <Text style={{ fontWeight: 'bold' }}>{I18n.get('Created date')}</Text>,
			dataIndex: 'created_at',
			key: 'created_at',
			ellipsis: true,
			align: 'center',
			responsive: ['lg'],
			render: (created_at) => (created_at ? moment(created_at).format('L') : '--/--/----'),
			sorter: (a, b) => sortByDate(a.created_at, b.created_at)
		},
		{
			title: <Text style={{ fontWeight: 'bold' }}>{I18n.get('Actions')}</Text>,
			key: 'actions',
			align: 'center',
			render: (_value, rowData) => <Actions rowData={rowData} report_id={data?.custom_report_id} onDeleteActionClick={onDelete} />
		}
	];

	function handlePaginationChange(page: number) {
		onPageChange(page);
	}

	function handleShowSizeChange(size: number) {
		onMaxReportsChange(size);
	}

	function sortByName(first: string | undefined, second: string | undefined) {
		if (!first || !second) {
			return -1;
		}
		return ('' + first).localeCompare(second);
	}

	const dataSource = data.rows?.map((report, index) => ({
		...report,
		key: String(index + 1)
	}));

	function sortByDate(date_a: Date | null, date_b: Date | null): number {
		return moment.utc(date_b).diff(moment.utc(date_a));
	}

	return (
		<Row justify="center">
			<S.CustomTable span={24}>
				<Table
					rowKey="key"
					columns={columns}
					pagination={false}
					loading={isLoading}
					showSorterTooltip={false}
					dataSource={dataSource}
				/>
				<PaginationCustom
					page={page}
					loading={isLoading}
					total={data?.count}
					maxPage={maxReports}
					setMaxPage={handleShowSizeChange}
					setPage={handlePaginationChange}
				/>
			</S.CustomTable>
		</Row>
	);
}
