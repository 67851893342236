import React, { useState } from 'react'
import { I18n } from '@aws-amplify/core';
import { Button, Col, Divider, Image, Row, Tooltip } from 'antd';
import { Paragraph, Text } from '@/components/Typography';
import moment from 'moment';
import { JdsD86ReviewDTO } from '../hooks/types/response';
import { TagRisk } from '../styles';
import { riskColors, riskLevels } from '@/utils/riskRange';
import { Block, ListItem } from './styles';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useDownloadFile } from '../hooks/hooks';

const DOWNLOAD_ICON = 'https://kinebot-statics.s3.amazonaws.com/download-video-link.svg';

const divider = <Col>
	<Divider
		type="vertical"
		style={{ height: '2rem', borderColor: '#00000033', margin: '0rem 0.5rem' }}
	/>
</Col>;

const getVersionName = (version: number) => {
	if (version === 1) {
		return 'Orig.'
	}

	return `R${version - 1}`
}

type Props = {
	data?: JdsD86ReviewDTO[]
}

type DownloadFileBody = {
	file_id: string;
	original_name: string;
}

const CustomTable = ({ data = [] }: Props) => {
	const { mutateAsync: downloadFile, isLoading: downloadingFile } = useDownloadFile();
	const [selectedFile, setSelectedFile] = useState<string | undefined>();
	const { organization, company } = useApplicationContext();

	async function handleDownloadFile({ file_id, original_name }: DownloadFileBody) {
		setSelectedFile(file_id)
		await downloadFile({
			company_id: company?.id,
			organization_id: organization?.id,
			file_id,
			original_name
		});
	}

	return (
		<>
			{data.map(item =>
				<ListItem>
					<Block>
						<Row key={item.id} justify="space-between" align={'middle'}>
							<Col xs={1} style={{ margin: 0 }}>
								<Row justify={'center'}>
									<Paragraph style={{ margin: 0, marginLeft: '0.7rem' }}>{getVersionName(item.version)}</Paragraph>
								</Row>
							</Col>
							<Col style={{ margin: 0 }}>
								{divider}
							</Col>
							<Col xs={8} style={{ margin: 0 }}>
								<Paragraph style={{ margin: 0 }}>{item.file_name}</Paragraph>
							</Col>
							<Col style={{ margin: 0 }}>
								{divider}
							</Col>
							<Col xs={4} style={{ margin: 0 }}>
								<Row justify={'center'}>
									<Paragraph style={{ margin: 0 }}>{item.evaluator_name}</Paragraph>
								</Row>
							</Col>
							<Col style={{ margin: 0 }}>
								{divider}
							</Col>
							<Col xs={3} style={{ margin: 0 }}>
								<Row justify="center">
									<Paragraph style={{ margin: 0 }}>{moment(item.created_at).format('L')}</Paragraph>
								</Row>
							</Col>
							<Col style={{ margin: 0 }}>
								{divider}
							</Col>
							<Col xs={4} style={{ margin: 0 }}>
								<Row justify="center">
									{item.result ? (
										<TagRisk
											brightcolors={item.result}
											color={riskColors[item.result]}
										>
											<Tooltip title={I18n.get(`${riskLevels[item.result]}`)}>
												{item.result === 5 ? (
													<Text ellipsis={true}>{I18n.get('Serious risk')}</Text>
												) : (
													<Text ellipsis={true}>
														{I18n.get(riskLevels[item.result])}
													</Text>
												)}
											</Tooltip>
										</TagRisk>
									) : (
										I18n.get('Not assigned')
									)}
								</Row>
							</Col>
							<Col style={{ margin: 0 }}>
								{divider}
							</Col>
							<Col xs={1} style={{ margin: 0 }}>
								<Row justify="center" style={{ marginLeft: '-6px' }}>
									<Button
										disabled={downloadingFile}
										loading={selectedFile === item.file_id}
										type="link"
										size="large"
										onClick={() => handleDownloadFile({
											file_id: item.file_id,
											original_name: item.file_name,
										})}
										icon={
											<Tooltip title={I18n.get('Download')}>
												<Image
													src={DOWNLOAD_ICON}
													preview={false}
													style={{ width: '45%', marginBottom: '0.2rem' }}
													alt={I18n.get('Download video')}
												/>
											</Tooltip>
										}
									/>
								</Row>
							</Col>
						</Row>
					</Block>
				</ListItem>
			)}
		</>
	)
}

export default CustomTable
