import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, DatePicker, Form, Row } from 'antd';

export const UploadedAt: React.FC = () => {
	return (
		<Col sm={4}>
			<Row>
				<Col xs={24}>
					<Form.Item name={['uploaded_at']} label={I18n.get('Uploaded date')} labelCol={{ span: 24 }}>
						<DatePicker.RangePicker
							style={{ width: '100%' }}
							format="L"
							disabledDate={(current) => current && current.valueOf() >= Date.now()}
						/>
					</Form.Item>
				</Col>
			</Row>
		</Col>
	);
};
