export function documentMaskBRL(value) {
	value = value.replace(/\D/g, '');

	if (value.length <= 11) {
		value = value.replace(/(\d{3})(\d)/, '$1.$2');
		value = value.replace(/(\d{3})(\d)/, '$1.$2');
		value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
	} else {
		value = value.replace(/^(\d{2})(\d)/, '$1.$2');
		value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
		value = value.replace(/\.(\d{3})(\d)/, '.$1/$2');
		value = value.replace(/(\d{4})(\d)/, '$1-$2');
	}

	return value;
}

export function formatSecondsDuration(seconds) {
	const format = val => `0${Math.floor(val)}`.slice(-2);
	const hours = seconds / 3600;
	const minutes = (seconds % 3600) / 60;

	return [hours, minutes, seconds % 60].map(format).join(':');
}
