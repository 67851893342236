import React from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { Col, Form } from 'antd';

import { TaskDTO } from '@/components/ui/Inputs/types/response/task';
import { TaskSelect } from '@/components/ui/Inputs/TaskSelect';
import { NamePath } from 'antd/lib/form/interface';

const { useFormInstance, useWatch } = Form;

interface TasksProps {
	cycleId: string;
	disabled: boolean;
	fieldName: NamePath;
	tasks: UseQueryResult<TaskDTO[], unknown>;
}

export const Tasks: React.FC<TasksProps> = ({ disabled, fieldName, tasks, cycleId }) => {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();

	const taskId = useWatch(fieldName, form);

	function handleTaskSelect(id: string) {
		setFieldValue(fieldName, id);
	}

	function handleClearTask() {
		setFieldValue(fieldName, undefined);
	}

	return (
		<Col xs={24}>
			<Form.Item
				name={fieldName}
				required={false}
				style={{ margin: 0 }}
				rules={[
					() => ({
						validator(_, value) {
							if (!disabled && !value) {
								return Promise.reject('');
							}
							return Promise.resolve();
						}
					})
				]}
			>
				<TaskSelect
					taskId={taskId}
					cycleId={cycleId}
					tasks={tasks.data}
					fieldName={fieldName}
					loading={tasks.isFetching}
					handleClearTask={handleClearTask}
					handleSelectTask={handleTaskSelect}
					disabled={tasks.isLoading || tasks.isFetching || disabled}
				/>
			</Form.Item>
		</Col>
	);
};
