import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Collapse } from 'antd';

import { PanelCustom } from '@/components/CustomReports/Results/Tools/styles';
import { RebaResponse, RebaData } from '@/hooks';
import { Chart } from './Chart';
import * as S from './styles';

interface IBodyPartsScoresProps {
	reba: RebaResponse;
}

export const RebaResults: React.FC<IBodyPartsScoresProps> = ({ reba }) => {
	const { data: RebaDataRows } = reba;
	const bodyPartsDataArray: RebaData[] = [];

	RebaDataRows.forEach((row) => bodyPartsDataArray.push(...row));

	const bodyPartPercentages = bodyPartsDataArray.map(({ scores, name }) => ({
		percentage: scores.map(({ percentage }) => percentage),
		name
	}));

	return (
		<Collapse ghost>
			<PanelCustom header={I18n.get('Movement by score (Kinebot/REBA)')} key={'reba-chart'}>
				<Row justify={'center'}>
					<Col span={23}>
						<S.RowCustom>
							{bodyPartPercentages.map(({ name, percentage }) => (
								<S.ColCustom xxl={12} key={`${name}_chart`}>
									<Chart angles={percentage} member={name} />
								</S.ColCustom>
							))}
						</S.RowCustom>
					</Col>
				</Row>
			</PanelCustom>
		</Collapse>
	);
};
