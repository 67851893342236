import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import { Form, Modal } from 'antd';

import { DeleteBaseRequest, HierarchyEnum, SectorResponse, UpdateBaseRequest } from '../hooks/types';
import { SectorSelect } from '@/components/ui/Inputs/SectorSelect';
import { useDelete, useGetSectors, useUpdate } from '../hooks';
import { useManageOrganizationContext } from '../context';
import { CompanyManager } from './CompanyManager';

const { useFormInstance, useWatch } = Form;

export const ManageSector = () => {
	const form = useFormInstance();
	const { isEditing, organizationId, handleIsEditing, treatFieldValidation } = useManageOrganizationContext();

	const sectorId = useWatch(['sector_id'], form);
	const companyId = useWatch(['company_id'], form);

	const {
		data: sectors,
		isLoading: gettingSectors,
		isFetching: fetchingSectors,
		isError: errorGettingSectors
	} = useGetSectors({ company_id: companyId, organization_id: organizationId });
	const { mutateAsync: deleteData } = useDelete<DeleteBaseRequest, SectorResponse>();
	const { mutateAsync: updateData } = useUpdate<UpdateBaseRequest, SectorResponse>();

	const currentName = sectors?.find(({ id }) => id === sectorId)?.name;

	async function handleUpdateName() {
		try {
			const values = await form.getFieldValue(['editing']);
			if (values) {
				const body: UpdateBaseRequest = {
					id: values?.currentId,
					company_id: companyId,
					name: values.sector_name,
					organization_id: organizationId,
					hierarchy_field: HierarchyEnum.sector
				};

				const updated = await updateData(body);
				if (updated.id) {
					handleIsEditing(false);
					form.setFieldValue('sector_id', updated.id);
					form.setFieldValue(['editing', 'sector_name'], undefined);
				}
			}
		} catch (error: any) {
			treatFieldValidation(error);
		}
	}

	async function handleDeleteSector() {
		Modal.confirm({
			title: I18n.get('Warning!'),
			content: I18n.get(
				'Heads up! Want to delete this sector? All lines and workstations related to it will be lost.'
			),
			icon: <ExclamationCircleOutlined />,
			okText: I18n.get('Confirm'),
			cancelText: I18n.get('Cancel'),
			okType: 'danger',
			onOk: async () => {
				const body: DeleteBaseRequest = {
					id: sectorId,
					company_id: companyId,
					organization_id: organizationId,
					hierarchy_field: HierarchyEnum.sector
				};
				const updated = await deleteData(body);
				if (updated.id) {
					handleClearSector();
					handleIsEditing(false);
					form.setFieldValue(['editing', 'company_name'], undefined);
				}
			}
		});
	}

	function handleSectorSelect(id: string) {
		form.setFieldValue(['sector_id'], id);
		form.setFieldValue(['line_id'], undefined);
		form.setFieldValue(['workstation_id'], undefined);
	}

	function handleClearSector() {
		form.setFieldValue(['sector_id'], undefined);
		form.setFieldValue(['line_id'], undefined);
		form.setFieldValue(['workstation_id'], undefined);
	}

	return (
		<CompanyManager
			key={companyId}
			currentId={sectorId}
			parentId={companyId}
			fieldLabel={'a sector'}
			fieldName={['sector_id']}
			currentName={currentName}
			onDelete={handleDeleteSector}
			onUpdateName={handleUpdateName}
			editingNameField={['editing', 'sector_name']}
		>
			<SectorSelect
				size="middle"
				sectors={sectors}
				sectorId={sectorId}
				allowCreation={true}
				companyId={companyId}
				fieldName={['sector_id']}
				handleClearSector={handleClearSector}
				handleSelectSector={handleSectorSelect}
				loading={isEditing || fetchingSectors}
				disabled={errorGettingSectors || gettingSectors || fetchingSectors || isEditing || !companyId}
			/>
		</CompanyManager>
	);
};
