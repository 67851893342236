import React, { useEffect } from 'react';
import { CheckboxOptionType } from 'antd/es/checkbox';
import { Col, Form, Row, Skeleton } from 'antd';
import { I18n } from '@aws-amplify/core';
import { v4 as uuidv4 } from 'uuid';

import type { AdditionalItemsResults, GetAdditionalItemOptionsResponse } from '@/hooks';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useStepKeysContext } from '../DefaultSelects/context';
import { fieldsUnitTexts } from './utils/FieldsUnitTexts';
import { useGetAdditionalItemsOptions } from '@/hooks';
import { ConditionCard } from '../../ConditionCard';
import { CustomRadioGroup } from '../../styles';
import { Text } from '@/components/Typography';
import { AditionalItemProps } from './types';

const { useWatch, useFormInstance } = Form;
const { formatLabelWithUnit } = fieldsUnitTexts;

export const Options: React.FC<AditionalItemProps> = ({ additionalItem }) => {
	const { id, title_1, unit_1 } = additionalItem;

	const form = useFormInstance();
	const { formFieldName, stepKey } = useStepKeysContext();
	const { organization, company } = useApplicationContext();

	const additionalItemOptionId = useWatch([...formFieldName, 'additional_items', id, 'additional_item_option_id_1']);

	const {
		data: options,
		isLoading: isGettingRadioOptions,
		isFetching: isFetchingRadioOptions
	} = useGetAdditionalItemsOptions({
		company_id: company?.id,
		organization_id: organization?.id,
		custom_report_step_key_id: stepKey.id
	});

	const selectedOption = options.find(({ id }) => id === additionalItemOptionId);

	const radioLabel = formatLabelWithUnit(title_1, unit_1);

	useEffect(() => {
		const result: AdditionalItemsResults[] = [
			{
				id: uuidv4(),
				additional_item_option_id_1: selectedOption?.id,
				result: selectedOption?.score ?? 0
			}
		];

		form.setFieldValue([...formFieldName, 'additional_items', id, 'results'], result);
	}, [selectedOption]);

	function handleOptions(data: GetAdditionalItemOptionsResponse[]): CheckboxOptionType[] {
		return data?.map(({ description, id }) => ({
			value: id,
			label: I18n.get(`${description}`)
		}));
	}

	if (isGettingRadioOptions) {
		return (
			<Row gutter={[0, 15]}>
				<Col span={18}>
					<Skeleton active />
				</Col>
				<Col span={16}>
					<Skeleton active paragraph />
				</Col>
				<Col span={22}>
					<Skeleton active />
				</Col>
			</Row>
		);
	}

	return (
		<Row gutter={[0, 15]} key={id}>
			<Col span={18}>
				<Text strong>{radioLabel}</Text>
			</Col>
			<Col span={16}>
				<Form.Item name={[...formFieldName, 'additional_items', id, 'additional_item_option_id_1']}>
					<CustomRadioGroup options={handleOptions(options)} />
				</Form.Item>
			</Col>
			<Col span={22}>
				<ConditionCard
					result={selectedOption?.score}
					isLoading={isGettingRadioOptions || isFetchingRadioOptions}
				/>
			</Col>
		</Row>
	);
};
