import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Form, Select, Skeleton } from 'antd';
import { NamePath } from 'antd/lib/form/interface';

import { SeverityDTO } from '@/components/ui/Inputs/types/response/severity';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

const { useFormInstance } = Form;

type SeveritySelectProps = {
	size?: SizeType;
	loading: boolean;
	disabled: boolean;
	showArrow?: boolean;
	fieldName: NamePath;
	severityId?: string;
	handleClearSeverity?: () => void;
	severities: SeverityDTO[] | undefined;
	handleSelectSeverity?: (id: string) => void;
};

type OptionType = { label: string; value: string } | undefined;

export const SeveritySelect: React.FC<SeveritySelectProps> = ({
	loading,
	disabled,
	fieldName,
	severities,
	severityId,
	size = 'middle',
	showArrow = true,
	handleClearSeverity,
	handleSelectSeverity
}) => {
	const { validateFields } = useFormInstance();

	async function handleChange(id: string) {
		if (handleSelectSeverity) {
			handleSelectSeverity(id);
			await validateFields([fieldName]);
		}
	}

	function handleClear() {
		if (handleClearSeverity) {
			handleClearSeverity();
		}
	}

	function filterOption(option: OptionType, input: string): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	if (loading) {
		return <Skeleton.Input block active size="small" />;
	}

	return (
		<Select
			showSearch
			allowClear
			size={size}
			loading={loading}
			value={severityId}
			disabled={disabled}
			showArrow={showArrow}
			onClear={handleClear}
			onChange={handleChange}
			placeholder={I18n.get('Severity')}
			filterOption={(input, option) => filterOption(option, input)}
			options={severities?.map(({ id, description, score }) => ({
				label: I18n.get(`[${score}] ${description}`),
				value: id
			}))}
		/>
	);
};
